import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledLink from '../../../../Layout/StyledLink';
import Button from '../../../../Layout/Button';
import FlaggedLimitIcon from '../../../../Layout/FlaggedLimitIcon';

const FlaggedAddTagBtn = ({ clearPref, rowCount, flags }) => {

  const tagsEnabled = flags?.tags?.enabled;

  const link = !tagsEnabled ? '#' : '/Configuration/tags/add-tag';

  const clickHandler = !tagsEnabled ? () => {} : clearPref;

  return (
    <div className="flex-row-nospacebetween-nowrap">
      <StyledLink to={link}>
        <Button disabled={!tagsEnabled} classname="transparentButton" onclick={clickHandler}>
          <FontAwesomeIcon
            style={{ width: '14px', height: '14px', marginBottom: '0px' }}
            icon={['far', 'tag']}
            className="h-50-P"
            size="1x"
          />{' '}
          Add Tag
        </Button>
      </StyledLink>
      {!tagsEnabled && (
        <FlaggedLimitIcon message="You have reached your plan limit. Please contact your CSM to upgrade and add more tags" />
      )}
    </div>
  );
};
export default FlaggedAddTagBtn;
