import React from 'react';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ViewAll = props => {
  const back = props?.goBack ? props.goBack : () => {};
  return (
    <div className="cd-viewall-container">
      <div className="cd-viewall-header">
        <div>
          {props?.location === 'allClientSalesOpportunities' ? (
            <FontAwesomeIcon
              icon="fa-sharp fa-solid fa-chevron-left"
              onClick={() => {
                props.setViewAllType('allSalesOpportunities');
                back()
              }}
              className="standard-layout__back-btn"
            />
          ) : (
            <FontAwesomeIcon
              icon="fa-sharp fa-solid fa-chevron-left"
              onClick={() => {
                props.setViewAllType('');
                back()
              }}
              className="standard-layout__back-btn"
            />
          )}
          <span>{props.title}</span>
          {props?.actionsLeft}
        </div>
        <div>{props?.actionsRight}</div>
      </div>
      <div className="cd-viewall-content scrollbar-y h-100-P">{props.children}</div>
    </div>
  );
};
export default ViewAll;
