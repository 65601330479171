import React from 'react';
import SmallTable from '../../../SmallTable';

const FiveTemplates = props => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '-58px',
        }}
      >
        <h2
          style={{
            fontSize: '15px',
            fontWeight: '500',
            textAlign: 'left',
            marginBottom: '7px',
          }}
        >
          Choose an approved template...
        </h2>
        <p
          className="pointer"
          style={{
            fontSize: '15px',
            fontWeight: '500',
            color: '#007bd0',
          }}
          onClick={() => {
            props.toggleViewAll(true);
          }}
        >
          View All
        </p>
      </div>

      <div className="add-edit-wrap" style={{ marginTop: '10px', marginBottom: '8px' }}>
        <input
          onChange={e => props.searchFunctionality(e.target.value)}
          onKeyDown={e => {
            const keyCode = e.key || e.which;
            if (keyCode === 13) {
              e.preventDefault();
            }
            return false;
          }}
          placeholder="Search WhatsApp templates..."
          className="h-50 w-100-P m-btm-6 body-head-search rem-pad-width focus-input-outline-blue"
          style={{ width: '100%', height: '50px', marginLeft: 0 }}
        />
      </div>

      <SmallTable
        tableContainerClass={'table-body-content hide-scrollbar'}
        tableClassName={'whatsapp-auto-five-templates-table hide-scrollbar'}
        tableStyle={{ marginRight: '0px', width: '100%' }}
        rowStyle={{ borderBottom: '1px solid #d0d0d0' }}
        classname="hidden"
        data={props.fiveTemplates}
        onRowClick={props.rowClickHandler}
        tableHeaders={props.tableHeaders}
        selected={props.selectedTemplate}
        noDataText={
          props.searchText ? 'No templates match your search criteria' : 'There are no approved templates currently'
        }
        noDataStyle={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default FiveTemplates;
