import React, { useMemo } from 'react';
import { Button, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.css';
import { COLLECTION_API_URL } from '../../../../../../../constants';

const CollectionProductsListHeader = props => {
  const {
    selectedProducts,
    selectedCollection,
    showAddProduct,
    saveButtonProps,
    toggleShowAddProduct,
    clientId,
    openRenameModal,
    openDeleteModal,
    openShareModal,
  } = props;

  const title = useMemo(() => {
    if (!showAddProduct) {
      return selectedCollection.collection_name;
    }

    if (!selectedProducts.size) {
      return 'Add Products';
    }

    return `${selectedProducts.size} selected`;
  }, [showAddProduct, selectedCollection.collection_name, selectedProducts]);

  const dropdownOptions = useMemo(
    () => [
      {
        key: '1',
        label: (
          <a target="_blank" href={`${COLLECTION_API_URL}/${clientId}/${selectedCollection.collection_id}`}>
            View on Web
          </a>
        ),
        icon: <FontAwesomeIcon icon="fa-regular fa-browser" style={{ fontSize: '16px' }} />,
      },
      {
        key: '2',
        label: 'Rename',
        icon: <FontAwesomeIcon icon="fa-light fa-pen" style={{ fontSize: '16px' }} />,
        onClick: () => openRenameModal(),
      },
      {
        key: '3',
        label: 'Delete',
        icon: <FontAwesomeIcon icon="fa-regular fa-trash-can" style={{ fontSize: '16px' }} />,
        onClick: () => openDeleteModal(),
      },
    ],
    [clientId, selectedCollection.collection_id],
  );

  return (
    <div className="clients-v2-collection-details--header">
      <div className="clients-v2-collection-details--header-info">
        <span className="clients-v2-collection-details--header-title">{title}</span>

        {!showAddProduct && (
          <>

            <span className="clients-v2-collection-details--header-shared">
              {selectedCollection.collection_is_shared ? 'Shared With Client' : 'Not Shared'}
            </span>
          </>
        )}
      </div>

      <div className="clients-v2-collection-details--header-options">
        {showAddProduct ? (
          <button type="button" className="secondary-button" {...saveButtonProps}>
            Save
          </button>
        ) : (
          <>
            <Button
              type="link"
              icon={<FontAwesomeIcon icon="fa-regular fa-folder-plus" />}
              onClick={toggleShowAddProduct}
            >
              Add products
            </Button>
            <Button type="link" icon={<FontAwesomeIcon icon="fa-regular fa-share" />} onClick={openShareModal}>
              Share
            </Button>
            <Dropdown menu={{ items: dropdownOptions }}>
              <Button type="link" icon={<FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />} />
            </Dropdown>
          </>
        )}
      </div>
    </div>
  );
};

export default CollectionProductsListHeader;
