/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import KioskSlider from '../../KioskSlider';
import RangePicker from '../../../CustomizedAntDesign/RangePicker';
import useSearchParams from '../../../../hooks/useSearchParams';
import fixRangeDates from '../../../../utils/fixRangeDates';

const BirthdayFilter = props => {

  const {setValue: setSearchParams, getAll} = useSearchParams();
  const params = getAll();

  const [itemOpen, setItemOpen] = useState(false);
  const [birthdayRange, setBirthdayRange] = useState([null, null]);
  const [hasBirthdayValue, setHasBirthdayValue] = useState(false);
  const [hasDoesNotHaveBirthdayValue, setHasDoesNotHaveBirthdayValue] = useState(false);

  const someFilterSelected = useMemo(
    () => params?.filterBirthdayDateRange?.some(item => item !== null) || params?.filterHasBirthday || params?.filterHasDoesNotHaveBirthday,
    [params],
  );

  const handleChangeBirthdayDateRange = (value, ...rest) => {
    setSearchParams('filterBirthdayDateRange', value ? `${value[0].format('MMDD')},${value[1].format('MMDD')}` : null);
    setSearchParams('filterHasBirthday', false);
    setSearchParams('filterHasDoesNotHaveBirthday', false);
  };

  useEffect(() => {
    if(params?.filterBirthdayDateRange && params.filterBirthdayDateRange.filter(item => item !== null).length > 0) {
      setBirthdayRange(fixRangeDates(params?.filterBirthdayDateRange))
    } else {
      setBirthdayRange([null, null])
    }
    if(params?.filterHasBirthday){
      setHasBirthdayValue(true)
    } else {
      setHasBirthdayValue(false)
    }
    if(params?.filterHasDoesNotHaveBirthday){
      setHasDoesNotHaveBirthdayValue(true)
    } else {
      setHasDoesNotHaveBirthdayValue(false)
    }
  }, [window.location.search])

  const handleChangeHasBirthday = value => {
    setSearchParams('filterBirthdayDateRange', false)
    setSearchParams('filterHasBirthday', value);
    setSearchParams('filterHasDoesNotHaveBirthday', false);
  };

  const handleChangeHasDoesNotHaveBirthday = value => {
    setSearchParams('filterBirthdayDateRange', false)
    setSearchParams('filterHasBirthday', false);
    setSearchParams('filterHasDoesNotHaveBirthday', value);
  };

  const handleClear = event => {
    event.stopPropagation();
    setSearchParams('filterBirthdayDateRange', false)
    setSearchParams('filterHasBirthday', false);
    setSearchParams('filterHasDoesNotHaveBirthday', false);
  };

  return (
    <div className="filter-item-container">
      <div className="filter-item-header" onClick={() => setItemOpen(!itemOpen)}>
        <div className="filter-item-header-title">Birthday</div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {someFilterSelected && (
            <div className="filter-item-clear" onClick={handleClear}>
              Clear
            </div>
          )}

          <FontAwesomeIcon
            icon={['far', `${itemOpen ? 'chevron-up' : 'chevron-down'}`]}
            className="fs-16"
            color="#818181"
          />
        </div>
      </div>

      {itemOpen && (
        <div className="filter-item-list-container" style={{ marginTop: '0px' }}>
          <div className="filter-item-list-toggle" style={{ width: '100%' }}>
            <RangePicker
              format="MM/DD"
              value={birthdayRange}
              onChange={handleChangeBirthdayDateRange}
              popupStyle={{ zIndex: 10000000 }}
              showToday

            />
          </div>

          <div className="filter-item-list-toggle" style={{ display: 'flex', alignItems: 'center' }}>
            <KioskSlider value={hasBirthdayValue} onChange={handleChangeHasBirthday} />

            <span className="filter-item-list-toggle-span" style={{ marginRight: '8px' }}>
              Has Birthday
            </span>

            <Tooltip
              title="This will return every client that has a birthday in their client profile."
              placement="right"
              zIndex={10000000}
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>

          <div className="filter-item-list-toggle" style={{ display: 'flex', alignItems: 'center' }}>
            <KioskSlider value={hasDoesNotHaveBirthdayValue} onChange={handleChangeHasDoesNotHaveBirthday} />

            <span className="filter-item-list-toggle-span" style={{ marginRight: '8px' }}>
              Doesn&apos;t have Birthday
            </span>

            <Tooltip
              title="This will return every client that doesn't have a birthday in their client profile."
              placement="right"
              zIndex={10000000}
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default BirthdayFilter;
