import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { postRequest } from '../../../../../../core/apiRequests';
import { notificationSuccess } from '../../../../../../core/actions';
import '../../../clientV2.css';

const ReminderButtonHeader = props => {
  const [checkCustomReminder, setCheckCustomReminder] = useState(false);

  useEffect(() => {
    if (props.selectedAction.length === 1) {
      const [action] = props.selectedAction;
      if (
        !action.life_event_id &&
        !action.tag_action_config_id &&
        !action.sales_opp_id &&
        !action.custom_event_type_id &&
        !action.sales_action_config_id
      ) {
        setCheckCustomReminder(true);
      }
    } else {
      setCheckCustomReminder(false);
    }
  }, [props.selectedAction]);

  const saveSelected = () => {
    const selected = props.selectedAction;
    const reqObj = {
      params: ['today', props.auth.merchantId, 'save'],
      data: { selected },
      query: {
        storeId: props.auth.storeId,
        associateId: props.auth.userId,
        createdBy: `${props.auth.firstName || ''} ${props.auth.lastName || ''}`,
      },
    };
    (async () => {
      const data = await postRequest(reqObj);
      if (data) {
        props.setSelectedAction([]);
        props.setReload(true);
        notificationSuccess('Reminder(s) Complete!');
      }
    })();
  };

  return (
    <div className="add-edit-wrap" style={{ justifyContent: 'flex-end' }}>
      {checkCustomReminder && (
        <button
          type="button"
          className="flex-row-nospacebetwee-nowrap border-none pointer"
          style={{ alignItems: 'center', backgroundColor: '#fff', marginRight: '45px' }}
          onClick={() => props.setEditReminderModal(true)}
        >
          <FontAwesomeIcon icon={['far', 'pencil']} className="fs-13 m-right-8" color="#33AAFC" />
          <span className="fw-600 fs-13" style={{ color: '#33AAFC' }}>
            Edit
          </span>
        </button>
      )}
      <button
        type="button"
        className="flex-row-nospacebetwee-nowrap border-none pointer"
        style={{ alignItems: 'center', backgroundColor: '#fff', marginRight: '45px' }}
        onClick={() => props.setAssignPopup(true)}
      >
        <FontAwesomeIcon icon={['far', 'user']} className="fs-13 m-right-8" color="#33AAFC" />
        <span className="fw-600 fs-13" style={{ color: '#33AAFC' }}>
          Assign Associate
        </span>
      </button>
      <button
        type="button"
        className="flex-row-nospacebetwee-nowrap border-none pointer"
        style={{ alignItems: 'center', backgroundColor: '#fff', marginRight: '45px' }}
        onClick={saveSelected}
      >
        <FontAwesomeIcon icon={['far', 'check-circle']} className="fs-16 m-right-8" color="#33AAFC" />
        <span className="fw-600 fs-13" style={{ color: '#33AAFC' }}>
          Complete
        </span>
      </button>
      <button
        type="button"
        className="flex-row-nospacebetwee-nowrap border-none pointer"
        style={{ alignItems: 'center', backgroundColor: '#fff', marginRight: '45px' }}
        onClick={() => props.handleClickDismissReminders(true)}
      >
        <FontAwesomeIcon icon={['far', 'eraser']} className="fs-16 m-right-8" color="#33AAFC" />
        <span className="fw-600 fs-13" style={{ color: '#33AAFC' }}>
          Dismiss
        </span>
      </button>
      <button
        type="button"
        className="flex-row-nospacebetwee-nowrap border-none pointer"
        style={{ alignItems: 'center', backgroundColor: '#fff', paddingRight: '0px' }}
        onClick={() => props.setShowSnoozeModal(true)}
      >
        <FontAwesomeIcon icon="fa-sharp fa-regular fa-alarm-clock" className="fs-16 m-right-8" color="#33AAFC" />
        <span className="fw-600 fs-13" style={{ color: '#33AAFC' }}>
          Snooze
        </span>
      </button>
    </div>
  );
};

export default ReminderButtonHeader;
