/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import yn from 'yn';
import { eventSubject } from '../../../core/events';
import {
  insertMyInboxList,
  setMsgResetClient,
  setMsgClient,
  getClientChat,
  setMsgChatMessages,
  updateConversationLists,
  setChatReviewLink,
} from '../../../reducers/messaging';

import Header from './components/Header';
import Messages from './components/Messages';
import ChatInputFooter from './components/ChatInputFooter';
import { DragAndDropContextProvider } from '../../../context/DragDropContext';
import { OptedOutBanner } from './components/OptedOutBanner';
import NoNumberBanner from './components/NoNumberBanner';
import { postRequest, getRequest } from '../../../core/apiRequests';
import { notificationSuccess } from '../../../core/actions';

function chatComponent({
  setDrawerToggle,
  chatIconColor,
  messaging,
  setAssignPopup,
  paymentBanner,
  type,
  auth,
  setChatMoreRefresh,
  allStoreConversationHistory,
  loading,
}) {
  const [contactMethod, setContactMethod] = useState();
  const [hasOptedOut, setHasOptedOut] = useState();
  const [hasNotOptedOut, setHasNotOptedOut] = useState();
  const [hasOptOutDate, setHasOptOutDate] = useState();
  const [cleanClientNameIfNumber, setCleanClientNameIfNumber] = useState();
  const [unknownNumber, setUnknownNumber] = useState();
  useEffect(() => {
    const subscription = eventSubject.subscribe(event => {
      const findSelectedStore = auth.stores.find(store => store.id === auth.selectedStore);
      if (findSelectedStore.name === event.store_name) {
        const { client_id } = event;
        if (messaging?.client?.client_id === client_id) {
          insertMyInboxList(event);
          // insertMyInboxConversation(event);
          // this section adds to the chat list bar for outbound collections and products or inbound messages when a user is clicked on the same conversation as an inbound message
          // the other event subject subscription covers other use cases
          if (event.hasOwnProperty('products') || event.hasOwnProperty('collections')) {
            if (event.hasOwnProperty('products')) {
              if (event.products.length > 0) {
                updateConversationLists({
                  ...event,
                  lastReadDateTime: messaging?.client?.lastSentTimestamp,
                  msg: `Sent ${event.products.length} products`,
                });
              }
            }
            if (event.hasOwnProperty('collections')) {
              if (event.collections.length > 0) {
                updateConversationLists({
                  ...event,
                  lastReadDateTime: messaging?.client?.lastSentTimestamp,
                  msg: `Sent ${event.collections.length} collections`,
                });
              }
            }
          } else if (event.client_id === messaging?.client?.client_id) {
            updateConversationLists({
              ...event,
              lastReadDateTime: messaging?.client?.lastSentTimestamp,
            });
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [messaging?.client?.client_id]);

  useEffect(() => {
    if (auth.selectedStore) {
      const reqObj = {
        params: ['chats', auth.selectedStore, 'getReviewLink'],
      };
      (async () => {
        const linkData = await getRequest(reqObj);
        if (linkData.length > 0) {
          const { review_link } = linkData[0];
          setChatReviewLink(review_link);
        }
      })();
    } else {
      console.error('Unable to getReviewLink, NO store ID!');
    }
  }, [auth.selectedStore]);

  useEffect(() => {
    setContactMethod(messaging?.email || (messaging?.mobile ?? true));
    setHasOptedOut(messaging?.DNC === 1);
    setHasNotOptedOut(messaging?.DNC !== 1);
    setHasOptOutDate(messaging?.optOutDate);
    setCleanClientNameIfNumber(messaging?.client?.name?.replace(/[{()}]|\s|[-]/g, ''));
    setUnknownNumber(messaging?.mobile?.includes(cleanClientNameIfNumber));
  }, [messaging?.email, messaging?.DNC, messaging?.optOutDate, messaging?.client, messaging?.mobile]);

  const clearSelectedClient = () => {
    setMsgResetClient();
    setMsgChatMessages([]);
    setMsgClient({});
    getClientChat({});
    setChatMoreRefresh(true);
  };

  const mergeSelectedClient = () => {
    setChatMoreRefresh(true);
  };

  const limitedVisibility =
    yn(auth.merchantConfig.LIMIT_ASSOCIATE_VISIBILITY_ENABLED) &&
    auth.role === 'ASSOCIATE' &&
    !messaging?.client?.assignedAssociates?.map(item => +item)?.includes(auth.userId);

  const getStoreName = storeId => {
    let returnStore = '';
    auth.stores.map(store => {
      if (store.id === storeId) {
        returnStore = store.name;
      }
    });
    return returnStore;
  };

  return (
    <DragAndDropContextProvider
      style={
        type === 'right'
          ? { height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }
          : {}
      }
    >
      <FullChatWrapper paymentBanner={paymentBanner} type={type}>
        <ChatBody
          setAssignPopup={setAssignPopup}
          setDrawerToggle={setDrawerToggle}
          chatIconColor={chatIconColor}
          type={type}
          contactMethod={contactMethod}
          hasOptedOut={hasOptedOut}
          hasNotOptedOut={hasNotOptedOut}
          hasOptOutDate={hasOptOutDate}
          limitedVisibility={limitedVisibility}
          unknownNumber={unknownNumber}
          messaging={messaging}
          auth={auth}
          clearSelectedClient={clearSelectedClient}
          mergeSelectedClient={mergeSelectedClient}
          getStoreName={getStoreName}
          allStoreConversationHistory={allStoreConversationHistory}
          loadingConversation={loading}
        />
      </FullChatWrapper>
    </DragAndDropContextProvider>
  );
}

const mapStateToProps = state => ({
  messaging: state.messaging,
  auth: state.auth,
  allStoreConversationHistory: state.auth.allStoreConversationHistory,
});

export default connect(mapStateToProps)(chatComponent);

const ChatBody = ({
  setAssignPopup,
  setDrawerToggle,
  chatIconColor,
  type,
  contactMethod,
  hasOptedOut,
  hasOptOutDate,
  hasNotOptedOut,
  limitedVisibility,
  unknownNumber,
  messaging,
  auth,
  clearSelectedClient,
  mergeSelectedClient,
  getStoreName,
  allStoreConversationHistory,
  loadingConversation,
}) => {
  const googleReviewAnswered = messaging.client?.type === 'G' && messaging.client.last_sent_store_id;
  const facebookConversationClosed = messaging.client?.type === 'F' && moment().diff(moment(messaging.client.clientReplyDateTime), 'hours') > 168;
  const hideChatInput = !contactMethod || limitedVisibility || hasOptedOut || hasOptOutDate || googleReviewAnswered || facebookConversationClosed;
  const showOverlay =
    allStoreConversationHistory &&
    messaging.client.last_sent_store_id !== 0 &&
    messaging.client.last_sent_store_id &&
    messaging?.client?.last_sent_store_id !== auth.selectedStore &&
    !messaging.client.sendStoreId;
  const [overlay, setOverlay] = useState(false);
  const [sendAfterOverlay, setSendAfterOverlay] = useState(false);

  const unblockPhoneNumber = async () => {
    const reqObj = {
      params: ['chats', 'unblockPhoneNumber'],
      data: {
        mobile: messaging.mobile,
      },
    };

    await postRequest(reqObj);

    setMsgClient({ isBlocked: false });
    notificationSuccess('Phone Number Unblocked');
  };

  return (
    <>
      <Header
        setAssignPopup={setAssignPopup}
        setDrawerToggle={setDrawerToggle}
        chatIconColor={chatIconColor}
        type={type}
      />
      {hasOptedOut || hasOptOutDate ? <OptedOutBanner /> : null}
      {messaging.client.isBlocked ? (
        <div className="opted-out-banner opted-out-banner-blocked">
          <p>
            <span>This number was blocked by {messaging.client.merchantName}.</span>{' '}
            <i onClick={unblockPhoneNumber}>Unblock number</i>
          </p>
        </div>
      ) : null}
      {facebookConversationClosed && (
        <div className="facebook-closed-banner">
          <p>
            <span>Facebook's time limit to respond is 7 days. Your response window has expired.</span>{' '}
          </p>
        </div>
      )}
      {unknownNumber ? (
        <NoNumberBanner
          messagingClient={messaging.client}
          messagingMobile={messaging.mobile}
          inboxList={messaging.inboxList}
          storeInboxList={messaging.storeInboxList}
          merchantId={auth.merchantId}
          userId={auth.userId}
          role={auth.role}
          pos_type={auth.pos_type}
          sync_clients={auth.sync_clients}
          first_name={auth.first_name}
          last_name={auth.last_name}
          clearSelectedClient={clearSelectedClient}
          mergeSelectedClient={mergeSelectedClient}
        />
      ) : null}
      {overlay ? (
        <div className={type === 'right' ? 'inboundStorePromptRight white' : 'inboundStorePromptDiv white'}>
          <div className="flex-col-center ">
            <span className="fw-600 fs-16 ">
              {`${messaging.client.name} messaged ${getStoreName(
                messaging.client.last_sent_store_id,
              )}. You are currently in ${getStoreName(auth.selectedStore)}'s inbox.`}
            </span>
            <span className="fw-400 fs-16 ">Which number would you like to reply from?</span>
          </div>
          <div className={type === 'right' ? 'inboundStorePromptButtonsRight' : 'inboundStorePromptButtons'}>
            <button
              type="button"
              className="save-button-v2 m-right-20"
              onClick={() => {
                setMsgClient({ ...messaging.client, sendStoreId: messaging.client.last_sent_store_id });
                setOverlay(false);
                setSendAfterOverlay(true);
              }}
            >
              {getStoreName(messaging.client.last_sent_store_id)}
            </button>
            <button
              type="button"
              className="save-button-v2"
              onClick={() => {
                setMsgClient({ ...messaging.client, sendStoreId: auth.selectedStore });
                setOverlay(false);
                setSendAfterOverlay(true);
              }}
            >
              {getStoreName(auth.selectedStore)}
            </button>
          </div>
        </div>
      ) : null}
      {/* eslint-disable-next-line no-nested-ternary */}
      {messaging.client.client_id &&
      messaging.client?.assignedAssociates &&
      !loadingConversation &&
      !contactMethod &&
      hasNotOptedOut ? (
        <div className="opted-out-banner opted-out-banner-chat-component">
          <p>No contact method available</p>
        </div>
      ) : (
        <Messages chatIconColor={chatIconColor} type={type} loadingConversation={loadingConversation} />
      )}
      {!hideChatInput && (
        <ChatInputFooter
          type={type}
          setDrawerToggle={setDrawerToggle}
          showOverlay={showOverlay}
          setOverlay={setOverlay}
          sendAfterOverlay={sendAfterOverlay}
        />
      )}
    </>
  );
};

const FullChatWrapper = ({ children, paymentBanner, type }) => {
  return type === 'full' ? (
    <div
      className={`conversation-window
        ${paymentBanner ? 'conversationWindowHeightWithBanner' : 'conversationWindowHeightNoBanner'}
      `}
    >
      {children}
    </div>
  ) : (
    children
  );
};
