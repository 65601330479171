import './index.css';
import React from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import store from './core/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider as ConfigProvider5 } from 'antd-v5';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import ReportContext from './context/ReportContext';

const queryClient = new QueryClient()

ReactDOM.render(
  <ConfigProvider5
    prefixCls="ant5"
    theme={{
      components: {
        Timeline: {
          dotBg: 'transparent'
        },
      },
    }}
  >
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <ReportContext>
            <App />
          </ReportContext>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </ConfigProvider5>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
