import React from 'react';
import { Link } from 'react-router-dom';

const TheEdge = () => {
  return (
    <>
      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Overview</h3>

        <p>
          Connect your Edge account with Clientbook! This connection will sync your business&apos;s existing Edge data
          with Clientbook&apos;s desktop and mobile platforms. There are two different ways that this data is synced:
        </p>

        <p>
          <strong>Two-Way</strong>: Client information (ex. Birthdays, phone numbers etc.), client activity history and
          Collection items/Wishlists are all <strong>two-way data</strong>. This means that if you update this data in
          Clientbook, it will automatically appear in Edge and vice versa.
        </p>

        <p>
          <strong>One-Way</strong>: Chat history, Products/Product images and purchase history are all{' '}
          <strong>one-way data</strong>. This means that this data will only be transferred automatically from Edge to
          Clientbook. If you update this information in Edge, it will automatically appear in Clientbook. But if you
          update this data into Clientbook, it will <strong>not</strong> automatically appear in Edge.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Important Notes to Remember</h3>

        <ul className="cb-integrations-details__list">
          <li>
            Our team will handle installation for you. Reach out to your Customer Success Manager and they will promptly
            assist you with getting your integration installed.
          </li>

          <li>
            You can set up Clientbook as your SMS provider in Edge. This means that any Edge messaging notifications
            will pop up in Clientbook. Clientbook notifications will not appear in Edge.
          </li>

          <li>
            When you enter information into Clientbook, there is a time interval before it appears in Edge. The transfer
            of information is <strong>not immediate</strong>. Every 15 minutes, a sync will occur. So, don&apos;t worry
            if your information doesn&apos;t appear right away. It will appear after 15 minutes.
          </li>

          <li>
            The transfer from Edge to Clientbook is <strong>almost immediate</strong>. Any time you enter information
            into Edge, it will promptly appear in Clientbook.
          </li>
        </ul>

        <p>
          If you have any questions regarding the Edge-Clientbook integration, reach out to our Support Team at
          support@clientbook.com or (385) 352-3518.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Helpful Links</h3>

        <ul className="cb-integrations-details__list">
          <li>
            <Link to="/Help/contact-support">Clientbook Help Center</Link>
          </li>
        </ul>
      </section>
    </>
  );
};

export default TheEdge;
