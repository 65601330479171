/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image, Skeleton } from 'antd';
import { getProductsElasticSearch } from '../../../../api/products';
import useDebounce from '../../../../hooks/useDebounce';

const ChatProductModal = ({ className, onClose, onSelect }) => {
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);

  useEffect(() => {
    setLoading(true);
    getProductsElasticSearch({
      params: {
        limit: 150,
        hasImage: true,
        search: debouncedSearch,
      },
    })
      .then(res => {
        setProducts(res.data.data.filter(product => !!product?.image));
        setTotal(res.data.total);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, [debouncedSearch]);

  const handleChangeSearch = e => {
    setSearch(e.target.value);
  };

  const handleSelectProduct = useCallback(product => {
    onSelect(product);
    onClose();
  }, []);

  return (
    <div
      className={className}
      style={{
        maxHeight: '700px',
        minHeight: '500px',
        overflow: 'hidden',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <span className="chat-templates-modal-header">{`Products ${total !== 0 ? `(${total})` : ''}`}</span>
      <div
        style={{
          position: 'absolute',
          right: '10px',
          top: '10px',
        }}
        onClick={onClose}
      >
        <FontAwesomeIcon className="fs-16 pointer" icon={['fa', 'x']} />
      </div>
      <input
        onChange={handleChangeSearch}
        className="searchInput h-45 bg-offwhite fs-13 focus-input-outline-blue w-300"
        placeholder="Search by name or item number"
        autoFocus
        style={{ minHeight: '35px' }}
      />
      <p
        style={{
          color: '#818181',
          fontSize: '12px',
          fontStyle: 'italic',
          marginTop: '10px',
        }}
      >
        Only showing products with images
      </p>
      {loading ? (
        <div className="chat-product-modal-list">
          {Array(3)
            .fill('')
            .map((_, index) => (
              <Skeleton.Input
                key={`sk-${index}`}
                active
                style={{
                  width: '100%',
                  height: '50px',
                  marginBottom: '10px',
                }}
              />
            ))}
        </div>
      ) : (
        <div className="chat-product-modal-content">
          {products.length > 0 ? (
            <div className="chat-product-modal-list scrollbar-y">
              {products.map(product => (
                <ProductItem key={product.id} onSelect={() => handleSelectProduct(product)} {...product} />
              ))}
            </div>
          ) : (
            <p className='align-center'>No products found</p>
          )}
        </div>
      )}
    </div>
  );
};
export default ChatProductModal;

const ProductItem = ({ onSelect, image, sku, name }) => {

  const [hasImageValid, setHasImageValid] = useState(true);

  return (
    hasImageValid ? (
      <div className="chat-product-modal-item" onClick={onSelect}>
        <div className="chat-product-modal-item-img">
          <Image
            src={image || 'https://dashboard-v2-images.s3.amazonaws.com/not-found.png'}
            width={50}
            height={50}
            preview={false}
            fallback="https://dashboard-v2-images.s3.amazonaws.com/not-found.png"
            onError={() => setHasImageValid(false)}
          />
        </div>

        <div className="chat-product-modal-item-content">
          <div>{name}</div>
          <p>{sku}</p>
        </div>
      </div>
    ) : (
      null
    )
  );
};
