import React from 'react';
import { Link } from 'react-router-dom';

const IntegrationsKWI = () => {
  return (
    <>
      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Overview</h3>

        <p>
          Connect your ProfitSystems account with Clientbook! This connection will sync your business&apos;s Profit
          Systems data with Clientbook&apos;s desktop and mobile platforms. There are two different ways that this data
          is synced
        </p>
        <p>
          <b>One-Way:</b> Clients data, Products, and purchase history are all one-way data. This means that this data
          will only be transferred automatically from Profit Systems to Clientbook. If you update this information in
          Profit Systems, it will appear in Clientbook. But if you update this data into Clientbook, it will not appear
          in Profit Systems.
        </p>
        <p>
          <b>Important Notes to Remember</b>
          <ol>
            <li type="disc">
              Merchants will need to be on Retailvantage API with Profit Systems. If you are not set up with this please
              contact Profit Systems.
            </li>
            <li type="disc">
              Profit Systems will provide Clientbook with the Credentials needed to start the integration.
            </li>
            <li type="disc">
              Clientbook will need the stores and pricing that you would like to be pulled into Clientbook. (Example:
              Price 1, Price 2 or Price 3)
            </li>
            <li type="disc">
              When you enter information into Profit Systems, there is a time interval before it appears in Clientbook.
              The transfer of information is not immediate. Every 15 minutes, a sync will occur. So, don&apos;t worry if
              your information doesn&apos;t appear right away. It will appear after 15 minutes.
            </li>
            <li type="disc">
              If you have any questions regarding Profit Systems-Clientbook integration, reach out to our Support Team
              at support@clientbook.com or (385) 352-3518.
            </li>
          </ol>
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Key Features</h3>

        <p>By adding your Profit Systems information to Clientbook, you can:</p>

        <ul className="cb-integrations-details__list">
          <li>
            Sync your list of clients to send automated messages, request payments and track specific life events to
            maximize POS activity.
          </li>
          <li>
            Attach you associated to document sales opportunities and intensify your sales team&apos;s efficiency.
          </li>
          <li>
            Connect your products to record sales triggers and transaction history to effectively document your sales in
            a clear, easy-to-use platform.
          </li>
          <li>
            Maximize communication with your clients by syncing documented Profit System sales transactions to trigger
            automated messsages, life events and client activities.
          </li>
        </ul>
      </section>
      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Helpful Links</h3>

        <ul className="cb-integrations-details__list">
          <li>
            <Link to="/Help/contact-support">Clientbook Help Center</Link>
          </li>
        </ul>
      </section>
    </>
  );
};

export default IntegrationsKWI;
