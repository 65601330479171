import React from 'react';
import { Link } from 'react-router-dom';

export default props => (
  <Link
    id={props.propertyId || ''}
    className={props.classprops ? `blue-link ${props.classprops}` : 'blue-link'}
    style={{ textDecoration: 'none', ...props.styleprops }}
    {...props}
  >
    {props.children}
  </Link>
);
