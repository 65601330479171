export const isValidUrl = str => {
  const match = str.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
  );

  return match !== null;
};

export const arrayHasDuplicates = array => {
  const set = new Set(array);
  return array.length !== set.size;
};

export const associateNameFormat = (firstName, lastName) => {
  if (firstName || lastName) {
    const fn = firstName || '';
    const ln = lastName || '';
    return `${fn} ${ln}`.trim();
  }
  return '';
}

export const url = (() => {
  return process.env.REACT_APP_API_URL;
})();

export const isDateValid = (dateStr) => {
  try {
    return !isNaN(new Date(dateStr));
  } catch (e) {
    console.log('Really bad date!');
    return false;
  }
}