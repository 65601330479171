import { oneOf, string } from 'prop-types';
import React from 'react';

const COLOR = {
  blue: {
    background: '#E8F4FF',
    color: '#33AAFC',
  },
  gray: {
    background: '#EBEBEB',
    color: '#9C9C9C',
  },
  red: {
    background: '#F9E0E0',
    color: '#FF7A7A',
  },
};

const Bubble = ({ text, color }) => {
  return (
    <div
      style={{
        ...COLOR[color],
        padding: '4px 0 2px 0',
        textTransform: 'uppercase',
      }}
      className="rem-pad-width align-center align-vert-middle h-24 fs-10 fw-600 w-72 border-r4"
    >
      {text}
    </div>
  );
};

Bubble.propTypes = {
  text: string,
  color: oneOf(Object.keys(COLOR)),
};

Bubble.defaultProps = {
  color: 'gray',
};

export default Bubble;
