/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import KioskSlider from '../../KioskSlider';
import RangePicker from '../../../CustomizedAntDesign/RangePicker';
import useSearchParams from '../../../../hooks/useSearchParams';

const disabledDate = current => {
  return current && current > moment().endOf('day');
};

const LastMessageSentFilter = props => {

  const {setValue: setSearchParams, getAll} = useSearchParams();
  const params = getAll();
  const [toggle, setToggle] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [lastMessageSentRange, setLastMessageSentRange] = useState([null, null]);
  const [lastMessageSentValue, setLastMessageSentValue] = useState(false);
  const [lastMessageNeverSentValue, setLastMessageNeverSentValue] = useState(false);

 useEffect(() => {
  if(params?.filterLastMessageSentDateRange) {
    setLastMessageSentValue(true)
    if(params.filterLastMessageSentDateRange.filter(item => item !== null && item !== '').length > 0) {
      const [start, end] = params?.filterLastMessageSentDateRange;
      setLastMessageSentRange([moment(start, 'YYYYMMDDHHmmss'), moment(end, 'YYYYMMDDHHmmss')])
    } else {
      setLastMessageSentRange([null, null])
    }
  } else {
    setLastMessageSentValue(false)
    setLastMessageSentRange([null, null])
  }

  if(params?.filterLastMessageNeverSent){
    setLastMessageNeverSentValue(true)
    setLastMessageSentValue(false)
  } else {
    setLastMessageNeverSentValue(false)
  }
}, [window.location.search])

  const someFilterSelected = useMemo(
    () => params.filterLastMessageSentDateRange || params?.filterLastMessageSentDateRange?.some(item => item !== null) || params.filterLastMessageNeverSent,
    [params],
  );

  const handleChangeLastMessageSent = value => {
    if (!value) {
      setSearchParams('filterLastMessageSentDateRange', false)
    } else {
      setSearchParams('filterLastMessageSentDateRange', [null, null])
    }
    setSearchParams('filterLastMessageNeverSent', false)
  };

  const handleChangeLastMessageSentDateRange = value => {
    setSearchParams('filterLastMessageSentDateRange', value ? `${value[0]?.utc().startOf('day').format('YYYYMMDDHHmmss')},${value[1]?.utc().endOf('day').format('YYYYMMDDHHmmss')}` : null);
    setSearchParams('filterLastMessageNeverSent', false)
  };

  const handleChangeLastMessageNeverSent = value => {
    if (value) {
      setSearchParams('filterLastMessageSentDateRange', false);
    }
    setSearchParams('filterLastMessageNeverSent', value)
  };

  const handleClear = event => {
    event.stopPropagation();
    setSearchParams('filterLastMessageSentDateRange', false);
    setSearchParams('filterLastMessageNeverSent', false)
  };

  return (
    <div className="filter-item-container">
      <div className="filter-item-header" onClick={() => setToggle(!toggle)}>
        <div className="filter-item-header-title">Last Message Sent</div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {someFilterSelected && (
            <div className="filter-item-clear" onClick={handleClear}>
              Clear
            </div>
          )}

          <FontAwesomeIcon
            icon={['far', `${toggle ? 'chevron-up' : 'chevron-down'}`]}
            className="fs-16"
            color="#818181"
          />
        </div>
      </div>

      {toggle && (
        <div className="filter-item-list-container" style={{ marginTop: '0px' }}>
          <div className="filter-item-list-toggle" style={{ display: 'flex', alignItems: 'center' }}>
            <KioskSlider value={lastMessageSentValue} onChange={handleChangeLastMessageSent} />

            <span className="filter-item-list-toggle-span">Sent Between</span>
          </div>

          {lastMessageSentValue && (
            <div className="filter-item-list-toggle" style={{ width: '100%' }}>
              <RangePicker
                open={datePickerOpen}
                onOpenChange={setDatePickerOpen}
                format="MM/DD/YYYY"
                disabledDate={disabledDate}
                value={lastMessageSentRange}
                onChange={handleChangeLastMessageSentDateRange}
                popupStyle={{ zIndex: 10000000 }}
                showToday
                autoFocus
                onFocus={() => setDatePickerOpen(true)}
              />
            </div>
          )}

          <div className="filter-item-list-toggle" style={{ display: 'flex', alignItems: 'center' }}>
            <KioskSlider value={lastMessageNeverSentValue} onChange={handleChangeLastMessageNeverSent} />

            <span className="filter-item-list-toggle-span">Never Sent</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LastMessageSentFilter;
