import React, { useEffect, useState } from 'react';
// import DescOverInput from "../../../../../Layout/DescOverInput";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setTriggerReminderInterval } from '../../../../../../../reducers/salesTriggers';
import DropdownSelect from '../../../../../../Layout/DropdownSelect';
import { getRequest, postRequest } from '../../../../../../../core/apiRequests';
import { notificationError, notificationSuccess } from '../../../../../../../core/actions';
import NumberInput from '../../../../../../Layout/NumberInput';

const PriceModal = props => {
  const [minPrice, setMinPrice] = useState(props.minPrice);
  const [maxPrice, setMaxPrice] = useState(props.maxPrice);

  useEffect(() => {
    // getTriggerCategories();
  }, [props.merchantId]);

  const hasAllSelected = () => {
    let hasAll = true;
    props.categories.forEach(ct => {
      if (!props.triggerCategories.includes(ct.category)) {
        hasAll = false;
      }
    });
    return hasAll;
  };

  const selectAllHandler = () => {
    if (hasAllSelected()) {
      props.setTriggerActionObj({ triggerCategories: [] });
    } else {
      props.setTriggerActionObj({
        triggerCategories: [...props.categories.map(ct => ct.category)],
      });
    }
  };

  const savePrices = () => {
    props.setTriggerActionObj({ maxPrice, minPrice });
    props.closeModal(false);
  };

  const checkboxClickHandler = (category, addIt) => {
    const tempArr = props.triggerCategories;
    if (addIt) {
      // add it
      tempArr.push(category);
      props.setTriggerActionObj({ triggerCategories: [...tempArr] });
    } else {
      // remove it
      const filteredArr = tempArr.filter(cat => cat !== category);
      props.setTriggerActionObj({ triggerCategories: [...filteredArr] });
    }
  };

  return (
    <div className="pos-abs z-index-5 left-0 align-top w-100-vw h-100-vh bg-white p-50 rem-pad-width max-h-100vh overflowY">
      <div className="w-100-P align-left m-top-60" style={{ maxHeight: '100%', maxWidth: '1000px' }}>
        <div className="flex-col-left w-100-P m-btm-15">
          <div className="flex-row-spacebetween-wrap w-100-P">
            <div className="flex-col-left">
              <span className="align-left fs-16 fw-500 w-100-P">Price Range</span>
              <span className="fs-14 m-btm-15">Optional</span>
            </div>
            <div className="flex-row-nospacebetween-wrap">
              <button className="cancel-button m-right-15 m-btm-15" onClick={() => props.handleCancelModal()}>
                Cancel
              </button>
              <button className="save-button" onClick={savePrices}>
                Done
              </button>
            </div>
          </div>

          <div className="m-top-50 w-100-P flex-row-nospacebetween-wrap" style={{ height: '100%' }}>
            <div className="m-right-15" style={{ maxWidth: '50%', width: 'calc(50% - 7.5px)' }}>
              <NumberInput
                value={minPrice || ''}
                min={0}
                classProps=""
                styleProps={{ width: '100%' }}
                placeholder="No minimum"
                onChange={val => setMinPrice(val)}
                decorator="$"
              />
            </div>
            <div style={{ maxWidth: '50%', width: 'calc(50% - 7.5px)' }}>
              <NumberInput
                value={maxPrice || ''}
                classProps=""
                min={0}
                styleProps={{ width: '100%' }}
                placeholder="No maximum"
                onChange={val => setMaxPrice(val)}
                decorator="$"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
  salesTriggers: state.salesTriggers,
  autoRemMsgs: { ...state.autoRemMsgs },
});
const mapDispatchToProps = dispatch => ({
  setInterval(val) {
    setTriggerReminderInterval(val);
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(PriceModal);
