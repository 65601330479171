import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setAuthEdison } from '../../../../reducers/auth';
import { differenceInHours, formatISO } from 'date-fns';
import { updatedAiAssistantViewDate } from '../apiRequests';

const EdisonToolTip = props => {
  const { leadObj, setShowLead, setShowToolTip, userId, AIActivity, vendorItemViewHandler } = props;

  function showEdisonToolTipFunc(e) {
    e.preventDefault();
    e.stopPropagation();
    setShowLead(true);
    setShowToolTip(false);
    updatedAiAssistantViewDate(userId, setAuthEdison);
    vendorItemViewHandler();
    AIActivity({ eventType: 'activity_viewed', msgRegen: false, msgEdit: false, resultCode: null });
  }

  function closeEdisonToolTip(e) {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem('aiToolTip', formatISO(new Date()));
    setShowToolTip(false);
  }

  const showEdisonToolTip = useMemo(() => {
    const lastClosed = localStorage.getItem('aiToolTip');
    if (lastClosed) {
      const lastClosedDate = new Date(lastClosed);
      const today = new Date();
      if (differenceInHours(today, lastClosedDate) > 24) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [localStorage])

  return (
    (leadObj?.client?.name && showEdisonToolTip ? <div className="pos-abs edisonToolTipOuterDiv">
      <FontAwesomeIcon
        onClick={e => closeEdisonToolTip(e)}
        className="pos-abs pointer"
        icon="fa-solid fa-x"
        style={{ color: '#fff', right: '15px', top: '15px', height: '18px', width: '18px' }}
        id="edisonToolTipClose"
      />
      <div className="align-left" style={{ margin: '20px 5px 0px 25px ' }}>
        <span
          className="white fs-13px fw-500 d-block"
          style={{ width: '235px' }}
        >{leadObj?.type?.headerText || `${leadObj?.client?.name} is ready to buy!`}</span>
        <div
          role="button"
          tabIndex={0}
          className="align-left"
          style={{ marginRight: '35px', marginTop: '5px' }}
          id="edisonToolTipLetsGetStarted"
          onClick={e => showEdisonToolTipFunc(e)}
        >
          <span style={{ color: '#33AAFC' }} className="fw-600 fs-16 align-left pointer">
            Let’s send a message!
          </span>
          <FontAwesomeIcon
            size="lg"
            icon="fa-regular fa-arrow-right"
            style={{ color: '#33aafc' }}
            className="m-left-29 pointer"
          />
        </div>
      </div>
      <FontAwesomeIcon
        icon="fa-solid fa-caret-up"
        className="pos-rel"
        size="xl"
        style={{ color: '#000', left: 2, bottom: '83px' }}
      />
    </div>: '')
  );
};

export default EdisonToolTip;
