const FIELD_PROPS = {
  PHONE: {
    placeholder: '10 digits',
    maxLength: '10',
    inputMode: 'numeric',
    helpText: '10 digits, no dashes',
  },
  DOB: {
    label: 'Date of Birth',
    placeholder: 'MM/DD/YYYY',
    maxLength: '10',
    helpText: 'MM/DD/YYYY',
  },
};

export default FIELD_PROPS;
