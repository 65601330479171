import React from 'react';
import { Link } from 'react-router-dom';

const LikeSew = () => {
  return (
    <>
      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Overview</h3>

        <p>
          Connect your Like Sew account with Clientbook! Like Sew is a leading POS platform that&apos;s been designed
          specifically for fabric and quilt stores. Like Sew enables stores to quickly obtain customer, product and
          sales data, as well as providing an all-inclusive eCommerce platform.
        </p>

        <p>
          By integrating these platforms, you will be able to effortlessly combine your business&apos;s existing Like
          Sew data with Clientbook&apos;s desktop and mobile platforms. Specifically, this will bring your client,
          product and sales data over to Clientbook. Reach out to your Customer Success Manager to get started!
        </p>

        <p>
          Please be sure the Like Sew user has the rights to access all relevant information. If the user is removed
          from the connected Like Sew account, the account will be removed from Clientbook.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Key Features</h3>

        <p>By adding your Like Sew information to Clientbook, you can:</p>

        <ul className="cb-integrations-details__list">
          <li>Send payment requests and track specific Life Events to maximize POS activity.</li>

          <li>Document Sales Opportunities to intensify your sales team&apos;s efficiency.</li>

          <li>Send Product Collections based on your client&apos;s tastes to effectively market your products.</li>

          <li>
            Maximize communication with your clients by creating Auto Messages. These messages can be sent automatically
            on days based on birthdays, anniversaries, special orders, sales etc.
          </li>
        </ul>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Setup Process</h3>

        <p>
          If you want to integrate your Like Sew account with Clientbook, reach out to your Customer Success Manager and
          they will assist you with the installation.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Helpful Links</h3>

        <ul className="cb-integrations-details__list">
          <li>
            <Link to="/Help/contact-support">Clientbook Help Center</Link>
          </li>
        </ul>
      </section>
    </>
  );
};

export default LikeSew;
