import Axios from 'axios';
import yn from 'yn';
import { API_URL } from '../../../../constants';
import { version } from '../../../../../package.json';
import { updateRequestHeaders } from '../../../../utils/request';
import { setAuth, setPlanFlags } from '../../../../reducers/auth';
import { logRocketIdentify } from '../../../../utils/logRocketIdentify';
import { deC, enC } from './Alch/theCrypt';

export const gAlch = async ({ setSidePanel }) => {
  const result = await Alch();
  if (result) {
    const data = deC(result);
    if (data) {
      const params = new URLSearchParams(window.location.search);
      const emailParams = params.get('a');
      const passwordParams = params.get('c');
      if (emailParams && passwordParams) {
        const decodedEmail = atob(emailParams);
        const decodedPassword = atob(passwordParams);
        const auth = {
          username: decodedEmail,
          password: decodedPassword,
        };
        const tkNa = enC({ tKn: data, auth });
        if (tkNa.tKn) {
          consoleLogin(tkNa, setSidePanel);
        } else {
          console.error('[gAlch] Error packaging data');
        }
      } else {
        console.error('[gAlch] Missing params: ', { emailParams, passwordParams });
      }
    } else {
      console.error('[gAlch] Error from Alch');
    }
  }
};

export class LoginHandler {
  constructor(type, token) {
    this.type = type;
    this.token = token;
  }

  endpoints = {
    basic: `${API_URL}/login`,
    console: `${API_URL}/login`,
    token: `${API_URL}/tokenLogin`,
  };

  loginHandler = async (requestBody, headers) => {
    try {
      const endpoint = this.endpoints[this.type];
      
      if(this.token) {
        Axios.defaults.headers.common.Authorization = this.token;
      }
      Axios.defaults.headers.common['atx'] = 'f6d31a3a-6bc4-4f54-a7fb';

      const loginResponse = await Axios.post(endpoint, requestBody, {
        headers: {
          'x-clientbook-user-agent': `clbk-web|${version}|${window.navigator.platform}`,
          ...headers
        },
      });

      let responseBody = loginResponse?.data;
      if(responseBody.hasOwnProperty('user')) {
        responseBody = responseBody.user;
      }

      if(!responseBody.associateId) throw "Login failed, no associateId on response."
      const {        
        allStoreConversationHistory,
        associateId,
        associateUuid,
        brandImage,
        brandName,
        edison_view_date,
        elasticSearchEnabled,
        elasticSearchEnabledProducts,
        email,
        enabled_email,
        enabled_sms,
        enabled_whatsapp,
        firstName,
        google_integration,
        image,
        iso_country_code,
        lastName,
        license_count,
        merchantId: licenseKey,
        merchantConfig,
        merchantFlags,
        message_notifications,
        id: merchantId,
        merchantPassword,
        messageLimit,
        meta_integration,
        mobile,
        password,
        payments,
        pipelineEnabled,
        planCode,
        pos_clients_last_updated_date,
        pos_products_last_updated_date,
        pos_purchase_history_last_updated_date,
        pos_type,
        role,
        scheduling_link,
        storeId,
        stores,
        survey,
        sync_clients,
        sync_products,
        sync_purchase_history,
        uuid,
        webKioskMode,
      } = responseBody;

      const token = responseBody.token || this.token || loginResponse?.data?.token;

      const userObj = {
        allStoreConversationHistory,
        userId: associateId,
        associateUuid,
        brandImage,
        brandName,
        edison_view_date,
        email,
        elasticSearchEnabled: yn(elasticSearchEnabled),
        elasticSearchEnabledProducts: yn(elasticSearchEnabledProducts),
        enabled_email,
        enabled_sms,
        enabled_whatsapp,
        firstName,
        google_integration: yn(google_integration),
        image,
        iso_country_code,
        lastName,
        license_count,
        licenseKey,
        merchantConfig,
        merchantId,
        merchantPassword,
        message_notifications,
        messageLimit,
        meta_integration: yn(meta_integration),
        mobile,
        password,
        payments,
        pipelineEnabled: yn(pipelineEnabled),
        planCode,
        pos_clients_last_updated_date,
        pos_products_last_updated_date,
        pos_purchase_history_last_updated_date,
        pos_type,
        role,
        schedulingLink: scheduling_link,
        selectedStore: Array.isArray(stores) ? stores[0].id : null,
        storeId,
        stores: [{ id: 0, name: 'All Stores' }, ...stores],
        survey,
        sync_clients: yn(sync_clients),
        sync_products: yn(sync_products),
        sync_purchase_history: yn(sync_purchase_history),
        token: token || this.token,
        uuid,
        webKioskMode: yn(webKioskMode),
      };

      const userAgent = `clbk-web|${version}|${window.navigator.platform}`;
      Axios.defaults.headers.common['x-clientbook-user-agent'] = userAgent;
      
      Axios.defaults.headers.common.Authorization = this.token || token; // all axios requests will use this token now
      Axios.defaults.headers.common['x-clientbook-user-id'] = associateId;
      Axios.defaults.headers.common['x-clientbook-merchant-id'] = merchantId;

      updateRequestHeaders({ merchantId, associateId, token });

      if(this.type === 'console') {
        localStorage.setItem('account', '');
        localStorage.setItem('jwtToken', '');
      }
      setAuth(userObj, token);
      setPlanFlags(merchantFlags);
      logRocketIdentify(associateId, firstName, lastName, email, merchantId);

      return userObj;
    }
    catch (error) {
      console.log(`[${this.type}] Login error: `, error);
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message !== 'string') {
          return { error: JSON.stringify(error.response.data.message) };
        } else {
          return { error: error.response.data.message.split('"').join('') };
        }
      } else {
        return { error: 'Server error' };
      }
    }
  }
}
