import React from 'react';
import { Link } from 'react-router-dom';

const Rain = () => {
  return (
    <>
      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Overview</h3>

        <p>
          Connect your Rain account with Clientbook! This connection will effortlessly combine your business&apos;s
          existing Rain data with Clientbook&apos;s desktop and mobile platforms. Specifically, this will bring your
          client, product and sales data over to Clientbook. Reach out to your Customer Success Manager to get started!
        </p>

        <p>
          Please be sure the Rain user has the rights to access all relevant information. If that user is removed from
          the connected Jewel 360 account, the account will be removed from Clientbook.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Key Features</h3>

        <p>By adding your Rain information to Clientbook, you can:</p>

        <ul className="cb-integrations-details__list">
          <li>Send payment requests and track specific Life Events to maximize POS activity.</li>

          <li>Document Sales Opportunities to intensify your sales team&apos;s efficiency.</li>

          <li>Send Product Collections based on your client&apos;s tastes to effectively market your products.</li>

          <li>
            Maximize communication with your clients by creating Auto Messages. These messages can be sent automatically
            on days based on birthdays, anniversaries, special orders, sales etc.
          </li>
        </ul>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Setup Process</h3>

        <p>
          If you want to integrate your Rain account with Clientbook, reach out to your Customer Success Manager and
          they will assist you with installation.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Helpful Links</h3>

        <ul className="cb-integrations-details__list">
          <li>
            <Link to="/Help/contact-support">Clientbook Help Center</Link>
          </li>
        </ul>
      </section>
    </>
  );
};

export default Rain;
