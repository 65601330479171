import React, { useEffect } from 'react';
import StandardLayoutV2 from "../../../../componentsV2/Layout/StandardLayoutV2";
import { Button } from 'antd-v5';
import ReportCard from './Card';
import { PurchaseOutline, MassMessages, SalesOpportunity, LightningBolt, MessageReviews, NewClient, PaymentRequests } from '../../../../componentsV2/Layout/Report/components/Icons'
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { TeamOutlined } from '@ant-design/icons';



const reports = ({isPOS, payment}) =>  {
  const salesCard = isPOS ? [{
    title: 'Sales',
    icon: <PurchaseOutline style={{ fontSize: '22px', marginRight: '8px' }}/>,
    description: 'Report of purchases with related Clientbook activities. Transactions include a timeline of Clientbook activities leading up to purchase.',
    path: '/Reports/sales'
  }] : [];
  const paymentCard = payment ? [{
    title: 'Payment Requests',
    icon: <PaymentRequests style={{ fontSize: '22px', marginRight: '8px' }}/>,
    description: 'Report of all payment links sent and their current status. Includes payment details and ability to export bank transactions for reconciliation.',
    path: '/Reports/payment-requests'
  }] : [];
  return [
  ...salesCard,
  {
    title: 'Automations',
    icon: <LightningBolt style={{ fontSize: '22px', marginRight: '8px' }}/>,
    description: 'Report of all automated messages, reminders, and AI activities generated by Clientbook. Includes sales related to these activities.',
    path: '/Reports/automations'
  },
  {
    title: 'Mass Messages',
    icon: <MassMessages style={{ fontSize: '22px', marginRight: '8px' }}/>,
    description: 'Report of mass messages sent including size of audience, number of delivered messages and related sales.',
    path: '/Reports/mass-message'
  },
  {
    title: 'New Clients',
    icon: <NewClient style={{ fontSize: '22px', marginRight: '8px' }}/>,
    description: 'Report of all new clients through inbound channels or added by associates. Includes any related purchases for each client.',
    path: '/Reports/new-clients'
  },
  {
    title: 'Sales Pipeline',
    icon: <SalesOpportunity style={{ fontSize: '22px', marginRight: '8px' }}/>,
    description: 'Report of all sales pipeline with projected revenue and win rate. Includes assigned activities associated with Open Opportunities.',
    path: '/Reports/sales-opp'
  },
  {
    title: 'Google Reviews',
    icon: <MessageReviews style={{ fontSize: '22px', marginRight: '8px' }}/>,
    description: 'Report of all Google Review links sent and opened by store and associate. Includes a count of reviews received and average rating.',
    path: '/Reports/google-review'
  },
  ...paymentCard,
  {
    title: 'Associate Performance',
    icon: <TeamOutlined style={{ fontSize: '22px', marginRight: '8px' }} />,
    description: 'Under construction.',
    path: '/Reports/associate-performance'
  },
]}


const LandingPage = (props) => {
  const history = useHistory();

  useEffect(() => {
    if(props?.auth?.launchFlagsForNav?.reportsPage !== undefined) {
      if(!props?.auth?.launchFlagsForNav?.reportsPage) {
        history.push('/Home')
      }
    }
  }, [props?.auth?.launchFlagsForNav?.reportsPage])

  return (
    <StandardLayoutV2
      title="Reports"
      classProps="flex-col-left "
      styleProps={{ justifyContent: 'flex-start' }}
      maxWidth="1200px"
      actions={<Button onClick={() => history.push('/Home')}>View Legacy Reports</Button>}
    >
      <div className='flex-row-nospacebetween-wrap w-100-P m-top-10' style={{ gap: '15.5px'}}>
        {reports({
          isPOS: !!props?.auth?.pos_type,
          payment: Number(props.auth.payments) > 0
        }).map(report => (
          <ReportCard
            key={report.path}
            title={report.title}
            icon={report.icon}
            description={report.description}
            path={report.path}
          />
        ))}
      </div>
    </StandardLayoutV2>
  )
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(LandingPage);
