import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TemplatedTextSalesTriggers = props => {
  const characterCount = () => {
    return (
      <span
        className="fs-12 align-vert-middle h-12 m-btm-15"
        style={{
          color: setNotif().color,
          display: 'table-cell',
          verticalAlign: 'middle',
          float: 'right',
        }}
      >
        {props.val.length} characters <FontAwesomeIcon className="m-right-15" icon={setNotif().icon} />
      </span>
    );
    function setNotif() {
      if (props.val.length < 120) {
        return { color: '#86cc4c', icon: ['far', 'check-circle'] };
      }
      if (props.val.length === 120) {
        return { color: '#f7b500', icon: ['far', 'exclamation-circle'] };
      }
      return { color: '#ff6363', icon: ['far', 'exclamation-triangle'] };
    }
  };

  const buttonStyles = {
    lineHeight: '20px',
    verticalAlign: 'middle',
    border: '1px solid #33aafc',
    borderRadius: '14.5px',
    padding: '3px 9px 2px 9px',
    backgroundColor: 'white',
  };

  const getCursorXY = val => {
    const inp = document.getElementById('textAreaInput');
    const cursorLoc = inp.selectionStart;
    const newString = props.val.substr(0, cursorLoc) + val + props.val.substr(cursorLoc);
    props.changeHandler(newString);
    inp.focus();
  };

  const textHandler = val => {
    props.changeHandler(val);
  };

  return (
    <div className="w-100-P pos-rel d-inline-block m-btm-28">
      <div className="border-r8-grey max-w-600 w-100-P h-218 pos-rel m-top-15 m-btm-15" style={props.styleProps}>
        <div className="pos-rel height-auto " style={{ minHeight: '120px' }}>
          <textarea
            id="textAreaInput"
            value={props.val}
            className="w-100-P transparent border-none outline-none rem-pad-width h-50-P resize-none fs-12 align-left m-left-0 "
            style={{
              // color: 'light-grey',
              // color: 'rgba(0, 0, 0, 0)',
              caretColor: 'black',
              position: 'absolute',
              left: 0,
              width: 'calc(100% - 3px)',
              top: 0,
              fontFamily: 'Poppins',
              height: '100px',
              paddingLeft: 20,
              paddingTop: '15px',
              letterSpacing: 0.5,
              wordSpacing: 1,
              whiteSpace: 'pre-wrap',
              overflow: 'hidden',
            }}
            onChange={e => textHandler(e.target.value)}
            onKeyDown={e => {
              const keyCode = e.key || e.which;
              if (Number(keyCode) === Number(13)) {
                e.preventDefault();
              }
              return false;
            }}
          />
        </div>
        <div className=" pos-abs align-bottom w-100-P">{characterCount()}</div>
      </div>
      <div className="align-left pos-abs w-100-P">
        <div className="flex-row-spacebetween-nowrap align-vert-middle w-100-P">
          <div>
            <button
              type="button"
              onClick={() => getCursorXY(':name:')}
              style={buttonStyles}
              className="pointer m-left-10 rem-pad-width blue h-29 fs-13"
            >
              Client Name
            </button>
            <button
              type="button"
              onClick={() => getCursorXY(':associate:')}
              style={buttonStyles}
              className="pointer m-left-10 rem-pad-width blue h-29 fs-13"
            >
              Associate Name
            </button>
            <button
              type="button"
              onClick={() => getCursorXY(':review:')}
              style={buttonStyles}
              className="pointer m-left-10 rem-pad-width blue h-29 fs-13"
            >
              Review Link
            </button>
            <button
              type="button"
              onClick={() => getCursorXY(':category:')}
              style={buttonStyles}
              className="pointer m-left-10 rem-pad-width blue h-29 fs-13"
            >
              Product Category
            </button>
            <button
              type="button"
              onClick={() => getCursorXY(':product:')}
              style={buttonStyles}
              className="pointer m-left-10 rem-pad-width blue h-29 fs-13"
            >
              Product Name
            </button>
            <button
              type="button"
              onClick={() => getCursorXY(':store:')}
              style={buttonStyles}
              className="pointer m-left-10 rem-pad-width blue h-29 fs-13"
            >
              Store Name
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TemplatedTextSalesTriggers;
