import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Timeline } from 'antd-v5';
import { useHistory } from 'react-router';
import { PurchaseOutline, NewClient } from '../../../../../componentsV2/Layout/Report/components/Icons';
import { getRequest } from '../../../../../core/apiRequests';
import SalesTimelineItem from '../../../../../componentsV2/Layout/Report/components/SalesTimelineItem';
import TimelineItem from '../../../../../componentsV2/Layout/Report/components/Timeline';

const NewClientExpandRow = ({ record }) => {
  const { client_name, client_id, activity_source, activity_date, transactions } = record;
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [salesTransactionData, setSalesTransactionData] = useState([]);

  const getReportAttributedData = useCallback(() => {
    setLoading(true)
    const reqObj = {
      params: ['report', 'attributedMeta'],
      query: {
        reminders: JSON.stringify([]),
        chatMessages: JSON.stringify([]),
        salesTransactions: JSON.stringify(transactions.map(item => item.sales_transaction_id))
      },
      loadingStatusOff: true
    }
    getRequest(reqObj).then((res) => {
      const sales = res.filter(item => item.type === 'salesTransaction').map(sale => {
        const findTransaction = transactions.find(transaction => transaction.sales_transaction_id === sale.id)
        if(findTransaction) {
          return {
            ...sale,
            ...findTransaction
          }
        }
        return {...sale}
      })
      setSalesTransactionData(sales)
    }).finally(() => {
      setLoading(false)
    });
  }, [transactions])

  useEffect(() => {
    getReportAttributedData();
  }, [transactions])

  return (
    <div className="m-left-23">
      <div className="flex-row-spacebetween-nowrap">
        <Timeline
          pending={loading ? 'Loading...' : null}
          items={[
            {
              children: <TimelineItem
                title={
                  <>
                    <span className="fw-600">{client_name} </span>was added as a client via <span className="fw-600">{`${activity_source}`} </span>
                    on
                    <span className="fw-600"> {moment(activity_date).format('MMM DD, YYYY')}</span>
                  </>
                }
              />,
              dot: <NewClient style={{ opacity: 0.45, color: "#000", fontSize: '20px' }} />
            },
            ...salesTransactionData.map(({sales_transaction_amount, description, sales_transaction_date}) => ({
              dot: <PurchaseOutline style={{ color: '#389E0D', fontSize: '18px'}} />,
              children: <SalesTimelineItem
                client_name={client_name}
                sales_transaction_amount={sales_transaction_amount}
                description={description}
                activity_date={sales_transaction_date}
              />
            }))
          ]}
        />
        <Button onClick={() => history.push(`/Clients?client=${client_id}`)}>View Details</Button>
      </div>
    </div>
  );
};
export default NewClientExpandRow;

