import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import DropdownSelect from '../../../../../Layout/DropdownSelect';
import { postRequest, getRequest } from '../../../../../../core/apiRequests';
import { notificationError, notificationSuccess } from '../../../../../../core/actions';
import NumberInput from '../../../../../Layout/NumberInput';
import Input from '../../../../../CustomizedAntDesign/Input/Input';
import KioskSlider from '../../../../../Layout/KioskSlider';
import { useQuery } from 'react-query';

const AddReminderModal = props => {
  const [reminderTimeframe, setReminderTimeframe] = useState();
  const [scheduledDate, setScheduledDate] = useState(new Date());
  const [factor, setFactor] = useState(2);
  const [timeUnit, setTimeUnit] = useState('weeks');
  const [repeats, toggleRepeats] = useState(false);
  const [descriptionText, setDescriptionText] = useState('');
  const [loading, setLoading] = useState(true);
  const [associate, setAssociate] = useState(props.auth.userId);

  const timeframeList = [
    // { label: 'Select Time Frame', value: 0 },
    { label: 'Time Period', value: 'timePeriod' },
    { label: 'Specific Date', value: 'specificDate' },
  ];

  const timeUnitList = [
    { label: 'Days', value: 'days' },
    { label: 'Weeks', value: 'weeks' },
    { label: 'Months', value: 'months' },
    { label: 'Years', value: 'years' },
  ];

  const {data: allAssociates, isLoading} = useQuery('getAssociates', async () => {
    const reqObj = {
      params: ['associates', props.auth.merchantId, 'getAllAssociateList']
    }
    const data = await getRequest(reqObj);
    return data.map(associate => ({
      label: `${associate.first_name || ''} ${associate.last_name || ''}`.trim(),
      value: associate.id
    })).sort((a, b) => b.label < a.label ? 1 : -1);
  }, {
    refetchOnWindowFocus: false,
    initialData: [],
  })

  const saveHandler = () => {
    if (props.auth.merchantId) {
      if (descriptionText) {
        if (reminderTimeframe === 'timePeriod') {
          if (`${factor}`) {
            if (factor >= 0) {
              makeTheCall();
            } else {
              notificationError("Time Interval Can't Be Negative");
            }
          } else {
            notificationError('Please Set A Time Interval');
          }
        }
        if (reminderTimeframe === 'specificDate') {
          if (scheduledDate) {
            makeTheCall();
          } else {
            notificationError('Please Set Scheduled Date');
          }
        }
      } else {
        notificationError('Please Add Reminder Description');
      }
    }
    function makeTheCall() {
      setLoading(true);
      const reqObj = {
        params: ['chatMessages', props.auth.merchantId, 'addReminder'],
        data: {
          clientId: props.clientId,
          createdBy: (`${props.auth.firstName} ` || '') + (props.auth.lastName || '').trim(),
          descriptionText,
          reminderTimeframe,
          factor,
          timeUnit,
          repeats,
          associate,
          scheduledDate,
        },
      };
      (async () => {
        const respData = await postRequest(reqObj);
        if (respData.id) {
          notificationSuccess('Reminder Created');
        } else {
          // notificationError('Error Creating Reminder');
        }
        setLoading(false);
        props.setReload(true);
        props.setShowReminderModal(false);
      })();
    }
  };

  useEffect(() => {
    if (associate && descriptionText && reminderTimeframe) {
      setLoading(false);
    }
  }, [associate, descriptionText, reminderTimeframe]);

  return (
    <div
      className="pop-confirm-background"
      id="modal-background"
      // onClick={backgroundCloseHandler}
    >
      <div className="pop-confirm-container rem-pad-width pos-rel" style={{ width: '404px', minHeight: '542px' }}>
        <div
          className="flex-col-center h-100-P"
          style={{
            justifyContent: 'flex-start',
            width: '295px',
            margin: '0 auto 15px',
          }}
        >
          <div className="flex-row-spacebetween-nowrap w-100-P m-top-15" />
          <span className="fs-18 fw-500" style={{ marginTop: '18px', marginBottom: '23px' }}>
            Add Reminder
          </span>
          <div className="align-left">
            <span className="dropDownDescriptionText">Assign an Associate</span>
            <DropdownSelect
              isLoading={isLoading}
              classProps="mq-w-200 w-295 align-left m-top-8"
              options={allAssociates}
              placeholder="Select an Associate..."
              value={associate}
              onChange={obj => {
                setAssociate(obj.value);
              }}
            />
          </div>
          <div className="align-left m-top-24">
            <span className="dropDownDescriptionText">Description</span>
            <Input
              placeholder="Reminder description..."
              className="h-50 m-top-8 fs-13 outline-none common_inputStyle left-0 input-height"
              style={{ boxSizing: 'border-box', width: '295px' }}
              value={descriptionText}
              onChange={e => setDescriptionText(e.target.value)}
            />
          </div>
          <div className="align-left m-top-24">
            <span className="dropDownDescriptionText">When do you want to be reminded?</span>
            <DropdownSelect
              classProps="w-295 m-top-8 "
              value={reminderTimeframe}
              placeholder="Select Time Frame"
              options={timeframeList}
              onChange={obj => setReminderTimeframe(obj.value)}
            />
          </div>
          <div style={{ width: '295px', height: '125px', marginTop: '22px' }}>
            {reminderTimeframe ? (
              <TimeFrame
                reminderTimeframe={reminderTimeframe}
                toggleRepeats={toggleRepeats}
                scheduledDate={scheduledDate}
                setScheduledDate={setScheduledDate}
                factor={factor}
                setFactor={setFactor}
                timeUnit={timeUnit}
                setTimeUnit={setTimeUnit}
                timeUnitList={timeUnitList}
                repeats={repeats}
              />
            ) : null}
          </div>
          <div className="mq-w-100-vw custom-action-save-flex m-top-10">
            <button className="save-button-v2 m-btm-8" onClick={saveHandler} disabled={loading}>
              Save
            </button>
            <button
              className="cancel-button-v2 m-btm-10"
              onClick={() => {
                props.setShowReminderModal(false);
                setReminderTimeframe();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  auth: { ...state.auth },
  client: { ...state.client },
});
export default connect(mapStateToProps, {})(AddReminderModal);

const TimeFrame = ({
  reminderTimeframe,
  toggleRepeats,
  scheduledDate,
  setScheduledDate,
  factor,
  setFactor,
  timeUnit,
  setTimeUnit,
  timeUnitList,
  repeats,
}) => {
  if (reminderTimeframe === 'timePeriod') {
    return (
      <div>
        <div className="flex-row-spacebetween-nowrap w-100-P">
          <NumberInput
            value={factor}
            classProps="w-140 h-50 fs-13  left-0 input-height"
            onChange={val => setFactor(val)}
            min={0}
          />
          <DropdownSelect
            classProps=" w-140"
            placeholder="Choose an opportunity..."
            value={timeUnit}
            options={timeUnitList}
            onChange={obj => setTimeUnit(obj.value)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'left',
          }}
          className="m-top-11"
        >
          <KioskSlider value={repeats} onChange={() => toggleRepeats(!repeats)} />
          <span className="m-left-10 fw-500 " style={{ marginTop: '2px' }}>
            Repeats
          </span>
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        width: '100%',
        textAlign: 'left',
      }}
    >
      <span className="dropDownDescriptionText">Select a Date</span>
      <ReactDatePicker
        selected={scheduledDate}
        onChange={date => setScheduledDate(date)}
        customInput={
          <input
            style={{
              width: '295px',
              height: '50px',
              borderRadius: '3px',
              border: 'solid 1px #818181',
              paddingLeft: '16px',
              boxSizing: 'border-box',
              marginTop: '8px',
              fontSize: '14px',
            }}
          />
        }
      />
    </div>
  );
};
