import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutoComplete from 'react-autocomplete';
import { getRequest } from '../../../../../../core/apiRequests';
import './productDrop.css';

const ProductDropToView = props => {
  const [outline, setOutline] = useState('');
  const [productList, setProductList] = useState([]);
  const [onHoverImg, setOnHoverImg] = useState('');
  const [ring, setRing] = useState('ringLoadOff');

  const menuStyle = {
    fontSize: '13px',
    zIndex: '100',
    height: '44px',
    width: '268px',
    textAlign: 'left',
    color: '#000000',
    outline,
    borderRadius: '3px',
    backgroundColor: props.missingData ? '#F9E0E0' : '#fff',
    border: props.missingData ? 'solid 1px #FF7A7A' : 'solid 1px #818181',
    marginBottom: '10px',
    paddingLeft: '16px',
  };

  // back end search for inputs
  const goGetItems = search => {
    (async () => {
      setRing('ringLoadOn');
      const reqObj = {
        params: ['chatMessages', props.merchantId, 'productDropdown'],
        query: { productName: search, loadingStatusOff: true },
        loadingStatusOff: true,
      };

      const data = await getRequest(reqObj);

      if (data.length > 0) {
        setProductList(data);
        setRing('ringLoadOff');
      } else {
        setProductList([]);
        setRing('ringLoadOff');
      }
    })();
  };

  const selectedProduct = (obj, key) => {
    const fullProd = productList.filter(item => item.label === obj)[0];
    props.setProduct({
      ...props.product,
      [key]: {
        value: fullProd.value,
        label: obj,
        image: fullProd.source_url ? fullProd.source_url : null,
        selected: true,
      },
    });
    setProductList([]);
  };

  const onKeyPressed = (e, key) => {
    if (e.key === 'Enter') {
      props.setProduct({ ...props.product, [key]: { ...props.product[key], selected: true } });
      setProductList([]);
    }
  };

  const onBlurInput = (e, key) => {
    if (Object.values(props.product[key]).length > 0) {
      props.setProduct({ ...props.product, [key]: { ...props.product[key], selected: true } });
      setProductList([]);
    }
  };

  const returnProduct = () => {
    const arrayProd = [];
    Object.keys(props.product).forEach(prod => {
      if (props.product[prod].hasOwnProperty('value') && props.product[prod].hasOwnProperty('selected')) {
        if (props.product[prod].selected) {
          props.setMissingData(false);
          arrayProd.push(
            <div className="flex-row-spacebetween-nowrap m-btm-10" style={{ width: '285px' }} key={prod}>
              <input
                className="common_dropdownStyle inputHeight ul-no-style w-100-P"
                style={{ paddingLeft: '15px' }}
                value={`${
                  props.product[prod].label.length > 20
                    ? `${props.product[prod].label.slice(0, 20)}...`
                    : props.product[prod].label
                }`}
                onChange={e => {
                  props.setProduct({
                    ...props.product,
                    [prod]: { value: props.product[prod].value, label: e.target.value },
                  });
                }}
              />
              {props.product[prod].image ? (
                <div
                  className="h-50 w-50 m-left-10 pos-rel"
                  onMouseEnter={() => setOnHoverImg(prod)}
                  onMouseLeave={() => setOnHoverImg('')}
                >
                  <img
                    src={props.product[prod].image || null}
                    height="50"
                    width="50"
                    className="border-r3"
                    alt="product"
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = "https://dashboard-v2-images.s3.amazonaws.com/Shopping_Bag.png";
                    }}
                  />
                  <div
                    className="h-50 w-50 bg-black-faded pos-abs z-index-5 bottom-0"
                    id={onHoverImg === prod ? 'imageToolRow' : 'imageToolRowHidden'}
                  >
                    <div className="bg-white w-33 h-33 border-r4 m-top-8 m-left-8">
                      <FontAwesomeIcon
                        icon={['far', 'trash']}
                        size="2x"
                        className="fs-16 pointer bg-white m-top-8 m-left-10"
                        style={{ color: 'black' }}
                        onClick={() => {
                          delete props.product[prod].image;
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <FontAwesomeIcon
                icon={['far', 'trash']}
                size="1x"
                className="fs-16 pointer m-left-10"
                style={{ color: 'black', marginTop: '18px' }}
                onClick={() => {
                  delete props.product[prod];
                  props.productDecrease(prod);
                }}
              />
            </div>,
          );
        }
      } else if (prod !== 'expirationDate') {
        arrayProd.push(
          <div
            className="pos-rel"
            onKeyDown={e => onKeyPressed(e, prod)}
            key={prod}
            role="menuitem"
            tabIndex={0}
            id="NewPaymentRequestDescription"
          >
            <AutoComplete
              menuStyle={{
                textAlign: 'left',
                background: '#FFFFFF',
                boxShadow: ' 0px 2px 8px rgba(0, 0, 0, 0.12)',
                borderRadius: '4px',
                maxHeight: '150px',
              }}
              inputProps={{
                style: menuStyle,
                onFocus: () => setOutline('1px solid #33aafc'),
                onBlur: e => {
                  setOutline('');
                  onBlurInput(e, prod);
                },
                placeholder: 'Ex. Diamond Earrings',
              }}
              items={productList}
              getItemValue={item => item.label}
              renderItem={(item, highlighted) => (
                <div
                  style={{
                    background: highlighted ? '#E5F2FB' : 'white',
                    paddingLeft: '15px',
                    fontSize: '12px',
                    height: '30px',
                    paddingTop: '10px',
                  }}
                  key={item.value}
                >
                  {`${item.label.length > 40 ? `${item.label.slice(0, 40)}...` : item.label}`}
                </div>
              )}
              value={props.product[prod].value}
              onChange={e => {
                props.setProduct({ ...props.product, [prod]: { value: e.target.value, label: e.target.value } });
                if (e.target.value.length > 0) {
                  goGetItems(e.target.value);
                }
              }}
              onSelect={obj => {
                selectedProduct(obj, [prod]);
              }}
            />
            <div id={ring} className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>,
        );
      }
    });
    return arrayProd;
  };
  return returnProduct();
};
export default ProductDropToView;
