import React, { useState } from 'react';
import { Radio, Space } from 'antd';
import LeadOuterComp from './LeadComponents/LeadOuterComp';
import { postRequest } from '../../../../core/apiRequests';
import { notificationError } from '../../../../core/actions';

const EdisonHelpMeImprove = ({
  setShowLead,
  setEdisonTile,
  aiActivityId,
  getLead,
  setLeadObj,
  myClient,
  AIActivity,
}) => {
  const sendEdisonFeedback = (v, place) => {
    (async () => {
      const dataObj = {
        code: 'SKIP',
        feedbackCode: v,
        message: '',
      };
      const reqObj = {
        params: ['ai', aiActivityId, 'edisonActivityResult'],
        data: dataObj,
        loadingStatusOff: true,
      };
      try {
        await postRequest(reqObj);
        AIActivity({ eventType: 'activity_skipped', msgRegen: false, msgEdit: false, resultCode: v });
        if (place === 'exit') {
          setLeadObj({});
          setEdisonTile('initialLoading');
          getLead();
        } else setEdisonTile('loadingSkipped');
      } catch (error) {
        console.log('error', error);
        notificationError('Error to skipped activity');
        setLeadObj({});
        setEdisonTile('initialLoading');
        getLead();
      }
    })();
  };

  return (
    <LeadOuterComp
      setShowLead={setShowLead}
      showMyClient={false}
      headerText="Help me improve"
      headerSubTxt="What feedback do you have about this idea?"
      headerDivMargin="20px 40px 20px 30px"
      headerSubTxtClass="align-center m-top-5"
      sendEdisonFeedback={sendEdisonFeedback}
      leadBody={<ImprovementSurvey sendEdisonFeedback={sendEdisonFeedback} myClient={myClient} />}
    />
  );
};

export default EdisonHelpMeImprove;

const ImprovementSurvey = ({ sendEdisonFeedback, myClient }) => {
  const [value, setValue] = useState(1);

  const onChange = e => {
    setValue(e.target.value);
  };

  return (
    <div style={{ margin: '0px 60px' }}>
      <div className="align-left fs-12 fw-500">
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            {myClient && <Radio value="ASSIGNMENT">They&apos;re not my customer</Radio>}
            <Radio value="REASON">Not a good reason to reach out</Radio>
            <Radio value="BAD_TIME">Not the right time for this message</Radio>
            <Radio value="NO_CONTACT">I&apos;ve already contacted them</Radio>
            <Radio value="NONE">I don&apos;t have any feedback </Radio>
          </Space>
        </Radio.Group>
      </div>
      <button
        type="button"
        className="save-button-v2 m-top-40"
        onClick={() => {
          sendEdisonFeedback(value, 'survey');
        }}
      >
        Submit
      </button>
    </div>
  );
};
