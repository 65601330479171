import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LargeImage = props => {
  return (
    <div className="pop-confirm-background" style={{ zIndex: 15 }} onClick={() => props.setLargeImagePopup(false)}>
      <div
        className="pop-confirm-container-large-messaging rem-pad-width flex-col-center"
        style={props.popConfirmLargeDivStyle}
      >
        <div style={{ width: '500px', height: '500px' }} className="pos-rel">
          <img
            alt="#"
            className="w-500 photoFit border-r8"
            style={{ height: '500px' }}
            src={
              props.templateObj.imageUrl
                ? props.templateObj.imageUrl
                : 'https://dashboard-v2-images.s3.amazonaws.com/image-placeholder@2x.png'
            }
            onError={e => {
              e.target.onerror = null;
              e.target.src = 'https://dashboard-v2-images.s3.amazonaws.com/image-placeholder@2x.png';
            }}
          />
          <FontAwesomeIcon
            className="pointer"
            icon={['fa', 'x']}
            id="payments-icon"
            size="lg"
            style={{ position: 'absolute', top: '20px', right: '20px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default LargeImage;
