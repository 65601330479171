import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer } from 'antd';
import { getRequest, postRequest } from '../../../../../../../core/apiRequests';
import ViewAll from '../../../ViewAll';
import Table from '../../../../../../Layout/Table';
import ReminderCard from '../ReminderCard';
import {
  notificationSuccess,
  getTablePaginationData,
  setTablePaginationPageCount,
  setTablePaginationRowCount,
} from '../../../../../../../core/actions';
import ReminderButtonHeader from '../ReminderButtonHeader';
import PopConfirm from '../../../../../../Layout/PopConfirm';
import SnoozeModal from '../../../../../Today/Reminders/children/SnoozeModal';
import { associateNameFormat } from '../../../../../../../utils';
import DropDownSelect from '../../../../../../Layout/DropdownSelect';
import Modal from '../../../../../../CustomizedAntDesign/Modal/Modal';
import assignAssociate from '../ReminderHelpers';
import ConversationWindow from '../../../../../../Layout/chat/chat';

const AllClientReminders = props => {
  const { client_id } = props.client;
  const [allReminders, setAllReminders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allCheckMark, setAllCheckMark] = useState(false);
  const [showSnoozeModal, setShowSnoozeModal] = useState(false);
  const [assignPopup, setAssignPopup] = useState(false);
  const [selectedAction, setSelectedAction] = useState([]);
  const [reload, setReload] = useState(false);

  const getReminderData = () => {
    setIsLoading(true);
    setSelectedAction([]);
    const page = props.pagination?.page || 0;
    const pageSize = props.pagination?.pageSize || 25;
    const reqObj = {
      params: ['clients', client_id, 'getAllPaginatedClientReminders'],
      query: {
        pageStart: page,
        count: pageSize,
      },
    };
    (async () => {
      const responseObj = await getRequest(reqObj);
      setIsLoading(false);
      if (!responseObj.hasOwnProperty('data') || !responseObj.hasOwnProperty('pageInfo')) {
        // notificationError('Error Getting Reminders');
        return;
      }
      const {
        data,
        pageInfo: { rowCount, pageCount },
      } = responseObj;
      if (data) {
        setReload(false);
        setAllReminders([...data]);
        setTablePaginationRowCount(rowCount, 'ALL_CLIENT_REMINDERS');
        setTablePaginationPageCount(pageCount, 'ALL_CLIENT_REMINDERS');
      }
    })();
  };

  useEffect(() => {
    getReminderData();
  }, [props.auth.merchantId, reload, props.pagination?.page, props.pagination?.pageSize]);

  useEffect(() => {
    allReminders.sort((a, b) => {
      return a.due_date - b.due_date;
    });
  }, [allReminders]);

  const rowClickHandler = () => {
    console.log('Click does nothing here');
  };

  const dismissSelected = async (deleteAll = false) => {
    const reqObj = {
      params: ['today', props.auth.merchantId, 'dismissSelected'],
      query: {
        associateName: associateNameFormat(props.auth.firstName, props.auth.lastName),
        associateId: props.auth.userId,
      },
      data: {
        selectedAction: selectedAction.map(item => ({
          ...item,
          repeats: deleteAll ? 0 : item.repeats,
        })),
      },
    };

    const responseObj = await postRequest(reqObj);

    if (responseObj) {
      setSelectedAction([]);
      props.setDismissPopup(false);
      setTimeout(() => {
        setReload(true);
      }, 1500);
      notificationSuccess(`Reminder(s) ${deleteAll ? 'Deleted' : 'Dismissed'}!`);
    }
  };

  const handleClickDismissReminders = async () => {
    const repeats = selectedAction.some(item => item.repeats);
    if (repeats) {
      props.setDismissPopup(true);
    } else {
      dismissSelected(false);
    }
  };

  const addAll = () => {
    if (allCheckMark === false) {
      setSelectedAction(allReminders);
    } else {
      setSelectedAction([]);
    }
  };

  const tableHeaders = [
    {
      keyName: 'id',
      style: { marginLeft: '6px' },
      title: (
        <div
          value={allCheckMark}
          className="rem-pad-width"
          role="checkbox"
          aria-checked="false"
          tabIndex="0"
          onClick={() => {
            setAllCheckMark(!allCheckMark);
            addAll();
          }}
          onKeyDown={() => {
            setAllCheckMark(!allCheckMark);
            addAll();
          }}
        >
          {allCheckMark === true ? (
            <FontAwesomeIcon className="fs-16 m-left-4" color="#1769af" icon={['fas', 'check-square']} />
          ) : (
            <FontAwesomeIcon className="fs-16 gray m-left-4" icon={['far', 'square']} />
          )}
        </div>
      ),
      render: (t, i) => (
        <ReminderCard
          isLoading={isLoading}
          client={props.client}
          reminder={i}
          auth={props.auth}
          setAssignPopup={setAssignPopup}
          showMessageHandler={props.showMessageHandler}
          setSelectedAction={setSelectedAction}
          selectedAction={selectedAction}
        />
      ),
    },
  ];

  return (
    <ViewAll title="All Reminders" setViewAllType={props.setViewAllType}>
      <div className="scrollbar-y h-100-P w-100-P">
        {selectedAction.length > 0 ? (
          <ReminderButtonHeader
            setAssignPopup={setAssignPopup}
            auth={props.auth}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            setReload={setReload}
            setShowSnoozeModal={setShowSnoozeModal}
            handleClickDismissReminders={handleClickDismissReminders}
          />
        ) : null}
        <Table
          data={allReminders}
          tableHeaders={tableHeaders}
          searchable={false}
          paginationV2
          styleProps={{
            width: '100%',
            maxWidth: '100%',
          }}
          tableStyles={{
            width: '100%',
            maxWidth: '100%',
            marginTop: 0,
          }}
          tableContainerClass="table-view-all-automessages"
          // tableClassName="table-container-reminder"
          tableName="ALL_CLIENT_REMINDERS"
        />
      </div>
      <SnoozeModal
        openModal={showSnoozeModal}
        setOpenModal={setShowSnoozeModal}
        merchantId={props.auth.merchantId}
        actions={selectedAction}
        refresh={getReminderData}
      />
      <Modal
        open={props.dismissPopup}
        onCancel={() => props.setDismissPopup(false)}
        footer={null}
        width={348}
        destroyOnClose
        closable
        centered
        className="cb-reminders-modal"
      >
        <div className="cb-reminders-modal__body">
          <h3>Dismiss reminders</h3>
          <p>This reminder repeats. Would you like to dismiss this occurrence or delete it?</p>
        </div>

        <div className="cb-reminders-modal__footer">
          <button type="button" className="save-button-v2 m-btm-8" onClick={() => dismissSelected(true)}>
            Delete All
          </button>

          <button type="button" className="cancel-button-v2" onClick={() => dismissSelected(false)}>
            Dismiss
          </button>
        </div>
      </Modal>
      {assignPopup ? (
        <PopConfirm
          popStyle={{
            width: '380px',
          }}
          confirmButtonText="SAVE"
          cancelButtonText="CANCEL"
          confirm="Assign Associate"
          description={[
            <div key="primary" className="align-left m-top-10" style={{ height: '175px' }}>
              <span className="dropDownDescriptionText">Assign an Associate</span>
              <DropDownSelect
                classProps="mq-w-200 w-295 align-left m-top-8"
                options={props.allAssoc.map(asc => ({
                  label: asc.fullName ? asc.fullName : asc.first_name,
                  value: asc.id,
                }))}
                placeholder="Select an Associate..."
                value={props.associate}
                onChange={obj => {
                  props.setAssociate(obj.value);
                }}
              />
            </div>,
          ]}
          noButtonDisable
          onCancel={() => setAssignPopup(false)}
          onConfirm={() =>
            assignAssociate({
              setAssignPopup,
              auth: props.auth,
              associate: props.associate,
              selectedAction,
              setSelectedAction,
              setReload,
            })
          }
        />
      ) : null}
      <Drawer
        bodyStyle={{
          padding: 0,
          overflow: 'hidden',
        }}
        closable={false}
        push={false}
        destroyOnClose
        width={750}
        open={props.showPopConfirm}
        onClose={props.closeChat}
      >
        <ConversationWindow setDrawerToggle={props.closeChat} chatIconColor="#EBEBEB" type="right" />
      </Drawer>
    </ViewAll>
  );
};

const mapStateToProps = state => ({
  pagination: getTablePaginationData(state, 'ALL_CLIENT_REMINDERS'),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AllClientReminders);
// export default AllClientReminders;
