import React, { useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input as InputComponent, FormField as FormFieldComponent } from '../Field';
import { HEADING_CLASSES } from '../WizardMulti';
import { ERROR_MESSAGE } from '../../const';

import './steps.css';

const today = moment().format('MM/DD/YYYY');
const LABEL_STYLES = { display: 'flex', gap: 5, marginBottom: 5 };

const BusinessAndOwnership = props => {
  const { data } = props;
  const Input = inputProps => (
    <InputComponent data={data} {...props} errorMessage={ERROR_MESSAGE.AGREEMENTS} {...inputProps} />
  );
  const BusinessDetails = formProps => <FormFieldComponent data={data} {...props} {...formProps} />;

  const [serviceAgreementDate, setServiceAgreementDate] = useState(data.serviceAgreementDate?.value);
  const [pricingAgreementDate, setPricingAgreementDate] = useState(data.pricingAgreementDate?.value);

  const setupCheck =
    fn =>
      ({ target: { checked } }) => {
        const value = checked ? today : '';
        fn(value);
      };

  return (
    <fieldset className="m-btm-15">
      <BusinessDetails id="businessName" title="Business Name" defaultValue={data.businessName?.value} maxLength="50" />
      <BusinessDetails
        id="businessLegalName"
        title="Legal Business Name"
        defaultValue={data.businessLegalName?.value}
        maxLength="60"
      />
      <BusinessDetails
        id="businessProductAndServiceDesc"
        title="Please provide a detailed description of your product or service:"
        defaultValue={data.businessProductAndServiceDesc?.value}
        maxLength="1500"
      />
      <BusinessDetails
        id="businessPhone"
        title="Business Phone Number"
        defaultValue={data.businessPhone?.value}
        type="number"
      />
      <BusinessDetails
        id="businessWebsite"
        title="Website"
        defaultValue={data.businessWebsite?.value}
        maxLength="100"
      />
      <BusinessDetails id="businessType" title="Business Type" defaultValue={data.businessType?.value} />
      <BusinessDetails id="businessTaxId" title="Tax ID" defaultValue={data.businessTaxId?.value} maxLength="9" />
      <BusinessDetails
        id="businessAddress"
        title="Business Address"
        defaultValue={data.businessAddress?.value}
        address={`${data.businessCity?.value}, ${data.businessState?.value}, ${data.businessZip?.value}`}
        maxLength="40"
      />
      <button
        type="button"
        style={{ fontWeight: '600', marginTop: '23px', marginBottom: '27px' }}
        className="link-button p-left-0"
        onClick={() => props.handleClick('business', true)}
      >
        <FontAwesomeIcon className="m-right-10" color="#33AAFC" icon={['fas', 'pen-to-square']} size="1x" />
        EDIT INFORMATION
      </button>
      {/* <legend className={HEADING_CLASSES}>Ownership</legend> */}
      <p className={HEADING_CLASSES}>Ownership</p>
      <span className="fw-500 fs-12 d-inline-block m-btm-24">
        Information must be gathered and verified for each owner who owns 25% or more of your company
      </span>

      <span className="fw-600 fs-12">First Owner</span>
      <BusinessDetails id={`owner${1}FirstName`} title="First Name" defaultValue={data.owner1FirstName?.value} />
      <BusinessDetails id={`owner${1}LastName`} title="Last Name" defaultValue={data.owner1LastName?.value} />
      <BusinessDetails id={`owner${1}DateOfBirth`} title="Date of Birth" defaultValue={data.owner1DateOfBirth?.value} />
      <BusinessDetails id="companyRepGovID" title="Last 4 Digits of SSN" defaultValue={data.owner1GovID?.value} />
      <BusinessDetails id={`owner${1}Phone`} title="Phone Number" defaultValue={data.owner1Phone?.value} />
      <BusinessDetails
        id={`owner${1}Address`}
        title="Company Representative's Address"
        defaultValue={data.owner1Address?.value}
        address={`${data.owner1City?.value}, ${data.owner1State?.value}, ${data.owner1Zip?.value}`}
      />
      <button
        type="button"
        style={{ fontWeight: '600', marginTop: '23px', marginBottom: '27px' }}
        className="link-button p-left-0"
        onClick={() => props.handleClick('ownership', true)}
      >
        <FontAwesomeIcon className="m-right-10" color="#33AAFC" icon={['fas', 'pen-to-square']} size="1x" />
        EDIT INFORMATION
      </button>
      {/* <legend className={HEADING_CLASSES}>Agreements</legend> */}
      <p className={HEADING_CLASSES}>Agreements</p>
      <span className="fw-500 fs-12 d-inline-block m-btm-24">Before continuing, read and accept the terms below</span>

      <label className="fs-13 fw-500" style={LABEL_STYLES}>
        <input type="checkbox" checked={!!serviceAgreementDate} onChange={setupCheck(setServiceAgreementDate)} />I have
        read and consent to{' '}
        <a
          href="https://terms.clbk.app/TCsFinalv5.12.Dec2020.pdf"
          className="no-decoration"
          target="_blank"
          rel="noopener noreferrer"
        >
          Clientbook’s Privacy Agreement
        </a>
      </label>
      <Input label="Service agreement sign date" id="serviceAgreementDate" defaultValue={serviceAgreementDate} hidden />

      <label className="fs-13 fw-500" style={LABEL_STYLES}>
        <input type="checkbox" checked={!!pricingAgreementDate} onChange={setupCheck(setPricingAgreementDate)} />I have
        read and consent to{' '}
        <a
          href="https://terms.clbk.app/ReferredMerchantSchUSD_JUN_2021.pdf"
          className="no-decoration"
          target="_blank"
          rel="noopener noreferrer"
        >
          Clientbook’s Pricing Agreement
        </a>
      </label>
      <Input label="Pricing agreement sign date" id="pricingAgreementDate" defaultValue={pricingAgreementDate} hidden />
    </fieldset>
  );
};

export default BusinessAndOwnership;
