import React from 'react';

const DefaultDisabledModal = props => {
  const { toggleDefaultDisabled, defaultMethod } = props;

  return (
    <div className="pop-confirm-background" id="modal-background">
      <div className="pop-confirm-container rem-pad-width" style={{ width: '430px' }}>
        <div
          className="flex-col-center h-100-P"
          style={{
            justifyContent: 'flex-start',
            width: '295px',
            margin: '0 auto',
          }}
        >
          <p
            style={{ width: '295px', marginTop: '24px', marginBottom: '20px' }}
            className='fs-18 fw-600'
          >
            Accepted Communication Methods
          </p>
          <p>
            Oops! {defaultMethod} is disabled! If you want to make {defaultMethod} your default, you will need to enable
            it first.
          </p>
          <div
            className="flex-col-center"
            style={{ width: '295px', marginTop: '37px', marginBottom: '20px' }}
          >
            <button
              onClick={() => toggleDefaultDisabled(false)}
              className="fw-600 fs-12 darkBlueButton pointer"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultDisabledModal;
