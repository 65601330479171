import { notificationError } from '../../../../../core/actions';

export const handleCatchError = error => {
  switch (error?.response?.status) {
    case 403:
      notificationError('You are not authorized to modify this item');
      break;
    case 404:
      notificationError('Item not found');
      break;
    default:
      notificationError('Internal Server Error');
      break;
  }
};
