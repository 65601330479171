import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { mapTableStateToProps, setTablePaginationPageNum } from '../../../core/actions';

const useVisiblePages = ({ pageCount, page, tableName }) => {
  const visiblePages = useMemo(() => {
    // Derived State
    const start = Math.floor(Math.max(1, page - 4));
    const end = Math.ceil(Math.max(Math.min(start + 7, pageCount), 1));
    const pages = Array.from(Array(end - start + 1), (_, visiblePageOffset) => start + visiblePageOffset).slice(0, 7);

    return pages;
  }, [pageCount, page]);
  const preventDefault = callback => e => {
    e.preventDefault();
    callback();
  };
  const setPageNum = page => {
    setTablePaginationPageNum(Math.min(pageCount, Math.max(1, page)), tableName);
  };
  return {
    visiblePages,
    forward: preventDefault(() => setPageNum(page + 1)),
    back: preventDefault(() => setPageNum(page - 1)),
    skipToEnd: preventDefault(() => setPageNum(pageCount)),
    skipToBeginning: preventDefault(() => setPageNum(1)),
    skipTo: page => preventDefault(() => setPageNum(page)),
  };
};

const Pagination = props => {
  const { visiblePages, ...paginationApi } = useVisiblePages({
    pageCount: props.pageCount,
    page: props.page,
    tableName: props.tableName,
  });

  useEffect(() => {
    // Optionally subscribe into persisting the page numbers
    if (!props.persistPagination) setTablePaginationPageNum(1, props.tableName);
  }, [props.persistPagination, props.tableName]);

  const paginationBlueFunc = event => {
    event.target.style.display = 'block';
  };

  return (
    <div className="pagination-container rem-pad-width" style={{ width: props.width }}>
      <a href="#">
        {
          <img
            className="h-12"
            src="https://dashboard-v2-images.s3.amazonaws.com/ic-backstep.svg"
            onClick={paginationApi.skipToBeginning}
          />
        }{' '}
      </a>
      <a href="#" onClick={paginationApi.back}>
        <img className="h-12" src="https://dashboard-v2-images.s3.amazonaws.com/ic-left.svg" />
      </a>
      <ul className="pagination-ul" onClick={paginationBlueFunc}>
        {visiblePages.map(number => (
          <li className={props.page == number ? 'pagination-li fw-600' : 'pagination-li'} key={number}>
            {
              props.page == number ? <div className="pagination-gray" /> : null // replace current page
            }
            <span onClick={paginationApi.skipTo(number)}>{number}</span>
          </li>
        ))}
      </ul>
      <a href="#" onClick={paginationApi.forward}>
        <img className="h-12" src="https://dashboard-v2-images.s3.amazonaws.com/ic-right.svg" />
      </a>
      <a href="#">
        <img
          className="h-12"
          src="https://dashboard-v2-images.s3.amazonaws.com/ic-frontstep.svg"
          onClick={paginationApi.skipToEnd}
        />
      </a>
    </div>
  );
};
const mapDispatchToProps = () => ({});

export default connect(
  mapTableStateToProps(({ tableName }) => tableName),
  mapDispatchToProps,
)(Pagination);
