import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import SmallTable from '../../SmallTable';
import { getRequest } from '../../../../core/apiRequests';
import { connect } from 'react-redux';

const ViewAllTemplates = props => {
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [leftCol, setLeftCol] = useState([]);
  const [rightCol, setRightCol] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const isMounted = useRef(null);
  const [filterTemplates, setFilterTemplates] = useState([]);
  const [allTemplates, setAllTemplates] = useState([]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    isMounted.current = true;
    if (!props.viewAllList || props.viewAllList.length === 0) {
      if (props.auth.merchantId) {
        (async () => {
          const reqObj = {
            params: ['chatMessages', props.auth.merchantId, 'allTemplates'],
          };
          const dataArr = await getRequest(reqObj);
          if (dataArr.length > 0) {
            let dataObj = dataArr[0];
            setAllTemplates([...dataArr]);
          }
        })();
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [props.auth.merchantId]);

  useEffect(() => {
    if (searchText) {
      let searchArray = searchText.split(' ');
      let filteredArr = [];
      allTemplates.forEach(tmpl => {
        let hasIt = true;
        searchArray.forEach(sText => {
          const sTextFm = sText.toLowerCase().trim() || '';
          let templateTxt = tmpl.template || '';
          let subTxt = tmpl.substitution || '';
          if (!templateTxt.toLowerCase().includes(sTextFm) && !subTxt.toLowerCase().includes(sTextFm)) {
            hasIt = false;
          }
        });
        if (hasIt) {
          filteredArr.push(tmpl);
        }
      });
      if (filteredArr.length > 0) {
        setFilterTemplates([...filteredArr]);
      } else {
        setFilterTemplates([]);
      }
    } else {
      setFilterTemplates([]);
    }
  }, [searchText]);

  useEffect(() => {
    splitColumns(whichData());
  }, [allTemplates, searchText]);

  const splitColumns = list => {
    let leftColumn = [];
    let rightColumn = [];

    list.filter((item, i) => {
      if ((i + 1) % 2 !== 0) {
        leftColumn.push(item);
      } else {
        rightColumn.push(item);
      }
    });
    setLeftCol([...leftColumn]);
    setRightCol([...rightColumn]);
  };

  const tableHeaders = [
    {
      keyName: 'template',
      title: '',
      headerClassName: '',
      style: { width: '100%', paddingTop: '0px' },
      sortable: false,
      render: (t, o) => {
        // t = text, o = obj.
        const subsObj = {}; // an obj that will look like {1: client, 2: product, 3: something, 4: whatevz}
        if (o.substitution) {
          // check for null columns
          const substitutes = o.substitution.split(','); // they're comma delimited, make an array
          substitutes.forEach(sub => {
            // loop over that array ^ , each sub looks something like 1=CLIENT or 2=PRODUCT
            let propertyDivide = sub.split('='); // split them up on that equals sign into an array
            if ((propertyDivide.length = 2)) {
              // each propertyDivide looks like this: [1, "CLIENT"] or [2, "PRODUCT"], etc...
              subsObj[propertyDivide[0]] = propertyDivide[1].toLowerCase(); // put them on that subsObj, and lowercase the values... we're rendering them later!
            }
          });
        }
        return t.replace(/{{\d}}/g, val => {
          // look for {{#}}
          const theNumber = val.replace(/[{}]/g, ''); // if found, remove all {'s and }'s
          if (subsObj[theNumber]) {
            // using the number, check if the property exists on the substitutes object
            return `[${subsObj[theNumber]}]`; // if so return it
          }
          return '[N/A]'; // else return this lol extreme edge case, I don't know.
        });
      },
    },
  ];

  const rowClickHandler = row => {
    props.setSelected(row.id, row); // went ahead an passed in row for views that don't have access to a list of all temps
  };

  const onSelectHandler = () => {
    props.saveHandler();
    //TODO: HEY, your previous function was this. I made it so it can be dynamicly used anywhere. Just feed saveHandler={theFunctionBelow} as props to this component.
    // const listItemId = props.listItemId;
    // history.push(
    //   `/Configuration/tags/edit-tag/${listItemId}/add-auto-whatsapp/${props.selected}`
    // );
  };

  const whichData = () => {
    if (searchText) {
      return filterTemplates;
    }
    return allTemplates;
  };

  return (
    <div className="view-all-wa-templates-background" id="modal-background">
      <div
        className="view-all-wa-templates-container rem-pad-width"
        style={
          width <= 500
            ? { width: '100vw', height: '100vh', zIndex: '99999' }
            : width <= 800
            ? { width: '606px', height: '782px', margin: 'auto' }
            : { width: '1078px', height: '782px', margin: 'auto' }
        }
      >
        <div
          className="flex-col-center h-100-P"
          style={{
            justifyContent: 'flex-start',
            width: 'calc(100% - 70px)',
            margin: '0 auto',
          }}
        >
          {width <= 500 ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '16px',
                marginBottom: '9px',
              }}
            >
              <FontAwesomeIcon icon={['fas', 'times']} onClick={() => props.toggleViewAll(false)} />
            </div>
          ) : null}
          <span
            className="fs-18 fw-500"
            style={width <= 500 ? { marginTop: 0, marginBottom: '18px' } : { marginTop: '32px', marginBottom: '18px' }}
          >
            WhatsApp Templates
          </span>
          {props.clientConversation ? (
            <span
              className="fs-12"
              style={width <= 500 ? { marginTop: 0, marginBottom: '18px' } : { marginBottom: '18px' }}
            >
              Client’s conversation session is closed. Please select an approved template.
            </span>
          ) : null}
          <div className="add-edit-wrap" style={{ width: '100%', marginTop: '10px' }}>
            <input
              onChange={e => {
                setSearchText(e.target.value);
              }}
              onKeyDown={e => {
                const keyCode = e.key || e.which;
                if (keyCode === 13) {
                  e.preventDefault();
                }
                return false;
              }}
              placeholder="Search WhatsApp templates..."
              className="h-50 w-100-P m-btm-6 body-head-search rem-pad-width focus-input-outline-blue"
              style={{ width: '100%', height: '50px' }}
            />
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'left',
              marginTop: '18px',
            }}
          >
            <p
              className={allTemplates.length === 0 || width <= 800 ? 'hidden' : ''}
              // className={Object.keys(allTemplates).length === 0 || width <= 800 ? 'hidden' : ''}
              style={{
                fontSize: '12px',
                fontWeight: '600',
                color: '#9c9c9c',
                margin: 0,
                marginBottom: '3px',
              }}
            >
              All Templates
            </p>
          </div>
          {allTemplates.length === 0 ? (
            <div
              style={{
                width: '100%',
                height: '510px',
              }}
            >
              {searchText ? (
                <p
                  style={{
                    fontSize: '12px',
                    fontStyle: 'italic',
                    color: '#9c9c9c',
                  }}
                >
                  No templates match your search criteria
                </p>
              ) : (
                <p
                  style={{
                    fontSize: '12px',
                    fontStyle: 'italic',
                    color: '#9c9c9c',
                  }}
                >
                  You do not have any approved WhatsApp templates
                </p>
              )}
            </div>
          ) : (
            <div
              id="double-column-table-container"
              className="hide-scrollbar"
              style={{
                width: '100%',
                display: 'flex',
                height: '510px',
                overflowY: 'scroll',
              }}
            >
              {width <= 800 ? (
                <Fragment>
                  <SmallTable
                    tableClassName={'edit-tags-auto-table hide-scrollbar max-w-600'}
                    tableContainerClass={'whatsapp-auto-container'}
                    tableStyle={{
                      paddingRight: '24px',
                      marginRight: 0,
                      boxSizing: 'border-box',
                      width: '100%',
                    }}
                    rowStyle={{ borderBottom: '1px solid #d0d0d0' }}
                    classname="hidden"
                    data={whichData()}
                    selected={props.selected}
                    onRowClick={rowClickHandler}
                    tableHeaders={tableHeaders}
                    noDataText={
                      window.location.href.includes('edit-auto-reminder')
                        ? false
                        : 'No templates match your search criteria'
                    }
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <SmallTable
                    tableClassName={'edit-tags-auto-table hide-scrollbar max-w-600'}
                    tableContainerClass={'whatsapp-auto-container'}
                    tableStyle={{
                      paddingRight: '24px',
                      marginRight: 0,
                      boxSizing: 'border-box',
                      width: '100%',
                      maxWidth: '480px',
                    }}
                    rowStyle={{ borderBottom: '1px solid #d0d0d0' }}
                    classname="hidden"
                    data={leftCol}
                    selected={props.selected}
                    onRowClick={rowClickHandler}
                    tableHeaders={tableHeaders}
                    noDataText={
                      window.location.href.includes('edit-auto-reminder')
                        ? false
                        : 'No templates match your search criteria'
                    }
                  />
                  <SmallTable
                    tableClassName={'edit-tags-auto-table hide-scrollbar max-w-600'}
                    tableContainerClass={'whatsapp-auto-container'}
                    tableStyle={{
                      marginLeft: '24px',
                      marginRight: '0px',
                      width: '100%',
                      maxWidth: '480px',
                    }}
                    rowStyle={{ borderBottom: '1px solid #d0d0d0' }}
                    classname="hidden"
                    data={rightCol}
                    selected={props.selected}
                    onRowClick={rowClickHandler}
                    tableHeaders={tableHeaders}
                    noDataText={' '}
                  />
                </Fragment>
              )}
            </div>
          )}

          <div
            className="flex-row-spacebetween-nowrap"
            style={{
              width: '295px',
              height: '103px',
              paddingTop: '26px',
              paddingBottom: '32px',
              boxSizing: 'border-box',
            }}
          >
            <button
              onClick={() => props.toggleViewAll(false)}
              style={{
                width: '140px',
                height: '45px',
                borderRadius: '3px',
                border: 'solid 1px #979797',
                backgroundColor: '#fff',
                color: '#979797',
              }}
              className="fs-12 fw-600 pointer"
            >
              CANCEL
            </button>
            <button onClick={onSelectHandler} style={{ width: '140px' }} className="fw-600 fs-12 save-button">
              {props.saveButtonText || 'SELECT'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(ViewAllTemplates);

const allTemplates0 = [];

const allTemplates1 = [
  {
    template: "I'm a peacock, you gotta let me fly on this one",
    id: 1,
  },
];

// const allTemplates = [
//   {
//     template: "I'm a peacock, you gotta let me fly on this one",
//     id: 1
//   },
//   {
//     template: "We're like the febreeze brothers we are so fresh",
//     id: 2
//   },
//   {
//     template: "You shut your mouth!",
//     id: 3
//   },
//   {
//     template: "Don't go chasing Waterfalls",
//     id: 4
//   },
//   {
//     template: "I'm a peacock, you gotta let me fly on this one",
//     id: 5
//   },
//   {
//     template: "We're like the febreeze brothers we are so fresh",
//     id: 6
//   },
//   {
//     template: "You shut your mouth!",
//     id: 7
//   },
//   {
//     template: "Don't go chasing Waterfalls",
//     id: 8
//   },
//   {
//     template: "I'm a peacock, you gotta let me fly on this one",
//     id: 11
//   },
//   {
//     template: "We're like the febreeze brothers we are so fresh",
//     id: 21
//   },
//   {
//     template: "You shut your mouth!",
//     id: 31
//   },
//   {
//     template: "Don't go chasing Waterfalls",
//     id: 41
//   },
//   {
//     template: "I'm a peacock, you gotta let me fly on this one",
//     id: 21
//   },
//   {
//     template: "We're like the febreeze brothers we are so fresh",
//     id: 22
//   },
//   {
//     template: "Don't go chasing Waterfalls",
//     id: 8
//   },
//   {
//     template: "I'm a peacock, you gotta let me fly on this one",
//     id: 11
//   },
//   {
//     template: "We're like the febreeze brothers we are so fresh",
//     id: 21
//   },
//   {
//     template: "You shut your mouth!",
//     id: 31
//   },
//   {
//     template: "Don't go chasing Waterfalls",
//     id: 41
//   },
//   {
//     template: "I'm a peacock, you gotta let me fly on this one",
//     id: 21
//   },
//   {
//     template: "We're like the febreeze brothers we are so fresh",
//     id: 22
//   },
//   {
//     template: "Don't go chasing Waterfalls",
//     id: 8
//   },
//   {
//     template: "I'm a peacock, you gotta let me fly on this one",
//     id: 11
//   },
//   {
//     template: "We're like the febreeze brothers we are so fresh",
//     id: 21
//   },
//   {
//     template: "You shut your mouth!",
//     id: 31
//   },
//   {
//     template: "Don't go chasing Waterfalls",
//     id: 41
//   },
//   {
//     template: "I'm a peacock, you gotta let me fly on this one",
//     id: 21
//   },
//   {
//     template: "We're like the febreeze brothers we are so fresh",
//     id: 22
//   },
//   {
//     template: "Don't go chasing Waterfalls",
//     id: 8
//   },
//   {
//     template: "I'm a peacock, you gotta let me fly on this one",
//     id: 11
//   },
//   {
//     template: "We're like the febreeze brothers we are so fresh",
//     id: 21
//   },
//   {
//     template: "You shut your mouth!",
//     id: 31
//   },
//   {
//     template: "Don't go chasing Waterfalls",
//     id: 41
//   },
//   {
//     template: "I'm a peacock, you gotta let me fly on this one",
//     id: 21
//   },
//   {
//     template: "We're like the febreeze brothers we are so fresh",
//     id: 22
//   },
//   {
//     template: "You shut your mouth!",
//     id: 23
//   },
//   {
//     template: "Don't go chasing Waterfalls",
//     id: 24
//   }
// ];
