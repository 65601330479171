import React from 'react';
import LoginLayout from '../LoginLayout';
import { useParams } from 'react-router';
import { postRequest } from '../../../../core/apiRequests';
import { installShopify } from './serviceApi';
import { setMerchantPosType } from '../../../../reducers/auth';

const serviceRoutes = {
  "shopify-install": "/help/integrations/shopify/settings"
};


const ServiceLogin = () => {
  const params = useParams();
  const { serviceType, serviceId } = params;

  const serviceCallbacks = {
    "shopify-install": async() => {
      await installShopify(["integrations/shopify/install"], serviceId);
      setMerchantPosType("SHOPIFY");
      return;
    }
  }

  const postLoginCallback = async() => {
    if(serviceCallbacks.hasOwnProperty(serviceType)) {
      await serviceCallbacks?.[serviceType]();
      return;
    }
  }

    return (
      <LoginLayout 
        redirectTo={serviceRoutes[serviceType] || "/"}
        postLoginCallback={postLoginCallback}
      />
    )
}

export default ServiceLogin;
