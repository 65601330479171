import React from 'react';

import { Input as InputComponent, Dropdown as DropdownComponent, Pair } from '../Field';

const AccountInfo = props => {
  const Input = inputProps => <InputComponent {...props} {...inputProps} />;
  const Dropdown = dropdownProps => <DropdownComponent {...props} {...dropdownProps} />;

  return (
    <fieldset>
      <Input label="Routing Number" id="bankRoutingNumber" placeholder="Routing Number" minLength="9" maxLength="9" inputMode="numeric" forget />
      <Input label="Account Number" id="bankAccountNumber" placeholder="Account Number" maxLength="17" inputMode="numeric" forget />
      <Input label="Bank Account Nickname (Optional)" id="bankAccountNickname" placeholder="Bank Account Nickname" maxLength="15" optional forget />
      <Pair>
        <Dropdown label="Account Type" id="bankAccountType" options={['Checking', 'Savings']} />
        <Input label="Bank Address State" id="bankState" minLength="2" maxLength="2" placeholder="State" />
      </Pair>
    </fieldset>
  );
};

export default AccountInfo;
