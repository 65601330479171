import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import StandardLayout from '../../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../../Layout/SecondaryPanel';

import AutoMessageSettings from '../../../../../Layout/AutoMessages/AutoMessageSettings';

const TagAutoMessage = props => {
  const history = useHistory();
  const listItemId = props.match.params.listItemId;

  const setRedirectBack = () => {
    history.push(`/Configuration/tags/edit-tag/${listItemId}`);
  };

  return (
    <StandardLayout
      title={`${props.autoMessageAddOrEdit} Auto Message`}
      description={`Write a message to send automatically.`}
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Tags', to: '/Configuration/tags' },
        { title: 'Edit Tag', to: `/Configuration/tags/edit-tag/${listItemId}` },
        { title: `${props.autoMessageAddOrEdit} Auto Message` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <AutoMessageSettings
        setRedirectBack={setRedirectBack}
        cancelRoute={`/Configuration/tags/edit-tag/${listItemId}`}
        popConfirmDesc={'This will remove the Auto Message from this Tag'}
        editRoute={`/Configuration/tags/edit-tag/${listItemId}`}
        type={'tags'}
        match={props.match}
      />
      <SecondaryPanel title={['Automation']} current="Tags" />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({ ...state.autoMessages });
export default connect(mapStateToProps, {})(TagAutoMessage);
