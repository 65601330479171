import { dispatcher } from '../../../../core/actions';

const DEFAULT_REVIEW_MESSAGE =
  ':name:, would you be willing to take 30 seconds to leave us a review using the link below? Let me know how I can help in the future. :review:';

export const messageDispatcher = (data, brandName) => {
  let OPT_IN_INVITE = '';
  let OPT_IN_CONFIRM = '';
  let OPT_IN_BULK_INVITE = '';
  let DEFAULT_STORE = '';
  let REVIEW_MESSAGE = '';
  let REVIEW_IMAGES_URL = [];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      let textVal = '';
      if (key.toUpperCase() === 'OPT_IN_INVITE') {
        OPT_IN_INVITE = data[key];
      }
      if (key.toUpperCase() === 'OPT_IN_CONFIRM') {
        OPT_IN_CONFIRM = data[key];
      }
      if (key.toUpperCase() === 'OPT_IN_BULK_INVITE') {
        OPT_IN_BULK_INVITE = data[key];
      }
      if (key.toUpperCase() === 'DEFAULT_STORE') {
        DEFAULT_STORE = data[key];
      }
      if (key.toUpperCase() === 'REVIEW_MESSAGE') {
        REVIEW_MESSAGE = data[key];
      }
      if (key.toUpperCase() === 'REVIEW_IMAGES_URL') {
        REVIEW_IMAGES_URL = data[key];
      }
      if (data[key] !== null) textVal = data[key];
      dispatcher(`SET_MSG_${key.toUpperCase()}`, textVal);
    }
  }
  if (!OPT_IN_INVITE) {
    dispatcher(
      'SET_MSG_OPT_IN_INVITE',
      `Hi :name:, It's :associate: from ${brandName} and you can reach me anytime at this number. Can I occasionally let you know about personalized promotions and products? If that's ok, reply YES to this msg`,
    );
  }
  if (!OPT_IN_CONFIRM) {
    dispatcher(
      'SET_MSG_OPT_IN_CONFIRM',
      ":name:, thanks for agreeing to let us contact you! You are free to reply STOP if you don't want to hear from us anymore. freq varies, msg&data rates apply",
    );
  }
  if (!OPT_IN_BULK_INVITE) {
    dispatcher(
      'SET_MSG_OPT_IN_BULK_INVITE',
      `Hi :name:, It's :associate: from ${brandName}. I have a message I'd like to send you but need your permission. I'll also occasionally let you know about personalized promotions and products? If that's ok, reply YES`,
    );
  }
  if (!REVIEW_MESSAGE) {
    dispatcher('SET_MSG_REVIEW_MESSAGE', DEFAULT_REVIEW_MESSAGE);
  }
  if (!REVIEW_IMAGES_URL) {
    dispatcher('SET_MSG_REVIEW_IMAGES_URL', []);
  }
  if (!DEFAULT_STORE) {
    dispatcher('SET_MSG_DEFAULT_STORE', '');
  }
};

export const bodyFormatter = props => {
  let {
    doubleOptIn,
    delayOptIn,
    autoAsA2p,
    optInInvite,
    optInConfirm,
    optInBulkInvite,
    setSchTime,
    defaultStore,
    reviewMessage,
    reviewImages,
    allStoreConversationHistory,
    merchantConfig: { DECLUTTERED_INBOX }
  } = props;

  if (reviewMessage === DEFAULT_REVIEW_MESSAGE) {
    reviewMessage = null;
  }

  return {
    double_opt_in: doubleOptIn,
    DELAY_OPT_IN: delayOptIn,
    AUTO_AS_A2P: autoAsA2p,
    OPT_IN_INVITE: optInInvite,
    OPT_IN_CONFIRM: optInConfirm,
    OPT_IN_BULK_INVITE: optInBulkInvite,
    AUTO_MSG_TIME: setSchTime,
    DEFAULT_STORE: defaultStore,
    REVIEW_MESSAGE: reviewMessage,
    REVIEW_IMAGES_URL: reviewImages,
    ALL_STORE_CONVERSATION_HISTORY: allStoreConversationHistory,
    DECLUTTERED_INBOX
  };
};

export const bodyFormatterKiosk = props => {
  const {
    logoUrl,
    roundLogo,
    backgroundColor,
    backgroundUrl,
    introTitle,
    formTitle,
    topSectionHeader,
    bottomSectionHeader,
    optInTitle,
    optInHelp,
    optInLegal,
    buttonText,
    completeTitle,
    completeSubtitle,
    show_anniversary,
    show_birthday,
    show_address,
    show_email,
  } = props;

  return {
    logo_url: logoUrl,
    round_logo: roundLogo,
    background_color: backgroundColor,
    background_url: backgroundUrl,
    intro_title: introTitle,
    form_title: formTitle,
    top_section_header: topSectionHeader,
    bottom_section_header: bottomSectionHeader,
    opt_in_title: optInTitle,
    opt_in_help: optInHelp,
    opt_in_legal: optInLegal,
    button_text: buttonText,
    complete_title: completeTitle,
    complete_subtitle: completeSubtitle,
    show_anniversary,
    show_birthday,
    show_address,
    show_email,
  };
};

export const bodyFormatterApp = props => {
  const {
    appBulkLimit,
    dailyContactGoal,
    salesOppName,
    merchantConfig: { LIMIT_ASSOCIATE_VISIBILITY_ENABLED, HIDE_PRODUCT_COST },
    merchantFlags
  } = props;

  const LIMITED_ASSOCIATE_VISIBILITY = merchantFlags?.limited_associate_visibility?.enabled;

  return {
    APP_BULK_LIMIT: appBulkLimit,
    daily_contact_goal: dailyContactGoal,
    SALES_OPP_NAME: salesOppName,
    LIMIT_ASSOCIATE_VISIBILITY_ENABLED,
    LIMITED_ASSOCIATE_VISIBILITY,
    HIDE_PRODUCT_COST,
  };
};
