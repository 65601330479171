import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import Layout from '../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';

import { setTriggerActionObj } from '../../../../../reducers/salesTriggers';
import AutoMessTriggerSettings from '../../../../Layout/AutoMessageSalesTrigger/AutoMessTriggerSettings';
import { getRequest, postRequest } from '../../../../../core/apiRequests';
import BorderlessTable from '../../../../Layout/BorderlessTable';

import SmallTable from '../../../../Layout/SmallTable';
import StyledLink from '../../../../Layout/StyledLink';
import { notificationError, notificationSuccess } from '../../../../../core/actions';

const EditWATriggerMsg = props => {
  const history = useHistory();
  const isMounted = useRef(null);
  const [allTemplates, setAllTemplates] = useState([]);
  const [selected, setSelected] = useState('');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (props.auth.merchantId) {
      (async () => {
        const reqObj = {
          params: ['salesTrigger', props.auth.merchantId, 'getWhatsappAutoMsg'],
          query: {
            actionId: props.match.params.actionId,
          },
        };
        const dataSet = await getRequest(reqObj);
        // const dataSet = await getAll('salesTrigger', props.auth.merchantId, `?actionId=${props.match.params.actionId}`);
        if (dataSet) {
          const { templates, actionObj } = dataSet;
          const { title, description, action_id, whatsapp_template_id } = actionObj;
          const stateObj = {
            actionTitle: title,
            actionDescription: description,
            actionId: action_id,
            // actionMsgTemplates: [...msgs],
          };
          if (whatsapp_template_id) {
            setSelected(whatsapp_template_id);
          }
          setAllTemplates([...templates]);
          setTriggerActionObj(stateObj);
        }
      })();
    }
  }, [props.auth.merchantId]);

  const rowClickHandler = val => {
    setSelected(val.id);
  };

  const tableHeaders = [
    {
      keyName: 'template',
      title: '',
      headerClassName: '',
      style: { width: '100%', paddingTop: '0px' },
      sortable: false,
      render: (t, o) => {
        // t = text, o = obj.
        const subsObj = {}; // an obj that will look like {1: client, 2: product, 3: something, 4: whatevz}
        if (o.substitution) {
          // check for null columns
          const substitutes = o.substitution.split(','); // they're comma delimited, make an array
          substitutes.forEach(sub => {
            // loop over that array ^ , each sub looks something like 1=CLIENT or 2=PRODUCT
            const propertyDivide = sub.split('='); // split them up on that equals sign into an array
            if ((propertyDivide.length = 2)) {
              // each propertyDivide looks like this: [1, "CLIENT"] or [2, "PRODUCT"], etc...
              subsObj[propertyDivide[0]] = propertyDivide[1].toLowerCase(); // put them on that subsObj, and lowercase the values... we're rendering them later!
            }
          });
        }
        return t.replace(/{{\d}}/g, val => {
          // look for {{#}}
          const theNumber = val.replace(/[{}]/g, ''); // if found, remove all {'s and }'s
          if (subsObj[theNumber]) {
            // using the number, check if the property exists on the substitutes object
            return `[${subsObj[theNumber]}]`; // if so return it
          }
          return '[N/A]'; // else return this lol extreme edge case, I don't know.
        });
      },
    },
  ];

  const saveWhatsappMessage = () => {
    (async () => {
      const reqObj = {
        params: ['salesTrigger', props.auth.merchantId, props.match.params.actionId, 'saveWhatsappAutoMsg'],
        query: {
          templateId: selected,
        },
      };
      const sendReq = await postRequest(reqObj);
      if (sendReq) {
        notificationSuccess('Message Config Saved');
        history.push('/Configuration/sales-triggers');
      } else {
        notificationError('Error Saving Message Config');
      }
    })();
  };

  const whichData = () => {
    return allTemplates.filter(tempRecord => {
      if (tempRecord.substitution) {
        if (tempRecord.substitution.toLowerCase().includes(searchText)) {
          return true;
        }
      }
      if (tempRecord.name) {
        if (tempRecord.name.toLowerCase().includes(searchText)) {
          return true;
        }
      }
      if (tempRecord.template) {
        if (tempRecord.template.toLowerCase().includes(searchText)) {
          return true;
        }
      }
      return false;
    });
  };

  return (
    <Layout
      title={`Edit ${props.salesTriggers.trig_action_title || ''} Whatsapp Auto-Message`}
      description="Configuration for this Auto-Message"
      // styleProps={{ marginLeft: '265px', width: '866px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Sales Triggers', to: '/Configuration/sales-triggers' },
        { title: 'Edit Auto Message' },
      ]}
      classProps="standard-width-with-sidebar"
    >
      <input
        onChange={e => setSearchText(e.target.value)}
        onKeyDown={e => {
          const keyCode = e.key || e.which;
          if (keyCode == 13) {
            e.preventDefault();
          }
          return false;
        }}
        placeholder="Search WhatsApp templates..."
        className="h-50 w-100-P m-btm-6 body-head-search rem-pad-width focus-input-outline-blue m-btm-15"
        style={{ width: '100%', height: '50px' }}
      />
      <SmallTable
        tableClassName=" hide-scrollbar max-w-600"
        tableContainerClass="h-auto"
        tableStyle={{
          paddingRight: '24px',
          marginRight: 0,
          boxSizing: 'border-box',
          width: '100%',
        }}
        rowStyle={{ borderBottom: '1px solid #d0d0d0' }}
        classname="hidden"
        data={whichData()}
        selected={selected}
        onRowClick={rowClickHandler}
        tableHeaders={tableHeaders}
        noDataText="No templates match your search criteria"
      />

      <div className="flex-col-center maw-600 m-top-15">
        <button className="save-button-v2 m-btm-8" onClick={saveWhatsappMessage}>
          Save
        </button>
        <StyledLink to="/Configuration/sales-triggers">
          <button className="cancel-button-v2">Cancel</button>
        </StyledLink>
      </div>

      <SecondaryPanel title={['Automation']} current="Sales Triggers" />
    </Layout>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
  salesTriggers: state.salesTriggers,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(EditWATriggerMsg);
