import React from 'react';
import { postRequest } from '../../../../../core/apiRequests';

const EnableConfirmationModal = props => {
  const { enabledContactMethodToggle, enableMethod, storeId, merchantId, methods, setMethods } = props;
  const methodName = enableMethod[0] == 'Email' ? 'email' : enableMethod[0];
  const methodEnabled = enableMethod[1];

  const onSaveHandler = () => {
    if (merchantId) {
      (async () => {
        const reqObj = {
          params: ['stores', 'enable-contact-method', merchantId, storeId],
          data: [methodName, methodEnabled],
        };
        const res = await postRequest(reqObj);
        if (res) {
          let newArr = methods.map(method => {
            if (method.type === methodName) {
              return {
                ...method,
                enabled: methodEnabled ? 0 : 1,
              };
            } else {
              return method;
            }
          });
          setMethods(newArr);
          enabledContactMethodToggle();
           props.toggleRefresh(true);
        }
      })();
    }
  };

  return (
    <div className="pop-confirm-background" id="modal-background">
      <div className="pop-confirm-container rem-pad-width" style={{ width: '430px' }}>
        <div
          className="flex-col-center h-100-P"
          style={{
            justifyContent: 'flex-start',
            width: '295px',
            margin: '0 auto',
          }}
        >
          <p
            style={{ width: '295px', marginTop: '24px', marginBottom: '20px' }}
            className='fs-18 fw-600'
          >
            Accepted Communication Methods
          </p>
          <p>
            Are you sure you want to {!!methodEnabled ? 'disable' : 'enable'} {methodName}?
          </p>
          <div
            className="flex-col-center"
            style={{ width: '295px', marginTop: '24px', marginBottom: '20px' }}
          >
            <button
              onClick={() => onSaveHandler()}
              className="fw-600 fs-12 darkBlueButton pointer"
            >
              Save
            </button>
            <button
              onClick={() => enabledContactMethodToggle(false)}
              className="fs-12 fw-600 pointer cancel-button-v2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnableConfirmationModal;
