import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router';
import StyledLink from '../../Layout/StyledLink';
import { gAlch } from './helpers/Alch';
import { connect } from 'react-redux';
import { LoginHandler } from './helpers/loginApi';
import { handleLoginRememberMe } from './helpers';

const LoginLayout = ({ redirectTo, postLoginCallback = () => {} }) => {
  const [rememberMe, setRememberMe] = useState(true);
  const [emailText, setEmailText] = useState('');
  const [pwText, setPWText] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const passwordEnterHandler = e => {
    const newE = e || window.event;
    const keyCode = newE.keyCode || newE.which;
    if (Number(keyCode) === 13) {
      attemptAuth();
      return false;
    }
    return true;
  };

  const attemptAuth = async () => {
    setIsButtonDisabled(true);
    const tkNa = await gAlch({ emailText, pwText });
    setErrorMsg('');
    if (tkNa?.tKn) {
      const newLogin = new LoginHandler('basic', null);
      const authResp = await newLogin.loginHandler({ tKn: tkNa?.tKn, ...tkNa?.auth }, {});
      if (authResp?.error) {
        const err = authResp.error;
        if (typeof err === 'string') {
          setErrorMsg(err);
        } else {
          setErrorMsg('Login error');
        }
        if (err.response && err.response.data && err.response.data.message) {
          if (typeof err.response.data.message !== 'string') {
            console.error({ error: JSON.stringify(err.response.data.message) });
          } else {
            console.error({ error: err.response.data.message.split('"').join('') });
          }
        } else {
          console.error({ error: 'Server error' });
        }
      }
      if (authResp?.merchantId) {
        setAppAuth(authResp);
      }
      setIsButtonDisabled(false);
    } else {
      console.error('[gAlch] Error packaging data');
      setIsButtonDisabled(false);
    }
    return;
  };

  const setAppAuth = async userObj => {
    const { token, merchantId, merchantFlags, userId } = userObj;
    handleLoginRememberMe(rememberMe, token, userObj);
    await postLoginCallback();
    setRedirect(true);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div>
      <img alt="#" src="https://dashboard-v2-images.s3.amazonaws.com/logo-gray.svg" className="login-top-left-logo" />
      <div className="left-login-panel" />
      <div className="right-login-panel">
        <img alt="#" src="https://dashboard-v2-images.s3.amazonaws.com/logo-mark.svg" />
        <span className="login-title">Dashboard</span>
        <div className="login-inputs">
          <h5 className="error-text">{errorMsg || ''}</h5>
          <div className="posRel emailDiv">
            <input
              className="associate_email focus-input-outline-blue"
              value={emailText}
              onChange={e => setEmailText(e.target.value)}
              placeholder="Email Address"
              type="email"
            />
          </div>
          <div className="posRel passwordDiv">
            <input
              value={pwText}
              className="associate_email focus-input-outline-blue"
              style={{
                backgroundImage: 'url("https://dashboard-v2-images.s3.amazonaws.com/ic-lock.svg")',
                backgroundPositionY: '15px',
              }}
              onChange={e => setPWText(e.target.value)}
              onKeyUp={passwordEnterHandler}
              placeholder="Password"
              type="password"
            />
          </div>
        </div>
        <div className="login-optional">
          <div aria-hidden="true" className="pointer" onClick={() => setRememberMe(!rememberMe)}>
            <img
              alt="#"
              src={
                rememberMe
                  ? 'https://dashboard-v2-images.s3.amazonaws.com/ic-checkbox.svg'
                  : 'https://dashboard-v2-images.s3.amazonaws.com/ic-box.svg'
              }
            />
            Remember me
          </div>
          <StyledLink
            classprops="right-0 pos-abs"
            styleprops={{
              color: '#33aafc',
              //  margin: "12px 20px 0 0"
            }}
            // href="mailto:support@clientbook.com?subject=Dashboard Login Help"
            className="login-help unstyleA"
            to="/Login/reset-password"
          >
            Need login help?
          </StyledLink>
        </div>
        <button
          disabled={isButtonDisabled}
          type="button"
          className="login-button pointer"
          onClick={() => {
            attemptAuth();
          }}
        >
          Login
        </button>
        <StyledLink to="/Login/reset-password" className="login-help-mobile unstyleA">
          Need login help?
        </StyledLink>
        <div className="info-footer">
          <span className="fs-12">
            <a rel="noopener noreferrer" target="_blank" className="unstyleA" href="https://clientbook.com/">
              Clientbook
            </a>
            <a rel="noopener noreferrer" target="_blank" className="unstyleA" href="https://clientbook.com/blog/">
              Blog
            </a>
            <a rel="noopener noreferrer" target="_blank" className="unstyleA" href="https://help.clientbook.com/en/">
              Help
            </a>
          </span>
          <span className="fs-12">© {currentYear} Clientbook Inc. All rights reserved.</span>
        </div>
      </div>
      {redirect ? <Redirect to={redirectTo || '/'} /> : null}
    </div>
  );
};
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
  setAuth(account, token) {
    dispatch({
      type: 'SET_AUTH',
      payload: { account, token },
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginLayout);
