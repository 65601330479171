import React, { useCallback, useState, useEffect } from 'react';
import Axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import DropdownSelect from '../../DropdownSelect';
import { notificationError, notificationSuccess } from '../../../../core/actions';
import { postRequest, getRequest } from '../../../../core/apiRequests';
import PopConfirm from '../../PopConfirm';

const PopMessageAddClient = ({ onClose, auth, client, stores, iso_country_code, newClient, getClientData }) => {
  const [loading, setLoading] = useState(false);
  const [addClientForm, setAddClientForm] = useState({
    name: '',
    email: '',
    phone: '',
    contactPref: '',
    storeId: auth.stores[1].id,
  });
  const [methods, setMethods] = useState([]);
  const [smsStoreNum, setSmsStoreNum] = useState(stores?.map(st => st.sms_number).filter(store => store !== undefined));
  const [showPopMergedClient, setShowPopMergedClient] = useState(false);
  const [mergedClientId, setMergedClientId] = useState(null);

  const confirmMergedClient = () => {
    getClientData(mergedClientId);
    setShowPopMergedClient(false);
    setMergedClientId(null);
    notificationSuccess('Client Saved!');
    cancelAddClientForm();
  };

  const resetData = useCallback(() => {
    setAddClientForm({
      name: '',
      email: '',
      phone: '',
      contactPref: '',
      storeId: '',
    });
    onClose();
    setLoading(false);
  }, []);

  const cancelAddClientForm = useCallback(() => {
    setAddClientForm({
      name: '',
      email: '',
      localNumber: '',
      contactPref: '',
    });
    onClose();
  }, []);

  const mobileValidation = phone => {
    if (!phone) {
      return true;
    }
    if (phone.match(/\d/g).length >= 10) {
      return true;
    }
    notificationError('Enter valid mobile phone number!');
    return false;
  };

  const smsMobile = phone => {
    if (!phone) {
      return true;
    }
    if (phone !== undefined) {
      const smsCompare = smsStoreNum.filter(number => {
        if (number.replace(/[^0-9]+/g, '') == phone.replace(/[^0-9]+/g, '')) {
          return true;
        }
        return false;
      });
      if (smsCompare.length > 0) {
        notificationError('Client number cannot match store number!');
      } else return true;
    }
  };

  const emailValidation = email => {
    if (email.length === 0) {
      return true;
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  };

  const formValidation = addClientForm => {
    let valid = true;
    if (addClientForm.name.length < 2) {
      notificationError('Please Enter a Valid Name');
      valid = false;
    } else if (!emailValidation(addClientForm.email)) {
      notificationError('Please Enter a Valid Email Address');
      valid = false;
    }
    return valid;
  };

  const handleValidate = addClientForm => {
    if (formValidation(addClientForm) && mobileValidation(addClientForm.localNumber)) {
      setLoading(true);
      const store_name = stores.find(store => store.id === addClientForm.storeId).name;
      const dataObj = {
        associateId: auth.userId,
        name: addClientForm.name.trim(),
        mobile: addClientForm.localNumber,
        email: addClientForm.email.trim(),
        contactPref: addClientForm.contactPref,
        store_id: addClientForm.storeId,
        store_name,
        preferences: [],
        lifeEvents: [],
        address: [],
      };
      const reqObj = {
        params: ['clients', auth.merchantId, 'save'],
        data: dataObj,
        query: { createdBy: auth.createdBy },
        loadingStatusOff: true,
      };
      (async () => {
        const data = await postRequest(reqObj);
        if (data && data.id) {
          if (data?.isMergedClient) {
            // merge client already exists logic here
            setMergedClientId(data.id);
            setShowPopMergedClient(true);
          } else {
            getClientData(data.id);
            notificationSuccess('Client Saved!');
            cancelAddClientForm();
          }
        }
      })();
      setLoading(false);
    }
  };

  const handleCancelButton = useCallback(() => {
    if (newClient) {
      cancelAddClientForm();
    } else {
      resetData();
    }
  }, [newClient, cancelAddClientForm]);

  const handleClientChange = useCallback(
    (field, value) => {
      setAddClientForm({
        ...addClientForm,
        [field]: value,
      });
    },
    [addClientForm],
  );

  const sortMethods = methods => {
    const filteredmethods = methods.filter(method => method.visible === 1);
    filteredmethods.sort((a, b) => {
      return b.isDefault - a.isDefault;
    });
    filteredmethods.sort((a, b) => {
      return b.enabled - a.enabled;
    });
    return filteredmethods;
  };

  const getStoreMethods = storeId => {
    if (auth.merchantId && storeId) {
      (async () => {
        const reqObj = {
          params: ['stores', 'contact-methods', auth.merchantId, storeId],
          query: {},
        };
        const data = await getRequest(reqObj);
        setMethods(sortMethods(data));
      })();
    }
  };

  useEffect(() => {
    getStoreMethods(addClientForm.storeId);
  }, [addClientForm.storeId]);

  useEffect(() => {
    if (methods?.length > 0 && auth.stores.length > 0 && addClientForm.storeId) {
      const store = auth.stores.find(store => store.id === addClientForm.storeId);
      const method = methods.find(method => method?.type?.toUpperCase() === store?.default_method);
      handleClientChange('contactPref', method?.type.toUpperCase());
    }
  }, [addClientForm.storeId, auth.stores, methods])

  return (
    <div
      //   onClick={() => onClose()}
      className="pop-confirm-background"
      id="modal-background"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '8px',
        justifyContent: 'center',
        zIndex: '15',
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: '348px',
          padding: '32px',
          overflowY: 'auto',
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxSizing: 'border-box',
          zIndex: '40',
        }}
      >
        <div className="flex-col-center h-100-P">
          <div style={{ position: 'relative', zIndex: '40' }} className="flex-row-center-center w-100-P">
            <span
              className="fs-16 fw-600"
              style={{
                lineHeight: '24px',
                marginBottom: '32px',
              }}
            >
              Add Client
            </span>
          </div>

          <DropdownSelect
            id="NewClientStoreDropdown"
            classProps="w-289 align-left m-btm-16"
            optionalStyles={{ paddingLeft: '10px' }}
            options={auth.stores
              .filter(s => s.id !== 0)
              .map((st, i) => ({
                label: `Preferred Store: ${st.name}`,
                value: st.id,
              }))}
            placeholder="Preferred Store"
            value={addClientForm.storeId}
            onChange={obj => {
              handleClientChange('storeId', obj.value);
            }}
          />

          <input
            className="h-50 rem-pad-width mq-50-P-to-100-P-on-535 input-image-style client_name_field focus-input-outline-blue"
            style={{ width: '100%' }}
            placeholder="Name"
            value={addClientForm.name}
            onChange={e => handleClientChange('name', e.target.value)}
          />
          <PhoneInput
            placeholder="Enter phone number"
            value={addClientForm?.localNumber}
            onChange={e => {
              handleClientChange('localNumber', e);
              smsMobile(e);
            }}
            defaultCountry={iso_country_code}
            style={{
              width: '100%',
              height: '50px',
              marginTop: '15px',
            }}
            countryOptionsOrder={[iso_country_code, '|']}
            countrySelectProps={{ backgroundcolor: 'rgb(129, 129, 129' }}
            className="w-100-P"
          />
          <input
            className="h-50 rem-pad-width m-top-15 w-100-P input-image-style client_email_field focus-input-outline-blue"
            style={{ width: '100%' }}
            placeholder="Email Address"
            value={addClientForm?.email || ''}
            onChange={e => handleClientChange('email', e.target.value)}
          />
          <DropdownSelect
            classProps="w-100-P align-left m-top-15"
            options={methods.map(val => {
              return {
                label: `Contact Method: ${val.type}`,
                value: val.type.toUpperCase(),
              };
            })}
            placeholder="Contact Method:"
            value={addClientForm?.contactPref}
            onChange={obj => {
              handleClientChange('contactPref', obj.value);
            }}
          />
          {/* here  */}
          <div className="w-100-P flex-col-center" style={{ width: '100%', marginTop: '24px' }}>
            <button
              type="button"
              id="SaveNewClient"
              className="save-button-v2 m-top-15 m-btm-8"
              disabled={loading}
              onClick={() => handleValidate(addClientForm)}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
            <button type="button" id="CancelNewClien" className="cancel-button-v2" onClick={handleCancelButton}>
              Cancel
            </button>
          </div>
        </div>
      </div>
      {showPopMergedClient ? (
        <PopConfirm
          confirm="This client already exists and has been updated"
          onConfirm={confirmMergedClient}
          showCancelButton={false}
        />
      ) : null}
    </div>
  );
};

export default PopMessageAddClient;
