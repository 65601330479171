import request from '../utils/request';

export const getVendorById = vendorId => {
  return request({
    url: `/vendors/${vendorId}`,
    method: 'GET',
  });
};

export const getVendors = ({ params = {} }) => {
  return request({
    url: '/vendors/getVendors',
    method: 'GET',
    params,
  });
};

export const getVendorItems = (vendorId, { params = {} }) => {
  return request({
    url: `/vendors/${vendorId}/items`,
    method: 'GET',
    params,
  });
};

export const getVendorItemById = (vendorId, itemId) => {
  return request({
    url: `/vendors/${vendorId}/items/${itemId}`,
    method: 'GET',
  });
};

export const addVendorItemToClientCollection = (clientId, collectionId, data) => {
  return request({
    url: `/clients/${clientId}/collections/${collectionId}/vendorItems`,
    method: 'POST',
    data,
  });
};

export const trackVendorEvent = data => {
  return request({
    url: '/vendors/events',
    method: 'POST',
    data,
  });
};
