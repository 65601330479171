import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate } from '../utils';
import { DATE_FORMAT } from '../const';

const STATUSES = {
  REMINDER: {
    icon: ['fas', 'bell'],
    color: '#818181',
    text: 'Sent a reminder',
  },
  UNPAID: {
    icon: ['far', 'file-alt'],
    color: '#7FBD31',
    text: 'Created a payment request',
  },
  PAID_MANUALLY: {
    icon: ['fas', 'check-circle'],
    color: '#33AAFC',
    text: 'Mark payment request as paid',
  },
  CANCELED: {
    icon: ['far', 'undo'],
    color: '#818181',
    text: 'Canceled payment request',
  },
  PAID_BY_CC: {
    icon: ['fas', 'check-circle'],
    color: '#33AAFC',
    text: `Client paid payment request`,
  },
  REFUNDED: {
    icon: ['far', 'undo'],
    color: '#818181',
    text: ({ amount }) => `Refunded $${amount}`,
  },
  PAYMENT_FAILED: {
    icon: ['fas', 'exclamation-circle'],
    color: '#D93B3B',
    text: 'Failed to process payment method',
  },
  REFUND_FAILED: {
    icon: ['fas', 'exclamation-circle'],
    color: '#D93B3B',
    text: 'Payment request failed to refund',
  },
  VIEWED: {
    icon: ['fas', 'eye'],
    color: '#818181',
    text: 'Client viewed payment request',
  },
  EXPIRED: {
    icon: ['fas', 'exclamation-circle'],
    color: '#D93B3B',
    text: 'Payment request has expired',
  },
  CHARGEBACK: {
    icon: ['far', 'undo'],
    color: '#818181',
    text: 'Chargeback by cardholder',
  },
};

const getStatusText = (status, options) =>
  typeof STATUSES[status]?.text === 'function' ? STATUSES[status]?.text(options) : STATUSES[status]?.text;

const ActivityItem = ({ status, time, amount }) => {
  return (
    <>
      <div className="flex-row-nospacebetween-nowrap">
        <FontAwesomeIcon icon={STATUSES[status]?.icon} color={STATUSES[status]?.color} />
        <p className="align-left fs-13 m-left-4 m-btm-8 fw-500">{getStatusText(status, { amount })}</p>
      </div>
      <p className="fs-12 gray m-top-0 m-left-21 fs-400">{formatDate(time, DATE_FORMAT.LONG_DATE_TIME)}</p>
    </>
  );
};

export default ActivityItem;
