import Axios from 'axios';
import FileDownload from 'js-file-download';

// reducers & action
import { addLoadingList, removeLoadingList } from '../../../../reducers/notification';
import { setBankAccount, setBankTransactionList, setPagination } from '../../../../reducers/transactions';
import { setFullBankAccountsList } from '../../../../reducers/bankAccounts';
import { notificationError } from '../../../../core/actions';

// local
import { getSearchParams } from './utils';
import { PAYMENTS_API_URL } from '../../../../constants';
import { v4 } from 'uuid';
// import { getSearchParams } from '../../Payments/utils';

export const getBankTransactionReport = (
  merchantId,
  bankAccounts,
  isConcatenatingList = false,
  {
    startDate = '',
    endDate = '',
    transactionFilterBankAccounts = [],
    transactionFilterStatus = [],
    nextPageToken = '',
  },
) =>
  // params,
  new Promise((resolve, reject) => {

    const uuid = v4();
    addLoadingList(uuid);
    const searchParams = getSearchParams({
      statuses: transactionFilterStatus,
      statusFromDate: startDate,
      statusToDate: endDate,
      nextPageToken,
    });

    const getBankId = accountName => {
      const bankAccount = bankAccounts.find(({ name }) => name === accountName);
      return bankAccount?.id;
    };
    const bankId = getBankId(transactionFilterBankAccounts[0]) || 0;

    return Axios.get(`${PAYMENTS_API_URL}/v1/reports/bankTransaction/${merchantId}/${bankId}${searchParams}`)
      .then(({ data }) => {
        const { transactionData, accountDetails, pagination } = data;
        setPagination(pagination.nextPageToken);
        setBankAccount(accountDetails.name);
        setBankTransactionList({ transactionData, isConcatenatingList });
        resolve();
      })
      .catch(err => {
        notificationError('Bank transactions did not load ');
        reject();
      })
      .finally(() => {
        removeLoadingList(uuid);
      });
  });

export const getBankTransactionCSV = (
  merchantId,
  bankAccounts,
  { startDate = '', endDate = '', page, count, transactionFilterBankAccounts = [] },
) =>
  // params,
  new Promise((resolve, reject) => {
    const uuid = v4();
    addLoadingList(uuid);

    const searchParams = getSearchParams({
      statusFromDate: startDate,
      statusToDate: endDate,
      page,
      count,
    });
    const tempBankFilterList = bankAccounts.filter(obj => {
      return obj.name === transactionFilterBankAccounts[0];
    });
    const bankId = tempBankFilterList[0]?.id || 0;

    return Axios.get(`${PAYMENTS_API_URL}/v1/reports/bankTransactionCSV/${merchantId}/${bankId}${searchParams}`)
      .then(({ data }) => {
        FileDownload(data, 'Bank-Transactions.csv');
        resolve();
      })
      .catch(err => {
        notificationError('Bank transaction CSV did not load ');
        reject();
      })
      .finally(() => {
        removeLoadingList(uuid)
      });
  });

export const getBankAccountsList = merchantId =>
  new Promise((resolve, reject) => {
    if (!merchantId) {
      return null;
    }

    return Axios.get(`${PAYMENTS_API_URL}/v1/merchant/${merchantId}/full-list`)
      .then(({ data }) => {
        setFullBankAccountsList(data);
        resolve();
      })
      .catch(err => {
        console.log({ err });
        // notificationError('Bank accounts did not load');
        reject();
      });
  });
