import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { differenceInDays, endOfDay, format, formatISO, isAfter, startOfDay, subDays } from 'date-fns'
import { Link } from 'react-router-dom';
import Report from '../../../../componentsV2/Layout/Report';
import { addLoadingList, removeLoadingList, setLoadingDescription } from '../../../../reducers/notification';
import { getRequest } from '../../../../core/apiRequests';
import { filterList } from './helpers/filterList';
import SalesExpandRow from './components/SalesExpandRow';
import { getType } from './helpers/getType';
import { getActivities } from './helpers/getActivities';
import moment from 'moment';
import useSearchParams from '../../../../hooks/useSearchParams';
import formatNumberAsCurrency from '../../../../utils/formatNumberAsCurrency';
import { useReport } from '../../../../context/ReportContext';

const Sales = ({ auth }) => {
  const options = useMemo(() => [
    {
      title: 'Activity Type',
      key: '0-0',
      value: 'activity',
      children: [
        {
          key: '0-0-0',
          title: 'Message',
          value: 'chatMessages'
        },
        {
          key: '0-0-1',
          title: 'AI Activity',
          value: 'aiActivityResult'

        },
        {
          key: '0-0-2',
          title: 'Reminder',
          value: 'reminders'
        },
      ]
    }
  ], [])
  const [filters, setFilters] = useState({
    activity: [],
    store: [],
    associate: []
  });

  const {setValue: setSearchParams, getAll} = useSearchParams();
  const params = getAll();

  useEffect(() => {
    let paramsFilters = {
      activity: params?.activity ? params?.activity : [],
      store: params?.store ? params?.store : [],
      associate: params?.associate ? params?.associate : [],
    }
    setFilters(paramsFilters)
    if(params?.startDate && params?.endDate) {
      setDateRange([new Date(params.startDate), new Date(params.endDate)])
    } else {
      setDateRange([startOfDay(subDays(new Date(), 90)), endOfDay(new Date())])
    }
  }, [window.location.search])

  const [dateRange, setDateRange] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [pageSizeTable, setPageSizeTable] = useState(10);
  const [page, setPage] = useState(1);

  const { chatMessages, isLoadingChatMessage, reminders, isLoadingReminder } = useReport();

  const getDefaultPeriod = useMemo(() => {
    if(params?.startDate && params?.endDate) {
      if(differenceInDays(new Date(params.endDate), new Date(params.startDate)) === 90) {
        return '90days'
      } else {
        return `${format(new Date(params.startDate), 'MM/dd/yyyy')} - ${format(new Date(params.endDate), 'MM/dd/yyyy')}`
      }
    } else {
      return '90days'
    }
  }, [])

  const handleOnDateRangeChange = useCallback((dates) => {
    setSearchParams('startDate', formatISO(dates[0]))
    setSearchParams('endDate', formatISO(dates[1]))
  }, []);

  const reportMessageData = useMemo(() => {
      if(!chatMessages) return []
      const { reportData } = chatMessages
      const data = reportData.filter(item => item?.sales_transaction_id && !item?.sales_transaction_amount?.includes('-'))
      return data.filter(item => item?.chat_message_id)
  }, [chatMessages])

  useEffect(() => {
    if(isLoadingChatMessage || isLoadingReminder) {
      setLoadingDescription("Sit tight! We're compiling your report.")
      addLoadingList('salesReport')
    } else {
      removeLoadingList('salesReport')
      setLoadingDescription(null)
    }
  }, [isLoadingChatMessage, isLoadingReminder])

    const reportReminderData = useMemo(() => {
      if(!reminders) return []
      const { reportData } = reminders
      return reportData.filter(item => item?.sales_transaction_id && !item?.sales_transaction_amount?.includes('-'))
    }, [reminders])

  const filteredData = useMemo(() => {
    if(reportMessageData && reportReminderData) {
      setPage(1)
      return filterList({
        data: [...reportMessageData, ...reportReminderData],
        filters,
        startDate: dateRange[0],
        endDate: dateRange[1]
      })
    }
    return []
  }, [reportMessageData, reportReminderData, filters, dateRange]);

  const handleFilterChange = useCallback((value) => {
    let updatedFilters = {
      activity: [],
      store: [],
      associate: []
    };
    value.forEach(item => {
      if (item[0] && item[1]) {
        updatedFilters[item[0]] = [...updatedFilters[item[0]], item[1]];
      } else {
        updatedFilters[item[0]] = [];
      }
    });
    Object.keys(updatedFilters).forEach(key => {
      setSearchParams(key, updatedFilters[key].join(','))
    })
  }, []);

  const removeFilter = useCallback((key, value) => {
    let updatedFilters = { ...filters };
    updatedFilters[key] = updatedFilters[key].filter(filter => filter !== value);
    Object.keys(updatedFilters).forEach(key => {
      setSearchParams(key, updatedFilters[key].join(','))
    })
  }, [filters]);

  const dataBySales = useMemo(() => {
    let data = [];
    filteredData.forEach(item => {
      const indexData = data.findIndex(dataItem => dataItem.sales_transaction_id === item.sales_transaction_id);
      if(indexData > -1) {
        if(getType(item)) {
          data[indexData].activities = {
            ...data[indexData].activities,
            [getType(item).type]: [...data[indexData].activities[getType(item).type], getType(item)]
          }
        }
      } else {
        let newData = {
          sales_transaction_amount: item.sales_transaction_amount,
          sales_transaction_id: item.sales_transaction_id,
          sales_transaction_date: item?.sales_transaction_date || item?.sales_transaction_date_time,
          sales_transaction_store_id: item.sales_transaction_store_id,
          client_id: item.client_id,
          client_name: item.client_name,
          activity_associate_name: item.activity_associate_name,
          store_name: item.activity_store_name,
          activities: {
            chatMessages: getType(item)?.type === 'chatMessages' ? [getType(item)] : [],
            reminders: getType(item)?.type === 'reminders' ? [getType(item)] : [],
          }
        }

        data.push(newData)
      }
    });

    data = data.filter(item => {
      let joinActivities = [...item.activities.chatMessages, ...item.activities.reminders]
      return filters.activity.length === 0 || joinActivities.some(activity => filters.activity.includes(activity.filter_type))
    })

    return data.map(item => ({...item, activitiesLength: item.activities.chatMessages.length + item.activities.reminders.length}))
  }, [filteredData, auth.stores, filters]);

  const dataByClientAndDate = useMemo(() => {
    let data = [];
    dataBySales.forEach(item => {
      const indexData = data.findIndex(dataItem => dataItem.client_store_date_id === `${item.client_id}-${item.sales_transaction_store_id}-${item.sales_transaction_date}`);
      if(indexData > -1) {
        data[indexData].activities.chatMessages = [...data[indexData].activities.chatMessages, ...item.activities.chatMessages];
        data[indexData].activities.reminders = [...data[indexData].activities.reminders, ...item.activities.reminders];
        data[indexData].sales_transaction_amount = data[indexData].sales_transaction_amount + parseFloat(item.sales_transaction_amount);
        data[indexData].activitiesLength += item.activitiesLength;
        data[indexData].transactions.push({
          sales_transaction_amount: item.sales_transaction_amount,
          sales_transaction_id: item.sales_transaction_id,
          sales_transaction_date: item.sales_transaction_date,
          sales_transaction_store_id: item.sales_transaction_store_id,
        })
      } else {
        let newData = {
          client_store_date_id: `${item.client_id}-${item.sales_transaction_store_id}-${item.sales_transaction_date}`,
          client_id: item.client_id,
          client_name: item.client_name,
          sales_transaction_date: item.sales_transaction_date,
          sales_transaction_amount: parseFloat(item.sales_transaction_amount),
          store_name: item.store_name,
          activities: item.activities,
          activitiesLength: item.activitiesLength,
          transactions: [
            {
              sales_transaction_amount: item.sales_transaction_amount,
              sales_transaction_id: item.sales_transaction_id,
              sales_transaction_date: item.sales_transaction_date,
              sales_transaction_store_id: item.sales_transaction_store_id,
            }
          ]
        }
        data.push(newData)
      }
    })
    return data
  }, [dataBySales])

  const dataByClient = useMemo(() => {
    let data = [];
    filteredData
    .filter(item => filters.activity.length === 0 || filters.activity.includes(getType(item)?.filter_type))
    .forEach(item => {
      const indexData = data.findIndex(dataItem => dataItem.client_id === item.client_id);
      if(indexData > -1) {
        data[indexData].transaction.push({
          sales_transaction_amount: item.sales_transaction_amount,
          sales_transaction_id: item.sales_transaction_id,
          sales_transaction_date: item?.sales_transaction_date || item?.sales_transaction_date_time,
          sales_transaction_store_id: item.sales_transaction_store_id,
        })
      } else {
        let newData = {
          client_id: item.client_id,
          client_name: item.client_name,
          activity_associate_name: item.activity_associate_name,
          transaction: [
            {
              sales_transaction_amount: item.sales_transaction_amount,
              sales_transaction_id: item.sales_transaction_id,
              sales_transaction_date: item?.sales_transaction_date || item?.sales_transaction_date_time,
              sales_transaction_store_id: item.sales_transaction_store_id,
            }
          ]
        }
        data.push(newData)
      }
    })
    return data
  }, [filteredData])

  const stats = useMemo(() => [
    {
      title: 'Related Activities',
      value: getActivities(filteredData, filters.activity).total,
      description: <span className="fs-14 mid-gray m-top-3">{`Across ${getActivities(filteredData, filters.activity).qty} activity types`}</span>
    },
    {
      title: 'Sales related to Clientbook',
      value: formatNumberAsCurrency(dataBySales?.reduce((acc, item) => acc + parseFloat(item.sales_transaction_amount), 0).toFixed(2)),
      description: <span className="fs-14 mid-gray m-top-3">{`${dataBySales.length} transactions`}</span>
    },
  ], [dataBySales, filteredData])

  const graphData = useMemo(() => {
    let data = {};
    dataBySales?.forEach(item => {
      data[item.activity_associate_name || "Unknown"] = [...data[item.activity_associate_name || "Unknown"] || [], {
        total: parseFloat(item.sales_transaction_amount).toFixed(2),
        date: item.sales_transaction_date || item.sales_transaction_date_time
      }]
    });
    return data;
  }, [dataBySales]);

  const columns = useMemo(() => {
    let storesColumn = auth?.stores?.filter(store => store.id !== 0).length > 1 ? [{
      title: 'Store',
      dataIndex: 'store_name',
      key: 'store_name',
      sorter: (a, b) => a.store_name.localeCompare(b.store_name),
    }] : [];
    return [
    {
      title: 'Date',
      dataIndex: 'sales_transaction_date',
      key: 'sales_transaction_date',
      sorter: (a, b) => isAfter(new Date(a.sales_transaction_date), new Date(b.sales_transaction_date)) ? 1 : -1,
      render: (value) => value ? moment.utc(new Date(value)).format('MMM DD, yyyy') : ' - ',
      defaultSortOrder: 'descend'
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
      sorter: (a, b) => a.client_name.localeCompare(b.client_name),
      render: (_, row) => (
        <Link to={`/Clients?client=${row.client_id}`}>
          {row.client_name}
        </Link>
      )
    },
    ...storesColumn,
    {
      title: 'Amount',
      dataIndex: 'sales_transaction_amount',
      key: 'sales_transaction_amount',
      sorter: (a, b) => a.sales_transaction_amount - b.sales_transaction_amount,
      render: (value) => formatNumberAsCurrency(value || 0),
    }
  ]}, [auth.stores]);

  const expandedRowRender = useCallback((record) => {
    return (
      <SalesExpandRow
        {...record}
      />
    );
  }, []);

  const handleExpandRow = useCallback((expand, record) => {
    let updatedExpandedRowKeys = [...expandedRowKeys];
    if (expand) {
      updatedExpandedRowKeys.push(record.client_store_date_id);
      setExpandedRowKeys(updatedExpandedRowKeys);
    } else {
      updatedExpandedRowKeys = updatedExpandedRowKeys.filter(key => key !== record.client_store_date_id);
      setExpandedRowKeys(updatedExpandedRowKeys);
    }
  }, [expandedRowKeys]);

  const loadingGraph = useMemo(() => {
    if(reminders && chatMessages) {
      return (reminders?.reportData?.length + chatMessages?.reportData?.length) > 0 ? !(Object.keys(graphData).length > 0) : true;
    } else {
      return true;
    }
  }, [reminders, chatMessages, graphData])

  return (
    <Report
      title={'Sales'}
      stats={stats}
      options={options}
      hasCSVExport
      CSVFilename={'sales.csv'}
      handleFilterChange={handleFilterChange}
      filters={filters}
      removeFilter={removeFilter}
      onDateRangeChange={handleOnDateRangeChange}
      dataRange={dateRange}
      defaultPeriodValue={getDefaultPeriod}
      graphData={(!isLoadingReminder && !isLoadingChatMessage && !loadingGraph) ? graphData :  null}
      columns={columns}
      table={dataByClientAndDate}
      stacked
      tablesProps={{
        pagination: {
          total: dataByClientAndDate.length,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSizeTable(pageSize)
          },
          page: page,
          pageSize: pageSizeTable,
        },
        rowKey: 'client_store_date_id',
        expandable: {
          expandedRowRender: (record) => expandedRowRender(record),
          defaultExpandedRowKeys: ['0'],
          onExpand: (expanded, record) => handleExpandRow(expanded, record),
          expandedRowKeys,
          rowExpandable: record => record.activitiesLength > 0,
          expandRowByClick: true
        }
      }}
      maxTicksLimit={8}
      labelLines={Object.keys(graphData).length === 0 ? 1 : Math.ceil(Object.keys(graphData).length / 9)}
    />
  )
};


const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Sales);
