import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { differenceInDays, endOfDay, format, formatISO, isAfter, startOfDay, subDays } from 'date-fns'
import { Link } from 'react-router-dom';
import Report from '../../../../componentsV2/Layout/Report';
import { filterList } from './helpers/filterList';
import AssociateExpandRow from './components/AssociateExpandRow';
import moment from 'moment';
import useSearchParams from '../../../../hooks/useSearchParams';
import formatNumberAsCurrency from '../../../../utils/formatNumberAsCurrency';
import { useReport } from '../../../../context/ReportContext';


const AssociatePerformance = ({ auth }) => {
  const options = useMemo(() => [
    {
      title: 'Activity Type',
      key: '0-0',
      value: 'activity',
      children: [
        {
          key: '0-0-0',
          title: 'Message',
          value: 'chatMessages'
        },
        {
          key: '0-0-1',
          title: 'Smart Assistant',
          value: 'aiActivityResult'

        },
        {
          key: '0-0-2',
          title: 'Reminders',
          value: 'reminders'
        },
        {
          key: '0-0-3',
          title: 'Client Activities',
          value: 'clientActivities'
        },
      ]
    }
  ], [])
  const [filters, setFilters] = useState({
    activity: [],
    store: [],
    associate: []
  });

  const {setValue: setSearchParams, getAll} = useSearchParams();
  const params = getAll();

  useEffect(() => {
    let paramsFilters = {
      activity: params?.activity ? params?.activity : [],
      store: params?.store ? params?.store : [],
      associate: params?.associate ? params?.associate : [],
    }
    setFilters(paramsFilters)
    if(params?.startDate && params?.endDate) {
      setDateRange([new Date(params.startDate), new Date(params.endDate)])
    } else {
      setDateRange([startOfDay(subDays(new Date(), 90)), endOfDay(new Date())])
    }
  }, [window.location.search])

  const [dateRange, setDateRange] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [pageSizeTable, setPageSizeTable] = useState(10);
  const [page, setPage] = useState(1);

  const { chatMessages, isLoadingChatMessage, reminders, isLoadingReminder } = useReport();

  const getDefaultPeriod = useMemo(() => {
    if(params?.startDate && params?.endDate) {
      if(differenceInDays(new Date(params.endDate), new Date(params.startDate)) === 90) {
        return '90days'
      } else {
        return `${format(new Date(params.startDate), 'MM/dd/yyyy')} - ${format(new Date(params.endDate), 'MM/dd/yyyy')}`
      }
    } else {
      return '90days'
    }
  }, [])

  const handleOnDateRangeChange = useCallback((dates) => {
    setSearchParams('startDate', formatISO(dates[0]))
    setSearchParams('endDate', formatISO(dates[1]))
  }, []);

  const reportMessageData = useMemo(() => {
    return [];
  }, [])

    const reportReminderData = useMemo(() => {
      return []
    }, [])

  const filteredData = useMemo(() => {
    if(reportMessageData && reportReminderData) {
      setPage(1)
      return filterList({
        data: [...reportMessageData, ...reportReminderData],
        filters,
        startDate: dateRange[0],
        endDate: dateRange[1]
      })
    }
    return []
  }, [reportMessageData, reportReminderData, filters, dateRange]);

  const handleFilterChange = useCallback((value) => {
    let updatedFilters = {
      activity: [],
      store: [],
      associate: []
    };
    value.forEach(item => {
      if (item[0] && item[1]) {
        updatedFilters[item[0]] = [...updatedFilters[item[0]], item[1]];
      } else {
        updatedFilters[item[0]] = [];
      }
    });
    Object.keys(updatedFilters).forEach(key => {
      setSearchParams(key, updatedFilters[key].join(','))
    })
  }, []);

  const removeFilter = useCallback((key, value) => {
    let updatedFilters = { ...filters };
    updatedFilters[key] = updatedFilters[key].filter(filter => filter !== value);
    Object.keys(updatedFilters).forEach(key => {
      setSearchParams(key, updatedFilters[key].join(','))
    })
  }, [filters]);



  const stats = useMemo(() => [
    {
      title: 'Smart Assistant Activities',
      value: `${0} of ${0}`,
      suffix: <span className="fs-14 mid-gray fw-400">{`(${0}%)`}</span>,
      description: <span className="fs-14 mid-gray m-top-3">{`${0} skipped (${0}%)`}</span>
    },
    {
      title: 'Reminders',
      value: `${0} of ${0}`,
      suffix: <span className="fs-14 mid-gray fw-400">{`(${0}%)`}</span>,
      description: <span className="fs-14 mid-gray m-top-3">{`${0} skipped (${0}%)`}</span>
    },
    {
      title: 'Messages',
      value: `${0}`,
      description: <span className="fs-14 mid-gray m-top-3">{`Sent across ${0} clients`}</span>
    },
    {
      title: 'Client activities',
      value: `${0}`,
      description: <span className="fs-14 mid-gray m-top-3">{`Across ${0} activity types`}</span>
    },
    {
      title: 'Related Sales',
      value: `$${0.00}`,
      description: <span className="fs-14 mid-gray m-top-3">{`${0} transactions`}</span>
    },
  ], [])

  const graphData = useMemo(() => {
    let data = {};
    return data;
  }, []);

  const columns = useMemo(() => {
    let storesColumn = auth?.stores?.filter(store => store.id !== 0).length > 1 ? [{
      title: 'Store',
      dataIndex: 'store_name',
      key: 'store_name',
      sorter: (a, b) => a.store_name.localeCompare(b.store_name),
    }] : [];
    return [
    {
      title: 'Date',
      dataIndex: 'sales_transaction_date',
      key: 'sales_transaction_date',
      sorter: (a, b) => isAfter(new Date(a.sales_transaction_date), new Date(b.sales_transaction_date)) ? 1 : -1,
      render: (value) => value ? moment.utc(new Date(value)).format('MMM DD, yyyy') : ' - ',
      defaultSortOrder: 'descend'
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
      sorter: (a, b) => a.client_name.localeCompare(b.client_name),
      render: (_, row) => (
        <Link to={`/Clients?client=${row.client_id}`}>
          {row.client_name}
        </Link>
      )
    },
    ...storesColumn,
    {
      title: 'Amount',
      dataIndex: 'sales_transaction_amount',
      key: 'sales_transaction_amount',
      sorter: (a, b) => a.sales_transaction_amount - b.sales_transaction_amount,
      render: (value) => formatNumberAsCurrency(value || 0),
    }
  ]}, [auth.stores]);

  const expandedRowRender = useCallback((record) => {
    return (
      <AssociateExpandRow
        {...record}
      />
    );
  }, []);

  const handleExpandRow = useCallback((expand, record) => {
    let updatedExpandedRowKeys = [...expandedRowKeys];
    if (expand) {
      updatedExpandedRowKeys.push(record.client_store_date_id);
      setExpandedRowKeys(updatedExpandedRowKeys);
    } else {
      updatedExpandedRowKeys = updatedExpandedRowKeys.filter(key => key !== record.client_store_date_id);
      setExpandedRowKeys(updatedExpandedRowKeys);
    }
  }, [expandedRowKeys]);

  return (
    <Report
      title={'Associate Performance'}
      stats={stats}
      options={options}
      hasCSVExport
      CSVFilename={'associates.csv'}
      handleFilterChange={handleFilterChange}
      filters={filters}
      removeFilter={removeFilter}
      onDateRangeChange={handleOnDateRangeChange}
      dataRange={dateRange}
      defaultPeriodValue={getDefaultPeriod}
      graphData={graphData}
      columns={columns}
      table={[]}
      stacked
      tablesProps={{
        pagination: {
          total: 0,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSizeTable(pageSize)
          },
          page: page,
          pageSize: pageSizeTable,
        },
        expandable: {
          expandedRowRender: (record) => expandedRowRender(record),
          defaultExpandedRowKeys: ['0'],
          onExpand: (expanded, record) => handleExpandRow(expanded, record),
          expandedRowKeys,
          rowExpandable: record => record?.activitiesLength > 0,
          expandRowByClick: true
        }
      }}
      maxTicksLimit={8}
    />
  )
};


const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AssociatePerformance);
