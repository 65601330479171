import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  setTablePaginationSortColumn,
  setTablePaginationAscendColumn,
  getTablePaginationData,
} from '../../../core/actions';

import './sortButton.css';

const SortButton = props => {
  const [image, setImage] = useState(
    props.ascendingColum
      ? 'https://dashboard-v2-images.s3.amazonaws.com/sort-up-dark.svg'
      : 'https://dashboard-v2-images.s3.amazonaws.com/sort-up-light.svg',
  );
  const [imageDown, setImageDown] = useState(
    props.ascendingColum
      ? 'https://dashboard-v2-images.s3.amazonaws.com/sort-down-light.svg'
      : 'https://dashboard-v2-images.s3.amazonaws.com/sort-down-dark.svg',
  );

  // pass in two variables column name, which one is currently sorted
  // if currently sorted show dark carrot
  // else, grey
  const imageStyle = () => {
    const { columnName, sortColumn, ascendingColumn } = props;
    if (columnName !== sortColumn) {
      setTablePaginationSortColumn(columnName, props.tableName);
      setTablePaginationAscendColumn(true, props.tableName);
      setImage('https://dashboard-v2-images.s3.amazonaws.com/sort-up-dark.svg');
      setImageDown('https://dashboard-v2-images.s3.amazonaws.com/sort-down-light.svg');
    } else if (ascendingColumn) {
      setTablePaginationAscendColumn(false, props.tableName);
      setImage('https://dashboard-v2-images.s3.amazonaws.com/sort-up-light.svg');
      setImageDown('https://dashboard-v2-images.s3.amazonaws.com/sort-down-dark.svg');
    } else {
      setTablePaginationAscendColumn(true, props.tableName);
      setImage('https://dashboard-v2-images.s3.amazonaws.com/sort-up-dark.svg');
      setImageDown('https://dashboard-v2-images.s3.amazonaws.com/sort-down-light.svg');
    }
  };

  const isActivelySorting = props.columnName === props.sortColumn;

  const upSrc = isActivelySorting ? image : "https://dashboard-v2-images.s3.amazonaws.com/sort-up-light.svg";
  const downSrc = isActivelySorting ? imageDown : "https://dashboard-v2-images.s3.amazonaws.com/sort-down-light.svg";

  return (
    <div style={{ display: 'flex', cursor: 'default' }}>
      <span onClick={() => imageStyle()} style={{ cursor: 'pointer' }}>
        {props.columnTitle}
      </span>
      <div onClick={() => imageStyle()} className="sortButtonDiv">
        <img className="upBtn" src={upSrc} />
        <img className="downBtn" src={downSrc} />
      </div>
    </div>
  );
};
const mapStateToProps = (state, props) => ({
  ...getTablePaginationData(state, props.tableName),
});
export default connect(mapStateToProps, {})(SortButton);
