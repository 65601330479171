import React, { useMemo } from 'react';
import TimelineItem from '../Timeline';
import moment from 'moment';


const ReminderItem = ({activity_associate_name, activity_date, completed, msg}) => {

  const title = useMemo(() => {
    return completed ? (
      <>
        <span className="fw-600">{activity_associate_name || 'An Associate'} </span>completed a reminder on
        <span className="fw-600"> {moment(activity_date).format('MMM DD, YYYY')}</span>
      </>
    ) : (
      <>
        Overdue reminder on
        <span className="fw-600"> {moment(activity_date).format('MMM DD, YYYY')}</span>
      </>
    )
  }, [activity_associate_name, ])

  return (
    <TimelineItem
      title={title}
      description={msg}
    />
  )
}

export default ReminderItem;
