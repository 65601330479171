import React from 'react';
import { CheckCircleOutlined, PlusOutlined, ShoppingOutlined /* , StopOutlined */ } from '@ant-design/icons';
import { Button } from 'antd-v5';
import './styles.scss';

const ProductsV2Sidebar = props => {
  const { vendors, selectedFilter, setSelectedFilter, showAddProductModal, setShowAddProductModal } = props;

  return (
    <div className="products-v2-sidebar">
      <div className="products-v2-sidebar__header">
        <h1 className="products-v2-sidebar__header-title">Products</h1>

        <Button type="primary" icon={<PlusOutlined />} onClick={() => setShowAddProductModal(!showAddProductModal)}>
          New
        </Button>
      </div>

      <div className="products-v2-sidebar__menu">
        <h2 className="products-v2-sidebar__menu-title">INVENTORY</h2>

        <Button
          type="text"
          icon={<ShoppingOutlined />}
          className={`products-v2-sidebar__menu-item ${
            selectedFilter === 'allProducts' ? 'products-v2-sidebar__menu-item-selected' : ''
          }`}
          onClick={() => setSelectedFilter('allProducts')}
        >
          All Products
        </Button>

        {/* <Button
          type="text"
          icon={<StopOutlined />}
          className={`products-v2-sidebar__menu-item ${
            selectedFilter === 'outOfStockItems' ? 'products-v2-sidebar__menu-item-selected' : ''
          }`}
          onClick={() => setSelectedFilter('outOfStockItems')}
        >
          Out of Stock
        </Button> */}

        <Button
          type="text"
          icon={<CheckCircleOutlined />}
          className={`products-v2-sidebar__menu-item ${
            selectedFilter === 'inStockProducts' ? 'products-v2-sidebar__menu-item-selected' : ''
          }`}
          onClick={() => setSelectedFilter('inStockProducts')}
        >
          In Stock
        </Button>
      </div>

      {vendors?.length > 0 ? (
        <div className="products-v2-sidebar__menu">
          <h2 className="products-v2-sidebar__menu-title">BRANDS</h2>

          <div className="products-v2-sidebar__menu-list">
            {vendors.map(brand => (
              <Button
                key={brand.id}
                type="text"
                className={`products-v2-sidebar__menu-item ${
                  selectedFilter === brand.id ? 'products-v2-sidebar__menu-item-selected' : ''
                }`}
                onClick={() => setSelectedFilter(brand.id)}
              >
                {brand.name}
              </Button>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductsV2Sidebar;
