import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.css';

const ChooseFileButton = props => {
  const fileInput = useRef(null);

  return (
    <div className="choose-file-div">
      <input
        onChange={props.handleFileUpload}
        accept=".csv,.xlsx,.xls"
        type="File"
        style={{ display: 'none' }}
        ref={fileInput}
      />
      <button type="button" onClick={() => fileInput.current.click()} className="choose-file-btn">
        <FontAwesomeIcon icon="fa-regular fa-upload" size="1x" />
        Choose file
      </button>
    </div>
  );
};

export default ChooseFileButton;
