import React, { Fragment, useState } from 'react';
import Table from '../../../Layout/Table';
import StyledLink from '../../../Layout/StyledLink';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MessageConfigToday from './MessageConfigToday';

const TodayRecentMessages = props => {
  const [isShown, setIsShown] = useState('');

  const rowClickHandler = () => {};

  const tableHeaders = [
    {
      keyName: 'name',
      title: 'Name',
      style: { minWidth: '135px', width: '2%', paddingTop: '0px' },
      headerClassName: '',
      rowClassName: '', // we can apply all styles to the header now.
      sortable: false, // This targets the header's className
      render: (t, o) => {
        return (
          <StyledLink classprops="blue-link" to={`/Clients?client=${o.client_id}`}>
            {t}
          </StyledLink>
        );
      },
    },
    {
      keyName: 'last_msg',
      style: { width: '30%', paddingTop: '0px', minWidth: '120px' },
      title: 'Message',
      sortable: false, // sort allows gives us the ability to sort the table
    },
    {
      keyName: '',
      style: { width: '1%', minWidth: '50px', paddingTop: '0px' },
      title: 'Message',
      headerClassName: 'align-center',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        return (
          <div className="align-center pos-rel" style={{ maxHeight: '34px', maxWidth: '87px' }}>
            <MessageConfigToday
              keyVar={Math.floor(Math.random() * o.id)}
              msgObj={o}
              setShowPopConfirm={props.setShowPopConfirm}
              initiateTemplates={props.initiateTemplates}
              setIsShown={setIsShown}
              isShown={isShown}
            >
            </MessageConfigToday>
          </div>
        );
      },
    },
    {
      keyName: 'last_sent_timestamp',
      style: { width: '2%', minWidth: '50px', paddingTop: '0px' },
      headerClassName: 'align-right p-right-15',
      title: 'Time',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        // last_sent_timestamp
        if (t) {
          return <div className="align-right p-right-15">{moment.unix(t / 1000).fromNow(true)}</div>;
        }
      },
    },
  ];

  return (
    <div className="m-top-50">
      <div className="flex-row-spacebetween-nowrap">
        <span className="fs-15 fw-500">Recent Messages</span>
        <StyledLink classprops="fs-13 blue-link fw-500" id="MyInbox" to="/Messaging/inbox">
          My Inbox
        </StyledLink>
      </div>
      {props.data.length > 0 ? (
        <Table
          data={props.data}
          tableHeaders={tableHeaders}
          tableClassName={'lg-table-sidebar  rem-pad-width'}
          optionalButton={null}
          onRowClick={rowClickHandler}
          tableName="TODAY_RECENT_MESSAGES"
        />
      ) : (
        <div className="align-center h-173 w-100-P">
          <span className="pos-rel gray fs-13" style={{ top: '45%' }}>
            No Message
          </span>
        </div>
      )}
    </div>
  );
};
export default TodayRecentMessages;
