
import React from 'react';
import { Tag } from 'antd-v5';


const ReportTagsFilters = ({
  options = [],
  filterSelected = {},
  removeFilter = () => {},
}) => {

  const tags = Object.entries(filterSelected).map(([key, value]) => {
    const findOption = options.find(option => option.value === key);
    const children = findOption?.children?.filter(child => value.includes(child?.value)) || [];
    return children?.map((child) => ({
      key: child.key,
      title: `${findOption.title} is ${child.title}`,
      valueOption: findOption.value.toString(),
      value: child.value.toString()
    }))
  })?.reduce((acc, val) => [...acc, ...val], []);

  return (
    tags.length > 0 && (
      <div className='flex-row-nospacebetween-wrap w-100-P p-top-16' style={{ gap: '16px' }}>
        {tags.map(tag => (
          <Tag
            closable
            onClose={() => removeFilter(tag.valueOption, tag.value)}
            color='blue'
            style={{ color: '#000'}}
            key={tag.key}
          >
            {tag.title}
          </Tag>
        ))}
      </div>
    )
  )
};

export default ReportTagsFilters;
