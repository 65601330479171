import React, { useCallback, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import IntegrationCard from '../Card';
import { getRequest } from '../../../../../../core/apiRequests';
import './styles.css';

const INTEGRATIONS = [
  {
    name: 'Point of Sale',
    type: 'pos',
    cards: [
      {
        id: 'lightspeed',
        title: 'Lightspeed',
        image: 'https://dashboard-v2-images.s3.amazonaws.com/lightspeed-logo.png',
        hasSettings: true,
      },
      {
        id: 'lightspeedx',
        title: 'Lightspeed series X',
        image: 'https://dashboard-v2-images.s3.amazonaws.com/lightspeed-logo.png',
        hasSettings: true,
      },
      {
        id: 'big',
        image: 'https://dashboard-v2-images.s3.amazonaws.com/big-logo.png',
        hasSettings: false,
      },
      {
        id: 'kwi',
        title: 'KWI',
        image: 'https://dashboard-v2-images.s3.amazonaws.com/kwi-logo.png',
        hasSettings: true,
      },
      {
        id: 'profitsystems',
        image: 'https://dashboard-v2-images.s3.amazonaws.com/profitsystems-logo.png',
        hasSettings: false,
      },
      {
        id: 'the-edge',
        image: 'https://dashboard-v2-images.s3.amazonaws.com/the-edge-logo.jpg',
        hasSettings: false,
      },
      {
        id: 'shopify',
        image: 'https://dashboard-v2-images.s3.amazonaws.com/shopify-logo.png',
        hasSettings: true,
      },
      {
        id: 'rain',
        image: 'https://dashboard-v2-images.s3.amazonaws.com/rain-logo.png',
        hasSettings: false,
      },
      {
        id: 'jewel-360',
        image: 'https://dashboard-v2-images.s3.amazonaws.com/jewel-360-logo.png',
        hasSettings: false,
      },
      {
        id: 'music-shop-360',
        image: 'https://dashboard-v2-images.s3.amazonaws.com/music-shop-360-logo.png',
        hasSettings: false,
      },
      {
        id: 'like-sew',
        image: 'https://dashboard-v2-images.s3.amazonaws.com/like-sew-logo.png',
        hasSettings: false,
      },
      {
        id: 'clover',
        image: 'https://dashboard-v2-images.s3.amazonaws.com/clover.png',
        hasSettings: true,
      },
    ],
  },
];

const IntegrationsCards = () => {
  const flags = useFlags();
  const [integrations, setIntegrations] = useState(INTEGRATIONS);

  const getVendorsIntegrations = useCallback(async updatedIntegrations => {
    const vendorsIntegrations = {
      name: 'Brands',
      type: 'vendor',
      cards: [],
    };
    const reqObj = {
      params: ['vendors', 'getVendors'],
      query: {
        limit: 50,
        page: 1,
      },
    };
    const request = await getRequest(reqObj);
    if(request?.data){
      vendorsIntegrations.cards = request?.data?.map(({ imageUrl, ...item }) => ({
        image: imageUrl,
        ...item,
      }));
      updatedIntegrations.push(vendorsIntegrations);
    }
    setIntegrations(updatedIntegrations);
  });

  useEffect(() => {
    const updatedIntegrations = [...integrations];
    const inboxIntegrations = {
      name: 'Unified Inbox',
      type: 'inbox',
      cards: [],
    };
    if (flags.googleIntegration) {
      inboxIntegrations.cards.push({
        id: 'google',
        image: 'https://dashboard-v2-images.s3.amazonaws.com/google-logo.png',
        hasSettings: true,
      });
    }
    if (flags.facebookIntegration) {
      inboxIntegrations.cards.push({
        id: 'facebook',
        title: 'Messenger',
        image: 'https://dashboard-v2-images.s3.amazonaws.com/messenger.png',
        hasSettings: true,
      });
    }
    updatedIntegrations.push(inboxIntegrations);
    if (flags.vendorIntegrations) {
      getVendorsIntegrations(updatedIntegrations);
    } else {
      setIntegrations(updatedIntegrations);
    }
  }, [flags]);

  return (
    <>
      {integrations.map(({ name, cards, type }) => (
        <div key={name} className="align-left">
          {cards?.length > 0 && (
            <>
              <div className="p-top-20 fs-16 fw-600">{name}</div>
              <div className="cb-integrations-cards">
                {cards.map(item => (
                  <IntegrationCard key={item.id} type={type} {...item} />
                ))}
              </div>
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default IntegrationsCards;
