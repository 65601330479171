/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import RangePicker from '../../../CustomizedAntDesign/RangePicker';
import useSearchParams from '../../../../hooks/useSearchParams';

const disabledDate = current => {
  return current && current > moment().endOf('day');
};

const CreatedDateFilter = props => {

  const {setValue: setSearchParams, getAll} = useSearchParams();
  const params = getAll();
  const [itemOpen, setItemOpen] = useState(false);
  const [lastMessageCreatedDateRange, setLastMessageCreatedDateRange] = useState([null, null]);

  useEffect(() => {
    if(params?.filterCreatedDateRange || params?.filterCreatedDateRange?.filter(item => item !== null && item !== '').length > 0) {
      const [start, end] = params?.filterCreatedDateRange;
      setLastMessageCreatedDateRange([moment(start, 'YYYYMMDD'), moment(end, 'YYYYMMDD')])
    } else {
      setLastMessageCreatedDateRange([null, null])
    }
  }, [window.location.search])

  const showClearButton = useMemo(() => params.filterCreatedDateRange || params?.filterCreatedDateRange?.some(item => item !== null), [params]);

  const handleChangeCreatedDateRange = value => {
    setSearchParams('filterCreatedDateRange', value ? `${value[0]?.format('YYYYMMDD')},${value[1]?.format('YYYYMMDD')}` : null);
  };

  const handleClear = event => {
    event.stopPropagation();
    setSearchParams('filterCreatedDateRange', false);
  };

  return (
    <div className="filter-item-container">
      <div className="filter-item-header" onClick={() => setItemOpen(!itemOpen)}>
        <div className="filter-item-header-title">Created Date</div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {showClearButton && (
            <div className="filter-item-clear" onClick={handleClear}>
              Clear
            </div>
          )}

          <FontAwesomeIcon
            icon={['far', `${itemOpen ? 'chevron-up' : 'chevron-down'}`]}
            className="fs-16"
            color="#818181"
          />
        </div>
      </div>

      {itemOpen && (
        <div className="filter-item-list-container" style={{ marginTop: '0px' }}>
          <div className="filter-item-list-toggle" style={{ width: '100%' }}>
            <RangePicker
              format="MM/DD/YYYY"
              disabledDate={disabledDate}
              value={lastMessageCreatedDateRange}
              onChange={handleChangeCreatedDateRange}
              popupStyle={{ zIndex: 10000000 }}
              showToday
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatedDateFilter;
