import React from 'react';

const TitleAndText = props => {
  const textFormat = texts => {
    if (texts.length > 1) {
      let returnArr = [];
      texts.forEach(text => {
        returnArr.push(
          <div key={text}>
            <p className="align-left align-left gray fw-500 fs-13">{text}</p>
          </div>,
        );
      });
      return returnArr;
    } else {
      return (
        <div>
          <p className="align-left align-left gray fw-500 fs-13">{texts}</p>
        </div>
      );
    }
  };
  return (
    <div className="maw-1200">
      <p className="align-left fs-16 black fw-600 "> {props.title} </p>
      {textFormat(props.text)}
    </div>
  );
};

export default TitleAndText;
