/**
 * This map exists because bluesnap's error messages include
 * different field names from the field names bluesnap requires
 * on the fields themselves. Here we map 1 name to the other,
 * for easy lookup.
 *
 * There are additional "custom" error below
 */

export const BLUESNAP_FIELD_ERRORS = {
  bankAccountNumber: 'bankingInfo.accountNumber',
  bankAccountType: 'bankingInfo.accountType',
  bankRoutingNumber: 'bankingInfo.routingNumber',
  bankState: 'bankingInfo.bankState',

  businessAddress: 'businessInfo.address',
  businessCity: 'businessInfo.city',
  businessLegalName: 'businessInfo.legalName',
  businessName: 'businessInfo.businessName',
  businessPhone: 'businessInfo.phone',
  businessProductAndServiceDesc: 'businessInfo.productAndServiceDesc',
  businessState: 'businessInfo.state',
  businessTaxId: 'businessInfo.taxId',
  businessType: 'businessInfo.businessType',
  businessWebsite: 'businessInfo.website',
  businessZip: 'businessInfo.zip',

  companyRepAddress: 'companyRep.companyRepAddress',
  companyRepCity: 'companyRep.companyRepCity',
  // companyRepCountry: 'companyRep.companyRepCountry',
  companyRepDateOfBirth: 'companyRep.companyRepDateOfBirth',
  companyRepFirstName: 'companyRep.companyRepFirstName',
  companyRepGovID: 'companyRep.companyRepGovID',
  companyRepLastName: 'companyRep.companyRepLastName',
  companyRepPhone: 'companyRep.companyRepPhone',
  companyRepState: 'companyRep.companyRepState',
  companyRepZip: 'companyRep.companyRepZip',

  owner1Address: 'ownershipInfo[1].address',
  owner1City: 'ownershipInfo[1].city',
  // owner1Country: 'ownershipInfo[1].country',
  owner1DateOfBirth: 'ownershipInfo[1].ownerDateOfBirth',
  owner1FirstName: 'ownershipInfo[1].ownerFirstName',
  owner1GovID: 'ownershipInfo[1].ownerGovID',
  owner1LastName: 'ownershipInfo[1].ownerLastName',
  owner1Phone: 'ownershipInfo[1].ownerPhone',
  owner1State: 'ownershipInfo[1].state',
  owner1Zip: 'ownershipInfo[1].zip',

  owner2Address: 'ownershipInfo[2].address',
  owner2City: 'ownershipInfo[2].city',
  // owner2Country: 'ownershipInfo[2].country',
  owner2DateOfBirth: 'ownershipInfo[2].ownerDateOfBirth',
  owner2FirstName: 'ownershipInfo[2].ownerFirstName',
  owner2GovID: 'ownershipInfo[2].ownerGovID',
  owner2LastName: 'ownershipInfo[2].ownerLastName',
  owner2Phone: 'ownershipInfo[2].ownerPhone',
  owner2State: 'ownershipInfo[2].state',
  owner2Zip: 'ownershipInfo[2].zip',

  owner3Address: 'ownershipInfo[3].address',
  owner3City: 'ownershipInfo[3].city',
  // owner3Country: 'ownershipInfo[3].country',
  owner3DateOfBirth: 'ownershipInfo[3].ownerDateOfBirth',
  owner3FirstName: 'ownershipInfo[3].ownerFirstName',
  owner3GovID: 'ownershipInfo[3].ownerGovID',
  owner3LastName: 'ownershipInfo[3].ownerLastName',
  owner3Phone: 'ownershipInfo[3].ownerPhone',
  owner3State: 'ownershipInfo[3].state',
  owner3Zip: 'ownershipInfo[3].zip',

  owner4Address: 'ownershipInfo[4].address',
  owner4City: 'ownershipInfo[4].city',
  // owner4Country: 'ownershipInfo[4].country',
  owner4DateOfBirth: 'ownershipInfo[4].ownerDateOfBirth',
  owner4FirstName: 'ownershipInfo[4].ownerFirstName',
  owner4GovID: 'ownershipInfo[4].ownerGovID',
  owner4LastName: 'ownershipInfo[4].ownerLastName',
  owner4Phone: 'ownershipInfo[4].ownerPhone',
  owner4State: 'ownershipInfo[4].state',
  owner4Zip: 'ownershipInfo[4].zip',

  pricingAgreementDate: 'merchantAgreementsSign.pricingAgreementDate',
  serviceAgreementDate: 'merchantAgreementsSign.serviceAgreementDate',
};

// We can add all the custom error strings we like here, for each field
export const BLUESNAP_CUSTOM_ERRORS = {
  bankRoutingNumber: ['Bank ID in the payout information is invalid'],
  businessLegalName: ['Name On Account \\ Account Holder Name in the payout information is invalid'],
};
