import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getShopifyUUID, shopifyLoginCheck } from '../../../../utils/redirectLogin';
import { installShopify } from '../../../Routes/Login/ServiceLogin/serviceApi';
import { setMerchantPosType } from '../../../../reducers/auth';

 
const ShopifyInstallService = (props) => {
    const history = useHistory();
    const [shopifyId, setShopifyId] = useState(null);
  
    useEffect(() => {
        if(shopifyLoginCheck()) {
            const shopifyUuid = getShopifyUUID();
            if(shopifyUuid) {
                setShopifyId(shopifyUuid);
            } else {
                console.warn("Invalid shopify uuid!", shopifyUuid)
            }
        }
    }, [shopifyLoginCheck()]);

    useEffect(() => {
        if(shopifyId && props.merchantId) {
            installProcess();
        }
    }, [shopifyId, props.merchantId])

    const installProcess = async() => {
        await installShopify(["integrations/shopify/install"], shopifyId);
        setShopifyId(null);
        setMerchantPosType("SHOPIFY");
        history.push("/help/integrations/shopify/settings");
    }

    return (
        <div />
    )
}
export default ShopifyInstallService