import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import PopMessageAddClient from '../../../Layout/PopMessage/PopMessageAddClient';
import useDebounce from '../../../../hooks/useDebounce';
import ToolTipBottom from '../../../Layout/ToolTip/ToolTipBottom';
import { Input } from 'antd-v5';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';

const ClientHeader = props => {
  const [newClient, setNewClient] = useState(false);
  const [hovering, setHovering] = useState('');

  return (
    <div style={{ padding: '0px 20px' }}>
      <div className="flex-row-spacebetween-nowrap">
        <span className="layoutTitle m-top-8 mq-fs-20 align-left flex-row-nospacebetween-nowrap">Clients</span>
        <div className="m-top-10 add-edit-wrap">
          <div
            className="clientIcon border-r4 pointer pos-rel"
            onClick={() => props.getCSVData()}
            role="button"
            tabIndex={0}
            id="new-msg-btn"
            onMouseEnter={() => setHovering('ExportClientList')}
            onMouseLeave={() => setHovering('')}
          >
            <ToolTipBottom
              isShown="ExportClientList"
              idx={hovering}
              description="Export Client List"
              toolTipWidth="125px"
              toolTipHeight="40px"
              toolTipTop="30px"
              toolTipRight="-35px"
              arrowLeft="6px"
              arrowBottom="51px"
            />
            <FontAwesomeIcon
              icon={['far', 'file-alt']}
              // size="2x"
              className="fs-16 pointer m-top-5 m-right-15"
            />
          </div>
          <div
            className="clientIcon border-r4 pointer pos-rel"
            onClick={() => props.setClientFilterOpen(!props.clientFilterOpen)}
            onKeyDown={() => props.setClientFilterOpen(!props.clientFilterOpen)}
            role="button"
            tabIndex={0}
            id="new-msg-btn"
            onMouseEnter={() => setHovering('ClientFilters')}
            onMouseLeave={() => setHovering('')}
          >
            <ToolTipBottom
              isShown="ClientFilters"
              idx={hovering}
              description="Client Filters"
              toolTipWidth="125px"
              toolTipHeight="40px"
              toolTipTop="30px"
              toolTipRight="-35px"
              arrowLeft="9px"
              arrowBottom="51px"
            />
            {!props.hasFilter ? (
              <FontAwesomeIcon icon={['far', 'filter']} size="2x" className="fs-16 pointer m-top-5 m-right-15" />
            ) : (
              <img
                src="https://dashboard-v2-images.s3.amazonaws.com/FilterActive.svg"
                alt="non-default filter icon"
                className="m-top-5 m-right-15 pointer"
                style={{ height: '16px', width: '16px' }}
              />
            )}
          </div>
          <div
            className="clientIcon border-r4 pointer pos-rel"
            onClick={() => setNewClient(true)}
            onKeyDown={() => setNewClient(true)}
            role="button"
            tabIndex={0}
            id="new-msg-btn"
            onMouseEnter={() => setHovering('AddClient')}
            onMouseLeave={() => setHovering('')}
          >
            <ToolTipBottom
              isShown="AddClient"
              idx={hovering}
              description="Add Client"
              toolTipWidth="125px"
              toolTipHeight="40px"
              toolTipTop="30px"
              toolTipRight="-35px"
              arrowLeft="6px"
              arrowBottom="51px"
            />
            <FontAwesomeIcon icon="fa-regular fa-user-plus" className="fs-16 pointer m-top-5" />
            {/* <Tooltip overlayInnerStyle={{ borderRadius: '5px' }} placement="bottom" title="Add Client">
              <FontAwesomeIcon icon="fa-regular fa-user-plus" className="fs-16 pointer m-top-5" />
            </Tooltip> */}
          </div>
        </div>
      </div>

      <div className="" />
      <Input
        className="h-45 bg-offwhite fs-13 w-100-P"
        id="client-search"
        placeholder="Name search..."
        style={{ border: 0 }}
        prefix={<SearchOutlined />}
        onChange={e => props.setSearch(e.target.value)}
        value={props.search}
        styles={{ prefix: { fontSize: '18px', marginRight: '12px', color: '#9C9C9C' }}}
        suffix={props?.loadingSearch && <LoadingOutlined />}
      />
      {newClient && (
        <PopMessageAddClient
          onClose={() => setNewClient(false)}
          newClient={newClient}
          client={props.client}
          stores={props.stores}
          iso_country_code={props.auth.iso_country_code}
          getClientData={props.getClientData}
          auth={{
            merchantId: props.auth.merchantId,
            role: props.auth.role,
            userId: props.auth.userId,
            stores: props.auth.stores,
            storeId: props.client.store_id,
            createdBy: `${props.auth.firstName || ''} ${props.auth.lastName || ''}`.trim(),
          }}
        />
      )}
    </div>
  );
};

export default ClientHeader;
