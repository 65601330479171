import React from 'react';
import './headerCard.css';

const HeaderCard = props => {
  return (
    <div
      className={
        // component has ability to override existing class.
        props.TabbedContainerClass ? `${props.TabbedContainerClass}` : 'wrap-table-container hide-scrollbar'
      }
    >
      <div className="wrap-table-header hide-scrollbar">{props.headerContent}</div>
      {props.children}
    </div>
  );
};
export default HeaderCard;
