import React, { useState } from 'react';
import { func } from 'prop-types';

import PopConfirm from '../../../Layout/PopConfirm';

const CancelPaymentRequest = ({ onConfirm, onCancel }) => {
  const [message, setMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const errorStyles =
    hasError && !isFocused
      ? {
        borderColor: '#FF7A7A',
        backgroundColor: '#F9E0E0',
      }
      : {};

  return (
    <PopConfirm
      confirm="Cancel Payment Request"
      className="pop-confirm"
      confirmButtonText="Cancel Payment"
      cancelButtonText="Close"
      customContent
      noButtonDisable
      description={
        <div>
          <p className="align-left fs-12 m-top-20">What is the reason for canceling?</p>
          <input
            className="common_inputStyle w-100-P p-top-8 p-btm-7"
            style={{ float: 'none', ...errorStyles }}
            onChange={({ target }) => setMessage(target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              setHasError(false);
              setIsFocused(false);
            }}
            value={message}
            placeholder="ex. Customer did not want items"
          />
        </div>
      }
      onConfirm={() => {
        if (message) {
          onConfirm(message);
        } else {
          setHasError(true);
        }
      }}
      onCancel={onCancel}
    />
  );
};

CancelPaymentRequest.propTypes = {
  onConfirm: func,
  onCancel: func,
};

export default CancelPaymentRequest;
