import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Table from '../../../Layout/Table';
import Layout from '../../../Layout/StandardLayout';
import Button from '../../../Layout/Button';
import TitleDescription from '../../../Layout/DescriptionBox';
import { setReminderIntv } from '../../../../reducers/reports';
import { setAuthStoreId } from '../../../../reducers/auth';
import ReusableChart from '../../../Layout/Chart';
import { getRequest } from '../../../../core/apiRequests';
import DropdownSelect from '../../../Layout/DropdownSelect';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import RangePicker from '../../../CustomizedAntDesign/RangePicker';
import '../reports.css';

const disabledDate = current => {
  return current && current > moment().endOf('day');
};

const Timeliness = props => {
  const [dateRange, setDateRange] = useState([moment().subtract(1, 'months'), moment()]);
  const [tableDef, setTableDef] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [chartConfigObj, setChartConfigObj] = useState({});

  const makeChart = goals => {
    let sumReminder = [];
    sumReminder = goals.map(gl => gl.complete).filter(item => item > 0);
    sumReminder.sort(function (a, b) {
      return b - a;
    });
    setChartConfigObj({
      type: 'bar',
      data: {
        datasets: [
          {
            label: 'On Time ',
            data: goals.map(gl => {
              return gl.complete;
            }),
            barPercentage: 0.5,
            barThickness: 20,
            backgroundColor: '#1769AF',
            order: 1,
          },
          {
            label: 'Late ',
            data: goals.map(gl => gl.days_late),
            barPercentage: 0.5,
            barThickness: 20,
            backgroundColor: '#FF6363',
            order: 1,
          },
        ],
        labels: goals.map(gl => gl.date),
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 10,
            fontSize: 12,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: true,
                padding: 8,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                min: 0,
                padding: 8,
              },
            },
          ],
        },
      },
    });
  };

  const runReport = e => {
    e.preventDefault();
    const reportEnd = moment(dateRange[1]).endOf('day').toISOString();
    const reportStart = moment(dateRange[0]).startOf('day').toISOString();
    const reqObj = {
      params: ['report', 'attributedSales'],
      query: {
        licenseKey: props.auth.licenseKey,
        interval: props.reports.reminderIntv,
        startDt: reportStart,
        endDt: reportEnd,
        storeAccess: props.auth.stores.filter(st => st.id !== 0).map(st => st.id),
      },
    };
    if (props.auth.storeId) {
      reqObj.query.storeId = props.auth.storeId;
    }
    (async () => {
      const res = {};
      res.data = await getRequest(reqObj);

      const tempTableHeaders = [
        {
          keyName: 'date',
          headerClassName: 'row1',
          rowClassName: 'col-p-l-25',
          title: 'Date',
          style: {
            width: '5%',
            paddingLeft: '20px',
            paddingTop: '80px',
            paddingRight: '20px',
          },
        },
        {
          keyName: 'days_late',
          headerClassName: 'row1',
          rowClassName: 'col-p-l-25',
          title: 'Late',
          style: {
            width: '5%',
            paddingLeft: '20px',
            paddingTop: '80px',
            paddingRight: '20px',
          },
        },
        {
          keyName: 'complete',
          headerClassName: 'row1',
          rowClassName: 'col-p-l-25',
          title: 'On Time',
          style: {
            width: '5%',
            paddingLeft: '20px',
            paddingTop: '80px',
            paddingRight: '20px',
          },
        },
      ];

      const newObj = {};
      let currentDate = moment(dateRange[0]).startOf('day');
      let notTheRealEndDate = moment(dateRange[1]);

      if (props.reports.reminderIntv === 'monthly') {
        while (currentDate.isBefore(notTheRealEndDate)) {
          newObj[currentDate.format('YYYYMM')] = {
            date: currentDate.format('YYYY MM'),
            days_late: 0,
            complete: 0,
          };
          currentDate = currentDate.add(1, 'M').startOf('month');
        }
      } else if (props.reports.reminderIntv === 'daily') {
        while (currentDate.isBefore(notTheRealEndDate)) {
          newObj[currentDate.format('YYYYMMMDDD')] = {
            date: currentDate.format('MMM D YYYY'),
            days_late: 0,
            complete: 0,
          };
          currentDate = currentDate.add(1, 'days');
        }
      } else {
        notTheRealEndDate = notTheRealEndDate.endOf('week').add(1, 'days');
        while (currentDate.isBefore(notTheRealEndDate)) {
          newObj[currentDate.format('YYYYMMMW')] = {
            date: currentDate.format('MMM D YYYY'),
            days_late: 0,
            complete: 0,
          };
          currentDate = currentDate.add(7, 'days').startOf('week');
        }
      }

      res.data.rows.forEach(rowDate => {
        if (props.reports.reminderIntv === 'monthly') {
          if (rowDate.days_late) {
            const recordComplete = Number(rowDate.complete);
            const recordLate = Number(rowDate.days_late);
            const monthLetter = moment(rowDate.date).format('YYYYMM');
            if (!recordLate && recordComplete) {
              if (newObj[monthLetter]) {
                newObj[monthLetter].complete += 1;
              }
            } else if (rowDate.complete && recordComplete) {
              if (newObj[monthLetter]) {
                newObj[monthLetter].days_late += 1;
              }
            }
          }
        } else if (props.reports.reminderIntv === 'daily') {
          if (rowDate.days_late) {
            const recordComplete = Number(rowDate.complete);
            const recordLate = Number(rowDate.days_late);
            const dayLetter = moment(rowDate.date).endOf('day').format('YYYYMMMDDD');
            if (!recordLate && recordComplete) {
              if (newObj[dayLetter]) {
                newObj[dayLetter].complete += rowDate.complete;
              }
            } else if (rowDate.complete && recordComplete) {
              if (newObj[dayLetter]) {
                newObj[dayLetter].days_late += 1;
              }
            }
          }
        } else if (rowDate.days_late) {
          const recordComplete = Number(rowDate.complete);
          const recordLate = Number(rowDate.days_late);
          const weekLetter = moment(rowDate.date).endOf('isoWeek').format('YYYYMMMW');

          if (!recordLate && recordComplete) {
            if (newObj[weekLetter]) {
              newObj[weekLetter].complete += rowDate.complete;
            }
          } else if (rowDate.complete && recordComplete) {
            if (newObj[weekLetter]) {
              newObj[weekLetter].days_late += 1;
            }
          }
        }
      });

      const renamethislater = Object.keys(newObj).map(dt => newObj[dt]);
      makeChart(renamethislater);
      setDownloadData(renamethislater);
      setTableHeaders(tempTableHeaders);
      setTableDef(renamethislater);
    })();

    setShowReport(true);
  };

  return (
    <Layout
      title="Timeliness"
      backArrow="/Home"
      description="Set your parameters and click on 'Run Report.'"
      classProps="standard-width-with-sidebar"
    >
      <div className="pos-rel w-100-P " style={{ maxWidth: '866px' }}>
        <TitleDescription
          title="Reporting Location"
          description="What store location would you like to run a report on?"
          input={
            <DropdownSelect
              isSearchable
              classProps="mq-w-200 w-267 align-left"
              options={props.auth.stores.map(store => ({
                label: store.name,
                value: store.id,
              }))}
              placeholder="Select a Store..."
              value={props.auth.storeId}
              onChange={obj => {
                setAuthStoreId(obj.value);
              }}
            />
          }
        />
        <TitleDescription
          title="Frequency"
          description="Want to run a report based on month, week, or day?"
          input={
            <DropdownSelect
              classProps="mq-w-200 w-267 align-left"
              options={[
                { label: 'Days', value: 'daily' },
                { label: 'Weeks', value: 'weekly' },
                { label: 'Months', value: 'monthly' },
              ]}
              placeholder="Select a time interval..."
              value={props.reports.reminderIntv}
              onChange={obj => {
                setReminderIntv(obj.value);
              }}
            />
          }
        />

        <TitleDescription
          title="Date Range"
          input={
            <RangePicker
              format="MM/DD/YYYY"
              disabledDate={disabledDate}
              value={dateRange}
              onChange={setDateRange}
              ranges={{
                'Last 7 Days': [moment().subtract(7, 'd'), moment()],
                'Last 30 Days': [moment().subtract(30, 'd'), moment()],
                'Last 60 Days': [moment().subtract(60, 'd'), moment()],
                'Last 90 Days': [moment().subtract(90, 'd'), moment()],
              }}
              showToday
            />
          }
          className="report-date-picker"
        />

        <div className="report-asct-msg-row" style={{ maxWidth: '866px' }}>
          <Button
            id="RunTimelinessReport"
            classname="darkBlueButton"
            onclick={e => {
              runReport(e);
            }}
          >
            Run Report
          </Button>
        </div>
      </div>
      {showReport ? (
        <div className="w-100-P h-100-P ">
          <div
            className="w-100-P h-100-P maw-1200"
            style={{
              border: 'solid 1.0px #979797',
              borderRadius: '8px',
              width: '100%',
              position: 'relative',
            }}
          >
            <div>
              <h5 style={{ textAlign: 'center' }}>Reminder Timeliness</h5>
            </div>
            <div style={{ minHeight: '200px' }}>
              <ReusableChart width="100%" height="400px" configObj={chartConfigObj} />
            </div>
          </div>
          <div
            className="import_export_group"
            style={{
              justifyContent: 'flex-end',
              maxWidth: '1105px',
              zIndex: '100',
              marginTop: '100px',
              marginRight: '30px',
            }}
          >
            <img src="https://dashboard-v2-images.s3.amazonaws.com/ic-export.svg" alt="export" />

            <CSVLink
              data={downloadData}
              filename="Timeliness.csv"
              id="DownloadTimelinessReport"
              className="exportButton m-right-15"
            >
              Download
            </CSVLink>
          </div>
          <Table
            tableClassName="maw-1200 rem-pad-width m-top-15 overflowX hide-scrollbar w-100-P"
            tableContainerClass="w-100-P rem-pad-width "
            style={{ marginTop: '-100px', width: '100%' }}
            data={tableDef}
            tableRowClassName="col-p-l-25"
            tableHeaders={tableHeaders}
            tableName="TIMLINESS"
          />
        </div>
      ) : null}
      <SecondaryPanel
        overhead_title="Reports"
        title={['CLIENTELING', 'MESSAGING', 'Google Reviews', 'PAYMENTS', 'AUTOMATION', 'SALES OPPORTUNITY']}
        current="Timeliness"
      />
    </Layout>
  );
};

const mapStateToProps = state => ({
  reports: state.reports,
  auth: state.auth,
  stores: state.stores,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Timeliness);
