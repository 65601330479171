import React from 'react';

const IntegrationsKWI = () => {
  return (
    <>
      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Overview</h3>

        <p>
          Connect your KWI account with Clientbook. This connection will effortlessly merge all of your business&apos;s
          KWI data into Clientbook&apos;s desktop and mobile platforms. Specifically, this will bring over your client,
          product and sales data. Click on Install to get started!
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Key Features</h3>

        <p>By adding your KWI information to Clientbook, you can:</p>

        <ul className="cb-integrations-details__list">
          <li>Send payment requests and track specific Life Events to maximize POS activity.</li>

          <li>Document Sales Opportunities to intensify your sales team&apos;s efficiency.</li>

          <li>Send Product Collections based on your client&apos;s tastes to effectively market your products.</li>

          <li>
            Maximize communication with your clients by creating Auto Messages. These messages can be sent automatically
            on days based on birthdays, anniversaries, special orders, sales etc.
          </li>
        </ul>
      </section>
    </>
  );
};

export default IntegrationsKWI;
