import moment from 'moment'

function fixRangeDates ([start, end]) {
  if(start && end) {
    if(parseInt(start) > parseInt(end)) {
      const year = moment().year();
      return [moment(`${start}${year}`, 'MMDDYYYY'), moment(`${end}${year + 1}`, 'MMDDYYYY')];
    } else {
      return [moment(start, 'MMDD'), moment(end, 'MMDD')];
    }
  } else {
    return [moment(start, 'MMDD'), moment(end, 'MMDD')];
  }
}

export default fixRangeDates;
