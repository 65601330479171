import React from 'react';
import { notification } from 'antd';
import { noGoodDirtyRottenChatIdQuery as nGDRCQ } from './noGoodDirtyRottenChatIdQuery';
import { eventSubject, eventReadSubject, messageStatusSubject } from '../../../../core/events';

import { setMsgClient, getClientChat, updateConversationLists } from '../../../../reducers/messaging';

import {
  CLIENT_INBOX_PATH,
  LOGIN_PATH,
  MY_CLIENT_INBOX_PATH,
  MY_INBOX_PATH,
  STORE_INBOX_PATH,
} from '../shared/constants';

import MessageNotificationMessage from '../components/MessageNotificationMessage';
import MessageNotificationDescription from '../components/MessageNotificationDescription';
import MessageNotificationButton from '../components/MessageNotificationButton';
import MessageNotificationCloseIcon from '../components/MessageNotificationCloseIcon';
import MessageNotificationIcon from '../components/MessageNotificationIcon';
import PaymentNotificationIcon from '../components/PaymentNotificationIcon';
import ConnectionNotificationCloseIcon from '../components/ConnectionNotificationCloseIcon';
import { getRequest } from '../../../../core/apiRequests';

const messageNotification = (messageObject, history, props) => {
  const { chat_message_id, client_id } = messageObject;
  const { merchantId } = props;

  const handleClick = async () => {
    const chatId = await nGDRCQ(chat_message_id, client_id, merchantId);

    if (chatId) {
      history.push(`${MY_CLIENT_INBOX_PATH}`);
      setMsgClient({ client_id, chat_id: chatId });
      getClientChat({ client_id, chat_id: chatId });
      setTimeout(() => {
        updateConversationLists({ ...messageObject, clickOnSocket: true, lastReadDateTime: 5 });
      }, 2000);
    }
  };

  notification.config({ maxCount: 5 });

  notification.open({
    top: 60,
    message: <MessageNotificationMessage message={messageObject.client_name} />,
    description: <MessageNotificationDescription description={messageObject.msg} />,
    btn: <MessageNotificationButton onClick={handleClick} />,
    closeIcon: <MessageNotificationCloseIcon />,
    icon: <MessageNotificationIcon />,
    duration: 5,
    style: {
      padding: '16px',
      fontFamily: 'Poppins',
      width: '385px',
      marginTop: '16px',
    },
    className: 'pos-rel pointer',
    onClick: handleClick,
  });
};

export const handleSetNewMessages = props => {
  const {
    pathname,
    client_id,
    clientIdAlreadyInList,
    isMyInboxMessage,
    isMyInboxConversationOpen,
    newMessages,
    setNewMessages,
    noUnreadMsgs,
  } = props;

  if (!clientIdAlreadyInList && isMyInboxMessage) {
    if (Array.isArray(newMessages)) {
      setNewMessages([...newMessages, client_id]);
    }
    setNewMessages([newMessages, client_id]);
  }
};

const paymentNotification = messageObject => {
  const handleClick = () => {
    window.location.replace(messageObject.payUrl);
  };

  notification.config({ maxCount: 5 });

  notification.open({
    top: 60,
    duration: 5,
    message: <MessageNotificationMessage message={messageObject.title} />,
    description: <MessageNotificationDescription description={messageObject.body} />,
    btn: <MessageNotificationButton onClick={handleClick} />,
    closeIcon: <MessageNotificationCloseIcon />,
    icon: <PaymentNotificationIcon />,
    style: {
      padding: '16px',
      fontFamily: 'Poppins',
      width: '385px',
      marginTop: '16px',
    },
    className: 'pos-rel pointer',
    onClick: handleClick,
  });
};

export const messageHandler = async (msgObj, history, props) => {
  try {
    const messageObject = msgObj;
    const { pathname } = window.location;
    const { newMessages, setNewMessages, userId } = props;
    const { client_id, observers, direction } = messageObject;

    if (messageObject.direction === 'INBOUND') {
      // this is for weird edge cases where stores may have the same phone number
      // or duplicate clients with a shared phone number
      const configObj = {
        params: ['chats', 'getChatClientStoreId', client_id],
        loadingStatusOff: true,
      };
      const storeObj = await getRequest(configObj); // a really fast query to get the clients store_id
      if (storeObj && storeObj.store_id) {
        // if one is found,  use it. Else use the default one
        messageObject.store_id = storeObj.store_id;
      }
    }

    if (pathname) {
      const currentPath = pathname;

      if (currentPath.includes(LOGIN_PATH)) return;

      eventSubject.next(msgObj);

      if (
        observers?.length > 0 &&
        observers.includes(userId) &&
        direction === 'INBOUND' &&
        pathname !== CLIENT_INBOX_PATH
      ) {
        if (Array.isArray(newMessages)) {
          setNewMessages([...newMessages, client_id]);
        }
        const timeout = setTimeout(() => messageNotification(messageObject, history, props), 1000);
        eventReadSubject.subscribe(clear => clear && clearTimeout(timeout));
      }
    }
  } catch (e) {
    console.error('[socketRouter] Error: ', e);
  }
};

const connectionNotification = (type, message) => {
  const durationVar = 5;
  notification.config({ maxCount: 1 });
  notification[type]({
    message,
    closeIcon: <ConnectionNotificationCloseIcon />,
    top: 60,
    style: {
      padding: '16px',
      fontFamily: 'Poppins',
      width: '385px',
      marginTop: '16px',
    },
    className: 'pos-rel pointer',
    duration: durationVar,
  });
};

export const connectionHandler = (type, message) => {
  const { pathname } = window.location;

  if (pathname) {
    const currentPath = pathname;
    if (currentPath.includes(LOGIN_PATH)) return;

    const isInboxOpen = currentPath === CLIENT_INBOX_PATH;

    if (isInboxOpen) {
      connectionNotification(type, message);
    }
  }
};

export const notificationHandler = messageObject => {
  const { category } = messageObject;

  switch (category) {
  case 'PAY_MSG':
    paymentNotification(messageObject);
    break;
  default:
    break;
  }
};

export function modifyMessageStatus(data) {
  messageStatusSubject.next(data);
}

export const socketRouter = {
  messageHandler,
  notificationHandler,
  connectionHandler,
  handleSetNewMessages,
};
