import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledLink from '../../../../Layout/StyledLink';
import Button from '../../../../Layout/Button';
import FlaggedLimitIcon from '../../../../Layout/FlaggedLimitIcon';

const FlaggedAddOppBtn = ({ clickHandler, flags }) => {
  const salesOppEnabled = flags?.sales_opps?.enabled;

  const link = !salesOppEnabled ? '#' : '/Configuration/sales-pipelines/add-sales-pipeline';
  const click = !salesOppEnabled ? () => {} : clickHandler;

  return (
    <div className="flex-row-nospacebetween-nowrap">
      <StyledLink to={link}>
        <Button
          disabled={!salesOppEnabled}
          id="AddPipelineButton"
          onclick={click}
          classname="transparentButton"
          styleProps={{ fontSize: '12px' }}
        >
          <FontAwesomeIcon
            style={{ width: '14px', height: '14px', marginBottom: '0px' }}
            icon={['far', 'code-branch']}
            className="h-50-P"
            size="1x"
          />{' '}
          Add Opportunity Type
        </Button>
      </StyledLink>
      {!salesOppEnabled && (
        <FlaggedLimitIcon message="You have reached your plan limit for Sales Opportunities. Please contact your CSM to upgrade and add more sales opportunities" />
      )}
    </div>
  );
};
export default FlaggedAddOppBtn;
