import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DatePicker from 'react-datepicker';
import {
  setMassMessageStore,
  setMassMessageAddedToCB,
  setMassMessageLastContacted,
  setMassMessageNeverContacted,
  setMassMessageAsct,
  setMassMessageContactMethod,
  setMassMessageBday,
  setMassMessagePref,
  setMassMessageAnni,
  setMassMessagePreferences,
  setMassMessageAssociates,
  setMassMessageTags,
  setMassMessageStores,
  setMassMessageTimezone,
  setMassMessageTagsCriteria,
  setMassMessage,
} from '../../../../../../reducers/massMessaging';
import { getRequest } from '../../../../../../core/apiRequests';
import 'react-datepicker/dist/react-datepicker.css';
import DropdownSelect from '../../../../../Layout/DropdownSelect';

const MessageCriteria = props => {
  const [criteriaList, setCriteriaList] = useState({
    // determines which Criteria inputs will be shown, no duplicates this way
    Tag: { show: false, addTime: '' },
    Store: { show: false, addTime: '' },
    Timezone: { show: false, addTime: '' },
    AddedToClientBook: { show: false, addTime: '' },
    Associate: { show: false, addTime: '' },
    LastContacted: { show: false, addTime: '' },
    NeverContacted: { show: false, addTime: '' },
    ContactMethod: { show: false, addTime: '' },
    Birthday: { show: false, addTime: '' },
    Anniversary: { show: false, addTime: '' },
    Preference: { show: false, addTime: '' },
  });
  const [mmPreference, setMmPreference] = useState({});

  useEffect(() => {
    if (props.merchantId) {
      const storeIds = [];
      if (props.stores.length > 1) {
        props.stores.map(store => {
          if (store.id !== 0) {
            storeIds.push(store.id);
          }
        });
      } else if (props.storeId !== 0) {
        storeIds.push(props.storeId);
      }
      const reqObj = {
        params: ['chatMessages', props.merchantId, 'config'],
        query: {
          userId: props.userId,
          role: props.role,
          storeIds: [...storeIds],
        },
      };
      (async () => {
        const dataObj = await getRequest(reqObj);
        if (dataObj) {
          const { preferences: prefs, associates: ascts, tags: tgs, stores: strs } = dataObj;

          if (prefs.length > 0) {
            const sortedPrefs = prefs.sort((a, b) => {
              if (a.name && b.name) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                }
                return 0;
              }
              return -1;
            });
            setMassMessagePreferences(sortedPrefs);
          }
          if (ascts.length > 0) {
            const joinName = ({ first_name, last_name }) => `${first_name || ''}${last_name || ''}`.toLowerCase();
            const alphabeticCompare = (a, b) => {
              const nameA = joinName(a);
              const nameB = joinName(b);
              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            };
            const sortedAscts = ascts.sort(alphabeticCompare);
            setMassMessageAssociates(sortedAscts);
          }
          if (tgs.length > 0) {
            const tagsObj = {};
            tgs.forEach(tg => {
              tagsObj[`Tag_${tg.id}`] = {
                show: false,
                name: tg.name,
                id: tg.id,
              }; // so, we basically structure this object to have a visibility flag, that's it. This visibility flag will determine if the id is to be used later
            });
            const sortedTags = tgs.sort((a, b) => {
              if (a.name && b.name) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                }
                return 0;
              }
              return 0;
            });

            setMassMessageTagsCriteria(tagsObj);
            setMassMessageTags(sortedTags);
          }
          if (strs.length > 0) {
            const sortedStores = strs.sort((a, b) => {
              if (a.name && b.name) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                }
                return 0;
              }
              return 0;
            });
            setMassMessageStores(sortedStores);
          }
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.merchantId, props.storeId, props.stores]);

  useEffect(() => {
    if (props.merchantId && props.stores.length > 1) {
      if (dateCheck()) {
        props.getClients(false, false);
      } else {
        // do nothing for now...
      }
    }
  }, [props.merchantId, props.stores]);
  // neverContacted
  useEffect(() => {
    if (criteriaList.NeverContacted.show) {
      setMassMessageNeverContacted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteriaList.NeverContacted.show]);

  const dateCheck = () => {
    const { birthdayRange, anniversaryRange, lastContacted, addedToClientbook } = props.massMessaging;
    if (
      ((anniversaryRange.start && anniversaryRange.end) || (!anniversaryRange.start && !anniversaryRange.end)) &&
      ((birthdayRange.start && birthdayRange.end) || (!birthdayRange.start && !birthdayRange.end)) &&
      ((lastContacted.start && lastContacted.end) || (!lastContacted.start && !lastContacted.end)) &&
      ((addedToClientbook.start && addedToClientbook.end) || (!addedToClientbook.start && !addedToClientbook.end))
    ) {
      return true;
    }
    return false;
  };

  const stateListHandler = (stringName, val) => {
    // this just hides the current box
    // fancy state handler for all of criteriaList
    const newState = { ...criteriaList, [`${stringName}`]: { show: val, addTime: moment(new Date()).format('x') } };
    setCriteriaList(newState);
  };

  const massMsgTags = props.massMessaging.tags.map(tg => ({
    label: tg.name,
    value: tg.id,
  }));

  const tagCriteriaHandler = () => {
    const tagCriteriaComponents = [];
    Object.values(props.massMessaging.tagsCriteria).forEach(tg => {
      const tagComponent = (
        <CriteriaItem
          stateListHandler={(tagId, boolVal) => {
            // do your own stateListHandler for tags
            setMassMessageTagsCriteria({
              ...props.massMessaging.tagsCriteria,
              [`Tag_${tagId}`]: {
                ...props.massMessaging.tagsCriteria[`Tag_${tagId}`],
                show: boolVal,
              },
            });
          }}
          key={`Tag_${tg.id}`} // key is just key
          title={tg.id}
          name="Tag"
          content={
            <DropdownSelect
              isSearchable
              selectProps={{
                maxMenuHeight: 220,
              }}
              classProps="w-267 align-left"
              options={massMsgTags}
              placeholder="Select a Tag..."
              value={tg.id}
              onChange={obj => {
                const newTagState = {
                  ...props.massMessaging.tagsCriteria,
                  [`Tag_${obj.value}`]: {
                    ...props.massMessaging.tagsCriteria[`Tag_${obj.value}`],
                    show: true,
                  },
                };
                if (obj.value !== tg.id) {
                  newTagState[`Tag_${tg.id}`] = {
                    ...props.massMessaging.tagsCriteria[`Tag_${tg.id}`],
                    show: false,
                  };
                }
                // setMassMessageTag(obj.value)
                setMassMessageTagsCriteria(newTagState);
                stateListHandler('Tag', false);
              }}
            />
          }
        />
      );
      if (tg.show) {
        tagCriteriaComponents.push({ ...tagComponent, addTime: tg.addTime });
      }
    });
    return tagCriteriaComponents;
  };

  const criteriaHandler = () => {
    // loops over all the criteriaList and constructs the input boxes that are set to true, returns content to DOM
    const criteriaOptions = [];
    let criteriaComponents = [];

    Object.keys(criteriaList).forEach(keyName => {
      if (criteriaList[keyName].show === true) {
        criteriaOptions.push({ ...criteriaList[keyName], keyName });
      }
    });
    criteriaComponents = criteriaOptions
      .sort((a, b) => a.addTime - b.addTime)
      .map(keyObj => {
        return {
          ...criteriaConstructor(keyObj.keyName),
          addTime: keyObj.addTime,
        };
      });
    return criteriaComponents;
  };

  const criteriaConstructor = keyName => {
    const {
      store,
      tag,
      associate,
      preference,
      birthdayRange,
      anniversaryRange,
      prefContact,
      stores,
      tags,
      associates,
      preferences,
      addedToClientbook,
      lastContacted,
      neverContacted,
    } = props.massMessaging;
    const criteriaObj = {
      Tag: (
        <CriteriaItem
          stateListHandler={stateListHandler}
          key={keyName}
          title="Tag"
          content={
            <DropdownSelect
              isSearchable
              selectProps={{
                maxMenuHeight: 220,
              }}
              classProps="maw-98-P w-267 align-left"
              options={tags.map(tg => ({ label: tg.name, value: tg.id }))}
              placeholder="Select a Tag..."
              value={tag}
              onChange={obj => {
                setMassMessageTagsCriteria({
                  ...props.massMessaging.tagsCriteria,
                  [`Tag_${obj.value}`]: {
                    id: obj.value,
                    show: true,
                    addTime: moment(new Date()).format('x'),
                    name: (() => {
                      let name = '';
                      const filteredTags = tags.filter(tg => tg.id === obj.value);
                      if (filteredTags.length > 0) {
                        name = filteredTags[0].name;
                      }
                      return name;
                    })(),
                  },
                });
                stateListHandler('Tag', false);
              }}
            />
          }
        />
      ),
      Store: (
        <CriteriaItem
          stateListHandler={stateListHandler}
          key={keyName}
          title="Store"
          deleteValue={() => setMassMessageStore('')}
          content={
            <DropdownSelect
              isSearchable
              selectProps={{
                maxMenuHeight: 220,
              }}
              classProps="maw-98-P w-267 align-left"
              options={stores.map(str => ({
                label: str.name,
                value: str.id,
              }))}
              placeholder="Select Store..."
              value={store}
              onChange={obj => {
                setMassMessage({ store: obj.value });
                // props.setAssociate(val)
              }}
            />
          }
        />
      ),
      AddedToClientbook: (
        <CriteriaItem
          stateListHandler={stateListHandler}
          key={keyName}
          title="Added To Clientbook"
          deleteValue={() => setMassMessageAddedToCB({ start: '', end: '' })}
          content={
            <TwoDateInputs
              // setFirstInput={valObj => setMassMessageAddedToCB({ ...addedToClientbook, ...valObj })}
              // firstInputVal={addedToClientbook.start}
              // setSecondInput={valObj => setMassMessageAddedToCB({ ...addedToClientbook, ...valObj })}

              setFirstInput={val => setMassMessageAddedToCB({ ...addedToClientbook, start: val })}
              firstInputVal={addedToClientbook.start}
              setSecondInput={val => setMassMessageAddedToCB({ ...addedToClientbook, end: val })}
              secondInputVal={addedToClientbook.end}
            />
          }
        />
      ),
      Associate: (
        <CriteriaItem
          stateListHandler={stateListHandler}
          key={keyName}
          title="Associate"
          deleteValue={() => setMassMessageAsct('')}
          content={
            <DropdownSelect
              isSearchable
              selectProps={{
                maxMenuHeight: 220,
              }}
              classProps="maw-98-P w-267 align-left"
              options={associates.map(act => ({
                label: `${act.first_name || ''} ${act.last_name || ''}`,
                value: act.id,
              }))}
              placeholder="Select Associate..."
              value={associate}
              onChange={obj => {
                setMassMessage({ associate: obj.value });
              }}
            />
          }
        />
      ),
      LastContacted: (
        <CriteriaItem
          stateListHandler={stateListHandler}
          key={keyName}
          title="Last Contacted"
          deleteValue={() => setMassMessageLastContacted({ start: '', end: '' })}
          content={
            <TwoDateInputs
              // setFirstInput={valObj => setMassMessageLastContacted({ ...lastContacted, ...valObj })}
              // firstInputVal={lastContacted.start}
              // setSecondInput={valObj => setMassMessageLastContacted({ ...lastContacted, ...valObj })}
              setFirstInput={val => setMassMessageLastContacted({ ...lastContacted, start: val })}
              firstInputVal={lastContacted.start}
              setSecondInput={val => setMassMessageLastContacted({ ...lastContacted, end: val })}
              secondInputVal={lastContacted.end}
            />
          }
        />
      ),
      // start here never contacted
      NeverContacted: (
        <CriteriaItem
          stateListHandler={stateListHandler}
          key={keyName}
          title="Never Contacted"
          deleteValue={() => setMassMessageNeverContacted(false)}
        />
      ),
      // end here
      ContactMethod: (
        <CriteriaItem
          stateListHandler={stateListHandler}
          key={keyName}
          title="Contact Method"
          deleteValue={() => setMassMessageContactMethod('')}
          content={
            <DropdownSelect
              isSearchable
              classProps="maw-98-P w-267 align-left"
              selectProps={{
                defaultOptions: false,
                maxMenuHeight: 220,
              }}
              options={[
                { label: 'EMAIL', value: 'EMAIL' },
                { label: 'SMS', value: 'SMS' },
              ]}
              placeholder="Select Contact Pref..."
              value={prefContact || null}
              onChange={obj => {
                setMassMessage({ prefContact: obj ? obj.value : null });
              }}
            />
          }
        />
      ),
      Birthday: (
        <CriteriaItem
          stateListHandler={stateListHandler}
          key={keyName}
          title="Birthday"
          deleteValue={() => setMassMessageBday({ start: '', end: '' })}
          content={
            <TwoDateInputs
              // setFirstInput={valObj =>
              //   setMassMessageBday({ ...birthdayRange, ...valObj })
              // }
              // firstInputVal={birthdayRange.start}
              // setSecondInput={valObj =>
              //   setMassMessageBday({ ...birthdayRange, ...valObj })
              setFirstInput={val => setMassMessageBday({ ...birthdayRange, start: val })}
              firstInputVal={birthdayRange.start}
              setSecondInput={val => setMassMessageBday({ ...birthdayRange, end: val })}
              secondInputVal={birthdayRange.end}
            />
          }
        />
      ),
      Anniversary: (
        <CriteriaItem
          stateListHandler={stateListHandler}
          key={keyName}
          title="Anniversary"
          deleteValue={() => setMassMessageAnni({ start: '', end: '' })}
          content={
            <TwoDateInputs
              // setFirstInput={valObj =>
              //   setMassMessageAnni({ ...anniversaryRange, ...valObj })
              // }
              // firstInputVal={anniversaryRange.start}
              // setSecondInput={valObj =>
              //   setMassMessageAnni({ ...anniversaryRange, ...valObj })
              setFirstInput={val => setMassMessageAnni({ ...anniversaryRange, start: val })}
              firstInputVal={anniversaryRange.start}
              setSecondInput={val => setMassMessageAnni({ ...anniversaryRange, end: val })}
              secondInputVal={anniversaryRange.end}
            />
          }
        />
      ),
      Preference: (
        <CriteriaItem
          stateListHandler={stateListHandler}
          key={keyName}
          title="Preference"
          deleteValue={() => setMassMessagePref('')}
          content={
            <DropdownSelect
              isSearchable
              selectProps={{
                maxMenuHeight: 220,
              }}
              classProps="maw-98-P w-267 align-left"
              options={preferences.map(prf => ({
                label: prf.name,
                value: prf.id,
              }))}
              placeholder="Select Preference..."
              value={preference}
              onChange={obj => {
                setMassMessage({ preference: obj.value });
                // props.setAssociate(val)
              }}
            />
          }
        />
      ),
    };
    return criteriaObj[keyName];
  };

  const listTitles = [
    { name: 'Added To Clientbook', id: 1 },
    { name: 'Anniversary', id: 2 },
    { name: 'Associate', id: 3 },
    { name: 'Birthday', id: 4 },
    { name: 'Contact Method', id: 5 },
    { name: 'Last Contacted', id: 6 },
    { name: 'Never Contacted', id: 7 },
    { name: 'Preference', id: 8 },
    { name: 'Store', id: 9 },
    { name: 'Tag', id: 10 },
    // "Added To Clientbook",
    // "Anniversary",
    // "Associate",
    // "Birthday",
    // "Contact Method",
    // "Last Contacted",
    // "Never Contacted",
    // "Preference",
    // "Store",
    // "Tag"
  ];

  return (
    <div className="align-left">
      <div className="m-btm-20">
        {[...criteriaHandler(), ...tagCriteriaHandler()].sort((a, b) => a.addTime - b.addTime)}
      </div>
      <div className="pos-rel">
        <DropdownSelect
          isSearchable
          selectProps={{
            maxMenuHeight: 220,
          }}
          classProps="mq-w-200 w-267 align-left"
          options={listTitles.map((ttl, i) => ({
            label: ttl.name,
            value: ttl.id,
          }))}
          value={mmPreference}
          onChange={obj => {
            stateListHandler(obj.label.split(' ').join(''), true);
          }}
          placeholder="Add Criteria"
        />
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  massMessaging: state.massMessaging,
});
export default connect(mapStateToProps, {})(MessageCriteria);

const CriteriaItem = props => {
  // make a tag criteria object??? yipes
  const closeAndReset = () => {
    props.stateListHandler(`${props.title}`.split(' ').join(''), false);
    props.deleteValue();
  };

  return (
    <div className="flex-col-left m-top-18">
      <span className="fs-14 m-btm-6">
        <FontAwesomeIcon className="m-right-8" onClick={closeAndReset} icon={['fas', 'times']} />
        {/* <button onClick={closeAndReset}>x</button> */}
        {props.name || props.title}
      </span>
      <div>{props.content}</div>
    </div>
  );
};

/// //////////////  OLD INPUT CONTENT ///////////////////
// const TwoDateInputs = props => {

//   useEffect(() => {
//     let today = moment(new Date()).format('YYYY-MM-DD');
//     let monthFromToday = moment(today, 'YYYY-MM-DD').subtract(1, 'months').format('YYYY-MM-DD');
//     props.setFirstInput({start: monthFromToday, end: today});
//   }, []);

//   return (
//     <div>
//       <div className="flex-col-left">
//         <span className="fs-10">From:</span>
//         <input
//           style={{paddingRight: '18px', paddingLeft: '18px'}}
//           className="rem-pad-width unstyledDate inputHeight common_inputStyle w-267 p-left-6 m-right-15"
//           placeholder="MM/DD"
//           type="date"
//           value={props.firstInputVal}
//           onChange={e => {
//             props.setFirstInput({start: e.target.value})
//           }}
//         />
//       </div>
//       <div className="flex-col-left">
//         <span className="fs-10">To:</span>
//         <input
//           style={{paddingRight: '18px', paddingLeft: '18px'}}
//           className="rem-pad-width unstyledDate inputHeight common_inputStyle w-267 p-left-6 "
//           placeholder="MM/DD"
//           type="date"
//           value={props.secondInputVal}
//           onChange={e => {
//             props.setSecondInput({end: e.target.value})
//           }}
//         />
//       </div>

const TwoDateInputs = props => {
  return (
    <div>
      <p>From:</p>
      <DatePicker
        className="w-267 unstyledDate inputHeight common_inputStyle p-left-18 p-right-18"
        selected={props.firstInputVal}
        onChange={date => {
          props.setFirstInput(date);
        }}
        showMonthDropdown
        showYearDropdown
        todayButton="Today"
        dropdownMode="select"
      />
      <p>To:</p>
      <DatePicker
        className="w-267 unstyledDate inputHeight common_inputStyle p-left-18 p-right-18"
        selected={props.secondInputVal}
        onChange={date => props.setSecondInput(date)}
        showMonthDropdown
        showYearDropdown
        todayButton="Today"
        dropdownMode="select"
      />
    </div>
  );
};
