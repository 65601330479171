/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { postRequest } from '../../../../../../core/apiRequests';
import { notificationError, notificationSuccess } from '../../../../../../core/actions';

const EditActivityModal = props => {
  const [notes, setNotes] = useState(props.notes || '');

  const saveActivityHandler = () => {
    const reqObj = {
      params: ['clients', 'updateClientActivity'],
      data: {
        id: props.id,
        client_id: props.clientId,
        notes,
      },
    };

    (async () => {
      const respData = await postRequest(reqObj);
      if (respData) {
        notificationSuccess('Activity Saved!');
        props.onSuccess({ id: props.id, notes });
        props.toggleEditModal(false);
      } else {
        notificationError('Failed To Save Activity');
      }
    })();
  };

  return (
    <div className="pop-confirm-background" id="modal-background">
      <div
        className="pop-confirm-container rem-pad-width"
        style={{ width: '404px', minHeight: '278px', maxHeight: '474px', overflowY: 'hidden' }}
      >
        <div
          className="flex-col-center h-100-P"
          style={{
            justifyContent: 'flex-start',
            width: '295px',
            margin: '0 auto',
          }}
        >
          <div className="flex-row-spacebetween-nowrap w-100-P m-top-15" />

          <span className="fs-18 fw-500" style={{ marginTop: '15px' }}>
            Edit Notes
          </span>

          <textarea
            style={{
              width: '295px',
              height: '188px',
              border: 'solid 1px #818181',
              borderRadius: '3px',
              margin: '21px 21px 0 21px',
              resize: 'none',
              padding: '20px 25px 3px 17px',
              boxSizing: 'border-box',
            }}
            placeholder="Add notes..."
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
          <div className="mq-w-100-vw custom-action-save-flex" style={{ marginTop: '30px' }}>
            <button className="save-button-v2 m-btm-8" onClick={saveActivityHandler}>
              Save
            </button>

            <button className="cancel-button-v2 m-btm-8" onClick={() => props.toggleEditModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditActivityModal;
