import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SavingNotification = props => {
  if (props.showSaving) {
    return (
      <div
        className="bg-blur z-index-1001 pos-abs w-100-vw h-100-vh flex-row-nospacebetween-nowrap"
        style={{ justifyContent: 'center' }}
      >
        {' '}
        {/* Full blured background */}
        <div
          className="z-index-1001 border-r14 bg-darkgray maw-340 w-100-P h-247 flex-col-center"
          style={{ width: '90%' }}
        >
          {' '}
          {/* Inner box... */}
          <div className="d-grid">
            <FontAwesomeIcon icon={['far', 'spinner-third']} spin className="blue fs-80 d-grid-stack" />
            <img
              alt="#"
              className="d-grid-stack m-left-15 m-top-15"
              style={{ marginTop: '25px', marginLeft: '30px' }}
              src="https://dashboard-v2-images.s3.amazonaws.com/clientbook-quill-blue.svg"

            />
          </div>
          <span className="fs-20 fw-600 white m-top-50">Saving...</span>
        </div>
      </div>
    );
  }
  return null;
};
const mapStateToProps = state => state.notification;
export default connect(mapStateToProps, {})(SavingNotification);
