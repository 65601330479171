import React from 'react';

const DashboardMetrics = props => {
  return (
    <div className=" p-top-26 rem-pad-width h-167 w-500 flex-row-nospacebetween-nowrap">
      <div className="align-left w-194 h-83 ">
        <span className="align-left fs-16 fw-500">{props.title}</span>
        <div className="align-left fs-13 fw-500 light-gray ">This month</div>
      </div>

      <div className="flex-row-spacebetween-nowrap  h-83 w-440">
        {props.metrics.map((met, i) => {
          return (
            <div
              key={i}
              className="flex-col-left w-100"
              // style={{ marginRight: "30%" }}
            >
              <span className="fw-500 fs-13 gray">{met.title}</span>
              <span className="fs-33 fw-600" style={met.color ? { color: met.color } : {}}>
                {met.stat}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default DashboardMetrics;
