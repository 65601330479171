import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';

const FlaggedAddCollectionButton = ({ showModal, flags }) => {
  const showWishlists = flags?.wishlists?.enabled;

  if(!showWishlists) return null;

  return (
    <Button
      type="primary"
      className="cb-button"
      icon={<FontAwesomeIcon icon={['far', 'folder-plus']} />}
      onClick={() => showModal(true)}
    >
      Add to Collection
    </Button>
  );
};
export default FlaggedAddCollectionButton;
