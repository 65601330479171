import React, { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ProductOverview from '../../Layout/ProductOverview';
import SecondaryPanel from '../../Layout/SecondaryPanel';
import Layout from '../../Layout/StandardLayout';
import TableViewSelector from './components/TableViewSelector';
import ProductsV2 from '../ProductsV2';
import './styles.css';

const Products = () => {
  const flags = useFlags();
  const [tableView, setTableView] = useState('grid');

  if (flags?.productsPage === undefined) {
    return null;
  }

  return flags.productsPage ? (
    <ProductsV2 />
  ) : (
    <Layout
      title="Products"
      classProps="standard-width-with-sidebar"
      description="Keep track of your product inventory"
    >
      <ProductOverview
        tableView={tableView}
        customToolbar={<TableViewSelector value={tableView} onChange={setTableView} />}
      />

      <SecondaryPanel title={['Manage']} current="Products" />
    </Layout>
  );
};

export default Products;
