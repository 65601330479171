import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StandardLayout from '../../../../../../Layout/StandardLayout';
import AutoReminderSettings from '../../../../../../Layout/AutoReminders/AutoReminderSettings';
import SecondaryPanel from '../../../../../../Layout/SecondaryPanel';

const SalesAutoReminder = props => {
  const history = useHistory();

  const { pipelineId } = props.match.params;
  const { listItemId } = props.match.params;

  const backUrl = `/Configuration/sales-pipelines/edit-sales-pipeline/${pipelineId}/edit-pipeline-stage/${listItemId}`;

  const setRedirectBack = () => {
    history.push(backUrl);
  };

  const reminderNameHandler = () => {
    if (props.autoRemMsgs.reminderName.length > 20) {
      return `${props.autoRemMsgs.reminderName.substring(0, 19)}...`;
    }
    return props.autoRemMsgs.reminderName;
  };

  return (
    <StandardLayout
      title={`${props.autoRemMsgs.reminderAddOrEdit} Auto Reminder`}
      description={`${props.autoRemMsgs.reminderAddOrEdit} an auto reminder that will notify the associate when the client is in this stage.`}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Sales Pipeline', to: '/Configuration/sales-pipelines' },
        { title: 'Edit Sales Pipeline', to: backUrl },
        {
          title:
            props.autoRemMsgs.reminderAddOrEdit === 'Add'
              ? `${props.autoRemMsgs.reminderAddOrEdit} Auto Reminder`
              : `${props.autoRemMsgs.reminderAddOrEdit} "${reminderNameHandler()}"`,
        },
      ]}
      classProps="standard-width-with-sidebar"
    >
      <AutoReminderSettings
        setRedirectBack={setRedirectBack}
        cancelRoute={backUrl}
        editRoute={backUrl}
        descriptionText="When do you want the associate to be reminded?"
        popConfirmDesc="This will remove the reminder from this sales pipeline"
        type="salesOpp"
        match={props.match}
      />
      <SecondaryPanel title={['Automation']} current="Sales Opportunities" />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  autoRemMsgs: state.autoRemMsgs,
});
export default connect(mapStateToProps, {})(SalesAutoReminder);
