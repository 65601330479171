import React, { useCallback, useEffect, useMemo, useState } from "react";
import StandardLayoutV2 from "../StandardLayoutV2";
import ReportStatics from "../../CustomizedAntDesign/ReportStatisc";
import ReportSelectDate from "../../CustomizedAntDesign/ReportSelectDate";
import ReportFilter from "../../CustomizedAntDesign/ReportFilter";
import ReportTagsFilters from "../../CustomizedAntDesign/ReportTagsFilters";
import { CSVLink } from "react-csv";
import { Button, Card, Table } from "antd-v5";
import { DownloadOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { getRequest } from "../../../core/apiRequests";
import ReportChartBar from "../../Chart/ReportChartBar";
import { useHistory } from "react-router";

const Report = ({
  title,
  stats,
  hasCSVExport,
  columns,
  table,
  CSVcolumns,
  CSVtable,
  CSVFilename,
  onDateRangeChange = () => {},
  options = [],
  filters = {},
  handleFilterChange = () => {},
  removeFilter = () => {},
  hasStoreFilter = true,
  hasAssociateFilter = true,
  auth,
  tablesProps = {},
  extraButtons = [],
  graphData,
  stacked = false,
  dataRange = [],
  defaultPeriodValue = '90days',
  overdueDate,
  typeDateOptions = 'past-options',
  customOptions = [],
  maxTicksLimit,
  paymentBannerActive,
  hasBanner = false,
  textBanner = '',
  labelLines = 1
}) => {

  const history = useHistory();

  useEffect(() => {
    if(auth?.launchFlagsForNav?.reportsPage !== undefined) {
      if(!auth?.launchFlagsForNav?.reportsPage) {
        history.push('/Home')
      }
    }
  }, [auth?.launchFlagsForNav?.reportsPage])

  const [associates, setAssociates] = useState([]);

  const handleDateRangeChange = useCallback((value) => {
    onDateRangeChange(value);
  }, [])

  useEffect(() => {
    if(auth.merchantId && hasAssociateFilter){
      const reqObj = {
        params: ['clients', auth.merchantId, 'tagsAndAssociates'],
      };
      getRequest(reqObj).then(response => {
        setAssociates(response[0].associates);
      })
    }
  }, [auth.merchantId, hasAssociateFilter])

  const filterOptions = useMemo(() => {
    let allStores = auth.stores.filter(store => store.id !== 0);
    let stores = hasStoreFilter && allStores.length > 1 ? [{
      title: 'Store',
      key: `0-${options.length + 1}`,
      value: 'store',
      children: allStores.map(store =>
        (
          {
            title: store.name,
            value: store.id ? `${store.id}`: '',
            key: `0-${options.length + 1}-${store.id}`
          }
        )
      )
    }] : [];
    let associatesFilters = hasAssociateFilter ? [{
      title: 'Associate',
      key: `0-${options.length + 2}`,
      value: 'associate',
      children: associates.map(associate => ({
        title: `${associate?.first_name ? `${associate.first_name} ` : ''}${associate?.last_name || ''}`,
        value: associate.id ? `${associate.id}`: '',
        key: `0-${options.length + 2}-${associate.id}`
      }))
    }] : [];
    return [
      ...options,
      ...stores,
      ...associatesFilters
    ]
  }, [auth.stores, associates, options]);


  return (
    <StandardLayoutV2
      title={title}
      breadcrumb={[
        {
          path: '/Reports',
          title: 'Reports'
        },
        {
          title: title
        }
      ]}
      classProps="flex-col-left "
      styleProps={{ justifyContent: 'flex-start' }}
      headerStyles={{
        marginTop: hasBanner ? paymentBannerActive ? '95px' : '45px' : '0'
      }}
    >
      {hasBanner && (
        <div
          style={{
            position: 'absolute',
            top: paymentBannerActive ? '105px' : '55px',
            left: '0',
            width: '100%',
            height: '65px',
            background: '#FFFBE6',
            zIndex: '2',
            display: 'flex',
            alignItems: 'center',
            padding: '0 44px',
            fontWeight: 500
          }}
        >
          {textBanner}
        </div>
      )}
      <div className='flex-row-spacebetween-nowrap w-100-P  m-top-10'>
        <div className='flex-row-center' style={{ gap: '16px'}}>
          <ReportSelectDate
            onChange={(value) => handleDateRangeChange(value)}
            defaultValue={defaultPeriodValue}
            type={typeDateOptions}
            customOptions={customOptions}
            overdueDate={overdueDate}
          />
          <ReportFilter
            options={filterOptions}
            handleFilterChange={handleFilterChange}
            filters={filters}
          />
        </div>

        <div className='flex-row-center' style={{ gap: '16px'}}>
          {extraButtons?.length > 0 && (
            extraButtons?.map((button) => (
              <Button onClick={button?.onClick} icon={button?.icon || null} size="middle">
                {button?.label}
              </Button>
            ))
          )}
          {hasCSVExport && (CSVtable?.length > 0 || table?.length > 0) && (
            <CSVLink
              data={CSVtable || table || 'No data'}
              headers={CSVcolumns || columns.map(column => ({label: column.title, key: column.key_export || column.key}))}
              filename={CSVFilename || 'report.csv'}
            >
              <Button>
                <DownloadOutlined  />
                Export
              </Button>
            </CSVLink>
          )}
        </div>
      </div>
      <ReportTagsFilters
        options={filterOptions}
        filterSelected={filters}
        removeFilter={removeFilter}
      />
      <div className="flex-row-spacebetween-nowrap w-100-P m-top-16" style={{ gap: '16px' }}>
        {stats.map(({ title, value, prefix, precision, description, ...rest }) => (
          <ReportStatics
            key={title}
            title={title}
            value={value}
            prefix={prefix}
            precision={precision}
            description={description}
            {...rest}
          />
        ))}
      </div>
      {graphData && (
        <ReportChartBar
          graphData={graphData}
          timeSpan={{ startDate: dataRange[0], endDate: dataRange[1] }}
          stacked={stacked}
          maxTicksLimit={maxTicksLimit}
          labelLines={labelLines}
        />
      )}
      {table && (
        <Card style={{ marginTop: '25px' }}>
          <Table
            columns={columns}
            dataSource={table}
            {...tablesProps}
          />
        </Card>
      )}
    </StandardLayoutV2>
  )
};


const mapStateToProps = state => ({
  auth: state.auth,
  paymentBannerActive: state.paymentBanner.active
});

export default connect(mapStateToProps)(Report);
