import Axios from "axios"
import { API_URL } from "../../../../../constants";
import { version } from '../../../../../../package.json';
import { deC, enC } from "./theCrypt";

export const Alch = async() => {
    try {
        const response = await Axios
        .get(`${API_URL}/gAlch`, {
            headers: {
                'x-clientbook-user-agent': `clbk-web|${version}|${window.navigator.platform}`,
                atx: "f6d31a3a-6bc4-4f54-a7fb"
              },
        });
        return response?.data?.alch || false;
    }
    catch(e)  {
        console.log('error', e);
        return false;
    }
}

export const gAlch = async({emailText, pwText}) => {
    try {
      const result = await Alch();
      if(result){
        const data = deC(result);
        if(data) {
          const auth = {
            username: emailText,
            password: pwText,
          };
          return enC({tKn: data, auth});
        } else {
          console.log('[gAlch] Error from Alch');
          return false;
        }
      }
      return;
    }  
    catch(e) {
        return false;
    }
  }