import moment from 'moment';

const dateFormat = dt => {
  let formattedDate = dt;
  if (formattedDate) {
    if (`${formattedDate}`.length === 3) {
      formattedDate = `0${formattedDate}`;
    } else {
      formattedDate = `${formattedDate}`;
    }
    if (`${formattedDate}`.length === 8) {
      formattedDate = `${formattedDate}`.substring(4);
    }
    if (moment(formattedDate, 'MMDD').isValid()) {
      return `${formattedDate}`;
    }
    console.error('BAD DATE FOUND ON EXPORT: ', formattedDate);
    return '';
  }
  return '';
};

export default dateFormat;
