import moment from 'moment';
import { sortByKey } from '../../utils';
import { PAYMENT_STATUS_KEY, DATE_FORMAT, DATE_RANGE, PAYMENT_STATUS } from './const';

/**
 * Helper to return usable data from a pair of dates
 * @param {moment} start - first day of date range
 * @param {moment} [end] - optional last day of date range
 * @param {string} [format] - optional format (https://momentjs.com/docs/#/displaying/format/)
 * @return {object} useful info (please continue to extend)
 */
const getDateRangeInfo = ({ start, end = start, format }) => ({
  start, // moment
  startDate: start.format(format), // formatted date
  end, // moment
  endDate: end.format(format), // formatted date
  numberOfDays: end.diff(start, 'days') + 1, // add 1, so `today - today returns 1`
});

/**
 * Get usable data from a text date range
 * @param {string} range - Date Range ID or value
 * @param {string} [opt.format] - optional `moment` format to override the default
 * @see DATE_RANGE
 */
export const getDateRange = (range, opt = {}) => {
  const { format = DATE_FORMAT.SHORT_DATE_SLASHES } = opt;

  switch (range) {
    case 'TODAY' || DATE_RANGE.TODAY:
      return getDateRangeInfo({
        start: moment(),
        format,
      });
    case 'YESTERDAY' || DATE_RANGE.YESTERDAY:
      return getDateRangeInfo({
        start: moment().subtract(1, 'day'),
        format,
      });
    case 'THIS_WEEK' || DATE_RANGE.THIS_WEEK:
      return getDateRangeInfo({
        start: moment().startOf('week'),
        end: moment(),
        format,
      });
    case 'LAST_WEEK' || DATE_RANGE.LAST_WEEK:
      return getDateRangeInfo({
        start: moment().subtract(1, 'week').startOf('week'),
        end: moment().subtract(1, 'week').endOf('week'),
        format,
      });
    case 'THIS_MONTH' || DATE_RANGE.THIS_MONTH:
      return getDateRangeInfo({
        start: moment().startOf('month'),
        end: moment(),
        format,
      });
    case 'LAST_MONTH' || DATE_RANGE.LAST_MONTH:
      return getDateRangeInfo({
        start: moment().subtract(1, 'month').startOf('month'),
        end: moment().subtract(1, 'month').endOf('month'),
        format,
      });
    case 'THIS_YEAR' || DATE_RANGE.THIS_YEAR:
      return getDateRangeInfo({
        start: moment().startOf('year'),
        end: moment(),
        format,
      });
    case 'LAST_YEAR' || DATE_RANGE.LAST_YEAR:
      return getDateRangeInfo({
        start: moment().subtract(1, 'year').startOf('year'),
        end: moment().subtract(1, 'year').endOf('year'),
        format,
      });
    case 'ONE_YEAR' || DATE_RANGE.ONE_YEAR:
      return getDateRangeInfo({
        start: moment().subtract(1, 'year').add(1, 'day'),
        end: moment(),
        format,
      });
    default:
      return {};
  }
};

// Map of Expired Statuses for BE requests & responses
/**
 * Helper to convert search params object to a string for the BE
 * @param {object} obj - key-value pairs, for getParams (values can be arrays)
 * @returns {string} search params
 */
export const getSearchParams = obj => {
  // BE does not recognize EXPIRED as a status
  const statuses = obj.statuses
    .map(status => PAYMENT_STATUS[status].filters)
    // and flatten the array
    .flat();

  const pairsList = Object.entries({
    ...obj,
    statuses,
  }).reduce((arr, [key, value]) => {
    const listForKey = Array.isArray(value)
      ? value.map(val => ({
          key: `${key}[]`,
          value: val,
        }))
      : [{ key, value }];

    return [...arr, ...listForKey];
  }, []);

  const cleanStrings = pairsList.reduce((arr, { key, value }) => {
    const pair = value ? [`${key}=${value}`] : [];
    return [...arr, ...pair];
  }, []);

  let searchParams = cleanStrings.length ? `?${cleanStrings.join('&')}` : '';
  if (Array.isArray(obj.statuses) && obj.statuses.length === 1 && obj.statuses[0] === 'EXPIRED') {
    searchParams += '&expired=true';
  }
  return searchParams;
};

export const setupFilterSelectedStatuses = paymentFilterStatuses => status => {
  // if no filters are selected, status is good
  if (!paymentFilterStatuses.length) {
    return true;
  }

  // if status appears in the list of selected filter statuses' statuses, is good
  if (
    paymentFilterStatuses
      .map(pfStatus => PAYMENT_STATUS[pfStatus].statuses)
      .flat()
      .includes(status)
  ) {
    return true;
  }

  // EXPIRED is not a BE status
  // if status is EXPIRED, but EXPIRED was not selected, status is bad
  if (status === PAYMENT_STATUS_KEY.EXPIRED && !paymentFilterStatuses.includes(PAYMENT_STATUS_KEY.EXPIRED)) {
    return false;
  }

  // EXPIRED is not a BE status
  // if EXPIRED was selected, but status is not EXPIRED (and not caught above), status is bad
  if (paymentFilterStatuses.includes(PAYMENT_STATUS_KEY.EXPIRED) && status !== PAYMENT_STATUS_KEY.EXPIRED) {
    return false;
  }

  return true;
};

// Update status for expired payments
export const getPaymentStatus = (status, hasExpired, refundDifference) => {
  return hasExpired && PAYMENT_STATUS.EXPIRED.filters.includes(status) ? PAYMENT_STATUS_KEY.EXPIRED : status;
};

// Helper to format each activity consistently
const ACTIVITY_TIME = 'time';
const formatActivity = (status, time) => ({
  status,
  [ACTIVITY_TIME]: time,
});

// Combine activities for Payment Details
export const combineActivities = ({ paymentRequest, statusChanges, linkTracking, communications, status }) => {
  // Add expired to the status if it is unpaid or failed and past expire date
  const expiredActivities = PAYMENT_STATUS.EXPIRED.statuses.includes(status)
    ? [formatActivity('EXPIRED', paymentRequest.expireAt)]
    : [];

  // Combine the statusChanges, linkTracking, and Communications arrays
  const fullList = [
    ...statusChanges.map(({ status, changeAt }) => formatActivity(status, changeAt)),
    ...linkTracking.map(({ viewedAt }) => formatActivity('VIEWED', viewedAt)),
    ...communications.map(({ createdAt }) => formatActivity('REMINDER', createdAt)),
  ];

  return [...expiredActivities, ...sortByKey(fullList, ACTIVITY_TIME)];
};

// Helper to format a date
export const formatDate = (date, format) => {
  return date ? moment(date).format(format) : undefined;
};
