import moment from 'moment';
import { dispatcher } from '../core/actions';
import store from '../core/store';
import { postRequest } from '../core/apiRequests';
import { eventReadSubject } from '../core/events';

const initialState = {
  client: {
    chat_id: 0,
    client_id: 0,
    name: '',
    store_id: 0,
    preferred_name: '',
    pos_ref_num: 0,
    WA_receive: null,
    mobile_invalid: '',
    isBlocked: '',
    merchantName: '',
    reminderId: null,
    last_sent_store_id: 0,
    sendStoreId: null,
    description: '',
    assignedAssociates: [],
  },
  dataCalledFor: null,
  clientChatMessages: [],
  myInboxChatMessages: [],
  chatMessages: [],
  emailMobile: '',
  email: null,
  mobile: null,
  clientAssociate: '',
  optOutDate: false,
  preferredContact: '',
  clientReply: '',
  reviewLink: '',
  DNC: 0,
  searchText: '',
  inboxType: 'my_inbox',
  showSearchResults: false,
  filterStore: 0,
  filterAssoc: [],
  inboxList: [],
  storeInboxList: [],
  closedInboxList: [],
  assignedAssociatesList: {},
  allAsctsList: [],
  clientPaymentStatus: [],
};

export default function reducer(state = initialState, action) {
  const state2 = state;
  const { payload } = action;
  switch (action.type) {
  case 'SET_MSG_CLIENT':
    return { ...state2, client: { ...state2.client, ...payload } };
  case 'SET_MSG_CLIENT_CLEAR':
    return { ...state2, client: {} };
  case 'SET_MSG_CLIENT_CHATMESSAGES':
    return { ...state2, clientChatMessages: payload };
  case 'SET_MSG_MYINBOX_CHATMESSAGES':
    return { ...state2, myInboxChatMessages: payload };
  case 'SET_MSG_CHATMESSAGES':
    return { ...state2, chatMessages: payload };
  case 'SET_MSG_EMAIL_MOBILE':
    return { ...state2, emailMobile: payload };
  case 'SET_MSG_EMAIL':
    return { ...state2, email: payload };
  case 'SET_MSG_MOBILE':
    return { ...state2, mobile: payload };
  case 'SET_MSG_CLIENT_ASSOCIATE':
    return { ...state2, clientAssociate: payload };
  case 'SET_MSG_OPT_OUT_DATE':
    return { ...state2, optOutDate: payload };
  case 'SET_MSG_PREFERRED_CONTACT':
    return { ...state2, preferredContact: payload };
  case 'SET_MSG_CLIENT_REPLY':
    return { ...state2, clientReply: payload };
  case 'SET_MSG_DO_NOT_CONTACT':
    return { ...state2, DNC: payload };
  case 'SET_MSG_ALL_ASCT_LIST':
    return { ...state2, allAsctsList: payload };
  case 'SET_MSG_REVIEW_LINK':
    return { ...state2, reviewLink: payload };
  case 'SET_MSG_DATA_CALLED_FOR':
    return { ...state2, dataCalledFor: payload };
  case 'SET_MSG_INBOX_LIST':
    return { ...state2, inboxList: payload };
  case 'SET_MSG_STORE_INBOX_LIST':
    return { ...state2, storeInboxList: payload };
  case 'SET_MSG_CLOSED_INBOX_LIST':
    return { ...state2, closedInboxList: payload };
  case 'SET_MSG_FILTER_STORE':
    return { ...state2, filterStore: payload };
  case 'SET_MSG_SEARCH_TEXT':
    return { ...state2, searchText: payload };
  case 'SET_INBOX_TYPE':
    return { ...state2, inboxType: payload };
  case 'SET_SHOW_SEARCH_RESULTS':
    return { ...state2, showSearchResults: payload };
  case 'SET_MSG_FILTER_ASSOC':
    return { ...state2, filterAssoc: payload };
  case 'SET_CLIENT_PAYMENT_STATUS':
    return { ...state2, clientPaymentStatus: payload };
  case 'SET_TRIGGER_REFRESH':
    return { ...state2, triggerRefresh: payload };
  case 'SET_MESSAGES_REFRESH':
    return { ...state2, messagesRefresh: payload };
  case 'SET_ASSIGNED_ASSOCIATES_LIST':
    return { ...state2, assignedAssociatesList: payload };
  case 'SET_MSG_REMINDER_ID':
    return { ...state2, client: { ...state2.client, ...{ reminderId: payload } } };
  case 'SET_MSG_REMINDER_DESCRIPTION':
    return { ...state2, client: { ...state2.client, ...{ description: payload } } };
  case 'RESET_MESSAGING':
    return initialState;
  default:
    break;
  }
  return state2;
}

export const resetMessaging = () => {
  dispatcher('RESET_MESSAGING');
};
export const setMsgClient = val => {
  dispatcher('SET_MSG_CLIENT', val);
};
export const setMsgClientChatMessages = val => {
  dispatcher('SET_MSG_CLIENT_CHATMESSAGES', val);
};
export const setMsgMyInboxChatMessages = val => {
  dispatcher('SET_MSG_MYINBOX_CHATMESSAGES', val);
};
export const setMsgChatMessages = val => {
  dispatcher('SET_MSG_CHATMESSAGES', val);
};
export const setMsgEmailMobile = val => {
  dispatcher('SET_MSG_EMAIL_MOBILE', val);
};
export const setChatReviewLink = val => {
  dispatcher('SET_MSG_REVIEW_LINK', val);
};
export const setMsgEmail = val => {
  dispatcher('SET_MSG_EMAIL', val);
};
export const setMsgMobile = val => {
  dispatcher('SET_MSG_MOBILE', val);
};
export const setClientAssociate = val => {
  dispatcher('SET_MSG_CLIENT_ASSOCIATE', val);
};
export const setMsgOptOutDate = val => {
  dispatcher('SET_MSG_OPT_OUT_DATE', val);
};
export const setMsgPreferredContact = val => {
  dispatcher('SET_MSG_PREFERRED_CONTACT', val);
};
export const setMsgClientReply = val => {
  dispatcher('SET_MSG_CLIENT_REPLY', val);
};
export const setMsgDNC = val => {
  dispatcher('SET_MSG_DO_NOT_CONTACT', val);
};
export const setMsgAllAsctsList = val => {
  dispatcher('SET_MSG_ALL_ASCT_LIST', val);
};
export const setInboxList = val => {
  dispatcher('SET_MSG_INBOX_LIST', val);
};
export const setStoreInboxList = val => {
  dispatcher('SET_MSG_STORE_INBOX_LIST', val);
};
export const setClosedInboxList = val => {
  dispatcher('SET_MSG_CLOSED_INBOX_LIST', val);
};
export const setFilterStore = val => {
  dispatcher('SET_MSG_FILTER_STORE', val);
};
export const setMsgSearchText = val => {
  dispatcher('SET_MSG_SEARCH_TEXT', val);
};
export const setInboxType = val => {
  dispatcher('SET_INBOX_TYPE', val);
};
export const setShowSearchResults = val => {
  dispatcher('SET_SHOW_SEARCH_RESULTS', val);
};
export const setFilterAssoc = val => {
  dispatcher('SET_MSG_FILTER_ASSOC', val);
};
export const setClientPaymentStatus = val => {
  dispatcher('SET_CLIENT_PAYMENT_STATUS', val);
};
export const setTriggerRefresh = val => {
  dispatcher('SET_TRIGGER_REFRESH', val);
};
export const setMessagesRefresh = val => {
  dispatcher('SET_MESSAGES_REFRESH', val);
};
export const setMsgReminderId = val => {
  dispatcher('SET_MSG_REMINDER_ID', val);
};
export const setMsgReminderDescription = val => {
  dispatcher('SET_MSG_REMINDER_DESCRIPTION', val);
};
export const setAssignedAssociatesList = val => {
  dispatcher('SET_ASSIGNED_ASSOCIATES_LIST', val);
};

export const setMsgResetClient = () => {
  dispatcher('SET_MSG_CLIENT_CLEAR');
};

export const setDataCalledFor = val => {
  dispatcher('SET_MSG_DATA_CALLED_FOR', val);
};
export const scrollToBottomOfChat = () => {
  setTimeout(() => {
    const objDiv = document.getElementById('clientChatListElement');
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }, 500);
};

const filterSafeMerge = (storeObj, associateObj) => {
  let safeCheck = true;
  const { filterStore, incStore } = storeObj;
  const { filterAssoc, observers } = associateObj;
  if (filterStore !== 0 && incStore !== filterStore) {
    safeCheck = false;
  }
  if (filterAssoc.length > 0) {
    const filterAsctObj = {};
    filterAssoc.forEach(a => (filterAsctObj[`${a}`] = true));
    const count = observers.filter(obs => filterAsctObj.hasOwnProperty(`${obs}`)).length;
    if (!count) {
      safeCheck = false;
    }
  }
  return safeCheck;
};

const updateImageChatObj = (
  filteredConversationList,
  msgClientId,
  imageUrls,
  msg,
  msgSent,
  msgStoreId,
  storeName,
  userId,
  userName,
  userImage,
  uuid,
  lastSent,
) => {
  imageUrls.forEach((image, i) => {
    filteredConversationList.unshift({
      clientId: msgClientId,
      id: msgClientId + Math.random() * i,
      imageUrl: image,
      collectionId: null,
      collectionName: null,
      message: msg === 'Image received' ? '' : msgSent,
      productId: null,
      productImage: null,
      productName: null,
      productPrice: null,
      sent: msgSent,
      sentTimestamp: lastSent,
      storeId: msgStoreId,
      storeName,
      thumbnailUrl: null,
      userId,
      userName,
      userImage: userImage || '',
      videoUrl: null,
      uuid,
      socket: true,
    });
  });
  return filteredConversationList;
};

const updateProductChatObj = (
  filteredConversationList,
  msgClientId,
  productId,
  productImage,
  productName,
  productPrice,
  msg,
  msgSent,
  msgStoreId,
  storeName,
  userId,
  userName,
  userImage,
  uuid,
) => {
  filteredConversationList.unshift({
    clientId: msgClientId,
    id: msgClientId + Math.random() * productId,
    imageUrl: null,
    collectionId: null,
    collectionName: null,
    message: msg == 'Image received' ? '' : msgSent,
    // msg: msg,
    productId,
    productImage,
    productName,
    productPrice,
    sent: msgSent,
    storeId: msgStoreId,
    storeName,
    thumbnailUrl: null,
    userId,
    userName,
    userImage: userImage || '',
    videoUrl: null,
    uuid,
    socket: true,
  });
  return filteredConversationList;
};

export const selfAssignConversation = (clientId, assignedAssociates) => {
  const storeState = store.getState();
  const conversation = storeState.messaging.storeInboxList.find(item => item.client.id === clientId);
  conversation.client.assignedAssociates = assignedAssociates;
  const tmpClosedInbox = [];
  let wasInserted = false;
  storeState.messaging.inboxList.forEach(item => {
    if (conversation.lastSentTimestamp > item.lastSentTimestamp && !wasInserted) {
      tmpClosedInbox.push(conversation);
      wasInserted = true;
    }
    tmpClosedInbox.push(item);
  });
  if (!wasInserted) {
    tmpClosedInbox.push(conversation);
  }
  setInboxList(tmpClosedInbox);
};

export const selfUnassignConversation = (clientId, assignedAssociates) => {
  const storeState = store.getState();
  const conversation = storeState.messaging.storeInboxList.find(item => item.client.id === clientId);
  conversation.client.assignedAssociates = assignedAssociates;
  setInboxList(storeState.messaging.inboxList.filter(item => item.client.id !== clientId));
};

export const closeConversation = chatId => {
  const { messaging } = store.getState();
  const conversation =
    messaging.inboxList.find(item => item.id === chatId) ?? messaging.storeInboxList.find(item => item.id === chatId);
  setInboxList(messaging.inboxList.filter(item => item.id !== chatId));
  setStoreInboxList(messaging.storeInboxList.filter(item => item.id !== chatId));
  const tmpClosedInbox = [];
  let wasInserted = false;
  messaging.closedInboxList.forEach(item => {
    if (conversation.lastSentTimestamp > item.lastSentTimestamp && !wasInserted) {
      tmpClosedInbox.push({ ...conversation, isOpen: false });
      wasInserted = true;
    }
    tmpClosedInbox.push(item);
  });
  if (!wasInserted) {
    tmpClosedInbox.push({ ...conversation, isOpen: false });
  }
  setClosedInboxList(tmpClosedInbox);
};

export const openConversation = chatId => {
  const { messaging, auth } = store.getState();
  const conversation = messaging.closedInboxList.find(item => item.id === chatId);
  setClosedInboxList(messaging.closedInboxList.filter(item => item.id !== chatId));
  const tmpMyInbox = [];
  let wasInserted = false;
  if (conversation.client.assignedAssociates?.includes(auth.userId)) {
    messaging.inboxList.forEach(item => {
      if (conversation.lastSentTimestamp > item.lastSentTimestamp && !wasInserted) {
        tmpMyInbox.push({ ...conversation, isOpen: true });
        wasInserted = true;
      }
      tmpMyInbox.push(item);
    });
    if (!wasInserted) {
      tmpMyInbox.push({ ...conversation, isOpen: true });
    }
    setInboxList(tmpMyInbox);
  }
  const tmpStoreInbox = [];
  wasInserted = false;
  messaging.storeInboxList.forEach(item => {
    if (conversation.lastSentTimestamp > item.lastSentTimestamp && !wasInserted) {
      tmpStoreInbox.push({ ...conversation, isOpen: true });
      wasInserted = true;
    }
    tmpStoreInbox.push(item);
  });
  if (!wasInserted) {
    tmpStoreInbox.push({ ...conversation, isOpen: true });
  }
  setStoreInboxList(tmpStoreInbox);
};

export const insertMyInboxList = msgObj => {
  const storeState = store.getState();
  const { store_id: msgStoreId, client_id: msgClientId, store_name: storeName, image_url } = msgObj;
  const lastSent = moment(new Date()).valueOf();
  if (storeState.hasOwnProperty('messaging') && storeState.hasOwnProperty('auth')) {
    const { messaging, auth } = storeState;
    if (messaging.hasOwnProperty('chatMessages') && auth.hasOwnProperty('stores')) {
      const { chatMessages: myInboxChatMessages } = messaging;
      if (!msgObj?.messageUUID || storeState.messaging.chatMessages[0].uuid !== msgObj?.messageUUID) {
        const filteredChatList = myInboxChatMessages.sort((a, b) => b.sent_timestamp - a.sent_timestamp);
        if (image_url?.length > 0) {
          image_url.forEach(image => {
            const imageObj = {
              id: msgObj.chat_message_id,
              imageUrl: image,
              message: null,
              sentTimestamp: lastSent,

              sent: moment(lastSent).format('YYYYMMDDHHmmss'),
              lastSentTimestamp: lastSent,
              userId: msgObj.user_id,
              userImage: msgObj.user_image,
              userName: msgObj.user_name,
              storeId: msgStoreId,
              storeName,
              chatId: storeState.messaging.client.chat_id,
              clientId: msgClientId,
              uuid: msgObj.messageUUID,
              direction: msgObj.direction,
              clientReply: lastSent,
              name: msgObj.client_name,
              associateCount: msgObj.observers.length,
            };
            filteredChatList.unshift(imageObj);
          });
        }
        if (msgObj?.products?.length > 0) {
          msgObj.products.forEach(product => {
            const productObj = {
              id: msgObj.chat_message_id,
              productId: product.product_id,
              productImage: product.product_image,
              productName: product.product_name,
              productPrice: product.product_price,
              message: null,
              sentTimestamp: lastSent,
              sent: moment(lastSent).format('YYYYMMDDHHmmss'),
              lastSentTimestamp: lastSent,
              userId: msgObj.user_id,
              userImage: msgObj.user_image,
              userName: msgObj.user_name,
              storeId: msgStoreId,
              storeName,
              chatId: storeState.messaging.client.chat_id,
              client_id: msgClientId,
              uuid: msgObj.messageUUID,
              direction: msgObj.direction,
              client_reply: lastSent,
              name: msgObj.client_name,
              associateCount: msgObj.observers.length,
            };
            filteredChatList.unshift(productObj);
          });
        }
        if (msgObj?.collections?.length > 0) {
          msgObj.collections.forEach(collection => {
            const collectionObj = {
              id: msgObj.chat_message_id,
              collectionId: collection.collection_id,
              collectionName: collection.collection_name,
              message: null,
              sentTimestamp: lastSent,
              sent: moment(lastSent).format('YYYYMMDDHHmmss'),
              lastSentTimestamp: lastSent,
              userId: msgObj.user_id,
              userImage: msgObj.user_image,
              userName: msgObj.user_name,
              storeId: msgStoreId,
              storeName,
              chatId: storeState.messaging.client.chat_id,
              clientId: msgClientId,
              uuid: msgObj.messageUUID,
              direction: msgObj.direction,
              clientReply: lastSent,
              name: msgObj.client_name,
              associateCount: msgObj.observers.length,
            };
            filteredChatList.unshift(collectionObj);
          });
        }
        if (msgObj.msg) {
          const chatObj = {
            direction: msgObj.direction,
            clientId: msgClientId,
            chatId: storeState.messaging.client.chat_id,
            storeId: msgStoreId,
            message: msgObj.msg === 'Image received' ? '' : msgObj.msg,
            lastReadDateTime: null,
            clientReply: lastSent,
            sentTimestamp: lastSent,
            sent: moment(lastSent).format('YYYYMMDDHHmmss'),
            lastSentTimestamp: lastSent,
            userId: msgObj.user_id,
            userName: msgObj.client_name,
            name: msgObj.client_name,
            user_name: msgObj.user_name,
            id: msgObj.chat_message_id,
            associateCount: msgObj.observers?.length ?? 0,
          };
          filteredChatList.unshift(chatObj);
        }
        setMsgChatMessages([...filteredChatList]);
        eventReadSubject.next(true);
      } else {
        console.log('Client conversation unrelated to incoming socket msg');
      }
    } else {
      console.log('<<< ERROR: messaging or auth reducer missing properties! >>>');
    }
  } else {
    console.log('<<< ERROR storeState missing properties!!! >>> ');
  }
};

export const getClientChat = async cli => {
  const storeState = store.getState();
  const { auth, messaging } = storeState;
  setMsgChatMessages([]);
  setMsgClient({});
  if (cli.client_id) {
    let reqObj = {};
    reqObj = {
      params: ['chatMessages', auth.allStoreConversationHistory ? 'list' : 'clientData'],
      data: {
        selectedStoreId: auth.selectedStore,
        chatId: cli.chat_id,
        clientId: cli.client_id,
      },
    };
    const dataArr = await postRequest(reqObj);
    if (dataArr.length > 0) {
      const dataObj = dataArr[0];
      setMsgPreferredContact(`${dataObj.preferred_contact_type || ''}`);
      setMsgDNC(dataObj.do_not_contact);
      const msgClient = {
        chat_id: dataObj.chatId,
        client_id: dataObj.client_id,
        name: dataObj.name,
        store_id: dataObj.store_id,
        preferred_name: dataObj.preferred_name,
        WA_receive: dataObj.WA_receive,
        pos_ref_num: dataObj.pos_ref_num,
        preferred_contact_type: dataObj.preferred_contact_type,
        mobile_invalid: dataObj.mobile_invalid,
        isBlocked: dataObj.isBlocked,
        merchantName: dataObj.merchantName,
        assignedAssociates: dataObj.assigned_associate_id,
        last_sent_store_id: dataObj.last_sent_store_id,
        type: cli.type,
      };
      // eslint-disable-next-line no-unused-expressions
      dataObj.isOpen != null && (msgClient.isOpen = dataObj.isOpen);
      setMsgClient(msgClient);
      const topReply = dataObj.messages.filter(message => message.WA_receive !== null);
      // eslint-disable-next-line no-unused-expressions
      topReply.length > 0 ? setMsgClientReply(topReply[0].WA_receive) : setMsgClientReply('');
      if (dataObj.opt_out_date) {
        setMsgOptOutDate(true);
      } else {
        setMsgOptOutDate(false);
      }
      if (auth.allStoreConversationHistory) {
        setMsgChatMessages(dataObj.messages ?? []);
      } else if (cli.chat_id === dataObj.chatId) {
        setMessagesRefresh(!messaging.messagesRefresh);
      }
      setDataCalledFor(cli.client_id);
      // eslint-disable-next-line no-prototype-builtins
      if (dataObj && (dataObj.hasOwnProperty('email') || dataObj.hasOwnProperty('mobile'))) {
        setMsgEmail(`${dataObj.email.trim() || ''}`);
        setMsgMobile(`${dataObj.mobile.trim() || ''}`);
      } else {
        setMsgEmail('');
        setMsgMobile('');
      }
    }
  } else {
    setMsgPreferredContact(`${cli.preferred_contact_type || ''}`);
    setMsgDNC(cli.do_not_contact);
    setMsgClient({
      ...cli,
      store_id: cli.storeId,
    });
    if (cli && (cli.hasOwnProperty('email') || cli.hasOwnProperty('mobile'))) {
      setMsgEmail(`${cli?.email?.trim() || ''}`);
      setMsgMobile(`${cli?.mobile?.trim() || ''}`);
    } else {
      setMsgEmail('');
      setMsgMobile('');
    }
  }
};

export const updateConversationLists = incMsgObj => {
  const incClientId = incMsgObj.client_id;
  const storeState = store.getState();
  let updatedMyInbox;
  let updatedStoreInbox;
  let updateWhatsappTime;
  const newMyInbox = [];
  const newStoreInbox = [];
  if (storeState.messaging && storeState.auth) {
    const { messaging, auth } = storeState;
    const { userId } = auth;
    const { filterStore, filterAssoc } = messaging;
    if (incMsgObj.observers.includes(userId) || incMsgObj?.user_id === userId) {
      messaging.inboxList.forEach(currMsg => {
        if (incClientId === currMsg?.client?.id) {
          updatedMyInbox = true;
          if (currMsg.preferred_contact_type === 'WHATSAPP' && incMsgObj.direction === 'INBOUND') {
            updateWhatsappTime = true;
          }
          newMyInbox.unshift({
            ...currMsg,
            lastReadDateTime: incMsgObj.lastReadDateTime || null,
            lastMessage: incMsgObj.msg,
            lastSentTimestamp: moment(new Date()),
            WA_receive: updateWhatsappTime ? moment(new Date()) : currMsg.WA_receive,
            clientReply: incMsgObj.clickOnSocket ? 3 : currMsg.client_reply,
            isOpen: true,
            userId: incMsgObj.user_id,
            isUnread: incMsgObj.direction === 'INBOUND' ? true : currMsg?.isUnread,
          });
        } else {
          newMyInbox.push(currMsg);
        }
      });
      if (!updatedMyInbox) {
        newMyInbox.unshift(newObjCreator(incMsgObj));
      }
      setInboxList(newMyInbox);
      if (updateWhatsappTime) {
        setMsgClient({ WA_receive: moment(new Date()) });
      }
    }
    setClosedInboxList(messaging.closedInboxList.filter(item => item.client.id !== incMsgObj.client_id));
    const storeObj = { filterStore, incStore: incMsgObj.store_id };
    const observerObj = { filterAssoc, observers: incMsgObj.observers };
    if (filterSafeMerge(storeObj, observerObj)) {
      messaging.storeInboxList.forEach(currMsg => {
        if (incClientId === currMsg?.client?.id) {
          updatedStoreInbox = true;
          newStoreInbox.unshift({
            ...currMsg,
            lastReadDateTime: incMsgObj.lastReadDateTime || null,
            lastMessage: incMsgObj.msg,
            lastSentTimestamp: moment(new Date()),
            client_reply: incMsgObj.clickOnSocket ? 3 : currMsg.client_reply,
            isOpen: true,
            userId: incMsgObj.user_id,
            isUnread: incMsgObj.direction === 'INBOUND' ? true : currMsg?.isUnread,
          });
        } else {
          newStoreInbox.push(currMsg);
        }
      });
      if (!updatedStoreInbox) {
        newStoreInbox.unshift(newObjCreator(incMsgObj));
      }
    } else {
      newStoreInbox.push(...messaging.storeInboxList);
    }
  }
  setStoreInboxList(newStoreInbox);
};

export const newObjCreator = msg => {
  return {
    WA_receive: null,
    chat_id: null,
    id: null,
    chat_message_id: msg.chat_message_id,
    client_id: msg.client_id,
    clientReply: msg.direction === 'INBOUND' ?? moment(new Date()),
    clientReplyDateTime: msg.direction === 'INBOUND' ?? moment(new Date()),
    image: msg?.image_url?.length > 0 ? msg.image_url[0] : null,
    lastMessage: msg.msg,
    lastReadDateTime: msg.direction === 'OUTBOUND' ?? moment(new Date()),
    lastSentTimestamp: moment(new Date()),
    name: msg.client_name,
    pos_ref_num: null,
    preferredContactType: 'SMS',
    preferred_name: msg.client_name,
    store_id: msg.store_id,
    isOpen: msg.isOpen ?? true,
    client: {
      id: msg.client_id,
      name: msg.client_name,
      preferredName: msg.client_name,
      clientReply: msg.direction === 'INBOUND' ?? moment(new Date()),
      assignedAssociates: msg.observers,
    },
  };
};

const updateChatObj = (
  msgClientId,
  imageUrls,
  msg,
  msgSent,
  msgStoreId,
  storeName,
  userId,
  userName,
  userImage,
  uuid,
) => {
  let chatObj = {};
  chatObj = {
    clientId: msgClientId,
    id: msgClientId + Math.random() * Math.random(),
    imageUrl: null,
    collectionId: null,
    collectionName: null,
    message: msg,
    productId: null,
    productImage: null,
    productName: null,
    productPrice: null,
    sent: msgSent,
    storeId: msgStoreId,
    storeName,
    thumbnailUrl: null,
    userId,
    userName,
    userImage: userImage || '',
    videoUrl: null,
    uuid,
    socket: true,
  };
  return chatObj;
};

export const insertMyInboxConversation = msgObj => {
  const storeState = store.getState();
  let message = msgObj.msg;
  const {
    store_id: msgStoreId,
    store_name: storeName,
    client_id: msgClientId,
    user_name: userName,
    user_image: userImage,
    sent: msgSent,
    uuid,
    messageUUID,
    image_url: imageUrls,
    direction,
  } = msgObj;
  // clean up messages
  if (message === 'undefined' || !message) {
    message = null;
  }
  // clean up images
  const imageCheck = conversation => {
    let imageNotIncludedInArray = false;
    if (imageUrls && conversation.image_url) {
      if (imageUrls.includes(conversation.image_url)) {
        imageNotIncludedInArray = true;
      }
    } else if ((!imageUrls || imageUrls.length == 0) && conversation.image_url == null) {
      return true;
    }
    return imageNotIncludedInArray;
  };
  const lastSent = moment(new Date()).valueOf();
  if (storeState.hasOwnProperty('messaging') && storeState.hasOwnProperty('auth')) {
    const { messaging, auth } = storeState;
    if (messaging.hasOwnProperty('chatMessages') && auth.hasOwnProperty('stores')) {
      const { chatMessages } = messaging;
      const { userId } = auth;
      // if (pathname.includes(msgClientId)) {
      const filteredConversationList = chatMessages;
      // ALWAYS SET CHAT OBJ FOR INBOUND MESSAGES (AKA FROM THE CLIENT)
      if (direction === 'INBOUND') {
        if (
          (message && ((Array.isArray(imageUrls) && imageUrls.length === 0) || !imageUrls)) ||
          (Array.isArray(imageUrls) && imageUrls.length > 0 && message !== 'Image received')
        ) {
          const chatObj = updateChatObj(
            msgClientId,
            imageUrls,
            message,
            msgSent,
            msgStoreId,
            storeName,
            userId,
            userName,
            userImage,
            messageUUID,
          );
          filteredConversationList.unshift(chatObj);
        }
        if (imageUrls.length > 0) {
          updateImageChatObj(
            filteredConversationList,
            msgClientId,
            imageUrls,
            message,
            msgSent,
            msgStoreId,
            storeName,
            // userId,
            msgClientId,
            userName,
            userImage,
            messageUUID,
            lastSent,
          );
        }
        setMsgChatMessages(filteredConversationList);
        // else check if direction is outbound and if message has already been posted by another associate
      } else if (direction == 'OUTBOUND') {
        const cutFilteredConvo = filteredConversationList.slice(0, 15);
        let shouldWeShift = true;
        cutFilteredConvo.forEach(conversation => {
          if (
            conversation.user_id === msgObj.user_id &&
            conversation.sent === msgObj.sent &&
            conversation.message == message
          ) {
            shouldWeShift = false;
          }
          if (conversation.user_id == msgObj.user_id && !conversation.socket) {
            const images = imageCheck(conversation);
            if (conversation.message == message && images) {
              shouldWeShift = false;
            }
            if (msgObj.hasOwnProperty('products') && Array.isArray(msgObj.products) && msgObj.products.length > 0) {
              shouldWeShift = true;
            }
          }
        });
        if (shouldWeShift) {
          if (message) {
            const chatObj = updateChatObj(
              msgClientId,
              imageUrls,
              message,
              msgSent,
              msgStoreId,
              storeName,
              userId,
              userName,
              userImage,
              messageUUID,
            );
            filteredConversationList.unshift(chatObj);
          }
          if (imageUrls.length > 0) {
            updateImageChatObj(
              filteredConversationList,
              msgClientId,
              imageUrls,
              message,
              msgSent,
              msgStoreId,
              storeName,
              userId,
              userName,
              userImage,
              messageUUID,
            );
          }
          if (msgObj.hasOwnProperty('products')) {
            if (msgObj.products.length > 0) {
              msgObj.products.forEach(product => {
                updateProductChatObj(
                  filteredConversationList,
                  msgClientId,
                  product.product_id,
                  product.product_image,
                  product.product_name,
                  product.product_price,
                  message,
                  msgSent,
                  msgStoreId,
                  storeName,
                  userId,
                  userName,
                  userImage,
                  messageUUID,
                );
              });
            }
          }
          setMsgChatMessages(filteredConversationList);
        }
      }
    } else {
      console.log('<<< ERROR: messaging or auth reducer missing properties! >>>');
    }
  } else {
    console.log('<<< ERROR storeState missing properties!!! >>> ');
  }
};
