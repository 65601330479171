import moment from 'moment';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Drawer } from 'antd';
import { connect } from 'react-redux';
import { getTablePaginationData, notificationSuccess, setTablePaginationAscendColumn } from '../../../core/actions';
import { getRequest, postRequest } from '../../../core/apiRequests';
import { setAuthPayments } from '../../../reducers/auth';
import { setTodayClientChat, setTodayTableData, setTodayTempTableData } from '../../../reducers/today';
import ViewAllTemplates from '../../Layout/AutoWhatsAppTemplate/ViewAllTemplates';
import DropDownSelect from '../../Layout/DropdownSelect';
import PopConfirm from '../../Layout/PopConfirm';
import PopMessagePayments from '../../Layout/PopMessage/PopMessagePayment';
import PopMessageTemplates from '../../Layout/PopMessage/PopMessageTemplate';
import SmallTable from '../../Layout/SmallTable';
import Layout from '../../Layout/StandardLayout';
import ConversationWindow from '../../Layout/chat/chat';
import useActionTextAreaFiles, {
  actionTextAreaFileActions,
} from '../../Layout/TextAreaWithActions/useActionTextAreaFiles';
import WhatsAppModal from '../Clients/ClientDetails/Modals/WhatsAppModal';
import TodayAutoReminders from './children/TodayAutoReminders';
import TodayPayments from './children/TodayPayments';
import TodayRecentMessages from './children/TodayRecentMessages';
import TodaySalesOpportunities from './children/TodaySalesOpportunites';
import {
  setMsgChatMessages,
  setMsgClient,
  setClientAssociate,
  setMsgResetClient,
  getClientChat,
  resetMessaging,
  setChatReviewLink,
} from '../../../reducers/messaging';
import { associateNameFormat } from '../../../utils';
import getClientPaymentStatus from '../../Layout/GetClientPaymentStatus';
import { useOnce } from '../../../hooks/useOnce';
import { logRocketEvent } from '../../../utils/logRocketIdentify';

const Today = props => {
  const [rems, setRems] = useState([]);
  const [msgs, setMsgs] = useState([]);
  const [opps, setOpps] = useState([]);
  const [salesStages, setSalesStages] = useState([]);
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [msgText, setMsgText] = useState('');
  const [fileData, fileDispatch] = useActionTextAreaFiles();
  const [templateModal, setTemplateModal] = useState(false);
  const [allTemplates, setAllTemplates] = useState([]);
  const [chatIconColor] = useState('#EBEBEB');
  const [selected, setSelected] = useState({
    id: '',
    name: '',
    substituion: '',
    template: '',
  });
  const [inputObj, setInputObj] = useState({});
  const isMounted = useRef(null);
  const [showWhatsAppTemp, setShowWhatsAppTemp] = useState(false);
  const [templateObj, setTemplateObj] = useState({
    WA_receive: '',
    preferredContact: '',
    client_id: '',
    client_name: '',
    store_id: 0,
  });
  const [templatePopup, showTemplatePopup] = useState(false);
  const [paymentsPopup, showPaymentsPopup] = useState(false);
  const [tempConfigId] = useState('');
  const [paymentRequestID, setPaymentRequestID] = useState('');
  const [allClientReminders, setAllClientReminders] = useState(false);
  const [assignPopup, setAssignPopup] = useState(false);
  const [allAssoc, setAllAssoc] = useState([]);
  const [associate, setAssociate] = useState();
  const [selectedAction, setSelectedAction] = useState([]);
  const [paymentsStatusRaw, setPaymentsStatusRaw] = useState({});
  const timeOffset = moment(new Date()).utcOffset();
  const [dismissPopup, setDismissPopup] = useState(false);
  const [singleRem, setSingleRem] = useState();
  const [loadingMessages, setLoadingMessages] = useState(false);

  const getTodayInformation = useCallback(async () => {
    isMounted.current = true;
    setLoadingMessages(true);

    try {
      if(props.auth.merchantId) {

        const reqObj = {
          params: ['chatMessages', props.auth.merchantId, 'allTemplates'],
        };
        const dataArr = await getRequest(reqObj);
        if (dataArr.length > 0) {
          setAllTemplates([...dataArr]);
        }
        
        const reqObj2 = {
          params: ['chatMessages', props.auth.merchantId, 'paymentsVerify'],
        };
        const dataArr2 = await getRequest(reqObj2);
        if (dataArr2.length > 0) {
          setAuthPayments(dataArr2[0].cnt);
        }
        getTodayPayments();
      }

    } catch (error) {
      //
    } finally {
      setLoadingMessages(false);
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  const { call } = useOnce(getTodayInformation);
  useEffect(() => {
    call();
    logRocketEvent('TodayScreen');
  }, [props.auth.merchantId]);

  useEffect(() => {
    isMounted.current = true;
    if (props.auth.merchantId) {
      const reqObj = {
        params: ['report', props.auth.merchantId, props.auth.storeId || 0, 'clientByAction'],
        query: {
          userId: props.auth.userId,
          role: props.auth.role,
        },
      };
      const assoc_data = async () => {
        const data = await getRequest(reqObj);
        const sortedAscts = data.sort((a, b) => {
          if (`${a.first_name || ''}${a.last_name || ''}` > `${b.first_name || ''}${b.last_name}`) {
            return 1;
          }
          if (`${a.first_name || ''}${a.last_name || ''}` < `${b.first_name || ''}${b.last_name}`) {
            return -1;
          }
          return 0;
        });
        setAllAssoc([...sortedAscts]);
        setAssociate(props.auth.userId);
      };
      assoc_data();
      return () => {
        isMounted.current = false;
      };
    }
  }, [props.auth.storeId]);

  const getReviewLink = async storeId => {
    if (storeId) {
      const reqObj = {
        params: ['chats', storeId, 'getReviewLink'],
      };
      const linkData = await getRequest(reqObj);
      if (linkData.length > 0) {
        const { review_link } = linkData[0];
        setChatReviewLink(review_link);
      }
    } else {
      console.error('Unable to getReviewLink, NO store ID!');
    }
  };

  const setSelectedClient = async cli => {
    if (!cli.store_id) {
      console.log('ALERT NO STORE ID: ', cli);
    }
    await getClientChat(cli);
    await getReviewLink(cli.store_id);
    setClientAssociate(cli.associateCount);
    getClientPaymentStatus({ client: cli, auth: props.auth });
    setShowPopConfirm(true);
  };

  const openChatModal = client => {
    setSelectedClient(client);
  };

  const getTodayData = () => {
    setSelectedAction([]);
    const reqObj = {
      params: ['today', props.auth.merchantId, props.auth.userId, 'getAll'],
      query: {
        sortColumn: '',
        ascending: 'false',
        allClientReminders,
      },
    };
    if (props.auth.merchantId && props.auth.userId) {
      (async () => {
        const data = await getRequest(reqObj);
        if (data) {
          const dataObj = data[0];
          const { actions, messages, salesOpps, salesStages: stages } = dataObj;
          setRems(actions);
          setMsgs(messages);
          setOpps(salesOpps);
          setSalesStages(stages);
          // setTablePaginationAscendColumn(false, props.pag);
          setTablePaginationAscendColumn(false, 'TODAY_AUTO_REMINDERS');
        }
      })();
    }
  };

  const getTodayPayments = () => {
    const { sortColumn, ascendingColumn } = props.pagination;
    const reqObj = {
      params: ['today', props.auth.merchantId, props.auth.userId, 'getTodayPayments'],
      query: {
        sortColumn: '',
        ascending: 'false',
        limitNum: 'LIMIT 5',
      },
    };
    (async () => {
      const data = await getRequest(reqObj);
      const tempStatusArr = [];
      if (data.length > 0) {
        tempStatusArr.push(...data[0].viewed);
        if (sortColumn === 'datetime') {
          setPaymentsStatusRaw(tempStatusArr);
        } else {
          const oldDateToNewDate = tempStatusArr.sort((a, b) => b[sortColumn] - a[sortColumn]);
          setPaymentsStatusRaw(oldDateToNewDate);
        }
        setTablePaginationAscendColumn(false, 'TODAY_PAYMENTS');
      }
    })();
  };

  useEffect(() => {
    const { sortColumn, ascendingColumn } = props.pagination;
    if (props.auth.merchantId && props.pag === 'TODAY_PAYMENTS') {
      const reqObj = {
        params: ['today', props.auth.merchantId, props.auth.userId, 'getTodayPayments'],
        query: {
          sortColumn: sortColumn || 'datetime',
          ascending: ascendingColumn,
          limitNum: 'LIMIT 5',
        },
      };
      const getData = async () => {
        const data = await getRequest(reqObj);
        const tempStatusArr = [];
        if (data.length > 0) {
          tempStatusArr.push(...data[0].viewed);
          if (sortColumn === 'datetime') {
            setPaymentsStatusRaw(tempStatusArr);
          } else {
            const oldDateToNewDate = tempStatusArr.sort((a, b) => b[sortColumn] - a[sortColumn]);
            setPaymentsStatusRaw(oldDateToNewDate);
          }
        }
      };
      getData();
      return () => {
        isMounted.current = false;
      };
    }
    // here
    if (props.auth.merchantId && props.pag === 'TODAY_AUTO_REMINDERS') {
      const reqObj = {
        params: ['today', props.auth.merchantId, props.auth.userId, 'getAll'],
        query: {
          sortColumn,
          ascending: ascendingColumn,
          allClientReminders,
        },
      };
      const data = async () => {
        const d = await getRequest(reqObj);
        if (d) {
          const dataObj = d[0];
          const { actions, messages, salesOpps, salesStages: stages } = dataObj;
          setRems(actions);
          setMsgs(messages);
          setOpps(salesOpps);
          setSalesStages(stages);
        }
      };
      data();
      return () => {
        isMounted.current = false;
      };
    }
  }, [props.pagination.sortColumn, props.pagination.ascendingColumn, props.pag, allClientReminders]);

  const ViewAllTempTextSelect = id => {
    const filteredTemplate = allTemplates.filter(template => template.id === id);
    if (filteredTemplate.length > 0) {
      setAllTemplateSelects(filteredTemplate[0]);
    }
  };

  const setAllTemplateSelects = template => {
    setSelected(template);
    const subsObj = {};
    if (template.substitution) {
      const substitutes = template.substitution.split(',');
      substitutes.forEach(sub => {
        const propertyDivide = sub.split('=');
        if ((propertyDivide.length = 2)) {
          subsObj[propertyDivide[0]] = {
            type: propertyDivide[1].toLowerCase(),
            value: '',
          };
        }
      });
      setInputObj(subsObj);
    }
  };

  const setClientTemplate = () => {
    setShowWhatsAppTemp(!showWhatsAppTemp);
    setTemplateModal(true);
  };

  const closeTheStuff = () => {
    setShowWhatsAppTemp(false);
    setTemplateModal(false);
    setShowPopConfirm(false);
  };

  useEffect(() => {
    isMounted.current = true;
    if (props.auth.merchantId && props.today.todayClientChat.clientRecipient) {
      (async () => {
        const reqObj = {
          params: ['chatMessages', props.auth.merchantId, 'allTemplates', props.today.todayClientChat.clientRecipient],
        };
        const data = await getRequest(reqObj);
        if (data) {
          setTodayTableData(data);
          setTodayTempTableData(data);
        }
      })();
    }
    return () => {
      isMounted.current = false;
    };
  }, [props.today.todayClientChat.clientRecipient, tempConfigId]);

  useEffect(() => {
    if (templatePopup) {
      setShowPopConfirm(false);
    }
    if (paymentsPopup) {
      setShowPopConfirm(false);
    }
  }, [templatePopup, paymentsPopup]);

  const lilTemplateHeader = [
    {
      keyName: 'msg',
      style: { width: '100%', paddingRight: '25px', minWidth: '75px' },
      headerClassName: 'no-text-select',
      title: '',
    },
  ];

  const handleSearchChange = value => {
    const lVal = value.toLowerCase();
    const filtered = props.today.tableData.filter(data => {
      if (data.msg.toLowerCase().includes(lVal)) {
        return true;
      }
      return false;
    });
    if (value.length > 0) {
      // setTableData([...filtered]);
      setTodayTableData([...filtered]);
    }
  };

  const templateReplace = message => {
    const splitName = props.today.todayClientChat.name.split(' ');
    const replaced = message
      .replace(':name:', props.client.preferred_name || splitName[0])
      .replace(':associate:', props.auth.firstName)
      .replace(':review:', props.today.todayClientChat.reviewLink);
    setMsgText(replaced);
  };

  const assignAssociate = () => {
    setAssignPopup(false);
    let reqObj;
    if (singleRem) {
      // const singleR = [singleRem];
      reqObj = {
        params: ['today', props.auth.merchantId, 'assignAssociate'],
        data: { selectedAction: singleRem },
        query: {
          assignedAssociate: associate,
          storeId: props.auth.storeId,
          associateId: props.auth.userId,
        },
      };
    } else {
      reqObj = {
        params: ['today', props.auth.merchantId, 'assignAssociate'],
        data: { selectedAction },
        query: {
          assignedAssociate: associate,
          storeId: props.auth.storeId,
          associateId: props.auth.userId,
        },
      };
    }
    (async () => {
      const data = await postRequest(reqObj);
      if (data) {
        setSelectedAction([]);
        getTodayData();
        setSingleRem([]);
        notificationSuccess('Reminder(s) Assigned!');
      }
    })();
  };

  const dismissSelected = () => {
    const reqObj = {
      params: ['today', props.auth.merchantId, 'dismissSelected'],
      query: {
        associateName: associateNameFormat(props.auth.firstName, props.auth.lastName),
        associateId: props.auth.userId,
      },
      data: { selectedAction },
    };
    (async () => {
      const responseObj = await postRequest(reqObj);
      if (responseObj) {
        setSelectedAction([]);
        setDismissPopup(false);
        getTodayData();
        notificationSuccess('Reminder(s) Dismissed!');
      }
    })();
  };

  const closeChat = () => {
    setShowPopConfirm(false);
    setMsgResetClient();
    resetMessaging();
  };
  return (
    <Layout
      title="Today"
      description="A detailed report of today’s activity"
      classProps="standard-width-wo-sidebar"
      // breadCrumbs={[{ title: "Stores" }]}
      // styleProps={{ marginLeft: 79 }}
    >
      <div>
        <TodayAutoReminders
          getTodayData={getTodayData}
          setShowPopConfirm={openChatModal}
          merchantId={props.auth.merchantId}
          data={rems}
          initiateTemplates={openChatModal}
          storeId={props.auth.storeId}
          createdBy={associateNameFormat(props.auth.firstName, props.auth.lastName)}
          allClientReminders={allClientReminders}
          setAllClientReminders={setAllClientReminders}
          setAssignPopup={setAssignPopup}
          setSelectedAction={setSelectedAction}
          selectedAction={selectedAction}
          dismissPopup={dismissPopup}
          setDismissPopup={setDismissPopup}
          setSingleRem={setSingleRem}
          singleRem={singleRem}
          setSelectedClient={setSelectedClient}
          allAssoc={allAssoc}
        />
        <TodaySalesOpportunities
          data={opps}
          salesStages={salesStages}
          salesName={props.auth.merchantConfig.SALES_OPP_NAME || 'Sales Opportunities'}
        />
        <TodayRecentMessages
          getTodayData={getTodayData}
          setShowPopConfirm={openChatModal}
          merchantId={props.auth.merchantId}
          data={msgs}
          initiateTemplates={openChatModal}
        />
        <TodayPayments
          setShowPopConfirm={openChatModal}
          merchantId={props.auth.merchantId}
          data={paymentsStatusRaw}
          initiateTemplates={openChatModal}
          storeId={props.auth.storeId}
          createdBy={associateNameFormat(props.auth.firstName, props.auth.lastName)}
          getTodayData={getTodayData}
        />
      </div>
      {/* HERE */}
      <Drawer
        bodyStyle={{
          padding: 0,
          overflow: 'hidden',
        }}
        closable={false}
        push={false}
        destroyOnClose
        width={750}
        open={showPopConfirm}
        onClose={closeChat}
      >
        <ConversationWindow
          type="right"
          loading={loadingMessages}
          chatIconColor={chatIconColor}
          setDrawerToggle={closeChat}
        />
      </Drawer>

      {showWhatsAppTemp ? (
        <ViewAllTemplates
          toggleViewAll={setShowWhatsAppTemp}
          setSelected={ViewAllTempTextSelect}
          // saveHandler={setShowMessage, setShowWhatsAppTemp}
          saveHandler={setClientTemplate}
          selected={selected.id}
          clientConversation
        />
      ) : null}
      {templateModal ? (
        <WhatsAppModal
          closeTheStuff={closeTheStuff}
          // showMessageHandler={showMessageHandler}
          selected={selected}
          setSelected={setSelected}
          inputObj={inputObj}
          setInputObj={setInputObj}
          WA_receive={templateObj.WA_receive}
          preferred_contact_type={templateObj.preferredContact}
          client_id={templateObj.client_id}
          client_name={templateObj.client_name}
          store_id={templateObj.store_id}
          associate={props.auth.firstName}
        />
      ) : null}
      {templatePopup ? (
        <PopMessageTemplates
          popStyle={{
            minWidth: '300px',
            maxWidth: '500px',
          }}
          confirm="Templates"
          customContent
          noButtonDisable
          onCancel={() => {
            showTemplatePopup(false);
            setShowPopConfirm(true);
          }}
          description={
            <div className="m-top-15">
              <input
                onKeyDown={e => handleSearchChange(e.target.value)}
                className="searchInput h-45 bg-offwhite fs-13 focus-input-outline-blue w-300 m-btm-15 "
                placeholder="Search templates.."
                style={{ width: '335px' }}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
              <SmallTable
                tableClassName="edit-tags-auto-table hide-scrollbar max-w-600"
                tableContainerClass="whatsapp-auto-container hide-scrollbar"
                tableContainerStyle={{ overflowY: 'scroll' }}
                tableStyle={{
                  paddingRight: '24px',
                  marginRight: 0,
                  boxSizing: 'border-box',
                  width: '100%',
                  maxWidth: '480px',
                }}
                rowStyle={{ borderBottom: '1px solid #d0d0d0' }}
                data={props.today.tableData}
                onRowClick={data => {
                  // setMsgText(data.msg)
                  templateReplace(data.msg);
                  showTemplatePopup(false);
                  setShowPopConfirm(true);
                  if (data.image_url) {
                    fileDispatch({
                      type: actionTextAreaFileActions.setToSingleImageUrl,
                      payload: data.image_url,
                    });
                  } else {
                    fileDispatch({
                      type: actionTextAreaFileActions.clear,
                    });
                  }
                }}
                tableHeaders={lilTemplateHeader}
              />
            </div>
          }
        />
      ) : null}
      {paymentsPopup ? (
        <PopMessagePayments
          onCancel={() => {
            showPaymentsPopup(false);
            setShowPopConfirm(true);
          }}
          clientName={props.today.todayClientChat.name.split(' ')[0]}
          clientFullName={props.today.todayClientChat.name}
          // popStyle = {{width: '348px', height: '572px', overflowY: 'hidden'}}
          popStyle={{ width: '348px', minHeight: '572px', overflowY: 'scroll' }}
          showPaymentsPopup={showPaymentsPopup}
          // clientId={clientRecipient}
          clientId={props.today.todayClientChat.clientRecipient}
          setMessageTxt={setMsgText}
          setPaymentRequestID={setPaymentRequestID}
          setShowMessage={setShowPopConfirm}
        />
      ) : null}
      {assignPopup ? (
        <PopConfirm
          popStyle={{
            width: '380px',
          }}
          confirmButtonText="SAVE"
          cancelButtonText="CANCEL"
          confirm="Assign Associate"
          description={[
            <div key="primary" className="align-left m-top-10" style={{ height: '175px' }}>
              <span className="dropDownDescriptionText">Assign an Associate</span>
              <DropDownSelect
                classProps="mq-w-200 w-295 align-left m-top-8"
                options={allAssoc.map(asc => ({
                  label: asc.fullName ? asc.fullName : asc.first_name,
                  value: asc.id,
                }))}
                placeholder="Select an Associate..."
                value={associate}
                onChange={obj => {
                  setAssociate(obj.value);
                }}
              />
            </div>,
          ]}
          // customContent={true}
          noButtonDisable
          onCancel={() => setAssignPopup(false)}
          onConfirm={assignAssociate}
        />
      ) : null}
      {dismissPopup ? (
        <PopConfirm
          popStyle={{
            width: '380px',
          }}
          customHeaderContent="fs-16 align-center"
          confirmButtonText="Dismiss"
          cancelButtonText="Cancel"
          confirm="Dismiss Selected Reminders"
          description="These reminders will be dismissed from your list.  If you continue, these reminders will be permanently removed from your profile."
          noButtonDisable
          onCancel={() => setDismissPopup(false)}
          onConfirm={dismissSelected}
        />
      ) : null}
    </Layout>
  );
};

function mapStateToProps(state) {
  const { auth, client, pagination, today } = state;
  const tableType = pagination.table_type;

  return {
    auth,
    client,
    pagination: getTablePaginationData(state, tableType),
    pag: pagination.table_type,
    today,
  };
}
export default connect(mapStateToProps, {})(Today);
