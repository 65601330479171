import React, { useState } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChooseFileButton from '../../../../Layout/ChooseFileButton';
import DropZone from '../../../../Layout/DropZone';

const ChooseFile = props => {
  const { setRawData, setColumns } = props;
  const [data, setData] = useState([[]]);

  // const processData = dataString => {
  //   // parsing the data from csv into arrays so it is easier to work with
  //   const dataStringLines = dataString.split(/\r\n|\n/);
  //   const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
  //   // list and pseudoCol are temp so that we can set the data up the way we want
  //   const list = [];
  //   const pseudoCol = {};
  //   // turns each array in the array into a row (object) of data
  //   for (let i = 0; i < dataStringLines.length; i++) {
  //     const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
  //     if (headers && row.length == headers.length) {
  //       console.log('headers and row lenght == headers length');
  //       const obj = {};
  //       for (let j = 0; j < row.length; j++) {
  //         let d = row[j];
  //         if (d.length > 0) {
  //           if (d[0] == '"') d = d.substring(1, d.length - 1);
  //           if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
  //         }
  //         obj[j] = d;
  //         if (i === 0) {
  //           pseudoCol[j] = {
  //             selector: '',
  //             displayName: '',
  //             ignore: false,
  //           };
  //         }
  //       }
  //       // remove the blank rows
  //       if (Object.values(obj).filter(x => x).length > 0) {
  //         list.push(obj);
  //       }
  //     }
  //   }
  //   setColumns(pseudoCol);
  //   setRawData(list);
  // };

  const handleFileChange = event => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        const rowsObj = {};
        const rawData = [];
        const cols = {};
        results.data.map((d, i) => {
          const tempValueObj = {};
          Object.keys(d).map((key, index) => {
            rowsObj[index] = key;
            cols[index] = {
              selector: '',
              displayName: '',
              ignore: false,
            };
          });
          Object.values(d).map((value, index) => {
            tempValueObj[i] = {
              ...tempValueObj[i],
              [index]: value,
            };
          });
          Object.keys(tempValueObj).forEach(key => {
            rawData.push(tempValueObj[key]);
          });
        });
        rawData.unshift(rowsObj);
        setRawData(rawData);
        setColumns(cols);
        props.setUploadModal('confirmHeaders');
      },
    });
  };
  // handle file upload
  // const handleFileUpload = e => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = evt => {
  //     /* Parse data */
  //     const bstr = evt.target.result;
  //     const wb = XLSX.read(bstr, { type: 'binary', raw: true });
  //     /* Get first worksheet */
  //     const wsname = wb.SheetNames[0];
  //     const ws = wb.Sheets[wsname];
  //     /* Convert array of arrays */
  //     const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
  //     processData(data);
  //     // handleParse(data);
  //   };
  //   reader.readAsBinaryString(file);
  //   e.target.value = null;
  //   props.setUploadModal('confirmHeaders');
  // };

  // pass this down to dropZone so it can be reusable
  const handleDropFile = (columns, data) => {
    const cols = {};
    columns.forEach(column => {
      cols[column] = {
        selector: '',
        displayName: '',
        ignore: false,
      };
    });
    setColumns(cols);
    setRawData(data);
    props.setUploadModal('confirmHeaders');
  };

  return (
    <div
      className={`pos-abs z-index-5 left-0 align-top w-100-vw h-100-vh bg-white p-42 rem-pad-width max-h-100vh overflowY ${
        props.paymentBannerActive ? 'chooseFileMarginTop' : 'm-top-44'
      }`}
    >
      <div className="upload-data-container">
        <div className="flex-row-spacebetween-wrap w-100-P align-items-center">
          <div className="flex-col-left">
            <span className="align-left fs-18 fw-500 w-100-P">Upload Data</span>
            <span className="align-left fs-14 w-100-P">Bulk add {props.endPoint}</span>
          </div>
          <span className="layoutBreadcrumb">
            <span className="current-bread-crumb">Upload</span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span>Match</span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span>Review</span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span>Done</span>
          </span>
        </div>
        <div className="header-container">
          <span className="align-left fs-18 fw-500 w-100-P">Upload your file</span>
        </div>
        <p className="align-left fs-14 h-40 m-top-40 text-gray">
          You can upload any .csv, .tsv file with any set of columns as long as it has 1 record per row. The next step
          will allow you to match your spreadsheet columns to the right data points. You'll be able to clean up or
          remove any corrupted data before finalizing your report.
        </p>
        <DropZone
          handleDropFile={handleDropFile}
          content={
            <div className="flex-column">
              <FontAwesomeIcon icon="fa-regular fa-folder-arrow-up" size="2x" />
              <span className="drag-files-span">Drag files here</span>
              <span className="or-span">or</span>
              <ChooseFileButton handleFileUpload={handleFileChange} />
              {/* <ChooseFileButton handleFileUpload={handleFileUpload} /> */}
            </div>
          }
        />
        <button
          className="import-cancel-button"
          onClick={() => {
            props.setUploadModal('');
            props.setSelStore('');
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  paymentBannerActive: state.paymentBanner.active,
});

export default connect(mapStateToProps)(ChooseFile);
