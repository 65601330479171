import React from 'react';
import { DatePicker } from 'antd';
import './styles.css';

const { RangePicker: AntDesignRangePicker } = DatePicker;

const RangePicker = props => {
  return <AntDesignRangePicker {...props} className="cb-range-picker" />;
};

export default RangePicker;
