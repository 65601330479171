import React from 'react';
import { Tag as AntDesignTag } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import './styles.css';

const Tag = ({ item, closable, onClose }) => {
  return (
    <AntDesignTag key={item} className="cb-tag">
      {item} {closable && <CloseCircleOutlined onClick={() => onClose(item)} className="cb-tag__icon" />}
    </AntDesignTag>
  );
};

export default Tag;
