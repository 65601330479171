import { getRequest } from "../../../../core/apiRequests";

export const clientDetailsEndpoints = [
    "getClientCollections",
    "getClientTags",
    "getClientAutoMessages",
    "getClientRecentActivity",
    "getClientReminders",
    "getClientSalesOpps",
    "getClientPurchases",
];

export const detailsRequestHandler = async(clientId, merchantId, endpoint, limit = 5) => {
    const reqObj = {
        params: ['clients', merchantId, clientId, endpoint],
        query: {limit},
    }
    return await getRequest(reqObj);
}
