import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { formatPhoneNumber } from 'react-phone-number-input';
import Table from '../../../../../Layout/Table';

const PreviewAudienceModal = props => {
  const tableHeaders = [
    // This is a configuration array.
    {
      keyName: 'name',
      style: { paddingLeft: '25px', width: '40%', minWidth: '120px' },
      // headerClassName: 'col-p-l-25',
      rowClassName: 'col-p-l-25',
      title: 'Name',
      sortable: true,
    },
    {
      keyName: 'mobile',
      style: { width: '100px', minWidth: '60px' },
      headerClassName: 'p-left-41 ',
      rowClassName: 'align-left p-left-41  rem-pad-width',
      title: 'Mobile Number',
      render: (t, o) => formatPhoneNumber(t),
      sortable: true,
    },
    {
      keyName: 'email',
      style: { width: '40%', minWidth: '100px' },
      headerClassName: 'p-left-41 align-left p-right-41 ',
      rowClassName: 'align-left p-left-41  rem-pad-width p-right-41 ',
      title: 'Email Address',
      sortable: true,
    },
  ];

  const asctNameConst = asct => {
    const filteredArr = props.associates.filter(tg => tg.id === props.associate);
    if (filteredArr.length > 0) {
      return `${filteredArr[0].first_name || ''} ${filteredArr[0].last_name || ''}`;
    }
    return '';
  };
  const basicNameConst = (arr, id) => {
    const filteredArr = arr.filter(o => o.id === id);
    if (filteredArr.length > 0) {
      return filteredArr[0].name;
    }
    return '';
  };
  const bdayAnniFormatter = day => {
    // Sat May 15 2021 00:00:00 GMT-0500
    return `${moment(day.start).format('MMMM DD, YYYY')} - ${moment(day.end).format('MMMM DD, YYYY')}`;
  };

  return (
    // <div className='pos-abs' style={{backgroundColor: 'rgba(0, 0, 0, 0.16)'}}> {/* Black clickable to close background */}
    <div className="pos-abs w-100-vw h-100-vh left-0 align-top flex-col-center" style={{ zIndex: '555' }}>
      {' '}
      {/* Black clickable to close background */}
      <div
        onClick={() => props.setShowModal(false)}
        className="w-100-P h-100-P pos-abs left-0 align-top"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.16)', zIndex: '101' }}
      />
      <div
        className="w-100-vw h-100-vh maw-1100 bg-white left-0 align-top max-h-893 p-right-41 p-left-41 p-top-36 rem-pad-width hide-scrollbar"
        style={{ zIndex: '102', overflowY: 'scroll' }}
      >
        <FontAwesomeIcon
          onClick={() => props.setShowModal(false)}
          icon={['far', 'times']}
          size="3x"
          className="gray pointer"
          style={{ float: 'right' }}
        />
        <div className="flex-col-center hide-scrollbar" style={{ overflowY: 'scroll' }}>
          <span className="fs-20 fw-500">{props.clientCount} Clients</span>
          {props.tag ? <span>Tag: {basicNameConst(props.tags, props.tag)}</span> : null}
          {props.store ? <span>Store: {basicNameConst(props.stores, props.store)}</span> : null}
          {props.preference ? <span>Preference: {basicNameConst(props.preferences, props.preference)}</span> : null}
          {props.associate ? <span>Associate: {asctNameConst()}</span> : null}
          {props.prefContact ? <span>Contact Preference: {props.prefContact}</span> : null}
          {props.birthdayRange.start && props.birthdayRange.end ? (
            <span>Birthday: {bdayAnniFormatter(props.birthdayRange)}</span>
          ) : null}
          {props.anniversaryRange.start && props.anniversaryRange.end ? (
            <span>Anniversary: {bdayAnniFormatter(props.anniversaryRange)}</span>
          ) : null}

          <Table
            tableHeaders={tableHeaders}
            data={props.fullClientData}
            style={{ maxWidth: '100%' }}
            tableClassName="rem-pad-width maw-1100"
            tableContainerClass="w-100-P "
            searchable={false}
            paginationV2
            tableName="PREVIEW_AUDIENCE"
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => state.massMessaging;
export default connect(mapStateToProps)(PreviewAudienceModal);
