import React from 'react';
import moment from 'moment';

const getTimeRemaining = props => {
  const endTime = moment(props).add(1, 'd');
  const e_timeDuration = moment.duration(endTime);
  // takes a duration of the end time and subtracts the current time
  const total = e_timeDuration.subtract(moment());
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  let seconds = Math.floor((total / 1000) % 60);
  let minutes = Math.floor((total / 1000 / 60) % 60);
  let hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  if (hours.toString().length < 2) {
    hours = `0${hours}`;
  }
  if (seconds.toString().length < 2) {
    seconds = `0${seconds}`;
  }
  if (minutes.toString().length < 2) {
    minutes = `0${minutes}`;
  }
  if ((hours < 0 && minutes < 0 && seconds < 0) || days < 0) {
    return false;
  }
  if (hours > 22) {
    return 'Now';
  }
  return `${hours}:${minutes}:${seconds} remaining`;
};

export default getTimeRemaining;
