/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import yn from 'yn';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { postRequest, deleteRequest } from '../../../../../../../../../core/apiRequests';
import { notificationError, notificationSuccess } from '../../../../../../../../../core/actions';
import {
  setMsgClient,
  openConversation,
  setMsgResetClient,
  closeConversation,
} from '../../../../../../../../../reducers/messaging';

const ChatMore = props => {
  const [associatesAssigned, setAssociatesAssigned] = useState([]);
  const [openChatOptions, setOpenChatOptions] = useState(false);
  const [elipsis, setElipsis] = useState(false);
  const [archived] = useState(!props?.chat?.isOpen);

  const isMounted = useRef(null);

  const MarkUnread = chat => {
    isMounted.current = true;
    (async () => {
      const reqObj = {
        params: ['chats', chat.id, 'markChatUnread'],
        query: { type: chat.type, clientId: chat.client.id },
        loadingStatusOff: true,
      };
      props.updateUnreadInInboxes(chat.id);
      const unreadReq = await postRequest(reqObj);
      if (unreadReq) {
        setOpenChatOptions(false);
      } else {
        notificationError('Error Setting Message as Unread');
      }
    })();
    return () => {
      isMounted.current = false;
    };
  };

  const archiveChat = async chat => {
    const reqObj = {
      params: ['chats', props.auth.userId, chat.id, 'archiveChat'],
      query: { type: chat.type },
    };
    const removeChatRequest = await deleteRequest(reqObj);
    if (removeChatRequest) {
      if (props.messaging.client.chat_id === chat.id) {
        setMsgClient({ isOpen: false });
        setMsgResetClient();
      }
      closeConversation(chat.id);
      notificationSuccess('Conversation Closed');
    } else {
      notificationError('Error closing conversation');
    }
  };

  const unarchiveChat = async chat => {
    const reqObj = {
      params: ['chats', props.associateId, chat.id, 'unarchiveChat'],
      query: { type: chat.type },
    };
    const removeChatRequest = await postRequest(reqObj);
    if (removeChatRequest) {
      notificationSuccess('Conversation Opened');
      if (props.messaging.client.chat_id === chat.id) {
        setMsgClient({ isOpen: true });
      }
      openConversation(chat.id);
    } else {
      notificationError('Error opening Chat');
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setAssociatesAssigned(props.chat?.client?.assignedAssociates?.includes(props.auth.userId) ?? false);
  }, [props.chat]);

  const stopEventBubbling = (event, obj) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.currentTarget.id === 'markUnread' && obj) {
      MarkUnread(obj);
    }
    if (event.currentTarget.id === 'removeChat' && obj) {
      if (archived) {
        unarchiveChat(obj);
      } else {
        archiveChat(obj);
      }
    }
    if (event.currentTarget.id === 'chatAssign' && obj) {
      props.setAssignPopup(true);
      props.setHoverAssignId(obj.client.id);
    }
    setOpenChatOptions(false);
  };

  let id = 0;
  // eslint-disable-next-line no-return-assign, no-plusplus, no-sequences
  const uid = (() => ((id = 0), () => id++))();

  return (
    <div className="pos-rel pointer" id="view-conversation-wrapper">
      <div
        role="button"
        tabIndex={0}
        id={`${elipsis ? `view-more-elipsis ${uid()}` : `view-conversation ${uid()}`}`}
        className="w-33 h-33 m-btm-10 border-r4 pointer m-right-8 pos-rel"
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          setOpenChatOptions(true);
          setElipsis(true);
        }}
        onKeyDown={() => {
          setOpenChatOptions(true);
          setElipsis(true);
        }}
      >
        <FontAwesomeIcon
          icon={['far', 'ellipsis-h']}
          size="1x"
          className="fs-16 pointer"
          id="view-more-btn"
          style={{ color: 'black', marginTop: '8px' }}
        />
      </div>
      {openChatOptions ? (
        <div className="ChatMoreOptionDiv" id="view-conversation">
          <FontAwesomeIcon
            icon={['fas', 'caret-up']}
            size="1x"
            className="fs-16 pointer"
            style={{ color: 'white', position: 'absolute', top: '-10px', right: '10px' }}
          />
          <ul className="ul-no-style" style={{ zIndex: '100' }} id="mark-unread">
            {!props.msg.isUnread && (
              <li className="flex-row-nospacebetween-nowrap inboxAvatarLILI pointer" id="mark-unread-list-item">
                <button
                  id="markUnread"
                  onClick={e => stopEventBubbling(e, props.chat)}
                  onKeyDown={e => stopEventBubbling(e, props.chat)}
                  type="button"
                  className="border-none w-100-P h-35 inboxAvatarLILI bg-white pointer"
                >
                  <img alt="unread message icon" src="https://dashboard-v2-images.s3.amazonaws.com/unreadMessage.svg" />
                  <span className="fs-12 fw-400 m-left-4 no-text-wrap">Mark as Unread</span>
                </button>
              </li>
            )}
            {yn(props.auth.merchantConfig.DECLUTTERED_INBOX) && (
              <li
                className="flex-row-nospacebetween-nowrap inboxAvatarLILI"
                onClick={e => stopEventBubbling(e, props.chat)}
                onKeyDown={e => stopEventBubbling(e, props.chat)}
                id="removeChat"
              >
                <div
                  style={{ marginLeft: '23px', marginTop: '7px', paddingBottom: '4px' }}
                  className="remove-chat"
                  id="remove-chat"
                >
                  <FontAwesomeIcon
                    icon={['far', archived ? 'eye' : 'eye-slash']}
                    size="1x"
                    className="fs-16 pointer"
                    id="remove-chat"
                    style={{ color: 'black' }}
                  />
                  <span className="fs-12 fw-400 m-left-10 no-text-wrap">{archived ? 'Open' : 'Close'}</span>
                </div>
              </li>
            )}
            {props.type === 'store_inbox' && props.auth.role !== 'ASSOCIATE' && props.chat.type === 'C' && (
              <li className="flex-row-nospacebetween-nowrap pos-rel inboxAvatarLILI pointer">
                <button
                  onClick={e => stopEventBubbling(e, props.chat)}
                  onKeyDown={e => stopEventBubbling(e, props.chat)}
                  id="chatAssign"
                  type="button"
                  className="border-none w-100-P h-35 inboxAvatarLILI bg-white pointer"
                  disabled={props.role === 'ASSOCIATE'}
                  style={{
                    opacity: props.role === 'ASSOCIATE' ? '.4' : '1',
                  }}
                >
                  <FontAwesomeIcon
                    icon={[associatesAssigned ? 'fas' : 'far', 'bookmark']}
                    size="1x"
                    className="fs-16 pointer"
                    id="chatAssign"
                    style={{ color: 'black', left: '23px', position: 'absolute' }}
                  />
                  <span id="chatAssign" className="fs-12 fw-400 pos-rel" style={{ left: '-10px' }}>
                    Assign
                  </span>
                </button>
              </li>
            )}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  messaging: state.messaging,
  newMessages: state.navigation.newMessages,
});
export default connect(mapStateToProps, {})(ChatMore);
