import React from 'react';
import { connect } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import StandardLayout from '../../../../../Layout/StandardLayout';
import AutoReminderSettings from '../../../../../Layout/AutoReminders/AutoReminderSettings';
import SecondaryPanel from '../../../../../Layout/SecondaryPanel';

const LiveEventReminder = props => {
  const history = useHistory();

  const { listItemId } = props.match.params;
  const backUrl = `/Configuration/life-events/edit-life-events/${listItemId}`;

  const setRedirectBack = () => {
    history.push(backUrl);
  };

  const reminderNameHandler = () => {
    if (props.autoRemMsgs.reminderName.length > 20) {
      return `${props.autoRemMsgs.reminderName.substring(0, 19)}...`;
    }
    return props.autoRemMsgs.reminderName;
  };

  return (
    <StandardLayout
      title={`${props.autoRemMsgs.reminderAddOrEdit} Auto Reminder`}
      description={
        props.autoRemMsgs.reminderAddOrEdit === 'Add'
          ? 'Create an auto reminder linked to this life event'
          : `Edit "${reminderNameHandler()}"`
      }
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Life Events', to: '/Configuration/life-events' },
        { title: 'Edit Life Event', to: backUrl },
        {
          title:
            props.autoRemMsgs.reminderAddOrEdit === 'Add'
              ? `${props.autoRemMsgs.reminderAddOrEdit} Auto Reminder`
              : `${props.autoRemMsgs.reminderAddOrEdit} "${reminderNameHandler()}"`,
        },
      ]}
      classProps="standard-width-with-sidebar"
    >
      <AutoReminderSettings
        setRedirectBack={setRedirectBack}
        cancelRoute={backUrl}
        popConfirmDesc="This will remove the reminder from this life event"
        descriptionText="When do you want the associate to be reminded?"
        type="lifeEvent"
        match={props.match}
        editRoute={backUrl}
      />
      {!props.autoRemMsgs.listItemId && !props.match.params.listItemId ? (
        <Redirect to="/Configuration/life-events" />
      ) : null}
      <SecondaryPanel title={['Automation']} current="Life Events" />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  autoRemMsgs: state.autoRemMsgs,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(LiveEventReminder);
