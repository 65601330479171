import React, { useState } from 'react';
import Layout from '../../../Layout/StandardLayout';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './editprofile.css';
import { notificationError, notificationSuccess } from '../../../../core/actions';
import Axios from 'axios';
import { API_URL } from '../../../../constants';

const ChangePassword = props => {
  const [pwText, setPwText] = useState('');
  const [pwConfirm, setPwConfirm] = useState('');
  const history = useHistory();

  const onSave = () => {
    const { userId, merchantId } = props;
    if (pwText.length > 5) {
      if (pwText === pwConfirm) {
        Axios.post(`${API_URL}/xa/associates/${merchantId}/${userId}/updatePassword`, { password: pwText }).then(() => {
          notificationSuccess('Password change Successful!');
          // setRedirect(true);
          history.push('/EditProfile');
        });
      } else {
        notificationError('Passwords Do Not Match');
      }
    } else {
      notificationError('Password must be 6 or more characters');
    }
  };

  return (
    <Layout
      title="Change Password"
      description=""
      classProps={'standard-width-wo-sidebar'}
      breadCrumbs={[{ title: 'Edit Profile', to: '/EditProfile' }, { title: 'Change Password' }]}
    >
      <div className="w-100-P h-100-P flex-col-left">
        <input
          type="password"
          value={pwText}
          onChange={e => setPwText(e.target.value)}
          placeholder="New Password"
          className="fs-16 input-height w-402 m-btm-28 common_inputStyle"
        />
        <input
          type="password"
          value={pwConfirm}
          onChange={e => setPwConfirm(e.target.value)}
          placeholder="Confirm Password"
          className="fs-16 input-height w-402 m-btm-28 common_inputStyle"
        />

        <div className="w-402 flex-col-center">
          <button onClick={onSave} className="save-button-v2 m-btm-8">
            Save
          </button>
            <button
            onClick={() => history.push('/EditProfile')}
            className="cancel-button-v2">
            Cancel
          </button>
        </div>
      </div>
    </Layout>
  );
};
const mapStateToProps = state => state.auth;
export default connect(mapStateToProps, {})(ChangePassword);
