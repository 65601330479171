import { Breadcrumb, Typography } from 'antd-v5';
import React from 'react';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const Header = ({headerStyles, breadcrumb, title, maxWidth, actions}) => {

  return (
    <div
      className="flex-row-spacebetween-center m-btm-10 m-top-10 p-left-40 p-right-40"
      style={{
        maxWidth: maxWidth ?? '1200px',
        height: 'auto',
        ...headerStyles,
      }}
    >
      <div className='flex-col-left'>
        <Breadcrumb
        itemRender={itemRender}
          items={breadcrumb}
        />
        <Title
          className='m-top-16'
          style={{ fontFamily: 'Poppins'}}
          level={2}
        >
          {title}
        </Title>
      </div>
      {actions && (
        <div className='flex-row-center'>
          {actions}
        </div>
      )}
    </div>
  )
};

function itemRender(currentRoute, params, items, paths) {
  const isLast = currentRoute?.path === items[items.length - 1]?.path;

  return isLast ? (
    <span>{currentRoute.title}</span>
  ) : (
    <Link to={`/${paths.join("/")}`}>{currentRoute.title}</Link>
  );
}

export default Header;
