// Key name to use for the table column (also matches BE names, for sorting)
export const PAYMENTS_COLUMN_KEY = {
  ID: 'id',
  CLIENT_NAME: 'clientName',
  STATUS: 'status',
  STORE: 'store',
  STORE_ID: 'storeId',
  BANK_ACCOUNT_TYPE: 'bankAccountType',
  BANK_ACCOUNT_NICKNAME: 'bankAccountNickname',
  BANK_ACCOUNT_LAST_FOUR: 'bankAccountLastFour',
  AMOUNT: 'amount',
  UPDATED: 'lastUpdated',
  PHONE: 'phoneNumber',
  EMAIL: 'email',
  CREATED_BY: 'createdBy',
  REFUNDED: 'refunded',
};

/**
 * Key Mirror for BE statuses
 * Add new statuses here, for local use below
 */
export const PAYMENT_STATUS_KEY = {
  UNPAID: 'UNPAID',
  PAID_BY_CC: 'PAID_BY_CC',
  PAID_MANUALLY: 'PAID_MANUALLY',
  PAID_WITH_APPLE_PAY: 'PAID_WITH_APPLE_PAY',
  PAID_WITH_GOOGLE_PAY: 'PAID_WITH_GOOGLE_PAY',
  REFUND_FAILED: 'REFUND_FAILED',
  PARTIALLY_REFUNDED: 'PARTIALLY_REFUNDED',
  REFUNDED: 'REFUNDED',
  EXPIRED: 'EXPIRED', // EXPIRED is not a BE status
  CANCELED: 'CANCELED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  CHARGEBACK: 'CHARGEBACK',
};

// Status config for the UI
export const PAYMENT_STATUS = {
  UNPAID: {
    text: 'Sent',
    color: 'yellow',
    statuses: [PAYMENT_STATUS_KEY.PAYMENT_FAILED, PAYMENT_STATUS_KEY.UNPAID],
    filters: [PAYMENT_STATUS_KEY.UNPAID],
  },
  PAID: {
    text: 'Paid',
    color: 'blue',
    statuses: [
      PAYMENT_STATUS_KEY.PAID_BY_CC,
      PAYMENT_STATUS_KEY.PAID_MANUALLY,
      PAYMENT_STATUS_KEY.PAID_WITH_APPLE_PAY,
      PAYMENT_STATUS_KEY.PAID_WITH_GOOGLE_PAY,
      PAYMENT_STATUS_KEY.REFUND_FAILED,
      PAYMENT_STATUS_KEY.PARTIALLY_REFUNDED,
    ],
    filters: [
      PAYMENT_STATUS_KEY.PAID_BY_CC,
      PAYMENT_STATUS_KEY.PAID_MANUALLY,
      PAYMENT_STATUS_KEY.PAID_WITH_APPLE_PAY,
      PAYMENT_STATUS_KEY.PAID_WITH_GOOGLE_PAY,
    ],
  },
  REFUNDED: {
    text: 'Refunded',
    color: 'gray',
    statuses: [PAYMENT_STATUS_KEY.REFUNDED],
    filters: [PAYMENT_STATUS_KEY.REFUNDED], // REFUNDED contains both full and partial refunds. When REFUNDED is sent to the server and PARTIALLY_REFUNDED is not sent, the server adds an additional AND clause to the query to exclude partial refunds.
  },
  PARTIALLY_REFUNDED: {
    text: 'Partially Refunded',
    color: 'gray',
    style: {
      width: '125px',
    },
    statuses: [PAYMENT_STATUS_KEY.PARTIALLY_REFUNDED],
    filters: [PAYMENT_STATUS_KEY.PARTIALLY_REFUNDED], // This is a pseudo-filter. It's not an actual status in the db, but it indicates to the server to query for partial refunds.
  },
  CANCELED: {
    text: 'Canceled',
    color: 'gray',
    statuses: [PAYMENT_STATUS_KEY.CANCELED],
    filters: [PAYMENT_STATUS_KEY.CANCELED],
  },
  EXPIRED: {
    text: 'Expired',
    color: 'red',
    statuses: [PAYMENT_STATUS_KEY.EXPIRED],
    filters: [PAYMENT_STATUS_KEY.PAYMENT_FAILED, PAYMENT_STATUS_KEY.UNPAID], // EXPIRED is not a BE status, it's a flag on UNPAID pr's
  },
  FAILED: {
    text: 'Failed',
    color: 'red',
    statuses: [PAYMENT_STATUS_KEY.PAYMENT_FAILED, PAYMENT_STATUS_KEY.REFUND_FAILED],
    filters: [PAYMENT_STATUS_KEY.PAYMENT_FAILED, PAYMENT_STATUS_KEY.REFUND_FAILED],
  },
  CHARGEBACK: {
    text: 'Chargeback',
    color: 'gray',
    statuses: [PAYMENT_STATUS_KEY.CHARGEBACK],
    filters: [PAYMENT_STATUS_KEY.CHARGEBACK],
  },
};

// Map BE status to Status object, for display (Add new BE statuses here)
export const PAYMENT_STATUS_LABEL = {
  [PAYMENT_STATUS_KEY.UNPAID]: PAYMENT_STATUS.UNPAID,
  [PAYMENT_STATUS_KEY.PAID_BY_CC]: PAYMENT_STATUS.PAID,
  [PAYMENT_STATUS_KEY.PAID_MANUALLY]: PAYMENT_STATUS.PAID,
  [PAYMENT_STATUS_KEY.PAID_WITH_APPLE_PAY]: PAYMENT_STATUS.PAID,
  [PAYMENT_STATUS_KEY.PAID_WITH_GOOGLE_PAY]: PAYMENT_STATUS.PAID,
  [PAYMENT_STATUS_KEY.REFUND_FAILED]: PAYMENT_STATUS.FAILED,
  [PAYMENT_STATUS_KEY.REFUNDED]: PAYMENT_STATUS.REFUNDED,
  [PAYMENT_STATUS_KEY.PARTIALLY_REFUNDED]: PAYMENT_STATUS.PARTIALLY_REFUNDED,
  [PAYMENT_STATUS_KEY.EXPIRED]: PAYMENT_STATUS.EXPIRED, // EXPIRED is not a BE status
  [PAYMENT_STATUS_KEY.CANCELED]: PAYMENT_STATUS.CANCELED,
  [PAYMENT_STATUS_KEY.PAYMENT_FAILED]: PAYMENT_STATUS.FAILED,
  [PAYMENT_STATUS_KEY.CHARGEBACK]: PAYMENT_STATUS.CHARGEBACK,
};

// Our default date format for Payment info
export const DATE_FORMAT = {
  SHORT_DATE_SLASHES: 'MM/DD/YYYY',
  ABBR_DATE_TIME: 'MMM D YYYY, h:mm A',
  LONG_DATE: 'MMMM D, YYYY',
  LONG_DATE_TIME: 'MMMM D, YYYY, h:mma',
};

// For DateRange filters
export const DATE_RANGE = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  THIS_WEEK: 'Week to date',
  LAST_WEEK: 'Last Week',
  THIS_MONTH: 'Month to date',
  LAST_MONTH: 'Last Month',
  THIS_YEAR: 'This Year',
  LAST_YEAR: 'Last Year',
  ONE_YEAR: 'Last 12 Months',
};
