const tableHeaders = [
  // this is a configuration array. It specifies how each column will be structured as well as the column header info
  {
    keyName: 'name',
    style: { width: '50%' },
    headerClassName: 'tags_row2 col-p-l-25 table-header-grey border-btm-l-gray',
    rowClassName: 'col-p-l-25',
    title: 'Name',
    sortable: true, // sort allows gives us the ability to sort the table
  },
  {
    keyName: 'weight',
    style: { width: '10%', textAlign: 'left', paddingRight: '33px' },
    headerClassName: 'table-header-grey border-btm-l-gray',
    rowClassName: 'align-left p-right-33',
    title: 'Weight',
    sortable: true,
    render: (t, o) => {
      return `${Math.floor(t * 100)}%`;
    },
  },
];
export default tableHeaders;
