import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {  differenceInDays, endOfDay, format, formatISO, isBefore, startOfDay, subDays } from 'date-fns'
import Report from '../../../../componentsV2/Layout/Report';
import { addLoadingList, removeLoadingList, setLoadingDescription } from '../../../../reducers/notification';
import { filterList } from './helpers/filterList';
import NewClientExpandRow from './components/NewClientsExpandRow';
import useSearchParams from '../../../../hooks/useSearchParams';
import formatNumberAsCurrency from '../../../../utils/formatNumberAsCurrency';
import { useReport } from '../../../../context/ReportContext';

const SOURCES = [ 'WEBCHAT', 'KIOSK', 'POS', 'APP' ];
const FROM_INBOUND = ['KIOSK', 'WEBCHAT'];
const ADDED_MANUALLY = ['APP', 'POS'];
const WEBCHAT = ['WEBCHAT'];


const NewClients = ({ auth }) => {

  const options = useMemo(() => [
    {
      title: 'Source',
      key: '0-0',
      value: 'sourceMethod',
      children: [
        {
          key: '0-0-0',
          title: 'Webchat',
          value: 'WEBCHAT'
        },
        {
          key: '0-0-1',
          title: 'Added Manually',
          value: 'MANUALLY'

        },
        {
          key: '0-0-2',
          title: 'Kiosk',
          value: 'KIOSK'
        },
      ]
    }
  ], [])
  const [filters, setFilters] = useState({
    sourceMethod: [],
    store: [],
    associate: []
  });
  const [dateRange, setDateRange] = useState([subDays(new Date(), 90), new Date()]);

  const {setValue: setSearchParams, getAll} = useSearchParams();
  const params = getAll();
  const { newClients, isLoadingNewClients, newClientsMeta, isLoadingNewClientsMeta } = useReport();


  useEffect(() => {
    let paramsFilters = {
      sourceMethod: params?.sourceMethod ? params?.sourceMethod : [],
      store: params?.store ? params?.store : [],
      associate: params?.associate ? params?.associate : [],
    }
    setFilters(paramsFilters)
    if(params?.startDate && params?.endDate) {
      setDateRange([new Date(params.startDate), new Date(params.endDate)])
    } else {
      setDateRange([startOfDay(subDays(new Date(), 90)), endOfDay(new Date())])
    }
  }, [window.location.search])

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [pageSizeTable, setPageSizeTable] = useState(10);
  const [page, setPage] = useState(1);

  const getDefaultPeriod = useMemo(() => {
    if(params?.startDate && params?.endDate) {
      if(differenceInDays(new Date(params.endDate), new Date(params.startDate)) === 90) {
        return '90days'
      } else {
        return `${format(new Date(params.startDate), 'MM/dd/yyyy')} - ${format(new Date(params.endDate), 'MM/dd/yyyy')}`
      }
    } else {
      return '90days'
    }
  }, [])

  const handleOnDateRangeChange = useCallback((dates) => {
    setSearchParams('startDate', formatISO(dates[0]))
    setSearchParams('endDate', formatISO(dates[1]))
  }, []);

  const reportData = useMemo(() => {
    const newClientMap = new Map();

    newClientsMeta?.forEach(client => {
      if(!newClientMap.has(client.client_id)) {
        let clientObj = client;
        if(!clientObj.activity_source) {
          clientObj.activity_source = 'Not Applicable';
        }
        newClientMap.set(client.client_id, clientObj);
      }
    });
    newClients?.forEach(client => {
        newClientMap.set(client.client_id, client);
    });
    return Array.from(newClientMap.values());
  }, [newClients, newClientsMeta]);

  useEffect(() => {
    if(isLoadingNewClients || isLoadingNewClientsMeta) {
      setLoadingDescription("Sit tight! We're compiling your report.")
      addLoadingList('newClientsReport')
    } else {
      removeLoadingList('newClientsReport')
      setLoadingDescription(null)
    }
  }, [isLoadingNewClients, isLoadingNewClientsMeta])

  const filteredData = useMemo(() => {
    const sources = [];
    setPage(1)
    return filterList({
      newClients: reportData.map(client => {
        let activitySource = client.activity_source;
        
        let sourceMethod = '';
        if(ADDED_MANUALLY.includes(client.activity_source)) {
          sourceMethod = 'MANUALLY';
        } else {
          sourceMethod = client.activity_source;
        }
        return {
          ...client,
          sourceMethod
        }
      }),
      filters,
      sources,
      startDate: dateRange[0],
      endDate: dateRange[1]
    })
  }, [reportData, filters, dateRange]);

  const dataByClientId = useMemo(() => {
    let data = [];
    filteredData.forEach(item => {
      const indexData = data.findIndex(dataItem => dataItem.client_id === item.client_id);
      if(indexData > -1) {
        data[indexData].purchases = data[indexData].purchases + (item.sales_transaction_id ? 1 : 0);
        data[indexData].related_sales = data[indexData].related_sales + (parseFloat(item.sales_transaction_amount) || 0);
        if(item.sales_transaction_id) {
          data[indexData].transactions.push({
            sales_transaction_amount: item.sales_transaction_amount,
            sales_transaction_id: item.sales_transaction_id,
            sales_transaction_date: item.sales_transaction_date,
            client_id: item.client_id,
            client_name: item.client_name,
          })
        }
      } else {
        let newData = {
          ...item,
          purchases: item.sales_transaction_id ? 1 : 0,
          related_sales: parseFloat(item.sales_transaction_amount) || 0,
          transactions: item.sales_transaction_id ? [{
            sales_transaction_amount: item.sales_transaction_amount,
            sales_transaction_id: item.sales_transaction_id,
            sales_transaction_date: item.sales_transaction_date,
            client_id: item.client_id,
            client_name: item.client_name,
          }] : []
        }
        delete newData.sales_transaction_amount;
        delete newData.sales_transaction_id;
        delete newData.sales_transaction_date;

        data.push(newData)
      }
    });
    return data;
  }, [filteredData])

  const dataBySales = useMemo(() => {
    let data = [];
    filteredData.forEach(item => {
      if(!data.find(dataItem => dataItem.sales_transaction_id === item.sales_transaction_id)) {
        data.push(item);
    }});
    return data;
  }, [filteredData])

  const handleFilterChange = useCallback((value) => {
    let updatedFilters = {
      sourceMethod: [],
      store: [],
      associate: []
    };
    value.forEach(item => {
      if (item[0] && item[1]) {
        updatedFilters[item[0]] = [...updatedFilters[item[0]], item[1]];
      } else {
        updatedFilters[item[0]] = [];
      }
    });
    Object.keys(updatedFilters).forEach(key => {
      setSearchParams(key, updatedFilters[key].join(','))
    })
  }, [filters]);

  const removeFilter = useCallback((key, value) => {
    let updatedFilters = { ...filters };
    updatedFilters[key] = updatedFilters[key].filter(filter => filter !== value);
    Object.keys(updatedFilters).forEach(key => {
      setSearchParams(key, updatedFilters[key].join(','))
    })
  }, [filters]);

  const stats = useMemo(() => {
    const fromInbound = dataByClientId?.filter(client => FROM_INBOUND.includes(client.activity_source));
    const totalChannels = fromInbound?.map(channel => channel.activity_source).filter((v, i, a) => a.indexOf(v) === i).length;
    const addedManually = dataByClientId?.filter(client => ADDED_MANUALLY.includes(client.activity_source));
    const totalSales = dataBySales?.reduce((acc, item) => {
      if(item.sales_transaction_amount) {
        return acc + parseFloat(item.sales_transaction_amount), 0
      }
      return acc;
    }, 0).toFixed(2);
    const totalTransactions = dataBySales?.filter(client => client.sales_transaction_id).length;
    let totalAssociates = [];
    addedManually.forEach(client => {
      if(!totalAssociates.includes(client.activity_associate_id)) {
        totalAssociates.push(client.activity_associate_id);
      }
    });
    return [
      {
        title: 'From Inbound',
        value: fromInbound.length,
        description: <span className="fs-14 mid-gray m-top-3">{`Across ${totalChannels} channel${totalChannels.length === 1 ? '' : 's'}`}</span>
      },
      {
        title: 'Added Manually',
        value: addedManually.length,
        description: <span className="fs-14 mid-gray m-top-3">{`by ${totalAssociates.length} people`}</span>
      },
      {
        title: 'Sales from new clients',
        value: formatNumberAsCurrency(totalSales),
        description: <span className="fs-14 mid-gray m-top-3">{`Across ${totalTransactions} transactions`}</span>
      }
    ]
  }, [dataByClientId, dataBySales]);

  const graphData = useMemo(() => {
    let data = {};
    data['Added Manually'] = dataByClientId.filter(client => ADDED_MANUALLY.includes(client.activity_source)).map(client => ({
      total: 1,
      date: client.activity_date
    }));
    data['Webchat'] = dataByClientId.filter(client => WEBCHAT.includes(client.activity_source)).map(client => ({
      total: 1,
      date: client.activity_date
    }));
    data['Kiosk'] = dataByClientId.filter(client => FROM_INBOUND.includes(client.activity_source)).map(client => ({
      total: 1,
      date: client.activity_date
    }));
    return data;
  }, [dataByClientId]);

  const columns = useMemo(() => {
    const relatedSales = auth?.pos_type ? [
      {
        title: 'Related Sales',
        dataIndex: 'related_sales',
        key: 'related_sales',
        sorter: (a, b) => a.related_sales - b.related_sales,
        render: amount => amount ? formatNumberAsCurrency(amount) : '-',
      }] : [];
    return [
      {
        title: 'Added on',
        dataIndex: 'activity_date',
        key: 'activity_date',
        sorter: (a, b) => {
          const dateA = new Date(a.activity_date);
          const dateB = new Date(b.activity_date);

          return dateA - dateB;
        },
        render: (value) => format(new Date(value), 'MMM dd, yyyy')
      },
      {
        title: 'Client',
        dataIndex: 'client_name',
        key: 'client_name',
        sorter: (a, b) => a.client_name.localeCompare(b.client_name),
      },
      {
        title: 'Added by',
        dataIndex: 'activity_associate_name',
        key: 'activity_associate_name',
        sorter: (a, b) => {
          var textA = a?.activity_associate_name?.toUpperCase() || 'zzzzzzzz';
          var textB = b?.activity_associate_name?.toUpperCase() || 'zzzzzzzz';
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        },
      },
      {
        title: 'Source',
        dataIndex: 'activity_source',
        key: 'activity_source',
        sorter: (a, b) => a.activity_source.localeCompare(b.activity_source),
        render: (value) => ADDED_MANUALLY.includes(value) ? 'Added Manually' : value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
      },
      ...relatedSales,
    ]
  }, [auth?.pos_type]);

  const expandedRowRender = useCallback((record) => {
    return (
      <NewClientExpandRow
        record={record}
      />
    );
  }, []);

  const handleExpandRow = useCallback((expand, record) => {
    let updatedExpandedRowKeys = [...expandedRowKeys];
    if (expand) {
      updatedExpandedRowKeys.push(record.client_id);
      setExpandedRowKeys(updatedExpandedRowKeys);
    } else {
      updatedExpandedRowKeys = updatedExpandedRowKeys.filter(key => key !== record.client_id);
      setExpandedRowKeys(updatedExpandedRowKeys);
    }
  }, [expandedRowKeys]);

  return (
    <Report
      title={'New Clients'}
      stats={stats}
      options={options}
      hasCSVExport
      CSVFilename={'new-clients.csv'}
      handleFilterChange={handleFilterChange}
      filters={filters}
      removeFilter={removeFilter}
      onDateRangeChange={handleOnDateRangeChange}
      dataRange={dateRange}
      defaultPeriodValue={getDefaultPeriod}
      graphData={graphData}
      stacked
      columns={columns}
      table={dataByClientId}
      hasBanner={isBefore(dateRange[0], new Date('2024-04-25'))}
      textBanner='Clientbook started to track that information as of April 25th, 2024. You are unable to see the information before that date.'
      tablesProps={{
        pagination: {
          total: dataByClientId.length,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSizeTable(pageSize)
          },
          page: page,
          pageSize: pageSizeTable,
        },
        rowKey: 'client_id',
        expandable: {
          expandedRowRender: (record) => expandedRowRender(record),
          defaultExpandedRowKeys: ['0'],
          onExpand: (expanded, record) => handleExpandRow(expanded, record),
          expandedRowKeys,
          expandRowByClick: true
        }
      }}
    />
  )
};


const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(NewClients);
