/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'antd';
import DropdownSelect from '../../../../../../Layout/DropdownSelect';
import KioskSlider from '../../../../../../Layout/KioskSlider';
import FilterChips from '../../../../../../Layout/FilterChips';
import NoResultsMatch from './NoResultsMatch';
import { postRequest } from '../../../../../../../core/apiRequests';
import { setMsgResetClient } from '../../../../../../../reducers/messaging';
import PopConfirm from '../../../../../../Layout/PopConfirm';
import StoreModal from '../../../../../../Layout/chat/components/selectedStoreModal';
import useDebounce from '../../../../../../../hooks/useDebounce';

const SearchAndFilter = props => {
  const [visible, setVisible] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState(true);
  const [archiveAllModal, setArchiveAllModal] = useState(false);
  const isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0 || window.navigator.maxTouchPoints > 0;
  const isSearching = useRef(false);

  const asctPlaceholder = () => {
    if (props.filterAssoc.length > 0) {
      if (props.filterAssoc[0] !== 0 && props.filterAssoc[0] !== 'none') {
        return `${props.filterAssoc.length} Selected Associate${props.filterAssoc.length > 1 ? 's' : ''}`;
      }
      if (props.filterAssoc[0] === 0) {
        return 'All Assigned Associates';
      }
      return 'No Assigned Associates';
    }
    return 'All Assigned Associates';
  };

  const setFilterSettings = obj => {
    if (obj.name.includes('assoc')) {
      if (props.filterAssoc.includes('none') || props.filterAssoc.includes(0)) {
        props.filterAssoc.shift();
      }
      if (obj.value === '' || Number(obj.value) === 0 || obj.value === 'NO') {
        if (obj.value === '') {
          props.setFilterAssoc([]);
        } else if (obj.value === 'NO') {
          props.setFilterAssoc([obj.value]);
        }
      } else {
        const filteredArr = props.filterAssoc.filter(aid => Number(aid) !== Number(obj.value));
        props.setFilterAssoc([...filteredArr, obj.value]);
      }
    }
  };

  const filterReturn = () => {
    const sortedAssociates = useMemo(() => {
      const filtered = props.asctFilterList.filter(item => ['', 'NO'].includes(item.value));

      const sorted = props.asctFilterList
        .filter(item => !['', 'NO'].includes(item.value))
        .sort((a, b) => a.label.localeCompare(b.label));

      return [...filtered, ...sorted];
    }, []);

    return (
      <div style={{ width: '348px', paddingTop: '15px' }}>
        <div className="p-btm-15">
          <KioskSlider
            value={props.unreadMsg}
            onChange={val => {
              props.setUnreadMsg(val);
              props.setAllPages(1);
            }}
          />
          <span className="p-left-8 fw-500 fs-15"> Show unread only </span>
        </div>
        {props.type !== 'my_inbox' && (
          <>
            {(props.auth.meta_integration || props.auth.google_integration) && (
              <DropdownSelect
                classProps="maw-100-P w-100-P align-left m-btm-20"
                options={[
                  { label: 'All Conversations', value: null },
                  { label: 'Facebook Messenger', value: 'F' },
                  { label: 'Google reviews', value: 'G' },
                  { label: 'Text Messages', value: 'C' },
                ]}
                placeholder="All Conversations"
                value={props.typeFilter}
                onChange={obj => {
                  props.setTypeFilter(obj.value);
                  props.setAllPages(1);
                }}
              />
            )}
            {/* associates */}
            <DropdownSelect
              classProps="maw-100-P w-100-P align-left"
              options={sortedAssociates}
              placeholder={asctPlaceholder()}
              value={null}
              selectProps={{
                // eslint-disable-next-line react/no-unstable-nested-components
                formatOptionLabel: obj => {
                  if (props.filterAssoc.includes(obj.value)) {
                    return (
                      <div style={{ alignItems: 'center' }} className="flex-row-nospacebetween-nowrap">
                        <FontAwesomeIcon icon={['fal', 'check']} className="fs-12 m-right-8" />
                        <span className="p-top-3">{obj.label}</span>
                      </div>
                    );
                  }
                  return obj.label;
                },
              }}
              onChange={obj => {
                setFilterSettings({
                  ...obj,
                  name: 'assoc',
                });
                props.setAllPages(1);
              }}
            />
            <div className="w-100-P">
              <FilterChips
                asctFilterList={props.asctFilterList}
                filterAssociates={props.filterAssoc}
                setFilterAssociates={props.setFilterAssoc}
                setTempAssoc={props.setFilterAssoc}
              />
            </div>
            <NoResultsMatch zeroResults={props.zeroResults} />
            {!defaultFilter && (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                className="filter-item-clear"
                onClick={() => {
                  props.setFilterAssoc([]);
                  props.setTypeFilter(null);
                  props.setUnreadMsg(false);
                  props.setAllPages(1);
                }}
              >
                Clear All
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const handleVisibleChange = newVisible => {
    if (props.type === 'my_inbox') {
      setVisible(Boolean(newVisible));
    } else {
      props.setShowStoreFilter(Boolean(newVisible));
    }
  };

  useEffect(() => {
    if (props.unreadMsg || props.filterAssoc.length > 0 || props.typeFilter) {
      setDefaultFilter(false);
    } else setDefaultFilter(true);
  }, [props.unreadMsg, props.filterAssoc, props.typeFilter]);

  const archiveAll = async () => {
    const { selectedStore } = props.auth;
    const reqObj = {
      params: ['chats', props.associateId, 'bulkArchiveChat'],
      data: {
        selectedStore,
      },
    };
    const removeChatRequest = await postRequest(reqObj);
    if (removeChatRequest) {
      setMsgResetClient();
      setTimeout(() => {
        props.setChatMoreRefresh(true);
      }, 2000);
    }
    setArchiveAllModal(false);
  };

  const [search, setSearch] = useState(props.messaging.searchText);
  const debouncedSearch = useDebounce(search);

  // removing this useEffect because it was causing the search to be cleared after page change
  // useEffect(() => {
  //   if (props.handleSearchChange && isSearching.current) {
  //     props.handleSearchChange(debouncedSearch);
  //   }
  // }, [debouncedSearch]);
  useEffect(() => {
    if (props.handleSearchChange && isSearching.current) {
      props.handleSearchChange(search);
    }
  }, [debouncedSearch]);

  const inputRef = useRef();

  const handleSearch = useCallback(
    e => {
      e.preventDefault();
      inputRef.current.autocomplete = 'off';
      isSearching.current = true;
      setSearch(e.target.value);
    },
    [inputRef],
  );

  return (
    <div className="m-top-5">
      <div className="flex-row-spacebetween-nowrap">
        <span className="layoutTitle m-top-8 mq-fs-20 align-left flex-row-nospacebetween-nowrap m-left-10">Inbox</span>
        <div className="m-right-15 m-top-15 add-edit-wrap">
          {/* {yn(props.auth.merchantConfig.DECLUTTERED_INBOX) && (
            <div
              className="inboxIcons border-r4 pointer"
              onClick={() => setArchiveAllModal(true)}
              onKeyDown={() => setArchiveAllModal(true)}
              role="button"
              tabIndex={0}
              title="Message"
              id="new-msg-btn"
            >
              <FontAwesomeIcon
                title="Close all chats"
                icon={['far', 'eye-slash']}
                size="2x"
                className="fs-16 pointer m-top-5"
                style={{ color: '#818181' }}
              />
            </div>
          )} */}
          <Popover
            autoAdjustOverflow
            placement="bottomRight"
            arrowPointAtCenter
            content={filterReturn}
            trigger="click"
            open={props.type === 'my_inbox' ? visible : props.showStoreFilter}
            onOpenChange={handleVisibleChange}
          >
            <div className="inboxIcons border-r4 pointer" title="Filter" id="filter-btn">
              {defaultFilter ? (
                <FontAwesomeIcon
                  icon={['far', 'filter']}
                  size="2x"
                  className="fs-16 pointer m-top-5"
                  style={{ color: '#818181' }}
                />
              ) : (
                <img
                  src="https://dashboard-v2-images.s3.amazonaws.com/FilterActive.svg"
                  alt="non-default filter icon"
                  className="m-btm-5 m-top-5 pointer"
                />
              )}
            </div>
          </Popover>
          <div
            className="inboxIcons border-r4 pointer"
            onClick={() => props.setNewMessage(true)}
            onKeyDown={() => props.setNewMessage(true)}
            role="button"
            tabIndex={0}
            title="Message"
            id="new-msg-btn"
          >
            <img
              src="https://dashboard-v2-images.s3.amazonaws.com/penToSquare.svg"
              alt="new message icon"
              className="m-btm-5 m-top-5 m-left-4 pointer"
            />
          </div>
        </div>
      </div>
      <StoreModal auth={props.auth} closeConversation setMsgResetClient={setMsgResetClient} />
      <div className="p-top-16" />
      <input
        onKeyDown={props.handleSearchChange}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={!isTouch}
        className="inboxSearch2 h-45 bg-offwhite fs-13 focus-input-outline-blue"
        id="inbox-search"
        placeholder="Search by name, phone, email"
        style={{ width: '235px' }}
        onChange={handleSearch}
        value={search}
        ref={inputRef}
        onBlur={_ => {
          inputRef.current.autocomplete = 'on';
        }}
      />
      {archiveAllModal && (
        <PopConfirm
          confirm="Are you sure you want to close all chats?"
          customHeaderContent="fs-18 fw-600 align-center"
          popStyle={{ textAlign: 'left' }}
          onConfirm={() => archiveAll()}
          description={
            <>
              <p className="align-center fw-500">Confirming will close all chats in this inbox and cannot be undone.</p>
              <p className="align-center gray">Chats will need to be reopened individually.</p>
            </>
          }
          onCancel={() => setArchiveAllModal(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  messaging: state.messaging,
  newMessages: state.navigation.newMessages,
});
export default connect(mapStateToProps, {})(SearchAndFilter);
