import { useCallback, useRef, useState } from "react";

export const useOnce = (fn) => {
  const [isCalling, setIsCalling] = useState(false);
  const isCallingRef = useRef(false);

  const call = useCallback(async () => {
    if (!isCallingRef.current) {
      isCallingRef.current = !isCallingRef.current;
      setIsCalling(true);
      await fn();
      setIsCalling(false);
      isCallingRef.current = !isCallingRef.current;
    }
  }, [fn]);

  return { call, isCalling };
};
