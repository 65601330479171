import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { Select } from 'antd';

// array of all timezones in string format
let clientbookTimezones = [
  'America/Denver',
  'America/Chicago',
  'America/New_York',
  'America/Los_Angeles',
  'Pacific/Honolulu',
  'America/Phoenix',
  'Europe/London',
  'Europe/Zurich',
  'America/Indiana/Indianapolis',
  'Australia/Sydney',
  'America/Detroit',
  'America/Vancouver',
  'America/Anchorage',
  'America/Boise',
  'America/Edmonton',
  'America/Santo_Domingo',
  'America/Grand_Turk',
  'America/Curacao',
  'America/Toronto',
  'America/St_Johns',
  'America/Halifax',
];
// we need to convert each string into it's own object to load in the select
clientbookTimezones = clientbookTimezones.map(str => ({ value: str.replace('_', ' '), id: str }));

// grabs current user location and associates with a timezone
const defaultTimezone = moment.tz.guess();

const TimezoneDropdown = props => (
  <Select
    defaultValue={defaultTimezone}
    size="large"
    style={props.style}
    onChange={props.onChange}
    mode="single"
    suffixIcon={<FontAwesomeIcon icon="fa-regular fa-angle-down" size="1x" className='m-btm-10' />}
    showArrow
    value={props.value}
    options={clientbookTimezones}
    className={`timezone-dropdown ${props.classProps}`}
  />
);

export default TimezoneDropdown;
