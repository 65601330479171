import React from 'react';
import {
  AutoReminder,
  MassMessages,
  PurchaseOutline,
  PaymentRequests,
  PurchaseViewed,
} from '../../../../../componentsV2/Layout/Report/components/Icons';
import moment from 'moment';

export const PTRRows = ({ rowList }) => {
  return rowList.map((item, index) => {
    switch (item.type) {
      case 'SENT':
        return {
          key: 'transaction',
          dot: <PaymentRequests style={{ fontSize: '16px' }} />,
          children: (
            <p>
              <span className="fw-600">{item.sentBy} </span>sent a payment request to{' '}
              <span className="fw-600">{item.client} </span>
              on
              <span className="fw-600"> {moment(item.date).format('MMM DD, YYYY')}</span>
            </p>
          ),
        };
      case 'VIEWED':
        return {
          key: 'viewed-' + index,
          dot: <PurchaseViewed style={{ fontSize: '16px' }} />,
          children: (
            <p>
              <span className="fw-600">{item.clientName} </span>viewed payment request{' '}
              on
              <span className="fw-600"> {moment(item.date).format('MMM DD, YYYY')}</span>
            </p>
          ),
        };
      case 'REMINDER':
        return {
          key: 'reminder-' + index,
          dot: <AutoReminder style={{ fontSize: '16px' }} />,
          children: (
            <p>
              <span className="fw-600">{item.sentBy} </span>sent a reminder to{' '}
              <span className="fw-600">{item.clientName} </span>
              on
              <span className="fw-600"> {moment(item.date).format('MMM DD, YYYY')}</span>
            </p>
          ),
        };
      case 'PAID':
        return {
          key: 'paid-' + index,
          dot: <PurchaseOutline style={{ fontSize: '16px' }} />,
          children: (
            <p>
              <span className="fw-600">{item.clientName} </span>made a payment{' '}
              on
              <span className="fw-600"> {moment(item.date).format('MMM DD, YYYY')}</span>
            </p>
          ),
        };
      default:
        return null;
    }
  });
};
