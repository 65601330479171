import React from 'react';
import whatsappTextFormatter from './whatsappTextFormatter';

const WhatsappInputTemplateCard = ({
  templateObj,
  configObj,
  styleProps,
  disableTruncate,
  selectHandler,
  onClickSelectHandler,
}) => {
  let formattedText = whatsappTextFormatter(templateObj, configObj);
  if (formattedText.length > 123 && !disableTruncate) {
    formattedText = `${formattedText.substr(0, 125)}...`;
  }
  const templateSelected = templateObject => {
    selectHandler(templateObject);
    onClickSelectHandler();
  };
  return (
    <div
      className="w-290 h-80 bg-ultra-light-blue align-left p-top-14 p-left-10 p-right-10 rem-pad-width pointer overflowY-hidden boxShadow"
      onClick={() => templateSelected(templateObj)}
      style={styleProps || {}}
    >
      <span className="fs-12 fw-400">{formattedText}</span>
    </div>
  );
};
export default WhatsappInputTemplateCard;
