import React from 'react';
import './button.css';

const Button = props => {
  const disabledClass = () => {
    if (props.disabled) {
      return { backgroundColor: '#818181' };
    }
    if (props.transparentDisabled) {
      return { opacity: 0.5, cursor: 'not-allowed', width: 'auto' };
    }
    return {};
  };

  return (
    <button
      id={props.id}
      onClick={props.onclick}
      className={`standardButton ${props.classname}`}
      style={{ ...props.styleProps, ...disabledClass() }}
      disabled={props.disabled}
      {...props}
    >
      {props.children}
    </button>
  );
};
export default Button;
