import React, { useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Drawer } from 'antd';
import Layout from '../../../Layout/StandardLayout';
import { getRequest, postRequest } from '../../../../core/apiRequests';
import { notificationSuccess, setTablePagination } from '../../../../core/actions';
import RemindersTable from './children/RemindersTable';
import ConversationWindow from '../../../Layout/chat/chat';
import {
  setMsgChatMessages,
  setMsgClient,
  setClientAssociate,
  setMsgResetClient,
  getClientChat,
  resetMessaging,
  setChatReviewLink,
  setMsgReminderId,
  setMsgReminderDescription,
} from '../../../../reducers/messaging';
import getClientPaymentStatus from '../../../Layout/GetClientPaymentStatus';

const Reminders = props => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [selected, setSelected] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [, setPages] = useState(1);
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [, setIsLoading] = useState(false);
  const [isShown, setIsShown] = useState('');
  const [, setAllTemplates] = useState([]);
  const timeOffset = moment(new Date()).utcOffset();

  const isMounted = useRef(null);

  const [templatePopup] = useState(false);
  const [paymentsPopup] = useState(false);
  const [, setTableData] = useState([]);
  const [scope] = useState('Tag');
  const [tempConfigId] = useState('');
  const [, setTempTableData] = useState([]);
  const [allClientReminders, setAllClientReminders] = useState(query.get('initialAllClientReminders') === 'true');

  const rowClickHandler = () => {
    // nothing yet....
  };

  const getReviewLink = async storeId => {
    if (storeId) {
      const reqObj = {
        params: ['chats', storeId, 'getReviewLink'],
      };
      const linkData = await getRequest(reqObj);
      if (linkData.length > 0) {
        const { review_link } = linkData[0];
        setChatReviewLink(review_link);
      }
    } else {
      console.error('Unable to getReviewLink, NO store ID!');
    }
  };

  const setSelectedClient = async cli => {
    if (!cli.store_id) {
      console.log('ALERT NO STORE ID: ', cli);
    }
    await getClientChat(cli);
    await getReviewLink(cli.store_id);
    setClientAssociate(cli.associateCount);
    getClientPaymentStatus({ client: cli, auth: props.auth });
    setShowPopConfirm(true);
    setMsgReminderId(cli.id);
    setMsgReminderDescription(cli.description);
  };

  useEffect(() => {
    if (props.auth.merchantId && props?.hasOwnProperty('pagination') && props?.pagination?.hasOwnProperty('pageSize')) {
      getReminderData();
    }
  }, [
    props.auth.merchantId,
    props.pagination?.sortColumn,
    props.pagination?.pageSize,
    props.pagination?.page,
    props.pagination?.ascendingColumn,
    allClientReminders,
  ]);

  const getReminderData = useCallback(() => {
    // setIsLoading(true);
    setSelected([]);
    const { sortColumn, ascendingColumn } = props.pagination ?? {};
    const page = props.pagination?.page || 0;
    const pageSize = props.pagination?.pageSize || 25;
    const reqObj = {
      params: ['today', props.auth.merchantId, 'getReminders', props.auth.userId],
      query: {
        pageStart: page,
        count: pageSize,
        ascending: ascendingColumn,
        sortColumn,
        allClientReminders,
      },
    };
    (async () => {
      const responseObj = await getRequest(reqObj);
      setIsLoading(false);
      if (!responseObj.hasOwnProperty('data') || !responseObj.hasOwnProperty('pageInfo')) {
        // notificationError('Error Getting Reminders');
        return;
      }
      const {
        data,
        pageInfo: { rowCount, pageCount },
      } = responseObj;
      if (data) {
        setReminders([...data]);
        setTablePagination({ rowCount, pageCount }, 'TODAY_REMINDERS');
      }
    })();
  }, [props.pagination, props.auth.merchantId, props.auth.userId, allClientReminders]);

  // all templates
  useEffect(() => {
    isMounted.current = true;
    if (props.auth.merchantId) {
      (async () => {
        const reqObj = {
          params: ['chatMessages', props.auth.merchantId, 'allTemplates'],
        };
        const dataArr = await getRequest(reqObj);
        if (dataArr.length > 0) {
          setAllTemplates([...dataArr]);
        }
      })();
    }
    return () => {
      isMounted.current = false;
    };
  }, [props.auth.merchantId]);

  const saveSelected = () => {
    const reqObj = {
      params: ['today', props.auth.merchantId, 'save'],
      data: { selected },
      query: {
        storeId: props.auth.storeId,
        associateId: props.auth.userId,
        createdBy: `${props.auth.firstName || ''} ${props.auth.lastName || ''}`,
      },
    };
    (async () => {
      const data = await postRequest(reqObj);
      if (data) {
        setSelected([]);
        setReminders([]);
        setPages(1);
        setTimeout(() => {
          getReminderData(1);
        }, 500);
        notificationSuccess('Reminder(s) Complete!');
      }
    })();
  };

  useEffect(() => {
    if (props.auth.merchantId) {
      (async () => {
        const reqObj = {
          params: ['today', props.auth.merchantId, 'autoReminderTemplates'],
          query: {
            tempConfigId,
            scope,
          },
        };
        const data = await getRequest(reqObj);
        if (data) {
          setTableData(data);
          setTempTableData(data);
        }
      })();
    }
  }, [scope, tempConfigId]);

  useEffect(() => {
    if (templatePopup) {
      setShowPopConfirm(false);
    }
    if (paymentsPopup) {
      setShowPopConfirm(false);
    }
  }, [templatePopup, paymentsPopup]);

  const closeChat = () => {
    setShowPopConfirm(false);
    resetMessaging();
  };

  return (
    <Layout
      title="All Reminders"
      description=""
      classProps="standard-width-wo-sidebar"
      breadCrumbs={[{ title: 'Today', to: '/' }, { title: 'All Reminders' }]}
    >
      <div className="align-left">
        <RemindersTable
          refresh={getReminderData}
          tableData={reminders}
          isShown={isShown}
          setIsShown={setIsShown}
          initiateChat={setSelectedClient}
          initiateTemplates={setSelectedClient}
          selected={selected}
          setSelected={setSelected}
          allClientReminders={allClientReminders}
          setAllClientReminders={setAllClientReminders}
          rowClickHandler={rowClickHandler}
          saveSelected={saveSelected}
          setShowPopConfirm={setShowPopConfirm}
          optionalButton
          ascending
        />
      </div>
      <Drawer
        bodyStyle={{
          padding: 0,
          overflow: 'hidden',
        }}
        closable={false}
        push={false}
        destroyOnClose
        width={750}
        open={showPopConfirm}
        onClose={closeChat}
      >
        <div style={{ height: '100vh' }}>
          <ConversationWindow setDrawerToggle={closeChat} chatIconColor="#EBEBEB" type="right" />
        </div>
      </Drawer>
    </Layout>
  );
};
const mapStateToProps = state => {
  return { auth: state.auth, pagination: state.tablePagination.get('TODAY_REMINDERS') };
};
export default connect(mapStateToProps, {})(Reminders);
