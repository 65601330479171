import React from 'react';
import Button from '../../../../../CustomizedAntDesign/Button';
import './styles.css';

const ProductDetailsModalFooter = props => {
  const { buttonLabel, onSave, onClose, loading, disabled } = props;

  return (
    <div className="flex-col-center m-top-30 maw-600">
      <button className="save-button-v2 m-btm-8" onClick={onSave} disabled={disabled}>
        {buttonLabel || 'Save'}
      </button>
      <button className="cancel-button-v2 m-btm-8" onClick={onClose}>
        Cancel
      </button>
    </div>
  );
};

export default ProductDetailsModalFooter;
