import Axios from 'axios';
import { getBaseUrl } from './getBaseUrl';
import { getHeaders } from './getHeaders';

const baseURL = getBaseUrl();
const headers = getHeaders();
const timeout = 20000;

const request = Axios.create({
  baseURL,
  headers,
  timeout,
});

request.interceptors.request.use(
  config => ({
    ...config,
    headers: {
      ...config.headers,
      ...getHeaders(),
    },
  }),
  response => response,
  error => Promise.reject(error),
);

request.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
);

export const updateRequestHeaders = ({ merchantId, associateId, token }) => {
  request.defaults.headers.common['x-clientbook-user-id'] = associateId;
  request.defaults.headers.common['x-clientbook-merchant-id'] = merchantId;
  request.defaults.headers.common.Authorization = token;
};

export default request;
