import { dispatcher } from '../core/actions';

const initialState = {
  msgId: '',
  message: '',

  associateId: '',
  associateName: '',
  imageUrls: [],
  intro: '',
  requireOptIn: false,
  sendAsAssociate: false,
  //
  archiveDate: '',
  archiveBy: '',
  archiveByName: '',
  archiveReason: '',
  createdDate: '',
  schedDate: '',
  startDate: '',
  completeDate: '',
  audienceSize: '',
  totalSent: '',
  //
  store: '',
  timezone: '',
  tag: '',
  tagsCriteria: {
    // some various tags here
  },
  associate: '',
  preference: '',
  birthdayRange: { start: '', end: '' },
  anniversaryRange: { start: '', end: '' },
  addedToClientbook: { start: '', end: '' },
  lastContacted: { start: '', end: '' },
  neverContacted: false,
  prefContact: '',
  contactPrefObj: {},
  clientCount: 0,
  fullClientData: [],
  tags: [],
  stores: [],
  preferences: [],
  associates: [],
};

const defaultMessageObj = {
  associates: [],
  stores: [],
  prefContact: '',
  clientCount: 0,
  associate: '',
  preference: '',
  store: '',
  tag: '',
  tags: [],
  tagsCriteria: {},
  birthdayRange: { start: '', end: '' },
  anniversaryRange: { start: '', end: '' },
  addedToClientbook: { start: '', end: '' },
  lastContacted: { start: '', end: '' },
  neverContacted: false,
};

// THESE ARE GLOBAL SO WATCH THE NAMES
// EX: actFirstName
export default function reducer(state = initialState, action) {
  let state2 = state;
  const { payload } = action;
  switch (action.type) {
    case 'RESET_MASS_MESSAGING':
      return { ...state2, ...defaultMessageObj };
    case 'SET_MASS_MSG':
      return { ...state2, ...payload };
    case 'SET_MASS_MSG_AST_NAME':
      return { ...state2, associateName: payload };
    case 'SET_MASS_STORE':
      return { ...state2, store: payload };
    case 'SET_MASS_TIMEZONE':
      return { ...state2, timezone: payload };
    //
    case 'SET_MASS_TAG':
      return { ...state2, tag: payload };

    case 'SET_MASS_TAGS_CRITERIA':
      return { ...state2, tagsCriteria: { ...payload } };
    //
    case 'SET_MASS_ASSOCIATE':
      return { ...state2, associate: payload };
    case 'SET_MASS_PREFERENCE':
      return { ...state2, preference: payload };
    case 'SET_MASS_BIRTHDAYRANGE':
      return { ...state2, birthdayRange: payload };
    case 'SET_MASS_ANNIRANGE':
      return { ...state2, anniversaryRange: payload };
    case 'SET_MASS_ADDED_TO_CB':
      return { ...state2, addedToClientbook: payload };
    case 'SET_MASS_LAST_CONTACTED':
      return { ...state2, lastContacted: payload };
    case 'SET_MASS_NEVER_CONTACTED':
      return { ...state2, neverContacted: payload };
    case 'SET_MASS_CONTACTMETHOD':
      return { ...state2, prefContact: payload };
    case 'SET_MASS_CLIENT_COUNT':
      return { ...state2, clientCount: payload };
    case 'SET_MASS_FULL_CLIENT_DATA':
      return { ...state2, fullClientData: [...payload] };
    case 'SET_MASS_MSG_TAGS':
      return { ...state2, tags: [...payload] };
    case 'SET_MASS_MSG_STORES':
      return { ...state2, stores: [...payload] };
    case 'SET_MASS_MSG_PREFERENCES':
      return { ...state2, preferences: [...payload] };
    case 'SET_MASS_MSG_ASSOCIATES':
      return { ...state2, associates: [...payload] };
    case 'RESET_MASS_MSG':
      return initialState;
    default:
      break;
  }
  return state2;
}

export const setMassMessage = val => {
  dispatcher('SET_MASS_MSG', val);
};

export const setMassMsgAsctName = val => {
  dispatcher('SET_MASS_MSG_AST_NAME', val);
};
export const resetMassMessaging = val => {
  dispatcher('RESET_MASS_MESSAGING', val);
};
export const setMassMessageTimezone = val => {
  dispatcher('SET_MASS_TIMEZONE', val);
};
export const setMassMessageStore = val => {
  dispatcher('SET_MASS_STORE', val);
};
export const setMassMessageTag = val => {
  dispatcher('SET_MASS_TAG', val);
};
export const setMassMessageTagsCriteria = val => {
  dispatcher('SET_MASS_TAGS_CRITERIA', val);
};
export const setMassMessageAsct = val => {
  dispatcher('SET_MASS_ASSOCIATE', val);
};
export const setMassMessagePref = val => {
  dispatcher('SET_MASS_PREFERENCE', val);
};
export const setMassMessageBday = val => {
  dispatcher('SET_MASS_BIRTHDAYRANGE', val);
};
export const setMassMessageAnni = val => {
  dispatcher('SET_MASS_ANNIRANGE', val);
};
export const setMassMessageAddedToCB = val => {
  dispatcher('SET_MASS_ADDED_TO_CB', val);
};
export const setMassMessageLastContacted = val => {
  dispatcher('SET_MASS_LAST_CONTACTED', val);
};
export const setMassMessageNeverContacted = val => {
  dispatcher('SET_MASS_NEVER_CONTACTED', val);
};
export const setMassMessageContactMethod = val => {
  dispatcher('SET_MASS_CONTACTMETHOD', val);
};
export const setMassMessageClientCount = val => {
  dispatcher('SET_MASS_CLIENT_COUNT', val);
};
export const setMassMessageFullClientData = val => {
  dispatcher('SET_MASS_FULL_CLIENT_DATA', val);
};
export const setMassMessageStores = val => {
  dispatcher('SET_MASS_MSG_STORES', val);
};
export const setMassMessageTags = val => {
  dispatcher('SET_MASS_MSG_TAGS', val);
};
export const setMassMessagePreferences = val => {
  dispatcher('SET_MASS_MSG_PREFERENCES', val);
};
export const setMassMessageAssociates = val => {
  dispatcher('SET_MASS_MSG_ASSOCIATES', val);
};
