import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';


const ConsoleLogin = props => {
  return (
    <div className="pw-reset-background align-vert-middle flex-col-center">
      <div className="flex-col-center bg-white w-100-P h-100-P max-w-684 max-h-603">
        <span className="fs-28 fw-500">Logging in...</span>
      </div>
      {/* {redirect ? <Redirect to="/Login" /> : null} */}
    </div>
  );
};
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
  setAuth(account, token) {
    dispatch({
      type: 'SET_AUTH',
      payload: { account, token },
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ConsoleLogin);
