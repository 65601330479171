import request from '../utils/request';

export const getGoogleReview = ({ params = {} }) => {
  return request({
    url: '/report/googleReviews',
    method: 'GET',
    params,
  });
};

export const getGoogleLocationReview = ({ params = {} }) => {
  return request({
    url: '/report/googleLocationReviews',
    method: 'GET',
    params,
  });
};

export const getSalesOpp = () => {
  return request({
    url: '/report/salesOpp',
    method: 'GET',
  });
};

export const getSalesOppLog = (salesOppId, clientId) => {
  return request({
    url: `/report/salesOppRoadMap?salesOppId=${salesOppId}&clientId=${clientId}`,
    method: 'GET',
  });
};
