import React, { useCallback, useState } from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.css';
import PopConfirm from '../../../../../../Layout/PopConfirm';

const CollectionProductHeader = props => {
  const { selectedProduct, selectedCollection, onDeleteToCollection, setSelectedProduct } = props;
  const [showPopConfirm, setShowPopConfirm] = useState(false);

  const onDelete = useCallback(async () => {
    await onDeleteToCollection(selectedProduct.id, selectedProduct.vendor_id || null);
    setShowPopConfirm(false);
    setSelectedProduct(null);
  }, [selectedProduct, onDeleteToCollection]);

  return (
    <div className="clients-v2-product-details--header">
      <span className="clients-v2-product-details--header-title">Product Details</span>

      {!selectedProduct?.viewDetails && (
        <div className="clients-v2-product-details--header-options">
          <Button
            type="link"
            icon={<FontAwesomeIcon icon="far fa-trash-alt" />}
            onClick={() => setShowPopConfirm(true)}
            danger
          >
            Remove from Collection
          </Button>
        </div>
      )}
      {showPopConfirm && (
        <PopConfirm
          confirm={`Are you sure you want to delete "${selectedProduct.name}" from "${selectedCollection.collection_name}" collection?`}
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={onDelete}
        />
      )}
    </div>
  );
};

export default CollectionProductHeader;
