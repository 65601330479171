import { dispatcher } from '../core/actions';
import { TRANSACTION_COLUMN_KEY } from '../components/Routes/Reports/BankTransaction/const';

const initialState = {
  list: [],
  bankAccount: '',
  nextPageToken: '',
  isError: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_BANK_TRANSACTION_LIST':
      const list = action.payload.isConcatenatingList ? state.list : [];

      return {
        ...state,
        list: [
          ...list,
          ...action.payload.transactionData.map(
            ({
              payoutPlannedDate: date,
              grossSales: grossSales,
              netSales: net,
              status,
              totalDisbursement: totalDisbursement,
            }) => ({
              [TRANSACTION_COLUMN_KEY.DATE]: date,
              [TRANSACTION_COLUMN_KEY.TOTAL_DISBURSEMENT]: totalDisbursement,
              [TRANSACTION_COLUMN_KEY.NET_AMOUNT]: net,
              [TRANSACTION_COLUMN_KEY.STATUS]: status,
              [TRANSACTION_COLUMN_KEY.GROSS_AMOUNT]: grossSales,
            }),
          ),
        ],
      };
    case 'SET_BANK_ACCOUNT':
      return {
        ...state,
        bankAccount: action.payload,
      };
    case 'SET_PAGINATION':
      return {
        ...state,
        nextPageToken: action.payload,
      };
    default:
      return state;
  }
}

export const setBankTransactionList = val => {
  dispatcher('SET_BANK_TRANSACTION_LIST', val);
};

export const setBankAccount = val => {
  dispatcher('SET_BANK_ACCOUNT', val);
};

export const setPagination = val => {
  dispatcher('SET_PAGINATION', val);
};
