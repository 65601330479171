import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Select from '../Select';
import './styles.css';

const SearchSelect = props => {
  return (
    <Select {...props} prefixIcon={props.prefixIcon || <SearchOutlined className="cb-search-select__prefix-icon" />} />
  );
};

export default SearchSelect;
