import React from 'react';
import { Link } from 'react-router-dom';

const IntegrationsLightspeed = () => {
  return (
    <>
      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Overview</h3>

        <p>
          Connect your Lightspeed account with Clientbook. This connection will effortlessly merge all of your
          business's Lightspeed data into Clientbook's desktop and mobile platforms. Specifically, this will bring over
          your client, product and sales data. Click on Install to get started! <br />
          <br />
          Please be sure the Lightspeed user has the rights to access all relevant information. If the user is removed
          from the connected Lightspeed account, the account will be removed from Clientbook.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Key Features</h3>

        <p>By adding your Lightspeed information to Clientbook, you can:</p>

        <ul className="cb-integrations-details__list">
          <li>Send payment requests and track specific Life Events to maximize POS activity.</li>

          <li>Document Sales Opportunities to intensify your sales team's efficiency. </li>

          <li>Send Product Collections based on your client's tastes to effectively market your products.</li>

          <li>
            Maximize communication with your clients by creating Auto Messages. These messages can be sent automatically
            on days based on birthdays, anniversaries, special orders, sales etc.
          </li>
        </ul>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Helpful Links</h3>

        <ul className="cb-integrations-details__list">
          <li>
            <a href="https://www.lightspeedhq.com/support" target="_blank" rel="noopener noreferrer">
              Lightspeed Help Center
            </a>
          </li>

          <li>
            <Link to="/Help/contact-support">Clientbook Help Center</Link>
          </li>
        </ul>
      </section>
    </>
  );
};

export default IntegrationsLightspeed;
