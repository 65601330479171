import React from 'react';
import './styles.css';

const RadioButton = props => {
  return (
    <div className="radio-btn">
      <input {...props} type="radio" />
    </div>
  );
};

export default RadioButton;
