import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import LayoutTable from '../../../Layout/Table';

export const CHANGE_AT = 'changeAt';

const Table = ({ data, tableName }) => {
  return (
    <>
      {/* Date, time, activity, user */}
      <LayoutTable
        tableName={tableName}
        tableHeaders={[
          {
            keyName: CHANGE_AT,
            title: 'Date',
            sortable: true,
            rowStyle: { paddingRight: 20 },
            render: date => moment(date).format('L'),
          },
          {
            keyName: 'changeAt',
            title: 'Time',
            rowStyle: { paddingRight: 20 },
            render: date => moment(date).format('LT') + ' ET',
          },
          {
            keyName: 'reason',
            title: 'Activity',
            rowStyle: { paddingRight: 20 },
          },
          {
            keyName: 'name',
            title: 'User',
          },
        ]}
        noPointer={true}
        data={data}
      />
    </>
  );
};

export default Table;
