import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const NoResultsMatch = ({ zeroResults }) => {
  return (
    zeroResults && (
      <div className="m-top-15">
        <FontAwesomeIcon className="missing-data-red m-right-7" icon={['fas', 'exclamation-circle']} />
        <span className="missing-data-red">No results match this filter.</span>
      </div>
    )
  );
};
export default NoResultsMatch;
