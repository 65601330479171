import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import DescOverInput from '../../../DescOverInput';
import DropdownSelect from '../../../DropdownSelect';
import { associateNameFormat } from '../../../../../utils';
import ProductDropToView from './ProductDropToView';
import { getRequest } from '../../../../../core/apiRequests';
import { PAYMENTS_API_URL } from '../../../../../constants';

const Axios = require('axios');

const EXPIRATION_DATE_FORMAT = 'YYYY-MM-DD';

const PopMessagePayment = props => {
  const [clicked, setClicked] = useState(false);
  const [price, setPrice] = useState(null);
  const [product, setProduct] = useState({ [uuidv4()]: {}, expirationDate: '' });
  const [dropProductCnt, setDropProductCnt] = useState(1);
  const [productView, setProductView] = useState(true);
  const [expirationCondition, setExpirationCondition] = useState(false);
  const [customerPreview, setCustomerPreview] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [dateInt, setDateInt] = useState('Never');
  const [startDate, setStartDate] = useState(new Date());
  const [missingData, setMissingData] = useState(false);
  const [missingPrice, setMissingPrice] = useState(false);
  const [priceTooHigh, setPriceTooHigh] = useState(false);
  const [storeAddress, setStoreAddress] = useState('');
  const [storeId, setStoreId] = useState(0);
  const [firstExpirationClick, setFirstExpirationClick] = useState(false);
  const [prodPrices, setProdPrices] = useState({});
  const [stores, setStores] = useState([]);
  const [kioskImage, setKioskImage] = useState('');
  const [kioskShape, setKioskShape] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.auth.merchantId) {
      const reqObj = {
        params: ['stores', props.auth.merchantId, 'storeAddress'],
        query: { clientId: props.clientId },
        loadingStatusOff: true,
      };
      (async () => {
        const data = await getRequest(reqObj);
        if (data.length > 0) {
          setStoreAddress(data[0].address);
          setStoreId(data[0].store_id);
        }
        const notAllStores = props.auth.stores.filter(st => Number(st.id) !== 0);
        setStores(notAllStores);
      })();
    }
  }, [props.auth.merchantId]);

  useEffect(() => {
    if (props.auth.merchantId && storeId != 0) {
      const reqObj = {
        params: ['stores', props.auth.merchantId, 'storeAddressUpdate'],
        query: { storeId },
        loadingStatusOff: true,
      };
      (async () => {
        const data = await getRequest(reqObj);
        if (data.length > 0) {
          setStoreAddress(data[0].address);
        }
      })();
    }
  }, [storeId]);

  useEffect(() => {
    if (props.auth.merchantId) {
      const reqObj = {
        params: ['merchant', props.auth.merchantId, 'kioskImage'],
      };
      (async () => {
        const data = await getRequest(reqObj);
        if (data.length > 0) {
          setKioskImage(data[0].logo_url);
          setKioskShape(data[0].round_logo);
        }
      })();
    }
  }, [props.auth.merchantId]);

  const productCount = () => {
    let tempNum = dropProductCnt;
    if (tempNum <= 3) {
      tempNum += 1;
    }
    setDropProductCnt(tempNum);
    setProduct({ ...product, [uuidv4()]: {} });
  };

  const setExpiration = event => {
    let expirationDate = '';
    switch (event.target.value) {
      case 'never':
        expirationDate = '';
        setDateInt('Never');
        setOpenCalendar(false);
        break;
      case 'endOfDay':
        expirationDate = moment().endOf('day').format(EXPIRATION_DATE_FORMAT);
        setDateInt('End of day');
        setOpenCalendar(false);
        break;
      case 'tomorrow':
        expirationDate = moment().add(1, 'day').format(EXPIRATION_DATE_FORMAT);
        setDateInt('Tomorrow');
        setOpenCalendar(false);
        break;
      case 'endOfMonth':
        expirationDate = moment().endOf('month').format(EXPIRATION_DATE_FORMAT);
        setDateInt('End of month');
        setOpenCalendar(false);
        break;
      case 'custom':
        setOpenCalendar(true);
        setDateInt('Custom');
        expirationDate = moment(startDate).format(EXPIRATION_DATE_FORMAT);
        break;
      default:
        setOpenCalendar(true);
        setDateInt('Custom');
        expirationDate = moment(startDate).format(EXPIRATION_DATE_FORMAT);
        break;
    }
    setProduct({ ...product, expirationDate });
    setFirstExpirationClick(true);
  };

  const productDecrease = prodKey => {
    let tempNum = dropProductCnt;
    if (tempNum > 0) {
      tempNum -= 1;
      setProduct({ ...product });
    }
    setDropProductCnt(tempNum);
    if (tempNum == 0) {
      setDropProductCnt(1);
      setProduct({ ...product, [uuidv4()]: {} });
      setPrice(0);
    }
  };

  const PreviewProducts = () => {
    const preview_prod = [];
    Object.keys(product).forEach(prod => {
      if (prod !== 'expirationDate') {
        preview_prod.push(
          <div className="pos-rel">
            <div className=" add-edit-wrap m-top-8 w-289 m-btm-8">
              <div
                className="h-50 w-50 m-left-23 border-r8"
                style={{ height: '40px', width: '40px', marginTop: product[prod].label.length > 30 ? '5px' : '0px' }}
              >
                {product[prod].hasOwnProperty('image') && product[prod].image !== null ? (
                  <img
                    alt=""
                    className="border-r4"
                    src={product[prod].image}
                    height="40"
                    width="40"
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = 'https://dashboard-v2-images.s3.amazonaws.com/product-thumbnail-26.png';
                    }}
                  />
                ) : (
                  <img
                    alt=""
                    className="border-r4"
                    src="https://dashboard-v2-images.s3.amazonaws.com/product-thumbnail-26.png"
                    height="40"
                    width="40"
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = 'https://dashboard-v2-images.s3.amazonaws.com/product-thumbnail-26.png';
                    }}
                  />
                )}
              </div>
              {product[prod].label.length > 30 ? (
                <span className="fs-13 break-word" style={{ margin: '3px 10px 0px 20px' }}>
                  {' '}
                  {`${`${product[prod].label.slice(0, 45)}...`}`}
                </span>
              ) : (
                <span className="m-left-29 fs-13 break-word"> {`${product[prod].label}`}</span>
              )}
            </div>
            <div
              className="divider pos-abs m-left-23"
              style={{
                width: '240px',
                color: '#BDBDBD',
              }}
            />
          </div>,
        );
      }
    });
    return preview_prod;
  };

  // trying to do error state here
  const onConfirmHandler = () => {
    let tempPrice = true;
    let tempProd = true;
    let tempPriceTooHigh = true;
    if (!price || price < 1) {
      setMissingPrice(true);
      tempPrice = false;
    }
    if (price > 99999.99) {
      setPriceTooHigh(true);
      tempPriceTooHigh = false;
    }
    Object.keys(product).forEach(prod => {
      if (prod !== 'expirationDate') {
        const tempObj = prod;
        if (Object.values(product[tempObj]) > -1) {
          setMissingData(true);
          tempProd = false;
          if (Object.keys(product).length > 2) {
            delete product[tempObj];
            setDropProductCnt(Number(Object.keys(product).length) - 1);
            setMissingData(true);
            tempProd = false;
          }
        }
      }
    });
    previewPageCheck(tempPrice, tempProd, tempPriceTooHigh);
  };

  const previewPageCheck = (price, prod, highPrice) => {
    if (price && prod && highPrice) {
      setCustomerPreview(true);
      setProductView(false);
      setExpirationCondition(false);
    }
  };

  const createURL = () => {
    if (loading) return;
    setLoading(true);
    const fixedLastName = props.clientFullName.split(' ')[1] ? props.clientFullName.split(' ')[1].trim() : '';
    const formatDate =
      dateInt == 'Custom'
        ? moment(startDate).format(EXPIRATION_DATE_FORMAT)
        : product.expirationDate
        ? product.expirationDate
        : '';
    let validatedPrice;
    if (price % 1 != 0) {
      validatedPrice = parseFloat(price).toFixed(2);
    } else {
      validatedPrice = Math.round(price);
    }
    const urlProd = [];
    Object.keys(product).forEach(prod => {
      if (prod != 'expirationDate') {
        urlProd.push({
          description: product[prod].label,
          imageURL: product[prod].image ? product[prod].image : '',
        });
      }
    });
    Axios({
      method: 'post',
      url: `${PAYMENTS_API_URL}/v1/paymentRequest`,
      data: {
        clientID: Number(props.clientId),
        clientFirstName: props.clientName,
        clientLastName: fixedLastName,
        merchantID: props.auth.merchantId,
        storeID: Number(storeId),
        expireAt: formatDate,
        totalPrice: Number(validatedPrice),
        products: urlProd,
      },
    })
      .then(result => {
        setLoading(false);
        props.setMessageTxt(result.data.paymentRequestMessage);
        props.setPaymentRequestID(result.data.paymentRequestId);
        props.onCancel();
        if (props.messageModal) {
          props.messageModal.open();
        }
        if (props.setShowMessage) {
          props.setShowMessage(true);
        }
        if (props.setShowMessage) {
          props.setShowMessage(true);
        }
      })
      .catch(err => {
        setLoading(false);
        console.log(`[payments result] FAILED${err}`);
      });
  };

  const setTwoNumberDecimal = () => {
    let validatedPrice;
    if (price % 1 != 0) {
      validatedPrice = parseFloat(price).toFixed(2);
      return validatedPrice;
    }
    return Math.round(price);
  };

  return (
    <div>
      {productView && (
        <div className={`${props.className} hide-scrollbar rem-pad-width p-btm-6"`} style={props.popStyle}>
          <div>
            <div className="flex-col-center">
              <span className="fs-22 fw-600 m-right-15 fs-16">Payment Request for {props.clientName}</span>
              {missingData || missingPrice ? (
                <span style={{ color: '#ff7a7a' }} className="fs-10 fw-400 m-right-15">
                  Please enter all required information
                </span>
              ) : null}
              {priceTooHigh ? (
                <span style={{ color: '#ff7a7a' }} className="fs-10 fw-400 m-right-15">
                  Price cannot exceed $99999.99
                </span>
              ) : null}
            </div>
            {/* requested payment */}
            <div className={`flex-col-center ${missingData || missingPrice || priceTooHigh ? 'm-top-5' : 'm-top-24'}`}>
              {stores.length > 1 ? (
                <div className="flex-col-center">
                  <span className="fs-12 fw-400 m-btm-8 w-100-P">Choose a store location</span>
                  <div className="pos-rel">
                    <img
                      src="https://dashboard-v2-images.s3.amazonaws.com/storeSelect.svg"
                      className="pos-abs"
                      style={{ top: '15px', left: '15px', zIndex: '1' }}
                    />
                    <DropdownSelect
                      id="NewPaymentRequestStoreDropdown"
                      classProps="w-289 align-left"
                      optionalStyles={{ paddingLeft: '25px' }}
                      options={stores.map((st, i) => ({
                        label: st.name,
                        value: st.id,
                      }))}
                      placeholder="Select a Store..."
                      value={storeId}
                      onChange={obj => {
                        setStoreId(obj.value);
                      }}
                    />
                  </div>
                </div>
              ) : null}
              <div className={`flex-col-center ${stores.length > 1 ? 'm-top-24' : ''}`}>
                <span className="fs-12 fw-400 m-btm-8 w-100-P">Enter the amount you want to request</span>
                <DescOverInput
                  classProps="w-289 h-50"
                  description={
                    <span
                      style={{
                        fontWeight: '500',
                        zIndex: '1',
                        paddingLeft: '18px',
                        paddingTop: '8px',
                        position: 'absolute',
                        fontSize: '17px',
                      }}
                    >
                      $
                    </span>
                  }
                  input={
                    <input
                      id="NewPaymentRequestPrice"
                      style={{ border: '1px solid #818181', borderRadius: '3px', paddingLeft: '35px' }}
                      onChange={e => {
                        setPrice(e.target.value);
                        setMissingPrice(false);
                        setPriceTooHigh(false);
                      }}
                      min="1"
                      step="1"
                      max="99999.99"
                      type="number"
                      //  pattern="^\d+(?:\.\d{1,2})?$"
                      onKeyDown={event => {
                        return event.key >= 48 && event.key <= 57;
                      }}
                      value={price}
                      className={`w-100-P inputHeight rem-pad-width fs-13 focus-input-outline-blue ${
                        missingPrice || priceTooHigh ? 'missing-data-state border-none' : ''
                      }`}
                      placeholder="0"
                      decorator="$"
                      autoFocus
                    />
                  }
                />
              </div>
            </div>
            {/* products */}
            <div className="m-top-24">
              <div className="flex-col-center">
                <span className="fs-12 fw-400 m-btm-8 w-290">Add a brief description of the product</span>
                <ProductDropToView
                  product={product}
                  setProduct={setProduct}
                  price={price}
                  merchantId={props.auth.merchantId}
                  setMissingData={setMissingData}
                  missingData={missingData}
                  setMissingPrice={setMissingPrice}
                  productDecrease={productDecrease}
                  setProdPrices={setProdPrices}
                  prodPrices={prodPrices}
                />
                {/* add button */}
                {dropProductCnt < 3 ? (
                  <button
                    className="border-none bg-white pointer m-top-5 w-290 align-left"
                    onClick={() => productCount()}
                    type="button"
                  >
                    <FontAwesomeIcon
                      className="fs-12 pointer"
                      icon={['far', 'plus']}
                      style={{ color: '#1769af', margin: '3px 5px 0px 0px' }}
                    />
                    <span className="fw-600 fs-13 CBdarkBlue">Add</span>
                  </button>
                ) : null}
              </div>
              {/* link expiration */}
              <div className="flex-col-center">
                <button
                  className="outline-none bg-white border-none w-100-P flex-row-spaceevenly-nowrap h-46"
                  style={{ width: '565px' }}
                  onClick={() => {
                    setProductView(false);
                    setExpirationCondition(true);
                  }}
                >
                  <span className="m-top-10">Link expiration</span>
                  <div className="m-top-10">
                    {dateInt == 'Custom' ? moment(startDate).format('MMMM DD, YYYY') : dateInt}
                    <FontAwesomeIcon
                      className="fs-12 pointer"
                      icon={['far', 'chevron-right']}
                      style={{ margin: '3px 0px 0px 5px' }}
                    />
                  </div>
                </button>
              </div>
              <br />
              {/* <div className="divider" style={{width: '290px', marginBottom: '5px'}}/> */}
              <div className="flex-row-spaceevenly-nowrap">
                <button
                  className="save-button"
                  id="NewPaymentRequestPreviewButton"
                  onClick={() => {
                    onConfirmHandler();
                  }}
                >
                  Preview
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {expirationCondition && (
        // expiration view
        <div className={`${props.className} flex-col-center hide-scrollbar`} style={props.popStyle}>
          <div>
            <span className="fs-22 fw-500 m-right-15 fs-16">Link Expiration</span>
            <div className="align-left m-top-10 fs-12" style={{ height: '390px' }} onChange={e => setExpiration(e)}>
              <input
                type="radio"
                id="never"
                name="expiration"
                value="never"
                className="m-btm-10"
                defaultChecked={dateInt === 'Never'}
              />
              <label htmlFor="never" className="m-left-10 pos-abs">
                Never
              </label>
              <br />
              <input
                type="radio"
                id="endOfDay"
                name="expiration"
                value="endOfDay"
                className="m-btm-10"
                defaultChecked={dateInt === 'End of day'}
              />
              <label htmlFor="endOfDay" className="m-left-10 pos-abs">
                End of day
              </label>
              <br />
              <input
                type="radio"
                id="tomorrow"
                name="expiration"
                value="tomorrow"
                className="m-btm-10"
                defaultChecked={dateInt === 'Tomorrow'}
              />
              <label htmlFor="tomorrow" className="m-left-10 pos-abs">
                Tomorrow
              </label>
              <br />
              <input
                type="radio"
                id="endOfMonth"
                name="expiration"
                value="endOfMonth"
                className="m-btm-10"
                defaultChecked={dateInt === 'End of month'}
              />
              <label htmlFor="endOfMonth" className="m-left-10 pos-abs">
                End of month
              </label>
              <br />
              <input
                type="radio"
                id="custom"
                name="expiration"
                value="custom"
                className="m-btm-10"
                defaultChecked={dateInt === 'Custom'}
              />
              <label htmlFor="custom" className="m-left-10 pos-abs">
                Custom
              </label>
              {dateInt == 'Custom' ? (
                <DatePicker
                  className="w-267 inputHeight rem-pad-width focus-input-outline-blue p-left-16"
                  style={{ marginLeft: '0px' }}
                  selected={startDate}
                  minDate={new Date()}
                  onChange={date => setStartDate(date)}
                  showMonthDropdown
                  showYearDropdown
                  todayButton="Today"
                  dropdownMode="select"
                />
              ) : null}
            </div>
            <div className="flex-row-nospacebetween-nowrap m-top-15 m-btm-28 w-100-P">
              <button
                className="cancel-button m-right-15 m-top-15"
                disabled={clicked}
                onClick={() => {
                  setProductView(true);
                  setExpirationCondition(false);
                }}
              >
                Go back
              </button>
              <button
                className="save-button m-top-15"
                id="NewPaymentRequestCreateButton"
                disabled={!firstExpirationClick}
                onClick={() => {
                  setProductView(true);
                  setExpirationCondition(false);
                }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
      {customerPreview && (
        // PREVIEW
        <div className={`${props.className} flex-col-center hide-scrollbar rem-pad-width p-btm-6`}>
          <span className="fs-22 fw-600 fs-16">Customer Preview</span>
          <div className="align-left m-top-10 " style={{ minHeight: '402px' }}>
            {/* brand data */}
            <div className="flex-col-center">
              <img
                src={kioskImage || props.auth.brandImage}
                // className="border-r8 m-top-15 photoFit"
                className={kioskShape === 1 ? 'border-circle m-top-15 photoFit' : 'border-r8 m-top-15 photoFit'}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = 'https://dashboard-v2-images.s3.amazonaws.com/empty-store-29.svg';
                }}
                alt="brand"
                style={{ width: '72px', height: '72px', border: '.5px solid #BDBDBD' }}
              />
              <span className="fw-600 fs-13 m-top-8">{props.auth.brandName}</span>
              <span className="fs-12 fw-400 align-center m-btm-5">{storeAddress}</span>
            </div>
            {/* product preview */}
            <div className="flex-col-center border-r8" style={{ minHeight: '80px', border: '1px solid #BDBDBD' }}>
              <div className="flex-col-left m-top-20 w-100-P p-left-30" style={{ lineHeight: '1.5' }}>
                <span className="fs-24 fw-700">{`$${setTwoNumberDecimal()}`}</span>
                {product.expirationDate === '' ? null : (
                  <span className="fs-12 fw-400">{`Expires after ${
                    dateInt === 'Custom'
                      ? moment(startDate).format('MMMM DD, YYYY')
                      : moment(product.expirationDate).format('MMMM DD, YYYY')
                  }`}</span>
                )}
              </div>
              {PreviewProducts()}
              <div className=" w-100-P" style={{ bottom: '80px' }}>
                <p className="fs-12 fw-400 m-left-23" style={{ margin: '9px 0px 9px 23px' }}>
                  <span className="fw-600">Sent on </span>
                  {moment().format('MMMM DD, YYYY')}
                </p>
                <p className="fs-12 fw-400 m-left-23" style={{ margin: '9px 0px 9px 23px' }}>
                  <span className="fw-600">To </span>
                  {props.clientFullName}
                </p>
                <p className="fs-12 fw-400 m-left-23" style={{ margin: '9px 0px 9px 23px' }}>
                  <span className="fw-600">From </span>
                  {associateNameFormat(props.auth.firstName, props.auth.lastName)}
                </p>
              </div>
            </div>
          </div>
          <div className="flex-row-spaceevenly-nowrap m-top-15 m-btm-28" style={{ width: '84%' }}>
            <button
              className="cancel-button m-right-15 m-top-15"
              disabled={clicked}
              onClick={() => {
                setProductView(true);
                setExpirationCondition(false);
                setCustomerPreview(false);
              }}
            >
              Edit
            </button>
            <button
              className="save-button m-top-15"
              id="NewPaymentRequestCreateButton"
              disabled={loading}
              onClick={createURL}
            >
              <div className="pos-rel flex-col-center">
                <p className="pos-abs" style={{ left: '23px' }}>
                  {loading && 'Loading'}{' '}
                </p>
                {!loading ? (
                  <span> Create link </span>
                ) : (
                  <div id="dashSpinOn" className="dash-ring-button" style={{ right: '40px', bottom: '12px' }}>
                    {Array.from(Array(4), (_, i) => (
                      <div key={`weird-and-seemingly-useless-div-${i}`} />
                    ))}
                  </div>
                )}
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
  stores: state.stores,
});
export default connect(mapStateToProps, {})(PopMessagePayment);
