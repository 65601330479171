import React from 'react';
import { postRequest } from '../../../../../core/apiRequests';

const DefaultConfirmationModal = props => {
  const { toggleDefaultConfirm, defaultMethod, storeId, merchantId, methods, setMethods } = props;
  const apiDefaultMethod = defaultMethod.split(' ')[0].toUpperCase();
  const defaultFormat = defaultMethod == 'Email' ? 'email' : defaultMethod;

  const onSaveHandler = () => {
    if (merchantId) {
      (async () => {
        const reqObj = {
          params: ['stores', 'set-default', merchantId, storeId],
          data: [apiDefaultMethod],
        };
        const res = await postRequest(reqObj);
        if (res) {
          let newArr = methods.map(method => {
            if (method.type.toLowerCase().includes(apiDefaultMethod.toLowerCase())) {
              return {
                ...method,
                isDefault: 1,
              };
            } else {
              return {
                ...method,
                isDefault: 0,
              };
            }
          });
          setMethods(newArr);
          toggleDefaultConfirm();
          props.toggleRefresh(true);
        }
      })();
    }
  };

  return (
    <div className="pop-confirm-background" id="modal-background">
      <div className="pop-confirm-container rem-pad-width" style={{ width: '430px' }}>
        <div
          className="flex-col-center h-100-P"
          style={{
            justifyContent: 'flex-start',
            width: '295px',
            margin: '0 auto',
          }}
        >
          <p
            style={{ width: '295px', marginTop: '24px', marginBottom: '20px' }}
            className='fs-18 fw-600'
          >
            Accepted Communication Methods
          </p>
          <p>Are you sure you want to set {defaultFormat} as the default?</p>

          <div
            className="flex-col-center"
            style={{ width: '295px', marginTop: '24px', marginBottom: '20px' }}
          >
            <button
              onClick={() => onSaveHandler()}
              className="fw-600 fs-12 darkBlueButton pointer"
            >
              Save
            </button>
            <button
              onClick={() => toggleDefaultConfirm()}
              className="fs-12 fw-600 pointer cancel-button-v2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultConfirmationModal;
