import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopOverVideo from '../../PopOverVideo';

const ChatVideo = ({ videoSrc, thumbnailUrl, outBound, scrollToBottom }) => {
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);

  const findPlacement = () => {
    if (outBound) {
      return { justifyContent: 'flex-start' };
    }
    return { justifyContent: 'flex-end' };
  };

  const imgRef = useRef();

  useEffect(() => {
    if (load && imgRef?.current?.complete) {
      scrollToBottom();
    }
  }, [load, imgRef?.current?.complete]);

  return (
    <div className="flex-row-nospacebetween-nowrap m-left-15" style={findPlacement()}>
      <div className="pos-rel">
        <img
          onError={e => {
            e.target.onerror = null;
            e.target.src = 'https://dashboard-v2-images.s3.amazonaws.com/image-placeholder@2x.png';
          }}
          ref={imgRef}
          onLoad={() => setLoad(true)}
          onClick={() => setOpen(true)}
          className="border-r4 photoFit"
          src={thumbnailUrl ?? 'https://dashboard-v2-images.s3.amazonaws.com/video-default-thumbnail.jpeg'}
          style={{ maxWidth: '200px', maxHeight: '200px', marginBottom: '5px', height: load ? 'auto' : '200px' }}
          alt="#"
        />
        {thumbnailUrl && (
          <FontAwesomeIcon
            onClick={() => setOpen(true)}
            className="pos-abs z-index-5 h-95-P w-100-P left-0 white-faded"
            icon="circle-play"
            size="2x"
            color="white"
          />
        )}
      </div>
      <PopOverVideo open={open} onClose={() => setOpen(false)} videoSrc={videoSrc} videoPosterSrc={thumbnailUrl} />
    </div>
  );
};
export default ChatVideo;
