import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Button } from 'antd';
import { connect } from 'react-redux';
// import Button from '../../../../../CustomizedAntDesign/Button';
import ProductDetailsLayoutDropdown from '../Dropdown';
import PopConfirm from '../../../../../Layout/PopConfirm';
import { deleteProductById } from '../../../../../../api/products';
import { notificationSuccess } from '../../../../../../core/actions';
import ClientCollectionModal from '../ClientCollectionModal';
import SendProductModal from '../SendProductModal';
import { handleCatchError } from '../../shared/catchError';
import FlaggedAddCollectionButton from './FlaggedAddCollectionButton';
import './styles.css';

const ProductDetailsLayoutActions = ({ product, ...props }) => {
  const [showClientCollectionsModal, setShowClientCollectionsModal] = useState(false);
  const [showSendProductsModal, setShowSendProductsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const history = useHistory();
  const managerProduct = ['MANAGER', 'ADMIN'].includes(props.auth.role);

  const handleDelete = async () => {
    try {
      await deleteProductById(product.id);

      notificationSuccess('Product deleted successfully');

      history.push('/products');
    } catch (error) {
      handleCatchError(error);
    } finally {
      setShowDeleteModal(false);
    }
  };

  const tooltipText = 'Needs image to send';

  return product ? (
    <>
      <FlaggedAddCollectionButton flags={props.auth.merchantFlags} showModal={setShowClientCollectionsModal} />

      {product.imageUrl !== null ? (
        <Button
          type="primary"
          className="cb-button"
          icon={<FontAwesomeIcon icon={['far', 'share']} />}
          onClick={() => setShowSendProductsModal(true)}
        >
          Share
        </Button>
      ) : (
        <Tooltip
          title={tooltipText}
          placement="bottom"
          overlayInnerStyle={{ maxInlineSize: '125px', textAlign: 'center' }}
        >
          <Button
            type="primary"
            className="cb-button-share"
            icon={<FontAwesomeIcon icon={['far', 'share']} />}
            onClick={() => setShowSendProductsModal(false)}
            disabled
            style={{ height: '55px', width: '105px', borderRadius: '5px', padding: '10px' }}
          >
            <span className="cb-button-share-spacer" />
            Share
          </Button>
        </Tooltip>
      )}

      {!product.posRefNum && managerProduct && (
        <ProductDetailsLayoutDropdown product={product} setShowDeleteModal={setShowDeleteModal} />
      )}

      {showDeleteModal && (
        <PopConfirm
          confirm={`Are you sure you want to delete "${product.name}" forever?`}
          description="This will permanetly delete the product"
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
        />
      )}

      <ClientCollectionModal
        productId={product.id}
        visible={showClientCollectionsModal}
        setVisible={setShowClientCollectionsModal}
      />

      <SendProductModal productId={product.id} visible={showSendProductsModal} setVisible={setShowSendProductsModal} />
    </>
  ) : null;
};

const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(ProductDetailsLayoutActions);
