import React from 'react';
import Chip from '../../../../../../../Layout/Chip';

const GoogleButton = (props) => {

  return (
    props.installed ? (
      <Chip>Installed</Chip>
    ) : (
      <div
        className='h-40 flex-row-center pointer'
        style={{
          borderRadius: '20px',
          background: '#F2F2F2',
          padding: '0 16px',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
        {...props}
      >
        <GoogleSvg style={{ marginRight: '10px'}} />
        Sign in with Google
      </div>
    )
  );
};

const GoogleSvg = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path d="M19.6 10.2271C19.6 9.51804 19.5364 8.83624 19.4182 8.18164H10V12.0498H15.3818C15.15 13.2998 14.4455 14.3589 13.3864 15.068V17.5771H16.6182C18.5091 15.8362 19.6 13.2725 19.6 10.2271Z" fill="#4285F4"/>
      <path d="M9.99988 19.9999C12.6999 19.9999 14.9635 19.1044 16.618 17.5772L13.3862 15.0681C12.4908 15.6681 11.3453 16.0226 9.99988 16.0226C7.39528 16.0226 5.19078 14.2635 4.40438 11.8999H1.06348V14.4908C2.70898 17.759 6.09078 19.9999 9.99988 19.9999Z" fill="#34A853"/>
      <path d="M4.4045 11.9002C4.2045 11.3002 4.0909 10.6593 4.0909 10.0002C4.0909 9.34108 4.2045 8.70018 4.4045 8.10018V5.50928H1.0636C0.3864 6.85928 0 8.38658 0 10.0002C0 11.6138 0.3864 13.1411 1.0636 14.4911L4.4045 11.9002Z" fill="#FBBC04"/>
      <path d="M9.99988 3.9773C11.468 3.9773 12.7862 4.4818 13.8226 5.4727L16.6908 2.6045C14.959 0.9909 12.6953 0 9.99988 0C6.09078 0 2.70898 2.2409 1.06348 5.5091L4.40438 8.1C5.19078 5.7364 7.39528 3.9773 9.99988 3.9773Z" fill="#E94235"/>
    </g>
  </svg>
)

export default GoogleButton;
