import React from 'react';
import { func } from 'prop-types';
import { useHistory } from 'react-router-dom';

import PopConfirm from '../../../Layout/PopConfirm';

const AddAccountIntro = ({ onCancel, data }) => {
  const history = useHistory();
  const openingStatement = () => {
    if (data.length < 1) {
      return (
        <p className="fs-12 italic m-top-0">
          The Default Bank Account will be assigned to all new stores until you assign them an individual bank account.
        </p>
      );
    }
    return null;
  };

  const bankConfirm = () => {
    if (data.length === 0) {
      history.push('/BankAccount/add');
    } else {
      history.push('/BankAccount/add-multi');
    }
  };
  return (
    <PopConfirm
      confirm="Add Default Bank Account"
      customHeaderContent="fs-18 fw-600 align-center"
      popStyle={{ textAlign: 'left' }}
      description={
        <>
          {openingStatement()}
          <p className="fw-500">
            This process will take about 15 minutes to complete. You will need the following information:{' '}
          </p>
          <ul className="fw-500">
            <li>Bank Information (Routing and Account Numbers)</li>
            <li>Tax ID Number</li>
            <li>Principal owner(s) or designated representative information (Name, Date of Birth, SSN)</li>
          </ul>
        </>
      }
      onConfirm={() => bankConfirm()}
      onCancel={onCancel}
    />
  );
};

AddAccountIntro.propTypes = {
  onCancel: func,
};

export default AddAccountIntro;
