import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import Select from '../../../../../../../CustomizedAntDesign/Select';
import PaginationV2 from '../../../../../../../CustomizedAntDesign/PaginationV2';
import './styles.css';

const IntegrationsSettingsStoresLoader = () => {
  return (
    <div className="cb-integrations-settings-stores__loader">
      <LoadingOutlined />
      <span>Loading store info...</span>
    </div>
  );
};

const IntegrationsSettingsStores = props => {
  const {
    integrationName,
    stores,
    posStores,
    storesMap,
    setStoresMap,
    onChange,
    sync_clients,
    sync_products,
    sync_purchase_history,
  } = props;

  const [form] = Form.useForm();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const total = useMemo(() => stores.length, [stores]);

  const paginatedStores = useMemo(() => stores.slice(page - 1, page * pageSize), [page, pageSize, stores]);

  const showPagination = useMemo(() => stores.length > 10, [stores]);

  const loadingStores = useMemo(() => !stores.length || !posStores.length, [stores, posStores]);

  const options = useMemo(
    () =>
      posStores.map(item => ({
        ...item,
        disabled: Object.values(storesMap).includes(item.value),
      })),
    [posStores, storesMap],
  );

  const handleChangeValues = () => {
    const values = form.getFieldsValue();
    const newStoresMap = { ...storesMap, ...values };

    const newStores = Object.entries(newStoresMap).map(([id, pos_ref_num]) => ({
      id: parseInt(id, 10),
      pos_ref_num: pos_ref_num ? `${pos_ref_num}` : null,
    }));

    setStoresMap(newStoresMap);
    onChange({ sync_clients, sync_products, sync_purchase_history, stores: newStores });
  };

  useEffect(() => {
    form.setFieldsValue(storesMap);
  }, [form, storesMap]);

  return (
    <div className="cb-integrations-settings-stores">
      {loadingStores && <IntegrationsSettingsStoresLoader />}

      {!loadingStores && (
        <div className="cb-integrations-settings-stores__list">
          <div className="cb-integrations-settings-stores__list-header">
            <h4>Stores in Clientbook</h4>
            <h4>Stores in {integrationName}</h4>
          </div>

          <Form form={form} autoComplete="off" onValuesChange={handleChangeValues}>
            {paginatedStores.map(store => (
              <div key={store.value} className="cb-integrations-settings-stores__input-group">
                <span className="cb-integrations-settings-stores__input-title">{store.label}</span>

                <ArrowRightOutlined className="cb-integrations-settings-stores__input-icon" />

                <Form.Item key={store.value} name={store.value} className="cb-integrations-settings-stores__input">
                  <Select placeholder="Unmapped" options={options} allowClear />
                </Form.Item>
              </div>
            ))}
          </Form>

          {showPagination && (
            <PaginationV2
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              total={total}
              showSizeChanger={false}
            />
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  sync_clients: state.auth.sync_clients,
  sync_products: state.auth.sync_products,
  sync_purchase_history: state.auth.sync_purchase_history,
});

const mapDispatchToProps = dispatch => ({
  setLoading(val) {
    dispatch({
      type: 'SET_SAVING_NOTIF',
      payload: val,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsSettingsStores);
