import { dispatcher } from '../core/actions';

const initialState = {
  layoutBreadCrumbs: [],
  layoutDescription: '',
  goBack: '',
  type: '',
  listItemId: '',
  remindersBoolean: true,
  // Reminders
  reminders: [],
  reminderId: '',
  reminderAddOrEdit: 'Add',
  reminderName: '',
  reminderCount: 1,
  reminderInterval: 'WEEK',
  reminderRepeat: 0,
  expireScale: 1,
  expireInterval: 'WEEK',
  expires: true,
  active: 1,
  // Auto Reminder Repeat
  autoReminderRepeatCount: 1,
  autoReminderRepeatInterval: 'WEEK',
  // MessageTemplate
  messageTemps: [],
  deleteMessageTemplates: [],
  msgTempId: '',
  msgTempAddOrEdit: 'Add',
  msgTempText: '',
  images: '',
};

export default function reducer(state = initialState, action) {
  const state2 = state;
  switch (action.type) {
    case 'SET_LAYOUT_DESC':
      return { ...state2, layoutDescription: action.payload };
    case 'SET_BREAD_CRUMBS':
      return { ...state2, layoutBreadCrumbs: action.payload };
    case 'SET_GO_BACK':
      return { ...state2, goBack: action.payload };
    case 'SET_LIST_TYPE':
      return { ...state2, type: action.payload };
    case 'SET_LIST_ID':
      return { ...state2, listItemId: action.payload };
    case 'SET_REMINDERS_BOOLEAN':
      return { ...state2, remindersBoolean: action.payload };
    //  REMINDERS
    case 'SET_AUTO_REMINDERS':
      return { ...state2, reminders: [...action.payload] };
    case 'SET_REM_ID':
      return { ...state2, reminderId: action.payload };
    case 'SET_REM_ADD_EDIT':
      return { ...state2, reminderAddOrEdit: action.payload };
    case 'SET_REM_NAME':
      return { ...state2, reminderName: action.payload };
    case 'SET_REM_COUNT':
      return { ...state2, reminderCount: action.payload };
    case 'SET_REM_INTERVAL':
      return { ...state2, reminderInterval: action.payload };
    case 'SET_REM_REPEAT':
      return { ...state2, reminderRepeat: action.payload };
    case 'SET_REM_ACTIVE':
      return { ...state2, active: action.payload };
    // repeat interval
    case 'SET_REM_REPEAT_COUNT':
      return { ...state2, autoReminderRepeatCount: action.payload };
    case 'SET_REM_REPEAT_INTERVAL':
      return { ...state2, autoReminderRepeatInterval: action.payload };
    // MESSAGE TEMPLATES
    case 'SET_REM_MSG_TEMPLATES':
      return { ...state2, messageTemps: [...action.payload] };
    case 'DELETE_REM_MSG_TEMPLATES':
      return { ...state2, deleteMessageTemplates: [...action.payload] };
    case 'SET_REM_MSG_ADD_EDIT':
      return { ...state2, msgTempAddOrEdit: action.payload };
    case 'SET_REM_MSG_ID':
      return { ...state2, msgTempId: action.payload };
    case 'SET_REM_MSG_TEXT':
      return { ...state2, msgTempText: action.payload };
    case 'SET_REM_MSG_IMG':
      return { ...state2, images: action.payload };
    case 'SET_REM_EXPIRE_SCALE':
      return { ...state2, expireScale: action.payload };
    case 'SET_REM_EXPIRE_INTERVAL':
      return { ...state2, expireInterval: action.payload };
    case 'SET_REM_EXPIRES':
      return { ...state2, expires: action.payload };
    default:
      break;
  }
  return state2;
}

export const setLayoutBreadCrumbs = val => {
  dispatcher('SET_BREAD_CRUMBS', val);
};
export const setLayoutDesc = val => {
  dispatcher('SET_LAYOUT_DESC', val);
};
export const setGoBack = val => {
  dispatcher('SET_GO_BACK', val);
};
export const setListType = val => {
  dispatcher('SET_LIST_TYPE', val);
};
export const setListId = val => {
  dispatcher('SET_LIST_ID', val);
};
export const setRemindersBoolean = val => {
  dispatcher('SET_REMINDERS_BOOLEAN', val);
};

// Reminder Actions
export const setAutoReminders = val => {
  dispatcher('SET_AUTO_REMINDERS', val);
};
export const setReminderName = val => {
  dispatcher('SET_REM_NAME', val);
};
export const setReminderCount = val => {
  dispatcher('SET_REM_COUNT', val);
};
export const setReminderInterval = val => {
  dispatcher('SET_REM_INTERVAL', val);
};
export const setReminderRepeat = val => {
  dispatcher('SET_REM_REPEAT', val);
};
export const setReminderActive = val => {
  dispatcher('SET_REM_ACTIVE', val);
};
// repeat scale and intervals auto message
export const setRemRepeatCount = val => {
  dispatcher('SET_REM_REPEAT_COUNT', val);
};
export const setRemRepeatInterval = val => {
  dispatcher('SET_REM_REPEAT_INTERVAL', val);
};
export const setReminderActionId = val => {
  dispatcher('SET_REM_ID', val);
};
export const setReminderAddEdit = val => {
  dispatcher('SET_REM_ADD_EDIT', val);
};
export const setExpireScale = val => {
  dispatcher('SET_REM_EXPIRE_SCALE', val);
};
export const setExpireInterval = val => {
  dispatcher('SET_REM_EXPIRE_INTERVAL', val);
};
export const setExpires = val => {
  dispatcher('SET_REM_EXPIRES', val);
};

// Reminder Msg Template Actions
export const setRemTempMsgs = val => {
  dispatcher('SET_REM_MSG_TEMPLATES', val);
};
export const deleteRemTempMsgs = val => {
  dispatcher('DELETE_REM_MSG_TEMPLATES', val);
};
export const setRemTempAddEdit = val => {
  dispatcher('SET_REM_MSG_ADD_EDIT', val);
};
export const setRemTempMsgId = val => {
  dispatcher('SET_REM_MSG_ID', val);
};
export const setRemTempMsgText = val => {
  dispatcher('SET_REM_MSG_TEXT', val);
};
export const setRemTempMsgImg = val => {
  dispatcher('SET_REM_MSG_IMG', val);
};
