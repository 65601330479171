import React from 'react';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Menu } from 'antd';
import Button from '../../../../../CustomizedAntDesign/Button';
import './styles.css';
import { useState } from 'react';

const ProductDetailsLayoutDropdown = ({ product, setShowDeleteModal }) => {
  const [open, setOpen] = useState(false);

  const history = useHistory();

  const handleEdit = () => {
    history.push(`/products/${product.id}/edit`);
  }

  return (
    <Dropdown
      menu={{
        items: [
          {
            label: "Edit",
            onClick: () => handleEdit()
          },
          {
            label: "Delete",
            onClick: () => setShowDeleteModal(true)
          }
        ],
        style: {
          paddingLeft: "8px",
          paddingRight: "24px",
          paddingTop: "8px",
          paddingTop: "8px",
        }
      }}
      overlayClassName="cb-product-details-layout__actions-dropdown"
      placement="bottomLeft"
      trigger={['click']}
      onOpenChange={(value) => setOpen(value)}
      open={open}
    >
      <Button type="link" icon={<FontAwesomeIcon icon={['fas', 'ellipsis-v']} />} />
    </Dropdown>
  );
};

export default ProductDetailsLayoutDropdown;
