import React from 'react';
import LoginLayout from '../LoginLayout';
import '../login.css';

const Login = () => {


  return (
    <LoginLayout 
      redirectTo={"/"}
    />
  );
};
export default Login;
