import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoneInput from 'react-phone-number-input';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Layout from '../../../Layout/StandardLayout';
import Button from '../../../Layout/Button';
import PopConfirm from '../../../Layout/PopConfirm';
import { saveListItem, getRequest, deleteRequest } from '../../../../core/apiRequests';
import { notificationSuccess, notificationError } from '../../../../core/actions';

import {
  setAssocAddEdit,
  setFirstName,
  setLastName,
  setMobileNum,
  setEmailAddress,
  setAllowManagerFeatures,
  setAssociateId,
  setAssociateStoreId,
  setDeletedAssociateList,
  setAssociateObject,
  setPOS,
  setAssociateRole,
  setSchedulingLink,
} from '../../../../reducers/associate';
import { setAuthSchedulingLink } from '../../../../reducers/auth';
import DropdownSelect from '../../../Layout/DropdownSelect';
import ReassignAssociateModal from './ReassignAssociateModal';

const AddAssociate = props => {
  const history = useHistory();
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [sendInvitation, setSendInvitation] = useState(true);
  const [showPinModal, setShowPinModal] = useState(false);
  const [userPin, setUserPin] = useState('');
  const [localNumber, setLocalNumber] = useState();
  const [showReassignAssoc, toggleReassignAssoc] = useState(false);
  const [tempRole, setTempRole] = useState({ label: 'Role: ', value: '' });
  const [roleOptions, setRoleOptions] = useState([{ label: 'Role: Associate', value: 'ASSOCIATE' }]);
  const [showModal, setShowModal] = useState(false);
  const [stores, setStoresNames] = useState([]);
  const [storeId, setStoresId] = useState([]);
  const [conditionedStores, setConditionedStores] = useState({});
  const [showPopEmail, setShowPopEmail] = useState(false);
  const [noEmail, setNoEmail] = useState(false);
  const [smsStoreNum, setSmsStoreNum] = useState([]);
  const flags = useFlags();

  useEffect(() => {
    if ((props.iso_country_code !== 'US' || !localNumber) && !props.associate.emailAddress) {
      setSendInvitation(false);
    } else {
      setSendInvitation(true);
    }
  }, [localNumber, props.associate.emailAddress]);

  useEffect(() => {
    if (props.merchantId && props.stores.length > 1) {
      if (window.location.href.includes('add-associate')) {
        // setSendInvite(false);
        conditionedStoreHandler([props.stores[1]]);
        setAssocAddEdit('Add');
        setAllowManagerFeatures(false);
        setSmsStoreNum(props.stores.map(st => st.sms_number).filter(store => store !== undefined));
        if (props.role === 'ADMIN') {
          setRoleOptions([
            { label: 'Role: Admin', value: 'ADMIN' },
            { label: 'Role: Manager', value: 'MANAGER' },
            { label: 'Role: Associate', value: 'ASSOCIATE' },
          ]);
        } else {
          setTempRole({ label: 'Role: Associate', value: 'ASSOCIATE' });
        }
        if (!props.associate.associateStoreId) {
          const validStores = props.stores.filter(st => st.id);
          if (validStores.length > 0) {
            setAssociateStoreId(validStores[0].id);
          }
        }
      } else if (props.match.params.associateId !== props.associate.associateId) {
        const reqObj = {
          params: ['associates', 'details', props.merchantId, props.match.params.associateId],
        };
        (async () => {
          const data = await getRequest(reqObj);
          if (data && data.length > 0) {
            const {
              first_name,
              last_name,
              mobile,
              id,
              email,
              // sendInvit,
              manager,
              location,
              image,
              pin,
              store_id,
              daily_contact_goal,
              count,
              pos_ref_num,
              role,
              stores,
              scheduling_link,
            } = data[0];
            setAssociateObject({
              firstName: first_name,
              lastName: last_name,
              mobileNum: mobile,
              emailAddress: email,
              associateId: id,
              allowManagerFeatures: Number(manager) === 1,
              addOrEdit: 'Edit',
              associateStoreId: store_id,
              associateImage: image,
              associatePin: pin,
              associateContactGoal: daily_contact_goal,
              associateAssignedCount: count,
              associateStore: location,
              associatePOS: pos_ref_num,
              role,
              schedulingLink: scheduling_link,
            });
            if (role === 'ADMIN') {
              setTempRole({ label: 'Role: Admin', value: 'ADMIN' });
            } else if (role === 'MANAGER') {
              setTempRole({ label: 'Role: Manager', value: 'MANAGER' });
            } else {
              setTempRole({ label: 'Role: Associate', value: 'ASSOCIATE' });
            }
            if (props.role === 'ADMIN') {
              setRoleOptions([
                { label: 'Role: Admin', value: 'ADMIN' },
                { label: 'Role: Manager', value: 'MANAGER' },
                { label: 'Role: Associate', value: 'ASSOCIATE' },
              ]);
            }
            formatStores(stores);
            setStoresId(stores);
            setAssociateRole(role);
            setLocalNumber(mobile);
            conditionedStoreHandler(stores.map(st => st.store_id));
            setSmsStoreNum(props.stores.map(st => st.sms_number).filter(store => store !== undefined));
          } else {
            history.push('/Associates');
          }
        })();
      }
    }
  }, [props.stores, props.merchantId]);

  const formatStores = stores => {
    const storeNames = [];
    stores.map(store => storeNames.push(store.store_name));
    if (storeNames.join().length > 30) {
      return setStoresNames(`${storeNames.join().slice(0, 30)}...`);
    }
    setStoresNames(storeNames.join(', '));
  };

  const getStoreAccess = () => {
    const names = [];
    if (Object.keys(conditionedStores).length > 0) {
      const storeAccess = props.stores.filter(prpsStr => conditionedStores.hasOwnProperty(prpsStr.id));
      storeAccess.map(store => names.push(store.name));
      if (names.join().length > 30) {
        return `${names.join(', ').slice(0, 30)}...`;
      }
      return names.join(', ');
    }
    return stores;
  };

  const addEditAssociate = () => {
    const {
      associate: { associateId, firstName, lastName, emailAddress, allowManagerFeatures, associatePOS, schedulingLink },
      brandName,
    } = props;
    if (!firstName) {
      notificationError('Associate first name required');
    } else if (!tempRole.value) {
      notificationError('Role required!');
    } else if (Object.keys(conditionedStores).length == 0) {
      notificationError('Store Access required');
    } else if (window.location.href.includes('add-associate') && !emailAddress && !noEmail) {
      setShowPopEmail(true);
    } else if (
      (window.location.href.includes('edit-associate') || userPin || sendInvitation) &&
      Object.keys(conditionedStores).length > 0
    ) {
      const phoneNumber = localNumber;
      (async () => {
        const dataObj = {
          firstName,
          lastName,
          mobileNum: phoneNumber,
          emailAddress,
          userPin,
          sendInvite: sendInvitation,
          allowManagerFeatures,
          merchantName: brandName,
          creatorAsct: props.firstName,
          associatePOS,
          role: tempRole.value,
          conditionedStores: Object.keys(conditionedStores),
          schedulingLink,
        };
        if (associateId) {
          dataObj.id = associateId;
        }
        if (mobileValidation(phoneNumber) && smsMobile(phoneNumber)) {
          setMobileNum(phoneNumber);
          const data = await saveListItem('associates', props.merchantId, dataObj);
          if (data.id) {
            setAssociateId(data.id);
            setAssocAddEdit('Edit');
            setAuthSchedulingLink(schedulingLink);
            // eslint-disable-next-line no-unused-expressions
            props.associate.addOrEdit === 'Edit'
              ? notificationSuccess('Associate Updated')
              : notificationSuccess('Associate Created');
            history.push('/Associates');
          } else {
            setShowPopConfirm(false);
            notificationError('Error Saving Associate');
          }
        }
      })();
    } else if (window.location.href.includes('add-associate') && !sendInvitation) {
      setShowPinModal(true);
    } else {
      notificationError('Please assign store access');
    }
  };

  const conditionedStoreHandler = storesArr => {
    const newStoresObj = {}; // ^ stores is an array, but conditionedStores is using an object to avoid duplication
    storesArr.forEach(st => {
      if (Number(st)) {
        newStoresObj[st] = st;
      }
    });
    setConditionedStores(newStoresObj);
  };

  const onConfirmHandler = stores => {
    if (stores.length < 1) {
      notificationError('Please assign store access');
    } else {
      conditionedStoreHandler(stores);
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (tempRole.value === 'ADMIN') {
      const idArray = [];
      const allStores = props.stores.filter(st => st.id !== 0).map(st => st.id);
      conditionedStoreHandler(allStores);
    }
  }, [tempRole]);

  const mobileValidation = phone => {
    if (!phone) {
      return true;
    }
    if (phone.match(/\d/g).length >= 10) {
      return true;
    }
    notificationError('Enter valid mobile phone number!');
    return false;
  };

  const smsMobile = phone => {
    if (!phone) {
      return true;
    }
    if (phone !== undefined) {
      if (phone.match(/\d/g).length >= 10) {
        const smsCompare = smsStoreNum.filter(number => {
          if (number.replace(/[^0-9]+/g, '') == phone.replace(/[^0-9]+/g, '')) {
            return true;
          }
          return false;
        });
        if (smsCompare.length > 0) {
          notificationError('Associate phone number cannot match store number!');
        } else return true;
      }
    }
  };

  const confirmDelete = newAssociateId => {
    (async () => {
      const reqObj = {
        params: ['associates', props.merchantId, 'remove', props.associate.associateId],
        query: {
          deletedBy: props.userId,
          newAssociateId,
        },
      };
      const data = await deleteRequest(reqObj);
      if (data) {
        notificationSuccess('Associate Removed');
        setShowPopConfirm(false);
        setDeletedAssociateList(props.associate.associateId);
        history.push('/Associates');
        toggleReassignAssoc(false);
      } else {
        // notificationError('Error Deleting Associate');
        setShowPopConfirm(false);
        toggleReassignAssoc(false);
      }
    })();
  };

  const redirectBack = () => {
    if (props.match.params.associateId) {
      history.push(`/Associates/associate-details/${props.match.params.associateId}`);
    } else {
      history.push('/Associates');
    }
  };

  const invitationClickHandler = () => {
    setSendInvitation(!sendInvitation);
  };

  return (
    <Layout
      title={`${window.location.href.includes('add-associate') ? 'Add' : 'Edit'} Associates`}
      description={
        window.location.href.includes('add-associate')
          ? 'Create a sales associate app account'
          : `Edit ${props.associate.firstName}'s information`
      }
      breadCrumbs={[
        { title: 'Associates', to: '/Associates' },
        {
          title: `${window.location.href.includes('add-associate') ? 'Add' : 'Edit'} Associate`,
        },
      ]}
      classProps="standard-width-wo-sidebar"
    >
      <div className="addAsctInputRows w-100-P">
        <div className="w-100-P flex-row-nospacebetween-wrap">
          <input
            id="AssociateFirstName"
            className="addAssociateInputs maw-98-P first_last_name focus-input-outline-blue"
            placeholder="First Name"
            value={props.associate.firstName || ''}
            onChange={e => setFirstName(e.target.value)}
          />
          <input
            id="AssociateLastName"
            className="addAssociateInputs maw-98-P first_last_name focus-input-outline-blue"
            placeholder="Last Name"
            value={props.associate.lastName || ''}
            onChange={e => setLastName(e.target.value)}
          />
        </div>
        <div className="w-100-P flex-row-nospacebetween-wrap">
          <PhoneInput
            id="AssociatePhoneNumber"
            placeholder="Enter phone number"
            value={localNumber}
            onChange={e => {
              setLocalNumber(e);
              smsMobile(e);
            }}
            defaultCountry={props.iso_country_code}
            style={{ width: '437px', height: '48px', marginRight: '15px', marginBottom: '15px' }}
            countryOptionsOrder={[props.iso_country_code, '|']}
            countrySelectProps={{ backgroundcolor: 'rgb(129, 129, 129' }}
          />
          <input
            id="AssociateEmail"
            className="addAssociateInputs maw-98-P associate_email focus-input-outline-blue"
            placeholder="Email Address"
            value={props.associate.emailAddress || ''}
            onChange={e => {
              setEmailAddress(e.target.value);
            }}
          />
        </div>
        <div className="w-100-P flex-row-nospacebetween-wrap">
          <div
            className="addAssociateInputs associate_role maw-98-P focus-input-outline-blue flex-row-spacebetween-wrap"
            placeholder="Store Access: "
          >
            {/* <span>Store Access: {getStoreAccess() ? getStoreAccess() : stores}</span> */}
            <span>Store Access: {tempRole.value == 'ADMIN' ? props.stores[0].name : getStoreAccess()}</span>
            {props.role === 'ASSOCIATE' || tempRole.value == 'ADMIN' ? null : (
              <button
                className="blue-link fs-13 border-none bg-white m-right-8 fw-500 "
                onClick={e => setShowModal(true)}
              >
                Edit
              </button>
            )}
          </div>
          {showModal ? (
            <StoreModal
              associate={props.associate.associateId}
              closeModal={() => {
                setShowModal(false);
              }}
              selectedStores={conditionedStores}
              merchantId={props.merchantId}
              stores={props.stores}
              onConfirmHandler={onConfirmHandler}
              storeId={storeId}
              role={tempRole}
              associateName={props.associate.firstName}
            />
          ) : null}
          {/* role */}
          {props.role === 'ADMIN' ? (
            <DropdownSelect
              classProps="maw-98-P w-440 align-left m-right-15 m-btm-15 fs-13"
              options={roleOptions}
              placeholder="Role"
              value={tempRole.value}
              onChange={obj => {
                setTempRole({ label: obj.label, value: obj.value });
              }}
              optionalStyle={{ fontSize: '13px', paddingLeft: '15px' }}
            />
          ) : (
            <input
              className="addAssociateInputs associate_role maw-98-P focus-input-outline-blue fs-13"
              placeholder="Role"
              value={tempRole.label}
              readOnly
              style={{ padding: '0px 0px 0px 15px' }}
            />
          )}
        </div>
        {/* pos div */}
        <div className="w-100-P flex-row-nospacebetween-wrap">
          {props.pos_type && (
            <input
              className="addAssociateInputs maw-98-P associate_pos focus-input-outline-blue"
              placeholder="Point of Sale ID"
              value={props.associate.associatePOS || ''}
              onChange={e => {
                setPOS(e.target.value);
              }}
              maxLength="50"
            />
          )}
          {props?.merchantFlags?.scheduling_link?.enabled && (
            <input
              className="addAssociateInputs maw-98-P focus-input-outline-blue associate_schedule"
              placeholder="Scheduling link"
              value={props.associate.schedulingLink || ''}
              onChange={e => {
                setSchedulingLink(e.target.value);
              }}
            />
          )}
        </div>

        <div className="w-100-P flex-row-nospacebetween-wrap">
          <div />
        </div>
        <div className="custom-action-save m-top-30 flex-col-center" style={{ maxWidth: '900px' }}>
          <div className="flex-col-center">
            <button id="AssociateSaveButton" className="save-button-v2 m-btm-8" onClick={addEditAssociate}>
              Save
            </button>
            {props.associate.addOrEdit === 'Edit' ? (
              <Button
                id="DeleteAssociate"
                classname="deleteButton-v2"
                style={{ marginBottom: '8px' }}
                onclick={() => setShowPopConfirm(true)}
              >
                Delete
              </Button>
            ) : (
              <div />
            )}
            <button id="AssociateCancelButton" className="cancel-button-v2" onClick={() => redirectBack()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
      {showPinModal ? (
        <ChangePinModal
          closeModal={() => setShowPinModal(false)}
          setUserPin={setUserPin}
          userPin={userPin}
          addEditAssociate={addEditAssociate}
        />
      ) : null}
      {showPopConfirm ? (
        <PopConfirm
          confirm={`Are you sure you want to remove "${props.associate.firstName || ''} ${
            props.associate.lastName || ''
          }"?`}
          description="This will remove the associate"
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={() => {
            toggleReassignAssoc(true);
            setShowPopConfirm(false);
          }}
        />
      ) : null}
      {showReassignAssoc ? (
        <ReassignAssociateModal
          toggleReassignAssoc={toggleReassignAssoc}
          storeObjs={props.stores}
          merchantId={props.merchantId}
          confirmDelete={confirmDelete}
        />
      ) : null}
      {showPopEmail ? (
        <PopConfirm
          confirm="Proceed without an email address?"
          description="Associate will not be able to access the dashboard and will not receive login instructions in their inbox"
          onCancel={() => setShowPopEmail(false)}
          onConfirm={() => {
            setShowPopEmail(false);
            setNoEmail(true);
          }}
        />
      ) : null}
    </Layout>
  );
};

const mapStateToProps = state => ({
  associate: state.associate,
  ...state.auth,
  ...state.notification,
});

const mapDispatchToProps = dispatch => ({
  setAssocAddEdit(val) {
    dispatch({
      type: 'SET_ASCT_ADD_EDIT',
      payload: val,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAssociate);

const ChangePinModal = props => {
  const backgroundCloseHandler = e => {
    if (e.target.id === 'modal-background') {
      props.closeModal();
    }
  };

  return (
    <div className="pop-confirm-background" id="modal-background" onClick={backgroundCloseHandler}>
      <div className="pop-confirm-container rem-pad-width" style={{ width: '388px', height: '294px' }}>
        <div className="flex-col-center h-100-P" style={{ justifyContent: 'flex-start' }}>
          <div className="flex-row-spacebetween-nowrap w-100-P m-top-15">
            <div />
            <FontAwesomeIcon
              onClick={props.closeModal}
              className="light-gray m-right-15 pointer"
              size="2x"
              icon={['far', 'times']}
            />
          </div>
          <span className="fs-20 fw-500" style={{ marginTop: '0px' }}>
            Please Enter A Pin
          </span>
          <input
            placeholder="4321"
            className="h-50 w-160 align-center m-top-37"
            value={props.userPin}
            onChange={e => props.setUserPin(e.target.value)}
          />
          <button
            onClick={props.addEditAssociate}
            style={{ width: '160px' }}
            className="m-top-37 save-button"
            disabled={!props.userPin}
          >
            Save
          </button>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

// ADD EDIT STORE MODAL

const StoreModal = props => {
  const [tempSelStores, setTempSelStores] = useState(
    props.stores
      .filter(st => {
        return props.selectedStores.hasOwnProperty(`${st.id}`);
      })
      .map(st => st.id),
  );

  const setStoreHandler = id => {
    // sets a stores as a "selectedStore", while trying to avoid dupes
    const storeList = tempSelStores;
    const idPresent = tempSelStores.indexOf(id);
    if (idPresent < 0) {
      storeList.push(id);
    } else {
      storeList.splice(idPresent, 1);
    }
    setTempSelStores([...storeList]);
  };

  const bgClickHandler = e => {
    if (e.target.id === 'storeAccessBg') {
      props.closeModal();
    }
  };

  const selectAllHandler = val => {
    // sets whether all or none get selected;
    if (val) {
      setTempSelStores([]);
    } else {
      const stIds = props.stores.map(st => st.id);
      setTempSelStores(stIds);
    }
  };

  const fullArrayMatch = arr => {
    // determines whether the array of props.stores is the same length as selectedStores
    const conditionedArr = arr.filter(st => st.id !== 0);
    return (
      conditionedArr.filter(st => {
        if (!tempSelStores.includes(st.id)) {
          return false;
        }
        return true;
      }).length === conditionedArr.length
    );
  };

  return (
    <div id="storeAccessBg" className="pop-confirm-background" onClick={bgClickHandler}>
      <div
        className="pop-confirm-container rem-pad-width"
        style={{
          width: '90%',
          maxWidth: '404px',
          height: '546px',
          overflowY: 'hidden',
        }}
      >
        <div className="flex-col-center h-100-P" style={{ justifyContent: 'flex-start' }}>
          {/* <div className="flex-row-spacebetween-nowrap w-100-P m-top-15">
                        <div />
                        <FontAwesomeIcon onClick={props.closeModal} className="light-gray m-right-15 pointer" size="2x" icon={['far', 'times']} />
                    </div> */}
          <span className="fw-500 fs-18 m-btm-12 m-top-37">Store Access</span>
          <span className="w-295 fs-14 fw-500 m-btm-20" style={{ marginTop: '0px' }}>
            Which store(s) should {props.associateName} have access to?
          </span>

          <div
            style={{ paddingTop: '25px' }}
            className="m-btm-28 w-295 rem-pad-width h-292 chatBubblePadding border-all bg-offwhite overflowY hide-scrollbar"
          >
            <ul className="ul-no-style flex-col-left ">
              <li
                className="fs-14 flex-row-nospacebetween-nowrap m-btm-28 light-gray m-top-15"
                onClick={() => selectAllHandler(fullArrayMatch(props.stores))}
              >
                {fullArrayMatch(props.stores) ? (
                  <FontAwesomeIcon
                    className="fs-16 m-right-20 pointer"
                    icon={['fas', 'check-square']}
                    color="#1769af"
                  />
                ) : (
                  <FontAwesomeIcon className="fs-16 m-right-20 pointer" icon={['far', 'square']} color="#b8b8b8" />
                )}
                <span className="fw-600 fs-12 black pointer">All Stores</span>
              </li>
              {props.stores
                .filter(st => st.id !== 0)
                .map(st => {
                  return (
                    <li
                      key={st.id}
                      className="fs-14 flex-row-nospacebetween-nowrap m-btm-28 pointer"
                      onClick={() => setStoreHandler(st.id)}
                    >
                      {tempSelStores.includes(st.id) ? (
                        <FontAwesomeIcon className="fs-16 m-right-20" icon={['fas', 'check-square']} color="#1769af" />
                      ) : (
                        <FontAwesomeIcon className="fs-16 m-right-20" icon={['far', 'square']} color="#b8b8b8" />
                      )}
                      <span style={{ margin: 0 }} className="fs-12 CBdarkBlue">
                        {st.name}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="flex-row-nospacebetween-wrap">
            <button onClick={props.closeModal} style={{ width: '140px' }} className="m-right-8 m-left-8 cancel-button">
              CANCEL
            </button>
            <button
              onClick={e => props.onConfirmHandler(tempSelStores)}
              style={{ width: '140px' }}
              className="m-right-8 m-left-8 save-button"
            >
              YES
            </button>
          </div>

          {/* </div> */}
        </div>
      </div>
    </div>
  );
};
