/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState, useCallback} from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { setLaunchFlagsForNav } from '../../../reducers/auth';
import { getRequest } from '../../../core/apiRequests';
import { useOnce } from '../../../hooks/useOnce';

// import SmallScreenMenu from './SmallScreenMenu';
import { setSelectedClientList } from '../../../reducers/client';

import './styles.scss';
import Header from './Header';
import Footer from './Footer';

const LayoutV2 = ({title, breadcrumb, classProps, styleProps, headerStyles, maxWidth, contentStyles, actions, ...props}) => {
  const history = useHistory();
  const isMounted = useRef(null);
  const [layoutClass, setLayoutClass] = useState('layout-main');
  const flags = useFlags();


  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setLaunchFlagsForNav(flags);
  }, [flags?.projectEdison]);


  const clickFunc = click => {
    if (click === 'client') {
      return setSelectedClientList([]);
    }
  };

  const paymentBannerStandardLayout = useCallback(() => {
    if (props.merchantId) {
      (async () => {
        const reqObj = {
          params: ['login', props.userId, props.merchantId, 'paymentBannerStatus'],
        };
        const data = await getRequest(reqObj);
        if (data.paymentCheck.length > 0) {
          if (data.paymentCheck[0].status == 'ACTIVE') {
            if (data.paymentSentRequest.length > 0) {
              // payment is active and they have used it. turn off banner
              setLayoutClass('layout-main-v2');
            } else {
              setLayoutClass('layout-main-w-payment-banner-v2');
            }
          } else if (data.paymentCheck[0].status == 'INACTIVE') {
            setLayoutClass('layout-main-w-payment-banner-v2');
            setLayoutClass('layout-main-w-payment-banner-v2');
            if (props.role != 'ADMIN') {
              setLayoutClass('layout-main-v2');
            }
          }
        } else {
          setLayoutClass('layout-main-w-payment-banner-v2');
          if (props.role != 'ADMIN') {
            setLayoutClass('layout-main-v2');
          }
        }
      })();
    }
  }, []);

  const { call } = useOnce(paymentBannerStandardLayout);
  useEffect(() => {
    call();
  }, [props.merchantId]);

  return (
    <div
      id="scrollWatchId"
      className={classProps ? `${layoutClass} rem-pad-width ${classProps} scrollbar-y` : `${layoutClass} rem-pad-width scrollbar-y`}
      style={styleProps}
    >
      <div style={{ display: 'flex', flexDirection: 'column'}} className='scrollbar-y w-100-P h-100-P p-top-15'>
        <Header
          breadcrumb={breadcrumb}
          title={title}
          maxWidth={maxWidth}
          headerStyles={headerStyles}
          actions={actions}
        />
        <div className='flex-col-left p-top-16 p-btm-16' style={{ backgroundColor: '#F5F5F5', justifyContent: 'space-between', flex: 1}}>
          <div className="p-left-40 p-btm-16 w-100-P" style={{ maxWidth: maxWidth ?? '1200px', ...contentStyles }}>
            {props.children}
          </div>
          <Footer
            footerClass={props?.footerClass}
          />
        </div>
      </div>

    </div>
  );
};
const mapStateToProps = state => ({
  ...state.navigation,
  ...state.auth,
});
const mapDispatchToProps = dispatch => ({
  toggleMobileMenu(val) {
    dispatch({
      type: 'SHOW_MOBILE_NAV',
      payload: val,
    });
  },
  toggleMultiMobileMenu(val) {
    dispatch({
      type: 'SHOW_MOBILE_MULTI_NAV',
      payload: val,
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(LayoutV2);
