import { dispatcher } from '../core/actions';

const initialState = {
  paymentFilterStatuses: [],
  paymentFilterDateRange: null,
  paymentFilterStartDate: null,
  paymentFilterEndDate: null,
  paymentFilterAssociates: [],
  clientFilterTags: [],
  clientFilterAssociates: [],
  transactionFilterBankAccounts: [],
  myClients: null,
};

export default function reducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case 'SET_CLIENT_FILTER_TAGS':
      return { ...state, clientFilterTags: payload };
    case 'SET_CLIENT_FILTER_ASSOCIATES':
      return { ...state, clientFilterAssociates: payload };
    case 'SET_CLIENT_FILTER_MY_CLIENTS':
      return { ...state, myClients: payload };
    case 'RESET_CLIENT_FILTER':
      return initialState;
    case 'SET_PAYMENT_FILTER_STATUS':
      return { ...state, paymentFilterStatuses: payload };
    case 'SET_PAYMENT_FILTER_DATE_RANGE':
      return { ...state, paymentFilterDateRange: payload };
    case 'SET_PAYMENT_FILTER_START_DATE':
      return { ...state, paymentFilterStartDate: payload };
    case 'SET_PAYMENT_FILTER_END_DATE':
      return { ...state, paymentFilterEndDate: payload };
    case 'SET_PAYMENT_FILTER_ASSOCIATES':
      return { ...state, paymentFilterAssociates: payload };
    case 'SET_TRANSACTION_FILTER_BANK_ACCOUNTS':
      return { ...state, transactionFilterBankAccounts: payload };
    default:
      return state;
  }
}

export const setClientFilterTags = val => {
  dispatcher('SET_CLIENT_FILTER_TAGS', val);
};

export const setClientFilterAssociates = val => {
  dispatcher('SET_CLIENT_FILTER_ASSOCIATES', val);
};

export const setClientFilterMyClients = val => {
  dispatcher('SET_CLIENT_FILTER_MY_CLIENTS', val);
};

export const resetClientFilters = val => {
  dispatcher('RESET_CLIENT_FILTER', val);
};

export const setPaymentFilterStatuses = val => {
  dispatcher('SET_PAYMENT_FILTER_STATUS', val);
};

export const setPaymentFilterDateRange = val => {
  dispatcher('SET_PAYMENT_FILTER_DATE_RANGE', val);
};

export const setPaymentFilterStartDate = val => {
  dispatcher('SET_PAYMENT_FILTER_START_DATE', val);
};

export const setPaymentFilterEndDate = val => {
  dispatcher('SET_PAYMENT_FILTER_END_DATE', val);
};

export const setPaymentFilterAssociates = val => {
  dispatcher('SET_PAYMENT_FILTER_ASSOCIATES', val);
};

export const setTransactionFilterBankAccounts = val => {
  dispatcher('SET_TRANSACTION_FILTER_BANK_ACCOUNTS', val);
};
