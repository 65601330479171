import moment from "moment";
import { statusFilter } from "./tableHelpers";

export const filterList = ({ paymentRequests, filters, startDate, endDate, dateName = 'sent' }) => {

  return paymentRequests.filter(paymentRequest => {
    let filterStatus = filters.statuses.length === 0 || filters.statuses.includes(statusFilter(paymentRequest.status.toString()));
    let filterStore = filters.store.length === 0 || filters.store.includes(paymentRequest.storeId.toString());
    let filterAssociate = filters.associate.length === 0 || filters.associate.includes(paymentRequest.sentById.toString());
    let filterBankAccount = filters.bankAccount.length === 0 || filters.bankAccount.includes(paymentRequest.accountId.toString());
    let filterTimeSpan = moment(paymentRequest[dateName]).isBetween(moment(startDate), moment(endDate));
    return filterStatus && filterStore && filterAssociate && filterBankAccount && filterTimeSpan;
  });
};
