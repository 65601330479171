import React, { useMemo, useState } from 'react';
import { Button, Tooltip } from 'antd-v5';
import {
  DeleteOutlined,
  EditOutlined,
  FolderAddOutlined,
  InfoCircleOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import './styles.scss';
import SaveProductModal from '../../../../../componentsV2/Products/SaveProductModal';

const ProductsV2DetailsHeader = props => {
  const {
    loading,
    product,
    showDetailsDrawer,
    setShowDetailsDrawer,
    showClientCollectionModal,
    setShowClientCollectionModal,
    showShareProductModal,
    setShowShareProductModal,
    showDeleteProductModal,
    setShowDeleteProductModal,
    fetchProduct,
  } = props;

  const [showEditProductModal, setShowEditProductModal] = useState(false);

  const enableShareButton = useMemo(() => product.images.length, [product.images.length]);

  return (
    <>
      <div className="products-v2-details-header">
        <div className="products-v2-details-header__title">{product.name}</div>

        <div className="products-v2-details-header__options">
          {!product.posRefNum && !product.mpn ? (
            <Tooltip title="Edit Product">
              <Button
                size="large"
                icon={<EditOutlined style={{ color: '#1677FF' }} />}
                onClick={() => setShowEditProductModal(!showEditProductModal)}
                disabled={loading}
              />
            </Tooltip>
          ) : null}

          <Tooltip title="Add Product to Collection">
            <Button
              size="large"
              icon={<FolderAddOutlined style={{ color: '#1677FF' }} />}
              onClick={() => setShowClientCollectionModal(!showClientCollectionModal)}
              disabled={loading}
            />
          </Tooltip>

          <Tooltip title={enableShareButton ? 'Share Product' : ' Needs Image to Send'}>
            <Button
              size="large"
              icon={<MessageOutlined style={{ color: '#1677FF' }} />}
              onClick={() => setShowShareProductModal(!showShareProductModal)}
              disabled={loading || !enableShareButton}
            />
          </Tooltip>

          <Tooltip title="Show Product Details">
            <Button
              size="large"
              icon={<InfoCircleOutlined style={{ color: '#1677FF' }} />}
              onClick={() => setShowDetailsDrawer(!showDetailsDrawer)}
              disabled={loading}
            />
          </Tooltip>

          {!product.posRefNum && !product.mpn ? (
            <Tooltip title="Delete Product">
              <Button
                size="large"
                icon={<DeleteOutlined style={{ color: '#f81d22' }} />}
                onClick={() => setShowDeleteProductModal(!showDeleteProductModal)}
                disabled={loading}
              />
            </Tooltip>
          ) : null}
        </div>
      </div>

      {!product.posRefNum && !product.mpn ? (
        <SaveProductModal
          productId={product.id}
          productData={product}
          open={showEditProductModal}
          setOpen={setShowEditProductModal}
          onSuccess={() => fetchProduct()}
        />
      ) : null}
    </>
  );
};

export default ProductsV2DetailsHeader;
