import store from '../core/store';
import { dispatcher } from '../core/actions';

const initialState = {
  // associate
  addOrEdit: 'Add',
  firstName: '',
  lastName: '',
  mobileNum: '',
  emailAddress: '',
  // sendInvite: false, // using local state for this one?
  allowManagerFeatures: false,
  associateId: '',
  associateStore: '',
  associateStoreId: '',
  associateImage: '',
  associatePin: '',
  associatePassword: '',
  associatePOS: '',
  associateContactGoal: 0,
  associateAssignedCount: 0,
  viewMessages: [],
  deletedAssociates: [],
  role: 'ASSOCIATE',
  schedulingLink: '',
};

// THESE ARE GLOBAL SO WATCH THE NAMES
// EX: actFirstName
export default function reducer(state = initialState, action) {
  const state2 = state;
  const { payload } = action;
  switch (action.type) {
    case 'SET_ASCT_ADD_EDIT':
      return { ...state2, addOrEdit: action.payload };
    case 'SET_ASCT_OBJECT':
      return { ...state2, ...action.payload };
    case 'SET_ASCT_FIRST_NAME':
      return { ...state2, firstName: action.payload };
    case 'SET_ASCT_LAST_NAME':
      return { ...state2, lastName: action.payload };
    case 'SET_ASCT_MOBILE_NUM':
      return { ...state2, mobileNum: action.payload };
    case 'SET_ASCT_EMAIL':
      return { ...state2, emailAddress: action.payload };
    case 'SET_ASSOCIATE_POS':
      return { ...state2, associatePOS: action.payload };
    case 'SET_ASCT_INVITE':
      return { ...state2, sendInvite: action.payload };
    case 'SET_ASCT_FEATURES':
      return { ...state2, allowManagerFeatures: action.payload };
    case 'SET_ASCT_ASSOCIATE_ID':
      return { ...state2, associateId: action.payload };
    case 'SET_ASCT_ASSOCIATE_STORE':
      return { ...state2, associateStore: action.payload };
    case 'SET_ASCT_ASSOCIATE_STORE_ID':
      return { ...state2, associateStoreId: action.payload };
    case 'SET_ASCT_ASSOCIATE_IMAGE':
      return { ...state2, associateImage: action.payload };
    case 'SET_ASCT_ASSOCIATE_PIN':
      return { ...state2, associatePin: action.payload };
    case 'SET_ASCT_ASSOCIATE_PASSWORD':
      return { ...state2, associatePassword: action.payload };
    case 'SET_ASSOC_CONTACT_GOAL':
      return { ...state2, associateContactGoal: action.payload };
    case 'SET_ASSOC_COUNT':
      return { ...state2, associateAssignedCount: action.payload };
    case 'SET_ASSOC_ROLE':
      return { ...state2, role: action.payload };
    case 'SET_SCHEDULING_LINK':
      return { ...state2, schedulingLink: action.payload };
    case 'SET_ASSOC_FROM_API':
      return {
        ...state,
        viewMessages: [...payload.viewMessages],
      };
    case 'SET_DELETED_ASSOC_LIST':
      return { ...state2, deletedAssociates: [...state2.deletedAssociates, action.payload] };
    default:
      break;
  }
  return state2;
}
function boolParse(val) {
  if (val === 1 || val === '1' || val === 'true' || val === true) return true;
  return false;
}

export const setAssociateObject = val => {
  dispatcher('SET_ASCT_OBJECT', val);
};

export const setAssocAddEdit = val => {
  dispatcher('SET_ASCT_ADD_EDIT', val);
};

export const setFirstName = val => {
  dispatcher('SET_ASCT_FIRST_NAME', val);
};

export const setLastName = val => {
  dispatcher('SET_ASCT_LAST_NAME', val);
};

export const setMobileNum = val => {
  dispatcher('SET_ASCT_MOBILE_NUM', val);
};

export const setEmailAddress = val => {
  dispatcher('SET_ASCT_EMAIL', val || '');
};
export const setPOS = val => {
  dispatcher('SET_ASSOCIATE_POS', val || '');
};

export const setSendInvite = val => {
  dispatcher('SET_ASCT_INVITE', val);
};

export const setAllowManagerFeatures = val => {
  dispatcher('SET_ASCT_FEATURES', boolParse(val));
};

export const setAssociateId = val => {
  dispatcher('SET_ASCT_ASSOCIATE_ID', val);
};

export const setAssociateStore = val => {
  dispatcher('SET_ASCT_ASSOCIATE_STORE', val);
};
export const setAssociateStoreId = val => {
  dispatcher('SET_ASCT_ASSOCIATE_STORE_ID', val);
};

export const setAssociateImage = val => {
  dispatcher('SET_ASCT_ASSOCIATE_IMAGE', val);
};
export const setAssociatePin = val => {
  dispatcher('SET_ASCT_ASSOCIATE_PIN', val);
};
export const setAssociatePassword = val => {
  dispatcher('SET_ASCT_ASSOCIATE_PASSWORD', val);
};
export const setAssocDetailsFromAPI = val => {
  dispatcher('SET_ASSOC_FROM_API', val);
};
export const setAssociateContactGoal = val => {
  dispatcher('SET_ASSOC_CONTACT_GOAL', val);
};
export const setAssociateCount = val => {
  dispatcher('SET_ASSOC_COUNT', val);
};
export const setAssociateRole = val => {
  dispatcher('ASSOC_ROLE', val);
};
export const setSchedulingLink = val => {
  dispatcher('SET_SCHEDULING_LINK', val);
};
export const setDeletedAssociateList = val => {
  dispatcher('SET_DELETED_ASSOC_LIST', val);
};

export const setAddEditAssociate = (data, addOrEdit) => {
  const { dispatch } = store;
  const {
    associateId,
    firstName,
    lastName,
    mobileNum,
    emailAddress,
    associatePOS,
    sendInvite,
    allowManagerFeatures,
    associateStore,
    associateImage,
    associatePin,
    role,
    schedulingLink,
  } = data;
  const dispatchArr = [
    {
      type: 'SET_ASCT_ADD_EDIT',
      payload: addOrEdit || 'Add',
    },
    {
      type: 'SET_ASCT_FIRST_NAME',
      payload: firstName || '',
    },
    {
      type: 'SET_ASCT_LAST_NAME',
      payload: lastName || '',
    },
    {
      type: 'SET_ASCT_MOBILE_NUM',
      payload: mobileNum || '',
    },
    {
      type: 'SET_ASCT_EMAIL',
      payload: emailAddress || '',
    },
    {
      type: 'SET_ASCT_INVITE',
      payload: sendInvite || '',
    },
    {
      type: 'SET_ASSOCIATE_POS',
      payload: associatePOS || '',
    },
    {
      type: 'SET_ASCT_FEATURES',
      payload: allowManagerFeatures || '',
    },
    {
      type: 'SET_ASCT_ASSOCIATE_ID',
      payload: associateId || '',
    },
    {
      type: 'SET_ASCT_ASSOCIATE_STORE',
      payload: associateStore || '',
    },
    {
      type: 'SET_ASCT_ASSOCIATE_IMAGE',
      payload: associateImage || '',
    },
    {
      type: 'SET_ASCT_ASSOCIATE_PIN',
      payload: associatePin || '',
    },
    {
      type: 'ASSOC_ROLE',
      payload: role || 'ASSOCIATE',
    },
    {
      type: 'SET_SCHEDULING_LINK',
      payload: schedulingLink || '',
    },
  ];
  dispatchArr.forEach(obj => {
    dispatch(obj);
  });
};
