import moment from "moment";

export const filterList = ({ newClients = [], filters, sources, startDate, endDate }) => {

  return newClients?.filter(client => {
    let filterStore = filters.store.length === 0 || filters.store.includes(client?.activity_store_id?.toString());
    let filterAssociate = filters.associate.length === 0 || filters.associate.includes(client?.activity_associate_id?.toString());
    let filterTimeSpan = moment(client.activity_date).isBetween(moment(startDate), moment(endDate));
    let filterSource = filters.sourceMethod.length === 0 || filters.sourceMethod.includes(client?.sourceMethod?.toString());
    return filterStore && filterAssociate && filterTimeSpan && filterSource;
  });
};
