import React, { useCallback, useState } from 'react';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import Layout from '../../../Layout/StandardLayout';
import { Controller, useForm } from 'react-hook-form';
import UploadProductImage from '../components/UploadProductImage';
import { createProduct, getProductById, updateProduct } from '../../../../api/products';
import { notificationError, notificationSuccess } from '../../../../core/actions';
import { addLoadingList, removeLoadingList } from '../../../../reducers/notification';
import { useHistory, useParams } from 'react-router';
import { useEffect } from 'react';
import { v4 } from 'uuid';

const AddEditProducts = ({}) => {

  const history = useHistory();
  const params = useParams();
  const [numImages, setNumImages] = useState([0]);
  const {
    register,
    handleSubmit,
    control,
    getValues,
    watch,
    reset
  } = useForm();

  useEffect(() => {
    if(params.productId) {
      const uuid = v4();
      addLoadingList(uuid);
      getProductById(params.productId).then(({data}) => {
        setNumImages(Array(data.images.length + 1).fill(0).map((_, i) => i))
        reset({
          id: data.id,
          title: data.name,
          price: data.price,
          description: data.description,
          images: data.images.map(image => image.url)
        })
      }).finally(() => {
        removeLoadingList(uuid);
      })
    }
  }, [params])

  const onSubmit = useCallback(async (data) => {
    const formData = new FormData();
    const uuid = v4();

    formData.append('name', data.title);
    formData.append('price', data.price);
    formData.append('description', data.description);

    data.images.forEach((image, index) => {
      if(!!image) {
        formData.append(`images_${index}`, image);
      }
    });

    addLoadingList(uuid);

    if(data.id) {
      try {
        await updateProduct(data.id, formData);

        notificationSuccess('Product updated');

        history.push(`/products/${data.id}`);
      } catch (error) {
        console.log(error);
        notificationError('Error updating product');
      } finally {
        removeLoadingList(uuid);
      }
    } else {
      try {
        const response = await createProduct(formData);

        notificationSuccess('Product added');

        history.push(`/products/${response.data.productId}`);
      } catch (error) {
        console.log(error);
        notificationError('Error creating product');
      } finally {
        removeLoadingList(uuid);
      }
    }

  }, [history, notificationSuccess, notificationError, params])

  const handleAddImage = useCallback((file, onChange) => {
    onChange(file);
    if(!!file) {
      let addLast = getValues().images.length
      let newNumImages = [...numImages];
      newNumImages.push(addLast)
      setNumImages(newNumImages);
    }
  }, [numImages, getValues()])

  const handleDeleteImage = useCallback((item) => {
    setNumImages(numImages.filter((_, index) => index !== item));
  }, [numImages])

  const handleCancel = useCallback(() => {
    reset();
    history.goBack();
  }, [history, reset])

  const onInputPrice = useCallback(e => {
    e.target.value = e.target.value.replace(/[^\d.]/g, '');

    if (e.target.value.split('.').length > 2) {
      e.target.value = e.target.value.replace(/\.+$/, '');
    }
  }, []);

  return (
    <Layout
      title="Add Product"
      classProps="standard-width-with-sidebar"
      breadCrumbs={[
        { title: 'Products', to: '/Products' },
        {
          title: `${window.location.href.includes('add-product') ? 'Add' : 'Edit'} Product`,
        },
      ]}
    >
      <form className='flex-col-left w-100-P' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex-row-center' style={{gap: '8px'}}>
          {numImages.map((item, index) => (
            <Controller
              key={item}
              name={`images[${item}]`}
              control={control}
              render={({ field }) => (
                <UploadProductImage
                  containerClass="m-btm-24"
                  file={field.value}
                  setFile={(value) => handleAddImage(value, field.onChange)}
                  ref={field.ref}
                  onDelete={() => handleDeleteImage(item)}
                />
              )}
            />
          ))}
        </div>
        <div className='flex-col-left w-50-P m-btm-24' style={{gap: 4}}>
          <span className='fw-500 fs-13'>Title</span>
          <input
            className="h-48 rem-pad-width input-image-style focus-input-outline-blue p-left-15 w-100-P focus-input-outline-blue-without-border"
            placeholder="Title"
            {...register('title')}
          />
        </div>
        <div className='flex-col-left w-50-P m-btm-24' style={{gap: 4}}>
          <span className='fw-500 fs-13'>Price</span>
          <input
            className="h-48 rem-pad-width input-image-style focus-input-outline-blue p-left-15 w-100-P focus-input-outline-blue-without-border"
            placeholder="Price"
            onInput={onInputPrice}
            {...register('price')}
          />
        </div>
        <div className='flex-col-left w-100-P m-btm-24' style={{gap: 4}}>
          <span className='fw-500 fs-13'>Description</span>
          <textarea
            style={{ resize: 'none' }}
            className="rem-pad-width input-image-style focus-input-outline-blue p-left-15 p-top-15 w-100-P h-108 focus-input-outline-blue-without-border"
            placeholder="Description"
            {...register('description')}
          />
        </div>
        <div className='flex-col-center w-100-P'>
          <button type='submit' disabled={!watch('title')} className='save-button-v2 m-btm-15'>Save</button>
          <button type='button' className='cancel-button-v2' onClick={handleCancel}>Cancel</button>
        </div>
      </form>
      <SecondaryPanel title={['Manage']} current="Products" />
    </Layout>
  );
};

export default AddEditProducts;
