import React, { memo, useEffect, useRef, useState } from 'react';
import Chart from 'chart.js';

let theChart;

const ReusableChart = props => {
  const chartRef = useRef(null);

  Chart.pluginService.register({
    beforeDraw(chart) {
      if (chart.config.options.elements.center) {
        // Get ctx from string
        const { ctx } = chart.chart;

        // Get options from the center object in options
        const centerConfig = chart.config.options.elements.center;
        const fontStyle = centerConfig.fontStyle || 'Arial';
        const txt = centerConfig.text;
        const color = centerConfig.color || '#000';
        const sidePadding = centerConfig.sidePadding || 20;
        const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
        // Start with a base font of 30px
        ctx.font = `30px ${fontStyle}`;

        // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
        const stringWidth = ctx.measureText(txt).width;
        const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

        // Find out how much the font can grow in width.
        const widthRatio = elementWidth / stringWidth;
        const newFontSize = Math.floor(30 * widthRatio);
        const elementHeight = chart.innerRadius * 2;

        // Pick a new font size so it will not be larger than the height of label.
        const fontSizeToUse = Math.min(newFontSize, elementHeight);

        // Set font settings to draw it correctly.
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
        ctx.font = `${fontSizeToUse}px ${fontStyle}`;
        ctx.fillStyle = color;

        // Draw text in center
        ctx.fillText(txt, centerX, centerY);
      }
    },
  });

  useEffect(() => {
    if (Object.keys(props.configObj).length > 0) {
      buildChart();
    }
  }, [props.configObj]);

  const buildChart = () => {
    if (theChart) {
      theChart.clear();
      theChart.destroy();
    }
    const ctx = chartRef.current.getContext('2d');
    theChart = new Chart(ctx, {
      ...props.configObj,
    });
  };

  return (
    <div className={props.classProps}>
      <canvas width="100%" height={`${380 + (40 * props.labelLines)}px`} id="" ref={chartRef} {...props} />
    </div>
  );
};

export default memo(ReusableChart, (prev, next) => {
  let isEqual = true;
  if( prev.labelLines !== next.labelLines ) {
    isEqual = false;
  } else if( prev.configObj !== next.configObj ) {
    isEqual = false;
  }
  return isEqual;
});
