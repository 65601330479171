import React, { useEffect } from 'react';
import ProductsGridTableItem from './components/GridTableItem';
import './styles.css';

const ProductsGridTable = ({ items }) => {
  useEffect(() => {
    const container = document.getElementById('cb-products-grid-table');

    container.scrollTop = 0;
  }, [items]);

  return (
    <div id="cb-products-grid-table" className="cb-products-grid-table">
      {items.map(item => (
        <ProductsGridTableItem key={item.id} item={item} />
      ))}
    </div>
  );
};

export default ProductsGridTable;
