import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { getProductById, updateProduct } from '../../../api/products';
import { notificationError, notificationSuccess } from '../../../core/actions';
import ProductsForm from '../../Products/Form';
import { handleCatchError } from '../ProductDetails/Layout/shared/catchError';
import EditProductLayout from './Layout';

const EditProduct = () => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const { productId } = useParams();
  const history = useHistory();

  const handleGetProduct = useCallback(async () => {
    try {
      const { data } = await getProductById(productId);

      setProduct(data);
    } catch (error) {
      handleCatchError(error);

      history.push('/products');
    }
  }, [productId]);

  const handleFinish = async fields => {
    const formData = new FormData();

    formData.append('name', fields.name);
    formData.append('price', fields.price);
    formData.append('description', fields.description);

    fields.images.forEach((image, index) => {
      formData.append(`images_${index}`, image.url || image.originFileObj);
    });

    setLoading(true);

    try {
      await updateProduct(productId, formData);

      notificationSuccess('Product updated');

      history.push(`/products/${productId}`);
    } catch (error) {
      notificationError('Error updating product');
    } finally {
      setLoading(false);
    }
  };

  const getInitialValues = useCallback(
    () => ({
      name: product.name,
      price: product.price,
      description: product.description,
      images: product.images.map((e, i) => ({
        uid: `prod-${i}`,
        url: e.url,
      })),
    }),
    [product],
  );

  useEffect(() => {
    handleGetProduct();
  }, []);

  return (
    <EditProductLayout>
      {product && (
        <ProductsForm disabled={loading} loading={loading} initialValues={getInitialValues()} onFinish={handleFinish} />
      )}
    </EditProductLayout>
  );
};

export default EditProduct;
