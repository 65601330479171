import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import StandardLayout from '../../../../../Layout/StandardLayout';
import AutoReminderSettings from '../../../../../Layout/AutoReminders/AutoReminderSettings';
import SecondaryPanel from '../../../../../Layout/SecondaryPanel';

const CustomAutoReminder = props => {
  const history = useHistory();

  const setRedirectBack = () => {
    history.push(`/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}`);
  };

  const reminderNameHandler = () => {
    if (props.autoRemMsgs.reminderName.length > 20) {
      return `${props.autoRemMsgs.reminderName.substring(0, 19)}...`;
    }
    return props.autoRemMsgs.reminderName;
  };

  return (
    <StandardLayout
      title={`${props.autoRemMsgs.reminderAddOrEdit} Auto Reminder`}
      description={
        props.autoRemMsgs.reminderAddOrEdit === 'Add'
          ? 'Link an Auto Reminder to this Client Activity'
          : 'Edit the auto reminder that is linked to this client activity'
      }
      // description="Create auto reminders that are linked to this Custom Action"
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Client Activity', to: '/Configuration/client-activity' },
        {
          title: 'Edit Client Activity',
          to: `/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}`,
        }, /// / REMEMBER THIS
        {
          title:
            props.autoRemMsgs.reminderAddOrEdit === 'Add'
              ? `${props.autoRemMsgs.reminderAddOrEdit} Auto Reminder`
              : `${props.autoRemMsgs.reminderAddOrEdit} "${reminderNameHandler()}"`,
        },
      ]}
      classProps="standard-width-with-sidebar"
    >
      <AutoReminderSettings
        setRedirectBack={setRedirectBack}
        cancelRoute={`/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}`} /// / REMEMBER THIS
        popConfirmDesc="This will remove the reminder from this client activity"
        descriptionText="When do you want the associate to be reminded?"
        type="clientEvent" /// / REMEMBER THIS
        match={props.match} /// / REMEMBER THIS
        editRoute={`/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}`} /// / REMEMBER THIS
      />
      {!props.autoRemMsgs.listItemId && !props.match.params.listItemId ? (
        <Redirect to="/Configuration/client-activity" />
      ) : null}
      <SecondaryPanel title={['Automation']} current="Client Activity" />
    </StandardLayout>
  );
};

const mapStateToProps = state => ({
  ...state.auth,
  autoRemMsgs: state.autoRemMsgs,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CustomAutoReminder);
