import React, { useState, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import './styles.scss';
import { useMemo } from 'react';
import { PartyIcon } from '../../../../../../../componentsV2/Layout/Report/components/Icons';


const AutoMessageItem = props => {

  const getLines = useMemo(() => {
    const el = document.getElementById(`item-expanded-span-${props.id}`);
    if(el) {
      return (el.scrollHeight > el.clientHeight) || (el.scrollHeight === 0 && el.clientHeight === 0)
    } else {
      return false
    }
  }, [document.getElementById(`item-expanded-span-${props.id}`), props.id]);

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const messageFormat = useCallback(() => {
    if(props.message) {
      return props.message
      .replaceAll(':name:', props.client_name)
      .replaceAll(':associate:', props.associate_name)
      .replaceAll(':relation_name:', props?.relation)
      .replaceAll(':review:', props.review_link);
    } else {
      return ''
    }
  }, [props.message, props.client_name, props.associate_name, props.review_link, props?.relation]);

  return (
    <div className='cd-automessages-item-container'>
      <span
        id={`item-expanded-span-${props.id}`}
        className={expanded ? '' : 'line-clamp-1'}
      >
        {messageFormat()}
      </span>
      <div className='cd-automessages-item-description'>{`Due on ${moment(props.scheduled_date_time, 'YYYYMMDDhhmm').format('MMM DD, YYYY')}`}</div>
      <div className='cd-automessages-item-description'>
      {props?.FullName === "Birthday" ||
          props?.FullName === "Anniversary" ? (
            <PartyIcon style={{ marginRight: '18px'}}/>
          ) : (
          <FontAwesomeIcon icon="fa-sharp fa-solid fa-dollar-sign" style={{ marginRight: '18px'}} />
          )
        }

        {props.FullName}
      </div>
      {!!Number(props.repeats) && (
        <div className='cd-automessages-item-description'>
          <FontAwesomeIcon
            icon={['fa', 'refresh']}
          />
          {Number(props.repeats) ? `Repeats every ${props.repeat_scale} ${props.repeat_interval.toLowerCase()}${parseInt(props.repeat_scale) > 1 ? 's' : ''}` : ''}
        </div>
      )}
      {getLines && (
        <div
          className='cd-automessages-item-action'
          onClick={toggleExpanded}
        >
          {!expanded ? (
            <FontAwesomeIcon
              icon="fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
            />
          ) : (
            <FontAwesomeIcon
              icon="fa-light fa-arrow-down-left-and-arrow-up-right-to-center"
            />
          )}
        </div>
      )}
    </div>
  );
};
export default AutoMessageItem;
