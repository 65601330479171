import React from 'react';
import { Link } from 'react-router-dom';

const Jewel360 = () => {
  return (
    <>
      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Overview</h3>

        <p>
          Connect your Jewel 360 account with Clientbook! Jewel 360 is a top-rated POS system designed specifically for
          jewelry stores. Jewel 360 allows stores to efficiently manage custom orders, repairs, customer and product
          data. Users can grow and manage their business quickly by using Jewel 360&apos;s intuitive desktop and mobile
          platforms.
        </p>

        <p>
          By integrating with Jewel 360, you will be able to effortlessly combine your business&apos;s existing Jewel
          360 data with Clientbook&apos;s desktop and mobile platforms. Specifically, this will bring your client,
          product and sales data over to Clientbook. Reach out to your Customer Success Manager to get started!
        </p>

        <p>
          Please be sure the Jewel 360 user has the rights to access all relevant information. If the user is removed
          from the connected Jewel 360 account, the account will be removed from Clientbook.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Key Features</h3>

        <p>By adding your Jewel 360 information to Clientbook, you can:</p>

        <ul className="cb-integrations-details__list">
          <li>Send payment requests and track specific Life Events to maximize POS activity.</li>

          <li>Document Sales Opportunities to intensify your sales team&apos;s efficiency.</li>

          <li>Send Product Collections based on your client&apos;s tastes to effectively market your products.</li>

          <li>
            Maximize communication with your clients by creating Auto Messages. These messages can be sent automatically
            on days based on birthdays, anniversaries, special orders, sales etc.
          </li>
        </ul>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Setup Process</h3>

        <p>
          If you want to integrate your Jewel 360 account with Clientbook, reach out to your Customer Success Manager
          and they will assist you with the installation.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Helpful Links</h3>

        <ul className="cb-integrations-details__list">
          <li>
            <Link to="/Help/contact-support">Clientbook Help Center</Link>
          </li>

          <li>
            <a href="https://jewel360.com/contact-us/" target="_blank" rel="noopener noreferrer">
              Jewel 360 Support
            </a>
          </li>
        </ul>
      </section>
    </>
  );
};

export default Jewel360;
