import moment from 'moment';
import React from 'react';
// 24 hour count down function from client_reply_date_time.
// There is another whatsapp timer that is slightly different in components used for chat screens with individual clients
function getTimeRemaining(time) {
  const endTime = moment(time).add(1, 'd');
  const e_timeDuration = moment.duration(endTime);
  const total = e_timeDuration.subtract(moment());
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  let seconds = Math.floor((total / 1000) % 60);
  let minutes = Math.floor((total / 1000 / 60) % 60);
  let hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  if (hours.toString().length < 2) {
    hours = `0${hours}`;
  }
  if (seconds.toString().length < 2) {
    seconds = `0${seconds}`;
  }
  if (minutes.toString().length < 2) {
    minutes = `0${minutes}`;
  }
  if ((hours < 0 && minutes < 0 && seconds < 0) || days < 0) {
    return false;
  }
  if (hours > 22) {
    return <span style={{ paddingLeft: '8px' }}>Now</span>;
  }
  return `${hours}:${minutes}:${seconds}`;
}
export default getTimeRemaining;
