import React from 'react';
import { Drawer } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const ChatModal = ({ type = 'full', children, open, onClose, drawer }) => {
  const handleClose = e => {
    if (e.target.id === 'overlay') {
      onClose();
    }
  };
  switch (type) {
    case 'full':
      return (
        open && (
          <div id="overlay" onClick={handleClose}>
            {children}
          </div>
        )
      );
    case 'right':
      return (
        <Drawer
          {...drawer}
          open={open}
          closeIcon={<LeftOutlined style={{ color: '#000', fontSize: '18px' }} />}
          onClose={onClose}
          headerStyle={{ border: 0, paddingBottom: 0 }}
          bodyStyle={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', overflow: 'hidden' }}
        >
          {children}
        </Drawer>
      );
    default:
      return null;
  }
};

export default ChatModal;
