import React from 'react';
import PhoneInput from 'react-phone-number-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RelFormItems from './RelFormItems';

const RelationshipsForm = ({
  clientId,
  clientName,
  openDeleteModal,
  toggleAddClientForm,
  handleValidateNewClient,
  setShowAddClientForm,
  newClient,
  setNewClient,
  showAddClientForm,
  handleClientChange,
  iso_country_code,
  stores,
  setNewEvent,
  prefixToSend,
  setPrefixToSend,
  relationships,
  setNewRelationship,
}) => {
  const disableNew = () => {
    if (newClient.hasOwnProperty('name') && newClient.name !== '') {
      if (
        (newClient.hasOwnProperty('email') && newClient.email !== '') ||
        (newClient.hasOwnProperty('localNumber') && newClient.localNumber !== '')
      ) {
        return false;
      }
    }
    return true;
  };

  const smsStoreNum = stores.map(st => st.sms_number).filter(store => store !== undefined);
  const smsMobile = phone => {
    if (!phone) {
      return true;
    }
    if (phone !== undefined) {
      const smsCompare = smsStoreNum.filter(number => {
        if (number.replace(/[^0-9]+/g, '') == phone.replace(/[^0-9]+/g, '')) {
          return true;
        }
        return false;
      });
      if (smsCompare.length > 0) {
        notificationError('Client number cannot match store number!');
      } else return true;
    }
  };

  return !showAddClientForm ? (
    <RelFormItems
      toggleAddClientForm={toggleAddClientForm}
      setNewEvent={setNewEvent}
      setPrefixToSend={setPrefixToSend}
      setNewRelationship={setNewRelationship}
      openDeleteModal={openDeleteModal}
      relationships={relationships}
      clientId={clientId}
      clientName={clientName}
    />
  ) : (
    <div className="flex-col-left m-btm-8 w-100-P m-btm-15">
      <span
        className="fs-13 fw-500"
        style={{
          marginBottom: '15px',
        }}
      >
        Add Client
      </span>

      <div className="relationshipNewDiv">
        <FontAwesomeIcon
          icon="fa-regular fa-user"
          className="pos-abs"
          style={{ top: '2px', marginLeft: '11px' }}
          size="xl"
        />
        <input
          className="h-29 rem-pad-width mq-50-P-to-100-P-on-535 border-none outline-none"
          style={{ width: '100%', borderBottom: '1px #818181 solid', paddingLeft: '57px' }}
          placeholder="Name"
          value={newClient?.name}
          onChange={e => handleClientChange('name', e.target.value)}
        />
      </div>
      <PhoneInput
        placeholder="Enter phone number"
        value={newClient?.localNumber}
        onChange={e => {
          handleClientChange('localNumber', e);
          smsMobile(e);
        }}
        defaultCountry={iso_country_code}
        countryOptionsOrder={[iso_country_code, '|']}
        countrySelectProps={{ backgroundcolor: 'rgb(129, 129, 129' }}
        className="w-100-P relationshipNewInput m-btm-12"
      />
      <div className="relationshipNewDiv">
        <FontAwesomeIcon icon="fa-regular fa-envelope" size="xl" className="pos-abs" style={{ top: '4px', marginLeft: '10px' }}/>
        <input
          className="h-29 rem-pad-width mq-50-P-to-100-P-on-535 border-none outline-none"
          style={{ width: '100%', borderBottom: '1px #818181 solid', paddingLeft: '57px' }}
          placeholder="Email Address"
          value={newClient?.email || ''}
          onChange={e => handleClientChange('email', e.target.value)}
        />
      </div>
      <div className="mq-w-100-vw custom-action-save-flex m-top-15">
        <button
          className="save-button-v2 m-btm-8"
          disabled={disableNew()}
          type="button"
          onClick={() => handleValidateNewClient(prefixToSend)}
        >
          Save
        </button>
        <button
          className="cancel-button-v2"
          type="button"
          onClick={() => {
            setNewClient({});
            setShowAddClientForm(false);
            setNewRelationship(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
export default RelationshipsForm;
