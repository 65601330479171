import React, { useEffect, useState } from 'react';
import { Popover, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChecklistDropdownPopover from './components/ChecklistDropdownPopover';
import './styles.css';

const ChecklistDropdown = props => {
  const {
    label,
    actionButtonLabel,
    visible,
    setVisible,
    value,
    onChange,
    requestMethod,
    children,
    overlayStyle = {},
    style = {},
  } = props;

  const [itemsChecked, setItemsChecked] = useState({});

  const handleSetItemsChecked = () => {
    const newItemsChecked = {};

    value.forEach(key => {
      newItemsChecked[key] = true;
    });

    setItemsChecked(newItemsChecked);
  };

  const handleChange = items => {
    onChange(items);
    setVisible(false);
  };

  const handleOpenChange = newOpen => {
    setVisible(newOpen);
    handleSetItemsChecked();
  };

  useEffect(() => {
    handleSetItemsChecked();
  }, [value]);

  return (
    <div className="cb-checklist-dropdown">
      <Popover
        placement="bottomLeft"
        trigger="click"
        open={visible}
        onOpenChange={handleOpenChange}
        style={{ ...style }}
        content={
          <ChecklistDropdownPopover
            label={label}
            value={value}
            itemsChecked={itemsChecked}
            setItemsChecked={setItemsChecked}
            actionButtonLabel={actionButtonLabel}
            requestMethod={requestMethod}
            onChange={handleChange}
          />
        }
        overlayStyle={{ maxWidth: '260px', ...overlayStyle }}
      >
        {children || (
          <Button className="cb-checklist-dropdown__button">
            {label}{' '}
            <FontAwesomeIcon
              icon={['fa', 'chevron-down']}
              style={{ marginLeft: '8px', width: '10px', color: '#000' }}
            />
          </Button>
        )}
      </Popover>
    </div>
  );
};

export default ChecklistDropdown;
