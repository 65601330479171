import React, { useState, useEffect } from 'react';
import { notificationError } from '../../../../core/actions';

const Upload = props => {
  const [upload_style, setUpload_style] = useState('upload_div');
  const [redX, setRedX] = useState('redX');

  useEffect(() => {
    if (props.logoUrl.length > 0) {
      setUpload_style('upload_div_active');
      setRedX('redX_active');
      const arrVars = props.logoUrl.split('/');
      const lastVar = arrVars.pop();
      if (lastVar.length > 15) {
        const truncateUpload = lastVar.substr(0, 15);
        const truncateReturn = `${truncateUpload}...`;
        props.setUploadButtonText(truncateReturn);
      } else {
        props.setUploadButtonText(lastVar);
      }
    }
  }, [props.logoUrl]);

  const delete_input = () => {
    props.setUploadButtonText('Upload');
    setUpload_style('upload_div');
    setRedX('redX');
    props.setUploadImg('');
  };

  const SplitUpload = event => {
    if (event.target.files[0].size < 1600000) {
      props.setUploadImg(event.target.files[0]);
    } else {
      notificationError('Image file size exceeds 1.5mb');
    }
    const arrVars = event.target.value.split('\\');
    const lastVar = arrVars.pop();
    if (lastVar.length > 15) {
      const truncateUpload = lastVar.substr(0, 15);
      const truncateReturn = `${truncateUpload}...`;
      props.setUploadButtonText(truncateReturn);
    } else {
      props.setUploadButtonText(lastVar);
    }
    if (lastVar.length == 0) {
      props.setUploadButtonText('Upload');
      setUpload_style('upload_div');
      setRedX('redX');
    } else {
      setUpload_style('upload_div_active');
      setRedX('redX_active');
    }
  };

  return (
    <div id={upload_style} className=" rem-pad-width inputHeight">
      <input
        type="file"
        id="file"
        className="inputfile_red "
        accept=".jpg, .png"
        value=""
        onChange={event => {
          SplitUpload(event);
        }}
        disabled={props.disabled}
      />
      {props.upload_label}
      <img
        alt="#"
        id={redX}
        src="https://dashboard-v2-images.s3.amazonaws.com/ic-remove-red.svg"
        onClick={() => delete_input()}
      />
    </div>
  );
};

export default Upload;
