import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import Button from '../../../Button';
import StyledLink from '../../../StyledLink';
import { getList, postRequest, deleteRequest, getRequest } from '../../../../../core/apiRequests';
import { notificationError, notificationSuccess } from '../../../../../core/actions';
import {
  setRemTempMsgText,
  setRemTempMsgId,
  setRemTempAddEdit,
  deleteRemTempMsgs,
} from '../../../../../reducers/autoRemMsgs';
import { setSalesUpdate } from '../../../../../reducers/salesTriggers';
import TextAreaWithActions from '../../../TextAreaWithActions';
import PopConfirm from '../../../PopConfirm';
import useActionTextAreaFiles, { actionTextAreaFileActions } from '../../../TextAreaWithActions/useActionTextAreaFiles';

const ReminderMessageTemplate = props => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [fileData, fileDispatch] = useActionTextAreaFiles();

  const history = useHistory();
  const [reviewLink, setReviewLink] = useState('[Review Link]');

  useEffect(() => {
    if (props.autoRemMsgs.images) {
      fileDispatch({
        type: actionTextAreaFileActions.setToSingleImageUrl,
        payload: props.autoRemMsgs.images,
      });
    } else {
      fileDispatch({
        type: actionTextAreaFileActions.clear,
      });
    }
  }, [props.merchantId]);

  useEffect(() => {
    let { storeId } = props;
    if (props.merchantId && props.stores.length > 1) {
      if (storeId === 0) {
        storeId = props.stores[1].id;
      }
      (async () => {
        const reqObj = {
          params: ['associates', props.userId, storeId, 'reviewLink'],
        };
        const data = await getRequest(reqObj);
        if (data[0].review_link) {
          setReviewLink(data[0].review_link);
        }
      })();
    }
  }, [props.merchantId, props.userId, props.stores]);

  useEffect(() => {
    if (props.merchantId) {
      if (!props.autoRemMsgs.msgTempId && window.location.href.includes('edit-message-template')) {
        setRemTempAddEdit('Edit');
        (async () => {
          const data = await getList(
            props.type,
            props.merchantId,
            'messageTemplates',
            props.match.params.reminderId,
            `?templateId=${props.match.params.templateId}`,
          );
          if (data && data.length > 0) {
            setRemTempMsgText(data[0].msg);
            setRemTempMsgId(data[0].id);
            if (data[0].image_url) {
              fileDispatch({
                type: actionTextAreaFileActions.setToSingleImageUrl,
                payload: data[0].image_url,
              });
            } else {
              fileDispatch({
                type: actionTextAreaFileActions.clear,
              });
            }
          } else {
            history.push(props.cancelRoute);
            console.error('Failed to get message template');
          }
        })();
      }
    }
  }, [props.merchantId]);

  const createReminderTemplate = () => {
    const {
      type,
      merchantId,
      autoRemMsgs: { msgTempText, msgTempId },
    } = props;
    const s3_images = fileData.images.urls.filter(image => image.includes('s3.amazonaws.com'));
    const cleanedURLs = fileData.images.urls.filter(image => image.includes('blob'));
    const cleanedImgObj = { urls: cleanedURLs, files: [...fileData.images.files] };
    const formattedText = msgTempText
    .replace('[Client Name]', ':name:')
    .replace('[Associate Name]', ':associate:')
    .replace('[Review Link]', ':review:')
    .replace('Client Name', ':name:')
    .replace('Associate Name', ':associate:')
    .replace('Review Link', ':review:');
    setSalesUpdate(!props.salesTriggers.update ?? false);
    if (!formattedText) {
      notificationError('Template Message Required');
    } else if (Number(props.match.params.reminderId)) {
      const dataObj = {
        msg: formattedText,
      };
      if (msgTempId) {
        dataObj.id = msgTempId;
      }
      (async () => {
        const reqObj = {
          params: [type, merchantId, props.match.params.reminderId, 'salesActionConfig'],
          query: { s3Images: s3_images },
          data: dataObj,
          images: cleanedImgObj,
        };
        const data = await postRequest(reqObj);
        if (data.id) {
          if (msgTempId) {
            notificationSuccess('Message Template Updated');
          } else {
            notificationSuccess('Message Template Created');
          }
          setRemTempMsgId(data.id);
          setRemTempAddEdit('Edit');
          history.push(`${props.cancelRoute}/edit-message-template/${data.id}`);
        }
      })();
    }
  };

  const deleteMessageTemplate = () => {
    const { type, merchantId } = props;
    (async () => {
      const reqObj = {
        params: [type, merchantId, props.match.params.reminderId, 'messageTemplates', props.match.params.templateId],
        query: { deletedBy: '' },
      };
      const data = await deleteRequest(reqObj);
      if (data.id) {
        deleteRemTempMsgs([...props.autoRemMsgs.deleteMessageTemplates, props.match.params.templateId]);
        notificationSuccess('Message Template Deleted');
      }
      setShowPopConfirm(false);
      history.push(`${props.cancelRoute}`);
    })();
  };

  return (
    <div className="align-left">
      <div
        style={{
          maxWidth: '500px',
          justifyContent: 'flex-end',
          display: 'flex',
        }}
      />

      <span style={{ display: 'block' }}>Message Body</span>
      {/* <TemplatedTextArea
        val={props.autoRemMsgs.msgTempText}
        changeHandler={setRemTempMsgText}
      /> */}
      <TextAreaWithActions
        val={props.autoRemMsgs.msgTempText}
        changeHandler={setRemTempMsgText}
        fileData={fileData}
        fileDispatch={fileDispatch}
        containerStyleProps={{
          marginTop: '16px',
          width: '100%',
          maxWidth: '600px',
        }}
        styleProps={{
          height: '18vh',
        }}
        autoMessageOrReminder
        reviewLink={null}
        template
        associate=":associate:"
      />

      <div className="m-top-37 w-600">
        <div className="mq-w-100-vw custom-action-save-flex">
          <StyledLink to={props.cancelRoute}>
            <button type="button" className="save-button-v2 m-btm-8" onClick={createReminderTemplate}>
              Save
            </button>
          </StyledLink>
          {props.autoRemMsgs.msgTempAddOrEdit === 'Edit' ? (
            <Button classname="deleteButton-v2 m-btm-8" onclick={() => setShowPopConfirm(true)}>
              Delete
            </Button>
          ) : (
            <div />
          )}
          <StyledLink to={props.cancelRoute}>
            <button type="button" className="cancel-button-v2">
              Cancel
            </button>
          </StyledLink>
        </div>
      </div>

      {showPopConfirm ? (
        <PopConfirm
          confirm="Are you sure you want to remove this message template?"
          description="Delete Message Template"
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={deleteMessageTemplate}
        />
      ) : null}

      {/* {redirect ? <Redirect to={props.cancelRoute} /> : null} */}
    </div>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  salesTriggers: state.salesTriggers,
  autoRemMsgs: state.autoRemMsgs,
});
export default connect(mapStateToProps, {})(ReminderMessageTemplate);
