import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { ShoppingOutlined } from '@ant-design/icons';
import { Button, Timeline } from 'antd-v5';
import { useHistory } from 'react-router';
import { MassMessages } from '../../../../../componentsV2/Layout/Report/components/Icons';
import { getPaymentReportHistory } from '../helpers/paymentRequestsAPI';
import { buildListFromResponse } from '../helpers/expandableHelpers';
import { PTRRows } from './PRTRows';

const PaymentRequestsExpandableRow = ({ record }) => {
  const [loading, setLoading] = useState(true);
  const [rowList, setRowList] = useState([]);
  const { products, sentByFirstName, sentByLastName, clientName, sent, paymentRequestId } = record;
  const sentBy = `${sentByFirstName || ''} ${sentByLastName || ''}`.trim();
  const history = useHistory();

  useEffect(() => {
    getReportMetaData();
  }, [record])

  const getReportMetaData = useCallback(() => {
    setLoading(true)

    const params = { paymentRequestId };

    getPaymentReportHistory(params)
    .then((res) => {
      console.log("the response: ", res);
      const sentObj = {
        type: 'SENT',
        date: sent,
        clientName,
        sentBy,
      };
      const builtAndSorted = buildListFromResponse(res, sentObj, clientName);
      setRowList(builtAndSorted);
    })
    .catch((error) => {
      console.log("the error: ", error)
    })
    .finally(() => {
      setLoading(false)
    });
  }, [record]);

  const renderList = useCallback(() => {
    return PTRRows({ rowList });
  }, [rowList]);


  return (
    <div className="m-left-23">
      <div className="flex-row-spacebetween-nowrap">
        <Timeline
          pending={loading ? 'Loading...' : null}
          items={[
            ...renderList(),
            ...products.map(product => (
              {
                key: product.id,
                dot: <ShoppingOutlined style={{ fontSize: '16px', opacity: 0.45, color: '#000' }} />,
                children: <p>{product.description}</p>
              }
            ))
          ]}
        />
        <Button
          onClick={() => history.push(`/Payments/details/${paymentRequestId}?src=report`)}
        >
          View Details
        </Button>
      </div>
    </div>
  );
};
export default PaymentRequestsExpandableRow;
