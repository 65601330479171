import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { startSocket } from './socketInit';
import { messageHandler, notificationHandler, connectionHandler, modifyMessageStatus } from './controller/socketRouter';

let socket;

const SocketHandler = props => {
  const history = useHistory();

  const { userId, token, merchantId, newMessages, setNewMessages } = props;

  useEffect(() => {
    async function initSocket() {
      if (!userId && socket) {
        socket.removeEventListener('message');
        socket.removeEventListener('notify');
        socket.removeEventListener('connecting'); // yeah I dono either if this is required, I had to do it for message and notify to go away
        socket.removeEventListener('connect_failed'); // yeah I dono either if this is required, I had to do it for message and notify to go away
        socket.removeEventListener('connect'); // yeah I dono either if this is required, I had to do it for message and notify to go away
        socket.removeEventListener('disconnect'); // yeah I dono either if this is required, I had to do it for message and notify to go away
        socket.removeEventListener('status'); // yeah I dono either if this is required, I had to do it for message and notify to go away
        socket = null; // because setting it to null was NOT enough to wipe the socket object clean

        if (newMessages.length) {
          setNewMessages([]);
        }
      }

      if (userId && token && !socket) {
        socket = await startSocket(props.userId, merchantId, token);

        /* socket.on('connecting', () => {}); */
        /* socket.on('connect_failed', () => {}); */

        socket.on('connect', () => {
          connectionHandler('success', 'Connected');
        });

        socket.on('disconnect', () => {
          connectionHandler('warning', 'Connection Lost');
        });

        socket.on('message', data => {
          messageHandler(data, history, props);
        });

        socket.on('notify', data => {
          notificationHandler(data);
        });

        socket.on('status', data => {
          modifyMessageStatus(data);
        });

        socket.onAny((event, ...args) => {
          console.log(event, args);
        });
      }
    }

    initSocket();
  }, [userId, merchantId, token, newMessages, setNewMessages]);

  return <div />;
};

const mapStateToProps = state => ({
  ...state.auth,
  newMessages: state.navigation.newMessages,
});

const mapDispatchToProps = dispatch => ({
  setNewMessages(val) {
    dispatch({
      type: 'SET_NEW_MESSAGES',
      payload: val,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SocketHandler);
