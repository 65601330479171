import { Button } from 'antd';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconButton = ({ onClick, icon, classname }) => (
  <Button
    type="text"
    icon={<FontAwesomeIcon icon={icon} />}
    onClick={onClick}
    shape="circle"
    style={{ paddingTop: '4px' }}
    className={{ classname }}
  />
);

export default IconButton;
