import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import StyledLink from '../../../Layout/StyledLink';

const SPButton = props => {
  // const [showList, setShowList] = useState(false);
  const [current, setCurrent] = useState('expanded-nav-item');
  const [spTitle, setSpTitle] = useState('');

  useEffect(() => {
    if (window.location.href.includes(props.title)) {
      props.selectNav(props.title);
    }
  }, [window.location.href]);

  if (props.menuOptions.length > 0) {
    return (
      <div
        className="sp-button-container "
        style={props.navigation.selected === props.title ? { opacity: '1', backgroundColor: '#007bd0' } : {}}
      >
        {/* //  <div className="sp-button-container" style={showListLocal === titleLocal ? { opacity: '1', backgroundColor: '#007bd0' } : {}}> */}
        <div
          className="sp-button"
          onClick={() => {
            props.setShowList(props.showList === props.title ? '' : props.title);
            props.selectNav(props.title);
          }}
        >
          <div>
            {props.image}
            {props.title}
          </div>
        </div>
        {props.showList === props.title ? <img className="menuCarrot hide-carrot-short" src="https://dashboard-v2-images.s3.amazonaws.com/menu-carrot.svg" /> : null}
        {props.showList === props.title ? (
          <div className="expanded-nav" style={{ zIndex: '102' }}>
            <ul>
              <p className="expandHeader">{props.title}</p>
              <hr className="expandHR"></hr>
            </ul>
            <ul className="secondary-menu-short hide-scrollbar">
              {props.menuOptions.map((opt, i) => {
                return (
                  <li className={'SPlink'} id={spTitle} key={i} onClick={() => props.setShowList('')}>
                    <StyledLink
                      classprops={current}
                      styleprops={{
                        height: '48px',
                        display: 'inline-block',
                        width: '100%',
                      }}
                      to={opt.link}
                    >
                      {opt.title}
                    </StyledLink>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>
    );
  } else {
    const menuRoute = props.title === 'Home' ? '' : props.title;
    return (
      <div
        className="sp-button-container"
        style={props.navigation.selected === props.title ? { opacity: '1', backgroundColor: '#007bd0' } : {}}
        onClick={() => {
          props.setShowList_noExpansion(props.showList_noExpansion === props.title ? ' ' : props.title);
          props.selectNav(props.title);
        }}
      >
        <StyledLink classprops="sp-button" to={`/${menuRoute}`}>
          <div>
            {props.image}
            {props.title}
          </div>
        </StyledLink>
      </div>
    );
  }
};
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
  selectNav(val) {
    dispatch({
      type: 'SELECT_NAV',
      payload: val,
    });
  },
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SPButton));
