/* eslint-disable max-len */
import React, { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import Input from '../../../../CustomizedAntDesign/Input/Input';

const GoogleAnalytics = ({ obj, setObj }) => {
  const [open, setOpen] = useState(false);
  const [googleStatus, setGoogleStatus] = useState('');

  const checkGoogle = id => {
    const valid = /^(G|UA|YT|MO)-[a-zA-Z0-9-]+$/.test(id);
    if (valid || id.length === 0) {
      setGoogleStatus('');
      setObj({ ...obj, googleAnalytics: { ...obj.googleAnalytics, google_id: id } });
    } else {
      setGoogleStatus('error');
      setObj({ ...obj, googleAnalytics: { ...obj.googleAnalytics, google_id: id } });
    }
  };

  return (
    <div className="borderBottom">
      <div
        className="googleAnalyticsDiv maw-1200"
        id="GoogleAnalyticsOuterDiv"
        onClick={() => setOpen(!open)}
        onKeyDown={() => setOpen(!open)}
        role="button"
        tabIndex={0}
      >
        <div className="blackListedPagesDiv">
          <TitleAndContent boldedTitle="Google Analytics" />
          {open ? (
            <UpOutlined style={{ fontSize: '21px', marginRight: '10px' }} />
          ) : (
            <DownOutlined style={{ fontSize: '21px', marginRight: '10px' }} />
          )}
        </div>
      </div>
      {open ? (
        <div style={{ marginTop: '-22px', marginBottom: '20px' }}>
          <TitleAndContent
            boldedTitle="Google Analytics ID"
            description="Add your Analytics ID to track web chat traffic"
            input={[
              <div className="w-100-P" key="GoogleAnalyticsId">
                <Row>
                  <Col span={6}>
                    <Input
                      value={obj.googleAnalytics.google_id}
                      status={googleStatus}
                      id="GoogleAnalyticsId"
                      placeholder="G-##########"
                      onChange={e => checkGoogle(e.target.value)}
                      className="inputHeight common_inputStyle w-333"
                    />
                  </Col>
                </Row>
              </div>,
            ]}
          />
          <div className="fs-11 fw-500 m-btm-6 align-left" style={{ color: '#9c9c9c', margin: '34px 0px 20px 20px' }}>
            Create personalized names for the events that can be tracked by Google Analytics
          </div>
          <TitleAndContent
            boldedTitle="Web Chat Initiation Clicks"
            description="This event tracks each time the web chat is initiated by your users"
            input={[
              <div className="w-100-P" key="InitiationClicks">
                <Row>
                  <Col span={6}>
                    <Input
                      id="InitiationClicks"
                      placeholder="chat_initiated"
                      onChange={e =>
                        setObj({
                          ...obj,
                          googleAnalytics: { ...obj.googleAnalytics, initiation_clicks: e.target.value },
                        })
                      }
                      className="inputHeight common_inputStyle w-333"
                      value={obj.googleAnalytics.initiation_clicks}
                    />
                  </Col>
                </Row>
              </div>,
            ]}
          />
          <TitleAndContent
            boldedTitle="Web Chat 'Text Me' Clicks"
            description="This event tracks each time your users click on the 'Text Me' button"
            input={[
              <div className="w-100-P" key="ChatSubmit">
                <Row>
                  <Col span={6}>
                    <Input
                      id="chat_submit"
                      placeholder="chat_submit"
                      onChange={e =>
                        setObj({ ...obj, googleAnalytics: { ...obj.googleAnalytics, textMe_clicks: e.target.value } })
                      }
                      className="inputHeight common_inputStyle w-333"
                      value={obj.googleAnalytics.textMe_clicks}
                    />
                  </Col>
                </Row>
              </div>,
            ]}
          />
          <TitleAndContent
            boldedTitle="Web Chat Closing Clicks"
            description="This event tracks each time your users close the web chat"
            input={[
              <div className="w-100-P" key="chatClosed">
                <Row>
                  <Col span={6}>
                    <Input
                      id="chat_closed"
                      placeholder="chat_closed"
                      onChange={e =>
                        setObj({ ...obj, googleAnalytics: { ...obj.googleAnalytics, closing_clicks: e.target.value } })
                      }
                      className="inputHeight common_inputStyle w-333"
                      value={obj.googleAnalytics.closing_clicks}
                    />
                  </Col>
                </Row>
              </div>,
            ]}
          />
        </div>
      ) : null}
    </div>
  );
};
export default GoogleAnalytics;

const TitleAndContent = props => {
  return (
    <div className="flex-col-left m-left-21 m-top-20">
      <div className="add-edit-wrap">
        <span className="fs-13 fw-500 "> {props.boldedTitle}</span>
      </div>
      <span className="fs-11 fw-500 m-btm-6" style={{ color: '#9c9c9c' }}>
        {props.description}
      </span>
      {props.input}
    </div>
  );
};
