import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Form, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Modal from '../../../../../../../CustomizedAntDesign/Modal/Modal';
import Input from '../../../../../../../CustomizedAntDesign/Input/Input';
import Button from '../../../../../../../CustomizedAntDesign/Button';
import { API_URL } from '../../../../../../../../constants';
import './styles.css';

const IntegrationsKwiModalLoader = () => {
  return (
    <div className="cb-integrations-modal__loader">
      <LoadingOutlined />
      <span>Logging in...</span>
    </div>
  );
};

const IntegrationsKwiModal = ({ visible, setVisible, setPosType }) => {
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const [form] = Form.useForm();

  const username = Form.useWatch('username', form);
  const password = Form.useWatch('password', form);

  const usernamePlaceholder = useMemo(() => (requestError ? 'Invalid username' : 'Username'), [requestError]);
  const passwordPlaceholder = useMemo(() => (requestError ? 'Incorrect Password' : 'Password'), [requestError]);

  const history = useHistory();

  const handleInstallKwi = async () => {
    setRequestError(false);
    setLoading(true);

    try {
      await axios.post(`${API_URL}/xa/integrations/kwi`, {
        username,
        password,
      });

      setPosType('KWI');

      message.success('KWI installed successfully');
      history.push('/help/integrations/kwi/settings');

      setVisible(false);
    } catch (error) {
      message.error('Error installing KWI');

      form.setFieldsValue({
        username: '',
        password: '',
      });

      setRequestError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      footer={null}
      closable={false}
      width={348}
      destroyOnClose
      className="cb-integrations-modal"
    >
      {loading && <IntegrationsKwiModalLoader />}

      <h2 className="cb-integrations-modal__title">KWI API Credentials</h2>

      <h3 className="cb-integrations-modal__subtitle">
        Please enter the API username
        <br /> and password
      </h3>

      <Form form={form} autoComplete="off" className={requestError ? 'cb-integrations-modal__input-error' : ''}>
        <Form.Item name="username">
          <Input prefix={<FontAwesomeIcon icon="far fa-user" />} placeholder={usernamePlaceholder} />
        </Form.Item>

        <Form.Item name="password" className={requestError ? 'cb-integrations-modal__input-error' : ''}>
          <Input type="password" prefix={<FontAwesomeIcon icon="far fa-key" />} placeholder={passwordPlaceholder} />
        </Form.Item>

        <span className="cb-integrations-modal__error">{requestError && 'Check your information and try again'}</span>

        <Form.Item className="cb-integrations-details__form-footer">
          <Button disabled={loading} onClick={() => setVisible(false)} block>
            Cancel
          </Button>

          <Button type="primary" disabled={loading || !username || !password} onClick={handleInstallKwi} block>
            Continue
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  setPosType(val) {
    dispatch({
      type: 'SET_POS_TYPE',
      payload: val,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsKwiModal);
