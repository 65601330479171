import store from '../core/store';
import { dispatcher } from '../core/actions';

const initialState = {
  addOrEdit: '',
  trigger_title: 'product category',
  products: [
    { text: 'rings', id: 0 },
    { text: 'bracelets', id: 1 },
    { text: 'earrings', id: 2 },
    { text: 'studs', id: 3 },
  ],
  productTypes: {},
  actionTypes: {},
  categoryId: 0,
  triggerActions: [], // as they add in actions, add to this array
  startRange: '',
  endRange: '',

  tiggerId: '',
  active: 1,
  name: '',
  minPrice: '',
  maxPrice: '',
  triggerCategories: [],
  actionConfigs: {},

  actions: [],
  triggers: [],
  categories: [],
  actionTitle: '',
  actionDescription: '',
  actionId: '',
  actionImg: '',
  actionMsgTemplates: [],
  actionMsgId: '',
  actionMsgText: '',

  trig_reminderCount: 1,
  trig_reminderInterval: 'WEEK',
  trig_reminderRepeat: false,

  trig_repeat_reminderCount: 1,
  trig_repeat_reminderInterval: 'WEEK',

  // auto message
  // trig_autoMessage : "",
  // trig_autoMessageId: ""
};

export default function reducer(state = initialState, action) {
  const state2 = state;
  switch (action.type) {
    case 'SET_TRIGGER_TITLE':
      return { ...state2, trigger_title: action.payload };
    case 'SET_TRIGGER_ADD_EDIT':
      return { ...state2, addOrEdit: action.payload };
    case 'SET_TRIGGER_PRODUCTS':
      return { ...state2, products: action.payload };
    case 'SET_TRIGGER_PRODUCT_TYPES':
      return { ...state2, productTypes: action.payload };
    case 'SET_TRIGGER_ACTION_TYPES':
      return { ...state2, actionTypes: action.payload };
    case 'SET_TRIGGER_CATEGORY':
      return { ...state2, categoryId: action.payload };
    case 'SET_TRIGGER_ACTIONS':
      return { ...state2, actions: action.payload };
    case 'SET_TRIGGER_ACTION_OBJ':
      return { ...state2, ...action.payload };
    case 'SET_TRIGGER_ACTION_DESC':
      return { ...state2, actionDescription: action.payload };
    case 'SET_TRIGGER_ACTIONID':
      return { ...state2, actionId: action.payload };
    case 'SET_TRIGGER_START':
      return { ...state2, startRange: action.payload };
    case 'SET_TRIGGER_END':
      return { ...state2, endRange: action.payload };
    case 'SET_TRIGGER_REM_COUNT':
      return { ...state2, trig_reminderCount: action.payload };
    case 'SET_TRIGGER_REM_INTERVAL':
      return { ...state2, trig_reminderInterval: action.payload };
    case 'SET_TRIGGER_REM_REPEAT':
      return { ...state2, trig_reminderRepeat: action.payload };
    case 'SET_TRIGGER_REPEAT_COUNT':
      return { ...state2, trig_repeat_reminderCount: action.payload };
    case 'SET_TRIGGER_REPEAT_INT':
      return { ...state2, trig_repeat_reminderInterval: action.payload };
    case 'SET_TRIGGER_ACTION_TITLE':
      return { ...state2, trig_action_title: action.payload };
    case 'SET_TRIGGER_AUTO_MSG':
      return { ...state2, trig_autoMessage: action.payload };
    case 'SET_SALES_UPDATE':
      const a = { ...state2, update: action.payload };
      return a;
    case 'SET_TRIGGER_AUTO_MSG_ID':
      return { ...state2, trig_autoMessageId: action.payload };
    default:
      break;
  }
  return state2;
}

export const setTrigTitle = val => {
  dispatcher('SET_TRIGGER_TITLE', val);
};
export const setAddEditTrigger = val => {
  dispatcher('SET_TRIGGER_ADD_EDIT', val);
};
export const setTriggerCategory = val => {
  dispatcher('SET_TRIGGER_CATEGORY', val);
};
export const setTriggerProducts = val => {
  dispatcher('SET_TRIGGER_PRODUCTS', val);
};
export const setTriggerActions = val => {
  dispatcher('SET_TRIGGER_ACTIONS', val);
};
export const setTriggerActionObj = val => {
  dispatcher('SET_TRIGGER_ACTION_OBJ', val);
};
export const setTriggerActionDesc = val => {
  dispatcher('SET_TRIGGER_ACTION_DESC', val);
};
export const setTriggerActionId = val => {
  dispatcher('SET_TRIGGER_ACTIONID', val);
};
export const setTriggerStartRange = val => {
  dispatcher('SET_TRIGGER_START', val);
};
export const setTriggerEndRange = val => {
  dispatcher('SET_TRIGGER_END', val);
};
export const setTriggerReminderCount = val => {
  dispatcher('SET_TRIGGER_REM_COUNT', val);
};
export const setTriggerReminderInterval = val => {
  dispatcher('SET_TRIGGER_REM_INTERVAL', val);
};
export const setTriggerReminderRepeat = val => {
  dispatcher('SET_TRIGGER_REM_REPEAT', val);
};
export const setTriggerRepeatCount = val => {
  dispatcher('SET_TRIGGER_REPEAT_COUNT', val);
};
export const setTriggerRepeatInterval = val => {
  dispatcher('SET_TRIGGER_REPEAT_INT', val);
};
export const setTriggerTitleAction = val => {
  dispatcher('SET_TRIGGER_ACTION_TITLE', val);
};
export const setTriggerAutoMessage = val => {
  dispatcher('SET_TRIGGER_AUTO_MSG', val);
};
export const setTriggerAutoMsgId = val => {
  dispatcher('SET_TRIGGER_AUTO_MSG_ID', val);
};
export const setTriggerProductTypes = val => {
  dispatcher('SET_TRIGGER_PRODUCT_TYPES', val);
};
export const setTriggerActionTypes = val => {
  dispatcher('SET_TRIGGER_ACTION_TYPES', val);
};
export const setSalesUpdate = val => {
  dispatcher('SET_SALES_UPDATE', val);
};
