import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Table from '../../../Layout/Table';
import StyledLink from '../../../Layout/StyledLink';
import MessageConfigToday from './MessageConfigToday';
import Bubble from './todayBubble';
import { setTableType } from '../../../../reducers/pagination';

const TodayPayments = props => {
  const [isShown, setIsShown] = useState('');
  const history = useHistory();

  const rowClickHandler = (data, e) => {
    if (e.target.id === 'todayMessageIconDiv' || e.target.tagName == 'svg' || e.target.tagName == 'path') {
    } else {
      history.push(`/Payments/details/${data.payment_request_id}`);
    }
  };

  const tableHeaders = [
    {
      keyName: 'datetime',
      title: 'Date',
      style: { width: '5%', midWidth: '120px' },
      headerClassName: '',
      rowClassName: '',
      sortable: true,
      render: t => {
        if (t) {
          return moment(t, 'YYYYMDD').format('MM/DD/YYYY');
        }
      },
    },
    {
      keyName: 'total_amount',
      title: 'Amount',
      style: { width: '5%' },
      headerClassName: '',
      rowClassName: '', // we can apply all styles to the header now.
      sortable: true, // This targets the header's className
      render: amount => `$${amount}`,
    },
    {
      keyName: 'name',
      style: { width: '10%', minWidth: '120px' },
      title: 'Client Name',
      sortable: true, // sort allows gives us the ability to sort the table
    },
    {
      keyName: 'status',
      style: { width: '15%', minWidth: '120px' },
      title: 'Client Recent Activity',
      sortable: true, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        if (
          o.status == 'PAID_MANUALLY' ||
          o.status == 'PAID_BY_CC' ||
          o.status == 'PAID_WITH_APPLE_PAY' ||
          o.status == 'PAID_WITH_GOOGLE_PAY'
        ) {
          return 'Client paid payment request';
        }
        if (o.status == 'VIEWED') {
          return 'Client viewed payment request';
        }
        if (o.status == 'CANCELED') {
          return 'Client canceled payment request';
        }
        if (o.status == 'REFUNDED') {
          return 'Client refunded payment request';
        }
        if (o.status == 'PAYMENT_FAILED') {
          return 'No payment method available';
        }
        if (o.status == 'UNPAID') {
          return 'Payment request sent to client';
        }
      },
    },
    {
      keyName: 'status',
      style: { width: '5%', textAlign: 'center' },
      title: 'Status',
      render: (t, o) => {
        const bubbleStyles = {
          padding: '4px 0 2px 0',
        };
        if (t == 'PAID_MANUALLY' || t == 'PAID_BY_CC' || t == 'PAID_WITH_APPLE_PAY' || t == 'PAID_WITH_GOOGLE_PAY') {
          return <Bubble text="PAID" color="blue" style={bubbleStyles} />;
        }
        if (t == 'VIEWED') {
          return <Bubble text={t} color="yellow" style={bubbleStyles} />;
        }
        if (t == 'CANCELED') {
          return <Bubble text={t} color="gray" style={bubbleStyles} />;
        }
        if (t == 'REFUNDED') {
          return <Bubble text={t} color="gray" style={bubbleStyles} />;
        }
        if (t == 'PAYMENT_FAILED') {
          return <Bubble text="FAILED" color="red" style={bubbleStyles} />;
        }
        if (t == 'UNPAID') {
          return <Bubble text="SENT" color="yellow" style={bubbleStyles} />;
        }
      },
      sortable: true,
    },
    {
      keyName: '',
      style: { width: '5%' },
      headerClassName: 'align-center',
      title: 'Message',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        return (
          <div className="align-center pos-rel" style={{ maxHeight: '34px' }} key={o.id}>
            <MessageConfigToday
              keyVar={Math.floor(Math.random() * o.id)}
              msgObj={o}
              setShowPopConfirm={props.setShowPopConfirm}
              initiateTemplates={props.initiateTemplates}
              setIsShown={setIsShown}
              isShown={isShown}
              setSelectedClient={props.setSelectedClient}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div className="align-left m-top-50" onClick={() => setTableType('TODAY_PAYMENTS')}>
      <div className="flex-row-spacebetween-nowrap">
        <span className="fs-15 fw-500">Payments</span>
      </div>
      {props.data.length > 0 ? (
        <div>
          <Table
            data={props.data}
            tableHeaders={tableHeaders}
            tableClassName="lg-table-sidebar rem-pad-width"
            optionalButton={null}
            onRowClick={rowClickHandler}
            tableName="TODAY_PAYMENTS"
          />
          <div className="w-100-P flex-col-center m-top-10">
            <StyledLink to="/all-payments" id="ViewAllPayments" classprops="fs-13 blue-link fw-500">
              View All Payments
            </StyledLink>
          </div>
        </div>
      ) : (
        <div className="align-center h-173 w-100-P">
          <span className="pos-rel gray fs-13" style={{ top: '45%' }}>
            No Payments
          </span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({ auth: state.auth, payments: state.payments });
export default connect(mapStateToProps, {})(TodayPayments);
