import {
  setTodayClientChat,
  setTodayReviewLink,
  setTodayTableData,
  setTodayTempTableData,
} from '../../../../reducers/today';
import { getRequest } from '../../../../core/apiRequests';
import store from '../../../../core/store';
import { setMsgReminderDescription, setMsgReminderId } from '../../../../reducers/messaging';

export default (clientObj, setShowPopConfirm) => {
  // store.getState is how we get state in a function not a component
  const props = store.getState();
  let storeId = clientObj.store_id;
  const fakeObj = {
    clientRecipient: 0,
    reminderId: null,
    name: '',
    storeId: 0,
    currentScope: 'Tag',
    temporaryConfigId: 0,
  };
  if (props.auth.merchantId && props.auth.stores.length > 1) {
    if (storeId === 0) {
      storeId = props.auth.stores[1].id;
    }
    (async () => {
      const reqObj = {
        params: ['associates', props.auth.userId, storeId, 'reviewLink'],
      };
      const data = await getRequest(reqObj);
      if (data[0].review_link) {
        setTodayReviewLink(data[0].review_link);
      }
    })();
  }

  if (clientObj.clientId || clientObj.client_id) {
    fakeObj.clientRecipient = clientObj.client_id;
  }
  // WhatsAppConfiguration
  setShowPopConfirm({
    ...clientObj,
    reminderId: clientObj.id || null,
  });

  if (clientObj.id) {
    fakeObj.reminderId = clientObj.id;

    setMsgReminderId(clientObj.id);
    setMsgReminderDescription(clientObj.description);
  }
  if (clientObj.name) {
    fakeObj.name = clientObj.name;
  }
  if (clientObj.store_id) {
    fakeObj.storeId = clientObj.store_id;
  }
  if (clientObj.custom_event_action_config_id) {
    fakeObj.currentScope = 'Client Activity';
    fakeObj.temporaryConfigId = clientObj.custom_event_action_config_id;
  } else if (clientObj.life_event_action_config_id) {
    fakeObj.currentScope = 'Life Event';
    fakeObj.temporaryConfigId = clientObj.life_event_action_config_id;
  } else if (clientObj.tag_action_config_id) {
    fakeObj.currentScope = 'Tag';
    fakeObj.temporaryConfigId = clientObj.tag_action_config_id;
  } else if (clientObj.sales_opp_action_config_id) {
    fakeObj.currentScope = 'Sales Opportunity';
    fakeObj.temporaryConfigId = clientObj.sales_opp_action_config_id;
  }
  if (clientObj.last_sent_timestamp) {
    (async () => {
      const reqObj = {
        params: ['chatMessages', props.auth.merchantId, 'globalTemplates'],
      };
      const data = await getRequest(reqObj);
      if (data) {
        setTodayTableData(data);
        setTodayTempTableData(data);
      }
    })();
  }
  setTodayClientChat({ ...fakeObj });
};
