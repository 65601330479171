import React, { useState, useEffect, useRef } from 'react';
import { Drawer } from 'antd';
import { connect } from 'react-redux';
import WhatsappInputTemplateCard from './WhatsappInputTemplateCard';

const ViewAllWhatsappTemplates = props => {
  const { allTemplates, configObj, setActiveTemplate, viewAllTemplates, toggleViewAll } = props;
  const [width, setWidth] = useState(window.innerWidth);
  const isMounted = useRef(null);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const columnBuilder = (list, colType) => {
    return list.map((tmpObj, i) => (
      <WhatsappInputTemplateCard
        key={i + colType}
        templateObj={tmpObj}
        configObj={configObj}
        disableTruncate
        styleProps={{ width: 'auto', marginBottom: '15px', opacity: 0.7 }}
        setActiveTemplate={setActiveTemplate}
        selectHandler={setActiveTemplate}
        onClickSelectHandler={props.onClickSelectHandler}
      />
    ));
  };

  return (
    <Drawer
      closable={false}
      push={false}
      width={600}
      open={viewAllTemplates}
      onClose={() => toggleViewAll(false)}
      title="WhatsApp Templates"
    >
      <div
        className="flex-col-center h-100-P"
        style={{
          justifyContent: 'flex-start',
          width: 'calc(100% - 70px)',
          margin: '0 auto',
        }}
      >
        <div className="overflowY">
          <div className="flex-col-center" style={{ justifyContent: 'flex-start' }}>
            {columnBuilder(allTemplates, 'left')}
          </div>
        </div>
        <div
          className="flex-row-spacebetween-nowrap"
          style={{
            width: '295px',
            height: '103px',
            paddingTop: '26px',
            paddingBottom: '32px',
            boxSizing: 'border-box',
          }}
        >
          <button
            onClick={() => {
              props.resetActiveTemplate();
              toggleViewAll(false);
            }}
            style={{
              width: '140px',
              height: '45px',
              borderRadius: '3px',
              border: 'solid 1px #979797',
              backgroundColor: '#fff',
              color: '#979797',
            }}
            className="fs-12 fw-600 pointer"
          >
            CANCEL
          </button>
          <button
            onClick={() => props.onClickSelectHandler()}
            style={{ width: '140px' }}
            className="fw-600 fs-12 save-button"
          >
            SELECT
          </button>
        </div>
      </div>
    </Drawer>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(ViewAllWhatsappTemplates);
