import LogRocket from 'logrocket';

export const logRocketIdentify = (associateId, firstName, lastName, email, merchantId) => {
  LogRocket.identify(`${associateId}`, {
    name: `${firstName} ${lastName}`,
    email: `${email}`,
    merchantId: `${merchantId}`,
  });
};

export const logRocketEvent = eventName => {
  LogRocket.track(`${eventName}`);
};