import { dispatcher } from '../core/actions';

const initialState = {
  autoMsgLayoutBreadCrumbs: [],
  autoMsgLayoutDescription: '',
  autoMsgGoBack: '',
  autoMsgType: '',
  autoMsgListItemId: '',
  // Auto Messages
  autoMessages: [],
  autoMessageId: '',
  autoMessageAddOrEdit: 'Add',
  autoMessageText: '',
  autoMessageCount: 1,
  autoMessageInterval: 'WEEK',
  autoMessageRepeat: 0,
  autoMessageActive: 1,
  // Auto Messages Repeat
  autoMessageRepeatCount: 1,
  autoMessageRepeatInterval: 'WEEK',

  // Auto Messages MessageTemplates
  autoMsgTemps: [],
  autoMsgTempId: '',
  autoMsgTempAddOrEdit: 'Add',
  autoMsgTempText: '',
};

const atuMessageDefaults = {
  autoMessageId: '',
  autoMessageAddOrEdit: 'Add',
  autoMessageText: '',
  autoMessageCount: 1,
  autoMessageInterval: 'WEEK',
  autoMessageRepeat: 0,
  autoMsgTempId: '',
  autoMsgTempAddOrEdit: 'Add',
  autoMsgTempText: '',
};

export default function reducer(state = initialState, action) {
  let state2 = state;
  switch (action.type) {
    case 'SET_AUTO_MSG_LAYOUT_DESC':
      return { ...state2, autoMsgLayoutDescription: action.payload };
    case 'SET_AUTO_MSG_BREAD_CRUMB':
      return { ...state2, autoMsgLayoutBreadCrumbs: action.payload };
    case 'SET_AUTO_MSG_GO_BACK':
      return { ...state2, autoMsgGoBack: action.payload };
    case 'SET_AUTO_MSG_LIST_TYPE':
      return { ...state2, autoMsgType: action.payload };
    case 'SET_AUTO_MSG_LIST_ID':
      return { ...state2, autoMsgListItemId: action.payload };
    //  REMINDERS
    case 'SET_AUTO_MESSAGES':
      return { ...state2, autoMessages: [...action.payload] };
    case 'SET_AUTO_MSG_ID':
      return { ...state2, autoMessageId: action.payload };
    case 'SET_AUTO_MSG_ADD_EDIT':
      return { ...state2, autoMessageAddOrEdit: action.payload };
    case 'SET_AUTO_MSG_TEXT':
      return { ...state2, autoMessageText: action.payload };
    case 'SET_AUTO_MSG_COUNT':
      return { ...state2, autoMessageCount: action.payload };
    case 'SET_AUTO_MSG_INTERVAL':
      return { ...state2, autoMessageInterval: action.payload };
    case 'SET_AUTO_MSG_REPEAT':
      return { ...state2, autoMessageRepeat: action.payload };
    case 'SET_AUTO_MSG_ACTIVE':
      return { ...state2, autoMessageActive: action.payload };
    // repeat interval
    case 'SET_AUTO_MSG_REPEAT_COUNT':
      return { ...state2, autoMessageRepeatCount: action.payload };
    case 'SET_AUTO_MSG_REPEAT_INTERVAL':
      return { ...state2, autoMessageRepeatInterval: action.payload };
    case 'RESET_AUTO_MESSAGES':
      return {
        ...state2,
        autoMessageId: '',
        autoMessageAddOrEdit: 'Add',
        autoMessageText: '',
        autoMessageRepeat: false,
        autoMessageRepeatCount: 1,
        autoMessageRepeatInterval: 'WEEK',
        autoMessageInterval: 'WEEK',
        autoMessageCount: 2,
        autoMsgTempId: '',
        autoMsgTempAddOrEdit: 'Add',
        autoMsgTempText: '',
      };
    // MESSAGE TEMPLATES
    case 'SET_AUTO_MSG_TEMPLATES':
      return { ...state2, autoMsgTemps: [...action.payload] };
    // case "SET_AUTO_MSG_ADD_EDIT":
    //   return { ...state2, autoMsgTempAddOrEdit: action.payload };
    case 'SET_AUTO_MSG_ID':
      return { ...state2, autoMsgTempId: action.payload };
    // case "SET_AUTO_MSG_TEXT":
    //   return { ...state2, autoMsgTempText: action.payload };
    default:
      break;
  }
  return state2;
}

export const resetAutoMessages = () => {
  dispatcher('RESET_AUTO_MESSAGES', null);
};
export const setMsgLayoutBreadCrumbs = val => {
  dispatcher('SET_AUTO_MSG_BREAD_CRUMB', val);
};
export const setMsgLayoutDesc = val => {
  dispatcher('SET_AUTO_MSG_LAYOUT_DESC', val);
};
export const setMsgGoBack = val => {
  dispatcher('SET_AUTO_MSG_GO_BACK', val);
};
export const setMsgListType = val => {
  dispatcher('SET_AUTO_MSG_LIST_TYPE', val);
};
export const setMsgListId = val => {
  dispatcher('SET_AUTO_MSG_LIST_ID', val);
};

// Auto Messages
export const setMessages = val => {
  dispatcher('SET_AUTO_MESSAGES', val);
};
export const setAutoMessageText = val => {
  dispatcher('SET_AUTO_MSG_TEXT', val);
};
export const setMessageCount = val => {
  dispatcher('SET_AUTO_MSG_COUNT', val);
};
export const setMessageInterval = val => {
  dispatcher('SET_AUTO_MSG_INTERVAL', val);
};
export const setMessageRepeat = val => {
  dispatcher('SET_AUTO_MSG_REPEAT', val);
};
export const setMessageActive = val => {
  dispatcher('SET_AUTO_MSG_ACTIVE', val);
};
// repeat scale and intervals auto message
export const setMessageRepeatCount = val => {
  dispatcher('SET_AUTO_MSG_REPEAT_COUNT', val);
};
export const setMessageRepeatInterval = val => {
  dispatcher('SET_AUTO_MSG_REPEAT_INTERVAL', val);
};
export const setMessageId = val => {
  dispatcher('SET_AUTO_MSG_ID', val);
};
export const setMessageAddEdit = val => {
  dispatcher('SET_AUTO_MSG_ADD_EDIT', val);
};

// Reminder Msg Template Actions
// export const setMsgTempMsgs = val => {
//   dispatcher('SET_AUTO_MSG_TEMPLATES', val);
// }
// export const setMsgTempAddEdit = val => {
//   dispatcher("SET_AUTO_MSG_ADD_EDIT", val);
// };
// export const setMsgTempMsgId = val => {
//   dispatcher("SET_AUTO_MSG_ID", val);
// };
// export const setMsgTempMsgText = val => {
//   dispatcher("SET_AUTO_MSG_TEXT", val);
// }
