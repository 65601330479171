import React from 'react';

const Footer = props => {
  const currentYear = new Date().getFullYear();

  return (
    <div
      className={props.footerClass ? `${props.footerClass}` : `pos-sticky`}
      style={{ marginTop: '60px', paddingLeft: '40px', paddingBottom: '24px'}}
    >
      <div className="flex-row-spacebetween-wrap maw-554">
        <p className="text-light-gray fs-12">© {currentYear} Clientbook Inc. All rights reserved.</p>
        <a
          className="text-light-gray fs-12 no-decoration"
          style={{ marginTop: '14px' }}
          href="https://clientbook.com/terms-and-conditions/"
        >
          {' '}
          Privacy{' '}
        </a>
        <a
          className="text-light-gray fs-12 no-decoration"
          style={{ marginTop: '14px' }}
          href="https://clientbook.com/terms-and-conditions/"
        >
          {' '}
          Terms of Service{' '}
        </a>
      </div>
    </div>
  );
};
export default Footer;
