import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ToolTipBottom = props => {
  const {
    isShown,
    idx,
    toolTipHeight,
    description,
    toolTipWidth,
    toolTipRight,
    toolTipBottom,
    arrowLeft,
    toolTipLeft,
    toolTipTop,
    arrowBottom,
  } = props;
  return (
    <div
      id={isShown === idx ? 'textBlock_active' : 'textBlock'}
      className="pos-abs border-r12"
      style={{
        backgroundColor: '#4e4e4e',
        width: toolTipWidth,
        right: toolTipRight,
        height: toolTipHeight,
        bottom: toolTipBottom,
        left: toolTipLeft,
        top: toolTipTop,
        zIndex: '9999999999999999999999999999999999999',
      }}
    >
      <p className="white" style={{ padding: '11px 12px', margin: '0px', fontSize: '12px', fontWeight: 400 }}>
        {description}
      </p>
      <FontAwesomeIcon
        icon="fa-solid fa-caret-up"
        className="pos-rel"
        size="xl"
        style={{ color: '#000000', left: arrowLeft, bottom: arrowBottom, opacity: '.7' }}
      />
    </div>
  );
};

export default ToolTipBottom;
