import React, { useEffect, useRef } from 'react';
import yn from 'yn';
import { connect } from 'react-redux';
import Button from '../../../Layout/Button';
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import TitleDescription from '../../../Layout/DescriptionBox';
import KioskSlider from '../../../Layout/KioskSlider';
import { bodyFormatterApp, messageDispatcher } from '../MessageSettings/largeMsgSettingFns';
import { saveMessageSettings, getMessageSettings } from '../../../../core/apiRequests';
import { setBulkLimit, setDailyGoal, setSalesOppName } from '../../../../reducers/messageSettings';
import { setLimitAssociateVisibilityEnabled, setHideProductCost } from '../../../../reducers/auth';

import '../MessageSettings/messageSettings.css';
import NumberInput from '../../../Layout/NumberInput';

const AppSettings = props => {
  const isMounted = useRef(null);

  const flags = props.merchantFlags;
  const LIMITED_ASSOCIATE_VISIBILITY = flags?.limited_associate_visibility?.enabled;


  useEffect(() => {
    isMounted.current = true;
    if (props.merchantId) {
      (async () => {
        const data = await getMessageSettings('merchant', props.merchantId);
        if (data) {
          messageDispatcher(data);
        }
      })();
    }
    return () => {
      isMounted.current = false;
    };
  }, [props.merchantId]);

  const saveSettings = () => {
    const { merchantId } = props;
    const bodyData = bodyFormatterApp(props); // exported b/c it's huge
    saveMessageSettings('merchant', merchantId, bodyData, 'appConfig');
  };

  const divStyle = {
    width: '866px',
  };

  return (
    <Layout
      title="General Settings"
      description="Configure your settings for the mobile app"
      classProps="standard-width-with-sidebar"
    >
      <SettingSegment
        title="APP ACCOUNT INFO"
        withDivider
        img="https://dashboard-v2-images.s3.amazonaws.com/key-solid.svg"
      >
        <TitleDescription
          title="Account ID"
          description="This is the Account ID you'll use when logging in to the mobile app"
          input={
            <input
              id="AccountIdInput"
              className="w-267 common_inputStyle inputHeight black rem-pad-width"
              placeholder="Account ID"
              value={props.licenseKey}
              readOnly
              disabled
              style={{ backgroundColor: '#f3f3f3', color: 'black' }}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Password"
          input={
            <input
              id="PasswordIdInput"
              className="w-267 common_inputStyle inputHeight black rem-pad-width"
              placeholder="Password"
              value={props.merchantPassword}
              readOnly
              disabled
              style={{ backgroundColor: '#f3f3f3', color: 'black' }}
            />
          }
          style={divStyle}
        />
      </SettingSegment>
      {/* Clienteling */}
      <SettingSegment
        title="CLIENTELING"
        withDivider
        img="https://dashboard-v2-images.s3.amazonaws.com/ic-handshake-gray.svg"
      >
        <TitleDescription
          title="Daily Goal"
          description="How many client contacts do you want your associates striving for each day? This will be the default for all stores unless your stores or associates have specific goals (found on the Edit Store or Associate Details pages)."
          input={
            <NumberInput
              onChange={setDailyGoal}
              min="0"
              step={1}
              precision={1}
              value={props.dailyContactGoal}
              id="DailyGoalInput"
              classProps="w-108"
              placeholder="4"
            />
          }
          style={divStyle}
        />
      </SettingSegment>
      <SettingSegment
        title="SALES PIPELINE"
        withDivider
        img="https://dashboard-v2-images.s3.amazonaws.com/ic-branch-gray.svg"
      >
        <TitleDescription
          title="Sales Pipeline Description"
          description="What name do you want to give the “Sales Opportunity” section on the client profile?"
          input={
            <input
              id="InAppDescriptionInput"
              className="w-267 common_inputStyle inputHeight rem-pad-width"
              placeholder="Special Order"
              value={props.salesOppName}
              onChange={event => setSalesOppName(event.target.value)}
            />
          }
          style={divStyle}
        />
      </SettingSegment>
      <SettingSegment
        title="MESSAGING"
        withDivider
        img="https://dashboard-v2-images.s3.amazonaws.com/ic-messaging-gray.svg"
      >
        <TitleDescription
          title="In-App Messaging Limit"
          description="What is the maximum number of clients your associates can bulk message at one time?"
          input={
            <NumberInput
              onChange={setBulkLimit}
              min="0"
              step={1}
              precision={1}
              value={props.appBulkLimit}
              id="MessagingLimitInput"
              classProps="w-108"
              placeholder="25"
            />
          }
          style={divStyle}
        />
      </SettingSegment>
      {yn(LIMITED_ASSOCIATE_VISIBILITY) && (
        <SettingSegment
          title="Limit Associate Visibility"
          withDivider
          img="https://dashboard-v2-images.s3.amazonaws.com/Lock.png"
          width="28px"
        >
          <TitleDescription
            descriptionBoxId="SendOptInAfterAddingClient"
            description="By enabling this setting, you will block associates from being able to access client profiles or conversations of clients they are not assigned to."
            input={
              <KioskSlider
                value={yn(props.merchantConfig.LIMIT_ASSOCIATE_VISIBILITY_ENABLED)}
                onChange={val => setLimitAssociateVisibilityEnabled(val)}
              />
            }
            style={divStyle}
          />
        </SettingSegment>
      )}
      <SettingSegment
        title="Hide Product Cost"
        withDivider
        img="https://dashboard-v2-images.s3.amazonaws.com/dollar-sign.svg"
      >
        <TitleDescription
          descriptionBoxId="hideProductCost"
          description="When this setting is turned off, admins and managers will be able to see the product cost when selecting a product. When turned on, cost will be hidden on the product page."
          input={
            <KioskSlider
              value={yn(props.merchantConfig.HIDE_PRODUCT_COST)}
              onChange={val => setHideProductCost(val)}
            />
          }
          style={divStyle}
        />
      </SettingSegment>

      <div className="settingSave" style={{ width: '863px' }}>
        <Button onClick={saveSettings} id="AppSettingsSaveButton" className="darkBlueButton border-none">
          Save
        </Button>
      </div>
      <SecondaryPanel title={['SETTINGS', 'HELP', 'PROFILE']} current="General Settings" />
    </Layout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  ...state.messageSettings,
});
export default connect(mapStateToProps, {})(AppSettings);

const SettingSegment = props => {
  return (
    <div>
      <div className="msgSetSegmentTitle">
        <img alt="#" src={props.img} width={props.width} />
        <span>{props.title}</span>
      </div>
      <div className="">{props.children}</div>
      {props.withDivider ? <div className="divider" /> : ''}
    </div>
  );
};
