import React, { useState } from 'react';
import './popconfirm.css';

const PopConfirm = ({showCancelButton = true, ...props}) => {
  const [clicked, setClicked] = useState(false);

  const onConfirmHandler = () => {
    if (!props.noButtonDisable) {
      setClicked(true);
    }
    props.onConfirm();
  };

  return (
    <div className={`pop-confirm-background ${props.className || ''}`} onClick={props.popConfirmBackgroundClick}>
      <div
        className="pop-confirm-container hide-scrollbar max-w-404 p-top-36 p-left-44 p-right-44 rem-pad-width p-btm-6"
        style={props.popStyle} // like overflow: 'visible'
      >
        <p
          className={`fw-500 m-right-15 ${props.customHeaderContent ? props.customHeaderContent : 'fs-18'}`}
          style={{ lineHeight: 1.4, marginBottom: 20, marginTop: 0 }}
        >
          {props.confirm}
        </p>
        {props.description && (
          <span
            className={`fs-13 maw-100-P ${props.customContent ? '' : 'd-inline-block'}`}
            style={props.description ? { marginTop: '15px', lineHeight: 1.15, ...props.descriptionStyle } : {}}
          >
            {props.description}
          </span>
        )}
        {props.children}
        {!props.customButtons ? (
          <div className="flex-col-center  m-top-15 m-btm-28" style={{ marginLeft: '5px' }}>
            <button
              id="ConfirmButton"
              type="button"
              className="save-button-v2 m-top-15"
              style={props.confirmButtonStyle}
              disabled={clicked || props.disabled}
              onClick={onConfirmHandler}
            >
              {props.confirmButtonText || 'Confirm'}
            </button>
            {showCancelButton && (
              <button className="cancel-button-v2 m-top-15" type="button" disabled={clicked} onClick={props.onCancel}>
                {props.cancelButtonText || 'Cancel'}
              </button>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default PopConfirm;
