import React, { useEffect } from 'react';

/**
 * Bluesnap js reads values from inputs.
 * We pass in our callback (addBankAccount) during init,
 * and the callback doesn't have values yet,
 * so the callback must get those values from the form in the document.
 * However Bluesnap empties the account number input, on submit.
 * This means we cannot use the same fields that Bluesnap uses,
 * so instead we duplicate the inputs.
 * This is less than ideal, but it works.
 */
const BluesnapFields = ({ data }) => {
  useEffect(() => {
    if (data.isSubmitting) {
      window.bluesnap.partnerSecuredCaptureSubmitData();
    }
  }, [data.isSubmitting]);

  return (
    <div id="bluesnap-fields">
        {Object.entries(data).map(([id, { value }]) =>
          value ? <input data-bluesnap={id} value={value} type="hidden" key={id} /> : null,
        )}

      <input data-bluesnap="businessEmail" value="support@clientbook.com" type="hidden" />

      {/* Static */}
      <input data-bluesnap="bankPayoutCurrency" value="USD" type="hidden" />
      <input data-bluesnap="bankMinimalPayoutAmount" value="0" type="hidden" />
      <input data-bluesnap="bankRefundReserve" value="35" type="hidden" />
      <input data-bluesnap="businessCountry" value="US" type="hidden" />
      <input data-bluesnap="businessSalesVolume" value="850000" type="hidden" />
      <input data-bluesnap="businessCategory" value="Physical Goods" type="hidden" />
      <input data-bluesnap="paymentReference" value="Clientbook Payments" type="hidden" />

      {/* Disabled by BlueSnap */}
      <input data-bluesnap="merchantIp" type="hidden" value="127.0.0.1" />
      <input data-bluesnap="defaultIPN" type="hidden" />

      {/* Values Not Required */}
      <input data-bluesnap="bankName" type="hidden" />
      <input data-bluesnap="bankBranchCode" type="hidden" />
      <input data-bluesnap="bankCode" type="hidden" />
      <input data-bluesnap="bankBsb" type="hidden" />
      <input data-bluesnap="bankTransitNumber" type="hidden" />
      <input data-bluesnap="bankInstitutionNumber" type="hidden" />
      <input data-bluesnap="bankSortOrder" type="hidden" />
      <input data-bluesnap="bankBic" type="hidden" />
      <input data-bluesnap="bankIban" type="hidden" />
      <input data-bluesnap="bankSwiftOrBICCode" type="hidden" />
      <input data-bluesnap="bankAccountNumberOrIBAN" type="hidden" />
      <input data-bluesnap="bankCity" type="hidden" />
      <input data-bluesnap="bankProvince" type="hidden" />
      <input data-bluesnap="bankCountry" type="hidden" />
      <input data-bluesnap="businessAccountUsername" type="hidden" />
      <input data-bluesnap="businessRegistrationNumber" type="hidden" />
      <input data-bluesnap="businessTradingName" type="hidden" />
      <input data-bluesnap="businessAcnOrAbn" type="hidden" />
      <input data-bluesnap="businessSuburb" placeholder="Suburb" type="hidden" />
    </div>
  );
};

export default BluesnapFields;
