import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import Layout from '../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';
import PopConfirmLarge from '../../../../Layout/PopConfirm/PopConfirmLarge';
import PopConfirm from '../../../../Layout/PopConfirm';
import CheckboxDesc from '../../../../Layout/CheckboxDesc';
import { setTriggerActionDesc, setTriggerActionObj } from '../../../../../reducers/salesTriggers';
import ReminderMessageTriggerTemplate from '../../../../Layout/AutoReminderSalesTrigger/ReminderMessageTriggerTemplate';
import AutoReminderTrigger from '../../../../Layout/AutoReminderSalesTrigger';
import { getRequest, postRequest } from '../../../../../core/apiRequests';
import ViewAllTemplates from '../../../../Layout/AutoWhatsAppTemplate/ViewAllTemplates';
import { notificationError, notificationSuccess } from '../../../../../core/actions';
import { setLoadingStatus } from '../../../../../reducers/notification';
import {
  setRemTempMsgText,
  setRemTempAddEdit,
  setRemTempMsgId,
  setRemTempMsgImg,
  setReminderAddEdit,
} from '../../../../../reducers/autoRemMsgs';

const EditActionAutoReminder = props => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [showWATemplates, setShowWATemplates] = useState(false);
  const [selected, setSelected] = useState('');
  const history = useHistory();
  const [pendingAuto, setPendingAuto] = useState({ futureOnly: false, automationType: '' });
  const [showPendingAutoPop, setShowPendingAutoPop] = useState(false);

  useEffect(() => {
    setLoadingStatus(true);
    if (props.auth.merchantId) {
      setTimeout(() => {
        getActionConfigData();
        setLoadingStatus(false);
      }, 2000);
    }
  }, [props.auth.merchantId, props.salesTriggers.update]);

  const getActionConfigData = async () => {
    const reqObj = {
      params: ['salesTrigger', props.auth.merchantId, 'getSingleReminders'],
      query: {
        actionId: props.match.params.actionId,
      },
    };
    const dataSet = await getRequest(reqObj);
    if (dataSet && dataSet.length > 0) {
      const dataObj = dataSet[0];
      const { title, description, action_id, msgs } = dataObj;
      const stateObj = {
        actionTitle: title,
        actionDescription: description,
        actionId: action_id,
        actionMsgTemplates: [...msgs],
      };
      setTriggerActionObj(stateObj);
    }
  };

  const rowClickHandler = data => {
    if (data.msg_id) {
      setRemTempMsgText(data.msg);
      setRemTempMsgId(data.msg_id);
      setRemTempMsgImg(data.image_url);
      setRemTempAddEdit('Edit');
      history.push(
        `/Configuration/sales-triggers/edit-trigger-auto-reminder/${props.match.params.actionId}/edit-message-template/${data.msg_id}`,
      );
    } else if (data.wat_id) {
      history.push(
        `/Configuration/sales-triggers/edit-trigger-auto-reminder/${props.match.params.actionId}/edit-whatsapp-template/${data.wat_id}`,
      );
    }
  };

  const reminderAddTemplate = () => {
    setShowPopConfirm(true);
    setRemTempMsgText('');
    setRemTempMsgId('');
    setRemTempMsgImg('');
    setReminderAddEdit('Add');
  };

  const cancelRoute = () => {
    setShowPopConfirm(false);
  };
  const saveAutoReminderMsgDetails = async () => {
    const reqObj = {
      params: ['salesTrigger', props.auth.merchantId, props.match.params.actionId, 'salesActionConfig'],
      data: {
        id: props.salesTriggers.actionMsgId,
        msg: props.salesTriggers.actionMsgText,
      },
    };
    await postRequest(reqObj);
    setShowPopConfirm(false);
    getActionConfigData();
  };

  const saveDescription = () => {
    (async () => {
      const reqObj = {
        params: ['salesTrigger', props.auth.merchantId, props.match.params.actionId, 'saveDescription'],
        data: {
          description: props.salesTriggers.actionDescription,
          futureOnly: pendingAuto.futureOnly,
          type: 'AUTO_REMINDER',
        },
      };
      await postRequest(reqObj);
      notificationSuccess('Saved Auto Reminder');
      history.push('/Configuration/sales-triggers');
    })();
  };

  const whatsAppSaveHandler = () => {
    (async () => {
      setShowWATemplates(false);
      const reqObj = {
        params: ['salesTrigger', props.auth.merchantId, props.match.params.actionId, 'saveWhatsappReminder'],
        query: {
          templateId: selected,
        },
      };
      const saveIt = await postRequest(reqObj);
      if (saveIt) {
        getActionConfigData();
        setSelected('');
        notificationSuccess('Saved Template to Reminder');
      } else {
        setSelected('');
        notificationError('Failed to Save Msg to Reminder');
      }
    })();
  };

  const AutoReminderNameChange = e => {
    setTriggerActionDesc(e);
    if (window.location.href.includes('edit-trigger-auto-reminder')) {
      setPendingAuto({ ...pendingAuto, automationType: 'DESCRIPTION' });
    } else setPendingAuto({ ...pendingAuto, automationType: '' });
  };

  const AutomationSubmit = () => {
    saveDescription();
    setShowPendingAutoPop(false);
  };

  const saveCheck = () => {
    if (pendingAuto.automationType == 'DESCRIPTION') {
      setShowPendingAutoPop(true);
    } else {
      saveDescription();
    }
  };

  return (
    <Layout
      title={`Edit "${props.salesTriggers.actionTitle}"`}
      description="Configure this Auto Reminder"
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Sales Triggers', to: '/Configuration/sales-triggers' },
        { title: `Edit "${props.salesTriggers.actionTitle}"` },
      ]}
      classProps="standard-width-with-sidebar"
    >
      <div className="custom-action-wrapper">
        <div className="flex-col-left m-btm-56">
          <input
            className="m-btm-15 m-top-15 fs-13 outline-none common_inputStyle  max-w-600 w-100-P  left-0 input-height "
            value={props.salesTriggers.actionDescription}
            onChange={e => AutoReminderNameChange(e.target.value)}
            placeholder="Description"
          />
        </div>
        <AutoReminderTrigger
          setShowWATemplates={setShowWATemplates}
          getActionConfigData={getActionConfigData}
          onclickTrig={reminderAddTemplate}
          onRowClick={rowClickHandler}
          match={props.match}
        />
      </div>
      <div className="max-w-600 w-100-P m-top-50 flex-col-center">
        <button type="button" onClick={() => saveCheck()} className="save-button-v2 m-btm-8">
          Save
        </button>
        <button
          type="button"
          onClick={() => history.push('/Configuration/sales-triggers')}
          className="cancel-button-v2 m-btm-8"
        >
          Cancel
        </button>
      </div>
      {showPopConfirm ? (
        <PopConfirmLarge
          confirm={props.remMsg.msgTempAddOrEdit === 'Add' ? 'Add Message Template' : 'Edit Message Template'}
          // input= {e => setPin(e.target.value)}
          description={
            <div style={{ width: '500px' }}>
              <ReminderMessageTriggerTemplate
                cancelRoute={cancelRoute}
                onclickSave={saveAutoReminderMsgDetails}
                type="sales-triggers"
                match={props.match}
              />
            </div>
          }
        />
      ) : null}
      {showWATemplates ? (
        <ViewAllTemplates
          toggleViewAll={setShowWATemplates}
          setSelected={setSelected}
          saveHandler={whatsAppSaveHandler}
          selected={selected}
        />
      ) : null}
      {showPendingAutoPop ? (
        <PopConfirm
          confirm="These changes will be applied by default to both scheduled and future automation"
          customButtons
          description={
            <div className="flex-col-left w-100-P align-center">
              <CheckboxDesc
                checked={pendingAuto.futureOnly}
                onclick={() => setPendingAuto({ ...pendingAuto, futureOnly: !pendingAuto.futureOnly })}
                text="Only apply to future automation"
                checkButtonProps="p-top-4 m-left-8"
                classProps="m-top-10"
                checkBoxId="SalesTriggerReminderAutomationPopupCheckbox"
              />
              <div
                className="w-100-P m-top-15 m-btm-28 flex-row-nospacebetween-nowrap"
                style={{ justifyContent: 'center' }}
              >
                <button type="button" className="save-button m-right-15 m-top-15" onClick={AutomationSubmit}>
                  Submit
                </button>
              </div>
            </div>
          }
        />
      ) : null}
      <SecondaryPanel title={['Automation']} current="Sales Triggers" />
    </Layout>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
  salesTriggers: state.salesTriggers,
  remMsg: state.autoRemMsgs,
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(EditActionAutoReminder);
