import React from 'react';
import CheckboxDesc from '../../../Layout/CheckboxDesc';
import formatPhoneNumber from '../../../../utils/formatPhoneNumber';

const ClientListItem = ({
  msg,
  idx,
  listAdder,
  selected_client_list,
  getClientData,
  checkClintAllValue,
}) => {
  const stopEventBubbling = (obj, event) => {
    const target = event.target.id;
    event.preventDefault();
    if (
      target == 'clientListItemCheckBoxDiv' ||
      target == 'clientListItemCheckBox' ||
      target == 'clientCheckIcon' ||
      target == 'clientCheckButton'
    ) {
      event.stopPropagation();
    } else {
      getClientData(obj.id);
    }
  };

  const checkedClient = e => {
    if (msg.disabled) return;
    listAdder(msg.id);
    checkClintAllValue(e);
  };

  return (
    <li
      id={idx}
      key={`${msg.id}client_id${Math.floor(Math.random() * 1000)}`}
      style={msg?.disabled ? { opacity: '0.5', pointerEvents: 'none' } : { borderBottom: '0.5px solid #EBEBEB' }}
      className="default-cursor clientAvatarLI"
      onClick={e => stopEventBubbling(msg, e)}
      onKeyDown={e => stopEventBubbling(msg, e)}
    >
      <div className="flex-col-center" id="clientListItemCheckBoxDiv">
        <CheckboxDesc
          checked={selected_client_list.includes(msg.id)}
          onclick={checkedClient}
          classProps="fs-13 transparent-important"
          checkButtonProps="m-left-21 m-top-4 transparent-important"
          checkBoxId="clientListItemCheckBox"
          checkBoxButtonId="clientCheckButton"
          checkBoxButtonIconId="clientCheckIcon"
        />
      </div>
      <UserIcon msg={msg} idx={idx} />
      <SmallMessage msg={msg}  />
    </li>
    // END
  );
};
export default ClientListItem;

const SmallMessage = ({ msg }) => {
  const msgTrim = msg => {
    if (msg && msg.length > 30) {
      return `${msg.substr(0, 30)}...`;
    }
    return msg;
  };
  return (
    <div
      key={`smallmsg_${msg.chat_id}`}
      className="no-text-wrap flex-col-left align-left"
      style={{ width: '140px', position: 'relative', margin: '4px 10px 4px 12px' }}
    >
      <p className="margin-0 fs-12 fw-400">{msgTrim(msg.name)}</p>
      <p className="margin-0 fs-10 fw-400 gray">{msg.pos_ref_num ? `POS: ${msg.pos_ref_num}` : 'No POS ID'}</p>
      <p className="margin-0 fs-10 fw-400 gray">
        {msg?.contactMethod === 'EMAIL' ? msg?.email || 'No Primary Contact Info' : formatPhoneNumber(msg?.mobile, 'b') || 'No Primary Contact Info'}
      </p>
    </div>
  );
};

const UserIcon = ({ msg, idx }) => {
  const initialFunc = name => {
    const initials = `${name}`
      .split(' ')
      .map(e => e.charAt(0))
      .slice(0, 2)
      .join('');
    return initials;
  };

  return (
    <div
      style={{ width: 'auto', alignItems: 'center', marginLeft: '8px' }}
      key={`${idx}_user_icon_${msg.chat_id}`}
      className="flex-row-nospacebetween-nowrap"
    >
      <div
        style={{ backgroundColor: '#BDBDBD', color: '#FFF' }}
        className="h-33 w-33  circle rem-pad-width clientAvatarInitials photoFit p-top-4 no-text-wrap m-top-8"
      >
        <span
          style={{
            fontSize: '12px',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5px',
          }}
          className="no-text-wrap"
        >
          {initialFunc(msg.name)}
        </span>
      </div>
    </div>
  );
};
