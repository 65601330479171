import moment from 'moment';

export default ({
  eventType, // activity_viewed, activity_completed, activity_skipped
  merchantId,
  packageCode,
  multiStore,
  associateId,
  associateRole,
  clientId,
  storeId,
  activityId,
  activityType,
  activityCreatedDateTime,
  assignmentType,
  msgRegen,
  msgEdit,
  resultCode,
}) => {
  const eventAttributes = new EventAttributes(
    merchantId,
    packageCode,
    multiStore,
    associateId,
    associateRole,
    clientId,
    storeId,
    activityId,
    activityType,
    activityCreatedDateTime,
    assignmentType,
    eventType,
  );

  if (eventType === 'activity_completed') {
    eventAttributes.setActivityCompleted(msgRegen, msgEdit);
  }
  if (eventType === 'activity_skipped') {
    eventAttributes.setActivitySkipped(resultCode);
  }
  return eventAttributes;
};

class EventAttributes {
  constructor(
    merchantId,
    packageCode,
    multiStore,
    associateId,
    associateRole,
    clientId,
    storeId,
    activityId,
    activityType,
    activityCreatedDateTime,
    assignmentType,
    eventType,
  ) {
    this.merchant_id = merchantId;
    this.package_code = packageCode;
    this.multi_store = multiStore;
    this.store_id = storeId;
    this.associate_id = associateId;
    this.associate_role = associateRole;
    this.client_id = clientId;
    this.activity_id = activityId;
    this.activity_type = activityType;
    this.activity_created_date_time = moment(activityCreatedDateTime, 'YYYYMMDD').format('YYYY-MM-DD 00:00:00');
    this.assignment_type = assignmentType;
    this.platform = 'web';
    this.event_type = eventType;
    this.event_date_time = moment(moment(new Date())).format('YYYY-MM-DD HH:mm:ss');

    this.msg_regen = false;
    this.msg_edit = false;
    this.result_code = null;
  }

  setActivityCompleted(msgRegen, msgEdit) {
    this.msg_regen = msgRegen;
    this.msg_edit = msgEdit;
  }

  setActivitySkipped(resultCode) {
    this.result_code = resultCode.toLowerCase();
  }
}
