import React from 'react';

import Field from './Field';

const FormField = ({ title, details, address, defaultValue, maxLength, minLength, ...props }) => {
  return (
    <div className="flex-col-left">
      <span className="fs-10 fw-400">{title}</span>
      <Field defaultValue={defaultValue} {...props}>
        <input
          className="fs-13 fw-500 border-none outline-none"
          maxLength={maxLength}
          minLength={minLength}
          value={defaultValue}
          id={props.id}
          readOnly
        />
      </Field>
      {address ? (
        <span className="fs-13 fw-500" style={{ marginTop: '-20px' }}>
          {address}
        </span>
      ) : null}
    </div>
  );
};

FormField.propTypes = {};

FormField.defaultProps = {};

export default FormField;
