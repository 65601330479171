import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function GoogleReviewRating({rating}) {
  return (
    <div className='add-edit-wrap p-btm-4'>
      {[...Array(5)].map((e, i) => (
        <FontAwesomeIcon key={i} icon="fa-solid fa-star" style={{color: (i < rating) ? "#EFA340" : "#BDBDBD",}} />
      ))}

    </div>
  )
}

export default GoogleReviewRating
