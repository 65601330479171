import moment from 'moment';
import React from 'react';

const MessageSentDate = incDate => {
  if (!incDate) return null;

  const date = moment(incDate, 'YYYYMMDD').format('MMMM DD, YYYY');
  return (
    <cite key={`date${date}`}>
      <div
        key={`date${date}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '12px',
          color: '#818181',
          paddingBlock: '10px',
          fontStyle: 'normal'
        }}
      >
        <span>{date}</span>
      </div>
    </cite>
  );
};

export default MessageSentDate;
