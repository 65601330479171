import { dispatcher } from '../core/actions';

const initialState = {
  addOrEdit: 'Add',
  preferenceId: '',
  preferenceName: '',
};
export default function reducer(state = initialState, action) {
  let state2 = state;
  switch (action.type) {
    case 'SET_CLI_PREF_ADD_EDIT':
      return { ...state2, addOrEdit: action.payload };
    case 'SET_CLI_PREF_ID':
      return { ...state2, preferenceId: action.payload };
    case 'SET_CLI_PREF_NAME':
      return { ...state2, preferenceName: action.payload };
    default:
      break;
  }
  return state2;
}

export const setCliPrefAddEdit = val => {
  dispatcher('SET_CLI_PREF_ADD_EDIT', val);
};

export const setCliPrefId = val => {
  dispatcher('SET_CLI_PREF_ID', val);
};

export const setCliPrefName = val => {
  dispatcher('SET_CLI_PREF_NAME', val);
};
