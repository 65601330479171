import React from 'react';
import StandardLayout from '../../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../../Layout/SecondaryPanel';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AutoWhatsAppSettings from '../../../../../Layout/AutoWhatsAppTemplate/AutoWhatsAppSettings';

const LifeEventAutoWhatsapp = props => {
  const history = useHistory();
  // const listItemId = props.match.params.listItemId;

  const setRedirectBack = () => {
    history.push(`/Configuration/life-events/edit-life-events/${props.match.params.listItemId}`);
  };

  return (
    <StandardLayout
      title={`${props.autoRemMsgs.reminderAddOrEdit} WhatsApp Auto Template`}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Life Events', to: '/Configuration/life-events' },
        {
          title: 'Edit Life Event',
          to: `/Configuration/life-events/edit-life-events/${props.match.params.listItemId}`,
        },
        { title: `${props.autoRemMsgs.reminderAddOrEdit} WhatsApp Auto Template` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <AutoWhatsAppSettings
        setRedirectBack={setRedirectBack}
        cancelRoute={`/Configuration/life-events/edit-life-events/${props.match.params.listItemId}`}
        descriptionText={'How soon after this life event would you like to receive this reminder?'}
        popConfirmDesc={'This will remove the auto message from this Life Event'}
        editRoute={`/Configuration/life-events/edit-life-events/${props.match.params.listItemId}`}
        type={'lifeEvent'}
        match={props.match}
      />
      <SecondaryPanel title={['Automation']} current="Life Events" />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  autoRemMsgs: state.autoRemMsgs,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(LifeEventAutoWhatsapp);
