import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import CollectionCard from '../CollectionCard';
import { API_URL } from '../../../../../../../constants';
import PaginationV2 from '../../../../../../CustomizedAntDesign/PaginationV2';
import './styles.scss';

const CollectionsList = ({
  setSelectedCollection,
  loading,
  collections,
  showPagination,
  page,
  pageSize,
  setPage,
  setPageSize,
  total
  }) => {

  return (
    <div className="clients-v2-collections--list">
      <div className="clients-v2-collections--list-items">
        {loading && <div className="clients-v2-collections--list-subtitle">Loading...</div>}

        {!loading && collections.length === 0 && (
          <div className="clients-v2-collections--list-subtitle">No Collections</div>
        )}

        {!loading &&
          collections.length > 0 &&
          collections.map(item => (
            <CollectionCard key={item.collection_id} collection={item} onClick={() => setSelectedCollection(item)} />
          ))}
      </div>

      {showPagination && (
        <PaginationV2
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          total={total}
          showSizeChanger={false}
          className="clients-v2-collections--list-pagination"
        />
      )}
    </div>
  );
};

export default CollectionsList;
