import { Row, Col } from 'antd';
import React from 'react';
import IconButton from '../IconButton';

const FullScreenModalTitleWithBack = ({ onBack, children }) => (
  <Row gutter={8} align="middle">
    <Col>
      <IconButton onClick={onBack} icon={['fas', 'arrow-left']} />
    </Col>
    <Col>
      <h1
        style={{
          marginBottom: 0,
          fontFamily: 'Poppins',
          fontWeight: 700,
          fontSize: '26px',
          lineHeight: '39px',
        }}
      >
        {children}
      </h1>
    </Col>
  </Row>
);

export default FullScreenModalTitleWithBack;
