import React, { useMemo } from 'react';
import TimelineItem from '../Timeline';
import moment from 'moment';


const SalesTimelineItem = ({client_name, activity_date, sales_transaction_amount, description}) => {
  const title = useMemo(() => {
    return (
      <>
        <span className="fw-600">{client_name} </span>made a <span className="fw-600">{`$${parseFloat(sales_transaction_amount).toFixed(2)}`} </span>
        on
        <span className="fw-600"> {moment(activity_date).format('MMM DD, YYYY')}</span>
      </>
    )
  }, [client_name, activity_date, sales_transaction_amount, description])

  return (
    <TimelineItem
      title={title}
      description={description}
    />
  )
}

export default SalesTimelineItem;
