import Axios from 'axios';

// reducers & actions
import { setLoadingStatus, setSavingStatus } from '../../../reducers/notification';
import {
  setBankAccountsList,
  setBankAccountsActivityLog,
  setBankAccountFormData,
  setBankAccountFormStores,
} from '../../../reducers/bankAccounts';
import { notificationSuccess, notificationError } from '../../../core/actions';

// local
import { getSearchParams } from '../Reports/BankTransaction/utils';
import { PAYMENTS_API_URL } from '../../../constants';

export const getBluesnapToken = merchantId =>
  new Promise((resolve, reject) => {
    if (!merchantId) {
      return null;
    }

    return Axios.get(`${PAYMENTS_API_URL}/v1/merchant/${merchantId}/getPaymentFieldsToken`)
      .then(({ data }) => {
        return resolve(data.pfToken);
      })
      .catch(err => {
        console.log({ err });
        notificationError('Please try again');
        return reject(err);
      });
  });

export const getBankAccountsList = merchantId =>
  new Promise((resolve, reject) => {
    setLoadingStatus(true);

    return Axios.get(`${PAYMENTS_API_URL}/v1/merchant/${merchantId}/list`)
      .then(({ data }) => {
        const filterDeletedAccounts = data.filter(({ status }) => status !== 'DELETED'); // Bank Accounts page doesnt support DELETED - breaks page
        setBankAccountsList(filterDeletedAccounts);
        return filterDeletedAccounts;
      })
      .catch(err => {
        console.log({ err });
        notificationError('Bank accounts did not load');
        reject();
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  });

export const getBankAccountFormData = merchantId =>
  new Promise((resolve, reject) => {
    setLoadingStatus(true);

    return Axios.get(`${PAYMENTS_API_URL}/v1/merchant/${merchantId}/getPaymentFields`)
      .then(({ data }) => {
        setBankAccountFormData(data.businessAndReps[0]);
        setBankAccountFormStores(data.unassignedStores);
        resolve();
      })
      .catch(err => {
        console.log({ err });
        notificationError('Bank account form data did not load');
        reject();
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  });
export const getBankAccountStores = async (merchantId, accountId) => {
  setLoadingStatus(true);

  try {
    const { data } = await Axios.get(`${PAYMENTS_API_URL}/v1/merchant/${merchantId}/stores/${accountId}`);

    return data;
  } catch (error) {
    notificationError('Bank account stores did not load');
  } finally {
    setLoadingStatus(false);
  }
};
export const getStoresBankAccount = async (merchantId, accountId) => {
  setLoadingStatus(true);

  try {
    const { data } = await Axios.get(`${PAYMENTS_API_URL}/v1/merchant/${merchantId}/stores/${accountId}`);

    return data;
  } catch (error) {
    notificationError('Bank account stores did not load');
  } finally {
    setLoadingStatus(false);
  }
};

export const updateBankAccountStores = async (merchantId, accountId, storeIDs) => {
  setLoadingStatus(true);

  try {
    const { data } = await Axios.put(`${PAYMENTS_API_URL}/v1/merchant/${merchantId}/stores/${accountId}`, { storeIDs });

    return data;
  } catch (error) {
    notificationError('Bank account stores did not load');
  } finally {
    setLoadingStatus(false);
  }
};

export const getBankAuditLog = (merchantId, { ascending }) =>
  new Promise((resolve, reject) => {
    setLoadingStatus(true);
    const searchParams = getSearchParams({
      ascending,
    });
    return Axios.get(`${PAYMENTS_API_URL}/v1/reports/bankAuditLog/${merchantId}${searchParams}`)
      .then(({ data }) => {
        setBankAccountsActivityLog(data);
        resolve();
      })
      .catch(err => {
        console.log({ err });
        notificationError('Bank audit log did not load');
        reject();
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  });

export const deleteBankAccount = async (merchantId, id) => {
  setSavingStatus(true);

  try {
    const data = await Axios.put(`${PAYMENTS_API_URL}/v1/merchant/${merchantId}/delete/${id}`);

    notificationSuccess('Your account was archived');

    return data;
  } catch (error) {
    notificationError('Account was not deleted');

    return error;
  } finally {
    setSavingStatus(false);
  }
};

export const setPrimaryAccountWithoutEffects = async (merchantId, id) => {
  setSavingStatus(true);

  try {
    const data = await Axios.put(`${PAYMENTS_API_URL}/v1/merchant/${merchantId}/setPrimary/${id}`);

    return data;
  } catch (error) {
    notificationError('Primary account has not been updated');
  } finally {
    setSavingStatus(false);
  }
};

export const setPrimaryAccount = (merchantId, id, update = true) =>
  new Promise((resolve, reject) => {
    setSavingStatus(true);

    return Axios.put(`${PAYMENTS_API_URL}/v1/merchant/${merchantId}/setPrimary/${id}`)
      .then(() => {
        setSavingStatus(false);

        if (update) {
          return getBankAccountsList(merchantId).then(() => {
            notificationSuccess('Your primary account has been updated');
            resolve();
          });
        }
      })
      .catch(err => {
        console.log({ err });
        setSavingStatus(false);
        notificationError('Primary account has not been updated');
        reject();
      });
  });

export const getPaymentSettings = merchantId =>
  new Promise((resolve, reject) => {
    setLoadingStatus(true);

    if (!merchantId) {
      return null;
    }

    return Axios.get(`${PAYMENTS_API_URL}/v1/paymentSettings/${merchantId}`)
      .then(({ data }) => {
        const settings = {
          displayName: data.paymentSettings.businessName,
          requestMessage: data.paymentSettings.paymentRequestDefaultMessage,
          reminderMessage: data.paymentSettings.paymentReminderDefaultMessage,
          confirmationMessage: data.paymentSettings.paymentConfirmationDefaultMessage,
        };
        setLoadingStatus(false);
        resolve(settings);
      })
      .catch(err => {
        console.log({ err });
        setLoadingStatus(false);
        notificationError('Your settings did not load');
        reject();
      });
  });

export const savePaymentSettings = (
  merchantId,
  { displayName, requestMessage, reminderMessage, confirmationMessage },
) =>
  new Promise((resolve, reject) => {
    setSavingStatus(true);

    return Axios.post(`${PAYMENTS_API_URL}/v1/paymentSettings/${merchantId}`, {
      businessName: displayName,
      paymentRequestDefaultMessage: requestMessage,
      paymentReminderDefaultMessage: reminderMessage,
      paymentConfirmationDefaultMessage: confirmationMessage,
    })
      .then(() => {
        setSavingStatus(false);
        notificationSuccess('Your settings have saved');
        getPaymentSettings(merchantId);
      })
      .catch(err => {
        console.log({ err });
        setSavingStatus(false);
        notificationError('Your settings did not save');
      });
  });

export const addBankAccount = (merchantId, data) =>
  new Promise((resolve, reject) => {
    return Axios.post(`${PAYMENTS_API_URL}/v1/merchant/${merchantId}`, JSON.stringify(data), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(({ data }) => {
        if(data === "ok") {
          notificationSuccess('Bank account was added');
        }
        if(data === "still-pending") {
          notificationSuccess('We are still creating your account. Please check back in a few minutes.');
        }
        return resolve(data);
      })
      .catch(({ response }) => {
        const errors = response?.data?.errorData || [];
        notificationError('Bank account was not added');
        reject(errors);
      });
  });
