export const STORE_INBOX_PATH = '/messaging/store-inbox';

export const CLIENT_INBOX_PATH = '/Messaging/inbox';

export const MY_INBOX_PATH = '/Messaging/inbox';

export const TODAY_PATH = '/';

export const CLIENT__PATH = '/Clients/client-details';

export const LOGIN_PATH = '/login';

export const MY_CLIENT_INBOX_PATH = '/Messaging/inbox';

export const PAYMENT_DETAILS_PATH = 'Payments/details/';
