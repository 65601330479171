import React from 'react';

const AssociateSpinner = ({ visible }) =>
  visible ? (
    <div style={{ position: 'relative' }}>
      <div className="dash-ring-small associateOutreachChart">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : null;
export default AssociateSpinner;
