
import React from 'react';
import yn from 'yn';

const AutomationTableHeaders = ({ classname, type, flags }) => {
  const relationshipAutomation = flags?.relationship_automation?.enabled;

  const tableHeaders = ['Description', 'Media', 'Type', 'Schedule'];
  if (type !== 'lifeEvent') {
    tableHeaders.push('Repeats');
  } else if (yn(relationshipAutomation)) {
    tableHeaders.splice(1, 0, 'Relationship Type');
  }
  tableHeaders.push('Status');

  return (
    <thead className={classname ? classname : 'table-head'}>
      <tr>
        {tableHeaders.map((colName, i) => {
          return (
            <th
              className={`darkest-gray fs-12 fw-600 border-btm-l-gray ${
                colName === 'Repeats' || colName === 'Status' ? 'align-center' : ''
              } ${colName === 'Description' ? 'p-left-15' : ''}`}
              key={`th${i}`}
            >
              {colName}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
export default AutomationTableHeaders;
