import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'antd';
import React from 'react';

const FakeDisabledInput = () => {
  return (
    <div
      className="fakeInputWrapper"
      style={{ backgroundColor: 'white', margin: '0px 11px 0px 11px', width: 'calc(100% - 22px)' }}
    >
      <div className="fakeChatInputBoxStyle">
        <Input.TextArea
          id="textAreaInputWithActionsInbox"
          style={{
            border: 'none',
            width: '100%',
            caretColor: 'black',
            display: 'block',
            fontFamily: 'Poppins',
            letterSpacing: 0.5,
            wordSpacing: 1,
            whiteSpace: 'pre-wrap',
            resize: 'none',
            padding: 0,
            backgroundColor: 'white',
          }}
          autoSize={{ minRows: 1, maxRows: 6 }}
          placeholder="Message..."
          disabled
        />
      </div>

      <div className="flex-row-spacebetween-nowrap w-100-P m-left-10 p-btm-6">
        <div>
          <FontAwesomeIcon
            className="fs-15 cursor-disabled m-right-7 m-left-7"
            icon={['far', 'dollar-sign']}
            style={{ marginLeft: '7px', color: '#BDBDBD' }}
          />

          {/* IMAGES */}
          <FontAwesomeIcon
            className="fs-15 cursor-disabled m-right-7 m-left-7"
            icon={['far', 'paperclip']}
            style={{
              color: '#BDBDBD',
            }}
          />
          {/* templates */}

          <FontAwesomeIcon
            className="fs-15 cursor-disabled m-right-7 m-left-7"
            icon={['far', 'comment-alt-lines']}
            style={{
              color: '#BDBDBD',
            }}
          />
        </div>
        <button
          type="button"
          style={{
            marginRight: '25px',
            marginLeft: '13px',
            background: 'transparent',
            border: 'none',
          }}
          disabled
        >
          <FontAwesomeIcon
            // size={'2x'}
            className="fs-16 cursor-disabled"
            icon={['fas', 'paper-plane']}
            style={{
              color: '#BDBDBD',
            }}
          />
        </button>
      </div>
    </div>
  );
};
export default FakeDisabledInput;
