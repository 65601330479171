import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfigProvider, Select, Tag, Space } from 'antd';
import { Input as InputComponent, Dropdown as DropdownComponent, Pair, FIELD_PROPS } from '../Field';
import { HEADING_CLASSES } from '../WizardMulti';
import './steps.css';

const GenInformation = props => {
  const { data, setBankStores, updateValues: saveValues } = props;
  const Input = inputProps => <InputComponent {...props} {...inputProps} />;
  const Dropdown = dropdownProps => <DropdownComponent {...props} {...dropdownProps} />;

  const tagRender = props => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="#EAF4FE"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          height: '40px',
          paddingTop: '8px',
        }}
      >
        {label}
      </Tag>
    );
  };

  // helper to update values
  const updateValues = value =>
    saveValues({
      stores: value,
    });

  const customizeRenderEmpty = () => (
    <div
      style={{
        textAlign: 'center',
      }}
    >
      <FontAwesomeIcon className="m-top-10" color="#D3D3D3" icon={['fas', 'store-slash']} size="2x" />
      <p>
        All stores have a default bank account. To assign a store to this account, please remove them from their default
        account.
      </p>
    </div>
  );

  return (
    <>
      <fieldset className="m-btm-15">
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Space
            direction="vertical"
            style={{
              width: '100%',
            }}
          >
            <Select
              mode="multiple"
              suffixIcon={
                <FontAwesomeIcon className="m-left-10" color="#000" icon={['fas', 'location-dot']} size="1x" />
              }
              showArrow
              tagRender={tagRender}
              style={{ width: '100%' }}
              options={props.bankAccountStores}
              className="dropdown-select maw-100-P w-100-P align-left"
              id="bankAccountStores"
              onChange={updateValues}
            />
          </Space>
        </ConfigProvider>
      </fieldset>

      <fieldset>
        <legend className={HEADING_CLASSES}>Bank Information</legend>
        <Input
          label="Routing Number"
          id="bankRoutingNumber"
          placeholder="Routing Number"
          minLength="9"
          maxLength="9"
          inputMode="numeric"
          forget
        />
        <Input
          label="Account Number"
          id="bankAccountNumber"
          placeholder="Account Number"
          maxLength="17"
          inputMode="numeric"
          forget
        />
        <Input
          label="Bank Account Nickname (Optional)"
          id="bankAccountNickname"
          placeholder="Bank Account Nickname"
          maxLength="15"
          optional
          forget
          defaultValue={data.bankAccountNickname?.value}
        />
        <Pair>
          <Dropdown label="Account Type" id="bankAccountType" options={['Checking', 'Savings']} />
          <Input
            label="Bank Address State"
            id="bankState"
            minLength="2"
            maxLength="2"
            placeholder="State"
            defaultValue={data.businessState?.value}
          />
        </Pair>
      </fieldset>
    </>
  );
};

export default GenInformation;
