import React, { useRef } from 'react';
import { Carousel, Image, Tag, Avatar } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import './styles.css';
import NotFoundImage from './not-found.png';

const PrevArrow = props => {
  return (
    <div {...props}>
      <ArrowLeftOutlined />
    </div>
  );
};
const NextArrow = props => {
  return (
    <div {...props}>
      <ArrowRightOutlined />
    </div>
  );
};

const CarouselTag = () => {
  return (
    <div className="cb-product-details-carousel__tag">
      <Tag color="#F9E0E0">OUT OF STOCK</Tag>
    </div>
  );
};

const SlideSelector = ({ images, onClick }) => {
  return (
    <div className="cb-product-details-carousel__slide-selector">
      {images.map((image, index) => (
        <Image key={image.id} src={image.url} preview={false} onClick={() => onClick(index)} />
      ))}
    </div>
  );
};

const ProductDetailsCarousel = ({ product }) => {
  const carouselRef = useRef(null);
  const showSlideSelector = product.images.length > 0;
  const productInStock = (typeof product.qty === 'number' && product.qty > 0) || product.qty === null;

  const handleSelect = index => {
    carouselRef.current.goTo(index);
  };

  return (
    <div className="cb-product-details-carousel">
      <div className="cb-product-details-carousel__container">
        {!productInStock && <CarouselTag />}
        {product.images.length ? (
          <Carousel ref={carouselRef} prevArrow={<PrevArrow />} nextArrow={<NextArrow />} dots={false} arrows>
            {product.images.map(image => (
              <Image
                key={image.url}
                src={image.url}
                size={{
                  lg: 450,
                  xl: 450,
                  xxl: 450,
                }}
              />
            ))}
          </Carousel>
        ) : (
          <Avatar
            shape="square"
            size={{
              lg: 550,
              xl: 550,
              xxl: 550,
            }}
            src={NotFoundImage}
            onError={NotFoundImage}
            className="cb-product-details-carousel__no-image"
          />
        )}
      </div>

      {showSlideSelector && <SlideSelector images={product.images} onClick={handleSelect} />}
    </div>
  );
};

export default ProductDetailsCarousel;
