import React, { useCallback, useEffect, useMemo, useState } from 'react';
import VendorRecommendationCard from '../VendorRecommendationCard';
import { getVendorById } from '../../../../../api/vendors';
import './styles.scss';

const VendorRecommendations = props => {
  const { selectedVendorItems, setSelectedVendorItems, vendorRecommendations } = props;

  const [vendor, setVendor] = useState(null);

  const vendorsIds = useMemo(
    () =>
      vendorRecommendations.reduce((acc, curr) => {
        if (!acc.includes(curr.vendorId)) {
          acc.push(curr.vendorId);
        }

        return acc;
      }, []) || [],
    [vendorRecommendations],
  );

  const title = useMemo(() => vendor?.imageUrl || vendor?.name || 'Recommendations', [vendor]);

  const fetchVendorById = useCallback(async vendorsId => {
    try {
      const response = await getVendorById(vendorsId);

      setVendor(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (vendorsIds.length === 1) {
      fetchVendorById(vendorsIds[0]);
    }
  }, [vendorsIds]);

  return (
    <div className="cb-vendor-recommendations">
      <div className="cb-vendor-recommendations__header">
        <h2 className="cb-vendor-recommendations__header-title">
          {title.startsWith('https') ? (
            <img src={title} alt="vendor" className="cb-vendor-recommendations__header-logo" />
          ) : (
            title
          )}
        </h2>
        <h3 className="cb-vendor-recommendations__header-subtitle">Recommended products to attach</h3>
      </div>

      <div className="cb-vendor-recommendations__body max-h-195 overflowY">
        {vendorRecommendations.map(item => {
          return (
            <VendorRecommendationCard
              key={item.id}
              vendorRecommendation={item}
              selectedVendorItems={selectedVendorItems}
              setSelectedVendorItems={setSelectedVendorItems}
            />
          );
        })}
      </div>
    </div>
  );
};

export default VendorRecommendations;
