const initialState = {
  data: [],
};
export default function reducer(state = initialState, action) {
  let state2 = state;
  switch (action.type) {
    case 'SET_DATA':
      return { ...state2, data: action.payload };
    default:
      break;
  }
  return state2;
}
