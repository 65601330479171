export const routeCheck = () => {
}

export const shopifyLoginCheck = () => (window.location.pathname.includes('/help/integrations/shopify') && window.location.search);

export const getShopifyUUID = () => {
        const params = new URLSearchParams(window?.location?.search);
        const shopifyUUID = params.get('shopifyInstallationId');
        return shopifyUUID || false;
}

export const consoleLoginCheck = () => window.location.pathname.includes('console-login');