import React, { useMemo } from 'react';
import { Button, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import formatNumberAsCurrency from '../../../../../utils/formatNumberAsCurrency';
import StyledLink from '../../../../Layout/StyledLink';
import './styles.scss';
import SmallTable from '../../../../Layout/SmallTable';

const dateFormatter = incDate => {
  let formattedDate = 'N/A';
  if (incDate) {
    if (Number(incDate) > 9999999) {
      formattedDate = moment(incDate, 'YYYYMMDD').format('MM/DD/YYYY');
    }
  }
  return formattedDate;
};

const attributeFormatter = attribute => {
  return attribute.charAt(0).toUpperCase() + attribute.slice(1).toLowerCase().replace('_', ' ');
};

const green = (firstSalesOpportunity, resultsStages) => {
  const filteredSalesOpps =
    resultsStages.filter(opp => opp.sales_pipeline_id === firstSalesOpportunity.sales_pipeline_id) || [];
  let small = {};
  if (filteredSalesOpps.length > 0) {
    small = filteredSalesOpps.reduce(function (prev, curr) {
      return prev.weight < curr.weight ? prev : curr;
    });
    return small.id;
  }
};

const getStage = (firstSalesOpportunity, resultsStages) => {
  const returnTile = [];
  if (firstSalesOpportunity.weight === 0 || firstSalesOpportunity.weight === 1) {
    returnTile.push(
      <div
        style={{
          width: 'fit-content',
          height: '22px',
          borderRadius: '3px',
          backgroundColor: '#e8f4ff',
          color: '#33aafc',
          fontSize: '10px',
          fontWeight: '600',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 5px',
        }}
      >
        COMPLETE
      </div>,
    );
  } else if (firstSalesOpportunity.sales_opp_stage_id === green(firstSalesOpportunity, resultsStages)) {
    returnTile.push(
      <div
        style={{
          width: 'fit-content',
          height: '22px',
          borderRadius: '3px',
          backgroundColor: '#ecf8dd',
          color: '#7fbd31',
          fontSize: '10px',
          fontWeight: '600',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 5px',
        }}
      >
        {firstSalesOpportunity.stage_name.toUpperCase()}
      </div>,
    );
  } else {
    returnTile.push(
      <div
        style={{
          width: 'fit-content',
          height: '22px',
          padding: '0 5px',
          borderRadius: '3px',
          backgroundColor: '#ffe5d3',
          color: '#fc8333',
          fontSize: '10px',
          fontWeight: '600',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {firstSalesOpportunity.stage_name.toUpperCase()}
      </div>,
    );
  }
  return returnTile;
};

const OpportunityModal = props => {
  const { open, onClose, loading, salesOpportunityHistory, onClickDelete, onClickEdit, showActivities = true } = props;

  const firstSalesOpportunity = useMemo(() => salesOpportunityHistory?.resultsOpp[0], [salesOpportunityHistory]);

  const tableHeaders = [
    {
      keyName: 'created_date',
      title: 'Date',
      headerClassName: 'align-left p-btm-6',
      rowClassName: '',
      style: { width: '25%' },
      sortable: false,
      render: t => moment(t, 'YYYYMMDDhhmmss').format('MM/DD/YYYY @ hh:mm A'),
    },
    {
      keyName: 'created_by_name',
      title: 'Associate',
      headerClassName: 'align-left p-btm-6',
      style: { width: '15%', paddingRight: '22px' },
      rowClassName: 'align-left',
      sortable: false,
      render: t => {
        return <p>{t}</p>;
      },
    },
    {
      keyName: '',
      style: { width: '60%' },
      title: 'Description',
      headerClassName: 'align-left p-btm-6',
      rowClassName: '',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        if (o.type === 'CREATE') {
          return <p>Created</p>;
        }

        if (o.type === 'STAGE') {
          return (
            <p>
              Moved from {o.prev_val} to {o.new_val}
            </p>
          );
        }

        if (o.type === 'UPDATE') {
          return (
            <p>
              Changed {attributeFormatter(o.attribute)} from{' '}
              {o.attribute === 'CLOSE_DATE'
                ? `${dateFormatter(o.prev_val)} to ${dateFormatter(o.new_val)}`
                : `${o.prev_val} to ${o.new_val}`}
            </p>
          );
        }

        return <p>No content available</p>;
      },
    },
  ];

  return (
    <Modal
      open={open}
      width={800}
      footer={false}
      closeIcon={
        <FontAwesomeIcon
          icon="fas fa-times"
          className="reports-sales-opportunity-details-modal--close"
          onClick={onClose}
        />
      }
      onCancel={onClose}
      className="reports-sales-opportunity-details-modal"
      centered
    >
      <h2 className="reports-sales-opportunity-details-modal--title">Opportunity Details</h2>

      {loading && <div>Loading...</div>}

      {!loading && !salesOpportunityHistory && <div>Error loading sales opportunity</div>}

      {!loading && salesOpportunityHistory && (
        <>
          <div className="reports-sales-opportunity-details-modal--header">
            <span className="reports-sales-opportunity-details-modal--header-title">{firstSalesOpportunity.name}</span>
            <div className="reports-sales-opportunity-details-modal--header-actions">
              {onClickDelete && (
                <Button type="link" icon={<FontAwesomeIcon icon="far fa-trash-alt" />} danger onClick={onClickDelete}>
                  Delete
                </Button>
              )}
              {onClickEdit && (
                <Button type="link" icon={<FontAwesomeIcon icon="far fa-pen" />} primary onClick={onClickEdit}>
                  Edit
                </Button>
              )}
            </div>
          </div>

          <div className="reports-sales-opportunity-details-modal--body">
            <div className="reports-sales-opportunity-details-modal--body-section">
              <div className="reports-sales-opportunity-details-modal--body-title">Price Value</div>

              <div className="reports-sales-opportunity-details-modal--body-content">
                {formatNumberAsCurrency(firstSalesOpportunity.amount)}
              </div>
            </div>

            <div className="reports-sales-opportunity-details-modal--body-section">
              <div className="reports-sales-opportunity-details-modal--body-title">Created By</div>

              <StyledLink to={`/associates/associate-details/${firstSalesOpportunity.associate_id}`}>
                {firstSalesOpportunity.first_name} {firstSalesOpportunity.last_name}
              </StyledLink>
            </div>

            <div className="reports-sales-opportunity-details-modal--body-section">
              <div className="reports-sales-opportunity-details-modal--body-title">Expected Close Date</div>

              <div className="reports-sales-opportunity-details-modal--body-content">
                {dateFormatter(firstSalesOpportunity.close_date)}
              </div>
            </div>

            <div className="reports-sales-opportunity-details-modal--body-section">
              <div className="reports-sales-opportunity-details-modal--body-title">Created Date</div>

              <div className="reports-sales-opportunity-details-modal--body-content">
                {dateFormatter(firstSalesOpportunity.created_date)}
              </div>
            </div>

            <div className="reports-sales-opportunity-details-modal--body-section">
              <div className="reports-sales-opportunity-details-modal--body-title">Stage</div>

              {getStage(firstSalesOpportunity, salesOpportunityHistory?.resultsStages)}
            </div>

            {firstSalesOpportunity.notes && (
              <div className="reports-sales-opportunity-details-modal--body-section">
                <div className="reports-sales-opportunity-details-modal--body-title">Notes</div>

                <div className="reports-sales-opportunity-details-modal--body-content">
                  {firstSalesOpportunity.notes}
                </div>
              </div>
            )}
          </div>

          {showActivities && salesOpportunityHistory && (
            <div className="reports-sales-opportunity-details-modal--table">
              <h2 className="reports-sales-opportunity-details-modal--table-title">Activity</h2>

              <SmallTable
                tableClassName="m-top-0"
                data={salesOpportunityHistory.resultsHist}
                tableHeaders={tableHeaders}
                classname="table-head border-btm h-20 rem-pad-width m-top-0"
                tableContainerClass="table-body-content w-100-P"
                tableStyle={{ width: '100%' }}
              />
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default OpportunityModal;
