import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Timeline } from 'antd-v5';
import { getRequest } from '../../../../../core/apiRequests';
import { AutoMessage, AutoReminder, PurchaseOutline } from '../../../../../componentsV2/Layout/Report/components/Icons';
import SalesTimelineItem from '../../../../../componentsV2/Layout/Report/components/SalesTimelineItem';
import { BulbOutlined } from '@ant-design/icons';
import TimelineItem from '../../../../../componentsV2/Layout/Report/components/Timeline';
import moment from 'moment';

function expandedRow(record) {
  const [message, setMessage] = useState('');
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSaleData = useCallback(async () => {
    setLoading(true)
    const reqObj = {
      params: ['report', 'attributedMeta'],
      query: {
        chatMessages: JSON.stringify([record.chat_message_id]),
        reminders: JSON.stringify([record.reminder_id]),
        salesTransactions: JSON.stringify(record.transactions.map(item => item.sales_transaction_id)),
      },
      loadingStatusOff: true
    };
    const attributedMeta = await getRequest(reqObj);
    setLoading(false)
    const sales = attributedMeta.filter(item => item.type === 'salesTransaction');
    const [action] = attributedMeta.filter(item => item.type !== 'salesTransaction' && item.msg);
    setMessage(action.msg);
    setSales(sales.map(sale => {
      const transaction = record.transactions.find(transaction => transaction.sales_transaction_id === sale.id);
      return {
        ...sale,
        ...transaction,
      };
    }));
  });

  useEffect(() => {
    getSaleData();
  }, [record]);

  const getTitle = useMemo(() => {
    switch (record.automationType) {
      case 'Smart Assistant Activity':
        return <>
          <span className="fw-600">{record.activity_associate_name || 'An Associate'} </span>completed a Smart Assistant activity on
          <span className="fw-600"> {moment.utc(record.activity_date).format('MMM DD, YYYY')}</span>
        </>;
      case 'Auto Message':
        return <>
          Auto-message from <span className="fw-600">{record.activity_associate_name || 'an Associate'} </span>sent on
          <span className="fw-600"> {moment.utc(record.activity_date).format('MMM DD, YYYY')}</span>
        </>;
      case 'Auto Reminder':
        return <>
          <span className="fw-600">{record.activity_associate_name || 'An Associate'} </span>completed an auto-reminder on
          <span className="fw-600"> {moment.utc(record.activity_date).format('MMM DD, YYYY')}</span>
        </>;
      default:
        return '';
    }
  }, [record])

  return (
    <Timeline
      pending={loading ? 'Loading...' : null}
      items={[
        {
          color: 'gray',
          dot: getIcon(record.automationType),
          children: (
            <TimelineItem
              description={message}
              title={getTitle}
            />
          ),
        },
        ...sales.map(({sales_transaction_amount, description, sales_transaction_data, sales_transaction_id}) => ({
          dot: <PurchaseOutline style={{ color: '#389E0D', fontSize: '18px'}} />,
          children: (
            <SalesTimelineItem
              activity_date={sales_transaction_data}
              client_name={record.client_name}
              description={description}
              sales_transaction_amount={sales_transaction_amount}
              key={sales_transaction_id}
            />
          )
        }))
      ]}
      />
  );
}

export default expandedRow;


const getIcon = (type) => {
  switch (type) {
    case 'Smart Assistant Activity':
      return <BulbOutlined style={{ color: '#000', opacity: 0.45, fontSize: '18px'}} />;
    case 'Auto Message':
      return <AutoMessage style={{ color: '#000', opacity: 0.45, fontSize: '18px'}} />;
    case 'Auto Reminder':
      return <AutoReminder style={{ color: '#000', opacity: 0.45, fontSize: '18px'}} />;
    default:
      return null;
  }
}
