import reactStringReplace from 'react-string-replace'

const options = [
  {
    value: ':name:',
    label: 'Client Name'
  },
  {
    value: ':associate:',
    label: 'Associate Name'
  },
  {
    value: ':review:',
    label: 'Review Link'
  },
  {
    value: ':store:',
    label: 'Store Name'
  },
  {
    value: ':category:',
    label: 'Product Category'
  },
  {
    value: ':product:',
    label: 'Product Name'
  },
  {
    value: ':relation_name:',
    label: 'Relationship Name'
  }
];

export const convertMessageTemplate = (messageTemplate) => {
  const regex = new RegExp(`(${options.map(t => t.value).join('|')})`)
    let splitString = reactStringReplace(messageTemplate, regex, (match, i) => {
      let find = options.find(t => t.value === match)
      if(find) {
        return (`<span class="ant-tag-clientbook" contentEditable="false">${find.label}</span>`)
      }
      return match
    })
    return splitString.join('')
}
