import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd-v5';
import ProductsV2Sidebar from './components/Sidebar';
import { getProductById, getProductsElasticSearch } from '../../../api/products';
import ProductsV2List from './components/List';
import ProductsV2Details from './components/Details';
import { getVendorItemById, getVendorItems, getVendors, trackVendorEvent } from '../../../api/vendors';
import usePreviousValue from '../../../hooks/usePreviousValue';
import formatToCurrency from '../../../utils/formatToCurrency';
import ProductsV2Drawer from './components/Drawer';
import ClientCollectionModal from '../ProductDetails/Layout/components/ClientCollectionModal';
import SendProductModal from '../ProductDetails/Layout/components/SendProductModal';
import SaveProductModal from '../../../componentsV2/Products/SaveProductModal';
import DeleteProductModal from '../../../componentsV2/Products/DeleteProductModal';
import './styles.scss';
import { updateVendorItemViewDate } from '../../Navigation_V2/Edison/apiRequests';

const GET_VENDORS_PARAMS = {
  offset: 0,
  limit: 100,
  enabled: true,
};

const GET_PRODUCTS_LIMIT = 20;

const parseVendorProduct = product => {
  let primaryImage = null;
  let images = [];

  if (product.imageUrl) {
    primaryImage = { isPrimary: true, url: product.imageUrl };
  } else {
    images = product?.images ? product.images.map((item, index) => ({ isPrimary: index === 0, url: item })) : [];
    primaryImage = images.find(image => image.isPrimary);
  }

  return {
    id: product.id,
    name: product.name,
    msrp: formatToCurrency(product.msrp),
    image: primaryImage ? primaryImage.url : null,
    images,
    sku: null,
    mpn: product.mpn,
    qty: null,
    posRefNum: null,
    description: product.description,
    attributes: {
      item_code: product.mpn,
      name: product.name,
      ...product.attributes,
    },
  };
};

const parseClientbookProduct = product => {
  return {
    id: product.id,
    name: product.name,
    price: formatToCurrency(product.price),
    image: product.image,
    images: product?.images || [],
    sku: product.sku,
    mpn: null,
    qty: product.qty,
    posRefNum: product.posRefNum,
    description: product.description,
    attributes: {
      item_code: product.sku || null,
      name: product.name,
      msrp: product.msrp,
      price: formatToCurrency(product.price),
      unitCost: formatToCurrency(product.price),
      posRefNum: product.posRefNum,
      upc: product.upc,
      stoneType: product.stoneType,
      stoneClarity: product.stoneClarity,
      stoneShape: product.stoneShape,
      stoneSize: product.stoneSize,
      stoneColor: product.stoneColor,
      totalDiaWt: product.totalDiaWt,
      pattern: product.pattern,
      color: product.color,
      material: product.material,
    },
  };
};

const ProductsV2 = props => {
  const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const vendorIdParam = useMemo(() => Number(searchParams.get('vendorId')), [searchParams]);
  const productIdParam = useMemo(() => Number(searchParams.get('productId')), [searchParams]);

  const { paymentBanner } = props;

  const [showBrandDropdown, setShowBrandDropdown] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsPage, setProductsPage] = useState(1);
  const [productsTotal, setProductsTotal] = useState(0);
  const [productsSearch, setProductsSearch] = useState('');
  const [selectedFilter, setSelectedFilter] = useState(vendorIdParam || 'inStockProducts');
  const [selectedProductId, setSelectedProductId] = useState(productIdParam || null);

  const [productLoading, setProductLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showClientCollectionModal, setShowClientCollectionModal] = useState(false);
  const [showShareProductModal, setShowShareProductModal] = useState(false);
  const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const prevProductsPage = usePreviousValue(productsPage);

  const isVendorSelected = useMemo(() => {
    return !['allProducts', /* 'outOfStockItems', */ 'inStockProducts'].includes(selectedFilter);
  }, [selectedFilter]);

  const title = useMemo(() => {
    if (selectedFilter === 'allProducts') {
      return 'All Products';
    }

    // if (selectedFilter === 'outOfStockItems') {
    //   return 'Out of Stock';
    // }

    if (selectedFilter === 'inStockProducts') {
      return 'In Stock';
    }

    const selectedVendor = vendors.find(vendor => vendor.id === selectedFilter);

    if (!selectedVendor) {
      return '';
    }

    return selectedVendor.imageUrl || selectedVendor.name;
  }, [vendors, selectedFilter]);

  const productsFilters = useMemo(() => {
    const filters = {};

    if (!isVendorSelected) {
      // if (selectedFilter === 'outOfStockItems') {
      //   filters.available = false;
      // }

      if (selectedFilter === 'inStockProducts') {
        filters.available = true;
      }

      if (selectedBrands.length > 0) {
        filters.brand = {
          $in: selectedBrands.map(item => item?.value || item),
        };
      }

      if (selectedCategories.length > 0) {
        filters.category = {
          $in: selectedCategories.map(item => item?.value || item),
        };
      }
    }

    return filters;
  }, [selectedFilter, isVendorSelected, selectedBrands, selectedCategories]);

  const fetchVendors = useCallback(async () => {
    try {
      const response = await getVendors({
        params: GET_VENDORS_PARAMS,
      });

      setVendors(response.data.data);
    } catch (error) {
      // console.error(error);
    }
  }, []);

  const fetchClientbookProducts = useCallback(
    async clear => {
      setProductsLoading(true);

      try {
        const params = {
          page: productsPage,
          limit: GET_PRODUCTS_LIMIT,
          search: productsSearch,
          filters: productsFilters,
        };

        const response = await getProductsElasticSearch({ params });

        const parsedProducts = response.data.data.map(parseClientbookProduct);

        if (clear) {
          setProducts(parsedProducts);
          setSelectedProductId(parsedProducts[0]?.id || null);
        } else {
          setProducts([...products, ...parsedProducts]);
          setSelectedProductId(selectedProductId || parsedProducts[0]?.id || null);
        }

        setProductsTotal(Number(response.data.total));
        setProductsPage(Number(response.data.page));
      } catch (error) {
        message.error('Error fetching products');
      } finally {
        setProductsLoading(false);
      }
    },
    [productsPage, productsSearch, productsFilters, products, selectedProductId],
  );

  const fetchVendorItems = useCallback(
    async clear => {
      setProductsLoading(true);

      try {
        const params = {
          page: productsPage,
          limit: GET_PRODUCTS_LIMIT,
          search: productsSearch,
          filters: productsFilters,
        };

        const response = await getVendorItems(selectedFilter, { params });
        const parsedProducts = response.data.data.map(parseVendorProduct);

        if (clear) {
          setProducts(parsedProducts);
          setSelectedProductId(parsedProducts[0]?.id || null);
        } else {
          setProducts([...products, ...parsedProducts]);
          setSelectedProductId(selectedProductId || parsedProducts[0]?.id || null);
        }

        setProductsTotal(Number(response.data.total));
        setProductsPage(Number(response.data.page));
      } catch (error) {
        message.error('Error fetching products');
      } finally {
        setProductsLoading(false);
      }
    },
    [selectedFilter, productsPage, productsSearch, productsFilters, products, selectedProductId],
  );

  const fetchProducts = useCallback(
    async (clear = false) => {
      if (isVendorSelected) {
        fetchVendorItems(clear);
      } else {
        fetchClientbookProducts(clear);
      }
    },
    [isVendorSelected, fetchVendorItems, fetchClientbookProducts],
  );

  const trackVendorItemViewed = useCallback(async (vendorId, mpn) => {
    try {
      await updateVendorItemViewDate([{ vendorId, mpn }], 'product_list', 'vendor_item_viewed');
    } catch (error) {
      //
    }
  }, []);

  const fetchVendorItem = useCallback(async () => {
    setProductLoading(true);

    try {
      const response = await getVendorItemById(selectedFilter, selectedProductId);
      const parsedProduct = parseVendorProduct(response.data);

      setSelectedProduct(parsedProduct);
      trackVendorItemViewed(selectedFilter, parsedProduct.mpn);
    } catch (error) {
      message.error('Error fetching product');
    } finally {
      setProductLoading(false);
    }
  }, [selectedFilter, selectedProductId]);

  // const getVendorItem = useCallback(() => {
  //   const product = products.find(item => Number(item.id) === Number(selectedProductId));

  //   if (product) {
  //     setSelectedProduct(product);
  //   } else {
  //     fetchVendorItem();
  //   }
  // }, [products, selectedProductId, fetchVendorItem]);

  const fetchClientbookProduct = useCallback(async () => {
    setProductLoading(true);

    try {
      const response = await getProductById(selectedProductId);
      const parsedProduct = parseClientbookProduct(response.data);

      setSelectedProduct(parsedProduct);
    } catch (error) {
      message.error('Error fetching product');
    } finally {
      setProductLoading(false);
    }
  }, [selectedProductId]);

  const fetchProduct = useCallback(async () => {
    if (isVendorSelected) {
      fetchVendorItem();
    } else {
      fetchClientbookProduct();
    }
  }, [isVendorSelected, fetchVendorItem, fetchClientbookProduct]);

  const handleDeleteProduct = useCallback(async () => {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise(resolve => setTimeout(resolve, 5000));

    setSelectedProductId(null);
    fetchProducts(true);
  }, [fetchProducts]);

  useEffect(() => {
    fetchVendors();
  }, []);

  useEffect(() => {
    if (prevProductsPage !== undefined) {
      setProductsPage(1);
      setProductsTotal(0);
      setProducts([]);
      setSelectedProductId(null);

      if (productsPage === 1) {
        fetchProducts(true);
      }
    }
  }, [selectedFilter, productsSearch, productsFilters]);

  useEffect(() => {
    fetchProducts();
  }, [productsPage]);

  useEffect(() => {
    setSelectedProduct(null);

    if (selectedProductId) {
      fetchProduct();
    }
  }, [selectedProductId]);

  return (
    <div className={`products-v2 ${paymentBanner.active ? 'products-v2__with-banner' : 'products-v2__without-banner'}`}>
      <div className="products-v2__container">
        <ProductsV2Sidebar
          vendors={vendors}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          showAddProductModal={showAddProductModal}
          setShowAddProductModal={setShowAddProductModal}
        />

        <ProductsV2List
          title={title}
          productsLoading={productsLoading}
          products={products}
          productsSearch={productsSearch}
          setProductsSearch={setProductsSearch}
          productsPage={productsPage}
          productsTotal={productsTotal}
          setProductsPage={setProductsPage}
          setSelectedProductId={setSelectedProductId}
          isVendorSelected={isVendorSelected}
          showBrandDropdown={showBrandDropdown}
          setShowBrandDropdown={setShowBrandDropdown}
          selectedBrands={selectedBrands}
          setSelectedBrands={setSelectedBrands}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />

        <ProductsV2Details
          loading={productLoading}
          product={selectedProduct}
          showClientCollectionModal={showClientCollectionModal}
          setShowClientCollectionModal={setShowClientCollectionModal}
          showShareProductModal={showShareProductModal}
          setShowShareProductModal={setShowShareProductModal}
          showDeleteProductModal={showDeleteProductModal}
          setShowDeleteProductModal={setShowDeleteProductModal}
          showDetailsDrawer={showDetailsDrawer}
          setShowDetailsDrawer={setShowDetailsDrawer}
          fetchProduct={fetchProduct}
        />
      </div>

      <SaveProductModal
        open={showAddProductModal}
        setOpen={setShowAddProductModal}
        onSuccess={() => fetchProducts(true)}
      />

      <ProductsV2Drawer
        open={showDetailsDrawer}
        onClose={() => setShowDetailsDrawer(false)}
        product={selectedProduct}
        zIndex={500}
        style={{
          marginTop: paymentBanner.active ? '107px' : '57px',
          maxHeight: paymentBanner.active ? 'calc(100vh - 107px)' : 'calc(100vh - 57px)',
        }}
      />

      <ClientCollectionModal
        productId={selectedProductId}
        visible={showClientCollectionModal}
        setVisible={setShowClientCollectionModal}
        vendorId={isVendorSelected ? selectedFilter : null}
      />

      <SendProductModal
        productId={selectedProductId}
        visible={showShareProductModal}
        setVisible={setShowShareProductModal}
        vendorId={isVendorSelected ? selectedFilter : null}
      />

      <DeleteProductModal
        productId={selectedProductId}
        open={showDeleteProductModal}
        setOpen={setShowDeleteProductModal}
        onSuccess={handleDeleteProduct}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  paymentBanner: state.paymentBanner,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsV2);
