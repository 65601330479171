import React, { useState, useEffect, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TagManagerModalV2 from '../../../helpers/TagManagerModalV2';
import { notificationError, notificationSuccess } from '../../../../../../core/actions';
import { postRequest } from '../../../../../../core/apiRequests';
import { setClientDetailsTagList } from '../../../../../../reducers/client';

const Tags = memo(props => {
  const [showTagModal, setShowTagModal] = useState(false);
  const selectedTags = (props?.client?.details_tag_list || []).filter(tag => (tag.client_id !== null) && (tag.client_id == props.client.client_id));

  const saveTagAccess = async singleTag => {
    const newTagArr = props.client.details_tag_list.map(tag => {
      if(tag.id == Number(singleTag)){
        return ({...tag, client_id: null})
      } else {
        return tag
      }
    });
    const reqObj = {
      params: ['clients', props.auth.merchantId, 'saveTagData'],
      data: {
        userList: [props.client.client_id],
        tagAccess: { [singleTag]: false },
      },
    };
    const reqData = await postRequest(reqObj);
    if (reqData) {
      notificationSuccess('Tag removed from client');
      setClientDetailsTagList(newTagArr);
      props.getClientData(props.client.client_id);
    } else {
      notificationError('Error removing tag from client');
    }
  };

  return (
    <div className="w-100-P fs-15 fw-500 flex-col-left m-left-21">
      <div className="flex-row-spacebetween-nowrap" style={{ alignItems: 'center' }}>
        <p>Tags</p>
        <FontAwesomeIcon
          size="1x"
          icon={['far', 'plus']}
          className="pointer m-left-16 fs-19"
          onClick={() => setShowTagModal(true)}
          id="ClientDetailsEditTag"
        />
      </div>
      {showTagModal && (
        <TagManagerModalV2
          showTagManagerHandler={setShowTagModal}
          client={props.client}
          merchantId={props.auth.merchantId}
          getClientData={props.getClientData}
        />
      )}
      <div className="flex-row-nospacebetween-wrap">
        {selectedTags.map((tag, i) => (
          <span
            key={i + tag.name}
            className="rem-pad-width fs-13 white m-right-8 m-btm-15"
            style={{
              whiteSpace: 'nowrap',
              lineHeight: '21px',
              verticalAlign: '-0.25em',
              borderRadius: '14.5px',
              padding: '4px 6px 2px 12px',
              height: '30px',
              borderColor: tag.color,
              backgroundColor: tag.color,
            }}
          >
            <span>{tag.name}</span>
            <FontAwesomeIcon
              icon="fa-regular fa-circle-xmark"
              style={{ color: '#ffffff', verticalAlign: '-0.25em' }}
              className="m-left-10"
              size="lg"
              onClick={() => {
                saveTagAccess(tag.id)
              }}
            />
          </span>
        ))}
      </div>
    </div>
  );
});
export default Tags;
