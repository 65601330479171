import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import TextAreaWithActions from '../../../../Layout/TextAreaWithActions';

import { notificationError, notificationSuccess } from '../../../../../core/actions';
import { postRequest, getRequest } from '../../../../../core/apiRequests';
import WhatsAppTimer from '../../../../Layout/WhatsAppTimer';
import PopConfirm from '../../../../Layout/PopConfirm';
import useActionTextAreaFiles from '../../../../Layout/TextAreaWithActions/useActionTextAreaFiles';

const WhatsAppModal = props => {
  const [msgText, setMsgText] = useState('');
  const [fileData, fileDispatch] = useActionTextAreaFiles();
  const [configObj, setConfigObj] = useState({
    name: '',
    review: '',
    spouse: '',
  });
  const [reviewLink, setReviewLink] = useState('[Review Link]');

  useEffect(() => {
    let { storeId } = props.auth;
    if (props.auth.merchantId && props.auth.stores.length > 1) {
      if (storeId === 0) {
        storeId = props.auth.stores[1].id;
      }
      (async () => {
        const reqObj = {
          params: ['associates', props.auth.userId, storeId, 'reviewLink'],
        };
        const data = await getRequest(reqObj);
        if (data[0].review_link) {
          setReviewLink(data[0].review_link);
        }
      })();
    }
  }, [props.auth.merchantId, props.auth.userId, props.auth.stores]);

  useEffect(() => {
    if (props.auth.merchantId) {
      findSpouseName();
    }
  }, [props.auth.merchantId]);

  const sendMessage = () => {
    const { userId, merchantId, firstName, lastName } = props.auth;
    if (msgText.length || fileData.images.urls.length) {
      if (props.client_id && merchantId) {
        // clean message square brackets and dispatch message to backend
        const bodyData = {
          id: props.client_id,
          msg: msgText,
        };
        setMsgText('');
        const containsSubstring = substr => str => str.includes(substr);
        const reqObj = {
          params: ['chatMessages', merchantId, 'save'],
          query: {
            associateId: userId,
            s3Images: fileData.images.urls.filter(containsSubstring('s3.amazonaws.com')),
            s3Videos: fileData.videos.urls.filter(containsSubstring('s3.amazonaws.com')),
            fullName: `${firstName} ${lastName}`,
            storeId: props.store_id,
          },
          data: bodyData,
          images: {
            urls: fileData.images.urls.filter(containsSubstring('blob')),
            files: fileData.images.files,
          },
          videos: {
            urls: fileData.videos.urls.filter(containsSubstring('blob')),
            files: fileData.videos.files,
            videoFrames: fileData.videos.videoFrames,
          },
        };
        (async () => {
          const respData = await postRequest(reqObj);
          if (respData.id) {
            notificationSuccess('Message successfully sent!');
            // scrollToBottom();
            props.closeTheStuff();
          } else {
            notificationError('Failed To Send Message');
          }
        })();
      }
    } else {
      notificationError('Please Type A Message');
    }
  };

  const buttonHideCheck = () => {
    const findSpouse = props.client.relationships.filter(
      rltn => rltn.relationship == 'Husband' || rltn.relationship == 'Wife',
    );
    if (findSpouse.length === 0) {
      return ['spouse'];
    }
  };

  const findSpouseName = () => {
    const findSpouse = props.client.relationships.filter(
      rltn => rltn.relationship == 'Husband' || rltn.relationship == 'Wife' || rltn.relationship == 'Spouse',
    );
    if (findSpouse.length > 0) {
      setConfigObj({ ...configObj, spouse: findSpouse[0].related_client_name });
    }
  };

  // WHATSAPP

  const whatsAppReplacementFormatter = template => {
    const subsObj = {};
    if (template.substitution) {
      const substitutes = template.substitution.split(',');
      substitutes.forEach(sub => {
        const propertyDivide = sub.split('=');
        if ((propertyDivide.length = 2)) {
          subsObj[propertyDivide[0]] = propertyDivide[1].toLowerCase();
        }
      });
    }
    if (template.id) {
      return template.template.replace(/{{\d}}/g, val => {
        const theNumber = val.replace(/[{}]/g, '');
        if (props.inputObj[theNumber].value) {
          return props.inputObj[theNumber].value;
        }
        if (subsObj[theNumber]) {
          // return `[${subsObj[theNumber]}]`
          switch (subsObj[theNumber]) {
          case 'client':
            return `${props.client_name}`;
          case 'associate':
            return `${props.associate}`;
          default:
            return `[${subsObj[theNumber]}]`;
          }
        }
        return '[N/A]';
      });
    }
  };

  const valueMatchPopConfirm = () => {
    const itemToReturn = whatsAppReplacementFormatter(props.selected);
    if (itemToReturn) {
      return itemToReturn;
    }
    return '';
  };

  const valueMatchReplace = () => {
    const heyArray = [];
    Object.keys(props.inputObj).forEach(key => {
      // key is going to be 1, 2, 3, 4
      heyArray.push(inputSwitch(props.inputObj[key], key));
    });
    const itemToReturn = heyArray;
    if (itemToReturn) {
      return (
        <div className="flex-col-center" style={{ width: '104%' }}>
          {itemToReturn}
        </div>
      );
    }
    return null;
  };

  const inputSwitch = (obj, key) => {
    switch (obj.type) {
      case 'date':
        return (
          <input
            onChange={e =>
              props.setInputObj({ ...props.inputObj, [key]: { ...props.inputObj[key], value: e.target.value } })
            }
            key={key}
            value={obj.value}
            placeholder={obj.type}
            className="inputHeight m-btm-15 placeholderInput"
            maxLength="50"
          />
        );
      case 'product':
        return (
          <input
            onChange={e =>
              props.setInputObj({ ...props.inputObj, [key]: { ...props.inputObj[key], value: e.target.value } })
            }
            key={key}
            value={obj.value}
            placeholder={obj.type}
            className="inputHeight m-btm-15 placeholderInput"
            maxLength="50"
          />
        );
      case 'interval':
        return (
          <input
            onChange={e =>
              props.setInputObj({ ...props.inputObj, [key]: { ...props.inputObj[key], value: e.target.value } })
            }
            key={key}
            value={obj.value}
            placeholder={obj.type}
            className="inputHeight m-btm-15 placeholderInput"
            maxLength="50"
          />
        );
      default:
        return null;
    }
  };

  const popConfirmSave = () => {
    if (inputSwitchValueFilled()) {
      if (msgText) {
        sendMessage();
        props.setSelected({});
      }
    } else {
      notificationError('Please enter a placeholder!');
    }
  };

  const inputSwitchValueFilled = () => {
    const arrayInputs = [];
    Object.keys(props.inputObj).forEach(key => {
      arrayInputs.push(inputSwitch(props.inputObj[key], key));
    });
    const allNull = arrayInputs.every(element => element === null);
    const filled = arrayInputs.every(element => element === null)
      ? null
      : arrayInputs.filter(obj => obj !== null)[0].props.value;
    if (filled) {
      return true;
    }
    if (allNull) {
      return true;
    }
    return false;
  };

  const whatsAppTitleChange = () => {
    const arrayInputs = [];
    Object.keys(props.inputObj).forEach(key => {
      arrayInputs.push(inputSwitch(props.inputObj[key], key));
    });
    const allNull = arrayInputs.every(element => element === null);
    if (allNull) {
      return true;
    }
    return false;
  };

  const inboxWhatsApp = () => {
    const whatsAppInbox = [];
    const whatsAppTemplates = [];
    if (!WhatsAppTimer(props.WA_receive)) {
      whatsAppTemplates.push(
        <div key={props.WA_receive}>
          <div className="pop-confirm-background">
            <div className="pop-confirm-container  p-top-36 p-left-44  p-right-44 rem-pad-width p-btm-6 flex-col-center hide-scrollbar">
              <span className="fs-22 fw-500 m-top-37 m-right-15 fs-18">
                {whatsAppTitleChange() ? 'Message Preview' : 'Enter Placeholders'}
              </span>
              <span className="fs-13  m-top-37 d-inline-block">
                <div>
                  <textarea
                    value={valueMatchPopConfirm()}
                    className="bg-offwhite fs-13 w-295 common_inputStyle:disabled m-btm-28 m-top-28 hide-scrollbar"
                    style={{
                      paddingBottom: '15px',
                      paddingTop: '15px',
                      border: 'none',
                      paddingLeft: '15px',
                      height: '100px',
                      paddingRight: '15px',
                    }}
                    readOnly
                  />
                  {valueMatchReplace()}
                </div>
              </span>
              <div
                className="flex-row-nospacebetween-wrap m-top-15 m-btm-28 w-100-P"
                style={{ marginLeft: '5px', justifyContent: 'center' }}
              >
                <button className="cancel-button m-right-15 m-top-8" onClick={() => props.closeTheStuff()}>
                  CANCEL
                </button>
                <button
                  className="save-button  m-top-8"
                  onClick={() => {
                    popConfirmSave();
                  }}
                >
                  SEND
                </button>
              </div>
            </div>
          </div>
        </div>,
      );
      return whatsAppTemplates;
    }
    whatsAppTemplates.push(
      <div
        className="pop-confirm-background"
        id="modal-background"
        // onClick={props.showMessageHandler}
      >
        <div
          className="pop-confirm-container rem-pad-width hide-scrollbar"
          style={{
            width: '630px',
            minHeight: '542px',
            // top: 'calc(50vh - 373px)',
            borderRadius: '8px',
          }}
        >
          <div className="flex-col-center h-100-P" style={{ justifyContent: 'flex-start' }}>
            <div className="flex-row-spacebetween-nowrap w-100-P m-top-15" />
            <span className="fs-18 fw-500" style={{ marginTop: '16px', marginBottom: '57px' }}>
              Send a Message
            </span>
            <TextAreaWithActions
              styleProps={{
                height: '18vh',
                width: '100%',
                borderRadius: '3px',
              }}
              containerStyleProps={
                {
                  // display: 'flex',
                  // flexDirection: 'column',
                  // justifyContent: 'space-between',
                  // alignItems: 'center'
                }
              }
              // key={props.client_id+Math.floor(Math.random()*9999)}
              val={msgText}
              changeHandler={setMsgText}
              fileData={fileData}
              fileDispatch={fileDispatch}
              configObj={configObj}
              client={props.client}
              reviewLink={reviewLink}
            />
            <div className="flex-row-spacebetween-nowrap" style={{ width: '295px', marginTop: '5px' }}>
              <button
                onClick={props.closeTheStuff}
                style={{
                  width: '140px',
                  height: '45px',
                  borderRadius: '3px',
                  border: 'solid 1px #979797',
                  backgroundColor: '#fff',
                  color: '#979797',
                }}
                className="fs-12 fw-600 pointer"
              >
                CANCEL
              </button>
              <button onClick={sendMessage} style={{ width: '140px' }} className="fs-12 fw-600 save-button">
                SEND
              </button>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>,
    );
    return whatsAppTemplates;
  };

  useEffect(() => {
    const fullText = whatsAppReplacementFormatter(props.selected);
    if (fullText) {
      setMsgText(fullText);
    }
  }, [valueMatchPopConfirm]);

  return <div>{inboxWhatsApp()}</div>;
};
const mapStateToProps = state => ({
  auth: state.auth,
  client: { ...state.client },
});

export default connect(mapStateToProps, {})(WhatsAppModal);

const ImageCard = props => {
  return (
    <div key={props.ind} className="pos-rel w-67 h-67 m-right-15">
      <FontAwesomeIcon
        className="pos-abs fs-24 bg-white circle gray"
        icon={['far', 'times-circle']}
        style={{
          top: '-12px',
          right: '-12px',
          border: '2px solid white',
        }}
        onClick={() => props.imageDeleteHandler(props.ind)}
      />
      <div className="w-67 h-67" style={{ overflow: 'hidden' }}>
        <img src={props.imageUrl} className="h-67 border-r4" alt="#" />
      </div>
    </div>
  );
};
