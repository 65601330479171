import React, { useState, useEffect, useRef } from 'react';
import { oneOfType, number, string } from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

// global
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import { setLoadingStatus, setSavingStatus } from '../../../../reducers/notification';

// local
import { load as loadBluesnap, init as initBluesnap } from './bluesnap';
import { updateData, getPostValues, updateDataBeforeSubmission, updateDataAfterSubmission, showErrors } from './utils';
import Errors from './Errors';
import Wizard from './Wizard';
import { AccountInfo, Agreements, BusinessDetails, CompanyRep, Owners } from './Steps';
import BluesnapFields from './BluesnapFields';
import { getBluesnapToken, addBankAccount } from '../apiRequests';
import { notificationError } from '../../../../core/actions';

const getBusinessDetails = data => {
  const retrieve = field => data[field]?.value;
  const retrieveAddress = prefix => ({
    line1: retrieve(`${prefix}Address`),
    city: retrieve(`${prefix}City`),
    state: retrieve(`${prefix}State`),
    zip: retrieve(`${prefix}Zip`),
  });
  const retrievePerson = prefix => ({
    firstName: retrieve(`${prefix}FirstName`),
    lastName: retrieve(`${prefix}LastName`),
    dateOfBirth: retrieve(`${prefix}DateOfBirth`),
    tel: retrieve(`${prefix}Phone`),
    address: retrieveAddress(prefix),
  });
  return {
    nickname: retrieve('bankAccountNickname'),
    dba: retrieve('businessName'),
    name: retrieve('businessLegalName'),
    description: retrieve('businessProductAndServiceDesc'),
    tel: retrieve('businessPhone'),
    website: retrieve('businessWebsite'),
    type: retrieve('businessType'),
    address: retrieveAddress('business'),
    representative: retrievePerson('companyRep'),
    owners: ['owner1', 'owner2', 'owner3', 'owner4']
      .map(retrievePerson)
      .filter(person => person.firstName !== undefined),
  };
};

const setupBluesnapSuccess = (id, setData, history, onError, retrieveData) => pfToken => {
  return addBankAccount(id, {
    ...getPostValues(),
    pfToken,
    businessDetails: getBusinessDetails(retrieveData()),
  })
    .then(() => {
      updateDataAfterSubmission(setData);
      setSavingStatus(false);
      history.push('/BankAccount/list');
    })
    .catch(errors => {
      updateDataAfterSubmission(setData);
      onError(errors, setData);
      setSavingStatus(false);
    });
};

const setupBluesnapError =
  (setData, onError) =>
    (errors = []) => {
      console.log('Error', { errors });
      updateDataAfterSubmission(setData);
      onError(errors, setData);
      setSavingStatus(false);
      notificationError('Bank account was not added');
    };

const AddAccount = ({ merchantId }) => {
  const [showAll, setShowAll] = useState(false);
  const [data, setData] = useState(null);
  const history = useHistory();
  // used for bluesnap errors (both FE & BE)
  const [errors, setErrors] = useState([]);

  const dataRef = useRef();
  dataRef.current = data;

  const handleErrors = (errs, set) => {
    setErrors(list => [...list, ...errs]);
    showErrors(errs, set);
    setShowAll(true);
  };

  useEffect(() => {
    // const getData = () => dataRef.current;
    // const i = setInterval(() => {
    //   try {
    //     getBusinessDetails(getData());
    //   } catch (e) {
    //     console.error('INSUFFICIENT DATA');
    //   }
    // }, 1000);
    // return () => clearInterval(i);
  }, []);

  useEffect(() => {
    setLoadingStatus(true);

    // get everything we need, async
    Promise.all([getBluesnapToken(merchantId), loadBluesnap()])
      .then(([token]) => {
        // set initial values
        updateData({}, setData);

        // init bluesnap
        const getData = () => dataRef.current;
        initBluesnap(
          token,
          () => getBluesnapToken(merchantId),
          setupBluesnapSuccess(merchantId, setData, history, handleErrors, getData),
          setupBluesnapError(setData, handleErrors),
        );
      })
      .catch(err => {
        console.log('### error loading BankAccount/Add', { err });
        notificationError('Page did not load properly. Please refresh.');
      })
      .finally(() => setLoadingStatus(false));
  }, [setData, merchantId, history]);

  const handleSubmit = () => {
    setErrors([]);
    setSavingStatus(true);
    updateDataBeforeSubmission(setData);
  };

  return (
    <Layout title="Add Bank Account" classProps="standard-width-with-sidebar align-left">
      <Errors errors={errors} />

      {data && (
        <Wizard
          data={data}
          setData={setData}
          onSubmit={handleSubmit}
          showAll={showAll}
          steps={[
            {
              title: 'Business Details',
              Component: BusinessDetails,
              helpText:
                'All stores will use this account by default unless you explicitly assign the store to another account.',
            },
            {
              title: 'Company Representative',
              Component: CompanyRep,
            },
            {
              title: 'Ownership',
              Component: Owners,
            },
            {
              title: 'Agreements',
              Component: Agreements,
            },
            {
              title: 'Bank Account',
              Component: AccountInfo,
            },
          ]}
        />
      )}
      {data && <BluesnapFields data={data} />}

      <SecondaryPanel title={['Payments']} current="Bank Accounts" />
    </Layout>
  );
};

AddAccount.propTypes = {
  merchantId: oneOfType([number, string]).isRequired,
};

AddAccount.defaultProps = {};

const mapStateToProps = state => ({
  merchantId: state.auth.merchantId,
});

export default connect(mapStateToProps)(AddAccount);
