import React from 'react';

const Card = props => {
  const arrowFunction = arrowData => {
    const arrowDataCurrent = arrowData.curr;
    const arrowDataPast = arrowData.prev;
    if (arrowDataPast > arrowDataCurrent) {
      return <img className="w-20 h-20" src="https://dashboard-v2-images.s3.amazonaws.com/arrow-square-down.svg" />;
    }
    if (arrowDataPast < arrowDataCurrent) {
      return <img className="w-20 h-20" src="https://dashboard-v2-images.s3.amazonaws.com/arrow-square-up.svg" />;
    }
    if (arrowDataPast == arrowDataCurrent) {
      return <img className="w-20 h-20" src="https://dashboard-v2-images.s3.amazonaws.com/minus-square.svg" />;
    }
    return null;
  };

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <div className="card">
      <div className="dashboardMetrics_card_header_div">
        {props.header}
        {props.arrow ? arrowFunction(props.arrow) : null}
      </div>
      <div className="dashboardMetrics_card_content_div">
        <div className="flex-col-left">
          <span className="card_number">{numberWithCommas(props.numberData.number)}</span>
          <span className="card_subHeader">{props.numberData.subHeader}</span>
          <span className="card_text">{props.numberData.text}</span>
        </div>
        {props.numberData2 ? (
          <div className="flex-col-left m-right-24">
            <span className="card_number">{numberWithCommas(props.numberData2.number)}</span>
            <span className="card_subHeader">{props.numberData2.subHeader}</span>
            <span className="card_text">{props.numberData2.text}</span>
          </div>
        ) : null}
      </div>
      <div className="dashboardMetrics_card_content_div">
        {props.bottomNumber ? (
          <div className="flex-col-left m-top-15">
            <span className="card_number">{numberWithCommas(props.bottomNumber.number)}</span>
            <span className="card_subHeader">{props.bottomNumber.subHeader}</span>
            <span className="card_text">{props.bottomNumber.text}</span>
          </div>
        ) : null}
      </div>
      <div id={props.ring} className="dash-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Card;
