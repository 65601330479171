import React from 'react';
import { Select, Tag } from 'antd';

const tagRender = props => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="blue"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        padding: 7,
      }}
    >
      {label}
    </Tag>
  );
};

const MultiSelect = ({ defaultValue, options, classProps, placeholder, value, onChange, disabled }) => (
  <Select
    className={`dropdown-select ${classProps}`}
    mode="multiple"
    tagRender={tagRender}
    defaultValue={defaultValue}
    placeholder={placeholder}
    value={value}
    filterOption={(input, option) =>
      option.searchable !== 0 && (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())
    }
    style={{
      width: '100%',
    }}
    options={options}
    onChange={onChange}
    disabled={disabled}
  />
);
export default MultiSelect;
