import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { differenceInDays, endOfDay, format, formatISO, isAfter, isBefore, isSameDay, isWithinInterval, startOfDay, subDays } from 'date-fns';
import Report from '../../../../componentsV2/Layout/Report';
import formatNumberAsCurrency from '../../../../utils/formatNumberAsCurrency';
import './styles.scss';
import SalesOppExpandRow from './components/SalesExpandRow';
import useSearchParams from '../../../../hooks/useSearchParams';
import { addLoadingList, removeLoadingList, setLoadingDescription } from '../../../../reducers/notification';
import { useReport } from '../../../../context/ReportContext';
import { convertDaysToMonths } from '../../../../utils/convertDaysToMonths';
import moment from 'moment';

const SalesOpp = ({auth, ...props}) => {


  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dateRange, setDateRange] = useState([]);
  const [filters, setFilters] = useState({
    opportunityType: [],
    store: [],
    associate: [],
  });

  const {setValue: setSearchParams, getAll} = useSearchParams();
  const params = getAll();
  const { salesOpp, isLoadingSalesOpp } = useReport();
  const {opportunityTypes, reportData, overdueDate} = useMemo(() => {
    return {
      opportunityTypes: salesOpp?.opportunityTypes || [],
      reportData: salesOpp?.reportData || [],
      overdueDate: salesOpp?.overdueDate || new Date(),
    }
  }, [salesOpp]);

  useEffect(() => {
    if(isLoadingSalesOpp) {
      setLoadingDescription("Sit tight! We're compiling your report.")
      addLoadingList('salesOppReport')
    } else {
      removeLoadingList('salesOppReport')
      setLoadingDescription(null)
    }
  }, [isLoadingSalesOpp])

  useEffect(() => {
    let paramsFilters = {
      opportunityType: params?.opportunityType ? params?.opportunityType : [],
      store: params?.store ? params?.store : [],
      associate: params?.associate ? params?.associate : [],
    }
    setFilters(paramsFilters)
    if(params?.startDate && params?.endDate) {
      setDateRange([new Date(params.startDate), new Date(params.endDate)])
    } else {
      setDateRange([startOfDay(subDays(new Date(), 90)), endOfDay(new Date())])
    }
  }, [window.location.search])

  const handleOnDateRangeChange = useCallback((dates) => {
    setSearchParams('startDate', formatISO(dates[0]))
    setSearchParams('endDate', formatISO(dates[1]))
  }, []);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const options = useMemo(
    () => [
      {
        title: 'Opportunity Type',
        key: '0-0',
        value: 'opportunityType',
        children: opportunityTypes.map(item => ({
          key: item.id.toString(),
          value: item.id.toString(),
          title: item.name,
        })),
      },
    ],
    [opportunityTypes],
  );

  const getDefaultPeriod = useMemo(() => {
    if(params?.startDate && params?.endDate) {
      if(differenceInDays(new Date(params.endDate), new Date(params.startDate)) === 90) {
        return 'next-90-days'
      } else {
        return `${format(new Date(params.startDate), 'MM/dd/yyyy')} - ${format(new Date(params.endDate), 'MM/dd/yyyy')}`
      }
    } else {
      return 'next-90-days'
    }
  }, [])

  const tableData = useMemo(() => {
    return reportData
      .map(item => {
        const key = item.salesOppId;
        const createdOn = item.salesOppCreatedDate ? new Date(item.salesOppCreatedDate) : '';

        let createdBy = item.associateFirstName || '';

        if (createdBy && item.associateLastName) {
          createdBy += ` ${item.associateLastName}`;
        }

        const client = item.clientName || '';
        const store = item.storeName || '';
        const opportunityType = item.opportunityType || '';
        const expectedClose = item.salesOppCloseDate ? new Date(item.salesOppCloseDate) : '';
        const amount = Number(item.salesOppAmount) || 0;
        const opportunityId = item.opportunityId ? `${item.opportunityId}` : '';
        const storeId = item.storeId ? `${item.storeId}` : '';
        const associateId = item.associateId ? `${item.associateId}` : '';
        const clientId = item.clientId ? `${item.clientId}` : '';

        return {
          key,
          createdOn,
          createdBy,
          client,
          store,
          opportunityType,
          expectedClose,
          amount,
          opportunityId,
          storeId,
          associateId,
          stageWeight: item.stageWeight,
          clientId,
          salesOppId: item.salesOppId,
          stageName: item.stageName,
        };
      })
      .filter(item => {
        if (filters.opportunityType.length && !filters.opportunityType.includes(item.opportunityId)) {
          return false;
        }

        if (filters.store.length && !filters.store.includes(item.storeId)) {
          return false;
        }

        if (filters.associate.length && !filters.associate.includes(item.associateId)) {
          return false;
        }

        if (item.expectedClose && dateRange[0] && dateRange[1]) {
          const start = startOfDay(dateRange[0]);
          const end = endOfDay(dateRange[1]);

          if (!isWithinInterval(item.expectedClose, { start, end })) {
            return false;
          }
        }

        return true;
      });
  }, [reportData, filters, dateRange]);

  const salesOppOverdue = useMemo(() => {
    return tableData.reduce((acc, curr) => {
      if (curr.expectedClose && curr.expectedClose < new Date() && curr.stageWeight !== 1 && curr.stageWeight !== 0) {
        return acc + 1;
      }

      return acc;
    }, 0);
  }, [tableData]);

  const salesOppProjectedRevenue = useMemo(() => {
    return reportData
      .filter(item => {
        let filterAssociate = filters.associate.length === 0 || filters.associate.includes(item.associateId.toString());
        let filterStore = filters.store.length === 0 || filters.store.includes(item.storeId.toString());
        let filterOpportunityType = filters.opportunityType.length === 0 || filters.opportunityType.includes(item.opportunityId.toString());
        let filterTimeSpan = moment(item.salesOppCloseDate).isBetween(moment(dateRange[0]), moment(dateRange[1]));
        return filterAssociate && filterStore && filterOpportunityType && filterTimeSpan;
      })
      .reduce((acc, curr) => {
      if (curr.stageWeight !== 0 && curr.stageWeight !== 1) {
        return acc + parseFloat(curr.salesOppAmount);
      }

      return acc;
    }, 0);
  }, [reportData, filters, dateRange]);

  const salesOppWinRate = useMemo(() => {
    let filtererData = reportData.filter(item => {
      let filterAssociate = filters.associate.length === 0 || filters.associate.includes(item.associateId.toString());
      let filterStore = filters.store.length === 0 || filters.store.includes(item.storeId.toString());
      let filterOpportunityType = filters.opportunityType.length === 0 || filters.opportunityType.includes(item.opportunityId.toString());
      let filterTimeSpan = moment(item.salesOppCloseDate).isBetween(moment(dateRange[0]), moment(dateRange[1]));
      return filterAssociate && filterStore && filterOpportunityType && filterTimeSpan;
    })

    const wonSalesOpp = filtererData.reduce((acc, curr) => {
      if (curr.stageWeight === 1) {
        return acc + 1;
      }
      return acc;
    }, 0);

    const lostSalesOpp = filtererData.reduce((acc, curr) => {
      if (curr.stageWeight === 0) {
        return acc + 1;
      }
      return acc;
    }, 0);

    if (wonSalesOpp + lostSalesOpp === 0) {
      return 0;
    }

    return Math.round(((wonSalesOpp / (wonSalesOpp + lostSalesOpp)) * 100));
  }, [reportData, filters, dateRange]);

  const openOpportunityTypes = useMemo(() => {
    const openOpportunityTypes = tableData.filter(item => item.stageWeight !== 0 && item.stageWeight !== 1);
    return opportunityTypes.filter(opp => {
      const findOpp = openOpportunityTypes.find(item => parseInt(item.opportunityId) === opp.id);
      return findOpp;
    })
  }, [opportunityTypes, tableData])

  const stats = useMemo(() => {
    const days = differenceInDays(new Date(params.endDate), new Date(params.startDate))
    let label = '';
    if(isAfter(new Date(params.endDate), new Date()) && isSameDay(new Date(params.startDate), new Date())) {
      label = ` next ${convertDaysToMonths(days)}`
    } else if (isBefore(new Date(params.endDate), new Date()) && isSameDay(new Date(params.startDate), new Date())) {
      label = ` last ${convertDaysToMonths(days)}`
    } else {
      label = ` during ${convertDaysToMonths(days)}`
    }
    return [
      {
        title: 'Open Opportunities',
        description: `Across ${openOpportunityTypes.length} types, ${salesOppOverdue} overdue`,
        value: tableData.filter(item => item.stageWeight !== 0 && item.stageWeight !== 1).length,
      },
      {
        title: 'Total Pipeline',
        value: formatNumberAsCurrency(salesOppProjectedRevenue),
        description: `${formatNumberAsCurrency(salesOppProjectedRevenue * salesOppWinRate / 100)} weighted pipeline, ${salesOppWinRate}% win rate${label}`,
      },
    ];
  }, [salesOppOverdue, salesOppProjectedRevenue, salesOppWinRate, openOpportunityTypes, tableData]);

  const total = useMemo(() => tableData.length, [tableData]);

  const handleFilterChange = useCallback(value => {
    const updatedFilters = {
      opportunityType: [],
      store: [],
      associate: [],
    };

    value.forEach(item => {
      updatedFilters[item[0]] = [...updatedFilters[item[0]], item[1]];
    });
    Object.keys(updatedFilters).forEach(key => {
      setSearchParams(key, updatedFilters[key].join(','))
    })
  }, []);

  const removeFilter = useCallback(
    (key, value) => {
      const updatedFilters = { ...filters };

      updatedFilters[key] = updatedFilters[key].filter(filter => filter !== value);

      Object.keys(updatedFilters).forEach(key => {
        setSearchParams(key, updatedFilters[key].join(','))
      })
    },
    [filters],
  );

  const graphData = useMemo(() => {
    const data = opportunityTypes.reduce((acc, curr) => {

      acc[curr.name] = [];

      return acc;
    }, {});

    tableData.forEach(item => {
      if(item.stageWeight !== 0 && item.stageWeight !== 1) {
        data[item.opportunityType].push({
          date: item.expectedClose,
          total: 1,
        });
      }
    });

    Object.keys(data).forEach(key => {
      if (!data[key].length) {
        delete data[key];
      }
    });

    return data;
  }, [tableData, opportunityTypes]);

  const expandedRowRender = useCallback(
    record => {
      return (
        <SalesOppExpandRow
          merchantId={auth.merchantId}
          {...record}
        />
      );
    },
    [expandedRowKeys]
  );

  const handleExpandRow = useCallback(
    (expand, record) => {
      if (expand) {
        setExpandedRowKeys([...expandedRowKeys, record.key]);
      } else {
        setExpandedRowKeys([...expandedRowKeys].filter(key => key !== record.key));
      }
    },
    [expandedRowKeys],
  );


  const columns = useMemo(() => {
    const storeColumn = auth.stores.filter(st => st.id !== 0).length > 1 ? [
      {
        key: 'opportunityType',
        dataIndex: 'opportunityType',
        title: 'Opportunity Type',
        sorter: (a, b) => a.opportunityType.localeCompare(b.opportunityType),
      }] : []
    return [
      {
        key: 'expectedClose',
        dataIndex: 'expectedClose',
        title: 'Expected Close',
        sorter: (a, b) => a.expectedClose - b.expectedClose,
        render: item => (item ? format(item, 'MMM dd, yyyy') : ''),
      },
      {
        key: 'createdBy',
        dataIndex: 'createdBy',
        title: 'Owner',
        sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
      },
      {
        key: 'client',
        dataIndex: 'client',
        title: 'Client',
        sorter: (a, b) => a.client.localeCompare(b.client),
        render: (_, row) => (
          <Link to={`/Clients?client=${row.clientId}`}>
            {row.client}
          </Link>
        )
      },
      ...storeColumn,
      {
        key: 'amount',
        dataIndex: 'amount',
        title: 'Amount',
        sorter: (a, b) => a.amount - b.amount,
        render: item => (item ? formatNumberAsCurrency(item) : ''),
      },
    ]
  }, [auth]);

  return (
    <Report
      title="Sales Pipeline"
      CSVFilename="sales-pipeline.csv"
      options={options}
      stats={stats}
      hasCSVExport
      table={tableData.filter(item => item.stageWeight !== 0 && item.stageWeight !== 1)}
      columns={columns}
      filters={filters}
      handleFilterChange={handleFilterChange}
      removeFilter={removeFilter}
      onDateRangeChange={handleOnDateRangeChange}
      dataRange={dateRange}
      defaultPeriodValue={getDefaultPeriod}
      stacked
      typeDateOptions='future-options'
      overdueDate={overdueDate}
      tablesProps={{
        pagination: {
          total,
          page,
          pageSize,
          showSizeChanger: true,
          showTotal: t => `Total ${t} items`,
          onChange: (p, ps) => {
            setPage(p);
            setPageSize(ps);
          },
        },
        expandable: {
          expandedRowKeys,
          onExpand: (expanded, record) => handleExpandRow(expanded, record),
          expandedRowRender,
          expandRowByClick: true
        },
      }}
      graphData={graphData}
      maxTicksLimit={8}
    />
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SalesOpp);
