import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Skeleton } from 'antd';
import StyledLink from '../../StyledLink';

const Associates = props => {
  const clientId = props.client.client_id;
  const { associates } = props.client;

  const associateData = () => {
    if (associates.length > 0) {
      const returnArr = [];
      associates.forEach(assoc => {
        returnArr.push(
          <StyledLink
            styleprops={{ color: '#1769AF' }}
            className="fw-500 fs-12"
            id={assoc.first_name + assoc.last_name}
            to={`/Associates/associate-details/${assoc.id}`}
            key={assoc.id}
          >
            {assoc.first_name} {assoc.last_name}
          </StyledLink>,
          associates.length > 1 ? <span key={'asct_comma'+assoc.id} style={{ marginLeft: '0' }}>, </span> : '',
        );
      });
      return returnArr;
    }

    return <span className="gray fs-12 d-block align-center">No Associates</span>;
  };

  return (
    <div className="align-left" style={{ borderTop: '1px #ebebeb solid' }}>
      <p className="fw-500 gray fs-15 align-left m-btm-5">Associates</p>
      {associateData()}
    </div>
  );
};

const mapStateToProps = state => ({
  client: state.client,
});
export default connect(mapStateToProps)(Associates);
