import React, { useEffect, useState } from 'react';
import { func, string } from 'prop-types';

import PopConfirm from '../../../Layout/PopConfirm';
import { getReminderMessage } from '../apiRequests';

const SendReminder = ({ id, onConfirm, onCancel }) => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    getReminderMessage(id).then(reminderMessage => {
      setMessage(reminderMessage);
    });
  }, [id, setMessage]);

  return (
    <PopConfirm
      confirm="Send Reminder"
      className="pop-confirm"
      confirmButtonText="Send Reminder"
      cancelButtonText="Close"
      customContent
      description={
        <div>
          <p className="align-left fs-12 m-top-20">Send a note to the client:</p>
          <textarea
            className="common_inputStyle w-100-P p-top-8 p-btm-7"
            style={{
              float: 'none',
              height: 88,
            }}
            onChange={({ target }) => setMessage(target.value)}
            value={message}
          />
        </div>
      }
      onConfirm={() => onConfirm(message)}
      onCancel={onCancel}
    />
  );
};

SendReminder.propTypes = {
  id: string,
  onConfirm: func,
  onCancel: func,
};

export default SendReminder;
