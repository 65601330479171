import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Layout from '../../../../componentsV2/Layout/StandardLayoutV2';
import { CSVLink  } from 'react-csv'
import '../reports.css';
import { Button, Card, Statistic, Table } from 'antd-v5';
import { differenceInDays, eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, format, formatISO, isSameDay, isSameMonth, isSameWeek, subDays } from 'date-fns'
import { DownloadOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import ReportSelectDate from '../../../../componentsV2/CustomizedAntDesign/ReportSelectDate';
import ReportFilter from '../../../../componentsV2/CustomizedAntDesign/ReportFilter';
import ReportTagsFilters from '../../../../componentsV2/CustomizedAntDesign/ReportTagsFilters';
import ChartLine from '../../../../componentsV2/Layout/ChartLine';
import usePaginatedRequest from '../../../../hooks/usePaginatedRequest';
import { getGoogleLocationReview, getGoogleReview } from '../../../../api/report';
import StyledLink from '../../../Layout/StyledLink';
import { getRequest } from '../../../../core/apiRequests';

const labelsChart = [
  {
    label: 'Links Sent',
    color: '#5B8FF9'
  },
  {
    label: 'Links Clicked',
    color: '#5AD8A6'
  },
  {
    label: 'Reviews',
    color: '#5D7092'
  }
]

const GoogleReviewStore = props => {
  const [associates, setAssociates] = useState([]);
  const options = useMemo(() => [
    {
      title: 'Delivery Method',
      key: '0-0',
      value: 'deliveryMethod',
      children: [
        {
          key: '0-0-0',
          title: 'Sent by Person',
          value: 'associate'
        },
        {
          key: '0-0-1',
          title: 'Sent by Clientbook',
          value: 'clientbook'
        },
        {
          key: '0-0-2',
          title: 'Sent by Point of Sale',
          value: 'pos'
        },
      ]
    },
    {
      title: 'Store',
      key: '0-1',
      value: 'store',
      children: props.auth.stores.filter(store => store.id !== 0).map(store =>
        (
          {
            title: store.name,
            value: store.id.toString(),
            key: `0-1-${store.id}`
          }
        )
      )
    },
    {
      title: 'Associate',
      key: '0-2',
      value: 'associate',
      children: associates.map(associate => ({
        title: `${associate?.first_name ? `${associate.first_name} ` : ''}${associate?.last_name || ''}`,
        value: associate.id.toString(),
        key: `0-2-${associate.id}`
      }))
    }
  ], [props.auth.stores, associates])
  const [filters, setFilters] = useState({
    deliveryMethod: [],
    store: [],
    associate: []
  });
  const [dateRange, setDateRange] = useState([subDays(new Date(), 90), new Date()]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [pageSizeTable, setPageSizeTable] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if(props.auth.merchantId){
      const reqObj = {
        params: ['clients', props.auth.merchantId, 'tagsAndAssociates'],
      };
      getRequest(reqObj).then(response => {
        setAssociates(response[0].associates);
      })
    }
  }, [props.auth.merchantId])

  const handleFilterChange = useCallback((value) => {
    let updatedFilters = {
      deliveryMethod: [],
      store: [],
      associate: []
    };
    value.forEach(item => {
      if(item[0] && item[1]) {
        updatedFilters[item[0]] = [...updatedFilters[item[0]], item[1]];
      } else {
        updatedFilters[item[0]] = [];
      }
    });
    setFilters(updatedFilters);
  }, [filters])

  const removeFilter = useCallback((key, value) => {
    let updatedFilters = {...filters};
    updatedFilters[key] = updatedFilters[key].filter(filter => filter !== value);
    setFilters(updatedFilters);
  }, [filters]);

  const { results: sentResults, total: sentTotal, setFilters: setFiltersSent, setPageSize: setPageSizeSent } = usePaginatedRequest({
    defaultPageSize: "*", // This is a string to indicate that we want all the results
    defaultFilters: {
      created_at: {
        $between: [formatISO(dateRange[0]), formatISO(dateRange[1])]
      },
    },
    requestMethod: getGoogleReview,
  });

  const { results: clickedResults, total: clickTotal, setFilters: setFiltersClicked, setPageSize: setPageSizeClicked } = usePaginatedRequest({
    defaultPageSize: "*",
    defaultFilters: {
      clicked_at: {
        $notNull: true
      },
      created_at: {
        $between: [formatISO(dateRange[0]), formatISO(dateRange[1])]
      },
    },
    requestMethod: getGoogleReview,
  });

  const { results: locationResults, total: locationTotal, setFilters: setFiltersLocation, setPageSize: setPageSizeLocation } = usePaginatedRequest({
    defaultPageSize: "*",
    defaultFilters: {
      created_at: {
        $and: {
          $gte: dateRange[0].getTime(),
          $lt: dateRange[1].getTime(),
        }
      }
    },
    requestMethod: getGoogleLocationReview,
  });

  const { results: locationRatingTotalResults, total: locationRatingTotalTotal, setFilters: setFiltersLocationRatingTotal, setPageSize: setPageSizeLocationRatingTotal } = usePaginatedRequest({
    defaultPageSize: "*",
    defaultFilters: {
      created_at: {
        $and: {
          $lt: dateRange[1].getTime(),
        }
      }
    },
    requestMethod: getGoogleLocationReview,
  });

  const averageRating = locationResults.reduce((acc, location) => acc + location.rating, 0) / locationResults.length;
  const averageRatingTotal = locationRatingTotalResults.reduce((acc, location) => acc + location.rating, 0) / locationRatingTotalResults.length;
  const ratingVariation = averageRating - averageRatingTotal;

  useEffect(() => {
    let getFiltersStore = {};
    let getFiltersAssociate = {};
    let getFiltersDeliveryMethod = {};
    if(filters.store.length) {
      getFiltersStore = {
        store_id: {
          $in: filters.store
        }
      }
    }
    if(filters.deliveryMethod.length) {
      getFiltersDeliveryMethod = {
        review_request_origin: {
          $in: filters.deliveryMethod
        }
      }
    }
    if(filters.associate.length) {
      getFiltersAssociate = {
        associate_id: {
          $in: filters.associate
        }
      }
    }
    setFiltersSent({
      created_at: {
        $between: [formatISO(dateRange[0]), formatISO(dateRange[1])],
      },
      ...getFiltersStore,
      ...getFiltersAssociate,
      ...getFiltersDeliveryMethod
    })
    setFiltersClicked({
      clicked_at: {
        $notNull: true
      },
      created_at: {
        $between: [formatISO(dateRange[0]), formatISO(dateRange[1])]
      },
      ...getFiltersStore,
      ...getFiltersAssociate,
      ...getFiltersDeliveryMethod
    });
    setFiltersLocation({
      created_at: {
        $and: {
          $gte: dateRange[0].getTime(),
          $lt: dateRange[1].getTime(),
        }
      },
      ...getFiltersStore,
    });
    setFiltersLocationRatingTotal({
      created_at: {
        $and: {
          $lt: dateRange[1].getTime(),
        }
      },
      ...getFiltersStore,
    });
    setPageSizeSent("*");
    setPageSizeClicked("*");
    setPageSizeLocation("*");
    setPageSizeLocationRatingTotal("*");
  }, [dateRange, filters.deliveryMethod, filters.store, filters.associate])

  const labels = useMemo(() => {
    if(!dateRange[0] || !dateRange[1]) return [];
    const difference = differenceInDays(dateRange[1], dateRange[0]);
    if (difference < 13) {
      return eachDayOfInterval({start: dateRange[0], end: dateRange[1]})
    } else if (difference < 120) {
      return eachWeekOfInterval({start: dateRange[0], end: dateRange[1]})
    } else {
      return eachMonthOfInterval({start: dateRange[0], end: dateRange[1]})
    }
  }, [dateRange])

  const data = useMemo(() => {
    let hasReviews = props?.auth?.google_integration ? [{
      label: 'Reviews',
      data: [],
      color: '#5D7092'
    }] : [];
    if(!dateRange[0] || !dateRange[1]) return [
      {
        label: 'Links Sent',
        data: [],
        color: '#5B8FF9'
      },
      {
        label: 'Links Clicked',
        data: [],
        color: '#5AD8A6'
      },
      ...hasReviews
    ];
    const difference = differenceInDays(dateRange[1], dateRange[0]);
    let sentData = [];
    let clickedData = [];
    let locationData = [];
    if (difference < 13) {
      labels?.forEach(label => {
        console.log({label})
        sentData.push(sentResults.reduce((acc, result) => {
          if(isSameDay(new Date(result.created_at), label)) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0))
        clickedData.push(clickedResults.reduce((acc, result) => {
          if(isSameDay(new Date(result.clicked_at), label)) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0))
        if(props?.auth?.google_integration){
          locationData.push(locationResults.reduce((acc, result) => {
            if(isSameDay(new Date(result.created_at), label)) {
              return acc + 1;
            } else {
              return acc;
            }
          }, 0))
        }
      });
    } else if (difference < 120) {
      labels?.forEach(label => {
        sentData.push(sentResults.reduce((acc, result) => {
          if(isSameWeek(new Date(result.created_at), label)) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0))
        clickedData.push(clickedResults.reduce((acc, result) => {
          if(isSameWeek(new Date(result.clicked_at), label)) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0))
        if(props?.auth?.google_integration){
          locationData.push(locationResults.reduce((acc, result) => {
            if(isSameWeek(new Date(result.created_at), label)) {
              return acc + 1;
            } else {
              return acc;
            }
          }, 0))
        }
      });
    } else {
      labels?.forEach(label => {
        sentData.push(sentResults.reduce((acc, result) => {
          if(isSameMonth(new Date(result.created_at), label)) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0))
        clickedData.push(clickedResults.reduce((acc, result) => {
          if(isSameMonth(new Date(result.clicked_at), label)) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0))
        if(props?.auth?.google_integration) {
          locationData.push(locationResults.reduce((acc, result) => {
            if(isSameMonth(new Date(result.created_at), label)) {
              return acc + 1;
            } else {
              return acc;
            }
          }, 0))
        }
      })
    }
    let hasReviewsData = props?.auth?.google_integration ? [{
      label: 'Reviews',
      data: locationData,
      color: '#5D7092'
    }] : [];
    return [
      {
        label: 'Links Sent',
        data: sentData,
        color: '#5B8FF9'
      },
      {
        label: 'Links Clicked',
        data: clickedData,
        color: '#5AD8A6'
      },
      ...hasReviewsData
    ]
  }, [dateRange, sentResults, locationResults, clickedResults, props?.auth?.google_integration]);

  const columns = useMemo(() => [
    {
      title: 'Sender',
      dataIndex: 'sender',
      key: 'sender',
      sorter: (a, b) => a.sender.localeCompare(b.sender),
    },
    {
      title: 'Sent',
      dataIndex: 'sent',
      key: 'sent',
      sorter: (a, b) => a.sent - b.sent,
    },
    {
      title: 'Clicked',
      dataIndex: 'clicked',
      key: 'clicked',
      sorter: (a, b) => a.clicked - b.clicked,
    },
    {
      title: 'Reviews',
      dataIndex: 'reviews',
      key: 'reviews',
      sorter: (a, b) => a.reviews - b.reviews,
    },
  ], [])

  const table = useMemo(() => {
    let stores = filters.store.length ? props.auth.stores.filter(store => filters.store.includes(store.id.toString())) : props.auth.stores;
    let dataTable = stores.filter(st => st.id !== 0).map(store =>
      ({
        key: store.id,
        store_id: store.id,
        sender: store.name,
        sent: 0,
        clicked: 0,
        reviews: locationResults?.reduce((acc, location) => {
          if(location.store_id === store.id) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0) || 0
      }));
    sentResults.forEach(result => {
      const findIndex = dataTable.findIndex(data => data.store_id === result.store_id)
      if(findIndex > -1) {
        dataTable[findIndex].sent += 1;
        if(result.clicked_at) {
          dataTable[findIndex].clicked += 1;
        }
      }
    });
    return dataTable.slice((page - 1) * pageSizeTable, page * pageSizeTable);
  }, [sentResults, locationResults, page, pageSizeTable, props.auth.stores, filters.store]);

  const expandedRowRender = useCallback((record) => {
    const columns = [
      {
        title: 'Client',
        dataIndex: 'client',
        key: 'client_id',
        render: (_, row) => (
          <StyledLink to={`/Clients?client=${row.client_id}`}>
            {row.client}
          </StyledLink>
        )
      },
      { title: 'Sent by', dataIndex: 'associate', key: 'associate_id' },
      {
        title: 'Sent',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (_, row) => row.created_at ? format(new Date(row.created_at), 'MMM dd, yyyy') : '-'
      },
      {
        title: 'Clicked',
        dataIndex: 'clicked_at',
        key: 'clicked_at',
        render: (_, row) => row.clicked_at ? format(new Date(row.clicked_at), 'MMM dd, yyyy') : '-'
      },
    ];

    const data = [];
    if(record.store_id) {
      sentResults?.forEach(result => {
        if(result.store_id === record.store_id) {
          data.push({
            key: result.id,
            client: result.client,
            client_id: result.client_id,
            associate: result.associate,
            associate_id: result.associate_id,
            created_at: result.created_at,
            clicked_at: result.clicked_at
          });
        }
      });
    }
    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          total: data.length,
        }}
      />
    );
  }, [sentResults]);

  const handleExpandRow = useCallback((expand, record) => {
    let updatedExpandedRowKeys = [...expandedRowKeys];
    if(expand) {
      updatedExpandedRowKeys.push(record.store_id);
      setExpandedRowKeys(updatedExpandedRowKeys);
    } else {
      updatedExpandedRowKeys = updatedExpandedRowKeys.filter(key => key !== record.store_id);
      setExpandedRowKeys(updatedExpandedRowKeys);
    }
  }, [expandedRowKeys])

  return (
    <Layout
      title="Google Reviews"
      classProps="flex-col-left "
      styleProps={{ justifyContent: 'flex-start' }}
      headerStyles={{ paddingLeft: '25px'}}
      breadcrumb={[
        {
          href: '/Home',
          title: 'Reports'
        },
        {
          title: 'Google Reviews'
        }
      ]}
    >
      <div className='flex-row-spacebetween-nowrap w-100-P'>
        <div className='flex-row-center' style={{ gap: '16px'}}>
          <ReportSelectDate
            onChange={(value) => setDateRange(value)}
          />
          <ReportFilter
            options={options}
            handleFilterChange={handleFilterChange}
            filters={filters}
          />
        </div>
        <CSVLink
          data={table}
          headers={[
            {
              label: 'Sender',
              key: 'sender'
            },
            {
              label: 'Sent',
              key: 'sent'
            },
            {
              label: 'Clicked',
              key: 'clicked'
            },
            {
              label: 'Reviews',
              key: 'reviews'
            }
          ]}
          filename="google-reviews.csv"
        >
          <Button>
            <DownloadOutlined />
            Export
          </Button>
        </CSVLink>
      </div>
      <ReportTagsFilters
        options={options}
        filterSelected={filters}
        removeFilter={removeFilter}
      />
      <div className="flex-row-spacebetween-nowrap w-100-P m-top-35" style={{ gap: '16px' }}>
        <Card bordered={false} className='w-100-P flex-row-center'>
          <Statistic
            title="Sent"
            value={sentTotal}
            precision={0}
            className='ff-poppins'
            valueStyle={{ fontWeight: 700}}
          />
        </Card>
        <Card bordered={false} className='w-100-P flex-row-center'>
          <Statistic
            title="Clicked"
            value={clickTotal}
            precision={0}
            className='ff-poppins'
            valueStyle={{ fontWeight: 700}}
          />
        </Card>
        {props?.auth?.google_integration && (
          <>
            <Card bordered={false} className='w-100-P flex-row-center'>
              <Statistic
                title="Reviews"
                value={locationTotal}
                precision={0}
                className='ff-poppins'
                valueStyle={{ fontWeight: 700}}
                prefix={locationTotal > 0 && <ArrowUpOutlined style={{opacity: 0.45}} />}
              />
            </Card>
            <Card bordered={false} className='w-100-P flex-row-center'>
              <Statistic
                title="Avg Rating"
                value={averageRating || 0}
                precision={1}
                className='ff-poppins'
                valueStyle={{ fontWeight: 700}}
                prefix={ratingVariation >= 0 ? <ArrowUpOutlined style={{opacity: 0.45}} /> : <ArrowDownOutlined style={{opacity: 0.45}} />}
              />
            </Card>
          </>
        )}
      </div>
      <div style={{ minHeight: '200px', mmaxHeight: '400px', marginTop: '25px' }}>
        <Card bordered={false} className='w-100-P'>
          <ChartLine
            configObj={{
              type: 'line',
              data: {
                labels: labels.map(label => format(label, 'MM/dd/yy')),
                datasets: data.map(({label, data, color}) => ({
                  label,
                  data,
                  fill: false,
                  borderColor: color,
                  borderDashOffset: 0.0,
                  pointHoverRadius: 0,
                  pointRadius: 0,
                }))
              },
              options: {
                maintainAspectRatio: false,
                legend: {
                  display: false,
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        padding: 12
                      },
                      gridLines: {
                        display: true,
                        drawTicks: false
                      },
                      title: {
                        align: 'start'
                      }
                    },
                  ],
                  xAxes: [
                    {
                      gridLines: {
                        display: true,
                      },
                    },
                  ],
                },
              },
            }}
          />
          <div className='flex-row-center w-100-P' style={{ gap: '16px' }}>
            {labelsChart.map(item => (
              <div className='flex-row-center m-top-8' style={{ color: "#8C8C8C" }}>
                <div style={{ width: '12px', height: '2px', backgroundColor: item.color, marginRight: '5px'}}></div>
                <span>{item.label}</span>
              </div>
            ))}
          </div>
        </Card>
      </div>
      <Card style={{ marginTop: '25px' }}>
        <Table
          columns={columns}
          dataSource={table}
          pagination={{
            total: props.auth.stores.length - 1,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            onChange: (page, pageSize) => {
              setPage(page);
              setPageSizeTable(pageSize)
            },
            pageSize: pageSizeTable,

          }}
          expandable={{
            expandedRowRender: (record) => expandedRowRender(record),
            defaultExpandedRowKeys: ['0'],
            rowExpandable: record => record.sent > 0,
            onExpand: (expanded, record) => handleExpandRow(expanded, record),
            expandedRowKeys,
          }}
        />
      </Card>
    </Layout>
  );
};

const mapStateToProps = state => ({
  reports: state.reports,
  auth: state.auth,
  stores: state.stores,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleReviewStore);
