import React from 'react';
import { Link } from 'react-router-dom';

const Clover = () => {
  return (
    <>
      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Overview</h3>

        <p>
          Connect your Clover systems with Clientbook. This connection will seamlessly merge your business&apos;s Clover
          data into Clientbook Dashboard and Mobile App. This includes your list of clients, products and sales
          transactions. Please be sure the Clover user has the rights to access all relevant information. If
          Clover&apos;s user information is removed from Clover&apos;s system, the connected account will be disabled.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Key Features</h3>

        <p>By adding your Clover information to Clientbook, you can:</p>

        <ul className="cb-integrations-details__list">
          <li>
            Sync your list of clients to send automated messages, request payments and track specific life events to
            maximize POS activity.
          </li>

          <li>
            Attach you associated to document sales opportunities and intensify your sales team&apos;s efficiency.
          </li>

          <li>
            Connect your products to record sales triggers and transaction history to effectively document your sales in
            a clear, easy-to-use platform.
          </li>

          <li>
            Maximize communication with your clients by syncing documented Clover sales transactions to trigger
            automated messsages, life events and client activities.
          </li>
        </ul>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Helpful Links</h3>

        <ul className="cb-integrations-details__list">
          <li>
            <Link to="/Help/contact-support">Clientbook Help Center</Link>
          </li>
        </ul>
      </section>
    </>
  );
};

export default Clover;
