export const headers = [
  { label: 'Client Name', key: 'name' },
  { label: 'Email', key: 'email' },
  { label: 'Mobile', key: 'mobile' },
  { label: 'Birthday', key: 'birthday' },
  { label: 'Anniversary', key: 'anniversary' },
  { label: 'PosRefNum', key: 'pos_ref_num' },
  { label: 'Address 1', key: 'address_1' },
  { label: 'City', key: 'city' },
  { label: 'Address 2', key: 'address_2' },
  { label: 'Postal Code', key: 'postal_code' },
  { label: 'State', key: 'state' },
  { label: 'Associates', key: 'associates' },
  { label: 'Tags', key: 'tags' },
  { label: 'Opted Out', key: 'opted_out' },
];
