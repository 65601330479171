import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NewPopConfirm from '../../../../../../../Layout/NewPopConfirm';
import './styles.css';

const GooglePopConfirm = ({ visible, authUrl }) => {
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    let counterInterval;

    if (visible && counter > 0) {
      counterInterval = setInterval(() => {
        setCounter(prevCounter => prevCounter - 1);
      }, 1000);
    }

    return () => {
      if (counterInterval) {
        clearInterval(counterInterval);
      }
    };
  }, [visible, counter]);

  return (
    visible && (
      <NewPopConfirm title={`You're being redirected in ${counter} seconds`}>
        <p>After completion, please return to this page and refresh.</p>

        <p>
          If you are not redirected, click{' '}
          <a href={authUrl} target="_blank" rel="noopener noreferrer" className="cb-integrations-pop-confirm__link">
            here
          </a>
          .
        </p>
      </NewPopConfirm>
    )
  );
};

const mapStateToProps = state => ({
  uuid: state.auth.uuid,
  pos_type: state.auth.pos_type,
});

export default connect(mapStateToProps, {})(GooglePopConfirm);
