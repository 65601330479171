import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import colors from 'nice-color-palettes';
import { CSVLink } from 'react-csv';
import Table from '../../../Layout/Table';
import Chart from '../../../Layout/Chart';
import Layout from '../../../Layout/StandardLayout';
import Button from '../../../Layout/Button';
import TitleDescription from '../../../Layout/DescriptionBox';
import { setReminderIntv } from '../../../../reducers/reports';
import { setAuthStoreId } from '../../../../reducers/auth';
import { getRequest } from '../../../../core/apiRequests';
import DropdownSelect from '../../../Layout/DropdownSelect';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import RangePicker from '../../../CustomizedAntDesign/RangePicker';
import '../reports.css';

const disabledDate = current => {
  return current && current > moment().endOf('day');
};

const AmountPaid = props => {
  const [dateRange, setDateRange] = useState([moment().subtract(1, 'months'), moment()]);
  const [tableDef, setTableDef] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [chartConfigObj, setChartConfigObj] = useState({});

  const CC_Colors = [
    ['#fc8333', '#feb32c', '#7fbd31', '#009470', '#1769af'],
    ['#803e9f', '#fd0062', '#fe99c0', '#a50087', '#55a4e7'],
    ['#6ee5ea', '#ff6363', '#9ddfff', '#ffd495', '#dcff66'],
    ['#0eb3ba'],
  ];

  const clientContactColors = CC_Colors.concat(colors);

  const runReport = e => {
    e.preventDefault();

    const reportEnd = moment(dateRange[1]).endOf('day').toISOString();
    const reportStart = moment(dateRange[0]).startOf('day').toISOString();

    const reqObj = {
      params: ['report', props.auth.merchantId, 'amountPaid'],
      query: {
        licenseKey: props.auth.licenseKey,
        interval: props.reports.reminderIntv,
        startDt: reportStart,
        endDt: reportEnd,
        storeAccess: props.auth.stores.filter(st => st.id !== 0).map(st => st.id),
      },
    };

    if (props.auth.storeId) {
      reqObj.query.storeId = props.auth.storeId;
    }
    (async () => {
      const res = {};
      res.data = await getRequest(reqObj);
      const tempTableHeaders = [
        {
          keyName: 'date',
          style: { width: '5%', paddingTop: '80px', paddingRight: '10px' },
          headerClassName: 'row4',
          title: '',
          sortable: false,
        },
      ];
      const amountPaid = { date: 'Amount Paid' };
      const labels = [];
      let currentDate = moment(dateRange[0]).startOf('day');
      let notTheRealEndDate = moment(dateRange[1]);

      if (res.data.interval === 'monthly') {
        while (currentDate.isBefore(notTheRealEndDate)) {
          labels.push(currentDate.format('MM/YYYY'));
          amountPaid[currentDate.format('MM/YYYY')] = 0;
          tempTableHeaders.push({
            keyName: currentDate.format('MM/YYYY'),
            style: { width: '5%', paddingTop: '80px', paddingRight: '10px' },
            headerClassName: 'row4',
            title: currentDate.format('MM/YYYY'),
            sortable: false,
            render: t => {
              return `$${t}`;
            },
          });
          currentDate = currentDate.add(1, 'M').startOf('month');
        }
      } else if (res.data.interval === 'daily') {
        while (currentDate.isBefore(notTheRealEndDate)) {
          labels.push(currentDate.format('MM/DD/YYYY'));
          amountPaid[currentDate.format('MM/DD/YYYY')] = 0;
          tempTableHeaders.push({
            keyName: currentDate.format('MM/DD/YYYY'),
            style: { width: '5%', paddingTop: '80px', paddingRight: '10px' },
            headerClassName: 'row4',
            title: currentDate.format('MM/DD/YY'),
            sortable: false,
            render: t => {
              return `$${t}`;
            },
          });
          currentDate = currentDate.add(1, 'days');
        }
      } else {
        notTheRealEndDate = notTheRealEndDate.endOf('week').add(1, 'days');
        while (currentDate.isBefore(notTheRealEndDate)) {
          labels.push(currentDate.startOf('week').format('MM/DD'));
          amountPaid[currentDate.startOf('week').format('MM/DD')] = 0;
          tempTableHeaders.push({
            keyName: currentDate.startOf('week').format('MM/DD'),
            style: { width: '5%', paddingTop: '80px', paddingRight: '10px' },
            headerClassName: 'row4',
            title: currentDate.startOf('week').format('MM/DD'),
            sortable: false,
            render: t => {
              return `$${t}`;
            },
          });
          currentDate = currentDate.add(7, 'days').startOf('week');
        }
      }

      res.data.results.forEach(result => {
        if (res.data.interval === 'monthly') {
          if (result.ts_month) {
            const monthLetter = `${moment(result.ts_month, 'M').format('MM')}/${moment(result.ts_year, 'YYYY').format(
              'YYYY',
            )}`;
            amountPaid[monthLetter] = result.total;
          }
        } else if (res.data.interval === 'daily') {
          if (result.ts_day) {
            const dayLetter = `${moment(result.ts_month, 'M').format('MM')}/${moment(result.ts_day, 'D').format(
              'DD',
            )}/${moment(result.ts_year, 'YYYY').format('YYYY')}`;
            amountPaid[dayLetter] = result.total;
          }
        } else if (result.week_start) {
          const weekLetter = moment(result.week_start, 'MM/D').format('MM/DD');

          amountPaid[weekLetter] = result.total;
        }
      });

      setDownloadData([amountPaid]);
      setTableHeaders(tempTableHeaders);
      setTableDef([amountPaid]);

      const graphObj = { amountPaid: [] };
      for (const [key, value] of Object.entries(amountPaid)) {
        if (key !== 'date') {
          graphObj.amountPaid.push(value);
        }
      }

      const datasets = [];

      Object.keys(graphObj).forEach(key => {
        const paletteIdx = Math.trunc(datasets.length / 5);
        const colorIdx = datasets.length % 5;

        if (key !== 'date') {
          datasets.push({
            label: key,
            data: graphObj[key],
            fill: false,
            borderColor: clientContactColors[paletteIdx][colorIdx],
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: clientContactColors[paletteIdx][colorIdx],
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: clientContactColors[paletteIdx][colorIdx],
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
          });
        }
      });

      setChartConfigObj({
        type: 'line',
        data: {
          labels,
          datasets,
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
      });
    })();
    setShowReport(true);
  };

  return (
    <Layout
      title="Amount Paid"
      backArrow="/Home"
      description="Set your parameters and click on 'Run Report.'"
      classProps="standard-width-with-sidebar flex-col-center"
      styleProps={{ justifyContent: 'flex-start' }}
    >
      <div className="report-smaller-description">
        Amount Paid reports measure the amount of money paid to your store through payment requests. You can select
        whether you want this report to be based on a daily, weekly or monthly basis.{' '}
      </div>

      <div className="pos-rel w-100-P " style={{ maxWidth: '866px' }}>
        <TitleDescription
          title="Reporting Location"
          description="What store location would you like to run a report on?"
          input={
            <DropdownSelect
              isSearchable
              classProps="mq-w-200 w-267 align-left"
              options={props.auth.stores.map(store => ({
                label: store.name,
                value: store.id,
              }))}
              placeholder={`'All Stores' + (${props.auth.stores.length}) `}
              value={props.auth.storeId}
              onChange={obj => {
                setAuthStoreId(obj.value);
              }}
            />
          }
        />
        <TitleDescription
          title="Frequency"
          description="Want to run a report based on month, week, or day?"
          input={
            <DropdownSelect
              classProps="mq-w-200 w-267 align-left"
              options={[
                { label: 'Days', value: 'daily' },
                { label: 'Weeks', value: 'weekly' },
                { label: 'Months', value: 'monthly' },
              ]}
              placeholder="Select time interval..."
              value={props.reports.reminderIntv}
              onChange={obj => {
                setReminderIntv(obj.value);
              }}
            />
          }
        />

        <TitleDescription
          title="Date Range"
          input={
            <RangePicker
              format="MM/DD/YYYY"
              disabledDate={disabledDate}
              value={dateRange}
              onChange={setDateRange}
              ranges={{
                'Last 7 Days': [moment().subtract(7, 'd'), moment()],
                'Last 30 Days': [moment().subtract(30, 'd'), moment()],
                'Last 60 Days': [moment().subtract(60, 'd'), moment()],
                'Last 90 Days': [moment().subtract(90, 'd'), moment()],
              }}
              showToday
            />
          }
          className="report-date-picker"
        />

        <div className="report-asct-msg-row" style={{ maxWidth: '866px' }}>
          <Button id="RunAmountPaidReport" classname="darkBlueButton" onclick={e => runReport(e)}>
            Run Report
          </Button>
        </div>
      </div>
      {showReport ? (
        <div className="w-100-P">
          <div
            className="w-100-P maw-1200"
            style={{
              border: 'solid 1.0px #979797',
              borderRadius: '8px',
              width: '100%',
              position: 'relative',
            }}
          >
            <div>
              <h5 style={{ textAlign: 'center' }}>Amount Paid</h5>
            </div>
            <div style={{ minHeight: '200px' }}>
              <Chart configObj={chartConfigObj} />
            </div>
          </div>
          <div
            className="import_export_group"
            style={{
              justifyContent: 'flex-end',
              maxWidth: '1105px',
              zIndex: '100',
              marginTop: '100px',
              marginRight: '30px',
            }}
          >
            <img src="https://dashboard-v2-images.s3.amazonaws.com/ic-export.svg" alt="#" />
            <CSVLink
              data={downloadData}
              filename="AmountPaid.csv"
              id="DownloadAmountPaidReport"
              className="exportButton m-right-15"
            >
              Download
            </CSVLink>
          </div>
          <Table
            tableClassName="maw-1200 rem-pad-width m-top-15 overflowX hide-scrollbar w-100-P"
            tableContainerClass="w-100-P rem-pad-width"
            style={{ marginTop: '-100px', width: '100%' }}
            data={tableDef}
            tableRowClassName=""
            tableHeaders={tableHeaders}
            tableName="AMOUNT_PAID"
          />
        </div>
      ) : null}
      <SecondaryPanel
        overhead_title="Reports"
        title={['CLIENTELING', 'MESSAGING', 'Google Reviews', 'PAYMENTS', 'AUTOMATION', 'SALES OPPORTUNITY']}
        current="Amount Paid"
      />
    </Layout>
  );
};

const mapStateToProps = state => ({
  reports: state.reports,
  auth: state.auth,
  stores: state.stores,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AmountPaid);
