/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Badge, Button, Input } from 'antd-v5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScroll from 'react-infinite-scroll-component';
import useDebounce from '../../../../../hooks/useDebounce';
import ProductsV2Card from '../Card';
import ProductFilters from '../../../../../componentsV2/Products/Filters';
import './styles.scss';

const ProductsV2List = props => {
  const {
    paymentBanner,
    title,
    productsLoading,
    productsSearch,
    setProductsPage,
    productsPage,
    setProductsSearch,
    productsTotal,
    products,
    setSelectedProductId,
    isVendorSelected,
    selectedBrands,
    setSelectedBrands,
    selectedCategories,
    setSelectedCategories,
    auth
  } = props;

  const [search, setSearch] = useState(productsSearch);

  const debouncedSearch = useDebounce(search);

  const [showFilters, setShowFilters] = useState(false);

  const handleChangeSearch = useCallback(e => {
    setSearch(e.target.value);
  }, []);

  const isImage = useMemo(() => title.startsWith('https'), [title]);

  useEffect(() => {
    setProductsSearch(debouncedSearch);
  }, [debouncedSearch]);

  const ListComponent = useMemo(() => {
    if (productsLoading && !products.length) {
      return <p style={{ textAlign: 'center' }}>Loading...</p>;
    }

    if (!productsLoading && !products.length) {
      return <p style={{ textAlign: 'center' }}>No results</p>;
    }

    return products.map(item => (
      <ProductsV2Card
        key={item.id}
        title={item.name}
        image={item.image}
        description={isVendorSelected ? item.mpn || '' : item.sku || ''}
        onClick={() => setSelectedProductId(item.id)}
      />
    ));
  }, [products, productsLoading, isVendorSelected]);

  useEffect(() => {
    if (!productsLoading) {
      document.getElementById('search-product-input').focus();
    }
  }, [productsLoading]);

  return (
    <div className="products-v2-list">
      <div className="products-v2-list__header">
        {isImage ? (
          <img src={title} alt="vendor" className="products-v2-list__logo" />
        ) : (
          <h2 className="products-v2-list__title">{title}</h2>
        )}
      </div>

      <Input.Group compact style={{ display: 'flex' }} className="products-v2-list__search">
        <Input
          id="search-product-input"
          size="large"
          prefix={<SearchOutlined style={{ color: '#737373' }} />}
          placeholder="Search for a product"
          onChange={handleChangeSearch}
          disabled={productsLoading}
          style={{ flex: '1' }}
        />

        {!isVendorSelected ? (
          <ProductFilters
            open={showFilters}
            setOpen={setShowFilters}
            brands={selectedBrands}
            setBrands={setSelectedBrands}
            categories={selectedCategories}
            setCategories={setSelectedCategories}
            showCategories={!!auth?.pos_type}
          >
            <Button
              size="large"
              icon={
                productsLoading ? (
                  <LoadingOutlined />
                ) : (
                  <Badge dot={selectedBrands.length || selectedCategories.length} offset={[0, 12]}>
                    <FontAwesomeIcon icon="fa-sharp fa-solid fa-bars-filter" color="#737373" />
                  </Badge>
                )
              }
              onClick={() => setShowFilters(!showFilters)}
              style={{ borderRadius: '0 4px 4px 0' }}
            />
          </ProductFilters>
        ) : null}
      </Input.Group>

      <InfiniteScroll
        dataLength={products.length}
        next={() => setProductsPage(productsPage + 1)}
        hasMore={products.length < productsTotal}
        loader={productsLoading ? <p style={{ textAlign: 'center' }}>Loading...</p> : null}
        height={paymentBanner ? 'calc(100vh - 230px)' : 'calc(100vh - 190px)'}
      >
        {ListComponent}
      </InfiniteScroll>
    </div>
  );
};

const mapStateToProps = state => ({
  paymentBanner: state.paymentBanner,
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsV2List);
