import React, { useState, useEffect } from 'react';
import StyledLink from '../StyledLink';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Button from '../Button';
import { notificationError } from '../../../core/actions';

const BorderlessTable = props => {
  const [dataList, setDataList] = useState([]);

  useEffect(() => {}, [props.auth.merchantId]);

  const descriptionSnippet = desc => {
    if (desc && desc.length > 20) {
      return `${desc.substr(0, 20)}...`;
    }
    return desc;
  };

  const tableRowBuilder = (item, rowKey) => {
    let rowArr = [];
    props.tableHeaders.forEach((column, colKey) => {
      let content;
      if (column.render) {
        content = (
          <td key={`td${rowKey}${colKey}`} className={`${column.rowClassName}`}>
            {/* {descriptionSnippet(item.description)} /////// I THINK this is broken or I'm just not sure what it does in general */}
            {column.render(item[column.keyName], item)}
          </td>
        );
      } else {
        content = (
          <td key={`td${rowKey}${colKey}`} className={`${column.rowClassName}`}>
            {item[column.keyName]}
          </td>
        );
      }
      rowArr.push(content);
    });
    return rowArr;
  };

  const tableHeaders = ['Description', 'Type', 'Schedule'];
  if (props.type !== 'lifeEvent') {
    tableHeaders.push('Repeats');
  }

  return (
    <div
      className={
        // component has ability to override existing class.
        props.tableContainerClass ? `${props.tableContainerClass}` : 'rem-pad-width'
      }
    >
      {props.title || props.linkButton ? (
        <div className="flex-row-spacebetween-wrap m-btm-20 m-top-30">
          <span className="fs-16 fw-500 p-top-10">{props.title}</span>
          {props.linkButton ? (
            <div>
              <StyledLink to={props.styledLinkTo}>
                {' '}
                {/* prop for path */}
                <Button
                  classname={props.buttonClass ? ` ${props.buttonClass}` : 'darkBlueButton'}
                  onclick={props.onButtonClick}
                >
                  {props.awesome} {/* font awesome prop */} {props.buttonText}
                </Button>
              </StyledLink>
            </div>
          ) : null}
        </div>
      ) : null}
      {props.data.length > 0 ? (
        <table className="real-small-table w-100-P">
          <thead className={props.classname ? props.classname : 'table-head'}>
            <tr>
              {props.tableHeaders.map((col, i) => {
                return (
                  <th
                    key={`th${i}`}
                    className={`darkest-gray fs-12 fw-600 border-btm-l-gray ${col.headerClassName}`}
                    // className={col.headerClassName}
                    style={col.style}
                  >
                    {col.title}{' '}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="table-row">
            {props.data.map(function (item, key) {
              return (
                <tr
                  style={item.cellStyle}
                  className="body-row pointer fs-12 border-btm-l-gray"
                  key={`tr${key}`}
                  onClick={e => (props.onRowClick ? props.onRowClick(item, e) : console.debug('No row click feature'))}
                >
                  {tableRowBuilder(item, key)}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <span className="body-row flex-col-center text-light-gray">No Data Available</span>
      )}
    </div>
  );
};
const mapStateToProps = state => ({ auth: state.auth });
export default connect(mapStateToProps, {})(BorderlessTable);
