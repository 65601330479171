import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRequest } from '../../../../core/apiRequests';
import { setAuthStores, setAuthStoreId } from '../../../../reducers/auth';
import DropdownSelect from '../../DropdownSelect';

const StoreMenu = props => {
  const history = useHistory();

  useEffect(() => {
    if (props.merchantId && props.stores.length < 2) {
      const allStores = props.role === 'ADMIN' ? true : false;
      const allOption = { id: 0, name: 'All Stores' };
      (async () => {
        const reqObj = {
          params: ['merchant', props.merchantId, 'stores', props.userId],
          query: {
            allStores,
          },
        };
        const data = await getRequest(reqObj);
        if (data && data.length > 0) {
          const sortStores = data.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          });
          setAuthStores([allOption, ...sortStores]);
          setAuthStoreId(0);
        }
      })();
    }
  }, [props.merchantId]);

  useEffect(() => {
    if (props.storeState.id) {
      if (
        window.location.pathname.toLowerCase().includes('stores/edit-store') ||
        window.location.pathname.toLowerCase().includes('stores/store-dashboard')
      ) {
        if (Number(props.storeState.id) && Number(props.storeState.id) !== Number(props.storeId)) {
          setAuthStoreId(Number(props.storeState.id));
        }
      }
    }
  }, [props.storeState.id]);

  const storeChangeHandler = val => {
    if (window.location.pathname.toLowerCase().includes('stores/edit-store')) {
      if (Number(props.storeState.id) !== Number(val)) {
        history.push(`/Stores/edit-store/${val}`);
      }
    }
    if (window.location.pathname.toLowerCase().includes('stores/store-dashboard')) {
      if (Number(props.storeState.id) !== Number(val)) {
        history.push(`/Stores/store-dashboard/${val}`);
      }
    }
    setAuthStoreId(val);
  };

  return (
    <div className="store-menu-input pointer">
      {props.stores.length > 0 ? (
        <DropdownSelect
          classProps={'mq-w-200 w-267 align-left'}
          options={props.stores.map((st, i) => ({
            label: st.name,
            value: st.id,
          }))}
          // placeholder=""
          value={props.storeId}
          onChange={obj => {
            storeChangeHandler(obj.value);
          }}
          placeholder="Select a Store..."
        />
      ) : (
        <div className="w-267 inputHeight rem-pad-width common_inputStyle align-left p-top-10">
          <span className="gray fs-14">No Store Access</span>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = state => ({ ...state.auth, storeState: state.stores });
export default connect(mapStateToProps, {})(StoreMenu);
