export function convertDaysToMonths(days) {
  if(days < 30) {
    return `${days} days`
  } else if (days >= 30 && days < 365) {
    return `${Math.floor(days / 30)} month${Math.floor(days / 30) > 1 ? 's' : ''} ${days % 30 !== 0 ? `${days % 30} days` : ''}`
  } else if (days >= 365 && days <= 395) {
    let years = Math.floor(days / 365);
    return `${years} year${years > 1 ? 's' : ''} ${days % 365 !== 0 ? `${days % 365} days` : ''}`
  } else if (days > 395) {
    let years = Math.floor(days / 365);
    let months = Math.floor((days % 365) / 30) === 0
    ? Math.floor((days % 365)) <= 20 ? 0 : 1
    : Math.floor((days % 365) / 30) + (Math.floor((days % 365) % 30) <= 20 ? 0 : 1);
    return `${years} year${years > 1 ? 's' : ''} ${months !== 0 ? `${months} month${months > 1 ? 's' : ''}` : ''}`
  }
  return `${days} days`;
}
