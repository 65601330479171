import React, { useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notificationError, notificationSuccess } from '../../../../core/actions';
import { API_URL } from '../../../../constants';

const ResetPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');

  const sendPwReq = () => {
    const validEmail = emailValidation(email);
    if (emailValidation(email)) {
      Axios.post(`${API_URL}/resetPassword?email=${email.toLowerCase()}`)
        .then(resp => {
          notificationSuccess('Reset Password Email Sent');
          history.push('/Login');
          // setRedirect(true)
        })
        .catch(err => {
          console.error('[ResetPassword] Error in ResetPassword request: ', err);
          if (err.response && err.response.data && err.response.data.message) {
            // notificationError(err.response.data.message);
          }
        });
    }
  };

  const emailValidation = email => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    notificationError('Enter valid email address!');
    return false;
  };

  return (
    <div className="pw-reset-background align-vert-middle flex-col-center">
      <div className="flex-col-center bg-white w-100-P h-100-P max-w-684 max-h-603">
        <span className="fs-28 fw-500">Reset Password</span>
        <span className="max-w-438 m-btm-28 p-left-20 p-right-21">
          Enter your email address and we'll send you instructions to reset your password
        </span>
        <input
          value={email}
          placeholder="Email Address"
          onChange={e => setEmail(e.target.value)}
          className="pw-reset-input inputHeight rem-pad-width w-402 m-btm-28"
        />
        <div className="w-402 flex-row-spacebetween-wrap maw-90-P  p-left-20 p-right-21">
          <button
            style={{ width: 'calc(50% - 8px)' }}
            onClick={() => history.push('/Login')}
            className="cancel-button m-right-8"
          >
            CANCEL
          </button>
          <button style={{ width: 'calc(50% - 8px)' }} onClick={sendPwReq} className="save-button m-left-8">
            SEND ME AN EMAIL
          </button>
        </div>
      </div>
      {/* {redirect ? <Redirect to="/Login" /> : null} */}
    </div>
  );
};
export default ResetPassword;
