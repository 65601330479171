import store from '../core/store';
import { dispatcher } from '../core/actions';

const initialState = {
  addOrEdit: 'Add',
  pipelineId: '',
  pipelineName: '',
  pipelineAvgCycle: '',
  pipelineDefAmt: '',
  pipelineStages: [],
  // stage
  spStageAddOrEdit: 'Add',
  spStageId: '',
  spStageName: '',
  spStageWeight: '0.00',
  spStageReminders: [],
  // reports
  store: 0,
  storeid: '',

  // used to filter out pipelines that haven't been deleted from SQL read clusters yet
  deletedSalesPipeline: '',
};
export default function reducer(state = initialState, action) {
  let state2 = state;
  switch (action.type) {
    case 'SET_SALES_PIPE_ADD_EDIT':
      return { ...state2, addOrEdit: action.payload };
    case 'SET_SALES_PIPE_ID':
      return { ...state2, pipelineId: action.payload };
    case 'SET_SALES_PIPE_DELETED':
      return { ...state2, deletedSalesPipeline: action.payload };
    case 'SET_EDIT_PIPELINE':
      return { ...state2, ...action.payload };
    case 'SET_SALES_PIPE_NAME':
      return { ...state2, pipelineName: action.payload };
    case 'SET_SALES_PIPE_AVG_CYCLE':
      return { ...state2, pipelineAvgCycle: action.payload };
    case 'SET_SALES_PIPE_DEF_AMT':
      return { ...state2, pipelineDefAmt: action.payload };
    //
    case 'SET_SPSTAGE_ADD_EDIT':
      return { ...state2, spStageAddOrEdit: action.payload };
    case 'SET_SPSTAGE_ID':
      return { ...state2, spStageId: action.payload };
    case 'SET_SPSTAGE_NAME':
      return { ...state2, spStageName: action.payload };
    case 'SET_SPSTAGE_WEIGHT':
      return { ...state2, spStageWeight: action.payload };
    case 'SET_SPSTAGE_REMINDERS':
      return { ...state2, spStageReminders: [...action.payload] }; // spread it in to help force rerender
    case 'SET_SP_STORE':
      return { ...state2, store: action.payload };
    case 'SET_SP_STORE_ID':
      return { ...state2, storeid: action.payload };
    default:
      break;
  }
  return state2;
}

export const setEditPipeline = (data, addOrEdit) => {
  const { id, name, amount, days_to_close } = data;
  dispatcher('SET_EDIT_PIPELINE', {
    addOrEdit: addOrEdit || 'Add',
    pipelineId: id,
    pipelineName: name,
    pipelineDefAmt: Number(amount),
    pipelineAvgCycle: days_to_close,
  });
};

export const setPipelineStage = (data, addOrEdit) => {
  const { id, name, weight } = data;
  const dispatchArr = [
    { type: 'SET_SPSTAGE_ADD_EDIT', payload: addOrEdit || 'Add' },
    { type: 'SET_SPSTAGE_ID', payload: id || '' },
    { type: 'SET_SPSTAGE_NAME', payload: name || '' },
    { type: 'SET_SPSTAGE_WEIGHT', payload: weight || '' },
  ];
  dispatchArr.forEach(obj => {
    dispatcher(obj.type, obj.payload);
  });
};

export const setDeletedSalesPipeline = val => {
  dispatcher('SET_SALES_PIPE_DELETED', val);
};

export const setPipelineId = val => {
  dispatcher('SET_SALES_PIPE_ID', val);
};

export const setPipelineName = val => {
  dispatcher('SET_SALES_PIPE_NAME', val);
};

export const setSpStore = val => {
  dispatcher('SET_SP_STORE', val);
};
export const setSpStoreId = val => {
  dispatcher('SET_SP_STORE_ID', val);
};
export const setStageName = val => {
  dispatcher('SET_SPSTAGE_NAME', val);
};
export const setStageId = val => {
  dispatcher('SET_SPSTAGE_ID', val);
};
export const setStageWeight = val => {
  dispatcher('SET_SPSTAGE_WEIGHT', parseFloat(val).toFixed(2));
};
export const setAddEditStage = val => {
  dispatcher('SET_SPSTAGE_ADD_EDIT', val);
};
