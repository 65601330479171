export const statusHelp = s => {
  if (s === 'UNPAID') {
    return 'Sent';
  }
  if (s === 'PAID_MANUALLY' || s === 'PAID_BY_CC' || s === 'PAID_WITH_APPLE_PAY' || s === 'PAID_WITH_GOOGLE_PAY') {
    return 'Paid';
  }
  if (s === 'CANCELED') {
    return 'Canceled';
  }
  if (s === 'REFUNDED' || s === 'PARTIALLY_REFUNDED') {
    return 'Refunded';
  }
  if (s === 'CHARGEBACK') {
    return 'Chargeback';
  }
  if (s === 'CANCELLED') {
    return 'Cancelled';
  }
  if (s === 'REFUND_FAILED') {
    return 'Refund Failed';
  }
  if (s === 'PAYMENT_FAILED') {
    return 'Payment Failed';
  }
  if (s === 'EXPIRED') {
    return 'Expired';
  }
};
export const colorHelp = s => {
  if (s === 'UNPAID') {
    return '#5B8FF9';
  }
  if (s === 'PAID_MANUALLY' || s === 'PAID_BY_CC' || s === 'PAID_WITH_APPLE_PAY' || s === 'PAID_WITH_GOOGLE_PAY') {
    return '#5AD8A6';
  }
  return '#cccbcb';
};

export const statusFilter = (s) => {
  switch (s) {
    case 'UNPAID':
      return 'Sent';
    case 'PAID_MANUALLY':
    case 'PAID_BY_CC':
    case 'PAID_WITH_APPLE_PAY':
    case 'PAID_WITH_GOOGLE_PAY':
      return 'Paid';
    case 'CANCELED':
      return 'Canceled';
    case 'REFUNDED':
    case 'PARTIALLY_REFUNDED':
      return 'Refunded';
    case 'CHARGEBACK':
      return 'Chargeback';
    case 'CANCELLED':
      return 'Cancelled';
    case 'REFUND_FAILED':
      return 'Refund Failed';
    case 'PAYMENT_FAILED':
      return 'Payment Failed';
    case 'EXPIRED':
      return 'Expired';
    default:
      return s;
  }
}
