import React, { useState, useEffect, useRef } from 'react';
import { Radio, Space } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledLink from '../../StyledLink';
import Button from '../../Button';
import PopConfirm from '../../PopConfirm';
import SmallTable from '../../SmallTable';
import CheckboxDesc from '../../CheckboxDesc';
import { postRequest, getRequest, deleteRequest } from '../../../../core/apiRequests';
import { notificationSuccess, AutoReminderCheck, notificationError } from '../../../../core/actions';
import {
  setReminderName,
  setReminderCount,
  setReminderInterval,
  setReminderRepeat,
  setRemRepeatCount,
  setRemRepeatInterval,
  setReminderActionId,
  setReminderAddEdit,
  setRemTempMsgs,
  setRemTempMsgText,
  setRemTempAddEdit,
  setRemTempMsgId,
  setListType,
  setReminderActive,
  setRemTempMsgImg,
  setExpireInterval,
  setExpireScale,
  setExpires,
} from '../../../../reducers/autoRemMsgs';
import messageHighlighter from '../messageHighlighter';
import DropdownSelect from '../../DropdownSelect';
import NumberInput from '../../NumberInput';
import ViewAllTemplates from '../../AutoWhatsAppTemplate/ViewAllTemplates';
import KioskSlider from '../../KioskSlider';
import { convertMessageTemplate } from '../../../../utils/convertMessageTemplate';

const AutoReminderSettings = props => {
  const defaultTempState = {
    id: '',
    merchant_id: '',
    name: '',
    scope: '',
    substitution: '',
    template: '',
  };
  const [showPopConfirm, setShowPopConfirm] = useState(false);

  const history = useHistory();
  const isMounted = useRef(null);

  const [showImmediateDD, setShowImmediateDD] = useState(false);
  const [autoReminderImmediate, setReminderImmediate] = useState(false);
  // reflect database data for interval / scale
  const [showViewAll, setShowViewAll] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState(defaultTempState);
  const [expirationRadio, setExpirationRadio] = useState(
    props.autoRemMsgs.expireInterval && props.autoRemMsgs.expireScale ? 1 : 0,
  );
  const [tempActionScale, setTempActionScale] = useState(1);
  const [tempActionInterval, setTempActionInterval] = useState('WEEK');
  const [pendingAuto, setPendingAuto] = useState({
    futureOnly: false,
    isDelete: false,
    automationType: '',
    isDisabled: false,
  });
  const [showPendingAutoPop, setShowPendingAutoPop] = useState(false);
  const intervalOptions = [
    { label: 'Days', value: 'DAY' },
    { label: 'Weeks', value: 'WEEK' },
    { label: 'Months', value: 'MONTH' },
  ];

  useEffect(() => {
    if (window.location.href.includes('add-auto-reminder')) {
      setReminderImmediate(true);
      setReminderCount(0);
      setReminderInterval('DAY');
      setReminderActive(1);
      setExpireInterval('DAY');
      setExpireScale(1);
    }
  }, []);

  useEffect(() => {
    isMounted.current = true;
    const {
      autoRemMsgs: { reminderId },
      type,
    } = props;
    setRemTempMsgs([]);
    if (props.merchantId) {
      if (reminderId && props.merchantId && window.location.href.includes('edit-auto-reminder')) {
        getTemplates(reminderId);
      } else if (
        window.location.href.includes('edit-auto-reminder') &&
        Number(reminderId) !== Number(props.match.params.reminderId)
      ) {
        getEditACData();
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [props.autoRemMsgs.reminderId, props.merchantId, props.match.params.reminderId]);

  const getTemplates = reminderId => {
    (async () => {
      const reqObj = {
        params: [props.type, props.merchantId, props.match.params.reminderId || reminderId, 'messageTemplates'],
      };
      const data = await getRequest(reqObj);
      if (data) {
        setRemTempMsgs(data);
      } else {
        console.error(`Failed to get message templates for type: ${props.type}`);
      }
    })();
  };

  const getEditACData = () => {
    (async () => {
      const reqObj = {
        // tags/:merchantId/:tagId/actionConfig'
        params: [props.type, props.merchantId, props.match.params.listItemId, 'actionConfig'],
        query: {
          reminderId: props.match.params.reminderId,
        },
      };
      const data = await getRequest(reqObj);
      if (data && data.length > 0) {
        const {
          id,
          description,
          action_interval,
          action_scale,
          active,
          repeats,
          repeat_interval,
          repeat_scale,
          expire_scale,
          expire_interval,
        } = data[0];
        setReminderAddEdit('Edit');
        setReminderActionId(id);
        setListType(props.type);
        setReminderActive(active);
        setReminderName(description);
        if (action_interval === 'DAY' && action_scale === 0) {
          setReminderImmediate(true);
        }
        setReminderInterval(action_interval);
        setReminderCount(action_scale);
        setReminderRepeat(Number(repeats));
        // CHANGING HERE
        setTempActionScale(action_scale);
        setTempActionInterval(action_interval);
        setRemRepeatInterval(repeat_interval);
        setRemRepeatCount(repeat_scale);
        if (expire_scale && expire_interval) {
          props.setExpireScl(expire_scale);
          props.setExpireInt(expire_interval);
          setExpires(true);
        } else {
          setExpires(false);
          props.setExpireScl(0);
          props.setExpireInt('DAY');
        }
        history.push(props.editRoute);
      }
    })();
  };

  useEffect(() => {
    if (props.autoRemMsgs.reminderCount === 0 && props.autoRemMsgs.reminderInterval === 'DAY') {
      setReminderImmediate(true);
    }
    setShowImmediateDD(!showImmediateDD);
  }, [autoReminderImmediate]);

  const tableHeaders = [
    // this is a configuration array. It specifies how each column will be structured as well as the column header info
    {
      keyName: 'msg',
      title: 'Message',
      rowClassName: '',
      style: { width: '75%', paddingTop: '0px' }, // we can apply all styles to the header now.
      sortable: false,
      render: (t, o) => {
        // returnedText = textStr.substr(0, 60) + "...";
        if (t) {
          return <div className='line-clamp-1' dangerouslySetInnerHTML={{ __html: convertMessageTemplate(t)}} />;
        }
        const waObj = {
          ...o,
        };
        if (o.template.length > 60) {
          waObj.template = `${o.template.substr(0, 60)}...`;
        }
        return listFormatter(waObj);
      },
    },
    {
      keyName: 'type',
      title: 'Type',
      rowClassName: '',
      style: { width: '25%', paddingTop: '0px' }, // we can apply all styles to the header now.
      sortable: false,
      render: t => {
        return t;
      },
    },
  ];

  const selectArray = () => {
    const arr = [];
    let i = 0;
    while (i < 32) {
      arr.push({ text: i, value: i });
      i++;
    }
    return arr;
  };

  const confirmDelete = () => {
    const { listItemId } = props.match.params;
    const {
      autoRemMsgs: { reminderId },
      firstName,
      lastName,
      type,
    } = props;
    const fn = firstName || '';
    const ln = lastName || '';
    const dataObj = { deletedBy: `${fn} ${ln}` };
    if (type !== 'lifeEvent') {
      dataObj.futureOnly = pendingAuto.futureOnly;
    }
    (async () => {
      const reqObj = {
        params: [
          type,
          props.merchantId,
          listItemId || props.match.params.listItemId,
          'actionConfig',
          reminderId || props.match.params.reminderId,
        ],
        query: dataObj,
      };
      const data = await deleteRequest(reqObj);
      if (data) {
        notificationSuccess('Reminder Removed');
        // props.setRedirectBack(true);
        history.push(`${props.editRoute}`);
      }
    })();
  };

  const createAutoReminder = () => {
    const { listItemId } = props.match.params;
    const {
      type,
      autoRemMsgs: {
        reminderId,
        reminderName,
        reminderCount,
        reminderInterval,
        reminderRepeat,
        active,
        expireScale,
        expireInterval,
        expires,
        // repeat stuff here
        autoReminderRepeatCount,
        autoReminderRepeatInterval,
      },
      firstName,
      lastName,
    } = props;
    const fn = firstName || '';
    const ln = lastName || '';
    if (type && AutoReminderCheck()) {
      const dataObj = {
        description: reminderName,
        actionScale: reminderCount,
        actionInterval: reminderInterval,
        active,
        // repeat section here
        repeats: reminderRepeat,
        repeatScale: reminderRepeat ? autoReminderRepeatCount : null,
        repeatInterval: reminderRepeat ? autoReminderRepeatInterval : null,
      };
      if (expires) {
        if (!expirationRadio) {
          dataObj.expireScale = 0;
          dataObj.expireInterval = 'DAY';
        } else {
          dataObj.expireScale = +expireScale;
          dataObj.expireInterval = expireInterval;
        }
      }
      if (reminderId) {
        dataObj.id = reminderId;
      }
      if (type !== 'lifeEvent') {
        dataObj.futureOnly = pendingAuto.futureOnly;
      }
      if (
        (reminderRepeat && !autoReminderRepeatCount && type !== 'lifeEvent') ||
        (reminderRepeat && !autoReminderRepeatInterval && type !== 'lifeEvent')
      ) {
        notificationError('Please set a repeat schedule');
      } else {
        (async () => {
          const reqObj = {
            params: [type, props.merchantId, listItemId, 'actionConfig'],
            data: dataObj,
            query: {
              createdBy: `${fn} ${ln}`.trim(), // TODO: HERE
            },
          };
          const data = await postRequest(reqObj);
          if (data.id) {
            if (window.location.href.includes('edit-auto-reminder')) {
              notificationSuccess('Auto Reminder Updated');
              history.push(`${props.editRoute}`);
            } else {
              notificationSuccess('Auto Reminder Created');
              props.setAddEdit('Edit');
              props.setActionId(data.id);
              history.push(`${props.editRoute}`);
            }
          } else {
            history.push(`${props.editRoute}`);
            // TODO: error handling?
          }
        })();
      }
    }
  };

  const clearMessageConfig = () => {
    setRemTempAddEdit('Add');
    setRemTempMsgId('');
    setRemTempMsgText('');
  };

  const rowClickHandler = data => {
    if (data.msg) {
      setRemTempMsgText(data.msg);
      setRemTempMsgId(data.id);
      setRemTempMsgImg(data.image_url);
      setRemTempAddEdit('Edit');
      history.push(
        `${props.editRoute}/edit-auto-reminder/${props.match.params.reminderId}/edit-message-template/${data.id}`,
      );
    } else if (data.template) {
      history.push(
        `${props.editRoute}/edit-auto-reminder/${props.match.params.reminderId}/edit-whatsapp-template/${data.id}`,
      );
    }
  };

  const listFormatter = o => {
    // t = text, o = obj.
    const subsObj = {}; // an obj that will look like {1: client, 2: product, 3: something, 4: whatevz}
    if (o.substitution) {
      // check for null columns
      const substitutes = o.substitution.split(','); // they're comma delimited, make an array
      substitutes.forEach(sub => {
        // loop over that array ^ , each sub looks something like 1=CLIENT or 2=PRODUCT
        const propertyDivide = sub.split('='); // split them up on that equals sign into an array
        if ((propertyDivide.length = 2)) {
          // each propertyDivide looks like this: [1, "CLIENT"] or [2, "PRODUCT"], etc...
          subsObj[propertyDivide[0]] = propertyDivide[1].toLowerCase(); // put them on that subsObj, and lowercase the values... we're rendering them later!
        }
      });
    }
    return o.template.replace(/{{\d}}/g, val => {
      // look for {{#}}
      const theNumber = val.replace(/[{}]/g, ''); // if found, remove all {'s and }'s
      if (subsObj[theNumber]) {
        // using the number, check if the property exists on the substitutes object
        return `[${subsObj[theNumber]}]`; // if so return it
      }
      return '[N/A]'; // else return this lol extreme edge case, I don't know.
    });
  };

  const immediateFunction = () => {
    setReminderImmediate(!autoReminderImmediate);
    if (!autoReminderImmediate) {
      props.setCount(0);
      props.setInterval('DAY');
    } else {
      props.setCount(tempActionScale);
      props.setInterval(tempActionInterval);
    }
  };

  const repeatFunction = () => {
    setReminderRepeat(props.autoRemMsgs.reminderRepeat ? 0 : 1);
    if (props.autoRemMsgs.reminderRepeat === false || props.autoRemMsgs.reminderRepeat === 0) {
      setRemRepeatCount(props.autoRemMsgs.reminderCount);
      setRemRepeatInterval(props.autoRemMsgs.reminderInterval);
    } else {
      setRemRepeatCount('');
      setRemRepeatInterval('');
    }
  };

  const whatsappSelectHandler = (id, obj) => {
    setSelectedTemplate(obj);
  };

  const saveWATemplate = () => {
    const { listItemId } = props.match.params;
    if (selectedTemplate.id) {
      (async () => {
        const reqObj = {
          params: [
            props.type,
            props.merchantId,
            listItemId,
            props.match.params.reminderId,
            selectedTemplate.id,
            'whatsappMessageTemplate',
          ],
        };
        const data = await postRequest(reqObj);
        if (data.id) {
          notificationSuccess('Added Whatsapp Template');
          setShowViewAll(false);
          setSelectedTemplate(defaultTempState);

          const newObj = {
            id: data.id,
            tag_action_config_id: props.match.params.reminderId,
            type: 'Whatsapp Template',
            whatsapp_template_id: selectedTemplate.id,
            template: selectedTemplate.template,
            merchant_id: props.merchantId,
            name: selectedTemplate.name,
            scope: selectedTemplate.scope,
            substitution: selectedTemplate.substitution,
          };

          setRemTempMsgs([...props.autoRemMsgs.messageTemps, newObj]);
        } else {
          notificationError('Failed to Save Whatsapp Template');
        }
      })();
    } else {
      notificationError('Please select a template');
    }
  };

  const AutoReminderNameChange = e => {
    props.setName(e.target.value);
    if (window.location.href.includes('edit-auto-reminder')) {
      setPendingAuto({ ...pendingAuto, automationType: 'DESCRIPTION' });
    } else setPendingAuto({ ...pendingAuto, automationType: '' });
  };

  const AutomationCheck = () => {
    if (pendingAuto.automationType === 'DESCRIPTION' || pendingAuto.isDisabled === true) {
      setShowPendingAutoPop(true);
    } else {
      createAutoReminder();
    }
  };

  const AutomationSubmit = () => {
    if (pendingAuto.isDelete) {
      setShowPopConfirm(true);
    } else {
      createAutoReminder();
    }
    setShowPendingAutoPop(false);
  };

  const radioChange = e => {
    setExpirationRadio(e.target.value);
  };

  return (
    <>
      <div className="">
        <div className="max-w-600" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <span style={{ fontSize: '13px', fontWeight: '500' }}>Turn On/Off Automation</span>
            <span style={{ fontSize: '10px', color: '#979797' }}>
              Enable or disable this auto-reminder and all linked automated actions
            </span>
          </div>
          <KioskSlider
            id="automationToggle"
            value={props.autoRemMsgs.active}
            onChange={() => {
              if (props.autoRemMsgs.active === 1) {
                setReminderActive(0);
                setPendingAuto({ ...pendingAuto, isDisabled: true });
              } else {
                setReminderActive(1);
                setPendingAuto({ ...pendingAuto, isDisabled: false });
              }
            }}
          />
        </div>
        <p style={{ fontSize: '13px', fontWeight: '500', marginBottom: 0, marginTop: '27px', textAlign: 'left' }}>
          Auto Reminder Name
        </p>
        <div className="add-edit-wrap ">
          <input
            id="autoREminderName"
            className="m-btm-15 fs-13 outline-none common_inputStyle max-w-600 w-100-P left-0 input-height "
            value={props.autoRemMsgs.reminderName}
            onChange={e => AutoReminderNameChange(e)}
            placeholder="Auto Reminder Name"
            style={{ width: '100%', marginTop: '8px' }}
          />
        </div>
        <span className="fs-13 fw-500 d-block m-top-30 m-btm-15 align-left">{props.descriptionText}</span>
        {/* IMMEDIATELY */}
        <CheckboxDesc
          checked={autoReminderImmediate}
          onclick={immediateFunction}
          text="Same Day"
          classProps="fs-13"
          checkButtonProps="m-btm-15 w-85"
        />
        {showImmediateDD ? (
          <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel m-btm-28">
            <div className="w-48-P align-left" style={{ position: 'relative' }}>
              <NumberInput
                onChange={props.setCount}
                min="1"
                max="31"
                step={1}
                precision={1}
                value={props.autoRemMsgs.reminderCount}
                classProps=""
                placeholder="1"
              />
            </div>
            <div className=" w-48-P inputHeight" style={{ position: 'relative' }}>
              <DropdownSelect
                classProps="maw-100-P w-100-P align-left"
                options={intervalOptions}
                placeholder="Weeks"
                value={props.autoRemMsgs.reminderInterval}
                onChange={obj => {
                  props.setInterval(obj.value);
                }}
              />
            </div>
          </div>
        ) : null}
        {/* REPEATS */}
        {props.type !== 'lifeEvent' ? (
          <div className="max-w-600 w-100-P">
            <span className="fs-13 fw-500 d-block m-btm-15 align-left">Do you want this reminder to be repeated?</span>
            <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel m-btm-28">
              <CheckboxDesc
                checked={props.autoRemMsgs.reminderRepeat}
                onclick={repeatFunction}
                text="Repeats"
                checkButtonProps="m-btm-15"
              />
              {props.autoRemMsgs.reminderRepeat ? (
                <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel">
                  <div className="w-48-P inputHeight align-left" style={{ position: 'relative' }}>
                    <NumberInput
                      onChange={setRemRepeatCount}
                      min="1"
                      max="31"
                      step={1}
                      precision={1}
                      value={props.autoRemMsgs.autoReminderRepeatCount}
                      classProps=""
                      placeholder="6"
                    />
                  </div>
                  <div className=" w-48-P inputHeight" style={{ position: 'relative' }}>
                    <DropdownSelect
                      classProps="maw-100-P w-100-P align-left"
                      options={intervalOptions}
                      placeholder="Months"
                      value={props.autoRemMsgs.autoReminderRepeatInterval}
                      onChange={obj => {
                        props.setRepeatInt(obj.value);
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {/* Expires after the event checkbox and date select */}
        <div className="max-w-600 flex-row-nospacebetween-wrap m-btm-15">
          <KioskSlider
            id="expirationToggle"
            value={props.autoRemMsgs.expires}
            onChange={value => setExpires(value)}
            classProps="fs-13"
            checkButtonProps="m-btm-15 w-85"
          />
          <div className="flex-col-left p-left-15">
            <span style={{ fontSize: '13px', fontWeight: '500' }}>Turn On Expiration</span>
            <span style={{ fontSize: '10px', color: '#979797' }}>
              Turning on expiration allows reminders to automatically go away after a certain amount of time
            </span>
          </div>
        </div>
        {props.autoRemMsgs.expires && (
          <div className="align-left">
            <span
              className={`${!props.autoRemMsgs.expires && 'gray'} fs-13 fw-500 d-block m-top-30 m-btm-15 align-left`}
            >
              How long do you want the reminder to remain overdue before it expires?
            </span>
            <Radio.Group
              size="large"
              onChange={radioChange}
              value={expirationRadio}
              disabled={!props.autoRemMsgs.expires}
            >
              <Space direction="vertical">
                <Radio value={0}>Expires when overdue</Radio>
                <Radio value={1}>Custom </Radio>
              </Space>
            </Radio.Group>
            <br />
            <br />
            <div className="flex-row-spacebetween-wrap w-100-vw max-w-600 pos-rel m-btm-28">
              <div className="w-48-P inputHeight align-left" style={{ position: 'relative' }}>
                <NumberInput
                  onChange={value => props.setExpireScl(value)}
                  min="1"
                  max="31"
                  step={1}
                  precision={1}
                  value={props.autoRemMsgs.expireScale}
                  classProps=""
                  placeholder={1}
                  disabled={!props.autoRemMsgs.expires || !expirationRadio}
                />
              </div>
              <div className="p-left-15 w-48-P inputHeight" style={{ position: 'relative' }}>
                <DropdownSelect
                  classProps="maw-100-P w-100-P align-left"
                  options={intervalOptions}
                  placeholder="Weeks"
                  value={props.autoRemMsgs.expireInterval}
                  onChange={obj => props.setExpireInt(obj.value)}
                  disabled={!props.autoRemMsgs.expires || !expirationRadio}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* START of save / cancel / delete code  */}
      <div className="flex-col-center m-btm-56 m-top-37 maw-600">
        <button className="save-button-v2 m-btm-8" onClick={AutomationCheck}>
          Save
        </button>
        {props.autoRemMsgs.reminderAddOrEdit === 'Edit' ? (
          <Button
            classname="deleteButton-v2 m-btm-8"
            style={{ marginBottom: '15px' }}
            onclick={() => {
              setShowPendingAutoPop(true);
              setPendingAuto({ ...pendingAuto, isDelete: true });
            }}
          >
            Delete
          </Button>
        ) : (
          <div />
        )}
        <StyledLink to={props.editRoute}>
          <button className="cancel-button-v2 m-btm-8">Cancel</button>
        </StyledLink>
      </div>
      {/* END BLOCK */}
      <div>
        <div className="flex-row-spacebetween-wrap max-w-600">
          <p className="black fs-16 fw-500 no-wrap" style={{ textAlign: 'center' }}>
            Message Templates
          </p>
          {window.location.href.includes('edit-auto-reminder') ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {props.enabled_whatsapp ? (
                <StyledLink
                  classprops="align-right"
                  // to={`/Configuration/tags/edit-tag/${props.match.params.listItemId}/edit-auto-reminder/${props.match.params.reminderId}/add-auto-whatsapp`}
                  styleprops={{
                    color: '#33aafc',
                    marginTop: '5px',
                  }}
                >
                  <Button
                    onclick={() => setShowViewAll(true)}
                    classname="blue-link align-right"
                    styleProps={{ paddingRight: '0px' }}
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'whatsapp']}
                      size="1x"
                      className="fs-16"
                      style={{ paddingBottom: '2px' }}
                    />{' '}
                    WHATSAPP TEMPLATE
                  </Button>
                </StyledLink>
              ) : null}

              <StyledLink
                classprops="align-right"
                to={`${props.editRoute}/edit-auto-reminder/${props.autoRemMsgs.reminderId}/add-message-template`}
                styleprops={{
                  color: '#33aafc',
                  marginTop: '5px',
                }}
              >
                <Button
                  styleProps={{ paddingRight: '0px' }}
                  onclick={clearMessageConfig}
                  classname="blue-link align-right"
                >
                  <FontAwesomeIcon
                    icon={['far', 'comment-medical']}
                    size="1x"
                    className="fs-16"
                    style={{ paddingBottom: '2px' }}
                  />{' '}
                  MESSAGE TEMPLATE
                </Button>
              </StyledLink>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {props.enabled_whatsapp ? (
                <span
                  className="light-gray not-allowed m-top-5 fs-13 fw-400 flex-row-nospacebetween-nowrap align-right m-right-15"
                  style={{ alignItems: 'center' }}
                >
                  <FontAwesomeIcon
                    icon={['fab', 'whatsapp']}
                    size="1x"
                    className="fs-16  m-right-6"
                    style={{ paddingBottom: '2px' }}
                  />{' '}
                  <span>WHATSAPP TEMPLATE</span>
                </span>
              ) : null}
              <span
                className="light-gray not-allowed m-top-5 fs-13 fw-400 flex-row-nospacebetween-nowrap align-right"
                style={{ alignItems: 'center' }}
              >
                <FontAwesomeIcon
                  icon={['far', 'comment-medical']}
                  size="1x"
                  className="fs-16  m-right-6"
                  style={{ paddingBottom: '2px' }}
                />{' '}
                <span>MESSAGE TEMPLATE</span>
              </span>
            </div>
          )}
        </div>
        <SmallTable
          tableClassName="edit-tags-auto-table hide-scrollbar max-w-600"
          tableContainerClass="edit-tags-auto-container"
          tableStyle={{ marginRight: '0px', width: '100%' }}
          classname="table-head border-btm"
          data={props.autoRemMsgs.messageTemps.filter(
            obj => !props.autoRemMsgs.deleteMessageTemplates.includes(`${obj.id}`),
          )}
          onRowClick={rowClickHandler}
          tableHeaders={tableHeaders}
          noDataText={
            window.location.href.includes('edit-auto-reminder')
              ? false
              : 'Save an auto-reminder before adding a template'
          }
        />
      </div>
      {showPopConfirm ? (
        <PopConfirm
          confirm={`Are you sure you want to remove "${props.autoRemMsgs.reminderName}" reminder?`}
          description={props.popConfirmDesc}
          onCancel={() => {
            setShowPopConfirm(false);
            setPendingAuto({ ...pendingAuto, isDelete: false });
          }}
          onConfirm={confirmDelete}
        />
      ) : null}
      {showPendingAutoPop ? (
        <PopConfirm
          confirm="These changes will be applied by default to both scheduled and future automation"
          customButtons
          description={
            <div className="flex-col-left w-100-P align-center">
              <CheckboxDesc
                checked={pendingAuto.futureOnly}
                onclick={() => setPendingAuto({ ...pendingAuto, futureOnly: !pendingAuto.futureOnly })}
                text="Only apply to future automation"
                checkButtonProps="p-top-4 m-left-8"
                classProps="m-top-10"
                checkBoxId="autoReminderAutomationPopupCheckbox"
              />
              <div
                className="w-100-P m-top-15 m-btm-28 flex-row-nospacebetween-nowrap"
                style={{ justifyContent: 'center' }}
              >
                <button
                  className="save-button m-right-15 m-top-15"
                  onClick={AutomationSubmit}
                  id="autoReminderAutomationSubmitBtn"
                >
                  Submit
                </button>
              </div>
            </div>
          }
        />
      ) : null}
      {showViewAll ? (
        <ViewAllTemplates
          saveButtonText="Save"
          listItemId={props.match.params.reminderId}
          selected={selectedTemplate.id}
          toggleViewAll={setShowViewAll}
          setSelected={whatsappSelectHandler}
          saveHandler={saveWATemplate}
        />
      ) : null}
      {/* {redirectEditTemp ? <Redirect to={props.editRoute} /> : null} */}
    </>
  );
};
const mapStateToProps = state => {
  return {
    ...state.auth,
    addEditTag: { ...state.addEditTag },
    autoMessages: state.autoMessages,
    autoRemMsgs: { ...state.autoRemMsgs },
  };
};
const mapDispatchToProps = dispatch => ({
  setName(val) {
    setReminderName(val);
  },
  setCount(val) {
    setReminderCount(val);
  },
  setInterval(val) {
    setReminderInterval(val);
  },
  setRepeat(val) {
    setReminderRepeat(val);
  },
  setRepeatCnt(val) {
    setRemRepeatCount(val);
  },
  setRepeatInt(val) {
    setRemRepeatInterval(val);
  },
  setActionId(val) {
    setReminderActionId(val);
  },
  setAddEdit(val) {
    setReminderAddEdit(val);
  },
  setExpireInt(val) {
    setExpireInterval(val);
  },
  setExpireScl(val) {
    setExpireScale(val);
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AutoReminderSettings);
