import React from 'react';

const IntegrationsGoogle = () => {

  return (
    <>
      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Overview</h3>

        <p>
          Connect your Google Account with Clientbook. Effortlessly sign into your Google account easy redirect right to Google.
          Once you are connected, never miss a review again. Respond to the review directly in the Clientbook Web application or mobile devices.
          Features like using generative AI will help give you the best response to the customers review.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Key Features</h3>

        <p>By adding your Google information to Clientbook, you can:</p>

        <ul className="cb-integrations-details__list">
          <li>Easily connect your Google account to Clientbook.</li>

          <li>Respond directly to Google Reviews from Clientbook.</li>

          <li>Have multiple lines of communications with clients.</li>

          <li>Get historical reviews to keep track of past reviews.</li>
        </ul>
      </section>
    </>
  );
};

export default IntegrationsGoogle;
