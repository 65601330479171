import { dispatcher } from '../core/actions';

const initialState = {
  text: 'Tag Removed',
  showNotification: false,
  isError: false,
  showSaving: false,
  showLoading: false,
  showSurvey: false,
  listLoading: [],
  loading: false,
  loadingDescription: null,
};
export default function reducer(state = initialState, action) {
  let state2 = state;
  switch (action.type) {
    case 'SET_NOTIF_TEXT':
      return { ...state2, text: action.payload };
    case 'SET_SHOW_NOTIF':
      return { ...state2, showNotification: action.payload };
    case 'SET_NOTIF_IS_ERR':
      return { ...state2, isError: action.payload };
    case 'SET_SAVING_NOTIF':
      return { ...state2, showSaving: action.payload };
    case 'SET_LOADING_NOTIF':
      return { ...state2, showLoading: action.payload };
    case 'ADD_LOADING_LIST':
      let copyList = [...state2.listLoading];
      if(!copyList.includes(action.payload)) {
        copyList = [...copyList, action.payload];
      }
      return { ...state2, loading: copyList.length > 0, listLoading: copyList };
    case 'REMOVE_LOADING_LIST':
      let list = [...state2.listLoading];
      if(list.includes(action.payload)) {
        list = list.filter(item => item !== action.payload);
      }
      return { ...state2, loading: list.length > 0, listLoading: list };
    case 'SET_SURVEY_STATUS':
      return { ...state2, showSurvey: action.payload };
    case 'SET_LOADING_DESCRIPTION':
      return { ...state2, loadingDescription: action.payload };
    default:
      break;
  }
  return state2;
}

export const setSavingStatus = val => {
  dispatcher('SET_SAVING_NOTIF', val);
};

export const addLoadingList = val => {
  dispatcher('ADD_LOADING_LIST', val);
};

export const removeLoadingList = val => {
  dispatcher('REMOVE_LOADING_LIST', val);
};

export const setLoadingStatus = val => {
  dispatcher('SET_LOADING_NOTIF', val);
};
export const setSurveyStatus = val => {
  dispatcher('SET_SURVEY_STATUS', val);
};

export const setLoadingDescription = val => {
  dispatcher('SET_LOADING_DESCRIPTION', val);
};
