const DEFAULT_SUFFIX = '...';
const DEFAULT_CUT_WORDS = false;

const truncateText = (text, maxLength, options) => {
  if (text.length <= maxLength) {
    return text;
  }

  let truncatedText;

  if (options?.cutWords || DEFAULT_CUT_WORDS) {
    truncatedText = text.substr(0, maxLength);
  } else {
    truncatedText = text.substr(0, text.substr(0, maxLength + 1).lastIndexOf(' '));
  }

  return `${truncatedText} ${options?.suffix || DEFAULT_SUFFIX}`;
};

export default truncateText;
