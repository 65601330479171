import React from 'react';
import { Switch } from 'antd';
import './kiosk.css';

const KioskSlider = props => {
  const { value, onChange, id, size = 'default', ...rest } = props;

  return (
    <Switch
      id={id}
      checked={Boolean(value)}
      size={size}
      onChange={onChange}
      defaultChecked={Boolean(value)}
      className={`kiosk-switch ${Boolean(value) && 'kiosk-switch__checked'}`}
      {...rest}
    />
  );
};

export default KioskSlider;
