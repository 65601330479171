import store from './store';

export const notificationSuccess = text => {
  const { dispatch } = store;
  dispatch({
    type: 'SET_SHOW_NOTIF',
    payload: true,
  });
  dispatch({
    type: 'SET_NOTIF_TEXT',
    payload: text,
  });
  dispatch({
    type: 'SET_NOTIF_IS_ERR',
    payload: false,
  });
};

export const notificationError = text => {
  store.dispatch({
    type: 'SET_NOTIF_TEXT',
    payload: text,
  });
  store.dispatch({
    type: 'SET_SHOW_NOTIF',
    payload: true,
  });
  store.dispatch({
    type: 'SET_NOTIF_IS_ERR',
    payload: true,
  });
};

export const dispatcher = (type, payload) => {
  store.dispatch({
    type,
    payload,
  });
};

export const AutoReminderCheck = () => {
  const state = store.getState().autoRemMsgs;
  const { reminderName, reminderCount, reminderInterval } = state;
  if (!reminderName) {
    notificationError('Reminder Name Required');
    return false;
  }
  if (!reminderInterval) {
    notificationError('Interval Required');
    return false;
  }
  return true;
};

export const AutoMessagesCheck = () => {
  const state = store.getState().autoMessages;
  const { autoMessageText, autoMessageCount, autoMessageInterval } = state;
  if (!autoMessageText) {
    notificationError('Message Text Required');
    return false;
  }
  if (!autoMessageInterval) {
    notificationError('Interval Required');
    return false;
  }
  return true;
};

// PAGINATION ACTIONS

export const resetPagination = (colName, options = {}) => {
  const { isAsc = true } = options;

  dispatcher('SET_PAG_PAGE', 1);
  dispatcher('SET_PAG_PAGE_SIZE', 25);
  dispatcher('SET_PAG_ROW_COUNT', 0);
  dispatcher('SET_PAG_PAGE_COUNT', 0);
  dispatcher('SET_PAG_SORT_COLUMN', colName);
  dispatcher('SET_PAG_ASC_COLUMN', isAsc);
  dispatcher('SET_PAG_SEARCH_TEXT', '');
};

export const setPageSize = val => {
  dispatcher('SET_PAG_PAGE_SIZE', val);
};
export const setRowCount = val => {
  dispatcher('SET_PAG_ROW_COUNT', val);
};
export const setPageCount = val => {
  dispatcher('SET_PAG_PAGE_COUNT', val);
};
export const setSortColumn = val => {
  dispatcher('SET_PAG_SORT_COLUMN', val);
};
export const setAscendColumn = val => {
  dispatcher('SET_PAG_ASC_COLUMN', val);
};
export const setSearchText = val => {
  dispatcher('SET_PAG_SEARCH_TEXT', val);
};

// Table Pagination Actions

const tableDispatcher = (type, payload, table = '') =>
  store.dispatch({
    type,
    table,
    payload,
  });
const sharedTableName = 'SHARED_TABLE';
export const setTablePaginationPageNum = (value, table) => {
  tableDispatcher('setTablePagination_Page', value, table ?? sharedTableName);
};
export const setTablePagination = (value, table) => {
  tableDispatcher('setTablePagination', value, table ?? sharedTableName);
};
export const resetTablePagination = (
  { page = 1, pageSize = 25, rowCount = 0, pageCount = 0, sortColumn = '', ascendingColumn = true, searchText = '' },
  table,
) => {
  setTablePagination(
    {
      page,
      pageSize,
      rowCount,
      pageCount,
      sortColumn,
      ascendingColumn,
      searchText,
    },
    table,
  );
};
export const setTablePaginationPageSize = (value, table) => {
  tableDispatcher('setTablePagination_PageSize', value, table ?? sharedTableName);
};
export const setTablePaginationRowCount = (value, table) => {
  tableDispatcher('setTablePagination_RowCount', value, table ?? sharedTableName);
};
export const setTablePaginationPageCount = (value, table) => {
  tableDispatcher('setTablePagination_PageCount', value, table ?? sharedTableName);
};
export const setTablePaginationSortColumn = (value, table) => {
  tableDispatcher('setTablePagination_SortColumn', value, table ?? sharedTableName);
};
export const setTablePaginationAscendColumn = (value, table) => {
  tableDispatcher('setTablePagination_AscendingColumn', value, table ?? sharedTableName);
};
export const setTablePaginationSearchText = (value, table) => {
  tableDispatcher('setTablePagination_SearchText', value, table ?? sharedTableName);
};
const defaultGetTableName = () => sharedTableName;
export const getTablePaginationData = ({ tablePagination }, tableName) => {
  const table = tableName ?? sharedTableName;
  const paginationData = tablePagination.get(table) ?? tablePagination.get('DEFAULT_PAGE_STATE');
  return { ...paginationData };
};
export const mapTableStateToProps =
  (getTableName = defaultGetTableName) =>
  (state, otherProps) => {
    return getTablePaginationData(state, getTableName(otherProps));
  };
