/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Image } from 'antd';
import NotFoundImage from './not-found.png';
import './styles.css';

const ProductCarousel = ({ images }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="clients-v2-collection-product-carousel">
      {!images?.length && (
        <div className="clients-v2-collection-product-carousel--image">
          <Image src={NotFoundImage} width={300} height={300} preview={false} />
        </div>
      )}

      {!!images?.length && (
        <>
          <div className="clients-v2-collection-product-carousel--selector">
            {images.map((image, index) => (
              <Image
                key={index}
                src={image}
                width={50}
                height={50}
                preview={false}
                fallback={NotFoundImage}
                onClick={() => setSelectedIndex(index)}
                className="clients-v2-collection-product-carousel--thumbnail"
              />
            ))}
          </div>

          <Image
            src={images[selectedIndex]}
            width={300}
            height={300}
            fallback={NotFoundImage}
            className="clients-v2-collection-product-carousel--image"
          />
        </>
      )}
    </div>
  );
};

export default ProductCarousel;
