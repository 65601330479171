import React from 'react';
import ClientActivity from './ClientActivity';

const Activity = props => {

  return (
    <div className='flex-col-left' style={{ alignItems: 'stretch', maxHeight: '100%' }}>
      <ClientActivity
        purchases={props?.client?.clientPurchases}
        associateRefObj={props.associateRefObj}
        activities={props?.client?.recentActivity}
        activityList={props.activityList}
        client={props.client}
        auth={props.auth}
      />
    </div>
  );
};
export default Activity;
