import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setAuthSelStore } from '../../../../reducers/auth';
import PopConfirm from '../../PopConfirm';
import DropdownSelect from '../../DropdownSelect';

function selectedStoreModal({ auth, hideSelector, setMsgResetClient, closeConversation }) {
  const [storeModal, setStoreModal] = useState(false);
  const [store, setStore] = useState(auth.selectedStore);
  const [storesObj] = useState(auth.stores.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.name }), {}));
  const [stores] = useState(auth.stores.filter(item => item.id));

  return (
    <>
      {!hideSelector && !!auth.selectedStore && stores.length > 1 && (
        <div className="flex-row-end-start p-left-16">
          <FontAwesomeIcon
            icon={['far', 'location-dot']}
            className="fs-20 p-right-6 p-btm-4"
            style={{ color: '#000' }}
          />
          <span className="fs-16 fw-700 p-right-6">{storesObj[auth.selectedStore]}</span>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <span
            className="fs-14 fw-600 blue pointer"
            onClick={() => setStoreModal(true)}
            style={{ marginBottom: '1px' }}
          >
            Change
          </span>
        </div>
      )}
      {storeModal && (
        <PopConfirm
          confirm="Store Location"
          customHeaderContent="align-center fs-18"
          onCancel={() => {
            setStoreModal(false);
            setStore(auth.selectedStore);
          }}
          onConfirm={() => {
            setAuthSelStore(store);
            setStoreModal(false);
            if (closeConversation) {
              setMsgResetClient();
            }
          }}
        >
          <DropdownSelect
            options={auth.stores.filter(item => item.id).map(item => ({ label: item.name, value: item.id }))}
            placeholder="Select relationship"
            value={store}
            onChange={obj => {
              setStore(obj.value);
            }}
          />
        </PopConfirm>
      )}
    </>
  );
}

export default selectedStoreModal;
