import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { importLargeData, getRequest } from '../../../../core/apiRequests';
import { notificationSuccess, notificationError } from '../../../../core/actions';
import { setSelectedClientList } from '../../../../reducers/client';

const MultiAssignedAssociateModal = props => {
  const [searchText, setSearchText] = useState('');
  const [associateData, setAssociateData] = useState([]);
  const [associateAccess, setAssociateAccess] = useState({});
  const [disableSave, setDisableSave] = useState(false);
  const [filterAsct, setFilterAsct] = useState([]);
  const isMounted = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    if (props.auth.merchantId) {
      setLoading(true);
      const storeIds = [];
      if (props.auth.storeId === 0) {
        props.auth.stores.map(store => {
          if (store.id !== 0) {
            storeIds.push(store.id);
          }
        });
      } else {
        storeIds.push(props.auth.storeId);
      }
      const { role, userId } = props.auth;
      (async () => {
        const reqObj = {
          params: ['clients', props.auth.merchantId, 'tagsAndAssociates'],
          query: {
            userId,
            role,
            storeIds: [...storeIds],
          },
          loadingStatusOff: true,
        };
        const reqData = await getRequest(reqObj);
        if (reqData.length > 0 && reqData[0].associates && reqData[0].associates.length > 0) {
          setLoading(false);
          setAssociateData(reqData[0].associates.map(asct => {
            let cnt = 0;
            props.client.selected_client_list.forEach(client => {
              if(props.clientAscts.hasOwnProperty(client) && props.clientAscts[client].find(clientAsct => clientAsct.id === asct.id)) {
                cnt++;
              }
            });
            return {
              ...asct,
              cnt,
            }
          }).sort((a, b) => b.cnt - a.cnt));
        } else {
          notificationError('Error Getting Associates List')
        }
      })();
    }
    return () => {
      isMounted.current = false;
    };
  }, [
    props.auth.merchantId,
    props.pagination.pageSize,
    props.pagination.page,
    props.pagination.ascendingColumn,
    props.clientAscts,
    props.client.selected_client_list
  ]);

  useEffect(() => {
    if (associateData.length > 0) {
      const filtered = associateData.filter(asct => {
        if (asct.first_name.toLowerCase().includes(searchText.toLowerCase())) {
          return true;
        }
        return false;
      });
      if (filtered.length === 0) {
        setFilterAsct([]);
      } else setFilterAsct([...filtered]);
    }
  }, [associateData, searchText]);

  const saveAssociateAccess = () => {
    setDisableSave(true);

    (async () => {
      const reqData = await importLargeData(
        'clients',
        props.auth.merchantId,
        {
          userList: props.client.selected_client_list,
          associateAccess,
        },
        'saveAssociateData',
      );
      if (reqData) {
        setTimeout(() => {
          notificationSuccess('Assigned Associates Saved!');
          setDisableSave(false);
          props.showAssociateManagerHandler(false);
          setSelectedClientList([]);
          props.getClients();
        }, 2000);
      } else {
        notificationError('Error Saving Assigned Associates');
        setDisableSave(false);
      }
    })();
  };

  const rowClickHandler = id => {
    if (associateAccess.hasOwnProperty(id)) {
      if (associateAccess[id] === true) {
        setAssociateAccess({ ...associateAccess, [id]: false });
      } else if (associateAccess[id] === false) {
        let existingData = false;
        associateData.forEach(tg => {
          if (tg.id === id) {
            if (Number(tg.cnt) > 0 && Number(tg.cnt) < props.client.selected_client_list.length) {
              existingData = true;
            }
          }
        });
        if (existingData === true) {
          const tempObj = associateAccess;
          delete tempObj[id];
          setAssociateAccess({ ...tempObj });
        } else {
          setAssociateAccess({ ...associateAccess, [id]: true });
        }
      }
    } else {
      let existingData = false;
      associateData.forEach(asct => {
        if (asct.id === id) {
          if (Number(asct.cnt) === props.client.selected_client_list.length) {
            existingData = true;
          }
        }
      });
      if (existingData === true) {
        setAssociateAccess({ ...associateAccess, [id]: false });
      } else {
        setAssociateAccess({ ...associateAccess, [id]: true });
      }
    }
  };

  const searchHandler = val => {
    setSearchText(val);
  };

  const asctCircle = asct => {
    if (associateAccess.hasOwnProperty(asct.id)) {
      if (!associateAccess[asct.id]) {
        return <FontAwesomeIcon icon="fa-regular fa-circle" className="fs-18 m-right-15" color="#000" />;
      }
      return <FontAwesomeIcon icon="fas fa-check-circle" className="fs-18 m-right-15" color="#000" />;
    }
    if (Number(asct.cnt) === 0) {
      return <FontAwesomeIcon icon="fa-regular fa-circle" className="fs-18 m-right-15" color="#000" />;
    }
    if (Number(asct.cnt) === props.client.selected_client_list.length) {
      return <FontAwesomeIcon icon="fas fa-check-circle" className="fs-18 m-right-15" color="#000" />;
    }
    return <FontAwesomeIcon className="fs-18 m-right-15" icon={['far', 'adjust']} />;
  };

  return (
    <div className="pop-confirm-background" id="modal-background">
      <div className="pop-confirm-container rem-pad-width" style={{ width: '540px', height: '748px' }}>
        <div
          className="flex-col-center h-100-P"
          style={{
            justifyContent: 'flex-start',
            width: '450px',
            margin: '0 auto',
          }}
        >
          <div className="flex-row-spacebetween-nowrap w-100-P m-top-15">
            <div />
          </div>
          <span className="fs-18 fw-600" style={{ marginTop: '15px' }}>
            Edit Client Assigned Associates
          </span>
          <div className="flex-row-spacebetween-nowrap w-100-P">
            <div className="add-edit-wrap m-top-15 fw-600" style={{ width: '185px' }}>
              <FontAwesomeIcon icon="fa-light fa-users" className="fs-18 m-right-15 fw-600" color="#000" />
              <span>{`Recipients: ${props.client?.selected_client_list.length}`}</span>
            </div>
            <div className="align-right m-top-15">
              <Tooltip
                overlayInnerStyle={{ borderRadius: '5px', textAlign: 'center' }}
                placement="bottom"
                title="All selected clients are assigned"
              >
                <FontAwesomeIcon icon="fas fa-check-circle" className="fs-18 m-right-15" color="#000" />
              </Tooltip>
              <Tooltip
                overlayInnerStyle={{ borderRadius: '5px', textAlign: 'center' }}
                placement="bottom"
                title="Some of the selected clients are assigned"
              >
                <FontAwesomeIcon className="fs-18 m-right-15" icon={['far', 'adjust']} />
              </Tooltip>
              <Tooltip
                overlayInnerStyle={{ borderRadius: '5px', textAlign: 'center' }}
                placement="bottom"
                title="None of the selected clients are assigned"
              >
                <FontAwesomeIcon icon="fa-regular fa-circle" className="fs-18" color="#000" />
              </Tooltip>
            </div>
          </div>
          <input
            placeholder="Search"
            value={searchText}
            onChange={e => searchHandler(e.target.value)}
            className="h-50 w-100-P m-top-10 m-btm-6 clientMultiAsctSearch rem-pad-width focus-input-outline-blue"
            style={{
              width: '100%',
              height: '50px',
              backgroundColor: '#EBEBEB',
            }}
          />

          <div
            style={{
              paddingTop: '25px',
              height: '600px',
              paddingLeft: '10px',
              width: '450px',
            }}
            className="rem-pad-width chatBubblePadding overflowY hide-scrollbar"
          >
            <ul className="ul-no-style flex-col-left ">
              {filterAsct
                .filter(asct => asct.id !== 0)
                .map(asct => {
                  return (
                    <li
                      key={asct.id}
                      className="fs-14 flex-row-nospacebetween-nowrap m-btm-12 pointer w-100-P p-btm-13"
                      onClick={() => rowClickHandler(asct.id)}
                      style={{ borderBottom: 'solid .5px #EBEBEB' }}
                    >
                      {asctCircle(asct)}
                      <div className="flex-col-left">
                        <span className="fs-13 black align-left fw-400">
                          {asct.first_name} {asct.last_name}
                        </span>
                        <span className="fs-10 gray align-left">{asct.email}</span>
                      </div>
                    </li>
                  );
                })}
              {loading && <Loading />}
            </ul>
          </div>
          <div className="mq-w-100-vw custom-action-save-flex m-top-30 m-btm-28">
            <button type="button" className="save-button-v2 m-btm-8" onClick={saveAssociateAccess}>
              Save
            </button>
            <button
              type="button"
              className="cancel-button-v2"
              onClick={() => {
                setSelectedClientList([]);
                props.showAssociateManagerHandler(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiAssignedAssociateModal;

const Loading = () => {
  return <h4 style={{ margin: '12px 16px' }}>Loading...</h4>;
};
