import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import Table from '../../../Layout/Table';
import { getRequest } from '../../../../core/apiRequests';
import { getTablePaginationData } from '../../../../core/actions';

const tableName = 'WHATS_APP_INBOX';

const WhatsApp = props => {
  const [searchText, setSearchText] = useState('');
  const isMounted = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  // watches the window width and sets to state, unmounts when page changes
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    isMounted.current = true;
  }, [
    props.auth.stores,
    props.auth.storeId,
    props.pagination.sortColumn,
    props.pagination.pageSize,
    props.pagination.page,
  ]);

  useEffect(() => {
    if (props.auth.merchantId && props.auth.enabled_whatsapp) {
      (async () => {
        const reqObj = {
          params: ['chatMessages', props.auth.merchantId, 'allTemplates'],
        };
        const data = await getRequest(reqObj);
        setTemplates(data);
        setFilteredTemplates(data);
      })();
    } else {
      props.history.push('/');
    }
  }, [props.auth.merchantId]);

  const listFormatter = (t, o) => {
    {
      // t = text, o = obj.
      const subsObj = {}; // an obj that will look like {1: client, 2: product, 3: something, 4: whatevz}
      if (o.substitution) {
        // check for null columns
        const substitutes = o.substitution.split(','); // they're comma delimited, make an array
        substitutes.forEach(sub => {
          // loop over that array ^ , each sub looks something like 1=CLIENT or 2=PRODUCT
          const propertyDivide = sub.split('='); // split them up on that equals sign into an array
          if ((propertyDivide.length = 2)) {
            // each propertyDivide looks like this: [1, "CLIENT"] or [2, "PRODUCT"], etc...
            subsObj[propertyDivide[0]] = propertyDivide[1].toLowerCase(); // put them on that subsObj, and lowercase the values... we're rendering them later!
          }
        });
      }
      return t.replace(/{{\d}}/g, val => {
        // look for {{#}}
        const theNumber = val.replace(/[{}]/g, ''); // if found, remove all {'s and }'s
        if (subsObj[theNumber]) {
          // using the number, check if the property exists on the substitutes object
          return `[${subsObj[theNumber]}]`; // if so return it
        }
        return '[N/A]'; // else return this lol extreme edge case, I don't know.
      });
    }
  };

  const searchFunctionality = searchText => {
    if (searchText) {
      const searchArray = searchText.split(' ');
      const filteredArr = [];
      templates.forEach(tmpl => {
        let hasIt = true;
        searchArray.forEach(sText => {
          const sTextFm = sText.toLowerCase().trim();
          const templateTxt = tmpl.template || '';
          const subTxt = tmpl.substitution || '';
          if (!templateTxt.toLowerCase().includes(sTextFm) && !subTxt.toLowerCase().includes(sTextFm)) {
            hasIt = false;
          }
        });
        if (hasIt) {
          filteredArr.push(tmpl);
        }
      });
      setFilteredTemplates(filteredArr);
    } else {
      setFilteredTemplates(templates);
    }
  };

  const tableHeaders = [
    {
      keyName: 'template',
      style: { maxWidth: '10%', minWidth: '30px', paddingBottom: '13px', paddingTop: '7px' },
      headerClassName: 'no-text-select',
      title: `Templates (${filteredTemplates.length})`,
      rowClassName: 'no-text-select',
      render: (t, o) => listFormatter(t, o),
    },
  ];

  return (
    <Layout
      title="WhatsApp Templates"
      description="Your company's templates are managed through your WhatsApp account. This is read-only."
      classProps="standard-width-with-sidebar rem-pad-width"
      breadCrumbs={[{ title: 'Messaging' }, { title: 'WhatsApp Templates' }]}
    >
      <div className="h-100-P pos-rel" style={{ maxWidth: '753px' }}>
        <div className="inboxHeader m-top-8">
          <input
            onChange={e => searchFunctionality(e.target.value)}
            className="searchInput h-50 bg-offwhite fs-13 focus-input-outline-blue"
            style={{ width: '100%', marginBottom: '-11px' }}
            placeholder="Search WhatsApp templates..."
          />
        </div>

        <Table
          tableClassName="lg-table-sidebar  rem-pad-width"
          data={filteredTemplates}
          tableHeaders={tableHeaders}
          searchable={false}
          optionalButton={false}
          noPointer
          tableName={tableName}
        />
      </div>
      <SecondaryPanel title={['Messaging']} current="WhatsApp" />
    </Layout>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
  messaging: state.messaging,
  pagination: getTablePaginationData(state, tableName),
  client: state.client,
});
export default connect(mapStateToProps, {})(WhatsApp);
