import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Timeline } from 'antd-v5';
import { PurchaseOutline, MassMessages } from '../../../../../componentsV2/Layout/Report/components/Icons';
import TimelineItem from '../../../../../componentsV2/Layout/Report/components/Timeline';
import { getRequest } from '../../../../../core/apiRequests';
import SalesItem from '../../../../../componentsV2/Layout/Report/components/SalesTimelineItem';

const MassMessageExpandRow = ({ transactions, activity_associate_name, activity_date, audience, chat_message_id  }) => {

  const [loading, setLoading] = useState(true);
  const [activitiesData, setActivitiesData] = useState([]);
  const [massMessageData, setMassMessageData] = useState({
    activity_associate_name,
    activity_date,
    audience,
    chat_message_id
  });

  const getReportAttributedData = useCallback(() => {
    setLoading(true)
    const reqObj = {
      params: ['report', 'attributedMeta'],
      query: {
        reminders: JSON.stringify([]),
        chatMessages: JSON.stringify([chat_message_id]),
        salesTransactions: JSON.stringify(transactions.map(item => item.sales_transaction_id))
      },
      loadingStatusOff: true
    }
    getRequest(reqObj).then((res) => {
      const msg = res.filter(item => item.type === 'chatMessage')
      const acts = res.filter(item => item.type === 'salesTransaction').map(item => {
        const findTransaction = transactions.find(transaction => transaction.sales_transaction_id === item.id)
        return {
          ...item,
          ...findTransaction
        }
      })
      if (msg.length > 0) {
        setMassMessageData({
          activity_associate_name,
          activity_date,
          audience,
          chat_message_id,
          msg: msg[0].msg
        })
      }
      setActivitiesData(acts)
    }).finally(() => {
      setLoading(false)
    });
  }, [transactions])

  useEffect(() => {
    getReportAttributedData();
  }, [transactions, chat_message_id])

  return (
    <div className="m-left-23">
      <div className="flex-row-spacebetween-nowrap">
        <Timeline
          pending={loading ? 'Loading...' : null}
          items={[
            {
              children: (
                <MessageItem
                  {...massMessageData}
                />
              ),
              dot: <MassMessages style={{ fontSize: '18px', opacity: 0.88}} />
            },
            ...activitiesData.map(activity => ({
              children: (
                <SalesItem
                  client_name={activity.client_name}
                  sales_transaction_amount={activity.sales_transaction_amount}
                  description={activity.description}
                  activity_date={activity.sales_transaction_date}
                />
              ),
              dot: <PurchaseOutline style={{ color: '#389E0D', fontSize: '18px'}} />
            }))
          ]}
        />
      </div>
    </div>
  );
};
export default MassMessageExpandRow;

const MessageItem = ({activity_associate_name, activity_date, audience, msg}) => {
  return (
    <TimelineItem
      title={<>
        <span className="fw-600">{activity_associate_name || 'An Associate'} </span>sent a mass message to
        <span className="fw-600"> {audience} recipients</span> on
        <span className="fw-600"> {moment(activity_date).format('MMM DD, YYYY')}</span>
      </>}
      description={msg}
    />
  )
}

