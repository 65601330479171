import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import Layout from '../../../Layout/StandardLayout';
import DropdownSelect from '../../../Layout/DropdownSelect';
import Button from '../../../Layout/Button';
import { getRequest } from '../../../../core/apiRequests';
import TitleDescription from '../../../Layout/DescriptionBox';
import Table from '../../../Layout/Table';
import { setAuthStoreId } from '../../../../reducers/auth';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import StyledLink from '../../../Layout/StyledLink';
import '../reports.css';

const SalesPipelineByAssociate = props => {
  const [tableHeadData, setTableHeadData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showReport, setShowReport] = useState(false);

  const runReport = () => {
    const associates = {};
    const tags = {};

    setShowReport(false);

    let rawdata = [];

    function resetReport() {
      rawdata.forEach(row => {
        if (!associates[`${row.associate}`]) {
          associates[`${row.associate}`] = {};
          associates[`${row.associate}`].name = row.associate;
          associates[`${row.associate}`].id = row.id;
        }
        associates[`${row.associate}`][`${row.stage}`] = row.count;
        if (!tags[`${row.stage}`]) {
          tags[`${row.stage}`] = true;
        }
      });

      const tempTableHeaders = [
        {
          keyName: 'name',
          headerClassName: 'row1',
          rowClassName: 'col-p-l-25',
          title: 'Associates',
          style: { width: '5%', paddingLeft: '20px', paddingTop: '80px' },
          render: (t, o) => (
            <StyledLink to={`/Associates/associate-details/${o.id}`}>
              {t || `${o.first_name || ''} ${o.last_name || ''}`.trim()}
            </StyledLink>
          ),
        },
      ];

      const tempAssocArray = [];

      Object.keys(associates).forEach(key => {
        tempAssocArray.push(associates[key]);
      });

      setTableData(tempAssocArray);

      Object.keys(tags).forEach(key => {
        tempTableHeaders.push({
          keyName: key,
          style: { width: '5%', paddingTop: '80px' },
          headerClassName: 'tags_row2',
          title: key,
          sortable: false,
        });
      });

      setTableHeadData(tempTableHeaders);
      setShowReport(true);
    }

    (async () => {
      const reqObj = {
        params: ['report', props.auth.merchantId, props.auth.storeId, 'salesPipelineByAssociate'],
        query: {
          selected_store_id: props.auth.storeId,
          storeAccess: props.auth.stores.filter(st => st.id !== 0).map(st => st.id),
        },
      };
      const data = await getRequest(reqObj);
      rawdata = data;
      resetReport();
    })();
  };

  return (
    <Layout
      title="Associate Sales Pipeline"
      backArrow="/Home"
      description="Set your parameters and click on 'Run Report.'"
      classProps="standard-width-with-sidebar flex-col-center"
      styleProps={{ justifyContent: 'flex-start' }}
    >
      <div className="report-smaller-description">
        {' '}
        Associate Sales Pipeline reports allow you to see which pipelines and sales stages your associates are hitting.
        Each report will measure how many opportunities each associate has in a particular stage.{' '}
      </div>
      <div className="pos-rel w-100-P " style={{ maxWidth: '866px' }}>
        <TitleDescription
          title="Reporting Location"
          description="What store location would you like to run a report on?"
          input={
            <DropdownSelect
              isSearchable
              classProps="mq-w-200 w-267 align-left"
              options={props.auth.stores.map(st => ({
                label: st.name,
                value: st.id,
              }))}
              placeholder="Select a Store..."
              value={props.auth.storeId}
              onChange={obj => {
                setAuthStoreId(obj.value);
              }}
            />
          }
        />
        <div className="report-asct-msg-row" style={{ maxWidth: '866px' }}>
          <Button id="RunSalesPipelineByAssociateReport" classname="darkBlueButton" onclick={() => runReport()}>
            Run Report
          </Button>
        </div>
      </div>
      {showReport ? (
        <div className="w-100-P rem-pad-width maw-1200">
          <div
            className="import_export_group w-100-P"
            style={{
              justifyContent: 'flex-end',
              zIndex: '100',
              marginLeft: '0px',
              marginTop: '100px',
            }}
          >
            <img src="https://dashboard-v2-images.s3.amazonaws.com/ic-export.svg" alt="export" />

            <CSVLink
              data={tableData}
              filename="SalesPipelineByAssociate.csv"
              id="DownloadSalesPipelineByAssociateReport"
              className="exportButton m-right-15"
            >
              Download
            </CSVLink>
          </div>

          <Table
            tableClassName="maw-1200 rem-pad-width m-top-15 overflowX w-100-P "
            tableContainerClass="maw-1200 w-100-P rem-pad-width "
            actualTableClassName="table-scroll"
            style={{ marginTop: '-100px', width: '100%' }}
            data={tableData}
            tableRowClassName="col-p-l-25"
            tableHeaders={tableHeadData}
            tableName="SALES_PIPELINE_BY_ASSOC_REPORT"
          />
        </div>
      ) : null}
      <SecondaryPanel
        overhead_title="Reports"
        title={['CLIENTELING', 'MESSAGING', 'Google Reviews', 'PAYMENTS', 'AUTOMATION', 'SALES OPPORTUNITY']}
        current="Associate Sales Pipeline"
      />
    </Layout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  reports: state.reports,
  stores: state.stores,
  salesPipeline: state.salesPipeline,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SalesPipelineByAssociate);
