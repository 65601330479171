import React, { useEffect, useState, useMemo } from 'react';
import { Pagination as AntDesignPagination } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import './styles.css';

const PAGE_SIZE_OPTIONS = [25, 50, 100, 200];

const itemRender = (_, type, page) => {
  if (type === 'prev') {
    return <CaretLeftOutlined />;
  }

  if (type === 'next') {
    return <CaretRightOutlined />;
  }

  return page;
};

const PaginationV2 = props => {
  const { page, pageSize, total, setPage, setPageSize, ...rest } = props;

  const parsedPage = useMemo(() => Number(page), [page]);
  const parsedPageSize = useMemo(() => Number(pageSize), [pageSize]);
  const parsedTotal = useMemo(() => Number(total), [total]);

  const [currentPage, setCurrentPage] = useState(parsedPage);
  const [currentPageSize, setCurrentPageSize] = useState(parsedPageSize);

  const showSizeChanger = useMemo(() => Math.ceil(parsedTotal / 25) > 1, [parsedTotal, parsedPageSize]);
  const showQuickJumper = useMemo(() => Math.ceil(parsedTotal / parsedPageSize) > 1, [parsedTotal, parsedPageSize]);

  useEffect(() => setCurrentPage(parsedPage), [parsedPage]);
  useEffect(() => setPage(currentPage), [currentPage]);

  useEffect(() => setCurrentPageSize(parsedPageSize), [parsedPageSize]);
  useEffect(() => setPageSize(currentPageSize), [currentPageSize]);

  return (
    <div className="cb-pagination-v2">
      <AntDesignPagination
        {...rest}
        size="small"
        current={currentPage}
        total={parsedTotal}
        pageSize={parsedPageSize}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        showSizeChanger={showSizeChanger}
        showQuickJumper={showQuickJumper}
        itemRender={itemRender}
        onChange={setCurrentPage}
        onShowSizeChange={(_, value) => setCurrentPageSize(value)}
      />
    </div>
  );
};

export default PaginationV2;
