export const defaultState = {
  bankAccountNumber: '',
  bankAccountType: '',
  bankMinimalPayoutAmount: '',
  bankPayoutCurrency: '',
  bankRefundReserve: '',
  bankRoutingNumber: '',
  bankState: '',
  businessAddress: '',
  businessCategory: '',
  businessCity: '',
  businessCountry: '',
  businessEmail: '',
  businessLegalName: '',
  businessName: '',
  businessPhone: '',
  businessProductAndServiceDesc: '',
  businessSalesVolume: '',
  businessState: '',
  businessTaxId: '',
  businessType: '',
  businessWebsite: '',
  businessZip: '',
  companyRepAddress: '',
  companyRepCity: '',
  companyRepCountry: '',
  companyRepDateOfBirth: '',
  companyRepFirstName: '',
  companyRepGovID: '',
  companyRepLastName: '',
  companyRepPhone: '',
  companyRepState: '',
  companyRepZip: '',
  merchantIp: '',
  owners: [],
  pricingAgreementDate: '',
  serviceAgreementDate: '',
  sourceDomain: '',
};

const reducer = (state, action) => {
  const state2 = state || { ...defaultState };

  switch (action.type) {
    case 'SET_BANK_ACCOUNT_NUMBER':
      return {
        ...state2,
        bankAccountNumber: action.payload,
      };
    case 'SET_BANK_ACCOUNT_TYPE':
      return {
        ...state2,
        bankAccountType: action.payload,
      };
    case 'SET_BANK_MINIMAL_PAYOUT_AMOUNT':
      return {
        ...state2,
        bankMinimalPayoutAmount: action.payload,
      };
    case 'SET_BANK_PAYOUT_CURRENCY':
      return {
        ...state2,
        bankPayoutCurrency: action.payload,
      };
    case 'SET_BANK_REFUND_RESERVE':
      return {
        ...state2,
        bankRefundReserve: action.payload,
      };
    case 'SET_BANK_ROUTING_NUMBER':
      return {
        ...state2,
        bankRoutingNumber: action.payload,
      };
    case 'SET_BANK_STATE':
      return {
        ...state2,
        bankState: action.payload,
      };
    case 'SET_BUSINESS_ADDRESS':
      return {
        ...state2,
        businessAddress: action.payload,
      };
    case 'SET_BUSINESS_CATEGORY':
      return {
        ...state2,
        businessCategory: action.payload,
      };
    case 'SET_BUSINESS_CITY':
      return {
        ...state2,
        businessCity: action.payload,
      };
    case 'SET_BUSINESS_COUNTRY':
      return {
        ...state2,
        businessCountry: action.payload,
      };
    case 'SET_BUSINESS_EMAIL':
      return {
        ...state2,
        businessEmail: action.payload,
      };
    case 'SET_BUSINESS_LEGAL_NAME':
      return {
        ...state2,
        businessLegalName: action.payload,
      };
    case 'SET_BUSINESS_NAME':
      return {
        ...state2,
        businessName: action.payload,
      };
    case 'SET_BUSINESS_PHONE':
      return {
        ...state2,
        businessPhone: action.payload,
      };
    case 'SET_BUSINESS_PRODUCT_AND_SERVICE_DESC':
      return {
        ...state2,
        businessProductAndServiceDesc: action.payload,
      };
    case 'SET_BUSINESS_SALES_VOLUME':
      return {
        ...state2,
        businessSalesVolume: action.payload,
      };
    case 'SET_BUSINESS_STATE':
      return {
        ...state2,
        businessState: action.payload,
      };
    case 'SET_BUSINESS_TAX_ID':
      return {
        ...state2,
        businessTaxId: action.payload,
      };
    case 'SET_BUSINESS_TYPE':
      return {
        ...state2,
        businessType: action.payload,
      };
    case 'SET_BUSINESS_WEBSITE':
      return {
        ...state2,
        businessWebsite: action.payload,
      };
    case 'SET_BUSINESS_ZIP':
      return {
        ...state2,
        businessZip: action.payload,
      };
    case 'SET_COMPANY_REP_ADDRESS':
      return {
        ...state2,
        companyRepAddress: action.payload,
      };
    case 'SET_COMPANY_REP_CITY':
      return {
        ...state2,
        companyRepCity: action.payload,
      };
    case 'SET_COMPANY_REP_COUNTRY':
      return {
        ...state2,
        companyRepCountry: action.payload,
      };
    case 'SET_COMPANY_REP_DATE_OF_BIRTH':
      return {
        ...state2,
        companyRepDateOfBirth: action.payload,
      };
    case 'SET_COMPANY_REP_FIRST_NAME':
      return {
        ...state2,
        companyRepFirstName: action.payload,
      };
    case 'SET_COMPANY_REP_GOV_ID':
      return {
        ...state2,
        companyRepGovID: action.payload,
      };
    case 'SET_COMPANY_REP_LAST_NAME':
      return {
        ...state2,
        companyRepLastName: action.payload,
      };
    case 'SET_COMPANY_REP_PHONE':
      return {
        ...state2,
        companyRepPhone: action.payload,
      };
    case 'SET_COMPANY_REP_STATE':
      return {
        ...state2,
        companyRepState: action.payload,
      };
    case 'SET_COMPANY_REP_ZIP':
      return {
        ...state2,
        companyRepZip: action.payload,
      };
    case 'SET_MERCHANT_IP':
      return {
        ...state2,
        merchantIp: action.payload,
      };
    case 'SET_OWNERS':
      return {
        ...state2,
        owners: action.payload,
      };
    case 'SET_PRICING_AGREEMENT_DATE':
      return {
        ...state2,
        pricingAgreementDate: action.payload,
      };
    case 'SET_SERVICE_AGREEMENT_DATE':
      return {
        ...state2,
        serviceAgreementDate: action.payload,
      };
    case 'SET_SOURCE_DOMAIN':
      return {
        ...state2,
        sourceDomain: action.payload,
      };
    default:
      return state2;
  }
};

export default reducer;
