import React, { useCallback } from 'react';
import { getRequest } from '../../../../../../../core/apiRequests';
import { notificationError, notificationSuccess } from '../../../../../../../core/actions';
import { Avatar, Button } from 'antd';
import { COLLECTION_API_URL } from '../../../../../../../constants';


const ShareCollectionModal = ({onClose, collection, client, auth, changeShareCollectionState}) => {

  const getInitials = useCallback((name) => {
    return name?.split(' ')?.map(word => word.substr(0, 1))?.join('')?.slice(0, 2);
  }, [])

  const copyBoard = useCallback(() => {
    navigator.clipboard.writeText(`${COLLECTION_API_URL}/${client.client_id}/${collection.collection_id}`)
    notificationSuccess('Copied to clipboard');
  }, [navigator]);

  const shareCollection = useCallback(async (type, relatedId, relatedName) => {
    if(type === 'client') {
      const reqObj = {
        params: ['collections', client.client_id, collection.collection_id, 'shareCollectionClient'],
        query: { associateId: auth.userId, merchantId: auth.merchantId }
      };
      const dataArr = await getRequest(reqObj);
      if (dataArr) {
        notificationSuccess(`"${collection.collection_name}" sent to ${client.name}`);
      } else {
        notificationError(`Share unsuccessful. Please try again in a few minutes`);
      }
    } else if (type === 'relationship' && relatedId && relatedName){
      const reqObj = {
        params: ['collections', client.client_id, collection.collection_id, relatedId, 'shareCollection'],
        query: { associateId: auth.userId, merchantId: auth.merchantId }
      };
      const dataArr = await getRequest(reqObj);
      if (dataArr) {
        notificationSuccess(`"${collection.collection_name}" sent to ${relatedName}`);
      } else {
        notificationError(`Share unsuccessful. Please try again in a few minutes`);
      }
    } else {
      notificationError(`Share unsuccessful. Please try again in a few minutes`);
    }
    changeShareCollectionState();
    onClose();
  }, [collection, client, auth])

  return (
    <div
      className="pop-confirm-background"
      id="modal-background"
      onClick={e => {
        if (e.target.id === 'modal-background') {
          onClose();
        }
      }}
    >
      <div
        className="pop-confirm-container rem-pad-width "
        style={{ width: '356px', minHeight: '278px', maxHeight: '520px', overflowY: 'hidden' }}
      >
        <div
          className="flex-col-center"
          style={{
            justifyContent: 'flex-start',
            width: '310px',
            margin: '0',
            padding: '0 18px 12px 28px',
            maxHeight: '500px'
          }}
        >
            <div className="flex-row-spacebetween-nowrap w-100-P m-top-15" />
            <span className="fs-18 fw-600" style={{ marginTop: '15px', marginBottom: '16px' }}>
              Share
            </span>
            <div className="flex-col-left w-100-P scrollbar-y" >
              <ShareItem
                avatar={
                  <img
                    src="https://dashboard-v2-images.s3.amazonaws.com/link-solid.svg"
                    style={{ width: '20px', height: '16px' }}
                  />
                }
                name='Share Link'
                buttonLabel='Copy'
                onClick={copyBoard}
              />
              <ShareItem
                avatar={getInitials(client.name)}
                name={client.name}
                relationship='Client'
                onClick={() => shareCollection('client')}
                style={{marginTop: '24px'}}
              />
              {client.relationships.length > 0 && (
                <>
                  <p className='fs-12 m-top-28'>Share with family or friends</p>
                  {client.relationships.map((rel, index) => {
                    let relationshipName = rel?.client_id !== client?.client_id ? rel?.related_client_name : rel?.client_name;
                    let relationship = `${rel?.relationship}${rel?.client_id !== client?.client_id ? '' : ' of'}`;
                    let relatedId = rel?.client_id !== client?.client_id ? rel?.related_client_id : rel?.client_id;
                    return (
                      <ShareItem
                        key={rel.id}
                        avatar={getInitials(relationshipName)}
                        name={relationshipName}
                        relationship={relationship}
                        onClick={() => shareCollection('relationship', relatedId, relationshipName)}
                        style={{marginTop: index === 0 ? '2px' : '24px'}}
                      />
                  )})}
                </>
              )}
            </div>
          <div className="mq-w-100-vw custom-action-save-flex" style={{ marginTop: '38px' }}>
            <button className="save-button-v2 m-btm-20" onClick={onClose} >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareCollectionModal;

const ShareItem = ({avatar, name, relationship, buttonLabel = 'Share', onClick, style = {}}) => {
  return (
    <div className='flex-row-spacebetween-nowrap w-100-P' style={{...style}}>
      <div className='flex-row-nospacebetween-nowrap' style={{ gap: '16px'}}>
        <Avatar size="large" style={{minWidth: '40px', background: '#BDBDBD'}}>
          {avatar}
        </Avatar>
        <div className='flex-col-left'>
          <span className='fw-500 fs-13 line-clamp-1 align-left'>{name}</span>
          {relationship && <span className='fs-12 line-clamp-1 align-left'>{relationship}</span>}
        </div>
      </div>
      <Button type="link" onClick={onClick} style={{fontSize: '13px', fontWeight: 500}}>
        {buttonLabel}
      </Button>
    </div>
  )
}
