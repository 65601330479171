/* eslint-disable react/no-danger */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import reactStringReplace from 'react-string-replace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import MessageSentDate from '../components/MessageSentDate';
import SentMessages from './SentMessages';
import ReceivedMessages from './ReceivedMessages';
import ProductImage from './ProductImage';
import { EMAIL_REGEX, URL_REGEX } from '../../../../constants/regex';
import Collection from './Collection';
import ChatVideo from './ChatVideo';

function replaceWithBr(msg) {
  return msg?.replace(/\n/g, '<br />') ?? '';
}

export const msgListDateOrganizer = (
  list,
  color,
  userId,
  merchantId,
  storesMap,
  payments,
  type,
  scrollToBottom,
  allStoreConversationHistory,
) => {
  let delivered = false;
  let failed = false;
  list.forEach(item => {
    if (item.status === 'delivered' && !delivered) {
      item.delivered = true;
      delivered = true;
      return;
    }
    if (item.errorCode || ((item.status === 'failed' || item.status === 'undelivered') && !failed)) {
      item.deliveryFailed = true;
      failed = true;
      return;
    }
    if (delivered === true) {
      item.delivered = false;
    }
    if (failed === true) {
      item.deliveryFailed = false;
    }
  });
  const combinedList = [...list, ...payments];
  const msgObj = {};
  const returnList = [];
  combinedList.forEach(msg => {
    if (msg.sentTimestamp) {
      msg.storeName = storesMap[msg.storeId];
      // I think Im just gonna reject messages without a sent time, otherwise where do I put it?
      const parsedDate = `${moment(msg.sentTimestamp).format('YYYYMMDD')}`;
      if (!msgObj.hasOwnProperty(parsedDate)) msgObj[parsedDate] = [];
      if (msg.id) {
        msgObj[parsedDate].unshift({
          ...msg,
          timeOffsetAdd: moment(msg.sentTimestamp).format('YYYYMMDDHHmmss'),
        });
      } else {
        msgObj[parsedDate].unshift(msg);
      }
    }
  });
  Object.keys(msgObj).forEach((o, oInd) => {
    returnList.unshift(dateElement(o));
    if (msgObj[o].length > 0) {
      msgObj[o].forEach((m, ind) => {
        returnList.unshift(
          messageTypeSwitch(
            m,
            `o-${oInd}:i-${ind}`,
            color,
            userId,
            merchantId,
            type,
            scrollToBottom,
            allStoreConversationHistory,
          ),
        );
      });
    }
  });

  return returnList;
};

function facebookTypeSwitch(msg, index, color, merchantId, type, scrollToBottom, allStoreConversationHistory) {
  let msgTypeFn;
  if (msg.attachments.length) {
    const [attachment] = msg.attachments;
    if (attachment.type === 'video') {
      msg.videoUrl = attachment.url;
      msgTypeFn = videoElement;
    } else if (attachment.type === 'image') {
      msg.imageUrl = attachment.url;
      if (msg.isInbound) {
        msgTypeFn = receivedImageElement;
      } else {
        msgTypeFn = sentImageElement;
      }
    }
  } else if (msg.isInbound) {
    msgTypeFn = receivedMsgElement;
  } else {
    msgTypeFn = sentMsgElement;
  }
  return msgTypeFn(msg, index, color, merchantId, type, scrollToBottom, allStoreConversationHistory);
}

function messageTypeSwitch(msg, index, color, userId, merchantId, type, scrollToBottom, allStoreConversationHistory) {
  if (msg.type === 'F') {
    return facebookTypeSwitch(msg, index, color, merchantId, type, scrollToBottom, allStoreConversationHistory);
  }
  let msgTypeFn;
  if (msg.imageUrl) {
    if (msg.userId === msg.clientId) {
      if (msg.userId !== msg.id) {
        msgTypeFn = receivedImageElement;
      } else return;
    } else {
      msgTypeFn = sentImageElement;
    }
  } else if (msg.videoUrl) msgTypeFn = videoElement;
  else if (msg.productId) msgTypeFn = productElement;
  else if (msg.collectionId) msgTypeFn = collectionElement;
  else if (msg.userId === msg.clientId && !msg.imageUrl) msgTypeFn = receivedMsgElement;
  else if (msg.userId !== msg.clientId && msg.userId !== userId && !msg.imageUrl) msgTypeFn = otherAsctSentElement;
  else msgTypeFn = sentMsgElement;
  return msgTypeFn(msg, index, color, merchantId, type, scrollToBottom, allStoreConversationHistory);
}

// function sentChatBuilder

function dateElement(date) {
  return MessageSentDate(date);
}

function sentMsgElement(o, i) {
  const formattedMsgContainer = [];
  const urlMatchShow = o.message?.match(URL_REGEX);

  if (urlMatchShow) {
    const message = replaceWithBr(o.message).replaceAll(URL_REGEX, ';%;');
    let index = 0;

    formattedMsgContainer.push(
      <div className="singleMessageWrapper align-right" key={`sent${i}`}>
        <ol className="shared maw-50-P m-right-13" id="sentLink" style={{ marginBottom: '3px', marginTop: 0 }}>
          <div className="sentTextDiv">
            <li className="sent m-btm-10">
              <span
                className="align-left d-block"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: reactStringReplace(message, ';%;', () => {
                    const url = urlMatchShow[index];
                    index += 1;
                    return url ? `<a target="_blank" style="z-index: 20" href="${parseHrefMessage(url)}" rel="noreferrer">${url}</a>` : '';
                  }).join(''),
                }}
              />
            </li>
            <span className="align-right fs-10 italic chatDateSentLink">
              {moment(o.sentTimestamp).format('hh:mm a')}
            </span>
          </div>
          <img
            className="blueTail"
            src="https://dashboard-v2-images.s3.amazonaws.com/inboxV2blueTail.svg"
            alt="conversation style tail"
          />
        </ol>
      </div>,
    );
  } else
    formattedMsgContainer.push(
      <div className="singleMessageWrapper align-right" key={`sent${i}`}>
        <div className="align-right flex-row-rev-center">
          <div className="flex-col-center">
            {(o.status === 'failed' || o.status === 'undelivered') && (
              <InfoCircleOutlined style={{ color: '#dc3545', fontSize: 'large' }} />
            )}
          </div>
          <ol className="shared maw-50-P m-right-13" id="sent" style={{ marginBottom: '3px', marginTop: 0 }}>
            <div className="sentTextDiv">
              <li className="sent p-right-6" dangerouslySetInnerHTML={{ __html: replaceWithBr(o?.message) }} />
              <span className="w-50" />
            </div>
            <span className="align-right fs-10 italic chatDate">{moment(o.sentTimestamp).format('hh:mm a')}</span>
            <img
              className="blueTail"
              src="https://dashboard-v2-images.s3.amazonaws.com/inboxV2blueTail.svg"
              alt="conversation style tail"
            />
          </ol>
        </div>
        {o.deliveryFailed ? (
          <span className="red fs-10 m-right-25 italic">Delivery Failed</span>
        ) : (
          <span className="text-light-gray fs-10 m-right-25 italic">{/* {o.delivered && 'Delivered'} */}</span>
        )}
      </div>,
    );
  return formattedMsgContainer;
}

function otherAsctSentElement(o, i, _c, _mId, _t, _sb, allStoreConversationHistory) {
  const fromOtherAsct = true;
  return baseLeftMsg(o, i, '#BDBDBD', fromOtherAsct, '#FFF', allStoreConversationHistory);
}

function receivedMsgElement(o, i, _c, _mId, _t, _sb, allStoreConversationHistory) {
  const fromOtherAsct = false;
  return baseLeftMsg(o, i, '#EBEBEB', fromOtherAsct, '#818181', allStoreConversationHistory);
}

function baseLeftMsg(o, i, avatarColor, fromOtherAsct, color, allStoreConversationHistory) {
  const bubbleClass = fromOtherAsct ? 'received' : 'otherAsctSent';
  const type = fromOtherAsct ? null : o.type;
  const initials = o.userName
    ?.split(' ')
    .map(e => e.charAt(0))
    .slice(0, 2)
    .join('');

  const formattedMsgContainer = [];
  const urlMatchShow = o.message?.match(URL_REGEX);

  if (urlMatchShow) {
    const message = replaceWithBr(o.message).replaceAll(URL_REGEX, ';%;');
    let index = 0;

    formattedMsgContainer.push(
      <div key={`receivedMsg${i}`}>
        {o.webchatUrl ? webchatElement(o, i) : null}
        <div className="flex-row-nospacebetween-nowrap m-top-12">
          <Avatar
            className="conversation-window-header-user-icon"
            size="medium"
            style={{
              color,
              backgroundColor: ['F', 'G'].includes(type) ? null : avatarColor,
            }}
            src={
              type === 'F'
                ? 'https://dashboard-v2-images.s3.amazonaws.com/facebook.svg'
                : type === 'G'
                  ? 'https://dashboard-v2-images.s3.amazonaws.com/google.svg'
                  : null
            }
          >
            {['F', 'G'].includes(type) ? null : <span className="fs-14">{initials}</span>}
          </Avatar>
          <div className="flex-col-left maw-50-P">
            <span className="text-light-gray fs-10 m-left-17">
              {o.userName}
              {o.userId !== o.clientId && allStoreConversationHistory && o.storeName ? ` at ${o.storeName}` : ''}
            </span>
            <ol className="shared" id={bubbleClass} style={{ marginTop: '1px', marginBottom: '3px', minWidth: '70px' }}>
              <div className="sentTextDiv">
                <li className="received">
                  {' '}
                  <span
                    className="align-left"
                    dangerouslySetInnerHTML={{
                      __html: reactStringReplace(message, ';%;', () => {
                        const url = urlMatchShow[index];
                        index += 1;
                        return url ? `<a target="_blank" style="text-decoration: underline; color: #fff; z-index: 20" href="${parseHrefMessage(url)}" rel="noreferrer">${url}</a>` : '';
                      }).join(''),
                    }}
                  />
                  <span className="align-right fs-10 italic invisible">
                    {moment(o.sentTimestamp).format('hh:mm a')}
                  </span>
                </li>
                <span className="w-50" />
              </div>
              <img
                className="inboxV2Tail"
                src={
                  fromOtherAsct
                    ? 'https://dashboard-v2-images.s3.amazonaws.com/inboxV2Tail.svg'
                    : 'https://dashboard-v2-images.s3.amazonaws.com/inboxV2TailGray.svg'
                }
                alt="conversation style tail"
              />
              <span className="fs-10 italic chatDateReceive">{moment(o.sentTimestamp).format('hh:mm a')}</span>
            </ol>
          </div>
        </div>
      </div>,
    );
  } else
    formattedMsgContainer.push(
      <div key={`receivedMsg${i}`}>
        {o.webchatUrl ? webchatElement(o, i) : null}
        {o.type === 'payment' ? paymentElement(o, i) : null}
        {o.message ? (
          <div className="flex-row-nospacebetween-nowrap m-top-12">
            <Avatar
              className="conversation-window-header-user-icon"
              size="medium"
              style={{
                color,
                backgroundColor: ['F', 'G'].includes(type) ? null : avatarColor,
              }}
              src={
                type === 'F'
                  ? 'https://dashboard-v2-images.s3.amazonaws.com/facebook.svg'
                  : type === 'G'
                    ? 'https://dashboard-v2-images.s3.amazonaws.com/google.svg'
                    : null
              }
            >
              {['F', 'G'].includes(type) ? null : <span className="fs-14">{initials}</span>}
            </Avatar>
            <div className="flex-col-left maw-50-P">
              <span className="text-light-gray fs-10 m-left-17">
                {o.userName}
                {o.userId !== o.clientId && allStoreConversationHistory ? ` at ${o.storeName}` : ''}
              </span>
              <ol
                className="shared"
                id={bubbleClass}
                style={{ marginTop: '1px', marginBottom: '3px', minWidth: '70px' }}
              >
                <div className="sentTextDiv">
                  <li className="received" dangerouslySetInnerHTML={{ __html: replaceWithBr(o.message) }} />
                  <span className="w-50" />
                </div>
                <img
                  className="inboxV2Tail"
                  src={
                    fromOtherAsct
                      ? 'https://dashboard-v2-images.s3.amazonaws.com/inboxV2Tail.svg'
                      : 'https://dashboard-v2-images.s3.amazonaws.com/inboxV2TailGray.svg'
                  }
                  alt="conversation style tail"
                />
                <span className="align-left fs-10 italic chatDateReceive">
                  {moment(o.sentTimestamp).format('hh:mm a')}
                </span>
              </ol>
            </div>
          </div>
        ) : null}
      </div>,
    );
  return formattedMsgContainer;
}

function paymentElement(message, i) {
  if (!message.status) return null;
  return (
    <cite key={`payment${i}`}>
      <div
        key={`${message.shortUrl}${Math.random() * 850}${i}`}
        className="m-top-15 m-btm-15 align-center"
        style={{ lineHeight: '17px' }}
      >
        {message.status === 'PAID' || message.status === 'CANCELLED' || message.status === 'REFUNDED' ? (
          <span className=" fs-11" style={{ color: '#A4A4A4' }}>
            {' '}
            {message.paymentStatus}
            <a
              className=" fs-11"
              style={{ textDecoration: 'none', color: '#33aafc', zIndex: '20' }}
              href={`${message.shortUrl}`}
            >
              View Details
            </a>
            <br />
            {message.sentFormat}
          </span>
        ) : message.status === 'VIEWED' ? (
          <span className=" fs-11" style={{ color: '#A4A4A4' }}>
            {' '}
            {message.paymentStatus}
            <br />
            {message.sentFormat}
          </span>
        ) : null}
      </div>
    </cite>
  );
}

function videoElement(o, i, c, m, t, scrollToBottom) {
  return (
    <ChatVideo
      thumbnailUrl={o.thumbnailUrl}
      videoSrc={o.videoUrl}
      key={i}
      outBound={o.clientId ? o.clientId === o.userId : o.isInbound}
      scrollToBottom={scrollToBottom}
    />
  );
}

function webchatElement(o, i) {
  return (
    <cite key={`date${i}`}>
      <div key={`date${i}`}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '11px',
            fontStyle: 'italic',
            color: '#d9d9d9',
          }}
        >
          <FontAwesomeIcon icon={['fas', 'comment-alt']} color="#d9d9d9" style={{ marginRight: '6px' }} />

          <span>
            Webchat:{' '}
            <a href={o.webchatUrl} target="_blank" rel="noreferrer">
              {o.webchatUrl}
            </a>
          </span>
        </div>
      </div>
    </cite>
  );
}

const sentImageElement = (o, i, c, m, t, scrollToBottom) => {
  return <SentMessages o={o} i={i} m={m} scrollToBottom={scrollToBottom} key={`sentMessage${i}`} t={t} />;
};

function receivedImageElement(o, i, c, m, t, scrollToBottom) {
  return <ReceivedMessages o={o} i={i} m={m} scrollToBottom={scrollToBottom} key={`receivedMessage${i}`} t={t} />;
}

function productElement(o, i, c, m, t) {
  return <ProductImage o={o} i={i} m={m} key={`sentMessage${i}`} t={t} />;
}

function collectionElement(o, i, c, m, t) {
  return <Collection o={o} i={i} m={m} key={`sentMessage${i}`} t={t} />;
}

function parseHrefMessage (url) {
  if (url?.startsWith('http://') || url?.startsWith('https://')) {
    return url
  } else if (url?.match(EMAIL_REGEX)) {
    return `mailto:${url}`
  } else {
    return `https://${url}`
  }
}
