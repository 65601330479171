import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import colors from 'nice-color-palettes';
import { CSVLink } from 'react-csv';
import Table from '../../../Layout/Table';
import TitleDescription from '../../../Layout/DescriptionBox';
import Layout from '../../../Layout/StandardLayout';
import Button from '../../../Layout/Button';
import StyledLink from '../../../Layout/StyledLink';
import { setReminderIntv, setReportActionType } from '../../../../reducers/reports';
import { setAuthStoreId } from '../../../../reducers/auth';
import { getRequest } from '../../../../core/apiRequests';
import ReusableChart from '../../../Layout/Chart';
import DropdownSelect from '../../../Layout/DropdownSelect';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import BulletedList from '../../../Layout/BulletedList.js';
import RangePicker from '../../../CustomizedAntDesign/RangePicker';
import '../reports.css';

const disabledDate = current => {
  return current && current > moment().endOf('day');
};

const Leaderboard = props => {
  const [dateRange, setDateRange] = useState([moment().subtract(1, 'months'), moment()]);
  const [tableDef, setTableDef] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [chartConfigObj, setChartConfigObj] = useState({});
  const [associateList, setAssociateList] = useState([]);
  const [activities, setActivities] = useState([]);

  const CC_Colors = [
    ['#fc8333', '#feb32c', '#7fbd31', '#009470', '#1769af'],
    ['#803e9f', '#fd0062', '#fe99c0', '#a50087', '#55a4e7'],
    ['#6ee5ea', '#ff6363', '#9ddfff', '#ffd495', '#dcff66'],
    ['#0eb3ba'],
  ];

  const clientContactColors = CC_Colors.concat(colors);
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  useEffect(() => {
    const { merchantId } = props.auth;
    if (merchantId) {
      (async () => {
        const reqObj = {
          params: ['client', 'activityList', merchantId],
        };
        const data = await getRequest(reqObj);
        const tempArr = [
          { label: 'All Activities', value: 0 },
          { label: 'Client Added', value: 'Add Client' },
          { label: 'Sent Message', value: 'Client Message' },
        ];
        if (data) {
          data.forEach(activity => {
            if (activity.scored === 1) {
              tempArr.push({ label: activity.description, value: activity.description });
            }
          });
          setActivities(tempArr);
        }
      })();
    }
  }, [props.auth.storeId]);

  useEffect(() => {
    const { stores, userId, storeId } = props.auth;
    const allStores = false;
    let selectedStores = [0];

    if (Number(selectedStores[0]) === 0) {
      selectedStores = [];
      stores.forEach(st => {
        if (`${st.id}` !== '0') {
          selectedStores.push(st.id);
        }
      });
    }
    if (selectedStores.length > 0) {
      const reqObj = {
        params: ['associates', props.auth.merchantId],
        query: {
          selectedStores: JSON.stringify(selectedStores),
          allStores,
          userId,
          singleStore: storeId,
        },
      };
      (async () => {
        const assctData = await getRequest(reqObj);
        if (assctData.length > 0) {
          const sortedAscts = assctData.sort((a, b) => {
            if (`${a.first_name || ''}${a.last_name || ''}` > `${b.first_name || ''}${b.last_name}`) {
              return 1;
            }
            if (`${a.first_name || ''}${a.last_name || ''}` < `${b.first_name || ''}${b.last_name}`) {
              return -1;
            }
            return 0;
          });
          setAssociateList(sortedAscts);
        }
      })();
    }
  }, [props.auth.storeId]);

  const runReport = e => {
    e.preventDefault();

    const reportEnd =
      props.reports.reminderIntv === 'monthly'
        ? `${moment(dateRange[1]).endOf('month').format('YYYY-MM-DDT00:00:00.000')}Z`
        : moment(dateRange[1]).endOf('day').toISOString();
    const reportStart =
      props.reports.reminderIntv === 'monthly'
        ? `${moment(dateRange[0]).startOf('month').format('YYYY-MM-DDT12:00:00.000')}Z`
        : moment(dateRange[0]).startOf('day').toISOString();

    const reqObj = {
      params: ['report', 'clientEngagementByAssociate'],
      query: {
        licenseKey: props.auth.licenseKey,
        interval: props.reports.reminderIntv,
        startDt: reportStart,
        endDt: reportEnd,
        storeId: props.auth.storeId,
        storeAccess: props.auth.stores.filter(st => st.id !== 0).map(st => st.id),
      },
    };
    if (props.auth.storeId) {
      reqObj.query.selected_store_id = props.auth.storeId;
    }
    if (props.reports.actionType) {
      reqObj.query.action = props.reports.actionType;
    }

    (async () => {
      const res = {};
      res.data = await getRequest(reqObj);
      const labels = res.data.rows.map(row => {
        if (res.data.interval === 'monthly') {
          return monthNames[row.month - 1];
        }
        if (res.data.interval === 'daily') {
          return `${row.month}/${row.day}`;
        }
        return row.week;
      });

      const tempTableHeaders = [
        {
          keyName: 'name',
          headerClassName: 'row1',
          rowClassName: 'col-p-l-25',
          title: 'Associate',
          style: {
            width: '5%',
            paddingLeft: '20px',
            paddingTop: '80px',
            paddingRight: '20px',
          },
          render: (t, o) =>
            o.id ? (
              <StyledLink to={`/Associates/associate-details/${o.id}`}>
                {t || `${o.first_name || ''} ${o.last_name || ''}`.trim()}
              </StyledLink>
            ) : (
              t
            ),
        },
      ];

      const assocData = [];
      const assocBottomGraph = {};
      const assocDict = {};

      let maxVal = 0;
      res.data.rows.forEach((rowDate, index) => {
        let startDt = '';

        if (res.data.interval === 'monthly') {
          startDt = `${monthNames[rowDate.month - 1]}/${rowDate.year.slice(2)}`;
        } else if (res.data.interval === 'daily') {
          startDt = `${rowDate.month}/${rowDate.day}`;
        } else {
          startDt = rowDate.week;
        }

        tempTableHeaders.push({
          keyName: startDt,
          style: { width: '5%', paddingTop: '80px', paddingRight: '10px' },
          headerClassName: 'row4',
          title: startDt,
          sortable: false,
        });
        associateList.forEach(rowAssociate => {
          const name = `${rowAssociate.fullName}-${rowAssociate.id}`;
          if (!assocDict[name]) {
            assocDict[name] = [];
            assocBottomGraph[name] = {
              name: rowAssociate.fullName,
              id: rowAssociate.id,
              [startDt]: 0,
            };
          }
          assocDict[name].push(0);
          assocBottomGraph[name][startDt] = 0;
        });
        rowDate.data.forEach(rowAssociate => {
          const name = `${rowAssociate.assoc.name}-${rowAssociate.assoc.id}`;
          if (!assocDict[name]) {
            assocDict[name] = [];
            assocBottomGraph[name] = {
              name: rowAssociate.assoc.name,
              startDt: rowAssociate.value,
            };
          }

          assocBottomGraph[name][startDt] = rowAssociate.value;
          if (rowAssociate.value > maxVal) {
            maxVal = rowAssociate.value;
          }
          assocDict[name][index] = rowAssociate.value;
        });
      });
      const tempData = [];
      Object.keys(assocBottomGraph)
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
        .forEach(function (ascName) {
          assocData.push(assocBottomGraph[ascName]);
          tempData.push(assocBottomGraph[ascName]);
        });

      setDownloadData(tempData);
      setTableHeaders(tempTableHeaders);
      const totals = assocData.reduce(
        (acc, curr) => {
          Object.keys(curr).forEach(key => {
            if (key !== 'name' && key !== 'id') {
              acc[key] = acc[key] ? acc[key] + parseInt(curr[key], 10) : parseInt(curr[key], 10);
            }
          });
          return acc;
        },
        { name: 'Total', className: 'fw-600 fs-16' },
      );
      assocData.push(totals);
      setTableDef(assocData);

      const datasets = [];

      Object.keys(assocDict).forEach(key => {
        const paletteIdx = Math.trunc(datasets.length / 5);
        const colorIdx = datasets.length % 5;
        datasets.push({
          label: '',
          data: assocDict[key],
          fill: false,
          borderColor: clientContactColors[paletteIdx][colorIdx],
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: clientContactColors[paletteIdx][colorIdx],
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: clientContactColors[paletteIdx][colorIdx],
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
        });
      });

      setChartConfigObj({
        type: 'line',
        data: {
          labels,
          datasets,
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
          },
          tooltips: {
            yAlign: 'bottom',
          },
        },
      });
    })();

    setShowReport(true);
  };

  return (
    <Layout
      title="Leaderboard"
      backArrow="/Home"
      description="Set your parameters and click on 'Run Report.'"
      classProps="standard-width-with-sidebar flex-col-center"
      styleProps={{ justifyContent: 'flex-start' }}
    >
      <div className="report-smaller-description">
        {' '}
        The Leaderboard is where you can see how well your team is clienteling. It ranks all associates based on the
        following actions:{' '}
      </div>
      <BulletedList items={['Clients Added', 'Client Calls', 'Sent Messages', 'Shared Wishlists', 'Store Visits']} />
      <div className="report-smaller-description">
        {' '}
        Each of these actions only count as 1 point on the Leaderboard. There is a 1 point maximum per client each day.
        If inclined, you may also run a report on only 1 of the client actions or on all of them at once.{' '}
      </div>
      <div className="pos-rel w-100-P " style={{ maxWidth: '866px' }}>
        <TitleDescription
          title="Reporting Location"
          description="What store location would you like to run a report on?"
          input={
            <DropdownSelect
              isSearchable
              classProps="mq-w-200 w-267 align-left"
              options={props.auth.stores.map(st => ({
                label: st.name,
                value: st.id,
              }))}
              placeholder="Select a Store..."
              value={props.auth.storeId}
              onChange={obj => {
                setAuthStoreId(obj.value);
              }}
            />
          }
        />
        <TitleDescription
          title="Action Type"
          description="What actions would you like to run a report on?"
          input={
            <DropdownSelect
              classProps="mq-w-200 w-267 align-left"
              options={activities}
              placeholder={`'All Activities' + (${props.reports.actionType.length}) `}
              value={props.reports.actionType}
              onChange={obj => {
                setReportActionType(obj.value);
              }}
            />
          }
        />
        <TitleDescription
          title="Frequency"
          description="Want to see a report based on month, week, or day?"
          input={
            <DropdownSelect
              classProps="mq-w-200 w-267 align-left"
              options={[
                { label: 'Days', value: 'daily' },
                { label: 'Weeks', value: 'weekly' },
                { label: 'Months', value: 'monthly' },
              ]}
              placeholder="Select a time interval"
              value={props.reports.reminderIntv}
              onChange={obj => {
                setReminderIntv(obj.value);
              }}
            />
          }
        />

        <TitleDescription
          title="Date Range"
          input={
            <RangePicker
              format="MM/DD/YYYY"
              disabledDate={disabledDate}
              value={dateRange}
              onChange={setDateRange}
              ranges={{
                'Last 7 Days': [moment().subtract(7, 'd'), moment()],
                'Last 30 Days': [moment().subtract(30, 'd'), moment()],
                'Last 60 Days': [moment().subtract(60, 'd'), moment()],
                'Last 90 Days': [moment().subtract(90, 'd'), moment()],
              }}
              showToday
            />
          }
          className="report-date-picker"
        />

        <div className="report-asct-msg-row" style={{ maxWidth: '866px' }}>
          <Button classname="darkBlueButton" id="RunLeaderboardReport" onclick={e => runReport(e)}>
            Run Report
          </Button>
        </div>
      </div>
      {showReport ? (
        <div className="w-100-P rem-pad-width maw-1200">
          <div
            className="w-100-P h-100-P"
            style={{
              border: 'solid 1.0px #979797',
              borderRadius: '8px',
              width: '100%',
              position: 'relative',
            }}
          >
            <div>
              <h4
                style={{
                  textAlign: 'center',
                  fontSize: '1em',
                  fontWeight: 'bold',
                  margin: '1.6em 0',
                }}
              >
                Client Engagement by Associate
              </h4>
            </div>
            <div style={{ minHeight: '200px' }}>
              <ReusableChart configObj={chartConfigObj} width="100%" height="400px" />
            </div>
          </div>
          <div
            className="import_export_group"
            style={{
              justifyContent: 'flex-end',
              maxWidth: '1105px',
              zIndex: '100',
              marginTop: '100px',
              marginRight: '30px',
            }}
          >
            <img src="https://dashboard-v2-images.s3.amazonaws.com/ic-export.svg" alt="export" />

            <CSVLink
              data={downloadData}
              filename="ClientEngagementbyActivity.csv"
              id="DownloadLeaderboardReport"
              className="exportButton m-right-15"
            >
              Download
            </CSVLink>
          </div>
          <Table
            tableClassName="maw-1200 rem-pad-width m-top-15 overflowX hide-scrollbar w-100-P"
            tableContainerClass="w-100-P rem-pad-width "
            style={{ marginTop: '-100px', width: '100%' }}
            data={tableDef}
            tableRowClassName="col-p-l-25 align-center"
            tableHeaders={tableHeaders}
            tableName="LEADERBOARD"
          />
        </div>
      ) : null}
      <SecondaryPanel
        overhead_title="Reports"
        title={['CLIENTELING', 'MESSAGING', 'Google Reviews', 'PAYMENTS', 'AUTOMATION', 'SALES OPPORTUNITY']}
        current="Leaderboard"
      />
    </Layout>
  );
};

const mapStateToProps = state => ({
  reports: state.reports,
  auth: state.auth,
  stores: state.stores,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
