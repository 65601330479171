import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Layout, PageHeader, Avatar, Drawer, Modal } from 'antd';
import { CloseOutlined, InfoCircleFilled, QrcodeOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteRequest, postRequest, queryMaker } from '../../../../core/apiRequests';
import ConversationWindow from '../../../Layout/chat/chat';
import OtpInQr from '../../../Layout/optInQr/opt-in-qr';
import { notificationSuccess } from '../../../../core/actions';
import { setClientAssociates, setClientAssociatesIds } from '../../../../reducers/client';
import ToolTipBottom from '../../../Layout/ToolTip/ToolTipBottom';

const Header = props => {
  const [showQr, setShowQr] = useState(false);
  const [tempAssigned, setTempAssigned] = useState(false);
  const [tempAssocs, setTempAssocs] = useState(props.client?.associates || []);
  const [hovering, setHovering] = useState('');

  const initials = props.client?.name
    ?.split(' ')
    .map(e => e.charAt(0))
    .slice(0, 2)
    .join('');

  const nameShorten = name => {
    if (name) {
      if (window.innerWidth < 1515 && name.length > 30) {
        return `${name.substr(0, 30)}...`;
      }
      if (window.innerWidth < 1642 && name.length > 58) {
        return `${name.substr(0, 58)}...`;
      }
      if (name.length > 80) {
        return `${name.substr(0, 80)}...`;
      }
    }
    return name || '';
  };

  useEffect(() => {
    if (props.client?.associateIds) {
      const isAssigned = props.client.associateIds.includes(props.auth.userId);
      setTempAssigned(isAssigned);
    }
  }, [props.client]);

  const bookmarkHandler = () => {
    const { userId, firstName, lastName } = props.auth;
    const clientId = props.client.client_id;

    if (tempAssigned) {
      (async () => {
        const reqObj = {
          params: ['clients', userId, clientId, 'deleteAssociateClient'],
        };
        const data = await deleteRequest(reqObj);
        if (data) {
          setTempAssigned(false);
          const updatedAssociates = [
            ...tempAssocs.filter(
              assoc => assoc.first_name !== props.auth.firstName && assoc.last_name !== props.auth.lastName,
            ),
          ];
          setClientAssociates(updatedAssociates);
          setTempAssocs(updatedAssociates);
          setClientAssociatesIds([...props.client.associateIds.filter(id => id !== props.auth.userId)]);
          notificationSuccess('Client unassigned from you');
        }
      })();
    } else {
      (async () => {
        const reqObj = {
          params: ['clients', userId, clientId, 'assignAssociateClient'],
        };
        const data = await postRequest(reqObj);
        if (data) {
          setTempAssigned(true);
          const updatedAssociates = [{ first_name: firstName, last_name: lastName || '', id: userId }, ...tempAssocs];
          setClientAssociates(updatedAssociates);
          setTempAssocs(updatedAssociates);
          setClientAssociatesIds([userId, ...props.client.associateIds]);
          notificationSuccess('Client assigned to you');
        }
      })();
    }
  };

  return (
    <div style={{ marginTop: '-5px' }}>
      <Layout className="conversation-window-layout" style={{ maxHeight: '100%' }}>
        <div className="conversation-window-header flex-row-spacebetween-nowrap">
          <div className="add-edit-wrap">
            <Avatar
              className="conversation-window-header-user-icon"
              id="user-icon"
              size="medium"
              key={initials}
              style={{ backgroundColor: '#BDBDBD', color: '#FFF', width: '48px', height: '48px', marginTop: '18px', lineHeight: '48px' }}
            >
              {initials}
            </Avatar>
            <PageHeader
              className="conversation-window-header-heading-sub"
              id="middle-panel-header"
              title={nameShorten(props.client?.name)}
              subTitle={`${props.client?.preferred_name || ''} ${props.client?.preferred_name ? '|' : ''} ${
                props.client?.preferred_contact_type
              }`}
            />
            {props.auth.webKioskMode && (
              <span
                style={{ fontSize: '28px', marginLeft: '-30px' }}
                className="pointer m-top-18"
                onClick={() => setShowQr(!showQr)}
              >
                &nbsp; {<QrcodeOutlined /> ?? ''}
              </span>
            )}
          </div>
          {/* <StoreModal auth={props.auth} hideSelector={props.type === 'full'} /> */}
          <div className="headingIcons" id="heading-icons">
            {/* eslint-disable-next-line no-nested-ternary */}
            {props.type === 'right' ? (
              <CloseOutlined
                title="Close"
                style={{ fontSize: '16px' }}
                id="more-info-icon"
                onClick={() => props.setDrawerToggle(true)}
              />
            ) : (
              <>
                <FontAwesomeIcon
                  className="m-x-12 fs-19 gray pointer"
                  color="#818181"
                  icon={['far', 'comment-alt-lines']}
                  onClick={props.showMessageHandler}
                />
                <div
                  className="pointer pos-rel m-x-12"
                  onClick={bookmarkHandler}
                  role="button"
                  tabIndex={0}
                  id="new-msg-btn"
                  onMouseEnter={() => setHovering('AssignedAssociate')}
                  onMouseLeave={() => setHovering('')}
                >
                  <ToolTipBottom
                    isShown="AssignedAssociate"
                    idx={hovering}
                    description={tempAssigned ? 'Unassign Client' : 'Assign to Me'}
                    toolTipWidth="125px"
                    toolTipHeight="40px"
                    toolTipTop="30px"
                    toolTipRight="-51px"
                    arrowLeft="6px"
                    arrowBottom="52.7px"
                  />
                  <FontAwesomeIcon icon={[tempAssigned ? 'fa' : 'far', 'bookmark']} size="1x" className="fs-16" />
                </div>
                <div
                  className="pointer pos-rel m-x-12"
                  onClick={() => props.setDrawerToggle(!props.toggleDrawer)}
                  role="button"
                  tabIndex={0}
                  id="new-msg-btn"
                  onMouseEnter={() => setHovering('ClientInfo')}
                  onMouseLeave={() => setHovering('')}
                >
                  <ToolTipBottom
                    isShown="ClientInfo"
                    idx={hovering}
                    description="Client Info"
                    toolTipWidth="95px"
                    toolTipHeight="40px"
                    toolTipTop="30px"
                    toolTipRight="-34px"
                    arrowLeft="6px"
                    arrowBottom="52.7px"
                  />
                  <InfoCircleFilled className="fs-16 pointer" id="more-info-icon" />
                </div>
              </>
            )}
          </div>
        </div>
        <Drawer
          bodyStyle={{
            padding: 0,
            overflow: 'hidden',
          }}
          closable={false}
          push={false}
          destroyOnClose
          width={750}
          open={props.showPopConfirm}
          onClose={props.closeChat}
        >
          <ConversationWindow setDrawerToggle={props.closeChat} chatIconColor="#EBEBEB" type="right" />
        </Drawer>
        <Modal
          open={showQr}
          centered
          onOk={() => setShowQr(!showQr)}
          onCancel={() => setShowQr(!showQr)}
          footer={null}
          maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }}
        >
          <OtpInQr
            url={`${process.env.REACT_APP_OPT_IN_FORM_URL}${queryMaker({
              merchantUuid: props.auth.uuid,
              clientUuid: props.client.uuid,
              associateUuid: props.auth.associateUuid,
            })}`}
          />
        </Modal>
      </Layout>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  client: state.client,
});
export default connect(mapStateToProps)(Header);
