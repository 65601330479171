import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledLink from '../../../../../../../Layout/StyledLink';
import SmallTable from '../../../../../../../Layout/SmallTable';
import EditOppModalWithNotes from '../EditOppModalWithNotes';
import { getRequest, deleteRequest } from '../../../../../../../../core/apiRequests';
import Button from '../../../../../../../Layout/Button';
import PopConfirm from '../../../../../../../Layout/PopConfirm';
import { notificationSuccess } from '../../../../../../../../core/actions';
import ViewAll from '../../../../ViewAll';
import useSearchParams from '../../../../../../../../hooks/useSearchParams';

const OpportunityDetails = props => {
  // please pass in id. The rest should be complete.
  const { id, oppLocation } = props.selectedSalesOpp;
  const { client_id } = props.client;
  const { setValue } = useSearchParams();

  const [showEditOppModal, setShowEditOppModal] = useState(false);
  const [showEditOppNoteModal, setShowEditOppNoteModal] = useState(false);
  const [salesOpp, setSalesOpp] = useState({
    amount: null,
    client_name: null,
    close_date: null,
    complete_date: null,
    created_date: null,
    first_name: '',
    id: null,
    last_name: '',
    name: '',
    notes: null,
    stage_name: '',
    sales_pipeline_id: '',
  });
  const [salesActivity, setSalesActivity] = useState([]);
  const [stageList, setStageList] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);


  const getTheOpp = () => {
    const reqObj = {
      params: ['client', props.auth.merchantId, id, 'getSalesOppHistory'],
      query: { merchantId: props.auth.merchantId },
    };
    (async () => {
      const opp = await getRequest(reqObj);
      if (opp) {
        setSalesActivity(opp.resultsHist);
        setSalesOpp(opp.resultsOpp[0]);
        setStageList(opp.resultsStages);
        const filteredSalesOpps = opp.resultsAllStages.filter(
          opp => opp.sales_pipeline_id == salesOpp.sales_pipeline_id,
        );
      }
    })();
  };

  // Get Sales Opp History
  useEffect(() => {
    // make a request to the backend with the sales opp id
    if (props.auth.merchantId) {
      getTheOpp();
    }
  }, [props.auth.merchantId, showEditOppModal]);

  const showEditOppModalHandler = () => {
    setShowEditOppModal(!showEditOppModal);
  };

  const showEditOppNoteModalHandler = () => {
    setShowEditOppNoteModal(!showEditOppNoteModal);
  };

  const dateFormatter = incDate => {
    let formattedDate = 'N/A';
    if (incDate) {
      if (Number(incDate) > 9999999) {
        formattedDate = moment(incDate, 'YYYYMMDD').format('MM/DD/YYYY');
      }
    }
    return formattedDate;
  };

  const attributeFormatter = attribute => {
    const formattedAtr = attribute.charAt(0).toUpperCase() + attribute.slice(1).toLowerCase().replace('_', ' ');
    return formattedAtr;
  };

  const oppActivity = [
    {
      keyName: 'created_date',
      title: 'Date',
      headerClassName: 'align-left p-btm-6',
      rowClassName: '',
      style: { width: '25%' },
      sortable: false,
      render: t => moment(t, 'YYYYMMDDhhmmss').format('MM/DD/YYYY @ hh:mm A'),
    },
    {
      keyName: 'created_by_name',
      title: 'Associate',
      headerClassName: 'align-left p-btm-6',
      style: { width: '15%', paddingRight: '22px' },
      rowClassName: 'align-left',
      sortable: false,
      render: t => {
        return <p>{t}</p>;
      },
    },
    {
      keyName: '',
      style: { width: '60%' },
      title: 'Description',
      headerClassName: 'align-left p-btm-6',
      rowClassName: '',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        switch (o.type) {
          case 'CREATE':
            return <p>Created</p>;
          case 'STAGE':
            return (
              <p>
                Moved from {o.prev_val} to {o.new_val}
              </p>
            );
          case 'UPDATE':
            return (
              <p>
                Changed {attributeFormatter(o.attribute)} from{' '}
                {o.attribute === 'CLOSE_DATE'
                  ? `${dateFormatter(o.prev_val)} to ${dateFormatter(o.new_val)}`
                  : `${o.prev_val} to ${o.new_val}`}
              </p>
            );
          default:
            return <p>No content available</p>;
        }
      },
    },
  ];

  const deleteAction = () => {
    const { merchantId } = props.auth;
    const salesOppId = salesOpp.id;
    const clientId = salesOpp.client_id;
    const fn = salesOpp.first_name ? salesOpp.first_name : '';
    const ln = salesOpp.last_name ? salesOpp.last_name : '';
    (async () => {
      const reqObj = {
        params: ['clients', merchantId, salesOppId, clientId, 'deleteClientSalesOpp'],
        query: { deletedBy: `${fn} ${ln}` },
      };
      const data = await deleteRequest(reqObj);
      if (data) {
        notificationSuccess('Sales Opportunity Removed');
        setShowDeleteConfirm(false);
        setValue('oppId', '');
        setValue('oppLocation', '');
      }
    })();
  };

  const green = obj => {
    const filteredSalesOpps =
      props.client.salesStages.filter(opp => opp.sales_pipeline_id == obj.sales_pipeline_id) || [];
    let small = {};
    if (filteredSalesOpps.length > 0) {
      small = filteredSalesOpps.reduce(function (prev, curr) {
        return prev.weight < curr.weight ? prev : curr;
      });
      return small.id;
    }
  };

  const stageTile = () => {
    const returnTile = [];
    if (salesOpp.weight === 0 || salesOpp.weight === 1) {
      returnTile.push(
        <div
          style={{
            width: '72px',
            height: '22px',
            borderRadius: '3px',
            backgroundColor: '#e8f4ff',
            color: '#33aafc',
            fontSize: '10px',
            fontWeight: '600',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          COMPLETE
        </div>,
      );
    } else if (salesOpp.sales_opp_stage_id === green(salesOpp)) {
      returnTile.push(
        <div
          style={{
            width: '72px',
            height: '22px',
            borderRadius: '3px',
            backgroundColor: '#ecf8dd',
            color: '#7fbd31',
            fontSize: '10px',
            fontWeight: '600',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {salesOpp.stage_name.toUpperCase()}
        </div>,
      );
    } else {
      returnTile.push(
        <div
          style={{
            height: '22px',
            padding: '0 5px',
            borderRadius: '3px',
            backgroundColor: '#ffe5d3',
            color: '#fc8333',
            fontSize: '10px',
            fontWeight: '600',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {salesOpp.stage_name.toUpperCase()}
        </div>,
      );
    }
    return returnTile;
  };

  //   onClick={showEditOppModalHandler}
  return (
    <ViewAll
      title="Opportunity Details"
      location={oppLocation}
      setViewAllType={props.setViewAllType}
      goBack={() => {
        setValue('oppId', '');
        setValue('oppLocation', '');
      }}
    >
      <div className="h-100-P w-100-P m-left-21" style={{ maxWidth: '763px' }}>
        <div className="w-100-P flex-col-left">
          <div className="flex-row-spacebetween-wrap w-100-P">
            <span
              style={{
                fontSize: '15px',
                fontWeight: '500',
                textAlign: 'left',
                paddingBottom: '10px',
              }}
            >
              {salesOpp.name}
            </span>
            <div className="flex-row-spacebetween-wrap">
              {props.auth.role == 'ADMIN' || props.auth.role == 'MANAGER' ? (
                <div className="add-edit-wrap align-left">
                  <Button
                    classname="deleteButton p-left-0"
                    style={{ marginBottom: '15px', color: '#FF7A7A', paddingBottom: '15px' }}
                    onclick={() => setShowDeleteConfirm(true)}
                  >
                    <FontAwesomeIcon
                      icon={['far', 'trash-alt']}
                      color="#FF7A7A"
                      size="1x"
                      style={{ marginBottom: '3px' }}
                    />
                    Delete
                  </Button>
                </div>
              ) : null}
              <div style={{ color: '#33AAFC' }} className="m-top-7 fw-500 pointer" onClick={showEditOppModalHandler}>
                <FontAwesomeIcon
                  className="fs-16 pointer"
                  icon={['far', 'pencil']}
                  onClick={showEditOppNoteModalHandler}
                  id="ClientOpportunityDetailsEditNotes"
                  style={{ color: '#33AAFC', margin: '0px 13px 0px 6px' }}
                />
                <span>Edit</span>
              </div>
            </div>
          </div>
          <div className="w-100-P flex-row-spacebetween-nowrap">
            {/* PRICE VALUE BY GOES HERE */}
            <div
              style={{
                width: '40%',
              }}
            >
              <TitleText title="Price Value" text={`$${salesOpp.amount}`} />
            </div>

            {/* Created BY GOES HERE */}
            <div
              style={{
                width: '60%',
              }}
            >
              <TitleText
                title="Created By"
                text={
                  <StyledLink to={`/associates/associate-details/${salesOpp.associate_id}`}>
                    {salesOpp.first_name} {salesOpp.last_name}
                  </StyledLink>
                }
              />
            </div>
          </div>
          <div className="w-100-P flex-row-spacebetween-nowrap">
            <div
              style={{
                width: '40%',
              }}
            >
              <TitleText title="Expected Close Date" text={dateFormatter(salesOpp.close_date)} />
            </div>

            {/* CREATED DATE GOES HERE */}
            <div
              style={{
                width: '60%',
              }}
            >
              <TitleText title="Created Date" text={dateFormatter(salesOpp.created_date)} />
            </div>
          </div>
          <div className="w-100-P flex-row-spacebetween-nowrap">
            {/* Add Stage HERE */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <TitleText title="Stage" />
              {stageTile()}
            </div>
            {/* NOTES GOES HERE */}
            <div className="flex-row-spacebetween-nowrap" style={{ width: '59%' }}>
              <TitleText
                title="Notes"
                text={salesOpp.notes ? salesOpp.notes : <i style={{ color: 'lightgray' }}>No content available</i>}
              />
            </div>
          </div>
        </div>
        {/* tables starts here */}
        <p
          style={{
            textAlign: 'left',
            fontSize: '15px',
            fontWeight: '500',
            marginTop: '40px',
            marginBottom: '0px',
            padding: '0',
          }}
        >
          Activity
        </p>
        <SmallTable
          tableClassName="m-top-0"
          data={salesActivity}
          tableHeaders={oppActivity}
          classname="table-head border-btm h-20 rem-pad-width m-top-0"
          tableContainerClass="table-body-content w-100-P"
          tableStyle={{ width: '100%' }}
        />
      </div>
      {showEditOppModal ? (
        <EditOppModalWithNotes
          showEditOppModalHandler={showEditOppModalHandler}
          salesOpp={salesOpp}
          setSalesOpp={setSalesOpp}
          stageList={stageList}
          merchantId={props.auth.merchantId}
          client_id={client_id}
          associateId={salesOpp.associate_id}
          store_id={props.client.store_id}
          firstName={props.auth.firstName}
          lastName={props.auth.lastName}
        />
      ) : null}
      {/* DELETE CONFIRMATION */}
      {showDeleteConfirm ? (
        <PopConfirm
          customContent
          confirm="Delete This Opportunity?"
          description="This will also delete attached auto reminders and messages"
          onCancel={() => setShowDeleteConfirm(false)}
          onConfirm={() => deleteAction()}
        />
      ) : null}
    </ViewAll>
  );
};

const mapStateToProps = state => ({
  auth: { ...state.auth },
  client: { ...state.client },
});
export default connect(mapStateToProps, {})(OpportunityDetails);

const TitleText = props => {
  return (
    <div className="flex-col-left fs-13 fw-500 m-btm-10 m-right-60">
      <span className="gray">{props.title}</span>
      <span className="align-left" style={props.color ? { color: props.color } : {}}>
        {props.text}
      </span>
    </div>
  );
};
