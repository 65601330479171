import React from 'react';

import TaxField from './TaxField';

const TaxId = ({ maxLength, inputMode, ...props }) => {
  return (
    <TaxField {...props}>
      <input
        maxLength={maxLength}
        inputMode={inputMode}
        pattern="\d{9}"
        title="Must be a 9-digit number e.g. 123456789 with no dashes"
        type="text"
      />
    </TaxField>
  );
};
TaxId.propTypes = {};

TaxId.defaultProps = {};

export default TaxId;
