const PRICING_DETAILS = [
  { key: 'msrp', label: 'MSRP' },
  { key: 'price', label: 'Retail' },
  { key: 'unit_cost', label: 'Cost', roles: ['ADMIN', 'MANAGER'] },
];

const OTHER_DETAILS = [
  { key: 'pos_ref_num', label: 'POS ID' },
  { key: 'upc', label: 'UPC' },
];

const PRODUCT_DETAILS = [
  { key: 'stone_type', label: 'Type' },
  { key: 'stone_clarity', label: 'Clarity' },
  { key: 'stone_shape', label: 'Shape' },
  { key: 'stone_size', label: 'Size' },
  { key: 'stone_color', label: 'Color' },
  { key: 'total_dia_wt', label: 'Total Diamond Weight' },
  { key: 'pattern', label: 'Pattern' },
  { key: 'color', label: 'Color' },
  { key: 'material', label: 'Material' },
];

export const PRODUCT_DETAILS_SECTIONS = [
  {
    label: 'Pricing',
    config: { name: 'HIDE_PRODUCT_COST', roles: ['ADMIN', 'MANAGER'], value: false },
    items: PRICING_DETAILS,
    itemType: 'number',
  },
  {
    label: 'Other',
    items: OTHER_DETAILS,
    itemType: 'string',
  },
  {
    label: 'Details',
    items: PRODUCT_DETAILS,
    itemType: 'string',
  },
];
