import React, { useEffect } from 'react';
import ProductCarousel from '../ProductCarousel';
import ProductDetails from '../ProductDetails';
import './styles.css';
import { getProductById } from '../../../../../../../api/products';
import { setLoadingStatus } from '../../../../../../../reducers/notification';

const CollectionProduct = ({ selectedProduct }) => {

  const [images, setImages] = React.useState(selectedProduct?.images || []);
  const [product, setProduct] = React.useState(selectedProduct);

  useEffect(() => {
    if(selectedProduct?.viewDetails) {
      setLoadingStatus(true);
      getProductById(selectedProduct.id).then((({data}) => {
        if(data?.images?.length > 0) {
        setImages(data.images.map(img => img.url))
        } else {
          setImages([])
        }
        setProduct({...data, viewDetails: true})
        setLoadingStatus(false)
      }))
    }
  }, [])
  return (
    <div className="clients-v2-collection-product">
      <ProductCarousel images={images} />
      <ProductDetails product={product} />
    </div>
  );
};

export default CollectionProduct;
