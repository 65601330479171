/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Typography, notification } from 'antd';
import QRCode from 'react-qr-code';

const { Paragraph } = Typography;

export default function optInQr({ url }) {
  const copyLink = () => {
    navigator.clipboard.writeText(url);
    notification.success({
      message: 'Link copied',
    });
  };

  return (
    <div className="flex-col-center p-25">
      <h2 className="fw-600">Scan to opt-in to messaging</h2>
      <br />
      <QRCode size={350} value={url} />
      <br />
      <Paragraph>
        <span className="fw-600 blue-link pointer" onClick={() => copyLink()}>
          Copy link
        </span>
      </Paragraph>
    </div>
  );
}
