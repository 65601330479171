import { useCallback, useEffect, useState } from 'react';

import { getProductById } from '../api/products';

const useProductDetails = productId => {
  const [product, setProduct] = useState(null);

  const handleGetProduct = useCallback(async () => {
    setProduct(null);
    if (!productId || productId <= 0) return;

    const { data } = await getProductById(productId);

    setProduct(data);
  }, [productId]);

  useEffect(() => {
    handleGetProduct();
  }, [handleGetProduct]);
  return product;
};

export default useProductDetails;
