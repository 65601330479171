import React from 'react';
import DropdownSelect from '../../../DropdownSelect';


const AutoMsgTypedRelationship = ({ selectLoading, typedRelationshipsOptions, relationship, setRelationship, flags }) => {
  const relationshipAutomation = flags?.relationship_automation?.enabled;

  if (relationshipAutomation) {
    return (
      <>
        <div
          className="max-w-600 w-100-P m-btm-10"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <span style={{ fontSize: '10px', color: '#979797' }}>Who will receive the message?</span>
          </div>
        </div>
        <div
          className="max-w-600 w-30-P m-btm-25"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <DropdownSelect
            classProps="w-100-P align-left"
            options={selectLoading ? [{ value: 'loading', label: 'Loading...' }] : typedRelationshipsOptions}
            placeholder="Select relationship"
            value={selectLoading ? 'loading' : relationship}
            onChange={obj => {
              setRelationship(obj.value);
            }}
          />
        </div>
      </>
    );
  }
  return null;
};
export default AutoMsgTypedRelationship;
