import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd-v5';
import {  differenceInDays, endOfDay, format, formatISO, isBefore, startOfDay, subDays } from 'date-fns'
import Report from '../../../../componentsV2/Layout/Report';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import usePaginatedRequest from '../../../../hooks/usePaginatedRequest';
import { getGoogleLocationReview, getGoogleReview } from '../../../../api/report';
import StyledLink from '../../../Layout/StyledLink';
import useSearchParams from '../../../../hooks/useSearchParams';

const GoogleReviews = ({ auth }) => {

  const options = useMemo(() => [
    {
      title: 'Delivery Method',
      key: '0-0',
      value: 'deliveryMethod',
      children: [
        {
          key: '0-0-0',
          title: 'Sent by Person',
          value: 'associate'
        },
        {
          key: '0-0-1',
          title: 'Sent by Clientbook',
          value: 'clientbook'
        },
        {
          key: '0-0-2',
          title: 'Sent by Point of Sale',
          value: 'pos'
        },
      ]
    }
  ], [])
  const [filters, setFilters] = useState({
    deliveryMethod: [],
    store: [],
    associate: []
  });
  const [dateRange, setDateRange] = useState([subDays(new Date(), 90), new Date()]);
  const [expandedRowKeys, setExpandedRowKeys] = useState(auth.stores.filter(st => st.id !== 0).length > 1 ?
    [] : auth.stores.filter(st => st.id !== 0).map(st => st.id));
  const [pageSizeTable, setPageSizeTable] = useState(10);
  const [page, setPage] = useState(1);

  const {setValue: setSearchParams, getAll} = useSearchParams();
  const params = getAll();

  useEffect(() => {
    let paramsFilters = {
      deliveryMethod: params?.deliveryMethod ? params?.deliveryMethod : [],
      store: params?.store ? params?.store : [],
      associate: params?.associate ? params?.associate : [],
    }
    setFilters(paramsFilters)
    if(params?.startDate && params?.endDate) {
      setDateRange([new Date(params.startDate), new Date(params.endDate)])
    } else {
      setDateRange([startOfDay(subDays(new Date(), 90)), endOfDay(new Date())])
    }
  }, [window.location.search]);

  const getDefaultPeriod = useMemo(() => {
    if(params?.startDate && params?.endDate) {
      if(differenceInDays(new Date(params.endDate), new Date(params.startDate)) === 90) {
        return '90days'
      } else {
        return `${format(new Date(params.startDate), 'MM/dd/yyyy')} - ${format(new Date(params.endDate), 'MM/dd/yyyy')}`
      }
    } else {
      return '90days'
    }
  }, [])

  const handleOnDateRangeChange = useCallback((dates) => {
    setSearchParams('startDate', formatISO(dates[0]))
    setSearchParams('endDate', formatISO(dates[1]))
  }, []);

  const handleFilterChange = useCallback((value) => {
    let updatedFilters = {
      deliveryMethod: [],
      store: [],
      associate: []
    };
    value.forEach(item => {
      if (item[0] && item[1]) {
        updatedFilters[item[0]] = [...updatedFilters[item[0]], item[1]];
      } else {
        updatedFilters[item[0]] = [];
      }
    });
    Object.keys(updatedFilters).forEach(key => {
      setSearchParams(key, updatedFilters[key].join(','))
    })
  }, [filters]);

  const removeFilter = useCallback((key, value) => {
    let updatedFilters = { ...filters };
    updatedFilters[key] = updatedFilters[key].filter(filter => filter !== value);
    Object.keys(updatedFilters).forEach(key => {
      setSearchParams(key, updatedFilters[key].join(','))
    })
  }, [filters]);

  const { results: sentResults, total: sentTotal, setFilters: setFiltersSent, setPageSize: setPageSizeSent } = usePaginatedRequest({
    defaultPageSize: "*", // This is a string to indicate that we want all the results
    defaultFilters: {
      created_at: {
        $between: [formatISO(dateRange[0]), formatISO(dateRange[1])]
      },
    },
    requestMethod: getGoogleReview,
  });

  const { results: clickedResults, total: clickTotal, setFilters: setFiltersClicked, setPageSize: setPageSizeClicked } = usePaginatedRequest({
    defaultPageSize: "*",
    defaultFilters: {
      clicked_at: {
        $notNull: true
      },
      created_at: {
        $between: [formatISO(dateRange[0]), formatISO(dateRange[1])]
      },
    },
    requestMethod: getGoogleReview,
  });

  const { results: locationResults, total: locationTotal, setFilters: setFiltersLocation, setPageSize: setPageSizeLocation } = usePaginatedRequest({
    defaultPageSize: "*",
    defaultFilters: {
      created_at: {
        $and: {
          $gte: dateRange[0].getTime(),
          $lt: dateRange[1].getTime(),
        }
      }
    },
    requestMethod: getGoogleLocationReview,
  });

  const { results: locationRatingTotalResults, total: locationRatingTotalTotal, setFilters: setFiltersLocationRatingTotal, setPageSize: setPageSizeLocationRatingTotal } = usePaginatedRequest({
    defaultPageSize: "*",
    defaultFilters: {
      created_at: {
        $and: {
          $lt: dateRange[1].getTime(),
        }
      }
    },
    requestMethod: getGoogleLocationReview,
  });

  const averageRating = locationResults.reduce((acc, location) => acc + location.rating, 0) / locationResults.length;
  const averageRatingTotal = locationRatingTotalResults.reduce((acc, location) => acc + location.rating, 0) / locationRatingTotalResults.length;
  const ratingVariation = averageRating - averageRatingTotal;

  useEffect(() => {
    let getFiltersStore = {};
    let getFiltersAssociate = {};
    let getFiltersDeliveryMethod = {};
    if (filters.store.length) {
      getFiltersStore = {
        store_id: {
          $in: filters.store
        }
      }
    }
    if (filters.deliveryMethod.length) {
      getFiltersDeliveryMethod = {
        review_request_origin: {
          $in: filters.deliveryMethod
        }
      }
    }
    if (filters.associate.length) {
      getFiltersAssociate = {
        associate_id: {
          $in: filters.associate
        }
      }
    }
    setFiltersSent({
      created_at: {
        $between: [formatISO(dateRange[0]), formatISO(dateRange[1])],
      },
      ...getFiltersStore,
      ...getFiltersAssociate,
      ...getFiltersDeliveryMethod
    })
    setFiltersClicked({
      clicked_at: {
        $notNull: true
      },
      created_at: {
        $between: [formatISO(dateRange[0]), formatISO(dateRange[1])]
      },
      ...getFiltersStore,
      ...getFiltersAssociate,
      ...getFiltersDeliveryMethod
    });
    setFiltersLocation({
      created_at: {
        $and: {
          $gte: dateRange[0].getTime(),
          $lt: dateRange[1].getTime(),
        }
      },
      ...getFiltersStore,
    });
    setFiltersLocationRatingTotal({
      created_at: {
        $and: {
          $lt: dateRange[1].getTime(),
        }
      },
      ...getFiltersStore,
    });
    setPageSizeSent("*");
    setPageSizeClicked("*");
    setPageSizeLocation("*");
    setPageSizeLocationRatingTotal("*");
  }, [dateRange, filters.deliveryMethod, filters.store, filters.associate])

  const columns = useMemo(() => {
    const reviews = auth?.google_integration ? [{
      title: 'Reviews',
      dataIndex: 'reviews',
      key: 'reviews',
      sorter: (a, b) => a.reviews - b.reviews,
    }] : [];
    return [
      {
        title: 'Sender',
        dataIndex: 'sender',
        key: 'sender',
        sorter: (a, b) => a.sender.localeCompare(b.sender),
      },
      {
        title: 'Sent',
        dataIndex: 'sent',
        key: 'sent',
        sorter: (a, b) => a.sent - b.sent,
      },
      {
        title: 'Clicked',
        dataIndex: 'clicked',
        key: 'clicked',
        sorter: (a, b) => a.clicked - b.clicked,
      },
      ...reviews,
    ]
  }, [auth.google_integration]);

  const table = useMemo(() => {
    let stores = filters.store.length ? auth.stores.filter(store => filters.store.includes(store.id.toString())) : auth.stores;
    let dataTable = stores.filter(st => st.id !== 0).map(store =>
    ({
      key: store.id,
      store_id: store.id,
      sender: store.name,
      sent: 0,
      clicked: 0,
      reviews: locationResults?.reduce((acc, location) => {
        if (location.store_id === store.id) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0) || 0
    }));
    sentResults.forEach(result => {
      const findIndex = dataTable.findIndex(data => data.store_id === result.store_id)
      if (findIndex > -1) {
        dataTable[findIndex].sent += 1;
        if (result.clicked_at) {
          dataTable[findIndex].clicked += 1;
        }
      }
    });
    return dataTable.slice((page - 1) * pageSizeTable, page * pageSizeTable);
  }, [sentResults, locationResults, page, pageSizeTable, auth.stores, filters.store]);

  const stats = useMemo(() => {
    let reviews = auth.google_integration ? [
      {
        title: 'Reviews',
        value: locationTotal,
        prefix: locationTotal > 0 && <ArrowUpOutlined style={{ opacity: 0.45 }} />
      },
      {
        title: 'Avg Rating',
        value: averageRating || 0,
        precision: 1,
        prefix: ratingVariation >= 0 ? <ArrowUpOutlined style={{ opacity: 0.45 }} /> : <ArrowDownOutlined style={{ opacity: 0.45 }} />
      }
    ] : []

    return [
      {
        title: 'Sent',
        value: sentTotal,
      },
      {
        title: 'Clicked',
        value: clickTotal,
      },
      ...reviews
    ]
  }, [sentTotal, clickTotal, locationTotal, averageRating, ratingVariation, auth.google_integration]);

  const expandedRowRender = useCallback((record) => {
    const columns = [
      {
        title: 'Client',
        dataIndex: 'client',
        key: 'client_id',
        render: (_, row) => (
          <StyledLink to={`/Clients?client=${row.client_id}`}>
            {row.client}
          </StyledLink>
        )
      },
      { title: 'Sent by', dataIndex: 'associate', key: 'associate_id' },
      {
        title: 'Sent',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (_, row) => row.created_at ? format(new Date(row.created_at), 'MMM dd, yyyy') : '-',
        sorter: (a, b) => isBefore(new Date(a.created_at), new Date(b.created_at)) ? -1 : 1,
        defaultSortOrder: 'descend'
      },
      {
        title: 'Clicked',
        dataIndex: 'clicked_at',
        key: 'clicked_at',
        render: (_, row) => row.clicked_at ? format(new Date(row.clicked_at), 'MMM dd, yyyy') : '-'
      },
    ];

    const data = [];
    if (record.store_id) {
      sentResults?.forEach(result => {
        if (result.store_id === record.store_id) {
          data.push({
            key: result.id,
            client: result.client,
            client_id: result.client_id,
            associate: result.associate,
            associate_id: result.associate_id,
            created_at: result.created_at,
            clicked_at: result.clicked_at
          });
        }
      });
    }
    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          total: data.length,
        }}
      />
    );
  }, [sentResults]);

  const handleExpandRow = useCallback((expand, record) => {
    let updatedExpandedRowKeys = [...expandedRowKeys];
    if (expand) {
      updatedExpandedRowKeys.push(record.store_id);
      setExpandedRowKeys(updatedExpandedRowKeys);
    } else {
      updatedExpandedRowKeys = updatedExpandedRowKeys.filter(key => key !== record.store_id);
      setExpandedRowKeys(updatedExpandedRowKeys);
    }
  }, [expandedRowKeys]);

  const graphData = useMemo(() => {
    let data = {};
    data['Sent'] = sentResults.map(result => ({
      date: result.created_at,
      total: 1,
    }))
    data['Clicked'] = clickedResults.map(result => ({
      date: result.clicked_at,
      total: 1,
    }))
    if (auth?.google_integration) {
      data['Reviews'] = locationResults.map(result => ({
        date: result.created_at,
        total: 1,
      }))
    }
    return data;
  }, [auth, sentResults, clickedResults, locationResults])

  return (
    <Report
      title={'Google Reviews'}
      stats={stats}
      options={options}
      hasCSVExport
      CSVFilename={'google-reviews.csv'}
      table={table}
      columns={columns}
      handleFilterChange={handleFilterChange}
      filters={filters}
      removeFilter={removeFilter}
      onDateRangeChange={handleOnDateRangeChange}
      dataRange={dateRange}
      defaultPeriodValue={getDefaultPeriod}
      tablesProps={{
        pagination: {
          total: auth.stores.length - 1,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSizeTable(pageSize)
          },
          pageSize: pageSizeTable,
        },
        expandable: {
          expandedRowRender: (record) => expandedRowRender(record),
          defaultExpandedRowKeys: ['0'],
          rowExpandable: record => record.sent > 0,
          onExpand: (expanded, record) => handleExpandRow(expanded, record),
          expandedRowKeys,
          expandRowByClick: true
        }
      }}
      graphData={graphData}
    />
  )
};


const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(GoogleReviews);
