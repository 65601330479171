import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Timeline } from 'antd-v5';
import { PurchaseOutline, MassMessages } from '../../../../../componentsV2/Layout/Report/components/Icons';
import { getRequest } from '../../../../../core/apiRequests';
import { BellOutlined, BulbOutlined } from '@ant-design/icons';
import TimelineItem from '../../../../../componentsV2/Layout/Report/components/Timeline';
import ReminderItem from '../../../../../componentsV2/Layout/Report/components/ReminderTimelineItem';
import SalesTimelineItem from '../../../../../componentsV2/Layout/Report/components/SalesTimelineItem';

const SalesExpandRow = ({ activities, transactions, client_name, sales_transaction_date, ...rest }) => {

  const [loading, setLoading] = useState(true);
  const [activitiesData, setActivitiesData] = useState([]);
  const [salesTransactionData, setSalesTransactionData] = useState([]);

  const getReportAttributedData = useCallback(() => {
    setLoading(true)
    const reqObj = {
      params: ['report', 'attributedMeta'],
      query: {
        reminders: JSON.stringify(activities.reminders?.map(item => item.id)),
        chatMessages: JSON.stringify(activities.chatMessages?.map(item => item.id)),
        salesTransactions: JSON.stringify(transactions.map(item => item.sales_transaction_id))
      },
      loadingStatusOff: true
    }
    getRequest(reqObj).then((res) => {
      const sales = res.filter(item => item.type === 'salesTransaction').map(sale => {
        const findTransaction = transactions.find(transaction => transaction.sales_transaction_id === sale.id)
        if(findTransaction) {
          return {
            ...sale,
            ...findTransaction
          }
        }
        return {...sale}
      })
      const acts = res.filter(item => item.type !== 'salesTransaction').map(act => {
        let type = act.type === 'chatMessage' ? 'chatMessages' : 'reminders'
        const findActivity = activities[type].find(activity => activity.id === act.id)
        if(findActivity) {
          return {
            ...act,
            ...findActivity
          }
        }
        return {...act}
      })
      setSalesTransactionData(sales)
      setActivitiesData(acts)
    }).finally(() => {
      setLoading(false)
    });
  }, [activities])

  useEffect(() => {
    getReportAttributedData();
  }, [activities])

  return (
    <div className="m-left-23">
      <div className="flex-row-spacebetween-nowrap">
        <Timeline
          pending={loading ? 'Loading...' : null}
          items={[
            ...salesTransactionData.map(({sales_transaction_amount, description}) => ({
              children: (
                <SalesTimelineItem
                  client_name={client_name}
                  sales_transaction_amount={sales_transaction_amount}
                  description={description}
                  activity_date={sales_transaction_date}
                />
              ),
              dot: <PurchaseOutline style={{ color: '#389E0D', fontSize: '18px'}} />
            })),
            ...activitiesData.map(activity => ({
              children: activity.type === 'reminders' ? <ReminderItem {...activity} /> : <MessageItem {...activity}/>,
              dot: Icon(activity.activity_type)
            }))
          ]}
        />
      </div>
    </div>
  );
};
export default SalesExpandRow;

const Icon = (type) => {
  switch (type) {
    case 'reminder':
      return <BellOutlined style={{ opacity: 0.45, color: '#000' }} />
    case 'massMessage':
    case 'autoMessage':
    case 'chatMessage':
      return <MassMessages style={{ fontSize: '16px', opacity: 0.45}} />
    case 'aiActivityResult':
      return <BulbOutlined style={{ opacity: 0.45, color: '#000' }} />
    default:
      return null
  }
};

const MessageItem = ({activity_associate_name, activity_date, activity_type, msg}) => {

  const title = useMemo(() => {
    return activity_type === 'aiActivityResult' ? (
      <>
        <span className="fw-600">{activity_associate_name || 'An Associate'} </span>did a Smart Assistant activity on
        <span className="fw-600"> {moment(activity_date).format('MMM DD, YYYY')}</span>
      </>
    ) : (
      <>
        <span className="fw-600">{activity_associate_name || 'An Associate'} </span>sent a message on
        <span className="fw-600"> {moment(activity_date).format('MMM DD, YYYY')}</span>
      </>
    )
  }, [activity_associate_name, ])

  return (
    <TimelineItem
      title={title}
      description={msg}
    />
  )
}

