import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const Footer = props => {
  const currentYear = new Date().getFullYear();

  return (
    <div
      className={props.footerClass ? `w-100-P ${props.footerClass}` : `w-100-P pos-sticky`}
      style={{ marginTop: '120px', marginBottom: '-25px' }}
    >
      <div className="flex-row-spacebetween-wrap maw-554">
        <p className="text-light-gray fs-12">© {currentYear} Clientbook Inc. All rights reserved.</p>
        <a
          className="text-light-gray fs-12 no-decoration"
          style={{ marginTop: '14px' }}
          href="https://clientbook.com/terms-and-conditions/"
        >
          {' '}
          Privacy{' '}
        </a>
        <a
          className="text-light-gray fs-12 no-decoration"
          style={{ marginTop: '14px' }}
          href="https://clientbook.com/terms-and-conditions/"
        >
          {' '}
          Terms of Service{' '}
        </a>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(Footer);
