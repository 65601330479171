import React, { useState } from 'react';

const PopMessage = ({ hasCancelButton = true, ...props }) => {
  const [clicked, setClicked] = useState(false);

  const onConfirmHandler = () => {
    if (!props.noButtonDisable) {
      setClicked(true);
    }
    props.onConfirm();
  };

  return (
    <div className="pop-confirm-background">
      <div
        className="pop-confirm-container  p-top-36 p-left-44 p-right-44 rem-pad-width p-btm-6 hide-scrollbar::-webkit-scrollbar hide-scrollbar"
        style={props.popStyle}
      >
        <span className="fs-22 fw-500 m-top-15 m-right-15 fs-18 m-btm-15 d-inline-block" style={props.confirmStyle}>
          {props.confirm}
        </span>
        <span className={`fs-13  m-top-37 ${props.customContent ? '' : 'd-inline-block'}`}>{props.description}</span>
        {props.children}
        <div
          className="flex-col-center m-top-25 m-btm-28 w-100-P"
          style={{ marginLeft: '5px', justifyContent: 'center', gridGap: '15px' }}
        >
          <button className="save-button-v2" disabled={clicked || props.disabledSaveButton} onClick={onConfirmHandler}>
            {props.confirmationButtonText || 'Send'}
          </button>
          {hasCancelButton && (
            <button
              className="cancel-button-v2"
              disabled={clicked || props.disabledCancelButton}
              onClick={props.onCancel}
            >
              {props.cancelButtonText || 'Cancel'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default PopMessage;
