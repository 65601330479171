import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import moment from 'moment';
import momenttz from 'moment-timezone';
import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import Layout from '../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';
import Button from '../../../../Layout/Button';
import MessageCriteria from './MessageCriteria';
import { getRequest } from '../../../../../core/apiRequests';
import {
  resetMassMessaging,
  setMassMessageClientCount,
  setMassMessageFullClientData,
} from '../../../../../reducers/massMessaging';
import PreviewAudienceModal from './PreviewAudienceModal';
import {
  setSortColumn,
  setRowCount,
  setPageCount,
  notificationError,
  notificationSuccess,
  setTablePaginationPageCount,
  setTablePaginationRowCount,
} from '../../../../../core/actions';

import 'react-datepicker/dist/react-datepicker.css';
import PopConfirm from '../../../../Layout/PopConfirm';
import TextAreaWithActions from '../../../../Layout/TextAreaWithActions';
import useActionTextAreaFiles from '../../../../Layout/TextAreaWithActions/useActionTextAreaFiles';
import KioskSlider from '../../../../Layout/KioskSlider';
import TimezoneDropdown from '../../Helpers/TimezoneDropdown';
import { API_URL } from '../../../../../constants';
import { setLoadingStatus } from '../../../../../reducers/notification';
import { logRocketEvent } from '../../../../../utils/logRocketIdentify';

const NewMassMessage = props => {
  const [msgTempText, setMsgTempText] = useState('');
  const [incAgreed, setIncAgreed] = useState(false);
  const [sendAsAsct, setSendAsAsct] = useState(true);
  const [rqrOptIn, setRqrOptIn] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [clientOptInPopup, setClientOptInPopup] = useState(false);
  const [clientIds, setClientIds] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [merchConfig, setMerchConfig] = useState({ intro: '', optOut: '' });
  const history = useHistory();
  const [reviewLink, setReviewLink] = useState('[Review Link]');
  const [fileData, fileDispatch] = useActionTextAreaFiles();
  const [timezone, setTimezone] = useState({ value: momenttz.tz.guess().replace('_', ' '), id: momenttz.tz.guess() });
  const [monthAudience, setMonthAudience] = useState('0');
  const [massMessageCriteria, setMassMessageCriteria] = useState({
    store: '',
    tagsCriteria: {},
    associate: '',
    preference: '',
    birthdayRange: { start: '', end: '' },
    anniversaryRange: { start: '', end: '' },
    prefContact: '',
    lastContacted: { start: '', end: '' },
    addedToClientbook: { start: '', end: '' },
    neverContacted: false,
  });

  const flags = props?.auth?.merchantFlags;
  const messageLimit = Number(flags?.mass_messaging?.value) || 0;

  const componentMounted = useRef(true);

  useEffect(() => {
    logRocketEvent('NewMassMessage');
  }, []);

  useEffect(() => {
    if (props.auth.merchantId && Object.keys(flags).length > 0 && !flags?.mass_messaging?.enabled) {
      if (componentMounted.current) {
        history.push('/');
      }
      return () => {
        componentMounted.current = false;
      };
    }
  }, [flags, props.auth.merchantId]);

  useEffect(() => {
    let { storeId } = props.auth;
    if (props.auth.merchantId && props.auth.stores.length > 1) {
      getMonthAudience();
      if (storeId === 0) {
        storeId = props.auth.stores[1].id;
      }
      (async () => {
        const reqObj = {
          params: ['associates', props.auth.userId, storeId, 'reviewLink'],
        };
        const data = await getRequest(reqObj);
        if (data[0].review_link) {
          setReviewLink(data[0].review_link);
        }
      })();
    }
  }, [props.auth.merchantId, props.auth.userId, props.auth.stores]);

  const getMonthAudience = () => {
    const { merchantId } = props.auth;
    (async () => {
      const reqObj = { params: ['massMessaging', merchantId, 'X', 'getMonthAudience'] };
      const monAud = await getRequest(reqObj);
      // const monAud = await getSettingList('massMessaging', merchantId, 'X', 'getMonthAudience');
      if (monAud && monAud.length > 0) {
        const respObj = monAud[0];
        setMonthAudience(respObj.monthAudience);
      }
    })();
  };

  const getClients = (getFullData, first) => {
    const {
      store,
      stores,
      tagsCriteria,
      associate,
      preference,
      birthdayRange,
      anniversaryRange,
      prefContact,
      lastContacted,
      addedToClientbook,
      neverContacted,
    } = props;
    // const { ascendingColumn } = props?.pagination;
    const ascendingColumn = props?.tablePaginationV2?.ascendingColumn;
    const sortColumn = props?.tablePaginationV2?.sortColumn || 'name';
    const pageSize = props?.tablePaginationV2?.pageSize || 25;
    const page = props?.tablePaginationV2?.page || 1;

    const storeIds = Number(store) ? [store] : props.auth.stores.filter(st => Number(st.id) !== 0).map(st => st.id);
    const sortedBy = first ? 'name' : sortColumn;
    const tagList = [];
    Object.values(tagsCriteria).forEach(tg => {
      if (tg.show) {
        tagList.push(tg.id);
      }
    });
    const birthdayStart = birthdayRange.start ? moment(birthdayRange.start, 'MM/DD/YYYY').format('YYYY-MM-DD') : '';
    const birthdayEnd = birthdayRange.end ? moment(birthdayRange.end, 'MM/DD/YYYY').format('YYYY-MM-DD') : '';
    const anniversaryStart = anniversaryRange.start
      ? moment(anniversaryRange.start, 'MM/DD/YYYY').format('YYYY-MM-DD')
      : '';
    const anniversaryEnd = anniversaryRange.end ? moment(anniversaryRange.end, 'MM/DD/YYYY').format('YYYY-MM-DD') : '';
    const addedToCBStart = addedToClientbook.start
      ? moment(addedToClientbook.start, 'MM/DD/YYYY').format('YYYY-MM-DD')
      : '';
    const addedToCbEnd = addedToClientbook.end ? moment(addedToClientbook.end, 'MM/DD/YYYY').format('YYYY-MM-DD') : '';
    const lastContactedStart = lastContacted.start
      ? moment(lastContacted.start, 'MM/DD/YYYY').format('YYYY-MM-DD')
      : '';
    const lastContactedEnd = lastContacted.end ? moment(lastContacted.end, 'MM/DD/YYYY').format('YYYY-MM-DD') : '';

    // HERE
    const reqObj = {
      params: ['chatMessages', props.auth.merchantId, 0, 'getClients'],
      query: {
        storeIds,
        tags: JSON.stringify(tagList),
        associate,
        preference,
        birthdayStart,
        prefContact,
        birthdayEnd,
        anniStart: anniversaryStart,
        anniEnd: anniversaryEnd,
        getFullData,
        // timezone,
        incAgreed,
        addedToCBStart,
        addedToCbEnd,
        lastContactedStart,
        lastContactedEnd,
        neverContacted,
        userId: Number(props.auth.userId),
        role: props.auth.role,
      },
    };
    if (getFullData) {
      reqObj.query.sortColumn = sortedBy;
      reqObj.query.pageSize = pageSize;
      reqObj.query.page = page;
      reqObj.query.ascending = ascendingColumn;
    }

    (async () => {
      setLoadingStatus(true);
      const clientData = await getRequest(reqObj);
      setLoadingStatus(false);
      if (clientData[0]) {
        if (getFullData) {
          const { rowCount, pageCount } = clientData[0].pageInfo;
          setMassMessageClientCount(clientData[0].count);
          setMassMessageFullClientData(clientData[0].fullData);
          setClientIds(clientData[0].clientIds);
          setMerchConfig(clientData[0].customizations);
          setRowCount(rowCount);
          setPageCount(pageCount);
          setTablePaginationPageCount(pageCount, 'PREVIEW_AUDIENCE');
          setTablePaginationRowCount(rowCount, 'PREVIEW_AUDIENCE');
        } else {
          setMassMessageClientCount(clientData[0].count);
          setClientIds(clientData[0].clientIds);
          setMerchConfig(clientData[0].customizations);
        }
      }
    })();
  };

  useEffect(() => {
    if (props.auth.merchantId) {
      if (showModal) {
        paginationHandler(false);
      }
    }
  }, [
    props.auth.merchantId,
    props.pagination.sortColumn,
    props.pagination.pageSize,
    props.pagination.page,
    props.pagination.ascendingColumn,
    props?.tablePaginationV2?.ascendingColumn,
    props?.tablePaginationV2?.page,
    props?.tablePaginationV2?.pageCount,
    props?.tablePaginationV2?.pageSize,
    props?.tablePaginationV2?.rowCount,
    props?.tablePaginationV2?.searchText,
    props?.tablePaginationV2?.sortColumn,
  ]);

  const paginationHandler = first => {
    if (first) {
      setSortColumn('name');
    }
    getClients(true, first);
  };

  const applyHandler = () => {
    if (dateCheck()) {
      getClients(false, false);
      validateMassMessage();
    }
  };

  const dateCheck = () => {
    const { birthdayRange, anniversaryRange, lastContacted, addedToClientbook } = props;
    if (
      ((anniversaryRange.start && anniversaryRange.end) || (!anniversaryRange.start && !anniversaryRange.end)) &&
      ((birthdayRange.start && birthdayRange.end) || (!birthdayRange.start && !birthdayRange.end)) &&
      ((lastContacted.start && lastContacted.end) || (!lastContacted.start && !lastContacted.end)) &&
      ((addedToClientbook.start && addedToClientbook.end) || (!addedToClientbook.start && !addedToClientbook.end))
    ) {
      return true;
    }
    return false;
  };

  const validateMassMessage = () => {
    const {
      store,
      tagsCriteria,
      associate,
      preference,
      birthdayRange,
      anniversaryRange,
      prefContact,
      lastContacted,
      addedToClientbook,
      neverContacted,
    } = props;
    setMassMessageCriteria({
      store,
      tagsCriteria,
      associate,
      preference,
      birthdayRange,
      anniversaryRange,
      prefContact,
      lastContacted,
      addedToClientbook,
      neverContacted,
    });
  };

  const saveMassMessage = () => {
    const imageFormObj = new FormData();

    const formattedText = msgTempText
      .replace('[Client Name]', ':name:')
      .replace('[Associate Name]', ':associate:')
      .replace('[Review Link]', ':review:')
      .replace('Client Name', ':name:')
      .replace('Associate Name', ':associate:')
      .replace('Review Link', ':review:');
    const ids = clientIds.map(cli => cli.id);

    const {
      store,
      tagsCriteria,
      // timezone,
      associate,
      preference,
      birthdayRange,
      anniversaryRange,
      prefContact,
      lastContacted,
      addedToClientbook,
      neverContacted,
    } = massMessageCriteria;

    const formattedDate = moment(startDate).tz(timezone.id, true).utc().format('YYYYMMDDHHmmss');

    const tagList = [];
    Object.values(tagsCriteria).forEach(tg => {
      if (tg.show) {
        tagList.push(tg.id);
      }
    });
    const reqData = {
      store,
      tags: tagList,
      associate,
      preference,
      birthdayRange,
      anniversaryRange,
      lastContacted,
      addedToClientbook,
      prefContact,
      incAgreed,
      rqrOptIn,
      sendAsAsct,
      formattedText,
      formattedDate,
      ids,
      merchConfig,
      neverContacted,
    };
    imageFormObj.append('text_data', JSON.stringify(reqData));
    fileData.images.files.forEach((imFile, i) => {
      imageFormObj.append(`image_${i}`, imFile);
    });
    if (formattedText.length > 5 || fileData.images.urls.length > 0) {
      if (startDate) {
        if (ids.length > 0) {
          Axios.post(
            `${API_URL}/xa/massMessaging/${props.auth.merchantId}/${props.auth.userId}/massMessageSave`,
            imageFormObj,
          )
            .then(resp => {
              resetMassMessaging();
              setShowPopup(false);
              notificationSuccess('Mass Message Saved!');
              history.push('/Messaging/mass-messaging');
            })
            .catch(err => {
              setShowPopup(false);
              console.error(`Error submitting new mass message: ${err}`);
              notificationError('Error Saving Mass Message');
            });
        } else {
          notificationError('Audience Has 0 Members!');
          setShowPopup(false);
        }
      } else {
        setShowPopup(false);
        notificationError('Please set a Start Date');
      }
    } else {
      setShowPopup(false);
      notificationError('Please type a valid message');
    }
  };

  const clientOptInCheck = kioskVal => {
    if (kioskVal === 'false' || kioskVal === false) {
      setClientOptInPopup(true);
    } else {
      setRqrOptIn(kioskVal);
    }
  };

  return (
    <Layout
      title="New Mass Message"
      description="Create personalized mass messages for filtered groups of clients. Your audience size may be limited based on the opt-in status of clients."
      classProps="standard-width-with-sidebar rem-pad-width"
      breadCrumbs={[
        { title: 'Messaging' },
        { title: 'Mass Messaging', to: '/Messaging/mass-messaging' },
        { title: 'New Mass Message' },
      ]}
    >
      <div
        className="maw-1200 h-100-P border-r8 border-all flex-row-nospacebetween-nowrap"
        style={{ alignItems: 'stretch' }}
      >
        <div className="w-289 border-right chatBubblePadding">
          <MsgCard
            key={1}
            titleContent={[
              <span key={1} className="fs-16 fw-500">
                Audience
              </span>,
              <span key={2} className="fs-16 fw-500 gray">
                {props.clientCount} Clients
              </span>,
            ]}
            includeTitle={false}
            bodyContent={[
              <MessageCriteria key={1} getClients={getClients} />,
              <div key={2} className="flex-row-spacebetween-nowrap w-100-P m-top-18">
                <span className="fs-13 gray max-w-249 align-left">
                  Only include clients that have previously agreed to receive marketing messages
                </span>
                <KioskSlider onChange={val => setIncAgreed(val)} value={incAgreed} />
              </div>,
              <div key={3} className="m-top-15 align-center w-100-P">
                <Button classname="darkBlueButton m-top-8 align-center" onclick={applyHandler}>
                  APPLY
                </Button>
              </div>,
            ]}
          />
          <MsgCard
            key={2}
            titleContent={<span className="fs-16 fw-500">Schedule</span>}
            includeTitle
            bodyContent={[
              <span key={1} className="fs-13 gray m-top-18">
                Delivery Start
              </span>,
              // <input key={2} type="datetime-local" className="unstyledDate m-top-18 common_inputStyle w-267 inputHeight" onChange={e => setStartDate(e.target.value)}/>,
              <DatePicker
                key={2}
                type="datetime-local"
                className="unstyledDate m-top-18 common_inputStyle w-267 inputHeight p-left-16"
                minDate={new Date()}
                // maxDate={new Date()}
                selected={startDate}
                minTime={setHours(setMinutes(new Date(), 0), 9)}
                maxTime={setHours(setMinutes(new Date(), 0), 19)}
                onChange={date => setStartDate(date)}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                showMonthDropdown
                showYearDropdown
                todayButton="Today"
                dropdownMode="select"
              />,
              <TimezoneDropdown
                key={4}
                type="timezone"
                id="timezone-dropdown"
                dropdownMode="select"
                onChange={(val, obj) => {
                  setTimezone(obj);
                }}
                value={timezone.value}
                style={{ width: 243, paddingTop: '8px' }}
              />,
              <span key={3} className="fs-10 gray align-left m-top-18">
                Messages are sent between 9am-7pm in the selected timezone above. If the batch is not sent within this
                timeframe, the messages will be delivered the next day. This is done to maintain TCPA compliance.
              </span>,
            ]}
          />
          <MsgCard
            key={3}
            titleContent={<span className="fs-16 fw-500">Settings</span>}
            includeTitle
            bodyContent={[
              <div key={1} className="flex-row-spacebetween-nowrap w-100-P m-top-18">
                <span className="fs-13 gray max-w-249 align-left">Send as associate when assigned</span>
                <KioskSlider onChange={val => setSendAsAsct(val)} value={sendAsAsct} />
              </div>,
              <div key={2} className="flex-row-spacebetween-nowrap w-100-P m-top-18">
                <span className="fs-13 gray max-w-249 align-left">Require client opt-in for marketing messages</span>
                <KioskSlider onChange={val => clientOptInCheck(val)} value={rqrOptIn} />
              </div>,
              <span key={3} className="fs-10 gray align-left m-top-18 m-btm-28">
                Before sending this message, an opt-in request will be sent to clients that have not previously received
                marketing messages. Opt-in messages will not count against your monthly messaging limits.
              </span>,
            ]}
          />
        </div>
        <div className="flex-col-left max-w-800 chatBubblePadding h-100-P">
          <div className="flex-col-right w-100-P">
            <span
              className=""
              onClick={() => {
                setShowModal(true);
                paginationHandler(true);
              }}
            >
              <FontAwesomeIcon className="gray fs-16 m-right-8" icon={['far', 'external-link']} size="2x" />
              <span className="gray fw-600 fs-13 pointer">PREVIEW AUDIENCE</span>
            </span>
          </div>
          <div>
            <TextAreaWithActions
              val={msgTempText}
              changeHandler={setMsgTempText}
              fileData={fileData}
              fileDispatch={fileDispatch}
              reviewLink={null}
              template
              styleProps={{ width: '100%', height: '200px', justifyContent: 'space-between', alignItems: 'stretch' }}
              containerStyleProps={
                {
                  // height: '261px'
                }
              }
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <button
                disabled={props.isLoading}
                className="save-button m-top-8"
                onClick={() => setShowPopup(true)}
                style={{ width: '160px' }}
              >
                SUBMIT
              </button>
            </div>
            <p className="fs-13 gray" style={{ marginTop: '15px' }}>
              Supported image file types: PNG, JPG, GIF. For optimal display, we recommend an image size of 640 x 1138
              or 1080 x 1920 pixels. The total size of all images may not exceed 5 MB. Images larger than 600 KB are not
              supported on all carriers.
            </p>
          </div>
        </div>
      </div>

      {/* {redirect ? <Redirect to="/Messaging/mass-messaging/message-details"/> : null} */}
      {showPopup ? (
        <PopConfirm
          confirm={
            clientIds.length + Number(monthAudience) > messageLimit
              ? `This audience exceeds your plan limit of ${messageLimit} messages`
              : 'Are you sure you want to send this message?'
          }
          description={`${
            clientIds.length + Number(monthAudience) > messageLimit ? 'You may be charged $0.05 per message.' : ''
          } This will send a message to ${clientIds.length} client${
            clientIds.length > 0 || clientIds.length === 0 ? 's' : ''
          }.`}
          onCancel={() => setShowPopup(false)}
          onConfirm={saveMassMessage}
        />
      ) : null}
      {clientOptInPopup ? (
        <PopConfirm
          confirm="Confirmation to turn off opt-in"
          description={`By proceeding, you are confirming that this message will go out to clients who have not opted in to receive text messages from you.
           You may be subject to fines and fees per TCPA legal guidelines. Sending this message to non-opted in clients is STRONGLY DISCOURAGED.`}
          onCancel={() => setClientOptInPopup(false)}
          onConfirm={() => {
            setClientOptInPopup(false);
            setRqrOptIn(false);
          }}
        />
      ) : null}
      {showModal ? <PreviewAudienceModal setShowModal={setShowModal} /> : null}
      <SecondaryPanel title={['Messaging']} current="Mass Messaging" />
    </Layout>
  );
};
const mapStateToProps = state => {
  return {
    ...state.massMessaging,
    tablePaginationV2: state.tablePagination.get('PREVIEW_AUDIENCE'),
    auth: state.auth,
    pagination: state.pagination,
  };
};
export default connect(mapStateToProps, {})(NewMassMessage);

const MsgCard = props => {
  return (
    <div>
      {props.includeTitle ? <div className="w-100-P-divider m-top-28" /> : null}
      <div className="flex-row-spacebetween-nowrap m-top-18">{props.titleContent}</div>
      <div className="flex-col-left">{props.bodyContent}</div>
    </div>
  );
};

/* const MiniKioskSlider = (props) => {
  return (
    <div>
      <label className='switch_mini'>
        <input
          value={props.value}
          className={props.value ? 'KioskTrue_mini' : ''}
          type='checkbox'
          onClick={() => {
            props.changeHandler(!props.value);
          }}
        />
        <span className='slider_mini round'></span>
      </label>
    </div>
  );
}; */

const ImageCard = props => {
  return (
    <div key={props.ind} className="pos-rel w-67 h-67 m-right-15">
      <FontAwesomeIcon
        className="pos-abs fs-24 bg-white circle gray"
        icon={['far', 'times-circle']}
        style={{
          top: '-12px',
          right: '-12px',
          border: '2px solid white',
        }}
        onClick={() => props.imageDeleteHandler(props.ind)}
      />
      <div className="w-67 h-67" style={{ overflow: 'hidden' }}>
        <img src={props.imageUrl} className="h-67 border-r4" alt="#" />
      </div>
    </div>
  );
};
