import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import StyledLink from '../../../Layout/StyledLink';
import Table from '../../../Layout/Table';
import Layout from '../../../Layout/StandardLayout';
import { getRequest } from '../../../../core/apiRequests';
import LoadingSpinner from '../../../Layout/Notifications/LoadingSpinner';

const SalesOpp = props => {
  const [salesOpps, setSalesOpps] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isThereMore, setIsThereMore] = useState(true);
  const [salesStages, setSalesStages] = useState([]);

  useEffect(() => {
    if (props.auth.merchantId) {
      getSalesOppData();
    }
  }, [props.auth.merchantId, page]);

  const rowClickHandler = () => {};

  const incrementPage = () => {
    const newPage = page + 1;
    setPage(newPage);
  };

  const getSalesOppData = () => {
    const reqObj = {
      params: ['today', props.auth.merchantId, 'getSalesOpps', props.auth.userId],
      query: { pageStart: page },
    };
    setIsLoading(true);
    (async () => {
      const data = await getRequest(reqObj);
      setIsLoading(false);
      if (data) {
        setSalesOpps([...salesOpps, ...data.salesOpps]);
        setSalesStages([...data.salesStages]);
      }
      if (data.length < 25) {
        setIsThereMore(false);
      }
    })();
  };

  const bubbleStyles = {
    padding: '4px 0 2px 0',
  };

  const tableHeaders = [
    {
      keyName: 'close_date',
      title: 'Due Date',
      style: { width: '2%', paddingTop: '0px', midWidth: '90px' },
      headerClassName: '',
      rowClassName: '', // we can apply all styles to the header now.
      sortable: false, // This targets the header's className
      render: t => {
        if (t) {
          return moment(t, 'YYYYMDD').format('MM/DD/YYYY');
        }
      },
    },
    {
      keyName: 'client_name',
      style: { width: '2%', minWidth: '120px', paddingTop: '0px' },
      title: 'Client Name',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        return (
          <StyledLink classprops="blue-link" to={`/Clients?client=${o.client_id}`}>
            {t}
          </StyledLink>
        );
      },
    },
    {
      keyName: 'name',
      style: { width: '20%', minWidth: '120px', paddingTop: '0px' },
      title: 'Description',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        return (
          <StyledLink classprops="blue-link" to={`/Clients?client=${o.client_id}&oppId=${o.id}`}>
            {t}
          </StyledLink>
        );
      },
    },
    {
      keyName: 'amount',
      style: { width: '3%', minWidth: '80px', paddingTop: '0px' },
      title: 'Price Value',
      sortable: false, // sort allows gives us the ability to sort the table
      render: t => {
        if (t) {
          return `$${t}`;
        }
        return '$0';
      },
    },
    {
      keyName: 'stage_name',
      style: { width: '3%', minWidth: '80px', paddingTop: '0px' },
      title: 'Stage',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        if (o.weight === 0 || o.weight === 1) {
          return (
            <div
              style={{ ...bubbleStyles, backgroundColor: '#e8f4ff' }}
              className="align-center rem-pad-width border-r3 align-vert-middle h-24 fs-10 fw-600 w-72 blue"
            >
              {t}
            </div>
          );
        }
        if (green(o) == o.sales_opp_stage_id) {
          return (
            <div
              style={{ ...bubbleStyles, backgroundColor: '#ecf8dd' }}
              className="align-center rem-pad-width border-r3 align-vert-middle h-24 fs-10 fw-600 w-72 green"
            >
              {t}
            </div>
          );
        }
        return (
          <div
            style={{
              ...bubbleStyles,
              backgroundColor: '#ffe5d3',
              color: '#fc8333',
            }}
            className="align-center rem-pad-width border-r3 align-vert-middle h-24 fs-10 fw-600 w-72"
          >
            {t}
          </div>
        );
      },
    },
    {
      keyName: 'created_by',
      style: { width: '2%', paddingTop: '0px', minWidth: '120px' },
      title: 'Created By',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        return <span>{fullNameCheck(o.first_name, o.last_name)}</span>;
      },
    },
    {
      keyName: '',
      style: { width: '3%', paddingTop: '0px' },
      title: '',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        const now = moment(new Date());
        if (!o.complete_date) {
          if (moment(now).isAfter(moment(o.close_date, 'YYYYMMDD'))) {
            return (
              <div
                style={{
                  ...bubbleStyles,
                  backgroundColor: '#f9e0e0',
                  color: '#ff7a7a',
                }}
                className="align-center border-r3 rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 blue"
              >
                OVERDUE
              </div>
            );
          }
        }
      },
    },
  ];

  const fullNameCheck = (first, last) => {
    if (last) {
      return `${first} ${last}`.trim();
    }
    return first;
  };

  const green = obj => {
    // here
    if (salesOpps.length > 0) {
      const filteredSalesOpps = salesStages.filter(opp => opp.sales_pipeline_id == obj.sales_pipeline_id) || [];
      let small = {};
      if (filteredSalesOpps.length > 0) {
        small = filteredSalesOpps.reduce(function (prev, curr) {
          return prev.weight < curr.weight ? prev : curr;
        });
        return small.id;
      }
    }
  };

  return (
    <Layout
      title="All Sales Opportunities"
      description=""
      classProps="standard-width-wo-sidebar"
      breadCrumbs={[{ title: 'Today', to: '/' }, { title: 'All Sales Opportunities' }]}
      // styleProps={{ marginLeft: 79 }}
    >
      <div className="align-left">
        <InfiniteScroll
          dataLength={salesOpps.length} // This is important field to render the next data
          next={incrementPage}
          hasMore={isThereMore}
          loader={isLoading ? <LoadingSpinner /> : null}
          endMessage={
            salesOpps.length > 0 ? (
              <p style={{ textAlign: 'center' }}>
                <b>End of Sales Opportunities</b>
              </p>
            ) : null
          }
          scrollableTarget="scrollWatchId"
        >
          {salesOpps.length > 0 ? (
            <Table
              data={salesOpps}
              tableHeaders={tableHeaders}
              tableClassName="lg-table-sidebar  rem-pad-width"
              optionalButton={null}
              onRowClick={rowClickHandler}
              tableName="SALES_OPP_TODAY_2"
            />
          ) : (
            <div className="align-center h-173 w-100-P">
              <span className="pos-rel gray fs-13" style={{ top: '45%' }}>
                No Sales Opportunities
              </span>
            </div>
          )}
        </InfiniteScroll>
      </div>
    </Layout>
  );
};
const mapStateToProps = state => ({ auth: state.auth });
export default connect(mapStateToProps, {})(SalesOpp);
