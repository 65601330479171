import React from 'react';
import ShopifyInstallService from './Services/ShopifyInstallService';

 
const ServiceTaskHandler = (props) => {


        return (
        <>
            <ShopifyInstallService merchantId={props.merchantId}/>
        </>
    )
}
export default ServiceTaskHandler