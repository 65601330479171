import React from 'react';

const FullScreenModal = ({ keepMounted = false, open = false, children }) => {
  if (!open && !keepMounted) return null;
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        padding: '57px 32px 0px 32px',
        zIndex: 997,
        backgroundColor: '#F5F6F8',
        overflowY: 'auto',
      }}
    >
      <div style={{ height: 32 }} />
      {children}
    </div>
  );
};

export default FullScreenModal;
