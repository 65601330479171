import React, { useRef, useState } from 'react';
import { EllipsisOutlined, InfoCircleFilled } from '@ant-design/icons';
import { List, ConfigProvider } from 'antd';
import { getRequest } from '../../../../../../core/apiRequests';
import FakeDisabledInput from './children/FakeDisabledInput';

const NewMessage = props => {
  const isMounted = useRef(null);
  const [searchList, setSearchList] = useState([]);
  const [searchResult, setSearchResult] = useState(false);
  const [customize, setCustomize] = useState(false);

  const searchAllClient = searchText => {
    isMounted.current = true;
    const returnArr = [];
    props.associateStores.forEach(store => {
      if (store.id !== 0) {
        returnArr.push(store.id);
      }
    });
    (async () => {
      const reqObj = {
        params: ['chats', 'clientSearch'],
        query: { searchText, associateStores: returnArr },
      };
      const clientSearchResults = await getRequest(reqObj);
      if (clientSearchResults.length) {
        setSearchList(clientSearchResults);
      } else {
        setCustomize(true);
      }
      setSearchResult(true);
    })();
    return () => {
      isMounted.current = false;
    };
  };

  const handleSearchChange = e => {
    if (e.key === 'Enter' && e.target.value) {
      // setMsgClientChatMessages([]);
      searchAllClient(e.target.value);
      setSearchResult(false);
    }
    if (e.target.value.length < 2 && e.key === 'Backspace') {
      setSearchResult(false);
    }
  };

  const customizeRenderEmpty = () => (
    <div id="customizedNoData" style={{ textAlign: 'center', paddingTop: '57px' }}>
      <p>No clients match your search</p>
    </div>
  );

  return (
    <div
      className="w-100-P flex-col-center"
      id="client-search-wrapper"
      style={{ justifyContent: 'space-between', height: 'calc(100% - 70px)' }}
    >
      <div
        className="conversation-window-header w-100-P "
        id="client-search-section"
        style={{ borderBottom: '0.5px solid #EBEBEB', height: '78px' }}
      >
        <div className="pos-rel add-edit-wrap" style={{ width: '50%' }} id="client-search">
          <input
            onKeyDown={handleSearchChange}
            className="clientSearchInbox h-45"
            id="client-search-input"
            placeholder="Client Search..."
            style={{ width: '50%', marginLeft: '37px' }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          {searchResult ? (
            <div
              className="pos-abs w-295 bg-white"
              id="client-search-list-wrapper"
              style={{ top: '50px', left: '50px' }}
            >
              <ConfigProvider renderEmpty={customize ? customizeRenderEmpty : undefined}>
                <List
                  size="small"
                  bordered
                  dataSource={searchList}
                  id="client-search-list"
                  renderItem={item => (
                    <List.Item
                      onClick={() => {
                        props.setSelectedClient({ ...item, id: item.client_id, isOpen: true, type: 'C' });
                        setSearchList([]);
                        props.setNewMessage(false);
                      }}
                    >
                      {item.name}
                    </List.Item>
                  )}
                />
              </ConfigProvider>
            </div>
          ) : null}
        </div>
        <div
          // className="conversation-window-header-heading-icons"
          style={{ width: '50%', justifyContent: 'flex-end', display: 'flex' }}
        >
          <EllipsisOutlined style={{ fontSize: '23px', marginRight: '12px', marginBottom: '3px', color: '#EBEBEB' }} />
          <InfoCircleFilled style={{ fontSize: '16px', color: '#EBEBEB', marginTop: '2px', marginRight: '16px' }} />
        </div>
      </div>
      <FakeDisabledInput />
    </div>
  );
};

export default NewMessage;
