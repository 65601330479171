import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledLink from '../../../Layout/StyledLink';

const MN_subMenu = props => {
  if (props.menuOptions && props.menuOptions.length > 0) {
    return (
      <div>
        <div
          className={`${props.subMenuToggle} ${
            props.paymentBannerActive ? 'mobile-sub-nav-panel-banner' : 'mobile-sub-nav-panel'
          }`}
          style={props.showListMobile ? { width: '269px', display: 'block' } : {}}
        >
          <div>
            <div
              className="add-edit-wrap"
              onClick={() => {
                props.setSubMenuToggle('subMenu_toggle');
                setTimeout(() => {
                  props.setShowListMobile('');
                }, 400);
              }}
            >
              <FontAwesomeIcon className="m-top-5" icon={['far', 'angle-left']} color="#45484b" size="sm" />
              <p className="expandHeaderMobile">{props.title}</p>
            </div>
            <div>
              <FontAwesomeIcon
                className={`pointer ${props.paymentBannerActive ? 'mobile-side-pane-x-banner' : 'mobile-side-pane-x'}`}
                onClick={() => props.toggleMobileMenu(false)}
                icon={['far', 'times']}
                color="white"
                size="sm"
              />
            </div>
          </div>
          <ul className="secondary-menu-short hide-scrollbar">
            {props.menuOptions.map((opt, i) => {
              if (opt.link) {
                return (
                  <div className="vertNavDivMobile" key={i}>
                    <li
                      key={i}
                      className=" subMenuHover pos-abs align-left"
                      onClick={() => props.toggleMobileMenu(false)}
                    >
                      <StyledLink
                        //   classprops={linkClass}
                        styleprops={{
                          height: '25px',
                          display: 'inline-block',
                          width: '150px',
                          paddingLeft: '43px',
                          paddingTop: '5px',
                          marginLeft: '-17px',
                          fontWeight: '500',
                          fontSize: '12px',
                          color: '#45484b',
                        }}
                        to={opt.link}
                      >
                        {opt.title}
                      </StyledLink>
                    </li>
                  </div>
                );
              }
              return (
                <div className="vertNavDiv pointer" style={{ marginTop: '-8px', marginLeft: '22px' }}>
                  <li key={i} className="subMenuHover pos-abs" onClick={() => props.toggleMobileMenu(false)}>
                    <div className="vertNavInnerDiv" style={{ marginLeft: '10px', height: '35px' }}>
                      <p
                        className="subMenuHover"
                        style={{
                          height: '27px',
                          display: 'inline-block',
                          width: '131px',
                        }}
                        onClick={() => {
                          props.menuOptions.onClick();
                        }}
                      >
                        {opt.title}
                      </p>
                    </div>
                  </li>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
};
const mapStateToProps = state => ({
  paymentBannerActive: state.paymentBanner.active,
  ...state.navigation,
});
const mapDispatchToProps = dispatch => ({
  expandMNav(val) {
    dispatch({
      type: 'COLLAPSE_MOBILE_NAV',
      payload: val,
    });
  },
  toggleMobileMenu(val) {
    dispatch({
      type: 'SHOW_MOBILE_NAV',
      payload: val,
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(MN_subMenu);
