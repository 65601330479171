import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Button, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API_URL } from '../../../../../../constants';
import AddSalesOpportunityModal from './components/AddSalesOpportunityModal';
import SalesOpportunityCard from './components/SalesOpportunityCard';
import './styles.scss';
import useSearchParams from '../../../../../../hooks/useSearchParams';

const SalesOpportunities = props => {
  const [loading, setLoading] = useState(false);
  const [salesOpportunities, setSalesOpportunities] = useState([]);
  const [showOpportunityModal, setShowOpportunityModal] = useState(false);
  const { setValue } = useSearchParams();

  const fetchSalesOpportunities = useCallback(async () => {
    if(props.client.client_id) {
      setLoading(true);

      try {
        const response = await axios.get(`${API_URL}/xa/clients/${props.client.client_id}/sales-opportunities`, {
          params: {
            page: 1,
            pageSize: 4,
          },
        });

        setSalesOpportunities(response.data.results);
      } catch (error) {
        message.error('Error Fetching Sales Opportunities');
      } finally {
        setLoading(false);
      }
    }
  }, [props.client.client_id]);

  const showAddSalesOppHandler = () => {
    setShowOpportunityModal(!showOpportunityModal);
  };


  useEffect(() => {
    fetchSalesOpportunities();
  }, [props.client.client_id]);

  return (
    <>
      <div className="cb-sales-opportunities">
        <div className="cb-sales-opportunities--header">
          <div className="cb-sales-opportunities--header-left">
            <h2 className="cb-sales-opportunities--header-title">Sales Opportunities</h2>

            <Button
              type="text"
              icon={
                <FontAwesomeIcon
                  size="1x"
                  icon={['far', 'plus']}
                  id="ClientDetailsEditTag"
                  className="pointer m-left-16 fs-19"
                />
              }
              onClick={() => setShowOpportunityModal(true)}
              className="cb-sales-opportunities--add-button"
            />
          </div>

          {Boolean(salesOpportunities.length) && (
            <div className="cb-sales-opportunities--header-right">
              <Button
                type="text"
                onClick={() => {
                  props.setViewAllType('allSalesOpportunities');
                }}
                className="cb-sales-opportunities--view-all-button"
              >
                VIEW ALL
              </Button>
            </div>
          )}
        </div>

        <div className="cb-sales-opportunities--body">
          {loading && <div className="cb-sales-opportunities--body-helper">Loading...</div>}

          {!loading && !salesOpportunities.length && (
            <>
              <div className="cb-sales-opportunities--body-helper">No Sales Opportunities</div>

              <Button
                type="text"
                onClick={() => {
                  setValue('viewAllTab', 'allSalesOpportunities')
                }}
                className="cb-sales-opportunities--view-all-button-empty"
              >
                VIEW ALL
              </Button>
            </>
          )}

          {/* @TODO: SALES OPPORTUNITY MODAL */}

          {!loading &&
            Boolean(salesOpportunities.length) &&
            salesOpportunities.map(item => (
              <SalesOpportunityCard
                key={item.id}
                salesOpportunity={item}
                onClick={() => {
                  setValue('oppId', item.id);
                  setValue('oppLocation', 'quickViewSalesOpp');
                }}
              />
            ))}
        </div>
      </div>

      {showOpportunityModal && (
        <AddSalesOpportunityModal
          showAddSalesOppHandler={showAddSalesOppHandler}
          salesPipelines={props.client.salesPipelines}
          associateId={props.auth.userId}
          merchantId={props.auth.merchantId}
          client={props.client}
          setSalesOpportunities={setSalesOpportunities}
          getReminderData={props.getReminderData}
          getClientData={props.getClientData}
          // associateId={props.associate.associateId}
        />
      )}
    </>
  );
};

export default SalesOpportunities;
