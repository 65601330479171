import React, { useState } from 'react';
import { DownOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import Input from '../../../../CustomizedAntDesign/Input/Input';

const BlackListedPages = ({ obj, setObj }) => {
  const [open, setOpen] = useState(false);
  let blackListedPages = (
    <div>
      <p>
        Important: The URLs need to be listed as strings, meaning that each URL needs to be separated by commas and
        enclosed with single quotation marks.
      </p>
      <span>For example: 'products', 'watches'. </span>

      <p>
        If this is not done correctly, your web chat will be unable to function on your site. This applies to both
        Blacklisted and Whitelisted URLs. To ensure your web chat works flawlessly, double check your spelling before
        adding the new script to your site. If you are having issues with your web chat, reach out to our Customer
        Success Team at (385) 352-3518 or
        <span style={{ color: '#33AAFC' }}>
          <a id="BlackListSupport" href="mailto:support@clientbook.com">
            {' '}
            support@clientbook.com
          </a>
        </span>
      </p>
    </div>
  );
  let blackListedUrls = (
    <div>
      <p>{"The Blacklist function allows you to choose which pages you don't want your web chat to appear on"}</p>
      <p>For example: You may want the web chat on all pages except your product page.</p>
    </div>
  );
  let regexBlackList = (
    <div>
      <p>
        Adding a regular expression to your Blacklisting parameters allows for more specific and complex page filtering.
        For more information on regular expressions,{' '}
        <span style={{ color: '#33AAFC' }}>
          <a id="RegularExpression" href="https://en.wikipedia.org/wiki/Regular_expression">
            {' '}
            click here
          </a>
          .
        </span>
      </p>
      <p>
        Important: Regular expressions can be incredibly complicated. You may want to consult a software developer
        before getting started.
      </p>
      <p>
        When adding a regular expression, double check that your input is correct. If it is incorrect, your web chat
        will not work on your site. If you are having issues with your web chat, reach out to our Customer Success Team
        at (385) 352-3518 or
        <span style={{ color: '#33AAFC' }}>
          <a id="RegularExpressionSupport" href="mailto:support@clientbook.com">
            {' '}
            support@clientbook.com
          </a>
        </span>
      </p>
    </div>
  );
  let whiteListedUrls = (
    <div>
      <p>The Whitelist function allows you to choose which pages your web chat should appear on. </p>
      <p>For example: You may want the web chat to only appear on your home page, but not on others.</p>
    </div>
  );
  return (
    <div className="borderBottom">
      <div className="blackListedDiv maw-1200" id="BlackListedOuterDivId" onClick={() => setOpen(!open)}>
        <div className="blackListedPagesDiv">
          <TitleAndContent
            boldedTitle={'Blacklist Pages'}
            toolTip={blackListedPages}
            description={"Choose pages that you don't want your web chat to appear on"}
          ></TitleAndContent>
          {open ? (
            <UpOutlined style={{ fontSize: '21px', marginRight: '10px' }} />
          ) : (
            <DownOutlined style={{ fontSize: '21px', marginRight: '10px' }} />
          )}
        </div>
      </div>
      {open ? (
        <div style={{ marginTop: '-22px', marginBottom: '20px' }}>
          <TitleAndContent
            boldedTitle={'Blacklisted URLs'}
            toolTip={blackListedUrls}
            description={
              "Enter URLs for pages that you don't want your web chat to appear on. Separate each URL with a comma and ensure you spell each one correctly."
            }
            input={[
              <div className="w-100-P">
                <Row>
                  <Col span={6}>
                    <Input
                      id="BlackListedInput"
                      placeholder="'URL', 'URL', 'URL'"
                      onChange={e => setObj({ ...obj, blackListed: e.target.value })}
                      className="inputHeight common_inputStyle w-333"
                    ></Input>
                  </Col>
                </Row>
              </div>,
            ]}
          ></TitleAndContent>
          <TitleAndContent
            boldedTitle={'Regex Blacklisted URLs'}
            toolTip={regexBlackList}
            description={'Enter a regular expression for more detailed and complex filtering'}
            input={[
              <div className="w-100-P">
                <Row>
                  <Col span={6}>
                    <Input
                      id="RegexInput"
                      placeholder="Enter an expression"
                      onChange={e => setObj({ ...obj, regex: e.target.value })}
                      className="inputHeight common_inputStyle w-333"
                    ></Input>
                  </Col>
                </Row>
              </div>,
            ]}
          ></TitleAndContent>
          <TitleAndContent
            boldedTitle={'Whitelisted URLs'}
            toolTip={whiteListedUrls}
            description={
              'Enter the URLs of pages you want to allow your web chat to appear on. Separate each URL with a comma and ensure you spell each one correctly.'
            }
            input={[
              <div className="w-100-P">
                <Row>
                  <Col span={6}>
                    <Input
                      id="WhiteListedInput"
                      placeholder="'URL', 'URL', 'URL'"
                      onChange={e => setObj({ ...obj, whiteListed: e.target.value })}
                      className="inputHeight common_inputStyle w-333"
                    ></Input>
                  </Col>
                </Row>
              </div>,
            ]}
          ></TitleAndContent>
        </div>
      ) : null}
    </div>
  );
};
export default BlackListedPages;

const TitleAndContent = props => {
  return (
    <div className="flex-col-left m-left-21 m-top-20">
      <div className="add-edit-wrap">
        <span className="fs-13 fw-500 "> {props.boldedTitle}</span>
        <Tooltip
          placement="bottomLeft"
          title={props.toolTip}
          overlayInnerStyle={{ width: '352px', borderRadius: '10px', padding: '19px 17px', backgroundColor: '#000' }}
        >
          <InfoCircleOutlined className="m-left-10" />
        </Tooltip>
      </div>
      <span className="fs-11 fw-500 m-btm-6" style={{ color: '#9c9c9c' }}>
        {props.description}
      </span>
      {props.input}
    </div>
  );
};
