import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Table from '../../../Layout/Table';
import TitleDescription from '../../../Layout/DescriptionBox';
import Layout from '../../../Layout/StandardLayout';
import Button from '../../../Layout/Button';
import { setAuthStoreId } from '../../../../reducers/auth';
import { getRequest } from '../../../../core/apiRequests';
import DropdownSelect from '../../../Layout/DropdownSelect';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import StyledLink from '../../../Layout/StyledLink';
import BulletedList from '../../../Layout/BulletedList.js';
import RangePicker from '../../../CustomizedAntDesign/RangePicker';
import '../reports.css';

const disabledDate = current => {
  return current && current > moment().endOf('day');
};

const AssociateActivity = props => {
  const [dateRange, setDateRange] = useState([moment().subtract(1, 'months'), moment()]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showReport, setShowReport] = useState(false);

  const runReport = e => {
    e.preventDefault();

    const reportEnd = moment(dateRange[1]).endOf('day').toISOString();
    const reportStart = moment(dateRange[0]).startOf('day').toISOString();

    const reqObj = {
      params: ['report', 'associateActivity'],
      query: {
        licenseKey: props.auth.licenseKey,
        startDt: reportStart,
        endDt: reportEnd,
        storeAccess: props.auth.stores.filter(st => st.id !== 0).map(st => st.id),
      },
    };
    if (props.auth.storeId) {
      reqObj.query.storeId = props.auth.storeId;
    }

    (async () => {
      const res = {};
      res.data = await getRequest(reqObj);

      const assocData = [];
      const associates = {};
      const action = {};
      res.data.rows.forEach(row => {
        const { name, result, id } = row;
        const actionType = row.type;
        if (!associates[`${name}`]) {
          associates[`${name}`] = {};
          associates[`${name}`].name = row.name;
        }

        // eslint-disable-next-line no-unused-expressions
        associates[name].id || (associates[name].id = id);
        associates[`${name}`][`${actionType}`] = `${result}`;
        if (!action[actionType]) {
          action[`${actionType}`] = true;
        }
      });

      const tempData = [];

      Object.keys(associates).forEach(ascName => {
        assocData.push(associates[ascName]);
        tempData.push(associates[ascName]);
      });

      const tempAssocArray = [];

      Object.keys(associates).forEach(key => {
        tempAssocArray.push(associates[key]);
      });

      const totals = tempAssocArray.reduce(
        (acc, curr) => {
          Object.keys(curr).forEach(key => {
            if (key !== 'name' && key !== 'id') {
              acc[key] = acc[key] ? acc[key] + parseInt(curr[key], 10) : parseInt(curr[key], 10);
            }
          });
          return acc;
        },
        { name: 'Total', className: 'fw-600 fs-16' },
      );
      tempAssocArray.push(totals);
      setTableData(tempAssocArray);

      const tempTableHeaders = [
        {
          keyName: 'name',
          headerClassName: 'row1',
          rowClassName: 'col-p-l-25',
          title: 'Associates',
          style: {
            width: '5%',
            paddingLeft: '20px',
            paddingTop: '80px',
            paddingRight: '20px',
          },
          render: (t, o) =>
            o.id ? (
              <StyledLink to={`/Associates/associate-details/${o.id}`}>
                {t || `${o.first_name || ''} ${o.last_name || ''}`.trim()}
              </StyledLink>
            ) : (
              t
            ),
        },
      ];

      Object.keys(action).forEach(key => {
        tempTableHeaders.push({
          keyName: key,
          style: { width: '5%', paddingTop: '80px', paddingRight: '10px' },
          headerClassName: 'tags_row2',
          title: key,
          sortable: false,
        });
      });

      setTableHeaders(tempTableHeaders);
    })();

    setShowReport(true);
  };

  return (
    <Layout
      title="Associate Activity"
      backArrow="/Home"
      description="Set your parameters and click on 'Run Report.'"
      classProps="standard-width-with-sidebar flex-col-center"
      styleProps={{ justifyContent: 'flex-start' }}
    >
      <div className="report-smaller-description">
        {' '}
        Associate Activity reports measure the number of activities completed or logged by your associates. These
        reports can help you see which associates are having the most success and where your team can improve.{' '}
      </div>
      <BulletedList
        items={['Clients Added', 'Calls Logged', 'Clients Messaged']}
        title="This report measures the following activities:"
      />
      <div className="pos-rel w-100-P " style={{ maxWidth: '866px' }}>
        <TitleDescription
          title="Reporting Location"
          description="What store location would you like to run a report on?"
          input={
            <DropdownSelect
              isSearchable
              classProps="mq-w-200 w-267 align-left"
              options={props.auth.stores.map(store => ({
                label: store.name,
                value: store.id,
              }))}
              placeholder="Select a Store..."
              value={props.auth.storeId}
              onChange={obj => {
                setAuthStoreId(obj.value);
              }}
            />
          }
        />

        <TitleDescription
          title="Date Range"
          input={
            <RangePicker
              format="MM/DD/YYYY"
              disabledDate={disabledDate}
              value={dateRange}
              onChange={setDateRange}
              ranges={{
                'Last 7 Days': [moment().subtract(7, 'd'), moment()],
                'Last 30 Days': [moment().subtract(30, 'd'), moment()],
                'Last 60 Days': [moment().subtract(60, 'd'), moment()],
                'Last 90 Days': [moment().subtract(90, 'd'), moment()],
              }}
              showToday
            />
          }
          className="report-date-picker"
        />

        <div className="report-asct-msg-row" style={{ maxWidth: '866px' }}>
          <Button id="RunAssociateActivityReport" classname="darkBlueButton" onclick={e => runReport(e)}>
            Run Report
          </Button>
        </div>
      </div>
      {showReport ? (
        <div className="w-100-P rem-pad-width maw-1200">
          <div
            className="import_export_group w-100-P"
            style={{
              justifyContent: 'flex-end',
              zIndex: '100',
              marginLeft: '0px',
              marginTop: '100px',
            }}
          >
            <img alt="#" src="https://dashboard-v2-images.s3.amazonaws.com/ic-export.svg" />
            <CSVLink
              data={tableData}
              filename="AssociateActivity.csv"
              id="DownloadAssociateActivityReport"
              className="exportButton m-right-15"
            >
              Download
            </CSVLink>
          </div>

          <Table
            tableClassName="maw-1200 rem-pad-width m-top-15 overflowX hide-scrollbar w-100-P"
            tableContainerClass="maw-1200 w-100-P rem-pad-width "
            style={{ marginTop: '-100px', width: '100%' }}
            data={tableData}
            tableRowClassName="col-p-l-25"
            tableHeaders={tableHeaders}
            tableName="ASSOCIATE_ACTIVITY"
          />
        </div>
      ) : null}
      <SecondaryPanel
        overhead_title="Reports"
        title={['CLIENTELING', 'MESSAGING', 'Google Reviews', 'PAYMENTS', 'AUTOMATION', 'SALES OPPORTUNITY']}
        current="Associate Activity"
      />
    </Layout>
  );
};

const mapStateToProps = state => ({
  reports: state.reports,
  auth: state.auth,
  stores: state.stores,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AssociateActivity);
