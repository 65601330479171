/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useMemo } from 'react';
import { Checkbox, Image } from 'antd-v5';
import './styles.scss';

const NOT_FOUND_IMAGE = 'https://dashboard-v2-images.s3.amazonaws.com/not-found.png';

const VendorRecommendationCard = props => {
  const { vendorRecommendation, selectedVendorItems, setSelectedVendorItems } = props;

  const image = useMemo(() => vendorRecommendation.images[0] || NOT_FOUND_IMAGE, [vendorRecommendation.images]);

  const handleChangeVendorItems = useCallback(() => {
    if (selectedVendorItems.includes(vendorRecommendation.id)) {
      setSelectedVendorItems(selectedVendorItems.filter(item => item !== vendorRecommendation.id));
    } else {
      setSelectedVendorItems([...selectedVendorItems, vendorRecommendation.id]);
    }
  }, [selectedVendorItems, setSelectedVendorItems, vendorRecommendation.id]);

  return (
    <div className="cb-vendor-recommendations-card" onClick={handleChangeVendorItems}>
      <div className="cb-vendor-recommendations-card__image">
        <Image src={image} fallback={NOT_FOUND_IMAGE} preview={false} alt="vendor item" />
      </div>

      <div className="cb-vendor-recommendations-card__body">
        <span className="cb-vendor-recommendations-card__name">{vendorRecommendation.name}</span>
        <span className="cb-vendor-recommendations-card__mpn">{vendorRecommendation.mpn}</span>
      </div>

      <Checkbox
        className="cb-vendor-recommendations-card__checkbox"
        checked={selectedVendorItems.includes(vendorRecommendation.id)}
        onChange={handleChangeVendorItems}
      />
    </div>
  );
};

export default VendorRecommendationCard;
