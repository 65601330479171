import { useCallback, useEffect, useState } from 'react';
import { createProduct, getProductById, updateProduct } from '../../../api/products';

const useProduct = props => {
  const { productId, productData } = props;

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(productData || null);

  const getProduct = useCallback(async () => {
    setLoading(true);

    try {
      const response = await getProductById(productId);

      setProduct(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [productId]);

  const saveProduct = useCallback(async fields => {
    const formData = new FormData();

    formData.append('name', fields.name);
    formData.append('price', fields.price);
    formData.append('description', fields.description);

    fields.images.forEach((image, index) => {
      formData.append(`images_${index}`, image.url || image.originFileObj);
    });

    setLoading(true);

    try {
      const response = await (productId ? updateProduct(productId, formData) : createProduct(formData));

      return response.data;
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (productId && !product) {
      getProduct();
    }
  }, [productId]);

  return {
    loading,
    product,
    getProduct,
    saveProduct,
  };
};

export default useProduct;
