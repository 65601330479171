export const TRANSACTION_COLUMN_KEY = {
  DATE: 'dateTime',
  STATUS: 'status',
  BANK_ACCOUNT: 'bankAccount',
  NET_AMOUNT: 'amount',
  TOTAL_DISBURSEMENT: 'totalDisbursement',
  GROSS_AMOUNT: 'grossAmount',
};

export const TRANSACTION_STATUS_KEY = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  UNKNOWN: 'UNKNOWN',
};

export const TRANSACTION_STATUS = {
  SUCCESS: {
    text: 'Succeeded',
    color: 'blue',
  },
  FAIL: {
    text: 'Failed',
    color: 'red',
  },
  UNKNOWN: {
    text: 'Unknown',
    color: 'gray',
  },
};

export const TRANSACTION_STATUS_LABEL = {
  [TRANSACTION_STATUS_KEY.SUCCESS]: TRANSACTION_STATUS.SUCCESS,
  [TRANSACTION_STATUS_KEY.FAIL]: TRANSACTION_STATUS.FAIL,
  [TRANSACTION_STATUS_KEY.UNKNOWN]: TRANSACTION_STATUS.UNKNOWN,
};

// Our default date format for Payment info
export const DATE_FORMAT = {
  SHORT_DATE_SLASHES: 'MM/DD/YYYY',
  LONG_DATE: 'MMMM D, YYYY',
  LONG_DATE_TIME: 'MMMM D, YYYY, h:mma',
};

// For DateRange filters
export const DATE_RANGE = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  THIS_WEEK: 'Week to date',
  LAST_WEEK: 'Last Week',
  THIS_MONTH: 'Month to date',
  LAST_MONTH: 'Last Month',
  THIS_YEAR: 'This Year',
  LAST_YEAR: 'Last Year',
  ONE_YEAR: 'Last 12 Months',
};
