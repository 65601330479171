import { types } from "./getType";

export function getActivities (data, filters) {
  const dataWithActivities = data.filter(item => item?.reminder_id
    || item?.message_batch_id
    || item?.auto_message_id
    || item?.ai_activity_result_id).map(item => types(item)).filter(item => filters.length === 0 || filters.includes(parseTypes(item)))

    return dataWithActivities.reduce((acc, item) => {
      acc[item] = acc[item] + 1
      acc.total = acc.total + 1
      acc.qty = (acc.reminder > 0 ? 1 : 0) + (acc.massMessage > 0 ? 1 : 0) + (acc.autoMessage > 0 ? 1 : 0) + (acc.aiActivityResult > 0 ? 1 : 0)
      return acc
    }, {
      total: 0,
      qty: 0,
      reminder: 0,
      massMessage: 0,
      autoMessage: 0,
      aiActivityResult: 0
    })
}

const parseTypes = (type) => {
  switch (type) {
    case 'reminder':
      return 'reminders'
    case 'massMessage':
      return 'chatMessages'
    case 'autoMessage':
      return 'chatMessages'
    case 'aiActivityResult':
      return 'aiActivityResult'
    default:
      return null
  }
}
