import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Input, Select } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PAGE_SIZE_OPTIONS = [
  { value: 25, label: '25 / page' },
  { value: 50, label: '50 / page' },
  { value: 100, label: '100 / page'},
  { value: 200, label: '200 / page'}
];

const PaginationV3 = props => {
  const { page, pageSize, total, setPage, setPageSize, showLastPage = true, ...rest } = props;
  const parsedPage = useMemo(() => Number(page), [page]);
  const parsedPageSize = useMemo(() => Number(pageSize), [pageSize]);
  const parsedTotal = useMemo(() => Number(total), [total]);
  const parsedTotalPages = useMemo(() => Math.ceil(parsedTotal / parsedPageSize), [parsedTotal, parsedPageSize]);

  const [currentPage, setCurrentPage] = useState(parsedPage);
  const [currentPageSize, setCurrentPageSize] = useState(parsedPageSize);

  const showSizeChanger = useMemo(() => Math.ceil(parsedTotal / 25) > 1, [parsedTotal, parsedPageSize]);
  const showQuickJumper = useMemo(() => Math.ceil(parsedTotal / parsedPageSize) > 1, [parsedTotal, parsedPageSize]);

  useEffect(() => setCurrentPage(parsedPage), [parsedPage]);
  useEffect(() => setPage(currentPage), [currentPage]);

  useEffect(() => setCurrentPageSize(parsedPageSize), [parsedPageSize]);
  useEffect(() => setPageSize(currentPageSize), [currentPageSize]);

  const handlePreviousPage = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage])

  const handleNextPage = useCallback(() => {
    if (currentPage < parsedTotalPages) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, parsedTotalPages])

  const showShortcut = useCallback((side) => {
    if (side === 'left') {
      return currentPage > 4;
    }
    return currentPage < parsedTotalPages - 3;
  }, [parsedTotalPages, currentPage]);

  const getItems = useCallback(() => {
    const items = [];
    for (let i = 2; i <= parsedTotalPages - 1; i++) {
      items.push(i);
    }
    if (currentPage <= 4) {
      return items.slice(0, 4);
    } else if (currentPage >= parsedTotalPages - 3) {
      return items.slice(-4);
    } else {
      return items.slice(currentPage - 4, currentPage + 1);
    }
  }, [currentPage, parsedTotalPages]);

  const handlePageSizeChange = useCallback((e) => {
    if(e.keyCode === 13){
      if (e.target.value <= parsedTotalPages) {
        setCurrentPage(e.target.value);
      }
    };
  }, [parsedTotal]);

  const handleShortcutClick = useCallback((side) => {
    if(side === 'left'){
      setCurrentPage(currentPage - 5 < 1 ? 1 : currentPage - 5);
    } else {
      setCurrentPage(currentPage + 5 > parsedTotalPages ? parsedTotalPages : currentPage + 5);
    }
  }, [currentPage, parsedTotalPages]);

  return (
    <div className="cb-pagination-v3">
      <PaginationChevron
        side='left'
        nowAllowed={currentPage === 1}
        onClick={handlePreviousPage}
      />
      <PaginationItem
        value={1}
        currentPage={currentPage}
        onClick={() => setCurrentPage(1)}
      />
      {showShortcut('left') && <PaginationShortcut side="left" onClick={() => handleShortcutClick('left')} />}
      {getItems().map((value) => (
        <PaginationItem
          key={value}
          value={value}
          currentPage={currentPage}
          onClick={() => setCurrentPage(value)}
        />
      ))}
      {showShortcut('right') && <PaginationShortcut side="right" onClick={() => handleShortcutClick('right')} />}
      {showLastPage && parsedTotalPages > 5 && (
        <PaginationItem
          value={parsedTotalPages}
          currentPage={currentPage}
          onClick={() => setCurrentPage(parsedTotalPages)}
        />
      )}
      <PaginationChevron
        side='right'
        nowAllowed={currentPage === parsedTotalPages}
        onClick={handleNextPage}
      />
      <div className="cb-pagination-v3-options">
        {showSizeChanger && (
          <Select
            defaultValue={25}
            onChange={(value) => setPageSize(value)}
            options={PAGE_SIZE_OPTIONS}
          />
        )}
        {showQuickJumper && (
          <div className='cb-pagination-v3-options-quick-jumper'>
            Go to
            <Input
              onKeyDown={handlePageSizeChange}
            />
            Page
          </div>
        )}
      </div>
    </div>
  );
};

export default PaginationV3;

const PaginationChevron = ({side, nowAllowed, ...props}) => {
  return (
    <div
      className='cb-pagination-v3__chevron'
      style={{
        cursor: nowAllowed ? 'not-allowed' : 'pointer',
      }}
      {...props}
    >
      {side === 'left' && <CaretLeftOutlined />}
      {side === 'right' && <CaretRightOutlined />}
    </div>
  )
}

const PaginationItem = ({value, currentPage, ...props}) => {
  return (
    <div
      className={`cb-pagination-v3__item${value === currentPage ? ' cb-pagination-v3__item--selected' : ''}`}
      {...props}
    >
      <span
      >
        {value}
      </span>
    </div>
  )
}

const PaginationShortcut = ({side, ...props}) => {
  return (
    <div className="cb-pagination-v3__short" {...props}>
      <span className='cb-pagination-v3__short--icon'>
        <FontAwesomeIcon
          icon={['far', `chevrons-${side === 'left' ? 'left' : 'right'}`]}
        />
      </span>
      <span className='cb-pagination-v3__short--dot'>
        •••
      </span>
    </div>
  )
}
