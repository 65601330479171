/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useRef, useEffect } from 'react';
import { Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeadOuterComp from './LeadComponents/LeadOuterComp';
import InputAIButton from '../../../Layout/chat/components/InputAIButton';
import { postRequest } from '../../../../core/apiRequests';
import VendorRecommendations from './VendorRecommendations';
import formatPhoneNumber from '../../../../utils/formatPhoneNumber';

const EdisonAI = ({
  setShowLead,
  leadObj,
  setLeadObj,
  merchantId,
  setEdisonTile,
  myClient,
  aiActivityId,
  setShowEllipses,
  showEllipses,
  queryEdison,
  AIActivity,
  showClientDetails,
  setShowClientDetails,
  flags,
}) => {

  return (
    <LeadOuterComp
      setShowLead={setShowLead}
      showMyClient
      myClient={myClient}
      leadObj={leadObj}
      headerText={leadObj?.type.titleMadLib}
      headerSubTxt={leadObj?.type.madLib}
      headerDivMargin="30px 40px auto 40px"
      headerSubTxtClass="align-left"
      leadBody={
        <EdisonChat
          leadObj={leadObj}
          setLeadObj={setLeadObj}
          merchantId={merchantId}
          setEdisonTile={setEdisonTile}
          aiActivityId={aiActivityId}
          setShowEllipses={setShowEllipses}
          showEllipses={showEllipses}
          queryEdison={queryEdison}
          AIActivity={AIActivity}
          setShowLead={setShowLead}
          showClientDetails={showClientDetails}
          setShowClientDetails={setShowClientDetails}
          showDetails={flags?.smartAssistantExpandedDetails}
        />
      }
    />
  );
};

export default EdisonAI;

const EdisonChat = ({
  merchantId,
  leadObj,
  setLeadObj,
  aiActivityId,
  setEdisonTile,
  showEllipses,
  setShowEllipses,
  queryEdison,
  AIActivity,
  showClientDetails,
  setShowClientDetails,
  showDetails
}) => {
  const [inputFocus, setInputFocus] = useState(false);
  const isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0 || window.navigator.maxTouchPoints > 0;
  // const [showEllipses, setShowEllipses] = useState('noShowEllipses');
  const ref = useRef(null);
  const [msgRegen, setMsgRegen] = useState(false);
  const [msgEdit, setMsgEdit] = useState(false);
  const [selectedVendorItems, setSelectedVendorItems] = useState([]);

  const skip = () => {
    setEdisonTile('helpMeImprove');
    setShowClientDetails(false);
  };

  const textHandler = val => {
    const messageLength = leadObj.type.message.length;
    if (val !== leadObj.type.message) {
      setLeadObj({ ...leadObj, type: { ...leadObj.type, message: val } });
      if (val.length !== 0 && val.length !== messageLength) {
        setMsgEdit(true);
      }
    }
  };
  const sendMessage = async () => {
    const dataObj = {
      code: 'COMPLETE',
      message: leadObj.type.message,
    };

    const reqObj = {
      params: ['ai', aiActivityId, 'edisonActivityResult'],
      data: dataObj,
      loadingStatusOff: true,
    };

    if (selectedVendorItems.length) {
      reqObj.data.vendorItemIds = selectedVendorItems;
    }

    await postRequest(reqObj);
    setEdisonTile('loadingComplete');
    setShowClientDetails(false);
    AIActivity({ eventType: 'activity_completed', msgRegen, msgEdit, resultCode: null });
    setMsgRegen(false);
    setMsgEdit(false);
  };

  const disabledSend =
    // client.mobile_invalid === 1 ||
    leadObj.type.message?.length === 0;

  const handleKeyDown = e => {
    if (e.key === 'Enter' && e.keyCode === 13 && !e.shiftKey && !disabledSend) {
      e.preventDefault();
      sendMessage();
    }
  };

  useEffect(() => {
    if (leadObj.vendorRecommendations?.length) {
      setSelectedVendorItems(leadObj.vendorRecommendations.map(vendor => vendor.id));
    }
  }, [leadObj.vendorRecommendations]);

  return (
    <div style={{ margin: '0px 40px' }}>
      <section className="align-left" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="cb-edison-ai-client-details__grey-text no-text-wrap">Sending to</span>
          <span className="fs-14 fw-500">{`${leadObj?.client?.name || ''} ${leadObj?.client?.preferredContactType === 'EMAIL' ? leadObj?.client?.email || '' : formatPhoneNumber(leadObj?.client?.mobile) || ''}`.trim()}</span>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="cb-edison-ai-client-details__grey-text no-text-wrap">Sending from</span>
          <span className="fs-14 fw-500">{leadObj.storeName}</span>
        </div>
      </section>
      <div className="edisonAITextArea pos-rel" style={{ padding: '10px 20px 20px 20px' }}>
        <Input.TextArea
          id="textAreaInputWithActionsInbox"
          value={leadObj.type.message}
          bordered={false}
          style={{
            width: '100%',
            caretColor: 'black',
            display: 'block',
            fontFamily: 'Poppins',
            letterSpacing: 0.5,
            wordSpacing: 1,
            whiteSpace: 'pre-wrap',
            resize: 'none',
            padding: 0,
            overflowY: 'scroll',
            zIndex: 0,
            position: 'relative',
            fontSize: '13px',
          }}
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
          autoSize={{ minRows: 1, maxRows: 4 }}
          onChange={e => {
            textHandler(e.target.value);
            // auto_grow(e.target);
          }}
          onKeyDown={handleKeyDown}
          ref={ref}
          autoFocus={!isTouch}
        />
        <div
          style={{ zIndex: '10', backgroundColor: '#fff', width: '74px', top: '7px', left: '17px' }}
          className={`pos-abs align-left ${showEllipses}`}
        >
          <div className="loading" />
        </div>
        <div
          className="flex-row-spacebetween-nowrap pos-abs bottom-0"
          style={{ margin: '10px', left: '-3px', width: '430px' }}
        >
          <InputAIButton
            source="edison"
            setInputText={textHandler}
            inputValue={leadObj.type.message}
            merchantId={merchantId}
            clientId={leadObj.client.id}
            storeId={leadObj.type.storeId}
            setShowEllipses={setShowEllipses}
            queryEdison={queryEdison}
            leadObj={leadObj}
            setMsgRegen={setMsgRegen}
          />
          <div role="button" className="pointer" tabIndex={0} onClick={() => sendMessage()}>
            <FontAwesomeIcon icon="fa-solid fa-paper-plane" style={{ color: '#33aafc', marginTop: '7px' }} size="lg" />
          </div>
        </div>
      </div>

      {leadObj.vendorRecommendations?.length ? (
        <VendorRecommendations
          vendorRecommendations={leadObj.vendorRecommendations}
          selectedVendorItems={selectedVendorItems}
          setSelectedVendorItems={setSelectedVendorItems}
        />
      ) : null}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button
          type="button"
          className="transparentButton m-top-20 border-none bg-white"
          style={{ color: '#33aafc' }}
          onClick={() => skip()}
        >
          Skip
        </button>
        {showDetails && (
          <button
            type="button"
            className="transparentButton m-top-20 border-none bg-white"
            style={{ color: '#33aafc' }}
            onClick={() => setShowClientDetails(!showClientDetails)}
          >
            {showClientDetails ? 'Hide Details' : 'Show Details'}
          </button>
        )}
      </div>
    </div>
  );
};
