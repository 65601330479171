import React, { useState, useRef, useEffect } from 'react';
import { parse } from 'papaparse';
import { SketchPicker } from 'react-color';
import '../webchat.css';
import { Col, Row, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KioskSlider from '../../../../Layout/KioskSlider';
import Input from '../../../../CustomizedAntDesign/Input/Input';
import CheckboxDesc from '../../../../Layout/CheckboxDesc';
import { notificationError } from '../../../../../core/actions';
import PopConfirmLarge from '../../../../Layout/PopConfirm/PopConfirmLarge';
import { postRequest, getRequest } from '../../../../../core/apiRequests';

const WebchatCustomize = ({ obj, setObj, auth }) => {
  const [colorOpen, setColorOpen] = useState(false);
  const [defaultImg, setDefaultImg] = useState('def1');
  const [disableImg, setDisableImg] = useState(false);
  const [previewState, setPreviewState] = useState('first');
  const [uploadPopup, setUploadPopup] = useState(false);
  const [uploadedImg, setImage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [poweredFlag, setPoweredFlag] = useState();

  useEffect(() => {
    (async () => {
      const reqObj = {
        params: ['merchant', auth.merchantId, 'webchatConfig'],
      };
      const data = await getRequest(reqObj);
      setPoweredFlag(data.feature_value);
    })();
  }, []);

  const handleChangeComplete = color => {
    if (color.hex == '#ffffff' || color.hex == '#fff') {
      notificationError('Cannot set button color to white');
    } else {
      setObj({ ...obj, background: color.hex });
    }
  };

  const LeftRightScreen = () => {
    if (obj.screenLocation == '') {
      setObj({ ...obj, screenLocation: 'left' });
    } else {
      setObj({ ...obj, screenLocation: '' });
    }
  };

  // handle file upload
  const handleFileUpload = e => {
    // here
    const file = e.target.files[0];
    if (file.size < 1600000) {
      const imagePath = URL.createObjectURL(file);
      setImage(file);
      setImageUrl(imagePath);
    } else {
      console.log('Image file size exceeds 1.5mb');
    }
  };

  const imageToolTipText = (
    <div>
      <span>What is a web hosted URL?</span>
      <p>A web hosted URL is a web address that specifies the single web location of an image.</p>
      <div className="add-edit-wrap">
        <img
          src="https://dashboard-v2-images.s3.amazonaws.com/errorImage.png"
          width="50"
          height="50"
          style={{ margin: '20px 10px' }}
        />
        <p>If you upload a PDF, JPG, etc. this default error symbol will appear instead of your image.</p>
      </div>
      <p>
        If you are having issues with your web chat, reach out to our Customer Success Team at (385) 352-3518 or{' '}
        <span style={{ color: '#33AAFC' }}>
          <a id="SupportAtClientbook" href="mailto:support@clientbook.com">
            support@clientbook.com
          </a>
        </span>
      </p>
    </div>
  );

  return (
    <div className="maw-1200 add-edit-wrap">
      <div className="w-500 ">
        <span className="fs-12 gray d-block m-btm-15 align-left">
          {' '}
          Clientbook’s web chat has six customizable components:
        </span>
        <CustomizeTitleAndText title="Color" text={['What color do you want your web chat to be?']} />
        <div className="pos-rel" onClick={() => setPreviewState('first')}>
          <div
            id="ColorBox"
            className="colorSelect pos-rel"
            onClick={() => setColorOpen(!colorOpen)}
            style={{ backgroundColor: obj.background }}
          />
          {colorOpen ? (
            <div className="pos-abs m-top-7 z-index-998">
              <SketchPicker color={obj.background} onChangeComplete={handleChangeComplete} />
            </div>
          ) : null}
        </div>
        <CustomizeTitleAndText title="Opening text" text={['Add an opening statement for your web chat']} />
        <div className="pos-rel align-left zIndexForWebchat" onClick={() => setPreviewState('first')}>
          <p className="align-left gray fw-500 fs-11 m-top-18 zIndexForWebchat">
            Would you like to turn opening statements off?
          </p>
          <div id="OpeningStatementKiosk">
            <KioskSlider
              value={obj.openingStatementOff}
              onChange={() => {
                if (obj.openingStatementOff === 'true') {
                  setObj({ ...obj, openingStatementOff: '' });
                } else {
                  setObj({ ...obj, openingStatementOff: 'true' });
                }
              }}
            />
          </div>
          <p className="align-left  gray fw-500 fs-11 m-top-18 m-btm-0 zIndexForWebchat">Write an opening statement</p>
          <p className="align-left gray fw-500 fs-11 m-top-0 zIndexForWebchat">Ex. “Have a question? Ask here!”</p>
          <Row>
            <Col span={16}>
              <Input
                id="openingStatementInput"
                disabled={obj.openingStatementOff}
                onChange={e => setObj({ ...obj, openingStatementText: e.target.value })}
                className="inputHeight common_inputStyle w-333"
                placeholder="Type your text here"
              />
            </Col>
          </Row>
        </div>
        <CustomizeTitleAndText title="Image" text={['Add a personalized image to appear on your opening statement']} />
        <div className="pos-rel align-left" onClick={() => setPreviewState('first')}>
          <p className="align-left align-left gray fw-500 fs-11 m-top-18 ">Select one of our default images</p>
          <div className="pos-rel flex-row-spacebetween-wrap" style={{ width: '190px' }}>
            <div className="defaultImageDiv" style={defaultImg == 'def1' ? { backgroundColor: '#33AAFC' } : {}}>
              <img
                onClick={() => {
                  setDefaultImg('def1');
                  setDisableImg(true);
                  setObj({
                    ...obj,
                    defaultImage: 'https://s3.amazonaws.com/images.clbk.app/Webchat/default_wbcht_one.jpg',
                    image: '',
                  });
                }}
                id="def1"
                className="defaultImage"
                src="https://s3.amazonaws.com/images.clbk.app/Webchat/default_wbcht_one.jpg"
              />
            </div>
            <div className="defaultImageDiv" style={defaultImg == 'def2' ? { backgroundColor: '#33AAFC' } : {}}>
              <img
                onClick={() => {
                  setDefaultImg('def2');
                  setDisableImg(true);
                  setObj({
                    ...obj,
                    defaultImage: 'https://s3.amazonaws.com/images.clbk.app/Webchat/default_wbcht_two.jpg',
                    image: '',
                  });
                }}
                id="def2"
                className="defaultImage"
                src="https://s3.amazonaws.com/images.clbk.app/Webchat/default_wbcht_two.jpg"
              />
            </div>
            <div className="defaultImageDiv" style={defaultImg == 'def3' ? { backgroundColor: '#33AAFC' } : {}}>
              <img
                onClick={() => {
                  setDefaultImg('def3');
                  setDisableImg(true);
                  setObj({
                    ...obj,
                    defaultImage: 'https://s3.amazonaws.com/images.clbk.app/Webchat/default_wbcht_three.jpg',
                    image: '',
                  });
                }}
                id="def3"
                className="defaultImage"
                src="https://s3.amazonaws.com/images.clbk.app/Webchat/default_wbcht_three.jpg"
              />
            </div>
          </div>
          <div className="add-edit-wrap">
            <p className="align-left align-left gray fw-500 fs-11 m-top-18 m-btm-6">
              Or add a personalized web hosted URL
            </p>
            <Tooltip
              placement="bottomLeft"
              title={imageToolTipText}
              overlayInnerStyle={{
                width: '352px',
                borderRadius: '10px',
                padding: '19px 17px',
                backgroundColor: '#000',
              }}
            >
              <InfoCircleOutlined className="m-top-18 m-left-10" />
            </Tooltip>
          </div>
          <Row>
            <Col
              span={16}
              onClick={() => {
                setDefaultImg('');
                setObj({ ...obj, defaultImage: '' });
                setDisableImg(false);
              }}
            >
              <Input
                id="ImageUrlInput"
                defaultValue="URL"
                disabled={disableImg}
                onChange={e => setObj({ ...obj, image: e.target.value })}
                value={obj.image}
                className="inputHeight common_inputStyle w-333"
                placeholder="URL"
              />
            </Col>
          </Row>
          {/* hosted images */}
          <div className="add-edit-wrap">
            <p className="align-left align-left gray fw-500 fs-11 m-top-18 m-btm-6">Or upload an image</p>
          </div>
          <Row>
            <Col
              span={16}
              onClick={() => {
                setDefaultImg('');
                setObj({ ...obj, defaultImage: '' });
                setDisableImg(false);
              }}
            >
              <button
                key="button"
                id="webchatUploadButton"
                onClick={() => setUploadPopup(true)}
                style={{ width: '207px' }}
                className="standardButton darkBlueButton"
                disabled={disableImg}
              >
                Upload
              </button>
            </Col>
          </Row>
        </div>
        <CustomizeTitleAndText title="Single Location" text={['Does your business operate in a single location?']} />
        <div className="pos-rel align-left" onClick={() => setPreviewState('second')} id="singleStoreKiosk">
          <KioskSlider
            value={obj.singleStore}
            onChange={() => {
              if (obj.singleStore === true) {
                setObj({ ...obj, singleStore: '' });
              } else {
                setObj({ ...obj, singleStore: true });
              }
            }}
          />
        </div>
        <CustomizeTitleAndText
          title="Screen Location"
          text={['Would you like your web chat to be located to the left of the screen, or the right?']}
        />
        <div className="pos-rel align-left" onClick={() => setPreviewState('second')} id="CheckBoxDiv">
          <CheckboxDesc
            checkBoxId="Right"
            checked={!obj.screenLocation}
            onclick={LeftRightScreen}
            text="Right"
            classProps="fs-13"
            checkButtonProps="m-btm-15 w-85"
          />
          <CheckboxDesc
            checkBoxId="Left"
            checked={obj.screenLocation}
            onclick={LeftRightScreen}
            text="Left"
            classProps="fs-13"
            checkButtonProps="m-btm-15 w-85"
          />
        </div>
        {poweredFlag === 'custom' && (
          <>
            <CustomizeTitleAndText
              title="Hide Powered by Clientbook"
              text={['Would you like to hide the "Powered by Clientbook" on the bottom of your webchat?']}
            />
            <div className="pos-rel align-left" onClick={() => setPreviewState('second')} id="CheckBoxDiv">
              <KioskSlider
                value={obj.poweredBy}
                onChange={() => {
                  if (obj.poweredBy === true) {
                    setObj({ ...obj, poweredBy: '' });
                  } else {
                    setObj({ ...obj, poweredBy: true });
                  }
                }}
              />
            </div>
          </>
        )}
      </div>
      {/* preview */}
      {previewState == 'first' ? <OpeningPreview obj={obj} /> : <StorePreview obj={obj} />}
      {uploadPopup ? (
        <PopConfirmLarge
          description={
            <div className="dashboard_msg_img_popup" style={{ width: '500px', height: '450px' }}>
              <DropZoneWeb
                setUploadPopup={setUploadPopup}
                imageUrl={imageUrl}
                setImage={setImage}
                setImageUrl={setImageUrl}
                handleFileUpload={handleFileUpload}
                uploadedImg={uploadedImg}
                auth={auth}
                obj={obj}
                setObj={setObj}
              />
            </div>
          }
        />
      ) : null}
    </div>
  );
};

export default WebchatCustomize;

const CustomizeTitleAndText = props => {
  const textFormat = texts => {
    if (texts.length > 1) {
      const returnArr = [];
      texts.forEach(text => {
        returnArr.push(<p className="align-left align-left gray fw-500 fs-11 m-top-0 ">{text}</p>);
      });
      return returnArr;
    }
    return <p className="align-left align-left gray fw-500 fs-11 m-top-0 ">{texts}</p>;
  };
  return (
    <div className="maw-1200 zIndexForWebchat">
      <p className="align-left fs-13 black fw-500 m-btm-0"> {props.title} </p>
      {textFormat(props.text)}
    </div>
  );
};

const OpeningPreview = props => {
  const openTextFiller = 'Your opening text will appear here';
  return (
    <div className="previewDiv pos-rel">
      <div>
        <div id="clbkwc_chat_id" style={props.obj.openingStatementOff ? { display: 'none' } : {}}>
          <img id="wlbkwc_chatExit" src="https://webchat.clientbook.com/images/X.svg" />

          <img
            id="wlbkwc_avatar_img"
            src={props.obj.defaultImage || props.obj.image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = 'https://dashboard-v2-images.s3.amazonaws.com/errorImage.png';
            }}
          />
          <p id="wlbkwc_chat">{props.obj.openingStatementText || openTextFiller}</p>
          <img id="wlbkwc_chat_carrot" src="https://webchat.clientbook.com/images/carrot-intro-white.svg" />
        </div>
        <div id="wlbkwc_messageButton" style={{ top: '275px' }}>
          <button className="clbkwc_btn_class" style={{ backgroundColor: props.obj.background }} />
        </div>
      </div>
    </div>
  );
};

const StorePreview = props => {
  return (
    <div className="previewDiv2 pos-rel">
      <div id="widget-outer">
        <div>
          <div style={props.obj.singleStore ? { display: 'none' } : {}}>
            <div className="storeSelectDiv">
              <span className="storeSelectText">York Jewelry - Salt Lake City</span>
              <img src="https://dashboard-v2-images.s3.amazonaws.com/whiteArrow.svg" className="whiteArrow" />
              <p className="selectLocationSubText">160 N Main St. Salt Lake City, UT</p>
            </div>
          </div>
          <div>
            <div className="messageUpper">
              <div className="widg-head">
                <p className="head-chat" style={{ backgroundColor: props.obj.background }}>
                  {' '}
                  👋 Hey! Got a question or want to chat?
                </p>
              </div>
              <div className="message">
                <div className="avatar">
                  <img
                    className="chatImg"
                    alt="Avatar"
                    height="40"
                    width="40"
                    src={props.obj.defaultImage || props.obj.image}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = 'https://dashboard-v2-images.s3.amazonaws.com/errorImage.png';
                    }}
                  />
                </div>
                <div className="widg-head-2" id="chatBox">
                  <p className="head-chat" id="head-chat-img" style={{ backgroundColor: props.obj.background }}>
                    {' '}
                    Enter your info and an associate from our store will text you back!{' '}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="23"
                    viewBox="0 0 33 23"
                    className="carrotStyle"
                  >
                    <path
                      className="carrot"
                      fillRule="evenodd"
                      d="M10 .587V10c0 5.467-3.251 9.8-9.753 13h32.32L10 .587z"
                      style={{ fill: props.obj.background }}
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="row2">
            <div className="form_all">
              <form className="form" method="post">
                <input
                  id="form_input_top"
                  className="mandatoryfields"
                  type="text"
                  placeholder="Name"
                  name="name"
                  maxLength="20"
                />
                <input type="tel" id="form_input" className="input-phone" placeholder="Phone Number" />
                <input
                  id="form_input_bot"
                  className="mandatoryfields"
                  type="text"
                  placeholder="Message"
                  name="message"
                  maxLength="250"
                />
              </form>
              <img
                className="greyCarrot"
                src="https://s3.amazonaws.com/webchatv2.clientbook.com/assets/carrot-message-gray.svg"
                alt="grey form carrot"
                width="0"
                height="0"
              />
              <p className="agree_text">
                {' '}
                By submitting you agree to receive text messages at the number provided. Msg&amp;Data rates may apply.{' '}
              </p>
              <input
                id="submit_butID"
                className="submit_but button"
                type="submit"
                value="Submit"
                style={{ backgroundColor: 'black' }}
              />
            </div>
          </div>
        </div>
        {!props.obj.poweredBy ? (
          <div className="footerContainer">
            <div className="widg-foot">
              <div className="widg-foot-logo-div">
                <img
                  className="footerImg"
                  src="https://dashboard-v2-images.s3.amazonaws.com/ic-logo-menu.svg"
                  alt="clientbook logo"
                  width="10"
                  height="10"
                />
              </div>
              <p className="powered_by"> Powered by Clientbook </p>
            </div>
          </div>
        ) : (
          <span className="m-top-10" />
        )}
      </div>
      <div
        id="wlbkwc_messageButton"
        style={props.obj.screenLocation == 'left' ? { left: '35px', bottom: '10px' } : { bottom: '10px' }}
      >
        <button className="clbkwc_btn_class" style={{ backgroundColor: props.obj.background }} />
      </div>
    </div>
  );
};

const DropZoneWeb = props => {
  const dragOver = e => {
    e.preventDefault();
  };

  const dragEnter = e => {
    e.preventDefault();
  };

  const dragLeave = e => {
    e.preventDefault();
  };

  const fileDrop = e => {
    e.preventDefault();
    Array.from(e.dataTransfer.files)
      .filter(file => file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')
      .forEach(async file => {
        if (file.size < 1600000) {
          const imagePath = URL.createObjectURL(file);
          props.setImage(file);
          props.setImageUrl(imagePath);
        } else {
          console.log('Image file size exceeds 1.5mb');
        }
      });
  };

  const saveS3Image = () => {
    const cleanImg =
      props.obj.image === 'null' || props.obj.image === '' || props.obj.image == null ? null : props.obj.image;
    const merchantNameClean = props.auth.brandName.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    const merchantUUID = props.auth.uuid;

    const reqObj = {
      params: ['merchant', props.auth.merchantId, 'webchatConfig'],
      images: { files: [props.uploadedImg] },
      data: {
        image: cleanImg,
        merchantUUID,
      },
    };
    (async () => {
      const saveData = await postRequest(reqObj);
      if (saveData) {
        props.setObj({ ...props.obj, image: saveData });
        props.setUploadPopup(false);
      }
    })();
  };

  return (
    <div className="container">
      {props.imageUrl ? (
        <div>
          <img src={props.imageUrl} alt="your image" style={{ width: '300px', height: '300px' }} />
          <div className="mq-w-100-vw custom-action-save-flex m-btm-10 m-top-20">
            <button className="save-button-v2 m-btm-8" onClick={() => saveS3Image()}>
              Upload
            </button>
            <button
              className="deleteButton-v2"
              onClick={() => {
                props.setImage(null);
                props.setImageUrl(null);
              }}
            >
              Clear
            </button>
            <button
              className="cancel-button-v2"
              onClick={() => {
                props.setUploadPopup(false);
                props.setImage(null);
                props.setImageUrl(null);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="drop-container"
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
          >
            <div className="flex-column">
              <FontAwesomeIcon icon="fa-regular fa-folder-arrow-up" size="2x" />
              <span className="drag-files-span">Drag files here</span>
              <span className="or-span">or</span>
              <ChooseFileButtonWebchat handleFileUpload={props.handleFileUpload} />
            </div>
          </div>
          <div className="mq-w-100-vw custom-action-save-flex m-btm-10 m-top-20">
            <button
              className="cancel-button-v2"
              onClick={() => {
                props.setUploadPopup(false);
                props.setImage(null);
                props.setImageUrl(null);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const ChooseFileButtonWebchat = props => {
  const fileInput = useRef(null);

  return (
    <div className="choose-file-div">
      <input
        type="file"
        accept="image/*"
        onChange={props.handleFileUpload}
        ref={fileInput}
        style={{ display: 'none' }}
      />
      <button onClick={() => fileInput.current.click()} className="choose-file-btn">
        <FontAwesomeIcon icon="fa-regular fa-upload" size="1x" />
        Choose file
      </button>
    </div>
  );
};
