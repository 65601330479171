import moment from 'moment';
import { getRequest } from '../../../core/apiRequests';
import { setClientPaymentStatus } from '../../../reducers/messaging';

const timeOffset = moment(new Date()).utcOffset();

const getClientPaymentStatus = async ({ client, auth }) => {
  if (Number(auth.payments) > 0) {
    const reqObj = {
      params: ['chatMessages', auth.merchantId, 'paymentsStatus'],
      query: { clientId: client.client_id, selectedStoreId: auth.selectedStore },
    };
    const dataArr = await getRequest(reqObj);
    const tempStatusArr = [];
    if (dataArr.length > 0) {
      const splitName = client?.name?.split(' ');
      tempStatusArr.push(...dataArr[0].paymentViewed, ...dataArr[0].paymentStatus);
      const oldDateToNewDate = tempStatusArr.sort((a, b) => b.datetime - a.datetime);
      const updatedPayments = [];
      oldDateToNewDate.forEach(payment => {
        let fakePayment = {};
        if (
          payment.status === 'PAID_MANUALLY' ||
          payment.status === 'PAID_BY_CC' ||
          payment.status === 'PAID_WITH_APPLE_PAY' ||
          payment.status === 'PAID_WITH_GOOGLE_PAY'
        ) {
          fakePayment = {
            key: payment.short_url + payment.datetime,
            status: 'PAID',
            paymentStatus: `${splitName?.[0]} paid $${payment.total_amount}\n`,
            shortUrl: payment.short_url,
            sentFormat: moment(payment.datetime, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('[at] h:mm A'),
            timeOffsetAdd: moment(payment.datetime, 'YYYYMMDDHHmmss')
              .add(timeOffset, 'minutes')
              .format('YYYYMMDDHHmmss'),
            sent_timestamp: moment(payment.datetime, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('YYYYMMDD'),
            type: 'payment',
          };
          updatedPayments.push({ ...fakePayment });
        } else if (payment.status === 'VIEWED') {
          fakePayment = {
            key: Math.random(),
            status: 'VIEWED',
            paymentStatus: 'Payment request viewed \n',
            sentFormat: moment(payment.datetime, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('[at] h:mm A'),
            timeOffsetAdd: moment(payment.datetime, 'YYYYMMDDHHmmss')
              .add(timeOffset, 'minutes')
              .format('YYYYMMDDHHmmss'),
            sent_timestamp: moment(payment.datetime, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('YYYYMMDD'),
            type: 'payment',
          };
          updatedPayments.push({ ...fakePayment });
        } else if (payment.status === 'CANCELED') {
          fakePayment = {
            key: payment.short_url,
            status: 'CANCELLED',
            paymentStatus: 'Payment request canceled \n',
            shortUrl: payment.short_url,
            sentFormat: moment(payment.datetime, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('[at] h:mm A'),
            timeOffsetAdd: moment(payment.datetime, 'YYYYMMDDHHmmss')
              .add(timeOffset, 'minutes')
              .format('YYYYMMDDHHmmss'),
            sent_timestamp: moment(payment.datetime, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('YYYYMMDD'),
            type: 'payment',
          };
          updatedPayments.push({ ...fakePayment });
        } else if (payment.status === 'REFUNDED') {
          fakePayment = {
            key: payment.short_url,
            status: 'REFUNDED',
            paymentStatus: 'Payment request refunded ',
            shortUrl: payment.short_url,
            sentFormat: moment(payment.datetime, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('[at] h:mm A'),
            timeOffsetAdd: moment(payment.datetime, 'YYYYMMDDHHmmss')
              .add(timeOffset, 'minutes')
              .format('YYYYMMDDHHmmss'),
            sent_timestamp: moment(payment.datetime, 'YYYYMMDDHHmmss').add(timeOffset, 'minutes').format('YYYYMMDD'),
            type: 'payment',
          };
          updatedPayments.push({ ...fakePayment });
        }
      });
      setClientPaymentStatus(updatedPayments);
    } else {
      setClientPaymentStatus([]);
    }
  }
};

export default getClientPaymentStatus;
