import { dispatcher } from '../core/actions';

const initialState = {
  addOrEdit: 'Add',
  eventId: '',
  eventName: '',
  createdBy: '',
  systemId: null,
  eventEnabled: true,
};
export default function reducer(state = initialState, action) {
  let state2 = state;
  switch (action.type) {
    case 'SET_LIFE_EVENT_ADD_EDIT':
      return { ...state2, addOrEdit: action.payload };
    case 'SET_LIFE_EVENT_ID':
      return { ...state2, eventId: action.payload };
    case 'SET_LIFE_EVENT_NAME':
      return { ...state2, eventName: action.payload };
    case 'SET_LIFE_EVENT_CREATED_BY':
      return { ...state2, createdBy: action.payload };
    case 'SET_LIFE_EVENT_SYSTEM_ID':
      return { ...state2, systemId: action.payload };
    case 'SET_LIFE_EVENT_ENABLED':
      return { ...state2, eventEnabled: action.payload };
    default:
      break;
  }
  return state2;
}

export const setLifeEvId = val => {
  dispatcher('SET_LIFE_EVENT_ID', val);
};

export const setLifeEvAddEdit = val => {
  dispatcher('SET_LIFE_EVENT_ADD_EDIT', val);
};

export const setLifeEvName = val => {
  dispatcher('SET_LIFE_EVENT_NAME', val);
};
export const setLifeCreatedBy = val => {
  dispatcher('SET_LIFE_EVENT_CREATED_BY', val);
}
export const setLifeSystemId = val => {
  dispatcher('SET_LIFE_EVENT_SYSTEM_ID', val);
};
export const setLifeEvEnabled = val => {
  dispatcher('SET_LIFE_EVENT_ENABLED', val);
};
