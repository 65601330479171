import { dispatcher } from '../core/actions';

const initialState = {
  addOrEdit: 'Add',
  relationshipId: '',
  relationshipUUId: '',
  relationshipName: '',
};
export default function reducer(state = initialState, action) {
  let state2 = state;
  switch (action.type) {
    case 'SET_RELATIONSHIP_ADD_EDIT':
      return { ...state2, addOrEdit: action.payload };
    case 'SET_RELATIONSHIP_ID':
      return { ...state2, relationshipId: action.payload };
    case 'SET_RELATIONSHIP_UUID':
      return { ...state2, relationshipUUId: action.payload };
    case 'SET_RELATIONSHIP_NAME':
      return { ...state2, relationshipName: action.payload };
    default:
      break;
  }
  return state2;
}

export const setRelationshipAddEdit = val => {
  dispatcher('SET_RELATIONSHIP_ADD_EDIT', val);
};

export const setRelationshipId = val => {
  dispatcher('SET_RELATIONSHIP_ID', val);
};

export const setRelationshipUUId = val => {
  dispatcher('SET_RELATIONSHIP_UUID', val);
};

export const setRelationshipName = val => {
  dispatcher('SET_RELATIONSHIP_NAME', val);
};
