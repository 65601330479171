import React from 'react';
import './styles.css';

const NewPopConfirm = props => {
  const { title, description, className, children } = props;

  return (
    <div className={`new-pop-confirm ${className || ''}`}>
      <div className="new-pop-confirm__card">
        {title && <h2 className="new-pop-confirm__title">{title}</h2>}

        {children || (description && <p className="new-pop-confirm__description">{description}</p>)}
      </div>
    </div>
  );
};

export default NewPopConfirm;
