import React from 'react';

import { FIELD_STYLES } from '../WizardMulti';

const Pair = ({ children, ...props }) => (
  <div className="flex-row-spacebetween-wrap" style={FIELD_STYLES}>
    {React.Children.map(children, child =>
      React.cloneElement(child, {
        ...child.props,
        wrapStyle: { ...child.props.wrapStyle, width: '48%' },
      }),
    )}
  </div>
);

export default Pair;
