import React from 'react';
import '../popconfirm.css';

const PopConfirmLarge = props => {
  return (
    <div className="pop-confirm-background" onClick={props.largeOnClick}>
      <div
        className="pop-confirm-container-large p-top-36 p-left-44 p-right-44 rem-pad-width p-btm-6 flex-col-center"
        style={props.style}
      >
        <span className="fs-22 fw-500 m-top-37 m-right-15 fs-13">{props.confirm}</span>
        <span className="fs-13 d-inline-block m-top-37">{props.description}</span>
      </div>
    </div>
  );
};
export default PopConfirmLarge;
