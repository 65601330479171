/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Skeleton } from 'antd';
import moment from 'moment';
import { getRequest } from '../../../../core/apiRequests';
import StyledLink from '../../StyledLink';
import { sourceDateCalc } from '../../../Routes/Today/children/logicFunctions';

const AutoReminders = props => {
  const [loading, setLoading] = useState(true);
  const [autoReminders, setAutoReminders] = useState([]);
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();
  today = yyyy + mm + dd;
  const clientId = props.messaging.client.client_id;

  useEffect(() => {
    if (props.merchantId) {
      (async () => {
        const reqObj = {
          params: ['chats', props.merchantId, clientId, 'get2AutoReminders'],
          loadingStatusOff: true,
        };
        const data = await getRequest(reqObj);
        if (data.length > 0) {
          setAutoReminders(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    }
  }, [props.merchantId]);

  const dueWhen = due => {
    if (due === today) {
      return 'Due Today';
    }
    const newDateObj = moment(due, 'YYYYMMDD').format('MMMM DD, YYYY');
    return newDateObj;
  };

  const reminderType = autoRem => {
    const {
      life_event_type,
      sales_opp_name,
      tag_name,
      tag_action_config_id,
      custom_event_type_id,
      custom_event_type_name,
      sales_opp_id,
      life_event_id,
    } = autoRem;
    if (life_event_id) {
      return life_event_type;
    }
    if (tag_action_config_id) {
      return tag_name;
    }
    if (sales_opp_id) {
      return sales_opp_name;
    }
    if (custom_event_type_id) {
      return custom_event_type_name;
    }
  };

  const msgTrim = msg => {
    if (msg.length > 30) {
      return `${msg.substr(0, 30)}...`;
    }
    return msg;
  };

  const AutoReminderData = () => {
    const returnArr = [];
    if (loading) {
      returnArr.push(
        <div key="skeleton">
          <Skeleton
            paragraph={{
              rows: 3,
            }}
            active
          />
        </div>,
      );
      return returnArr;
    }
    if (autoReminders.length > 0) {
      autoReminders.forEach(autoRem => {
        returnArr.push(
          <div className="upcomingAutoReminder m-btm-12" key={autoRem.id}>
            <div
              className="upcomingReminderStrip"
              style={{
                backgroundColor:
                  // eslint-disable-next-line no-nested-ternary
                  String(autoRem.due_date) === today
                    ? '#FC8333'
                    : String(autoRem.due_date) > today
                    ? '#63C866'
                    : '#FC4721',
              }}
            />
            <div className="fs-12 fw-500 upcomingAutoMessageCardPadding align-left">
              <p className="m-btm-0 m-top-0">{msgTrim(autoRem.description)}</p>
            </div>
            <div className="fs-12 fw-400 gray flex-col-left m-left-10">
              <span>{dueWhen(autoRem.due_date)}</span>
              {autoRem.life_event_id ||
              autoRem.tag_action_config_id ||
              autoRem.sales_opp_id ||
              autoRem.custom_event_type_id ? (
                <span className='align-left'>
                  {`${reminderType(autoRem)} `} on {` ${sourceDateCalc(autoRem)}`}
                </span>
              ) : null}
              <div>
                {autoRem.assigned_to ? (
                  <div>
                    {' '}
                    <span>Assigned to </span>
                    <span
                      style={autoRem.assigned_to && autoRem.assigned_to === props.userId ? { fontStyle: 'italic' } : {}}
                    >
                      {autoRem.associateName}
                    </span>
                  </div>
                ) : (
                  <span>Not Assigned</span>
                )}
              </div>
            </div>
          </div>,
        );
      });
      return returnArr;
    }
  };

  return (
    <div className="pos-rel" id="upcoming-reminders">
      {AutoReminderData() ? (
        <div style={{ borderTop: '1px #ebebeb solid' }}>
          <p className="fw-500 gray fs-15 align-left m-btm-5">Upcoming Reminders</p>
          {AutoReminderData()}
          <StyledLink
            className="black fw-500 fs-12 m-right-20 m-top-3"
            id="upcoming-reminders-link"
            to={`/Clients?client=${clientId}`}
          >
            View All In Client Profile
          </StyledLink>
        </div>
      ) : (
        <div style={{ borderTop: '1px #ebebeb solid' }}>
          <p className="fw-500 gray fs-15 align-left m-btm-5">Upcoming Reminders</p>
          <span className="gray fs-12" id="no-reminders">
            No Reminders
          </span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  messaging: state.messaging,
});
export default connect(mapStateToProps)(AutoReminders);
