import React from 'react';

const MessageNotificationMessage = props => {
  const { message } = props;

  return (
    <div className="fs-13 fw-500" style={{ marginLeft: '-10px' }}>
      {message}
    </div>
  );
};

export default MessageNotificationMessage;
