import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton, Space, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import CheckboxDesc from '../../../Layout/CheckboxDesc';
import ClientListItem from './ClientListItem';
import { notificationError } from '../../../../core/actions';
import BulkActions from './BulkActions';
import MultiClientTagManagerModalV2 from '../helpers/MultiClientTagManagerModalV2';
import MultiAssignedAssociateModal from '../helpers/MultiAssignedAssociateModal';

const ListOfClients = props => {
  const [openTagManager, setOpenTagManager] = useState(false);
  const [openAssignAsctManager, setOpenAssignAsctManager] = useState(false);


  const [clientAll, setClientAll] = useState(false);

  const addAll = () => {
    const all = props.tableData.filter(item => !item.disabled).map(item => item?.id);
    if(all.length > 0) {
      if (!props.allTags && props.selected_client_list.length < all.length) {
        props.setAllTags(true);
        setClientAll(true);
        // setSelectAllDisplay(true);
        props.setSelectedClientList(all);
      } else {
        props.setAllTags(false);
        setClientAll(false);
        props.setSelectedClientList([]);
      }
    } else {
      notificationError('No clients to add');
    }
  };

  const incrementPage = () => {
    const newPage = props.page + 1;
    props.setPage(newPage);
  };

  useEffect(() => {
    if(clientAll){
      const all = props.tableData.filter(item => !item.disabled).map(item => item?.id);
      if (all.length > 0) {
        props.setSelectedClientList(all);
      } else {
        notificationError('No clients to add');
      }
    }
  }, [props.tableData.length])

  const checkClintAllValue = (value) => {
    if(!value) {
      setClientAll(false);
    } else {
      setClientAll(props.tableData.length === props.selected_client_list.length)
    }
  }


  return (
    <div>
      <div className="add-edit-wrap clientBulkActionAndAll">
        <CheckboxDesc checked={clientAll} onclick={addAll} classProps="fs-13" checkButtonProps="m-btm-15 w-85" />
        {props.selected_client_list.length > 0 && (
          <BulkActions
            auth={props.auth}
            setOpenTagManager={setOpenTagManager}
            setOpenAssignAsctManager={setOpenAssignAsctManager}
          />
        )}
      </div>
      <div
        id="scrollableDivChat"
        className={props.paymentBanner ? 'scrollableDivChatWithBanner' : 'scrollableDivChatNoBanner'}
        style={{
          overflow: 'auto',
        }}
      >
        {props.tableData.length === 0 ? (
          <div className="color-bdbdbd fs-12 fw-400 m-top-25">
            <span>{`No clients found ${props.search.length > 0 ? `for "${props.search}"` : ''}`}</span>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={props.tableData.length}
            next={incrementPage}
            hasMore={props.isThereMoreClient}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            endMessage={<h4 style={{ margin: '12px 16px', paddingBottom: '40px' }}>No more clients</h4>}
            scrollableTarget="scrollableDivChat"
            pullDownToRefreshThreshold={50}
          >
            <ul className="ul-no-style" style={{ marginBottom: '40px' }}>
              {props.tableData.map(function (msg, idx) {
                return (
                  <DisabledTooltip
                    key={idx}
                    disabled={msg.disabled}
                    disabledText={
                      <div className="flex-row-nospacebetween-nowrap" style={{ width: '352px', padding: '20px' }}>
                        <Space size="large">
                          <FontAwesomeIcon icon="fa-regular fa-lock" size="4x" />
                          <span className="fs-12">
                            You do not have access to view this client. If you need access, please have your manager
                            assign you to this client.
                          </span>
                        </Space>
                      </div>
                    }
                  >
                    <ClientListItem
                      key={`client_${msg.id}`}
                      getClientData={props.getClientData}
                      setCheckedClient={props.setCheckedClient}
                      checkedClient={props.checkedClient}
                      setAllClientsChecked={props.setAllClientsChecked}
                      msg={msg}
                      idx={idx}
                      clientAscts={props.clientAscts}
                      selected_client_list={props.selected_client_list}
                      listAdder={props.listAdder}
                      checkClintAllValue={checkClintAllValue}
                    />
                  </DisabledTooltip>
                );
              })}
            </ul>
          </InfiniteScroll>
        )}
      </div>
      {openTagManager && (
        <MultiClientTagManagerModalV2
          showTagManagerHandler={setOpenTagManager}
          client={props.client}
          pagination={props.pagination}
          auth={props.auth}
          getClientData={props.getClientData}
        />
      )}
      {openAssignAsctManager && (
        <MultiAssignedAssociateModal
          showAssociateManagerHandler={setOpenAssignAsctManager}
          client={props.client}
          pagination={props.pagination}
          auth={props.auth}
          setSelectedClientList={props.setSelectedClientList}
          getClients={props.getClients}
          clientAscts={props.clientAscts}
        />
      )}
    </div>
  );
};

export default ListOfClients;

const DisabledTooltip = ({ children, disabled, disabledText }) => {
  return !disabled ? (
    children
  ) : (
    <Tooltip title={disabledText} color="#454545" placement="bottomLeft" overlayInnerStyle={{ borderRadius: '10px' }}>
      {children}
    </Tooltip>
  );
};
