import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useActionTextAreaFiles, {
  actionTextAreaFileActions,
} from '../../components/Layout/TextAreaWithActions/useActionTextAreaFiles';
import './styles.scss';
import { notificationError } from '../../core/actions';

const MAX_IMAGE_FILE_SIZE = 1600000;
const MAX_IMAGE_FILE_SIZE_HUMAN = '1.5mb';
const MAX_VIDEO_FILE_SIZE = MAX_IMAGE_FILE_SIZE;
const MAX_VIDEO_FILE_SIZE_HUMAN = MAX_IMAGE_FILE_SIZE_HUMAN;

export const DragAndDropContext = createContext({});

const DragAndDropContextProvider = ({ children, style = {}, contentStyle = {} }) => {
  const [fileData, fileDispatch] = useActionTextAreaFiles();
  const [maxFilesImage, setMaxFilesImage] = useState(0);
  const [maxFilesVideo, setMaxFilesVideo] = useState(0);

  const imageHandler = files => {
    const eventImageFiles = [];
    const eventVideoFiles = [];

    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      if (file.type.includes('image/')) {
        eventImageFiles.push(file);
      } else if (file.type.includes('video/')) {
        eventVideoFiles.push(file);
      }
    }
    processImageFiles(eventImageFiles);
    processVideoFiles(eventVideoFiles);

    function processImageFiles(imageFiles) {
      if (eventImageFiles.length > 0) {
        // this is a prop for auto reminder msgs and auto messages that only allow 1 image
        fileDispatch({
          type: actionTextAreaFileActions.setImages,
          payload: processFiles({
            files: imageFiles,
            maxFiles: maxFilesImage,
            maxSize: MAX_IMAGE_FILE_SIZE,
            sizeError: `Image file size exceeds ${MAX_IMAGE_FILE_SIZE_HUMAN}`,
            currentData: fileData.images,
            overflowError: maxFilesImage === 1 ? '1 image allowed' : `A Maximum of ${maxFilesImage} Images Allowed.`,
          }),
        });
      }
    }

    async function processVideoFiles(videoFiles) {
      const maxFiles = maxFilesVideo;
      const videoData = processFiles({
        files: videoFiles,
        maxFiles: maxFilesVideo,
        maxSize: MAX_VIDEO_FILE_SIZE,
        sizeError: `Video file size exceeds ${MAX_VIDEO_FILE_SIZE_HUMAN}`,
        currentData: fileData.videos,
        overflowError: maxFiles === 0 ? 'Video is not supported here.' : `A Maximum of ${maxFiles} Video Is Allowed.`,
      });
      const videoFrames = {
        files: [],
        urls: [],
      };
      let container;
      let canvas;
      let video;
      let ctx;
      if (videoFiles.length > 0) {
        container = document.createElement('div');
        canvas = document.createElement('canvas');
        video = document.createElement('video');
        container.appendChild(canvas);
        container.appendChild(video);
        container.style.visibility = 'hidden';

        document.body.appendChild(container);
        ctx = canvas.getContext('2d');
      }

      for (const videoURL of videoData.urls) {
        const { file, url } = await videoURLToThumbnail(videoURL);
        videoFrames.files.push(file);
        videoFrames.urls.push(url);
      }
      // document.body.removeChild(container)
      function videoURLToThumbnail(url) {
        return new Promise(resolve => {
          const loadedMetaEvent = 'loadedmetadata';
          const loadedEvent = 'seeked';
          const onVideoMetaLoaded = () => {
            video.currentTime = 0.2;
          };
          const onVideoLoaded = () => {
            canvas.height = video.videoHeight;
            canvas.width = video.videoWidth;
            ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            canvas.toBlob(blob => {
              const file = new File([blob], 'videoThumbnail.jpg', { type: 'image/jpeg' });
              resolve({
                file,
                url: URL.createObjectURL(file),
              });
            }, 'image/jpeg');
            video.removeEventListener(loadedMetaEvent, onVideoMetaLoaded);
            video.removeEventListener(loadedEvent, onVideoLoaded);
          };
          video.addEventListener(loadedMetaEvent, onVideoMetaLoaded);
          video.addEventListener(loadedEvent, onVideoLoaded);
          video.src = url;
        });
      }
      fileDispatch({
        type: actionTextAreaFileActions.setVideos,
        payload: {
          ...videoData,
          videoFrames,
        },
      });
    }

    function processFiles({
      files: filesToProcess,
      maxFiles,
      maxSize,
      sizeError,
      currentData = {
        files: [],
        urls: [],
      },
      overflowError,
    }) {
      const [hadLargeFile, files, urls] = filesToProcess.reduce(
        ([hadLargeFile_r, files_r, urls_r], file) => {
          const fileIsLarge = file.size >= maxSize;
          if (files_r.length >= maxFiles) {
            return [hadLargeFile_r || fileIsLarge, files_r, urls_r];
          }
          if (fileIsLarge) {
            return [true, files_r, urls_r];
          }
          files_r.push(file);
          urls_r.push(URL.createObjectURL(file));
          return [hadLargeFile_r, files_r, urls_r];
        },
        [false, [], []],
      );
      if (hadLargeFile) {
        notificationError(sizeError);
      }

      if (files.length + currentData.urls.length > maxFiles) {
        notificationError(overflowError);
      }

      return {
        urls: urls.concat(currentData.urls).slice(0, maxFiles),
        files: files.concat(currentData.files).slice(0, maxFiles),
      };
    }
  };

  const handleDragOver = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.classList.remove('draggeOver');

    const { files, types } = event.dataTransfer;
    if (types.length === 1 && types[0] === 'Files') {
      imageHandler(files);
    }
  };

  const handleDragEnter = event => {
    const { dropEffect, effectAllowed, files, items, types } = event.dataTransfer;
    if (types.length === 1 && types[0] === 'Files') {
      event.currentTarget.classList.add('draggeOver');
    }
  };

  const handleDragLeave = event => {
    if (event.currentTarget.contains(event.relatedTarget)) return;
    event.currentTarget.classList.remove('draggeOver');
  };

  useEffect(() => {
    document.getElementById('textAreaInputWithActionsInbox')?.addEventListener('paste', e => {
      if (e.clipboardData.files.length > 0) {
        e.preventDefault();
        imageHandler(e.clipboardData.files);
      }
    });
  }, [document.getElementById('textAreaInputWithActionsInbox')]);

  useEffect(() => {
    document.getElementById('textAreaInputWithActions')?.addEventListener('paste', e => {
      if (e.clipboardData.files.length > 0) {
        e.preventDefault();
        imageHandler(e.clipboardData.files);
      }
    });
  }, [document.getElementById('textAreaInputWithActions')]);

  return (
    <DragAndDropContext.Provider value={{ fileData, fileDispatch, imageHandler, setMaxFilesImage, setMaxFilesVideo }}>
      <div
        style={{ ...style }}
        className="drag-and-drop-container"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        {children}
        <div className="drag-and-drop-content" style={{ ...contentStyle }}>
          <div>
            <FontAwesomeIcon icon="fa-regular fa-folder-arrow-up" fontSize={42} />
            <span>Drag and Drop Image</span>
            <p>Accepted file types: .jpg and .png</p>
          </div>
        </div>
      </div>
    </DragAndDropContext.Provider>
  );
};

export function useDragAndDrop() {
  const context = useContext(DragAndDropContext);

  if (!context) {
    throw new Error('useDragAndDrop must be used within an DragAndDropContext');
  }
  return context;
}

export { DragAndDropContextProvider };
