import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Drawer } from 'antd';
import {
  getTablePaginationData,
  setTablePaginationAscendColumn,
  setTablePaginationPageCount,
  setTablePaginationRowCount,
  setTablePaginationSortColumn,
} from '../../../../core/actions';
import ConversationWindow from '../../../Layout/chat/chat';
import { getRequest } from '../../../../core/apiRequests';
import { setAuthPayments } from '../../../../reducers/auth';
import { setTableType } from '../../../../reducers/pagination';

import Layout from '../../../Layout/StandardLayout';
import Table from '../../../Layout/Table';

import MessageConfigToday from '../children/MessageConfigToday';
import Bubble from '../children/todayBubble';
import { setClientAssociate, getClientChat, setChatReviewLink } from '../../../../reducers/messaging';
import getClientPaymentStatus from '../../../Layout/GetClientPaymentStatus';

const TodayPayments = props => {
  const [isShown, setIsShown] = useState('');
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const history = useHistory();
  const [, setAllTemplates] = useState([]);

  const [, setShowWhatsAppTemp] = useState(false);
  const [, setTemplateObj] = useState({
    WA_receive: '',
    preferredContact: '',
    client_id: '',
    client_name: '',
    store_id: 0,
  });
  const [paymentsStatusRaw, setPaymentsStatusRaw] = useState({});
  const isMounted = useRef(null);
  const timeOffset = moment(new Date()).utcOffset();

  const rowClickHandler = (data, e) => {
    const stayOnCurrentPage =
      e.target.id === 'todayMessageIconDiv' || e.target.tagName === 'svg' || e.target.tagName === 'path';
    if (!stayOnCurrentPage) {
      history.push(`/Payments/details/${data.payment_request_id}`);
    }
  };

  useEffect(() => {
    const {
      firstSort,
      firstAscend,
      auth: { merchantId },
    } = props;
    if (merchantId) {
      setTablePaginationSortColumn(firstSort, 'TODAY_PAYMENTS');
      setTablePaginationAscendColumn(firstAscend || false, 'TODAY_PAYMENTS');
    }
  }, [props.auth.merchantId]);

  useEffect(() => {
    isMounted.current = true;
    if (props.auth.merchantId && props.pagination.pageSize) {
      const {
        pagination: { page, pageSize, sortColumn, ascendingColumn },
      } = props;
      const reqObj = {
        params: ['today', props.auth.merchantId, props.auth.userId, 'paginatedPaymentList'],
        query: {
          page,
          count: pageSize,
          sortColumn: sortColumn || 'datetime',
          ascending: ascendingColumn,
        },
      };
      (async () => {
        const respObj = await getRequest(reqObj);
        const tempStatusArr = [];
        if (
          Object.prototype.hasOwnProperty.call(respObj, 'results') &&
          Object.prototype.hasOwnProperty.call(respObj, 'pageInfo')
        ) {
          const {
            results,
            pageInfo: { rowCount, pageCount },
          } = respObj;
          tempStatusArr.push(...results);
          // const oldDateToNewDate = tempStatusArr.sort(
          //   (a, b) => b[sortColumn || 'datetime'] - a[sortColumn || 'datetime'],
          // );
          // setPaymentsStatusRaw(oldDateToNewDate);
          setPaymentsStatusRaw(tempStatusArr);
          setTablePaginationRowCount(rowCount, 'TODAY_PAYMENTS');
          setTablePaginationPageCount(pageCount, 'TODAY_PAYMENTS');
        } else {
          // notificationError('Error getting Payments List');
        }
      })();
      (async () => {
        const req = {
          params: ['chatMessages', props.auth.merchantId, 'allTemplates'],
        };
        const dataArr = await getRequest(req);
        if (dataArr.length > 0) {
          setAllTemplates([...dataArr]);
        }
      })();
      (async () => {
        const req = {
          params: ['chatMessages', props.auth.merchantId, 'paymentsVerify'],
        };
        const dataArr = await getRequest(req);
        if (dataArr.length > 0) {
          setAuthPayments(dataArr[0].cnt);
        }
      })();
    }
    return () => {
      isMounted.current = false;
    };
  }, [
    props.auth.merchantId,
    props.pagination.sortColumn,
    props.pagination.ascendingColumn,
    props.pagination.pageSize,
    props.pagination.page,
  ]);

  const getReviewLink = async storeId => {
    if (storeId) {
      const reqObj = {
        params: ['chats', storeId, 'getReviewLink'],
      };
      const linkData = await getRequest(reqObj);
      if (linkData.length > 0) {
        const { review_link } = linkData[0];
        setChatReviewLink(review_link);
      }
    } else {
      console.error('Unable to getReviewLink, NO store ID!');
    }
  };

  const setSelectedClient = async cli => {
    if (!cli.store_id) {
      console.log('ALERT NO STORE ID: ', cli);
    }
    await getClientChat(cli);
    await getReviewLink(cli.store_id);
    setClientAssociate(cli.associateCount);
    getClientPaymentStatus({ client: cli, auth: props.auth });
    setShowPopConfirm(true);
  };

  const openChatModal = client => {
    setSelectedClient(client);
  };

  const tableHeaders = [
    {
      keyName: 'datetime',
      title: 'Date',
      style: { width: '5%', midWidth: '120px' },
      headerClassName: '',
      rowClassName: '',
      sortable: true,
      render: t => {
        if (t) {
          return moment(t, 'YYYYMDD').format('MM/DD/YYYY');
        }
      },
    },
    {
      keyName: 'total_amount',
      title: 'Amount',
      style: { width: '5%' },
      headerClassName: '',
      rowClassName: '', // we can apply all styles to the header now.
      sortable: true, // This targets the header's className
      render: amount => `$${amount}`,
    },
    {
      keyName: 'name',
      style: { width: '10%', minWidth: '120px' },
      title: 'Client Name',
      sortable: true, // sort allows gives us the ability to sort the table
    },
    {
      keyName: 'status',
      style: { width: '15%', minWidth: '120px' },
      title: 'Client Recent Activity',
      sortable: true, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        if (
          o.status === 'PAID_MANUALLY' ||
          o.status === 'PAID_BY_CC' ||
          o.status === 'PAID_WITH_APPLE_PAY' ||
          o.status === 'PAID_WITH_GOOGLE_PAY'
        ) {
          return 'Client paid payment request';
        }
        if (o.status === 'VIEWED') {
          return 'Client viewed payment request';
        }
        if (o.status === 'CANCELED') {
          return 'Client canceled payment request';
        }
        if (o.status === 'REFUNDED') {
          return 'Client refunded payment request';
        }
        if (o.status === 'PAYMENT_FAILED') {
          return 'No payment method available';
        }
        if (o.status === 'UNPAID') {
          return 'Payment request sent to client';
        }
      },
    },
    {
      keyName: 'status',
      style: { width: '5%', textAlign: 'center' },
      title: 'Status',
      render: t => {
        const bubbleStyles = {
          padding: '4px 0 2px 0',
        };
        switch (t) {
          case 'VIEWED':
            return <Bubble text={t} color="yellow" style={bubbleStyles} />;
          case 'CANCELED':
            return <Bubble text={t} color="gray" style={bubbleStyles} />;
          case 'REFUNDED':
            return <Bubble text={t} color="gray" style={bubbleStyles} />;
          case 'UNPAID':
            return <Bubble text="SENT" color="yellow" style={bubbleStyles} />;
          case 'PAYMENT_FAILED':
            return <Bubble text="FAILED" color="red" style={bubbleStyles} />;
          case 'PAID_MANUALLY':
          case 'PAID_BY_CC':
          case 'PAID_WITH_APPLE_PAY':
          case 'PAID_WITH_GOOGLE_PAY':
            return <Bubble text="PAID" color="blue" style={bubbleStyles} />;
          default:
            return null;
        }
      },
      sortable: true,
    },
    {
      keyName: '',
      style: { width: '5%' },
      headerClassName: 'align-center',
      title: 'Message',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        return (
          <div className="align-center pos-rel" style={{ maxHeight: '34px' }} key={o.id}>
            <MessageConfigToday
              keyVar={o.id + Math.floor(Math.random(o.id))}
              msgObj={o}
              setShowPopConfirm={openChatModal}
              initiateTemplates={openChatModal}
              setIsShown={setIsShown}
              isShown={isShown}
            />
          </div>
        );
      },
    },
  ];
  return (
    <Layout
      title="All Payments"
      description=""
      classProps="standard-width-wo-sidebar"
      breadCrumbs={[{ title: 'Today', to: '/' }, { title: 'All Payments' }]}
      // styleProps={{ marginLeft: 79 }}
    >
      <div className="align-left m-top-50" onClick={() => setTableType('TODAY_PAYMENTS')}>
        <div className="flex-row-spacebetween-nowrap">
          <span className="fs-15 fw-500">Payments</span>
        </div>
        {paymentsStatusRaw.length > 0 ? (
          <Table
            data={paymentsStatusRaw}
            tableHeaders={tableHeaders}
            tableClassName="lg-table-sidebar rem-pad-width"
            optionalButton={null}
            onRowClick={rowClickHandler}
            tableName="TODAY_PAYMENTS"
            paginationV2
          />
        ) : (
          <div className="align-center h-173 w-100-P">
            <span className="pos-rel gray fs-13" style={{ top: '45%' }}>
              No Payments
            </span>
          </div>
        )}
      </div>
      <Drawer
        bodyStyle={{
          padding: 0,
          overflow: 'hidden',
        }}
        closable={false}
        push={false}
        destroyOnClose
        width={750}
        open={showPopConfirm}
        onClose={() => setShowPopConfirm(false)}
      >
        <ConversationWindow setDrawerToggle={() => setShowPopConfirm(false)} chatIconColor="#EBEBEB" type="right" />
      </Drawer>
    </Layout>
  );
};

function mapStateToProps(state) {
  const { auth, pagination, payments, today } = state;
  const tableType = pagination.table_type;
  return {
    auth,
    pagination: getTablePaginationData(state, tableType),
    pag: pagination.table_typ,
    payments,
    today,
  };
}
export default connect(mapStateToProps, {})(TodayPayments);
