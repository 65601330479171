import React, { useState, useEffect } from 'react';
import './index.css';

const ImportTable = props => {
  const [data, setData] = useState([]);

  // use to keep the table to a max of 10 rows of data
  useEffect(() => {
    if (props.confirm && props.data.length > 10) {
      let tempData = props.data.slice(0, 10);
      setData([...tempData]);
    } else if (!props.confirm && props.data.length > 9) {
      let tempData = props.data.slice(0, 9);
      setData([...tempData]);
    } else {
      setData([...props.data]);
    }
  }, [props.data]);

  // this gives you the column label to look like a spreadsheet
  const tableHeaders = [
    '',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  const tableRowBuilder = (item, rowKey) => {
    let rowArr = [];
    Object.keys(props.columns).forEach((column, colKey) => {
      let content;
      content = (
        <td
          key={`td${rowKey}${colKey}`}
          // allows the first row to be highlighted in blue
          className={props.confirm && rowKey === 0 ? 'cell highlighted-cells' : 'cell'}
        >
          {item[column]}
        </td>
      );
      rowArr.push(content);
    });
    return rowArr;
  };

  return (
    <table className="import-table">
      <thead className="import-header">
        {/* This is the border row at the top with A, B, C... for all the columns */}
        <tr>
          {Object.keys(props.columns).map((col, i) => {
            return (
              <th key={i} className="cell border-cells">
                {tableHeaders[i]}
              </th>
            );
          })}
          {/* allows for the last column to be added in */}
          <th className="cell border-cells left-column-cells">{tableHeaders[Object.keys(props.columns).length]}</th>
        </tr>
      </thead>
      {props.confirm ? (
        <tbody>
          {/* This is all the data from the table not including the headers */}
          {data.map((item, key) => {
            return (
              <tr key={key}>
                <td className="cell border-cells left-column-cells">
                  {/* This accounts for the headers row and the border cells on the table giving it the blank, 1, 2, 3... on the side */}
                  {key + 1}
                </td>
                {tableRowBuilder(item, key)}
                {item.name}
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td className="cell border-cells left-column-cells">1</td>
            {/* This will be the headers row that is highlighted at the top */}
            {Object.keys(props.columns).map((col, i) => {
              return (
                <td key={i} className={props.bold ? 'cell highlighted-cells bold' : 'cell highlighted-cells'}>
                  {props.columns[col].displayName ? props.columns[col].displayName : col}
                </td>
              );
            })}
          </tr>
          {/* This is all the data from the table not including the headers */}
          {data.map((item, key) => {
            return (
              <tr key={key}>
                <td className="cell border-cells left-column-cells">
                  {/* This accounts for the headers row and the border cells on the table giving it the blank, 1, 2, 3... on the side */}
                  {key + 2}
                </td>
                {tableRowBuilder(item, key)}
                {item.name}
              </tr>
            );
          })}
        </tbody>
      )}
    </table>
  );
};

export default ImportTable;
