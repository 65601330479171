import React from 'react';
import NewPopConfirm from '../../../../Layout/NewPopConfirm';
import Button from '../../../../CustomizedAntDesign/Button';
import './styles.css';

const DeleteBankAccountModal = ({ onCancel, onConfirm }) => {
  return (
    <NewPopConfirm className="cb-delete-bank-account-modal">
      <h3 className="cb-delete-bank-account-modal__title">Delete Account</h3>

      <p className="cb-delete-bank-account-modal__subtitle">Are you sure you want to delete this account?</p>

      <p className="cb-delete-bank-account-modal__description">
        All stores connected to this account will be assigned to your Default Bank Account. All historical data will be
        saved.
      </p>
      <div className="mq-w-100-vw custom-action-save-flex">
        <button className="save-button-v2 m-btm-8" onClick={onConfirm}>
          Continue
        </button>
          <button className="cancel-button-v2" onClick={onCancel}>Cancel</button>
      </div>
    </NewPopConfirm>
  );
};

export default DeleteBankAccountModal;
