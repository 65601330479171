import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Avatar } from 'antd';
import './styles.css';

const ClientCard = ({ posType, client }) => {
  const [initials, setInitials] = useState('#');
  const identifier = (posType ? client.pos_ref_num : client.mobile) || client.mobile;
  const label = client.name || client.preferred_name || identifier;

  const handleSetInitials = () => {
    if (client.name || client.preferred_name) {
      const newInitials = label
        .split(' ')
        .map(e => e.charAt(0))
        .slice(0, 2)
        .join('');

      setInitials(newInitials);
    }
  };

  useEffect(() => {
    handleSetInitials();
  }, []);

  return (
    <div className="cb-client-card">
      <Avatar size={40} style={{ backgroundColor: '#803E9F' }}>
        {initials}
      </Avatar>

      <div className="cb-client-card__content">
        <h2>{label}</h2>
        <span>{identifier}</span>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  posType: state.auth.pos_type,
});

export default connect(mapStateToProps, {})(ClientCard);
