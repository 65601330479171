import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer } from 'antd';
import React, { useState, useEffect } from 'react';
import { getRequest } from '../../../../../core/apiRequests';
import Button from '../../../Button';
import ViewAllWhatsappTemplates from './children/ViewAllWhatsappTemplates';
import WAPlaceHolderModal from './children/WAPlaceHolderModal';
import WhatsappInputSearchBox from './children/WhatsappInputSearchBox';
import WhatsappInputTemplateCard from './children/WhatsappInputTemplateCard';
import whatsappTextFormatter from './children/whatsappTextFormatter';

const WhatsappMessageInput = ({
  merchantId,
  associateName,
  clientName,
  reviewLink,
  changeHandler,
  sendMessage,
  clientId,
}) => {
  const defaultActiveMsg = {
    id: 0,
    name: '',
    scope: '',
    substitution: '',
    template: '',
    formattedText: '',
    placeHolderValues: {},
  };
  const [WATemplates, setWATemplates] = useState([]);
  const [viewAllTemplates, setViewAllTemplates] = useState(false);
  const [showPlaceHolderModal, setShowPlaceHolderModal] = useState(false);
  const [activeMsg, setActiveMsg] = useState(defaultActiveMsg);
  const configObj = { associate: `${associateName}`, client: clientName, reviewlink: reviewLink };

  useEffect(() => {
    if (merchantId) {
      // maybe check for existing client? I don't know
      getWATemplates();
    }
  }, []);

  useEffect(() => {
    resetActiveTemplate();
  }, [clientId]);

  const getWATemplates = async () => {
    const reqObj = {
      params: ['chats', 'getWATemplates'],
    };
    const data = await getRequest(reqObj);
    if (Array.isArray(data)) {
      setWATemplates([...data]);
    }
    // id: 1;
    // merchant_id: 22;
    // name: 'Anniversary Appointment';
    // scope: 'ANNIVERSARY';
    // substitution: '1=CLIENT,2=DATE,3=ASSOCIATE';
    // template: 'Hi {{1}}, I can see it’s your wedding anniversary on {{2}}. I have some exciting ideas to mark the occasion. Please let me know if you are interested in scheduling an in-person visit or Facetime appoointment. Best, {{3}}';
  };

  const autoSelectHandler = tmpObj => {
    setActiveTemplate(tmpObj);
    setTimeout(() => {
      setShowPlaceHolderModal(true);
    }, 20);
  };

  const onClickSelectHandler = () => {
    setViewAllTemplates(false);
    setShowPlaceHolderModal(true);
  };

  const get3WACards = () => {
    if (WATemplates.length > 0) {
      return WATemplates.slice(0, 3).map((tmpObj, i) => (
        <WhatsappInputTemplateCard
          key={i}
          templateObj={tmpObj}
          configObj={configObj}
          selectHandler={autoSelectHandler}
          onClickSelectHandler={onClickSelectHandler}
          styleProps={{ opacity: 0.7 }}
        />
      ));
    }
  };

  const resetActiveTemplate = () => {
    setActiveMsg({ ...defaultActiveMsg });
    setViewAllTemplates(false);
    setShowPlaceHolderModal(false);
    changeHandler('');
  };

  const setActiveTemplate = tmpObj => {
    const formattedText = whatsappTextFormatter(tmpObj, configObj);
    setActiveMsg({ ...tmpObj, formattedText, placeHolderValues: configObj });
  };

  const sendHandler = () => {
    sendMessage();
    resetActiveTemplate();
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      sendMessage();
      resetActiveTemplate();
    }
  };

  return (
    <div className="w-100-P p-top-10">
      <div className="flex-row-spacebetween-nowrap p-left-15 rem-pad-width" style={{ justifyContent: 'flex-start' }}>
        {get3WACards()}
      </div>
      <div className="flex-row-nospacebetween-nowrap" style={{ justifyContent: 'flex-start', paddingRight: '90px' }}>
        <WhatsappInputSearchBox
          setViewAllTemplates={setViewAllTemplates}
          activeMsg={activeMsg}
          resetActiveTemplate={resetActiveTemplate}
          handleKeyDown={handleKeyDown}
        />
        <Button
          onclick={() => (activeMsg.complete ? sendHandler() : null)}
          classname="blue-link fs-13 m-right-14"
          styleProps={{ width: 'auto', paddingRight: 0, backgroundColor: 'transparent' }}
        >
          <FontAwesomeIcon
            icon={['fa', 'paper-plane']}
            className={'fs-16 ' + `${activeMsg.complete ? 'blue' : 'mid-gray'}`}
            size="1x"
            style={{ paddingBottom: '2px' }}
          />
        </Button>
      </div>
      <ViewAllWhatsappTemplates
        allTemplates={WATemplates}
        configObj={configObj}
        viewAllTemplates={viewAllTemplates}
        toggleViewAll={setViewAllTemplates}
        setActiveTemplate={setActiveTemplate}
        showPlaceHolderModal={setShowPlaceHolderModal}
        onClickSelectHandler={onClickSelectHandler}
        resetActiveTemplate={resetActiveTemplate}
      />
      <WAPlaceHolderModal
        visible={showPlaceHolderModal}
        setVisible={setShowPlaceHolderModal}
        activeMsg={activeMsg}
        setActiveMsg={setActiveMsg}
        resetActiveTemplate={resetActiveTemplate}
        changeHandler={changeHandler}
      />
    </div>
  );
};
export default WhatsappMessageInput;
