import React from 'react';
import AutoMessageItem from './AutoMessageItem';

const AutoMessages = props => {
  return (
    <div className="fs-15 fw-500 p-left-21 p-right-15">
      <div style={{ paddingRight: '8px' }} className="cb-payment-request--header">
        <h2 className="cb-payment-request--header-title">Auto Messages</h2>
        {props.client.autoMessages.length > 0 && (
          <button
            type="button"
            className="blue fs-16 border-none transparent fw-500 align-left"
            style={{ padding: 0 }}
            onClick={() => {
              props.setViewAllType('allAutoMessages');
            }}
          >
            VIEW ALL
          </button>
        )}
      </div>
      {props.client.autoMessages.length > 0 ? (
        <div className='flex-col-left' style={{ alignItems: 'stretch', paddingRight: '8px' }}>
          {props.client.autoMessages.slice(0, 2).map((autoMessage) => (
            <AutoMessageItem
              client_name={autoMessage?.relationship_type_id ? (autoMessage?.relation_name || '') : (props.client?.preferred_name || props.client?.name)}
              associate_name={props.associate_name}
              review_link={''}
              key={autoMessage.id}
              relation={autoMessage?.relationship_type_id ? props?.client?.name : ''}
              {...autoMessage}
            />
          ))}
        </div>
      ) : (
        <div className='flex-col-left'>
          <div className='fw-400 fs-12 align-left' style={{ color: '#818181'}}>
            No Auto Messages
          </div>
          <button
            type="button"
            className="blue fs-14 border-none transparent fw-500 align-left"
            style={{ padding: 0 }}
            onClick={() => {
              props.setViewAllType('allAutoMessages');
            }}
          >
            VIEW ALL
          </button>
        </div>
      )}
    </div>
  );
};
export default AutoMessages;
