import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setAuthEdison } from '../../../../reducers/auth';
import { updatedAiAssistantViewDate } from '../apiRequests';

const IntroEdisonToolTip = props => {
  const { setShowLead, setShowToolTip, userId, AIActivity, vendorItemViewHandler } = props;

  const apiClickHandler = () => {
    vendorItemViewHandler();
    updatedAiAssistantViewDate(userId, setAuthEdison)
  }

  return (
    <div
      id="textBlock_active"
      className="pos-abs introEdisonToolTipOuterDiv"
      style={{
        zIndex: '2147483647',
      }}
    >
      <div className="flex-col-center m-top-50">
        <span className="fw-500 fs-13">Introducing</span>
        <span className="fw-600 fs-16">Clientbook Smart Assistant!</span>
      </div>
      <div className="flex-col-center">
        <p className="fw-500 fs-14 align-left" style={{ width: '285px' }}>
          You’re great at building relationships. I’m great at using data to find who to reach out to. Together, we will
          make an awesome team!{' '}
        </p>
        <p className="fw-500 fs-14 align-left" style={{ width: '285px' }}>
          Everyday, I will check to who you should message, and suggest what you might say.
        </p>
      </div>
      <div
        role="button"
        tabIndex={0}
        className="align-right"
        style={{ marginRight: '35px' }}
        onClick={() => {
          setShowLead(true);
          AIActivity({ eventType: 'activity_viewed', msgRegen: false, msgEdit: false, resultCode: null });
          setShowToolTip(false);
        }}
      >
        <span
          style={{ color: '#33AAFC' }}
          className="fw-600 fs-16 align-left pointer"
          onClick={() => apiClickHandler()}
        >
          Let’s get started
        </span>
        <FontAwesomeIcon icon="fa-bold fa-arrow-right" style={{ color: '#33aafc' }} className="m-left-10" />
      </div>
      <FontAwesomeIcon
        icon="fa-solid fa-caret-up"
        className="pos-rel"
        size="xl"
        style={{ color: '#fff', left: 2, bottom: '341px' }}
      />
    </div>
  );
};

export default IntroEdisonToolTip;
