import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './payment.css';

const PaymentBanner = props => {
  const { bannerText, role, paymentBannerActive } = props;

  const text = () => {
    if (bannerText === 'ACTIVE') {
      return (
        <p>
          Clientbook Payments is active! &nbsp;
          <span className="underline ">Learn More</span>
          <img
            src="https://dashboard-v2-images.s3.amazonaws.com/bannerArrow.png"
            alt="banner-arrow"
            className="bannerArrowAnimation"
          />
        </p>
      );
    }
    if (bannerText === 'PENDING' && role === 'ADMIN') {
      return <p>Your Clientbook Payments account is pending approval</p>;
    }
    if (bannerText === 'NONE' && role === 'ADMIN') {
      return (
        <p>
          Get started with Clientbook Payments. &nbsp;
          <span className="underline">Set up now.</span>
          <img
            src="https://dashboard-v2-images.s3.amazonaws.com/bannerArrow.png"
            alt="banner-arrow"
            className="bannerArrowAnimation"
          />
        </p>
      );
    }
  };
  if ((bannerText === 'ACTIVE' || bannerText === 'NONE') && paymentBannerActive) {
    return (
      <div className="w-100-vw d-inline-block rem-pad-width z-index-999 flex-row-spacebetween-nowrap paymentBanner">
        {bannerText === 'NONE' ? (
          <Link to="/BankAccount/list">
            <div className="bannerText">{text()}</div>
          </Link>
        ) : (
          <a href="https://vimeo.com/651225668/f15eeb077e">
            <div className="bannerText">{text()}</div>
          </a>
        )}
        {props.navigation}
      </div>
    );
  }
  if (bannerText === 'PENDING' && paymentBannerActive) {
    return (
      <div className=" w-100-vw pos-fixed d-inline-block rem-pad-width z-index-999 flex-row-spacebetween-nowrap pendingPaymentBanner">
        <div className="bannerText">{text()}</div>
        {props.navigation}
      </div>
    );
  }
  return props.navigation;
};
const mapStateToProps = state => ({
  paymentBannerActive: state.paymentBanner.active,
});
export default connect(mapStateToProps)(PaymentBanner);
