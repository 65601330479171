import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SmallTable from '../../SmallTable';
import { actionTextAreaFileActions } from '../../TextAreaWithActions/useActionTextAreaFiles';
import { convertMessageTemplate } from '../../../../utils/convertMessageTemplate';

const ChatTemplatePopup = ({
  setShowPopup,
  showPopup,
  setShowPopConfirm,
  templates,
  tableData,
  setMsgText,
  fileDispatch,
  currentClientName,
  associateName,
  reviewLink,
  type,
  auth,
  client,
}) => {
  const lilTemplateHeader = [
    {
      keyName: 'msg',
      style: { width: '100%', paddingRight: '25px', minWidth: '75px' },
      headerClassName: 'no-text-select',
      title: '',
      render: (msg, item) => {
        return (
          <div className="p-left-8">
            <span className="fw-500 fs-14">{item.autoReminder}</span>
            <div dangerouslySetInnerHTML={{ __html: convertMessageTemplate(msg) }} />
          </div>
        );
      },
    },
  ];
  const flags = useFlags();

  const [selectedStoreName, setSelectedStoreName] = useState('');

  useEffect(() => {
    if (flags?.multiLocationMessaging) {
      if (auth?.selectedStore) {
        const storeName = auth?.stores.find(store => store.id === auth.selectedStore)?.name;
        setSelectedStoreName(storeName || '');
      }
    } else if (client?.store_id) {
      const storeName = auth.stores.find(store => store?.id === client?.store_id)?.name;
      setSelectedStoreName(storeName || '');
    }
  }, [auth?.selectedStore, client, flags]);

  const templateReplace = message => {
    const splitName = currentClientName?.split(' ');
    const replaced = message
      .replaceAll(/:name:/g, splitName[0])
      .replaceAll(/:associate:/g, associateName)
      .replaceAll(/:review:/g, reviewLink)
      .replaceAll(/:store:/g, selectedStoreName);
    setMsgText(replaced);
  };

  return (
    <div className={type === 'full' ? 'chat-templates-modal-container-full' : 'chat-templates-modal-container-right'}>
      {type === 'full' && (
        <div className="flex-row-rev-left">
          <FontAwesomeIcon className="pointer" icon={['fa', 'x']} onClick={() => setShowPopup(false)} />
        </div>
      )}
      <div className="p-btm-10 align-center">
        <span className="chat-templates-modal-header">Templates</span>
      </div>
      <input
        onKeyDown={e => templates.handleSearchChange(e.target.value)}
        className="searchInput h-45 bg-offwhite fs-13 focus-input-outline-blue m-btm-15 w-300"
        placeholder="Search templates.."
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      <SmallTable
        classname="invisible"
        tableClassName="edit-tags-auto-table max-w-600"
        tableContainerClass="whatsapp-auto-container"
        tableContainerStyle={{ overflowY: 'scroll', height: '80%' }}
        tableStyle={{
          marginRight: 0,
          boxSizing: 'border-box',
          width: '100%',
          maxWidth: '100%',
          borderCollapse: 'separate',
          borderSpacing: '8px',
        }}
        rowStyle={{
          borderRadius: '5px',
          border: '1px solid gray',
          boxShadow: '1px 1px 5px rgba(150,150,150,0.5)',
        }}
        data={tableData}
        row
        onRowClick={data => {
          // setMsgText(data.msg)
          templateReplace(data.msg);
          setShowPopup(false);
          setShowPopConfirm(true);
          if (data.image_url) {
            fileDispatch({
              type: actionTextAreaFileActions.setToSingleImageUrl,
              payload: data.image_url,
            });
          }
        }}
        tableHeaders={lilTemplateHeader}
      />
    </div>
  );
};
export default ChatTemplatePopup;
