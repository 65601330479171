import Axios from 'axios';
import FileDownload from 'js-file-download';

// reducers & action
import { setLoadingStatus, setSavingStatus } from '../../../reducers/notification';
import { setPaymentsList } from '../../../reducers/payments';
import { getRequest, postRequest } from '../../../core/apiRequests';
import { notificationSuccess, notificationError } from '../../../core/actions';

// local
import { getSearchParams, combineActivities, getPaymentStatus, setupFilterSelectedStatuses } from './utils';
import { ALL_ASSOCIATES_OPTION } from './Activity';
import { PAYMENTS_API_URL } from '../../../constants';

export const getPaymentActivityReport = ({
  paymentFilterStatuses = [],
  paymentFilterAssociates = [],
  bankAccounts,
  transactionFilterBankAccounts = [],
  startDate = '',
  endDate = '',
  storeId = '',
  searchQuery = '',
  page,
  count,
  sortColumn,
  ascending,
}) =>
  new Promise((resolve, reject) => {
    setLoadingStatus(true);

    // conditional validation if the string is empty do not parse it. The parsing is converting the + sign to %2B for url encoding.
    if (!(searchQuery === '')) {
      searchQuery = searchQuery.replace(/\+/g, '%2B');
    }

    const tempBankFilterList = bankAccounts.filter(obj => {
      return obj.name === transactionFilterBankAccounts[0];
    });

    const bankAccountLastFour = tempBankFilterList[0]?.lastFour;

    const searchParams = getSearchParams({
      associateIDs: paymentFilterAssociates,
      bankAccountLastFour,
      statuses: paymentFilterStatuses,
      statusFromDate: startDate,
      statusToDate: endDate,
      storeIDs: [storeId],
      searchQuery,
      page,
      count,
      sortColumn,
      ascending,
    });

    // helper function to let the reducer filter
    // showing only statuses that match the selected statuses (and not the mapped filters)

    return Axios.get(`${PAYMENTS_API_URL}/v1/reports/paymentActivity${searchParams}`)
      .then(({ data }) => {
        const { paymentRequests, totalRecords } = data;

        setPaymentsList({
          paymentRequests,
          filter: setupFilterSelectedStatuses(paymentFilterStatuses),
        });
        resolve({ totalRecords });
      })
      .catch(err => {
        console.log({ err });
        notificationError('Payment activity did not load ');
        reject();
      })
      .finally(() => setLoadingStatus(false));
  });

export const getPaymentActivityReportCSV = ({
  paymentFilterStatuses = [],
  paymentFilterAssociates = [],
  startDate = '',
  endDate = '',
  storeId = '',
  searchQuery = '',
  page,
  count,
  sortColumn,
  ascending,
}) =>
  new Promise((resolve, reject) => {
    setLoadingStatus(true);

    const searchParams = getSearchParams({
      associateIDs: paymentFilterAssociates,
      statuses: paymentFilterStatuses,
      statusFromDate: startDate,
      statusToDate: endDate,
      storeIDs: [storeId],
      searchQuery,
      page: 0,
      count: 9999999, // hard coded this because you'd have to rebuild how payments backend is paginated and it's shared with other report endpoints
      sortColumn,
      ascending,
    });
    // helper function to let the reducer filter
    // showing only statuses that match the selected statuses (and not the mapped filters)

    return Axios.get(`${PAYMENTS_API_URL}/v1/reports/paymentActivityCSV${searchParams}`)
      .then(({ data }) => {
        FileDownload(data, 'Payment-Activity.csv');
        resolve();
      })
      .catch(err => {
        notificationError('Payment activity did not load ');
        reject();
      })
      .finally(() => setLoadingStatus(false));
  });

export const getReminderMessage = id =>
  new Promise((resolve, reject) => {
    setLoadingStatus(true);

    return Axios.get(`${PAYMENTS_API_URL}/v1/paymentRequest/${id}/getRemindMessage`)
      .then(({ data: responseData }) => {
        console.log({ responseData });
        resolve(responseData.paymentReminderMessage);
      })
      .catch(err => {
        console.log({ err });
        notificationError('Default message did not load ');
        reject();
      })
      .finally(() => setLoadingStatus(false));
  });

export const sendReminder = (id, commMessage, associateId, merchantId, clientId, associateName, storeId) =>
  new Promise((resolve, reject) => {
    return postRequest({
      params: ['chatMessages', merchantId, 'save'],
      query: {
        associateId,
        paymentRequestID: id,
        fullName: associateName,
        storeId,
      },
      data: {
        id: clientId,
        msg: commMessage,
      },
    })
      .then(() => {
        notificationSuccess('Payment reminder sent');
        resolve();
      })
      .catch(err => {
        console.log({ err });
        notificationError('Payment reminder was not sent');
        reject();
      })
      .finally(() => {
        setSavingStatus(false);
      });
  });

export const markAsPaid = (id, reason) =>
  new Promise((resolve, reject) => {
    return Axios.put(`${PAYMENTS_API_URL}/v1/paymentRequest/${id}/paid`, {
      reason,
    })
      .then(() => {
        notificationSuccess('Marked as paid');
        resolve();
      })
      .catch(err => {
        console.log({ err });
        notificationError('Not marked as paid');
        reject();
      })
      .finally(() => {
        setSavingStatus(false);
      });
  });

export const cancelPayment = (id, reason) =>
  new Promise((resolve, reject) => {
    return Axios.put(`${PAYMENTS_API_URL}/v1/paymentRequest/${id}/cancel`, {
      reason,
    })
      .then(() => {
        notificationSuccess('Canceled payment');
        resolve();
      })
      .catch(err => {
        console.log({ err });
        notificationError('Not canceled');
        reject();
      })
      .finally(() => {
        setSavingStatus(false);
      });
  });

export const refundPayment = (id, { amount, reason }) =>
  new Promise((resolve, reject) => {
    return Axios.put(`${PAYMENTS_API_URL}/v1/paymentRequest/${id}/refund`, {
      amount: Number(amount),
      reason,
    })
      .then(() => {
        notificationSuccess('Refunded payment');
        resolve();
      })
      .catch(err => {
        console.log({ err });
        notificationError('Not refunded');
        reject();
      })
      .finally(() => {
        setSavingStatus(false);
      });
  });

const sortAssociateNames = (a, b) => {
  if (`${a.first_name || ''}${a.last_name || ''}` > `${b.first_name || ''}${b.last_name}`) {
    return 1;
  }
  if (`${a.first_name || ''}${a.last_name || ''}` < `${b.first_name || ''}${b.last_name}`) {
    return -1;
  }
  return 0;
};

export const loadAssociatesList = async ({ stores, role, userId, merchantId }, set) => {
  const isAllStores = role === 'ADMIN';
  const selectedStores = stores.map(({ id }) => id).filter(id => !!id);

  if (selectedStores.length) {
    const associates = await getRequest({
      params: ['associates', merchantId],
      query: {
        selectedStores: JSON.stringify(selectedStores),
        allStores: isAllStores,
        userId,
      },
    });

    if (associates.length) {
      const sortedAssociates = associates.sort(sortAssociateNames);
      const dropdownAssociates = sortedAssociates.map(asct => ({
        label: `${asct.first_name || ''} ${asct.last_name || ''}`,
        value: asct.id,
      }));

      set([ALL_ASSOCIATES_OPTION, ...dropdownAssociates]);
    }
  }
};

export const getPaymentDetails = id =>
  new Promise((resolve, reject) =>
    Axios.get(`${PAYMENTS_API_URL}/v1/reports/${id}/paymentDetails`)
      .then(({ data }) => {
        const details = {
          ...data,
          status: getPaymentStatus(data.paymentRequest.paymentRequestLastStatus, data.hasExpired),
        };

        resolve({
          ...details,
          paymentActivity: combineActivities(details),
        });
      })
      .catch(err => {
        console.log({ err });
        reject(err);
      }),
  );
