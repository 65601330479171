import { postRequest } from '../../../../../../core/apiRequests';
import { notificationSuccess } from '../../../../../../core/actions';

const assignAssociate = ({ auth, associate, selectedAction, setSelectedAction, setAssignPopup, setReload }) => {
  setAssignPopup(false);
  //   if (singleRem) {
  //     // const singleR = [singleRem];
  //     reqObj = {
  //       params: ['today', auth.merchantId, 'assignAssociate'],
  //       data: { selectedAction: singleRem },
  //       query: {
  //         assignedAssociate: associate,
  //         storeId: auth.storeId,
  //         associateId: auth.userId,
  //       },
  //     };
  //   } else {
  const reqObj = {
    params: ['today', auth.merchantId, 'assignAssociate'],
    data: { selectedAction },
    query: {
      assignedAssociate: associate,
      storeId: auth.storeId,
      associateId: auth.userId,
    },
  };
  //   }
  (async () => {
    const data = await postRequest(reqObj);
    if (data) {
      setSelectedAction([]);
      notificationSuccess('Reminder(s) Assigned!');
      setReload(true);
    }
  })();
};

export default assignAssociate;
