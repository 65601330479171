import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Skeleton } from 'antd';
import { getRequest, postRequest } from '../../../../core/apiRequests';
import StyledLink from '../../StyledLink';
import { Buttons } from './Buttons';
import { notificationError, notificationSuccess } from '../../../../core/actions';
import { setClientPreferences } from '../../../../reducers/client';

const Preference = props => {
  const clientId = props.client.client_id;
  const [openEdit, setOpenEdit] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const setEditButton = useCallback((value) => {
    setOpenEdit(value);
  }, []);

  const handleCancel = useCallback(() => {
    setOpenEdit(false);
    props.resetField('preferences');
  }, []);

  const handleSave = useCallback(async () => {
    if(!props.dirtyFields.preferences) return;

    const formattedPreferences = props.preferences.map(pref => {
      if(pref.value === ''){
        return {
          ...pref,
          value: null
        }
      } else {
        return {
          ...pref
        }
      }
    })
    let updatedPreferences = formattedPreferences.filter((_, index) => (
      !!props?.dirtyFields?.preferences[index]?.value
    ));
    const reqObj = {
      params: ['clients', clientId, 'editClientPreferences'],
      data: {
        client_id: clientId,
        preferences: updatedPreferences
      },
    };
    try {
      await postRequest(reqObj);
      notificationSuccess('Preferences Updated');
      setClientPreferences(props.preferences)
    } catch (err) {
      console.log(err);
      notificationError('Error Updating Preferences');
    } finally {
      setOpenEdit(false);
    }
  }, [props.dirtyFields, clientId, props.preferences]);

  const filteredPreferences = useMemo(() => {
    return props.preferences.filter(pref => !!pref.value);
  }, [props.preferences])

  const handleKeyDown = useCallback((e) => {
    if(e.key === 'Enter'){
      handleSave();
    }
  }, [handleSave, props.dirtyFields, props.resetField, props.preferences])

  return (
    <div>
      <div
        style={{ borderTop: '1px #ebebeb solid', position: 'relative' }}
        id='client-details-preferences-box'
      >
        <p className="fw-500 gray fs-15 align-left m-btm-5">Preferences</p>
        <Buttons
          open={openEdit}
          setOpen={setEditButton}
          onSave={handleSave}
          onCancel={handleCancel}
          containerStyles={{ top: '12px'}}
          id='client-details-preferences-buttons'
          parentId='client-details-preferences-box'
        />
        <div className='flex-col-left m-top-4'>
          {openEdit ? (
            props.preferences.map((pref, index) => (
              <PreferenceEditItem
                {...pref}
                key={"preferences"+index}
                prefix={`preferences[${index}].`}
                register={props.register}
                handleKeyDown={e => handleKeyDown(e)}
              />
            ))
          ) : (
            filteredPreferences?.length > 0 ? (
              filteredPreferences.map((pref, index) => (
                <PreferenceItem {...pref} key={"preferences"+index}
                />
              ))
            ) : (
              <span className="gray fs-12 w-100-P align-center">No Preferences</span>
            )
          )}
        </div>
      </div>
    </div>
  );
};

const PreferenceItem = ({ config_id, name, value }) => {

  return (
    <div className="m-btm-5 w-100-P" key={config_id}>
      <div className="flex-row-center fs-12 align-left w-100-P" >
        <span className="fs-12 fw-500 black" style={{width: '45%', paddingRight: "4px"}}>{name}</span>
        <span className="fs-12 fw-500 black">{value}</span>
      </div>
    </div>
  )
}

const PreferenceEditItem = ({name, prefix, register, handleKeyDown}) => {

  return (
    <div className="flex-row-spacebetween-center w-100-P">
      <span className="fs-12 fw-500 black line-clamp-1 align-left" style={{ width: "45%"}}>{name}</span>
      <input
          className="fs-12 outline-none client-input fw-500 m-btm-8"
          style={{ width: "50%"}}
          placeholder="Not Set"
          {...register(`${prefix}value`)}
          onKeyDown={handleKeyDown}
        />
    </div>
  )
}

const mapStateToProps = state => ({
  messaging: state.messaging,
});
export default connect(mapStateToProps)(Preference);
