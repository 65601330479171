import React, { useMemo } from 'react';
import yn from 'yn';
import { connect } from 'react-redux';
import { PRODUCT_DETAILS_SECTIONS } from './constants';
import formatToCurrency from '../../../../../../../utils/formatToCurrency';
import './styles.css';

const formatString = value => {
  return (value || '').trim();
};

const filterSectionItems = (section, product, role) => {
  return section.items.map(item => {
    if (item.roles && !item.roles.includes(role)) {
      return null;
    }

    const value =
      section.itemType === 'number' ? formatToCurrency(`${product[item.key]}`) : formatString(product[item.key]);

    if (!value) return null;

    return [item.label, value];
  });
};

const ProductDetailsContent = ({ product, role, merchantConfig }) => {
  const sectionHasItems = section => {
    return section.itemType === 'number' || section.items.some(item => Boolean(product[item.key]));
  };

  const price = useMemo(() => {
    const value = product.vendor_id ? product.msrp : product.price;

    return formatToCurrency(`${value}`);
  }, [product.price, product.vendor_id, product.msrp]);

  return (
    <div className="clients-v2-collection-product-details">
      <h1 className="clients-v2-collection-product-details--name">{product.name}</h1>

      <h2 className="clients-v2-collection-product-details--sku">{product.sku}</h2>

      {(yn(merchantConfig.HIDE_PRODUCT_COST) && role === 'ASSOCIATE') || (
        <h3 className="clients-v2-collection-product-details--price">{price}</h3>
      )}

      <hr />

      <section>
        <p>{product.description || 'No Description'}</p>
      </section>

      <section>
        {PRODUCT_DETAILS_SECTIONS.map(section => {
          if (
            sectionHasItems(section) && section.config
              ? yn(merchantConfig[section.config?.name]) === section.config?.value ||
                section.config?.roles.includes(role)
              : true
          ) {
            const filteredItems = filterSectionItems(section, product, role);

            if (filteredItems.every(item => item === null)) {
              return null;
            }

            return (
              <div key={section.label}>
                <h2 className="clients-v2-collection-product-details--title">{section.label}</h2>

                <div className="clients-v2-collection-product-details--section">
                  <div>
                    {filteredItems
                      .filter(item => Array.isArray(item))
                      .map(item => (
                        <span key={`${item[1]}-label`}>{item[0]}</span>
                      ))}
                  </div>

                  <div>
                    {filteredItems
                      .filter(item => Array.isArray(item))
                      .map(item => (
                        <span key={`${item[1]}-value`}>{item[1]}</span>
                      ))}
                  </div>
                </div>
              </div>
            );
          }

          return null;
        })}
      </section>
    </div>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  merchantConfig: state.auth.merchantConfig,
});

export default connect(mapStateToProps, {})(ProductDetailsContent);
