import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { List, ConfigProvider } from 'antd';
import PopConfirm from '../../PopConfirm';
import PopConfirmLarge from '../../PopConfirm/PopConfirmLarge';
import { deleteRequest, getRequest, postRequest } from '../../../../core/apiRequests';
import { notificationSuccess, notificationError } from '../../../../core/actions';
// import DropdownSelect from '../../DropdownSelect';
import { setMsgClient, getClientChat, setInboxList, setStoreInboxList } from '../../../../reducers/messaging';

const uuidv4 = require('uuid/v4');

const NoNumberBanner = ({
  messagingClient,
  merchantId,
  inboxList,
  storeInboxList,
  userId,
  auth,
  // role,
  pos_type,
  sync_clients,
  first_name,
  last_name,
  clearSelectedClient,
  messagingMobile,
  mergeSelectedClient,
}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openMerge, setOpenMerge] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [stores, setStores] = useState([]);
  // const [storeName, setStoreName] = useState('');
  // const [clientStore, setClientStore] = useState('');
  const [clientName, setClientName] = useState('');
  const [lifeEvents, setLifeEvents] = useState({});
  const [preferences, setPreferences] = useState({});
  const [addresses, setAddresses] = useState({});
  const [smsStoreNum, setSmsStoreNum] = useState([]);
  const isMounted = useRef(null);
  const [searchList, setSearchList] = useState([]);
  const [searchResult, setSearchResult] = useState(false);
  const [customize, setCustomize] = useState(false);
  const [client, setClient] = useState({});
  const [mergeForSure, setMergeForSure] = useState(false);

  const history = useHistory();

  const confirmDelete = () => {
    const reqObj = {
      params: ['clients', merchantId, messagingClient.client_id, userId, 'deleteClient'],
    };
    (async () => {
      const resp = await deleteRequest(reqObj);
      if (resp) {
        notificationSuccess('Client Removed');
        clearSelectedClient();
        history.push('/Messaging/inbox');
      }
    })();
  };

  const getClientData = () => {
    if (merchantId) {
      (async () => {
        const reqObj = {
          params: ['clients', merchantId, 0, 'clientPreferences'],
        };
        const prefData = await getRequest(reqObj);
        if (prefData.length > 0) {
          const dataObj = prefData[0];
          const { preferenceData, lifeEventData } = dataObj;
          const prefObj = {};
          preferenceData.forEach(prf => {
            prefObj[prf.config_id] = prf;
          });
          const leObj = {};
          lifeEventData.forEach(le => {
            let date = '';

            if (le.date) {
              const d = moment(le.date.padStart(8, '2020'), 'YYYYMMDD');
              if (d.isValid()) date = le.date;
            }

            leObj[le.type] = {
              ...le,
              date,
              life_event_type_id: le.life_event_type_id ? le.life_event_type_id : '',
            };
          });

          const newClientAddress = [
            {
              address_1: '',
              address_2: '',
              city: '',
              client_id: null,
              id: null,
              postal_code: '',
              state: '',
              type: '',
            },
          ];
          const addObj = {};
          newClientAddress.forEach(addr => {
            addObj[uuidv4()] = addr;
          });
          setLifeEvents(leObj);
          setPreferences(prefObj);
          setAddresses(addObj);
          setSmsStoreNum(stores.map(st => st.sms_number).filter(store => store !== undefined));
        } else {
          console.log('error getting client data');
        }
      })();
    }
  };

  useEffect(() => {
    (async () => {
      const reqObj = {
        params: ['stores'],
        query: {},
      };
      const data = await getRequest(reqObj);
      setStores(data);
      getClientData();
    })();
  }, [merchantId]);

  const formValidation = obj => {
    const { name, store_id } = obj;
    let valid = true;
    if (name.length < 2) {
      notificationError('Please Enter a Valid Name');
      valid = false;
    }
    if (!store_id) {
      notificationError('Please Select a Store');
      valid = false;
    }
    return valid;
  };

  const smsMobile = phone => {
    if (!phone) {
      return true;
    }
    if (phone !== undefined) {
      const smsCompare = smsStoreNum.filter(number => {
        if (number.replace(/[^0-9]+/g, '') === phone.replace(/[^0-9]+/g, '')) {
          return true;
        }
        return false;
      });
      if (smsCompare.length > 0) {
        notificationError('Client number cannot match store number!');
      } else return true;
    }
  };

  const searchAllClient = searchText => {
    isMounted.current = true;
    (async () => {
      const associateStores = auth.stores.filter(store => store.id !== 0).map(store => store.id);
      const reqObj = {
        params: ['chats', 'clientSearch'],
        query: { searchText, associateStores },
      };
      const clientSearchResults = await getRequest(reqObj);
      if (clientSearchResults.length) {
        setSearchList(clientSearchResults);
      } else {
        setCustomize(true);
      }
      setSearchResult(true);
    })();
    return () => {
      isMounted.current = false;
    };
  };

  const handleSearchChange = e => {
    if (e.key === 'Enter' && e.target.value) {
      // setMsgClientChatMessages([]);
      searchAllClient(e.target.value);
      setSearchResult(false);
    }
    if (e.target.value.length < 2 && e.key === 'Backspace') {
      setSearchResult(false);
    }
  };

  const customizeRenderEmpty = () => (
    <div id="customizedNoData" style={{ textAlign: 'center', paddingTop: '57px' }}>
      <p>No clients match your search</p>
    </div>
  );

  const confirmMerge = () => {
    const srcClientId = messagingClient.client_id;
    const mergeClientId = client?.client_id;
    const reqObj = {
      params: ['clients', userId, srcClientId, mergeClientId, 'mergeClient'],
    };
    (async () => {
      const resp = await postRequest(reqObj);
      if (resp && resp.returnObj) {
        const { chat_id, client_id } = resp.returnObj;
        notificationSuccess('Client Merged');
        setMergeForSure(false);
        getClientChat({ chat_id, client_id });
        mergeSelectedClient();
      }
      if (resp === 'OK') {
        setMsgClient({ ...messagingClient, chat_id: null, name: client.name });
        setMergeForSure(false);
      }
    })();
  };

  const saveClient = () => {
    const { client_id } = messagingClient;
    const storeName = stores.find(item => item.id === auth.storeId).name;
    // const store_name = storeName;

    const dataObj = {
      associateId: userId,
      client_id,
      name: clientName.trim(),
      mobile: messagingMobile,
      email: null,
      doNotContact: false,
      contactPref: 'SMS',
      preferred_name: '',
      store_id: auth.storeId,
      store_name: storeName,
      preferences: Object.values(preferences).filter(pref => pref.updated),
      lifeEvents: Object.values(lifeEvents),
      addresses: Object.values(addresses),
    };
    if (formValidation(dataObj) && smsMobile(messagingMobile)) {
      (async () => {
        const fn = first_name;
        const ln = last_name;
        const reqObj = {
          params: ['clients', merchantId, 'save'],
          data: dataObj,
          query: { createdBy: `${fn || ''} ${ln || ''}`.trim() },
        };

        const data = await postRequest(reqObj);
        if (data && data.id) {
          const newStoreInboxList = storeInboxList.map(listItem => {
            if (listItem.id === messagingClient.chat_id) {
              return {
                ...listItem,
                name: clientName,
                client: {
                  ...listItem.client,
                  name: clientName,
                },
              };
            }

            return listItem;
          });

          const newInboxList = inboxList.map(listItem => {
            if (listItem.id === messagingClient.chat_id) {
              return {
                ...listItem,
                name: clientName,
                client: {
                  ...listItem.client,
                  name: clientName,
                },
              };
            }

            return listItem;
          });

          notificationSuccess('Client Saved!');
          setOpenNew(false);
          setMsgClient({ ...messagingClient, name: clientName });
          setStoreInboxList(newStoreInboxList);
          console.log('newStoreInboxList', newStoreInboxList);
          setInboxList(newInboxList);
          console.log('newInboxList', newInboxList);
        }
      })();
    }
  };

  return (
    <div>
      <div className="opted-out-banner flex-row-center-center">
        <p>We don&apos;t recognize this number</p>
      </div>
      <div className="unknown-number-banner flex-row-spacearound-nowrap">
        <button
          type="button"
          className="transparentButton border-none transparent"
          onClick={() => setOpenNew(!openNew)}
        >
          New Client
        </button>
        <button
          type="button"
          className="transparentButton border-none transparent"
          onClick={() => setOpenMerge(!openMerge)}
        >
          Merge
        </button>
        <button type="button" onClick={() => setOpenDelete(!openDelete)} className="red border-none transparent fw-600">
          Delete
        </button>
      </div>
      {openDelete ? (
        <PopConfirm
          confirm="Are you sure you wish to delete this client?"
          description="This action cannot be undone"
          onCancel={() => {
            setOpenDelete(false);
          }}
          onConfirm={confirmDelete}
        />
      ) : null}
      {openMerge ? (
        <PopConfirmLarge
          largeOnClick={() => setOpenMerge(false)}
          confirm="Clients (searching)"
          style={{ width: '600px', height: '400px' }}
          description={
            <div className="pos-rel flex-col-left" id="client-search" style={{ width: '600px', height: '300px' }}>
              <input
                onKeyDown={handleSearchChange}
                className="clientSearchInbox h-45"
                id="client-search-input"
                placeholder="Client Search..."
                style={{ width: '50%', marginLeft: '37px' }}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
              {searchResult ? (
                <div
                  className="pos-abs w-295 bg-white"
                  id="client-search-list-wrapper"
                  style={{ top: '50px', left: '50px' }}
                >
                  <ConfigProvider renderEmpty={customize ? customizeRenderEmpty : undefined}>
                    <List
                      size="small"
                      bordered
                      dataSource={searchList}
                      id="client-search-list"
                      renderItem={item => (
                        <List.Item
                          onClick={() => {
                            setClient(item);
                            setMergeForSure(true);
                            setOpenMerge(false);
                          }}
                        >
                          {item.name}
                        </List.Item>
                      )}
                    />
                  </ConfigProvider>
                </div>
              ) : null}
            </div>
          }
          onCancel={() => {
            setOpenDelete(false);
          }}
          onConfirm={confirmDelete}
        />
      ) : null}
      {mergeForSure ? (
        <PopConfirm
          confirm={`Do you want to merge this conversation with ${client.name} and update their mobile number to ${messagingClient.name}?`}
          description="This action cannot be undone"
          onCancel={() => {
            setMergeForSure(false);
            setClient({});
          }}
          onConfirm={confirmMerge}
          confirmButtonText="Yes"
        />
      ) : null}
      {openNew ? (
        <PopConfirm
          confirm="Create a new client"
          confirmButtonText="Save"
          customContent="w-100-P"
          description={
            <div className="flex-row-spacebetween-wrap">
              {/* <DropdownSelect
                classProps="w-100-P align-left"
                options={stores
                  .filter(st => `${st.id}` !== '0')
                  .map(store => {
                    return {
                      label: `Preferred Store: ${store.name}`,
                      value: store.id,
                    };
                  })}
                disabled={role === 'ASSOCIATE'}
                placeholder="Preferred Store:"
                value={Number(clientStore)}
                onChange={obj => {
                  setClientStore(obj.value);
                  setStoreName(obj.label.substring(16).trim());
                }}
              /> */}
              <div className="w-100-P preferredNameContainer">
                <input
                  className="h-50 rem-pad-width m-top-15 mq-50-P-to-100-P-on-535 input-image-style client_name_field focus-input-outline-blue"
                  style={{ width: '100%' }}
                  placeholder="Name"
                  value={clientName}
                  onChange={e => setClientName(e.target.value)}
                />
              </div>
            </div>
          }
          onCancel={() => {
            setOpenNew(false);
          }}
          onConfirm={saveClient}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NoNumberBanner);
