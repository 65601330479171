/* eslint-disable react/no-unknown-property */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import DropdownSelect from '../../../../../../../Layout/DropdownSelect';
import { postRequest } from '../../../../../../../../core/apiRequests';
import NumberInput from '../../../../../../../Layout/NumberInput';
import { notificationError } from '../../../../../../../../core/actions';

const EditOppModalWithNotes = props => {
  const { salesOpp, setSalesOpp, stageList } = props;
  const [opp, setOpp] = useState(salesOpp);
  const [expectedCloseDate, setExpectedCloseDate] = useState(
    new Date(moment(opp.close_date, 'YYYYMMDD').format('YYYY-MM-DDTHH:mm:ssZ')),
  );
  const [notesText, setNotesText] = useState(props.salesOpp.notes);

  const onSaveHandler = () => {
    const new_close_date = moment(expectedCloseDate).format('YYYYMMDD');
    setOpp({ ...opp, close_date: new_close_date });
    const { name, amount, close_date, sales_opp_stage_id, id } = opp;
    if (props.merchantId && expectedCloseDate) {
      setSalesOpp(opp);
      // here
      const bodyData = {
        client_id: props.client_id,
        associateId: props.associateId,
        store_id: props.store_id,
        pipelineName: name,
        priceValue: amount,
        expectedCloseDate: moment(expectedCloseDate).format('YYYYMMDD'),
        merchantId: props.merchantId,
        firstName: props.firstName,
        lastName: props.lastName,
        sales_opp_stage_id,
        sales_opp_id: id,
        notes: notesText,
      };
      const reqObj = {
        params: ['clients', props.merchantId, opp.id, 'saveAndUpdateSalesOpp'],
        query: { createdBy: 1 },
        data: bodyData,
        delay: 2500,
      };

      (async () => {
        await postRequest(reqObj);
        const newStageName = stageList.filter(stage => stage.id === sales_opp_stage_id);
        props.setSalesOpp({
          ...salesOpp,
          ...opp,
          stage_name: newStageName[0].name,
          close_date,
        });
        props.showEditOppModalHandler();

        if (typeof props.onSuccess === 'function') {
          props.onSuccess({
            ...salesOpp,
            ...opp,
            stage_name: newStageName[0].name,
            close_date,
          });
        }
      })();
    } else if (!expectedCloseDate) {
      notificationError('Please set a close date');
    }
  };

  return (
    <div
      className="pop-confirm-background"
      id="modal-background"
      // onClick={backgroundCloseHandler}
    >
      <div
        className="pop-confirm-container rem-pad-width"
        style={{ width: '404px', height: '726px', overflowY: 'hidden' }}
      >
        <div
          className="flex-col-center h-100-P"
          style={{
            justifyContent: 'flex-start',
            width: '295px',
            margin: '0 auto',
          }}
        >
          <div className="flex-row-spacebetween-nowrap w-100-P m-top-15" />
          <span className="fs-18 fw-500" style={{ marginTop: '15px', marginBottom: '34px' }}>
            Edit Sales Opportunities
          </span>

          <input
            value={opp.name}
            onChange={e => setOpp({ ...opp, name: e.target.value })}
            classprops="w-295 p-top-15"
            style={{
              width: '295px',
              height: '50px',
              borderRadius: '3px',
              border: '1px solid #818181',
              boxSizing: 'border-box',
              paddingLeft: '16px',
            }}
            placeholder="Choose an opportunity..."
          />
          <div className="w-100-P m-top-15">
            <NumberInput
              decorator="$"
              min="0"
              step={1}
              precision={1}
              placeholder="1000"
              onChange={e => {
                if (e?.target) {
                  const cleanedNumber = e.target.value.replace(/\D/g, '');
                  setOpp({ ...opp, amount: cleanedNumber });
                } else {
                  const cleanedNumber = e.toString().replace(/\D/g, '');
                  setOpp({ ...opp, amount: cleanedNumber });
                }
              }}
              value={opp.amount}
            />
          </div>
          <div style={{ marginBottom: '12px', marginTop: '20px' }}>
            <p
              style={{
                fontWeight: 500,
                fontSize: '14px',
                margin: '0',
                textAlign: 'left',
                width: '100%',
              }}
            >
              Expected Close Date
            </p>
            <ReactDatePicker
              selected={expectedCloseDate}
              onChange={date => {
                setExpectedCloseDate(date);
              }}
              // dateFormat="yyyy/MM/dd"
              // openToDate={opp.close_date}
              customInput={
                <input
                  style={{
                    width: '295px',
                    height: '50px',
                    borderRadius: '3px',
                    border: 'solid 1px #818181',
                    paddingLeft: '16px',
                    marginTop: '13px',
                    boxSizing: 'border-box',
                    fontSize: '14px',
                  }}
                />
              }
            />
          </div>

          <div style={{ marginBottom: '2px' }}>
            <p
              style={{
                fontWeight: 500,
                fontSize: '14px',
                margin: '0',
                textAlign: 'left',
                width: '100%',
                marginTop: '9px',
              }}
            >
              Stage
            </p>

            <DropdownSelect
              classProps="w-295 m-top-13"
              placeholder="Choose an opportunity..."
              value={opp.sales_opp_stage_id}
              options={stageList.map(stage => ({
                value: stage.id,
                label: stage.name,
              }))}
              onChange={val => setOpp({ ...opp, sales_opp_stage_id: val.value })}
            />
          </div>
          <div
            className="flex-col-center"
            style={{
              justifyContent: 'flex-start',
              width: '295px',
              margin: '0 auto',
            }}
          >
            <p
              style={{
                fontWeight: 500,
                fontSize: '14px',
                margin: '9px 0px 0px',
                textAlign: 'left',
                width: '100%',
              }}
            >
              Opportunity Notes
            </p>
            <textarea
              placeholder="Opportunity notes..."
              style={{
                width: '295px',
                height: '130px',
                border: 'solid 1px #818181',
                borderRadius: '3px',
                marginTop: '13px',
                resize: 'none',
                boxSizing: 'border-box',
                padding: '12px',
              }}
              value={notesText}
              onChange={e => setNotesText(e.target.value)}
              maxLength={10000}
            />
          </div>
          <div className="mq-w-100-vw custom-action-save-flex" style={{ marginTop: '30px' }}>
            <button className="save-button-v2 m-btm-8" onClick={onSaveHandler}>
              Save
            </button>
            <button className="cancel-button-v2" onClick={props.showEditOppModalHandler}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOppModalWithNotes;
