const clientList = [
  'Client Name',
  'Email',
  'Mobile',
  'Birthday',
  'Anniversary',
  'Point of Sale ID',
  'Address',
  'City',
  'Postal Code',
  'State',
  'Associate',
  'Tags',
  'Opted Out',
  'Preferences',
];
const chatList = ['Last 50 messages per Client for the last year', 'Client', 'Associate assigned'];

const salesList = [
  'Get all open Sales Opportunities',
  'Sales Opportunities Description',
  'Amount',
  'Date Created',
  'Expected Close Date',
  'Create By',
  'Client',
];

export { clientList, chatList, salesList };
