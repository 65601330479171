import React from 'react';
import { formatDate } from '../utils';
import { DATE_FORMAT } from '../const';

const PaymentRefund = ({ associateName: name, totalAmount: amount, reason, status, returnedAt, transactions }) => {
  if (status === 'success') {
    return (
      <div className="m-btm-10">
        <div className="flex-row-spacebetween-nowrap fw-600 fs-13 missing-data-red w-282">
          <p className="m-btm-0 m-top-0">Refunded</p>
          <p className="align-right m-btm-0 m-top-0">{`$${amount}`}</p>
        </div>
        <div className="fs-12 fw-400 align-left">
          <p className="m-top-0 fs-400 m-btm-0">{formatDate(returnedAt, DATE_FORMAT.LONG_DATE_TIME)}</p>
          <p className="m-top-0 fs-400 m-btm-0 break-word">{`Paid with ${transactions[0].cardType}-${transactions[0].cardLastFour}`}</p>
          <p className="m-top-0 fs-400 m-btm-0 break-word">{`${name} noted: "${reason}"`}</p>
        </div>
      </div>
    );
  }
  return null;
};

export default PaymentRefund;
