import React, { useState, useEffect } from 'react';
import Button from '../../../../Layout/Button';
import PopConfirm from '../../../../Layout/PopConfirm';
import StyledLink from '../../../../Layout/StyledLink';
import StandardLayout from '../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveListItem, deleteListItem, getSingleDetails } from '../../../../../core/apiRequests';
import { notificationSuccess, notificationError } from '../../../../../core/actions';
import { setCliPrefId, setCliPrefAddEdit, setCliPrefName } from '../../../../../reducers/clientPreference';
import { associateNameFormat } from '../../../../../utils';

const AddEditPreference = props => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (props.addOrEdit === 'Add' && props.preferenceId && window.location.href.includes('add-preference')) {
      if (props.preferenceId) {
        setCliPrefId('');
        setCliPrefName('');
      }
    } else if (window.location.href.includes('edit-preference') && !props.preferenceId) {
      setCliPrefAddEdit('Edit');
      if (props.merchantId) {
        (async () => {
          const data = await getSingleDetails('personalizations', props.merchantId, props.match.params.preferenceId);
          if (data && data.length > 0) {
            const { id, name } = data[0];
            setCliPrefId(id);
            setCliPrefName(name);
          } else {
            history.push(`/Configuration/client-preferences`);
          }
        })();
      }
      // query pref info
    }
  }, [history, props.addOrEdit, props.match.params.preferenceId, props.merchantId, props.preferenceId]);

  const confirmDelete = () => {
    (async () => {
      const data = await deleteListItem('personalizations', props.merchantId, props.preferenceId);
      if (data) {
        notificationSuccess('Preference Removed');
        history.push(`/Configuration/client-preferences`);
      }
    })();
  };

  const savePref = () => {
    const { preferenceName, preferenceId, firstName, lastName } = props;
    if (!preferenceName) {
      // TODO: a demo on error handling and edge cases
      notificationError('Preference Name Required');
    } else {
      (async () => {
        const dataObj = {
          name: preferenceName,
          createdBy: associateNameFormat(firstName, lastName),
        };
        if (preferenceId) {
          dataObj.id = preferenceId;
        }
        const data = await saveListItem('personalizations', props.merchantId, dataObj);
        if (data.id) {
          if (preferenceId) {
            notificationSuccess('Preference Updated');
          } else {
            notificationSuccess('Preference Created');
            history.push(`/Configuration/client-preferences`);
          }
          history.push(`/Configuration/client-preferences`);
          setCliPrefId(data.id);
          setCliPrefAddEdit('Edit');
        } else {
          // error handling?
        }
      })();
    }
  };

  return (
    <StandardLayout
      title={`${props.addOrEdit} Preference`}
      description={
        props.addOrEdit === 'Add' ? 'Add a preference to gather client information' : 'Edit custom preference'
      }
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        {
          title: 'Client Preferences',
          to: '/Configuration/client-preferences',
        },
        { title: `${props.addOrEdit} Preference` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <div className="max-w-600">
        <div className="align-left w-100-P">
          <input
            id="PreferenceNameInput"
            className={`m-btm-15 m-top-15 fs-13 outline-none common_inputStyle left-0 input-height w-100-P rem-pad-width`}
            value={props.preferenceName}
            onChange={e => setCliPrefName(e.target.value)}
            placeholder="Preference Name"
            //   style={{width: '100%'}}
          />
        </div>
        {/* here */}
        <div className="m-top-37">
          <div className="mq-w-100-vw flex-col-center">
            <button id="AddEditPreferenceSaveButton" className="save-button-v2 m-btm-8" onClick={savePref}>
              Save
            </button>
             {props.addOrEdit === 'Edit' ? (
            <Button
              id="DeletePreferenceButton"
              classname="deleteButton-v2 "
              style={{ marginBottom: '8px' }}
              onclick={() => setShowPopConfirm(true)}
            >
              Delete
            </Button>
          ) : (
            <div />
          )}
            <StyledLink to="/Configuration/client-preferences">
              <button id="AddEditPreferenceCancelButton" className="cancel-button-v2">
                Cancel
              </button>
            </StyledLink>
          </div>
        </div>
        {/* end here */}
      </div>
      {showPopConfirm ? (
        <PopConfirm
          confirm={`Are you sure you want to remove "${props.preferenceName}" preference?`}
          description={'This will permanently delete this preference'}
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={confirmDelete}
        />
      ) : null}
      <SecondaryPanel title={['SETTINGS', 'HELP', 'PROFILE']} current="Client Preferences" />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.clientPreference,
  ...state.auth,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AddEditPreference);
