import React from 'react';
import Tag from '../Tag';

const TagsGroup = ({ items, closable, onClose, group = 'default' }) => {
  const handleClose = value => {
    onClose({ group, value });
  };

  return (
    <>
      {items.map(item => (
        <Tag key={item} item={item} closable={closable} onClose={() => handleClose(item)} />
      ))}
    </>
  );
};

export default TagsGroup;
