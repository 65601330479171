import React from 'react';
import { Input as AntDesignInput } from 'antd';
import './styles.css';

const Input = props => {
  const { className, ...propsWithoutClassName } = props;

  return <AntDesignInput {...propsWithoutClassName} className={['cb-input', className || '']} />;
};

export default Input;
