import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Button, message } from 'antd';
import { API_URL } from '../../../../../../constants';
import PaymentRequestCard from './components/PaymentRequestCard';
import './styles.css';

const PaymentRequest = props => {
  const { setViewAllType, showMessageHandler } = props;

  const [loading, setLoading] = useState(false);
  const [paymentRequests, setPaymentRequests] = useState([]);

  const fetchPaymentRequests = useCallback(async () => {
    if(props.client.client_id) {
      setLoading(true);

      try {
        const response = await axios.get(`${API_URL}/xa/clients/${props.client.client_id}/payment-requests`, {
          params: {
            page: 1,
            pageSize: 2,
          },
        });

        setPaymentRequests(response.data.results);
      } catch (error) {
        message.error('Error Fetching Payment Requests');
      } finally {
        setLoading(false);
      }
    }
  }, [props.client.client_id]);

  useEffect(() => {
    fetchPaymentRequests();
  }, [props.client.client_id]);

  return (
    <div className="cb-payment-request">
      <div className="cb-payment-request--header">
        <h2 className="cb-payment-request--header-title">Payment Request</h2>

        {Boolean(paymentRequests.length) && (
          <Button
            type="text"
            onClick={() => {
              setViewAllType('allPaymentRequests');
            }}
            className="cb-payment-request--view-all-button"
          >
            VIEW ALL
          </Button>
        )}
      </div>

      <div className="cb-payment-request--body">
        {loading && <div className="cb-payment-request--body-helper">Loading...</div>}

        {!loading && !paymentRequests.length && (
          <>
            <div className="cb-payment-request--body-helper">No payment history available</div>

            <Button
              type="text"
              onClick={() => {
                setViewAllType('allPaymentRequests');
              }}
              className="cb-payment-request--view-all-button-empty"
            >
              VIEW ALL
            </Button>
          </>
        )}

        {!loading &&
          Boolean(paymentRequests.length) &&
          paymentRequests.map(item => (
            <PaymentRequestCard key={item.id} paymentRequest={item} onClick={showMessageHandler} />
          ))}
      </div>
    </div>
  );
};
export default PaymentRequest;
