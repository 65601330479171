import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Checkbox } from 'antd';
import truncateText from '../../../../../../../utils/truncateText';
import NotFoundImage from './not-found.png';
import './styles.css';

const ListItem = ({ children, clickable, reverseHoverColor }) => (
  <div
    className={`cb-products-list-table-item cb-products-list-table-item-padding-and-border-radius pos-rel ${
      clickable ? 'clickable' : ''
    } ${reverseHoverColor ? 'reverse-hover-color' : ''}`}
  >
    {children}
  </div>
);

const ProductsListTableItem = ({ item, disabled, selected, toggleSelection, onProductDetailClick }) => {
  const handleSelect = useCallback(() => toggleSelection(item.id), [item.id, toggleSelection]);
  const productInStock = (typeof item.qty === 'number' && item.qty > 0) || item.qty === null;

  const body = (
    <>
      <div>
        <Avatar
          shape="square"
          size={52}
          src={item.image || item.image_url || NotFoundImage}
          onError={NotFoundImage}
          className="cb-products-list-table-item__image"
        />
        {productInStock || item.available ? null : (
          <div
            className="cb-product-details-list__out-of-stock align-center"
            style={{ width: '68px', height: '22px', backgroundColor: '#FFD9D2', color: '#FC5025' }}
          >
            <span className="fw-600 fs-10">Out of Stock</span>
          </div>
        )}
      </div>
      <div className="cb-products-list-table-item__content">
        <h4 className="cb-products-list-table-item__title">{truncateText(item.name, 80)}</h4>
        <p className="cb-products-list-table-item__description">{item.sku}</p>
      </div>
    </>
  );
  if (toggleSelection)
    return (
      <ListItem reverseHoverColor>
        <div
          style={{
            flex: 1,
          }}
          className="cb-products-list-table-item reverse-hover-color"
        >
          <Checkbox
            disabled={disabled}
            checked={selected}
            onChange={handleSelect}
            className="cb-products-list-table-item__checkbox"
          />
          {body}
        </div>
        <p
          style={{ cursor: 'pointer' }}
          onClick={() => onProductDetailClick(item)}
          className="blue-link hide-unless-hovered"
        >
          View Details
        </p>
      </ListItem>
    );
  return (
    <Link to={`/products/${item.id}`}>
      <ListItem clickable>{body}</ListItem>
    </Link>
  );
};

export default ProductsListTableItem;
