import React, { useEffect, useState } from 'react';
import RadioGroup from '../../../../CustomizedAntDesign/RadioGroup';

const OPTIONS = [
  {
    value: 'in_stock',
    label: 'In Stock',
  },
  {
    value: 'all_products',
    label: 'All Products',
  },
];

export const FILTERS = {
  in_stock: {
    qty: {
      $notNull: true,
      $gt: 0,
    }
  }
};

const StockFilters = ({ value, onChange, white }) => {
  const [filters, setFilters] = useState(value);
  const [option, setOption] = useState('in_stock');

  const handleChangeFilters = newValue => {
    const newFilters = FILTERS[newValue];

    if (newFilters) {
      setFilters({ ...newFilters });
    } else {
      setFilters({});
    }

    setOption(newValue);
  };

  useEffect(() => {
    onChange(filters);
  }, [filters]);

  return (
    <RadioGroup
      white={white}
      options={OPTIONS}
      value={option}
      defaultValue="all_products"
      onChange={handleChangeFilters}
    />
  );
};

export default StockFilters;
