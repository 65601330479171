import moment from "moment";

export const filterList = ({ massMessages = [], filters, startDate, endDate }) => {

  return massMessages?.filter(massMessage => {
    let filterStore = filters.store.length === 0 || filters.store.includes(massMessage.activity_store_id.toString());
    let filterAssociate = filters.associate.length === 0 || filters.associate.includes(massMessage.activity_associate_id.toString());
    let filterTimeSpan = moment(massMessage.activity_date).isBetween(moment(startDate), moment(endDate));
    return filterStore && filterAssociate && filterTimeSpan;
  });
};

export const numberOfSalesClients = (sales, msgBatchId) => {
  let clientDedupe = {};

  sales.forEach(sale => {
    if((msgBatchId && (sale.message_batch_id === msgBatchId)) || !msgBatchId) {
      if(clientDedupe[sale.client_id]) {
        clientDedupe[sale.client_id] += 1;
      } else {
        clientDedupe[sale.client_id] = 1;
      }
    }
  });

  return Object.keys(clientDedupe).length
}

export const filterMassMsgMeta = (massMessages, {startDate, endDate}, associates = []) => {
  return massMessages?.filter(massMessage => {
    let filterAssociate = associates.length === 0 || associates.includes(massMessage?.associate_id?.toString());
    let filterTimeSpan = moment(new Date(massMessage.scheduled_date_time)).isBetween(moment(startDate), moment(endDate));
    return filterAssociate && filterTimeSpan;
  });
};
