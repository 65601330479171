import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Card } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopConfirm from '../../PopConfirm';
import { notificationSuccess, notificationError } from '../../../../core/actions';
import { deleteRequest } from '../../../../core/apiRequests';
import { getClientChat } from '../../../../reducers/messaging';
import LargeProduct from '../../LargeProduct';
import { getProductById } from '../../../../api/products';
import { handleCatchError } from '../../../Routes/ProductDetails/Layout/shared/catchError';

const ProductImage = ({ o, i, m, t }) => {
  const [onHoverImg, setOnHoverImg] = useState('');
  const [chatMessageObj, setChatMessageObj] = useState({});
  const [deleteImgPopup, setDeleteImgPopup] = useState(false);
  const [openLrgProduct, setOpenLrgProduct] = useState(false);
  const isMounted = useRef(null);
  const [product, setProduct] = useState(null);

  const updateChat = (clientId, chatId) => {
    getClientChat({ clientId, chatId });
  };

  const deleteMessage = msg => {
    isMounted.current = true;
    const reqObj = {
      params: ['chatMessages', m, 'delete'],
      query: { chatMsgId: msg.id, chatMsgText: msg.message, chatId: msg.chatId },
    };
    (async () => {
      const respData = await deleteRequest(reqObj);
      if (respData) {
        notificationSuccess('Message Successfully Deleted!');
        updateChat(msg.clientId, msg.chatId);
      } else {
        notificationError('Failed To Delete Message');
      }
      setDeleteImgPopup(false);
    })();
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleGetProduct = useCallback(async () => {
    try {
      const { data } = await getProductById(o.productId);
      setProduct(data);
      setOpenLrgProduct(true);
    } catch (error) {
      handleCatchError(error);
    }
  }, [o.productId]);

  const stopEventBubbling = (target, messageObj) => {
    if (messageObj.productType !== 'VENDOR') {
      handleGetProduct();
      setChatMessageObj(messageObj);
    }
  };
  return (
    <div
      className="flex-row-nospacebetween-nowrap"
      style={{ justifyContent: 'flex-end', marginBottom: '3px' }}
      key={`sentImage${i}`}
    >
      <div
        className="border-r4 pos-rel pointer align-right m-right-8"
        style={{ zIndex: '5' }}
        onMouseEnter={() => setOnHoverImg(o.id)}
        onMouseLeave={() => setOnHoverImg('')}
        onClick={e => stopEventBubbling(e.target, o)}
        onKeyDown={e => stopEventBubbling(e.target, o)}
        role="button"
        tabIndex={0}
      >
        <div
          className=" pos-abs w-100-P m-top-10"
          style={{ zIndex: '35', justifyContent: 'flex-end' }}
          id={onHoverImg === o.id ? 'imageToolRow' : 'imageToolRowHidden'}
        >
          <div
            className="w-33 h-33 bg-offwhite m-right-8 border-r4 pointer"
            onClick={() => {
              setDeleteImgPopup(true);
              setChatMessageObj(o);
            }}
            onKeyDown={() => {
              setDeleteImgPopup(true);
              setChatMessageObj(o);
            }}
            role="button"
            tabIndex={0}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <FontAwesomeIcon icon={['far', 'trash']} size="1x" className="fs-16 pointer" />
          </div>
          <div
            className="w-33 h-33 bg-offwhite m-right-8 border-r4 pointer"
            onClick={() => window.open(o.productImage)}
            onKeyDown={() => window.open(o.productImage)}
            role="button"
            tabIndex={0}
            onMouseEnter={e => {
              e.target.style.backgroundColor = '#e8f6ff';
            }}
            onMouseLeave={e => {
              e.target.style.backgroundColor = '#f4f4f4';
            }}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <FontAwesomeIcon icon={['far', 'arrow-to-bottom']} size="1x" className="fs-16 pointer" />
          </div>
        </div>
        <Card
          style={{ width: 200, borderRadius: '5%' }}
          bodyStyle={{
            backgroundColor: '#F2F3F6',
            textAlign: 'left',
            padding: '8px 11px',
            lineHeight: '14.4px',
            fontWeight: 500,
          }}
          cover={
            <img
              onError={e => {
                e.target.onerror = null;
                e.target.src = 'https://dashboard-v2-images.s3.amazonaws.com/image-placeholder@2x.png';
              }}
              id={onHoverImg === o.id ? 'onHoverImg' : ''}
              style={{ borderRadius: '5% 5% 0 0' }}
              className="border-r4 photoFit"
              src={o.productImage}
              alt="#"
            />
          }
        >
          <div className="flex-col-left-center">
            {`${o.productName} | ${o.productId}`}
            <span className="w-100-P text-light-gray fs-10 italic align-right m-top-4">
              {moment(o.sentTimestamp).format('hh:mm a')}
            </span>
          </div>
        </Card>
      </div>
      {deleteImgPopup ? (
        <PopConfirm
          customContent={false}
          confirm="Are you sure you want to delete this message?"
          onCancel={() => setDeleteImgPopup(false)}
          onConfirm={() => deleteMessage(chatMessageObj)}
          noButtonDisable
        />
      ) : null}
      {openLrgProduct ? (
        <LargeProduct
          popConfirmLargeDivStyle={{ zIndex: '40' }}
          setLargeImagePopup={setOpenLrgProduct}
          productObj={chatMessageObj}
          product={product}
          t={t}
        />
      ) : null}
    </div>
  );
};

export default ProductImage;
