/**
 * Helper to convert search params object to a string for the BE
 * @param {object} obj - key-value pairs, for getParams (values can be arrays)
 * @returns {string} search params
 */
export const getSearchParams = obj => {
  const pairsList = Object.entries({
    ...obj,
  }).reduce((arr, [key, value]) => {
    const listForKey = Array.isArray(value)
      ? value.map(val => ({
          key: `${key}[]`,
          value: val,
        }))
      : [{ key, value }];

    return [...arr, ...listForKey];
  }, []);

  const cleanStrings = pairsList.reduce((arr, { key, value }) => {
    const pair = value ? [`${key}=${value}`] : [];
    return [...arr, ...pair];
  }, []);

  const searchParams = cleanStrings.length ? `?${cleanStrings.join('&')}` : '';

  return searchParams;
};
