import React, { useState, useEffect } from 'react';
import { notificationError } from '../../../../../core/actions';
import { getRequest } from '../../../../../core/apiRequests';
import PaymentBannerSpacer from '../../../../Layout/PaymentBannerSpacer';
import PopConfirm from '../../../../Layout/PopConfirm';
import addClientsHeaderOptions from '../importDropDownOptions/addClientsHeaderOptions.json';
import addClientTagsHeaderOptions from '../importDropDownOptions/addClientTagsHeaderOptions.json';
import addProductsHeaderOptions from '../importDropDownOptions/addProductsHeaderOptions.json';
import MatchTable from './MatchTable';

const Match = props => {
  const [data, setData] = useState([]);
  const [dropdownMenu, setDropdownMenu] = useState([]);
  const [allPrefs, setAllPrefs] = useState([]);
  const [newPrefsArr, setNewPrefsArr] = useState([]);
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const tableHeaders = [
    '',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  useEffect(() => {
    if (props.endPoint === 'clients') {
      (async () => {
        const reqObj = {
          params: ['personalizations', props.merchantId, 'getAllPrefs'],
        };
        const prefData = await getRequest(reqObj);
        if (prefData && prefData.length > 0) {
          setAllPrefs(prefData);
        } else {
          // notificationError('Error Getting Config Data');
        }
      })();
    }
  }, [props.merchantId]);

  // Limits the tables to only header and 3 rows
  useEffect(() => {
    if (props.data.length > 3) {
      const tempData = props.data.slice(0, 3);
      setData([...tempData]);
    } else {
      setData([...props.data]);
    }
  }, [props.data]);

  // Determines which dropdown menu options you should have based off which upload button is clicked
  useEffect(() => {
    setDropdownMenu([]);
    if (props.endPoint === 'clients') {
      setDropdownMenu([...addClientsHeaderOptions]);
    } else if (props.endPoint === 'tags') {
      setDropdownMenu([...addClientTagsHeaderOptions]);
    } else if (props.endPoint === 'products') {
      setDropdownMenu([...addProductsHeaderOptions]);
    } else {
      console.log('no endpoint');
    }
  }, [props.endPoint]);

  // This determines when the next button should be disabled or not
  const disabled = () => {
    let badConfig = false;
    // badConfig = duplicateCheck(); // duplicate check
    const existingConfigNames = {};
    Object.values(props.columns).forEach(val => {
      if (!val.ignore) {
        if (val.newPref) {
          if (!val.displayName) {
            badConfig = true;
          }
          if (existingConfigNames.hasOwnProperty(`${val.displayName}`)) {
            badConfig = true;
          } else {
            existingConfigNames[val.displayName] = true;
          }
        } else {
          if (!val.selector) {
            // blank check
            badConfig = true;
          }
          if (val.selector) {
            if (existingConfigNames.hasOwnProperty(`${val.selector}`)) {
              badConfig = true;
            } else {
              existingConfigNames[val.selector] = true;
              existingConfigNames[val.displayName] = true;
            }
          }
        }
      }
    });
    return badConfig;
  };

  // filters out the already selected options so you can't duplicate column headers and adds "IGNORE" to the list always
  const getDropDownOptions = currentOption => {
    const selectedOptions = Object.values(props.columns)
      .filter(obj => obj.selector)
      .map(obj => obj.selector);
    const prefsList = allPrefs.map(pref => ({
      value: pref.name,
      label: pref.name,
      pref: true,
    }));
    const filteredMenu = [...dropdownMenu, ...prefsList].filter(
      option =>
        option.label === '<< IGNORE >>' ||
        option.label === '<< ADD NEW PREFERENCE >>' ||
        currentOption == option.value ||
        !selectedOptions.includes(option.value),
    );

    filteredMenu.push({
      label: '<< IGNORE >>',
      value: '<< IGNORE >>',
    });
    if (props.endPoint === 'clients') {
      filteredMenu.push({
        label: '<< ADD NEW PREFERENCE >>',
        value: '<< ADD NEW PREFERENCE >>',
      });
    }
    return filteredMenu;
  };

  // resets the headers so that things are all wonky if they accidentally set them or dont set them and hit back
  const onBackClick = () => {
    const tempCols = {};
    Object.keys(props.columns).map(element => {
      tempCols[element] = {
        selector: '',
        displayName: '',
        ignore: false,
      };
      props.setColumns(tempCols);
      props.setUploadModal('confirmHeaders');
    });
  };

  const nextButtonHandler = () => {
    if (props.endPoint === 'clients') {
      let newPrefsExist = false;
      const prefsArray = [];
      Object.values(props.columns).forEach(col => {
        if (col.newPref) {
          newPrefsExist = true;
          prefsArray.push(col.displayName);
        }
      });
      if (newPrefsExist && prefsArray.length > 0) {
        setNewPrefsArr(prefsArray);
        setShowPopConfirm(true);
      } else {
        props.setUploadModal('review');
      }
    } else {
      props.setUploadModal('review');
    }
  };

  return (
    <div className="pos-abs z-index-5 left-0 align-top w-100-vw h-100-vh bg-white p-42 rem-pad-width m-top-44 overflowY">
      <PaymentBannerSpacer />
      <div className="maw-965">
        <div className="flex-row-spacebetween-wrap w-100-P align-items-center">
          <div className="flex-col-left">
            <span className="align-left fs-18 fw-500 w-100-P">Upload Data</span>
            <span className="align-left fs-14 w-100-P">Bulk add {props.endPoint}</span>
          </div>
          <span className="layoutBreadcrumb">
            <span
              className="completed-bread-crumb"
              style={{
                padding: '3.5px 9.765px',
              }}
            >
              Upload
            </span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span className="current-bread-crumb">Match</span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span>Review</span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span>Done</span>
          </span>
        </div>
        <div className="header-container">
          <span className="align-left fs-16 w-100-P">Map all columns</span>
          <button
            className={disabled() ? 'import-cancel-button disabled-button' : 'import-positive-button'}
            style={{ marginTop: 0 }}
            onClick={() => nextButtonHandler()}
            disabled={disabled()}
          >
            NEXT
          </button>
          <button className="import-cancel-button" style={{ marginTop: 0 }} onClick={() => onBackClick()}>
            BACK
          </button>
        </div>

        <div className="w-100-P m-top-35">
          {Object.keys(props.columns).map((column, indexOfColumn) => {
            return (
              <MatchTable
                column={column}
                indexOfColumn={indexOfColumn}
                isLastColumn={Object.keys(props.columns).length - 1 === indexOfColumn}
                tableHeaders={tableHeaders}
                data={data}
                columns={props.columns}
                setColumns={props.setColumns}
                getDropDownOptions={getDropDownOptions}
                key={column + indexOfColumn}
                allPrefs={allPrefs}
              />
            );
          })}
        </div>
      </div>
      {showPopConfirm ? (
        <PopConfirm
          confirm="Are you sure you want to add new preference(s)?"
          description={newPrefsArr.map((pref, ind) => (
            <span>
              {pref}
              {newPrefsArr[ind + 1] ? ', ' : null}
            </span>
          ))}
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={() => props.setUploadModal('review')}
        />
      ) : null}
    </div>
  );
};

export default Match;
