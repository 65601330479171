import React from 'react';
import { string, bool, oneOfType, func, node, number } from 'prop-types';

import TitleDescription from '../../../Layout/DescriptionBox';

const Input = ({ title, placeholder, value, handleChange, onBlur, textarea, help, noMargin, className, id }) => {
  const El = textarea ? 'textarea' : 'input';
  const noMarginStyle = noMargin
    ? {
        marginBottom: 0,
      }
    : {};

  return (
    <TitleDescription
      title={title}
      className={className || ''}
      style={noMarginStyle}
      input={
        <>
          <El
            className={`common_inputStyle inputHeight black rem-pad-width w-402 maw-100-P m-btm-5 ${
              textarea ? 'p-top-12 p-btm-13' : ''
            }`}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={({ target }) => handleChange(target.value)}
            onBlur={onBlur}
          />
          {help}
        </>
      }
    />
  );
};

Input.propTypes = {
  title: string,
  placeholder: string,
  value: oneOfType([number, string]),
  onBlur: func,
  handleChange: func,
  textarea: bool,
  help: node,
  noMargin: bool,
};

export default Input;
