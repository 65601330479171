import React, { useEffect, useState } from 'react';
import { Radio, Space } from 'antd';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setTriggerReminderInterval } from '../../../../../../reducers/salesTriggers';
import CheckboxDesc from '../../../../../Layout/CheckboxDesc';
import DropdownSelect from '../../../../../Layout/DropdownSelect';
import NumberInput from '../../../../../Layout/NumberInput';
import KioskSlider from '../../../../../Layout/KioskSlider';

const intervalOptions = [
  { label: 'Days', value: 'DAY' },
  { label: 'Weeks', value: 'WEEK' },
  { label: 'Months', value: 'MONTH' },
];

const ActionDetails = props => {
  const radioChange = e => {
    props.setExpirationRadio(e.target.value);
  };

  useEffect(() => {
    if(props.actionScale === 0 && props.actionInterval === 'DAY'){
      props.setSalesTriggerImmediate(true);
    }
  }, [props.actionScale, props.actionInterval])

  return (
    <div
      className="flex-col-center pos-abs z-index-5 left-0 align-top w-100-vw h-100-vh bg-white p-60 rem-pad-width max-h-100vh overflowY"
      style={{ justifyContent: 'flex-start' }}
    >
      <div className="m-top-75 flex-col-left" style={{ width: '60%' }}>
        <div className="flex-row-spacebetween-wrap w-100-P">
          <div className="flex-col-left">
            <span className="align-left fs-16 fw-600 w-100-P">Action Details</span>
          </div>
        </div>
        <DropdownSelect
          key="action key"
          classProps="w-100-P align-left h-50 m-top-25"
          selectProps={{ hideSelectedOptions: true }}
          options={props.salesTriggers.actions
            .sort((a, b) => {
              if (a.title.toLowerCase() < b.title.toLowerCase()) {
                return -1;
              }
              if (a.title.toLowerCase() > b.title.toLowerCase()) {
                return 1;
              }
              // a must be equal to b
              return 0;
            })
            .map(act => ({
              label: (
                <div className="flex-row-nospacebetween-nowrap h-50" style={{ alignItems: 'center' }}>
                  <FontAwesomeIcon
                    icon={act.type === 'MESSAGE' ? ['fal', 'comment-alt-lines'] : ['fal', 'bell']}
                    className="fs-18 m-right-11"
                  />
                  <span>{act.title}</span>
                </div>
              ),
              value: act.action_id,
              /// ////////////////////////////////////////////////
            }))}
          placeholder="Choose an action..."
          value={props.selectedAction}
          onChange={obj => {
            // props.setRepeatInterval(obj.value);
            props.setSelectedAction(obj.value);
            const filterActionObj = props.salesTriggers.actions.filter(act => {
              return act.action_id === obj.value;
            });
            if (filterActionObj.length > 0) {
              props.setActionObj({ ...props.actionObj, ...filterActionObj[0] });
            }
          }}
        />
        <div className="align-left m-top-30">
          <span className="w-100-P fw-500 fs-14">Schedule</span>
        </div>
        <br />
        <div className="align-left">
          <Radio.Group
            size="large"
            onChange={props.immediateFunction}
            value={props.salesTriggerImmediate}
          >
            <Space direction="vertical">
              <Radio value>Same Day</Radio>
              <Radio value={false}>Custom</Radio>
            </Space>
          </Radio.Group>
        </div>
        {props.showImmediateDD ? (
          <div className="flex-row-nospacebetween-wrap w-100-P m-top-15" style={{ gridGap: '15px' }}>
            <div className=" m-btm-15" style={{ maxWidth: '247px', minWidth: '100px', flexGrow: 1 }}>
              <NumberInput
                onChange={val => props.setActionScale(val)}
                styleProps={{}}
                value={props.actionScale}
                classProps="maw-247, w-100-P miw-100"
                min={1}
                max={31}
              />
            </div>
            <div style={{ flexGrow: 1 }}>
              <DropdownSelect
                key="interval key"
                classProps="w-100-P h-50 align-left"
                options={[
                  { label: 'Days', value: 'DAY' },
                  { label: 'Weeks', value: 'WEEK' },
                  { label: 'Months', value: 'MONTH' },
                ]}
                placeholder="Weeks"
                value={props.actionInterval}
                optionalStyles={{ minWidth: '100px' }}
                onChange={obj => {
                  props.setActionInterval(obj.value);
                }}
              />
            </div>
          </div>
        ) : null}
        {/* repeat schedule here  */}
        <span className="w-100-P fw-500 fs-14 align-left m-btm-15 m-top-15">
          Do you want this action to be repeated?
        </span>
        <CheckboxDesc
          checked={Number(props.actionRepeat) === 1}
          onclick={props.repeatFunction}
          text="Repeats"
          classProps="fs-13"
          checkButtonProps="m-top-4"
        />
        {props.repeatCheck ? (
          <div className="flex-row-nospacebetween-wrap w-100-P pos-rel m-top-15" style={{ gridGap: '15px' }}>
            <div className=" m-btm-15" style={{ maxWidth: '247px', minWidth: '100px', flexGrow: 1 }}>
              <NumberInput
                onChange={val => props.setRepeatScale(val)}
                styleProps={{}}
                value={props.repeatScale}
                classProps="maw-247, w-100-P miw-100"
                min={0}
                max={31}
              />
            </div>
            <div style={{ flexGrow: 1 }}>
              <DropdownSelect
                key="interval key"
                classProps="w-100-P align-left h-50"
                options={[
                  { label: 'Days', value: 'DAY' },
                  { label: 'Weeks', value: 'WEEK' },
                  { label: 'Months', value: 'MONTH' },
                ]}
                placeholder="Months"
                value={props.repeatInterval}
                optionalStyles={{ minWidth: '100px' }}
                onChange={obj => {
                  props.setRepeatInterval(obj.value);
                }}
              />
            </div>
          </div>
        ) : null}
        <br />
        <div className="max-w-600 flex-row-nospacebetween-wrap m-btm-15">
          <KioskSlider
            id="expirationToggle"
            value={props.actionExpires}
            onChange={value => props.setActionExpires(value)}
            classProps="fs-13"
            checkButtonProps="m-btm-15 w-85"
          />
          <div className="flex-col-left p-left-15">
            <span style={{ fontSize: '13px', fontWeight: '500' }}>Turn On Expiration</span>
            <span style={{ fontSize: '10px', color: '#979797' }}>
              Turning on expiration allows reminders to automatically go away after a certain amount of time
            </span>
          </div>
        </div>
        {props.actionExpires && (
          <div className="align-left">
            <span className={`${!props.actionExpires && 'gray'} fs-13 fw-500 d-block m-top-15 m-btm-15 align-left`}>
              How long do you want the reminder to remain overdue before it expires?
            </span>
            <Radio.Group
              size="large"
              onChange={radioChange}
              value={props.expirationRadio}
              disabled={!props.actionExpires}
            >
              <Space direction="vertical">
                <Radio value={0}>Expires when overdue</Radio>
                <Radio value={1}>Custom </Radio>
              </Space>
            </Radio.Group>
            <br />
            <br />
            <div className="flex-row-spacebetween-wrap w-100-vw max-w-600 pos-rel m-btm-28">
              <div className="w-48-P inputHeight align-left" style={{ position: 'relative' }}>
                <NumberInput
                  onChange={value => props.setActionExpireScale(value)}
                  min="1"
                  max="31"
                  step={1}
                  precision={1}
                  value={props.actionExpireScale}
                  classProps=""
                  placeholder={1}
                  disabled={!props.actionExpires || !props.expirationRadio}
                />
              </div>
              <div className="p-left-15 w-48-P inputHeight" style={{ position: 'relative' }}>
                <DropdownSelect
                  classProps="maw-100-P w-100-P align-left"
                  options={intervalOptions}
                  placeholder="Weeks"
                  value={props.actionExpireInterval}
                  onChange={obj => props.setActionExpireInterval(obj.value)}
                  disabled={!props.actionExpires || !props.expirationRadio}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <br />
      <div className="flex-col-center">
        <button type="button" className="save-button-v2 m-btm-8" onClick={props.onConfirm}>
          Save
        </button>
         <button type="button" className="cancel-button-v2 m-btm-15" onClick={props.handleCancelModal}>
          Cancel
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
  salesTriggers: state.salesTriggers,
  autoRemMsgs: { ...state.autoRemMsgs },
});
const mapDispatchToProps = dispatch => ({
  setInterval(val) {
    setTriggerReminderInterval(val);
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ActionDetails);
