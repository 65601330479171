import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NewPopConfirm from '../../../../../../../Layout/NewPopConfirm';
import './styles.css';
import PopConfirm from '../../../../../../../Layout/PopConfirm';

const FacebookPopConfirm = ({ visible, authUrl, onClose }) => {
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    let counterInterval;

    if (visible && counter > 0) {
      counterInterval = setInterval(() => {
        setCounter(prevCounter => prevCounter - 1);
      }, 1000);
    }

    return () => {
      if (counterInterval) {
        clearInterval(counterInterval);
      }
    };
  }, [visible, counter]);

  return (
    visible && (
      <PopConfirm
        confirm={`You're being redirected in ${counter} seconds`}
        customButtons
        popStyle={{
          width: '540px',
          maxWidth: '540px',
          paddingBottom: '32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '230px'
        }}
        popConfirmBackgroundClick={onClose}
      >
        <p>After completion, please return to this page and refresh.</p>

        <p style={{marginBottom: '26px'}}>
          If you are not redirected, click{' '}
          <a href={authUrl} target="_blank" rel="noopener noreferrer" className="cb-integrations-pop-confirm__link">
            here
          </a>
          .
        </p>
      </PopConfirm>
    )
  );
};

const mapStateToProps = state => ({
  uuid: state.auth.uuid,
  pos_type: state.auth.pos_type,
});

export default connect(mapStateToProps, {})(FacebookPopConfirm);
