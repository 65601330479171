import React, { useState } from 'react';
import { bool, arrayOf, shape, string, func } from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { formatPhoneNumber } from 'react-phone-number-input';

import LayoutTable from '../../../Layout/Table';
import StyledLink from '../../../Layout/StyledLink';

import { PAYMENT_STATUS_LABEL, PAYMENTS_COLUMN_KEY, PAYMENT_STATUS, DATE_FORMAT } from '../const';
import { cancelPayment, markAsPaid, sendReminder, refundPayment } from '../apiRequests';
import { CancelPaymentRequest, MarkAsPaid, SendReminder, RefundPayment } from '../Dialogs';
import ActionButton from './ActionButton';
import Bubble from '../Bubble';

const Table = ({ data, refresh, isAssociate, merchantId, userId, fullName, storeId, tableName }) => {
  const history = useHistory();
  const [hoverItemId, setHoverItemId] = useState(null);
  const [cancelDialogItem, setCancelDialogItem] = useState(null);
  const [markAsPaidDialogItem, setMarkAsPaidDialogItem] = useState(null);
  const [sendReminderDialogItem, setSendReminderDialogItem] = useState(null);
  const [refundDialogItem, setRefundDialogItem] = useState(null);

  const columnProps = {
    sortable: true,
    style: { whiteSpace: 'nowrap', minWidth: 80, paddingRight: 10 },
    rowStyle: { paddingRight: 20 },
  };

  const adminColumns = isAssociate
    ? []
    : [
      {
        keyName: PAYMENTS_COLUMN_KEY.CREATED_BY,
        title: 'Created by',
        ...columnProps,
        style: { ...columnProps.style, width: '100px' },
        sortable: true,
      },
    ];

  const tableHeaders = [
    {
      keyName: PAYMENTS_COLUMN_KEY.AMOUNT,
      title: 'Amount',
      ...columnProps,
      render: amount => `$${amount}`,
    },
    {
      keyName: PAYMENTS_COLUMN_KEY.STATUS,
      title: 'Status',
      ...columnProps,
      style: {
        ...columnProps.style,
        minWidth: '120px',
      },
    },
    {
      keyName: PAYMENTS_COLUMN_KEY.CLIENT_NAME,
      title: 'Client Name',
      ...columnProps,
      render: (name, item) => <StyledLink to={`/Clients?client=${item.clientId}`}>{name}</StyledLink>,
    },
    {
      keyName: PAYMENTS_COLUMN_KEY.UPDATED,
      title: 'Last Updated',
      ...columnProps,
      render: updated => moment(updated).format(DATE_FORMAT.ABBR_DATE_TIME),
    },
    {
      keyName: PAYMENTS_COLUMN_KEY.STORE,
      title: 'Store',
      ...columnProps,
    },
    {
      keyName: PAYMENTS_COLUMN_KEY.BANK_ACCOUNT_NICKNAME,
      title: 'Bank Account',
      ...columnProps,
      render: (value, item) =>
        value || `${item[PAYMENTS_COLUMN_KEY.BANK_ACCOUNT_TYPE]}-${item[PAYMENTS_COLUMN_KEY.BANK_ACCOUNT_LAST_FOUR]}`,
    },
    {
      keyName: PAYMENTS_COLUMN_KEY.PHONE,
      title: 'Phone Number',
      ...columnProps,
      render: phone => formatPhoneNumber(phone),
    },
    {
      keyName: PAYMENTS_COLUMN_KEY.EMAIL,
      title: 'Email',
      ...columnProps,
      render: email => email,
    },
    ...adminColumns,
    {
      keyName: PAYMENTS_COLUMN_KEY.ID,
      title: 'Payment ID',
      ...columnProps,
      render: paymentRequestId => paymentRequestId,
      // rowStyle: { width: 'fit-content', textAlign: 'right' },
    },
    {
      keyName: 'actions',
      title: '',
      rowStyle: { width: 'fit-content', textAlign: 'right' },
    },
  ];

  const rowClickHandler = (data, e) => {
    if (e.target.tagName === 'TD') {
      history.push(`/Payments/details/${data.id}`);
    }
  };

  return (
    <>
      <LayoutTable
        tableContainerClass="table-big"
        tableClassName="table-big"
        data={data.map(item => {
          let itemStatus = item.status;
          if (itemStatus === 'REFUNDED' && Number(item.refunded) > 0 && Number(item.refunded) < Number(item.amount)) {
            itemStatus = 'PARTIALLY_REFUNDED';
          }
          return {
            ...item,
            status: <Bubble {...PAYMENT_STATUS_LABEL[itemStatus]} />,
            actions: (
              <span
                className="hide-under-800 no-wrap"
                style={{
                  visibility: hoverItemId === item.id ? 'visible' : 'hidden',
                }}
              >
                {/* Cancel and mark as paid for sent */}
                {PAYMENT_STATUS.UNPAID.statuses.includes(itemStatus) && (
                  <>
                    <ActionButton
                      actionButtonId="SendReminderPayment"
                      title="Send Reminder"
                      icon="bell"
                      onClick={() => {
                        setSendReminderDialogItem(item);
                      }}
                    />
                    <ActionButton
                      actionButtonId="MarkAsPaidPayment"
                      title="Mark as Paid"
                      icon="paid"
                      onClick={() => setMarkAsPaidDialogItem(item)}
                    />
                    <ActionButton
                      actionButtonId="CancelPaymentSent"
                      title="Cancel"
                      icon="undo"
                      onClick={() => setCancelDialogItem(item)}
                    />
                  </>
                )}

                {/* Refund for paid transactions */}
                {PAYMENT_STATUS.PAID.statuses.includes(itemStatus) && (
                  <ActionButton
                    actionButtonId="CancelPaymentPaid"
                    title="Refund payment"
                    icon="undo"
                    onClick={() => setRefundDialogItem(item)}
                    // onClick={() => calculateRefundDialog(item)}
                  />
                )}
              </span>
            ),
            onMouseOver: ({ id }) => setHoverItemId(id),
            onMouseLeave: () => setHoverItemId(null),
          };
        })}
        tableHeaders={tableHeaders}
        paginationV2
        onRowClick={rowClickHandler}
        tableName={tableName}
      />

      {cancelDialogItem ? (
        <CancelPaymentRequest
          onConfirm={reason =>
            cancelPayment(cancelDialogItem.id, reason)
              .then(() => setCancelDialogItem(null))
              .then(refresh)
          }
          onCancel={() => setCancelDialogItem(null)}
        />
      ) : null}

      {markAsPaidDialogItem ? (
        <MarkAsPaid
          onConfirm={message =>
            markAsPaid(markAsPaidDialogItem.id, message)
              .then(() => setMarkAsPaidDialogItem(null))
              .then(refresh)
          }
          onCancel={() => setMarkAsPaidDialogItem(null)}
        />
      ) : null}

      {sendReminderDialogItem ? (
        <SendReminder
          id={sendReminderDialogItem.id}
          onConfirm={reason => {
            sendReminder(
              sendReminderDialogItem.id,
              reason,
              userId,
              merchantId,
              sendReminderDialogItem.clientId,
              fullName,
              sendReminderDialogItem.storeId,
            )
              .then(() => setSendReminderDialogItem(null))
              .then(refresh);
          }}
          onCancel={() => setSendReminderDialogItem(null)}
        />
      ) : null}

      {refundDialogItem ? (
        <RefundPayment
          refundSum={refundDialogItem.refunded}
          amount={refundDialogItem.amount}
          onConfirm={confirmationObj =>
            refundPayment(refundDialogItem.id, {
              amount: confirmationObj.amount,
              reason: confirmationObj.reason,
            })
              .then(() => setRefundDialogItem(null))
              .then(refresh)
          }
          onCancel={() => setRefundDialogItem(null)}
        />
      ) : null}
    </>
  );
};

Table.propTypes = {
  data: arrayOf(
    shape({
      id: string,
      status: string,
    }),
  ),
  refresh: func,
  isAssociate: bool,
  merchantId: string,
  userId: string,
  fullName: string,
  storeId: string,
};

export default Table;
