import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSearchParams from '../../../../hooks/useSearchParams';

import KioskSlider from '../../KioskSlider';
import CheckboxDesc from '../../CheckboxDesc';

const FilterItemList = ({
  title,
  options,
  labelKey,
  valueKey,
  renderItem,
  paramValue,
  hasSearch = true,
  hasSelectAll = true,
}) => {
  const { setValue: setSearchParams, getValue: getSearchParam } = useSearchParams();
  const params = getSearchParam(paramValue);

  const [openItem, setOpenItem] = useState(false);
  const items = useMemo(() => {
    if (!params || params === 'all') {
      return options.map(option => option[valueKey].toString());
    }
    if (params === 'none') {
      return [];
    }
    return params.split(',');
  }, [options, params]);
  const setItems = useCallback(value => {
    if (value === 'all') {
      setSearchParams(paramValue, 'all');
    } else if (value === 'none') {
      setSearchParams(paramValue, 'none');
    } else {
      setSearchParams(paramValue, value?.join(','));
    }
  }, []);
  const [filterList, setFilterList] = useState([]);
  const [toggleButton, setToggleButton] = useState(true);

  useEffect(() => {
    setFilterList(options);
  }, [options, valueKey]);

  const selectItem = useCallback(
    value => {
      if (items.includes(value.toString())) {
        const selectedItems = items.filter(item => item !== value.toString());
        setItems(selectedItems.length === 0 ? 'none' : selectedItems);
      } else {
        const selectedItems = [...items, value.toString()];
        setItems(
          options.filter(opt => !selectedItems.includes(opt[valueKey].toString())).length === 0 ? 'all' : selectedItems,
        );
      }
    },
    [items, options],
  );

  const selectItemOnly = useCallback((e, value) => {
    e.stopPropagation();
    setToggleButton(false);
    setItems([value]);
  }, []);

  const handleToggle = useCallback(value => {
    if (value) {
      setItems('all');
      setToggleButton(value);
    } else {
      setItems('none');
      setToggleButton(value);
    }
  }, []);

  const toggleItem = useCallback(() => {
    setOpenItem(!openItem);
  }, [openItem]);

  const handleSearch = useCallback(
    value => {
      if (value !== '') {
        const filterOptions = options.filter(option => option[labelKey].toLowerCase().includes(value.toLowerCase()));
        setFilterList(filterOptions);
      } else {
        setFilterList(options);
      }
    },
    [options, labelKey],
  );

  useEffect(() => {
    const totalItems = params.includes('all') || params.includes('none') ? -1 : params.split(',').length;
    if (!params || params === 'all' || totalItems === options.length) {
      setToggleButton(true);
    } else {
      setToggleButton(false);
    }
  }, [params, options]);

  const handleClear = useCallback(e => {
    e.stopPropagation();
    handleToggle(true);
  }, []);

  return (
    <div className="filter-item-container">
      <div className="filter-item-header" onClick={toggleItem}>
        <div className="filter-item-header-title">{title}</div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {params && params !== 'all' && (
            <div className="filter-item-clear" onClick={handleClear}>
              Clear
            </div>
          )}
          <FontAwesomeIcon
            icon={['far', `${openItem ? 'chevron-up' : 'chevron-down'}`]}
            className="fs-16"
            color="#818181"
          />
        </div>
      </div>
      {openItem && (
        <div className="filter-item-list-container">
          {hasSearch && options.length > 6 && (
            <input
              onChange={e => handleSearch(e.target.value)}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
                return false;
              }}
              id="ClientSearchBox"
              placeholder="Search..."
              className="h-50 w-100-P m-btm-6 m-top-10 body-head-search rem-pad-width focus-input-outline-blue filter-item-input"
            />
          )}
          {hasSelectAll && (
            <div className="filter-item-list-toggle">
              <KioskSlider value={toggleButton} onChange={value => handleToggle(value)} />
              <span className="filter-item-list-toggle-span">{`All ${title}`}</span>
            </div>
          )}
          <div className="filter-item-list-content">
            {filterList.map(item => (
              <CheckboxDesc
                key={item[valueKey]}
                checked={!!items.find(i => i.toString() === item[valueKey].toString())}
                onclick={() => selectItem(item[valueKey])}
                text={
                  <div className="checkboxItem">
                    {renderItem ? renderItem(item) : item[labelKey]}
                    <div onClick={e => selectItemOnly(e, item[valueKey])} className="checkboxItem-only">
                      Only
                    </div>
                  </div>
                }
                classProps="m-top-10 w-100-P"
                checkButtonProps="m-top-4 w-100-P"
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterItemList;
