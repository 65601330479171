import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import Layout from '../../../Layout/StandardLayout';
import Button from '../../../Layout/Button';
import { getRequest } from '../../../../core/apiRequests';
import Table from '../../../Layout/Table';
import TitleDescription from '../../../Layout/DescriptionBox';
import { setAuthStoreId } from '../../../../reducers/auth';
import DropdownSelect from '../../../Layout/DropdownSelect';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import StyledLink from '../../../Layout/StyledLink';
import '../reports.css';

const ClientTagsbyAssoc = props => {
  const [tableHeadData, setTableHeadData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showReport, setShowReport] = useState(false);

  const runReport = e => {
    setShowReport(false);
    e.preventDefault();
    const reqObj = {
      params: ['report', props.auth.merchantId, props.auth.storeId || 0, 'clientTagsByAssociate'],
      query: {
        storeAccess: props.auth.stores.filter(st => st.id !== 0).map(st => st.id),
      },
    };
    (async () => {
      const data = await getRequest(reqObj);
      const associates = {};
      const tags = {};

      function resetReport() {
        data.forEach(row => {
          if (!associates[`${row.associate}`]) {
            associates[`${row.associate}`] = {};
            associates[`${row.associate}`].name = row.associate;
            associates[`${row.associate}`].id = row.id;
          }
          associates[`${row.associate}`][`${row.tag}`] = row.count;
          if (!tags[`${row.tag}`]) {
            tags[`${row.tag}`] = true;
          }
        });

        const tempTableHeaders = [
          {
            keyName: 'name',
            headerClassName: 'row1',
            rowClassName: 'col-p-l-25',
            title: 'Associates',
            style: { width: '5%', paddingLeft: '20px', paddingTop: '80px' },
            render: (t, o) => (
              <StyledLink to={`/Associates/associate-details/${o.id}`}>
                {t || `${o.first_name || ''} ${o.last_name || ''}`.trim()}
              </StyledLink>
            ),
          },
        ];

        const tempAssocArray = [];

        Object.keys(associates).forEach(key => {
          tempAssocArray.push(associates[key]);
        });
        setTableData(tempAssocArray);

        Object.keys(tags).forEach(key => {
          tempTableHeaders.push({
            keyName: key,
            style: { width: '5%', paddingTop: '80px', paddingRight: '10px' },
            headerClassName: 'tags_row2',
            title: key,
            sortable: false,
          });
        });

        setTableHeadData(tempTableHeaders);
        setShowReport(true);
      }

      resetReport();
    })();
  };

  return (
    <Layout
      title="Tags by Associate"
      backArrow="/Home"
      description="Set your parameters and click on 'Run Report.'"
      classProps="standard-width-with-sidebar flex-col-center"
      styleProps={{ justifyContent: 'flex-start' }}
    >
      <div className="report-smaller-description">
        {' '}
        Tags by Associates report measures the amount of each tag has been added to clients by your associates. Each
        report is based around the tags created by your associates in the mobile app or Dashboard.{' '}
      </div>
      <div className="pos-rel w-100-P " style={{ maxWidth: '866px' }}>
        <TitleDescription
          title="Reporting Location"
          description="What store location would you like to run a report on?"
          input={
            <DropdownSelect
              isSearchable
              classProps="mq-w-200 w-267 align-left"
              options={props.auth.stores.map(st => ({
                label: st.name,
                value: st.id,
              }))}
              placeholder="Select a Store..."
              value={props.auth.storeId}
              onChange={obj => {
                setAuthStoreId(obj.value);
              }}
            />
          }
        />
        <div className="report-asct-msg-row" style={{ maxWidth: '866px' }}>
          <Button id="RunClientTagsByAssociateReport" classname="darkBlueButton" onclick={e => runReport(e)}>
            Run Report
          </Button>
        </div>
      </div>
      {showReport ? (
        <div>
          <div
            className="import_export_group"
            style={{
              justifyContent: 'flex-end',
              maxWidth: '1105px',
              zIndex: '100',
              marginTop: '100px',
            }}
          >
            <img src="https://dashboard-v2-images.s3.amazonaws.com/ic-export.svg" alt="export" />

            <CSVLink
              data={tableData}
              filename="tagsByAssociate.csv"
              id="DownloadClientTagsByAssociateReport"
              className="exportButton m-right-15"
            >
              Download
            </CSVLink>
          </div>

          <Table
            tableClassName="maw-1200 rem-pad-width m-top-15 overflowX show-scrollbar w-100-P"
            tableContainerClass="w-100-P rem-pad-width "
            style={{ marginTop: '-100px', width: '100%' }}
            data={tableData}
            tableRowClassName="col-p-l-25"
            tableHeaders={tableHeadData}
            tableName="CLIENT_TAGS_BY_ASSOCIATE_REPORT"
          />
        </div>
      ) : null}
      <SecondaryPanel
        overhead_title="Reports"
        title={['CLIENTELING', 'MESSAGING', 'Google Reviews', 'PAYMENTS', 'AUTOMATION', 'SALES OPPORTUNITY']}
        current="Tags by Associate"
      />
    </Layout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  stores: state.stores,
  reports: state.reports,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ClientTagsbyAssoc);
