import { dispatcher } from '../core/actions';

const initialState = {
  // Messaging
  appBulkLimit: 0, // merchantConfig
  setSchTime: '1000',
  autoAsA2p: false, // merchantConfig
  // optAutoMess: false,
  // Clienteling
  dailyContactGoal: 4,
  // Sales Pipeline
  salesOppName: '', // merchantConfig
  // Marketing Opt-In
  defaultStore: '',
  doubleOptIn: false, // also a Kiosk Setting
  delayOptIn: true, // merchantConfig // value is opposite of switch. If switch on = false.
  optInInvite: '', // merchantConfig
  optInConfirm: '', // merchantConfig
  optInBulkInvite: '',
  // Kiosk Settings
  logoUrl: '',
  roundLogo: false,
  backgroundColor: '',
  backgroundUrl: '',
  introTitle: '',
  formTitle: '',
  topSectionHeader: '',
  bottomSectionHeader: '',
  optInTitle: '',
  optInHelp: '',
  optInLegal: '',
  buttonText: '',
  completeTitle: '',
  completeSubtitle: '',
  reviewMessage: '',
  show_anniversary: false,
  show_birthday: false,
  show_address: false,
  show_email: false,
  // General settings
  // AUTO_MSG_OPTIN
};
export default function reducer(state = initialState, action) {
  const state2 = state;
  switch (action.type) {
  case 'SET_MSG_SETTINGS_OBJECT':
    return { ...state2, ...action.payload };
  case 'SET_MSG_APP_BULK_LIMIT':
    return { ...state2, appBulkLimit: action.payload };
  case 'SET_MSG_AUTO_MSG_TIME':
    return { ...state2, setSchTime: action.payload };
    // case "SET_MSG_OPT_AUTO_MSG":
    //     return { ...state2, optAutoMess: action.payload };
  case 'SET_MSG_AUTO_AS_A2P':
    return { ...state2, autoAsA2p: boolParse(action.payload) };
    // Clienteling
    case 'SET_MSG_DAILY_CONTACT_GOAL':
      return { ...state2, dailyContactGoal: action.payload };
    // Sales Pipeline
  case 'SET_MSG_SALES_OPP_NAME':
    return { ...state2, salesOppName: action.payload };
    // Marketing Opt-In
  case 'SET_MSG_DOUBLE_OPT_IN':
    return { ...state2, doubleOptIn: boolParse(action.payload) };
  case 'SET_MSG_DELAY_OPT_IN':
    return { ...state2, delayOptIn: boolParse(action.payload) };
  case 'SET_MSG_OPT_IN_INVITE':
    return { ...state2, optInInvite: action.payload };
  case 'SET_MSG_OPT_IN_CONFIRM':
    return { ...state2, optInConfirm: action.payload };
  case 'SET_MSG_OPT_IN_BULK_INVITE':
    return { ...state2, optInBulkInvite: action.payload };
    // Kiosk Settings
  case 'SET_MSG_LOGO_URL':
    return { ...state2, logoUrl: action.payload };
  case 'SET_MSG_ROUND_LOGO':
    return { ...state2, roundLogo: boolParse(action.payload) };
  case 'SET_MSG_BACKGROUND_COLOR':
    return { ...state2, backgroundColor: action.payload };
  case 'SET_MSG_BACKGROUND_URL':
    return { ...state2, backgroundUrl: action.payload };
  case 'SET_MSG_INTRO_TITLE':
    return { ...state2, introTitle: action.payload };
  case 'SET_MSG_FORM_TITLE':
    return { ...state2, formTitle: action.payload };
  case 'SET_MSG_TOP_SECTION_HEADER':
    return { ...state2, topSectionHeader: action.payload };
  case 'SET_MSG_BOTTOM_SECTION_HEADER':
    return { ...state2, bottomSectionHeader: action.payload };
  case 'SET_MSG_OPT_IN_TITLE':
    return { ...state2, optInTitle: action.payload };
  case 'SET_MSG_OPT_IN_HELP':
    return { ...state2, optInHelp: action.payload };
  case 'SET_MSG_OPT_IN_LEGAL':
    return { ...state2, optInLegal: action.payload };
  case 'SET_MSG_BUTTON_TEXT':
    return { ...state2, buttonText: action.payload };
  case 'SET_MSG_COMPLETE_TITLE':
    return { ...state2, completeTitle: action.payload };
  case 'SET_MSG_COMPLETE_SUBTITLE':
    return { ...state2, completeSubtitle: action.payload };
    // Messaging
  case 'SET_MSG_DEFAULT_STORE':
    return { ...state2, defaultStore: `${action.payload}` };
  case 'SET_MSG_REVIEW_MESSAGE':
    return { ...state2, reviewMessage: `${action.payload}` };
  case 'SET_MSG_REVIEW_IMAGES_URL':
    return { ...state2, reviewImages: action.payload };
  case 'SET_MSG_SHOW_ANNIVERSARY':
    return { ...state2, show_anniversary: !!action.payload };
  case 'SET_MSG_SHOW_BIRTHDAY':
    return { ...state2, show_birthday: !!action.payload };
  case 'SET_MSG_SHOW_ADDRESS':
    return { ...state2, show_address: !!action.payload };
  case 'SET_MSG_SHOW_EMAIL':
    return { ...state2, show_email: !!action.payload };
  case 'RESET_MSG_SETTINGS':
    return initialState;
  default:
    break;
  }
  return state2;
}

function boolParse(val) {
  if (val === 1 || val === '1' || val === 'true' || val === true) return true;
  return false;
}
export const setMsgSettingsObject = val => {
  dispatcher('SET_MSG_SETTINGS_OBJECT', val);
};
export const setBulkLimit = val => {
  dispatcher('SET_MSG_APP_BULK_LIMIT', val);
};
export const setDailyGoal = val => {
  dispatcher('SET_MSG_DAILY_CONTACT_GOAL', val);
};
export const setLimitAssociateVisibilityGoal = val => {
  dispatcher('SET_LIMIT_ASSOCIATE_VISIBILITY_ENABLED_GOAL', val);
};
export const setSalesOppName = val => {
  dispatcher('SET_MSG_SALES_OPP_NAME', val);
};
export const setDoubleOptIn = val => {
  dispatcher('SET_MSG_DOUBLE_OPT_IN', val);
};
export const setDelayOptIn = val => {
  dispatcher('SET_MSG_DELAY_OPT_IN', val);
};
export const setOptInInv = val => {
  dispatcher('SET_MSG_OPT_IN_INVITE', val);
};
export const setOptInBulkInvite = val => {
  dispatcher('SET_MSG_OPT_IN_BULK_INVITE', val);
};
export const setOptInConfirm = val => {
  dispatcher('SET_MSG_OPT_IN_CONFIRM', val);
};
export const setLogoUrl = val => {
  dispatcher('SET_MSG_LOGO_URL', val);
};
export const setRoundLogo = val => {
  dispatcher('SET_MSG_ROUND_LOGO', val);
};
export const setBGColor = val => {
  dispatcher('SET_MSG_BACKGROUND_COLOR', val);
};
export const setBGImage = val => {
  dispatcher('SET_MSG_BACKGROUND_URL', val);
};
export const setIntroTitle = val => {
  dispatcher('SET_MSG_INTRO_TITLE', val);
};
export const setFormTitle = val => {
  dispatcher('SET_MSG_FORM_TITLE', val);
};
export const setTopSectHeader = val => {
  dispatcher('SET_MSG_TOP_SECTION_HEADER', val);
};
export const setBtmSectHeader = val => {
  dispatcher('SET_MSG_BOTTOM_SECTION_HEADER', val);
};
export const setOptInTitle = val => {
  dispatcher('SET_MSG_OPT_IN_TITLE', val);
};
export const setOptInHelp = val => {
  dispatcher('SET_MSG_OPT_IN_HELP', val);
};
export const setOptInLegal = val => {
  dispatcher('SET_MSG_OPT_IN_LEGAL', val);
};
export const setMsgButtonText = val => {
  dispatcher('SET_MSG_BUTTON_TEXT', val);
};
export const setReviewMessage = val => {
  dispatcher('SET_MSG_REVIEW_MESSAGE', val);
};
export const setReviewImages = val => {
  dispatcher('SET_MSG_REVIEW_IMAGES_URL', val);
};
export const setCompleteTitle = val => {
  dispatcher('SET_MSG_COMPLETE_TITLE', val);
};
export const setCompleteSubtitle = val => {
  dispatcher('SET_MSG_COMPLETE_SUBTITLE', val);
};
export const setMessageSchedule = val => {
  dispatcher('SET_MSG_AUTO_MSG_TIME', val);
};
export const setDefaultStore = val => {
  dispatcher('SET_MSG_DEFAULT_STORE', val);
};
export const setMsgShowAnniversary = val => {
  dispatcher('SET_MSG_SHOW_ANNIVERSARY', val);
};
export const setMsgShowBirthday = val => {
  dispatcher('SET_MSG_SHOW_BIRTHDAY', val);
};
export const setMsgShowAddress = val => {
  dispatcher('SET_MSG_SHOW_ADDRESS', val);
};
export const setMsgShowEmail = val => {
  dispatcher('SET_MSG_SHOW_EMAIL', val);
};
// export const setOptAutoMsg = val => {
//     dispatcher("SET_MSG_OPT_AUTO_MSG", val)
// }

export const setAutoAsA2p = val => {
  dispatcher('SET_MSG_AUTO_AS_A2P', val);
};

export const getDefaultOptInInv = val => {
  return `Hi :name:, It's :associate: from ${val} and you can reach me anytime at this number. Can I occasionally let you know about personalized promotions and products? If that's ok, reply YES to this msg`
}
