import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import useDebounce from '../../../hooks/useDebounce';
import './styles.css';

const DEFAULT_PLACEHOLDER = 'Search...';

const SearchBar = ({ value, placeholder, onChange, white }) => {
  const [search, setSearch] = useState(value);
  const debouncedSearch = useDebounce(search);

  const handleChangeSearch = e => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    onChange(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <div className={`cb-search-bar-input ${white ? 'bg-white' : ''}`}>
      <Input
        size="large"
        value={search}
        placeholder={placeholder || DEFAULT_PLACEHOLDER}
        onChange={handleChangeSearch}
        prefix={<SearchOutlined />}
      />
    </div>
  );
};

export default SearchBar;
