import React from 'react';
import { func } from 'prop-types';

import PopConfirm from '../../../Layout/PopConfirm';

const DeletePrimaryAccount = ({ onConfirm, onCancel }) => {
  return (
    <PopConfirm
      confirm="Archive Primary Account"
      description="Future payment requests will be associated to your most recently added account. This will not affect any pending payments."
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText="ARCHIVE"
      confirmButtonStyle={{ background: 'var(--red)' }}
    />
  );
};

DeletePrimaryAccount.propTypes = {
  onConfirm: func,
  onCancel: func,
};

export default DeletePrimaryAccount;
