/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'antd';
import { sourceDateCalc } from '../../../../Today/children/logicFunctions';
import '../../../clientV2.css';
import { setMsgReminderDescription, setMsgReminderId } from '../../../../../../reducers/messaging';
import { PartyIcon } from '../../../../../../componentsV2/Layout/Report/components/Icons';

const ReminderCard = props => {
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();
  today = yyyy + mm + dd;
  const dueWhen = due => {
    if (due === today) {
      return 'Due Today';
    }
    const newDateObj = moment(due, 'YYYYMMDD').format('MMMM DD, YYYY');
    return newDateObj;
  };

  const reminderType = autoRem => {
    const {
      life_event_type,
      sales_opp_name,
      tag_name,
      tag_action_config_id,
      custom_event_type_id,
      custom_event_type_name,
      sales_opp_id,
      life_event_id,
    } = autoRem;
    if (life_event_id) {
      return life_event_type;
    }
    if (tag_action_config_id) {
      return tag_name;
    }
    if (sales_opp_id) {
      return sales_opp_name;
    }
    if (custom_event_type_id) {
      return custom_event_type_name;
    }
  };

  const msgTrim = msg => {
    if (msg.length > 100) {
      return `${msg.substr(0, 100)}...`;
    }
    return msg;
  };

  const checkboxClickHandler = (obj, included) => {
    let tempSel = [...props.selectedAction];
    if (!included) {
      tempSel.push({
        ...obj,
      });
    } else {
      tempSel = props.selectedAction.filter(arrId => arrId.id !== Number(obj.id));
    }
    props.setSelectedAction(tempSel);
  };

  const assignedTo = autoRem => {
    const assignStyleArr = [];
    if (autoRem.assigned_to) {
      if (autoRem.assigned_to && autoRem.assigned_to === props.auth.userId) {
        assignStyleArr.push(
          <div key={autoRem.id + autoRem.due_date}>
            {' '}
            <span>ASSIGNED TO </span>
            <span className="m-right-5">ME</span>
            <FontAwesomeIcon icon={['far', 'pencil']} className="p-right-10 gray" />
          </div>,
        );
      } else {
        assignStyleArr.push(
          <div key={autoRem.id + autoRem.due_date}>
            {' '}
            <span>ASSIGNED TO </span>
            {autoRem.associateName ? (
              <span className="m-right-5">{`${autoRem.associateName}`}</span>
            ) : (
              <span className="m-right-5">{`${autoRem.associate_first_name || ''} ${
                autoRem.associate_last_name || ''
              }`}</span>
            )}
            <FontAwesomeIcon icon={['far', 'pencil']} className="p-right-10 gray" />
          </div>,
        );
      }
    } else {
      assignStyleArr.push(
        <div key={`${autoRem.id}notAssigned`}>
          <span className="m-right-5">NOT ASSIGNED</span>
          <FontAwesomeIcon icon={['far', 'pencil']} className="p-right-10 gray" />
        </div>,
      );
    }
    return assignStyleArr;
  };
  const repeatType = (scale, interval) => {
    let returnType = '';
    if (scale === 1) {
      switch (interval) {
        case 'MONTH':
          returnType = 'month';
          break;
        case 'WEEK':
          returnType = 'week';
          break;
        default:
          returnType = 'day';
        break;
      }
    } else {
      switch (interval) {
        case 'MONTH':
          returnType = `${scale} months`;
        break;
        case 'WEEK':
          returnType = `${scale} weeks`;
          break;
        default:
          returnType = `${scale} days`;
        break;
      }
    }
    return returnType;
  };
  const AutoReminderData = autoRem => {
    const returnArr = [];
    if (props.isLoading) {
      returnArr.push(
        <div key="skeleton">
          <Skeleton
            paragraph={{
              rows: 3,
            }}
            active
          />
        </div>,
      );
      return returnArr;
    }
    returnArr.push(
      <div key={autoRem.id} className="add-edit-wrap w-100-P" style={{ position: 'relative' }}>
        {props.selectedAction?.filter(obj => obj.id === autoRem.id).length < 1 ? (
          <FontAwesomeIcon
            onClick={() => checkboxClickHandler(autoRem, false)}
            className="fs-16 gray  m-top-50 m-right-10"
            icon={['far', 'square']}
            style={{ left: '-26px', cursor: 'pointer' }}
          />
        ) : (
          <FontAwesomeIcon
            onClick={() => checkboxClickHandler(autoRem, true)}
            className="fs-16  m-top-50 m-right-10"
            color="#1769af"
            icon={['fas', 'check-square']}
            style={{ left: '-26px', cursor: 'pointer' }}
          />
        )}
        <div className="reminderCard m-btm-12">
          <div
            className="reminderStrip"
            style={{
              backgroundColor:
                // eslint-disable-next-line no-nested-ternary
                String(autoRem.due_date) === today
                  ? '#FC8333'
                  : String(autoRem.due_date) > today
                  ? '#63C866'
                  : '#FC4721',
            }}
          />
          <div className="fs-16 fw-500 reminderCardPadding align-left">
            <p className="m-btm-0 m-top-0">{msgTrim(autoRem.description)}</p>
          </div>
          <div className="fs-13 fw-400 flex-col-left m-left-21" style={{ color: '#9C9C9C' }}>
            <span>Due on {dueWhen(autoRem.due_date)}</span>
            {autoRem.life_event_id ||
            autoRem.tag_action_config_id ||
            autoRem.sales_opp_id ||
            autoRem.custom_event_type_id ? (
              <div>
                {autoRem?.life_event_type === "Birthday" ||
                  autoRem?.life_event_type === "Anniversary" ? (
                    <PartyIcon />
                  ) : (
                    <FontAwesomeIcon icon="fa-solid fa-dollar-sign" />
                  )
                }
                <span className="align-left m-left-10">
                  {`${reminderType(autoRem)} `} on {` ${sourceDateCalc(autoRem)}`} (Source of reminder)
                </span>
              </div>
            ) : null}
            {autoRem.repeats === 1 ? (
              <div>
                <FontAwesomeIcon icon="fa-solid fa-rotate" />
                <span className="align-left m-left-10">
                  {`Repeats every ${repeatType(autoRem.repeat_scale, autoRem.repeat_interval)} `}
                </span>
              </div>
            ) : (
              <div className="h-20" />
            )}
            <div className="flex-row-spacebetween-nowrap reminderBottomStrip">
              <span
                className="fw-600 fs-13"
                style={{ color: '#33AAFC' }}
                onClick={() => {
                  props.showMessageHandler(props.reminder);
                  setMsgReminderId(autoRem.id);
                  setMsgReminderDescription(autoRem.description);
                }}
              >
                MESSAGE
              </span>
              <div
                onClick={() => {
                  props.setSelectedAction([autoRem]);
                  props.setAssignPopup(true);
                }}
              >
                {assignedTo(autoRem)}
              </div>
            </div>
          </div>
        </div>
      </div>,
    );
    return returnArr;
  };

  return (
    <div className="pos-rel" id="upcoming-reminders-clientV2">
      <div style={{ maxWidth: '855px' }}>{AutoReminderData(props.reminder)}</div>
    </div>
  );
};

export default ReminderCard;
