import React from 'react';
import PaymentBannerSpacer from '../../../../Layout/PaymentBannerSpacer';
import ImportTable from '../ImportTable';

const Review = props => {
  const onImportHandler = () => {
    props.importData(props.data, props.selStore);
    props.setUploadModal('submitted');
  };

  const removeIgnored = () => {
    let newColumns = {};
    Object.keys(props.columns).forEach(col => {
      if (!props.columns[col].ignore) {
        newColumns[col] = props.columns[col];
      }
    });
    return newColumns;
  };

  return (
    <div className="pos-abs z-index-5 left-0 align-top w-100-vw h-100-vh bg-white p-42 rem-pad-width max-h-100vh overflowY m-top-44">
      <PaymentBannerSpacer />
      <div className="maw-965">
        <div className="flex-row-spacebetween-wrap w-100-P align-items-center'">
          <div className="flex-col-left">
            <span className="align-left fs-18 fw-500 w-100-P">Upload Data</span>
            <span className="align-left fs-14 w-100-P">Bulk add {props.endPoint}</span>
          </div>
          <span className="layoutBreadcrumb">
            <span className="completed-bread-crumb" style={{ padding: '3.5px 9.765px' }}>
              Upload
            </span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span className="completed-bread-crumb" style={{ padding: '3.5px 11.87px' }}>
              Match
            </span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span className="current-bread-crumb">Review</span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span>Done</span>
          </span>
        </div>
        <div className="header-container m-btm-35">
          <span className="align-left fs-16 w-100-P">Does everything look correct?</span>
          <button className="import-positive-button" onClick={() => onImportHandler()}>
            IMPORT
          </button>
          <button className="import-cancel-button" style={{marginTop: 0}} onClick={() => props.setUploadModal('match')}>
            BACK
          </button>
        </div>

        <ImportTable columns={removeIgnored()} data={props.data} bold />
      </div>
    </div>
  );
};

export default Review;
