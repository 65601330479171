import React from 'react';

const MessageNotificationDescription = props => {
  const { description } = props;

  const truncateDescription = text => {
    if (text.length > 330) {
      return `${text.substr(0, 330)}...`;
    }

    return text;
  };

  return (
    <div className="fs-12 fw-400" style={{ marginLeft: '-10px' }}>
      {truncateDescription(description)}
    </div>
  );
};

export default MessageNotificationDescription;
