import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Table from '../../../Layout/Table';
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import { getRequest } from '../../../../core/apiRequests';
import { setMsgListId } from '../../../../reducers/autoMessages';
import {
  resetTablePagination,
  setTablePaginationRowCount,
  setTablePaginationPageCount,
  getTablePaginationData,
} from '../../../../core/actions';
import { setListId, setListType } from '../../../../reducers/autoRemMsgs';
import { setTagId, setTagColor, setTagName, setTagKiosk, setTagAddEdit } from '../../../../reducers/addEditTag';
import FlaggedAddTagBtn from './FlaggedAddTagBtn';

import './configuration.css';

const tableName = 'TAGS';

const Tags = props => {
  const [tableData, setTableData] = useState([]);
  const [first, setFirst] = useState(true);
  const isMounted = useRef(null);
  const flags = props.auth.merchantFlags;

  const tagsEnabled = flags?.tags?.enabled;
  const history = useHistory();

  useEffect(() => {
    isMounted.current = true;
    if (props.auth.merchantId) {
      const { page, pageSize, sortColumn, ascendingColumn, searchText } = props.pagination;
      let data;
      setTableData([]);
      if (first) {
        resetTablePagination({ sortColumn: 'name' }, tableName);
        setFirst(false);
        data = async () => {
          const reqObj = {
            params: ['tags', props.auth.merchantId, 'paginatedList'],
            query: {
              page: 1,
              count: 25,
              sortColumn: 'name',
              ascending: true,
              searchText: '',
            },
          };

          const dataArr = await getRequest(reqObj);
          const { pageInfo, results } = dataArr;
          if (pageInfo) {
            const { rowCount, pageCount } = pageInfo;
            setTablePaginationRowCount(rowCount, tableName);
            setTablePaginationPageCount(pageCount, tableName);
          }
          setTableData(results);
        };
      } else {
        data = async () => {
          const reqObj = {
            params: ['tags', props.auth.merchantId, 'paginatedList'],
            query: {
              page,
              count: pageSize,
              sortColumn,
              ascending: ascendingColumn,
              searchText,
            },
          };
          const dataArr = await getRequest(reqObj);
          const { pageInfo, results } = dataArr;
          if (pageInfo) {
            const { rowCount, pageCount } = pageInfo;
            setTablePaginationRowCount(rowCount, tableName);
            setTablePaginationPageCount(pageCount, tableName);
          }
          setTableData(results);
        };
      }
      data();
      return () => {
        isMounted.current = false;
      };
    }
  }, [
    props.auth.merchantId,
    props.pagination.sortColumn,
    props.pagination.pageSize,
    props.pagination.page,
    props.pagination.ascendingColumn,
    props.pagination.searchText,
  ]);

  const tableHeaders = [
    // This is a configuration array.
    {
      // It specifies how each column will be structured as well as the column header info
      keyName: 'color',
      style: { width: '5%', minWidth: '25px', paddingRight: '22px' }, // we can apply all styles to the header now.
      headerClassName: '', // This targets the header's className
      render: (text, obj) => {
        return (
          <div
            className="tags-row-color"
            style={{
              backgroundColor: obj.color,
              width: '21px',
              height: '21px',
              borderRadius: '50%',
              marginLeft: '10px',
            }}
          />
        );
      },
      title: '',
      rowClassName: 'tags-row-color',
    },
    {
      keyName: 'name',
      style: { maxWidth: '45%', minWidth: '120px' },
      headerClassName: '',
      rowClassName: 'col-p-r-25 darkBlue',
      title: 'Tag Name',
      sortable: true, // sort allows gives us the ability to sort the table
    },
    {
      keyName: 'inc_kiosk',
      style: { width: '10%', minWidth: '200px' },
      headerClassName: 'tags_row4 align-right',
      rowClassName: 'align-center col-p-r-25 rem-pad-width col-p-l-25',
      title: 'Show in Kiosk Mode',
      render: (text, obj) => {
        // text is a true/false value, object is the entire row's information (including created date and such)
        return (
          <label className="tags-checkmark pos-rel">{text ? <FontAwesomeIcon icon={['fas', 'check']} /> : null}</label>
        );
      },
      sortable: true,
    },
    {
      keyName: 'created_date',
      style: { width: '10%', minWidth: '150px' },
      headerClassName: 'tags_row4 align-right',
      rowClassName: 'align-right col-p-r-25 rem-pad-width',
      title: 'Created Date',
      sortable: true,
      render: (t, o) => {
        return moment(t, 'YYYYMMDD').format('MM/DD/YYYY');
      },
    },
    {
      keyName: 'created_by',
      style: { width: '10%', minWidth: '150px' },
      headerClassName: 'col-p-l-25 align-left rem-pad-width',
      rowClassName: 'align-left col-p-l-25 rem-pad-width no-wrap',
      title: 'Created By',
      render: t => {
        if (t) {
          if (t.length > 20) {
            return `${t.slice(0, 20)}...`;
          }
          return t;
        }
        return '';
      },
      sortable: true,
    },
    {
      keyName: 'tagged_clients',
      style: { width: '10%', minWidth: '150px', paddingRight: '22px' },
      headerClassName: 'align-right',
      rowClassName: 'align-right col-p-r-70 rem-pad-width',
      title: 'Tagged Clients',
      sortable: true,
    },
  ];

  const rowClickHandler = data => {
    if (tagsEnabled) {
      const { color, inc_kiosk, id, name } = data;
      setTagAddEdit('Edit'); //  Set the Add / Edit page to "Edit" mode.
      setListType('tags'); //  set list type is required later for requests (props.type)
      setTagName(name);
      setTagId(id);
      setListId(id);
      setMsgListId(id);
      setTagColor(color);
      setTagKiosk(inc_kiosk);
      // setRedirect(true);      // Redirect to next page b/c what we clicked wasn't a link, it was a table row.
      history.push(`/Configuration/tags/edit-tag/${id}`);
    }
  };

  const clearPref = () => {
    // This is new tags
    setTagAddEdit('Add'); //  Set the Add / Edit page to "Add" mode.
    setTagId(''); //  set default
    setTagName(''); //  set default
    setTagColor('#FD0062'); //  set default
    setTagKiosk(false); //  set default
    setListType('tags'); //  set list type is required later for requests (props.type)
  };

  return (
    <Layout
      title="Tags"
      description="Keep track of your client preferences by creating new Tags"
      // styleProps={{ marginLeft: '265px' }}
      // classProps={"tags-layout"}
      classProps="standard-width-with-sidebar"
    >
      <div className="w-100-P h-100-P">
        <Table
          tableClassName="lg-table-sidebar  rem-pad-width"
          data={tableData.filter(tg => !props.addEditTag.deletedTags.includes(tg.id))}
          tableHeaders={tableHeaders}
          searchable
          paginationV2
          tableName={tableName}
          optionalButton={<FlaggedAddTagBtn flags={flags} clearPref={clearPref} rowCount={props?.pagination?.rowCount} />}
          onRowClick={rowClickHandler}
        />
      </div>
      <SecondaryPanel title={['Automation']} current="Tags" />
    </Layout>
  );
};
const mapStateToProps = state => ({
  ...state,
  pagination: getTablePaginationData(state, tableName),
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Tags);
