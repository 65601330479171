import React, { useState } from 'react';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import PopMessage from '../PopMessage';
import DropdownSelect from '../DropdownSelect';
import NumberInput from '../NumberInput';
import KioskSlider from '../KioskSlider';
import { postRequest } from '../../../core/apiRequests';

const timeframeList = [
  { label: 'Time Period', value: 'timePeriod' },
  { label: 'Specific Date', value: 'specificDate' },
];

const timeUnitList = [
  { label: 'Days', value: 'days' },
  { label: 'Weeks', value: 'weeks' },
  { label: 'Months', value: 'months' },
  { label: 'Years', value: 'years' },
];

const intervalObj = {
  DAY: 'days',
  WEEK: 'weeks',
  MONTH: 'months',
  YEA: 'yearsR',
};

const EditReminderModal = ({ open, allAssoc, onClose, reminder, setReload }) => {
  const [associate, setAssociate] = useState(reminder.assigned_to);
  const [description, setDescription] = useState(reminder.description);
  const [reminderTimeframe, setReminderTimeframe] = useState(!reminder.repeats ? 'specificDate' : 'timePeriod');
  const [factor, setFactor] = useState(reminder.repeat_scale ?? 1);
  const [timeUnit, setTimeUnit] = useState(intervalObj[reminder.repeat_interval] ?? 'weeks');
  const [repeats, toggleRepeats] = useState(reminder.repeats);
  const [scheduledDate, setScheduledDate] = useState(moment(reminder.due_date, 'YYYYMMDD').toDate());

  const saveEdit = async () => {
    const reqObj = {
      params: ['clients', 'editClientReminder'],
      data: {
        associate,
        description,
        reminderTimeframe,
        repeats: reminderTimeframe === 'timePeriod' ? repeats : null,
        interval: factor,
        scale: timeUnit,
        scheduledDate,
        reminderId: reminder.id,
      },
    };
    await postRequest(reqObj);
    onClose();
    setReload(true);
  };

  return (
    <PopMessage
      popStyle={{ minWidth: '300px', width: '650px', minHeight: '400px', overflow: 'hidden' }}
      confirm="Edit Reminder"
      customContent
      noButtonDisable
      confirmationButtonText="Save"
      onCancel={onClose}
      onConfirm={() => saveEdit()}
    >
      <div key="primary" className="align-left m-top-10">
        <span className="dropDownDescriptionText">Assign an Associate</span>
        <DropdownSelect
          classProps="align-left m-top-8"
          options={allAssoc.map(asc => ({
            label: asc.fullName ? asc.fullName : asc.first_name,
            value: asc.id,
          }))}
          placeholder="Select an Associate..."
          value={associate}
          onChange={obj => {
            setAssociate(obj.value);
          }}
        />
      </div>
      <div className="align-left m-top-24">
        <span className="dropDownDescriptionText">Description</span>
        <input
          id="autoREminderName"
          className="m-btm-15 fs-13 outline-none common_inputStyle max-w-600 w-100-P left-0 input-height "
          value={description}
          onChange={e => setDescription(e.target.value)}
          placeholder="Auto Reminder Name"
          style={{ width: '100%', marginTop: '8px' }}
        />
      </div>
      <div className="align-left m-top-18">
        <span className="dropDownDescriptionText">When do you want to be reminded?</span>
        <DropdownSelect
          classProps="m-top-8 "
          value={reminderTimeframe}
          placeholder="Select Time Frame"
          options={timeframeList}
          onChange={obj => setReminderTimeframe(obj.value)}
        />
      </div>
      {reminderTimeframe && (
        <div className="m-top-24">
          {reminderTimeframe === 'timePeriod' ? (
            <div>
              <div className="flex-row-spacebetween-nowrap w-100-P">
                <NumberInput value={factor} classProps="w-48-P" onChange={val => setFactor(val)} min={0} />
                <DropdownSelect
                  classProps="w-48-P"
                  placeholder="Choose an opportunity..."
                  value={timeUnit}
                  options={timeUnitList}
                  onChange={obj => setTimeUnit(obj.value)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  textAlign: 'left',
                }}
                className="m-top-11"
              >
                <KioskSlider value={repeats} onChange={() => toggleRepeats(!repeats)} />
                <span className="m-left-10 fw-500 " style={{ marginTop: '2px' }}>
                  Repeats
                </span>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                width: '100%',
                textAlign: 'left',
              }}
            >
              <span className="dropDownDescriptionText">Select a Date</span>
              <ReactDatePicker
                popperPlacement="top"
                selected={scheduledDate}
                onChange={date => setScheduledDate(date)}
                customInput={
                  <input className="m-btm-15 fs-13 outline-none common_inputStyle max-w-600 w-100-P left-0 input-height " />
                }
              />
            </div>
          )}
        </div>
      )}
    </PopMessage>
  );
};

export default EditReminderModal;
