// Maps BE API terminology (val) to UI terminology (key)
export const BANK_ACCOUNT_STATUS = {
  PRIMARY: 'ACTIVE',
  INACTIVE: 'ARCHIVED',
  PENDING: 'INACTIVE',
  FAILED: 'FAILED',
};

// Maps UI label to status key, from above
export const BANK_ACCOUNT_STATUS_LABEL = {
  [BANK_ACCOUNT_STATUS.PRIMARY]: {
    text: 'Active',
    color: 'green',
    order: 1,
  },
  [BANK_ACCOUNT_STATUS.INACTIVE]: {
    text: 'Inactive',
    color: 'gray',
    order: 2,
  },
  [BANK_ACCOUNT_STATUS.PENDING]: {
    text: 'In Review',
    color: 'orange',
    order: 3,
  },
  [BANK_ACCOUNT_STATUS.FAILED]: {
    text: 'Failed',
    color: 'red',
    order: 4,
  },
};

// Error messaging
export const ERROR_MESSAGE = {
  EMPTY: 'Please fill in all fields',
  AGREEMENTS: 'Please check both agreements',
};
