import React from 'react';

import { FIELD_PROPS, Input as InputComponent, Pair } from '../Field';
import { HEADING_CLASSES } from '../Wizard';

const CompanyRep = ({ data, active, setDisableContinue, ...props }) => {
  const Input = inputProps => <InputComponent data={data} active={active} {...props} {...inputProps} />;

  return (
    <>
      <fieldset>
        <legend className="fs-13 m-btm-15">
          Please provide information for the person who oversees the business and has significant financial control. For
          example, the CEO, COO, or CFO.
        </legend>

        <Pair>
          <Input id="companyRepFirstName" label="First Name" placeholder="First Name" maxLength="40" />
          <Input id="companyRepLastName" label="Last Name" placeholder="Last Name" maxLength="40" />
        </Pair>
        <Pair>
          <Input {...FIELD_PROPS.DOB} id="companyRepDateOfBirth" />
          <Input id="companyRepGovID" label="Last 4 Digits of SSN" placeholder="Last 4 Digits of SSN" maxLength="4" />
        </Pair>
        <Input {...FIELD_PROPS.PHONE} id="companyRepPhone" label="Phone Number" />
      </fieldset>

      <fieldset>
        <legend className={HEADING_CLASSES}>Company Representative's Address</legend>

        <Input id="companyRepAddress" label="Address" placeholder="Address" maxLength="40" />
        <Pair>
          <Input id="companyRepCity" label="City" placeholder="City" maxLength="25" />
          <Input id="companyRepState" label="State" placeholder="State" maxLength="2" />
        </Pair>
        <Input id="companyRepZip" label="Zip Code" placeholder="Zip Code" maxLength="10" />
        <Input id="companyRepCountry" label="Country" placeholder="Country" defaultValue="US" hidden />
      </fieldset>
    </>
  );
};

export default CompanyRep;
