import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { format, isToday, isYesterday, parse } from 'date-fns';
import { Form, Switch, Tooltip } from 'antd';
import { InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';
import createDateAsUTC from '../../../../../../../../utils/createDateAsUTC';
import './styles.css';
import PopConfirm from '../../../../../../../Layout/PopConfirm';
import usePreviousValue from '../../../../../../../../hooks/usePreviousValue';

const POS_DATE_FORMAT = 'yyyyMMddHHmmss';

const getLastUpdatedDateStatus = (sync, date) => {
  if (!sync) {
    return 'Unsynced';
  }

  if (!date) {
    return (
      <>
        Syncing <SyncOutlined />
      </>
    );
  }

  const lastUpdatedDateString = date.toString();
  const lastUpdatedDate = createDateAsUTC(parse(lastUpdatedDateString, POS_DATE_FORMAT, new Date()));

  if (isToday(lastUpdatedDate)) {
    return `Last synced ${format(lastUpdatedDate, "'today at' HH:mm a O")}.`;
  }

  if (isYesterday(lastUpdatedDate)) {
    return `Last synced ${format(lastUpdatedDate, "'yesterday at' HH:mm a O")}.`;
  }

  return `Last synced ${format(lastUpdatedDate, "MMMM d, yyyy 'at' HH:mm a O")}.`;
};

const IntegrationsSettingsOptions = props => {
  const {
    disabled,
    sync_clients,
    sync_products,
    sync_purchase_history,
    pos_clients_last_updated_date,
    pos_products_last_updated_date,
    pos_purchase_history_last_updated_date,
    storesMap,
    onChange,
  } = props;

  const [form] = Form.useForm();

  const syncClients = Form.useWatch('sync_clients', form);
  const syncProducts = Form.useWatch('sync_products', form);
  const syncPurchaseHistory = Form.useWatch('sync_purchase_history', form);

  const prevSyncClients = usePreviousValue(syncClients);
  const prevSyncProducts = usePreviousValue(syncProducts);
  const prevSyncPurchaseHistory = usePreviousValue(syncPurchaseHistory);

  const initialValues = {
    sync_clients,
    sync_products,
    sync_purchase_history,
  };

  const [showSyncConfirm, setShowSyncConfirm] = useState(false);

  const disableSyncPurchaseHistory = useMemo(
    () => disabled || !sync_clients || !sync_products,
    [disabled, sync_clients, sync_products],
  );

  const handleUpdate = () => {
    const values = form.getFieldsValue();

    const newStores = Object.entries(storesMap).map(([id, pos_ref_num]) => ({
      id: parseInt(id, 10),
      pos_ref_num: pos_ref_num ? `${pos_ref_num}` : null,
    }));

    onChange({
      sync_clients: values.sync_clients,
      sync_products: values.sync_products,
      sync_purchase_history: values.sync_purchase_history,
      stores: newStores,
    });

    setShowSyncConfirm(false);
  };

  const handleCancel = () => {
    form.setFieldsValue({
      sync_clients: prevSyncClients,
      sync_products: prevSyncProducts,
      sync_purchase_history: prevSyncPurchaseHistory,
    });

    setShowSyncConfirm(false);
  };

  const handleChange = value => {
    const everyStoreMapped = Object.values(storesMap).every(item => Boolean(item));

    if (!value || everyStoreMapped) {
      handleUpdate();
    } else {
      setShowSyncConfirm(true);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      sync_clients,
      sync_products,
      sync_purchase_history,
    });
  }, [sync_clients, sync_products, sync_purchase_history]);

  return (
    <>
      <Form form={form} initialValues={initialValues} className="cb-integrations-settings-options">
        <div className="cb-integrations-settings-options__switch">
          <Form.Item name="sync_clients" valuePropName="checked" noStyle>
            <Switch disabled={disabled} onChange={handleChange} />
          </Form.Item>

          <div
            className={`cb-integrations-settings-options__switch-label ${
              disabled && 'cb-integrations-settings__disabled'
            }`}
          >
            <p>Clients</p>
            <p>{getLastUpdatedDateStatus(sync_clients, pos_clients_last_updated_date)}</p>
          </div>
        </div>

        <div className="cb-integrations-settings-options__switch">
          <Form.Item name="sync_products" valuePropName="checked" noStyle>
            <Switch disabled={disabled} onChange={handleChange} />
          </Form.Item>

          <div
            className={`cb-integrations-settings-options__switch-label ${
              disabled && 'cb-integrations-settings__disabled'
            }`}
          >
            <p>Products</p>
            <p>{getLastUpdatedDateStatus(sync_products, pos_products_last_updated_date)}</p>
          </div>
        </div>

        <div className="cb-integrations-settings-options__switch">
          <Form.Item name="sync_purchase_history" valuePropName="checked" noStyle>
            <Switch disabled={disableSyncPurchaseHistory} onChange={handleChange} />
          </Form.Item>

          <div
            className={`cb-integrations-settings-options__switch-label ${
              disabled && 'cb-integrations-settings__disabled'
            }`}
          >
            <p>Sales Transactions</p>
            <p>{getLastUpdatedDateStatus(sync_purchase_history, pos_purchase_history_last_updated_date)}</p>
          </div>

          <Tooltip
            placement="bottom"
            title="You must toggle Clients and Products in order to activate Sales transactions."
            overlayClassName="cb-integrations-settings-options__switch-tooltip"
          >
            <InfoCircleOutlined className="cb-integrations-settings-options__switch-tooltip-icon" />
          </Tooltip>
        </div>
      </Form>

      {showSyncConfirm && (
        <PopConfirm
          confirm="Are you sure you want to proceed?"
          description={
            <>
              <i className="cb-integrations-settings-options__modal-help">
                You have not chosen a mapping for each store location.
              </i>

              <p className="cb-integrations-settings-options__modal-description">
                Doing so is not recommended and may lead to future data issues.
              </p>
            </>
          }
          className="cb-integrations-settings-options__modal"
          onCancel={handleCancel}
          onConfirm={handleUpdate}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  sync_clients: state.auth.sync_clients,
  sync_products: state.auth.sync_products,
  sync_purchase_history: state.auth.sync_purchase_history,
  pos_clients_last_updated_date: state.auth.pos_clients_last_updated_date,
  pos_products_last_updated_date: state.auth.pos_products_last_updated_date,
  pos_purchase_history_last_updated_date: state.auth.pos_purchase_history_last_updated_date,
});

const mapDispatchToProps = dispatch => ({
  setLoading(val) {
    dispatch({
      type: 'SET_SAVING_NOTIF',
      payload: val,
    });
  },
  setSyncClients(val) {
    dispatch({
      type: 'SET_SYNC_CLIENTS',
      payload: val,
    });
  },
  setSyncProducts(val) {
    dispatch({
      type: 'SET_SYNC_PRODUCTS',
      payload: val,
    });
  },
  setSyncPurchaseHistory(val) {
    dispatch({
      type: 'SET_SYNC_PURCHASE_HISTORY',
      payload: val,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsSettingsOptions);
