const url = process.env.REACT_APP_BLUESNAP_API_URL;

export const load = () =>
  new Promise((resolve, reject) => {
    const ID = 'bluesnap-web-sdk';
    const existingScript = document.getElementById(ID);
    const handleResolve = typeof resolve === 'function' ? resolve : () => null;

    if (existingScript) {
      handleResolve();
    } else {
      const script = document.createElement('script');
      script.src = `${url}/web-sdk/4/bluesnap.js`;
      script.id = ID;
      script.onerror = err => reject(err, script);

      document.body.appendChild(script);

      script.onload = () => {
        handleResolve();
      };
    }
  });

export const init = (token, updateToken, onSubmitSuccess, onSubmitError) => {
  window.bluesnap.setTokenProvider(callback => {
    return updateToken().then(token => {
      callback(token);
    });
  });

  window.bluesnap.partnerSecuredCaptureSetup(token, sdkResponse => {
    if ([1, '1'].includes(sdkResponse.code)) {
      onSubmitSuccess(token);
    } else {
      const { errors = [], warnings = [] } = sdkResponse.info;
      onSubmitError([...warnings, ...errors]);
    }
  });
};
