import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PhoneInput from 'react-phone-number-input';
import SearchClientsInput from '../../../../Routes/ProductDetails/Layout/components/SearchClientsInput';

const RelationshipsNew = ({
  handleValidateNewClient,
  handleSaveNewRelationship,
  setShowAddClientForm,
  newClient,
  setNewClient,
  relationship,
  setRelationship,
  setNewRelationship,
  toggleAddClientForm,
  showAddClientForm,
  handleClientChange,
  iso_country_code,
  stores,
}) => {
  const smsStoreNum = stores.map(st => st.sms_number).filter(store => store !== undefined);
  const smsMobile = phone => {
    if (!phone) {
      return true;
    }
    if (phone !== undefined) {
      const smsCompare = smsStoreNum.filter(number => {
        if (number.replace(/[^0-9]+/g, '') == phone.replace(/[^0-9]+/g, '')) {
          return true;
        }
        return false;
      });
      if (smsCompare.length > 0) {
        notificationError('Client number cannot match store number!');
      } else return true;
    }
  };

  const disableNew = () => {
    if (newClient.hasOwnProperty('name') && newClient.name !== '') {
      if (
        (newClient.hasOwnProperty('email') && newClient.email !== '') ||
        (newClient.hasOwnProperty('localNumber') && newClient.localNumber !== '')
      ) {
        return false;
      }
    }
    return true;
  };
  return (
    <div className="flex-col-center h-100-P">
      <div className="flex-col-left m-btm-8 w-100-P m-btm-15">
        {!showAddClientForm ? (
          <div style={{ width: '275px' }} className="align-left">
            {relationship.name ? (
              <div
                style={{ display: 'flex', width: '275px', borderBottom: '1px #818181 solid' }}
                className="flex-row-spacebetween-nowrap"
              >
                <span className={'fs-12 fw-500 black'}>{relationship.name}</span>
                <FontAwesomeIcon
                  icon="fa-duotone fa-circle-xmark"
                  style={{ '--fa-primary-color': '#6b6b6b', '--fa-secondary-color': '#d1d1d1' }}
                  onClick={() => {
                    setRelationship({ ...relationship, name: '' });
                  }}
                />
              </div>
            ) : (
              <div>
                {' '}
                <span className="fs-12 fw-500 gray">Add Relationship</span>
                <SearchClientsInput
                  onSelect={value => {
                    if (value) {
                      setRelationship(value);
                    }
                  }}
                  className="cb-send-product-modal__client-input"
                  posType
                  style={{ margin: '0 0 8px' }}
                  placeholder="Search client"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <div
                    id="add-client-button"
                    style={{ color: '#33AAFC', fontWeight: 600, fontSize: 13, cursor: 'pointer' }}
                    onClick={toggleAddClientForm}
                    role="button"
                    tabIndex={0}
                  >
                    <FontAwesomeIcon className="m-right-8 fs-13" icon={['far', 'user-plus']} />
                    Add Client
                  </div>
                </div>{' '}
              </div>
            )}
            <div
              style={{ display: 'flex', width: '275px', borderBottom: '1px #818181 solid' }}
              className="flex-row-spacebetween-nowrap"
            >
              <input
                className="h-29 rem-pad-width mq-50-P-to-100-P-on-535 border-none outline-none m-top-20"
                style={{ width: '100%' }}
                placeholder="Relationship name"
                value={relationship.relationshipName}
                onChange={e => setRelationship({ ...relationship, relationshipName: e.target.value })}
              />
              <FontAwesomeIcon
                className="m-top-20"
                icon="fa-duotone fa-circle-xmark"
                style={{ '--fa-primary-color': '#6b6b6b', '--fa-secondary-color': '#d1d1d1' }}
                onClick={() => {
                  setRelationship({ ...relationship, relationshipName: '' });
                }}
              />
            </div>
            <div className="mq-w-100-vw custom-action-save-flex m-top-15">
              <button
                className="save-button-v2 m-btm-8"
                disabled={!relationship.name || !relationship.relationshipName}
                type="button"
                onClick={() => handleSaveNewRelationship()}
              >
                Save
              </button>
              <button
                className="cancel-button-v2"
                type="button"
                onClick={() => {
                  setNewClient({});
                  setRelationship({});
                  setNewRelationship(false);
                  setShowAddClientForm(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <>
            <span
              className="fs-13 fw-500"
              style={{
                marginBottom: '15px',
              }}
            >
              Add Client
            </span>

            <div className="relationshipNewDiv">
              <FontAwesomeIcon
                icon="fa-regular fa-user"
                className="pos-abs"
                style={{ top: '2px', marginLeft: '11px' }}
                size="xl"
              />
              <input
                className="h-29 rem-pad-width mq-50-P-to-100-P-on-535 border-none outline-none"
                style={{ width: '100%', borderBottom: '1px #818181 solid', paddingLeft: '57px' }}
                placeholder="Name"
                value={newClient?.name}
                onChange={e => handleClientChange('name', e.target.value)}
              />
            </div>
            <PhoneInput
              placeholder="Enter phone number"
              value={newClient?.localNumber}
              onChange={e => {
                handleClientChange('localNumber', e);
                smsMobile(e);
              }}
              defaultCountry={iso_country_code}
              countryOptionsOrder={[iso_country_code, '|']}
              countrySelectProps={{ backgroundcolor: 'rgb(129, 129, 129' }}
              className="w-100-P relationshipNewInput m-btm-12"
            />
            <div className="relationshipNewDiv">
              <FontAwesomeIcon
                icon="fa-regular fa-envelope"
                size="xl"
                className="pos-abs"
                style={{ top: '4px', marginLeft: '10px' }}
              />
              <input
                className="h-29 rem-pad-width mq-50-P-to-100-P-on-535 border-none outline-none"
                style={{ width: '100%', borderBottom: '1px #818181 solid', paddingLeft: '57px' }}
                placeholder="Email Address"
                value={newClient?.email || ''}
                onChange={e => handleClientChange('email', e.target.value)}
              />
            </div>
            <div className="mq-w-100-vw custom-action-save-flex m-top-15">
              <button
                className="save-button-v2 m-btm-8"
                disabled={disableNew()}
                type="button"
                onClick={() => handleValidateNewClient('newRelationship')}
              >
                Save
              </button>
              <button
                className="cancel-button-v2"
                type="button"
                onClick={() => {
                  setNewClient({});
                  setRelationship({});
                  setNewRelationship(false);
                  setShowAddClientForm(false);
                }}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RelationshipsNew;
