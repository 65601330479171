import React, { useState } from 'react';
import moment from 'moment';

import { ERROR_MESSAGE } from '../../const';
import { Input as InputComponent } from '../Field';

const today = moment().format('MM/DD/YYYY');
const LABEL_STYLES = { display: 'flex', gap: 5, marginBottom: 5 };

const Agreements = ({ data, ...props }) => {
  const Input = inputProps => (
    <InputComponent data={data} {...props} errorMessage={ERROR_MESSAGE.AGREEMENTS} {...inputProps} />
  );

  const [serviceAgreementDate, setServiceAgreementDate] = useState(data.serviceAgreementDate?.value);
  const [pricingAgreementDate, setPricingAgreementDate] = useState(data.pricingAgreementDate?.value);

  const setupCheck =
    fn =>
    ({ target: { checked } }) => {
      const value = checked ? today : '';
      fn(value);
    };

  return (
    <fieldset className="m-btm-41">
      <span className="fw-500 fs-12 d-inline-block m-btm-24">Before continuing, read and accept the terms below</span>
      <label className="fs-13 fw-500" style={LABEL_STYLES}>
        <input type="checkbox" checked={!!serviceAgreementDate} onChange={setupCheck(setServiceAgreementDate)} />I have
        read and consent to{' '}
        <a
          href="https://terms.clbk.app/TCsFinalv5.12.Dec2020.pdf"
          className="no-decoration"
          target="_blank"
          rel="noopener noreferrer"
        >
          Clientbook’s Privacy Agreement
        </a>
      </label>
      <Input label="Service agreement sign date" id="serviceAgreementDate" defaultValue={serviceAgreementDate} hidden />

      <label className="fs-13 fw-500" style={LABEL_STYLES}>
        <input type="checkbox" checked={!!pricingAgreementDate} onChange={setupCheck(setPricingAgreementDate)} />I have
        read and consent to{' '}
        <a
          href="https://terms.clbk.app/ReferredMerchantSchUSD_JUN_2021.pdf"
          className="no-decoration"
          target="_blank"
          rel="noopener noreferrer"
        >
          Clientbook’s Pricing Agreement
        </a>
      </label>
      <Input label="Pricing agreement sign date" id="pricingAgreementDate" defaultValue={pricingAgreementDate} hidden />
    </fieldset>
  );
};

export default Agreements;
