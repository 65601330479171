import React, { useMemo } from 'react';
import { Card, Image, Tooltip } from 'antd';
import DefaultImage from './default-image.png';
import truncateText from '../../../../../../../utils/truncateText';
import InitialsAvatar from '../../../../../../CustomizedAntDesign/InitialsAvatar';
import formatToCurrency from '../../../../../../../utils/formatToCurrency';
import './styles.css';

const CollectionProductCard = props => {
  const { product, onClick } = props;

  const price = useMemo(() => {
    const value = product.vendor_id ? product.msrp : product.price;

    return formatToCurrency(`${value}`);
  }, [product.price, product.vendor_id, product.msrp]);

  return (
    <Card className="clients-v2-collection-product--card" onClick={onClick} hoverable>
      <div className="clients-v2-collection-product--card-header">
        <Image
          src={product.images[0] || DefaultImage}
          width={220}
          height={220}
          preview={false}
          fallback={DefaultImage}
        />

        <div className="clients-v2-collection-product--card-header-price">{price}</div>
      </div>

      <div className="clients-v2-collection-product--card-body">
        <span className="clients-v2-collection-product--card-body-title">
          <Tooltip title={product.name}>{truncateText(product.name, 24, { cutWords: true })}</Tooltip>
        </span>

        <span className="clients-v2-collection-product--card-body-subtitle">{product.id}</span>
      </div>

      <div className="clients-v2-collection-product--card-footer">
        <InitialsAvatar size={25} name={product.added_by_name} style={{ backgroundColor: '#F2A39B' }} />

        <span className="clients-v2-collection-product--card-footer-title">
          <Tooltip title={`Added by  ${product.added_by_name}`}>
            {truncateText(`Added by  ${product.added_by_name}`, 18, { cutWords: true })}
          </Tooltip>
        </span>
      </div>
    </Card>
  );
};

export default CollectionProductCard;
