import React from 'react';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import StandardLayout from '../../../Layout/StandardLayout';
import IntegrationsCards from './components/Cards';
import './styles.css';

const Integrations = () => {
  return (
    <div className="cb-integrations">
      <StandardLayout title="Integrations" classProps="standard-width-with-sidebar">
        <SecondaryPanel title={['SETTINGS', 'HELP', 'PROFILE']} current="Integrations" />
        <IntegrationsCards />
      </StandardLayout>
    </div>
  );
};

export default Integrations;
