/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import KioskSlider from '../../KioskSlider';
import RangePicker from '../../../CustomizedAntDesign/RangePicker';
import useSearchParams from '../../../../hooks/useSearchParams';

const disabledDate = current => {
  return current && current > moment().endOf('day');
};

const LastMessageReceivedFilter = props => {

  const {setValue: setSearchParams, getAll} = useSearchParams();
  const params = getAll();
  const [toggle, setToggle] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [lastMessageReceivedRange, setLastMessageReceivedRange] = useState([null, null]);
  const [lastMessageReceivedValue, setLastMessageReceivedValue] = useState(false);
  const [lastMessageNeverReceivedValue, setLastMessageNeverReceivedValue] = useState(false);

  useEffect(() => {
    if(params?.filterLastMessageReceivedDateRange) {
      setLastMessageReceivedValue(true)
      if(params.filterLastMessageReceivedDateRange.filter(item => item !== null && item !== '').length > 0) {
        const [start, end] = params?.filterLastMessageReceivedDateRange;
        setLastMessageReceivedRange([moment(start, 'YYYYMMDDHHmmss'), moment(end, 'YYYYMMDDHHmmss')])
      } else {
        setLastMessageReceivedRange([null, null])
      }
    } else {
      setLastMessageReceivedValue(false)
      setLastMessageReceivedRange([null, null])
    }

    if(params?.filterLastMessageNeverReceived){
      setLastMessageNeverReceivedValue(true)
      setLastMessageReceivedValue(false)
    } else {
      setLastMessageNeverReceivedValue(false)
    }
  }, [window.location.search])

  const someFilterSelected = useMemo(
    () => params.filterLastMessageReceivedDateRange || params?.filterLastMessageReceivedDateRange?.some(item => item !== null) || params.filterLastMessageNeverReceived,
    [params],
  );

  const handleChangeLastMessageReceived = value => {
    if (!value) {
      setSearchParams('filterLastMessageReceivedDateRange', false)
    } else {
      setSearchParams('filterLastMessageReceivedDateRange', [null, null])
    }
    setSearchParams('filterLastMessageNeverReceived', false)
  };

  const handleChangeLastMessageReceivedDateRange = value => {
    setSearchParams('filterLastMessageReceivedDateRange', value ? `${value[0]?.utc().startOf('day').format('YYYYMMDDHHmmss')},${value[1]?.utc().endOf('day').format('YYYYMMDDHHmmss')}` : null);
    setSearchParams('filterLastMessageNeverReceived', false)
  };

  const handleChangeLastMessageNeverReceived = value => {
    if (value) {
      setSearchParams('filterLastMessageReceivedDateRange', false);
    }
    setSearchParams('filterLastMessageNeverReceived', value)
  };

  const handleClear = event => {
    event.stopPropagation();
    setSearchParams('filterLastMessageReceivedDateRange', false);
    setSearchParams('filterLastMessageNeverReceived', false)
  };

  return (
    <div className="filter-item-container">
      <div className="filter-item-header" onClick={() => setToggle(!toggle)}>
        <div className="filter-item-header-title">Last Message Received</div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {someFilterSelected && (
            <div className="filter-item-clear" onClick={handleClear}>
              Clear
            </div>
          )}

          <FontAwesomeIcon
            icon={['far', `${toggle ? 'chevron-up' : 'chevron-down'}`]}
            className="fs-16"
            color="#818181"
          />
        </div>
      </div>

      {toggle && (
        <div className="filter-item-list-container" style={{ marginTop: '0px' }}>
          <div className="filter-item-list-toggle" style={{ display: 'flex', alignItems: 'center' }}>
            <KioskSlider value={lastMessageReceivedValue} onChange={handleChangeLastMessageReceived} />

            <span className="filter-item-list-toggle-span">Received Between</span>
          </div>

          {lastMessageReceivedValue && (
            <div className="filter-item-list-toggle" style={{ width: '100%' }}>
              <RangePicker
                open={datePickerOpen}
                onOpenChange={setDatePickerOpen}
                format="MM/DD/YYYY"
                disabledDate={disabledDate}
                value={lastMessageReceivedRange}
                onChange={handleChangeLastMessageReceivedDateRange}
                popupStyle={{ zIndex: 10000000 }}
                showToday
                autoFocus
                onFocus={() => setDatePickerOpen(true)}
              />
            </div>
          )}

          <div className="filter-item-list-toggle" style={{ display: 'flex', alignItems: 'center' }}>
            <KioskSlider value={lastMessageNeverReceivedValue} onChange={handleChangeLastMessageNeverReceived} />

            <span className="filter-item-list-toggle-span">Never Received</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LastMessageReceivedFilter;
