import React from 'react';
import StandardLayout from '../../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../../Layout/SecondaryPanel';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AutoWhatsAppSettings from '../../../../../Layout/AutoWhatsAppTemplate/AutoWhatsAppSettings';

const TagAutoWhatsapp = props => {
  const history = useHistory();
  const listItemId = props.match.params.listItemId;

  const setRedirectBack = () => {
    history.push(`/Configuration/tags/edit-tag/${listItemId}`);
  };

  return (
    <StandardLayout
      title={`${props.autoRemMsgs.reminderAddOrEdit} WhatsApp Auto Template`}
      // description={`${props.autoRemMsgs.reminderAddOrEdit} an auto reminder that is linked to this tag`}
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Tags', to: '/Configuration/tags' },
        { title: 'Edit Tag', to: `/Configuration/tags/edit-tag/${listItemId}` },
        { title: `${props.autoRemMsgs.reminderAddOrEdit} WhatsApp Auto Template` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <AutoWhatsAppSettings
        setRedirectBack={setRedirectBack}
        cancelRoute={`/Configuration/tags/edit-tag/${listItemId}`}
        descriptionText={'How soon after this client is tagged would you like to receive this reminder?'}
        popConfirmDesc={'This will remove the WhatsApp template from this tag'}
        editRoute={`/Configuration/tags/edit-tag/${listItemId}`}
        type={'tags'}
        match={props.match}
      />
      <SecondaryPanel title={['Automation']} current="Tags" />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  autoRemMsgs: state.autoRemMsgs,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TagAutoWhatsapp);
