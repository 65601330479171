import React from 'react'

const TimelineItem = ({ title, description}) => {
  return (
    <div className="flex-col-left">
      <p style={{ margin: 0}}>{title}</p>
      <p className='fs-12 line-clamp-2' style={{opacity: 0.45, margin: 0}}>{description}</p>
    </div>
  )
};

export default TimelineItem
