/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import { Image } from 'antd-v5';
import './styles.scss';

const NOT_FOUND_IMAGE = 'https://dashboard-v2-images.s3.amazonaws.com/not-found.png';

const Carousel = props => {
  const { images } = props;

  if (!images.length) {
    return <Image src={NOT_FOUND_IMAGE} fallback={NOT_FOUND_IMAGE} alt="Product" />;
  }

  const primaryImage = useMemo(() => images.find(image => image.isPrimary) || images[0], [images]);

  const [selectedImage, setSelectedImage] = useState(primaryImage);

  useEffect(() => {
    setSelectedImage(primaryImage);
  }, [primaryImage]);

  return (
    <div className="cb-carousel">
      <div className="cb-carousel__preview">
        <Image src={selectedImage.url || NOT_FOUND_IMAGE} fallback={NOT_FOUND_IMAGE} alt="Product" />
      </div>

      <div className="cb-carousel__track">
        {images.map(image => (
          <Image
            key={image.url}
            src={image.url || NOT_FOUND_IMAGE}
            fallback={NOT_FOUND_IMAGE}
            preview={false}
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
