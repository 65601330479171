import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LoadingSpinner = () => {
  return (
    <div className="w-100-P h-247 flex-col-center">
      <div className="d-grid">
        <FontAwesomeIcon icon={['far', 'spinner-third']} spin={true} className="blue fs-80 d-grid-stack" />
        <img
          alt="#"
          className="d-grid-stack m-left-15 m-top-15"
          style={{ marginTop: '25px', marginLeft: '30px' }}
          src="https://dashboard-v2-images.s3.amazonaws.com/clientbook-quill-blue.svg"

        />
      </div>
      <span className="fs-20 fw-600 white m-top-50">Loading...</span>
    </div>
  );
};
export default LoadingSpinner;
