import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteRequest, getRequest } from '../../../../../core/apiRequests';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';
import Layout from '../../../../Layout/StandardLayout';
import Table from '../../../../Layout/Table';
import StyledLink from '../../../../Layout/StyledLink';
import PopConfirm from '../../../../Layout/PopConfirm';
import { getTablePaginationData, setTablePagination } from '../../../../../core/actions';
import { convertMessageTemplate } from '../../../../../utils/convertMessageTemplate';

const tableName = 'AUTO_MESSAGE_RUNTIME';

const AutoMessageRuntime = props => {
  const [tableData, setTableData] = useState([]);
  const [activeHover, setActiveHover] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [messageObj, setMessageObj] = useState({});

  let hoverTimeout;

  useEffect(() => {
    if (props.auth.merchantId) {
      getAutoMessages();
    }
  }, [props.auth.merchantId, props.pagination.pageSize, props.pagination.page]);

  const getAutoMessages = () => {
    (async () => {
      const reqObj = {
        params: ['chatMessages', props.auth.merchantId, 'getAutoMsgRuntime'],
        query: {
          count: props.pagination.pageSize,
          page: props.pagination.page,
        },
      };
      const dataReq = await getRequest(reqObj);
      if (dataReq) {
        const {
          results,
          pageInfo: { page, pageSize, rowCount, pageCount },
        } = dataReq;
        const arrayWithMouseOver = results.map(item => ({
          ...item,
          onMouseOver: obj => {
            clearTimeout(hoverTimeout);
            hoverTimeout = setTimeout(() => {
              setActiveHover(obj.id);
            }, 250);
          },
          onMouseLeave: () => {
            clearTimeout(hoverTimeout);
            setActiveHover('');
          },
        }));
        setTableData(arrayWithMouseOver);
        setTablePagination(
          {
            rowCount,
            pageCount,
          },
          tableName,
        );
      }
    })();
  };

  const tableHeaders = [
    {
      keyName: 'scheduled_date_time',
      style: { maxWidth: '2%', minWidth: '110px' }, // we can apply all styles to the header now.
      headerClassName: 'no-text-select', // This targets the header's className
      title: 'Scheduled Date',
      sortable: false,
      rowClassName: 'no-text-select',
      render: (t, o) => moment(t, 'YYYYMMDDHHmmss').format('MMM DD, YYYY'),
    },
    {
      keyName: 'client_name',
      style: { width: '15%', paddingRight: '30px', minWidth: '200px' },
      headerClassName: 'row4 no-text-select',
      title: 'Client Name',
      rowClassName: 'blue-link',
      sortable: false,
      render: (t, o) => {
        return <StyledLink to={`/Clients?client=${o.client_id}`}>{t}</StyledLink>;
      },
    },
    {
      keyName: 'message',
      style: { width: '20%', paddingRight: '30px', minWidth: '130px' },
      headerClassName: 'row4 no-text-select',
      title: 'Message',
      sortable: false,
      render: (msg, item) => {
        return <div className='line-clamp-1' dangerouslySetInnerHTML={{ __html: convertMessageTemplate(msg)}} />
      }
    },
    {
      keyName: 'image_url',
      style: { width: '15%', paddingRight: '30px', minWidth: '130px' },
      headerClassName: 'row4 no-text-select',
      title: 'Attachments',
      sortable: false,
      render: t => {
        if (t) {
          return <img key={t} className="border-r4 photoFit h-167 w-167 m-right-15" src={t} alt="#" />;
        }
      },
    },
    {
      keyName: 'scope',
      style: { width: '15%', paddingRight: '30px', minWidth: '110px' },
      headerClassName: 'row4 no-text-select',
      title: 'Scope',
      sortable: false,
      render: (t, o) => {
        const { life_event_type, tag_type, custom_event_type, sales_opp_type, sales_trigger_type } = o;
        if (life_event_type) {
          return 'Life Event';
        }
        if (tag_type) {
          return 'Tag';
        }
        if (custom_event_type) {
          return 'Custom Event';
        }
        if (sales_opp_type) {
          return 'Sales Opp';
        }
        if (sales_trigger_type || (o.sales_trigger_action_id && !sales_trigger_type)) {
          return 'Sales Trigger';
        }
      },
    },
    {
      keyName: 'type',
      style: { width: '20%', paddingRight: '30px', minWidth: '130px' },
      headerClassName: 'row4 no-text-select',
      title: 'Type',
      sortable: false,
      render: (t, o) => {
        const { life_event_type, tag_type, custom_event_type, sales_opp_type, sales_trigger_type } = o;
        let msgText = life_event_type || tag_type || custom_event_type || sales_opp_type || sales_trigger_type;
        if (msgText) {
          if (msgText.length > 60) {
            return `${msgText.substr(0, 60)}...`;
          }
        } else if (o.sales_trigger_action_id && !msgText) {
          msgText = 'Trigger Removed';
        }
        return msgText;
      },
    },
    {
      keyName: 'repeats',
      style: { width: '15%', paddingRight: '30px', minWidth: '30px' },
      headerClassName: 'row4 no-text-select align-center',
      title: 'Repeats',
      sortable: false,
      render: (t, o) => {
        if (Number(t)) {
          return (
            <label className="tags-checkmark pos-rel">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </label>
          );
        }
      },
    },
    {
      keyName: 'id',
      style: { width: '25px' },
      headerClassName: 'align-left rem-pad-width',
      rowClassName: 'align-left rem-pad-width ',
      title: '',
      sortable: false,
      render: (text, obj) => {
        if (obj.id === activeHover) {
          return (
            <div
              className="w-33 h-33 bg-white border-r4 pointer"
              style={{ marginRight: '5px' }}
              id="deleteTemplate"
              onMouseEnter={e => {
                e.target.style.backgroundColor = '#e8f6ff';
              }}
              onMouseLeave={e => {
                e.target.style.backgroundColor = '#ffffff';
              }}
              onClick={e => stopEventBubbling(e.target, obj)}
            >
              <FontAwesomeIcon
                icon={['far', 'trash']}
                size="1x"
                className="fs-16 pointer"
                style={{ color: 'black', marginTop: '8px', marginLeft: '10px' }}
              />
            </div>
          );
        }
        return <div className="w-33 h-33 border-r4 pointer" style={{ marginRight: '5px' }} />;
      },
    },
  ];

  const stopEventBubbling = (target, obj) => {
    if (target.tagName === 'svg' || target.id === 'deleteTemplate') {
      setOpenPopup(true);
      setMessageObj(obj);
    }
  };

  const deleteHandler = () => {
    (async () => {
      const reqObj = {
        params: ['chatmessages', props.auth.merchantId, 'deleteClientAutoMsg'],
        query: {
          id: messageObj.id,
        },
        delay: 2200,
      };
      const deleteReq = await deleteRequest(reqObj);
      if (deleteReq) {
        getAutoMessages();
      } else {
        console.error('Error deleting auto message');
      }
      setOpenPopup(false);
    })();
  };

  return (
    <Layout
      title="Upcoming Messages"
      description="View all scheduled Auto Messages"
      classProps="standard-width-with-sidebar rem-pad-width"
      breadCrumbs={[{ title: 'Messaging' }, { title: 'Upcoming Messages', to: '/Messaging/auto-msg-runtime' }]}
    >
      <Table
        tableClassName="lg-table-sidebar rem-pad-width"
        data={tableData}
        tableHeaders={tableHeaders}
        searchable={false}
        paginationV2
        tableName={tableName}
      />
      {openPopup ? (
        <PopConfirm
          customContent={false}
          confirm="Cancel all future instances of this message for this client?"
          onCancel={() => setOpenPopup(false)}
          onConfirm={() => deleteHandler()}
          noButtonDisable
        />
      ) : null}
      <SecondaryPanel title={['Messaging']} current="Auto Messages" />
    </Layout>
  );
};
const mapStateToProps = state => ({
  ...state,
  pagination: getTablePaginationData(state, tableName),
});
export default connect(mapStateToProps, {})(AutoMessageRuntime);
