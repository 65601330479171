import React from 'react';
import LoadingSpinner from '../../components/Layout/Notifications/LoadingSpinner';


export default (
  <div
    className="w-100-vw h-100-vh center"
    style={{ display: 'table-cell', verticalAlign: 'middle' }}
  >
    <LoadingSpinner />
  </div>
);
