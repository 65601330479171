/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { addMinutes, format, parse } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import formatToCurrency from '../../../../../utils/formatToCurrency';
import { getClientMessages, getClientSales } from '../../../../../api/clients';
import truncateText from '../../../../../utils/truncateText';
import './styles.scss';
import { useQuery } from 'react-query';
import { LoadingOutlined } from '@ant-design/icons';

const EdisonAiClientDetailsChatMessage = props => {
  const { imageUrl, videoUrl, direction, isPayment, chatId, collection, product, message, user, sentDateTime } = props;

  const parsedSentDateTime = useMemo(() => {
    const parsedDate = parse(`${sentDateTime}`, 'yyyyMMddHHmmss', new Date());
    const myTimezoneOffset = new Date().getTimezoneOffset() *  -1;

    return format(addMinutes(parsedDate, myTimezoneOffset),  'MMMM d, y hh:mm a');
  }, [sentDateTime]);

  const messageText = useMemo(() => {
    if (message) {
      return truncateText(message, 250, { cutWords: true });
    }

    let text = '';
    let icon = null;

    if (isPayment) {
      text = 'Payment';
      icon = <FontAwesomeIcon icon="fa-regular fa-file-invoice-dollar" color="#818181" />;
    }

    if (imageUrl) {
      text = 'Image';
      icon = <FontAwesomeIcon icon="fa-regular fa-image" color="#818181" />;
    }

    if (videoUrl) {
      text = 'Video';
      icon = <FontAwesomeIcon icon="fa-sharp fa-regular fa-file-video" color="#818181" />;
    }

    if (collection) {
      text = 'Collection';
      icon = <FontAwesomeIcon icon="fa-regular fa-rectangle-history-circle-plus" color="#818181" />;
    }

    if (product) {
      text = 'Product';
      icon = <FontAwesomeIcon icon="fa-sharp fa-regular fa-bag-shopping" color="#818181" />;
    }

    text += direction === 'inbound' ? ' received' : ' sent';

    return (
      <>
        {icon} {text}
      </>
    );
  }, [imageUrl, videoUrl, direction, isPayment, chatId, collection, product, message]);

  return (
    <div
      className={`cb-edison-ai-client-details__chat-message ${`cb-edison-ai-client-details__chat-message-${direction}`}`}
    >
      <div className="cb-edison-ai-client-details__chat-message-container">
        <div className="cb-edison-ai-client-details__chat-message-header">
          <span>{user.name}</span>
          <span>{parsedSentDateTime}</span>
        </div>

        <div className="cb-edison-ai-client-details__chat-message-body">{messageText}</div>
      </div>
    </div>
  );
};

const EdisonAiClientDetailsChat = props => {
  const { messages } = props;

  return (
    <div className="cb-edison-ai-client-details__chat">
      {messages.map(item => (
        <EdisonAiClientDetailsChatMessage key={item.id} {...item} />
      ))}
    </div>
  );
};

const EdisonAiClientDetails = props => {
  const { lead, setShowLead } = props;

  const history = useHistory();

  const { data: recentPurchases, isLoading } = useQuery(['recentPurchases', lead.client.id, lead.storeId], async () => {
    try {
      const clientId = lead.client.id;

      const data = {
        params: {
          limit: 3,
          sort: 'transactionDateTime',
          storeId: lead.storeId,
        },
      };

      const response = await getClientSales(clientId, data);

      return response.data;
    } catch (error) {
      return []
    }
  }, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const {data: recentMessages, isLoading: isLoadingMsg} = useQuery(['recentMessages', lead.client.id, lead.storeId], async () => {
    try {
      const clientId = lead.client.id;

      const data = {
        params: {
          limit: 2,
          sort: '-sentDateTime',
          storeId: lead.storeId,
        },
      };

      const response = await getClientMessages(clientId, data);

      return response.data;
    } catch (error) {
      return []
    }
  }, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const getTransactionDateTime = useCallback(transactionDateTime => {
    const slicedDate = `${transactionDateTime}`.slice(0, 8);
    const parsedDate = parse(slicedDate, 'yyyyMMdd', new Date());

    return format(parsedDate, 'MMMM d, y');
  }, []);

  const handleRedirectToClientProfile = useCallback(() => {
    history.push(`/Clients?client=${lead.client.id}`);
    setShowLead(false);
  }, [lead.client.id, history]);


  return (
    <div className="cb-edison-ai-client-details">
      <h2 className="cb-edison-ai-client-details__title">Client Details</h2>

      <section style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="cb-edison-ai-client-details__grey-text">Total Spend</span>
          <strong>{formatToCurrency(lead?.attributes.total_spend || 0)}</strong>
        </div>
      {lead.attributes.avg_purchase_interval ?
        (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="cb-edison-ai-client-details__grey-text">Avg Frequency</span>
            <strong>{lead.attributes.avg_purchase_interval}</strong>
          </div>
        ) : <div />
      }
      </section>
      <section style={{ marginBottom: '12px' }}>
        <h2 className="cb-edison-ai-client-details__grey-text">Recent Purchases</h2>
        {isLoading ? (
          <LoadingOutlined />
        ) : (
          recentPurchases.length > 0 ? recentPurchases.map((item, index) => (
            <div key={`${index}-${item.product.id}`}>
              <span>{item.product.name}</span>

              <div className="cb-edison-ai-client-details__grey-text" style={{ display: 'flex', gap: '12px' }}>
                <span>{formatToCurrency(`${item.unitPrice * item.quantity}`)}</span>

                <span>{getTransactionDateTime(item.transactionDateTime)}</span>

                <span>{item.product.refNum}</span>
              </div>

              {index !== recentPurchases.length ? <hr className="cb-edison-ai-client-details__hr" /> : null}
            </div>
          )) : <span style={{ opacity: 0.65 }}>No recent purchases</span>
        )}
      </section>


        <section style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
          <h2 className="cb-edison-ai-client-details__grey-text">Recent Conversation</h2>
          {isLoadingMsg ? (
            <LoadingOutlined />
          ) : recentMessages.length ? (
              <EdisonAiClientDetailsChat messages={recentMessages} />
            ) : <span style={{ opacity: 0.65 }}>No recent messages</span>
          }
        </section>

      {lead.attributes.sales_opp_type && lead.attributes.sales_opp_created_date ? (
        <section style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
          <h2 className="cb-edison-ai-client-details__grey-text">Sales Opportunity</h2>
          <span>{lead.attributes.sales_opp_type}</span>
          <span className="cb-edison-ai-client-details__grey-text">{lead.attributes.sales_opp_created_date}</span>
        </section>
      ) : null}

      <section style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="cb-edison-ai-client-details__grey-text">Birthday</span>
            {lead.attributes.birthday ? (
              <span>{lead.attributes.birthday}</span>
            ) : <span style={{ opacity: 0.65 }}>None</span>}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="cb-edison-ai-client-details__grey-text">Anniversary</span>
            {lead.attributes.anniversary ? (
              <span>{lead.attributes.anniversary}</span>
            ) : <span style={{ opacity: 0.65 }}>None</span>}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="cb-edison-ai-client-details__grey-text">Spouse</span>
            {lead.attributes.spouse_name ? (
              <span>{lead.attributes.spouse_name}</span>
            ) : <span style={{ opacity: 0.65 }}>None</span>}
          </div>
      </section>

      <button
        type="button"
        className="transparentButton m-top-20 border-none bg-white"
        style={{ color: '#33aafc', margin: '0', padding: '0' }}
        onClick={handleRedirectToClientProfile}
      >
        Go to Client
      </button>
    </div>
  );
};

export default EdisonAiClientDetails;
