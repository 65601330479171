import React from 'react';
import { connect } from 'react-redux';

const PaymentBannerSpacer = ({ paymentBannerActive }) => {
  const height = paymentBannerActive ? 50 : 0;
  return <div style={{ height }} />;
};

const mapStateToProps = state => ({
  paymentBannerActive: state.paymentBanner.active,
});

export default connect(mapStateToProps)(PaymentBannerSpacer);
