const moment = require('moment');

const formatDataForInterval = interval => {
  switch (interval) {
    case 'monthly':
      return {
        format: 'MM/YYYY',
        addAmount: 1,
        addUnit: 'M',
        startOf: 'month',
        propertyCheck: 'ts_month',
        getKeyFromData: ({ ts_month: month, ts_year: year }) => {
          return `${moment(month, 'M').format('MM')}/${moment(year, 'YYYY').format('YYYY')}`;
        },
      };
    case 'daily':
      return {
        format: 'MM/DD/YYYY',
        titleFormat: 'MM/DD/YY',
        addAmount: 1,
        addUnit: 'days',
        propertyCheck: 'ts_day',
        getKeyFromData: ({ ts_month: month, ts_year: year, ts_day: day }) => {
          return `${moment(month, 'M').format('MM')}/${moment(day, 'D').format('DD')}/${moment(year, 'YYYY').format(
            'YYYY',
          )}`;
        },
      };
    default:
      return {
        format: 'MM/DD',
        addAmount: 7,
        addUnit: 'days',
        startOf: 'week',
        modifyEndDate: {
          endOf: 'week',
          add: 1,
          unit: 'days',
        },
        propertyCheck: 'week_start',
        getKeyFromData: ({ week_start: weekStart }) => {
          return moment(weekStart, 'MM/DD').format('MM/DD');
        },
      };
  }
};

const generateLabelsAndHeaders = ({
  startDate,
  endDate: endDateString,
  formatData: { format, titleFormat, addAmount, addUnit, startOf, modifyEndDate },
}) => {
  let currentDate = moment(startDate).startOf('day');
  let endDate = moment(endDateString);
  if (modifyEndDate) {
    endDate = endDate.endOf(modifyEndDate.endOf).add(modifyEndDate.add, modifyEndDate.unit);
  }

  const labels = [];
  const headers = [
    {
      keyName: 'name',
      style: { width: '5%', paddingTop: '80px', paddingRight: '10px' },
      headerClassName: 'row4',
      title: 'Name',
      sortable: false,
    },
  ];
  const inbound = { name: 'Inbound' };
  const outbound = { name: 'Outbound' };

  while (currentDate.isBefore(endDate)) {
    const key = currentDate.format(format);
    labels.push(key);
    headers.push({
      keyName: key,
      style: { width: '5%', paddingTop: '80px', paddingRight: '10px' },
      headerClassName: 'row4',
      title: currentDate.format(titleFormat ?? format),
      sortable: false,
    });
    inbound[key] = 0;
    outbound[key] = 0;
    currentDate = startOf ? currentDate.add(addAmount, addUnit).startOf(startOf) : currentDate.add(addAmount, addUnit);
  }
  return {
    labels,
    headers,
    inbound,
    outbound,
  };
};

const parseResults = ({ formatData: { getKeyFromData, propertyCheck }, data }) => {
  const inbound = {};
  const outbound = {};
  for (const d of data) {
    if (!d.hasOwnProperty(propertyCheck)) {
      continue;
    }
    const key = getKeyFromData(d);
    const resultSet = d.scope === 'inbound' ? inbound : outbound;
    resultSet[key] = d.cnt;
  }
  return {
    inbound,
    outbound,
  };
};

const formatData = ({ startDate, endDate, interval, data }) => {
  const formatData = formatDataForInterval(interval);
  const { labels, headers, inbound, outbound } = generateLabelsAndHeaders({
    startDate,
    endDate,
    formatData,
  });
  const parsedData = parseResults({
    formatData,
    data,
  });
  return {
    labels,
    headers,
    inbound: Object.assign({}, inbound, parsedData.inbound),
    outbound: Object.assign({}, outbound, parsedData.outbound),
  };
};

export default formatData;
