/* eslint-disable import/extensions */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Group from 'antd/lib/input/Group';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Layout from '../../../Layout/StandardLayout';
import '../help.css';
import { notificationSuccess } from '../../../../core/actions';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import TitleAndText from './WebchatComponents/TitleAndText.jsx';
import WebchatCustomize from './WebchatComponents/WebchatCustomize.jsx';
import GoogleAnalytics from './WebchatComponents/GoogleAnalytics.jsx';
import BlackListedPages from './WebchatComponents/BlackListedPages';
import Input from '../../../CustomizedAntDesign/Input/Input';
import Button from '../../../CustomizedAntDesign/Button';

const WebChat = props => {
  // const [textArea, setTextArea] = useState(null);
  const [textAreaCustom, setTextAreaCustom] = useState(null);
  const [obj, setObj] = useState({
    background: '#33AAFC',
    openingStatementOff: '',
    openingStatementText: '',
    defaultImage: 'https://s3.amazonaws.com/images.clbk.app/Webchat/default_wbcht_one.jpg',
    image: '',
    singleStore: '',
    screenLocation: '',
    googleAnalytics: {
      google_id: '',
      initiation_clicks: 'chat_initiated',
      textMe_clicks: 'chat_submit',
      closing_clicks: 'chat_closed',
    },
    blackListed: '',
    regex: '',
    whiteListed: '',
    poweredBy: '',
  });

  useEffect(() => {
    const itemsFrmLocalStorage = JSON.parse(localStorage.getItem('webchatItems'));
    if (itemsFrmLocalStorage) {
      setObj(itemsFrmLocalStorage);
    }
  }, []);

  // setting to local storage
  useEffect(() => {
    localStorage.setItem('webchatItems', JSON.stringify(obj));
  }, [obj]);

  const copyCodeToClipboardCustom = async () => {
    window.getSelection().removeAllRanges();
    const range = document.createRange();
    range.selectNode(textAreaCustom);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    notificationSuccess('Script Copied!');
  };

  // const initRef = c => setTextArea(c);
  const initRefCustom = m => setTextAreaCustom(m);

  const handleCopy = () => {
    notificationSuccess('Copied to clipboard');
  };

  return (
    <Layout
      title="Web Chat Setup"
      description="Install a personalized web chat in just a few easy steps!"
      breadcrumb="Help >> Web Chat Setup"
      breadCrumbs={[{ title: 'Help' }, { title: 'Web Chat Setup' }]}
      classProps="standard-width-with-sidebar"
    >
      <div className="w-100-P h-100-P">
        <TitleAndText
          key="1"
          title="1. How did you build your website?"
          text={[
            'Before starting the installation process, it is important to understand how your website was created.',
            'If you used HTML, pull up the code to your site.',
            'If you used a website builder like Wix or WordPress, you will need to download a developer plugin that coincides with the builder you used. Make sure you download a plugin that allows you to edit the header and footer of the script. In order for the web chat to appear on your website, you need to have the ability to add scripts just before the closing </body> tag. After making sure you have the correct plugin, pull up the website code on your screen.',
          ]}
        />
        <TitleAndText
          key="2"
          title="2. Locate the </body> tag in your code"
          text={[
            'Find the </body> tag to your code. Do this quickly by using CTRL + F. In order for changes to be made to your code, the script needs to be pasted between the two </body> tags. To ensure the web chat works flawlessly, make sure you paste it as close to the </body> tag as possible. This helps avoid sequencing issues with existing code. ',
          ]}
        />
        <TitleAndText
          key="3"
          title="3. Customize your web chat"
          text={['Add a real human touch by tailoring your web chat to your business and design needs.']}
        />
        {/* customizations start here */}
        <WebchatCustomize setObj={setObj} obj={obj} auth={props.auth} />
        {/* Google Analytics */}
        <GoogleAnalytics setObj={setObj} obj={obj} />
        {/* black listed pages  */}
        <BlackListedPages setObj={setObj} obj={obj} />
        {/* script */}
        <div className="copyCode maw-1200">
          <div>
            <p className="align-left fs-13 black fw-500 m-btm-0"> Final Script </p>
            <p className="align-left align-left gray fw-500 fs-11 m-top-0 ">
              This is the final script for your personalized web chat! Copy the code to your clipboard and proceed to
              step four.
            </p>
          </div>
          <div className="import_export_group">
            <button
              type="button"
              id="WebchatScriptTagCopyButton"
              className="pointer"
              onClick={copyCodeToClipboardCustom}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <img
                src="https://dashboard-v2-images.s3.amazonaws.com/copyCode.svg"
                alt="copy code"
                style={{ marginTop: '13px' }}
              />
              <p className="blue">Copy Code</p>
            </button>
          </div>
        </div>
        <div className="maw-1200">
          <div className="border-r8-grey">
            <p
              id="script"
              className=" align-left fs-13 m-left-15 w-500"
              style={{ fontFamily: 'Roboto Mono' }}
              ref={initRefCustom}
            >
              {`<script>
        (function (u, c, o, i, s, l, b, gid, oc, sb, cc, pb) {
            if(urlCheck(window.location.href)) {window["ClientbookWebChatObject"] = {u: u,c: c,w: o,i: i,s: s,l: l,b: b,
              gid: gid, oc: oc, sb: sb, cc: cc, pb: pb};
                var ga = document.createElement("script");
                ga.type = "text/javascript";
                ga.async = true;
                ga.src = "https://s3.amazonaws.com/webchatv2stage.clientbook.com/wc.js";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(ga, s);
            }
            function urlCheck(theUrl) {
                const blackListedUrls = [${obj.blackListed}];
                const regexBlackListing = [${obj.regex}];
                const whiteListedUrls = [${obj.whiteListed}];
                var isItGood = true;
                if(Array.isArray(blackListedUrls) && blackListedUrls.length > 0) {
                    blackListedUrls.forEach(bL => {
                        if(theUrl.includes(bL)) {
                            isItGood = false;
                        }
                    })
                }
                if(Array.isArray(whiteListedUrls) && whiteListedUrls.length > 0) {
                    whiteListedUrls.forEach(bL => {
                        if(!theUrl.includes(bL)) {
                            isItGood = false;
                        }
                    })
                }
                if(Array.isArray(regexBlackListing) && regexBlackListing.length > 0) {
                    regexBlackListing.forEach(reggy => {
                        const test = theUrl.match(reggy);
                        if(test) {
                            isItGood = false;
                        }
                    })
                }
                return isItGood;
            }
        })("`}
              {props.auth.uuid}
              {`", "${obj.background}", "${obj.openingStatementText}", "${obj.defaultImage || obj.image}", "${
                obj.singleStore
              }", "${obj.screenLocation}", "${obj.openingStatementOff}", "${obj.googleAnalytics.google_id}",
              "${obj.googleAnalytics.initiation_clicks}", "${obj.googleAnalytics.textMe_clicks}", "${
                obj.googleAnalytics.closing_clicks
              }", "${obj.poweredBy}")</script>`}
            </p>
          </div>
        </div>
        <TitleAndText
          key="4"
          title="4. Paste your new code just above the </body> tag "
          text={[
            'Important: Before saving your new code, make sure to check for smart quotes!',
            'Many plugins automatically change regular double quotes to smart quotes. Your code implementation will NOT work if it has the smart double quotes. Remove all smart quotes before saving Clientbook’s script to your website.',
          ]}
        />
        <div className="maw-1200">
          <img
            src="https://dashboard-v2-images.s3.amazonaws.com/smartQuotes.png"
            alt="smart quotes"
            style={{ width: '550px', height: '250px', marginRight: '770px' }}
          />
          <p className="align-left gray  fs-13">
            {' '}
            Return to your current website code you pulled up in step one, and paste your personalized script just above
            the second {'</body>'} tag.
          </p>
        </div>
        <TitleAndText
          key="5"
          title="5. After the customization process is complete, save your new code!"
          text={[
            'For any questions regarding the web chat, reach out to our Customer Success team at support@clientbook.com or (385) 352-3518.',
          ]}
        />

        <TitleAndText
          key="6"
          title="Web Inquiry API:"
          text={[
            'Use your merchant_uuid to call the web inquiry API. You can also select a specific store by sending the store_uuid (available in stores list) in the request body.',
          ]}
        />

        <Group
          compact
          style={{
            display: 'flex',
            textAlign: 'left',
            height: '48px',
            maxWidth: '550px',
          }}
        >
          <Input
            value={props.auth.uuid}
            readOnly
            style={{
              borderRadius: '4px 0 0 4px',
            }}
          />

          <CopyToClipboard text={props.auth.uuid} options={{ onCopy: handleCopy }}>
            <Button
              type="primary"
              style={{
                borderRadius: '0 4px 4px 0',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CopyOutlined />
            </Button>
          </CopyToClipboard>
        </Group>
      </div>
      <SecondaryPanel title={['SETTINGS', 'HELP', 'PROFILE']} current="Web Chat Setup" />
    </Layout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(WebChat);
