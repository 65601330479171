import React from 'react';
import { List } from 'antd';
import ChecklistDropdownPopoverListItem from '../ChecklistDropdownPopoverListItem';

const ChecklistDropdownPopoverList = props => {
  const { items, itemsChecked, onChange } = props;

  return (
    <List
      size="small"
      dataSource={items}
      renderItem={item => (
        <ChecklistDropdownPopoverListItem
          key={item}
          item={item}
          checked={itemsChecked[item]}
          onChange={() => onChange(item)}
        />
      )}
    />
  );
};

export default ChecklistDropdownPopoverList;
