import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { notificationSuccess, notificationError } from '../../../../../core/actions';
import PopConfirm from '../../../../Layout/PopConfirm';
import DropdownSelect from '../../../../Layout/DropdownSelect';
import Tooltip from '../../../../Layout/ToolTip';

const ImportData = props => {
  const [showModal, setShowModal] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  let clicked = false;

  const storeCheck = () => {
    if (props.endPoint === 'clients') {
      console.log("endpoint is clients")
      if (props.selStore) {
        console.log("selected store is : ", props.selStore)
        props.setSelStore(props.selStore);
        props.setEndPoint(props.endPoint);
        props.setUploadModal('chooseFile');
      } else {
        console.log("No selected store :( ")
        const notAllStores = props.stores.filter(st => Number(st.id) !== 0);
        if (notAllStores.length === 1 && notAllStores[0].hasOwnProperty('id')) {
          props.setSelStore(notAllStores[0].id);
          props.setEndPoint(props.endPoint);
          props.setUploadModal('chooseFile');
          props.setShowModal(false);
        } else {
          setShowModal(true);
        }
      }
    } else {
      props.setEndPoint(props.endPoint);
      props.setUploadModal('chooseFile');
      if (props.setShowModal) {
        props.setShowModal(false);
      }
    }
  };

  const confirmSelectedStore = () => {
    if (props.selStore) {
      props.setEndPoint(props.endPoint);
      props.setUploadModal('chooseFile');
      setShowModal(false);
      if (props.setShowModal) {
        props.setShowModal(false);
      }
    } else {
      notificationError('Please Select a Store For Client Import');
    }
  };

  return [
    <div
      className="pos-rel m-top-30 m-btm-28 "
      key="buttonParent"
      style={{ height: '45px', padding: '5px', width: '212px' }}
      onMouseEnter={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
    >
      <Tooltip
        description="To avoid data duplication, client upload is not available for stores with POS integrations"
        isShown={props.disabled && showToolTip}
        idx={true}
        toolTipRight="-40px"
        toolTipBottom="70px"
        toolTipWidth="300px"
        arrowLeft="135px"
      />
      <span>
        <button
          key="button"
          id={props.importUploadButton}
          onClick={() => storeCheck()}
          style={{ width: '207px' }}
          className="standardButton darkBlueButton"
          disabled={props.disabled}
        >
          Upload
        </button>
      </span>
    </div>,
    showModal ? (
      <PopConfirm
        key="popconfirm"
        confirm={
          <div className="align-center">
            <span>Please select a store</span>
          </div>
        }
        popStyle={{ overflow: 'visible' }}
        noButtonDisable={true}
        description={
          <div className="add-edit-wrap">
            <DropdownSelect
              optionalStyles={{ maxWidth: '100%', marginLeft: '5px' }}
              classProps={'w-500 align-center'}
              options={props.stores
                .filter(st => st.id !== 0)
                .map((st, i) => ({
                  label: st.name,
                  value: st.id,
                }))}
              // placeholder=""
              value={props.selStore}
              onChange={obj => {
                props.setSelStore(obj.value);
              }}
              placeholder="Select a store..."
            />
          </div>
        }
        onCancel={() => {
          setShowModal(false);
          props.setSelStore('');
        }}
        onConfirm={confirmSelectedStore}
      />
    ) : null,
  ];
};
const mapStateToProps = state => ({ ...state.auth });
export default connect(mapStateToProps, {})(ImportData);
