import React, { useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import EdisonLogo from '../../LottieAnimations/edisonLogo.json';

const CenteredEdison = ({ headerText, headerSubTxt, getLead, edisonTile, AIActivity, vendorItemViewHandler }) => {
  useEffect(() => {
    setTimeout(() => {
      getLead();
      vendorItemViewHandler();
      AIActivity({ eventType: 'activity_viewed', msgRegen: false, msgEdit: false, resultCode: null });
    }, 4000);
  }, [edisonTile]);

  return (
    <div role="button" className="pop-confirm-background flex-col-center" style={{ zIndex: 40 }} tabIndex={0}>
      <div className="leadOuterCompDiv">
        <div style={{ marginTop: '50px' }}>
          <Player autoplay loop src={EdisonLogo} style={{ height: '300px', width: '300px' }} />
          <div style={{ width: '450px', margin: '-30px 40px' }}>
            <span className="fw-600 fs-16">{headerText}</span>
            <p className="fw-500 fs-13 align-center m-top-0">{headerSubTxt}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CenteredEdison;
