import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState } from 'react';
import SearchClientsInput from '../../../../../Routes/ProductDetails/Layout/components/SearchClientsInput';
import { setClientMixedRelationships } from '../../../../../../reducers/client';

const RelFormItems = ({
  toggleAddClientForm,
  setNewEvent,
  setNewRelationship,
  relationships,
  clientId,
  clientName,
  openDeleteModal,
}) => {
  const [openForm, setOpenForm] = useState(false);
  const [matchingIndex, setMatchingIndex] = useState({});

  const isItOpen = key => {
    setMatchingIndex({ ...matchingIndex, [key]: true });
    setOpenForm(!openForm);
  };

  const removeMatchingIndex = key => {
    const tempObj = { ...matchingIndex };
    delete tempObj[key];
    setMatchingIndex(tempObj);
  };

  const setValue = (propKey, newValue) => {
    setClientMixedRelationships({
      ...relationships,
      [propKey]: newValue,
    });
  };

  return Object.keys(relationships).map((propKey, index) => {
    const event = relationships[propKey];
    if (event) {
      return (
        <Fragment key={`${event.relationship_type_id}-${index}`}>
          <div className="flex-row-spacebetween-start" key={`${event.relationship_type_id}-${index}`}>
            <div className="flex-col-left m-btm-8">
              <span className="fs-12 fw-500 gray">
                {event.relatedTo ? `${event.relationship} of` : event.relationship}
              </span>
              <div>
                <div
                  style={
                    matchingIndex.hasOwnProperty(propKey) ? { display: 'none' } : { display: 'flex', width: '275px' }
                  }
                  className="flex-row-spacebetween-nowrap"
                >
                  <span
                    className={event.client_name ? 'fs-12 fw-500 black' : 'fs-12 fw-500 color-bdbdbd italic'}
                    onClick={() => isItOpen(propKey)}
                  >
                    {event.related_client_name || 'not set'}
                  </span>
                  {event.uniqueRel ? (
                    <FontAwesomeIcon
                      icon={['far', 'trash-alt']}
                      className="pointer"
                      color="#FF7A7A"
                      onClick={() => openDeleteModal(event)}
                    />
                  ) : event.related_client_name ? (
                    <FontAwesomeIcon
                      icon="fa-duotone fa-circle-xmark"
                      style={{ '--fa-primary-color': '#6b6b6b', '--fa-secondary-color': '#d1d1d1' }}
                      onClick={() => {
                        setValue(propKey, {
                          ...event,
                          client_id: null,
                          client_name: null,
                          related_client_name: null,
                          related_client_id: null,
                          delete: true,
                        });
                      }}
                    />
                  ) : null}
                </div>
                {matchingIndex.hasOwnProperty(propKey) ? (
                  <div>
                    <SearchClientsInput
                      style={{ display: 'flex', width: '275px', margin: '0 0 8px' }}
                      ref={null}
                      onSelect={value => {
                        if (matchingIndex.hasOwnProperty(propKey)) {
                          if (value) {
                            setValue(propKey, {
                              ...event,
                              originalId: null,
                              client_id: Number(clientId),
                              client_name: clientName,
                              related_client_name: value?.name,
                              related_client_id: value?.id,
                              delete: false,
                            });
                            removeMatchingIndex(propKey);
                          }
                        }
                      }}
                      posType
                      placeholder="Search client"
                      type="select"
                    />
                    {/* )}
                    /> */}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        width: '100%',
                      }}
                    >
                      <div
                        id="add-client-button"
                        style={{ color: '#33AAFC', fontWeight: 600, fontSize: 13, cursor: 'pointer' }}
                        onClick={() => {
                          toggleAddClientForm();
                          setNewEvent(event);
                          removeMatchingIndex(index);
                          setNewRelationship(true);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <FontAwesomeIcon className="m-right-8 fs-13" icon={['far', 'user-plus']} />
                        Add Client
                      </div>
                    </div>{' '}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  });
};
export default RelFormItems;
