import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FIELD_STYLES } from './WizardMulti';

const Errors = ({ errors }) =>
  errors.length ? (
    <div className="m-btm-20" style={FIELD_STYLES}>
      <div
        className="fs-16 fw-500 border-r4 flex-row-nospacebetween-nowrap"
        style={{
          border: '1px solid #FF7A7A',
          backgroundColor: '#F9E0E0',
          color: '#FF7A7A',
          padding: '5px 20px',
        }}
      >
        <FontAwesomeIcon size="2x" icon={['fas', 'exclamation-circle']} />
        <ul
          style={{
            flexGrow: 1,
            listStyle: errors.length === 1 ? 'none' : 'initial',
            paddingLeft: errors.length === 1 ? 20 : 40,
          }}
        >
          {errors.map(err => (
            <li key={err}>{err}</li>
          ))}
        </ul>
      </div>
    </div>
  ) : null;

export default Errors;
