import React, { useEffect, useState } from 'react';
import { Divider } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WhatsAppSessionIndicator = props => {
  const { WA_receive, preferred_contact_type } = props.messaging.client;
  const [active, setActive] = useState();

  useEffect(() => {
    if(WA_receive) {
      setActive(moment().isBefore(moment(WA_receive).add(24, 'hour')));
    } else {
      setActive(false);
    }
  }, [WA_receive]);

  const bubbleStyles = {
    padding: '4px 0 2px 0',
    margin: '5px',
  };

  return (
    preferred_contact_type === 'WHATSAPP' && (
      <div
        style={{
          ...bubbleStyles,
          backgroundColor: active ? '#ecf8dd' : '#ffe5d3',
          color: active ? 'green' : '#fc8333',
        }}
        className="border-r3 flex-col-center"
      >
        <span className="p-4 p-left-8 p-right-8">{active ? 'Session Open' : 'Session Closed'}</span>
        {active && (
          <span style={{ color: 'green' }}>{moment(WA_receive).add(24, 'hour').diff(moment(), 'h')}hrs left</span>
        )}
      </div>
    )
  );
};

const mapStateToProps = state => ({
  messaging: state.messaging,
  auth: state.auth,
});
export default connect(mapStateToProps)(WhatsAppSessionIndicator);
