import store from '../core/store';
import { dispatcher } from '../core/actions';

const initialState = {
  openId: '',
  openImgId: '',
};

export default function reducer(state = initialState, action) {
  let state2 = state;
  switch (action.type) {
    case 'SET_OPEN_DROPDOWN_ID':
      return { ...state2, openId: action.payload };
    case 'SET_OPEN_IMAGE_DROPDOWN_ID':
      return { ...state2, openImgId: action.payload };
    default:
      break;
  }
  return state2;
}

export const setOpenDropdownId = val => {
  dispatcher('SET_OPEN_DROPDOWN_ID', val);
};

export const setOpenImageDropdownId = val => {
  dispatcher('SET_OPEN_IMAGE_DROPDOWN_ID', val);
};
