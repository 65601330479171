import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import colors from 'nice-color-palettes';
import Chart from 'chart.js';
import moment from 'moment';
import { getAll, getList, getRequest } from '../../../../core/apiRequests';
import SmallGraphs from '../../../Layout/SmallHomeGraphs';
import Layout from '../../../Layout/StandardLayout';
import { setSelectedStore } from '../../../../reducers/stores';
import { setAuthStoreId } from '../../../../reducers/auth';
import DashboardMetrics from '../../../Layout/DashboardMetrics';

const StoreDashboard = props => {
  const [daysLate, setDayslate] = useState('0');
  const [overDue, setOverDue] = useState('0');
  const [associateData, setAssociateData] = useState([]);
  const [outreachTable, setoutreachTable] = useState([]);
  const [salesNew, setSalesNew] = useState('');
  const [salesWon, setSalesWon] = useState('');
  const [salesLost, setSalesLost] = useState('');
  const [goals, setGoals] = useState([]);
  const [asctList, setAsctList] = useState([]);
  const CC_Colors = [
    ['#fc8333', '#feb32c', '#7fbd31', '#009470', '#1769af'],
    ['#803e9f', '#fd0062', '#fe99c0', '#a50087', '#55a4e7'],
    ['#6ee5ea', '#ff6363', '#9ddfff', '#ffd495', '#dcff66'],
    ['#0eb3ba'],
  ];
  const clientContactColors = CC_Colors.concat(colors);
  const TO_Colors = [
    ['#fd0062', '#fe99c0', '#803e9f', '#1769af', '#009470'],
    ['#7fbd31', '#fc8333', '#feb32c', '#a50087', '#55a4e7'],
    ['#6ee5ea', '#ff6363', '#9ddfff', '#ffd495', '#dcff66'],
    ['#0eb3ba'],
  ];
  const [automationMsgCnt, setAutomationMsgCnt] = useState(0);
  const [totalMsgCnt, setTotalMsgCnt] = useState(0);
  const TotalOutreachColors = TO_Colors.concat(colors);

  useEffect(() => {
    if (props.auth.merchantId || Number(props.auth.storeId) !== Number(props.stores.id)) {
      getSalesOppData();
      getGoalsData();
    }
    if (props.auth.stores.length > 0) {
      if (!props.stores.id || Number(props.auth.storeId) !== Number(props.stores.id)) {
        const selectedStore = props.auth.stores.filter(st => st.id === Number(props.match.params.storeId));
        if (selectedStore.length > 0) {
          setSelectedStore({ ...selectedStore[0] });
        }
      }
    }
  }, [props.auth.merchantId, props.auth.stores, props.auth.storeId]);

  const getSalesOppData = () => {
    (async () => {
      const salesOppData = await getList('home', props.auth.merchantId, '', 'salesPipeline');
      if (salesOppData && salesOppData.length > 0) {
        setSalesNew(salesOppData[0].salesPipelineNew.toString());
        setSalesWon(salesOppData[0].salesPipelineWon.toString());
        setSalesLost(salesOppData[0].salesPipelineLost.toString());
      }
    })();
  };

  const getGoalsData = () => {
    let data;

    data = async () => {
      const { merchantId } = props.auth;
      const reqObj = {
        params: ['stores', merchantId, props.match.params.storeId, 'storeDashboard'],
        query: { storeId: props.match.params.storeId },
      };
      const data = await getRequest(reqObj);
      setDayslate(`${data[0].avgDayLate || ''}`.toString() || 0);
      setAssociateData(data[0].topAssociate.sort((a, b) => b.cnt - a.cnt));
      setoutreachTable((data[0].totalOutreach || []).sort((a, b) => b.outreachCnt - a.outreachCnt));
      const totalMessagesSent = data[0].totalMessagesSent || [];
      const contactData = data[0].clientContactGraph || [];
      const contactGoal = data[0].clientContactGoal || [];
      const ttlOutreach = data[0].totalOutreachGraph || [];
      const reminderTL = data[0].reminderTimeliness || [];
      const dataObj = {};

      const automationCount = totalMessagesSent.reduce((acc, curr) => {
        if (curr.associate === 'Clientbook') {
          return (acc += 1);
        }
        return acc;
      }, 0);
      setAutomationMsgCnt(automationCount);
      setTotalMsgCnt(totalMessagesSent.length);
      const asctsObj = {};
      contactData.forEach(cD => {
        // associates.push(cD.associate)
        asctsObj[cD.associate] = 0;
      });
      const associates = Object.keys(asctsObj);
      setAsctList(associates);

      let i = 0;
      while (i < 12) {
        const dateDiff = moment(new Date()).startOf('isoWeek').subtract(i, 'weeks').format('YYYYMMDD');
        dataObj[dateDiff] = {
          date: moment(dateDiff, 'YYYYMMDD').format('MMM D'),
          contactCount: { ...asctsObj },
          goal: 0,
          reminderOnTime: 0,
          reminderLate: 0,
          reminderDismissed: 0,
          actionTypes: {},
        };
        i++;
      }

      let overdue = 0;
      const weekPlacer = day => {
        const diff = moment(new Date()).startOf('isoWeek').diff(day.date, 'weeks');
        const targetDt = moment(new Date()).startOf('isoWeek').subtract(diff, 'weeks').format('YYYYMMDD');
        const contactCountStep = [];
        if (dataObj[targetDt]) {
          if (day.hasOwnProperty('contactCount')) {
            contactCountStep.push(day.contactCount);
            dataObj[targetDt].contactCount[day.associate] += day.contactCount;
          }
          if (day.hasOwnProperty('goal')) {
            dataObj[targetDt].goal = day.goal;
          }
          if (day.hasOwnProperty('actionType')) {
            Object.keys(dataObj).forEach(wk => {
              if (!dataObj[wk].actionTypes[day.actionType]) {
                dataObj[wk].actionTypes[day.actionType] = 0;
              }
            });
            dataObj[targetDt].actionTypes[day.actionType] += day.orCount;
          }
          if (day.hasOwnProperty('daysLate')) {
            const recordComplete = Number(day.complete);
            const recordLate = Number(day.daysLate);
            if (!recordLate && recordComplete) {
              dataObj[targetDt].reminderOnTime += 1;
            } else if (day.complete && recordComplete) {
              dataObj[targetDt].reminderLate += 1;
            } else if (!day.complete) {
              dataObj[targetDt].reminderDismissed += 1;
            }
            if (day.daysLate > 0) {
              overdue += 1;
            }
          }
        }
      };

      contactData.forEach(obj =>
        weekPlacer({
          date: moment(new Date(obj.date)).format('YYYYMMDD'),
          contactCount: obj.cnt,
          associate: obj.associate,
        }),
      );
      contactGoal.forEach(obj => weekPlacer(obj));
      ttlOutreach.forEach(obj => {
        weekPlacer({
          date: moment(new Date(obj.date)).format('YYYYMMDD'),
          orCount: obj.cnt,
          actionType: obj.action_type,
        });
      });
      reminderTL.forEach(obj =>
        weekPlacer({
          date: moment(new Date(obj.date)).format('YYYYMMDD'),
          complete: obj.complete,
          daysLate: obj.days_late,
        }),
      );
      const dataObjToArr = Object.keys(dataObj).map(dt => dataObj[dt]);
      setGoals(dataObjToArr);
      setOverDue(overdue);
    };
    data();
  };

  const calculateStep = maxVal => {
    if (maxVal <= 10) {
      return 1;
    }
    if (maxVal < 50) {
      return 5;
    }
    if (maxVal < 100) {
      return 10;
    }
    if (maxVal < 250) {
      return 20;
    }
    if (maxVal < 500) {
      return 25;
    }
    if (maxVal < 1000) {
      return 100;
    }
    if (maxVal < 5000) {
      return 500;
    }
    if (maxVal < 10000) {
      return 1000;
    }
    if (maxVal < 50000) {
      return 5000;
    }
    return 10000;
  };

  // TOP STORES
  // table head data for top stores table
  const tableHeadData_store = [
    {
      keyName: 'associate',
      headerClassName: 'row1',
      rowClassName: 'fs-12',
      title: 'Associate',
      style: { width: '10%' },
    },
    {
      keyName: 'cnt',
      style: { width: '5%', paddingRight: '10px' },
      headerClassName: 'tags_row2 align-center',
      rowClassName: 'align-center fs-12',
      title: 'Contacts', // key value from the tags obj
      sortable: false,
    },
    {
      keyName: 'goal',
      style: { width: '5%', paddingRight: '10px' },
      headerClassName: 'tags_row2 align-center',
      rowClassName: 'align-center fs-12',
      title: 'Goal', // key value from the tags obj
      sortable: false,
      render: (t, o) => {
        if (t) {
          return t;
        }
        return <span className="fw-500 fs-11 light-gray align-left">No goal</span>;
      },
    },
  ];

  // TOTAL OUTREACH TABLE
  const tableHeadData_outreach = [
    {
      keyName: 'action_type',
      headerClassName: 'row1',
      rowClassName: 'fs-12',
      title: 'Activity',
      style: { width: '5%' },
    },
    {
      keyName: 'outreachCnt',
      style: { width: '5%' },
      headerClassName: 'tags_row2 align-center p-right-21 rem-pad-width',
      rowClassName: 'align-center p-right-21 rem-pad-width fs-12',
      title: 'Number',
      sortable: false,
    },
  ];

  if (goals.length > 0) {
    const sumContact = [];
    const sumAction = [];
    let sumReminder = [];

    goals.forEach(gl => {
      // CONTACT COUNT
      const pointsOfData_contact = Object.values(gl.contactCount);
      const contact_sum = pointsOfData_contact.reduce(function (a, b) {
        return a + b;
      }, 0);
      sumContact.push(contact_sum);
      // ACTION TYPES
      const pointsOfData_action = Object.values(gl.actionTypes);
      const action_sum = pointsOfData_action.reduce(function (a, b) {
        return a + b;
      }, 0);
      sumAction.push(action_sum);
    });

    sumReminder = goals.map(gl => gl.reminderOnTime).filter(item => item > 0);
    sumContact.sort(function (a, b) {
      return b - a;
    });
    sumAction.sort(function (a, b) {
      return b - a;
    });
    sumReminder.sort(function (a, b) {
      return b - a;
    });
    const step1 = calculateStep(sumContact[0]);
    const step2 = calculateStep(sumAction[0]);
    const step3 = calculateStep(sumReminder[0]);
    // END HERE

    // CLIENT CONTACT VS GOAL
    const ctx = 'homeClientContact';
    new Chart(ctx, {
      type: 'bar',
      data: {
        datasets: (() => {
          let colorLayer = 0;
          let colorIndex = 0;
          const eachStoreObject = asctList.map((asct, i) => {
            const dataConfig = {
              data: goals.map(gl => {
                return gl.contactCount[asct];
              }),
              label: asct,
              barPercentage: 0.5,
              barThickness: 20,
              // fill: false,
              order: 1,
              backgroundColor: clientContactColors[colorLayer][colorIndex],
            };
            colorIndex += 1;
            if (colorIndex > 4) {
              colorIndex = 0;
              colorLayer += 1;
            }
            return dataConfig;
          });
          eachStoreObject.push({
            data: goals.map(gl => gl.goal),
            label: 'Goals',
            pointRadius: 0,
            backgroundColor: 'white',
            borderWidth: '1',
            borderColor: '#979797',
            borderDash: [5, 5],
            type: 'line',
          });
          return eachStoreObject;
        })(),
        labels: goals.map(gl => gl.date),
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 10,
            fontSize: 12,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: true,
                padding: 8,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                min: 0,
                stepSize: step1,
                padding: 8,
              },
            },
          ],
        },
      },
    });

    // SECOND GRAPH FOR TOTAL OUTREACH
    const out = 'homeOutreachChart';
    new Chart(out, {
      type: 'bar',
      data: {
        datasets: (() => {
          const keyNames = goals.length > 0 ? Object.keys(goals[0].actionTypes) : [];
          let colorLayer = 0;
          let colorIndex = 0;
          return keyNames.map((kn, ind) => {
            const dataConfig = {
              label: `${kn} `,
              data: goals.map(gl => gl.actionTypes[kn]),
              barPercentage: 0.5,
              barThickness: 20,
              backgroundColor: TotalOutreachColors[colorLayer][colorIndex],
              order: 1,
            };
            colorIndex += 1;
            if (colorIndex > 4) {
              colorIndex = 0;
              colorLayer += 1;
            }
            return dataConfig;
          });
        })(),
        labels: goals.map(gl => gl.date),
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 10,
            fontSize: 12,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: true,
                padding: 8,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                min: 0,
                stepSize: step2,
                padding: 8,
              },
            },
          ],
        },
      },
    });

    // REMINDER TIMELINESS
    const timeliness = 'homeTimelinessChart';
    new Chart(timeliness, {
      type: 'bar',
      data: {
        datasets: [
          {
            label: 'On Time ',
            data: goals.map(gl => {
              return gl.reminderOnTime;
            }),
            barPercentage: 0.5,
            barThickness: 20,
            backgroundColor: '#1769AF',
            order: 1,
          },
          {
            label: 'Late ',
            data: goals.map(gl => gl.reminderLate),
            barPercentage: 0.5,
            barThickness: 20,
            backgroundColor: '#FF6363',
            order: 1,
          },
          {
            label: 'Dismissed ',
            data: goals.map(gl => gl.reminderDismissed),
            barPercentage: 0.5,
            barThickness: 20,
            backgroundColor: '#F7B500',
            order: 1,
          },
        ],
        labels: goals.map(gl => gl.date),
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 10,
            fontSize: 12,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: true,
                padding: 8,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                min: 0,
                stepSize: step3,
                padding: 8,
              },
            },
          ],
        },
      },
    });
  }

  return (
    <Layout
      title={`${props.stores.name} Dashboard`}
      description="View key reports and metrics at a store level"
      classProps="standard-width-wo-sidebar"
      breadCrumbs={[{ title: 'Stores', to: '/Stores' }, { title: `${props.stores.name} Dashboard` }]}
    >
      <div className="flex-row-spacebetween-wrap">
        <DashboardMetrics
          title="Sent Messages"
          metrics={[
            { title: 'Total', stat: totalMsgCnt },
            { title: 'Associate', stat: totalMsgCnt - automationMsgCnt },
            {
              title: 'Automated',
              stat: automationMsgCnt,
              // color: salesLost === "0" ? "#000000" : "#FF6060"
            },
          ]}
          specialColoring={salesLost === '0' ? { color: '#000000' } : { color: '#FF6060' }}
        />
        <DashboardMetrics
          title="Sales Opportunities"
          metrics={[
            { title: 'New', stat: salesNew },
            { title: 'Won', stat: salesWon },
            {
              title: 'Lost',
              stat: salesLost,
              color: salesLost === '0' ? '#000000' : '#FF6060',
            },
          ]}
          specialColoring={salesLost === '0' ? { color: '#000000' } : { color: '#FF6060' }}
        />
      </div>
      <div>
        <h3 style={{ textAlign: 'left' }}>Client Contact</h3>
        <p className="homeClientContact">
          Scores are calculated from your team's daily client contacts. These actions include: sending a message,
          sharing a collection, adding a new client and any other scored client activities. Maximum of 1 point per
          client each day.
        </p>
      </div>
      <>
        {/* 1st row */}
        <div className="assoc_time">
          <div className="assoc_timeliness">
            <div className="assoc_card_head">
              <h4 className="assoc_card_title m-left-21">Client Contacts vs Goal</h4>
              <p className="assoc_last_time"> last 12 weeks </p>
            </div>

            <div className="assoc_chart homeMargins" style={{ maxWidth: '790px', height: '250px' }}>
              <canvas id="homeClientContact" />
            </div>
          </div>
          <SmallGraphs
            title="Top Associates"
            subTitle="This month "
            tableHeaders={tableHeadData_store.sort((a, b) => a.cnt - b.cnt)}
            data={associateData}
            smallGraphStyles="assoc_days_late p-left-15 p-top-26 rem-pad-width p-btm-26"
            st_tr_style="fs-12"
          />
        </div>
        {/* 2st row */}
        <div className="assoc_time">
          <div className="assoc_timeliness">
            <div className="assoc_card_head">
              <h4 className="assoc_card_title m-left-21">Total Outreach</h4>
              <p className="assoc_last_time"> last 12 weeks </p>
            </div>

            <div className="assoc_chart homeMargins" style={{ maxWidth: '790px', height: '250px' }}>
              <canvas id="homeOutreachChart" />
            </div>
          </div>
          <SmallGraphs
            title="Total Outreach"
            subTitle="This month "
            tableHeaders={tableHeadData_outreach}
            data={outreachTable}
            smallGraphStyles="assoc_days_late p-left-15 p-top-26 rem-pad-width p-btm-26"
            st_tr_style="fs-12"
          />
        </div>
        {/* 3st row */}
        <div className="assoc_time">
          <div className="assoc_timeliness">
            <div className="assoc_card_head">
              <h4 className="assoc_card_title m-left-21">Reminder Timeliness</h4>
              <p className="assoc_last_time"> last 12 weeks </p>
            </div>

            <div className="assoc_chart homeMargins" style={{ maxWidth: '790px', height: '250px' }}>
              <canvas id="homeTimelinessChart" />
            </div>
          </div>
          <div className="assoc_days_late p-left-15 p-top-26 rem-pad-width">
            <div className="assoc_card_head" style={{ flexDirection: 'column' }}>
              <span className="fs-16 fw-500 align-left ">Reminder Timeliness</span>
              <div className="align-left fs-13 fw-500 light-gray ">
                <p style={{ marginTop: '3px', width: '100%' }} className="assoc_last_time m-btm-0">
                  {' '}
                  total last 12 weeks{' '}
                </p>
              </div>
            </div>
            <div className="assoc_last_time" />
            <div className="flex-row-nospacebetween-nowrap p-btm-26">
              <div className="flex-col-left" style={{ marginRight: '20%' }}>
                <span className="fs-42 fw-600">{daysLate}</span>
                <span className="fw-500 fs-13 light-gray align-left">Average Days Late</span>
              </div>
              <div className="flex-col-left">
                <span className="fs-42 fw-600">{overDue}</span>
                <span className="fw-500 fs-13 light-gray align-left">Overdue Reminders</span>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  stores: state.stores,
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StoreDashboard);
