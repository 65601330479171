const initialState = {
  active: true,
  status: '',
};

// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_PAYMENT_BANNER_STATUS':
      return { ...state, status: action.payload };
    case 'SET_PAYMENT_BANNER_ACTIVE':
      return { ...state, active: action.payload };
    default:
      return state;
  }
}
