import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content from '../../Routes/ProductDetails/components/Content';

const LargeProduct = props => {
  const { productObj, product } = props;
  const [popUpHover, setPopUpHover] = useState('bgOffwhite');

  return (
    <div className="pop-confirm-background" style={{ zIndex: 15 }} onClick={() => props.setLargeImagePopup(false)}>
      <div
        className="pop-confirm-container-large-messaging rem-pad-width flex-col-center"
        style={props.popConfirmLargeDivStyle}
      >
        <div
          style={{ width: '900px', height: '560px', overflowY: 'scroll', zIndex: '40' }}
          id="scrollableDivChat"
          className="pos-rel"
        >
          <div className="add-edit-wrap pos-abs w-100-P m-top-10" style={{ zIndex: '40', justifyContent: 'flex-end' }}>
            <div
              className="w-33 h-33 m-right-8 border-r4 pointer"
              id={popUpHover === productObj.id + 3 ? 'highlightSelected' : 'bgOffwhite'}
              onClick={() => window.open(productObj.product_image)}
              onMouseEnter={() => setPopUpHover(productObj.id + 3)}
              onMouseLeave={() => setPopUpHover('')}
            >
              <FontAwesomeIcon
                icon={['far', 'arrow-to-bottom']}
                size="1x"
                className="fs-16 pointer"
                style={
                  props.t === 'right'
                    ? { marginLeft: '8px', color: 'black', marginTop: '8px' }
                    : { color: 'black', marginTop: '8px' }
                }
              />
            </div>
            <div
              className="w-33 h-33 m-right-8 border-r4 pointer"
              id={popUpHover === productObj.id + 5 ? 'highlightSelected' : 'bgOffwhite'}
              onClick={() => props.setLargeImagePopup(false)}
              onMouseEnter={() => setPopUpHover(productObj.id + 5)}
              onMouseLeave={() => setPopUpHover('')}
            >
              <FontAwesomeIcon
                icon={['far', 'times']}
                size="1x"
                className="fs-16 pointer"
                style={
                  props.t === 'right'
                    ? { marginLeft: '11px', color: 'black', marginTop: '8px' }
                    : { color: 'black', marginTop: '8px' }
                }
              />
            </div>
          </div>
          <div className="add-edit-wrap">
            <img
              alt="#"
              className="w-500 photoFit border-r8"
              style={{ height: '500px', marginTop: '55px' }}
              src={
                productObj.productImage
                  ? productObj.productImage
                  : 'https://dashboard-v2-images.s3.amazonaws.com/image-placeholder@2x.png'
              }
              onError={e => {
                e.target.onerror = null;
                e.target.src = 'https://dashboard-v2-images.s3.amazonaws.com/image-placeholder@2x.png';
              }}
            />
            <div className="m-top-50 m-left-21 align-left m-btm-10" style={{ marginRight: '40px' }}>
              <Content product={product} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LargeProduct;
