/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { useHistory, useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  setPrimaryAccount,
  setPrimaryAccountWithoutEffects,
  deleteBankAccount,
  getBankAccountsList,
  getStoresBankAccount,
  updateBankAccountStores,
} from '../apiRequests';
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import { BANK_ACCOUNT_STATUS_LABEL as STATUS_LABEL, BANK_ACCOUNT_STATUS as STATUS } from '../const';
import Bubble from '../Bubble';
import { DeletePrimaryAccount, SetPrimaryAccount } from '../Dialogs';
import DeleteBankAccountModal from '../Dialogs/DeleteBankAccountModal';
import ReassignBankAccountModal from '../Dialogs/ReassignBankAccountModal';
import ActionButton from '../List/ActionButton';
import DropdownSelect from '../../../Layout/DropdownSelect';
import './styles.css';
import usePreviousValue from '../../../../hooks/usePreviousValue';
import { notificationSuccess } from '../../../../core/actions';
import Button from '../../../Layout/Button';

const BankAccountDetails = ({ list, merchantId }) => {
  const [primaryDialogItem, setPrimaryDialogItem] = useState(null);
  const [deleteDialogItem, setDeleteDialogItem] = useState(null);
  const [account, setAccount] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmReassign, setShowConfirmReassign] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const { id } = useParams();
  const history = useHistory();

  const prevSelectedStores = usePreviousValue(selectedStores);

  const handleReassign = async item => {
    try {
      await deleteBankAccount(merchantId, selectedBankAccount);

      setShowConfirmReassign(false);
      setSelectedBankAccount(null);

      await setPrimaryAccountWithoutEffects(merchantId, item.value, false);

      history.push('/BankAccount/list');
    } catch (error) {
      // ERROR HANDLER
    }
  };

  const handleDelete = async itemId => {
    try {
      await deleteBankAccount(merchantId, itemId);

      setSelectedBankAccount(null);
      setShowConfirmDelete(false);

      history.push('/BankAccount/list');
    } catch (error) {
      // ERROR HANDLER
    }
  };

  const updateStores = value => {
    if (prevSelectedStores) {
      const parsedValue = Array.isArray(value) ? value : [];
      const storeIDs = parsedValue.map(item => item.value).filter(item => item !== 0);

      updateBankAccountStores(merchantId, account.id, storeIDs).then(response => {
        const newStores = response.stores
          .filter(item => !item.paymentsMerchantId)
          .map(item => ({
            value: item.id,
            label: item.name,
          }));

        const newSelectedStores = response.stores
          .filter(item => item.paymentsMerchantId === account.id)
          .map(item => ({
            value: item.id,
            label: item.name,
          }));

        setStores(newStores);
        setSelectedStores(newSelectedStores);
        notificationSuccess('Stores updated successfully!');
      });
    }
  };

  useEffect(() => {
    const parsedAccountId = Number(id);

    if (!parsedAccountId) return;

    const bankAccount = list.find(item => item.id === parsedAccountId);

    if (bankAccount) {
      setAccount(bankAccount);
    } else if (merchantId) {
      getBankAccountsList(merchantId);
    }
  }, [merchantId, list]);

  useEffect(() => {
    if (account && merchantId) {
      getStoresBankAccount(merchantId, account.id).then(response => {
        const newStores = response.stores
          .filter(item => !item.paymentsMerchantId)
          .map(item => ({
            value: item.id,
            label: item.name,
          }));

        const newSelectedStores = response.stores
          .filter(item => item.paymentsMerchantId === account.id)
          .map(item => ({
            value: item.id,
            label: item.name,
          }));

        setStores(newStores);
        setSelectedStores(newSelectedStores);
      });
    }
  }, [account, merchantId]);

  const fixedStores = useMemo(() => {
    // if (account?.isPrimary) {
    //   return [
    //     {
    //       value: 0,
    //       label: 'All Unassigned Stores',
    //       isFixed: true,
    //     },
    //     ...stores,
    //   ];
    // }

    return stores.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }

      if (a.label > b.label) {
        return 1;
      }

      return 0;
    });
  }, [stores, selectedStores]);

  const fixedSelectedStores = useMemo(() => {
    if (account?.isPrimary) {
      return [
        {
          value: 0,
          label: 'All New Stores',
          isFixed: true,
        },
        ...selectedStores,
      ];
    }

    return selectedStores.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }

      if (a.label > b.label) {
        return 1;
      }

      return 0;
    });
  }, [stores, selectedStores]);

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <FontAwesomeIcon className="m-top-10" color="#D3D3D3" icon={['fas', 'store-slash']} size="2x" />
        <p>
          All stores have a default bank account. To assign a store to this account, please remove them from their
          default account.
        </p>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Layout
      title="Account Details"
      breadCrumbs={[{ title: 'Bank Accounts', to: '/BankAccount/List' }, { title: 'Account Details' }]}
      classProps="standard-width-with-sidebar"
    >
      <SecondaryPanel title={['Payments']} current="Bank Accounts" />

      {Boolean(account) && (
        <div className="cb-bank-account-details__body">
          <h2 className="cb-bank-account-details__title">General</h2>

          <div className="cb-bank-account-details__container">
            <div>
              <p className="cb-bank-account-details__text">Account Name:</p>
              <h3 className="cb-bank-account-details__subtitle">{account.name}</h3>

              <p className="cb-bank-account-details__text">Account Number:</p>
              <h3 className="cb-bank-account-details__subtitle">•••• •••• •••• {account.lastFour}</h3>
            </div>

            <div>
              <div>
                <Bubble {...STATUS_LABEL[account.status]} />

                <div
                  style={{
                    color: account.isPrimary ? '#000' : '#bdbdbd',
                  }}
                >
                  <ActionButton
                    icon="star"
                    onClick={() => setPrimaryDialogItem(account)}
                    disabled={account.isPrimary}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="cb-bank-account-details__dropdown">
            <label className="cb-bank-account-details__dropdown-label">Stores Assigned</label>
            {account.isPrimary ? (
              <span className="fw-400 fs-12 italic d-inline-block m-btm-15" style={{ marginTop: '-5px' }}>
                All stores will use this account by default unless you explicitly assign the store to another account.
              </span>
            ) : null}
            {account.isPrimary ? (
              <div className="fw-500 fs-13 unassignedStoreDropdownDiv">
                <p className="unassignedStoreDropdown">All Unassigned Stores</p>
              </div>
            ) : (
              <DropdownSelect
                id="bankAccountStores"
                placeholder="Stores Assigned"
                options={fixedStores}
                value={fixedSelectedStores}
                isMulti
                isClearable
                onChange={updateStores}
                componentProps={{ NoOptionsMessage }}
                disabled={account.isPrimary}
              />
            )}
          </div>

          <div className="mq-w-100-vw custom-action-save-flex m-top-15">
            {(!account.isPrimary || list.length > 1) && (
              <Button
                classname="deleteButton-v2 m-btm-8"
                style={{ marginBottom: '15px' }}
                onClick={() => {
                  setSelectedBankAccount(account.id);

                  if (account.isPrimary) {
                    setShowConfirmReassign(true);
                  } else {
                    setShowConfirmDelete(true);
                  }
                }}
              >
                Delete Account
              </Button>
            )}
          </div>
        </div>
      )}

      {primaryDialogItem && (
        <SetPrimaryAccount
          name={primaryDialogItem.name}
          onConfirm={() => setPrimaryAccount(merchantId, primaryDialogItem.id).then(setPrimaryDialogItem(null))}
          onCancel={() => setPrimaryDialogItem(null)}
        />
      )}

      {deleteDialogItem && (
        <DeletePrimaryAccount
          onConfirm={() =>
            deleteBankAccount(merchantId, deleteDialogItem.id).then(() => history.push('/BankAccount/list'))
          }
          onCancel={() => setDeleteDialogItem(null)}
        />
      )}

      {showConfirmReassign && (
        <ReassignBankAccountModal
          current={selectedBankAccount}
          onCancel={() => {
            setSelectedBankAccount(null);
            setShowConfirmReassign(false);
          }}
          onConfirm={handleReassign}
        />
      )}

      {showConfirmDelete && (
        <DeleteBankAccountModal
          onCancel={() => {
            setSelectedBankAccount(null);
            setShowConfirmDelete(false);
          }}
          onConfirm={() => handleDelete(selectedBankAccount)}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = state => ({
  list: state.bankAccounts.list,
  merchantId: state.auth.merchantId,
});

export default connect(mapStateToProps)(BankAccountDetails);
