import React from 'react';
import './description.css';

const DescriptionBox = props => {
  return (
    <div className={`msgSetTitle_DescContainer ${props.className ? props.className : ''}`} style={props.style}>
      <div className="msgSetTitle_Div">
        <span id={!props.title ? 'no_DescTitle' : ''} className="msgSetTitle_DescTitle fs-13 fw-500">
          {props.title}
        </span>
        <span className="fs-10 m-btm-6 gray max-w-560 align-left d-block">{props.description}</span>
      </div>
      <div className="msgSetTitle_DescInput" id={props.descriptionBoxId}>
        {props.input}
      </div>
    </div>
  );
};

export default DescriptionBox;
