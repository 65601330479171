import { dispatcher } from '../core/actions';

const initialState = {
  page: 1, // which page you're viewing
  pageSize: 25, // # of results per page. Should this really be 25 or 'unlimited'(view all) ??
  rowCount: 0, // Total # of DB records (not specifically the results given)
  pageCount: 0, // Total # of DB records divided by pageSize
  sortColumn: '', // Name of column to sort on
  ascendingColumn: true, // sortColumn ascending... false = descending. SQL default sort is ascending, so let's match that on first query
  searchText: '', // The text we're searching by,
  table_type: '',
  // searchText should always be passed in I think, as a LIKE '%%' will still return all results if empty string
};

export default function reducer(state = initialState, action) {
  let state2 = state;
  switch (action.type) {
    case 'SET_PAG_OBJ':
      return { ...state2, ...action.payload };
    case 'SET_PAG_PAGE':
      return { ...state2, page: action.payload };
    case 'SET_PAG_PAGE_SIZE':
      return { ...state2, pageSize: action.payload };
    case 'SET_PAG_ROW_COUNT':
      return { ...state2, rowCount: action.payload };
    case 'SET_PAG_PAGE_COUNT':
      return { ...state2, pageCount: action.payload };
    case 'SET_PAG_SORT_COLUMN':
      return { ...state, sortColumn: action.payload };
    case 'SET_PAG_ASC_COLUMN':
      return { ...state, ascendingColumn: action.payload };
    case 'SET_PAG_SEARCH_TEXT':
      return { ...state2, searchText: action.payload };
    case 'SET_TABLE_TYPE':
      return { ...state2, table_type: action.payload };
    default:
      break;
  }
  return state2;
}

export const setPageNum = val => {
  dispatcher('SET_PAG_PAGE', val);
};

export const setTableType = val => {
  dispatcher('SET_TABLE_TYPE', val);
};

export const setPaginationObj = val => {
  dispatcher('SET_PAG_OBJ', val);
};
