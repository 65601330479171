/* eslint-disable prefer-destructuring */
import React, { useCallback, useEffect, useMemo } from 'react';
import { Form, Input, Upload, message } from 'antd-v5';
import { PlusOutlined } from '@ant-design/icons';
import './styles.scss';

const SaveProductForm = props => {
  const { form, product, disabled, onFinish } = props;

  const initialValues = useMemo(
    () => ({
      images: product?.images || [],
      name: product?.name || '',
      price: product?.price || '',
      description: product?.description || '',
    }),
    [product],
  );

  const price = Form.useWatch('price', form);

  const handleValidateImage = useCallback(file => {
    if (file.url) {
      return true;
    }

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      message.error('You can only upload JPEG/JPG/PNG files.');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB.');
    }

    return isJpgOrPng && isLt2M;
  }, []);

  const handleChangeImages = useCallback(e => {
    return e.fileList.filter(file => handleValidateImage(file));
  }, []);

  const handleChangePrice = useCallback(() => {
    if (!price) {
      return;
    }

    let newPrice = price.replace(/[^0-9.]/g, '');

    if (newPrice.split('.').length > 2) {
      newPrice = newPrice.slice(0, -1);
    }

    form.setFieldsValue({
      price: newPrice,
    });
  }, [price]);

  useEffect(() => {
    handleChangePrice();
  }, [handleChangePrice]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      initialValues={initialValues}
      disabled={disabled}
      onFinish={onFinish}
    >
      <Form.Item name="images" valuePropName="fileList" getValueFromEvent={handleChangeImages}>
        <Upload
          listType="picture-card"
          beforeUpload={() => false}
          showUploadList={{ showPreviewIcon: false }}
          className="cb-save-product-form__upload"
        >
          <PlusOutlined />
        </Upload>
      </Form.Item>

      <Form.Item
        name="name"
        label="Product name"
        rules={[
          {
            required: true,
            message: 'Product name is required',
          },
        ]}
      >
        <Input placeholder="Product name" />
      </Form.Item>

      <Form.Item
        name="price"
        label="Product price"
        rules={[
          {
            required: true,
            message: 'Product price is required',
          },
        ]}
      >
        <Input addonBefore="$" placeholder="Product price" />
      </Form.Item>

      <Form.Item
        name="description"
        label="Product description"
        rules={[
          {
            required: true,
            message: 'Product description is required',
          },
        ]}
      >
        <Input.TextArea rows={4} placeholder="Product description" />
      </Form.Item>
    </Form>
  );
};

export default SaveProductForm;
