import React from 'react';
import addClientsHeaderOptions from '../../importDropDownOptions/addClientsHeaderOptions.json';
import DropdownSelect from '../../../../../Layout/DropdownSelect';

const MatchTable = props => {
  const { column, data, getDropDownOptions, indexOfColumn, tableHeaders, allPrefs } = props;

  const duplicateCheck = () => {
    let duplicateFound = false;
    Object.keys(props.columns).forEach(colKey => {
      if (colKey !== column) {
        if (props.columns[colKey].displayName === props.columns[column].displayName) {
          duplicateFound = true;
        }
      }
    });
    return duplicateFound;
  };

  const existingLabelCheck = () => {
    let existing = false;
    if (props.columns[column].newPref) {
      addClientsHeaderOptions.forEach(headerOption => {
        if (headerOption.label === props.columns[column].displayName) {
          existing = true;
        }
      });
      allPrefs.forEach(pref => {
        if (props.columns[column].displayName === pref.name) {
          existing = true;
        }
      });
    }

    return existing;
  };

  const inputStyleHandler = () => {
    let newStyle = {};
    if (duplicateCheck() || existingLabelCheck()) {
      newStyle = {
        backgroundColor: '#F9E0E0',
        border: '1px solid #FF7A7A',
      };
    }
    return newStyle;
  };

  return (
    <div className="table-container">
      <table className="confirm-table">
        <thead className="import-header">
          <tr>
            <th className="cell border-cells">{tableHeaders[indexOfColumn + 1]}</th>
            <th className="cell border-cells">{props.columns[column].displayName}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, key) => {
            return (
              <tr key={key}>
                <td className="cell border-cells left-column-cells">
                  {/* This accounts for the headers row and the border cells on the table giving it the blank, 1, 2, 3... on the side */}
                  {key + 2}
                </td>
                <td className="cell confirm-cells">{item[column]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <DropdownSelect
          classProps="w-359 h-50"
          selectProps={{ menuPlacement: props.isLastColumn ? 'top' : 'auto' }}
          options={getDropDownOptions(props.columns[column].selector)}
          placeholder="Not mapped..."
          value={props.columns[column].selector}
          onChange={obj => {
            if (obj.label === '<< IGNORE >>') {
              props.setColumns({
                ...props.columns,
                [column]: {
                  ...props.columns[column],
                  ignore: true,
                  newPref: false,
                  selector: obj.value,
                  existingPref: false,
                },
              });
            } else if (obj.label === '<< ADD NEW PREFERENCE >>') {
              props.setColumns({
                ...props.columns,
                [column]: {
                  ...props.columns[column],
                  displayName: '',
                  ignore: false,
                  newPref: true,
                  selector: obj.value,
                  existingPref: false,
                },
              });
            } else if (obj.pref) {
              // if it is a pref
              props.setColumns({
                ...props.columns,
                [column]: {
                  ...props.columns[column],
                  selector: obj.value,
                  displayName: obj.label,
                  ignore: false,
                  newPref: false,
                  existingPref: true,
                },
              });
            } else {
              props.setColumns({
                ...props.columns,
                [column]: {
                  ...props.columns[column],
                  selector: obj.value,
                  displayName: obj.label,
                  ignore: false,
                  newPref: false,
                  existingPref: false,
                },
              });
            }
          }}
          optionalStyles={{ paddingLeft: '8px' }}
        />
        {props.columns[column].newPref ? (
          <div className="flex-col-left">
            <input
              style={inputStyleHandler(props.columns[column].displayName)}
              className="w-359 h-48 rem-pad-width m-top-15 p-left-10 focus-input-outline-blue"
              placeholder="New preference name"
              value={props.columns[column].displayName || ''}
              onChange={e => {
                props.setColumns({
                  ...props.columns,
                  [column]: {
                    ...props.columns[column],
                    displayName: e.target.value,
                    newPref: true,
                    existingPref: false,
                  },
                });
              }} // update with existing pref shit
            />
            {duplicateCheck() ? (
              <span className="missing-data-red fs-10 m-top-8">This label has already been mapped.</span>
            ) : null}
            {existingLabelCheck() ? (
              <span className="missing-data-red fs-10 m-top-8">This label already exists.</span>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MatchTable;
