import { useCallback, useEffect, useState } from 'react';
import usePreviousValue from './usePreviousValue';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE = 1;
const DEFAULT_SEARCH = '';
const DEFAULT_FILTERS = {};
const DEFAULT_SORT_FIELD = '';
const DEFAULT_SORT_ORDER = '';

const usePaginatedRequest = ({
  defaultPageSize,
  defaultPage,
  defaultSearch,
  defaultFilters,
  defaultSortField,
  defaultSortOrder,
  requestMethod,
  mergeResults = false,
  automaticRequest = true,
}) => {
  const [loading, setLoading] = useState(false);

  const [pageSize, setPageSize] = useState(defaultPageSize || DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(defaultPage || DEFAULT_PAGE);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [results, setResults] = useState([]);

  const [search, setSearch] = useState(defaultSearch || DEFAULT_SEARCH);
  const [filters, setFilters] = useState(defaultFilters || DEFAULT_FILTERS);
  const [sortField, setSortField] = useState(defaultSortField || DEFAULT_SORT_FIELD);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder || DEFAULT_SORT_ORDER);

  const prevPage = usePreviousValue(page);

  const getParams = useCallback(() => {
    const params = {};

    if (defaultPageSize) {
      if (pageSize !== defaultPageSize) {
        params.pageSize = pageSize;
        params.limit = pageSize;
      } else {
        params.pageSize = defaultPageSize;
        params.limit = defaultPageSize;
      }
    } else if (pageSize !== DEFAULT_PAGE_SIZE) {
      params.pageSize = pageSize;
      params.limit = pageSize;
    }

    if (page > 1) {
      params.page = page;
    }

    if (search) {
      params.search = search;
    }

    if (filters && Object.keys(filters).length) {
      params.filters = filters;
    }

    if (sortField) {
      params.sortField = sortField;
    }

    if (sortOrder) {
      params.sortOrder = sortOrder;
    }

    return params;
  }, [defaultPageSize, pageSize, page, search, filters, sortField, sortOrder]);

  const getResults = useCallback(
    async (clear = false) => {
      if (!loading) {
        setLoading(true);

        try {
          const params = getParams();
          const response = await requestMethod({ params });

          setPageSize(response.data.pageSize || response.data.limit);
          setPage(response.data.page);
          setPages(response.data.pages);
          setTotal(response.data.total);

          if (clear || !mergeResults) {
            if (response.data?.results) {
              setResults([...response.data.results]);
            } else {
              setResults([...response.data.data]);
            }
          } else {
            if (response.data?.results) {
              setResults([...results, ...response.data.results]);
            } else {
              setResults([...results, ...response.data.data]);
            }
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    },
    [getParams, mergeResults, requestMethod, loading, results],
  );

  useEffect(() => {
    if (automaticRequest && prevPage !== undefined) {
      setPage(1);
      setPages(0);
      setTotal(0);
      setResults([]);

      if (page === 1) {
        getResults(true);
      }
    }
  }, [pageSize, search, filters, sortField, sortOrder]);

  useEffect(() => {
    if (automaticRequest) {
      getResults();
    }
  }, [page]);

  return {
    defaultPageSize,
    pageSize,
    setPageSize,
    defaultPage,
    page,
    prevPage,
    setPage,
    defaultSearch,
    search,
    setSearch,
    defaultFilters,
    filters,
    setFilters,
    defaultSortField,
    sortField,
    setSortField,
    defaultSortOrder,
    sortOrder,
    setSortOrder,
    total,
    pages,
    results,
    setResults,
    loading,
    setLoading,
    getResults,
    requestMethod,
    automaticRequest,
  };
};

export default usePaginatedRequest;
