import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import NewPopConfirm from '../../../../Layout/NewPopConfirm';
import Button from '../../../../CustomizedAntDesign/Button';
import './styles.css';
import DropdownSelect from '../../../../Layout/DropdownSelect';

const ReassignBankAccountModalWarning = () => {
  return (
    <>
      <h3 className="cb-reassign-bank-account-modal__title">Delete Default Bank Account</h3>

      <p className="cb-reassign-bank-account-modal__subtitle">Are you sure you want to delete your default account?</p>

      <p className="cb-reassign-bank-account-modal__description">
        You will need to assign a new account to be your default. All historical data related to this account will be
        saved.
      </p>
    </>
  );
};

const ReassignBankAccountModalSelector = ({ current, list, selectedBankAccount, setSelectedBankAccount }) => {
  const history = useHistory();

  const options = useMemo(() => {
    return list
      .filter(item => item.id !== current)
      .map(item => ({
        value: item.id,
        label: item.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [list]);

  return (
    <>
      <h3 className="cb-reassign-bank-account-modal__title">Assign Bank Account as Default</h3>

      <DropdownSelect
        options={options}
        placeholder="Select Bank Account"
        value={selectedBankAccount?.value}
        onChange={setSelectedBankAccount}
      />

      <div className="cb-reassign-bank-account-modal__link" onClick={() => history.push('/BankAccount/add')}>
        Get Started
      </div>
    </>
  );
};

const ReassignBankAccountModalConfirm = ({ accountName }) => {
  return (
    <>
      <h3 className="cb-reassign-bank-account-modal__title">Set as Default Bank Account</h3>

      <p className="cb-reassign-bank-account-modal__subtitle">
        All new registered stores will be assigned to {accountName}.
      </p>
    </>
  );
};

const ReassignBankAccountModal = ({ current, list, onCancel, onConfirm }) => {
  const [step, setStep] = useState(0);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);

  const handleClick = useCallback(() => {
    if (step < 2) {
      setStep(step + 1);
    } else {
      onConfirm(selectedBankAccount);
    }
  }, [step, selectedBankAccount]);

  return (
    <NewPopConfirm className="cb-reassign-bank-account-modal">
      {step === 0 && <ReassignBankAccountModalWarning />}

      {step === 1 && (
        <ReassignBankAccountModalSelector
          current={current}
          list={list}
          selectedBankAccount={selectedBankAccount}
          setSelectedBankAccount={setSelectedBankAccount}
        />
      )}

      {step === 2 && <ReassignBankAccountModalConfirm accountName={selectedBankAccount.label} />}

      <div className="mq-w-100-vw custom-action-save-flex">
        <button className="save-button-v2 m-btm-8" onClick={handleClick} disabled={step && !selectedBankAccount}>
          {step === 2 ? 'Save' : 'Continue'}
        </button>
        <button className="cancel-button-v2" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </NewPopConfirm>
  );
};

const mapStateToProps = state => ({
  list: state.bankAccounts.list,
});

export default connect(mapStateToProps, {})(ReassignBankAccountModal);
