import React from 'react';
import yn from 'yn';
import { connect } from 'react-redux';
import { PRODUCT_DETAILS_SECTIONS } from './constants';
import formatNumberAsCurrency from '../../../../../utils/formatNumberAsCurrency';
import './styles.css';

const ProductDetailsContent = ({ product, role, merchantConfig }) => {
  const sectionHasItems = section => {
    return section.itemType === 'number' || section.items.some(item => Boolean(product[item.key]));
  };

  const formatNumber = value => {
    if (!value) return '-.--';

    if (value.match(/[$]/g)) return value;

    // return formatNumberAsCurrency(value);
    return value;
  };

  const formatString = value => {
    return (value || '').trim();
  };

  return (
    <div className="product-details-content">
      <section>
        <h1>{product.name}</h1>
        <h2>{product.sku}</h2>
        {(yn(merchantConfig.HIDE_PRODUCT_COST) && role === 'ASSOCIATE') || <h2>{formatNumber(product.price)}</h2>}
      </section>

      <section>
        <h2>Description</h2>
        <p>{product.description || 'No Description'}</p>
      </section>

      <section>
        {PRODUCT_DETAILS_SECTIONS.map(section => {
          if (
            sectionHasItems(section) && section.config
              ? yn(merchantConfig[section.config?.name]) === section.config?.value ||
                section.config?.roles.includes(role)
              : true
          ) {
            return (
              <div key={section.label}>
                <h2>{section.label}</h2>

                {section.items.map(item => {
                  if (item.roles && !item.roles.includes(role)) {
                    return null;
                  }

                  const value =
                    section.itemType === 'number' ? formatNumber(product[item.key]) : formatString(product[item.key]);

                  if (!value) return null;

                  return (
                    <p key={item.key}>
                      {item.label} <strong>{value}</strong>
                    </p>
                  );
                })}
              </div>
            );
          }

          return null;
        })}
      </section>
    </div>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  merchantConfig: state.auth.merchantConfig,
});

export default connect(mapStateToProps, {})(ProductDetailsContent);
