import React from 'react';
import { arrayOf, bool, shape, string, func } from 'prop-types';

const Arrow = () => <span className="bread-crumb-arrows">{'>>'}</span>;

const Crumb = ({ title, stepNumber, isActive, onClick }) => {
  return (
    <span className={`no-wrap pointer d-inline-block ${isActive ? 'current-bread-crumb' : ''}`} onClick={onClick}>
      {stepNumber}
      <span className={isActive ? '' : 'hide-under-800'}>. {title}</span>
    </span>
  );
};

const Crumbs = ({ crumbs, changeStep, className }) => {
  return (
    <span className={`layoutBreadcrumb d-block ${className}`}>
      {/* loop thru crumbs, adding arrows in between */}
      {crumbs.reduce((list, crumbProps, index, { length }) => {
        const renderArrow = () => (index < length - 1 ? [<Arrow key={`arrow=${index}`} />] : []);

        return [
          ...list,
          <Crumb {...crumbProps} stepNumber={index + 1} onClick={() => changeStep(index)} key={crumbProps.title} />,
          ...renderArrow(),
        ];
      }, [])}
    </span>
  );
};

Crumbs.propType = {
  crumbs: arrayOf(
    shape({
      title: string,
      isActive: bool,
    }),
  ),
  changeStep: func,
  className: string,
};

export default Crumbs;
