import React from 'react';

const PaymentProduct = ({ url, description }) => {
  return (
    <div className="flex-row-nospacebetween-nowrap">
      <img className="m-right-15 w-40 h-40" src={url}></img>
      <p className="fs-13 align-left fw-500 break-word">{description}</p>
    </div>
  );
};

export default PaymentProduct;
