import React, { useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import { Badge, Space, ConfigProvider } from 'antd';
import IntroEdisonToolTip from './Components/IntroEdisonToolTip';
import NoLeadForNow from './Components/NoLeadForNow';
import CenteredEdison from './Components/LeadComponents/CenteredEdison';
import EdisonHelpMeImprove from './Components/EdisonHelpMeImprove';
import CenteredEdisonInitial from './Components/LeadComponents/CenteredEdisonInitial';
import EdisonToolTip from './Components/EdisonToolTip';
import EdisonAI from './Components/EdisonAI';
import { getRequest, postRequest } from '../../../core/apiRequests';
import sendActivityEvent from './sendActivityEvent';
import './edison.css';
import { updateVendorItemViewDate } from './apiRequests';

const Edison = props => {
  const [showLead, setShowLead] = useState(false);
  const [hasSeenEdison, setHasSeenEdison] = useState(null);
  const [showToolTip, setShowToolTip] = useState(true);
  const [leadObj, setLeadObj] = useState({});
  const [edisonTile, setEdisonTile] = useState('initialLoading');
  const [showEllipses, setShowEllipses] = useState('noShowEllipses');
  const [myClient, setMyClient] = useState(false);
  const [badgeLight, showBadgeLight] = useState(false);

  const getHeaderText = useCallback((clientName, type) => {
    switch (type) {
      case 'ready_to_buy':
        return `${clientName} is ready to buy!`;
      case 'anniversary':
        return `${clientName} is celebrating an anniversary!`;
      case 'spouse_birthday':
        return `${clientName} is celebrating a birthday soon!`;
      case 'birthday':
        return `${clientName} is celebrating a birthday soon!`;
      case 'jewelry_bridal_purchase':
        return `${clientName} recently purchased an engagement ring!`;
      case 'sales_opp_overdue':
        return `${clientName} has an overdue sales opportunity!`;
      case 'sales_opp_no_contact':
        return `Did you forget about the open sales opportunity for ${clientName}?`;
      case 'slipping_away':
        return `${clientName} is slipping away!`;
      case 'vip_needs_attention':
        return `${clientName} is a VIP and hasn't heard from you recently!`;
      case 'annual_spend_appreciation':
        return `${clientName} is one of your top customers over the last year!`;
      case 'new_client_purchase':
        return `${clientName} is a high-value, new customer!`;
      default:
        return `${clientName} is ready to buy!`;
    }
  }, [])

  const vendorItemViewHandler = () => {
    updateVendorItemViewDate(leadObj?.vendorRecommendations, 'smart_assistant', 'vendor_item_recommended');
  }

  const queryEdison = async (mainObj, prompt) => {
    try {
      if (props.merchantId) {
        const reqObj = {
          params: ['ai', mainObj.id, 'regenerateEdisonMessage'],
          data: { prompt },
          loadingStatusOff: true,
        };
        const data = await postRequest(reqObj);
        if (Object.keys(data).length > 0) {
          const storeName =  props?.stores?.find(store => store?.id === mainObj?.storeId)?.name || '';
          setLeadObj({
            ...mainObj,
            storeName,
            type: { ...mainObj.type, headerText: getHeaderText(mainObj.client.name, mainObj.type.code), message: data.message },
          });
          if (data.message !== mainObj?.type.message) {
            setShowEllipses('noShowEllipses');
          }
        } else {
          setShowEllipses('noShowEllipses');
        }
        return true;
      }
    } catch (e) {
      console.error('[queryEDISON] ', e);
      return false;
    }
  };

  const getLead = async () => {
    let { selectedStore } = props;
    const { stores } = props;
    if (props.merchantId && stores.length > 1) {
      if (selectedStore === 0) {
        selectedStore = stores[1].id;
      }
      const reqObj = {
        params: ['ai', selectedStore, 'getEdisonActivity'],
        loadingStatusOff: true,
      };
      const data = await getRequest(reqObj);
      if (Object.keys(data).length > 0) {
        const edisonMessage = await queryEdison(
          {
            ...data,
            type: { ...data.type, headerText: getHeaderText(data?.client.name, data.type.code), message: '' },
          },
          data.type.prompt,
        );
        if (edisonMessage) {
          showBadgeLight(true);
          setMyClient(data.assignedAssociate);
          if (edisonTile === 'loadingComplete' || edisonTile === 'loadingSkipped') {
            setEdisonTile('edisonAI');
          }
          setHasSeenEdison(true);
        }
      } else {
        showBadgeLight(false);
        if (hasSeenEdison === true) {
          setEdisonTile('done');
        }
        setEdisonTile('noLead');
      }
    }
  };

  // tracking AI
  const AIActivity = ({ eventType, msgRegen, msgEdit, resultCode }) => {
    const multiStore = props.stores.length > 1;
    const aiBody = sendActivityEvent({
      eventType,
      merchantId: props.merchantId,
      packageCode: props.auth.planCode,
      multiStore,
      storeId: props.selectedStore,
      associateId: props.userId,
      associateRole: props.auth.role.toLowerCase(),
      clientId: leadObj?.client?.id,
      activityId: leadObj?.id,
      activityType: leadObj?.type?.code,
      activityCreatedDateTime: leadObj?.createdDate,
      assignmentType: leadObj.assignedAssociate ? 'associate' : 'store',
      msgRegen,
      msgEdit,
      resultCode,
    });
    Axios({
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${props.auth.jwtToken}`,
      },
      Authorization: `Bearer ${props.auth.jwtToken}`,
      url: `${process.env.REACT_APP_CLIENTBOOK_API_URL}/api/v1/events/aiactivity`,
      data: aiBody,
    }).catch(err => console.error(err));
  };

  useEffect(() => {
    getLead();
  }, []);
  const edisonTiles = () => {
    switch (edisonTile) {
      case 'noLead':
        return <NoLeadForNow setShowLead={setShowLead} headerText="No activities for now" />;
      case 'loadingComplete':
        return (
          <CenteredEdison
            headerText="Great job!"
            headerSubTxt="Let me look for another one."
            getLead={getLead}
            AIActivity={AIActivity}
            vendorItemViewHandler={vendorItemViewHandler}
          />
        );
      case 'initialLoading':
        return <CenteredEdisonInitial leadObj={leadObj} setEdisonTile={setEdisonTile} />;
      case 'loadingSkipped':
        return (
          <CenteredEdison
            headerText="Thanks for your feedback!"
            headerSubTxt="Let me look for another one.."
            getLead={getLead}
            edisonTile={edisonTile}
            AIActivity={AIActivity}
            vendorItemViewHandler={vendorItemViewHandler}
          />
        );
      case 'helpMeImprove':
        return (
          <EdisonHelpMeImprove
            setShowLead={setShowLead}
            setEdisonTile={setEdisonTile}
            aiActivityId={leadObj.id}
            getLead={getLead}
            setLeadObj={setLeadObj}
            myClient={myClient}
            AIActivity={AIActivity}
          />
        );
      case 'done':
        return <NoLeadForNow setShowLead={setShowLead} headerText="That's all I have for today!" />;
      case 'edisonAI':
        return (
          <EdisonAI
            myClient={myClient}
            merchantId={props.merchantId}
            setShowLead={setShowLead}
            setEdisonTile={setEdisonTile}
            leadObj={leadObj}
            setLeadObj={setLeadObj}
            aiActivityId={leadObj.id}
            getLead={getLead}
            showEllipses={showEllipses}
            setShowEllipses={setShowEllipses}
            queryEdison={queryEdison}
            AIActivity={AIActivity}
            stores={props.stores}
            flags={props.auth?.launchFlagsForNav}
          />
        );
      default:
        null;
    }
  };

  const edisonToolTip = () => {
    const returnArr = [];
    if (props?.edison_view_date && badgeLight) {
      returnArr.push(
        <EdisonToolTip
          key="tool1"
          userId={props.userId}
          leadObj={leadObj}
          setShowLead={setShowLead}
          setShowToolTip={setShowToolTip}
          AIActivity={AIActivity}
          vendorItemViewHandler={vendorItemViewHandler}
        />,
      );
    }
    if (!props?.edison_view_date && badgeLight) {
      returnArr.push(
        <IntroEdisonToolTip
          key="tool2"
          leadObj={leadObj}
          userId={props.userId}
          setShowLead={setShowLead}
          setShowToolTip={setShowToolTip}
          AIActivity={AIActivity}
          vendorItemViewHandler={vendorItemViewHandler}
        />,
      );
    }
    return returnArr;
  };

  function showEdisonClick(e) {
    setShowLead(true);
    vendorItemViewHandler();
    AIActivity({ eventType: 'activity_viewed', msgRegen: false, msgEdit: false, resultCode: null });
  }

  return (
    <div className="pos-rel m-right-24">
      <div className={!props.edison_view_date && badgeLight ? 'backgroundDarkDiv' : ''} />
      <button
        id={'edisonAssistantBtn'}
        onClick={e => {
          showEdisonClick(e);
        }}
        className="edisonAssistantBtn"
        disabled={props.disabled}
        type="button"
        style={{ zIndex: '2147483647' }}
      >
        <ConfigProvider
          theme={{
            components: {
              Badge: {
                dotSize: 12,
              },
            },
          }}
        >
          <Space size="large">
            <Badge dot={badgeLight} offset={[-4, 4]} />
          </Space>
        </ConfigProvider>
        <span className="white fw-600 fs-13 m-left-7 pointer">AI Assistant</span>
      </button>
      {!showLead && showToolTip && edisonToolTip()}
      {showLead && edisonTiles()}
    </div>
  );
};
export default Edison;
