import React from 'react';
import { Checkbox, List } from 'antd';
import './styles.css';

const ChecklistDropdownPopoverListItem = props => {
  const { item, checked, onChange } = props;

  return (
    <List.Item>
      <Checkbox checked={checked} onChange={onChange} className="cb-checklist-dropdown-popover-list-item__checkbox">
        {item}
      </Checkbox>
    </List.Item>
  );
};

export default ChecklistDropdownPopoverListItem;
