import React from 'react';

import Carousel from './Carousel';
import Content from './Content';
import './styles.css';

const ProductDetailsBody = ({ product }) => {
  if (!product) return null;
  return (
    <div className="product-details-layout__container">
      <Carousel product={product} />
      <Content product={product} />
    </div>
  );
};

export default ProductDetailsBody;
