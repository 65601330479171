import { parseWithOptions } from 'date-fns/fp';
import React from 'react';

import './smallTable.css';

const SmallTable = props => {
  const tableRowClass = itemId => {
    let classString = '';
    if (props.selected && props.selected === itemId) {
      classString += ' highlight-selected ';
    }
    if (!props.st_tr_style) {
      classString += ' body-row pointer ';
    }
    return classString;
  };

  const tableRowBuilder = (item, rowKey) => {
    let rowArr = [];
    props.tableHeaders.forEach((column, colKey) => {
      let content;
      if (column.render) {
        content = (
          <td key={`td${rowKey}${colKey}`} className={column.rowClassName}>
            {column.render(item[column.keyName], item)}
          </td>
        ); // built for custom rendered content
      } else {
        content = (
          <td key={`td${rowKey}${colKey}`} className={column.rowClassName}>
            {item[column.keyName]}
          </td>
        ); // else they'll just go in a row
      }
      rowArr.push(content);
    });
    return rowArr;
  };
  return (
    <div
      style={props.tableContainerStyle ? props.tableContainerStyle : { overflowY: 'visible', height: 'auto' }}
      className={
        // component has ability to override existing class.
        props.tableContainerClass ? `${props.tableContainerClass}` : 'table-body-content rem-pad-width'
      }
    >
      <div
        className={props.tableClassName ? `${props.tableClassName}` : 'table'}
        style={{ overflowY: 'visible', height: 'auto' }}
      >
        {props.data.length > 0 ? (
          <table className="real-small-table" style={props.tableStyle ? props.tableStyle : {}}>
            <thead className={props.classname ? props.classname : 'table-head'}>
              <tr>
                {props.tableHeaders.map((col, i) => {
                  return (
                    <th key={`th${i}`} className={col.headerClassName} style={{ ...col.style }}>
                      {col.title}{' '}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="table-row">
              {/* {dataZero ? <tr className="body-row flex-col-center text-light-gray"> No Data Available </tr> : null} */}
              {props.data.map((item, key) => {
                return (
                  <tr
                    className={tableRowClass(item.id)}
                    key={`tr${key}`}
                    onClick={() => (props.onRowClick ? props.onRowClick(item) : console.debug('No row click feature'))}
                    // Second ternary is basically so you don't have a bottom border on the last one but it can still key your other styles if you need in the future∂
                    style={
                      props.rowStyle
                        ? key + 1 === props.data.length
                          ? { ...props.rowStyle, borderBottom: 'none' }
                          : props.rowStyle
                        : {}
                    }
                  >
                    {tableRowBuilder(item, key)}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <span className="body-row flex-col-center text-light-gray" style={props.noDataStyle ? props.noDataStyle : {}}>
            {props.noDataText ? props.noDataText : 'No Data Available'}
          </span>
        )}
      </div>
    </div>
  );
};
export default SmallTable;
