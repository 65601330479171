import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getMessageSettings, postRequest } from '../../../core/apiRequests';
import { messageDispatcher } from '../../Routes/Configuration/MessageSettings/largeMsgSettingFns';
import { useDragAndDrop } from '../../../context/DragDropContext';
import { actionTextAreaFileActions } from './useActionTextAreaFiles';

const ReviewLinkCheck = props => {
  const { fileData, fileDispatch } = useDragAndDrop();
  const [selectedStoreName, setSelectedStoreName] = useState('');

  const flags = useFlags();

  useEffect(() => {
    checkHovering();
  }, [props.hovering]);

  const checkHovering = () => {
    if (props.hovering === 'review') {
      return props.hoverButtonStyles;
    }
    return props.buttonStyles;
  };

  useEffect(() => {
    if (flags?.multiLocationMessaging) {
      if (props?.auth?.selectedStore) {
        const storeName = props?.auth.stores.find(store => store.id === props?.auth.selectedStore)?.name;
        setSelectedStoreName(storeName || '');
      }
    } else if (props?.client?.store_id) {
      const storeName = props?.auth.stores.find(store => store?.id === props?.client?.store_id)?.name;
      setSelectedStoreName(storeName || '');
    }
  }, [props?.auth?.selectedStore, props?.client, flags]);

  useEffect(() => {
    if (props.auth.merchantId && !props?.reviewMessage && !props.messageSettings.reviewMessage) {
      (async () => {
        const data = await getMessageSettings('merchant', props.auth.merchantId);
        if (data) {
          messageDispatcher(data, props.brandName);
        }
      })();
    }
  }, [props.auth.merchantId, props.reviewMessage]);

  const getReviewShortLink = useCallback(async () => {
    let storeId;
    if (props.location === 'inbox') {
      storeId = props.auth.selectedStore;
    } else storeId = props?.client?.store_id ? props?.client?.store_id : props.auth.stores[1].id;
    const reqObj = {
      params: ['stores', storeId, 'google', 'reviews', 'shortUrl'],
      data: {
        clientId: props.client?.client_id,
      },
    };
    const data = await postRequest(reqObj);
    return data.reviewShortUrl;
  }, [props.client?.store_id, props.client?.client_id, props.auth.selectedStore]);

  const parseReviewMessage = useCallback(
    async msg => {
      let trackableLink = props.messaging.reviewLink;
      let formattedMsg = msg;
      if (props?.client?.name) {
        formattedMsg = formattedMsg.replace(/:name:/g, props?.client?.name.split(' ')[0]);
      }
      if (formattedMsg.includes(':review:') && flags?.googleReviewsReport) {
        try {
          trackableLink = await getReviewShortLink();
        } catch (error) {
          trackableLink = props.messaging.reviewLink;
        }
      }
      formattedMsg = formattedMsg
        .replaceAll(/:review:/g, trackableLink)
        .replaceAll(/:associate:/g, props.auth.firstName)
        .replaceAll(/:store:/g, selectedStoreName);
      return formattedMsg;
    },
    [props.client?.name, props.messaging.reviewLink, props.auth, selectedStoreName],
  );

  const handleClick = useCallback(async () => {
    const review_msg = await parseReviewMessage(props.messageSettings.reviewMessage);
    console.log(review_msg, 'review_msg');
    props.getCursorXY(review_msg);
    if (props?.messageSettings?.reviewImages.length > 0) {
      fileDispatch({
        type: actionTextAreaFileActions.setImages,
        payload: {
          urls: [...props?.messageSettings?.reviewImages, ...fileData?.images.urls],
          files: [...fileData?.images?.files],
        },
      });
    }
  }, [props, fileData]);

  if (props.client && !props.messaging.reviewLink) {
    return (
      <button
        type="button"
        onClick={handleClick}
        style={checkHovering()}
        onMouseLeave={props.onMouseLeave}
        onMouseEnter={props.onMouseEnter}
        className="pointer m-left-10 rem-pad-width h-29 fs-10"
        id="review-link-btn"
      >
        Review Link
      </button>
    );
  }
  if (props.messaging.reviewLink) {
    return (
      <button
        type="button"
        onClick={handleClick}
        style={checkHovering()}
        onMouseLeave={props.onMouseLeave}
        onMouseEnter={props.onMouseEnter}
        className="pointer m-left-10 rem-pad-width h-29 fs-10"
      >
        Review Link
      </button>
    );
  }
  if (props.template) {
    return (
      <button
        type="button"
        onClick={() => props.getCursorXY(':review:')}
        style={checkHovering()}
        onMouseLeave={props.onMouseLeave}
        onMouseEnter={props.onMouseEnter}
        className="pointer m-left-10 rem-pad-width h-29 fs-10"
      >
        Review Link
      </button>
    );
  }
  return null;
};

const mapStateToProps = state => ({
  auth: state.auth,
  messageSettings: state.messageSettings,
  messaging: state.messaging,
});
export default connect(mapStateToProps, {})(ReviewLinkCheck);
