import React from 'react';
import { PAYMENT_STATUS_KEY as STATUS, PAYMENT_STATUS } from '../const';

const METHOD = {
  UNPAID: () => <p className="fs-13 gray m-top-8 m-btm-0">No payment method available</p>,
  PAID_MANUALLY: (reason, name) => (
    <>
      <div className="fs-13 fw-500 m-top-8">
        <p className="fs-13 fw-500 m-top-8 break-word">{`${name} noted: "${reason}"`}</p>
      </div>
    </>
  ),
  CANCELED: (reason, name) => (
    <>
      <div className="fs-13 fw-500 m-top-8">
        <p className="fs-13 fw-500 m-top-8 break-word">{`${name} noted: "${reason}`}"</p>
      </div>
    </>
  ),
  PAID_BY_CC: (transaction, amount, details) => (
    <>
      <div>
        <p className="fs-13 margin-0 fw-500">Paid with Credit Card</p>
        <p className="fs-12 gray margin-0 fw-400">{`${transaction.cardType}-${transaction.cardLastFour}`}</p>
      </div>

      <div className="fs-13 fw-500 flex-row-spacebetween-nowrap">
        <p className="m-btm-6">Total Amount</p>
        <p className="m-btm-6 align-right">{`$${amount}`}</p>
      </div>
      <div className="fs-13 fw-500 flex-row-spacebetween-nowrap">
        <p className="m-top-0">Processing Fees</p>
        <p className="m-top-0">{`$${details.fee.toFixed(2)}`}</p>
      </div>
      <div className={'divider w-100-P m-btm-0'} style={{ width: '100%' }} />
      <div className="fs-13 fw-500 flex-row-spacebetween-nowrap">
        <p>Net Amount</p>
        <p className="align-right">{`$${details.netAmount.toFixed(2)}`}</p>
      </div>
    </>
  ),
  REFUNDED: (reason, name, payment) => {
    const amount = payment.paymentRequest.totalAmount;
    const refundedSum = payment.refunds.reduce((partialSum, a) => partialSum + a, 0);
    return (
      <>
        <div className="fs-13 fw-600 flex-row-spacebetween-nowrap">
          <p className="m-btm-16 red">Refunded</p>
          <p className="m-btm-16 align-right red">{`$${amount}`}</p>
        </div>
        <div className="fs-13 fw-500 m-top-8">
          <p className="fs-13 fw-500 m-top-8 break-word">{`${name} noted: "${reason}"`}</p>
        </div>
      </>
    );
  },
  PAYMENT_FAILED: () => null,
  REFUND_FAILED: () => null,
};

const PaymentMethod = ({ data, payment }) => {
  if (!data || !payment) return null;

  const { status } = data;
  const lastStatus = payment.paymentRequest.paymentRequestLastStatus;

  switch (status) {
    case STATUS.UNPAID:
      if (
        !PAYMENT_STATUS.PAID.statuses.includes(lastStatus) &&
        !PAYMENT_STATUS.CANCELED.statuses.includes(lastStatus) &&
        !PAYMENT_STATUS.REFUNDED.statuses.includes(lastStatus)
      ) {
        return METHOD[status]();
      } else {
        return null;
      }
    case STATUS.PAID_MANUALLY:
      return METHOD[status](data.reason, data.associateName);
    case STATUS.CANCELED:
      return METHOD[status](data.reason, data.associateName);
    case STATUS.PAID_BY_CC:
      return METHOD[status](payment.transactions[0], payment.paymentRequest.totalAmount, payment.transactionDetails);
    case STATUS.REFUNDED:
      return METHOD[status](data.reason, data.associateName, payment);
    case STATUS.PAYMENT_FAILED:
      return METHOD[status](null);
    case STATUS.REFUND_FAILED:
      return METHOD[status](null);
    default:
      return null;
  }
};

export default PaymentMethod;
