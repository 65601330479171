
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FilterOutlined } from '@ant-design/icons';
import { Button, theme, Dropdown, Cascader } from 'antd-v5';

const { useToken } = theme;

const ReportFilter = ({
  options = [],
  filters = {},
  handleFilterChange = () => {},
}) => {

  const ref = useRef();
  const { token } = useToken();
  const [open, setOpen] = useState(false);

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };


  const treeData = useMemo(() => options?.map(option => ({
    label: option.title,
    key: option.key,
    value: option.value,
    children: option?.children?.map(child => ({
      label: child.title,
      value: child.value,
      key: child.key
    }))
  })), [options]);

  const filter = (inputValue, path) =>{
  return path.some(
    (option) => (option.label).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
  )};

  const handleOpenFilter = useCallback((open) => {
    if(open) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [])

  return (
    <Dropdown
      placement="bottomLeft"
      trigger={['click']}
      onOpenChange={handleOpenFilter}
      open={open}
      destroyPopupOnHide
      dropdownRender={() =>  (
        <div style={contentStyle}>
          <Cascader
            style={{ width: '100%', minWidth: '320px' }}
            options={treeData}
            multiple
            open={open}
            maxTagCount="responsive"
            expandTrigger="hover"
            showSearch={{ filter }}
            onChange={(value) => handleFilterChange(value)}
            value={Object.entries(filters).reduce((acc, [key, value]) => {
              if (!value) return [...acc];
              const auxValues = value.map(val => [key, val?.toString()]);
              return [...acc, ...auxValues.filter(val => val[1])];
            }, [])}
          />
        </div>
      )}
    >
      <Button>
        <FilterOutlined />
        Filter
      </Button>
    </Dropdown>
  )
};

export default ReportFilter;
