import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { postRequest } from '../../../../core/apiRequests';
import { notificationSuccess, notificationError } from '../../../../core/actions';
import { setClientDetailsTagList } from '../../../../reducers/client';

const TagManagerModalV2 = props => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagsToSend] = useState({});
  const [filterTags, setFilterTags] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setSelectedTags([
      ...props.client.details_tag_list.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return -1;
      }),
    ]);
  }, []);

  const setTagHandler = id => {
    // sets a Tags as a "selectedTags", while trying to avoid dupes
    let tagAccess;
    const tagList = selectedTags.map(tag => {
      if (id === tag.id) {
        if (tag.client_id) {
          tag.client_id = null;
          tagAccess = false;
        } else {
          tag.client_id = props.client.client_id;
          tagAccess = true;
        }
      }
      return tag;
    });
    if (tagsToSend[id]) {
      delete tagsToSend[id];
    } else {
      tagsToSend[id] = tagAccess;
    }
    setSelectedTags([...tagList]);
  };

  const saveTagAccess = async () => {
    const tagAccess = {};
    selectedTags.forEach(tag => {
      const tagId = tag.id;
      const access = !!tag.client_id;
      tagAccess[tagId] = access;
    });
    const reqObj = {
      params: ['clients', props.merchantId, 'saveTagData'],
      data: {
        userList: [props.client.client_id],
        tagAccess: tagsToSend,
      },
    };
    const reqData = await postRequest(reqObj);
    if (reqData) {
      notificationSuccess('Tags Saved To Client!');
      props.showTagManagerHandler(false);
      setClientDetailsTagList(selectedTags);
      props.getClientData(props.client.client_id);
    } else {
      notificationError('Error Saving Tags To Client');
      props.showTagManagerHandler(false);
    }
  };

  const searchHandler = val => {
    setSearchText(val);
    const lVal = val.toLowerCase();
    const filtered = selectedTags.filter(tag => {
      if (tag.name.toLowerCase().includes(lVal)) {
        return true;
      }
      return false;
    });
    if (val.length > 0) {
      setFilterTags([...filtered]);
    } else {
      setFilterTags([]);
    }
  };

  const whichList = () => {
    const sortedTagsArr = [];
    props.client.details_tag_list.forEach(tag => {
      if (tag.client_id === null) {
        sortedTagsArr.push(tag);
      }
    });
    sortedTagsArr.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return -1;
    });
    if (searchText) {
      return filterTags;
    }
    const selected = props.client.details_tag_list.filter(tag => {
      if (tag.client_id !== null) {
        return tag;
      }
    });
    return selected.concat(sortedTagsArr);
  };

  return (
    <div className="pop-confirm-background" id="modal-background">
      <div className="pop-confirm-container rem-pad-width" style={{ width: '404px', height: '748px' }}>
        <div
          className="flex-col-center h-100-P"
          style={{
            justifyContent: 'flex-start',
            width: '295px',
            margin: '0 auto',
          }}
        >
          <div className="flex-row-spacebetween-nowrap w-100-P m-top-15">
            <div />
          </div>
          <span className="fs-18 fw-500" style={{ marginTop: '15px' }}>
            Edit Client Tags
          </span>
          <input
            placeholder="Search"
            value={searchText}
            onChange={e => searchHandler(e.target.value)}
            className="h-50 w-100-P m-top-18 m-btm-6 body-head-search rem-pad-width focus-input-outline-blue"
            style={{
              width: '100%',
              height: '50px',
              backgroundColor: '#EBEBEB',
            }}
          />

          <div
            style={{
              paddingTop: '25px',
              height: '600px',
              paddingLeft: '10px',
            }}
            className="w-295 rem-pad-width chatBubblePadding overflowY hide-scrollbar"
          >
            <ul className="ul-no-style flex-col-left ">
              {whichList()
                .filter(tag => tag.id !== 0)
                .map(tag => {
                  return (
                    <li
                      key={tag.id}
                      className="fs-14 flex-row-nospacebetween-nowrap m-btm-12 pointer w-100-P p-btm-13"
                      onClick={() => setTagHandler(tag.id)}
                      style={{ borderBottom: 'solid .5px #EBEBEB' }}
                    >
                      {tag.client_id ? (
                        <FontAwesomeIcon icon="fas fa-check-circle" className="fs-18 m-right-15" color="#000" />
                      ) : (
                        <FontAwesomeIcon icon="fa-regular fa-circle" className="fs-18 m-right-15" color="#000" />
                      )}
                      <span className="circle w-12 h-12 m-right-18" style={{ backgroundColor: tag.color }} />
                      <span className="fs-12 CBdarkBlue align-left" style={{ maxWidth: '73%' }}>
                        {tag.name}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="mq-w-100-vw custom-action-save-flex m-top-15 m-btm-10">
            <button type="button" className="save-button-v2 m-btm-8" onClick={saveTagAccess}>
              Save
            </button>
            <button type="button" className="cancel-button-v2" onClick={() => props.showTagManagerHandler(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagManagerModalV2;
