import React from 'react';

const TitleAndExport = ({ title, list, type, sendExportRequest }) => {
  return (
    <div className="maw-1200 pos-rel m-btm-20">
      <div className="add-edit-wrap">
        <p className="align-left fs-13 black fw-500 "> {title} </p>
        <button
          type="button"
          className="transparentButton border-none transparent m-left-15"
          onClick={() => sendExportRequest([type])}
          style={{ color: '#33aafc' }}
        >
          Export
        </button>
      </div>
      <ul className="exportDataListUL">
        {list.map(item => (
          <li key={item} className="exportDataList">
            {item}
          </li>
        ))}
      </ul>
      {type !== 'Sales Opportunities' ? (
        <div className="pos-abs" style={{ width: '885px', borderBottom: '1px solid #9c9c9c', margin: '10px 0px' }} />
      ) : null}
    </div>
  );
};

export default TitleAndExport;
