import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReminderCard from './ReminderCard';
import SnoozeModal from '../../../../Today/Reminders/children/SnoozeModal';
import ReminderButtonHeader from './ReminderButtonHeader';
import { associateNameFormat } from '../../../../../../utils';
import { postRequest, getRequest } from '../../../../../../core/apiRequests';
import { notificationSuccess } from '../../../../../../core/actions';
import PopConfirm from '../../../../../Layout/PopConfirm';
import { setMergeClientDetailsFromAPI } from '../../../../../../reducers/client';
import DropDownSelect from '../../../../../Layout/DropdownSelect';
import ReminderModal from '../../../../../Layout/Modals/EditReminderModal';
import assignAssociate from './ReminderHelpers';
import Modal from '../../../../../CustomizedAntDesign/Modal/Modal';
import AddReminderModal from './AddReminderModal';

const Reminders = props => {
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [editReminderModal, setEditReminderModal] = useState(false);
  const [showSnoozeModal, setShowSnoozeModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState([]);
  const [assignPopup, setAssignPopup] = useState(false);
  const [reload, setReload] = useState(false);

  const dismissSelected = async (deleteAll = false) => {
    const reqObj = {
      params: ['today', props.auth.merchantId, 'dismissSelected'],
      query: {
        associateName: associateNameFormat(props.auth.firstName, props.auth.lastName),
        associateId: props.auth.userId,
      },
      data: {
        selectedAction: selectedAction.map(item => ({
          ...item,
          repeats: deleteAll ? 0 : item.repeats,
        })),
      },
    };

    const responseObj = await postRequest(reqObj);

    if (responseObj) {
      setSelectedAction([]);
      props.setDismissPopup(false);
      notificationSuccess(`Reminder(s) ${deleteAll ? 'Deleted' : 'Dismissed'}!`);
      setTimeout(() => {
        setReload(true);
      }, 1500);
    }
  };

  const handleClickDismissReminders = async () => {
    const repeats = selectedAction.some(item => item.repeats);
    if (repeats) {
      props.setDismissPopup(true);
    } else {
      dismissSelected(false);
    }
  };

  const getReminderData = () => {
    if (props.auth.merchantId && props.client.client_id) {
      (async () => {
        const reqObj = {
          params: ['clients', props.auth.merchantId, props.client.client_id, 'getClientReminders'],
          query: {
            limit: 4,
          },
          loadingStatusOff: true,
        };
        const data = await getRequest(reqObj);
        if (data) {
          setReload(false);
          setSelectedAction([]);
          setMergeClientDetailsFromAPI({ reminders: data.reminders });
        }
      })();
    }
  };

  useEffect(() => {
    getReminderData();
  }, [props.client.client_id, reload]);

  const returnReminder = reminders => {
    const reminderArr = [];
    reminders.forEach(reminder => {
      reminderArr.push(
        <ReminderCard
          key={reminder.id}
          client={props.client}
          reminder={reminder}
          auth={props.auth}
          setAssignPopup={setAssignPopup}
          showMessageHandler={props.showMessageHandler}
          setSelectedAction={setSelectedAction}
          selectedAction={selectedAction}
        />,
      );
    });
    return reminderArr;
  };

  return (
    <>
      <div className="fs-15 fw-500 flex-col-left m-left-21 cb-sales-opportunities" style={{ alignItems: 'stretch' }}>
        <div className="flex-row-spacebetween-wrap" style={{ maxWidth: '878px' }}>
          <div className="flex-row-spacebetween-nowrap" style={{ alignItems: 'center' }}>
            <p>Reminders</p>
            <FontAwesomeIcon
              size="1x"
              icon={['far', 'plus']}
              className="pointer m-left-16 fs-19"
              onClick={() => setShowReminderModal(true)}
              id="ClientDetailsEditTag"
            />
          </div>
          {selectedAction.length > 0 ? (
            <ReminderButtonHeader
              setAssignPopup={setAssignPopup}
              auth={props.auth}
              setSelectedAction={setSelectedAction}
              setEditReminderModal={setEditReminderModal}
              selectedAction={selectedAction}
              setReload={setReload}
              setShowSnoozeModal={setShowSnoozeModal}
              handleClickDismissReminders={handleClickDismissReminders}
            />
          ) : (
            props.client.reminders.length > 0 && (
              <button
                type="button"
                className="blue fs-16 border-none transparent fw-500"
                onClick={() => {
                  props.setViewAllType('allReminders');
                }}
              >
                VIEW ALL
              </button>
            )
          )}
        </div>
        {props.client.reminders.length > 0 ? (
          returnReminder(props.client.reminders.slice(0, 4))
        ) : (
          <div className="flex-col-left">
            <div className="fw-400 fs-12 align-left" style={{ color: '#818181' }}>
              No Reminders
            </div>
            <button
              type="button"
              className="blue fs-14 border-none transparent fw-500 align-left"
              style={{ padding: 0 }}
              onClick={() => {
                props.setViewAllType('allReminders');
              }}
            >
              VIEW ALL
            </button>
          </div>
        )}
      </div>
      {showSnoozeModal && (
        <SnoozeModal
          openModal={showSnoozeModal}
          setOpenModal={setShowSnoozeModal}
          merchantId={props.auth.merchantId}
          actions={selectedAction}
          refresh={getReminderData}
        />
      )}
      {editReminderModal && (
        <ReminderModal
          open={editReminderModal}
          reminder={selectedAction?.[0] ?? {}}
          allAssoc={props.allAssoc}
          onClose={() => setEditReminderModal(false)}
          setReload={setReload}
        />
      )}
      <Modal
        open={props.dismissPopup}
        onCancel={() => props.setDismissPopup(false)}
        footer={null}
        width={348}
        destroyOnClose
        closable
        centered
        className="cb-reminders-modal"
      >
        <div className="cb-reminders-modal__body">
          <h3>Dismiss reminders</h3>
          <p>This reminder repeats. Would you like to dismiss this occurrence or delete it?</p>
        </div>

        <div className="cb-reminders-modal__footer">
          <button type="button" className="save-button-v2 m-btm-8" onClick={() => dismissSelected(true)}>
            Delete All
          </button>

          <button type="button" className="cancel-button-v2" onClick={() => dismissSelected(false)}>
            Dismiss
          </button>
        </div>
      </Modal>
      {assignPopup ? (
        <PopConfirm
          popStyle={{
            width: '380px',
          }}
          confirmButtonText="SAVE"
          cancelButtonText="CANCEL"
          confirm="Assign Associate"
          description={[
            <div key="primary" className="align-left m-top-10" style={{ height: '175px' }}>
              <span className="dropDownDescriptionText">Assign an Associate</span>
              <DropDownSelect
                classProps="mq-w-200 w-295 align-left m-top-8"
                options={props.allAssoc.map(asc => ({
                  label: asc.fullName ? asc.fullName : asc.first_name,
                  value: asc.id,
                }))}
                placeholder="Select an Associate..."
                value={props.associate}
                onChange={obj => {
                  props.setAssociate(obj.value);
                }}
              />
            </div>,
          ]}
          noButtonDisable
          onCancel={() => setAssignPopup(false)}
          onConfirm={() =>
            assignAssociate({
              setAssignPopup,
              auth: props.auth,
              associate: props.associate,
              selectedAction,
              setSelectedAction,
              setReload,
            })
          }
        />
      ) : null}
      {showReminderModal && (
        <AddReminderModal
          setReload={setReload}
          setShowReminderModal={setShowReminderModal}
          client={props.client}
          clientId={props.client.client_id}
          auth={props.auth}
        />
      )}
    </>
  );
};
export default Reminders;
