import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getClients } from '../../../../../../api/clients';
import usePaginatedRequest from '../../../../../../hooks/usePaginatedRequest';
import SearchSelect from '../../../../../CustomizedAntDesign/SearchSelect';

const SearchClientsInput = ({ posType, onSelect, className, requireCollections=false, style, placeholder = 'Search client by name' }) => {
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);

  const { loading, results, setSearch } = usePaginatedRequest({
    limit: 50,
    mergeResults: true,
    filters: {
      archive_date: {
        $null: true
      },
    },
    requestMethod: getClients,
  });

  const handleSetOptions = useCallback(() => {
    const newOptions = results.flatMap(item => {
      if (requireCollections && !item.collections) return [];
      const suffix = (posType ? item.pos_ref_num : item.mobile) || item.mobile;
      const label = item.name || item.preferred_name || suffix;
      return {
        value: item.id.toString(),
        label,
        suffix,
      };
    });
    setOptions(newOptions);
  }, [results]);

  const handleSelect = (_, option) => setValue(option);

  useEffect(() => {
    handleSetOptions();
  }, [results]);

  useEffect(() => {
    const selectedOption = results.find(e => e.id.toString() === value?.value);

    onSelect(selectedOption);
  }, [value]);

  const emptyOptions = !loading && !options.length;
  const placeholderFormatted = emptyOptions ? 'No Clients' : placeholder;

  return (
    <SearchSelect
      value={value}
      options={options}
      loading={loading}
      filterOption={false}
      onSearch={setSearch}
      onSelect={handleSelect}
      placeholder={placeholderFormatted}
      //disabled={emptyOptions}
      className={className || ''}
      showSearch
      style={style}
    />
  );
};

const mapStateToProps = state => ({
  posType: state.auth.pos_type,
});

export default connect(mapStateToProps, {})(SearchClientsInput);
