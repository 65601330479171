import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeadHeader from './LeadHeader';
import LeadBody from './LeadBody';
import EdisonAiClientDetails from '../ClientDetails';

const LeadOuterComp = ({
  headerText,
  headerSubTxt,
  leadBody,
  leadObj,
  setShowLead,
  headerSubTxtClass,
  headerDivMargin,
  myClient,
  showMyClient,
  sendEdisonFeedback,
}) => {
  const [showClientDetails, setShowClientDetails] = useState(false);

  const handleBubbleUp = e => {
    if (e.target.getAttribute('id') === 'edisonBackgroundDiv') {
      setShowLead(false);
      if (headerText === 'Help me improve') {
        sendEdisonFeedback('NONE', 'exit');
      }
    }
  };

  const edisonClose = () => {
    setShowLead(false);
    if (headerText === 'Help me improve') {
      sendEdisonFeedback('NONE', 'exit');
    }
  };

  return (
    <div
      onClick={e => handleBubbleUp(e)}
      role="button"
      className="pop-confirm-background flex-col-center"
      style={{ zIndex: 40, display: 'grid', overflow: 'auto' }}
      tabIndex={0}
      id="edisonBackgroundDiv"
    >
      <div className="leadOuterCompDiv" style={{ maxWidth: showClientDetails ? '1024px' : '540px' }}>
        <div className="flex-row-spacebetween-nowrap" style={{ margin: '20px 20px' }}>
          {showMyClient ? (
            <div
              style={{
                width: '83px',
                height: '22px',
                backgroundColor: myClient ? '#E8F4FF' : '#EBEBEB',
              }}
            >
              <span
                className="fw-600 fs-10 align-center"
                style={{ color: myClient ? '#33aafc' : '#9C9C9C'}}
              >
                {myClient ? 'MY CLIENT' : 'STORE CLIENT'}
              </span>
            </div>
          ) : (
            <div />
          )}
          <div className="pointer">
            <FontAwesomeIcon onClick={() => edisonClose()} icon="fa-solid fa-x" style={{ color: '#000000' }} />
          </div>
        </div>
        <div className="flex-row-spacebetween-nowrap">
          <div style={{ width: '100%', maxWidth: '540px' }}>
            {/* exit and lead top bar */}


            <LeadHeader
              headerText={headerText}
              headerSubTxt={headerSubTxt}
              headerSubTxtClass={headerSubTxtClass}
              headerDivMargin={headerDivMargin}
            />
            <LeadBody
              setShowLead={setShowLead}
              showClientDetails={showClientDetails}
              setShowClientDetails={setShowClientDetails}
            >
              {leadBody}
            </LeadBody>
          </div>

          {showClientDetails ? (
            <>
              <div className="leadOuterCompDiv-separator" />
              <EdisonAiClientDetails setShowLead={setShowLead} lead={leadObj} />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LeadOuterComp;
