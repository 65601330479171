import React from 'react';
import ImportTable from '../ImportTable';
import addClientsHeaderOptions from '../importDropDownOptions/addClientsHeaderOptions.json';
import addClientTagsHeaderOptions from '../importDropDownOptions/addClientTagsHeaderOptions.json';
import addProductsHeaderOptions from '../importDropDownOptions/addProductsHeaderOptions.json';
import PaymentBannerSpacer from '../../../../Layout/PaymentBannerSpacer';

const ConfirmHeaders = props => {
  const confirmHeadersHandler = () => {
    let JSONObj = {
      clients: addClientsHeaderOptions,
      tags: addClientTagsHeaderOptions,
      products: addProductsHeaderOptions,
    };
    let currentJSON = JSONObj[props.endPoint];

    const storageHeadersStr = localStorage.getItem(`${props.endPoint}Headers`);
    let storageHeaders = {};
    if (storageHeadersStr) {
      storageHeaders = JSON.parse(storageHeadersStr);
    }
    // prepare columns list from headers
    let tempColumns = {};
    Object.values(props.rawData[0]).map((element, index) => {
      tempColumns[index] = {
        selector: '',
        displayName: element,
        ignore: false,
        originalName: element,
      };
      // this will auto-populate stuff if the header name matches our preset dropdown menu
      const lcElement = element.toLowerCase();
      if (storageHeaders.hasOwnProperty(`${lcElement}`)) {
        tempColumns[index].selector = storageHeaders[lcElement].value;
        tempColumns[index].displayName = storageHeaders[lcElement].label;
        tempColumns[index].ignore = storageHeaders[lcElement].ignore || false;
        tempColumns[index].existingPref = storageHeaders[lcElement].existingPref || false;
      }

      if (!tempColumns[index].selector) {
        currentJSON.forEach(option => {
          if (
            `${option.label}`.toLowerCase === `${element}`.toLowerCase() ||
            `${option.value}`.toLowerCase === `${element}`.toLowerCase() ||
            (option.hasOwnProperty('alternates') && option.alternates.includes(`${element}`.toLowerCase()))
          ) {
            tempColumns[index].selector = option.value;
            tempColumns[index].displayName = option.label;
          }
        });
      }
    });
    let tempData = [...props.rawData];
    // this will remove the first row from the data and move it into the columns section with the logic above BUT prevents someone from coming back and deleting rows over and over again
    if (!props.headersConfirmed) {
      tempData.shift();
      props.setHeadersConfirmed(true);
    }
    props.setData(tempData);
    props.setColumns(tempColumns);
    props.setUploadModal('match');
  };

  // When you hit "NO" for if the first row is the headers
  const noHeaderHandler = () => {
    props.setData(props.rawData);
    props.setUploadModal('match');
  };

  return (
    <div className="pos-abs z-index-5 left-0 align-top w-100-vw h-100-vh bg-white p-42 rem-pad-width max-h-100vh overflowY m-top-44">
      <PaymentBannerSpacer />
      <div className="maw-965">
        <div className="flex-row-spacebetween-wrap w-100-P align-items-center">
          <div className="flex-col-left">
            <span className="align-left fs-18 fw-500 w-100-P">Upload Data</span>
            <span className="align-left fs-14 w-100-P">Bulk add {props.endPoint}</span>
          </div>
          <span className="layoutBreadcrumb">
            <span className="current-bread-crumb">Upload</span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span>Match</span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span>Review</span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span>Done</span>
          </span>
        </div>
        <div className="header-container m-btm-35">
          <span className="align-left fs-18 fw-500 w-100-P">Does the selected row contain column headers?</span>
          <button className="import-positive-button" onClick={() => confirmHeadersHandler()}>
            YES
          </button>
          <button className="import-positive-button" onClick={() => noHeaderHandler()}>
            NO
          </button>
          <button
            className="import-cancel-button"
            style={{marginTop: 0}}
            onClick={() => {
              props.setUploadModal('chooseFile');
              props.setHeadersConfirmed(false);
            }}
          >
            BACK
          </button>
        </div>
        <ImportTable columns={props.columns} data={props.rawData} confirm />
      </div>
    </div>
  );
};

export default ConfirmHeaders;
