import { getRequest } from "../../../../../core/apiRequests";
import { setMergeClientDetailsFromAPI } from "../../../../../reducers/client";

export const getReminderData = (client, merchantId) => {
    if (client.client_id) {
      (async () => {
        const reqObj = {
          params: ['chats', merchantId, client.client_id, 'get2AutoReminders'],
          loadingStatusOff: true,
        };
        const data = await getRequest(reqObj);
        if (data) {
          setMergeClientDetailsFromAPI({ reminders: data });
        }
      })();
    }
  };