import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton, DatePicker } from 'antd';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import { getRequest, saveListItem, postRequest, deleteRequest } from '../../../../core/apiRequests';
import { notificationSuccess, notificationError } from '../../../../core/actions';
import { Buttons } from './Buttons';
import PopConfirm from '../../PopConfirm';
import RadioButton from '../../RadioButton';
import { setClientLifeEvents } from '../../../../reducers/client';
import '../infoDrawer.css';

const LifeEvents = ({ merchantId, client, auth, dirtyFields, lifeEvents, resetField, register, setValue, control }) => {
  const [loading, setLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const clientId = client.client_id;
  const [timeFrame, setTimeFrame] = useState('repeats');
  const [newDate, setNewDate] = useState(null);
  const [name, setName] = useState('');
  const [newLifeEvent, setNewLifeEvent] = useState(false);
  const [selectedLE, setSelectedLE] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    if (merchantId && clientId) {
      (async () => {
        const reqObj = {
          params: ['chats', merchantId, clientId, 'getAllClientLifeEvents'],
          loadingStatusOff: true,
        };
        const data = await getRequest(reqObj);
        if (data.length > 0) {
          resetField('lifeEvents', { defaultValue: data[0].lifeEventData });
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    }
  }, [merchantId, client.client_id]);

  const LifeEventData = () => {
    const returnArr = [];
    if (loading) {
      returnArr.push(
        <div key="skeleton">
          <Skeleton
            paragraph={{
              rows: 3,
            }}
            active
          />
        </div>,
      );
      return returnArr;
    }
    if (lifeEvents.length > 0) {
      lifeEvents.forEach(event => {
        // ask chris tomorrow about life event dates, it looks like typed dates are longer than 4
        if (event.date && `${event.date}`.length === 4) {
          returnArr.push(
            <div className="flex-col-left m-btm-8" key={event.date + event.type}>
              <span className="fs-12 fw-500 gray">{event.type}</span>
              <span className="fs-12 fw-500 black">{moment(event.date, 'MMDD').format('MMMM DD')}</span>
            </div>,
          );
        }
        if (event.date && `${event.date}`.length === 8) {
          returnArr.push(
            <div className="flex-col-left m-btm-8" key={event.date + event.type}>
              <span className="fs-12 fw-500 gray">{event.type}</span>
              <span className="fs-12 fw-500 black">{moment(event.date, 'YYYYMMDD').format('MMMM DD')}</span>
            </div>,
          );
        }
      });
      if (returnArr.length > 0) {
        return returnArr;
      }
    }
    return <span className="gray fs-12">No Life Events</span>;
  };

  const handleSave = useCallback(async () => {
    const fn = auth.firstName || '';
    const ln = auth.lastName || '';
    const reqObj = {
      params: ['clients', clientId, 'editClientLifeEvents'],
      query: { createdBy: `${fn} ${ln}` },
      data: {
        client_id: clientId,
        lifeEvents,
        preferences: client.preferences,
        clientObj: { email: client.email, mobile: client.mobile, store_id: client.store_id },
        name: client.name,
        associateId: auth.userId,
        doNotContact: client.do_not_contact,
        contactPref: client.preferred_contact_type,
        addresses: client.addresses,
        preferred_name: client.preferred_name,
      },
    };
    try {
      await postRequest(reqObj);
      setClientLifeEvents(lifeEvents);
      notificationSuccess('Life Events Updated');
    } catch (err) {
      notificationError('Error Updating Life Events');
    } finally {
      setOpenEdit(false);
    }
  }, [clientId, lifeEvents]);

  const handleCancel = useCallback(() => {
    setOpenEdit(false);
    resetField('lifeEvents');
  }, []);

  const setEditButton = useCallback(value => {
    setOpenEdit(value);
    setNewLifeEvent(false);
  }, []);

  const handleUpdateLifeEvents = newLifeEvents => {
    const reducerLifeEvents = [...lifeEvents, ...newLifeEvents];
    resetField('lifeEvents', { defaultValue: reducerLifeEvents });
    setClientLifeEvents(reducerLifeEvents);
  };

  const handleSaveNew = async () => {
    setLoading(true);

    const firstName = auth?.firstName || '';
    const lastName = auth?.lastName || '';
    const createdBy = firstName + lastName.trim();

    const dateFormat = timeFrame === 'oneTime' ? 'YYYYMMDD' : 'MMDD';
    const formatedDate = moment(newDate).format(dateFormat);

    const requestData = { clientId, name, timeFrame, date: formatedDate };
    const responseData = await saveListItem('lifeEvent', auth.merchantId, requestData, createdBy);

    if (responseData.id) {
      handleUpdateLifeEvents([responseData]);
      setNewLifeEvent(false);
      setOpenEdit(false);
      notificationSuccess('Life Event Created');
    }
    setLoading(false);
  };

  const handleValidate = () => {
    if (!name) {
      notificationError('Please Add Life Event Name');
      return;
    }

    if (!timeFrame) {
      notificationError('Please Add Life Event Time Frame');
      return;
    }

    if (!newDate) {
      notificationError('Please Set Scheduled Date');
      return;
    }

    handleSaveNew();
  };

  const openDeleteModal = useCallback(lifeEvent => {
    setSelectedLE(lifeEvent);
    setOpenDelete(true);
  }, []);

  const handleDeleteLifeEvent = useCallback(async () => {
    const requestData = {
      params: ['clients', clientId, 'lifeEvents', selectedLE.id],
    };
    try {
      await deleteRequest(requestData);
      notificationSuccess('Life Event Removed');
      const filteredLifeEvents = lifeEvents.filter(lE => lE.id !== selectedLE.id);
      resetField('lifeEvents', { defaultValue: filteredLifeEvents });
    } catch (err) {
      notificationError('Error Removing Life Event');
    } finally {
      setOpenDelete(false);
      setSelectedLE(null);
    }
  }, [selectedLE, lifeEvents]);

  return (
    <div
      style={{ borderTop: '1px #ebebeb solid' }}
      id="client-details-life-box"
    >
      <div className="flex-row-center" style={{ position: 'relative', gap: '8px' }}>
        <p className="fw-500 gray fs-15 align-left m-btm-5">Life Events</p>
        {openEdit && !newLifeEvent && (
          <FontAwesomeIcon size="1x" icon={['far', 'plus']} className="pointer" onClick={() => setNewLifeEvent(true)} />
        )}
        {newLifeEvent ? null : (
          <Buttons
            open={openEdit}
            setOpen={setEditButton}
            onSave={handleSave}
            onCancel={handleCancel}
            containerStyles={{ top: '12px' }}
            id='client-details-life-buttons'
            parentId='client-details-life-box'
          />
        )}
      </div>
      {newLifeEvent ? (
        <LifeEventNew
          setValue={setValue}
          setTimeFrame={setTimeFrame}
          timeFrame={timeFrame}
          loading={loading}
          handleValidate={handleValidate}
          name={name}
          setName={setName}
          setNewLifeEvent={setNewLifeEvent}
          setNewDate={setNewDate}
        />
      ) : openEdit ? (
        <LifeEventForm
          register={register}
          setValue={setValue}
          lifeEvents={lifeEvents}
          control={control}
          openDeleteModal={openDeleteModal}
        />
      ) : (
        LifeEventData()
      )}
      {openDelete && (
        <PopConfirm
          confirm={`Are you sure you want to delete "${selectedLE.type}"?`}
          description="This will permanently delete this life event."
          onCancel={() => setOpenDelete(false)}
          onConfirm={handleDeleteLifeEvent}
        />
      )}
    </div>
  );
};

const LifeEventNew = ({
  timeFrame,
  setTimeFrame,
  loading,
  handleValidate,
  name,
  setName,
  setNewLifeEvent,
  setNewDate,
}) => {
  return (
    <div className="flex-col-center h-100-P">
      <div className="flex-col-left m-btm-8 w-100-P m-top-15 m-btm-15">
        <span className="fs-12 fw-500 gray">Add Life Event</span>
        <input
          className="fs-12 fw-500 gray outline-none w-100-P client-input m-btm-8"
          placeholder="Life Event Name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </div>
      <div
        className="align-left fs-12"
        style={{
          width: '100%',
          marginBottom: '32px',
        }}
        onChange={e => setTimeFrame(e.target.value)}
      >
        <div
          style={{
            marginBottom: '14px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <RadioButton id="repeats" name="expiration" value="repeats" defaultChecked={timeFrame === 'repeats'} />

          <label
            htmlFor="repeats"
            style={{
              marginLeft: '16px',
            }}
          >
            Repeat annually
          </label>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <RadioButton id="oneTime" name="expiration" value="oneTime" defaultChecked={timeFrame === 'oneTime'} />

          <label
            htmlFor="oneTime"
            style={{
              marginLeft: '16px',
            }}
          >
            One-time
          </label>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          width: '100%',
          marginBottom: '48px',
        }}
      >
        <DatePicker
          style={{
            paddingLeft: '0px',
            fontWeight: '500',
            fontSize: '12px',
            width: '270px',
          }}
          format={timeFrame === 'oneTime' ? 'MM-DD-YYYY' : 'MM-DD'}
          onChange={date => setNewDate(date)}
          bordered={false}
          placeholder="Date"
        />
        <span className="client-input" />
      </div>
      <div className="mq-w-100-vw custom-action-save-flex">
        <button className="save-button-v2 m-btm-8" disabled={false} type="button" onClick={() => handleValidate()}>
          Save
        </button>
        <button
          className="cancel-button-v2"
          type="button"
          onClick={() => {
            setName('');
            setNewDate(null);
            setNewLifeEvent(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const LifeEventForm = ({ register, setValue, lifeEvents, control, openDeleteModal }) => {
  return lifeEvents.map((event, index) => {
    const prefix = `lifeEvents.${index}.`;
    return (
      <div className="flex-row-spacebetween-start" key={`${event.type}-${index}`}>
        <div className="flex-col-left m-btm-8" key={event.date} style={{ borderBottom: 'solid 1px #818181' }}>
          <span className="fs-12 fw-500 gray" {...register(`${prefix}type`, { required: true })}>
            {event.type}
          </span>
          {event.life_event_type_id ? (
            <Controller
              name={`${prefix}type`}
              control={control}
              render={({ field }) => (
                <DatePicker
                  style={{ paddingLeft: '0px', fontWeight: '500', fontSize: '12px', width: '270px' }}
                  format="MM-DD"
                  onChange={date => {
                    date ? setValue(`${prefix}date`, moment(date).format('MMDD')) : setValue(`${prefix}date`, '')}}
                  ref={field.ref}
                  bordered={false}
                  placeholder="Event Date"
                  defaultValue={event.date ? moment(event.date, 'MM-DD') : ''}
                  type="button"
                />
              )}
            />
          ) : (
            <div className="flex-row-spacebetween-nowrap" style={{ width: '270px' }}>
              <Controller
                name={`${prefix}type`}
                control={control}
                render={({ field }) => (
                  <DatePicker
                    style={{ paddingLeft: '0px', fontWeight: '500', fontSize: '12px', width: '115px' }}
                    format="MM-DD"
                    onChange={date => {
                      date
                        ? setValue(`${prefix}date`, moment(date).format('MMDD'))
                        : setValue(`${prefix}date`, '');
                    }}
                    ref={field.ref}
                    bordered={false}
                    placeholder="Event Date"
                    defaultValue={
                      event.date
                        ? event.date.length > 4
                          ? moment(event.date, 'YYYY-MM-DD')
                          : moment(event.date, 'MM-DD')
                        : ''
                    }
                    type="button"
                  />
                )}
              />
              <div style={{ width: '40px', marginTop: '4px' }}>
                <FontAwesomeIcon
                  icon={['far', 'trash-alt']}
                  className="pointer"
                  color="#FF7A7A"
                  onClick={() => openDeleteModal(event)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  });
};

const mapStateToProps = state => ({
  messaging: state.messaging,
});
export default connect(mapStateToProps)(LifeEvents);
