import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Cascader, Dropdown, Popover, message, theme } from 'antd-v5';
import { getProductsBrands, getProductsCategories } from '../../../api/products';

const { useToken } = theme;

const ProductFiltersCascader = props => {
  const { setOpen, brands, setBrands, brandsOptions, categories, setCategories, categoriesOptions, showCategories } = props;
  const { token } = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    padding: '8px',
    transform: 'translateX(-50%)',
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = useMemo(
    () => {
      const categories = showCategories ?
        [{
          value: 'categories',
          label: 'Categories',
          children: categoriesOptions,
        }] :
        [];
      return [
        {
          value: 'brands',
          label: 'Brands',
          children: brandsOptions,
        },
        ...categories
      ]
    },
    [brandsOptions, categoriesOptions, showCategories],
  );

  const handleChangeBrandsOrCategories = useCallback(() => {
    const newSelectedOptions = [];

    if (brandsOptions.length === brands.length) {
      newSelectedOptions.push(['brands']);
    } else {
      newSelectedOptions.push(...brands.map(item => ['brands', item]));
    }

    if(showCategories) {
      if (categoriesOptions.length === categories.length) {
        newSelectedOptions.push(['categories']);
      } else {
        newSelectedOptions.push(...categories.map(item => ['categories', item]));
      }
    }

    setSelectedOptions(newSelectedOptions);
  }, [brandsOptions, brands, categoriesOptions, categories, showCategories]);

  const handleClickApply = useCallback(() => {
    const newSelectedBrands = [];
    const newSelectedCategories = [];

    selectedOptions.forEach(item => {
      if (item[0] === 'brands') {
        if (item[1] === undefined) {
          newSelectedBrands.push(...brandsOptions);
        } else {
          newSelectedBrands.push(item[1]);
        }
      } else if (item[0] === 'categories') {
        if (item[1] === undefined) {
          newSelectedCategories.push(...categoriesOptions);
        } else {
          newSelectedCategories.push(item[1]);
        }
      }
    });

    setBrands(newSelectedBrands);
    setCategories(newSelectedCategories);
    setOpen(false);

    message.success('Filters have been applied');
  }, [selectedOptions, brandsOptions, categoriesOptions, setBrands, setCategories]);

  const handleClickCancel = useCallback(() => {
    handleChangeBrandsOrCategories();
    setOpen(false);

    message.info('Changes have been discarded');
  }, [handleChangeBrandsOrCategories]);

  const handleClickClear = useCallback(() => {
    setBrands([]);
    setCategories([]);
    setOpen(false);

    message.info('Filters have been cleared');
  }, [setBrands, setCategories]);

  useEffect(() => {
    handleChangeBrandsOrCategories();
  }, [handleChangeBrandsOrCategories]);

  return (
    <div style={contentStyle}>
      <Cascader
        value={selectedOptions}
        onChange={setSelectedOptions}
        options={options}
        placeholder="Select filters"
        maxTagCount={10}
        style={{ width: '280px', marginBottom: '12px' }}
        onClear={handleClickClear}
        showSearch
        allowClear
        multiple
      />

      <div style={{ display: 'flex', gap: '12px' }}>
        <Button onClick={handleClickCancel} block>
          Cancel
        </Button>

        <Button type="primary" onClick={handleClickApply} block>
          Apply
        </Button>
      </div>
    </div>
  );
};

const ProductFilters = props => {
  const { open, setOpen, brands, setBrands, categories, setCategories, children, showCategories } = props;

  const [brandsOptions, setBrandsOptions] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  const fetchProductsBrands = useCallback(async () => {
    try {
      const response = await getProductsBrands({
        params: { pageSize: '*' },
      });

      const results = response.data.results.map(item => ({
        value: item,
        label: item,
      }));

      setBrandsOptions(results);
    } catch (error) {
      message.error('Failed to fetch brands');
    }
  }, []);

  const fetchProductsCategories = useCallback(async () => {
    try {
      const response = await getProductsCategories({
        params: { pageSize: '*' },
      });

      const results = response.data.results.map(item => ({
        value: item,
        label: item,
      }));

      setCategoriesOptions(results);
    } catch (error) {
      message.error('Failed to fetch categories');
    }
  }, []);

  useEffect(() => {
    if (brands && setBrands) {
      fetchProductsBrands();
    }
  }, [brands, setBrands]);

  useEffect(() => {
    if (categories && setCategories && showCategories) {
      fetchProductsCategories();
    }
  }, [categories, setCategories, showCategories]);


  const handleOpenFilter = useCallback(open => {
    setOpen(!open);
  }, [open]);

  return (
    <Dropdown
      placement="bottomLeft"
      trigger={['click']}
      onOpenChange={handleOpenFilter}
      open={open}
      destroyPopupOnHide
      dropdownRender={() =>  (
        <ProductFiltersCascader
          setOpen={setOpen}
          brands={brands}
          setBrands={setBrands}
          brandsOptions={brandsOptions}
          categories={categories}
          setCategories={setCategories}
          categoriesOptions={categoriesOptions}
          showCategories={showCategories}
        />
      )}
    >
      {children}
    </Dropdown>
  );
};

export default ProductFilters;
