/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import axios from 'axios';
import { message } from 'antd';
import Layout from '../../../Layout/StandardLayout';
import Button from '../../../Layout/Button';
import { getRequest } from '../../../../core/apiRequests';
import TitleDescription from '../../../Layout/DescriptionBox';
import Table from '../../../Layout/Table';
import { setReportAssoc } from '../../../../reducers/reports';
import { setAuthStoreId } from '../../../../reducers/auth';
import DropdownSelect from '../../../Layout/DropdownSelect';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import StyledLink from '../../../Layout/StyledLink';
import RangePicker from '../../../CustomizedAntDesign/RangePicker';
import OpportunityModal from './OpportunityModal';
import PopConfirm from '../../../Layout/PopConfirm';
import { API_URL } from '../../../../constants';
import { notificationSuccess } from '../../../../core/actions';
import EditOppModalWithNotes from '../../ClientsV2/components/QuickView/SalesOpportunities/components/EditOppModalWithNotes';
import '../reports.css';

const disabledDate = current => {
  return current && current > moment().endOf('day');
};

const SalesOpportunity = props => {
  const [dateRange, setDateRange] = useState([moment().subtract(1, 'months'), moment()]);
  const [tableData, setTableData] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [allAssoc, setAllAssoc] = useState([]);

  const [pipeFilterList, setPipeFilterList] = useState([{ label: 'All Opportunity Types', value: 0 }]);
  const [stageFilterList, setStageFilterList] = useState([{ label: 'All Stages', value: 0 }]);
  const [stageWeight, setStageWeight] = useState(0);
  const [filterPipeline, setFilterPipeline] = useState(0);
  const [filterStage, setFilterStage] = useState(null);
  const associateOptions = allAssoc.map(associate => ({
    label: associate.fullName || `${associate.first_name || ''} ${associate.last_name || ''}`.trim(),
    value: associate.id,
  }));

  const [loading, setLoading] = useState(false);
  const [salesOpportunity, setSalesOpportunity] = useState(null);
  const [salesOpportunityHistory, setSalesOpportunityHistory] = useState(null);
  const [showOpportunityDetailsModal, setShowOpportunityDetailsModal] = useState(false);
  const [showDeleteOpportunityModal, setShowDeleteOpportunityModal] = useState(false);
  const [showEditOpportunityModal, setShowEditOpportunityModal] = useState(false);

  useEffect(() => {
    if (props.auth.merchantId) {
      const allAssociateOption = { id: 0, fullName: 'All Associates' };
      const reqObj = {
        params: ['report', props.auth.merchantId, props.auth.storeId || 0, 'clientByAction'],
        query: {
          userId: props.auth.userId,
          role: props.auth.role,
        },
      };
      const assoc_data = async () => {
        const data = await getRequest(reqObj);
        const sortedAscts = data.sort((a, b) => {
          if (`${a.first_name || ''} ${a.last_name || ''}` > `${b.first_name || ''} ${b.last_name || ''}`) {
            return 1;
          }
          if (`${a.first_name || ''} ${a.last_name || ''}` < `${b.first_name || ''} ${b.last_name || ''}`) {
            return -1;
          }
          return 0;
        });
        setAllAssoc([allAssociateOption, ...sortedAscts]);
        setReportAssoc(0);
      };

      const stageReqObj = {
        params: ['report', props.auth.merchantId, props.auth.storeId || 0, 'salesPipeline_stages'],
        query: {
          filterPipeline: filterPipeline || '',
        },
      };

      const stage_data = async () => {
        const stageData = await getRequest(stageReqObj);
        if (stageData && stageData.length > 0) {
          const dataObj = stageData[0];
          const { pipeArray, stageArray } = dataObj;
          if (pipeArray.length > 0) {
            const dropDownPipes = pipeArray.map(pipe => ({
              label: pipe.name,
              value: pipe.pipeline_id,
            }));
            dropDownPipes.unshift({ label: 'All Opportunity Types', value: 0 });
            setPipeFilterList([...dropDownPipes]);
          }
          const dropDownStages = stageArray.map(stage => ({
            label: stage.name,
            value: stage.id,
            weight: stage.weight,
          }));
          dropDownStages.unshift({ label: 'All Stages', value: 0 });
          setStageFilterList([...dropDownStages]);
        }
      };
      assoc_data();
      stage_data();
    }
  }, [props.auth.storeId, filterPipeline, props.auth.merchantId]);

  const requestDataSalesOpp = async () => {
    setShowReport(false);
    const reqObj = {
      params: ['report', props.auth.merchantId, props.auth.storeId, 'salesOpportunity'],
      query: {
        startDt: moment(dateRange[0]).format('YYYYMMDD'),
        endDt: moment(dateRange[1]).format('YYYYMMDD'),
        pipeline: filterPipeline || '',
        stage: filterStage?.value || '',
        associateId: props.reports.associate || '',
        storeAccess: props.auth.stores.filter(st => st.id !== 0).map(st => st.id),
      },
    };
    if (props.salesPipeline.store) {
      reqObj.query.selected_store_id = props.salesPipeline.store;
    }
    const res = {};
    res.data = await getRequest(reqObj);
    setStageWeight(filterStage?.weight);
    setTableData(res.data || []);
    setShowReport(true);
  };

  const handleClickDetails = async obj => {
    console.log(obj);
    setSalesOpportunity(obj);
    setLoading(true);

    try {
      const endpoint = `${API_URL}/xa/client/${obj.merchant_id}/${obj.sales_opp_id}/getSalesOppHistory`;

      const config = {
        params: {
          merchantId: obj.merchant_id,
        },
      };

      const response = await axios.get(endpoint, config);

      console.log(response.data);

      setSalesOpportunityHistory(response.data);
      setShowOpportunityDetailsModal(true);
    } catch (error) {
      message.error('Error Fetching Sales Opportunity');
    } finally {
      setLoading(false);
    }
  };

  const handleClickDelete = () => {
    setShowOpportunityDetailsModal(false);
    setShowDeleteOpportunityModal(true);
  };

  const handleClickEdit = () => {
    setShowOpportunityDetailsModal(false);
    setShowEditOpportunityModal(true);
  };

  const handleDeleteSalesOpportunity = useCallback(async () => {
    setLoading(true);

    try {
      const { merchantId } = props.auth;
      const salesOppId = salesOpportunity.sales_opp_id;
      const clientId = salesOpportunity.client_id;
      const firstName = salesOpportunity?.first_name || '';
      const lastName = salesOpportunity?.last_name || '';
      const endpoint = `${API_URL}/xa/clients/${merchantId}/${salesOppId}/${clientId}/deleteClientSalesOpp`;

      const config = {
        params: {
          deletedBy: `${firstName} ${lastName}`,
        },
      };

      await axios.delete(endpoint, config);

      notificationSuccess('Sales Opportunity Removed');
      setShowDeleteOpportunityModal(false);
      requestDataSalesOpp();
    } catch (error) {
      notificationSuccess('Error Removing Sales Opportunity');
    } finally {
      setLoading(false);
    }
  });

  const tableHeaders = [
    {
      keyName: 'created_by_assoc',
      headerClassName: 'row1',
      title: 'Associate',
      style: { width: '10%', paddingTop: '80px' },
      render: (text, obj) => <StyledLink to={`/Associates/associate-details/${obj.assoc_id}`}>{text}</StyledLink>,
    },
    {
      keyName: 'pipeline_name',
      headerClassName: 'row1',
      title: 'Opportunity Type',
      style: { width: '10%', paddingTop: '80px' },
    },
    {
      keyName: 'stage',
      headerClassName: 'row1',
      title: 'Stage',
      style: { width: '10%', paddingTop: '80px' },
    },
    {
      keyName: 'opp_name',
      headerClassName: 'row1',
      title: 'Description',
      style: { width: '10%', paddingTop: '80px' },
      render: (text, obj) => (
        <span className="report--link-button" onClick={() => handleClickDetails(obj)}>
          {text}
        </span>
      ),
    },
    {
      keyName: 'client_name',
      headerClassName: 'row1',
      title: 'Client',
      style: { width: '10%', paddingTop: '80px' },
      render: (text, obj) => <StyledLink to={`/Clients?client=${obj.client_id}`}>{text}</StyledLink>,
    },
    {
      keyName: 'created_date',
      headerClassName: 'row1',
      title: 'Date Created',
      style: { width: '10%', paddingTop: '80px' },
    },
    {
      keyName: 'expected_close_date',
      headerClassName: 'row1',
      title: 'Expected Close Date',
      style: { width: '13%', paddingTop: '80px' },
    },
    {
      keyName: 'complete_date',
      headerClassName: 'row1',
      title: 'Closed Date',
      hidden: ![0, 1].includes(stageWeight),
      style: { width: '10%', paddingTop: '80px' },
      render: (text, obj) => {
        if (obj.closePercentage === 0 || obj.closePercentage === 1) {
          if (text === 'Invalid date') {
            return '-';
          }
          return text;
        }
        return 'N/A';
      },
    },
    {
      keyName: 'amount',
      headerClassName: 'row1',
      title: 'Amount',
      style: { width: '10%', paddingTop: '80px' },
      render: (text, obj) => {
        let amount;
        if (obj.amount === null) {
          amount = 'no amount';
        } else {
          amount = `$${obj.amount}`;
        }
        return amount;
      },
    },
    {
      keyName: 'closePercentage',
      headerClassName: 'row1',
      title: 'Close %',
      style: { width: '10%', paddingTop: '80px' },
      render: (text, obj) => {
        const percent = `${Math.round(obj.closePercentage.toFixed(2) * 100)}%`;
        return percent;
      },
    },
  ];

  return (
    <Layout
      title="Current Pipeline"
      backArrow="/Home"
      description="Set your parameters and click on 'Run Report.'"
      classProps="standard-width-with-sidebar flex-col-center"
      styleProps={{ justifyContent: 'flex-start' }}
    >
      <div className="report-smaller-description">
        {' '}
        Current Pipeline reports allow you to see how effectively your team is utilizing your current pipeline. Each
        report lists how many pipelines each of your associates have in each stage.{' '}
      </div>
      <div className="pos-rel w-100-P " style={{ maxWidth: '866px' }}>
        <TitleDescription
          title="Reporting Location"
          description="What store location would you like to run a report on?"
          input={
            <DropdownSelect
              isSearchable
              classProps="mq-w-200 w-267 align-left"
              options={props.auth.stores.map(st => ({
                label: st.name,
                value: st.id,
              }))}
              placeholder="Select a Store..."
              value={props.auth.storeId}
              onChange={obj => {
                setAuthStoreId(obj.value);
              }}
            />
          }
        />
        <TitleDescription
          title="Opportunity Type"
          description="What opportunity type would you like to filter by?"
          input={
            <DropdownSelect
              classProps="mq-w-200 w-267 align-left"
              options={pipeFilterList}
              placeholder="Select an opportunity type..."
              value={filterPipeline}
              onChange={obj => {
                setFilterPipeline(obj.value);
                setFilterStage(0);
              }}
            />
          }
        />
        <TitleDescription
          title="Stage"
          description="View all stages or select an opportunity and only view stages for that opportunity"
          input={
            <DropdownSelect
              classProps="mq-w-200 w-267 align-left"
              options={stageFilterList}
              placeholder="Select a Stage..."
              disabled={Number(filterPipeline) === 0}
              value={filterStage?.value}
              onChange={obj => {
                setFilterStage(obj);
              }}
            />
          }
        />
        <TitleDescription
          title="Associate"
          description="Which associate's report would you like to run?"
          input={
            <DropdownSelect
              classProps="mq-w-200 w-267 align-left"
              options={associateOptions}
              placeholder="Select an Associate"
              value={props.reports.associate}
              onChange={obj => {
                setReportAssoc(obj.value);
              }}
            />
          }
        />

        <TitleDescription
          title="Date Range"
          input={
            <RangePicker
              format="MM/DD/YYYY"
              disabledDate={disabledDate}
              value={dateRange}
              onChange={setDateRange}
              ranges={{
                'Last 7 Days': [moment().subtract(7, 'd'), moment()],
                'Last 30 Days': [moment().subtract(30, 'd'), moment()],
                'Last 60 Days': [moment().subtract(60, 'd'), moment()],
                'Last 90 Days': [moment().subtract(90, 'd'), moment()],
              }}
              showToday
            />
          }
          className="report-date-picker"
        />

        <div className="report-asct-msg-row" style={{ maxWidth: '866px' }}>
          <Button id="RunSalesPipelineDetailsReport" classname="darkBlueButton" onclick={e => requestDataSalesOpp(e)}>
            Run Report
          </Button>
        </div>
      </div>
      {showReport ? (
        <div className="w-100-P rem-pad-width maw-1200">
          <div
            className="import_export_group w-100-P"
            style={{
              justifyContent: 'flex-end',
              zIndex: '100',
              marginLeft: '0px',
              marginTop: '100px',
            }}
          >
            <img src="https://dashboard-v2-images.s3.amazonaws.com/ic-export.svg" alt="export" />

            <CSVLink
              data={tableData}
              filename="SalesPipelineDetails.csv"
              id="DownloadSalesPipelineDetailsReport"
              className="exportButton m-right-15"
            >
              Download
            </CSVLink>
          </div>

          <Table
            tableClassName="maw-1200 rem-pad-width m-top-15 overflowX hide-scrollbar w-100-P"
            tableContainerClass="maw-1200 w-100-P rem-pad-width "
            style={{ marginTop: '-100px', width: '100%' }}
            data={tableData}
            tableRowClassName="col-p-l-25"
            tableHeaders={tableHeaders}
            tableName="SALES_OPPORTUNITY"
          />
        </div>
      ) : null}
      <SecondaryPanel
        overhead_title="Reports"
        title={['CLIENTELING', 'MESSAGING', 'Google Reviews', 'PAYMENTS', 'AUTOMATION', 'SALES OPPORTUNITY']}
        current="Current Pipeline"
      />

      {showOpportunityDetailsModal && (
        <OpportunityModal
          open={showOpportunityDetailsModal}
          salesOpportunityHistory={salesOpportunityHistory}
          loading={loading}
          onClose={() => {
            setSalesOpportunity(null);
            setShowOpportunityDetailsModal(false);
          }}
          onClickDelete={handleClickDelete}
          onClickEdit={handleClickEdit}
        />
      )}

      {showDeleteOpportunityModal ? (
        <PopConfirm
          customContent
          confirm="Delete This Opportunity?"
          description="This will also delete attached auto reminders and messages"
          loading={loading}
          onCancel={() => {
            setShowDeleteOpportunityModal(false);
            setShowOpportunityDetailsModal(true);
          }}
          onConfirm={handleDeleteSalesOpportunity}
        />
      ) : null}

      {showEditOpportunityModal ? (
        <EditOppModalWithNotes
          showEditOppModalHandler={() => {
            setShowEditOpportunityModal(false);
            setShowOpportunityDetailsModal(true);
          }}
          salesOpp={salesOpportunityHistory?.resultsOpp[0]}
          setSalesOpp={data =>
            setSalesOpportunityHistory({
              ...salesOpportunityHistory,
              resultsOpp: [data],
            })
          }
          stageList={salesOpportunityHistory?.resultsStages}
          merchantId={props.auth.merchantId}
          client_id={salesOpportunityHistory?.resultsOpp[0].client_id}
          associateId={salesOpportunityHistory?.resultsOpp[0].associate_id}
          store_id={salesOpportunityHistory?.resultsOpp[0].store_id}
          firstName={props.auth.firstName}
          lastName={props.auth.lastName}
          onSuccess={async newSalesOpp => {
            notificationSuccess('Sales Opportunity Updated');
            setSalesOpportunity(newSalesOpp);
            requestDataSalesOpp();
          }}
        />
      ) : null}
    </Layout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  reports: state.reports,
  stores: state.stores,
  salesPipeline: state.salesPipeline,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SalesOpportunity);
