import React from 'react';

// -------- ************ MAKE SURE YOU HAVE THE CONTAINING DIV YOU PUT THIS IN AS POSITION: RELATIVE OR YOU WILL BE SWEARING FOR HOURS ************ -------- //

const ToolTip = props => {
  const { isShown, idx, description, toolTipWidth, toolTipRight, toolTipBottom, arrowLeft } = props;
  return (
    <div
      id={isShown === idx ? 'textBlock_active' : 'textBlock'}
      className="pos-abs border-r12"
      style={{
        backgroundColor: '#4e4e4e',
        width: toolTipWidth,
        right: toolTipRight,
        bottom: toolTipBottom,
        zIndex: '9999999999999999999999999999999999999',
      }}
    >
      <p className="white" style={{ padding: '11px 12px', margin: '0px', fontSize: '12px', fontWeight: 400 }}>
        {description}
      </p>
      <img
        src="https://dashboard-v2-images.s3.amazonaws.com/caret-down-solid.svg"
        className="pos-abs"
        style={{ bottom: '-15px', left: arrowLeft }}
      />
    </div>
  );
};

export default ToolTip;
