import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Timeline, Button, message } from 'antd-v5';
import { MassMessages } from '../../../../../componentsV2/Layout/Report/components/Icons';
import { BellOutlined, FileTextOutlined } from '@ant-design/icons';
import TimelineItem from '../../../../../componentsV2/Layout/Report/components/Timeline';
import { getSalesOppLog } from '../../../../../api/report';
import formatNumberAsCurrency from '../../../../../utils/formatNumberAsCurrency';
import OpportunityModal from '../../SalesOpportunity/OpportunityModal';
import { getRequest } from '../../../../../core/apiRequests';

const SalesOppExpandRow = ({ salesOppData,
  salesOppId,
  clientId,
  createdBy,
  client,
  amount,
  stageName,
  opportunityType,
  createdOn,
  merchantId
 }) => {

  const [loading, setLoading] = useState(true);
  const [salesOpportunityDetails, setSalesOpportunityDetails] = useState([]);
  const [lastContact, setLastContact] = useState('');
  const [showOpportunityDetailsModal, setShowOpportunityDetailsModal] = useState(false);
  const [loadingViewDetails, setLoadingViewDetails] = useState(false);
  const [salesOpportunityHistory, setSalesOpportunityHistory] = useState({});

  const getReportAttributedData = useCallback(async () => {
    if(!salesOppId || !clientId) return;
    try {
      setLoading(true);

      const { data } = await getSalesOppLog(salesOppId, clientId);
      let salesOpp = [];
      data?.salesOppActions?.forEach(action => {
        let activity_date = '';
        let reminder_type = '';
        if(action?.completeDate) {
          activity_date = moment(action?.completeDate);
          reminder_type = 'complete';
        } else if (action?.expiredDate && action?.expiresOnDate) {
          activity_date = moment(action?.expiredDate);
          reminder_type = 'expired';
        } else {
          activity_date = moment(action?.dueDate);
          reminder_type = 'due';
        }
        salesOpp.push({
          ...action,
          key:`reminder-${action.id}`,
          type: 'reminder',
          activity_date,
          reminder_type,
        })
      });

      data?.salesOppAutoMsgs?.forEach(msg => {
        salesOpp.push({
          ...msg,
          key:`message-${msg.id}`,
          type: 'message',
          activity_date: moment(msg?.completedDateTime || msg?.scheduledDateTime)
        })
      })

      const salesOppSorted = salesOpp.sort((a, b) => a.activity_date - b.activity_date);
      const lastActivityDate = salesOppSorted.filter(opp => opp.type === 'message').at(-1)?.activity_date || createdOn;
      if(moment(new Date()).diff(moment(lastActivityDate), 'days') < 7) {
        setLastContact(`${moment(new Date()).diff(moment(lastActivityDate), 'days')} days ago`)
      } else {
        setLastContact(moment(lastActivityDate).format('MMM DD, YYYY'));
      }

      setSalesOpportunityDetails(salesOppSorted);

    } catch (error) {
      console.log(error);
      message.error('Error fetching Sales Opportunity details');
    } finally {
      setLoading(false);
    }

  }, [salesOppId, clientId]);

  useEffect(() => {
    getReportAttributedData();
  }, [salesOppId]);

  const handleClickDetails = useCallback(async () => {
    setLoadingViewDetails(true);
    try {

      const reqObj = {
        params: ['client', merchantId, salesOppId, 'getSalesOppHistory'],
        loadingStatusOff: true
      };

      const response = await getRequest(reqObj);

      setSalesOpportunityHistory(response);
    } catch (error) {
      message.error('Error Fetching Sales Opportunity');
    } finally {
      setShowOpportunityDetailsModal(true);
      setLoadingViewDetails(false);
    }
  }, [salesOppId, merchantId]);

  return (
    <div className="cb-sales-opp-report__inner-table">
      <Timeline
        pending={loading ? 'Loading...' : null}
        items={[
          {
            key: salesOppId,
            dot: <FileTextOutlined className='cb-sales-opp-report__timeline-icon' />,
            children: (
              <TimelineItem
                title={
                  <>
                    <strong>{createdBy}</strong> has a <strong>{formatNumberAsCurrency(amount)}</strong> sales
                    opportunity with <strong>{client}</strong>
                  </>
                }
                description={`Type: ${opportunityType}, Stage: ${stageName}, Last contact: ${lastContact}`}
              />
            )
          },
          ...salesOpportunityDetails.map(item => ({
              children: item.type === 'reminder' ? (
                <ReminderItem {...item} />
              ) : (
                <AutoMessageItem {...item} />
              ),
              key: item.key,
              dot: <Icon type={item.type} reminder_type={item?.reminder_type || ''} />
          }))
        ]}
      />
      <Button onClick={handleClickDetails} loading={loadingViewDetails}>View Details</Button>
      {showOpportunityDetailsModal && !!salesOpportunityHistory && (
        <OpportunityModal
          open={showOpportunityDetailsModal}
          onClose={() => setShowOpportunityDetailsModal(false)}
          loading={loadingViewDetails}
          salesOpportunityHistory={salesOpportunityHistory}
          showActivities={false}
        />
      )}
    </div>
  );
};
export default SalesOppExpandRow;


const Icon = ({type, reminder_type}) => {
  switch (type) {
    case 'reminder':
      return <BellOutlined style={{ opacity: reminder_type === 'expired' ? 1 : 0.45, color: reminder_type === 'expired' ? '#FF4D4F' :'#000' }} />
    case 'message':
      return <MassMessages style={{ fontSize: '16px', opacity: 0.45}} />
    default:
      return null
  }
};

const ReminderItem = ({activity_date, reminder_type, description}) => {

  const title = useMemo(() => {
    switch (reminder_type) {
      case 'complete':
      return (
          <>
            Completed reminder on
            <span className="fw-600"> {moment(activity_date).format('MMM DD, YYYY')}</span>
          </>
        )
      case 'due':
        return (
          <>
            Reminder due on
            <span className="fw-600"> {moment(activity_date).format('MMM DD, YYYY')}</span>
          </>
        )
      case 'expired':
        return (
          <>
            Overdue reminder on
            <span className="fw-600"> {moment(activity_date).format('MMM DD, YYYY')}</span>
          </>
        )
      default:
        break;
    }
  }, [activity_date])

  return (
    <TimelineItem
      title={title}
      description={description}
    />
  )
}

const AutoMessageItem = ({activity_date, message}) => {
  return (
    <TimelineItem
      title={
        <>
          Auto Message sent on
          <span className="fw-600"> {moment(activity_date).format('MMM DD, YYYY')}</span>
        </>
      }
      description={message}
    />
  )
}
