import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../../../Layout/Button';

import { Input as InputComponent, Pair, FIELD_PROPS } from '../Field';
import { HEADING_CLASSES } from '../Wizard';

const OWNERS = {
  MIN: 0,
  MAX: 4,
};

const Owners = ({ data, updateValues: saveValues, active, ...props }) => {
  const Input = ({ ownerNumber, ...inputProps }) => (
    <InputComponent data={data} active={active} updateValues={saveValues} {...props} {...inputProps} />
  );

  // get data
  const ownerCount = data.ownerCount?.value || OWNERS.MIN;

  // helper to update values
  const updateValues = (opt = {}) =>
    saveValues({
      ownerCount,
      ...opt,
    });

  // helpers to update what elements are showing
  const show = useCallback(num => num <= ownerCount, [ownerCount]);

  return (
    <>
      <p className="fs-13 m-btm-15">
        Information must be gathered and verified for each owner who owns 25% or more of your company.
      </p>

      {['First', 'Second', 'Third', 'Fourth'].map((nth, index) => {
        const num = index + 1;

        return (
          show(num) && (
            <fieldset className="m-btm-25 border-none padding-0" key={`owner-list-${num}`}>
              <header className="flex-row-spacebetween-wrap">
                <h5 className={HEADING_CLASSES}>{nth} Owner</h5>
                {num === ownerCount && (
                  <Button
                    onclick={evt => {
                      // clear existing values, when removing
                      updateValues({
                        ownerCount: ownerCount - 1,
                        [`owner${num}FirstName`]: 'REMOVE',
                        [`owner${num}LastName`]: 'REMOVE',
                        [`owner${num}DateOfBirth`]: 'REMOVE',
                        [`owner${num}GovID`]: 'REMOVE',
                        [`owner${num}Phone`]: 'REMOVE',
                        [`owner${num}Address`]: 'REMOVE',
                        [`owner${num}City`]: 'REMOVE',
                        [`owner${num}State`]: 'REMOVE',
                        [`owner${num}Zip`]: 'REMOVE',
                        [`owner${num}Country`]: 'REMOVE',
                      });
                    }}
                    classname="blue-link red no-wrap"
                    styleProps={{ width: 'auto' }}
                  >
                    <FontAwesomeIcon size="1x" icon={['far', 'trash']} style={{ marginBottom: 0 }} />
                    Remove
                  </Button>
                )}
              </header>
              <Pair>
                <Input id={`owner${num}FirstName`} label="First Name" maxLength="40" ownerNumber={num} />
                <Input id={`owner${num}LastName`} label="Last Name" maxLength="40" ownerNumber={num} />
              </Pair>
              <Pair>
                <Input {...FIELD_PROPS.DOB} id={`owner${num}DateOfBirth`} ownerNumber={num} />
                <Input id={`owner${num}GovID`} label="Last 4 Digits of SSN" maxLength="4" ownerNumber={num} />
              </Pair>
              <Input {...FIELD_PROPS.PHONE} id={`owner${num}Phone`} label="Business owner phone" ownerNumber={num} />

              <h5 className={HEADING_CLASSES}>{nth} Owner's Address</h5>
              <Input id={`owner${num}Address`} placeholder="Address" maxLength="40" ownerNumber={num} />
              <Pair>
                <Input id={`owner${num}City`} placeholder="City" maxLength="25" ownerNumber={num} />
                <Input id={`owner${num}State`} placeholder="State" maxLength="2" ownerNumber={num} />
              </Pair>
              <Input id={`owner${num}Zip`} placeholder="Zip Code" maxLength="10" ownerNumber={num} />
              <Input id={`owner${num}Country`} placeholder="Country" defaultValue="US" hidden />
            </fieldset>
          )
        );
      })}
      {ownerCount !== OWNERS.MAX && (
        <Button
          onclick={evt => {
            evt.preventDefault();
            const num = ownerCount + 1;

            updateValues({
              ownerCount: num,
              [`owner${num}FirstName`]: '',
              [`owner${num}LastName`]: '',
              [`owner${num}DateOfBirth`]: '',
              [`owner${num}GovID`]: '',
              [`owner${num}Phone`]: '',
              [`owner${num}Address`]: '',
              [`owner${num}City`]: '',
              [`owner${num}State`]: '',
              [`owner${num}Zip`]: '',
              [`owner${num}Country`]: '',
            });
          }}
          classname="blue-link no-wrap m-btm-20"
          styleProps={{ height: 30 }}
        >
          <FontAwesomeIcon size="1x" icon={['far', 'plus']} className=" m-btm-6 p-btm-3" />
          Add an owner
        </Button>
      )}
    </>
  );
};

export default Owners;
