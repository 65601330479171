import { dispatcher } from '../core/actions';

const initialState = {
  list: [],
  fullList: [],
  activityLog: [],
  isError: false,
  formList: {},
  formStores: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case 'SET_BANK_ACCOUNTS_LIST':
    return {
      ...state,
      list: action.payload.map(
        ({
          bankAccountLastFour: lastFour,
          bankAccountNickname: name,
          bankAccountType: type,
          // bluesnapMerchantId: bluesnapId,
          // merchantId,
          paymentsMerchantId: id,
          status,
          ownerName,
          stores,
          isPrimary,
        }) => ({
          name: name || `${type}-${lastFour}`,
          type,
          lastFour,
          id,
          status,
          ownerName,
          stores: stores?.split(',').sort().join(', '),
          isPrimary,
        }),
      ),
    };
  case 'SET_FULL_BANK_ACCOUNTS_LIST':
    return {
      ...state,
      fullList: action.payload.map(
        ({
          bankAccountLastFour: lastFour,
          bankAccountNickname: name,
          bankAccountType: type,
          // bluesnapMerchantId: bluesnapId,
          // merchantId,
          paymentsMerchantId: id,
          status,
          ownerName,
        }) => ({
          name: name || `${type}-${lastFour}`,
          type,
          lastFour,
          id,
          status,
          ownerName,
        }),
      ),
    };
  case 'SET_BANK_ACCOUNTS_ACTIVITY_LOG':
    return {
      ...state,
      activityLog: action.payload.map(
        ({
          associateId: id,
          reason,
          changeAt,
          status,
          associateName: name,
          bankAccountNickname: bankNickname,
          bankAccountLastFour: lastFour,
        }) => ({
          id,
          reason,
          changeAt,
          status,
          name,
          bankNickname,
          lastFour,
        }),
      ),
    };
  case 'SET_BANK_ACCOUNTS_FORM_DATA':
      const formD = action.payload;
      return {
        ...state,
      formList: {
        businessAddress: formD?.businessAddress || '',
        businessCity: formD?.businessCity || '',
        businessLegalName: formD?.businessDba || '',
        businessProductAndServiceDesc: formD?.businessDescription || '',
        businessName: formD?.businessName || '',
        bankAccountNickname: formD?.businessNickname || '',
        businessState: formD?.businessState || '',
        taxId: formD?.taxId || '',
        businessPhone: formD?.businessTel || '',
        businessType: formD?.businessType || '',
        businessWebsite: formD?.businessWebsite || '',
        businessZip: formD?.businessZip || '',
        companyRepAddress: formD?.representativeAddress || '',
        owner1City: formD?.representativeCity || '',
        owner1DateOfBirth: formD?.representativeDob || '',
        owner1FirstName: formD?.representativeFirstName || '',
        owner1LastName: formD?.representativeLastName || '',
        owner1State: formD?.representativeState || '',
        owner1Phone: formD?.representativeTel || '',
        owner1Zip: formD?.representativeZip || '',
      },
      };
  case 'SET_BANK_ACCOUNTS_FORM_STORES':
    return {
      ...state,
      formStores: action.payload.map(({ cbStoreId: value, storeName: label }) => ({
        label,
        value,
      })),
    };
  default:
    return state;
  }
}

export const setBankAccountsList = val => {
  dispatcher('SET_BANK_ACCOUNTS_LIST', val);
};

export const setFullBankAccountsList = val => {
  dispatcher('SET_FULL_BANK_ACCOUNTS_LIST', val);
};

export const setBankAccountsActivityLog = val => {
  dispatcher('SET_BANK_ACCOUNTS_ACTIVITY_LOG', val);
};

export const setBankAccountFormData = val => {
  dispatcher('SET_BANK_ACCOUNTS_FORM_DATA', val);
};

export const setBankAccountFormStores = val => {
  dispatcher('SET_BANK_ACCOUNTS_FORM_STORES', val);
};
