import { notificationError } from "../../../../core/actions";
import { postRequest } from "../../../../core/apiRequests";

export const installShopify = async(params, serviceId) => {
    try {
        const  reqObj = {
            params: params,
            data: {
                uuid: serviceId
            }
        }
        await postRequest(reqObj);
        return;
    }
    catch(e) {
        console.error('Error installing shopify!', e);
        notificationError(`There was an error installing Shopify! Please contact support.`)
        return false;
    }
}