export function getType (activity) {
  if(activity?.chat_message_id) {
    return {
      type: 'chatMessages',
      id: activity.chat_message_id,
      activity_associate_id: activity.activity_associate_id,
      activity_associate_name: activity.activity_associate_name,
      activity_store_name: activity.activity_store_name,
      activity_date: activity.activity_date,
      activity_type: types(activity),
      filter_type: filterTypes(activity)
    }
  } else if (activity?.reminder_id) {
    return {
      type: 'reminders',
      id: activity.reminder_id,
      activity_associate_id: activity.activity_associate_id,
      activity_associate_name: activity.activity_associate_name,
      activity_store_name: activity.activity_store_name,
      activity_date: activity.activity_date,
      activity_type: types(activity),
      filter_type: filterTypes(activity)
    }
  } else {
    return null
  }
}

const filterTypes = (item) => {
  if(item?.reminder_id) {
    return 'reminders'
  }
  if(item?.chat_message_id && !item?.ai_activity_result_id) {
    return 'chatMessages'
  }
  if(item?.ai_activity_result_id) {
    return 'aiActivityResult'
  }
}


export const types = (item) => {
  if(item?.reminder_id) {
    return 'reminder'
  }
  if(item?.message_batch_id) {
    return 'massMessage'
  }
  if(item?.auto_message_id) {
    return 'autoMessage'
  }
  if(item?.ai_activity_result_id) {
    return 'aiActivityResult'
  }
  return 'chatMessage'
}
