/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Skeleton } from 'antd';
import Axios from 'axios';
// import { getRequest } from '../../../../../../../core/apiRequests';
import StyledLink from '../../StyledLink';
import { PAYMENTS_API_URL } from '../../../../constants';

const Payments = props => {
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [payments, setPayments] = useState([]);
  const clientId = props.messaging.client.client_id;


  useEffect(() => {
    if (props.merchantId) {
      // eslint-disable-next-line no-new
      new Promise((resolve, reject) => {
        Axios.get(
          //   `${PAYMENTS_API_URL}/v1/paymentRequest/getPaymentRequestByClientId?clientId=${432741}`,
          `${PAYMENTS_API_URL}/v1/paymentRequest/getPaymentRequestByClientId?clientId=${clientId}`,
        )
          .then(response => {
            const newestPayments = response.data.sort((a, b) => {
              return b.paymentId - a.paymentId;
            });
            setPayments(newestPayments.slice(0, 2));
            setLoading(false);
            resolve(response.data);
          })
          .catch(err => {
            console.log({ err });
            reject(err);
          });
      });
    }
  }, [props.merchantId]);

  const PaymentData = () => {
    const returnArr = [];
    if (loading) {
      returnArr.push(
        <div key="skeleton">
          <Skeleton
            paragraph={{
              rows: 3,
            }}
            active
          />
        </div>,
      );
      return returnArr;
    }
    if (payments.length > 0) {
      payments.forEach(pay => {
        returnArr.push(
          <div className="m-btm-12" key={pay.paymentId}>
            <div className="paymentDivider" />
            <div className="flex-col-left m-top-8 fs-12 align-left">
              <span>{`Item: ${
                pay.productDescriptions.length > 0 ? pay.productDescriptions[0] : 'no description'
              }`}</span>
              <span>{`Amount: $${pay.amount}`}</span>
              <span>{`Status: ${pay.status}`}</span>
              <span>{`Payment ID: ${pay.paymentId}`}</span>
            </div>
          </div>,
        );
      });
      return returnArr;
    }
    return returnArr;
  };

  return (
    <div style={{ borderTop: '1px #ebebeb solid' }}>
      {PaymentData().length > 0 ? (
        <div>
          <p className="fw-500 gray fs-15 align-left m-btm-5">Payments</p>
          {PaymentData()}
          <StyledLink
            className="black fw-500 fs-12 m-right-20 m-top-3"
            to={`/Clients?client=${clientId}`}
          >
            View All In Client Profile
          </StyledLink>
        </div>
      ) : (
        <div className="m-top-8 ">
          <p className="fw-500 gray fs-15 align-left m-btm-5">Payments</p>
          <span className="gray fs-12">No Payment History</span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  messaging: state.messaging,
});
export default connect(mapStateToProps)(Payments);
