import React from 'react';

const IntegrationsFacebook = () => {

  return (
    <>
      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Overview</h3>

        <p>
        Connect your Facebook business account with Clientbook. Effortlessly sign into your Facebook Messenger business account with easy redirect right to Facebook Messenger.
        Once you are connected, never miss a message again. Respond to customers directly in the Clientbook Web application or mobile devices.
        Features like using generative AI will help give you the best response to the customers needs.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Key Features</h3>

        <p>By adding your Facebook Messenger  information to Clientbook, you can:</p>

        <ul className="cb-integrations-details__list">
          <li>Easily connect your Facebook business account to Clientbook.</li>

          <li>Respond directly to messages from Facebook.</li>

          <li>Have multiple lines of communications with clients.</li>

          <li>Get historical chats to keep track of past conversations.</li>
        </ul>
      </section>
    </>
  );
};

export default IntegrationsFacebook;
