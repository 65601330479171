import React from 'react';
import './descOverInput.css';

const DescOverInput = props => {
  return (
    <div
      className={
        props.classProps
          ? `descOverInput w-48-P  inputHeight ${props.classProps}`
          : ' descOverInput w-48-P  inputHeight'
      }
      style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}
    >
      {props.description} {/* This should be in a span for styling, even if you include a FA Icon in it */}
      {props.input}
    </div>
  );
};
export default DescOverInput;
