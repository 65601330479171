import React, { useState, useEffect, useCallback } from 'react';
import { Skeleton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Buttons } from '../Buttons';
import { getRequest, postRequest, deleteRequest } from '../../../../../core/apiRequests';
import { setClientMixedRelationships } from '../../../../../reducers/client';
import { notificationSuccess, notificationError } from '../../../../../core/actions';
import PopConfirm from '../../../PopConfirm';
import RelationshipsNew from './RelationshipsNew';
import RelationshipsForm from './RelatsionshipsForm';
import StyledLink from '../../../StyledLink';

const Relationships = ({ merchantId, client, auth, relationships, resetField, setValue, control, getValues }) => {
  const clientId = client.client_id;
  const [loading, setLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [newClient, setNewClient] = useState({});
  const [relationship, setRelationship] = useState({});
  const [newRelationship, setNewRelationship] = useState(false);
  const [showAddClientForm, setShowAddClientForm] = useState(false);
  const [methods, setMethods] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRelationship, setSelectedRelationship] = useState(null);
  const [newEvent, setNewEvent] = useState(null);
  const [prefixToSend, setPrefixToSend] = useState('');

  useEffect(() => {
    if (merchantId && clientId) {
      getStoreMethods(client.store_id);
      setLoading(false);
      getAllRelationships();
    }
  }, [merchantId, clientId]);

  const getAllRelationships = () => {
    setLoading(true);
    const mixedRelationshipObj = {};
    (async () => {
      const reqObj = {
        params: ['relationship', 'getAllClientRelationshipsAndTyped', Number(clientId)],
      };
      const data = await getRequest(reqObj);
      setLoading(false);
      if (data) {
        const { relationships: clientRelationship, allRelationships } = data;

        allRelationships?.forEach(rel => {
          mixedRelationshipObj[rel.id] = {
            originalId: null,
            client_id: null,
            client_name: null,
            related_client_name: null,
            relationship_type_id: rel.id,
            related_client_id: null,
            relationship: rel.name,
            relatedTo: false,
            delete: false,
          };
        });

        clientRelationship.forEach(clientRel => {
          if (clientRel.relationship_type_id) {
            mixedRelationshipObj[clientRel.relationship_type_id] = {
              originalId: clientRel.id,
              client_id: clientRel.client_id,
              client_name: clientRel.client_name,
              related_client_name: clientRel.related_client_name,
              relationship_type_id: clientRel.relationship_type_id,
              related_client_id: clientRel.related_client_id,
              relationship: clientRel.relationship,
              relatedTo: Number(clientId) == clientRel.related_client_id,
              delete: false,
            };
          }
          if (!clientRel.relationship_type_id) {
            mixedRelationshipObj[`UNTYPED-${clientRel.id}`] = {
              // to keep duplicates where client_relationship ID also equals a relationship_type ID.
              originalId: clientRel.id,
              client_id: clientRel.client_id,
              client_name: clientRel.client_name,
              related_client_name: clientRel.related_client_name,
              relationship_type_id: clientRel.relationship_type_id,
              related_client_id: clientRel.related_client_id,
              relationship: clientRel.relationship,
              relatedTo: Number(clientId) == clientRel.related_client_id,
              uniqueRel: true,
              delete: false,
            };
          }
        });
        setClientMixedRelationships(mixedRelationshipObj);
      } else {
        notificationError('Error getting client details');
      }
    })();
  };

  const cancelAddClientForm = useCallback(() => {
    setNewClient({
      name: '',
      email: '',
      localNumber: '',
      contactPref: '',
    });
    setShowAddClientForm(false);
  }, []);

  const handleClientChange = useCallback(
    (field, value) => {
      setNewClient({
        ...newClient,
        [field]: value,
      });
    },
    [newClient],
  );

  const handleSaveNewClient = async area => {
    setLoading(true);
    const fn = auth.firstName ? auth.firstName : '';
    const ln = auth.lastName ? auth.lastName.trim() : '';
    const dataObj = {
      associateId: auth.userId,
      name: newClient.name.trim(),
      mobile: newClient.localNumber,
      email: newClient.email ? newClient.email.trim() : null,
      contactPref: !newClient.localNumber ? 'EMAIL' : 'SMS',
      store_id: auth.stores[1].id,
      store_name: auth.stores[1].name,
      preferences: [],
      lifeEvents: [],
      address: [],
    };
    const reqObj = {
      params: ['clients', auth.merchantId, 'save'],
      data: dataObj,
      query: { createdBy: `${fn} ${ln}` },
      loadingStatusOff: true,
    };
    const { id } = await postRequest(reqObj);
    if (area === 'newRelationship') {
      setRelationship({
        ...relationship,
        id,
        name: newClient.name.trim(),
      });
    } else {
      // Do I need to do anything below?
    }
    setLoading(false);
    cancelAddClientForm();
    setNewRelationship(true);
  };

  const handleUpdateRelationships = newRelationships => {
    resetField('relationships', { defaultValue: newRelationships });
    setClientMixedRelationships(newRelationships);
    setRelationship({});
  };

  const handleSaveNewRelationship = async () => {
    setLoading(true);
    const reqObj = {
      params: ['relationship', 'untyped'],
      data: {
        client_id: Number(clientId),
        related_client_id: relationship.id,
        relationship_name: relationship.relationshipName,
      },
    };

    const responseData = await postRequest(reqObj);

    const cleanObj = {};
    const reducerRelationships = {
      ...relationships,
      [responseData.insertId]: {
        originalId: responseData.insertId,
        client_id: Number(clientId),
        client_name: client.name,
        related_client_name: relationship.name,
        relationship_type_id: null,
        related_client_id: relationship.id,
        relationship: relationship.relationshipName,
        relatedTo: false,
        uniqueRel: true,
      },
    };
    Object.keys(reducerRelationships).map(key => {
      if (reducerRelationships[key] && reducerRelationships[key].relationship.length > 0) {
        cleanObj[key] = reducerRelationships[key];
      }
    });
    if (responseData) {
      handleUpdateRelationships(cleanObj);
      setNewRelationship(false);
      setOpenEdit(false);
      notificationSuccess('Relationship Created');
    }
    setLoading(false);
  };

  const handleValidateNewClient = area => {
    if (!newClient.name) {
      notificationError('Please Add Client Name');
      return;
    }

    if (!newClient.email && !newClient.localNumber) {
      notificationError('Please add number or email');
      return;
    }

    handleSaveNewClient(area);
  };

  const handleCancel = useCallback(() => {
    setOpenEdit(false);
    resetField('relationships');
  }, [relationships]);

  const setEditButton = useCallback(value => {
    setOpenEdit(value);
    setNewRelationship(false);
  }, []);

  const handleSave = useCallback(async () => {
    const filteredArr = Object.values(relationships).filter(rel => {
      if (!rel.originalId && rel.client_id && rel.related_client_id) {
        return true;
      }
      if (rel.originalId && rel.delete) {
        return true;
      }
      return false;
    });

    const reqObj = {
      params: ['clients', clientId, 'editClientRelationships'],
      data: {
        relationships: filteredArr,
      },
    };

    try {
      if (filteredArr.length > 0) {
        await postRequest(reqObj);
        getAllRelationships();
      } else {
        notificationSuccess('No new relationships to update');
      }
    } catch (err) {
      notificationError('Error Updating Relationships');
    } finally {
      setOpenEdit(false);
    }
  }, [clientId, relationships]);

  const toggleAddClientForm = useCallback(() => {
    setShowAddClientForm(!showAddClientForm);
  }, [showAddClientForm]);

  const relationshipData = () => {
    if (loading) {
      return (
        <Skeleton
          paragraph={{
            rows: 3,
          }}
          active
        />
      );
    }
    if (relationships && Object.keys(relationships).length > 0) {
      const returnArr = [];
      if (Object.values(relationships).filter(rel => rel.related_client_id).length > 0) {
        Object.values(relationships).forEach(rel => {
          if (rel && rel.related_client_id) {
            if (rel.related_client_id == clientId) {
              returnArr.push(
                <div
                  key={rel.originalId || `${rel.relationship_type_id}-${rel.related_client_id}`}
                  className="flex-col-left m-btm-8"
                >
                  <span className="fs-12 fw-500 gray">{`${rel.relationship} of`}</span>

                  <StyledLink
                    to={`/Clients?client=${rel.client_id}`}
                    styleprops={{ color: '#1769AF' }}
                    className="fw-500 fs-12"
                  >
                    {rel.client_name}
                  </StyledLink>
                </div>,
              );
            } else {
              returnArr.push(
                <div
                  key={rel.originalId || `${rel.relationship_type_id}-${rel.related_client_id}`}
                  className="flex-col-left m-btm-8"
                >
                  <span className="fs-12 fw-500 gray">{rel.relationship}</span>

                  <StyledLink
                    to={`/Clients?client=${rel.related_client_id}`}
                    styleprops={{ color: '#1769AF' }}
                    className="fw-500 fs-12"
                  >
                    {rel.related_client_name}
                  </StyledLink>
                </div>,
              );
            }
          }
        });
      } else {
        returnArr.push(
          <span key="no-rel" className="gray fs-12">
            No Relationships
          </span>,
        );
      }
      return returnArr;
    }

    return <span className="gray fs-12">No Relationships</span>;
  };

  const getStoreMethods = storeId => {
    if (merchantId && storeId) {
      (async () => {
        const reqObj = {
          params: ['stores', 'contact-methods', merchantId, storeId],
          query: {},
        };
        const data = await getRequest(reqObj);
        setMethods(sortMethods(data));
      })();
    }
  };

  const sortMethods = methods => {
    const filteredmethods = methods.filter(method => method.visible === 1);
    filteredmethods.sort((a, b) => {
      return b.isDefault - a.isDefault;
    });
    filteredmethods.sort((a, b) => {
      return b.enabled - a.enabled;
    });
    return filteredmethods;
  };

  const openDeleteModal = useCallback(delRel => {
    setSelectedRelationship(delRel);
    setOpenDelete(true);
  }, []);

  const handleDeleteRelationship = useCallback(async () => {
    const requestData = {
      params: ['relationship', selectedRelationship.originalId],
    };
    try {
      await deleteRequest(requestData);
      notificationSuccess('Relationship Removed');
      const fakeRelationships = { ...relationships };
      delete fakeRelationships[Number(selectedRelationship.originalId)];
    } catch (err) {
      notificationError('Error Removing Life Event');
    } finally {
      setOpenDelete(false);

      const tempObj = relationships;
      Object.keys(tempObj).forEach(key => {
        const keyObj = tempObj[key];
        if (keyObj.originalId === selectedRelationship.originalId) {
          delete tempObj[key];
        }
      });
      setClientMixedRelationships(tempObj);

      setSelectedRelationship(null);
    }
  }, [selectedRelationship, relationships]);

  return (
    <div style={{ borderTop: '1px #ebebeb solid' }} id="client-details-relationships-box">
      {/* here */}
      <div className="flex-row-center" style={{ position: 'relative', gap: '8px' }}>
        <p className="fw-500 gray fs-15 align-left m-btm-5">Relationships</p>
        {openEdit && !newRelationship && (
          <FontAwesomeIcon
            size="1x"
            icon={['far', 'plus']}
            className="pointer"
            onClick={() => setNewRelationship(true)}
          />
        )}
        {newRelationship ? null : (
          <Buttons
            open={openEdit}
            setOpen={setEditButton}
            onSave={handleSave}
            onCancel={handleCancel}
            containerStyles={{ top: '12px' }}
            id="client-details-relationships-buttons"
            parentId="client-details-relationships-box"
          />
        )}
      </div>
      {/* eslint-disable-next-line no-nested-ternary */}
      {newRelationship ? (
        <RelationshipsNew
          handleValidateNewClient={handleValidateNewClient}
          handleSaveNewRelationship={handleSaveNewRelationship}
          relationship={relationship}
          setRelationship={setRelationship}
          setNewClient={setNewClient}
          newClient={newClient}
          setNewRelationship={setNewRelationship}
          toggleAddClientForm={toggleAddClientForm}
          showAddClientForm={showAddClientForm}
          cancelAddClientForm={cancelAddClientForm}
          handleClientChange={handleClientChange}
          iso_country_code={auth.iso_country_code}
          stores={auth.stores}
          methods={methods}
          setShowAddClientForm={setShowAddClientForm}
        />
      ) : openEdit ? (
        <RelationshipsForm
          setValue={setValue}
          relationships={relationships}
          control={control}
          clientId={clientId}
          clientName={client.name}
          openDeleteModal={openDeleteModal}
          toggleAddClientForm={toggleAddClientForm}
          handleValidateNewClient={handleValidateNewClient}
          handleSaveNewRelationship={handleSaveNewRelationship}
          relationship={relationship}
          setRelationship={setRelationship}
          setNewClient={setNewClient}
          newClient={newClient}
          setNewRelationship={setNewRelationship}
          showAddClientForm={showAddClientForm}
          cancelAddClientForm={cancelAddClientForm}
          handleClientChange={handleClientChange}
          iso_country_code={auth.iso_country_code}
          stores={auth.stores}
          methods={methods}
          setShowAddClientForm={setShowAddClientForm}
          setNewEvent={setNewEvent}
          setPrefixToSend={setPrefixToSend}
          prefixToSend={prefixToSend}
          getValues={getValues}
        />
      ) : (
        relationshipData()
      )}
      {openDelete && (
        <PopConfirm
          confirm={`Are you sure you want to delete "${selectedRelationship.relationship}"?`}
          description="This will permanently delete this relationship"
          onCancel={() => setOpenDelete(false)}
          onConfirm={handleDeleteRelationship}
        />
      )}
      {/* end */}
    </div>
  );
};

// const mapStateToProps = state => ({
//   messaging: state.messaging,
// });
// export default connect(mapStateToProps)(Relationships);
export default Relationships;
