/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/button-has-type */
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PopMessage from '../../../../Layout/PopMessage';
import DropdownSelect from '../../../../Layout/DropdownSelect';
import { putRequest } from '../../../../../core/apiRequests';

const SnoozeModal = ({
  openModal,
  setOpenModal,
  merchantId,
  actions,
  refresh
}) => {

  const options = useMemo(() => [
    {
      label: 'Day',
      value: 'day',
    },
    {
      label: 'Week',
      value: 'week',
    },
    {
      label: 'Month',
      value: 'month',
    }
  ], [])

  const [snoozeInterval, setSnoozeInterval] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSave = useCallback(async () => {
    const reqObj = {
      params: ['today', merchantId, 'delayReminders'],
      data: {
        snoozeInterval,
        actionIds: actions.map(action => action.id)
      },
    };
    setLoading(true);
    await putRequest(reqObj);
    setLoading(false);
    setOpenModal(false);
    refresh()
  }, [snoozeInterval, merchantId, actions])

  const handleCancel = useCallback(() => {
    setOpenModal(false);
  }, [])

  return (
    openModal ? (
      <PopMessage
        popStyle={{ width: '360px', overflow: 'hidden', padding: '36px' }}
        confirm="Delay this reminder for another..."
        confirmStyle={{ textAlign: 'center' }}
        confirmationButtonText={loading ? 'Applying...' : 'Apply'}
        onCancel={handleCancel}
        onConfirm={handleSave}
        disabledSaveButton={!snoozeInterval}
        noButtonDisable
      >
        <div className='m-top-25 m-btm-28'>
          <DropdownSelect
            classProps="maw-100-P w-100-P align-left"
            options={options}
            placeholder="Select time frame"
            value={snoozeInterval}
            onChange={obj => {
              setSnoozeInterval(obj.value);
            }}
          />
        </div>
      </PopMessage>
    ) : null
  );
};

const mapStateToProps = state => ({ auth: state.auth, messaging: state.messaging });
export default connect(mapStateToProps, {})(SnoozeModal);
