import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MessageNotificationButton = props => {
  const { onClick } = props;

  return (
    <div
      className="flex-row-nospacebetween-nowrap pos-abs"
      style={{
        top: '17px',
        right: '53px',
      }}
    >
      <FontAwesomeIcon
        className="fs-16 pointer"
        color="#D0D0D0"
        icon={['far', 'external-link-alt']}
        onClick={onClick}
      />
    </div>
  );
};

export default MessageNotificationButton;
