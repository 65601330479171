// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoneInput from 'react-phone-number-input';
import { format, isBefore, toDate } from 'date-fns';
import { dispatcher, notificationSuccess, notificationError } from '../../../../core/actions';
import Button from '../../../Layout/Button';
import Layout from '../../../Layout/StandardLayout';
import DescriptionBox from '../../../Layout/DescriptionBox';
import { saveListItem, getRequest } from '../../../../core/apiRequests';
import KioskSlider from '../../../Layout/KioskSlider';
import DefaultConfirmationModal from './modals/DefaultConfirmationModal';
import {
  setStoreName,
  setStoreTimezone,
  setStoreAddr1,
  setStoreAddr2,
  setStoreCity,
  setStoreState,
  setStoreZip,
  setStorePhone,
  setWCAfterhours,
  setAfterhoursMsg,
  setStoreHourId,
  setDayCheck,
  setTimes,
  setStoreReviewLink,
  setSelectedStore,
  setStoreWeeklyContactGoal,
  setDefaultManager,
  setInboundNotifyAll,
  setInboundNotifyManagers,
  setStoreReferenceNumber,
} from '../../../../reducers/stores';
import 'react-datepicker/dist/react-datepicker.css';
import DropdownSelect from '../../../Layout/DropdownSelect';
import EnableConfirmationModal from './modals/EnableConfirmationModal';
import DefaultDisabledModal from './modals/DefaultDisabledModal';
import DisabledDefaultModal from './modals/DisabledDefaultModal';
import StoreHours from './StoreHours';
import TimezoneDropdown from '../../Messaging/Helpers/TimezoneDropdown';
// import format from 'date-fns/format';

const EditStores = props => {
  const history = useHistory();

  const [localNumber, setLocalNumber] = useState();
  const [showDefaultConfirm, toggleDefaultConfirm] = useState(false);
  const [showEnabledConfirm, toggleEnabledConfirm] = useState(false);
  const [showDisabledDefault, toggleDisabledDefault] = useState(false);
  const [showDefaultDisabled, toggleDefaultDisabled] = useState(false);
  const [defaultMethod, setDefaultMethod] = useState(null);
  const [enableMethod, setEnableMethod] = useState([]);
  const [refresh, toggleRefresh] = useState(false);
  const [methods, setMethods] = useState([]);
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    if (props.auth.merchantId && props.auth.stores.length > 0) {
      const storeIds = props.auth.stores.map(store => store.id);

      if (
        Number(props.match.params.storeId) &&
        storeIds.includes(Number(props.match.params.storeId)) &&
        window.location.href.includes('edit-store')
      ) {
        (async () => {
          const reqObj = {
            params: ['stores', 'details', props.auth.merchantId, props.match.params.storeId],
          };
          const data = await getRequest(reqObj);
          if (data && data[0].id) {
            const {
              id,
              name,
              pos_ref_num,
              address_1,
              address_2,
              phone_number,
              postal_code,
              city,
              timezone,
              state,
              hoursId,
              after_hours,
              times,
              after_hours_msg,
              review_link,
              daily_contact_goal,
              sms_number,
              manager_id,
              inbound_notify_all,
              inbound_notify_managers,
            } = data[0];
            const newDataObj = {
              id,
              name,
              pos_ref_num,
              address_1,
              address_2,
              phone_number,
              postal_code,
              city,
              timezone,
              state,
              after_hours,
              hoursId,
              review_link,
              daily_contact_goal,
              sms_number,
              manager_id,
              inbound_notify_all,
              inbound_notify_managers,
            };
            setLocalNumber(phone_number);
            setInboundNotifyAll(newDataObj.inbound_notify_all);

            if (newDataObj.manager_id) {
              setDefaultManager(newDataObj.manager_id);
            }

            if (newDataObj.inbound_notify_managers) {
              setInboundNotifyManagers(newDataObj.inbound_notify_managers);
            }

            if (!after_hours_msg) {
              setAfterhoursMsg(
                `Hello! Thanks for reaching out to ${props.auth.brandName}. We are currently closed, however, you can expect to hear back from us during regular business hours! Talk to you soon!`,
              );
            } else {
              setAfterhoursMsg(after_hours_msg);
            }
            setSelectedStore(newDataObj);

            const newObj = {};
            const year = new Date().getFullYear();
            const month = new Date().getMonth();
            const day = format(new Date(), 'dd');
            times.forEach(timeObj => {
              Object.keys(timeObj).forEach(key => {
                if (timeObj[key] !== null) {
                  if (!after_hours) {
                    setWCAfterhours(true);
                  }
                  if (`${timeObj[key]}`.length > 3) {
                    const first = String(timeObj[key]).slice(0, 2);
                    const second = String(timeObj[key]).slice(2, 4);
                    newObj[key] = toDate(new Date(year, month, day, first, second, 0));
                  } else if (`${timeObj[key]}`.length == 2) {
                    // newObj[key] = format(`00${timeObj[key]}`, 'HHmm');
                    newObj[key] = toDate(new Date(year, month, day, 0, timeObj[key], 0));
                  } else if (`${timeObj[key]}`.length == 1) {
                    // newObj[key] = format(`000${timeObj[key]}`, 'HHmm');
                    const second = String(`0${timeObj[key]}`);
                    newObj[key] = toDate(new Date(year, month, day, 0, second, 0));
                  } else {
                    // newObj[key] = format(`0${timeObj[key]}`, 'HHmm');
                    const first = String(timeObj[key]).slice(0, 1);
                    const second = String(timeObj[key]).slice(1, 3);
                    newObj[key] = toDate(new Date(year, month, day, first, second, 0));
                  }
                } else {
                  newObj[key] = '';
                }
              });
            });
            setTimes({
              ...props.stores.times,
              sun_endTime: newObj.sun_close,
              sun_startTime: newObj.sun_open,
              mon_startTime: newObj.mon_open,
              mon_endTime: newObj.mon_close,
              tue_startTime: newObj.tues_open,
              tue_endTime: newObj.tues_close,
              wed_startTime: newObj.wed_open,
              wed_endTime: newObj.wed_close,
              thu_startTime: newObj.thur_open,
              thu_endTime: newObj.thur_close,
              fri_startTime: newObj.fri_open,
              fri_endTime: newObj.fri_close,
              sat_startTime: newObj.sat_open,
              sat_endTime: newObj.sat_close,
            });

            const dayTranslate = {
              sun: 'sunday',
              mon: 'monday',
              tues: 'tuesday',
              wed: 'wednesday',
              thur: 'thursday',
              fri: 'friday',
              sat: 'saturday',
            };
            const openDays = {
              sunday: false,
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
            };
            Object.keys(newObj).forEach(dayKey => {
              const abvDay = dayKey.split('_')[0];
              if (newObj[dayKey]) {
                openDays[dayTranslate[abvDay]] = true;
              }
            });
            setDayCheck(openDays);

            setStoreHourId(hoursId);
          } else {
            // notificationError('Error getting store details');
            history.push('/Stores');
          }
        })();
      } else {
        history.push('/Stores');
      }
    }
  }, [props.auth.merchantId, props.auth.storeId, props.auth.stores]);

  useEffect(() => {
    const selectedManagers = managers.filter(e => props.stores.inbound_notify_managers.includes(e.value));
    setInboundNotifyManagers(selectedManagers);
  }, [props.stores.inbound_notify_all]);

  useEffect(() => {
    const { merchantId } = props.auth;
    if (merchantId || refresh === true) {
      (async () => {
        const { storeId } = props.match.params;
        const reqObj = {
          params: ['stores', 'contact-methods', merchantId, storeId],
          query: {},
        };
        const data = await getRequest(reqObj);
        setMethods(data);
        toggleRefresh(false);
      })();
    }
  }, [props.auth.merchantId, refresh]);

  useEffect(() => {
    const { merchantId } = props.auth;
    if (merchantId) {
      (async () => {
        const { storeId } = props.match.params;
        const reqObj = {
          params: ['stores', 'managers', merchantId, storeId],
          query: {},
        };
        const data = await getRequest(reqObj);
        managerListHandler(data);
      })();
    }
  }, [props.auth.merchantId, props.auth.storeId]);

  const sortMethods = () => {
    const filteredmethods = methods.filter(method => method.visible === 1);
    filteredmethods.sort((a, b) => {
      return b.isDefault - a.isDefault;
    });
    filteredmethods.sort((a, b) => {
      return b.enabled - a.enabled;
    });
    return filteredmethods;
  };

  const saveStore = () => {
    const {
      id,
      name,
      pos_ref_num,
      address_1,
      address_2,
      phone_number,
      postal_code,
      city,
      timezone,
      state,
      after_hours,
      times,
      after_hours_msg,
      store_hour_id,
      day_checkmark,
      review_link,
      sms_number,
      default_manager,
      inbound_notify_all,
      inbound_notify_managers,
    } = props.stores;
    const phoneNumber = localNumber;

    // no offset time
    const convertedTimes = {};
    Object.keys(times).forEach(key => {
      if (times[key] && key != 'timeObj') {
        convertedTimes[key] = format(times[key], 'HHmm');
      } else {
        convertedTimes[key] = null;
      }
    });

    if (!name) {
      notificationError('Please complete the form');
    } else if ((!after_hours_msg || after_hours_msg == '') && after_hours) {
      notificationError('Please add an after hours message');
      dispatcher(
        'SET_AFTERHOURS_MSG',
        `Hello! Thanks for reaching out to ${props.auth.brandName}. We are currently closed, however, you can expect to hear back from us during regular business hours! Talk to you soon!`,
      );
    } else if (!id) {
      notificationError('Error sending request with Selected Store');
    } else if (
      (day_checkmark.sunday === true && (convertedTimes.sun_startTime == null || convertedTimes.sun_endTime == null)) ||
      isBefore(Number(convertedTimes.sun_endTime), Number(convertedTimes.sun_startTime)) ||
      (convertedTimes.sun_endTime !== null && convertedTimes.sun_endTime == convertedTimes.sun_startTime)
    ) {
      if (convertedTimes.sun_startTime == null && convertedTimes.sun_endTime !== null) {
        notificationError('Please add opening Sunday time');
      } else if (convertedTimes.sun_startTime !== null && convertedTimes.sun_endTime == null) {
        notificationError('Please add closing Sunday time');
      } else if (convertedTimes.sun_startTime == null && convertedTimes.sun_endTime == null) {
        notificationError('Please add store hours');
      } else if (
        isBefore(Number(convertedTimes.sun_endTime), Number(convertedTimes.sun_startTime)) ||
        (convertedTimes.sun_endTime !== null && convertedTimes.sun_endTime == convertedTimes.sun_startTime)
      ) {
        notificationError('Please enter valid store hours');
      }
    } else if (
      (day_checkmark.monday === true && (convertedTimes.mon_startTime == null || convertedTimes.mon_endTime == null)) ||
      isBefore(Number(convertedTimes.mon_endTime), Number(convertedTimes.mon_startTime)) ||
      (convertedTimes.mon_endTime !== null && convertedTimes.mon_endTime == convertedTimes.mon_startTime)
    ) {
      if (convertedTimes.mon_startTime == null && convertedTimes.mon_endTime !== null) {
        notificationError('Please add opening Monday time');
      } else if (convertedTimes.mon_startTime !== null && convertedTimes.mon_endTime == null) {
        notificationError('Please add closing Monday time');
      } else if (convertedTimes.mon_startTime == null && convertedTimes.mon_endTime == null) {
        notificationError('Please add store hours');
      } else if (
        isBefore(Number(convertedTimes.mon_endTime), Number(convertedTimes.mon_startTime)) ||
        (convertedTimes.mon_endTime !== null && convertedTimes.mon_endTime == convertedTimes.mon_startTime)
      ) {
        notificationError('Please enter valid store hours');
      }
    } else if (
      (day_checkmark.tuesday === true &&
        (convertedTimes.tue_startTime == null || convertedTimes.tue_endTime == null)) ||
      isBefore(Number(convertedTimes.tue_endTime), Number(convertedTimes.tue_startTime)) ||
      (convertedTimes.tue_endTime !== null && convertedTimes.tue_endTime == convertedTimes.tue_startTime)
    ) {
      if (convertedTimes.tue_startTime == null && convertedTimes.tue_endTime !== null) {
        notificationError('Please add opening Tuesday time');
      } else if (convertedTimes.tue_startTime !== null && convertedTimes.tue_endTime == null) {
        notificationError('Please add closing Tuesday time');
      } else if (convertedTimes.tue_startTime == null && convertedTimes.tue_endTime == null) {
        notificationError('Please add store hours');
      } else if (
        isBefore(Number(convertedTimes.tue_endTime), Number(convertedTimes.tue_startTime)) ||
        (convertedTimes.tue_endTime !== null && convertedTimes.tue_endTime == convertedTimes.tue_startTime)
      ) {
        notificationError('Please enter valid store hours');
      }
    } else if (
      (day_checkmark.wednesday === true &&
        (convertedTimes.wed_startTime == null || convertedTimes.wed_endTime == null)) ||
      isBefore(Number(convertedTimes.wed_endTime), Number(convertedTimes.wed_startTime)) ||
      (convertedTimes.wed_endTime !== null && convertedTimes.wed_endTime == convertedTimes.wed_startTime)
    ) {
      if (convertedTimes.wed_startTime == null && convertedTimes.wed_endTime !== null) {
        notificationError('Please add opening Wednesday time');
      } else if (convertedTimes.wed_startTime !== null && convertedTimes.wed_endTime == null) {
        notificationError('Please add closing Wednesday time');
      } else if (convertedTimes.wed_startTime == null && convertedTimes.wed_endTime == null) {
        notificationError('Please add store hours');
      } else if (
        isBefore(Number(convertedTimes.wed_endTime), Number(convertedTimes.wed_startTime)) ||
        (convertedTimes.wed_endTime !== null && convertedTimes.wed_endTime == convertedTimes.wed_startTime)
      ) {
        notificationError('Please enter valid store hours');
      }
    } else if (
      (day_checkmark.thursday === true &&
        (convertedTimes.thu_startTime == null || convertedTimes.thu_endTime == null)) ||
      isBefore(Number(convertedTimes.thu_endTime), Number(convertedTimes.thu_startTime)) ||
      (convertedTimes.thu_endTime !== null && convertedTimes.thu_endTime == convertedTimes.thu_startTime)
    ) {
      if (convertedTimes.thu_startTime == null && convertedTimes.thu_endTime !== null) {
        notificationError('Please add opening Thursday time');
      } else if (convertedTimes.thu_startTime !== null && convertedTimes.thu_endTime == null) {
        notificationError('Please add closing Thursday time');
      } else if (convertedTimes.thu_startTime == null && convertedTimes.thu_endTime == null) {
        notificationError('Please add store hours');
      } else if (
        isBefore(Number(convertedTimes.thu_endTime), Number(convertedTimes.thu_startTime)) ||
        (convertedTimes.thu_endTime && convertedTimes.thu_endTime == convertedTimes.thu_startTime)
      ) {
        notificationError('Please enter valid store hours');
      }
    } else if (
      (day_checkmark.friday === true && (convertedTimes.fri_startTime == null || convertedTimes.fri_endTime == null)) ||
      isBefore(Number(convertedTimes.fri_endTime), Number(convertedTimes.fri_startTime)) ||
      (convertedTimes.fri_endTime !== null && convertedTimes.fri_endTime == convertedTimes.fri_startTime)
    ) {
      if (convertedTimes.fri_startTime == null && convertedTimes.fri_endTime !== null) {
        notificationError('Please add opening Friday time');
      } else if (convertedTimes.fri_startTime !== null && convertedTimes.fri_endTime == null) {
        notificationError('Please add closing Friday time');
      } else if (convertedTimes.fri_startTime == null && convertedTimes.fri_endTime == null) {
        notificationError('Please add store hours');
      } else if (
        isBefore(Number(convertedTimes.fri_endTime), Number(convertedTimes.fri_startTime)) ||
        (convertedTimes.fri_endTime !== null && convertedTimes.fri_endTime == convertedTimes.fri_startTime)
      ) {
        notificationError('Please enter valid store hours');
      }
    } else if (
      (day_checkmark.saturday === true &&
        (convertedTimes.sat_startTime == null || convertedTimes.sat_endTime == null)) ||
      isBefore(Number(convertedTimes.sat_endTime), Number(convertedTimes.sat_startTime)) ||
      (convertedTimes.sat_endTime !== null && convertedTimes.sat_endTime == convertedTimes.sat_startTime)
    ) {
      if (convertedTimes.sat_startTime == null && convertedTimes.sat_endTime !== null) {
        notificationError('Please add opening Saturday time');
      } else if (convertedTimes.sat_startTime !== null && convertedTimes.sat_endTime == null) {
        notificationError('Please add closing Saturday time');
      } else if (convertedTimes.sat_startTime == null && convertedTimes.sat_endTime == null) {
        notificationError('Please add store hours');
      } else if (
        isBefore(Number(convertedTimes.sat_endTime), Number(convertedTimes.sat_startTime)) ||
        (convertedTimes.sat_endTime !== null && convertedTimes.sat_endTime == convertedTimes.sat_startTime)
      ) {
        notificationError('Please enter valid store hours');
      }
    } else {
      const { firstName, lastName, merchantId } = props.auth;
      const inboundNotifyManagersIds = inbound_notify_managers.map(e => (isNaN(e) ? e.value : e));
      const configObj = {
        id,
        name,
        pos_ref_num,
        address_1,
        address_2,
        phone_number: phoneNumber,
        postal_code,
        city,
        timezone,
        state,
        after_hours,
        times: convertedTimes,
        after_hours_msg,
        hoursId: store_hour_id,
        review_link,
        sms_number,
        default_manager,
        inbound_notify_all,
        inbound_notify_managers: inboundNotifyManagersIds,
      };
      const fn = firstName || '';
      const ln = lastName || '';
      if (mobileValidation(phoneNumber)) {
        (async () => {
          setStorePhone(phoneNumber);
          const data = await saveListItem('stores', merchantId, configObj, `${fn} ${ln}`);
          if (data.id) {
            notificationSuccess('Store Saved');
          } else {
            // error handling?
          }
          history.push('/Stores');
        })();
      }
    }
  };

  const mobileValidation = phone => {
    if (!phone) {
      return true;
    }
    if (phone.match(/\d/g).length >= 10) {
      return true;
    }
    notificationError('Enter valid mobile phone number!');
    return false;
  };

  const webchatKiosk = value => {
    setWCAfterhours(value);
    if (value === false) {
      setTimes({
        ...props.stores.times,
        sun_endTime: null,
        sun_startTime: null,
        mon_startTime: null,
        mon_endTime: null,
        tue_startTime: null,
        tue_endTime: null,
        wed_startTime: null,
        wed_endTime: null,
        thu_startTime: null,
        thu_endTime: null,
        fri_startTime: null,
        fri_endTime: null,
        sat_startTime: null,
        sat_endTime: null,
      });
      setDayCheck({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      });
      setAfterhoursMsg(null);
    } else {
      setDayCheck({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      });
      setAfterhoursMsg(
        `Hello! Thanks for reaching out to ${props.auth.brandName}. We are currently closed, however, you can expect to hear back from us during regular business hours! Talk to you soon!`,
      );
    }
  };

  const managerListHandler = managersList => {
    const formattedManagerList = managersList.map(manager => ({
      label: `${manager.first_name} ${manager.last_name}`,
      value: manager.id,
    }));
    setManagers(formattedManagerList);
  };

  function formatPhoneNumber(number) {
    const cleaned = `${number}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
  }

  const defaultContactMethodToggle = () => {
    toggleDefaultConfirm(true);
  };

  const enabledContactMethodToggle = () => {
    toggleEnabledConfirm(!showEnabledConfirm);
  };

  return (
    <Layout
      title="Stores"
      description="Edit store information, review links, and adjust your weekly clienteling goals "
      classProps="standard-width-wo-sidebar"
      maxWidth={1000}
      breadCrumbs={[{ title: 'Stores', to: '/Stores' }, { title: 'Edit Store' }]}
    >
      <div className="w-100-P h-100-P m-top-30">
        <SettingSegment title="STORE DETAILS" withDivider img={<FontAwesomeIcon icon={['far', 'store']} />}>
          <DescriptionBox
            title="Store Name"
            description="How does your team refer to this location?"
            input={
              <input
                className="w-267 common_inputStyle inputHeight rem-pad-width"
                placeholder="Store Name"
                value={props.stores.name}
                onChange={e => setStoreName(e.target.value)}
              />
            }
          />
          <DescriptionBox
            title="Store Time Zone"
            description="What time zone is your store located in?"
            input={
              <TimezoneDropdown
                classProps="timezone-dropdown-edit-store"
                key={4}
                type="timezone"
                id="timezone-dropdown"
                dropdownMode="select"
                onChange={(val, obj) => {
                  setStoreTimezone(obj.id);
                }}
                value={props.stores.timezone}
                style={{ width: 243, right: '25px' }}
              />
            }
          />
          <DescriptionBox
            title="Store Address"
            description="Where is your store located?"
            input={
              <div className="flex-col-right mq-flex-align-left-at-1200 msgSetTitle_DescInput">
                <input
                  className="w-267 common_inputStyle inputHeight rem-pad-width m-btm-12"
                  placeholder="Street Address"
                  value={props.stores.address_1}
                  onChange={e => setStoreAddr1(e.target.value)}
                />
                <input
                  className="w-267 common_inputStyle inputHeight rem-pad-width m-btm-12"
                  placeholder="Suite #"
                  value={props.stores.address_2}
                  onChange={e => setStoreAddr2(e.target.value)}
                />
                <input
                  className="w-267 common_inputStyle inputHeight rem-pad-width m-btm-12"
                  placeholder="City"
                  value={props.stores.city}
                  onChange={e => setStoreCity(e.target.value)}
                />
                <div className="flex-row-spacebetween-wrap w-267">
                  <input
                    className="w-48-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                    placeholder="State"
                    value={props.stores.state}
                    onChange={e => setStoreState(e.target.value)}
                  />
                  <input
                    className="w-48-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                    placeholder="Zip Code"
                    value={props.stores.postal_code}
                    onChange={e => setStoreZip(e.target.value)}
                  />
                </div>
              </div>
            }
          />
          <DescriptionBox
            title="Store Phone Number"
            description="Where can clients reach you by phone?"
            input={
              <PhoneInput
                placeholder="Enter phone number"
                value={localNumber}
                onChange={setLocalNumber}
                defaultCountry={props.auth.iso_country_code}
                style={{ width: '267px', height: '50px' }}
                countryOptionsOrder={[props.auth.iso_country_code, '|']}
                countrySelectProps={{ backgroundcolor: 'rgb(129, 129, 129' }}
              />
            }
          />

          <DescriptionBox
            title="Phone Number for SMS Messaging"
            description="This number cannot be changed without contacting your customer success manager."
            input={
              <PhoneInput
                placeholder="Enter phone number"
                value={props.stores.sms_number}
                disabled
                defaultCountry={props.auth.iso_country_code}
                style={{
                  width: '267px',
                  height: '50px',
                  border: 'none',
                  backgroundColor: '#f3f3f3',
                  color: 'black',
                }}
                countryOptionsOrder={[props.auth.iso_country_code, '|']}
                countrySelectProps={{ backgroundcolor: 'rgb(129, 129, 129' }}
              />
            }
          />
          <DescriptionBox
            title="Notify All Managers of Unassigned Messages"
            description="When this setting is enabled, all managers will receive a notification for inbound messages from unassigned clients.
            Customize by disabling this setting and selecting which managers should receive notifications."
            input={
              <>
                <div id="NotifyAllManagers">
                  <KioskSlider
                    value={props.stores.inbound_notify_all}
                    onChange={val => {
                      setInboundNotifyAll(val);
                    }}
                  />
                </div>

                {!props.stores.inbound_notify_all ? (
                  <DropdownSelect
                    isMulti
                    isSearchable
                    classProps="w-267 m-top-13 align-left"
                    options={managers}
                    placeholder="Choose a manager..."
                    value={props.stores.inbound_notify_managers}
                    onChange={obj => {
                      const newObj = obj === null ? [] : obj;
                      setInboundNotifyManagers(newObj);
                    }}
                  />
                ) : null}
              </>
            }
          />
          <DescriptionBox
            title="Store Manager for Unassigned Clients"
            description="When no associate is assigned to a client, who do you want displayed as the sender?"
            input={
              <DropdownSelect
                classProps="w-267 align-left"
                options={managers}
                placeholder="Manager"
                value={props.stores.default_manager}
                onChange={obj => {
                  setDefaultManager(obj.value);
                }}
                optionalStyles={{ paddingLeft: '8px' }}
              />
            }
          />
          <DescriptionBox
            title="Accepted Communication Methods"
            description="Which contact methods would you like to support when communicating with clients?"
          />
          <div style={{ marginTop: '-26px', marginBottom: '41px' }}>
            {sortMethods().map((method, i) => {
              return (
                <MethodRow
                  method={method}
                  length={sortMethods().length}
                  defaultContactMethodToggle={defaultContactMethodToggle}
                  setDefaultMethod={setDefaultMethod}
                  enabledContactMethodToggle={enabledContactMethodToggle}
                  enableMethod={enableMethod}
                  setEnableMethod={setEnableMethod}
                  key={i}
                  index={i}
                  toggleDefaultDisabled={toggleDefaultDisabled}
                  toggleDisabledDefault={toggleDisabledDefault}
                />
              );
            })}
          </div>

          {/* STORE HOURS WILL GO HERE  */}
          <DescriptionBox
            title="After Hours Auto Response"
            description="Enter your store hours below. When clients contact you via text, email, WhatsApp, or Webchat outside of store hours, they will receive your customized response to let them know."
            input={
              <div id="AfterHoursResponse">
                <KioskSlider
                  value={props.stores.after_hours}
                  onChange={() => {
                    webchatKiosk(!props.stores.after_hours);
                  }}
                />
              </div>
            }
          />
          {props.stores.after_hours ? <StoreHours stores={props.stores} /> : null}
        </SettingSegment>
        <SettingSegment title="RATINGS & REVIEWS" withDivider img={<FontAwesomeIcon icon={['far', 'star']} />}>
          <DescriptionBox
            title="Google Review Link"
            description="When you send a review link to a client, what URL do you use?"
            input={
              <input
                className="w-267 common_inputStyle inputHeight rem-pad-width"
                placeholder="Add a Link..."
                value={props.stores.review_link || ''}
                onChange={e => setStoreReviewLink(e.target.value)}
              />
            }
          />
        </SettingSegment>
        <SettingSegment title="CLIENTELING" withDivider img={<FontAwesomeIcon icon={['far', 'handshake']} />}>
          <DescriptionBox
            title="Weekly Goal…"
            description="The number of unique daily client contacts you want your store to achieve per week. If left blank, your goal will default to the sum total of all the store associates’ daily goals X 5 (average number of days in a work week)."
            input={
              <input
                id="WeeklyGoalInput"
                className="w-267 common_inputStyle inputHeight rem-pad-width"
                placeholder="Set a Weekly Goal"
                value={props.stores.weekly_contact_goal || ''}
                onChange={e => setStoreWeeklyContactGoal(e.target.value)}
              />
            }
          />
        </SettingSegment>
        <SettingSegment
          title="POINT OF SALE"
          withDivider={false}
          img={<FontAwesomeIcon icon={['far', 'badge-dollar']} />}
        >
          <DescriptionBox
            title="Store Reference Number"
            description="The alphanumerical value that the Point of Sale runtime uses to identify your store."
            input={
              <input
                id="StoreRefNum"
                className="w-267 common_inputStyle inputHeight rem-pad-width"
                placeholder="Set Store Ref Number"
                value={props.stores.pos_ref_num || ''}
                onChange={e => setStoreReferenceNumber(e.target.value)}
              />
            }
          />
        </SettingSegment>
        <div className="align-center">
          <Button onclick={saveStore} id="EditStoreSaveButton" classname="darkBlueButton">
            Save
          </Button>
        </div>
      </div>
      {showDefaultConfirm ? (
        <DefaultConfirmationModal
          toggleDefaultConfirm={toggleDefaultConfirm}
          methods={methods}
          setMethods={setMethods}
          defaultMethod={defaultMethod}
          merchantId={props.auth.merchantId}
          storeId={props.match.params.storeId}
          toggleRefresh={toggleRefresh}
        />
      ) : null}
      {showEnabledConfirm ? (
        <EnableConfirmationModal
          enabledContactMethodToggle={enabledContactMethodToggle}
          methods={methods}
          setMethods={setMethods}
          enableMethod={enableMethod}
          merchantId={props.auth.merchantId}
          storeId={props.match.params.storeId}
          toggleRefresh={toggleRefresh}
        />
      ) : null}
      {showDefaultDisabled ? (
        <DefaultDisabledModal toggleDefaultDisabled={toggleDefaultDisabled} defaultMethod={defaultMethod} />
      ) : null}
      {showDisabledDefault ? (
        <DisabledDefaultModal toggleDisabledDefault={toggleDisabledDefault} enableMethod={enableMethod} />
      ) : null}
    </Layout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  stores: state.stores,
  pagination: state.pagination,
});
export default connect(mapStateToProps, {})(EditStores);

const SettingSegment = props => {
  return (
    <div>
      <div className="msgSetSegmentTitle">
        <span className="m-right-15">{props.img}</span>
        <span>{props.title}</span>
      </div>
      <div className="">{props.children}</div>
      {props.withDivider ? <div className="divider" /> : ''}
    </div>
  );
};

const MethodRow = props => {
  const {
    method: { type, isDefault, enabled },
    index,
    length,
    defaultContactMethodToggle,
    setDefaultMethod,
    enabledContactMethodToggle,
    enableMethod,
    setEnableMethod,
    toggleDefaultDisabled,
    toggleDisabledDefault,
  } = props;

  return (
    <div
      style={
        index !== length - 1
          ? {
            borderBottom: 'solid 0.5px #979797',
            width: '100%',
            height: '64px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }
          : {
            width: '100%',
            height: '64px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }
      }
    >
      <p style={{ fontSize: '13px' }}>{type}</p>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isDefault ? (
          <div
            style={{
              width: '71px',
              height: '22px',
              borderRadius: '3px',
              backgroundColor: '#e8f4ff',
              marginRight: '35px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: '10px',
                fontWeight: '600',
                textAlign: 'center',
                color: '#33aafc',
              }}
            >
              DEFAULT
            </p>
          </div>
        ) : (
          <div
            className="pointer"
            style={{
              width: '102px',
              height: '22px',
              borderRadius: '3px',
              backgroundColor: '#ebebeb',
              marginRight: '35px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => {
              setDefaultMethod(type);
              if (!enabled) {
                toggleDefaultDisabled(true);
              } else {
                defaultContactMethodToggle();
              }
            }}
          >
            <p
              style={{
                fontSize: '10px',
                fontWeight: '600',
                textAlign: 'center',
                color: '#9c9c9c',
              }}
            >
              SET AS DEFAULT
            </p>
          </div>
        )}
        <div id={`AcceptedCommunication${type}`}>
          <KioskSlider
            // value={enabled[1]}
            value={enabled}
            onChange={() => {
              setEnableMethod([type, enabled]);
              if (isDefault) {
                toggleDisabledDefault(true);
              } else {
                enabledContactMethodToggle();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
