import React from 'react';
import StyledLink from '../../../../Layout/StyledLink';
import Button from '../../../../Layout/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FlaggedAddClientActivityBtn = ({ clearAction, merchantFlags: flags }) => {

  const clientActivityEnabled = flags?.client_activity?.enabled;

  if (clientActivityEnabled) {
    return (
      <StyledLink to="/Configuration/client-activity/add-client-activity">
        <Button id="AddClientActivityButton" classname="transparentButton" onclick={clearAction}>
          <FontAwesomeIcon
            style={{ width: '14px', height: '14px', marginBottom: '0px' }}
            icon={['far', 'list']}
            className="h-50-P"
            size="1x"
          />{' '}
          Add Client Activity
        </Button>
      </StyledLink>
    );
  }
  return null;
};
export default FlaggedAddClientActivityBtn;
