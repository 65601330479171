import React from 'react';
import Button from '../../../../../../CustomizedAntDesign/Button';
import Chip from '../../../../../../Layout/Chip';

const IntegrationsActions = props => {
  const { installed, showInstallButton, onClick } = props;

  if (installed) {
    return <Chip>Installed</Chip>;
  }

  if (showInstallButton) {
    return (
      <Button type="primary" onClick={onClick}>
        Install
      </Button>
    );
  }

  return null;
};

export default IntegrationsActions;
