import React, { useRef, useState } from 'react';
import { bool, func, object, array } from 'prop-types';

import Crumbs from './Crumbs';
import Errors from './Errors';
import { updateData } from './utils';

export const FIELD_STYLES = {
  width: 506,
  maxWidth: '100%',
  minWidth: 'auto',
};
export const HEADING_CLASSES = 'fs-16 fw-600 m-top-0 m-btm-8';

const Wizard = ({ data, setData, onSubmit, showAll, steps }) => {
  const [disableContinue, setDisableContinue] = useState(false);
  const [errors, setErrors] = useState([]);
  const wizardForm = useRef(null);

  // changing steps/pages
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const finalStepIndex = steps.length - 1;

  const previous = () => setActiveStepIndex(Math.max(activeStepIndex - 1, 0));
  const next = () => setActiveStepIndex(Math.min(activeStepIndex + 1, finalStepIndex));

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    document.getElementById('scrollWatchId').scrollTo(0, 0);
  };

  // helper to show step-level errors
  const pushError = error => {
    setErrors(errorList => [...errorList, error].filter((value, index, self) => self.indexOf(value) === index));
  };

  const validateField = field => {
    if (Boolean(field.optional) && !field.value) {
      return false;
    }

    if (!field.value) {
      pushError(`${field.id} is required`);
      return true;
    }

    if (field.inputMode === 'numeric' && Number.isNaN(Number(field.value, 10))) {
      pushError(`${field.id} must be a number`);
      return true;
    }

    if (
      field.minLength &&
      !Number.isNaN(Number(field.minLength, 10)) &&
      `${field.value || ''}`.length < Number(field.minLength, 10)
    ) {
      pushError(`${field.id} length must be lower than ${field.minLength}`);
      return true;
    }

    if (
      field.maxLength &&
      !Number.isNaN(Number(field.maxLength, 10)) &&
      `${field.value || ''}`.length > Number(field.maxLength, 10)
    ) {
      pushError(`${field.id} length must not be greater than ${field.maxLength} numbers`);
      return true;
    }

    return false;
  };

  // helper to validate active fields
  const validateFields = activeFields => {
    const errorFields = activeFields.filter(el => {
      return validateField(el);
    });
    console.log({ errorFields }, 'error in validate fields');

    return errorFields;
  };

  // form submission
  const isSubmitButton = showAll || activeStepIndex === finalStepIndex;

  // helper to get values from fields
  const getValues = (fields, isSaving) =>
    fields.reduce((obj, { id, value, forget }) => {
      return {
        ...obj,
        [id]: isSaving && forget ? null : value,
      };
    }, {});

  // get fields
  const getFields = () => {
    const fields = Array.from(wizardForm.current.elements)
      .filter(({ id }) => id)
      .map(el => {
        return {
          id: el.id,
          bluesnap: el.id,
          value: el.value,
          active: el.getAttribute('data-active') === 'true',
          optional: el.getAttribute('data-optional') === 'true',
          forget: el.getAttribute('data-forget') === 'true',
          minLength: el.getAttribute('minlength'),
          maxLength: el.getAttribute('maxlength'),
          inputMode: el.getAttribute('inputmode'),
          errorMessage: el.getAttribute('data-error-message'),
        };
      });

    return fields;
  };

  // get active fields, for validation & updating data
  const getActiveFields = () => {
    const fields = getFields();
    const activeFields = fields.filter(({ active }) => active);

    return activeFields;
  };

  // handler to update values by saving in the parent component
  // use instead of setData for changes that cause re-render
  const updateValues = (optionalValues = {}) => {
    const allFields = getFields();
    const allFieldsValues = getValues(allFields);

    updateData({ ...allFieldsValues, ...optionalValues }, setData);
  };

  // common actions between next/prev step changes
  const handleStepChange = () => {
    // save values so we don't lose them on re-render
    updateValues();

    // reset the page
    setErrors([]);
    setDisableContinue(false);
    scrollToTop();
  };

  // main handler for next/submit
  const handleSubmit = evt => {
    evt.preventDefault();

    handleStepChange();

    // validation
    const activeFields = getActiveFields();
    const errorFields = validateFields(activeFields);
    const isValid = !errorFields.length;

    // update our main `data` object with errors
    setData(d =>
      errorFields.reduce(
        (obj, { id }) => ({
          ...obj,
          [id]: { ...d[id], hasError: true },
        }),
        d,
      ),
    );

    // don't submit if invalid
    if (!isValid) {
      return;
    }

    if (isSubmitButton) {
      onSubmit(data, evt.target);
    } else {
      next();
    }
  };

  return (
    <>
      {/* breadcrumbs for steps/pages */}
      {!showAll && (
        <Crumbs
          crumbs={steps.map(({ title, to }, index) => ({
            title,
            isActive: activeStepIndex === index,
          }))}
          changeStep={index => {
            handleStepChange();
            setActiveStepIndex(index);
          }}
          className="m-btm-24"
        />
      )}

      <Errors errors={errors} />

      <form id="wizard-form" onSubmit={handleSubmit} ref={wizardForm}>
        {steps.map(({ title, Component, helpText }, index) => {
          const active = showAll || activeStepIndex === index;

          return (
            <section
              key={title}
              /*
               * BlueSnap javascript requires that all inputs are found in the DOM,
               * so we hide them instead
               */
              style={{
                ...FIELD_STYLES,
                display: active ? 'block' : 'none',
              }}
            >
              {helpText ? (
                <span className="fs-12 fw-500 italic m-btm-28  d-inline-block">
                  All stores will use this account by default unless you explicitly assign the store to another account.
                </span>
              ) : null}
              <h2 className={HEADING_CLASSES}>{title}</h2>
              <Component
                data={data}
                setData={setData}
                updateValues={updateValues}
                active={active}
                setDisableContinue={disabled => (active ? setDisableContinue(disabled) : null)}
              />
            </section>
          );
        })}

        <div className="buttons align-center" style={FIELD_STYLES}>
          <button type="submit" className="standardButton darkBlueButton m-btm-8" disabled={disableContinue}>
            {isSubmitButton ? 'Complete' : 'Continue'}
          </button>
          {activeStepIndex > 0 && (
            <button
              type="button"
              onClick={() => {
                handleStepChange();
                previous();
              }}
              className="cancel-button-v2 m-btm-10"
            >
              Go Back
            </button>
          )}
        </div>
      </form>
    </>
  );
};

Wizard.propTypes = {
  initialValues: object,
  onSubmit: func,
  showAll: bool,
  steps: array,
};

Wizard.defaultProps = {
  initialValues: {},
};
export default Wizard;
