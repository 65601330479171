const SANITIZE_REGEX = /[^0-9.-]+/g;


const formatPhoneNumber = (value, type = 'a') => {
  let sanitizedValue = String(value).replace(SANITIZE_REGEX, '');

  if(sanitizedValue.length > 10) {
    sanitizedValue = sanitizedValue.slice(sanitizedValue.length - 10);
  }

  return sanitizedValue.replace(/(\d{3})(\d{3})(\d{4})/, type === 'b' ? '($1) $2-$3' : '$1-$2-$3' );
};

export default formatPhoneNumber;
