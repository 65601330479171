import React, { useEffect, useRef } from 'react';
import { Route } from 'react-router-dom';
import { postRequest } from '../apiRequests';

const CBRoute = props => {
  const isMounted = useRef(null);

  useEffect(() => {
    if (props.hasOwnProperty('displayName')) {
      const displayName = props.displayName;
      if (displayName && displayName !== 'Component') {
        const reqObj = {
          params: ['merchant', 'mixpanel', 'locationMetric'],
          query: { location: displayName },
          errors: 'Error sending metrics data',
          loadingStatusOff: true,
        };
        postRequest(reqObj);
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [props.displayName]);

  return <Route {...props} />;
};
export default CBRoute;
