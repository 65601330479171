import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import Layout from '../../../Layout/StandardLayout';
import Button from '../../../Layout/Button';
import { getRequest } from '../../../../core/apiRequests';
import TitleDescription from '../../../Layout/DescriptionBox';
import Table from '../../../Layout/Table';
import { setReportAssoc } from '../../../../reducers/reports';
import { setAuthStoreId } from '../../../../reducers/auth';
import DropdownSelect from '../../../Layout/DropdownSelect';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import BulletedList from '../../../Layout/BulletedList.js';
import StyledLink from '../../../Layout/StyledLink';
import RangePicker from '../../../CustomizedAntDesign/RangePicker';
import '../reports.css';

const sourceList = [
  { label: 'All Sources', value: 0 },
  { label: 'Client Activity', value: 1 },
  { label: 'Life Event', value: 2 },
  { label: 'Manual Reminder', value: 3 },
  { label: 'Sales Opportunity', value: 4 },
  { label: 'Sales Trigger', value: 5 },
  { label: 'Tag', value: 6 },
];

const disabledDate = current => {
  return current && current > moment().endOf('day');
};

const IncompleteReminders = props => {
  const [dateRange, setDateRange] = useState([moment().subtract(1, 'months'), moment()]);
  const [tableData, setTableData] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [allAssoc, setAllAssoc] = useState([]);
  const [source, setSource] = useState({ label: 'All Sources', value: 0 });
  const associateOptions = allAssoc.map(associate => ({
    label: associate.fullName || `${associate.first_name || ''} ${associate.last_name || ''}`.trim(),
    value: associate.id,
  }));

  const sourceCalc = dataObj => {
    const {
      tag_action_config_id,
      tag_name,
      custom_event_type_id,
      custom_event_type_name,
      sales_opp_id,
      sales_opp_name,
      life_event_id,
      life_event_type,
      sales_trigger_action_id,
    } = dataObj;
    if (tag_action_config_id || tag_name) {
      if (tag_name) {
        return `Tag: ${dataObj.tag_name}`;
      }
      return 'Tag';
    }
    if (custom_event_type_id || custom_event_type_name) {
      if (custom_event_type_name) {
        return custom_event_type_name;
      }
      return 'Client Event';
    }
    if (sales_opp_id || sales_opp_name) {
      if (sales_opp_name) {
        return sales_opp_name;
      }
      return 'Sales Opp';
    }
    if (life_event_id || life_event_type) {
      if (life_event_type) {
        return life_event_type;
      }
      return 'Life Event';
    }
    if (sales_trigger_action_id) {
      return 'Sales Trigger';
    }
    return 'Manual Reminder';
  };

  useEffect(() => {
    if (props.auth.merchantId) {
      const allAssociateOption = { id: 0, fullName: 'All Associates' };
      const reqObj = {
        params: ['report', props.auth.merchantId, props.auth.storeId || 0, 'clientByAction'],
        query: {
          userId: props.auth.userId,
          role: props.auth.role,
        },
      };

      const assoc_data = async () => {
        const data = await getRequest(reqObj);
        const sortedAscts = data.sort((a, b) => {
          if (`${a.first_name || ''} ${a.last_name || ''}` > `${b.first_name || ''} ${b.last_name || ''}`) {
            return 1;
          }
          if (`${a.first_name || ''} ${a.last_name || ''}` < `${b.first_name || ''} ${b.last_name || ''}`) {
            return -1;
          }
          return 0;
        });
        setAllAssoc([allAssociateOption, ...sortedAscts]);
        setReportAssoc(0);
      };
      assoc_data();
    }
  }, [props.auth.storeId, props.auth.merchantId]);

  const requestDataIncompleteReminders = () => {
    setShowReport(false);
    const reqObj = {
      params: ['report', props.auth.merchantId, props.auth.storeId, 'incompleteReminderReport'],
      query: {
        startDt: moment(dateRange[0]).format('YYYYMMDD'),
        endDt: moment(dateRange[1]).format('YYYYMMDD'),
        associateId: props.reports.associate || '',
        storeAccess: props.auth.stores.filter(st => st.id !== 0).map(st => st.id),
      },
    };
    if (source.value === 0) {
      reqObj.query.source = '';
    } else {
      reqObj.query.source = source.label;
    }
    (async () => {
      const res = {};
      res.data = await getRequest(reqObj);
      setTableData(res.data || []);
      setShowReport(true);
    })();
  };

  const tableHeaders = [
    {
      keyName: 'due_date',
      headerClassName: '',
      title: 'Due Date',
      style: { width: '5%', paddingTop: '80px' },
      render: t => {
        return moment(t, 'YYYYMMDD').format('MM/DD/YYYY');
      },
    },
    {
      keyName: 'store_name',
      headerClassName: '',
      title: 'Store Name',
      style: { width: '7%', paddingTop: '80px' },
    },
    {
      keyName: 'name',
      headerClassName: 'row1',
      title: 'Client',
      style: { width: '10%', paddingTop: '80px' },
      render: (text, obj) => <StyledLink to={`/Clients?client=${obj.client_id}`}>{text}</StyledLink>,
    },
    {
      keyName: 'description',
      headerClassName: 'row1',
      title: 'Description',
      style: { width: '15%', paddingTop: '80px' },
    },
    {
      keyName: 'associate',
      headerClassName: 'row1',
      title: 'Associate',
      style: { width: '15%', paddingTop: '80px' },
      rowClassName: 'p-right-15',
      render: (t, o) => {
        if (t) {
          const linkList = [];
          allAssoc.forEach(associate => {
            o.associateIds.split(',').forEach((id, i) => {
              if (associate.id === Number(id) && i !== 0) {
                linkList.push(
                  <StyledLink to={`/Associates/associate-details/${id}`}>{` ${associate.fullName},`}</StyledLink>,
                );
              }
              if (associate.id === Number(id) && i === 0) {
                linkList.push(
                  <StyledLink to={`/Associates/associate-details/${id}`}>{` ${associate.fullName}`}</StyledLink>,
                );
              }
            });
          });
          return linkList;
        }
        return <span className="pos-rel gray fs-13">No assigned associate</span>;
      },
    },
    {
      keyName: '',
      headerClassName: 'row1',
      title: 'Source',
      style: { width: '10%', paddingTop: '80px' },
      render: (t, o) => {
        return sourceCalc(o);
      },
    },
  ];

  return (
    <Layout
      title="Active Reminders"
      backArrow="/Home"
      description="Set your parameters and click on 'Run Report.'"
      classProps="standard-width-with-sidebar  flex-col-center"
      styleProps={{ justifyContent: 'flex-start' }}
    >
      <div className="report-smaller-description">
        {' '}
        Active Reminders reports allow you to see all current reminders that are assigned to your associates. Each
        report can be filtered based on the source of the reminders.{' '}
      </div>
      <BulletedList
        title="The sources of the reminders in this report are the following:"
        items={[
          'Client Activities',
          'Life Events',
          'Manual Reminders',
          'Sales Opportunities',
          'Sales Triggers',
          'Tags',
        ]}
      />
      <div className="pos-rel w-100-P " style={{ maxWidth: '866px' }}>
        <TitleDescription
          title="Reporting Location"
          description="What store location would you like to run a report on?"
          input={
            <DropdownSelect
              isSearchable
              classProps="mq-w-200 w-267 align-left"
              options={props.auth.stores.map(st => ({
                label: st.name,
                value: st.id,
              }))}
              placeholder="Select a Store..."
              value={props.auth.storeId}
              onChange={obj => {
                setAuthStoreId(obj.value);
              }}
            />
          }
        />
        <TitleDescription
          title="Associate"
          description="Which associate's report would you like to run?"
          input={
            <DropdownSelect
              classProps="mq-w-200 w-267 align-left"
              options={associateOptions}
              placeholder="Select an Associate"
              value={props.reports.associate}
              onChange={obj => {
                setReportAssoc(obj.value);
              }}
            />
          }
        />
        <TitleDescription
          title="Source"
          description="What reminders would you like to see?"
          input={
            <DropdownSelect
              classProps="mq-w-200 w-267 align-left"
              options={sourceList}
              placeholder="Select a Source"
              value={source.value}
              onChange={obj => {
                setSource(obj);
              }}
            />
          }
        />

        <TitleDescription
          title="Date Range"
          input={
            <RangePicker
              format="MM/DD/YYYY"
              disabledDate={disabledDate}
              value={dateRange}
              onChange={setDateRange}
              ranges={{
                'Last 7 Days': [moment().subtract(7, 'd'), moment()],
                'Last 30 Days': [moment().subtract(30, 'd'), moment()],
                'Last 60 Days': [moment().subtract(60, 'd'), moment()],
                'Last 90 Days': [moment().subtract(90, 'd'), moment()],
              }}
              showToday
            />
          }
          className="report-date-picker"
        />

        <div className="report-asct-msg-row" style={{ maxWidth: '866px' }}>
          <Button
            id="RunActiveRemindersReport"
            classname="darkBlueButton"
            onclick={e => requestDataIncompleteReminders(e)}
          >
            Run Report
          </Button>
        </div>
      </div>
      {showReport ? (
        <div className="w-100-P rem-pad-width maw-1200">
          <div
            className="import_export_group w-100-P"
            style={{
              justifyContent: 'flex-end',
              zIndex: '100',
              marginLeft: '0px',
              marginTop: '100px',
            }}
          >
            <img src="https://dashboard-v2-images.s3.amazonaws.com/ic-export.svg" alt="export" />

            <CSVLink
              data={tableData}
              filename="activeReminders.csv"
              id="DownloadActiveRemindersReport"
              className="exportButton m-right-15"
            >
              Download
            </CSVLink>
          </div>

          <Table
            tableClassName="maw-1200 rem-pad-width m-top-15 overflowX hide-scrollbar w-100-P"
            tableContainerClass="maw-1200 w-100-P rem-pad-width "
            style={{ marginTop: '-100px', width: '100%' }}
            data={tableData}
            tableRowClassName="col-p-l-25"
            tableHeaders={tableHeaders}
            tableName="INCOMPLETE_REMINDER_REPORT"
          />
        </div>
      ) : null}
      <SecondaryPanel
        overhead_title="Reports"
        title={['CLIENTELING', 'MESSAGING', 'Google Reviews', 'PAYMENTS', 'AUTOMATION', 'SALES OPPORTUNITY']}
        current="Active Reminders"
      />
    </Layout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  reports: state.reports,
  stores: state.stores,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(IncompleteReminders);
