import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledLink from '../../Layout/StyledLink';

 
const NotFound = () => {


    return (
      <div className="h-100-vh w-100-vw rem-pad-width flex-col-center" >
        <FontAwesomeIcon
          icon={['fass', 'link-simple-slash']}
          size="6x"
          className="blue"
          style={{ paddingBottom: '2px' }}
        />
        <span className="fs-100 fw-700">Oh no!</span>
        <span className="fs-46 fw-700">You've found a page that doesn't exist.</span>
        <span className="fs-24 fw-500">Let's help you get back to <StyledLink styleprops={{color: "black", textDecoration: 'underline'}} to="/">clienteling</StyledLink>.</span>
      </div>
    );
}
export default NotFound