import React from 'react';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AutoWhatsAppSettings from '../../../../../../Layout/AutoWhatsAppTemplate/AutoWhatsAppSettings';
import SecondaryPanel from '../../../../../../Layout/SecondaryPanel';
import StandardLayout from '../../../../../../Layout/StandardLayout';

const SalesAutoWhatsapp = props => {
  const history = useHistory();
  const pipelineId = props.match.params.pipelineId;
  const listItemId = props.match.params.listItemId;

  const backUrl = `/Configuration/sales-pipelines/edit-sales-pipeline/${pipelineId}/edit-pipeline-stage/${listItemId}`;

  const setRedirectBack = () => {
    history.push(backUrl);
  };

  return (
    <StandardLayout
      title={`${props.autoRemMsgs.reminderAddOrEdit} WhatsApp Auto Template`}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Sales Opportunities', to: '/Configuration/sales-pipelines' },
        // do we want editPipeline here as well?  that'll make a huge breadcrumb
        { title: 'Edit Opportunity', to: backUrl },
        { title: `${props.autoRemMsgs.reminderAddOrEdit} WhatsApp Auto Template` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <AutoWhatsAppSettings
        setRedirectBack={setRedirectBack}
        cancelRoute={backUrl}
        descriptionText={'When would you like this Whatsapp Message to send?'}
        popConfirmDesc={'This will remove the Auto Message from this Sales Opportunity'}
        editRoute={backUrl}
        type={'salesOpp'}
        match={props.match}
      />
      <SecondaryPanel title={['Automation']} current="Sales Opportunities" />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  autoRemMsgs: state.autoRemMsgs,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(SalesAutoWhatsapp);
