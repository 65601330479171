import { getRequest } from '../../../../core/apiRequests';

export const noGoodDirtyRottenChatIdQuery = (messageId, clientId, merchantId) => {
  return new Promise((resolve, reject) => {
    const reqObj = {
      params: ['chatMessages', merchantId, 'getChatId'],
      query: {
        messageId,
        clientId,
      },
    };
    (async () => {
      const data = await getRequest(reqObj);
      if (data.chat_id) {
        resolve(data.chat_id);
      } else {
        reject(false);
      }
    })();
  });
};
