import React from 'react';
import './BulletedList.css';

const BulletedList = ({ items, title }) => {
  return (
    <>
      <div className="bulleted-list-title">{title ?? null}</div>
      <ul>
        {items.map(item => (
          <li key={item} className="bulleted-list-list">
            {item}
          </li>
        ))}
      </ul>
    </>
  );
};
export default BulletedList;
