import React from 'react';
import { string, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SettingSegment = ({ icon, title, border, children }) => {
  const borderStyle = border
    ? {
        borderBottom: '1px solid #BDBDBD',
        marginBottom: 40,
      }
    : {};

  return (
    <div style={borderStyle} className="align-left">
      {/* <div className="msgSetSegmentTitle">
        <FontAwesomeIcon icon={['far', icon]} color="#818181" />
        <span className="m-left-8">{title}</span>
      </div> */}
      <h4 className="fs-16 fw-600 m-btm-8">{title}</h4>
      <div>{children}</div>
    </div>
  );
};

SettingSegment.propTypes = {
  icon: string,
  title: string,
  border: bool,
};

export default SettingSegment;
