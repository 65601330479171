import React, { forwardRef } from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useCallback } from "react";
import { notificationError } from "../../../../../core/actions";
import { useEffect } from "react";


const UploadProductImage = forwardRef(({containerClass = "", file, setFile, onDelete, ...rest}, ref) => {

  const [fileImg, setFileImg] = useState(null);

  useEffect(() => {
    if(typeof file === 'string') {
      setFileImg(file);
    }
  }, [file])

  const handleChange = useCallback((e) => {
    if(handleValidate(e)) {
      setFile(e);
      setFileImg(URL.createObjectURL(e));
    }
  }, [setFile])

  const handleDelete = useCallback(() => {
    setFile(null);
    setFileImg(null);
    onDelete();
  }, [onDelete])

  const handleValidateFormat = useCallback(file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      notificationError('You can only upload JPG/PNG file');
    }

    return isJpgOrPng;
  }, []);

  const handleValidateSize = useCallback(file => {
    const isLowerThan2M = file.size / 1024 / 1024 < 1.5;

    if (!isLowerThan2M) {
      notificationError('Image cannot exceed 1.5MB');
    }

    return isLowerThan2M;
  }, []);

  const handleValidate = useCallback(file => {
    const isJpgOrPng = handleValidateFormat(file);
    const isLowerThan2M = handleValidateSize(file);

    return isJpgOrPng && isLowerThan2M;
  }, [handleValidateFormat, handleValidateSize]);

  const handleDragOver = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.classList.remove('product-draggeOver');

    const { files, types } = event.dataTransfer;
    if (types.length === 1 && types[0] === 'Files') {
      handleChange(files[0]);
    }
  };

  const handleDragEnter = event => {
    const { types } = event.dataTransfer;
    if (types.length === 1 && types[0] === 'Files') {
      event.currentTarget.classList.add('product-draggeOver');
    }
  };

  const handleDragLeave = event => {
    if (event.currentTarget.contains(event.relatedTarget)) return;
    event.currentTarget.classList.remove('product-draggeOver');
  };

  return (
    <div
      className={`upload-product-image ${file ? 'upload-product-image-filled' : 'upload-product-image-empty' } ${containerClass}`}
    >
        {file ? (
          <>
            <div className="delete-product-image" onClick={handleDelete}>
              <FontAwesomeIcon
                className="fs-28"
                icon={['far', 'trash-alt']}
                style={{ color: '#FFF' }}
              />
            </div>
            <img
              src={fileImg}
              alt="img"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </>
        ) : (
          <label
            htmlFor="image"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="flex-col-center fs-14 fw-500" style={{gap: 8}}>
              <FontAwesomeIcon
                className="fs-16 pointer"
                icon={['far', 'plus']}
                style={{ color: '#000' }}
              />
              Upload
            </div>
            <input
              style={{ display: 'none' }}
              type="file"
              id="image"
              {...rest}
              ref={ref}
              accept="image/*"
              onChange={value => handleChange(value.target.files[0])}
            />
          </label>
        )}
    </div>
  )
})

export default UploadProductImage;
