import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Collection = ({ o, i, m, t }) => {
  const isMounted = useRef(null);
  const phrasePart1 = o?.msg ? o?.msg?.match(/.app(.*)/)[1] : null;
  const OriginalClientId = phrasePart1 ? phrasePart1.split('/')[1] : o.clientId;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div
      className="w-100-P flex-row-nospacebetween-nowrap m-right-15"
      key={`collections${i}`}
      style={{
        justifyContent: 'flex-end',
        margin: '0px 0 3px',
      }}
    >
      <Link
        className="flex-col-left p-top-12 p-btm-6 p-left-15 p-right-8 border-r6 pointer"
        style={{
          background: '#F2F3F6',
          gap: '2px',
          minWidth: '200px',
          maxWidth: '250px',
          textDecoration: 'none',
          marginRight: '8px',
          position: 'relative',
        }}
        to={`/Clients?client=${OriginalClientId}&clientTab=collections&collectionId=${o.collectionId}`}
      >
        <div className="flex-row-spacebetween-center w-100-P">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <div
              className="flex-row-center"
              style={{
                color: '#818181',
                fontSize: '11px',
                fontWeight: 500,
              }}
            >
              <CollectionBagIcon />
              <div
                style={{
                  lineHeight: 0,
                }}
              >
                Collection
              </div>
            </div>
            <div
              style={{
                color: '#000',
                fontSize: '13px',
                fontWeight: 600,
              }}
              className="line-clamp-1"
            >
              {o?.collectionName || 'Collection Name'}
            </div>
          </div>
          <FontAwesomeIcon
            icon="fa-regular fa-chevron-right"
            style={{
              color: '#000',
              fontSize: '22px',
            }}
          />
        </div>
        <div className="singleMessageWrapper align-right w-100-P" key={`sent${i}`}>
          {o.deliveryFailed ? (
            <span className="red fs-10 m-right-6 italic">Delivery Failed</span>
          ) : (
            <span className="text-light-gray fs-10 m-right-6 italic">{moment(o.sentTimestamp).format('hh:mm a')}</span>
          )}
        </div>
      </Link>
    </div>
  );
};

const CollectionBagIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: 4 }}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M4.50733 4.99707V3.4956C4.50733 1.56012 6.06745 0 8.00293 0C9.93842 0 11.4985 1.56012 11.4985 3.4956V4.99707H13.4927C14.3255 4.99707 14.9941 5.66569 14.9941 6.49854V12.9971C14.9941 14.651 13.6569 16 11.9912 16H4.00293C2.34897 16 1 14.6628 1 12.9971V6.49854C1 5.66569 1.66862 4.99707 2.50147 4.99707H4.4956H4.50733ZM6.0088 4.99707H10.0088V3.4956C10.0088 2.39296 9.1173 1.50146 8.01466 1.50146C6.91202 1.50146 6.02053 2.39296 6.02053 3.4956V4.99707H6.0088ZM2.5132 6.49854V12.9971C2.5132 13.8299 3.18182 14.4985 4.01466 14.4985H12.0147C12.8475 14.4985 13.5161 13.8299 13.5161 12.9971V6.49854H11.522V8.24633C11.522 8.65689 11.1935 8.99707 10.7713 8.99707C10.349 8.99707 10.0205 8.66862 10.0205 8.24633V6.49854H6.02053V8.24633C6.02053 8.65689 5.69208 8.99707 5.26979 8.99707C4.84751 8.99707 4.51906 8.66862 4.51906 8.24633V6.49854H2.5132Z"
      fill="#818181"
    />
  </svg>
);

export default Collection;
