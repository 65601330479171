import React, { useState, useEffect, useCallback } from 'react';
import { string, bool, array, number, shape, oneOfType } from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { resetTablePagination } from '../../../../core/actions';
import {
  setPaymentFilterDateRange,
  setPaymentFilterStartDate,
  setPaymentFilterEndDate,
  setTransactionFilterBankAccounts,
} from '../../../../reducers/Filters';

// Layout & images
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import GlobalHeader from '../../../Layout/GlobalHeader';
import FilterSetter from '../../../Layout/FilterSetter';
import FilterChips from '../../../Layout/FilterChips';

// local
import { getBankTransactionReport, getBankTransactionCSV, getBankAccountsList } from './apiRequests';
import { DATE_RANGE } from '../../Payments/const';
import Table from './Table';
import { getDateRange } from '../../Payments/utils';
import { TRANSACTION_COLUMN_KEY } from './const';

export const ALL_ASSOCIATES_OPTION = { label: 'All Associates', value: 0 };

const tableName = 'BANK_TRANSACTIONS';

const BankTransactionReport = ({
  activeBankAccount,
  transactions = [],
  bankAccounts = [],
  filters,
  // auth
  merchantId,
  authenticated,
  role,
  // pagination
  nextPageToken,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [getParams, setGetParams] = useState({});

  // initial load
  useEffect(() => {
    resetTablePagination(
      {
        sortColumn: TRANSACTION_COLUMN_KEY.DATE,
        ascendingColumn: false,
      },
      tableName,
    );
  }, [authenticated, role, merchantId]);

  // update getParams whenever filters change
  useEffect(() => {
    const { paymentFilterDateRange, paymentFilterStartDate, paymentFilterEndDate, ...rest } = filters;
    const { startDate, endDate } = paymentFilterDateRange
      ? getDateRange(paymentFilterDateRange)
      : {
        startDate: paymentFilterStartDate,
        endDate: paymentFilterEndDate,
      };

    setGetParams(params => ({
      ...params,
      ...rest,
      startDate,
      endDate,
    }));
  }, [filters]);

  useEffect(() => {
    getBankAccountsList(merchantId);
  }, [merchantId]);

  // callback to refresh results (updates with getParams & pagination)
  const refresh = useCallback(() => {
    const hasLoadedGetParams = Object.keys(getParams).length;

    if (authenticated && bankAccounts.length && hasLoadedGetParams) {
      const isConcatenatingList = false;

      getBankTransactionReport(merchantId, bankAccounts, isConcatenatingList, getParams).catch(e => {
        console.log(e);
      });
    }
  }, [getParams, authenticated, bankAccounts, merchantId]);

  const loadMoreTransactions = useCallback(() => {
    const isConcatenatingList = true;

    getBankTransactionReport(merchantId, bankAccounts, isConcatenatingList, {
      ...getParams,
      nextPageToken,
    }).catch(e => {
      console.log(e);
    });
  }, [bankAccounts, getParams, merchantId, nextPageToken]);

  // refresh results whenever callback updates
  useEffect(() => refresh(), [refresh]);

  /*  FILTERS  */
  const dateRangeList = Object.entries(DATE_RANGE).map(([value, label]) => ({
    label,
    value,
  }));

  // Bank Filter
  const bankFilterList = bankAccounts.map(val => ({
    id: val.id,
    bankName: val.name,
    status: val.status,
  }));

  // Temp Bank Not Changed in this function
  const saveFilters = ({
    // tempAssoc,
    tempDateRange,
    tempStartDate,
    tempEndDate,
    tempBank,
  }) => {
    setShowFilters(false);

    setPaymentFilterDateRange(tempDateRange);
    setPaymentFilterStartDate(tempStartDate);
    setPaymentFilterEndDate(tempEndDate);

    if (tempBank?.[0] !== 0) {
      setTransactionFilterBankAccounts(tempBank);
    }
  };
  const exportCSV = () => {
    if (authenticated) {
      getBankTransactionCSV(merchantId, bankAccounts, {
        ...getParams,
        nextPageToken,
      });
    }
  };
  const csvButton = () => {
    if (transactions.length > 0) {
      return (
        <button id="BankTransactionsExportButton" className="save-button m-top-15" onClick={exportCSV}>
          <FontAwesomeIcon className="m-right-8 fs-16" icon={['far', 'file-excel']} />
          Export
        </button>
      );
    }
  };
  return (
    <Layout
      title="Bank Transactions"
      classProps="standard-width-with-sidebar"
      description="All client card transactions are listed here"
    >
      <div
        onClick={() => setShowFilters(false)}
        className={showFilters ? 'pos-abs w-100-vw h-100-vh left-0 align-top  nav-z-100' : 'pos-rel '}
      />

      <div className="w-100-P h-100-P">
        <GlobalHeader
          setShowFilters={setShowFilters}
          showFilters={showFilters}
          filter={
            <FilterSetter
              setShowFilters={setShowFilters}
              showFilters={showFilters}
              saveFilters={saveFilters}
              // Date
              filterDateRange={filters.paymentFilterDateRange}
              dateRangeList={dateRangeList}
              startDate={filters.paymentFilterStartDate}
              endDate={filters.paymentFilterEndDate}
              // Bank Accounts
              filterBank={filters.transactionFilterBankAccounts[0]}
              bankFilterList={bankFilterList}
              // Store
              hideStoreFilter
            />
          }
          additionalButtons={csvButton}
        />
        <FilterChips
          // Date
          filterDateRange={filters.paymentFilterDateRange}
          dateRangeList={dateRangeList}
          setFilterDateRange={setPaymentFilterDateRange}
          startDate={filters.paymentFilterStartDate}
          setFilterStartDate={setPaymentFilterStartDate}
          endDate={filters.paymentFilterEndDate}
          setFilterEndDate={setPaymentFilterEndDate}
          // Bank Account
          filterBank={filters.transactionFilterBankAccounts}
          setFilterBank={setTransactionFilterBankAccounts}
          bankFilterList={bankFilterList}
        />
        {transactions?.length && bankAccounts.length ? (
          <div>
            <Table tableName={tableName} data={transactions} bankAccount={activeBankAccount} refresh={refresh} />
            {/* Load more button if there is a nextPageToken */}
            {nextPageToken ? (
              <button className="cancel-button m-top-20 align-center" onClick={loadMoreTransactions}>
                Load More
              </button>
            ) : null}
          </div>
        ) : (
          <div className="bank-transaction-list__empty">
            <img
              src="https://dashboard-v2-images.s3.amazonaws.com/empty-bank-acct.svg"
              alt=""
              role="presentation"
              style={{ width: 240 }}
            />
            <h3 className="fs-16 fw-600">No bank transactions yet</h3>
            <p className="fs-13 gray">When clients pay with their cards, they will appear here</p>
          </div>
        )}
      </div>

      <SecondaryPanel title={['Payments']} current="Bank Transactions" />
    </Layout>
  );
};

BankTransactionReport.propTypes = {
  activeBankAccount: string,
  transactions: array,
  bankAccounts: array,
  filters: shape({
    paymentFilterDateRange: string,
    paymentFilterStartDate: string,
    paymentFilterEndDate: string,
    transactionFilterBankAccounts: array,
  }),
  // auth
  merchantId: oneOfType([number, string]),
  authenticated: bool,
  role: string,
  // pagination
  nextPageToken: string,
};

const mapStateToProps = state => ({
  activeBankAccount: state.transactions.bankAccount,
  transactions: state.transactions.list,
  // bankAccounts: state.bankAccounts.list,
  bankAccounts: state.bankAccounts.fullList,
  filters: state.filters,
  merchantId: state.auth.merchantId,
  authenticated: state.auth.authenticated,
  role: state.auth.role,
  nextPageToken: state.transactions.nextPageToken,
});

export default connect(mapStateToProps)(BankTransactionReport);
