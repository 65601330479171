import React, { useState, Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button';
import PopConfirm from '../PopConfirm';
import { deleteRequest } from '../../../core/apiRequests';
import { notificationSuccess } from '../../../core/actions';
import {
  setReminderName,
  setReminderCount,
  setReminderInterval,
  setReminderRepeat,
  setReminderActionId,
  setReminderAddEdit,
} from '../../../reducers/autoRemMsgs';
import { setTriggerActionObj } from '../../../reducers/salesTriggers';
import StyledLink from '../StyledLink';
import BorderlessTable from '../BorderlessTable';
import { convertMessageTemplate } from '../../../utils/convertMessageTemplate';

const AutoReminderTrigger = props => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [deleteType, setDeleteType] = useState('');
  const isMounted = useRef(null);
  console.log(props);

  const deleteMessage = (o, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (o.msg_id) {
      setDeleteType('regularTemplate');
      setDeleteId(o.msg_id);
    } else if (o.wat_id) {
      setDeleteType('whatsappTemplate');
      setDeleteId(o.wat_id);
    }
    setShowPopConfirm(true);
  };

  const deleteMessageTemplate = async () => {
    const { firstName, lastName, type, merchantId } = props;
    const fn = firstName || '';
    const ln = lastName || '';
    const reqObj = {
      params: ['salesTrigger', merchantId, props.match.params.actionId, 'messageTemplates', deleteId],
      query: { deletedBy: `${fn} ${ln}` },
    };
    const data = await deleteRequest(reqObj);
    if (data.id) {
      notificationSuccess('Message Template Deleted');
      const newMsgList = props.salesTriggers.actionMsgTemplates.filter(msgObj => {
        return Number(msgObj.msg_id) !== Number(deleteId);
      });
      setTriggerActionObj({ actionMsgTemplates: [...newMsgList] });
    }
    props.getActionConfigData();
    setShowPopConfirm(false);
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, [props.merchantId]);

  const tableHeaders = [
    // this is a configuration array. It specifies how each column will be structured as well as the column header info
    {
      keyName: 'msg',
      title: 'Message',
      rowClassName: 'col-p-l-25',
      style: { width: '25%', paddingTop: '0px', paddingLeft: '25px' }, // we can apply all styles to the header now.
      sortable: false,
      render: (t, o) => {
        if (o.msg_id) {
          return <div className="line-clamp-1" dangerouslySetInnerHTML={{ __html: convertMessageTemplate(t) }} />;
        }
        if (o.wat_id) {
          const subsObj = {};
          if (o.substitution) {
            const substitutes = o.substitution.split(',');
            substitutes.forEach(sub => {
              const propertyDivide = sub.split('=');
              if ((propertyDivide.length = 2)) {
                subsObj[propertyDivide[0]] = propertyDivide[1].toLowerCase();
              }
            });
          }
          let formattedMsg = o.msg.replace(/{{\d}}/g, val => {
            const theNumber = val.replace(/[{}]/g, '');
            if (subsObj[theNumber]) {
              return `[${subsObj[theNumber]}]`;
            }
            return '[N/A]';
          });
          if (formattedMsg.length > 60) {
            formattedMsg = `${formattedMsg.substr(0, 60)}...`;
          }
          return formattedMsg;
        }
      },
    },
    {
      keyName: '',
      title: 'Media',
      rowClassName: 'col-p-l-25',
      style: {
        width: '2%',
        paddingTop: '0px',
        paddingLeft: '25px',
        minWidth: '120px',
      },
      sortable: false,
      render: (t, o) => {
        if (o.image_url) {
          return (
            <img
              style={{ objectFit: 'cover', borderRadius: '2px', width: '32px', height: '32px', marginTop: '4px' }}
              className="m-right-4"
              alt="#"
              src={o.image_url}
              onError={e => {
                e.target.onerror = null;
                e.target.src = 'gift';
              }}
            />
          );
        }
        return 'None';
      },
    },
    {
      keyName: '',
      title: 'Type',
      rowClassName: 'col-p-l-25',
      style: {
        width: '2%',
        paddingTop: '0px',
        paddingLeft: '25px',
        minWidth: '120px',
      },
      sortable: false,
      render: (t, o) => {
        if (o.msg_id) {
          return 'Message Template';
        }
        return 'Whatsapp Template';
      },
    },
    {
      keyName: 'msg_id',
      title: '',
      rowClassName: 'col-p-l-25 no-text-select align-center no-y-padding',
      style: { width: '6%', paddingTop: '0px', paddingLeft: '25px' },
      render: (t, o) => {
        if (o.msg_id || o.wat_id) {
          return (
            <div
              onClick={event => deleteMessage(o, event)}
              style={{ width: '100%', height: '50px', position: 'relative' }}
            >
              <FontAwesomeIcon
                icon={['fas', 'times']}
                color="#000"
                style={{ position: 'absolute', marginTop: '18px' }}
              />
            </div>
          );
        }
      },
      isImg: 'image',
    },
  ];

  return (
    <>
      <div className="flex-row-spacebetween-wrap" style={{ alignItems: 'center' }}>
        <span className="fs-16 fw-500">Message Templates</span>
        <div className="flex-row-nospacebetween-wrap">
          {props.enabled_whatsapp ? (
            <Button
              onclick={() => props.setShowWATemplates(true)}
              classname="blue-link"
              styleProps={{ width: '225px' }}
            >
              <FontAwesomeIcon
                style={{ width: '16px', height: '16px' }}
                icon={['fab', 'whatsapp']}
                className="h-50-P blue-link"
                size="1x"
                color="#818181"
              />
              ADD WHATSAPP TEMPLATE
            </Button>
          ) : null}
          <StyledLink
            classprops="align-right"
            to={`/Configuration/sales-triggers/edit-trigger-auto-reminder/${props.match.params.actionId}/add-message-template`}
            styleprops={{
              color: '#33aafc',
              marginTop: '5px',
            }}
          >
            <Button onclick={props.onclickTrig} classname="blue-link" styleProps={{ width: '155px' }}>
              <FontAwesomeIcon
                style={{ width: '16px', height: '16px' }}
                icon={['far', 'comment-alt-check']}
                className="h-50-P blue-link"
                size="1x"
                color="#818181"
              />
              ADD TEMPLATE
            </Button>
          </StyledLink>
        </div>
      </div>
      <BorderlessTable
        tableHeaders={tableHeaders}
        data={props.salesTriggers.actionMsgTemplates}
        // onButtonClick={clearPref}
        onRowClick={props.onRowClick}
      />
      {showPopConfirm ? (
        <PopConfirm
          confirm="This will remove the template from this Reminder"
          description={props.popConfirmDesc}
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={deleteMessageTemplate}
        />
      ) : null}
      {/* {redirectEditTemp ? <Redirect to={props.editRoute} /> : null} */}
    </>
  );
};
const mapStateToProps = state => {
  return {
    ...state.auth,
    addEditTag: state.addEditTag,
    salesTriggers: state.salesTriggers,
  };
};
const mapDispatchToProps = dispatch => ({
  setName(val) {
    setReminderName(val);
  },
  setCount(val) {
    setReminderCount(val);
  },
  setInterval(val) {
    setReminderInterval(val);
  },
  setRepeat(val) {
    setReminderRepeat(val);
  },
  setActionId(val) {
    setReminderActionId(val);
  },
  setAddEdit(val) {
    setReminderAddEdit(val);
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AutoReminderTrigger);
