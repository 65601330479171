import React from 'react';
import { useHistory } from 'react-router-dom';
import LeadOuterComp from './LeadComponents/LeadOuterComp';

const NoLeadForNow = ({ setShowLead, headerText }) => {
  return (
    <LeadOuterComp
      setShowLead={setShowLead}
      showMyClient={false}
      headerText={headerText}
      headerSubTxt="Come back tomorrow for more ideas to reach out to customers. Until then, here’s some suggestions of things you could do next:"
      headerDivMargin="30px 40px"
      headerSubTxtClass="align-left"
      leadBody={<NoLeadForNowBody setShowLead={setShowLead} />}
    />
  );
};

export default NoLeadForNow;

const NoLeadForNowBody = ({ setShowLead }) => {
  const history = useHistory();
  return (
    <div style={{ margin: '0px 40px' }}>
      <ul className="fs-13 fw-500 align-left p-left-25">
        <li>
          <span
            className="pointer"
            role="button"
            tabIndex={0}
            onClick={() => {
              history.push('/Messaging/inbox');
              setShowLead(false);
            }}
            style={{ color: '#33AAFC' }}
          >
            Check the inbox
          </span>
          <span className="m-left-4">for any unread messages.</span>
        </li>
        <li>
          <span
            className="pointer"
            role="button"
            tabIndex={0}
            onClick={() => {
              history.push('/');
              setShowLead(false);
            }}
            style={{ color: '#33AAFC' }}
          >
            Work on reminders
          </span>
          <span className="m-left-4">assigned to you.</span>
        </li>
        <li>
          <span
            className="pointer"
            role="button"
            tabIndex={0}
            onClick={() => {
              history.push('/Help/training-videos');
              setShowLead(false);
            }}
            style={{ color: '#33AAFC' }}
          >
            Watch a video
          </span>
          <span className="m-left-4">to learn how to get more from Clientbook</span>
        </li>
      </ul>
      <button type="button" className="save-button-v2 m-top-20" onClick={() => setShowLead(false)}>
        Goodbye for now
      </button>
    </div>
  );
};
