import React from 'react';
import NewPopConfirm from '../../../../Layout/NewPopConfirm';
import Button from '../../../../CustomizedAntDesign/Button';
import './styles.css';

const SetPrimaryAccountModal = ({ name, onCancel, onConfirm }) => {
  return (
    <NewPopConfirm className="cb-primary-bank-account-modal">
      <h3 className="cb-primary-bank-account-modal__title">Set as Default Account</h3>

      <p className="cb-primary-bank-account-modal__subtitle">
        All new stores will be assigned to <br />
        {name}
      </p>

      <p className="cb-primary-bank-account-modal__description">
        Any new stores will be automatically linked to this account.
      </p>
      <div className="mq-w-100-vw custom-action-save-flex">
        <button className="save-button-v2 m-btm-8" onClick={onConfirm}>
          Confirm
        </button>
        <button className="cancel-button-v2" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </NewPopConfirm>
  );
};

export default SetPrimaryAccountModal;
