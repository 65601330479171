import React, { useState } from 'react';
import ViewAll from '../../../ViewAll';
import AutoMessageItem from '../AutoMessageItem';
import Table from '../../../../../../Layout/Table';
import { useEffect } from 'react';
import { getTablePaginationData, setTablePaginationPageCount, setTablePaginationRowCount } from '../../../../../../../core/actions';
import { connect } from 'react-redux';

const AllClientAutoMessages = props => {

  const [autoMessages, setAutoMessages] = useState([]);

  useEffect(() => {
    if(props?.autoMessages){
      let rowCount = props?.autoMessages?.length;
      let pageCount = Math.ceil(rowCount / props?.pagination?.pageSize || 25);
      setTablePaginationRowCount(rowCount, 'AutoMessageClient');
      setTablePaginationPageCount(pageCount, 'AutoMessageClient');
      setAutoMessages(props?.autoMessages.slice((props.pagination.page - 1) * props.pagination.pageSize, props.pagination.page * props.pagination.pageSize));
    }
  }, [
    props.pagination.page,
    props.pagination.pageSize,
  ])

  const tableHeaders = [
    {
      keyName: 'id',
      render: (t, i) => (
        <AutoMessageItem
          {...i}
          client_name={i?.relationship_type_id ? (i?.relation_name || '') : (props.client_name || props.client_name)}
          associate_name={props.associate_name}
          review_link={props.review_link}
          relation={i?.relationship_type_id ? props?.client_name : ''}
        />
      ),
    },
  ];

  return (
    <ViewAll
      title="Auto Messages"
      setViewAllType={props.setViewAllType}
    >
      <Table
        data={autoMessages}
        tableHeaders={tableHeaders}
        tableName='AutoMessageClient'
        searchable={false}
        paginationV2
        styleProps={{
          width: '100%',
          maxWidth: '100%'
        }}
        tableStyles={{
          width: '100%',
          maxWidth: '100%',
          marginTop: 0
        }}
        tableContainerClass='table-view-all-automessages'
      />
    </ViewAll>
  );
};

const mapStateToProps = state => ({
  pagination: getTablePaginationData(state, 'AutoMessageClient'),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AllClientAutoMessages);
