import React, { useEffect, useState, useRef } from 'react';
import Table from '../../../../Layout/Table';
import { connect } from 'react-redux';
import {
  notificationError,
  setTablePaginationSortColumn,
  setTablePaginationAscendColumn,
  getTablePaginationData,
  setTablePaginationPageNum,
} from '../../../../../core/actions';
import { getPagAssocDetails } from '../../../../../core/apiRequests';
import '../../associates.css';

const tableName = 'ASSOC_PAG_TABLE';

const AssocPagTable = props => {
  const [tableData, setData] = useState([]);
  // const [first, setFirst] = useState(true);
  const isMounted = useRef(null);

  useEffect(() => {
    setData([]);
    if (props.auth.merchantId) {
      getTheData(false);
    }
    return () => {
      isMounted.current = false;
    };
  }, [
    props.auth.merchantId,
    props.pagination.sortColumn,
    props.pagination.pageSize,
    props.pagination.page,
    props.pagination.ascendingColumn,
    // props.type,
  ]);

  useEffect(() => {
    const {
      firstSort,
      firstAscend,
      auth: { merchantId },
    } = props;
    if (merchantId) {
      setData([]);
      setTablePaginationPageNum(1, tableName);
      setTablePaginationSortColumn(firstSort, tableName);
      setTablePaginationAscendColumn(firstAscend || false, tableName);
    }
  }, [props.type, props.auth.merchantId]);

  const getTheData = first => {
    let sortByColumn = '';
    let ascendColumn = '';
    const {
      firstSort,
      firstAscend,
      type,
      auth: { merchantId },
      pagination: { page, pageSize, sortColumn, ascendingColumn },
    } = props;
    if (first) {
      sortByColumn = firstSort;
      ascendColumn = firstAscend;
      setTablePaginationSortColumn(firstSort, tableName);
      setTablePaginationAscendColumn(firstAscend || false, tableName);
      // setFirst(false)
    } else {
      sortByColumn = sortColumn;
      ascendColumn = ascendingColumn;
    }

    (async () => {
      const dataArr = await getPagAssocDetails(
        tableName,
        props.match.params.associateId,
        merchantId,
        page,
        pageSize,
        sortByColumn,
        ascendColumn,
        type,
      );
      if (dataArr) {
        setData(dataArr);
      } else {
        // notificationError('Error getting client details');
      }
    })();
  };

  return (
    <Table
      tableContainerClass={'table-content-tabbed-head'}
      tableClassName={'lg-table-sidebar table-content-tabbed-head rem-pad-width'}
      data={tableData}
      style={window.location.href.includes('associate-reminders') ? { width: '100%' } : {}}
      tableStyles={window.location.href.includes('associate-reminders') ? { border: 'none' } : {}}
      tableHeaders={props.tableHeaders}
      searchable={false}
      paginationV2
      tableName={tableName}
    ></Table>
  );
};
const mapStateToProps = state => ({
  pagination: getTablePaginationData(state, tableName),
  auth: state.auth,
  associate: state.associate,
});
export default connect(mapStateToProps, {})(AssocPagTable);
