import { dispatcher } from '../core/actions';
import { getPaymentStatus } from '../components/Routes/Payments/utils';
import { PAYMENTS_COLUMN_KEY } from '../components/Routes/Payments/const';

const initialState = {
  list: [],
  isError: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_PAYMENTS_LIST':
      return {
        ...state,
        list: action.payload.paymentRequests
          .map(item => {
            const {
              associateName: createdBy, // "Robin Ferguson"
              clientId,
              email, // "crasianomi101@gmail.com"
              // expireAt, // "2021-08-07T06:00:00.000Z"
              hasExpired, // true
              mobile: phone, // "+18019413870"
              name: clientName, // "mimimaaN"
              paymentRequestId: id, // 10
              paymentRequestLastStatus: status, // "UNPAID"
              paymentRequestLastStatusAt: updated, // "2021-08-11T01:51:17.000Z"
              storeName,
              storeId,
              bankAccountType,
              bankAccountNickname,
              bankAccountLastFour,
              // storeId, // 7
              totalPaid: amount, // "10.00"
              refundAmount: refunded, // "5.00"
            } = item;

            return {
              [PAYMENTS_COLUMN_KEY.ID]: id,
              clientId,
              [PAYMENTS_COLUMN_KEY.CLIENT_NAME]: clientName,
              // EXPIRED is not a BE status
              [PAYMENTS_COLUMN_KEY.STATUS]: getPaymentStatus(status, hasExpired),
              [PAYMENTS_COLUMN_KEY.STORE]: storeName,
              [PAYMENTS_COLUMN_KEY.STORE_ID]: storeId,
              [PAYMENTS_COLUMN_KEY.BANK_ACCOUNT_TYPE]: bankAccountType,
              [PAYMENTS_COLUMN_KEY.BANK_ACCOUNT_NICKNAME]: bankAccountNickname,
              [PAYMENTS_COLUMN_KEY.BANK_ACCOUNT_LAST_FOUR]: bankAccountLastFour,
              [PAYMENTS_COLUMN_KEY.AMOUNT]: amount,
              [PAYMENTS_COLUMN_KEY.UPDATED]: updated,
              [PAYMENTS_COLUMN_KEY.PHONE]: phone,
              [PAYMENTS_COLUMN_KEY.EMAIL]: email,
              [PAYMENTS_COLUMN_KEY.CREATED_BY]: createdBy,
              [PAYMENTS_COLUMN_KEY.REFUNDED]: refunded,
            };
          })
          // EXPIRED is not a BE status
          .filter(({ [PAYMENTS_COLUMN_KEY.STATUS]: status }) => action.payload.filter(status)),
      };
    default:
      return state;
  }
}

export const setPaymentsList = val => {
  dispatcher('SET_PAYMENTS_LIST', val);
};
