import { differenceInDays, eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, isSameDay, isSameMonth, isSameWeek } from 'date-fns';
import moment from 'moment';

const colors = ['#5B8FF9', '#30BF78', '#5D7092', '#F6BD16', '#E8684A', '#6DC8EC', '#945FB9', '#FF9845', '#1E9493'];

export const calculateDatasets = ({ graphData, startDate, endDate }) => {

  if(!graphData) return [];

  const data = Object.entries(graphData);

  let datasets = data?.map(([key, value], index) => ({
    label: key,
    data: [],
    backgroundColor: colors[index % colors.length],
  }));

  const step = calculateLabelsStep(startDate, endDate);
  const isSame = getIsSameStepFunction(step);

  const labels = calculateLabels(startDate, endDate, false);

  labels.forEach(label => {
    data.forEach(([key, value], index) => {
      const total = value.reduce((acc, result) => {
        if(isSame(new Date(result.date), label)) {
          return (parseFloat(acc) + parseFloat(result.total)).toFixed(2);
        } else {
          return acc;
        }
      }, 0)
      datasets[index].data.push(total);
    });
  });

  return datasets;
};

export const calculateLabelsStep = (startDate, endDate) => {
  const difference = differenceInDays(endDate, startDate);
  if (difference < 13) {
    return 'days';
  } else if (difference < 120) {
    return 'weeks';
  } else {
    return 'months';
  }
};

const getIsSameStepFunction = (step) => {
  switch (step) {
    case 'days':
      return isSameDay;
    case 'weeks':
      return isSameWeek;
    case 'months':
      return isSameMonth;
    default:
      return () => false;
  }
}

export const calculateLabels = (startDate, endDate, formatted = true) => {
    if(!startDate || !endDate) return [];
    const step = calculateLabelsStep(startDate, endDate);
    let labels = [];
    switch (step) {
      case 'days':
        labels = eachDayOfInterval({start: startDate, end: endDate})
        return formatted ? labels.map(date => moment(date).format('MMM DD')) : labels;
      case 'weeks':
        labels = eachWeekOfInterval({start: startDate, end: endDate})
        return formatted ? labels.map(date => moment(date).format('MMM DD')) : labels;
      case 'months':
        labels = eachMonthOfInterval({start: startDate, end: endDate})
        return formatted ? labels.map(date => moment(date).format('MMM YYYY')) : labels;
      default:
        return [];
    }
};

export const calculateStep = datasets => {
  let maxVal = 0;
  datasets.forEach(data => {
    data.data.forEach(val => {
      if (val > maxVal) {
        maxVal = val;
      }
    });
  });
  if (maxVal <= 20) {
    return 5;
  }
  if (maxVal < 100) {
    return 10;
  }
  if (maxVal > 500) {
    return 25;
  }
  if (maxVal > 1000) {
    return 100;
  }
  return 100;
};
