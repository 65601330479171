import { useReducer } from 'react';

export const actionTextAreaFileActions = Object.freeze({
  setImages: 'setImages',
  setToSingleImageUrl: 'setToSingleImageUrl',
  setVideos: 'setVideos',
  removeImage: 'removeImg',
  removeVideo: 'removeVideo',
  clear: 'clear',
});

const initialState = {
  images: {
    urls: [],
    files: [],
  },
  videos: {
    urls: [],
    files: [],
    videoFrames: {
      urls: [],
      files: [],
    },
  },
};

const notIndex = index => (_, i) => i !== index;

const reducer = (state, action) => {
  switch (action.type) {
    case actionTextAreaFileActions.setImages:
      return { ...state, images: action.payload };
    case actionTextAreaFileActions.setToSingleImageUrl:
      return {
        ...initialState,
        images: {
          files: [],
          urls: [action.payload],
        },
      };
    case actionTextAreaFileActions.setVideos:
      return {
        ...state,
        videos: action.payload,
      };
    case actionTextAreaFileActions.removeImage: {
      const notPayloadIndex = notIndex(action.payload);
      return {
        ...state,
        images: {
          files: state.images.files.filter(notPayloadIndex),
          urls: state.images.urls.filter(notPayloadIndex),
        },
      };
    }
    case actionTextAreaFileActions.removeVideo: {
      const notPayloadIndex = notIndex(action.payload);
      return {
        ...state,
        videos: {
          files: state.videos.files.filter(notPayloadIndex),
          urls: state.videos.urls.filter(notPayloadIndex),
          videoFrames: {
            files: state.videos.videoFrames.files.filter(notPayloadIndex),
            urls: state.videos.videoFrames.urls.filter(notPayloadIndex),
          },
        },
      };
    }
    case actionTextAreaFileActions.clear:
      return initialState;
    default:
      throw new Error('Unsupported action: ' + action.type);
  }
};

const useActionTextAreaFiles = () => {
  return useReducer(reducer, initialState);
};

export default useActionTextAreaFiles;
