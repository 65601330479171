const defaultPageState = {
  page: 1, // which page you're viewing
  pageSize: 25, // # of results per page. Should this really be 25 or 'unlimited'(view all) ??
  rowCount: 0, // Total # of DB records (not specifically the results given)
  pageCount: 0, // Total # of DB records divided by pageSize
  sortColumn: '', // Name of column to sort on
  ascendingColumn: true, // sortColumn ascending... false = descending. SQL default sort is ascending, so let's match that on first query
  searchText: '', // The text we're searching by,
};

const initialState = new Map([['DEFAULT_PAGE_STATE', defaultPageState]]);

export default (state = initialState, action) => {
  const tablePages = new Map(state);
  const currentOperatingPage = tablePages.get(action.table) ?? defaultPageState;
  const updateTable = update => {
    const fullUpdate = {
      ...currentOperatingPage,
      ...update,
    };
    fullUpdate.pageCount = Math.ceil(fullUpdate.pageCount);
    fullUpdate.page = Math.ceil(Math.max(Math.min(fullUpdate.page, fullUpdate.pageCount), 1));
    tablePages.set(action.table, fullUpdate);
  };
  switch (action.type) {
    case 'setTablePagination':
      updateTable(action.payload);
      break;
    case 'setTablePagination_Page':
      updateTable({ page: action.payload });
      break;
    case 'setTablePagination_PageSize':
      updateTable({ pageSize: action.payload });
      break;
    case 'setTablePagination_RowCount':
      updateTable({ rowCount: action.payload });
      break;
    case 'setTablePagination_PageCount':
      updateTable({ pageCount: action.payload });
      break;
    case 'setTablePagination_SortColumn':
      updateTable({ sortColumn: action.payload });
      break;
    case 'setTablePagination_AscendingColumn':
      updateTable({ ascendingColumn: action.payload });
      break;
    case 'setTablePagination_SearchText':
      updateTable({ searchText: action.payload });
      break;
    default:
      break;
  }
  return tablePages;
};
