import React, { useMemo } from 'react';
import { Card, Image, Tooltip } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import DefaultImage from './default-image.png';
import truncateText from '../../../../../../../utils/truncateText';
import './styles.css';

const CollectionCard = props => {
  const { collection, onClick } = props;

  const images = useMemo(() => {
    const mergedImages = [...collection.collection_products_images, ...collection.collection_vendor_items_images];

    return Array.from(Array(4), (_, i) => mergedImages[i] || DefaultImage);
  }, [collection.collection_products_images, collection.collection_vendor_items_images]);

  return (
    <Card className="clients-v2-collection--card" onClick={onClick} hoverable>
      <div className="clients-v2-collection--card-header">
        <div>
          <span className="clients-v2-collection--card-header-title">
            <Tooltip title={collection.collection_name}>
              {truncateText(collection.collection_name, 22, { cutWords: true })}
            </Tooltip>
          </span>

          <span className="clients-v2-collection--card-header-subtitle">
            {collection.collection_is_shared ? 'Shared with client' : ''}
          </span>
        </div>

        <RightOutlined />
      </div>

      <div className="clients-v2-collection--card-body">
        {images.map((image, index) => (
          <Image
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            src={image}
            width={110}
            height={110}
            preview={false}
            fallback={DefaultImage}
          />
        ))}
      </div>
    </Card>
  );
};

export default CollectionCard;
