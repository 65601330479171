import React, { Fragment, useState, useEffect } from 'react';
import '../../associates.css';
import { connect } from 'react-redux';
import Chart from 'chart.js';
import moment from 'moment';
import colors from 'nice-color-palettes';
import { setAllowManagerFeatures } from '../../../../../reducers/associate';
import { saveSetting, getAllAssocDetails, getRequest } from '../../../../../core/apiRequests';
import AssociateSpinner from '../AssociateSpinner.js';

const AssociateGraphs = props => {
  const [daysLate, setDayslate] = useState('0');
  const [overDue, setOverDue] = useState(0);
  const [asctList, setAsctList] = useState([]);
  const [goals, setGoals] = useState([]);
  const [dataObj, setDataObj] = useState({});
  const [loadClientContact, setLoadClientContact] = useState(true);
  const [loadTotalOutreach, setLoadTotalOutreach] = useState(true);
  const [loadReminderTime, setLoadReminderTime] = useState(true);

  const TO_Colors = [
    ['#fd0062', '#fe99c0', '#803e9f', '#1769af', '#009470'],
    ['#7fbd31', '#fc8333', '#feb32c', '#a50087', '#55a4e7'],
    ['#6ee5ea', '#ff6363', '#9ddfff', '#ffd495', '#dcff66'],
    ['#0eb3ba'],
  ];
  const TotalOutreachColors = TO_Colors.concat(colors);

  const toggleManagerLock = () => {
    const {
      merchantId,
      associate: { associateId, allowManagerFeatures },
    } = props;
    (async () => {
      const data = await saveSetting(
        'associates',
        merchantId,
        associateId,
        'toggleManagerLock',
        { allowManagerFeatures },
        '',
      );
      const { manager } = data;
      setAllowManagerFeatures(manager);
    })();
  };

  const updateGoalsAndOverdue = tempDataObj => {
    const dataObjToArr = Object.keys(tempDataObj).map(dt => tempDataObj[dt]);
    setDataObj(tempDataObj);
    setGoals(dataObjToArr);
  };

  const getClientContactData = async tmpDataObj => {
    const {
      auth: { merchantId },
      associate: { associateId },
    } = props;
    const configObj = {
      params: ['associates', merchantId, associateId, 'getClientContactData'],
      query: {},
      loadingStatusOff: true,
    };
    const data = await getRequest(configObj);

    const asctsObj = {};
    data.forEach(cD => {
      asctsObj[cD.cnt] = 0;
    });
    const associates = Object.keys(asctsObj);
    setAsctList(associates);
    const tempDataObj = tmpDataObj;
    data.forEach(obj => {
      const tDO = weekPlacer(
        { date: moment(new Date(obj.date)).format('YYYYMMDD'), contactCount: obj.cnt },
        tmpDataObj,
      );
      Object.assign(tempDataObj, tDO);
    });

    updateGoalsAndOverdue(tempDataObj);
    setLoadClientContact(false);
  };

  const getTotalOutreach = async tmpDataObj => {
    const {
      auth: { merchantId },
      associate: { associateId },
    } = props;
    const configObj = {
      params: ['associates', merchantId, associateId, 'getTotalOutreach'],
      query: {},
      loadingStatusOff: true,
    };
    const data = await getRequest(configObj);
    const tempDataObj = tmpDataObj;
    data.forEach(obj => {
      const tDO = weekPlacer(
        { date: moment(new Date(obj.date)).format('YYYYMMDD'), orCount: obj.cnt, actionType: obj.action_type },
        tmpDataObj,
      );
      Object.assign(tempDataObj, tDO);
    });

    updateGoalsAndOverdue(tempDataObj);
    setLoadTotalOutreach(false);
  };

  const getReminderTimeliness = async tmpDataObj => {
    const {
      auth: { merchantId },
      associate: { associateId },
    } = props;
    const configObj = {
      params: ['associates', merchantId, associateId, 'getReminderTimeliness'],
      query: {},
      loadingStatusOff: true,
    };
    const data = await getRequest(configObj);
    const tempDataObj = tmpDataObj;
    data.forEach(obj => {
      const tDO = weekPlacer(
        { date: moment(new Date(obj.date)).format('YYYYMMDD'), complete: obj.complete, daysLate: obj.days_late },
        tmpDataObj,
      );
      Object.assign(tempDataObj, tDO);
    });
    updateGoalsAndOverdue(tempDataObj);
    setLoadReminderTime(false);
  };

  useEffect(() => {
    if (props.associate.associateId) {
      let graphData;
      graphData = async () => {
        const { associateId } = props.associate;
        const graphData = await getAllAssocDetails('associates', associateId);
        setDayslate(`${graphData[0]?.avgDaysLate[0]?.avg_days_late || ''}`.toString() || 0);

        const contactGoal = graphData[0].clientContactGoal || [];
        const ttlOutreach = graphData[0].totalOutreachGraph || [];

        let tempDataObj = {};
        let i = 0;
        while (i < 12) {
          const dateDiff = moment(new Date())
            .startOf('isoWeek') // why this?
            .subtract(i, 'weeks')
            .format('YYYYMMDD');
          tempDataObj = {
            ...tempDataObj,
            [dateDiff]: {
              date: moment(dateDiff, 'YYYYMMDD').format('MMM DD'),
              contactCount: 0,
              goal: 0,
              reminderOnTime: 0,
              reminderLate: 0,
              reminderDismissed: 0,
              actionTypes: {},
            },
          };

          i++;
        }
        setDataObj({ ...tempDataObj });
        getClientContactData(tempDataObj);
        getTotalOutreach(tempDataObj);
        getReminderTimeliness(tempDataObj);
        contactGoal.forEach(obj => weekPlacer(obj, tempDataObj));
      };
      graphData();
    }
  }, [props.associate.associateId]);

  const weekPlacer = (day, tDO) => {
    let tempDataObj = tDO;
    const diff = moment(new Date()).startOf('isoWeek').diff(day.date, 'weeks');
    const targetDt = moment(new Date()).startOf('isoWeek').subtract(diff, 'weeks').format('YYYYMMDD');
    const contactCountStep = [];
    if (tempDataObj[targetDt]) {
      if (day.hasOwnProperty('contactCount')) {
        tempDataObj = {
          ...tempDataObj,
          [targetDt]: {
            ...tempDataObj[targetDt],
            contactCount: tempDataObj[targetDt].contactCount + day.contactCount,
          },
        };
      }
      if (day.hasOwnProperty('goal')) {
        tempDataObj = {
          ...tempDataObj,
          [targetDt]: {
            ...tempDataObj[targetDt],
            goal: tempDataObj[targetDt].goal + day.goal,
          },
        };
      }
      if (day.hasOwnProperty('actionType')) {
        Object.keys(tempDataObj).forEach(wk => {
          if (!tempDataObj[wk].actionTypes[day.actionType]) {
            tempDataObj = {
              ...tempDataObj,
              [wk]: {
                ...tempDataObj[wk],
                actionTypes: {
                  ...tempDataObj[wk].actionTypes,
                  [day.actionType]: 0,
                },
              },
            };
          }
        });
        tempDataObj = {
          ...tempDataObj,
          [targetDt]: {
            ...tempDataObj[targetDt],
            actionTypes: {
              ...tempDataObj[targetDt].actionTypes,
              [day.actionType]: tempDataObj[targetDt].actionTypes[day.actionType] + day.orCount,
            },
          },
        };
      }
      if (day.hasOwnProperty('daysLate')) {
        const recordComplete = Number(day.complete);
        const recordLate = Number(day.daysLate);
        if (!recordLate && recordComplete) {
          tempDataObj = {
            ...tempDataObj,
            [targetDt]: {
              ...tempDataObj[targetDt],
              reminderOnTime: tempDataObj[targetDt].reminderOnTime + 1,
            },
          };
        } else if (day.complete && recordComplete) {
          tempDataObj = {
            ...tempDataObj,
            [targetDt]: {
              ...tempDataObj[targetDt],
              reminderLate: tempDataObj[targetDt].reminderLate + 1,
            },
          };
        } else if (!day.complete) {
          tempDataObj = {
            ...tempDataObj,
            [targetDt]: {
              ...tempDataObj[targetDt],
              reminderDismissed: tempDataObj[targetDt].reminderDismissed + 1,
            },
          };
          // dataObj[targetDt].reminderDismissed += 1;
        }
        if (day.daysLate > 0) {
          setOverDue(overDue + 1);
        }
      }
    }
    return tempDataObj;
  };

  const calculateStep = maxVal => {
    if (maxVal <= 10) {
      return 1;
    }
    if (maxVal < 50) {
      return 5;
    }
    if (maxVal < 100) {
      return 10;
    }
    if (maxVal < 250) {
      return 20;
    }
    if (maxVal < 500) {
      return 25;
    }
    if (maxVal < 1000) {
      return 100;
    }
    if (maxVal < 5000) {
      return 500;
    }
    if (maxVal < 10000) {
      return 1000;
    }
    if (maxVal < 50000) {
      return 5000;
    }
    return 10000;
  };

  if (goals.length > 0) {
    const sumContact = [];
    const sumAction = [];
    let sumReminder = [];

    goals.forEach(gl => {
      // CONTACT COUNT
      if (gl.contactCount) {
        const pointsOfData_contact = Object.values(gl.contactCount);
        const contact_sum = pointsOfData_contact.reduce(function (a, b) {
          return a + b;
        }, 0);
        sumContact.push(contact_sum);
        // ACTION TYPES
        const pointsOfData_action = Object.values(gl.actionTypes);
        const action_sum = pointsOfData_action.reduce(function (a, b) {
          return a + b;
        }, 0);
        sumAction.push(action_sum);
      }
    });
    sumReminder = goals.map(gl => gl.reminderOnTime).filter(item => item > 0);

    sumContact.sort(function (a, b) {
      return b - a;
    });
    sumAction.sort(function (a, b) {
      return b - a;
    });
    sumReminder.sort(function (a, b) {
      return b - a;
    });
    const step1 = calculateStep(sumContact[0]);
    const step2 = calculateStep(sumAction[0]);
    const step3 = calculateStep(sumReminder[0]);
    // END HERE

    // CLIENT CONTACT VS GOAL
    const ctx = 'associateClientContact';
    new Chart(ctx, {
      type: 'bar',
      data: {
        datasets: [
          {
            label: 'Goals',
            data: goals.map(gl => gl.goal),
            pointRadius: 0,
            backgroundColor: 'white',
            borderWidth: '1',
            borderColor: '#979797',
            borderDash: [5, 5],
            type: 'line',
          },
          {
            label: 'Count',
            data: goals.map(gl => gl.contactCount),
            barPercentage: 0.5,
            barThickness: 20,
            backgroundColor: '#fc8333',
            order: 1,
          },
        ],
        labels: goals.map(gl => gl.date),
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 10,
            fontSize: 12,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: true,
                padding: 8,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                min: 0,
                stepSize: step1,
                padding: 8,
              },
            },
          ],
        },
      },
    });

    // SECOND GRAPH FOR TOTAL OUTREACH

    const out = 'associateOutreachChart';
    new Chart(out, {
      type: 'bar',
      data: {
        datasets: (() => {
          if (goals.length > 0 && goals[0].actionTypes) {
            const keyNames = goals.length > 0 ? Object.keys(goals[0].actionTypes) : [];
            let colorLayer = 0;
            let colorIndex = 0;
            return keyNames.map((kn, ind) => {
              const dataConfig = {
                label: `${kn} `,
                data: goals.map(gl => gl.actionTypes[kn]),
                barPercentage: 0.5,
                barThickness: 20,
                backgroundColor: TotalOutreachColors[colorLayer][colorIndex],
                order: 1,
              };
              colorIndex += 1;
              if (colorIndex > 4) {
                colorIndex = 0;
                colorLayer += 1;
              }
              return dataConfig;
            });
          }
        })(),
        labels: goals.map(gl => gl.date),
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 10,
            fontSize: 12,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: true,
                padding: 8,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                min: 0,
                stepSize: step2,
                padding: 8,
              },
            },
          ],
        },
      },
    });

    // REMINDER TIMELINESS
    const timeliness = 'associateTimelinessChart';
    new Chart(timeliness, {
      type: 'bar',
      data: {
        datasets: [
          {
            label: 'On Time ',
            data: goals.map(gl => {
              return gl.reminderOnTime;
            }),
            barPercentage: 0.5,
            barThickness: 20,
            backgroundColor: '#1769AF',
            order: 1,
          },
          {
            label: 'Late ',
            data: goals.map(gl => gl.reminderLate),
            barPercentage: 0.5,
            barThickness: 20,
            backgroundColor: '#FF6363',
            order: 1,
          },
          {
            label: 'Dismissed ',
            data: goals.map(gl => gl.reminderDismissed),
            barPercentage: 0.5,
            barThickness: 20,
            backgroundColor: '#F7B500',
            order: 1,
          },
        ],
        labels: goals.map(gl => gl.date),
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 10,
            fontSize: 12,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: true,
                padding: 8,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                min: 0,
                stepSize: step3,
                padding: 8,
              },
            },
          ],
        },
      },
    });
  }

  return (
    <>
      {/* two graphs here  */}
      <div className="assoc_outreach w-100-P">
        <div className="assoc_card m-right-20 p-25 rem-pad-width">
          <div className="assoc_card_head">
            <span className="assoc_card_title fs-16 fw-500">Client Contacts vs Goal</span>
            <p style={{ marginTop: '3px' }} className="assoc_last_time m-btm-0">
              {' '}
              last 12 weeks{' '}
            </p>
          </div>
          <div className="assoc_chart " style={{ height: '230px' }}>
            <AssociateSpinner visible={loadClientContact} />
            <canvas style={{ visibility: loadClientContact ? 'hidden' : 'visible' }} id="associateClientContact" />
          </div>
        </div>
        <div className="assoc_card p-25 rem-pad-width">
          <div className="assoc_card_head">
            <span className="assoc_card_title fs-16 fw-500">Total Outreach</span>
            <p style={{ marginTop: '3px' }} className="assoc_last_time m-btm-0">
              {' '}
              last 12 weeks{' '}
            </p>
          </div>
          <div className="assoc_chart " style={{ height: '230px' }}>
            <AssociateSpinner visible={loadTotalOutreach} />
            <canvas style={{ visibility: loadTotalOutreach ? 'hidden' : 'visible' }} id="associateOutreachChart" />
          </div>
        </div>
      </div>
      {/* second row */}
      <div className="assoc_time">
        <div className="assoc_timeliness p-25 rem-pad-width">
          <div className="assoc_card_head">
            <span className="assoc_card_title fs-16 fw-500">Reminder Timeliness</span>
            <p style={{ marginTop: '3px' }} className="assoc_last_time m-btm-0">
              {' '}
              last 12 weeks{' '}
            </p>
          </div>

          <div className="assoc_chart homeMargins" style={{ maxWidth: '830px', height: '230px' }}>
            <AssociateSpinner visible={loadReminderTime} />
            <canvas style={{ visibility: loadReminderTime ? 'hidden' : 'visible' }} id="associateTimelinessChart" />
          </div>
        </div>
        <div className="assoc_days_late p-25 rem-pad-width">
          <div className="assoc_card_head" style={{ flexDirection: 'column' }}>
            <span className="fs-16 fw-500 align-left ">Reminder Timeliness</span>
            <div className="align-left fs-13 fw-500 light-gray ">
              <p style={{ marginTop: '3px', width: '100%' }} className="assoc_last_time m-btm-0">
                {' '}
                total last 12 weeks{' '}
              </p>
            </div>
          </div>
          <div className="assoc_last_time m-btm-0" />
          <div className="flex-row-nospacebetween-nowrap ">
            <div className="flex-col-left" style={{ marginRight: '20%' }}>
              <span className="fs-42 fw-600">{daysLate}</span>
              <span className="fw-500 fs-13 light-gray align-left">Average Days Late</span>
            </div>
            <div className="flex-col-left">
              <span className="fs-42 fw-600">{overDue}</span>
              <span className="fw-500 fs-13 light-gray align-left">Overdue Reminders</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  associate: state.associate,
  auth: state.auth,
  ...state.notification,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AssociateGraphs);
