import { postRequest } from "../../../core/apiRequests";

export const updatedAiAssistantViewDate = (userId, setAuthEdison=()=>{}) => {
    const today = new Date();
    const date = `${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}`;
    setAuthEdison(date);
    const reqObj = {
      params: ['associates', userId, 'edisonViewDate'],
      query: {
        hasSeenDate: date,
      },
      loadingStatusOff: true,
    };
    (async () => {
      await postRequest(reqObj);
    })();
  };

export const updateVendorItemViewDate = async(vendorRecommendations=[], eventSource, eventType) => {
    if(vendorRecommendations?.length > 0) {
        const reqObj = {
            params: ['vendors', 'viewEvent'],
            loadingStatusOff: true,
            data: {
                vendorRecommendations,
                eventSource,
                eventType
            }
        };
        await postRequest(reqObj);
    }
  }