import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MessageNotificationCloseIcon = () => (
  <div style={{ marginTop: '-3px' }}>
    <FontAwesomeIcon
      color="#D0D0D0"
      className="pointer"
      icon={['far', 'times']}
      style={{
        width: '24px',
        height: '24px',
        paddingBottom: '3px',
      }}
    />
  </div>
);

export default MessageNotificationCloseIcon;
