import React, { useState, useEffect } from 'react';
import { notificationError, notificationSuccess } from '../../../../core/actions';
import { getRequest, postRequest } from '../../../../core/apiRequests';
import DropdownSelect from '../../../Layout/DropdownSelect';

const ReassignAssociateModal = props => {
  const [associateName, setAssociateName] = useState('');
  const [associateList, setAssociateList] = useState([]);
  const [associateID, setAssociateID] = useState('');

  useEffect(() => {
    if (props.merchantId) {
      if (props.storeObjs) {
        const reqObj = {
          params: ['associates', props.merchantId, 'getAllAssociateList'],
        };
        (async () => {
          const data = await getRequest(reqObj);
          const tempAssocList = [
            {
              name: 'No Associate',
              id: 0,
            },
          ];
          if (data) {
            data.map(assoc => {
              tempAssocList.push({
                name: `${assoc.first_name} ${assoc.last_name || ''}`,
                id: assoc.id,
              });
            });
          }
          setAssociateList(tempAssocList);
        })();
      }
    }
  }, [props.merchantId, props.storeObjs]);

  return (
    <div
      className="pop-confirm-background"
      id="modal-background"
      // onClick={backgroundCloseHandler}
    >
      <div
        className="pop-confirm-container rem-pad-width"
        style={{ width: '404px', minHeight: '278px', maxHeight: '474px' }}
      >
        <div
          className="flex-col-center h-100-P"
          style={{
            justifyContent: 'flex-start',
            width: '295px',
            margin: '0 auto',
          }}
        >
          <div className="flex-row-spacebetween-nowrap w-100-P m-top-15"></div>
          <span className="fs-18 fw-500" style={{ marginTop: '15px' }}>
            Which associate should we reassign their clients to?
          </span>
          <DropdownSelect
            classProps={'w-295 m-top-13 p-top-15 align-left'}
            options={associateList.map(associate => ({
              label: associate.name,
              value: associate.id,
            }))}
            placeholder="Choose an associate..."
            value={associateID}
            onChange={obj => setAssociateID(obj.value)}
            optionalStyles={{
              menuList: (provided, state) => ({
                ...provided,
                // fontSize: "12px",
                height: '100px',
              }),
            }}
          />
          <div
            className="flex-row-spacebetween-nowrap"
            style={{ width: '295px', marginTop: '29px', marginBottom: '43px' }}
          >
            <button
              onClick={() => props.toggleReassignAssoc(false)}
              style={{
                width: '140px',
                height: '45px',
                borderRadius: '3px',
                border: 'solid 1px #979797',
                backgroundColor: '#fff',
                color: '#979797',
              }}
              className="fs-12 fw-600"
            >
              Cancel
            </button>
            <button
              style={{ width: '140px' }}
              className="fw-600 fs-12 save-button"
              onClick={() => props.confirmDelete(associateID)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReassignAssociateModal;
