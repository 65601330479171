import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Button, message } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import CollectionsListHeader from './components/CollectionsListHeader';
import CollectionsList from './components/CollectionsList';
import CollectionProductsListHeader from './components/CollectionProductsListHeader';
import CollectionProductsList from './components/CollectionProductsList';
import CollectionProductHeader from './components/CollectionProductHeader';
import CollectionProduct from './components/CollectionProduct';
import './styles.css';
import NameCollectionModal from './components/NameCollectionModal';
import { notificationError, notificationSuccess } from '../../../../../core/actions';
import {
  addProductToClientCollection,
  deleteCollection,
  deleteProductFromCollection,
  deleteVendorItemFromCollection,
} from '../../../../../api/collections';
import { API_URL } from '../../../../../constants';
import axios from 'axios';
import { postRequest } from '../../../../../core/apiRequests';
import PopConfirm from '../../../../Layout/PopConfirm';
import ShareCollectionModal from './components/ShareCollectionModal';
import useSearchParams from '../../../../../hooks/useSearchParams';

const Collections = ({...props}) => {
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState(new Set());


  const [loading, setLoading] = useState(false);
  const [collections, setCollections] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(0);
  const showPagination = useMemo(() => total > 20, [total]);

  const { setValue, getValue: getSearchParam } = useSearchParams()
  const selectedCollectionId = getSearchParam('collectionId');
  const setSelectedCollectionId = useCallback((collection) => {
    setValue('collectionId', collection?.collection_id)
  }, [])

  useEffect(() => {
    if(selectedCollectionId) {
      const collection = collections.find((item) => item.collection_id == selectedCollectionId);
      setSelectedCollection(collection);
      setSelectedProduct(null);
      setShowAddProduct(false)
    } else {
      setSelectedCollection(null);
      setSelectedProduct(null);
      setShowAddProduct(false);
    }
  }, [selectedCollectionId, collections])

  const toggleShowAddProduct = useCallback(() => {
    setShowAddProduct(!showAddProduct);
  }, [showAddProduct]);


  const fetchCollections = useCallback(async () => {
    if(props.client.client_id) {
      setLoading(true);

      try {
        const response = await axios.get(`${API_URL}/xa/clients/${props.client.client_id}/collections-v2`, {
          params: {
            page,
            pageSize,
          },
        });

        setPage(response.data.page);
        setPageSize(response.data.pageSize);
        setTotal(response.data.total);
        setCollections(response.data.results);
      } catch (error) {
        message.error('Error Fetching Collections');
      } finally {
        setLoading(false);
      }
    }
  }, [props.client.client_id, page, pageSize]);

  useEffect(() => {
    fetchCollections();
  }, [props.client.client_id, page, pageSize]);

  const onAddToCollection = async () => {
    if (selectedProducts.size > 0) {
      const productIds = [...selectedProducts];
      const res = await addProductToClientCollection({
        clientId: props.client?.client_id,
        collectionId: selectedCollection?.collection_id,
        data: {
          productId: productIds,
        },
      });
      if (res) {
        notificationSuccess(`Products added to "${selectedCollection.collection_name}"`);
        let updatedCollection = {
          ...selectedCollection,
          new_products_ids: [...productIds],
        }
        setSelectedCollection(updatedCollection);
      }
    }
    setSelectedProducts(new Set());
    toggleShowAddProduct()
  };

  const handleDeleteProductFromCollection = useCallback(
    async id => {
      const res = await deleteProductFromCollection({
        clientId: props.client?.client_id,
        collectionId: selectedCollection?.collection_id,
        productId: id,
      });

      if (res) {
        notificationSuccess(`Product removed from "${selectedCollection.collection_name}"`);

        const updatedCollection = {
          ...selectedCollection,
          new_products_ids: [id],
        };

        setSelectedCollection(updatedCollection);
      }
    },
    [props.client, selectedCollection],
  );

  const handleDeleteVendorItemFromCollection = useCallback(
    async vendorItemId => {
      const res = await deleteVendorItemFromCollection({
        clientId: props.client?.client_id,
        collectionId: selectedCollection?.collection_id,
        vendorItemId,
      });

      if (res) {
        notificationSuccess(`Product removed from "${selectedCollection.collection_name}"`);

        const updatedCollection = {
          ...selectedCollection,
          new_products_ids: [vendorItemId],
        };

        setSelectedCollection(updatedCollection);
      }
    },
    [props.client, selectedCollection],
  );

  const onDeleteToCollection = async (id, vendorId = null) => {
    if (vendorId) {
      handleDeleteVendorItemFromCollection(id);
    } else {
      handleDeleteProductFromCollection(id);
    }
  };

  const toggleProductSelection = useCallback(
    productId => {
      setSelectedProducts(oldSet => {
        const newSet = new Set(oldSet);
        if (newSet.has(productId)) newSet.delete(productId);
        else newSet.add(productId);
        return newSet;
      });
    },
    [setSelectedProducts],
  );

  const handleGoBack = useCallback(() => {
    if (showAddProduct) {
      setSelectedProducts(new Set());
      toggleShowAddProduct();
    } else {
      setSelectedCollectionId(null);
    }
  }, [showAddProduct]);


  const handleNameCollection = (name) => {
    if(props.client.client_id) {
      if(selectedCollection?.collection_id) {
        const reqObj = {
          params: ['clients', props.client.client_id, 'collections', selectedCollection.collection_id, 'rename-collection'],
          data: {
            name
          },
        };
        (async () => {
          const resp = await postRequest(reqObj);
          if (resp) {
            const findIndex = collections.findIndex((item) => item.collection_id == selectedCollection.collection_id);
            const updatesCollections = [...collections];
            updatesCollections[findIndex].collection_name = name;
            setCollections(updatesCollections);
            notificationSuccess('Collection renamed!');
            setShowRenameModal(false);
          } else {
            notificationError('Failed To create collection');
            setShowRenameModal(false);
          }
        })();
      } else {
        const reqObj = {
          params: ['clients', props.client.client_id, 'collections'],
          data: {
            name
          },
        };
        (async () => {
          const { collectionId } = await postRequest(reqObj);
          if (collectionId) {
            notificationSuccess('Collection Created!');
            const newCollection = {
              client_id: props.client.client_id,
              client_name: props.client?.preferred_name || props.client.name,
              collection_id: collectionId,
              collection_is_shared: false,
              collection_name: name,
              collection_products_images: [],
              collection_vendor_items_images: [],
            }
            setSelectedCollectionId(newCollection);
            setCollections([...collections, newCollection]);
            setShowCreateModal(false);
          } else {
            notificationError('Failed To create collection');
            setShowCreateModal(false);
          }
        })();
      }
    }
  };

  const deleteCustomerCollection = () => {
    (async () => {
      const respObj = await deleteCollection({
        clientId: props.client?.client_id,
        collectionId: selectedCollection?.collection_id,
      });
      if (respObj) {
        setShowDeleteModal(false);
        notificationSuccess('Collection deleted!');
        const filteredCollections = collections.filter(c => c.collection_id !== selectedCollection?.collection_id);
        setCollections(filteredCollections);
        setSelectedCollectionId(null)
      } else {
        notificationError('Error deleting collection');
      }
    })();
  };

  const changeShareCollectionState = useCallback(() => {
    const findIndex = collections.findIndex((item) => item.collection_id == selectedCollection.collection_id);
    const updatesCollections = [...collections];
    updatesCollections[findIndex].collection_is_shared = true;
    setCollections(updatesCollections);
  }, [selectedCollection, collections]);

  if (selectedProduct) {
    return (
      <div className="clients-v2-collections--container">
        <Button
          type="text"
          size="large"
          icon={<LeftOutlined />}
          onClick={() => setSelectedProduct(null)}
          className="clients-v2-collections--back"
        />

        <div className="clients-v2-collections">
          <CollectionProductHeader
            selectedProduct={selectedProduct}
            onDeleteToCollection={onDeleteToCollection}
            selectedCollection={selectedCollection}
            setSelectedProduct={setSelectedProduct}
          />
          <CollectionProduct selectedProduct={selectedProduct} />
        </div>
      </div>
    );
  }

  if (selectedCollection) {
    return (
      <div className="clients-v2-collections--container">
        <Button
          type="text"
          size="large"
          icon={<LeftOutlined />}
          onClick={handleGoBack}
          className="clients-v2-collections--back"
        />

        <div className="clients-v2-collections">
          <CollectionProductsListHeader
            selectedProducts={selectedProducts}
            selectedCollection={selectedCollection}
            setSelectedCollection={setSelectedCollectionId}
            showAddProduct={showAddProduct}
            saveButtonProps={{
              disabled: selectedProducts.size === 0,
              onClick: onAddToCollection,
            }}
            toggleShowAddProduct={toggleShowAddProduct}
            clientId={props.client?.client_id}
            openRenameModal={() => setShowRenameModal(true)}
            openDeleteModal={() => setShowDeleteModal(true)}
            openShareModal={() => setShowShareModal(true)}
          />
          <CollectionProductsList
            collection={selectedCollection}
            setSelectedProduct={setSelectedProduct}
            listItemsStyles={{ height: '100%'}}
            toggleShowAddProduct={toggleShowAddProduct}
            showAddProduct={showAddProduct}
            toggleProductSelection={toggleProductSelection}
            selectedProducts={selectedProducts}
          />
          {showRenameModal && (
            <NameCollectionModal
              onClose={() => setShowRenameModal(false)}
              setSelectedCollection={setSelectedCollectionId}
              onSaveCollection={handleNameCollection}
              collection={selectedCollection}
            />
          )}
          {showDeleteModal && (
            <PopConfirm
              confirm={`Are you sure you want to delete "${selectedCollection.collection_name}" ?`}
              description="This will permanetly delete this collection and shared links will no longer be valid"
              onCancel={() => setShowDeleteModal(false)}
              onConfirm={deleteCustomerCollection}
            />
          )}
          {showShareModal && (
            <ShareCollectionModal
              client={props.client}
              collection={selectedCollection}
              onClose={() => setShowShareModal(false)}
              auth={props.auth}
              changeShareCollectionState={changeShareCollectionState}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="clients-v2-collections">
      <CollectionsListHeader
        setShowCreateModal={setShowCreateModal}
        showCreateModal={showCreateModal}
        flags={props?.auth?.merchantFlags}
      />
      <CollectionsList
        setSelectedCollection={setSelectedCollectionId}
        showPagination={showPagination}
        collections={collections}
        loading={loading}
        page={page}
        pageSize={pageSize}
        total={total}
        setPage={setPage}
        setPageSize={setPageSize}
      />
      {showCreateModal && (
        <NameCollectionModal
          onClose={() => setShowCreateModal(false)}
          client={props.client}
          setSelectedCollection={setSelectedCollectionId}
          onSaveCollection={handleNameCollection}
        />
      )}
    </div>
  );
};

export default Collections;
