import React, { useState, useEffect, useCallback } from 'react';
import { string, bool } from 'prop-types';
import { connect } from 'react-redux';

// redux & api
import { markAsPaid, sendReminder, cancelPayment, refundPayment } from '../apiRequests';

import { getPaymentDetails } from '../apiRequests';

// Layout & images
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import StyledLink from '../../../Layout/StyledLink';

// local
import { PAYMENT_STATUS_LABEL, PAYMENT_STATUS, DATE_FORMAT } from '../const';
import { formatDate } from '../utils';
import { CancelPaymentRequest, MarkAsPaid, SendReminder, RefundPayment } from '../Dialogs';
import PaymentProduct from './PaymentProduct';
import PaymentRefund from './PaymentRefund';
import ActivityItem from './ActivityItem';
import PaymentMethod from './PaymentMethod';
import { associateNameFormat } from '../../../../utils';
import Bubble from '../Bubble';
import { useLocation } from 'react-router';

const PaymentDetails = ({ match, auth, fullName }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const [markAsPaidDialogItem, setMarkAsPaidDialogItem] = useState(null);
  const [sendReminderDialogItem, setSendReminderDialogItem] = useState(null);
  const [cancelDialogItem, setCancelDialogItem] = useState(null);
  const [refundDialogItem, setRefundDialogItem] = useState(null);
  const [refundSum, setRefundSum] = useState(0);
  const [payment, setPayment] = useState(null);
  const [storeId, setStoreId] = useState(0);
  const [paymentActivity, setActivity] = useState(null);

  const paymentId = match.params.id;
  const payActivity = paymentActivity;

  const refresh = useCallback(() => {
    getPaymentDetails(paymentId).then(data => {
      let activity = data.paymentActivity;
      if (activity.length > 5) {
        activity = data.paymentActivity.slice(0, 5);
      }

      setActivity(activity);

      const paymentObj = data;

      if (data.refunds.length > 0) {
        const sumOfRefunds = data.refunds.reduce((acc, val) => {
          if (val.status && val.status === 'success') {
            acc = parseFloat(acc) + (parseFloat(val.totalAmount) || 0);
          }
          return acc;
        }, 0);

        setRefundSum(parseFloat(sumOfRefunds).toFixed(2));

        if (sumOfRefunds < paymentObj.paymentRequest.totalAmount) {
          paymentObj.status = 'PARTIALLY_REFUNDED';
        }
      }
      setPayment(data);
    });
  }, [paymentId]);

  useEffect(() => {
    if (auth?.authenticated) {
      refresh();
    }
  }, [auth, refresh]);

  useEffect(() => {
    if (payment) {
      setStoreId(payment.paymentRequest.storeId);
    }
  }, [auth, refresh, payment]);
  const setCollapse = () => {
    setActivity(payment.paymentActivity.slice(0, 5));
  };

  const setViewAll = () => {
    setActivity(payment.paymentActivity);
  };

  return (
    // Title and BreadCrumbs
    <Layout
      title="Payment Activity Details"
      breadCrumbs={[{ title: 'Payment Activity', to: searchParams.get('src') ? '/Reports/payment-requests' : '/Payments/activity'}, { title: 'Payment Activity Details' }]}
      classProps="standard-width-with-sidebar"
    >
      {payment ? (
        <>
          {PAYMENT_STATUS.EXPIRED.statuses.includes(payment.status) ? (
            <div className="m-btm-16">
              <div
                style={{
                  background: '#F9E0E0',
                  color: '#D93B3B',
                  padding: '4px 0 2px 0',
                }}
                className="rem-pad-width align-center align-vert-middle h-36 fs-13 fw-500 w-590 border-r4-red m-btm-16"
              >
                This payment request has expired. Please create a new request and send again.
              </div>
            </div>
          ) : null}
          {/* Flex Row to create the two columns */}
          <div className="maw-590 flex-row-spacebetween-nowrap">
            <div className="flex-col-left">
              <div className="h-50">
                <div className="flex-row-nospacebetween-nowrap margin-0">
                  {/* Total price of payment */}
                  <p className="fs-16 fw-600 margin-0 m-right-8">{`$${payment.paymentRequest.totalAmount}`}</p>

                  {/* Status Bubble */}
                  <Bubble {...PAYMENT_STATUS_LABEL[payment.status]} />
                </div>

                {/* Expires at */}
                {payment.paymentRequest.expireAt === null ? (
                  <p className="fs-12 fw-400 m-top-4 align-left" />
                ) : (
                  <p className="fs-12 fw-400 m-top-4 align-left">
                    Expires after {formatDate(payment.paymentRequest.expireAt, DATE_FORMAT.LONG_DATE)}
                  </p>
                )}
              </div>

              {/* Payment Request Detail */}
              <div className="flex-col-left w-282" style={{ marginBottom: '20px' }}>
                <p className="align-left fs-16 fw-600 m-btm-8">Payment Request Details</p>
                {/* Display each product in payment */}
                {payment.paymentRequestProducts.map(({ imageUrl, description }) => (
                  <PaymentProduct url={imageUrl} description={description} />
                ))}

                {/* Sent to */}
                <div className="m-top-18">
                  <p className="fs-12 text-light-gray fw-400 margin-0">Sent to</p>
                </div>
                <div>
                  {/* Link to client */}
                  <StyledLink
                    className="fs-12 fw-500 margin-0"
                    to={`/Clients?client=${payment.paymentRequest.clientId}`}
                  >
                    {payment.paymentRequest.name}
                  </StyledLink>
                </div>

                {/* Created by */}
                <div>
                  <p className="fs-12 text-light-gray fw-400 margin-0">Created by</p>
                </div>
                <div>
                  {/* Link to associate */}
                  <StyledLink
                    className="fs-12 fw-500 margin-0"
                    to={`/Associates/associate-details/${payment.paymentRequest.associateId}`}
                  >
                    {payment.paymentRequest.associateName}
                  </StyledLink>
                </div>

                {/* Store */}
                <div>
                  <p className="fs-12 text-light-gray fw-400 margin-0">Store</p>
                </div>
                <div>
                  {/* Link to store */}
                  <StyledLink
                    className="fs-12 fw-500 margin-0"
                    to={`/Stores/store-dashboard/${payment.paymentRequest.storeId}`}
                  >
                    {payment.paymentRequest.storeName}
                  </StyledLink>
                </div>
              </div>

              {/* Payment Method */}
              <div className="flex-col-left">
                <div className="align-left w-282">
                  <p className="fs-16 m-btm-0 m-top-8 fw-600">Payment Method</p>

                  {/* Display the payment method information */}
                  {payment.statusChanges
                    .filter(change => change.status !== 'REFUNDED')
                    .map(data => {
                      return <PaymentMethod data={data} payment={payment} />;
                    })}
                </div>
              </div>

              {/* Cancel Payment Link */}
              {PAYMENT_STATUS.UNPAID.statuses.includes(payment.status) ? (
                <div>
                  <p className="fs-12 fw-500 red m-top-4" onClick={() => setCancelDialogItem({ id: paymentId })}>
                    Cancel Invoice
                  </p>
                </div>
              ) : null}

              {payment.refunds.map(data => {
                return <PaymentRefund {...data} transactions={payment.transactions} />;
              })}

              {/* Refund payment Link */}
              {PAYMENT_STATUS.PAID.statuses.includes(payment.status) &&
              Number(refundSum) < Number(payment.paymentRequest.totalAmount) ? (
                <div>
                  <span
                    className="fs-12 fw-500 red m-top-0 pointer"
                    onClick={() =>
                      setRefundDialogItem({
                        id: paymentId,
                        amount: payment.paymentRequest.totalAmount,
                      })
                    }
                  >
                    Refund Payment
                  </span>
                </div>
                ) : null}
            </div>

            {/* Recent Activity */}
            <div className="flex-col-left m-top-0">
              <div className="h-50">
                <div className="flex-row-nospacebetween-nowrap margin-0">
                  {PAYMENT_STATUS.UNPAID.statuses.includes(payment.status) ? (
                    <>
                      {/* Send Reminder Button */}
                      <button
                        className="cancel-button margin-0"
                        style={{ width: 'fit-content' }}
                        onClick={() => setSendReminderDialogItem({ id: paymentId })}
                      >
                        Send Reminder
                      </button>

                      {/* Mark as Paid Button */}
                      <button
                        className="save-button  margin-0 m-left-8"
                        style={{ width: 'fit-content' }}
                        onClick={() => setMarkAsPaidDialogItem({ id: paymentId })}
                      >
                        Mark as Paid
                      </button>
                    </>
                  ) : null}
                </div>
              </div>
              {/* Recent Activity */}
              <p className="fs-16 fw-600 m-btm-8">Recent Activity</p>

              {/* Display Each Activity */}
              {payment.refunds.map(({ totalAmount, returnedAt }) => {
                return <ActivityItem status="REFUNDED" time={returnedAt} amount={totalAmount} />;
              })}
              {payment.paymentActivity
                .filter(({ status }) => status !== 'REFUNDED')
                .map(({ status, time }) => (
                  <ActivityItem status={status} time={time} amount={payment.paymentRequest.amount} />
                ))}
              {(() => {
                if (payment.paymentActivity.length > 5) {
                  return (
                    <div className="flex-col-left">
                      <p
                        style={{
                          fontSize: '15px',
                          fontWeight: '500',
                          color: '#007bd0',
                          width: '70px',
                          cursor: 'pointer',
                          display: 'inline',
                        }}
                        onClick={() => {
                          setViewAll();
                        }}
                      >
                        View All
                      </p>
                      <p
                        style={{
                          fontSize: '15px',
                          fontWeight: '500',
                          color: '#007bd0',
                          width: '70px',
                          cursor: 'pointer',
                          display: 'inline',
                        }}
                        onClick={() => {
                          setCollapse();
                        }}
                      >
                        Collapse
                      </p>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
          {/* Sidebar */}
          <SecondaryPanel title={['Payments']} current="Payment Activity" />

          {/* Send Reminder Popup */}
          {sendReminderDialogItem ? (
            <SendReminder
              id={sendReminderDialogItem.id}
              onConfirm={reason =>
                sendReminder(
                  sendReminderDialogItem.id,
                  reason,
                  auth.userId,
                  auth.merchantId,
                  payment.paymentRequest.clientId,
                  fullName,
                  payment.paymentRequest.storeId,
                )
                  .then(refresh)
                  .finally(() => setSendReminderDialogItem(null))
              }
              onCancel={() => setSendReminderDialogItem(null)}
            />
          ) : null}

          {/* Mark as paid Popup */}
          {markAsPaidDialogItem ? (
            <MarkAsPaid
              onConfirm={message =>
                markAsPaid(markAsPaidDialogItem.id, message)
                  .then(() => setMarkAsPaidDialogItem(null))
                  .then(refresh)
              }
              onCancel={() => setMarkAsPaidDialogItem(null)}
            />
          ) : null}

          {/* Cancel Payment Popup */}
          {cancelDialogItem ? (
            <CancelPaymentRequest
              onConfirm={reason =>
                cancelPayment(cancelDialogItem.id, reason)
                  .then(() => setCancelDialogItem(null))
                  .then(refresh)
              }
              onCancel={() => setCancelDialogItem(null)}
            />
          ) : null}

          {/* Refund Payment Popup */}
          {refundDialogItem ? (
            <RefundPayment
              amount={payment.paymentRequest.totalAmount}
              refundSum={refundSum}
              onConfirm={confirmationObj =>
                refundPayment(refundDialogItem.id, {
                  amount: confirmationObj.amount,
                  reason: confirmationObj.reason,
                })
                  .then(() => setRefundDialogItem(null))
                  .then(refresh)
              }
              onCancel={() => setRefundDialogItem(null)}
            />
          ) : null}
        </>
      ) : null}
    </Layout>
  );
};

PaymentDetails.propTypes = {
  sortColumn: string,
  ascendingColumn: bool,
};

const mapStateToProps = state => ({
  auth: state.auth,
  fullName: associateNameFormat(state.auth.firstName, state.auth.lastName),
});

export default connect(mapStateToProps)(PaymentDetails);
