import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'antd';
import { getRequest } from '../../../../core/apiRequests';
import { useOnce } from '../../../../hooks/useOnce'

const ChatCollectionModal = ({
  className,
  onClose,
  clientId,
  merchantId,
  onSave,
  selectedCollections: selectedCollectionsProp,
}) => {
  const [collections, setCollection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCollections, setSelectedCollections] = useState([...selectedCollectionsProp]);

  const getClientCollections = useCallback(() => {
    setLoading(true);
    const reqObj = {
      params: ['clients', merchantId, clientId, 'getClientCollections'],
      query: { limit: 999 },
    };
    getRequest(reqObj)
      .then(res => {
        setCollection(res.collections);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, [clientId, merchantId]);

  const { call } = useOnce(getClientCollections)
  useEffect(() => {
    call();
  }, [clientId]);

  const handleSelectedCollection = useCallback(
    value => {
      if (selectedCollections.find(item => item?.clientCollectionId == value)) {
        setSelectedCollections(selectedCollections.filter(item => item.clientCollectionId != value));
      } else {
        const collection = collections.find(item => item.clientCollectionId == value);
        setSelectedCollections([...selectedCollections, collection]);
      }
    },
    [selectedCollections, collections],
  );

  const onCloseModal = useCallback(() => {
    setSelectedCollections([]);
    onClose();
  }, []);

  const handleSave = useCallback(() => {
    onSave(selectedCollections);
    onClose();
  }, [selectedCollections]);

  return (
    <div
      className={className}
      style={{
        height: '500px',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <span className="chat-templates-modal-header">Collections</span>
        <div
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
          }}
          onClick={onClose}
        >
          <FontAwesomeIcon className="fs-16 pointer" icon={['fa', 'x']} />
        </div>
        {loading ? (
          <div className="chat-product-modal-list">
            {Array(3)
              .fill('')
              .map((_, index) => (
                <Skeleton.Input
                  key={`sk-${index}`}
                  active
                  style={{
                    width: '100%',
                    height: '50px',
                    marginBottom: '10px',
                  }}
                />
              ))}
          </div>
        ) : (
          <div className="chat-collection-modal-content">
            {collections.length > 0 ? (
              <div className="chat-product-modal-list scrollbar-y">
                {collections.map(collection => (
                  <ProductItem
                    selectedCollections={selectedCollections}
                    key={collection.clientCollectionId}
                    onSelect={handleSelectedCollection}
                    {...collection}
                  />
                ))}
              </div>
            ) : (
              <p>No collections found</p>
            )}
            {collections.length > 0 && (
              <div className="maw-600 w-100-P m-top-15 flex-col-center ">
                <button onClick={handleSave} className="save-button-v2 m-btm-8">
                  Add to Message
                </button>
                <button onClick={onCloseModal} className="cancel-button-v2 m-btm-8" style={{ border: 'none' }}>
                  Cancel
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default ChatCollectionModal;

const ProductItem = ({ onSelect, image, clientCollectionId, name, selectedCollections }) => {
  return (
    <div className="chat-product-modal-item" onClick={() => onSelect(clientCollectionId)}>
      <input
        type="checkbox"
        name={clientCollectionId}
        className="app-checkbox__input"
        onChange={e => onSelect(e.target.name)}
        checked={selectedCollections.find(item => item?.clientCollectionId == clientCollectionId)}
      />
      <div className="chat-product-modal-item-img">
        <img src={image || 'https://dashboard-v2-images.s3.amazonaws.com/image-placeholder@2x.png'} alt="img" />
      </div>
      <div className="chat-product-modal-item-content">
        <div>{name}</div>
      </div>
    </div>
  );
};
