import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StandardLayout from '../../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../../Layout/SecondaryPanel';
import AutoReminderSettings from '../../../../../Layout/AutoReminders/AutoReminderSettings';
import ViewAllTemplates from '../../../../../Layout/AutoWhatsAppTemplate/ViewAllTemplates';

const TagAutoReminder = props => {
  const [showViewAll, toggleViewAll] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const history = useHistory();
  const { listItemId } = props.match.params;

  const setRedirectBack = () => {
    history.push(`/Configuration/tags/edit-tag/${listItemId}`);
  };

  const toggleViewAllHandler = () => {
    toggleViewAll(!showViewAll);
  };

  const selectHandler = id => {
    setSelectedTemplate(id);
  };

  const reminderNameHandler = () => {
    if (props.autoRemMsgs.reminderName.length > 20) {
      return `${props.autoRemMsgs.reminderName.substring(0, 19)}...`;
    }
    return props.autoRemMsgs.reminderName;
  };

  return (
    <StandardLayout
      title={`${props.autoRemMsgs.reminderAddOrEdit} Auto Reminder`}
      description="Create an auto reminder linked to this tag"
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Tags', to: '/Configuration/tags' },
        { title: 'Edit Tag', to: `/Configuration/tags/edit-tag/${listItemId}` },
        {
          title:
            props.autoRemMsgs.reminderAddOrEdit === 'Add'
              ? `${props.autoRemMsgs.reminderAddOrEdit} Auto Reminder`
              : `${props.autoRemMsgs.reminderAddOrEdit} "${reminderNameHandler()}"`,
        },
      ]}
      classProps="standard-width-with-sidebar"
    >
      <AutoReminderSettings
        setRedirectBack={setRedirectBack}
        cancelRoute={`/Configuration/tags/edit-tag/${listItemId}`}
        descriptionText="When do you want the associate to be reminded?"
        popConfirmDesc="This will remove the reminder from this tag"
        editRoute={`/Configuration/tags/edit-tag/${listItemId}`}
        type="tags"
        match={props.match}
        toggleViewAllHandler={toggleViewAllHandler}
      />

      <SecondaryPanel title={['Automation']} current="Tags" />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  autoRemMsgs: state.autoRemMsgs,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TagAutoReminder);
