import React from 'react';
import Button from '../../../../Layout/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FlaggedLimitIcon from '../../../../Layout/FlaggedLimitIcon';

const FlaggedAddTriggerBtn = ({ clickHandler, flags }) => {
  let salesTriggerEnabled = flags?.sales_trigger?.enabled;
  
  const click = !salesTriggerEnabled ? () => {} : clickHandler;

  return (
    <div className="flex-row-nospacebetween-nowrap">
      <Button transparentDisabled={!salesTriggerEnabled} classname="transparentButton" onClick={() => click()}>
        <FontAwesomeIcon icon={['far', 'bolt']} className="fs-16 p-btm-3 m-right-8" size="1x" />
        Add Trigger
      </Button>
      {!salesTriggerEnabled && (
        <FlaggedLimitIcon message="You have reached your plan limit. Please contact your CSM to upgrade and add more sales triggers" />
      )}
    </div>
  );
};
export default FlaggedAddTriggerBtn;
