import React from 'react';
import { bool, func, object} from 'prop-types';

import Errors from './Errors';

export const FIELD_STYLES = {
  width: 506,
  maxWidth: '100%',
  minWidth: 'auto',
};
export const HEADING_CLASSES = 'fs-16 fw-600 m-top-0 m-btm-8';

const Wizard = ({
  data,
  setData,
  onSubmit,
  showAll,
  steps,
  handleClick,
  activeStepIndex,
  previous,
  next,
  handleStepChange,
  wizardForm,
  getFields,
  updateValues,
  disableContinue,
  errors,
  setErrors,
  bankAccountStores,
}) => {
  // helper to show step-level errors
  const pushError = error => {
    setErrors(errorList => [...errorList, error].filter((value, index, self) => self.indexOf(value) === index));
  };

  const validateField = field => {
    if (Boolean(field.optional) && !field.value) {
      return false;
    }

    if (!field.value) {
      pushError(`${field.id} is required`);
      return true;
    }

    if (field.inputMode === 'numeric' && Number.isNaN(Number(field.value, 10))) {
      pushError(`${field.id} must be a number`);
      return true;
    }

    if (
      field.minLength &&
      !Number.isNaN(Number(field.minLength, 10)) &&
      `${field.value || ''}`.length < Number(field.minLength, 10)
    ) {
      pushError(`${field.id} length must be lower than ${field.minLength}`);
      return true;
    }

    if (
      field.maxLength &&
      !Number.isNaN(Number(field.maxLength, 10)) &&
      `${field.value || ''}`.length > Number(field.maxLength, 10)
    ) {
      pushError(`${field.id} length must not be greater than ${field.maxLength} numbers`);
      return true;
    }

    return false;
  };

  // helper to validate active fields
  const validateFields = activeFields => {
    const errorFields = activeFields.filter(el => {
      return validateField(el);
    });

    return errorFields;
  };

  // form submission
  const isSubmitButton = showAll || activeStepIndex === 1;

  // get active fields, for validation & updating data
  const getActiveFields = () => {
    const fields = getFields();
    const activeFields = fields.filter(({ active }) => active);

    return activeFields;
  };

  // main handler for next/submit
  const handleSubmit = evt => {
    evt.preventDefault();

    handleStepChange();

    // validation
    const activeFields = getActiveFields();
    const errorFields = validateFields(activeFields);
    const isValid = !errorFields.length;

    // update our main `data` object with errors
    setData(d =>
      errorFields.reduce(
        (obj, { id }) => ({
          ...obj,
          [id]: { ...d[id], hasError: true },
        }),
        d,
      ),
    );

    // don't submit if invalid
    if (!isValid) {
      return;
    }

    if (isSubmitButton) {
      onSubmit(data, evt.target);
    } else if (activeStepIndex === 2) {
      handleClick('', false);
      handleStepChange();
    } else next();
  };

  return (
    <>
      <Errors errors={errors} />

      <form
        id="wizard-form-multi"
        onSubmit={handleSubmit}
        ref={wizardForm}
        style={{ marginBottom: '150px', position: 'relative' }}
      >
        {Object.keys(steps).map((key, index) => {
          const { crumb, title, Component } = steps[key];
          const active = showAll || activeStepIndex === index;
          return (
            <section
              className="pos-rel"
              key={crumb}
              /*
               * BlueSnap javascript requires that all inputs are found in the DOM,
               * so we hide them instead
               */
              style={{
                ...FIELD_STYLES,
                display: active ? 'block' : 'none',
              }}
            >
              <h2 className={HEADING_CLASSES}>{title}</h2>
              <Component
                handleClick={handleClick}
                steps={Object.keys(steps)}
                data={data}
                setData={setData}
                updateValues={updateValues}
                active={active}
                setDisableContinue={disabled => (active ? setDisableContinue(disabled) : null)}
                bankAccountStores={bankAccountStores}
              />
            </section>
          );
        })}

        <div className="buttons align-center pos-abs m-top-50" style={FIELD_STYLES}>
          <button type="submit" className="standardButton darkBlueButton m-btm-8" disabled={disableContinue}>
            {isSubmitButton ? 'Complete' : 'Continue'}
          </button>
          {activeStepIndex > 0 && (
            <button
              type="button"
              onClick={() => {
                handleStepChange();
                previous();
                if (activeStepIndex === 2) {
                  handleClick('', false);
                }
              }}
              className="cancel-button-v2 m-btm-10"
            >
              Go Back
            </button>
          )}
        </div>
      </form>
    </>
  );
};

Wizard.propTypes = {
  initialValues: object,
  onSubmit: func,
  showAll: bool,
  steps: object,
};

Wizard.defaultProps = {
  initialValues: {},
};
export default Wizard;
