import React from 'react';
import CheckboxDesc from '../../../../Layout/CheckboxDesc';
import DescriptionBox from '../../../../Layout/DescriptionBox';
import '../../stores.css';
import { setAfterhoursMsg, setDayCheck, setTimes } from '../../../../../reducers/stores';
import DatePicker from 'react-datepicker';
import { startOfDay, endOfDay } from 'date-fns';

const StoreHours = props => {
  const startOfDate = startOfDay(new Date());
  const endOfDate = endOfDay(new Date());
  const checkboxState = (dayObj, timeObj, value) => {
    setDayCheck(dayObj);
    if (value) {
      setTimes({ ...props.stores.times, timeObj });
    } else {
      Object.keys(timeObj).forEach(key => {
        timeObj[key] = '';
      });
      setTimes({ ...props.stores.times, ...timeObj });
    }
  };

  // ('');
  return (
    <div>
      <DescriptionBox
        title={
          <CheckboxDesc
            text="Sunday"
            onclick={() => {
              checkboxState(
                { sunday: !props.stores.day_checkmark.sunday },
                { sun_startTime: null, sun_endTime: null },
                !props.stores.day_checkmark.sunday,
              );
            }}
            checked={props.stores.day_checkmark.sunday}
          />
        }
        input={
          props.stores.day_checkmark.sunday == true ? (
            <div className="flex-col-right mq-flex-align-left-at-1200 msgSetTitle_DescInput">
              <div className="flex-row-nospacebetween-nowrap ">
                <DatePicker
                  selected={props.stores.times.sun_startTime}
                  onChange={time =>
                    setTimes({
                      ...props.stores.times,
                      sun_startTime: time,
                    })
                  }
                  className="maw-96-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="9:00 AM"
                />
                <DatePicker
                  selected={props.stores.times.sun_endTime}
                  onChange={time =>
                    setTimes({
                      ...props.stores.times,
                      sun_endTime: time,
                    })
                  }
                  className="maw-96-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="5:00 PM"
                  minTime={props.stores.times.sun_startTime || startOfDate}
                  maxTime={endOfDate}
                />
              </div>
            </div>
          ) : (
            <div className="w-267 gray fs-13 rem-pad-width  m-top-20 store_closed_align">
              <p className="m-top-28">Store Closed</p>
            </div>
          )
        }
      />
      <DescriptionBox
        title={
          <CheckboxDesc
            text="Monday"
            onclick={() => {
              checkboxState(
                { monday: !props.stores.day_checkmark.monday },
                { mon_startTime: null, mon_endTime: null },
                !props.stores.day_checkmark.monday,
              );
            }}
            checked={props.stores.day_checkmark.monday}
          />
        }
        input={
          props.stores.day_checkmark.monday ? (
            <div className="flex-col-right mq-flex-align-left-at-1200 msgSetTitle_DescInput">
              <div className="flex-row-nospacebetween-nowrap">
                <DatePicker
                  selected={props.stores.times.mon_startTime}
                  onChange={time =>
                    setTimes({
                      ...props.stores.times,
                      mon_startTime: time,
                    })
                  }
                  className="maw-96-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="9:00 AM"
                />
                <DatePicker
                  selected={props.stores.times.mon_endTime}
                  onChange={time =>
                    setTimes({
                      ...props.stores.times,
                      mon_endTime: time,
                    })
                  }
                  className="maw-96-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="5:00 PM"
                  minTime={props.stores.times.mon_startTime || startOfDate}
                  maxTime={endOfDate}
                />
              </div>
            </div>
          ) : (
            <div className="w-267 gray fs-13 rem-pad-width  m-top-20 store_closed_align">
              <p className="m-top-28">Store Closed</p>
            </div>
          )
        }
      />
      <DescriptionBox
        title={
          <CheckboxDesc
            text="Tuesday"
            onclick={() => {
              checkboxState(
                { tuesday: !props.stores.day_checkmark.tuesday },
                { tue_startTime: null, tue_endTime: null },
                !props.stores.day_checkmark.tuesday,
              );
            }}
            checked={props.stores.day_checkmark.tuesday}
          />
        }
        input={
          props.stores.day_checkmark.tuesday ? (
            <div className="flex-col-right mq-flex-align-left-at-1200 msgSetTitle_DescInput">
              <div className="flex-row-nospacebetween-nowrap">
                <DatePicker
                  selected={props.stores.times.tue_startTime}
                  onChange={time =>
                    setTimes({
                      ...props.stores.times,
                      tue_startTime: time,
                    })
                  }
                  className="maw-96-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="9:00 AM"
                />
                <DatePicker
                  selected={props.stores.times.tue_endTime}
                  onChange={time =>
                    setTimes({
                      ...props.stores.times,
                      tue_endTime: time,
                    })
                  }
                  className="maw-96-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="5:00 PM"
                  minTime={props.stores.times.tue_startTime || startOfDate}
                  maxTime={endOfDate}
                />
              </div>
            </div>
          ) : (
            <div className="w-267 gray fs-13 rem-pad-width  m-top-20 store_closed_align">
              <p className="m-top-28">Store Closed</p>
            </div>
          )
        }
      />
      <DescriptionBox
        title={
          <CheckboxDesc
            text="Wednesday"
            onclick={() => {
              checkboxState(
                { wednesday: !props.stores.day_checkmark.wednesday },
                { wed_startTime: null, wed_endTime: null },
                !props.stores.day_checkmark.wednesday,
              );
            }}
            checked={props.stores.day_checkmark.wednesday}
          />
        }
        input={
          props.stores.day_checkmark.wednesday ? (
            <div className="flex-col-right mq-flex-align-left-at-1200 msgSetTitle_DescInput">
              <div className="flex-row-nospacebetween-nowrap ">
                <DatePicker
                  selected={props.stores.times.wed_startTime}
                  onChange={time =>
                    setTimes({
                      ...props.stores.times,
                      wed_startTime: time,
                    })
                  }
                  className="maw-96-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="9:00 AM"
                />
                <DatePicker
                  selected={props.stores.times.wed_endTime}
                  onChange={time =>
                    setTimes({
                      ...props.stores.times,
                      wed_endTime: time,
                    })
                  }
                  className="maw-96-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="5:00 PM"
                  minTime={props.stores.times.wed_startTime || startOfDate}
                  maxTime={endOfDate}
                />
              </div>
            </div>
          ) : (
            <div className="w-267 gray fs-13 rem-pad-width  m-top-20 store_closed_align">
              <p className="m-top-28">Store Closed</p>
            </div>
          )
        }
      />
      <DescriptionBox
        title={
          <CheckboxDesc
            text="Thursday"
            onclick={() => {
              checkboxState(
                { thursday: !props.stores.day_checkmark.thursday },
                { thu_startTime: null, thu_endTime: null },
                !props.stores.day_checkmark.thursday,
              );
            }}
            checked={props.stores.day_checkmark.thursday}
          />
        }
        input={
          props.stores.day_checkmark.thursday ? (
            <div className="flex-col-right mq-flex-align-left-at-1200 msgSetTitle_DescInput">
              <div className="flex-row-nospacebetween-nowrap ">
                <DatePicker
                  selected={props.stores.times.thu_startTime}
                  onChange={time =>
                    setTimes({
                      ...props.stores.times,
                      thu_startTime: time,
                    })
                  }
                  className="maw-96-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="9:00 AM"
                />
                <DatePicker
                  selected={props.stores.times.thu_endTime}
                  onChange={time =>
                    setTimes({
                      ...props.stores.times,
                      thu_endTime: time,
                    })
                  }
                  className="maw-96-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="5:00 PM"
                  minTime={props.stores.times.thu_startTime || startOfDate}
                  maxTime={endOfDate}
                />
              </div>
            </div>
          ) : (
            <div className="w-267 gray fs-13 rem-pad-width  m-top-20 store_closed_align">
              <p className="m-top-28">Store Closed</p>
            </div>
          )
        }
      />
      <DescriptionBox
        title={
          <CheckboxDesc
            text="Friday"
            onclick={() => {
              checkboxState(
                { friday: !props.stores.day_checkmark.friday },
                { fri_startTime: null, fri_endTime: null },
                !props.stores.day_checkmark.friday,
              );
            }}
            checked={props.stores.day_checkmark.friday}
          />
        }
        input={
          props.stores.day_checkmark.friday ? (
            <div className="flex-col-right mq-flex-align-left-at-1200 msgSetTitle_DescInput">
              <div className="flex-row-nospacebetween-nowrap ">
                <DatePicker
                  selected={props.stores.times.fri_startTime}
                  onChange={time =>
                    setTimes({
                      ...props.stores.times,
                      fri_startTime: time,
                    })
                  }
                  className="maw-96-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="9:00 AM"
                />
                <DatePicker
                  selected={props.stores.times.fri_endTime}
                  onChange={time =>
                    setTimes({
                      ...props.stores.times,
                      fri_endTime: time,
                    })
                  }
                  className="maw-96-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="5:00 PM"
                  minTime={props.stores.times.fri_startTime || startOfDate}
                  maxTime={endOfDate}
                />
              </div>
            </div>
          ) : (
            <div className="w-267 gray fs-13 rem-pad-width  m-top-20 store_closed_align">
              <p className="m-top-28">Store Closed</p>
            </div>
          )
        }
      />
      <DescriptionBox
        title={
          <CheckboxDesc
            text="Saturday"
            onclick={() => {
              checkboxState(
                { saturday: !props.stores.day_checkmark.saturday },
                { sat_startTime: null, sat_endTime: null },
                !props.stores.day_checkmark.saturday,
              );
            }}
            checked={props.stores.day_checkmark.saturday}
          />
        }
        input={
          props.stores.day_checkmark.saturday ? (
            <div className="flex-col-right mq-flex-align-left-at-1200 msgSetTitle_DescInput">
              <div className="flex-row-nospacebetween-nowrap ">
                <DatePicker
                  selected={props.stores.times.sat_startTime}
                  onChange={time =>
                    setTimes({
                      ...props.stores.times,
                      sat_startTime: time,
                    })
                  }
                  className="maw-96-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="9:00 AM"
                />
                <DatePicker
                  selected={props.stores.times.sat_endTime}
                  onChange={time =>
                    setTimes({
                      ...props.stores.times,
                      sat_endTime: time,
                    })
                  }
                  className="maw-96-P common_inputStyle inputHeight rem-pad-width m-btm-12"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="5:00 PM"
                  minTime={props.stores.times.sat_startTime || startOfDate}
                  maxTime={endOfDate}
                />
              </div>
            </div>
          ) : (
            <div className="w-267 gray fs-13 rem-pad-width  m-top-20 store_closed_align">
              <p className="m-top-28">Store Closed</p>
            </div>
          )
        }
      />
      <DescriptionBox
        title="After Hours Message"
        description="When clients contact you outside your store hours, this is the response they’ll receive. We recommend keeping it informative and friendly."
        input={
          <textarea
            className="msgSettTextarea focus-input-outline-blue"
            value={props.stores.after_hours_msg || ''}
            onChange={event => setAfterhoursMsg(event.target.value)}
          />
        }
      />
    </div>
  );
};
export default StoreHours;
