import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import { connect } from 'react-redux';
import {
  mapTableStateToProps,
  setTablePaginationPageNum,
  setTablePaginationPageSize,
  setTablePaginationSearchText,
} from '../../../core/actions';
import PaginationV2 from '../../CustomizedAntDesign/PaginationV2';
import SortButton from '../SortButton';
import Pagination from './Pagination';
import './table.css';
import PaginationV3 from '../../CustomizedAntDesign/PaginationV3';

const DisabledTooltip = ({ children, disabled, disabledText }) => {
  return !disabled ? (
    children
  ) : (
    <Tooltip title={disabledText} color="#454545" placement="bottomLeft" overlayInnerStyle={{ borderRadius: '10px' }}>
      {children}
    </Tooltip>
  );
};

const Table = props => {
  const firstIncrement = useMemo(() => props.firstInc || 'View 25', [props.firstInc]);

  const PagePerSlide = () => {
    setTablePaginationPageSize(25, props.tableName);
  };

  const allClicked = () => {
    setTablePaginationPageSize(99999999, props.tableName);
  };

  // name = column string in parameters
  // make a copy to sort the temp data. React doesn't like manipulation of state
  // greater than less than for strings. a-b for numbers

  const handleSearchChange = e => {
    if (e.key === 'Enter') {
      setTablePaginationSearchText(e.target.value, props.tableName);
    }
  };

  const tableRowBuilder = (item, rowKey) => {
    return props.tableHeaders
      .filter(flt_item => !flt_item.hidden)
      .map((column, colKey) => {
        if (column.render)
          return (
            <td
              key={`${rowKey}${colKey}`}
              className={
                item.className || column.rowClassName ? `${column.rowClassName} ${item.className}` : 'fs-12 p-btm-16 '
              }
              style={column.rowStyle}
            >
              {column.render(item[column.keyName], item)}
            </td>
          );

        return (
          <td
            key={`${rowKey}${colKey}`}
            className={item.className || column.rowClassName || 'fs-12 p-btm-16 '}
            style={column.rowStyle}
          >
            {item[column.keyName]}
          </td>
        );
      });
  };

  return (
    <div
      className={
        // component has ability to override existing class.
        props.tableContainerClass
          ? `table-body-content rem-pad-width ${props.tableContainerClass}`
          : 'table-body-content rem-pad-width'
      }
      style={{ height: 'auto', overflowY: 'visible', ...props.styleProps }}
    >
      {props.searchable || props.optionalButton ? (
        <div className="table-body-header">
          {props.searchable ? (
            <input
              id="SearchBar"
              className="body-head-search fs-13 focus-input-outline-blue m-top-3"
              type="text"
              placeholder="Search..."
              onKeyDown={handleSearchChange}
            />
          ) : (
            <div />
          )}
          {props.optionalButton}
        </div>
      ) : null}
      <div
        className={props.tableClassName ? `table m-top-50 ${props.tableClassName}` : 'table m-top-50'}
        // id={scroll}
        style={props.tableStyles}
      >
        <table className={`real-table rem-pad-width ${props.actualTableClassName ?? ''}`}>
          <thead className="table-head">
            <tr>
              {props.tableHeaders
                .filter(item => !item.hidden)
                .map((col, i) => {
                  return (
                    <th
                      key={i}
                      className={`pos-rel p-btm-20 fs-12 border-btm-l-gray ${col.headerClassName}`}
                      style={{ cursor: 'default', ...col.style }}
                    >
                      {col.sortable ? (
                        <SortButton tableName={props.tableName} columnTitle={col.title} columnName={col.keyName} />
                      ) : (
                        ('', (<span>{col.title}</span>))
                      )}
                    </th>
                  );
                })}
            </tr>
            {props.selectAllDisplay ? (
              <tr>
                <th colSpan="6" scope="colgroup" style={{ padding: '0', backgroundColor: '#F2F3F6' }}>
                  {/* <div className="add-edit-wrap" style={{ width: '1200' }}> */}
                  <div style={{ width: '1200' }}>
                    {/* {props.grabAllClients ? <p className="align-right fs-14 fw-400" style={{ width: '50%' }}> */}
                    {props.grabAllClients ? (
                      <p className="align-right fs-14 fw-400" style={{ width: '60%' }}>
                        All <span className="fw-600">{props.selectAllCount}</span> clients in all pages are selected.
                      </p>
                    ) : (
                      <p className="align-right fs-14 fw-400" style={{ width: '60%' }}>
                        All <span className="fw-600">{props.selectAllCount}</span> clients in this page are selected.
                      </p>
                    )}
                    {/* <button
                      className="align-left transparent border-none col-p-l-25 fs-13 fw-600"
                      style={{ width: '50%', color: '#1769AF' }}
                      onClick={() => props.setGrabAllClients(!props.grabAllClients)}
                      type="button"
                    >
                      {props.grabAllClients ? 'Clear selection' : 'Select all results'}
                    </button> */}
                  </div>
                </th>
              </tr>
            ) : null}
          </thead>
          <tbody className="table-row">
            {/* {dataZero ? <tr className="body-row flex-col-center"> No Data Available </tr> : null} */}
            {props?.data?.map(function (item, key) {
              return (
                <DisabledTooltip key={key} disabled={item.disabled} disabledText={props.disabledText}>
                  <tr
                    className={
                      props.noPointer || item.disabled
                        ? 'body-row border-btm-l-gray'
                        : 'body-row pointer border-btm-l-gray'
                    }
                    style={item.disabled ? { opacity: '0.5', background: '#F2F3F6' } : { ...item.style }}
                    onMouseLeave={e => (item.onMouseLeave ? item.onMouseLeave(item, e) : null)}
                    onMouseOver={e => (item.onMouseOver ? item.onMouseOver(item, e) : null)}
                    onClick={e =>
                      props.onRowClick && !item.disabled
                        ? props.onRowClick(item, e)
                        : console.debug('No row click feature')
                    }
                    onFocus={e =>
                      props.onRowClick && !item.disabled
                        ? props.onRowClick(item, e)
                        : console.debug('No row click feature')
                    }
                  >
                    {tableRowBuilder(item, key)}
                  </tr>
                </DisabledTooltip>
              );
            })}
          </tbody>
        </table>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          marginTop: '16px',
        }}
      >
        {props.paginationV3 && (
          <PaginationV3
            page={props.page}
            pageSize={props.pageSize}
            total={props.rowCount}
            setPage={value => setTablePaginationPageNum(value, props.tableName)}
            setPageSize={value => setTablePaginationPageSize(value, props.tableName)}
            showLastPage={props.showLastPage}
          />
        )}
        {props.paginationV2 && (
          <PaginationV2
            page={props.page}
            pageSize={props.pageSize}
            total={props.rowCount}
            setPage={value => setTablePaginationPageNum(value, props.tableName)}
            setPageSize={value => setTablePaginationPageSize(value, props.tableName)}
          />
        )}
        {props.paginationExtraOptions}
      </div>

      {props.pagination && (
        <div
          className="pagination rem-pad-width"
          style={props.style && props.style.width ? { width: props.style.width } : {}}
        >
          <Pagination
            persistPagination={props.persistPagination}
            tableName={props.tableName}
            width={props.style && props.style.width ? props.style.width : {}}
          />
          <div className="pag-button">
            <button
              type="button"
              id={props.pageSize != '25' ? 'page-num' : 'page-num-active'}
              onClick={e => {
                PagePerSlide(e);
              }}
              className="pagenum pointer fs-12"
            >
              {firstIncrement}
            </button>
            <button
              type="button"
              id={props.pageSize == '25' ? 'page-num1' : 'page-num-active1'}
              onClick={() => {
                allClicked();
              }}
              className="pagenum pointer fs-12"
            >
              View All
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(
  mapTableStateToProps(({ tableName }) => tableName),
  {},
)(Table);
