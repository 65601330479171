import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { differenceInDays, endOfDay, formatISO, isBefore, startOfDay, subDays } from 'date-fns'
import Report from '../../../../componentsV2/Layout/Report';
import { addLoadingList, removeLoadingList, setLoadingDescription } from '../../../../reducers/notification';
import { getRequest } from '../../../../core/apiRequests';
import { filterList, filterMassMsgMeta } from './helpers/filterList';
import MassMessageExpandRow from './components/MassMessageExpandRow';
import moment from 'moment';
import useSearchParams from '../../../../hooks/useSearchParams';
import formatNumberAsCurrency from '../../../../utils/formatNumberAsCurrency';
import { useQuery } from 'react-query';
import { useReport } from '../../../../context/ReportContext';

const MassMessage = ({ auth }) => {

  const {setValue: setSearchParams, getAll} = useSearchParams();
  const params = getAll();

  const [filters, setFilters] = useState({
    store: [],
    associate: []
  });
  const [dateRange, setDateRange] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [pageSizeTable, setPageSizeTable] = useState(10);
  const [page, setPage] = useState(1);

  const { chatMessages, isLoadingChatMessage } = useReport();

  useEffect(() => {
    let paramsFilters = {
      store: params?.store ? params?.store : [],
      associate: params?.associate ? params?.associate : [],
    }
    setFilters(paramsFilters)
    if(params?.startDate && params?.endDate) {
      setDateRange([new Date(params.startDate), new Date(params.endDate)])
    } else {
      setDateRange([startOfDay(subDays(new Date(), 90)), endOfDay(new Date())])
    }
  }, [window.location.search]);

  const getDefaultPeriod = useMemo(() => {
    if(params?.startDate && params?.endDate) {
      if(differenceInDays(new Date(params.endDate), new Date(params.startDate)) === 90) {
        return '90days'
      } else {
        return `${format(new Date(params.startDate), 'MM/dd/yyyy')} - ${format(new Date(params.endDate), 'MM/dd/yyyy')}`
      }
    } else {
      return '90days'
    }
  }, [])

  const handleOnDateRangeChange = useCallback((dates) => {
    setSearchParams('startDate', formatISO(dates[0]))
    setSearchParams('endDate', formatISO(dates[1]))
  }, []);

  useEffect(() => {
    if(isLoadingChatMessage) {
      setLoadingDescription("Sit tight! We're compiling your report.")
      addLoadingList('massMsgReport')
    } else {
      removeLoadingList('massMsgReport')
      setLoadingDescription(null)
    }
  }, [isLoadingChatMessage])

  const reportData = useMemo(() => {
    if(!chatMessages) return [];
    const data = chatMessages?.reportData?.filter(item => item?.sales_transaction_id && !item?.sales_transaction_amount?.includes('-'))
    return data?.filter(item => item?.chat_message_id)
  }, [chatMessages])

  const { data: massMsgMeta } = useQuery(
    'massMsgMeta',
    async () => {
      addLoadingList('massMessageReportMeta')
      const reqObj = {
        params: ['report', 'massMsgMeta'],
      }
      const data = await getRequest(reqObj)
      removeLoadingList('massMessageReportMeta')
      let msgMetaMap = {};
      if(Array.isArray(data) && data.length > 0) {
        data.forEach(item => {
          msgMetaMap[item.message_batch_id] = item;
        });
        return msgMetaMap
      } else {
        return {}
      }
    },
    {
      initialData: {},
      refetchOnWindowFocus: false,
    }
  )

  const filteredData = useMemo(() => {
    setPage(1)
    return filterList({
      massMessages: reportData?.filter(item => item.message_batch_id) || [],
      filters,
      startDate: dateRange[0],
      endDate: dateRange[1]
    })
  }, [reportData, filters, dateRange]);

  const dataByMessageBatch = useMemo(() => {
    let data = [];
    filteredData.forEach(item => {
      const indexData = data.findIndex(dataItem => dataItem.message_batch_id === item.message_batch_id);
      if(indexData > -1) {
        data[indexData].purchases = data[indexData].purchases + (item.sales_transaction_id ? 1 : 0);
        data[indexData].related_sales = data[indexData].related_sales + (parseFloat(item.sales_transaction_amount) || 0);
        data[indexData].delivered = data[indexData].delivered + (item.chat_message_id ? 1 : 0);
        data[indexData].audience = data[indexData].audience + 1;
        data[indexData].transactions.push({
          sales_transaction_amount: item.sales_transaction_amount,
          sales_transaction_id: item.sales_transaction_id,
          sales_transaction_date: item.sales_transaction_date,
          client_id: item.client_id,
          client_name: item.client_name,
        })
      } else {
        let newData = {
          ...item,
          purchases: item.sales_transaction_id ? 1 : 0,
          related_sales: parseFloat(item.sales_transaction_amount) || 0,
          delivered: item.chat_message_id ? 1 : 0,
          audience: 1,
          transactions: [{
            sales_transaction_amount: item.sales_transaction_amount,
            sales_transaction_id: item.sales_transaction_id,
            sales_transaction_date: item.sales_transaction_date,
            client_id: item.client_id,
            client_name: item.client_name,
          }]
        }
        delete newData.client_id;
        delete newData.client_name;
        delete newData.sales_transaction_amount;
        delete newData.sales_transaction_id;
        delete newData.sales_transaction_date;

        data.push(newData)
      }
    });
    return data;
  }, [filteredData])

  const dataBySales = useMemo(() => {
    let data = [];
    filteredData.forEach(item => {
      if(!data.find(dataItem => dataItem.sales_transaction_id === item.sales_transaction_id)) {
        data.push(item);
    }});
    return data;
  }, [filteredData])

  const handleFilterChange = useCallback((value) => {
    let updatedFilters = {
      deliveryMethod: [],
      store: [],
      associate: []
    };
    value.forEach(item => {
      if (item[0] && item[1]) {
        updatedFilters[item[0]] = [...updatedFilters[item[0]], item[1]];
      } else {
        updatedFilters[item[0]] = [];
      }
    });
    Object.keys(updatedFilters).forEach(key => {
      setSearchParams(key, updatedFilters[key].join(','))
    })
  }, [filters]);

  const removeFilter = useCallback((key, value) => {
    let updatedFilters = { ...filters };
    updatedFilters[key] = updatedFilters[key].filter(filter => filter !== value);
    Object.keys(updatedFilters).forEach(key => {
      setSearchParams(key, updatedFilters[key].join(','))
    })
  }, [filters]);

  const stats = useMemo(() => {
    const relatedSales = auth.pos_type ? [{
      title: 'Related Sales',
      value: formatNumberAsCurrency(dataBySales?.reduce((acc, item) => acc + parseFloat(item.sales_transaction_amount), 0).toFixed(2)),
      description: <span className="fs-14 mid-gray m-top-3">{`Across ${dataBySales?.length} transactions`}</span>
    }] : [];
    const filteredByDate = massMsgMeta ? filterMassMsgMeta(Object.values(massMsgMeta), {startDate: dateRange[0], endDate: dateRange[1]}, filters.associate) : [];
    return [
    {
      title: 'Total',
      value: filteredByDate?.reduce((acc, item) => acc + parseInt(item.total_sent), 0),
      description: <span className="fs-14 mid-gray m-top-3">{`Across ${filteredByDate?.length} messages`}</span>
    },
    ...relatedSales,
  ]}, [auth?.pos_type, massMsgMeta, dateRange, dataBySales, filters.associate]);

  const graphData = useMemo(() => {
    let data = {};
    data['Related Sales'] = dataBySales.map(item => ({
      date: item.sales_transaction_date,
      total: parseFloat(item.sales_transaction_amount)
    }));
    return data;
  }, [dataBySales]);

  const columns = useMemo(() => {
    const relatedSales = auth.pos_type ? [{
      title: 'Related Sales',
      dataIndex: 'related_sales',
      key: 'related_sales',
      sorter: (a, b) => a.related_sales - b.related_sales,
      render: (value) => formatNumberAsCurrency(value || 0)
    }] : [];
    const storeColumn = auth.stores.filter(st => st.id !== 0).length > 1 ? [
      {
        title: 'Store',
        dataIndex: 'activity_store_name',
        key: 'activity_store_name',
        sorter: (a, b) => a.activity_store_name.localeCompare(b.activity_store_name),
      }] : [];
    return [
      {
        title: 'Sent',
        dataIndex: 'activity_date',
        key: 'activity_date',
        sorter: (a, b) => a.activity_date - b.activity_date,
        render: (value) => moment.utc(new Date(value)).format('MMM DD, yyyy'),// changed to moment bc datefns is fighting me on timezone offset
        defaultSortOrder: 'descend'
      },
      {
        title: 'Created by',
        dataIndex: 'message_batch_id',
        key: 'message_batch_id',
        key_export: 'created_by',
        render: (value) => {
          const meta = massMsgMeta[value];
          return `${meta?.associate_first_name || ''} ${meta?.associate_last_name || ''}`.trim() || "Unknown Associate";
        },
        sorter: (a, b) => {
          const metaA = massMsgMeta[a.message_batch_id];
          const metaB = massMsgMeta[b.message_batch_id];
          const aName = `${metaA?.associate_first_name || ''} ${metaA?.associate_last_name || ''}`.trim();
          const bName = `${metaB?.associate_first_name || ''} ${metaB?.associate_last_name || ''}`.trim();
          if (!aName) return -1;
          if (!bName) return 1;
          return aName.localeCompare(bName);
        },
      },
      ...storeColumn,
      {
        title: 'Audience Size',
        dataIndex: 'message_batch_id',
        key: 'message_batch_id',
        key_export: 'audience_size',
        render: (value) => {
          const meta = massMsgMeta[value];
          return meta?.audience_size || 0;
        },
        sorter: (a, b) => {
          const metaA = massMsgMeta[a.message_batch_id];
          const metaB = massMsgMeta[b.message_batch_id];
          const aAudience = Number(metaA?.audience_size) || 0;
          const bAudience = Number(metaB?.audience_size) || 0;
          return aAudience - bAudience;
        },
      },
      {
        title: 'Delivered',
        dataIndex: 'message_batch_id',
        key: 'message_batch_id',
        key_export: 'delivered',
        render: (value) => {
          const meta = massMsgMeta[value];
          return meta?.total_sent || 0;
        },
        sorter: (a, b) => {
          const metaA = massMsgMeta[a.message_batch_id];
          const metaB = massMsgMeta[b.message_batch_id];
          const aTotal = Number(metaA?.total_sent) || 0;
          const bTotal = Number(metaB?.total_sent) || 0;
          return aTotal - bTotal;
        },
      },
      {
        title: 'Purchase',
        dataIndex: 'purchases',
        key: 'purchases',
        sorter: (a, b) => a.purchases - b.purchases,
      },
      ...relatedSales
    ]
  }, [massMsgMeta, auth]);

  const expandedRowRender = useCallback((record, audience) => {
    return (
      <MassMessageExpandRow
        {...record}
        audience={audience}
      />
    );
  }, []);

  const handleExpandRow = useCallback((expand, record) => {
    let updatedExpandedRowKeys = [...expandedRowKeys];
    if (expand) {
      updatedExpandedRowKeys.push(record.message_batch_id);
      setExpandedRowKeys(updatedExpandedRowKeys);
    } else {
      updatedExpandedRowKeys = updatedExpandedRowKeys.filter(key => key !== record.message_batch_id);
      setExpandedRowKeys(updatedExpandedRowKeys);
    }
  }, [expandedRowKeys]);

  const dataCSVExport = useMemo(() => {
    return dataByMessageBatch.map(item => {
      const meta = massMsgMeta[item.message_batch_id];
      return {
        activity_date: moment.utc(new Date(item.activity_date)).format('MMM DD, yyyy'),
        created_by: `${meta?.associate_first_name || ''} ${meta?.associate_last_name || ''}`.trim() || "Unknown Associate",
        activity_store_name: item.activity_store_name,
        audience_size: meta?.audience_size || 0,
        delivered: meta?.total_sent || 0,
        purchases: item.purchases,
        related_sales: formatNumberAsCurrency(item.related_sales || 0)
      }
    })
  }, [massMsgMeta, dataByMessageBatch])

  return (
    <Report
      title={'Mass Message'}
      stats={stats}
      options={[]}
      hasCSVExport
      CSVtable={dataCSVExport}
      CSVFilename={'mass-message.csv'}
      handleFilterChange={handleFilterChange}
      filters={filters}
      removeFilter={removeFilter}
      onDateRangeChange={handleOnDateRangeChange}
      dataRange={dateRange}
      defaultPeriodValue={getDefaultPeriod}
      graphData={graphData}
      columns={columns}
      table={dataByMessageBatch}
      hasBanner={isBefore(dateRange[0], new Date('2024-04-25'))}
      textBanner='Clientbook started to track that information as of April 25th, 2024. You are unable to see the information before that date.'
      tablesProps={{
        pagination: {
          total: dataByMessageBatch.length,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSizeTable(pageSize)
          },
          page: page,
          pageSize: pageSizeTable,
        },
        rowKey: 'message_batch_id',
        expandable: {
          expandedRowRender: (record) => expandedRowRender(record, massMsgMeta[record.message_batch_id]?.audience_size || 0),
          defaultExpandedRowKeys: ['0'],
          onExpand: (expanded, record) => handleExpandRow(expanded, record),
          expandedRowKeys,
          expandRowByClick: true
        }
      }}
    />
  )
};


const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(MassMessage);
