import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, Typography } from 'antd-v5';

const { Text } = Typography;

const ReportCard = ({ title, icon, description, path }) => {
  const history = useHistory()
  return (
    <Card
      title={
        <div className='flex-row-center pointer'>
          {icon}
          {title}
        </div>
      }
      styles={{
        body: {
          padding: '16px 24px',
          cursor: 'pointer'
        }
      }}
      onClick={() => history.push(path)}
      extra={<Link to={path}>View</Link>}
      style={{ width: '389px', height: '154px',textAlign: 'start' }}
    >
        <Text className='m-btm-0 m-toText-0'>{description}</Text>
    </Card>
  )
}

export default ReportCard;
