import { dispatcher } from '../core/actions';

const initialState = {
  // Tags
  tagId: '',
  addOrEdit: 'Add',
  tagName: '',
  tagColor: '#FD0062',
  kioskMode: false,
  deletedTags: [], // for when our write server doesn't sync fast enough for our read server...
};
export default function reducer(state = initialState, action) {
  let state2 = state;
  switch (action.type) {
    case 'SET_TAG_OBJ':
      return { ...state2, ...action.payload };
    case 'SET_TAG_ADD_EDIT':
      return { ...state2, addOrEdit: action.payload };
    case 'SET_TAG_NAME':
      return { ...state2, tagName: action.payload };
    case 'SET_TAG_KIOSK':
      return { ...state2, kioskMode: action.payload };
    case 'SET_TAG_COLOR':
      return { ...state2, tagColor: action.payload };
    case 'SET_TAG_ID':
      return { ...state2, tagId: action.payload };
    default:
      break;
  }
  return state2;
}

export const setTagObj = val => {
  dispatcher('SET_TAG_OBJ', val);
};

export const setTagId = val => {
  dispatcher('SET_TAG_ID', val);
};

export const setTagName = val => {
  dispatcher('SET_TAG_NAME', val);
};

export const setTagKiosk = val => {
  dispatcher('SET_TAG_KIOSK', val);
};

export const setTagColor = val => {
  dispatcher('SET_TAG_COLOR', val);
};

export const setTagAddEdit = val => {
  dispatcher('SET_TAG_ADD_EDIT', val);
};
