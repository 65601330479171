import React from 'react';
import StandardLayout from '../../../../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../../../../Layout/SecondaryPanel';
import { connect } from 'react-redux';
import WhatasppReminderTemplates from '../../../../../../../Layout/AutoReminders/AutoReminderSettings/WhatasppReminderTemplates';

const SalesAutoWhatsappTemplate = props => {
  const pipelineId = props.match.params.pipelineId;
  const listItemId = props.match.params.listItemId;
  const reminderId = props.match.params.reminderId;

  const backUrl = `/Configuration/sales-pipelines/edit-sales-pipeline/${pipelineId}/edit-pipeline-stage/${listItemId}`;

  return (
    <StandardLayout
      title={`${
        window.location.href.includes('add-auto-whatsapp') ? 'Add' : 'Edit'
      } Whatsapp Template for Auto Reminder`}
      description={'Create message templates that appear with the auto reminder'}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Sales Pipeline', to: '/Configuration/sales-pipelines' },
        { title: 'Edit Sales Pipeline', to: backUrl },
        {
          title: `Edit Auto Reminder`,
          to: `${backUrl}/edit-auto-reminder/${reminderId}`,
        },
        { title: `${window.location.href.includes('add-auto-whatsapp') ? 'Add' : 'Edit'} Whatsapp Message Template` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <WhatasppReminderTemplates
        cancelRoute={`${backUrl}/edit-auto-reminder/${reminderId}`}
        popConfirmDesc={'This will remove the template from this Reminder'}
        type={'salesOpp'}
        match={props.match}
      />

      <SecondaryPanel title={['Automation']} current="Sales Opportunities" />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  ...state.autoRemMsgs,
});
export default connect(mapStateToProps, {})(SalesAutoWhatsappTemplate);
