import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'antd';
import React from 'react';

const IconAndTextButton = ({ onClick, icon, children, disabled }) => (
  <Button
    type="primary"
    className="cb-button"
    onClick={onClick}
    disabled={disabled}
    style={{ backgroundColor: disabled ? '#EBEBEB' : undefined }}
  >
    <Row gutter={8} align="middle" justify="center">
      <Col>
        <FontAwesomeIcon icon={icon} />
      </Col>
      <Col
        style={{
          fontFamily: 'Poppins',
          fontWeight: 600,
          fontSize: '13px',
          lineHeight: '20px',
        }}
      >
        {children}
      </Col>
    </Row>
  </Button>
);

export default IconAndTextButton;
