import React from 'react'
import Icon, { EyeOutlined } from '@ant-design/icons';
import { ReactComponent as PurchaseOutlineSvg } from './Purchase.svg';
import { ReactComponent as SalesOpportunitySvg } from './SalesOpportunity.svg';
import { ReactComponent as MassMessagesSvg } from './MassMessage.svg';
import { ReactComponent as NewClientSvg } from './NewClient.svg';
import { ReactComponent as LightningBoltSvg } from './LightningBolt.svg';
import { ReactComponent as MessageReviewsSvg } from './MessageReviews.svg';
import { ReactComponent as PaymentRequestsSvg } from './PaymentRequest.svg';
import { ReactComponent as AutoMessageSvg } from './AutoMessage.svg';
import { ReactComponent as AutoReminderSvg } from './AutoReminder.svg';
import { ReactComponent as Party } from './Party.svg';


const PurchaseViewed = ({size = 16, color = 'black', ...props}) => {
  return (
    <Icon
      component={EyeOutlined}
      size={size}
      color={color}
      {...props}
    />
  )
}

const PartyIcon = ({size = 16, color = 'black', ...props}) => {
  return (
    <Icon
      component={Party}
      size={size}
      color={color}
      {...props}
    />
  )
}

const PurchaseOutline = ({size = 16, color = '#85BB65', ...props}) => {
  return (
    <Icon
      component={PurchaseOutlineSvg}
      size={size}
      color={color}
      {...props}
    />
  )
}

const SalesOpportunity = ({size = 16, color = 'black', ...props}) => {
  return (
    <Icon
      component={SalesOpportunitySvg}
      size={size}
      color={color}
      {...props}
    />
  )
}

const MassMessages = ({size = 16, color = 'black', ...props}) => {
  return (
    <Icon
      component={MassMessagesSvg}
      size={size}
      color={color}
      {...props}
    />
  )
}

const NewClient = ({size = 16, color = 'black', ...props}) => {
  return (
    <Icon
      component={NewClientSvg}
      size={size}
      color={color}
      {...props}
    />
  )
}

const LightningBolt = ({size = 16, color = 'black', ...props}) => {
  return (
    <Icon
      component={LightningBoltSvg}
      size={size}
      color={color}
      {...props}
    />
  )
}

const MessageReviews = ({size = 16, color = 'black', ...props}) => {
  return (
    <Icon
      component={MessageReviewsSvg}
      size={size}
      color={color}
      {...props}
    />
  )
}

const PaymentRequests = ({size = 16, color = 'black', ...props}) => {
  return (
    <Icon
      component={PaymentRequestsSvg}
      size={size}
      color={color}
      {...props}
    />
  )
}

const AutoMessage = ({size = 16, color = 'black', ...props}) => {
  return (
    <Icon
      component={AutoMessageSvg}
      size={size}
      color={color}
      {...props}
    />
  )
}

const AutoReminder = ({size = 16, color = 'black', ...props}) => {
  return (
    <Icon
      component={AutoReminderSvg}
      size={size}
      color={color}
      {...props}
    />
  )
}

export {
  PurchaseOutline,
  SalesOpportunity,
  MassMessages,
  NewClient,
  LightningBolt,
  MessageReviews,
  PaymentRequests,
  AutoMessage,
  AutoReminder,
  PurchaseViewed,
  PartyIcon
}
