export default (templateObj, configObj) => {
  const subsObj = {}; // an obj that will look like {1: client, 2: product, 3: something, 4: whatevz}
  const { substitution, template: templateTxt } = templateObj;
  if (substitution) {
    // check for null columns
    const substitutes = substitution.split(','); // they're comma delimited, make an array
    substitutes.forEach(sub => {
      // loop over that array ^ , each sub looks something like 1=CLIENT or 2=PRODUCT
      const propertyDivide = sub.split('='); // split them up on that equals sign into an array
      if ((propertyDivide.length = 2)) {
        // each propertyDivide looks like this: [1, "CLIENT"] or [2, "PRODUCT"], etc...
        subsObj[propertyDivide[0]] = propertyDivide[1].toLowerCase(); // put them on that subsObj, and lowercase the values... we're rendering them later!
      }
    });
  }
  return templateTxt.replace(/{{\d}}/g, val => {
    // look for {{#}}
    const theNumber = val.replace(/[{}]/g, ''); // if found, remove all {'s and }'s
    if (subsObj[theNumber]) {
      // using the number, check if the property exists on the substitutes object
      if (configObj[subsObj[theNumber]]) {
        // using the value of the substitues obj property, check if configObj property exists
        return configObj[subsObj[theNumber]]; // if so return it
      }
    }
    return `[${subsObj[theNumber]}]`; // else return this lol extreme edge case, I don't know.
  });
};
