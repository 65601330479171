import React, { useState } from 'react';

const CardComponent = props => {
  return (
    <div className={`dashboardMetrics_card ${props.metrics_cardClass}`} style={props.metrics_card}>
      <div className={'dashboardMetrics_header_div'}>
        <p>{props.cardHeader}</p>
      </div>
      <div className="dashboardMetrics_card_body_div">
        {props.cards.map(card => {
          return card;
        })}
      </div>
    </div>
  );
};

export default CardComponent;
