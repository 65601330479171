import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const PreviewCard = ({ hovering, ind, setHovering, onDelete, imageUrl, isVideo }) => {
  const hoverKey = `${isVideo ? 'VID' : 'IMG'}_${ind}`;
  const showDelete = () => {
    if (hovering === hoverKey) {
      return (
        <div
          className="pos-abs pointer"
          style={{
            width: '67px',
            height: '67px',
            zIndex: '1000',
            backgroundColor: 'rgba(0,0,0,0.38)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
          }}
          onMouseLeave={() => setHovering('')}
          onClick={() => onDelete(ind)}
        >
          <div
            style={{
              width: '29px',
              height: '29px',
              borderRadius: '4px',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon className="pointer" icon={['far', 'trash']} />
          </div>
        </div>
      );
    }
  };

  const showVideoIcon = () => {
    if (!isVideo) return null;
    return (
      <div
        className="pos-abs"
        style={{
          display: 'flex',
          width: '63px',
          height: '67px',
          zIndex: '999',
          justifyContent: 'flex-end',
          paddingTop: '4px',
        }}
      >
        <div
          style={{
            height: '13px',
            width: '13px',
            borderRadius: 1000,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '6px',
          }}
        >
          <FontAwesomeIcon size="sm" icon={['fas', 'play']} />
        </div>
      </div>
    );
  };

  return (
    <div
      key={ind}
      className="w-67 h-67 border-r4"
      // style={{ margin: '7px 7px 13px' }}
      onMouseEnter={() => setHovering(hoverKey)}
    >
      {showVideoIcon()}
      {showDelete()}
      <div className="w-67 h-67 border-r4" style={{ overflow: 'hidden' }}>
        <img src={imageUrl} className="h-67 border-r4" alt="#" />
      </div>
    </div>
  );
};

export default PreviewCard;
