import store from '../core/store';
import { dispatcher } from '../core/actions';

const initialState = {
  // Custom Actions
  customId: '',
  customTitle: '',
  addOrEdit: 'Add',
  allowNotes: false,
  allowScore: false,
};

export default function reducer(state = initialState, action) {
  let state2 = state;
  switch (action.type) {
    case 'SET_CUSTOM_TITLE':
      return { ...state2, addOrEdit: action.payload };
    case 'SET_CUSTOM_NAME':
      return { ...state2, customTitle: action.payload };
    case 'SET_NOTES':
      return { ...state2, allowNotes: action.payload };
    case 'SET_SCORE':
      return { ...state2, allowScore: action.payload };
    case 'SET_CUSTOM_ID':
      return { ...state2, customId: action.payload };
    case 'RESET_CUSTOM_ACTIONS':
      return { ...initialState };
    default:
      break;
  }
  return state2;
}

export const setCustomTitle = val => {
  dispatcher('SET_CUSTOM_TITLE', val);
};

export const setCustomName = val => {
  dispatcher('SET_CUSTOM_NAME', val);
};

export const setInc_notes = val => {
  dispatcher('SET_NOTES', val);
};

export const setScored = val => {
  dispatcher('SET_SCORE', val);
};

export const setCustomId = val => {
  dispatcher('SET_CUSTOM_ID', val);
};

export const setEditCustomAction = (data, addOrEdit) => {
  const { dispatch } = store;
  const { customId, customTitle, allowNotes, allowScore } = data;
  const dispatchArr = [
    {
      type: 'SET_CUSTOM_TITLE',
      payload: addOrEdit || 'Add',
    },
    {
      type: 'SET_CUSTOM_NAME',
      payload: customTitle || '',
    },
    {
      type: 'SET_NOTES',
      payload: allowNotes || '',
    },
    {
      type: 'SET_SCORE',
      payload: allowScore || '',
    },
    {
      type: 'SET_CUSTOM_ID',
      payload: customId || '',
    },
  ];
  dispatchArr.forEach(obj => {
    dispatch(obj);
  });
};
