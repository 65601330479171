import { Link } from 'react-router-dom';
import { setAuthStoreId } from '../../../reducers/auth';
import React from 'react';

export const storeWithPaymentHeaders = [
  {
    keyName: 'name',
    style: { maxWidth: '50%', minWidth: '100px' }, // we can apply all styles to the header now.
    headerClassName: 'tags_row1 col-p-l-25',
    title: 'Name',
    rowClassName: 'col-p-l-25 darkBlue',
    sortable: true,
  },
  {
    keyName: 'bank_account_nickname',
    style: { maxWidth: '50%', minWidth: '170px' }, // we can apply all styles to the header now.
    headerClassName: 'tags_row1 col-p-l-25',
    title: 'Linked Bank Account',
    rowClassName: 'col-p-l-25 darkBlue',
    sortable: true,
    render: (t, o) => {
      if (!t && o.bank_account_type) {
        return [o.bank_account_type || '', o.bank_account_last_four || ''].join('-');
      }
      return t;
    },
  },
  // {
  //   keyName: "uuid",
  //   style: { maxWidth: "10%", minWidth: "80px", textAlign: 'left' }, // we can apply all styles to the header now.
  //   headerClassName: "tags_row1 col-p-l-25",
  //   title: "Uuid",
  //   rowClassName: "col-p-l-25 darkBlue",
  //   render: value => {
  //     return (
  //       <CopyToClipboard text={value} onCopy={handleCopy}>
  //         <Button type="link" style={{ padding: 0 }}>
  //           <span style={{ fontWeight: 'normal' }}>{value}</span>
  //           <CopyOutlined />
  //         </Button>
  //       </CopyToClipboard>
  //     );
  //   },
  // },
  {
    keyName: 'state',
    style: { width: '25%', paddingRight: '45px', minWidth: '100px' }, // we can apply all styles to the header now.
    headerClassName: 'row1', // This targets the header's className
    title: 'Store',
    sortable: true,
    render: (t, o) => {
      if (o.city && o.state) {
        return `${o.city}, ${o.state}`;
      }
      if (o.city) {
        return o.city;
      }
      return o.state;
    },
  },
  {
    keyName: 'action',
    style: { width: '15%', paddingRight: '22px', minWidth: '133px' },
    headerClassName: 'row4',
    title: 'Action',
    sortable: false,
    render: (t, o) => {
      return (
        <span className="blue-link fs-13" onClick={() => setAuthStoreId(o.id)}>
          <Link className="blue-link no-decoration" to={`/Stores/edit-store/${o.id}`}>
            Edit Store
          </Link>
        </span>
      );
    },
  },
];

export const storeWithoutPaymentHeaders = [
  {
    keyName: 'name',
    style: { maxWidth: '50%', minWidth: '100px' }, // we can apply all styles to the header now.
    headerClassName: 'tags_row1 col-p-l-25',
    title: 'Name',
    rowClassName: 'col-p-l-25 darkBlue',
    sortable: true,
  },
  // {
  //   keyName: "uuid",
  //   style: { maxWidth: "10%", minWidth: "80px", textAlign: 'left' }, // we can apply all styles to the header now.
  //   headerClassName: "tags_row1 col-p-l-25",
  //   title: "Uuid",
  //   rowClassName: "col-p-l-25 darkBlue",
  //   render: value => {
  //     return (
  //       <CopyToClipboard text={value} onCopy={handleCopy}>
  //         <Button type="link" style={{ padding: 0 }}>
  //           <span style={{ fontWeight: 'normal' }}>{value}</span>
  //           <CopyOutlined />
  //         </Button>
  //       </CopyToClipboard>
  //     );
  //   },
  // },
  {
    keyName: 'state',
    style: { width: '25%', paddingRight: '45px', minWidth: '100px' }, // we can apply all styles to the header now.
    headerClassName: 'row1', // This targets the header's className
    title: 'Store',
    sortable: true,
    render: (t, o) => {
      if (o.city && o.state) {
        return `${o.city}, ${o.state}`;
      }
      if (o.city) {
        return o.city;
      }
      return o.state;
    },
  },
  {
    keyName: 'action',
    style: { width: '15%', paddingRight: '22px', minWidth: '133px' },
    headerClassName: 'row4',
    title: 'Action',
    sortable: false,
    render: (t, o) => {
      return (
        <span className="blue-link fs-13" onClick={() => setAuthStoreId(o.id)}>
          <Link className="blue-link no-decoration" to={`/Stores/edit-store/${o.id}`}>
            Edit Store
          </Link>
        </span>
      );
    },
  },
];
