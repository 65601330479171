import React, { useEffect, useState } from 'react';
import { Select as AntDesignSelect } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDebounce from '../../../hooks/useDebounce';
import './styles.css';

const Select = props => {
  const { loading, options, onSearch, prefixIcon, className, dropdownClassName, style = {}, ...otherProps } = props;

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);

  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedSearch);
    }
  }, [debouncedSearch]);

  const searchClass = prefixIcon ? 'cb-select__prefix' : '';
  const containerClass = `cb-select ${searchClass} ${className || ''} `;
  const dropdownClass = `cb-select__dropdown ${dropdownClassName || ''}`;

  return (
    <div className={containerClass} style={style}>
      {prefixIcon || null}

      <AntDesignSelect
        {...otherProps}
        onSearch={onSearch ? setSearch : undefined}
        showSearch={Boolean(onSearch)}
        popupClassName={dropdownClass}
        loading={loading}
        suffixIcon={!loading ? <FontAwesomeIcon icon={['fas', 'angle-down']} /> : undefined}
      >
        {options.map((option, index) => (
          <AntDesignSelect.Option key={`${option.value}`} disabled={option.disabled}>
            {option.label} {option.suffix && <span className="cb-select__suffix">({option.suffix})</span>}
          </AntDesignSelect.Option>
        ))}
      </AntDesignSelect>
    </div>
  );
};

export default Select;
