import React, { useState, Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { postRequest, getRequest, deleteRequest } from '../../../../../core/apiRequests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notificationSuccess, notificationError } from '../../../../../core/actions';
import {
  setReminderActionId,
  setReminderAddEdit,
  setRemTempMsgs,
  setRemTempAddEdit,
  deleteRemTempMsgs,
} from '../../../../../reducers/autoRemMsgs';
import { setListType } from '../../../../../reducers/autoRemMsgs';
import StyledLink from '../../../StyledLink';
import PopConfirm from '../../../PopConfirm';
import Button from '../../../Button';
import ViewAllTemplates from '../../../AutoWhatsAppTemplate/ViewAllTemplates';
import FiveTemplates from '../../../AutoWhatsAppTemplate/AutoWhatsAppSettings/FiveTemplates';
import SelectedTemplate from '../../../AutoWhatsAppTemplate/AutoWhatsAppSettings/SelectedTemplate';

const WhatsappReminderTemplates = props => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const listItemId = props.match.params.listItemId;
  const history = useHistory();
  const isMounted = useRef(null);
  const whatsappId = props.match.params.templateId;
  const [showViewAll, toggleViewAll] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [fiveTemplates, setFiveTemplates] = useState([]);
  const [allTemplates, setAllTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const listFormatter = (t, o) => {
    {
      // t = text, o = obj.
      const subsObj = {}; // an obj that will look like {1: client, 2: product, 3: something, 4: whatevz}
      if (o.substitution) {
        // check for null columns
        const substitutes = o.substitution.split(','); // they're comma delimited, make an array
        substitutes.forEach(sub => {
          // loop over that array ^ , each sub looks something like 1=CLIENT or 2=PRODUCT
          let propertyDivide = sub.split('='); // split them up on that equals sign into an array
          if ((propertyDivide.length = 2)) {
            // each propertyDivide looks like this: [1, "CLIENT"] or [2, "PRODUCT"], etc...
            subsObj[propertyDivide[0]] = propertyDivide[1].toLowerCase(); // put them on that subsObj, and lowercase the values... we're rendering them later!
          }
        });
      }
      return t.replace(/{{\d}}/g, val => {
        // look for {{#}}
        const theNumber = val.replace(/[{}]/g, ''); // if found, remove all {'s and }'s
        if (subsObj[theNumber]) {
          // using the number, check if the property exists on the substitutes object
          return `[${subsObj[theNumber]}]`; // if so return it
        }
        return '[N/A]'; // else return this lol extreme edge case, I don't know.
      });
    }
  };

  const searchFunctionality = searchText => {
    if (searchText) {
      let searchArray = searchText.split(' ');
      let filteredArr = [];
      allTemplates.forEach(tmpl => {
        let hasIt = true;
        searchArray.forEach(sText => {
          const sTextFm = sText.toLowerCase().trim() || '';
          let templateTxt = tmpl.template || '';
          let subTxt = tmpl.substitution || '';
          if (!templateTxt.toLowerCase().includes(sTextFm) && !subTxt.toLowerCase().includes(sTextFm)) {
            hasIt = false;
          }
        });
        if (hasIt) {
          filteredArr.push(tmpl);
        }
      });
      if (filteredArr.length > 5) {
        setFiveTemplates([...filteredArr].slice(0, 5));
      } else {
        setFiveTemplates([...filteredArr]);
      }
    } else {
      if (allTemplates.length > 5) {
        setFiveTemplates([...allTemplates.slice(0, 5)]);
      } else {
        setFiveTemplates([...allTemplates]);
      }
    }
  };

  useEffect(() => {
    isMounted.current = true;
    const { type } = props; // do we use this anymore?
    setRemTempMsgs([]);
    if (props.merchantId) {
      if (window.location.href.includes('edit-whatsapp-template')) {
        const reminderId = props.match.params.reminderId;
        const reqObj = {
          params: [
            type,
            props.merchantId,
            props.match.params.listItemId,
            reminderId,
            whatsappId,
            'whatsappMessageTemplate',
          ],
          //   '/xa/lifeEvent/:merchantId/:lifeEventId/:reminderId/:templateId/whatsappMessageTemplate'
        };
        (async () => {
          const data = await getRequest(reqObj);
          if (data && data.hasOwnProperty('templateObj') && data.hasOwnProperty('allTemplates')) {
            const { id } = data.templateObj;

            if (data.allTemplates.length > 5) {
              setFiveTemplates(data.allTemplates.slice(0, 5));
            } else {
              setFiveTemplates(data.allTemplates);
            }
            setSelectedTemplate(id);
            setAllTemplates(data.allTemplates);
            setListType(type);
          } else {
            console.error('Failed to get Whatsapp Template');
            history.push(props.cancelRoute);
          }
        })();
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [props.merchantId]);

  const tableHeaders = [
    {
      keyName: 'template',
      title: '',
      rowClassName: '',
      style: { width: '100%', paddingTop: '0px' },
      sortable: false,
    },
  ];

  const confirmDelete = () => {
    const reminderId = props.match.params.reminderId;
    const { firstName, lastName, type } = props;
    const fn = firstName ? firstName : '';
    const ln = lastName ? lastName : '';
    (async () => {
      const reqObj = {
        //   '/xa/tags/:merchantId/:tagId/:reminderId/:templateId/whatsappMessageTemplate'
        params: [type, props.merchantId, listItemId, reminderId, whatsappId, 'whatsappMessageTemplate'],
        query: { deletedBy: `${fn} ${ln}` },
      };
      const data = await deleteRequest(reqObj);
      if (data) {
        notificationSuccess('WhatsApp Template Removed');
        deleteRemTempMsgs([...props.autoRemMsgs.deleteMessageTemplates, props.match.params.templateId]);
        // props.setRedirectBack(true);
        history.push(`${props.cancelRoute}`);
      }
    })();
  };

  const saveAutoWhatsapp = () => {
    const listItemId = props.match.params.listItemId;
    const templateId = props.match.params.templateId;
    const reminderId = props.match.params.reminderId;
    const {
      type,
      autoRemMsgs: {
        reminderCount,
        reminderInterval,
        reminderRepeat,
        // repeat stuff here
        autoReminderRepeatCount,
        autoReminderRepeatInterval,
      },
      firstName,
      lastName,
    } = props;
    const fn = firstName ? firstName : '';
    const ln = lastName ? lastName : '';
    const filterTemp = allTemplates.filter(tmp => tmp.id === selectedTemplate);
    let templateObj = {};
    if (filterTemp.length > 0) {
      templateObj = filterTemp[0];
    }
    if (type) {
      let dataObj = {
        id: whatsappId,
        templateObj,
        actionScale: reminderCount,
        actionInterval: reminderInterval,
        repeats: reminderRepeat,
        // repeat stuff here
        repeatScale: reminderRepeat === 1 ? autoReminderRepeatCount : null,
        repeatInterval: reminderRepeat === 1 ? autoReminderRepeatInterval : null,
      };
      if (
        (reminderRepeat && !autoReminderRepeatCount && type !== 'lifeEvent') ||
        (reminderRepeat && !autoReminderRepeatInterval && type !== 'lifeEvent')
      ) {
        //notificationError('Please set a repeat schedule');
      } else if (!templateObj.id) {
        //notificationError('Please select a template');
      } else {
        (async () => {
          const reqObj = {
            //            :merchantId/:tagId/:reminderId/:templateId/
            //   '/xa/tags/:merchantId/:tagId/:reminderId/:templateId/whatsappMessageTemplate'
            params: [type, props.merchantId, listItemId, reminderId, selectedTemplate, 'whatsappMessageTemplate'],
            data: dataObj,
            query: {
              // oldId: templateId,
              createdBy: `${fn} ${ln}`.trim(),
            },
          };
          if (window.location.href.includes('edit-whatsapp-template')) {
            reqObj.query.oldId = templateId;
          }
          const data = await postRequest(reqObj);
          if (data.id) {
            if (window.location.href.includes('edit-whatsapp-template')) {
              notificationSuccess(`WhatsApp Template Updated`);
              history.push(`${props.cancelRoute}`);
            } else {
              notificationSuccess(`WhatsApp Template Added`);
              props.setAddEdit('Edit');
              props.setActionId(data.id);
              history.push(`${props.cancelRoute}/edit-whatsapp-template/${data.id}`);
            }
          } else {
            history.push(`${props.editRoute}`);
            // TODO: error handling?
          }
        })();
      }
    }
  };

  const selectHandler = id => {
    setSelectedTemplate(id);
  };

  const rowClickHandler = data => {
    setSelectedTemplate(data.id);
    setRemTempAddEdit('Edit');
  };

  return (
    <Fragment>
      <div className="" style={{ maxWidth: '1008px' }}>
        {selectedTemplate ? (
          <SelectedTemplate
            selected={selectedTemplate}
            setSelected={setSelectedTemplate}
            allTemplates={allTemplates.map(dt => {
              return {
                ...dt,
                template: listFormatter(dt.template, dt),
              };
            })}
            match={props.match}
          />
        ) : (
          <FiveTemplates
            searchFunctionality={searchFunctionality}
            toggleViewAll={toggleViewAll}
            fiveTemplates={fiveTemplates.map(dt => {
              return {
                ...dt,
                template: listFormatter(dt.template, dt),
              };
            })}
            rowClickHandler={rowClickHandler}
            tableHeaders={tableHeaders}
            selectedTemplate={selectedTemplate}
            searchText={searchText}
          />
        )}

        {/* REPEATS */}
      </div>
      {/* START of save / cancel / delete code  */}
      <div className="flex-col-center m-btm-56 m-top-37" style={{ maxWidth: '1008px' }}>
           <button className="save-button-v2 m-btm-8" onClick={saveAutoWhatsapp}>
            Save
          </button>
        {window.location.href.includes('edit-whatsapp-template') ? (
          <Button
            classname="deleteButton-v2 m-btm-8"
            style={{ marginBottom: '15px' }}
            onclick={() => setShowPopConfirm(true)}
          >
            Delete
          </Button>
        ) : (
          <div />
        )}
          <StyledLink to={props.cancelRoute}>
            <button className="cancel-button-v2 m-btm-15">Cancel</button>
          </StyledLink>
      </div>
      {showPopConfirm ? (
        <PopConfirm
          confirm={`Are you sure you want to remove this Whatsapp config?`}
          description={props.popConfirmDesc}
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={confirmDelete}
        />
      ) : null}
      {showViewAll ? (
        <ViewAllTemplates
          viewAllList={allTemplates}
          listItemId={listItemId}
          selected={selectedTemplate}
          toggleViewAll={toggleViewAll}
          setSelected={selectHandler}
          saveHandler={() => toggleViewAll(false)}
        />
      ) : null}
    </Fragment>
  );
};
const mapStateToProps = state => {
  return {
    ...state.auth,
    addEditTag: { ...state.addEditTag },
    autoRemMsgs: { ...state.autoRemMsgs },
  };
};
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(WhatsappReminderTemplates);
