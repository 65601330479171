import { createStore, applyMiddleware, combineReducers } from 'redux';
import addEditTag from '../reducers/addEditTag';
import addEditCustomActions from '../reducers/addEditCustomActions';
import auth from '../reducers/auth';
import autoRemMsgs from '../reducers/autoRemMsgs';
import autoMessages from '../reducers/autoMessages';
import associate from '../reducers/associate';
import bankAccounts from '../reducers/bankAccounts';
import client from '../reducers/client';
import clientPreference from '../reducers/clientPreference';
import relationshipTypes from '../reducers/relationshipTypes';
import dropdownInputs from '../reducers/dropdownInputs';
import filters from '../reducers/Filters';
import home from '../reducers/home';
import lifeEvent from '../reducers/lifeEvent';
import mainReducer from '../reducers/mainReducer';
import massMessaging from '../reducers/massMessaging';
import messageSettings from '../reducers/messageSettings';
import messaging from '../reducers/messaging';
import navigation from '../reducers/navigation';
import notification from '../reducers/notification';
import addBankAccount from '../reducers/addBankAccount';
import pagination from '../reducers/pagination';
import payments from '../reducers/payments';
import reports from '../reducers/reports';
import salesPipeline from '../reducers/salesPipeline';
import salesTriggers from '../reducers/salesTriggers';
import stores from '../reducers/stores';
import transactions from '../reducers/transactions';
import paymentBanner from '../reducers/paymentBanner';
import tablePagination from '../reducers/tablePagination';
import promiseMiddleware from 'redux-promise-middleware';
import today from '../reducers/today';

export default createStore(
  combineReducers({
    auth,
    autoRemMsgs,
    autoMessages,
    addEditTag,
    addEditCustomActions,
    associate,
    bankAccounts,
    client,
    clientPreference,
    relationshipTypes,
    dropdownInputs,
    home,
    filters,
    lifeEvent,
    mainReducer,
    massMessaging,
    messageSettings,
    messaging,
    navigation,
    notification,
    addBankAccount,
    pagination,
    payments,
    reports,
    salesPipeline,
    salesTriggers,
    stores,
    transactions,
    paymentBanner,
    tablePagination,
    today
  }),
  applyMiddleware(promiseMiddleware),
);
