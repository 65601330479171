const numberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatNumberAsCurrency = value => {
  return numberFormat.format(value);
};

export default formatNumberAsCurrency;
