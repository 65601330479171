import React, { useState } from 'react';
import { bool, object, string, node } from 'prop-types';

import { FIELD_STYLES } from '../WizardMulti';
import { ERROR_MESSAGE } from '../../const';

const Field = ({
  data,
  id,
  type,
  hidden,
  label,
  placeholder,
  active,
  optional,
  errorMessage,
  forget, // do not save (for sensitive info)
  wrapStyle,
  labelStyle,
  inputStyle,
  defaultValue,
  before,
  after,
  children,
  helpText,
  paymentInputSubText,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasError, setHasError] = useState(data[id]?.hasError);
  const [value, setValue] = useState(
    // check for undefined, to allow for null or empty string
    defaultValue !== undefined ? defaultValue : data[id]?.value || '',
  );

  const errorStyles =
    hasError && !isFocused
      ? {
          borderColor: '#FF7A7A',
          backgroundColor: '#F9E0E0',
        }
      : {};

  return (
    <div
      className="field m-btm-20"
      style={{
        position: 'relative',
        ...FIELD_STYLES,
        ...wrapStyle,
      }}
    >
      {/*
       * BlueSnap javascript requires that all inputs are found in the DOM,
       * so we set optional fields to type="hidden",
       * and don't render their label.
       */}
      {label && !hidden && (
        <div>
          <label htmlFor={`add-bank-account-${id}`} className="fs-12 d-block m-btm-8" style={labelStyle}>
            {label}
          </label>
          {paymentInputSubText ? (
            <span className="d-block gray fs-10 max-w-560 m-btm-6" style={{ marginTop: '-8px' }}>
              {paymentInputSubText}{' '}
            </span>
          ) : null}
        </div>
      )}

      {before}

      {React.Children.map(children, child =>
        React.cloneElement(child, {
          // 'data-bluesnap': id,
          'data-active': active,
          'data-optional': optional,
          'data-forget': forget,
          'data-error-message': errorMessage,
          id,
          value,
          onChange: ({ target }) => setValue(target.value),
          // defaultValue:
          //   // check for undefined, to allow for null or empty string
          //   defaultValue !== undefined ? defaultValue : data[id]?.value || '',
          type: hidden ? 'hidden' : type || 'text',
          placeholder,
          title: placeholder,
          onFocus: () => setIsFocused(true),
          onBlur: ({ target }) => {
            // only setHasError if input already had an error
            // (not on initial blur, not if optional)
            if (hasError) {
              setHasError(false);
            }
            setIsFocused(false);
          },
          className: 'input-height w-100-P focus-input-outline-blue p-left-8 rem-pad-width common_dropdownStyle fs-14',
          style: {
            ...errorStyles,
            ...inputStyle,
          },
          autoComplete: 'off',
          ...child.props,
        }),
      )}
      {hasError ? <p className="red fs-12 m-top-5">{helpText}</p> : null}
      {after}
    </div>
  );
};

Field.propTypes = {
  data: object,
  id: string,
  hidden: bool,
  label: string,
  placeholder: string,
  before: node,
  after: node,
  active: bool,
  optional: bool,
  forget: bool,
  errorMessage: string,
  wrapStyle: object,
  labelStyle: object,
  inputStyle: object,
  defaultValue: string,
  helpText: string,
  paymentInputSubText: string,
};

Field.defaultProps = {
  data: {},
  errorMessage: ERROR_MESSAGE.EMPTY,
};

export default Field;
