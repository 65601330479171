import { useLocation, useHistory } from 'react-router-dom';
import { useCallback } from 'react';

export default () => {
  const location = useLocation();
  const history = useHistory();

  const setValue = useCallback(
    (parameterName, val = '') => {
      const urlParams = new URLSearchParams(window.location.search);
      if (!val) {
        urlParams.delete(parameterName);
      } else if (urlParams.has(parameterName)) {
        urlParams.set(parameterName, val);
      } else {
        urlParams.append(parameterName, val);
      }

      history.replace({
        ...location,
        search: decodeURIComponent(urlParams.toString()),
      });
      return urlParams;
    },
    [history, location],
  );

  const getAll = useCallback(() => {
    const paramsObj = {};
    const urlParams = new URLSearchParams(window.location.search);
    for (const [key, value] of urlParams.entries()) {
      if (value === 'all') {
        paramsObj[key] = value;
      } else if (value === 'none') {
        paramsObj[key] = value;
      } else {
        paramsObj[key] = value.split(',');
      }
    }
    return paramsObj;
  }, []);

  const resetAll = useCallback(() => {
    history.replace({
      ...location,
      search: '',
    });
  }, []);

  const getTotal = useCallback(filterValue => {
    const urlParams = new URLSearchParams(window.location.search);
    let total = 0;
    urlParams.forEach(t => {
      if (filterValue) {
        total = t !== filterValue ? total + 1 : total;
      } else {
        total += 1;
      }
    });
    return total;
  }, []);

  const getValues = useCallback((paramsNames) => {
    return paramsNames.reduce((acc, param) => {
      const paramValue = new URLSearchParams(window.location.search).get(param) || '';
      return { ...acc, [param]: paramValue };
    }, {});
  }, []);

  const getTotalValues = useCallback((paramsNames) => {
    const values = getValues(paramsNames);
    return Object.values(values).reduce((acc, value) => {
      if (value === '') {
        return acc;
      } else {
        return acc + 1;
      }
    }, 0);
  }, []);

  const resetValues = useCallback((paramsNames) => {
    for(const param of paramsNames) {
      setValue(param, '');
    }
  }, [])

  return {
    getAll,
    getTotal,
    getValue: parameterName => new URLSearchParams(window.location.search).get(parameterName) || '',
    setValue,
    resetAll,
    resetValues,
    getValues,
    getTotalValues
  };
};
