import React from 'react';
import { connect } from 'react-redux';
import yn from 'yn';
import getTimeRemaining from '../../../../../Helpers/TwentyFourHourCountDown';
import timeSinceFormat from '../../../../../Helpers/TimeSinceFormat';
import ChatMore from './ChatMore';
import GoogleReviewRating from '../../../../../../../../Layout/chat/components/GoogleReviewRating';

const CC_Colors = ['#F2A39B', '#F6AE2C', '#479C83'];

const InboxChatListItem = ({
  hoverMsg,
  setHoverMsg,
  msg,
  idx,
  inboxList,
  selectedClient,
  setSelectedClient,
  selectedClientICL,
  setSelectedClientICL,
  setChatIconColor,
  associateId,
  type,
  setChatMoreRefresh,
  updateUnreadInInboxes,
  setAssignPopup,
  setHoverAssignId,
  role,
  setUnreadMsgMatching,
  setNewMessages,
  number,
  auth,
}) => {
  const stopEventBubbling = (obj, event, color) => {
    event.preventDefault();
    setHoverMsg(obj.id);
    if (event.target.id === '' && obj) {
      const client = { ...obj, ...obj.client, client_id: obj.client.id, chat_id: obj.id, store_id: obj.storeId };
      setSelectedClient(client, event);
      setSelectedClientICL(obj.client?.id);
      setChatIconColor(color);
      for (const convo of inboxList) {
        if (convo.id === obj.id) {
          setUnreadMsgMatching({ chatId: convo.id, inbox: type });
          convo.isUnread = false;
          convo.lastReadDateTime = 5;
          convo.clientReplyDateTime = 3;
        }
        if (number === 0) {
          setNewMessages([]);
        }
      }
    }
  };

  let id = 0;
  // eslint-disable-next-line no-return-assign, no-plusplus, no-sequences
  const uid = (() => ((id = 0), () => id++))();

  return (
    <li
      id={selectedClientICL === msg?.client?.id ? `chatListSelect${uid()}` : `chatListUnselected${uid()}`}
      key={`${msg.id}chat_id${Math.floor(Math.random() * 1000)}`}
      style={{
        borderBottom: '0.5px solid #EBEBEB',
      }}
      className={`default-cursor inboxAvatarLI ${selectedClient === msg?.client?.id ? 'chatListSelect' : ''}`}
      onClick={e => stopEventBubbling(msg, e, '#EBEBEB')}
      onKeyDown={e => stopEventBubbling(msg, e, '#EBEBEB')}
      onMouseEnter={() => setHoverMsg(msg.id)}
      onMouseLeave={() => setHoverMsg('')}
    >
      <UserIcon associateId={associateId} msg={msg} idx={idx} selected={selectedClient === msg?.client?.id} />
      <SmallMessage msg={msg} idx={idx} decluttered={yn(auth.merchantConfig.DECLUTTERED_INBOX)} />
      <ChatMoreOrDate
        msg={msg}
        hoverMsg={hoverMsg}
        associateId={associateId}
        type={type}
        chat={hoverMsg === msg.id ? msg : null}
        setChatMoreRefresh={setChatMoreRefresh}
        selectedClient={selectedClient}
        updateUnreadInInboxes={updateUnreadInInboxes}
        setAssignPopup={setAssignPopup}
        setHoverAssignId={setHoverAssignId}
        role={role}
      />
    </li>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(InboxChatListItem);

const ChatMoreOrDate = ({
  msg,
  hoverMsg,
  associateId,
  type,
  chat,
  setChatMoreRefresh,
  selectedClient,
  updateUnreadInInboxes,
  setAssignPopup,
  setHoverAssignId,
  role,
}) => {
  const inboxTime = msg => {
    if (msg?.client?.preferredContactType === 'WHATSAPP') {
      if (getTimeRemaining(msg.whatsappReceivedDateTime)) {
        return <span className="green">{getTimeRemaining(msg.whatsappReceivedDateTime)}</span>;
      }
      return <span className="missing-data-red">{timeSinceFormat(msg.lastSentTimestamp)}</span>;
    }
    return timeSinceFormat(msg.lastSentTimestamp);
  };

  if (hoverMsg === msg.id || selectedClient === msg?.client?.id) {
    return (
      <div
        key={`chatMoreOrDate_chatMore_${msg.id}`}
        className="add-edit-wrap m-top-10"
        style={{ justifyContent: 'flex-end' }}
      >
        <ChatMore
          msg={msg}
          associateId={associateId}
          type={type}
          chat={chat}
          id="chat-more"
          setChatMoreRefresh={setChatMoreRefresh}
          selectedClient={selectedClient}
          updateUnreadInInboxes={updateUnreadInInboxes}
          setAssignPopup={setAssignPopup}
          setHoverAssignId={setHoverAssignId}
          role={role}
        />
      </div>
    );
  }
  return (
    <div
      key={`chatMoreOrDate_time_${msg.id}`}
      className="fs-10 fw-400 gray add-edit-wrap align-vert-middle m-right-8"
      style={{ right: '10px', justifyContent: 'flex-end', alignItems: 'center' }}
    >
      {msg.disabled || <span>{inboxTime(msg)}</span>}
    </div>
  );
};

const SmallMessage = ({ msg, idx, decluttered }) => {
  const msgTrim = msg => {
    if (msg && msg.length > 16) {
      return `${msg.substr(0, 16)}...`;
    }
    return msg;
  };
  return (
    <div
      key={`smallmsg_${msg.id}`}
      className="no-text-wrap flex-col-left-center align-left"
      style={{ width: '140px', position: 'relative' }}
    >
      <div>
        <span className="fs-12 fw-400">{msgTrim(msg?.client?.name)}</span>
        {msg?.client?.preferredContactType === 'WHATSAPP' ? (
          <img
            alt="whatsapp icon"
            className="m-left-4"
            style={{ marginBottom: '3px' }}
            src="https://dashboard-v2-images.s3.amazonaws.com/whatsapp.svg"
          />
        ) : null}
        {decluttered && !msg?.isOpen && (
          <span className="fs-10 fw-400 mid-gray italic" style={{ position: 'absolute', top: 4, right: -60 }}>
            Closed
          </span>
        )}
      </div>
      {!(msg?.client?.assignedAssociates?.length > 0) && msg?.client.type === 'C ' && (
        <span className="fs-10 fw-400 mid-gray italic">Unassigned</span>
      )}
      {msg?.type === 'G' && <GoogleReviewRating rating={4} />}
      {msg.disabled || <span className="fs-10 fw-400 gray">{msgTrim(msg.lastMessage)}</span>}
    </div>
  );
};

const UserIcon = ({ msg, idx, selected }) => {
  const unreadMessage = m => {
    let classNames = 'circle w-8 m-left-10';
    if (m.isUnread) {
      classNames += ' visible';
    } else {
      classNames += ' invisible';
    }

    return (
      <div
        key={`user_icon_${m.id}`}
        style={{ backgroundColor: '#33aafc', height: '8px', width: '8px' }}
        className={classNames}
      />
    );
  };

  return (
    <div
      style={{ width: 'auto', alignItems: 'center' }}
      key={`${idx}_user_icon_${msg.id}`}
      className="flex-row-nospacebetween-nowrap"
    >
      {unreadMessage(msg)}
      <UserIconImage msg={msg} idx={idx} selected={selected} />
    </div>
  );
};

const UserIconImage = ({ msg, idx, selected }) => {
  const initialFunc = name => {
    const initials = `${name}`
      .split(' ')
      .map(e => e.charAt(0))
      .slice(0, 2)
      .join('');
    return initials;
  };

  if (msg?.type === 'F') {
    return <img alt="Avatar" className="inboxAvatar" src="https://dashboard-v2-images.s3.amazonaws.com/facebook.svg" />;
  }
  if (msg?.type === 'G') {
    return <img alt="avatar" className="inboxAvatar" src="https://dashboard-v2-images.s3.amazonaws.com/google.svg" />;
  }
  if (msg?.client?.image) {
    return <img alt="avatar" className="inboxAvatar" src={msg?.client?.image} />;
  }
  return (
    <div style={{ backgroundColor: selected ? '#FFF' : '#EBEBEB', color: '#818181' }} className="inboxAvatarInitials">
      <span className="no-text-wrap fs-14">{initialFunc(msg?.client?.name)}</span>
    </div>
  );
};
