import React from 'react';
import { Space, Typography } from 'antd';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { Text } = Typography;

const handleKeyDown = e => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};

const GlobalHeader = props => {
  return (
    <div>
      <div>{props.additionalButtonsTop ? props.additionalButtonsTop() : null}</div>
      <div className="w-100-P h-100-P">
        {props.search}
        {props.elasticResults && !props.mysqlSearch && (
          <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
            <Text italic type="secondary">
              {' '}
              Not seeing your results?
            </Text>
            <Text
              italic
              type="secondary"
              underline
              strong
              onClick={() => {
                props.changeQuery();
              }}
              style={{ cursor: 'pointer' }}
            >
              Click Here
            </Text>
          </Space>
        )}
        <div className="flex-row-spacebetween-wrap pos-rel w-100-P" style={props.additionalButtonStyle}>
          {props.filter ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 24,
                marginLeft: 16,
              }}
            >
              <div
                id="filters-button"
                role="button"
                tabIndex={0}
                onKeyDown={handleKeyDown}
                style={{ color: '#1769AF', fontWeight: 600, fontSize: 13, cursor: 'pointer' }}
                onClick={() => props.setShowFilters(true)}
              >
                <FontAwesomeIcon className="m-right-8 fs-13" icon={['far', 'filter']} />
                Filters
              </div>
            </div>
          ) : null}
          {/* FILTER POP UP  */}
          {props.showFilters ? props.filter : null}
          {/* extra buttons */}
          <div className={props.additionalButtonsClass || ''}>
            {props.additionalButtons ? props.additionalButtons() : null}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => state.auth;
export default connect(mapStateToProps, {})(GlobalHeader);
