import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../Layout/Button';
import Layout from '../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';
import PopConfirm from '../../../../Layout/PopConfirm';

import { setTriggerReminderInterval, setTriggerActionObj } from '../../../../../reducers/salesTriggers';
import '../trig.css';
import { deleteListItem, getRequest, postRequest } from '../../../../../core/apiRequests';
import { notificationError } from '../../../../../core/actions';
import ActionDetails from './ActionDetails';
import CategoryModal from './TriggerDetails/CategoryModal';
import PriceModal from './TriggerDetails/PriceModal';
import KioskSlider from '../../../../Layout/KioskSlider';
import CheckboxDesc from '../../../../Layout/CheckboxDesc';
import whatsAppTemplateFormatter from '../../../../Layout/WhatsAppTemplateFormatter';

const AddEditTrigger = props => {
  const history = useHistory();
  const [showPopConfirm, setShowPopConfirm] = useState(true);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [showPopConfirmAction, setShowPopConfirmAction] = useState(false);
  const [isShown, setIsShown] = useState('');
  // Add Action State:
  const [actionRepeat, setActionRepeat] = useState(0);
  const [actionInterval, setActionInterval] = useState('WEEK');
  const [actionScale, setActionScale] = useState('1');
  const [actionExpires, setActionExpires] = useState(false);
  const [expirationRadio, setExpirationRadio] = useState(0);
  const [actionExpireInterval, setActionExpireInterval] = useState(null);
  const [actionExpireScale, setActionExpireScale] = useState(null);
  const [selectedAction, setSelectedAction] = useState('');
  const [selectedTrigger, setSelectedTrigger] = useState('');
  const [actionObj, setActionObj] = useState({
    description: '',
    title: '',
    trigger_action_id: '',
    type: '',
    action_id: '',
  });
  const [priceObj, setPriceObj] = useState({
    minPrice: '',
    maxPrice: '',
  });

  //   start of repeat / immediate
  const [repeatCheck, setRepeatCheck] = useState(false);
  const [showImmediateDD, setShowImmediateDD] = useState(false);
  const [salesTriggerImmediate, setSalesTriggerImmediate] = useState(false);
  // ask Chris here, since this is also in redux
  const [repeatInterval, setRepeatInterval] = useState('');
  const [repeatScale, setRepeatScale] = useState('');

  // reflect database data for interval / scale
  const [tempActionScale, setTempActionScale] = useState(0);
  const [tempActionInterval, setTempActionInterval] = useState('WEEK');

  const [pendingAuto, setPendingAuto] = useState({
    futureOnly: false,
    isDelete: false,
    automationType: '',
    isDisabled: false,
  });
  const [showPendingAutoPop, setShowPendingAutoPop] = useState(false);
  const [deleteTriggerPop, setDeleteTriggerPop] = useState(false);

  const intervalConversionObj = {
    DAY: 1,
    WEEK: 2,
    MONTH: 3,
  };

  useEffect(() => {
    getTriggerDetails();
  }, [props.auth.merchantId]);

  useEffect(() => {
    if (salesTriggerImmediate) {
      setShowImmediateDD(!showImmediateDD);
    } else {
      setShowImmediateDD(!showImmediateDD);
    }
  }, [salesTriggerImmediate]);

  const getTriggerDetails = editBoolean => {
    if (props.auth.merchantId) {
      (async () => {
        const reqObj = {
          params: ['salesTrigger', props.auth.merchantId, 'triggersAndSales'],
        };
        const dataSet = await getRequest(reqObj);
        if (dataSet && dataSet.length > 0) {
          const dataObj = dataSet[0];
          const { categories, actions, triggers } = dataObj;
          const triggerFilter = triggers.filter(tr => Number(tr.trigger_id) === Number(props.match.params.triggerId));
          if (window.location.href.includes('add-trigger')) {
            let highestNumber = 0;
            triggers.forEach(tr => {
              if (tr.name && tr.name.startsWith('New Sales Trigger')) {
                const theNumber = tr.name.replace(/\D+/g, '');
                if (Number(theNumber) && Number(theNumber > highestNumber)) {
                  highestNumber = Number(theNumber);
                }
              }
            });
            if (!editBoolean) setTriggerActionObj({ name: `New Sales Trigger ${highestNumber + 1}` });
          }

          if (triggerFilter.length > 0) {
            const triggerObj = triggerFilter[0];
            const {
              trigger_id,
              active,
              max_price,
              min_price,
              name,
              categories: triggerCategories, // rename the trigger's categories to triggerCategories to keep them from being confused
              actionConfigs,
            } = triggerObj;
            const dedupObj = {};
            categories.forEach(catg => (dedupObj[catg.category] = true));
            triggerCategories.forEach(catg => (dedupObj[catg] = true));
            const stateObj = {
              active,
              tiggerId: trigger_id,
              minPrice: min_price,
              name,
              maxPrice: max_price,
              triggerCategories,
              actionConfigs,
            };
            setPriceObj({
              minPrice: min_price,
              maxPrice: max_price,
            });
            if (!editBoolean) {
              setTriggerActionObj({
                actions,
                triggers,
                categories: Object.keys(dedupObj).map(objProp => ({ category: objProp })),
                ...stateObj,
              });
            }
          } else {
            if (!editBoolean) {
              setTriggerActionObj({ actions, triggers, categories });
            }

            if (props.match.params.triggerId) {
              history.push('/Configuration/sales-triggers');
            }
          }
        }
      })();
    }
  };

  const handleCancelModal = () => {
    setShowCategoryModal(false);
    setShowPriceModal(false);
    setShowPopConfirmAction(false);
    getTriggerDetails(true);
  };

  const deleteTriggerCategory = deleteVal => {
    const tempObj = {};
    props.salesTriggers.triggerCategories.forEach(ct => (tempObj[ct] = ct));
    if (`${deleteVal}`) {
      delete tempObj[deleteVal];
    }
    setTriggerActionObj({ triggerCategories: [...Object.keys(tempObj)] });
  };

  const saveActionDetails = () => {
    /// FIRST need to check if trigger_action_id exists.... Then it's an update. Else it's an insert...
    const actObj = {
      action_id: selectedAction,
      action_interval: actionInterval,
      action_scale: actionScale,
      description: actionObj.description,
      repeats: actionRepeat,
      repeat_interval: repeatInterval || null,
      repeat_scale: repeatScale || null,
      title: actionObj.title,
      trigger_action_id: actionObj.trigger_action_id || '',
      type: actionObj.type,
    };
    if (actionExpires) {
      Object.assign(actObj, {
        expire_interval: expirationRadio === 1 ? actionExpireInterval : 'DAY',
        expire_scale: expirationRadio === 1 ? actionExpireScale : 0,
      });
    }
    const stateObj = {
      actionConfigs: {
        ...props.salesTriggers.actionConfigs,
        [selectedTrigger || `NEW_${Math.floor(Math.random() * 99999999)}`]: actObj,
      },
    };
    if ((actionRepeat && !repeatInterval) || (actionRepeat && !repeatScale)) {
      notificationError('Please add a repeat schedule!');
    } else {
      setTriggerActionObj({ ...stateObj });
      setShowPopConfirmAction(false);
    }
  };

  const saveAll = () => {
    const { triggerCategories, minPrice, maxPrice, name, active, triggerId, triggers, actionConfigs } =
      props.salesTriggers;
    if (name.length > 0) {
      (async () => {
        const reqObj = {
          params: ['salesTrigger', props.auth.merchantId, props.match.params.triggerId || 'X', 'saveTrigger'],
          data: {
            actionConfigs,
            maxPrice,
            name,
            minPrice,
            active,
            triggerCategories,
            futureOnly: pendingAuto.futureOnly,
          },
        };
        const save = await postRequest(reqObj);
        setTimeout(() => {
          history.push('/Configuration/sales-triggers');
        }, 1000);
      })();
    } else {
      notificationError('Please Enter a Trigger Name');
    }
  };

  const addAction = () => {
    setRepeatCheck(false);
    setActionRepeat(0);
    setActionInterval('WEEK');
    setActionScale('1');
    setRepeatScale('');
    setRepeatInterval('');
    setTempActionScale('1');
    setTempActionInterval('WEEK');
    setSelectedAction('');
    setSelectedTrigger('');
    setActionObj({
      description: '',
      title: '',
      trigger_action_id: '',
      type: '',
      action_id: '',
    });

    setShowPopConfirmAction(true);
  };

  const deleteAction = () => {
    const { tiggerId } = props.salesTriggers;
    const deleteActions = pendingAuto.futureOnly;
    (async () => {
      const delItem = await deleteListItem('salesTrigger', props.auth.merchantId, tiggerId, '', `${deleteActions}`);
      if (delItem) {
        history.push('/Configuration/sales-triggers');
      } else {
        notificationError('Error Deleting Sales Trigger!');
      }
    })();
  };

  const priceCalculator = () => {
    if (props.salesTriggers.minPrice && props.salesTriggers.maxPrice) {
      return (
        <span>
          &nbsp; between <span className="blue-link pointer underline fw-600">${props.salesTriggers.minPrice}</span> and{' '}
          <span className="blue-link pointer underline fw-600">${props.salesTriggers.maxPrice}</span>
        </span>
      );
    }
    if (props.salesTriggers.minPrice) {
      return (
        <span>
          &nbsp; above <span className="blue-link pointer underline fw-600">${props.salesTriggers.minPrice}</span>
        </span>
      );
    }
    if (props.salesTriggers.maxPrice) {
      return (
        <span>
          &nbsp; below <span className="blue-link pointer underline fw-600">${props.salesTriggers.maxPrice}</span>
        </span>
      );
    }
    return '';
  };

  const intervalFormatter = interval => {
    switch (interval) {
    case 'MONTH':
      return 'months';
    case 'DAY':
      return 'days';
    case 'WEEK':
      return 'weeks';
    default:
      break;
    }
  };

  const setCategoryHandle = () => {
    setPriceObj({
      minPrice: props.salesTriggers.minPrice,
      maxPrice: props.salesTriggers.maxPrice,
    });
    setShowPopConfirm(true);
    const tempCats = props.salesTriggers.categories;
    props.salesTriggers.triggerCategories.forEach(tC => {
      let hasCat = false;
      props.salesTriggers.categories.forEach(cat => {
        if (cat.category === tC) {
          hasCat = true;
        }
      });
      if (!hasCat) {
        tempCats.push({ category: tC });
      }
    });
    const stateObj = { categories: tempCats };
    setTriggerActionObj(stateObj);
  };

  const deleteHandler = keyVal => {
    const tempObj = props.salesTriggers.actionConfigs;
    delete tempObj[keyVal];
    setTriggerActionObj({ ...tempObj });
  };

  //   repeat / immediate functions
  const immediateFunction = () => {
    setSalesTriggerImmediate(!salesTriggerImmediate);
    if (!salesTriggerImmediate) {
      setActionScale(0);
      setActionInterval('DAY');
    } else {
      // set to temp data.
      setActionScale(tempActionScale);
      setActionInterval(tempActionInterval);
    }
  };

  const repeatFunction = () => {
    setRepeatCheck(!repeatCheck);
    if (actionRepeat === 0) {
      setRepeatScale(Number(actionScale));
      setRepeatInterval(actionInterval);
      setActionRepeat(1);
    } else {
      setRepeatInterval('');
      setRepeatScale('');
      setActionRepeat(0);
    }
    // setActionRepeat(Number(actionRepeat) === 1 ? 0 : 1);
  };

  const setNameText = txt => {
    if (txt.length < 51) {
      setTriggerActionObj({ name: txt });
    }
  };

  const whatsAppDescription = actionConfig => {
    const rightActionConfig = props.salesTriggers.actions.filter(act => act.title === actionConfig.title);
    const subMsg = whatsAppTemplateFormatter(rightActionConfig[0]);
    return subMsg;
  };

  const AutomationCheck = () => {
    if (pendingAuto.isDisabled == true) {
      setShowPendingAutoPop(true);
    } else {
      saveAll();
    }
  };

  const AutomationSubmit = () => {
    if (pendingAuto.isDelete) {
      deleteAction();
    } else {
      saveAll();
    }
    setShowPendingAutoPop(false);
  };

  const loadReminder = aC => {
    const reminder = props.salesTriggers.actionConfigs[aC];
    setActionInterval(reminder.action_interval);
    setActionScale(reminder.action_scale);
    setActionExpireInterval(reminder.expire_interval);
    setActionExpireScale(reminder.expire_scale);
    setActionRepeat(reminder.repeats);
    setTempActionInterval(reminder.action_interval);
    setTempActionScale(reminder.action_scale);
    setRepeatInterval(reminder.repeat_interval);
    setRepeatScale(reminder.repeat_scale);
    setSelectedAction(Number(reminder.action_id));
    if (reminder.expire_scale !== null && reminder.expire_interval !== null) {
      setActionExpires(true);
      setExpirationRadio(reminder.expire_interval === 'DAY' && reminder.expire_scale === 0 ? 0 : 1);
    }
    if (reminder.repeats === 1) {
      setRepeatCheck(true);
    }
    setSelectedTrigger(aC);
    setActionObj({
      description: reminder.description,
      trigger_action_id: reminder.trigger_action_id,
      title: reminder.title,
      type: reminder.type,
      action_id: reminder.action_id,
    });
    setShowPopConfirmAction(true);
  };

  return (
    <Layout
      title={window.location.href.includes('add-trigger') ? 'Add Trigger' : `Edit "${props.salesTriggers.name}"`}
      description={
        window.location.href.includes('add-trigger')
          ? 'Add a new Sales Trigger and configure automation'
          : 'Edit your Sales Trigger and configure automation'
      }
      classProps="standard-width-with-sidebar"
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Sales Triggers', to: '/Configuration/sales-triggers' },
        { title: window.location.href.includes('add-trigger') ? 'Add Trigger' : `Edit "${props.salesTriggers.name}"` },
      ]}
    >
      <div>
        <div className="flex-row-spacebetween-nowrap maw-600 w-100-P  m-btm-28">
          <div className="flex-col-left">
            <span className="fw-500 fs-13 m-btm-6">Turn On/Off Automation</span>
            <span className="align-left fs-10 gray">
              Enable or disable this Sales Trigger and all linked automated actions
            </span>
          </div>
          <KioskSlider
            value={props.salesTriggers.active}
            onChange={() => {
              setTriggerActionObj({ active: Number(props.salesTriggers.active) === 1 ? 0 : 1 });
              setPendingAuto({ ...pendingAuto, isDisabled: Number(props.salesTriggers.active) === 1 });
            }}
          />
        </div>
        <div className="flex-col-left">
          <span className="fw-500 fs-13">Trigger Name</span>
          <input
            className="m-top-8 maw-600 w-100-P h-50 common_inputStyle"
            value={props.salesTriggers.name}
            onChange={e => setNameText(e.target.value)}
          />
        </div>
        <div onClick={setCategoryHandle}>
          <p className="fs-20 align-left m-btm-0 fw-600">When...</p>
          <span
            className="align-left fs-14 m-top-10 flex-row-nospacebetween-wrap"
            onClick={() => setShowCategoryModal(true)}
          >
            <span>a sale occurs in&nbsp;</span>
            {props.salesTriggers.triggerCategories.length > 0 ? (
              props.salesTriggers.triggerCategories.map((cat, i) => {
                return (
                  <span key={`outerSpan${i}`}>
                    <span key={`innerSpan${i}`} className="blue-link pointer underline fw-600">
                      {` ${cat} `}
                    </span>
                    {props.salesTriggers.triggerCategories[i + 1] ? <span>{' , '} &nbsp;</span> : ''}
                  </span>
                );
              })
            ) : (
              <span className="blue-link pointer underline fw-600">any category</span>
            )}
          </span>
          {/* <span id={cost}> */}
          {props.salesTriggers.minPrice || props.salesTriggers.maxPrice ? (
            <span
              className="align-left fs-14 m-top-10 flex-row-nospacebetween-wrap pointer"
              onClick={() => setShowPriceModal(true)}
            >
              and the price is{priceCalculator()}
            </span>
          ) : (
            <span
              className="align-left fs-14 m-top-10 flex-row-nospacebetween-wrap pointer"
              onClick={() => setShowPriceModal(true)}
            >
              at &nbsp;<span className="blue-link pointer underline fw-600">any price</span>
            </span>
          )}
          {/* </span> */}
        </div>
        <div className="maw-600">
          <p className="fs-20 align-left m-btm-0 fw-600">then...</p>
          {Object.keys(props.salesTriggers.actionConfigs)
            .sort((a, b) => {
              const aAC = props.salesTriggers.actionConfigs[a];
              const bAC = props.salesTriggers.actionConfigs[b];
              if (intervalConversionObj[aAC.action_interval] < intervalConversionObj[bAC.action_interval]) {
                return -1;
              }
              if (intervalConversionObj[aAC.action_interval] > intervalConversionObj[bAC.action_interval]) {
                return 1;
              }
              if (aAC.action_scale < bAC.action_scale) {
                return -1;
              }
              if (aAC.action_scale > bAC.action_scale) {
                return 1;
              }
              return 0;
            })
            .map((aC, idx) => {
              if (props.salesTriggers.actionConfigs[aC].type === 'REMINDER') {
                return (
                  <div key={idx} className="align-left fs-14">
                    <div className="flex-row-spacebetween-nowrap w-100-P maw-600 d-block  m-top-8 m-btm-8">
                      <div onClick={() => loadReminder(aC)} className="d-inline-block">
                        <span>create a </span>

                        <div className="d-inline-block pos-rel">
                          {/* this is the tool tip */}
                          <div
                            id={isShown === idx ? 'textBlock_active' : 'textBlock'}
                            className="pos-abs w-222 border-r12 textBlock"
                            style={{
                              backgroundColor: '#4e4e4e',
                              bottom: '40px',
                            }}
                          >
                            <p className="white" style={{ padding: '11px 12px', margin: '0px' }}>
                              {props.salesTriggers.actionConfigs[aC].description}
                            </p>
                            <img
                              alt="????"
                              src="https://dashboard-v2-images.s3.amazonaws.com/caret-down-solid.svg"
                              className="pos-abs"
                              style={{ bottom: '-15px', left: '17px' }}
                            />
                          </div>

                          {/* end here */}
                          <span
                            className="blue-link pointer underline fw-600"
                            key={idx}
                            onMouseEnter={() => setIsShown(idx)}
                            // onMouseEnter={mouseOverThings}
                            onMouseLeave={() => setIsShown('')}
                          >
                            {/* create component on hover , name like hover dude, pass in (description text) */}
                            {/* make isShown blank string */}
                            {props.salesTriggers.actionConfigs[aC].title} reminder
                          </span>
                        </div>
                        <span>&nbsp; after &nbsp;</span>
                        <span className="blue-link pointer underline fw-600">
                          {`${props.salesTriggers.actionConfigs[aC].action_scale} ${intervalFormatter(
                            props.salesTriggers.actionConfigs[aC].action_interval,
                          )}`}
                        </span>
                      </div>
                      <FontAwesomeIcon
                        onClick={() => {
                          deleteHandler(aC);
                        }}
                        icon={['far', 'times']}
                        className="m-top-3"
                      />
                    </div>
                    {idx < Object.keys(props.salesTriggers.actionConfigs).length - 1 ? (
                      <div className="fs-16 fw-600">and...</div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              }
              if (props.salesTriggers.actionConfigs[aC].type === 'MESSAGE') {
                return (
                  <div key={idx} className="align-left fs-14">
                    <div className="flex-row-spacebetween-nowrap w-100-P maw-600 d-block m-top-8 m-btm-8">
                      <div onClick={() => loadReminder(aC)} className="d-inline-block">
                        <span>send a </span>

                        <div className="d-inline-block pos-rel">
                          {/* this is the tool tip */}
                          <div
                            id={isShown === idx ? 'textBlock_active' : 'textBlock'}
                            className="pos-abs w-222 border-r12 textBlock"
                            style={{
                              backgroundColor: '#4e4e4e',
                              bottom: '40px',
                            }}
                          >
                            <p className="white" style={{ padding: '11px 12px', margin: '0px' }}>
                              {props.salesTriggers.actionConfigs[aC].description !== null
                                ? props.salesTriggers.actionConfigs[aC].description
                                : whatsAppDescription(props.salesTriggers.actionConfigs[aC])}
                            </p>
                            <img
                              alt="???????"
                              src="https://dashboard-v2-images.s3.amazonaws.com/caret-down-solid.svg"
                              className="pos-abs"
                              style={{ bottom: '-15px', left: '17px' }}
                            />
                          </div>

                          {/* end here */}
                          <span
                            className="blue-link pointer underline fw-600"
                            key={idx}
                            onMouseEnter={() => setIsShown(idx)}
                            onMouseLeave={() => setIsShown('')}
                          >
                            {props.salesTriggers.actionConfigs[aC].title} message
                          </span>
                        </div>
                        <span>&nbsp; after &nbsp;</span>
                        <span className="blue-link pointer underline fw-600">
                          {`${props.salesTriggers.actionConfigs[aC].action_scale} ${intervalFormatter(
                            props.salesTriggers.actionConfigs[aC].action_interval,
                          )}`}
                        </span>
                      </div>
                      <FontAwesomeIcon
                        onClick={() => {
                          deleteHandler(aC);
                        }}
                        icon={['far', 'times']}
                        className="m-top-3"
                      />
                    </div>
                    {idx < Object.keys(props.salesTriggers.actionConfigs).length - 1 ? (
                      <div className="fs-16 fw-600">and...</div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              }
            })}
        </div>
        <div className="w-100-P-divider w-100-P maw-600 m-top-30" />
        <Button onclick={addAction} classname="blue flex-row-nospacebetween-nowrap h-100-P rem-pad-width">
          <FontAwesomeIcon icon={['far', 'plus']} className="blue fs-14 m-right-15" style={{ marginBottom: '2px' }} />
          <span>Add Action</span>
        </Button>
        {showCategoryModal ? (
          <CategoryModal
            closeModal={setShowCategoryModal}
            handleCancelModal={handleCancelModal}
            merchantId={props.auth.merchantId}
            categories={props.salesTriggers.categories}
            triggerId={props.match.params.triggerId}
            triggerCategories={props.salesTriggers.triggerCategories}
            setTriggerActionObj={setTriggerActionObj}
          />
        ) : null}
        {showPriceModal ? (
          <PriceModal
            closeModal={setShowPriceModal}
            handleCancelModal={handleCancelModal}
            merchantId={props.auth.merchantId}
            minPrice={props.salesTriggers.minPrice}
            maxPrice={props.salesTriggers.maxPrice}
            triggerId={props.match.params.triggerId}
            setTriggerActionObj={setTriggerActionObj}
          />
        ) : null}
        {showPopConfirmAction ? (
          <ActionDetails
            handleCancelModal={handleCancelModal}
            setSelectedAction={setSelectedAction}
            selectedAction={selectedAction}
            actionObj={actionObj}
            setActionObj={setActionObj}
            actionScale={actionScale}
            setActionScale={setActionScale}
            showImmediateDD={showImmediateDD}
            actionInterval={actionInterval}
            setActionInterval={setActionInterval}
            repeatScale={repeatScale}
            setRepeatScale={setRepeatScale}
            repeatCheck={repeatCheck}
            immediateFunction={immediateFunction}
            salesTriggerImmediate={salesTriggerImmediate}
            actionRepeat={actionRepeat}
            repeatInterval={repeatInterval}
            setRepeatInterval={setRepeatInterval}
            actionExpireScale={actionExpireScale}
            setActionExpireScale={setActionExpireScale}
            actionExpires={actionExpires}
            expirationRadio={expirationRadio}
            setExpirationRadio={setExpirationRadio}
            setActionExpires={setActionExpires}
            actionExpireInterval={actionExpireInterval}
            setActionExpireInterval={setActionExpireInterval}
            repeatFunction={repeatFunction}
            onConfirm={() => saveActionDetails()}
            setSalesTriggerImmediate={setSalesTriggerImmediate}
          />
        ) : null}
        {/* PENDING AUTOMATION */}
        {showPendingAutoPop ? (
          <PopConfirm
            confirm="These changes will be applied by default to both scheduled and future automation"
            customButtons
            description={
              <div className="flex-col-left w-100-P align-center">
                <CheckboxDesc
                  checked={pendingAuto.futureOnly}
                  onclick={() => setPendingAuto({ ...pendingAuto, futureOnly: !pendingAuto.futureOnly })}
                  text="Only apply to future automation"
                  checkButtonProps="p-top-4 m-left-8"
                  classProps="m-top-10"
                  checkBoxId="SalesTriggerAutomationPopupCheckbox"
                />
                <div
                  className="w-100-P m-top-15 m-btm-28 flex-row-nospacebetween-nowrap"
                  style={{ justifyContent: 'center' }}
                >
                  <button
                    className="save-button m-right-15 m-top-15"
                    onClick={AutomationSubmit}
                    id="SalesTriggerAutomationSubmitBtn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            }
          />
        ) : null}
        {/* DELETE TRIGGER */}
        {deleteTriggerPop ? (
          <PopConfirm
            popConfirmBackgroundClick={() => setDeleteTriggerPop(false)}
            confirm="Deleting this trigger will permanently delete all scheduled and future automations"
            customButtons
            description={
              <div className="flex-col-left w-100-P align-center">
                <div
                  className="w-100-P m-top-15 m-btm-28 flex-row-nospacebetween-nowrap"
                  style={{ justifyContent: 'center' }}
                >
                  <button
                    className="save-button m-right-15 m-top-15"
                    onClick={AutomationSubmit}
                    id="SalesTriggerAutomationSubmitBtn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            }
          />
        ) : null}
        <div className="maw-600 w-100-P m-top-15 flex-col-center ">
          {/* <div className="max-w-600 flex-row-nospacebetween-nowrap" style={{ flexDirection: 'row-reverse' }}> */}
          <button onClick={AutomationCheck} className="save-button-v2 m-btm-8">
            Save
          </button>
          {window.location.href.includes('edit-trigger') ? (
            <Button
              classname="deleteButton-v2 m-btm-8"
              style={{ marginBottom: '15px' }}
              onclick={() => {
                // setShowPendingAutoPop(true);
                setDeleteTriggerPop(true);
                setPendingAuto({ ...pendingAuto, isDelete: true });
              }}
            >
              Delete
            </Button>
          ) : (
            <div />
          )}
          <button
            onClick={() => history.push('/Configuration/sales-triggers')}
            className="cancel-button-v2 m-btm-8"
            style={{ border: 'none' }}
          >
            Cancel
          </button>
          {/* </div> */}
        </div>
      </div>
      <SecondaryPanel title={['Automation']} current="Sales Triggers" />
    </Layout>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
  salesTriggers: state.salesTriggers,
  autoRemMsgs: { ...state.autoRemMsgs },
});
const mapDispatchToProps = dispatch => ({
  setInterval(val) {
    setTriggerReminderInterval(val);
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AddEditTrigger);
