import request from '../utils/request';

// available params: limit, page, search
export const getClients = ({ params = {} }) => {
  return request({
    url: '/clients',
    method: 'GET',
    params,
  });
};

// available params: limit, page, storeId
export const getClientSales = (clientId, { params = {} }) => {
  return request({
    url: `/clients/${clientId}/sales`,
    method: 'GET',
    params,
  });
};

// available params: limit, page, storeId
export const getClientMessages = (clientId, { params = {} }) => {
  return request({
    url: `/clients/${clientId}/messages`,
    method: 'GET',
    params,
  });
};
