import React, { useCallback, useState, useId, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownSelect from '../../DropdownSelect';
import { Controller } from 'react-hook-form';
import { Buttons } from './Buttons';

export const EditableField = ({
  label,
  containerClassName,
  contentClassName,
  value,
  icon,
  iconClassName,
  editable = true,
  type,
  placeholder,
  inputProps,
  register,
  editAll,
  reset,
  onSave,
  control,
  selectProps,
  isSubmitting,
  labelClassName,
  addMore,
  action,
  actionText,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const field = useCallback(() => {
    switch (type) {
      case 'input':
        return (
          <input
            className="fs-13 outline-none client-input"
            style={{
              width: 'calc(100% - 140px)',
            }}
            placeholder={placeholder}
            {...inputProps}
            {...register}
          />
        );
      case 'select':
        return (
          <Controller
            name={register.name}
            control={control}
            render={({ field }) => (
              <DropdownSelect
                classProps="w-100-P align-left m-top-8"
                {...selectProps}
                value={field.value?.value || field.value}
                onChange={val => field.onChange(val.value)}
                ref={field.ref}
              />
            )}
          />
        );
      default:
        break;
    }
  }, [type, placeholder, register, inputProps]);

  return (
    <div
      id={`client-details-${register?.name}-box`}
      className={`align-left flex-col-left pos-rel ${containerClassName}`}
    >
      {label && (
        <div className="flex-row-nospacebetween-wrap">
          <span className={`fs-12 fw-500 gray ${labelClassName}`}>{label}</span>
          {addMore && (
            <button>
              <FontAwesomeIcon name={['far', 'plus']} />
            </button>
          )}
        </div>
      )}
      <div className={`flex-row-center w-100-P ${contentClassName}`}>
        {editable && type && (editAll || isEditing) ? (
          field()
        ) : (
          <>
            {icon && <FontAwesomeIcon className={`fs-16 ${iconClassName}`} size="2x" icon={icon} />}
            <span className="fs-12 fw-500 black">{value}</span>
            {action && (
              <div className="markingStatusAction m-left-10 " style={{ marginBottom: '1px' }} onClick={action}>
                {actionText}
              </div>
            )}
          </>
        )}
      </div>
      {editable && (
        <Buttons
          open={isEditing}
          setOpen={setIsEditing}
          isSubmitting={isSubmitting}
          onCancel={() => reset()}
          onSave={onSave}
          id={`client-details-${register?.name}-buttons`}
          parentId={`client-details-${register?.name}-box`}
        />
      )}
    </div>
  );
};

