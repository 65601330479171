import React, { useMemo } from 'react';
import AutoMessages from './AutoMessages';
import Tags from './Tags';
import PaymentRequest from './PaymentRequest';
import SalesOpportunities from './SalesOpportunities';
import Reminders from './Reminders';

const QuickView = props => {
  const assignTo = useMemo(() => {
    const assignToMe = props.client.associates.filter(assoc => assoc.id === props.auth.userId).length > 0;
    if (props.client.associates && props.client.associates.length > 0) {
      if (assignToMe && props.client.associates.length > 1) {
        return `Also assigned to ${props.client.associates
          .filter(assoc => assoc.id !== props.auth.userId)
          .map(a => `${a.first_name || ''} ${a.last_name || ''}`.trim())
          .join(', ')}`;
      }
      if (!assignToMe) {
        return `Assigned to ${props.client.associates
          .map(a => `${a.first_name || ''} ${a.last_name || ''}`.trim())
          .join(', ')}`;
      }
      return null;
    }
    return null;
  }, [props.client.associates, props.client.id, props.auth]);
  return (
    <>
      {assignTo && (
        <p className="margin-0 fw-400 fs-12 align-left m-left-21 m-top-18" style={{ color: '#818181' }}>
          {assignTo}
        </p>
      )}
      <div
        className={`flex-col-left ${assignTo ? '' : 'm-top-18'}`}
        style={{ alignItems: 'stretch', maxHeight: '100%', gap: '36px', overflowX: 'hidden', paddingLeft: '26px' }}
      >
        {props?.auth?.merchantFlags?.tags?.enabled && (
          <Tags client={props.client} auth={props.auth} getClientData={props.getClientData} />
        )}

        <SalesOpportunities
          client={props.client}
          setViewAllType={props.setViewAllType}
          auth={props.auth}
          setSelectedSalesOpp={props.setSelectedSalesOpp}
          getReminderData={props.getReminderData}
          getClientData={props.getClientData}
        />

        <Reminders
          client={props.client}
          setViewAllType={props.setViewAllType}
          auth={props.auth}
          open={props.showPopConfirm}
          closeChat={props.closeChat}
          showMessageHandler={props.showMessageHandler}
          setDismissPopup={props.setDismissPopup}
          dismissPopup={props.dismissPopup}
          setAllAssoc={props.setAllAssoc}
          allAssoc={props.allAssoc}
          setAssociate={props.setAssociate}
          associate={props.associate}
        />

        <AutoMessages
          client={props.client}
          setViewAllType={props.setViewAllType}
          associate_name={`${props.auth?.firstName || ''} ${props.auth?.lastName || ''}`}
          review_link={props.reviewLink}
        />
        <PaymentRequest
          client={props.client}
          setViewAllType={props.setViewAllType}
          showMessageHandler={props.showMessageHandler}
        />
      </div>
    </>
  );
};
export default QuickView;
