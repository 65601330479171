/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo } from 'react';
import { Image } from 'antd-v5';
import truncateText from '../../../../../utils/truncateText';
import './styles.scss';

const NOT_FOUND_IMAGE = 'https://dashboard-v2-images.s3.amazonaws.com/not-found.png';

const ProductsV2Card = props => {
  const { image, title, description, onClick } = props;

  const truncateTitle = useMemo(() => {
    return truncateText(title, 16, { cutWords: true });
  }, [title]);

  const truncateDescription = useMemo(() => {
    return truncateText(description, 16, { cutWords: true });
  }, [description]);

  return (
    <div className="products-v2-card" onClick={onClick}>
      <div className="products-v2-card__image">
        <Image src={image || NOT_FOUND_IMAGE} fallback={NOT_FOUND_IMAGE} preview={false} alt="product" />
      </div>

      <div className="products-v2-card__body">
        <span className="products-v2-card__title">{title.length === truncateTitle.length ? title : truncateTitle}</span>

        <span className="products-v2-card__description">
          {description.length === truncateDescription.length ? description : truncateDescription}
        </span>
      </div>
    </div>
  );
};

export default ProductsV2Card;
