import moment from 'moment';

const timeSinceFormat = then => {
  if (then) {
    const now = moment(new Date());
    const thenDate = moment(new Date(then));
    const difference = moment.duration(now.diff(thenDate));
    const years = Math.floor(difference.asYears());
    const months = Math.floor(difference.asMonths());
    const weeks = Math.floor(difference.asWeeks());
    const days = Math.floor(difference.asDays());
    const hours = Math.floor(difference.asHours());
    return years
      ? `${years}y`
      : months
      ? `${months}m`
      : weeks
      ? `${weeks}w`
      : days
      ? `${days}d`
      : hours
      ? `${hours}h`
      : 'Now';
  }
  return 'n/a';
};
export default timeSinceFormat;
