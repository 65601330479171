import React from 'react';

import {
  Input as InputComponent,
  TaxId as TaxIdComponent,
  Dropdown as DropdownComponent,
  Textarea as TextareaComponent,
  Pair,
  FIELD_PROPS,
} from '../Field';
import { HEADING_CLASSES } from '../WizardMulti';

const BusinessDetails = props => {
  const { data } = props;
  const Input = inputProps => <InputComponent {...props} {...inputProps} />;
  const TaxInput = taxInputProps => <TaxIdComponent {...props} {...taxInputProps} />;
  const Dropdown = dropdownProps => <DropdownComponent {...props} {...dropdownProps} />;
  const Textarea = textareaProps => <TextareaComponent {...props} {...textareaProps} />;

  return (
    <>
      <fieldset className="m-btm-15">
        <Input
          id="businessName"
          label="Business Name"
          placeholder="ex. Company Name"
          maxLength="50"
          defaultValue={data.businessName?.value}
        />
        <Input
          id="businessLegalName"
          label="Legal Business Name"
          placeholder="ex. Company Name, LLC"
          maxLength="60"
          defaultValue={data.businessLegalName?.value}
        />
        <Textarea
          id="businessProductAndServiceDesc"
          label="Please provide a detailed description of your product or service:"
          maxLength="1500"
          defaultValue={data.businessProductAndServiceDesc?.value}
        />
        <Input
          {...FIELD_PROPS.PHONE}
          id="businessPhone"
          type="number"
          label="Business Phone"
          defaultValue={data.businessPhone?.value}
        />
        <Input
          id="businessWebsite"
          label="Website"
          placeholder="www."
          maxLength="100"
          defaultValue={data.businessWebsite?.value}
        />
        <Dropdown
          id="businessType"
          label="Business type"
          options={[
            'Private Corporation',
            'Limited Liability',
            'Non-profit',
            'Partnership',
            'Public Corporation',
            'Sole Proprietorship',
          ]}
          defaultValue={data.businessType?.value}
        />
        {/* <Input id="businessTaxId" label="Business tax ID" maxLength="9" paymentInputSubText="Must be a 9-digit number e.g. 123456789" /> */}
        <TaxInput
          placeholder="000000000"
          id="businessTaxId"
          label="Business tax ID"
          maxLength="9"
          paymentInputSubText="Must be a 9-digit number e.g. 123456789 with no dashes"
          defaultValue={data.businessTaxId?.value}
        />
      </fieldset>

      <fieldset>
        <legend className={HEADING_CLASSES}>Business Address</legend>
        <Input id="businessAddress" placeholder="Address" maxLength="40" defaultValue={data.businessAddress?.value} />
        <Pair>
          <Input id="businessCity" placeholder="City" maxLength="25" defaultValue={data.businessCity?.value} />
          <Input id="businessState" placeholder="State" maxLength="2" defaultValue={data.businessState?.value} />
        </Pair>
        <Input id="businessZip" placeholder="Zip Code" maxLength="10" defaultValue={data.businessZip?.value} />
      </fieldset>
    </>
  );
};

export default BusinessDetails;
