import FileDownload from 'js-file-download';
import paymentHttpRequest from '../../../../../utils/paymentHttpRequest';
import request from '../../../../../utils/request';
import { addLoadingList, removeLoadingList } from '../../../../../reducers/notification';
import { notificationError, notificationSuccess } from '../../../../../core/actions';

export const getPaymentActivityCSV = async ({ params = {} }) => {
  try {
    addLoadingList('getPaymentActivityCSV');
    await paymentHttpRequest({
      url: '/v2/reports/paymentActivityCSV',
      method: 'GET',
      params,
    }).then(response => {
      FileDownload(response.data, 'Payment-Activity.csv');
    });
    notificationSuccess("Download Complete!")
  } catch (error) {
    console.log(error);
  }
  removeLoadingList('getPaymentActivityCSV');
  return;
};

export const getBankTransactionCSV = async ({ params = {} }) => {
  try {
    addLoadingList('getBankTransactionCSV');
    await paymentHttpRequest({
      url: '/v2/reports/bankTransactionCSV',
      method: 'GET',
      params,
      responseType: 'blob',
    }).then(response => {
      FileDownload(response.data, 'Bank-Transactions.xlsx');
    });
    notificationSuccess("Download Complete!")
  } catch (error) {
    console.log(error);
    if (error.response.status === 404) {
      notificationError('No data found for the selected date range');
    }
  }
  removeLoadingList('getBankTransactionCSV');
  return;
};

export const getPaymentMerchantData = async ({ params = {} }) => {
  try {
    return await paymentHttpRequest({
      url: 'v1/reports/paymentReportMerchantData',
      method: 'GET',
      params,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getAssociates = async params => {
  const { merchantId, storeId, userId, role } = params;
  try {
    return await request({
      url: ['/report', merchantId, storeId || 0, 'clientByAction'].join('/'),
      method: 'GET',
      params: {
        userId,
        role,
      },
    });
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getPaymentReportHistory = async (params) => {
  const {paymentRequestId} = params
  try {
    return await paymentHttpRequest({
      url: `/v1/reports/${paymentRequestId}/paymentDetails`,
      method: 'GET',
      params
    });
  }
  catch (error) {
    console.log(error);
    return [];
  }
}

export const getPaymentReport = async ({ params = {} }) => {
  try {
    return await paymentHttpRequest({
      url: '/v2/reports/paymentActivity',
      method: 'GET',
      params,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getPaymentReportGraph = async ({ params = {} }) => {
  try {
    return await paymentHttpRequest({
      url: '/v1/reports/paymentActivityGraph',
      method: 'GET',
      params,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
};
