import React from 'react';
import { Link } from 'react-router-dom';

const MusicShop360 = () => {
  return (
    <>
      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Overview</h3>

        <p>
          Connect your Music Shop 360 account with Clientbook! Music Shop 360 is a all-in-one POS system that has been
          created for music stores. Music Shop 360 makes in-store and online purchases easy, and even tracks client,
          product and sales data.
        </p>

        <p>
          By integrating your Music Shop 360 account with Clientbook, you will be able to effortlessly combine your
          business&apos;s existing data with Clientbook&apos;s desktop and mobile platforms. Reach out to your Customer
          Success Manager to get started!
        </p>

        <p>
          Please be sure the Music Shop 360 user has the rights to access all relevant information. If the user is
          removed from the connected Music Shop 360 account, that account will be removed from Clientbook.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Key Features</h3>

        <p>By adding your Music Shop 360 information to Clientbook, you can:</p>

        <ul className="cb-integrations-details__list">
          <li>Send payment requests and track specific Life Events to maximize POS activity.</li>

          <li>Document Sales Opportunities to intensify your sales team&apos;s efficiency.</li>

          <li>Send Product Collections based on your client&apos;s tastes to effectively market your products.</li>

          <li>
            Maximize communication with your clients by creating Auto Messages. These messages can be sent automatically
            on days based on birthdays, anniversaries, special orders, sales etc.
          </li>
        </ul>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Setup Process</h3>

        <p>
          If you want to integrate your Music Shop 360 account with Clientbook, reach out to your Customer Success
          Manager and they will assist you with installation.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Helpful Links</h3>

        <ul className="cb-integrations-details__list">
          <li>
            <Link to="/Help/contact-support">Clientbook Help Center</Link>
          </li>

          <li>
            <a href="https://musicshop360.com/contact-us/" target="_blank" rel="noopener noreferrer">
              Music Shop 360 Support
            </a>
          </li>
        </ul>
      </section>
    </>
  );
};

export default MusicShop360;
