import io from 'socket.io-client';

export const startSocket = async (userId, merchantId, token) => {
  return await io(process.env.REACT_APP_SOCKET_URL, {
    // transports: ["websocket"],
    transportOptions: {
      polling: {
        extraHeaders: {
          'x-clientbook-user-id': userId,
          'x-clientbook-merchant-id': merchantId,
          Authorization: token,
        },
      },
    },
  });
};
