import React from 'react';
import { bool, oneOf, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ICON = {
  trash: ['far', 'trash'],
  star: ['far', 'star'],
  edit: ['far', 'edit'],
};

const ActionButton = ({ icon, onClick, disabled }) => {
  const pointerClass = disabled ? '' : 'pointer';

  return (
    <button
      className={`w-33 h-33 bg-white border-r4 ${pointerClass} bank-accounts-list__action-button`}
      style={{ marginRight: 5, padding: 0, border: 'none' }}
      onClick={onClick}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={ICON[icon]} className={`fs-16 ${pointerClass}`} />
    </button>
  );
};

ActionButton.propTypes = {
  icon: oneOf(Object.keys(ICON)),
  onClick: func,
  disabled: bool,
};

export default ActionButton;
