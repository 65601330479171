import React from 'react';
import { Radio } from 'antd';
import './styles.css';

const RadioGroup = ({ value, defaultValue, options, onChange, white }) => {
  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <div className={`cb-radio-group ${white ? 'not-transparent' : ''}`}>
      <Radio.Group value={value} defaultValue={defaultValue} size="large" onChange={handleChange}>
        {options.map(option => (
          <Radio.Button key={option.value} value={option.value}>
            {option.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default RadioGroup;
