import React, { useCallback, useState } from 'react';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import Layout from '../../../Layout/StandardLayout';
import { getProductById } from '../../../../api/products';
import { addLoadingList, removeLoadingList } from '../../../../reducers/notification';
import { useHistory, useParams } from 'react-router';
import { useEffect } from 'react';
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { PRODUCT_DETAILS_SECTIONS } from '../../ProductDetails/components/Content/constants';
import yn from 'yn';
import { connect } from 'react-redux';
import FlaggedAddCollectionButton from '../../ProductDetails/Layout/components/Actions/FlaggedAddCollectionButton';
import ClientCollectionModal from '../../ProductDetails/Layout/components/ClientCollectionModal';
import SendProductModal from '../../ProductDetails/Layout/components/SendProductModal';
import { v4 } from 'uuid';



const ProductPage = ({merchantConfig, role, ...props}) => {

  const history = useHistory();
  const { productId } = useParams();
  const [product, setProduct] = useState({});
  const [selectImage, setSelectImage] = useState(null);
  const [showClientCollectionsModal, setShowClientCollectionsModal] = useState(false);
  const [showSendProductsModal, setShowSendProductsModal] = useState(false);
  const productInStock = (typeof product.qty === 'number' && product.qty > 0) || product.qty === null;


  const flaggedAddCollectionButton = useCallback(() => {
    return props.auth.merchantFlags.wishlists?.enabled
  }, [props.auth.merchantFlags])

  const sectionHasItems = useCallback(section => {
    return section.itemType === 'number' || section.items.some(item => Boolean(product[item.key]));
  }, []);

  const formatNumber = useCallback(value => {
    if (!value) return '-.--';

    if (value.match(/[$]/g)) return value;
    return value;
  }, []);

  const formatString = useCallback(value => {
    return (value || '').trim();
  }, []);

  useEffect(() => {
    if(productId) {
      const uuid = v4();
      addLoadingList(uuid);
      getProductById(productId).then(({data}) => {
        setProduct(data)
        if(data.images.length > 0) {
          setSelectImage(0)
        }
      }).catch((error) => {
        console.log(error);
        history.push('/Products')
      }).finally(() => {
        removeLoadingList(uuid);
      })
    } else {
      history.push('/Products')
    }
  }, [productId])

  return (
    <Layout
      title="Product Details"
      classProps="standard-width-with-sidebar"
      breadCrumbs={[
        { title: 'Products', to: '/Products' },
        {
          title: product?.id ? `Details "${product?.name}"` : `Details`,
        },
      ]}
    >
      {product?.id && (
        <div style={{ gap: '32px', display: 'flex', flexDirection: 'row' }}>
          <div className='flex-row-start-center' style={{ gap: '16px' }}>
            <div className='flex-col-center'>
              {product?.images.length > 0 ? (
                product.images.map((image, index) => (
                  <div
                    onClick={() => setSelectImage(index)}
                    key={image.url}
                    className={`product-image-scroll ${selectImage === index ? 'product-image-scroll-selected' : ''}`}
                  >
                    <img className='product-image-scroll-img' src={image.url} alt="img" />
                  </div>
                ))
              ) : (
                <div>
                  <div className="product-image-scroll">
                    <img className='product-image-scroll-img' src='https://dashboard-v2-images.s3.amazonaws.com/image-placeholder@2x.png' alt="img" />
                  </div>
                </div>
              )}
            </div>
            <div className='product-image-selected pos-rel'>
              <img
                className='product-image-selected-img'
                src={selectImage !== null ? product?.images[selectImage]?.url : 'https://dashboard-v2-images.s3.amazonaws.com/image-placeholder@2x.png'}
                alt="image"
              />
               {productInStock || product.available ? null : (
                 <div
                  className="cb-product-details-grid__out-of-stock align-center"
                  style={{ width: '68px', height: '22px', backgroundColor: '#FFD9D2', color: '#FC5025' }}
                >
                  <span className="fw-600 fs-10">Out of Stock</span>
          </div>
              )}
            </div>
          </div>
          <div className='flex-col-center-start w-100-P' style={{ maxWidth: '460px' }}>
            <div className="flex-row-spacebetween-start w-100-P" style={{ borderBottom: '0.5px solid #f3f3f3', paddingBottom: '12px', textAlign: 'left', gap: 10 }}>
              <div className='flex-col-left'>
                <div className="fs-22 fw-500" style={{ marginBottom: '12px' }}>{product?.name}</div>
                {product?.sku ? <div className="fs-22 fw-500" style={{ marginBottom: '12px' }}>{product?.sku}</div> : null}
                <div className="fs-22 fw-600">{`$${product?.price}`}</div>
              </div>
              <div className='flex-row-center' style={{ gap: '18px' }}>
                {flaggedAddCollectionButton && (
                  <Tooltip placement="bottom" title={'Add to Collection'} overlayInnerStyle={{ borderRadius: '12px'}}>
                    <FontAwesomeIcon
                      className='pointer'
                      icon={['far', 'folder-plus']}
                      style={{ fontSize: '16px', color: '#1769AF' }}
                      onClick={() => setShowClientCollectionsModal(true)}
                    />
                  </Tooltip>
                )}
                <Tooltip placement="bottom" title={product?.images.length > 0 ? 'Share' : 'Needs image to send'} overlayInnerStyle={{ borderRadius: '12px'}}>
                  <FontAwesomeIcon
                    className='pointer'
                    icon={['far', 'share']}
                    style={{ fontSize: '16px', color: '#1769AF' }}
                    onClick={() => product?.images.length > 0 ? setShowSendProductsModal(true) : {}}
                  />
                </Tooltip>
                {!product?.posRefNum && (
                  <Tooltip placement="bottom" title={'Edit'} overlayInnerStyle={{ borderRadius: '12px'}}>
                    <FontAwesomeIcon
                      className='pointer'
                      icon={['far', 'pencil']}
                      style={{ fontSize: '16px', color: '#1769AF' }}
                      onClick={() => history.push(`/Products/edit-product/${product?.id}`)}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
            <div className='flex-col-left w-100-P'>
              <div className='fs-14 m-top-24 m-btm-24'>
                {product?.description}
              </div>
              {PRODUCT_DETAILS_SECTIONS.map(section => {
                if (
                  sectionHasItems(section) && section.config
                    ? yn(merchantConfig[section.config?.name]) === section.config?.value ||
                      section.config?.roles.includes(role)
                    : true
                ) {
                  return (
                    <div className='flex-col-left w-100-P' key={section.label}>
                      <div className='fs-18 fw-500 m-btm-15'>{section.label}</div>
                      <div className='product-details m-btm-15'>
                        {section.items.map(item => {
                          if (item.roles && !item.roles.includes(role)) {
                            return null;
                          }

                          const value =
                            section.itemType === 'number' ? formatNumber(product[item.key]) : formatString(product[item.key]);

                          if (!value) return null;

                          if(item.key === 'unitCost' && yn(merchantConfig.HIDE_PRODUCT_COST)) return null;

                          return (
                            <>
                              <div className='fs-14 m-right-15'>{item.label}</div>
                              <div className='fw-600 fs-14'>{value}</div>
                              </>
                          );
                        })}
                      </div>
                    </div>
                  );
                }

                return null;
              })}
            </div>
          </div>
        </div>
      )}
      <ClientCollectionModal
        productId={product.id}
        visible={showClientCollectionsModal}
        setVisible={setShowClientCollectionsModal}
      />
      <SendProductModal productId={product.id} visible={showSendProductsModal} setVisible={setShowSendProductsModal} />
      <SecondaryPanel title={['Manage']} current="Products" />
    </Layout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  merchantConfig: state.auth.merchantConfig,
});

export default connect(mapStateToProps, {})(ProductPage);

