import React from 'react';
import { convertDaysToMonths } from '../../../../../utils/convertDaysToMonths';

const LeadHeader = ({ headerText, headerSubTxt, headerSubTxtClass, headerDivMargin }) => {
  function formatHeaderSubTxt(txt) {
    const regex = /[0-9]+\sdays/gi
    return txt.replaceAll(regex, (match) => {
      return convertDaysToMonths(parseInt(match));
    })
  }
  return (
    <div className="">
      <img
        alt="edison logo"
        src="https://dashboard-v2-images.s3.amazonaws.com/edison_clbk_logo.svg"
        style={{ width: '80px', height: '80px' }}
      />
      <div style={{ width: '470px', margin: headerDivMargin }}>
        <span className="fw-600 fs-16">{headerText}</span>
        <p className={`fw-500 fs-13 ${headerSubTxtClass}`}>{formatHeaderSubTxt(headerSubTxt)}</p>
      </div>
    </div>
  );
};

export default LeadHeader;
