import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import colors from 'nice-color-palettes';
import { CSVLink } from 'react-csv';
import Table from '../../../Layout/Table';
import TitleDescription from '../../../Layout/DescriptionBox';
import Layout from '../../../Layout/StandardLayout';
import Button from '../../../Layout/Button';
import { setAuthStoreId } from '../../../../reducers/auth';
import { getRequest } from '../../../../core/apiRequests';
import ReusableChart from '../../../Layout/Chart';
import DropdownSelect from '../../../Layout/DropdownSelect';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import RangePicker from '../../../CustomizedAntDesign/RangePicker';
import '../reports.css';

const disabledDate = current => {
  return current && current > moment().endOf('day');
};

const AttributedSales = props => {
  const [dateRange, setDateRange] = useState([moment().subtract(1, 'months'), moment()]);
  const [tableDef, setTableDef] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [chartConfigObj, setChartConfigObj] = useState({});

  const CC_Colors = [
    ['#fc8333', '#feb32c', '#7fbd31', '#009470', '#1769af'],
    ['#803e9f', '#fd0062', '#fe99c0', '#a50087', '#55a4e7'],
    ['#6ee5ea', '#ff6363', '#9ddfff', '#ffd495', '#dcff66'],
    ['#0eb3ba'],
  ];
  const conversationColors = CC_Colors.concat(colors);

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const calculateStep = maxVal => {
    if (maxVal <= 10) {
      return 1;
    }

    if (maxVal < 50) {
      return 5;
    }

    if (maxVal < 100) {
      return 10;
    }

    if (maxVal < 250) {
      return 20;
    }

    if (maxVal < 500) {
      return 25;
    }

    if (maxVal < 1000) {
      return 100;
    }

    if (maxVal < 5000) {
      return 500;
    }

    if (maxVal < 10000) {
      return 1000;
    }

    if (maxVal < 50000) {
      return 5000;
    }

    return 10000;
  };

  const runReport = e => {
    e.preventDefault();

    const reportEnd = moment(dateRange[1]).endOf('month').toISOString();
    const reportStart = moment(dateRange[0]).startOf('month').toISOString();

    const reqObj = {
      params: ['report', 'attributedSales'],
      query: {
        licenseKey: props.auth.licenseKey,
        startDt: reportStart,
        endDt: reportEnd,
        storeAccess: props.auth.stores.filter(st => st.id !== 0).map(st => st.id),
        posType: props.auth.pos_type,
      },
    };
    if (props.auth.storeId) {
      reqObj.query.storeId = props.auth.storeId;
    }
    (async () => {
      const res = {};
      res.data = await getRequest(reqObj);

      const tempTableHeaders = [
        {
          keyName: 'merchant',
          headerClassName: 'row1',
          rowClassName: 'col-p-l-25',
          title: '',
          style: {
            width: '5%',
            paddingLeft: '20px',
            paddingTop: '80px',
            paddingRight: '20px',
          },
        },
      ];

      const newObj = {};
      let currentDate = moment(dateRange[0]).startOf('day');
      const notTheRealEndDate = moment(dateRange[1]);

      const maxVal = 0;

      while (currentDate.isBefore(notTheRealEndDate)) {
        newObj[currentDate.format('YYYYMMM')] = {};
        newObj.merchant = props.auth.licenseKey;

        newObj[currentDate.format('YYYYMMM')].year = Number(currentDate.format('YYYY'));
        newObj[currentDate.format('YYYYMMM')].month = Number(currentDate.format('M'));
        newObj[currentDate.format('YYYYMMM')].sum = 0;
        currentDate = currentDate.add(1, 'M').startOf('month');
      }

      res.data.rows.forEach(rowDate => {
        const threeMonth = moment(rowDate.month, 'M').format('MMM');
        if(newObj[rowDate.year + threeMonth]){
          newObj[rowDate.year + threeMonth].sum = rowDate.sales ? rowDate.sales : 0;
          newObj[rowDate.year + threeMonth].year = rowDate.year;
          newObj[rowDate.year + threeMonth].month = rowDate.month;
        }
      });

      const labelMap = [];

      Object.keys(newObj).forEach(YEARmonth => {
        if (YEARmonth !== 'merchant') {
          labelMap.push(newObj[YEARmonth]);
        }
      });
      const labels = labelMap.map(m => {
        return `${monthNames[m.month - 1]} ${newObj[m.year + moment(m.month, 'M').format('MMM')].year}`;
      });

      const datasets = [];
      const whateverArray = [];
      let currentDateTable = moment(dateRange[0]).startOf('day');

      while (currentDateTable.isBefore(notTheRealEndDate)) {
        const iMonth = currentDateTable.format('YYYYMMM');

        if (newObj[iMonth]) {
          tempTableHeaders.push({
            keyName: iMonth,
            style: { width: '5%', paddingTop: '80px', paddingRight: '10px' },
            headerClassName: 'row4',
            title: `${iMonth.slice(4, 7)} ${newObj[iMonth].year}`,
            sortable: false,
            render: t => {
              if (t) {
                if (t.sum) {
                  return `$${t.sum}`;
                }
                return '$0';
              }
            },
          });
          const value = newObj[iMonth].sum;
          whateverArray.push(value);
        }

        currentDateTable = currentDateTable.add(1, 'M').startOf('month');
      }

      const paletteIdx = Math.trunc(datasets.length / 5);
      const colorIdx = datasets.length % 5;

      datasets.push({
        label: '',
        data: whateverArray,
        fill: false,
        borderColor: conversationColors[paletteIdx][colorIdx],
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: conversationColors[paletteIdx][colorIdx],
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: conversationColors[paletteIdx][colorIdx],
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 3,
        pointHitRadius: 10,
      });

      const tempData = [];
      Object.keys(newObj).forEach(month => {
        if (month !== 'merchant') {
          tempData.push(newObj[month]);
        }
      });

      setDownloadData(tempData);
      setTableHeaders(tempTableHeaders);
      setTableDef([newObj]);

      const step = calculateStep(maxVal);

      setChartConfigObj({
        type: 'line',
        data: {
          labels,
          datasets,
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  stepSize: step,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
      });
    })();

    setShowReport(true);
  };

  return (
    <Layout
      title="Attributed Sales"
      backArrow="/Home"
      description="Set your parameters and click on 'Run Report.'"
      classProps="standard-width-with-sidebar flex-col-center"
      styleProps={{ justifyContent: 'flex-start' }}
    >
      <div className="pos-rel w-100-P " style={{ maxWidth: '866px' }}>
        <TitleDescription
          title="Reporting Location"
          description="What store location would you like to run a report on?"
          input={
            <DropdownSelect
              isSearchable
              classProps="mq-w-200 w-267 align-left"
              options={props.auth.stores.map(store => ({
                label: store.name,
                value: store.id,
              }))}
              placeholder="Select a Store..."
              value={props.auth.storeId}
              onChange={obj => {
                setAuthStoreId(obj.value);
              }}
            />
          }
        />

        <TitleDescription
          title="Date Range"
          input={
            <RangePicker
              format="MM/DD/YYYY"
              disabledDate={disabledDate}
              value={dateRange}
              onChange={setDateRange}
              ranges={{
                'Last 7 Days': [moment().subtract(7, 'd'), moment()],
                'Last 30 Days': [moment().subtract(30, 'd'), moment()],
                'Last 60 Days': [moment().subtract(60, 'd'), moment()],
                'Last 90 Days': [moment().subtract(90, 'd'), moment()],
              }}
              showToday
            />
          }
          className="report-date-picker"
        />

        <div className="report-asct-msg-row" style={{ maxWidth: '866px' }}>
          <Button id="RunAttributedSalesReport" classname="darkBlueButton" onclick={e => runReport(e)}>
            Run Report
          </Button>
        </div>
      </div>
      {showReport ? (
        <div className="w-100-P">
          <div
            className="w-100-P maw-1200"
            style={{
              border: 'solid 1.0px #979797',
              borderRadius: '8px',
              width: '100%',
              position: 'relative',
            }}
          >
            <div>
              <h5 style={{ textAlign: 'center' }}>Attributed Sales by Month</h5>
            </div>
            <div style={{ minHeight: '200px' }}>
              <ReusableChart width="100%" height="400px" configObj={chartConfigObj} />
            </div>
          </div>
          <div
            className="import_export_group"
            style={{
              justifyContent: 'flex-end',
              maxWidth: '1105px',
              zIndex: '100',
              marginTop: '100px',
              marginRight: '30px',
            }}
          >
            <img src="https://dashboard-v2-images.s3.amazonaws.com/ic-export.svg" alt="export" />
            <CSVLink
              data={downloadData}
              filename="AttributedSales.csv"
              id="DownloadAttributedSalesReport"
              className="exportButton m-right-15"
            >
              Download
            </CSVLink>
          </div>
          <Table
            tableClassName="maw-1200 rem-pad-width m-top-15 overflowX hide-scrollbar w-100-P"
            tableContainerClass="w-100-P rem-pad-width"
            style={{ marginTop: '-100px', width: '100%' }}
            data={tableDef}
            tableRowClassName="col-p-l-25"
            tableHeaders={tableHeaders}
            tableName="ATTRIBUTED_SALES"
          />
        </div>
      ) : null}
      <SecondaryPanel
        overhead_title="Reports"
        title={['CLIENTELING', 'MESSAGING', 'Google Reviews', 'PAYMENTS', 'AUTOMATION', 'SALES OPPORTUNITY']}
        current="Attributed Sales"
      />
    </Layout>
  );
};

const mapStateToProps = state => ({
  reports: state.reports,
  auth: state.auth,
  stores: state.stores,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AttributedSales);
