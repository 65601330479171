import React, { useState } from 'react';

const PopMessageTemplate = props => {
  const [clicked, setClicked] = useState(false);

  const onConfirmHandler = () => {
    if (!props.noButtonDisable) {
      setClicked(true);
    }
    props.onConfirm();
  };

  return (
    <div className="pop-confirm-background">
      <div
        className="pop-confirm-container hide-scrollbar p-top-36 p-left-44 p-right-44 rem-pad-width p-btm-6"
        style={props.popStyle}
      >
        <span className="fs-22 fw-500 m-top-37 m-right-15 fs-18">{props.confirm}</span>
        <span className={`fs-13  m-top-37 ${props.customContent ? '' : 'd-inline-block'}`}>{props.description}</span>
        <div
          className="flex-row-nospacebetween-wrap m-top-15 m-btm-28 w-100-P"
          style={{ marginLeft: '5px', justifyContent: 'center' }}
        >
          <button className="cancel-button m-right-15 m-top-15" disabled={clicked} onClick={props.onCancel}>
            CANCEL
          </button>
        </div>
      </div>
    </div>
  );
};
export default PopMessageTemplate;
