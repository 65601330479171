import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import { Collapse, Avatar } from 'antd';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { EditableField } from './Helpers/EditableField';
import { deleteRequest, getRequest, postRequest } from '../../../core/apiRequests';
import LifeEvents from './Helpers/LifeEvents';
import AutoMessages from './Helpers/AutoMessages';
import AutoReminders from './Helpers/AutoReminders';
import Payments from './Helpers/Payments';
import Addresses from './Helpers/Addresses';
import Associates from './Helpers/Associates';
import Preference from './Helpers/Preference';
import {
  setClientEmail,
  setClientMobile,
  setClientStore,
  setClientPreferences,
  setClientOptReqInDate,
} from '../../../reducers/client';
import { insertMyInboxList } from '../../../reducers/messaging';
import Relationships from './Helpers/Relationships/index';
import { getDefaultOptInInv } from '../../../reducers/messageSettings';
import ClientInfo from './Helpers/ClientInfo';
import { notificationSuccess, notificationError } from '../../../core/actions';
import PopConfirm from '../PopConfirm';
import useSearchParams from '../../../hooks/useSearchParams';
import { logRocketEvent } from '../../../utils/logRocketIdentify';

const InfoDrawer = props => {
  const [stores, setStores] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { setValue: setParamValue } = useSearchParams();
  const { Panel } = Collapse;
  const flags = props.auth.merchantFlags;
  const flaggedLifeEvents = flags?.life_events?.enabled;

  const {
    register,
    formState: { dirtyFields, isSubmitting },
    reset,
    resetField,
    getValues,
    handleSubmit,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      clientObj: {
        mobile: props.client.mobile,
        email: props.client.email,
        store_id: props?.client?.store_id,
        store_name: props?.client?.store_name,
        name: props?.client?.name,
        preferred_contact_type: props?.client?.preferred_contact_type,
        preferred_name: props?.client?.preferred_name,
        do_not_contact: props?.client?.do_not_contact,
      },
      addresses: props?.client?.addresses || [],
      LifeEvents: props.client?.lifeEvents || [],
      preferences: props.client?.preferences || [],
    },
  });

  useEffect(() => {
    if (props.location === 'clientDetails') {
      logRocketEvent('ClientDetails');
    }
  }, []);

  useEffect(() => {
    if (props.auth.merchantId) {
      (async () => {
        const reqObj = {
          params: ['stores'],
          query: {},
        };
        const data = await getRequest(reqObj);
        if (props.auth.role === 'ASSOCIATE') {
          const filteredStore = data.filter(st => props.auth.stores.find(s => s.id === st.id));
          setStores(
            filteredStore
              .filter(st => `${st.id}` !== '0')
              .map(store => ({
                label: store.name,
                value: store.id,
              })),
          );
        } else {
          setStores(
            data
              .filter(st => `${st.id}` !== '0')
              .map(store => ({
                label: store.name,
                value: store.id,
              })),
          );
        }
      })();
    }
  }, [props.auth.stores, props.auth.merchantId]);

  const nameShorten = name => {
    if (name) {
      const shortName = [];
      const splitName = name.split(' ');
      splitName.forEach(nm => {
        if (nm && nm.length > 15) {
          shortName.push(`${nm.substr(0, 15)}...`);
        } else {
          shortName.push(`${nm}`);
        }
      });
      return shortName.join(' ');
    }
    return '';
  };

  const sendOptInInvite = useCallback(async () => {
    let formattedMsg = props?.auth?.merchantConfig?.OPT_IN_INVITE || getDefaultOptInInv(props.auth.brandName || '');
    if (props?.client?.name) {
      formattedMsg = formattedMsg.replace(/:name:/g, props?.client?.name.split(' ')[0]);
    }
    formattedMsg = formattedMsg.replace(/:associate:/g, props.auth.firstName);

    const bodyData = {
      mobile: props?.client?.mobile,
      store_id: props.auth.selectedStore,
      associateId: props.auth.userId,
      clientId: props?.client?.client_id,
      sendOptIn: true,
    };
    const reqObj = {
      params: ['clients', 'inviteOptIn'],
      data: bodyData,
    };
    try {
      await postRequest(reqObj);
      notificationSuccess('Invite sent');
      setClientOptReqInDate(moment().format('YYYYMMDDHHmmss'));
      insertMyInboxList({
        client_id: props.client.client_id,
        direction: 'OUTGOING',
        msg: formattedMsg,
        user_id: props.auth.userId,
        client_name: props.client.name,
        user_name: `${props.auth.firstName || ''} ${props.auth.lastName || ''}`,
      });
    } catch (e) {
      notificationError('Error sending invite');
    }
  }, [props.auth, props.client, getDefaultOptInInv]);

  const mktStatusParser = () => {
    const returnArr = [];
    if (props.client.opt_out_date) {
      returnArr.push(
        <div key="mktStatus1">
          <span
            className="circle m-right-5 d-inline-block"
            style={{ backgroundColor: '#FF7A7A', width: '6px', height: '6px' }}
          />
          <span>Declined</span>
        </div>,
      );
    } else if (props.client.opt_in_date) {
      returnArr.push(
        <div key="mktStatus2">
          <span
            className="circle m-right-5 d-inline-block"
            style={{ backgroundColor: '#7FBD31', width: '6px', height: '6px' }}
          />
          <span>Confirmed</span>
        </div>,
      );
    }
    if (props.client.opt_in_req_date && props.client.opt_in_date === null && props.client.opt_out_date === null) {
      returnArr.push(
        <div key="mktStatus3">
          <span
            className="circle m-right-5 d-inline-block"
            style={{ backgroundColor: '#F3B64C', width: '6px', height: '6px' }}
          />
          <span>Waiting Response</span>
        </div>,
      );
    }
    if (
      props.client.opt_in_req_date === null &&
      props.client.opt_in_date === null &&
      props.client.opt_out_date === null
    ) {
      returnArr.push(
        <div key="mktStatus4">
          <span
            className="circle m-right-5 d-inline-block"
            style={{ backgroundColor: '#FC8333', width: '6px', height: '6px' }}
          />
          <span>Needs Invite</span>
        </div>,
      );
    }
    return returnArr;
  };
  const actionText = () => {
    if (props.client.opt_in_req_date && props.client.opt_in_date === null && props.client.opt_out_date === null) {
      return 'Resend';
    }
    if (
      props.client.opt_in_req_date === null &&
      props.client.opt_in_date === null &&
      props.client.opt_out_date === null
    ) {
      return 'Invite';
    }
  };

  useEffect(() => {
    if (props.client.client_id) {
      reset({
        clientObj: {
          mobile: props.client.mobile,
          email: props.client.email,
          store_id: props?.client?.store_id,
          store_name: props?.client?.store_name,
          name: props?.client?.name,
          preferred_contact_type: props?.client?.preferred_contact_type,
          preferred_name: props?.client.preferred_name,
          do_not_contact: props?.client?.do_not_contact,
        },
        addresses: props.client.addresses,
        preferences: props.client.preferences,
        lifeEvents: props.client.lifeEvents,
      });
    }
  }, [props.client.client_id, props.client.preferences, props.client.mixedRelationships]);

  useEffect(() => {
    if (props.auth.merchantId && props.client.client_id) {
      (async () => {
        const reqObjClient = {
          params: ['clients', props.client.client_id, props.auth.merchantId, 'clientDetailsPreferences'],
        };
        const dataClient = await getRequest(reqObjClient);
        const reqObjMerchant = {
          params: ['clients', props.auth.merchantId, 0, 'clientPreferences'],
        };
        const data = await getRequest(reqObjMerchant);
        const formattedData = data[0]?.preferenceData?.map(item => {
          const prefClient = dataClient.find(i => i.config_id == item.config_id);
          if (prefClient) {
            return {
              ...item,
              value: prefClient.value,
              personalization_id: prefClient.personalization_id,
            };
          }
          return { ...item };
        });
        setClientPreferences(formattedData);
      })();
    }
  }, [props.auth.merchantId, props.client.client_id]);

  useEffect(() => {
    if (props.auth.merchantId && props.client.client_id) {
      (async () => {
        const reqObjClient = {
          params: ['clients', props.client.client_id, props.auth.merchantId, 'clientDetailsPreferences'],
        };
        const dataClient = await getRequest(reqObjClient);
        const reqObjMerchant = {
          params: ['clients', props.auth.merchantId, 0, 'clientPreferences'],
        };
        const data = await getRequest(reqObjMerchant);
        const formattedData = data[0]?.preferenceData?.map(item => {
          const prefClient = dataClient.find(i => i.config_id == item.config_id);
          if (prefClient) {
            return {
              ...item,
              value: prefClient.value,
              personalization_id: prefClient.personalization_id,
            };
          }
          return { ...item };
        });
        setClientPreferences(formattedData);
      })();
    }
  }, [props.auth.merchantId, props.client.client_id]);

  const preferredStore = () => {
    const storeName = props.auth.stores.map(store => {
      if (store.id === getValues('clientObj.store_id')) {
        return store.name;
      }
    });
    return storeName;
  };

  const onSubmit = async data => {
    console.log('submit');
  };

  const saveClientInfo = useCallback(
    async data => {
      if (!dirtyFields?.clientObj) {
        return;
      }
      const dataObj = {
        clientObj: {
          mobile: {},
          email: {},
          store_id: {},
          store_name: {},
        },
        client_id: props.client.client_id,
      };
      // client obj
      if (data?.clientObj) {
        Object.keys(data.clientObj).forEach(key => {
          if (key === 'store_name' && dirtyFields?.clientObj?.store_id) {
            const findStore = stores.find(store => store.value === data.clientObj.store_id);
            if (findStore) {
              dataObj.clientObj.store_name = {
                value: findStore.label,
                hasChanged: true,
              };
            }
          } else {
            dataObj.clientObj[key] = {
              value: data.clientObj[key],
              hasChanged: dirtyFields?.clientObj[key] || false,
            };
          }
        });
      }
      const reqObj = {
        params: ['clients', props.messaging.client.client_id, 'editClientInformation'],
        data: dataObj,
      };
      try {
        await postRequest(reqObj);
        setClientStore(data?.clientObj?.store_id);
        setClientMobile(data?.clientObj?.mobile);
        setClientEmail(data?.clientObj?.email);
      } catch (err) {
        // console.log(err);
      }
    },
    [dirtyFields],
  );

  const infoPanel = useMemo(() => {
    const components = [
      {
        key: '7',
        id: 'associates-panel',
        header: 'Associates',
        component: <Associates merchantId={props.auth.merchantId} client={props.client} />,
      },
      {
        key: '1',
        id: 'relationships-panel',
        header: 'Relationships',
        component: (
          <Relationships
            merchantId={props.auth.merchantId}
            auth={props.auth}
            client={props.client}
            register={register}
            getValues={getValues}
            resetField={resetField}
            dirtyFields={dirtyFields}
            setValue={setValue}
            control={control}
            relationships={props.client.mixedRelationships}
          />
        ),
      },
      ...(flaggedLifeEvents
        ? [
            {
              key: '2',
              id: 'life-events-panel',
              header: 'Life Events',
              component: (
                <LifeEvents
                  merchantId={props.auth.merchantId}
                  auth={props.auth}
                  client={props.client}
                  register={register}
                  lifeEvents={getValues('lifeEvents')}
                  resetField={resetField}
                  dirtyFields={dirtyFields}
                  setValue={setValue}
                  control={control}
                />
              ),
            },
          ]
        : []),
      {
        key: '3',
        id: 'auto-messages-panel',
        header: 'Upcoming Auto Messages',
        component: <AutoMessages merchantId={props.auth.merchantId} id="upcoming-auto-messages" />,
      },
      {
        key: '4',
        id: 'reminders-panel',
        header: 'Upcoming Reminders',
        component: <AutoReminders merchantId={props.auth.merchantId} userId={props.auth.userId} />,
      },
      {
        key: '5',
        id: 'payments-panel',
        header: 'Payments',
        component: <Payments merchantId={props.auth.merchantId} />,
      },
      {
        key: '6',
        id: 'addresses-panel',
        header: 'Addresses',
        component: (
          <Addresses
            merchantId={props.auth.merchantId}
            client={props.client}
            register={register}
            addresses={getValues('addresses')}
            resetField={resetField}
            dirtyFields={dirtyFields}
            setValue={setValue}
          />
        ),
      },
      {
        key: '8',
        id: 'preference-panel',
        header: 'Preferences',
        component: (
          <Preference
            merchantId={props.auth.merchantId}
            client={props.client}
            register={register}
            preferences={getValues('preferences')}
            resetField={resetField}
            dirtyFields={dirtyFields}
          />
        ),
      },
    ];

    if (props.auth.role === 'ADMIN' || props.auth.role === 'MANAGER') {
      components.push({
        key: '9',
        id: 'delete-button',
        header: null,
        component: (
          <div className="flex-col-left" style={{ borderTop: '1px #ebebeb solid' }}>
            <button
              className="deleteButton m-top-15 pointer"
              type="button"
              onClick={() => {
                setShowDeleteModal(true);
              }}
            >
              Delete
            </button>
          </div>
        ),
      });
    }

    return components;
  }, [
    props,
    getValues('addresses'),
    getValues('preferences'),
    getValues('lifeEvents'),
    getValues('relationships'),
    resetField,
    dirtyFields,
    register,
    control,
    setValue,
  ]);

  const handleDelete = useCallback(() => {
    const { merchantId, userId } = props.auth;
    const reqObj = {
      params: ['clients', merchantId, props.client.client_id, userId, 'deleteClient'],
    };
    (async () => {
      const resp = await deleteRequest(reqObj);
      if (resp) {
        notificationSuccess('Client Removed');
        setShowDeleteModal(false);
        setParamValue('client', '');
        setParamValue('clientTab', '');
        setParamValue('collectionId', '');
        setParamValue('activityFilter', '');
        reset({
          clientObj: {},
          addresses: [],
          preferences: [],
          lifeEvents: [],
        });
        props.removeClientOfList(parseInt(props.client.client_id));
      }
    })();
  }, [props.client.client_id, props.auth.merchantId, props.auth.userId, setParamValue, props.removeClientOfList]);

  return (
    <div style={{ width: '300px' }} className="three-column-child-4 clientInfoDrawerDiv">
      <form className="p-btm-48" onSubmit={handleSubmit(onSubmit)}>
        {/* header */}
        <ClientInfo
          iso_country_code={props.auth.iso_country_code}
          stores={props.auth.stores}
          smsStoreNum
          merchantId={props.auth.merchantId}
          client={props.client}
          messaging={props.messaging}
          register={register}
          control={control}
          clientObj={getValues('clientObj')}
          resetField={resetField}
          dirtyFields={dirtyFields}
          setValue={setValue}
          location={props.location}
        />
        {/* PREFERRED STORE */}
        <EditableField
          label="Preferred Store"
          value={preferredStore() || 'Preferred store not set'}
          icon="fa-light fa-location-dot"
          containerClassName="p-top-8 m-left-13 m-btm-8"
          iconClassName="m-right-6"
          type="select"
          control={control}
          register={register('clientObj.store_id')}
          reset={() => resetField('clientObj.store_id')}
          onSave={handleSubmit(saveClientInfo)}
          selectProps={{
            options: stores,
          }}
          isSubmitting={isSubmitting}
        />
        {/* MARKETING STATUS */}
        {props.client.preferred_contact_type === 'SMS' ? (
          <EditableField
            label="Marketing Status"
            value={mktStatusParser()}
            containerClassName="m-top-15 m-left-13 m-btm-8"
            editable={false}
            actionText={actionText()}
            action={sendOptInInvite}
          />
        ) : null}
        {/* dropdown */}
        {infoPanel.map(panel => {
          if (props.panels.includes(panel.key)) {
            return (
              <div className="p-left-15 p-right-15 m-btm-15" id={panel.id} key={panel.key}>
                {panel.component}
              </div>
            );
          }
        })}
        {showDeleteModal && (
          <PopConfirm
            confirm={`Are you sure you want to delete ${props.client.preferred_name || props.client.name}`}
            description={`This will remove ${props.client.preferred_name || props.client.name} from your client list`}
            onCancel={() => setShowDeleteModal(false)}
            onConfirm={handleDelete}
          />
        )}
      </form>
    </div>
  );
};
const mapStateToProps = state => ({
  newMessages: state.navigation.newMessages,
  messaging: state.messaging,
  auth: state.auth,
  client: state.client,
});
export default connect(mapStateToProps)(InfoDrawer);
