import { postRequest, deleteRequest } from '../core/apiRequests';
import request from '../utils/request';

// available params: limit, page, search
export const getClientCollections = ({ clientId, params = {} }) => {
  return request({
    url: `/clients/${clientId}/collections`,
    method: 'GET',
    params,
  });
};

export const getClientCollection = ({ clientId, collectionId }) => {
  return request({
    method: 'GET',
    url: `/clients/${clientId}/collections/${collectionId}`,
  });
};

export const addProductToClientCollection = ({ clientId, collectionId, data }) => {
  const reqObj = {
    params: ['clients', clientId, 'collections', collectionId, 'products'],
    data,
  }
  return postRequest(reqObj)
};

export const deleteCollection = ({ clientId, collectionId }) => {
  const reqObj = {
    params: ['clients', clientId, 'collections', collectionId, 'delete'],
  }
  return deleteRequest(reqObj)
};

export const deleteProductFromCollection = ({ clientId, collectionId, productId }) => {
  return request({
    url: `/clients/${clientId}/collections/${collectionId}/deleteProduct/${productId}`,
    method: 'DELETE',
  });
};

export const deleteVendorItemFromCollection = ({ clientId, collectionId, vendorItemId }) => {
  return request({
    url: `/clients/${clientId}/collections/${collectionId}/vendorItems/${vendorItemId}`,
    method: 'DELETE',
  });
};

export const renameClientCollection = ({ clientId, collectionId, data }) => {
  return request({
    url: `/clients/${clientId}/collections/${collectionId}/rename-collection`,
    method: 'POST',
    data,
  });
};

export const getClientRelationships = ({ merchantId, clientId }) => {
  return request({
    method: 'GET',
    url: `/clients/${clientId}/${merchantId}/clientRelationships`,
  });
};
