import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import { notificationError } from '../../../../../../core/actions';
import Input from '../../../../../CustomizedAntDesign/Input/Input';
import PopConfirm from '../../../../PopConfirm';
import whatsappTextFormatter from './whatsappTextFormatter';

const WAPlaceHolderModal = ({ visible, setVisible, activeMsg, setActiveMsg, resetActiveTemplate, changeHandler }) => {
  const possiblePlaceholders = ['product', 'date', 'interval', 'client', 'associate'];
  const { formattedText } = activeMsg;
  const [finalMsg, setFinalMsg] = useState('');
  const [confirmedInputs, setConfirmedInputs] = useState([]);

  useEffect(() => {
    if (formattedText) {
      determineMissingValues();
      setFinalMsg(activeMsg.formattedText);
    }
  }, [activeMsg.formattedText]);

  const determineMissingValues = () => {
    const confirmedPlaceHolders = [];
    const newPHValues = {};
    possiblePlaceholders.forEach(ph => {
      if (formattedText.includes(`[${ph}]`)) {
        confirmedPlaceHolders.push(ph);
        newPHValues[ph] = `[${ph}]`;
      }
    });

    if (confirmedPlaceHolders.length > 0) {
      const newActiveMsgObj = {
        ...activeMsg,
        placeHolderValues: { ...activeMsg.placeHolderValues, ...newPHValues },
      };
      setActiveMsg(newActiveMsgObj);
      setConfirmedInputs([...confirmedPlaceHolders]);
    } else {
      setActiveMsg({ ...activeMsg, complete: true });
      changeHandler(activeMsg.formattedText);
      setVisible(false); // else nothing to update - no missing placeholders
    }
  };

  const inputStateHandler = (property, value) => {
    const valueToUse = value || `[${property}]`;
    const newActiveMsgObj = {
      ...activeMsg,
      placeHolderValues: { ...activeMsg.placeHolderValues, [property]: valueToUse },
    };
    setActiveMsg(newActiveMsgObj);
    setTimeout(() => {
      setFinalMsg(whatsappTextFormatter(activeMsg, { ...activeMsg.placeHolderValues, [property]: valueToUse }));
    }, 20);
  };

  const confirmPlaceHolders = () => {
    if (confirmedInputs.filter(inpt => finalMsg.includes(`[${inpt}]`)).length > 0) {
      notificationError('Please Type Placeholders!');
    } else {
      setActiveMsg({ ...activeMsg, complete: true, formattedText: finalMsg });
      changeHandler(finalMsg);
    }
  };

  return (
    <Drawer
      closable={false}
      push={false}
      width={500}
      open={visible}
      onClose={() => setVisible(false)}
      title="WhatsApp Templates"
    >
      <div className="flex-col-left w-100-P align-center">
        <div className="">{finalMsg || ''}</div>
        <div className="flex-col-center w-100-P m-top-15">
          {confirmedInputs.map(inputType => (
            <Input
              className="w-100-P m-btm-20"
              key={inputType}
              placeholder={inputType}
              onChange={e => inputStateHandler(inputType, e.target.value)}
            />
          ))}
        </div>
        <div className="flex-row-nospacebetween-nowrap m-top-15 w-100-P" style={{ justifyContent: 'center' }}>
          <button className="cancel-button m-right-8" style={{ width: '125px' }} onClick={() => resetActiveTemplate()}>
            CANCEL
          </button>
          <button className="save-button m-left-8" style={{ width: '125px' }} onClick={() => confirmPlaceHolders()}>
            CONFIRM
          </button>
        </div>
      </div>
    </Drawer>
  );
};
export default WAPlaceHolderModal;
