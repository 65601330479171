import React, { useCallback, useEffect, useMemo, useState } from 'react';
import KioskSlider from '../../../../../../Layout/KioskSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownSelect from '../../../../../../Layout/DropdownSelect';
import { components } from 'react-select'
import { Tooltip } from 'antd';
import PopConfirm from '../../../../../../Layout/PopConfirm';
import { getRequest, postRequest } from '../../../../../../../core/apiRequests';
import { useOnce } from '../../../../../../../hooks/useOnce';
import { connect } from 'react-redux';
import SecondaryPanel from '../../../../../../Layout/SecondaryPanel';
import StandardLayout from '../../../../../../Layout/StandardLayout';
import Chip from '../../../../../../Layout/Chip';
import IntegrationsGooglePopConfirm from '../../Details/components/GooglePopConfirm'
import { Redirect } from 'react-router';

const IntegrationsGoogleSettings = ({auth}) => {

  const installed = useMemo(() => (auth?.google_integration), [auth]);
  const googleAuthUrl = useMemo(() => {
    const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

    const options = {
      redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
      scope: 'https://www.googleapis.com/auth/business.manage',
      state: auth.uuid,
    };

    const qs = new URLSearchParams(options);

    return `${rootUrl}?${qs}`;
  }, [auth.uuid]);

  const [showGoogleConfirmModal, setShowGoogleConfirmModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [pastMessages, setPastMessages] = useState(false);
  const [synced, setSynced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [optionsPages, setOptionsPages] = useState([]);
  const [values, setValues] = useState({});
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);


  const handleAddAccount = useCallback(() => {
    setShowGoogleConfirmModal(true);

    setTimeout(() => {
      window.open(googleAuthUrl, '_blank');
    }, 5000);
  }, [googleAuthUrl])

  const getGoogleLocation = useCallback(async () => {
    const reqObj = {
      params: ['integrations', 'google', 'locations'],
    }
    const data = await getRequest(reqObj);
    if( data ) {
      setLocations(data);
    }
  }, [])

  const { call } = useOnce(getGoogleLocation);
  useEffect(() => {
    call()
  }, [])


  useEffect(() => {
    let options = [
      {
        options: [
          {
            label: 'Unmapped',
            value: 'unmapped',
          }
        ]
      }
    ]
    if (locations.length) {
      locations.forEach(location => {
        const findIndex = options.findIndex(opt => opt?.extRefId === location?.accountId)
        if(findIndex !== -1) {
          options[findIndex]?.options.push({
            label: location?.title || '',
                value: location?.extRefId,
                disabled: location.storeId !== null,
                accountId: location?.accountId
          })
        } else {
          options.push({
            label: location.google_account_name,
            extRefId: location?.accountId,
            value: location?.accountId,
            options: [
              {
                label: location?.title || '',
                value: location?.extRefId,
                disabled: location.storeId !== null,
                accountId: location?.accountId
              }
            ]
          })
        }
      })
    }
    options = [
      ...options,
      {
        label: <button type="button" className="link-button">Add account</button>,
        value: 'add-account'
      }
    ]
    setOptionsPages(options);
    let storesTemp = {};
    auth.stores.forEach(store => {
      const findLocation = locations.find(location => location.storeId === store.id)
      storesTemp[store.id] =  findLocation ?{
        label: findLocation?.title || '',
        value: findLocation?.extRefId,
        disabled: findLocation.storeId !== null,
        accountId: findLocation?.accountId
      } : null;
    })
    setValues(storesTemp)
  }, [locations, auth]);

  const handleChange = useCallback((value, storeId) => {
    if(value.disabled) return;
    if(value.value === 'add-account') {
      handleAddAccount()
    } else {
      if(values.hasOwnProperty(storeId)) {
        setValues(prevState => ({
          ...prevState,
          [storeId]: value
        }))
      }
    }
  }, [values, handleAddAccount])

  useEffect(() => {
    let countSynced = 0;
    Object.keys(values).forEach(key => {
      if(values[key]?.value && values[key]?.value !== 'unmapped' && values[key]?.value !== 'add-account') {
        countSynced = countSynced + 1;
      }
    })
    if(countSynced > 0 ) {
      setSynced(true)
    } else {
      setSynced(false)
    }
  }, [values])

  useEffect(() => {
    let updateOptions = [...optionsPages];
    let valuesTemp = [];
    Object.keys(values).forEach(key => {
      if(values[key]?.value && values[key]?.value !== 'unmapped' && values[key]?.value !== 'add-account') {
        valuesTemp.push(values[key]?.value)
      }
    })
    updateOptions.forEach((opt, index) => {
      if(opt.extRefId) {
        updateOptions[index].options = updateOptions[index].options.map(o => ({
          ...o,
          disabled: valuesTemp.includes(o.value)
        }))
      }
    })
    setOptionsPages(updateOptions)
  }, [values]);

  const handleSave = useCallback(async () => {
    const data = [];
    Object.keys(values).forEach(key => {
      if(key !== '0')
      data.push({
        storeId: key,
        accountId: values[key]?.accountId || null,
        extRefId: values[key]?.value || null,
        title: values[key]?.label || null,
        pastMessages
      })
    })
    const reqObj = {
      params: ['integrations', 'google', 'locations'],
      data
    }
    await postRequest(reqObj)
    setShowSubmitPopup(false);
  }, [values, pastMessages])

  const handleSubmit = useCallback(() => {
    let countSynced = 0;
    Object.keys(values).forEach(key => {
      if(values[key]?.value && values[key]?.value !== 'unmapped' && values[key]?.value !== 'add-account') {
        countSynced = countSynced + 1;
      }
    })
    if(countSynced !== (auth.stores.filter(store => store.id !== 0).length)) {
      setShowSubmitPopup(true);
    } else {
      handleSave();
    }
  }, [handleSave, values, auth.stores]);


  return (
    installed ? (
      <div className="cb-integrations-settings">
        <StandardLayout
          titleImage='https://dashboard-v2-images.s3.amazonaws.com/google-logo.png'
          titleImageSize="xl"
          actions={<Chip>Installed</Chip>}
          backLink="/help/integrations"
          classProps="standard-width-with-sidebar"
        >
          <div className="cb-integrations-settings__body">
            <SecondaryPanel title={['SETTINGS', 'HELP', 'PROFILE']} current="Integrations" />
            <section className="cb-integrations-details__section">
              <h3 className="cb-integrations-details__title">Store Mapping</h3>
              <p className='cb-integrations-details-google__p'>At least one store MUST be mapped in order to sync your data</p>
            </section>
            <section className="cb-integrations-details__section">
              <div className='cb-integrations-details-google__container'>
                {loading ? (
                  <div className='flex-col-center'>
                    <FontAwesomeIcon style={{ color: '#919191' }} icon={['far', 'spinner-third']} spin={true} className="fs-24 m-btm-16 d-grid-stack" />
                    <p className="cb-integrations-details-google__p">
                      Loading store info...
                    </p>
                  </div>
                ) : (
                  <div className='flex-col-center-start h-100-P w-100-P'>
                    <div style={{ gap: 16 }} className="flex-row-center-center w-100-P m-btm-16">
                      <div className="cb-integrations-details-google__items">
                        Stores in Clientbook
                      </div>
                      <div className="cb-integrations-details-google__items">
                        Stores in Google
                      </div>
                    </div>
                    {auth.stores.filter(s => s.id !== 0).map((store) => (
                      <div key={store.id} style={{ gap: 16 }} className="flex-row-center-center w-100-P m-btm-8">
                        <div className="cb-integrations-details-google__items">
                          {store.name}
                          <FontAwesomeIcon
                            icon={['far', 'arrow-right']}
                          />
                        </div>
                        <div className="cb-integrations-details-google__items">
                          <DropdownSelect
                            classProps="w-100-P"
                            placeholder="Unmapped"
                            formatGroupLabel={formatGroupLabel}
                            componentProps={{ Option: (optionsProps) => CustomOption({...optionsProps}) }}
                            options={optionsPages}
                            onChange={val => handleChange(val, store.id)}
                            value={values[store.id]}
                            isGroup
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </section>
            <section className="cb-integrations-details__section">
              <h3 className={`cb-integrations-details__title${!synced ? '-google-unsynced' : ''}`}>Sync Data</h3>
              <p className={`cb-integrations-details-google__p${!synced ? '-unsynced' : ''}`}>The initial sync may take up to 30 minutes. While the sync is ongoing, you can leave this page and return later.</p>
            </section>
            <section className="cb-integrations-details__section">
              <div className="flex-row-center">
                <KioskSlider
                  value={pastMessages}
                  onChange={(value) => setPastMessages(value)}
                  disabled={!synced}
                />
                <div className="flex-col-left-center m-left-23">
                  <p className={`cb-integrations-details-google__p${!synced ? '-unsynced' : ''}`} style={{ fontSize: '1rem'}}>Past messages</p>
                  <p className={`cb-integrations-details-google__p${!synced ? '-unsynced' : ''}`}>Unsynced</p>
                </div>
              </div>
            </section>
            <section className="cb-integrations-details__section">
              <div className="w-100-P flex-col-center">
                <button type="button" className="save-button-v2 m-btm-8" disabled={loading} onClick={handleSubmit}>
                  Submit
                </button>
                <button type="button" className="error-button m-btm-8" disabled={loading} onClick={() => {}}>
                  Uninstall
                </button>
              </div>
            </section>
            <IntegrationsGooglePopConfirm
              visible={showGoogleConfirmModal}
              authUrl={googleAuthUrl}
              onClose={() => setShowGoogleConfirmModal(false)}
            />
            {showSubmitPopup && (
              <PopConfirm
                popStyle={{ maxWidth: '540px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}
                confirm="Are you sure you want to proceed?"
                description="You have not chosen a mapping for each store location."
                onCancel={() => setShowSubmitPopup(false)}
                onConfirm={handleSave}
                descriptionStyle={{ margin: '0 0 8px', textAlign: 'center', fontStyle: 'italic', fontWeight: 400, fontSize: '12px' }}
                confirmButtonText="Continue"
              >
                <div className='fs-13 fw-500'>Doing so is not recommended and may lead to future data issues.</div>
              </PopConfirm>
            )}
          </div>
        </StandardLayout>
      </div>
    ) : (
      <Redirect to="/help/integrations" />
    )
  );
};

const formatGroupLabel = (data) => (
  <div >
    <span>{data.label}</span>
  </div>
);

const { Option } = components;

const CustomOption = ({ isDisabled, data, ...rest }) => {
  return !data?.disabled ? (
    <Option isDisabled={data?.disabled}  {...rest} className={`${rest.selectProps.className} fw-500 fs-13 ${!['unmapped', 'add-account'].includes(rest.value) > 0 ? 'p-left-30-imp' : ''}`}  />
  ) : (
    <Tooltip
      placement="bottomLeft"
      overlayInnerStyle={{ borderRadius: '10px', background: '#454545', padding: '10px 16px' }}
      zIndex={99999999}
      title="This store is already mapped. Please unmap to use."
    >
      <div>
        <Option {...rest} isDisabled={data?.disabled} className={`${rest.selectProps.className} fw-500 fs-13 ${!['unmapped', 'add-account'].includes(rest.value) > 0 ? 'p-left-30-imp' : ''}`} />
      </div>
    </Tooltip>
  )};




const mapStateToProps = state => ({
  uuid: state.auth.uuid,
  pos_type: state.auth.pos_type,
  auth: state.auth,
});

export default connect(mapStateToProps, {})(IntegrationsGoogleSettings);

