export const getLocalStorageItem = key => {
  const item = localStorage.getItem(key);

  if (!item) return undefined;

  try {
    return JSON.parse(item);
  } catch (error) {
    return item;
  }
};
