import React from 'react';
import { TableOutlined, UnorderedListOutlined } from '@ant-design/icons';
import RadioGroup from '../../../../CustomizedAntDesign/RadioGroup';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';

const TableViewSelector = ({ value, onChange }) => {
  return (
    <div style={{ marginRight: 2, cursor: 'pointer'}} >
      {value === 'grid' ? (
        <Tooltip placement="bottom" title={'Switch to list view'} overlayInnerStyle={{ borderRadius: '12px'}}>
          <FontAwesomeIcon icon={['fas', 'list']} style={{ fontSize: '20px', color: '#1769AF' }} onClick={() => onChange('list')} />
        </Tooltip>
      ) : (
        <Tooltip placement="bottom" title={'Switch to grid view'} overlayInnerStyle={{ borderRadius: '12px'}}>
          <FontAwesomeIcon icon={['far', 'grid-2']} style={{ fontSize: '20px', color: '#1769AF' }} onClick={() => onChange('grid')} />
        </Tooltip>
      )}
    </div>
  );
};

export default TableViewSelector;
