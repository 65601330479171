import React, { useCallback } from 'react';
import { notificationError } from '../../../../../../../core/actions';


const NameCollectionModal = ({onClose, collection, onSaveCollection}) => {
  const [name, setName] = React.useState(collection?.name || '');

  const saveCollectionHandler = useCallback(() => {
    if (name !== '') {
      onSaveCollection(name);
    } else {
      notificationError('Please enter a collection name');
    }
  }, [name, onSaveCollection]);

  return (
    <div
      className="pop-confirm-background"
      id="modal-background"
    >
      <div
        className="pop-confirm-container rem-pad-width"
        style={{ width: '404px', minHeight: '278px', maxHeight: '474px', overflowY: 'hidden' }}
      >
        <div
          className="flex-col-center h-100-P"
          style={{
            justifyContent: 'flex-start',
            width: '295px',
            margin: '0 auto',
          }}
        >
            <div className="flex-row-spacebetween-nowrap w-100-P m-top-15" />
            <span className="fs-18 fw-500" style={{ marginTop: '15px' }}>
              {collection?.collection_id ? 'Rename' : 'New Collection'}
            </span>
            <p className='w-100-P align-left m-top-9 fw-500 fs-14'>
              Collection Name
            </p>
            <input
              value={name}
              onChange={e => setName(e.target.value )}
              classprops="w-295 p-top-15"
              style={{
                width: '295px',
                height: '50px',
                borderRadius: '3px',
                border: '1px solid #818181',
                boxSizing: 'border-box',
                paddingLeft: '16px',
              }}
              placeholder="Collection name..."
            />
          <div className="mq-w-100-vw custom-action-save-flex" style={{ marginTop: '30px' }}>
            <button className="save-button-v2 m-btm-8" onClick={saveCollectionHandler} disabled={name === ''}>
              Save
            </button>
            <button className="cancel-button-v2 m-btm-8" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NameCollectionModal;
