import React, { useCallback, useState } from 'react';
import { Modal, message } from 'antd-v5';
import { deleteProductById } from '../../../api/products';

const DeleteProductModal = props => {
  const { open, setOpen, productId, onSuccess, onError, ...rest } = props;

  const [loading, setLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    setLoading(true);

    try {
      const response = await deleteProductById(productId);

      message.success('Product deleted successfully');

      if (onSuccess) {
        onSuccess(response.data);
      }

      setOpen(false);
    } catch (error) {
      message.error('Error deleting product');

      if (onError) {
        onError(error);
      }
    } finally {
      setLoading(false);
    }
  }, [productId]);

  return (
    <Modal
      {...rest}
      open={open}
      title="Delete Product"
      okText="Delete"
      onOk={handleDelete}
      onCancel={() => setOpen(false)}
      okButtonProps={{ loading, danger: true }}
      cancelButtonProps={{ disabled: loading }}
      width={350}
      destroyOnClose
    >
      Are you sure you want to delete this product?
    </Modal>
  );
};

export default DeleteProductModal;
