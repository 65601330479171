import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button } from 'antd';
import SearchBar from '../../../SearchBar';
import usePaginatedRequest from '../../../../../hooks/usePaginatedRequest';
import ChecklistDropdownPopoverList from '../ChecklistDropdownPopoverList';
import './styles.css';

const Loading = () => {
  return <h4 style={{ margin: '12px 16px' }}>Loading...</h4>;
};

const ListEnd = () => {
  return <h4 style={{ margin: '12px 16px' }}>No more conversations</h4>;
};

const ChecklistDropdownPopover = props => {
  const { label, actionButtonLabel, itemsChecked, setItemsChecked, requestMethod, onChange } = props;

  const [items, setItems] = useState([]);

  const { page, setPage, search, setSearch, total, results, loading } = usePaginatedRequest({
    limit: 50,
    mergeResults: true,
    requestMethod,
  });

  const handleChange = key => {
    const newItemsChecked = { ...itemsChecked };

    if (newItemsChecked[key]) {
      delete newItemsChecked[key];
    } else {
      newItemsChecked[key] = true;
    }

    setItemsChecked(newItemsChecked);
  };

  const handleClickSave = () => {
    onChange([...Object.keys(itemsChecked)]);
  };

  useEffect(() => {
    setItems(new Set([...Object.keys(itemsChecked), ...results]));
  }, [results, itemsChecked]);

  return (
    <div className="cb-checklist-dropdown-popover">
      <SearchBar value={search} onChange={setSearch} placeholder={`Search ${label.toLowerCase()}`} />

      <div className="cb-checklist-dropdown-popover__list">
        <InfiniteScroll
          dataLength={results.length}
          next={() => setPage(page + 1)}
          hasMore={results.length < total}
          loader={loading && <Loading />}
          endMessage={!loading && results.length && <ListEnd />}
          height={264}
        >
          <ChecklistDropdownPopoverList items={items} itemsChecked={itemsChecked} onChange={handleChange} />
        </InfiniteScroll>
      </div>

      <Button type="primary" block className="cb-checklist-dropdown-popover__button" onClick={handleClickSave}>
        {actionButtonLabel || 'Save'}
      </Button>
    </div>
  );
};

export default ChecklistDropdownPopover;
