import React, { useEffect, useRef, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import Button from '../../../Layout/Button';
import Layout from '../../../Layout/StandardLayout';
import KioskSlider from '../../../Layout/KioskSlider';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import TitleDescription from '../../../Layout/DescriptionBox';
import TextAreaWithActions from '../../../Layout/TextAreaWithActions';
import { bodyFormatter, messageDispatcher } from './largeMsgSettingFns';
import { saveMessageSettings, getMessageSettings } from '../../../../core/apiRequests';
import {
  setDoubleOptIn,
  setDelayOptIn,
  setAutoAsA2p,
  setOptInInv,
  setOptInConfirm,
  setMsgSettingsObject,
  setOptInBulkInvite,
  setReviewMessage,
} from '../../../../reducers/messageSettings';
import { setAllStoreConversationHistory, setAuthBrandName, setDeclutteredInbox } from '../../../../reducers/auth';

import './messageSettings.css';
import { notificationError } from '../../../../core/actions';
import DropdownSelect from '../../../Layout/DropdownSelect';
import useActionTextAreaFiles, {
  actionTextAreaFileActions,
} from '../../../Layout/TextAreaWithActions/useActionTextAreaFiles';
import { useOnce } from '../../../../hooks/useOnce';

const MessageSettings = props => {
  // const [dftStore, setDftStore] = useState(); // currently unused
  const [dataReceived, setDataReceived] = useState(false);
  const isMounted = useRef(null);
  const [storeList, setStoreList] = useState([]);
  const [fileData, fileDispatch] = useActionTextAreaFiles();
  const flags = useFlags();

  const getMessageConfig = async () => {
    const data = await getMessageSettings('merchant', props.merchantId);
    if (data) {
      fileDispatch({
        type: actionTextAreaFileActions.setImages,
        payload: { urls: data?.REVIEW_IMAGES_URL, files: [] },
      });
      setAuthBrandName(data.brandName || '');
      messageDispatcher(data, data.brandName || props.brandName);
      setDataReceived(true);
      if (data?.DECLUTTERED_INBOX === 'true' || data?.DECLUTTERED_INBOX === true) {
        setDeclutteredInbox(true);
      } else {
        setDeclutteredInbox(false);
      }
      if (data.ALL_STORE_CONVERSATION_HISTORY === 'false' || data.ALL_STORE_CONVERSATION_HISTORY === false) {
        setAllStoreConversationHistory(false);
      } else {
        setAllStoreConversationHistory(true);
      }
    }
    setStoreList([{ name: 'None', id: 'none' }, ...props.stores]);
  };

  const { call } = useOnce(getMessageConfig)

  useEffect(() => {
    if(props.merchantId) {
      call()
    }
  }, [props.merchantId, props.stores])

  const saveSettings = () => {
    const { merchantId } = props;
    const bodyData = bodyFormatter({ ...props, reviewImages: fileData }); // exported b/c it's huge
    if (props.optInInvite.trim() && props.optInConfirm.trim() && props.optInBulkInvite.trim()) {
      saveMessageSettings('merchant', merchantId, bodyData, 'messageConfig');
    } else if (!props.optInConfirm.trim()) {
      setOptInConfirm(
        ":name:, thanks for agreeing to let us contact you! You are free to reply STOP if you don't want to hear from us anymore. freq varies, msg&data rates apply",
      );
      notificationError('Please Enter an Opt-in Confirmation Message');
    } else if (!props.optInInvite.trim()) {
      setOptInInv(getDefaultOptInInv(props.brandName));
      notificationError('Please Enter an Opt-in Request Message');
    } else if (!props.optInBulkInvite.trim()) {
      setOptInBulkInvite(
        `Hi :name:, It's :associate: from ${props.brandName}. I have a message I'd like to send you but need your permission. I'll also occasionally let you know about personalized promotions and products? If that's ok, reply YES`,
      );
      notificationError('Please Enter an Opt-in (Mass Message) Request Message');
    }
  };

  const divStyle = {
    width: '866px',
  };

  const defaultStoreFunc = storeObj => {
    if (storeObj.value == 'none') {
      setMsgSettingsObject({ defaultStore: '' });
    } else {
      setMsgSettingsObject({ defaultStore: Number(storeObj.value) });
    }
  };

  return (
    <Layout
      title="Message Settings"
      description="Configure your message preferences below"
      classProps="standard-width-with-sidebar"
      headerStyles={{ maxWidth: '866px' }}
      contentStyles={{ maxWidth: '866px' }}
    >
      {/* Messaging */}
      <SettingSegment
        title="MESSAGING"
        withDivider
        img="https://dashboard-v2-images.s3.amazonaws.com/ic-messaging-black.svg"
      >
        <TitleDescription
          title="Default Store to Receive In-bound Webchat Messages"
          description="When an unrecognized number messages your store using Clientbook's webchat, which location should receive it?"
          input={
            <DropdownSelect
              id="DefaultStoreDropdown"
              classProps="maw-98-P w-267 align-left"
              options={storeList.filter(st => st.id).map(store => ({ label: store.name, value: store.id }))}
              placeholder="Not Set"
              value={Number(props.defaultStore)}
              onChange={obj => {
                defaultStoreFunc(obj);
              }}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          title="Auto Message Scheduled Time"
          description="When should auto messages begin sending each day?"
          input={
            <DropdownSelect
              id="AutoMessageScheduledTimeDropdown"
              classProps="maw-98-P w-267 align-left"
              options={[
                { label: '9:00 AM', value: '0900' },
                { label: '10:00 AM', value: '1000' },
                { label: '11:00 AM', value: '1100' },
                { label: '12:00 PM', value: '1200' },
                { label: '1:00 PM', value: '1300' },
                { label: '2:00 PM', value: '1400' },
                { label: '3:00 PM', value: '1500' },
                { label: '4:00 PM', value: '1600' },
                { label: '5:00 PM', value: '1700' },
              ]}
              placeholder="Schedule runtime..."
              value={props.setSchTime}
              onChange={obj => {
                setMsgSettingsObject({
                  setSchTime: obj.value,
                });
                // setDefaultStore(obj.value)
              }}
            />
          }
          style={divStyle}
        />
        <TitleDescription
          descriptionBoxId="OptInAutoMessages"
          title="Require Opt-In to Receive Auto Messages"
          description="Should clients be required to opt-in to marketing messages before receiving automated messages? "
          // input={<KioskSlider value={props.optAutoMess} onChange={setOptAutoMsg} />}
          input={<KioskSlider value={props.autoAsA2p} onChange={setAutoAsA2p} />}
          style={divStyle}
        />
        <TitleDescription
          title="Review Link Template"
          description={<p>Customize the review link template message that is shown in a client conversation.</p>}
          input={
            <TextAreaWithActions
              id="ReviewLinkTemplateInput"
              val={props?.reviewMessage}
              changeHandler={value => setReviewMessage(value)}
              placeholder=":name:, would you be willing to take 30 seconds to leave us a review using the link below? Let me know how I can help in the future. :review:"
              fileData={fileData}
              fileDispatch={fileDispatch}
              template
              associate=":associate:"
              reviewMessage={props?.reviewMessage || ' '}
            />
          }
          style={{
            flexDirection: 'column',
            ...divStyle,
          }}
        />
        {flags.storeInboxDeclutter ? (
          <TitleDescription
            title="Decluttered Inbox"
            description={
              <p>Enabling this setting allows you and your associates the ability to close conversations.</p>
            }
            input={<KioskSlider value={props.merchantConfig?.DECLUTTERED_INBOX} onChange={setDeclutteredInbox} />}
            style={divStyle}
          />
        ) : null}
        <TitleDescription
          title="View conversation history from all stores"
          description={
            <p>
              Turning this toggle on will set the conversation view to see all stores conversations with a client in the
              thread. Turning it off will set the view to only see the conversations with a client from the inbox you're
              in.
            </p>
          }
          input={<KioskSlider value={props.allStoreConversationHistory} onChange={setAllStoreConversationHistory} />}
          style={divStyle}
        />
      </SettingSegment>
      {/* Marketing Opt In */}
      <SettingSegment
        title="MARKETING OPT-IN"
        withDivider
        img="https://dashboard-v2-images.s3.amazonaws.com/ic-open-email-black.svg"
      >
        <TitleDescription
          descriptionBoxId="DoubleOptInAutoMessages"
          title="Require Double Opt-In for Kiosk Signups"
          description="By enabling this setting, your clients will receive a message opt-in request after signing up via Kiosk Mode. This is required in some states, including California."
          input={<KioskSlider value={props.doubleOptIn} onChange={setDoubleOptIn} />}
          style={divStyle}
        />
        <TitleDescription
          descriptionBoxId="SendOptInAfterAddingClient"
          title="Send Opt-In Message After Adding a New Client"
          description="This will automatically send an opt-in message to your clients after a sales associate adds them to Clientbook. This increases opt-in rates for marketing messages."
          input={<KioskSlider value={!props.delayOptIn} onChange={val => setDelayOptIn(!val)} />}
          style={divStyle}
        />
        <TitleDescription
          title="Opt-in Request Message"
          description={
            <div>
              <p>
                Below is the opt-in request message your clients will see. The opt-in request message should include
                help text with the following:
              </p>
              <p>“Successful opt-in requires the client to reply with ‘YES’"</p>
              <p>
                This should be made clear in the invitation. TCPA requires contacts be made aware that their consent is
                not a condition of making a purchase.{' '}
              </p>
              <p>Industry best practices include: </p>
              <ul>
                <li>describing your purpose or intent of auto-dialed messaging </li>
                <li>disclosing that message and data rates may apply.</li>
              </ul>
              <p>Maximum 500 Characters</p>
            </div>
          }
          input={
            <TextAreaWithActions
              id="OptInRequestMessageInput"
              val={props.optInInvite}
              changeHandler={value => setOptInInv(value)}
              placeholder={`Hi :name:, It's :associate: from ${props.brandName} and you can reach me anytime at this number. Can I occasionally let you know about personalized promotions and products? If that's ok, reply YES to this msg`}
              hideImages
              template
              associate=":associate:"
              reviewMessage={props?.reviewMessage || ' '}
            />
          }
          style={{
            flexDirection: 'column',
            ...divStyle,
          }}
        />
        {/* working here 04/21 */}
        <TitleDescription
          title="Mass Message Opt-In Request"
          description={
            <div>
              <p>
                Below is the opt-in request message your clients will see if they have been included in a mass message.
                The opt-in request message should include help text with the following:
              </p>
              <p>“Successful opt-in requires the client to reply with ‘YES’"</p>
              <p>
                This should be made clear in the invitation. TCPA requires contacts be made aware that their consent is
                not a condition of making a purchase.{' '}
              </p>
              <p>Industry best practices include: </p>
              <ul>
                <li>describing your purpose or intent of auto-dialed messaging </li>
                <li>disclosing that message and data rates may apply.</li>
              </ul>
              <p>Maximum 500 Characters</p>
            </div>
          }
          input={
            <TextAreaWithActions
              id="OptInRequestMassMessageInput"
              val={props.optInBulkInvite}
              changeHandler={value => setOptInBulkInvite(value)}
              placeholder={`Hi :name:, It's :associate: from ${props.brandName}. I have a message I'd like to send you but need your permission. I'll also occasionally let you know about personalized promotions and products? If that's ok, reply YES`}
              hideImages
              template
              associate=":associate:"
              reviewMessage={props?.reviewMessage || ' '}
            />
          }
          style={{
            flexDirection: 'column',
            ...divStyle,
          }}
        />
        {/* end */}
        <TitleDescription
          title="Opt-in Confirmation Message"
          description={
            <div>
              <p>This is the message your clients will receive after they opt-in.</p>
              <p>
                The opt-in confirmation message should include instructions for opting out of future messages. For
                Example:{' '}
              </p>
              <p>“Thank you for signing up! You can opt out of future messages at any time by replying 'STOP' "</p>
              <p>Maximum 500 Characters</p>
            </div>
          }
          input={
            <TextAreaWithActions
              id="OptInConfirmationInput"
              val={props.optInConfirm}
              changeHandler={value => setOptInConfirm(value)}
              placeholder={
                ":name:, thanks for agreeing to let us contact you! You are free to reply STOP if you don't want to hear from us anymore. freq varies, msg&data rates apply"
              }
              hideImages
              template
              associate=":associate:"
              reviewMessage={props?.reviewMessage || ' '}
            />
          }
          style={{
            flexDirection: 'column',
            ...divStyle,
          }}
        />
      </SettingSegment>

      <div className="settingSave">
        <Button id="MessageSettingsSaveButton" onclick={saveSettings} classname="darkBlueButton">
          Save
        </Button>
      </div>

      <SecondaryPanel title={['SETTINGS', 'HELP', 'PROFILE']} current="Message Settings" />
    </Layout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  ...state.messageSettings,
});
export default connect(mapStateToProps, {})(MessageSettings);

const SettingSegment = props => {
  return (
    <div>
      <div className="msgSetSegmentTitle">
        <img alt="#" src={props.img} />
        <span>{props.title}</span>
      </div>
      <div className="">{props.children}</div>
      {props.withDivider ? <div className="divider" /> : ''}
    </div>
  );
};
