import React, { useEffect, useState } from 'react';
import StyledLink from '../../StyledLink';
import Button from '../../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FlaggedLimitIcon from '../../FlaggedLimitIcon';

const FlaggedAutomationButtons = ({
  showWhatsApp,
  redirectToAutoWhatsappSettings,
  clearActionConfigMessage,
  redirectToAutoMsgSettings,
  redirectToReminderSettings,
  clearActionConfig,
  dataLength,
  flags
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const lifeEventsEnabled = flags?.life_events_automation?.enabled;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (false) {
  }

  const showLimitIcon = () => {
    if (!lifeEventsEnabled) {
      return (
        <FlaggedLimitIcon
          message={
            'You have reached your plan limit. Please contact your CSM to upgrade and add life event automations'
          }
        />
      );
    }
  };

  const opacityStyles = {
    ...(!lifeEventsEnabled ? { opacity: '0.5', cursor: 'not-allowed' } : {}),
  };

  const disableButtons = !lifeEventsEnabled;

  const linkRoutes = () => {
    if (!disableButtons) {
      return {
        whatsapp: `${redirectToAutoWhatsappSettings}/add-auto-whatsapp`,
        autoMessage: `${redirectToAutoMsgSettings}/add-auto-message`,
        reminder: `${redirectToReminderSettings}/add-auto-reminder`,
      };
    }
    return {
        whatsapp: '#',
        autoMessage: '#',
        reminder: '#',
    }
  };

  const fnRouter = (cbFunction) => {
    if(!disableButtons) {
        cbFunction();
    }
  }

  return (
    <div style={{ width: 'auto' }} className="flex-row-nospacebetween-nowrap">
      {showWhatsApp ? (
        <StyledLink
          classprops=""
          to={linkRoutes().whatsapp}
          styleprops={{ color: '#33aafc', margin: '10px 0 0 0', width: 'auto' }}
        >
          <Button
            onclick={() => fnRouter(clearActionConfigMessage)}
            classname="blue-link fs-13"
            styleProps={{ width: 'auto', ...opacityStyles }}
          >
            <FontAwesomeIcon icon={['fab', 'whatsapp']} size="1x" className="fs-16" style={{ paddingBottom: '2px' }} />{' '}
            {width <= 500 ? <span /> : <span>WHATSAPP AUTO TEMPLATE</span>}
          </Button>
        </StyledLink>
      ) : null}
      <StyledLink
        classprops=""
        to={linkRoutes().autoMessage}
        styleprops={{ color: '#33aafc', margin: '10px 0 0 0', width: 'auto' }}
      >
        <Button
          onclick={() => fnRouter(clearActionConfigMessage)}
          classname="blue-link fs-13"
          styleProps={{ width: 'auto', ...opacityStyles }}
        >
          <FontAwesomeIcon
            icon={['far', 'comment-alt-medical']}
            size="1x"
            className="fs-16"
            style={{ paddingBottom: '2px' }}
          />{' '}
          {width <= 500 ? <span /> : <span>MESSAGE</span>}
        </Button>
      </StyledLink>
      <StyledLink classprops="" to={linkRoutes().reminder} styleprops={{ margin: '10px 0 0 0' }}>
        <Button
          onclick={() => fnRouter(clearActionConfig)}
          classname="blue-link fs-13"
          styleProps={{ width: 'auto', paddingRight: 0, ...opacityStyles }}
        >
          <FontAwesomeIcon
            icon={['far', 'bell-plus']}
            className="blue-link fs-16"
            size="1x"
            style={{ paddingBottom: '2px' }}
          />{' '}
          {width <= 500 ? <span /> : <span>REMINDER</span>}
        </Button>
      </StyledLink>
      {showLimitIcon()}
    </div>
  );
};
export default FlaggedAutomationButtons;
