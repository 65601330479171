import React, { useEffect } from 'react';
import ProductsListTableItem from './components/ListTableItem';
import './styles.css';

const ProductsListTable = ({
  items,
  selectedProducts,
  selectedAndDisabledProducts,
  toggleProductSelection,
  onProductDetailClick,
}) => {
  useEffect(() => {
    const container = document.getElementById('cb-products-list-table');
    container.scrollTop = 0;
  }, [items]);

  return (
    <div id="cb-products-list-table" className="cb-products-list-table">
      {items.map(item => (
        <ProductsListTableItem
          key={item.id}
          item={item}
          disabled={selectedAndDisabledProducts.has(item.id)}
          selected={selectedAndDisabledProducts.has(item.id) || selectedProducts.has(item.id)}
          toggleSelection={toggleProductSelection}
          onProductDetailClick={onProductDetailClick}
        />
      ))}
    </div>
  );
};

export default ProductsListTable;
