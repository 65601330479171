import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './notification.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Notification = props => {
  const [display, setDisplay] = useState(false);
  const [top, setTop] = useState('20px');

  useEffect(() => {
    if (props.showNotification) {
      setDisplay(true);

      if (props.paymentBannerActive) {
        setTimeout(() => {
          setTop('110px');
        }, 100);
      } else {
        setTimeout(() => {
          setTop('60px');
        }, 100);
      }

      setTimeout(() => {
        props.closeNoti(false);
      }, 3000);
    } else {
      setTop('-60px');

      setTimeout(() => {
        setDisplay(false);
        props.setNoErrorState();
      }, 1000);
    }
  }, [props.showNotification]);

  if (display) {
    return (
      <div
        className={`pos-abs notif-cont rem-pad-width`}
        style={{
          top,
          backgroundColor: props.isError ? 'rgba(255, 99, 99, 0.92)' : 'rgba(53, 53, 56, 0.92)',
        }}
      >
        <span className="fs-12 white fw-500">{props.text}</span>
        <button className="pos-abs notif-close" onClick={() => props.closeNoti(false)}>
          <FontAwesomeIcon className="notif-close" icon={['far', 'times']} size="2x" color="white" />
        </button>
      </div>
    );
  } else {
    return null;
  }
};
const mapStateToProps = state => ({
  ...state.notification,
  paymentBannerActive: state.paymentBanner.active,
});

const mapDispatchToProps = dispatch => ({
  closeNoti(val) {
    dispatch({
      type: 'SET_SHOW_NOTIF',
      payload: val,
    });
  },
  setNoErrorState() {
    dispatch({
      type: 'SET_NOTIF_IS_ERR',
      payload: false,
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
