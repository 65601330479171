import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { Skeleton } from 'antd';
import InboxChatListItem from './children/InboxChatListItem';
import Button from '../../../../../../../Layout/Button';
import { setInboxType } from '../../../../../../../../reducers/messaging';

const InboxChatList = props => {
  const [hoverMsg, setHoverMsg] = useState('');
  const [selectedClientICL, setSelectedClientICL] = useState(0);

  const incrementPage = () => {
    const newPage = props.page + 1;
    props.setPage(newPage);
  };

  return (
    <div
      id="scrollableDivChat"
      className={props.paymentBanner ? 'scrollableDivChatWithBanner' : 'scrollableDivChatNoBanner'}
      style={{
        overflow: 'auto',
      }}
    >
      {props.inboxList.length === 0 ? (
        <>
          <div className="fs-14 fw-500 m-top-28 m-btm-25">
            <span>There are no visible messages</span>
          </div>
          <Button classname="darkBlueButton newMessageButton" onClick={() => props.setNewMessage(true)}>
            New Message
          </Button>
          {props.messaging?.closedInboxList?.length > 0 && (
            <Button classname="clearButton m-top-10" onClick={() => setInboxType('closed')}>
              View Closed
            </Button>
          )}
        </>
      ) : (
        <InfiniteScroll
          dataLength={props.inboxList.length}
          next={incrementPage}
          hasMore={props.isThereMore}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={<h4 style={{ margin: '12px 16px', paddingBottom: '40px' }}>No more conversations</h4>}
          scrollableTarget="scrollableDivChat"
          pullDownToRefreshThreshold={50}
        >
          <ul className="ul-no-style" style={{ marginBottom: '40px' }}>
            {props.inboxList.map(function (msg, idx) {
              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <InboxChatListItem
                  key={`chat_list_item_${idx}`}
                  hoverMsg={hoverMsg}
                  setHoverMsg={setHoverMsg}
                  msg={msg}
                  idx={idx}
                  inboxList={props.inboxList}
                  selectedClient={props.selectedClient}
                  setSelectedClient={props.setSelectedClient}
                  setChatIconColor={props.setChatIconColor}
                  setSelectedClientICL={setSelectedClientICL}
                  selectedClientICL={selectedClientICL}
                  associateId={props.associateId}
                  type={props.type}
                  setChatMoreRefresh={props.setChatMoreRefresh}
                  updateUnreadInInboxes={props.updateUnreadInInboxes}
                  setAssignPopup={props.setAssignPopup}
                  setHoverAssignId={props.setHoverAssignId}
                  role={props.role}
                  setUnreadMsgMatching={props.setUnreadMsgMatching}
                  setNewMessages={props.setNewMessages}
                  number={props.number}
                />
              );
            })}
          </ul>
        </InfiniteScroll>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  messaging: state.messaging,
});

export default connect(mapStateToProps, {})(InboxChatList);
