/* eslint-disable no-return-assign */
import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InfoCircleOutlined } from '@ant-design/icons';
import PopConfirm from '../../PopConfirm';
import { notificationSuccess, notificationError } from '../../../../core/actions';
import { deleteRequest } from '../../../../core/apiRequests';
import { getClientChat } from '../../../../reducers/messaging';
import LargeImage from '../../LargeImage';
import '../../../Routes/Messaging/clientInbox.css';

// eslint-disable-next-line no-unused-vars
const sentImageElement = ({ o, i, c, m, t, scrollToBottom }) => {
  const [onHoverImg, setOnHoverImg] = useState('');
  const [chatMessageObj, setChatMessageObj] = useState({});
  const [deleteImgPopup, setDeleteImgPopup] = useState(false);
  const isMounted = useRef(null);
  const [openLrgImg, setOpenLrgImg] = useState(false);
  const [load, setLoad] = useState(false);

  const updateChat = (clientId, chatId) => {
    getClientChat({ clientId, chatId });
  };

  const deleteMessage = msg => {
    isMounted.current = true;
    const reqObj = {
      params: ['chatMessages', m, 'delete'],
      query: { chatMsgId: msg.id, chatMsgText: msg.message, chatId: msg.chatId },
    };
    (async () => {
      const respData = await deleteRequest(reqObj);
      if (respData) {
        notificationSuccess('Message Successfully Deleted!');
        updateChat(msg.clientId, msg.chatId);
      } else {
        notificationError('Failed To Delete Message');
      }
      setDeleteImgPopup(false);
    })();
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const stopEventBubbling = (target, messageObj) => {
    if (target.id === 'onHoverImg') {
      setOpenLrgImg(true);
      setChatMessageObj(messageObj);
    }
  };

  const imgRef = useRef();

  useEffect(() => {
    if (load && imgRef?.current?.complete) {
      scrollToBottom();
    }
  }, [load, imgRef?.current?.complete]);

  return (
    <div
      className="w-100-P flex-row-nospacebetween-nowrap"
      style={{ justifyContent: 'flex-end' }}
      key={`sentImage${i}`}
      onMouseEnter={() => setOnHoverImg(o.id)}
      onMouseLeave={() => setOnHoverImg('')}
      onClick={e => stopEventBubbling(e.target, o)}
      onKeyDown={e => stopEventBubbling(e.target, o)}
      role="button"
      tabIndex={0}
    >
      <div
        className="border-r4 pos-rel pointer m-right-9"
        style={{
          background: 'white',
          zIndex: '5',
          maxWidth: '200px',
          maxHeight: '200px',
        }}
        onMouseEnter={() => setOnHoverImg(o.id)}
        onMouseLeave={() => setOnHoverImg('')}
        onClick={e => stopEventBubbling(e.target, o)}
        onKeyDown={e => stopEventBubbling(e.target, o)}
        role="button"
        tabIndex={0}
      >
        <div
          className="add-edit-wrap pos-abs w-100-P m-top-10"
          style={{ zIndex: '35', justifyContent: 'flex-end' }}
          id={onHoverImg === o.id ? 'imageToolRow' : 'imageToolRowHidden'}
        >
          <div
            className="w-33 h-33 bg-offwhite m-right-8 border-r4 pointer"
            onMouseEnter={e => (e.target.style.backgroundColor = '#e8f6ff')}
            onMouseLeave={e => (e.target.style.backgroundColor = '#f4f4f4')}
            onClick={() => {
              setDeleteImgPopup(true);
              setChatMessageObj(o);
            }}
            onKeyDown={() => {
              setDeleteImgPopup(true);
              setChatMessageObj(o);
            }}
            role="button"
            tabIndex={0}
          >
            <FontAwesomeIcon
              icon={['far', 'trash']}
              size="1x"
              className="fs-16 pointer"
              style={
                t === 'right'
                  ? { marginLeft: '8px', color: 'black', marginTop: '8px' }
                  : { color: 'black', marginTop: '8px' }
              }
            />
          </div>
          <div
            className="w-33 h-33 bg-offwhite m-right-8 border-r4 pointer"
            onClick={() => window.open(o.imageUrl)}
            onKeyDown={() => window.open(o.imageUrl)}
            role="button"
            tabIndex={0}
            onMouseEnter={e => (e.target.style.backgroundColor = '#e8f6ff')}
            onMouseLeave={e => (e.target.style.backgroundColor = '#f4f4f4')}
          >
            <FontAwesomeIcon
              icon={['far', 'arrow-to-bottom']}
              size="1x"
              className="fs-16 pointer"
              style={
                t === 'right'
                  ? { marginLeft: '10px', color: 'black', marginTop: '8px' }
                  : { color: 'black', marginTop: '8px' }
              }
            />
          </div>
        </div>
        <img
          onError={e => {
            e.target.onerror = null;
            e.target.src = 'https://dashboard-v2-images.s3.amazonaws.com/image-placeholder@2x.png';
          }}
          ref={imgRef}
          onLoad={() => setLoad(true)}
          id={onHoverImg === o.id ? 'onHoverImg' : ''}
          className="border-r4 photoFit"
          src={o.imageUrl}
          style={{ maxWidth: '200px', maxHeight: '200px', marginBottom: '5px', height: load ? 'auto' : '200px' }}
          alt="#"
        />
        <div className="singleMessageWrapper align-right pos-abs bottom-12 right-8" key={`sent${i}`}>
          {o.deliveryFailed ? (
            <span className="red fs-10 italic">Delivery Failed</span>
          ) : (
            <span className="text-light-gray fs-10 italic">{moment(o.sentTimestamp).format('hh:mm a')}</span>
          )}
        </div>
      </div>
      {deleteImgPopup ? (
        <PopConfirm
          customContent={false}
          confirm="Are you sure you want to delete this message?"
          onCancel={() => setDeleteImgPopup(false)}
          onConfirm={() => deleteMessage(chatMessageObj)}
          noButtonDisable
        />
      ) : null}
      {openLrgImg ? <LargeImage setLargeImagePopup={setOpenLrgImg} templateObj={chatMessageObj} t={t} /> : null}
      {(o.status === 'failed' || o.status === 'undelivered') && (
        <InfoCircleOutlined style={{ color: '#dc3545', fontSize: 'large' }} />
      )}
    </div>
  );
};

export default sentImageElement;
