import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';

const InitialsAvatar = props => {
  const { name, ...rest } = props;

  const [initials, setInitials] = useState('#');

  const handleSetInitials = () => {
    if (name) {
      const newInitials = name
        .split(' ')
        .map(e => e.charAt(0).toUpperCase())
        .slice(0, 2)
        .join('');

      setInitials(newInitials);
    }
  };

  useEffect(() => {
    handleSetInitials();
  }, []);

  return <Avatar {...rest}>{initials}</Avatar>;
};

export default InitialsAvatar;
