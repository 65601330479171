import request from '../utils/request';

// available params: limit, page, search, filters, sort_order, sort_direction
export const getProducts = ({ params = {} }) => {
  return request({
    url: '/products',
    method: 'GET',
    params,
  });
};

export const getProductsElasticSearch = ({ params = {} }) => {
  return request({
    url: '/products/elasticSearch',
    method: 'GET',
    params,
  });
};

// available params: page, pageSize, search
export const getProductsBrands = ({ params = {} }) => {
  return request({
    url: '/products/brands',
    method: 'GET',
    params,
  });
};

// available params: page, pageSize, search
export const getProductsCategories = ({ params = {} }) => {
  return request({
    url: '/products/categories',
    method: 'GET',
    params,
  });
};

export const getProductById = id => {
  return request({
    url: `/products/${id}`,
    method: 'GET',
  });
};

export const deleteProductById = id => {
  return request({
    url: `/products/${id}`,
    method: 'DELETE',
  });
};

export const createProduct = data => {
  return request({
    url: '/products',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  });
};

export const updateProduct = (id, data) => {
  return request({
    url: `/products/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  });
};
