import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NumberInput = props => {
  const incrementValue = () => {
    const { step, value, max } = props;
    const stepBy = Number(step) || 1;
    const newVal = Number(value) + stepBy;
    if (`${max}` && max !== null && max !== undefined) {
      if (newVal <= Number(max)) {
        props.onChange(newVal);
      }
    } else {
      props.onChange(newVal);
    }
  };

  const decrementValue = () => {
    const { step, value, min } = props;
    const stepBy = Number(step) || 1;
    const newVal = Number(value) - stepBy;
    if (`${min}` && min !== null && min !== undefined) {
      if (newVal >= Number(min)) {
        props.onChange(newVal);
      }
    } else {
      props.onChange(newVal);
    }
  };

  return (
    <div className={`${props.classProps || ''} pos-rel align-left`} style={{ ...props.styleProps }}>
      <input
        className={`${
          props.classProps || ''
        } inputHeight w-100-P rem-pad-width common_inputStyle no-input-arrows p-right-25`}
        style={props.decorator ? { paddingLeft: '40px' } : { position: 'relative' }}
        onChange={e => {
          const filterText = e.target.value.replace(/[^-0-9]/g, '');
          props.onChange(filterText);
          // let filterText = e.target.value.replace(/[^-0-9]/g, "");
          // setMessageCount(filterText);
        }}
        id={props.id}
        min={props.min}
        max={props.max}
        precision={props.precision || 1}
        value={props.value}
        type="number"
        placeholder={props.placeholder}
        onKeyPress={props.onKeyPress}
        disabled={props.disabled}
      />
      <div
        className="pos-abs flex-col-left right-0 h-50 border-left"
        style={{ position: 'absolute', right: '0px', top: '0px' }}
      >
        <button
          id={props.incrementButtonId}
          className="h-25 border-none border-btm transparent p-right-8 mid-gray incrementbtn"
          onClick={incrementValue}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          disabled={props.disabled}
        >
          <FontAwesomeIcon icon={['far', 'chevron-up']} />
        </button>
        <button
          id={props.decrementButtonId}
          className="h-25 border-none transparent p-right-8 mid-gray decrementbtn"
          onClick={decrementValue}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          disabled={props.disabled}
        >
          <FontAwesomeIcon icon={['far', 'chevron-down']} />
        </button>
      </div>
      {props.decorator ? (
        <div style={{ top: 0 }} className="pos-abs left-0 p-left-17 p-top-12 fs-16">
          {props.decorator}
        </div>
      ) : null}
    </div>
  );
};
export default NumberInput;
