/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Skeleton } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getRequest } from '../../../../core/apiRequests';
import StyledLink from '../../StyledLink';

const AutoMessages = props => {
  const [loading, setLoading] = useState(true);
  const [autoMessages, setAutoMessages] = useState([]);
  const clientId = props.messaging.client.client_id;

  useEffect(() => {
    if (props.merchantId) {
      (async () => {
        const reqObj = {
          params: ['chats', props.merchantId, clientId, 'get2AutoMessages'],
          // params: ['chats', props.merchantId, 406466, 'get2AutoMessages'],
          loadingStatusOff: true,
        };
        const data = await getRequest(reqObj);
        if (data.data.length > 0) {
          setAutoMessages(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    }
  }, [props.merchantId]);

  const dateFormat = dataObj => {
    const { custom_event_date, life_event_date, sales_trans_date_time } = dataObj;
    const dateToFormat = custom_event_date || life_event_date || sales_trans_date_time;
    if (`${dateToFormat}`.length === 14) {
      return moment(dateToFormat, 'YYYYMMDDHHmmss').format('MM/DD/YYYY');
    }
    if (`${dateToFormat}`.length === 8) {
      return moment(dateToFormat, 'YYYYMMDD').format('MM/DD/YYYY');
    }
    if (`${dateToFormat}`.length === 4) {
      return moment(dateToFormat, 'MMDD').format('MM/DD');
    }
  };

  const sourceCalc = dataObj => {
    const {
      tag_action_config_id,
      tag_name,
      custom_event_type_id,
      custom_event_type_name,
      sales_opp_id,
      sales_opp_name,
      life_event_id,
      life_event_type,
      sales_trigger_action_id,
      sales_trans_product_nm,
    } = dataObj;
    const returnMsgArr = [];
    if (tag_action_config_id || tag_name) {
      if (tag_name) {
        returnMsgArr.push(
          <div className="m-top-4 " key={tag_action_config_id + tag_name}>
            <FontAwesomeIcon className="fs-16 m-right-6" size="2x" icon={['far', 'tag']} />{' '}
            <span>{`"${tag_name}"`}</span>
          </div>,
        );
      }
    }
    if (custom_event_type_id || custom_event_type_name) {
      if (custom_event_type_name) {
        returnMsgArr.push(
          <div className="m-top-4 " key={custom_event_type_id + custom_event_type_name}>
            <FontAwesomeIcon className="fs-16 m-right-6" size="2x" icon={['far', 'clipboard-list']} />{' '}
            <span>{`${custom_event_type_name}`}</span>
          </div>,
        );
      }
    }
    if (sales_opp_id || sales_opp_name) {
      returnMsgArr.push(
        <div className="m-top-4 " key={sales_opp_id + dataObj.description}>
          <FontAwesomeIcon className="fs-16 m-right-6" size="2x" icon={['far', 'shopping-bag']} />{' '}
          <span>{`${dataObj.description}`}</span>
        </div>,
      );
    }
    if (life_event_id || life_event_type) {
      returnMsgArr.push(
        <div className="m-top-4 " key={life_event_id}>
          <img className=" m-right-6" src="https://dashboard-v2-images.s3.amazonaws.com/partyHornSolid.svg" alt="party horn icon" />
          <span>{`${life_event_type} on ${dateFormat(dataObj)}`}</span>
        </div>,
      );
    }
    if (sales_trigger_action_id) {
      returnMsgArr.push(
        <div key={sales_trigger_action_id + sales_trans_product_nm}>
          <div>
            <FontAwesomeIcon className="fs-16 m-right-6" size="2x" icon={['far', 'dollar-sign']} />{' '}
            <span>{`${sales_trans_product_nm}`}</span>
          </div>
          <span>{`Sold on ${dateFormat(dataObj)}`}</span>
        </div>,
      );
    }
    return returnMsgArr;
  };

  const msgTrim = msg => {
    if (msg.length > 60) {
      return `${msg.substr(0, 65)}...`;
    }
    return msg;
  };

  const AutoMessageData = () => {
    const returnArr = [];
    if (loading) {
      returnArr.push(
        <div key="skeleton">
          <Skeleton
            paragraph={{
              rows: 3,
            }}
            active
          />
        </div>,
      );
      return returnArr;
    }
    if (autoMessages.length > 0) {
      autoMessages.forEach(autoMsg => {
        returnArr.push(
          <div className="upcomingAutoMessage m-btm-12" key={autoMsg.message}>
            <div className="fs-12 fw-500 upcomingAutoMessageCardPadding align-left">
              <p className="m-btm-0 m-top-0">{msgTrim(autoMsg.message)}</p>
            </div>
            <div className="fs-12 fw-400 gray flex-col-left m-left-10">
              <span>
                {`Sending on
                ${new Date(autoMsg.scheduled_date_time).toLocaleDateString('en-us', {
                  month: 'short',
                  day: 'numeric',
                })}`}
              </span>
              {sourceCalc(autoMsg)}
            </div>
          </div>,
        );
      });
      return returnArr;
    }
  };

  return (
    <div>
      {AutoMessageData() ? (
        <div style={{ borderTop: '1px #ebebeb solid' }}>
           <p className="fw-500 gray fs-15 align-left m-btm-5">Upcoming Auto Messages</p>
          {AutoMessageData()}
          <StyledLink
            className="black fw-500 fs-12 m-right-20 m-top-3"
            id="view-all-auto-messages"
            to={`/Clients?client=${clientId}`}
          >
            View All In Client Profile
          </StyledLink>
        </div>
      ) : (
         <div style={{ borderTop: '1px #ebebeb solid' }}>
           <p className="fw-500 gray fs-15 align-left m-btm-5">Upcoming Auto Messages</p>
          <span className="gray fs-12">No Auto Messages</span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  messaging: state.messaging,
});
export default connect(mapStateToProps)(AutoMessages);
