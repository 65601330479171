import React from 'react';

import Field from './Field';

const Textarea = ({ maxLength, inputStyle, ...props }) => {
  return (
    <Field
      {...props}
      inputStyle={{
        float: 'none',
        paddingTop: 12,
        paddingBottom: 12,
        height: 80,
        ...inputStyle,
      }}
    >
      <textarea maxLength={maxLength} />
    </Field>
  );
};

Textarea.propTypes = {};

Textarea.defaultProps = {};

export default Textarea;
