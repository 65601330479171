import React from 'react';
import { Link } from 'react-router-dom';

const Shopify = () => {
  return (
    <>
      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Overview</h3>

        <p>
          Connect your Shopify account with Clientbook! Shopify is an excellent e-Commerce platform that allows
          businesses to easily sell their products. Shopify also tracks client, product and sales data which allows
          businesses to adapt to changing market conditions.
        </p>

        <p>
          By integrating with Shopify, you will be able to effortlessly combine your business&apos;s existing Shopify
          data with Clientbook&apos;s desktop and mobile platforms. Specifically, this will bring your client, product
          and sales data over to Clientbook.
        </p>

        <p>
          Please be sure the Shopify user has the rights to access all relevant information. If the user is removed from
          the connected Shopify account, the account will be removed from Clientbook.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Key Features</h3>

        <p>By adding your Shopify information to Clientbook, you can:</p>

        <ul className="cb-integrations-details__list">
          <li>Send payment requests and track specific Life Events to maximize POS activity.</li>

          <li>Document Sales Opportunities to intensify your sales team&apos;s efficiency.</li>

          <li>Send Product Collections based on your client&apos;s tastes to effectively market your products.</li>

          <li>
            Maximize communication with your clients by creating Auto Messages. These messages can be sent automatically
            on days based on birthdays, anniversaries, special orders, sales etc.
          </li>
        </ul>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Click on Install to get started!</h3>

        <p>
          This will take you to Shopify. Log in to your Shopify account and follow the steps to successfully install the
          integration.
        </p>
      </section>

      <section className="cb-integrations-details__section">
        <h3 className="cb-integrations-details__title">Helpful Links</h3>

        <ul className="cb-integrations-details__list">
          <li>
            <Link to="/Help/contact-support">Clientbook Help Center</Link>
          </li>

          <li>
            <a
              href="https://clientbook.zendesk.com/hc/en-us/articles/14516549472283-How-to-set-up-a-Shopify-integration-with-Clientbook"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to set up a Shopify integration with Clientbook
            </a>
          </li>
        </ul>
      </section>
    </>
  );
};

export default Shopify;
