import React, { useState } from 'react';
import { connect } from 'react-redux';
import MVButton from './MVButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { getPaymentsNav } from '../../Navigation_V2';

const MobileMenu = props => {
  const [showListMobile, setShowListMobile] = useState('');

  const isSafe = () => {
    // const safeMerchants = [11, 70, 2, 17, 51, 350, 354, 363, 400, 402, 410, 418, 421];
    const posType = props.auth.pos_type ? props.auth.pos_type.trim() : null;
    const showTags = props?.merchantFlags?.tags?.enabled || false;
    const showMassMessaging = props?.merchantFlags?.mass_messaging?.enabled || false;

    let configMenu = [];
    if (posType || process.env.REACT_APP_API_ENV === 'development' || process.env.REACT_APP_API_ENV === 'localhost') {
      configMenu = [
        { title: 'Sales Triggers', link: '/Configuration/sales-triggers' },
        { title: 'Life Events', link: '/Configuration/life-events' },
        { title: 'Client Activity', link: '/Configuration/client-activity' },
        { title: 'Tags', link: '/Configuration/tags' }, // these links need to be modified
      ];
    } else {
      configMenu = [
        { title: 'Sales Triggers', link: '/Configuration/sales-triggers' },

        { title: 'Life Events', link: '/Configuration/life-events' },
        { title: 'Client Activity', link: '/Configuration/client-activity' },
        { title: 'Tags', link: '/Configuration/tags' }, // these links need to be modified
      ];
    }

    if (props.auth.pipelineEnabled === true) {
      configMenu.splice(1, 0, { title: 'Sales Opportunities', link: '/Configuration/sales-pipelines' });
    }

    if(!showTags) {
      let newAutomation = configMenu.filter(item => item.title !== 'Tags');
      configMenu = {...newAutomation}
    }

    if (!showMassMessaging) {
      const newMessaging = configMenu.Messaging.filter(item => item.title !== 'Mass Messaging');
      configMenu = { ...configMenu, Messaging: newMessaging };
    }

    return configMenu;
  };

  const messagingMenuOptions = () => {
    let menuListArr = [
      {
        title: 'Inbox',
        link: '/Messaging/inbox',
        current: 'Inbox',
      },
      // {
      //   title: 'My Inbox',
      //   link: '/Messaging/my-inbox',
      //   current: 'My Inbox',
      // },
      // {
      //   title: 'Store Inbox',
      //   link: '/Messaging/store-inbox',
      //   current: 'Store Inbox',
      // },
    ];
    if (props.auth.role == 'ADMIN' || props.auth.role == 'MANAGER') {
      menuListArr.push(
        {
          title: 'Mass Messaging',
          link: '/Messaging/mass-messaging',
          current: 'Mass Messaging',
        },
        {
          title: 'Templates',
          link: '/Messaging/all-templates',
          current: 'Templates',
        },
        {
          title: 'Auto Messages',
          link: '/Messaging/auto-messages',
          current: 'Auto Messages',
        },
      );
    }
    if (props.auth.enabled_whatsapp) {
      menuListArr.push({
        title: 'WhatsApp',
        link: '/Messaging/WhatsApp',
        current: 'WhatsApp',
      });
    }
    return menuListArr;
  };

  const manageMenuOptions = () => {
    let menuListArr = [
      { title: 'Clients', link: '/Clients' },
      { title: 'Products', link: '/Products' },
    ];

    if (props.auth.role == 'ADMIN' || props.auth.role == 'MANAGER') {
      menuListArr.push({ title: 'Associates', link: '/Associates' }, { title: 'Stores', link: '/Stores' });
    }
    return menuListArr;
  };

  return (
    <div>
      <div className="mobile-side-panel">
        <FontAwesomeIcon
          className={`pointer ${props.paymentBannerActive ? 'mobile-side-pane-x-banner' : 'mobile-side-pane-x'}`}
          onClick={() => props.toggleMobileMenu(false)}
          icon={['far', 'times']}
          color="white"
          size="sm"
        />
        <ul className="nav-bar-short hide-scrollbar">
          <li className="m-btm-35">
            <Link to="/today" onClick={() => props.toggleMobileMenu(false)}>
              <img src="https://dashboard-v2-images.s3.amazonaws.com/horizontal_nav_cb.svg" className="d-block m-top-15 left-0 w-26 h-26"></img>
            </Link>
          </li>
          <li className="mobileUL">
            <MVButton menuOptions={[]} title={'Dashboard'} />
          </li>
          <li className="mobileUL flex-row-spacebetween-wrap">
            <MVButton
              title={'Messaging'}
              showListMobile={showListMobile}
              setShowListMobile={setShowListMobile}
              menuOptions={messagingMenuOptions()}
            />
            <FontAwesomeIcon
              className="m-top-20 m-right-10"
              // onClick={() => props.toggleMobileMenu(false)}
              icon={['far', 'angle-right']}
              color="#45484b"
              size="sm"
            />
          </li>
          <li className="mobileUL flex-row-spacebetween-wrap">
            <MVButton
              menuOptions={getPaymentsNav(props.auth.role)}
              image={<FontAwesomeIcon icon={['fal', 'store']} color="white" />}
              title={'Payments'}
              showListMobile={showListMobile}
              setShowListMobile={setShowListMobile}
            />
            <FontAwesomeIcon
              className="m-top-20 m-right-10"
              // onClick={() => props.toggleMobileMenu(false)}
              icon={['far', 'angle-right']}
              color="#45484b"
              size="sm"
            />
          </li>
          {props.auth.role === 'ADMIN' ? (
            <li className="mobileUL flex-row-spacebetween-wrap">
              <MVButton
                menuOptions={isSafe()}
                image={<FontAwesomeIcon icon={['fal', 'store']} color="white" />}
                title={'Automation'}
                showListMobile={showListMobile}
                setShowListMobile={setShowListMobile}
              />
              <FontAwesomeIcon
                className="m-top-20 m-right-10"
                // onClick={() => props.toggleMobileMenu(false)}
                icon={['far', 'angle-right']}
                color="#45484b"
                size="sm"
              />
            </li>
          ) : null}
          <li className="mobileUL flex-row-spacebetween-wrap">
            <MVButton
              title={'Manage'}
              showListMobile={showListMobile}
              setShowListMobile={setShowListMobile}
              menuOptions={manageMenuOptions()}
            />
            <FontAwesomeIcon
              className="m-top-20 m-right-10"
              // onClick={() => props.toggleMobileMenu(false)}
              icon={['far', 'angle-right']}
              color="#45484b"
              size="sm"
            />
          </li>
        </ul>
      </div>
      <div className="mobile-side-panel-bg-closer" onClick={() => props.toggleMobileMenu(false)} />
    </div>
  );
};
const mapStateToProps = state => ({ ...state.navigation, auth: state.auth });

export default connect(mapStateToProps, {})(MobileMenu);
