import React from 'react';
import SecondaryPanel from '../../../../../../Layout/SecondaryPanel';
import StandardLayout from '../../../../../../Layout/StandardLayout';
import { connect } from 'react-redux';
import WhatasppReminderTemplates from '../../../../../../Layout/AutoReminders/AutoReminderSettings/WhatasppReminderTemplates';

const TagAutoWhatsappTemplate = props => {
  const listItemId = props.match.params.listItemId;
  const reminderId = props.match.params.reminderId;

  return (
    <StandardLayout
      title={`${
        window.location.href.includes('add-auto-whatsapp') ? 'Add' : 'Edit'
      } Whatsapp Template for Auto Reminder`}
      description={'Sales associates will be able to choose to send this message instead of typing their own'}
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Tags', to: '/Configuration/tags' },
        { title: 'Edit Tag', to: `/Configuration/tags/edit-tag/${listItemId}` },
        {
          title: `Auto Reminder`,
          to: `/Configuration/tags/edit-tag/${listItemId}/edit-auto-reminder/${reminderId}`,
        },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <WhatasppReminderTemplates
        popConfirmDesc={'This will remove the template from this Reminder'}
        cancelRoute={`/Configuration/tags/edit-tag/${listItemId}/edit-auto-reminder/${reminderId}`}
        type={'tags'} //// REMEMBER THIS
        match={props.match}
      />

      <SecondaryPanel title={['Automation']} current="Tags" />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  ...state.autoRemMsgs,
});
export default connect(mapStateToProps, {})(TagAutoWhatsappTemplate);
