import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import Logo from './LogoLg.png';
import './styles.css';

const ProductsGridTableItemCover = ({ src, alt }) => {
  const [showImage, setShowImage] = useState(true);

  return (
    <div className="cb-products-grid-table-item__image">
      {src && showImage && <img src={src} alt={alt} onError={() => setShowImage(false)} />}
    </div>
  );
};

const ProductsGridTableItem = ({ item }) => {
  const productInStock = (typeof item.qty === 'number' && item.qty > 0) || item.qty === null;

  return (
    <Link to={`/products/${item.id}`}>
      <Card
        cover={
          <ProductsGridTableItemCover src={item.image || item.image_url} alt={item.name} inStock={productInStock} />
        }
        className="cb-products-grid-table-item"
        hoverable
      >
        {item.price && <div className="cb-products-grid-table-item__price">{`$${item.price}`}</div>}
        {!item.image && !item.image_url && (
          <div className="cb-products-grid-table-item__unavailable">
            <img src={Logo} alt="out-of-stock" />
            <div className="logo-txt">No Image</div>
          </div>
        )}
        {productInStock || item.available ? null : (
          <div
            className="cb-product-details-grid__out-of-stock align-center"
            style={{ width: '68px', height: '22px', backgroundColor: '#FFD9D2', color: '#FC5025' }}
          >
            <span className="fw-600 fs-10">Out of Stock</span>
          </div>
        )}

        <div className="cb-products-grid-table-item__content">
          <h4 className="cb-products-grid-table-item__title line-clamp-2">{item.name}</h4>
          <p className="cb-products-grid-table-item__description">{item.sku}</p>
        </div>
      </Card>
    </Link>
  );
};

export default ProductsGridTableItem;
