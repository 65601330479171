import { v4 as uuidv4 } from 'uuid';
import { BLUESNAP_CUSTOM_ERRORS, BLUESNAP_FIELD_ERRORS } from './bluesnap-errors';

const NAME_DELIMITER = ' ';

export const updateData = (values, set) => {
  const formattedData = Object.entries(values).reduce((obj, [id, value]) => {
    if (value === 'REMOVE') {
      return obj;
    }

    return {
      ...obj,
      [id]: {
        hasError: false,
        value,
      },
    };
  }, {});

  set(d => ({
    ...d,
    ...formattedData,
  }));
};

const addUuid = value => {
  const uuid = uuidv4().substr(0, 8);
  const unique = `${value}${NAME_DELIMITER}${uuid}`;

  return { value, unique };
};

const getForm = () => document.getElementById('wizard-form');

export const updateDataBeforeSubmission = set => {
  const form = getForm();
  const businessName = form.elements.businessName.value;

  // this `isSubmitting` flag triggers submission to bluesnap
  // adding this flag to data helps us to avoid a race condition
  // (state updates are done whenever react want to do them...)
  set(d => ({
    ...d,
    businessName: {
      ...d.businessName,
      value: addUuid(businessName).unique,
    },
    isSubmitting: true,
  }));
};

export const updateDataAfterSubmission = set => {
  set(d => ({
    ...d,
    businessName: {
      ...d.businessName,
      value: removeUuid(d.businessName.value),
    },
    isSubmitting: false,
  }));
};

// remove uuid from businessName
const removeUuid = value => {
  if (typeof value !== 'string') {
    return value;
  }

  const delimiterIndex = value.lastIndexOf(NAME_DELIMITER);

  if (delimiterIndex > -1) {
    const name = value.substring(0, delimiterIndex);

    return name;
  }
  return value;
};

export const getPostValues = () => {
  const form = getForm();
  const getLastFour = number => number.substring(number.length - 4);

  const postValues = {
    businessName: form.elements.businessName.value,
    bankAccountType: form.elements.bankAccountType.value,
    bankAccountNickname: form.elements.bankAccountNickname.value,
    bankAccountLastFour: getLastFour(form.elements.bankAccountNumber.value),
  };

  return postValues;
};

/**
 * Map error messages back to fields in the form
 * by updating `hasError` on each data object
 */
export const showErrors = (messages, set) =>
  set(data => {
    return Object.entries(data).reduce((obj, [key, value]) => {
      // leave data.isSubmitting alone
      if (key === 'isSubmitting') return obj;

      const hasFieldError =
        // only test keys found in the map
        BLUESNAP_FIELD_ERRORS[key] &&
        // check the key itself (for bluesnap's client-side validation)
        (messages.find(message => message.includes(key)) ||
          // check the mapped field name (for bluesnap's backend validation)
          messages.find(message => message.includes(BLUESNAP_FIELD_ERRORS[key])));

      const hasCustomError =
        // only test keys found in the map
        BLUESNAP_CUSTOM_ERRORS[key] &&
        // check each message
        messages.find(message =>
          // to see if a custom message string
          BLUESNAP_CUSTOM_ERRORS[key].find(customError =>
            // is included in the message
            message.includes(customError),
          ),
        );

      return {
        ...obj,
        [key]: {
          ...value,
          hasError: hasFieldError || hasCustomError,
        },
      };
    }, data);
  });
