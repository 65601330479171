const initialState = {
  showMobileNav: false,
  collapseMNav: false,
  selected: 'Home',
  showMobileMultiNav: false,
  newMessages: [],
};

export default function reducer(state, action) {
  const state2 = state || initialState;

  switch (action.type) {
  case 'SHOW_MOBILE_NAV':
    return { ...state2, showMobileNav: action.payload, collapseMNav: false };
  case 'COLLAPSE_MOBILE_NAV':
    return { ...state2, collapseMNav: action.payload };
  case 'SELECT_NAV':
    return { ...state2, selected: action.payload };
  case 'SHOW_MOBILE_MULTI_NAV':
    return { ...state2, showMobileMultiNav: action.payload };
  case 'SET_NEW_MESSAGES':
    return { ...state2, newMessages: action.payload };
  case 'RESET_NAVIGATION':
    setTimeout(() => {}, 3000);
    return initialState;
  default:
    return state2;
  }
}
