import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Field from './Field';

const Dropdown = ({ options, ...props }) => {
  return (
    <Field
      {...props}
      before={
        <FontAwesomeIcon
          style={{
            position: 'absolute',
            top: 'calc(50% - 2px)',
            right: 12,
            opacity: '25%',
            zIndex: -1,
          }}
          size="2x"
          icon={['fal', 'angle-down']}
        />
      }
    >
      <select
        style={{
          appearance: 'none',
          background: 'transparent',
          fontFamily: 'inherit',
        }}
      >
        {options.map(opt => (
          <option key={opt} value={opt}>
            {opt}
          </option>
        ))}
      </select>
    </Field>
  );
};

Dropdown.propTypes = {};

Dropdown.defaultProps = {};

export default Dropdown;
