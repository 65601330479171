import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TemplatedTextSalesTriggers from '../../TemplatedTextSalesTriggers';
import { setTriggerActionObj } from '../../../../reducers/salesTriggers';

const ReminderMessageTriggerTemplate = props => {
  const history = useHistory();

  useEffect(() => {
    // if (props.merchantId) {
    //     if (
    //         !props.autoRemMsgs.msgTempId &&
    //         window.location.href.includes("edit-message-template")
    //     ) {
    //         setRemTempAddEdit("Edit");
    //         (async () => {
    //             const data = await getList(
    //                 props.type,
    //                 props.merchantId,
    //                 "messageTemplates",
    //                 props.match.params.reminderId,
    //                 `?templateId=${props.match.params.templateId}`
    //             );
    //             if (data && data.length > 0) {
    //                 setRemTempMsgText(data[0].msg);
    //                 setRemTempMsgId(data[0].id);
    //             } else {
    //                 history.push(props.cancelRoute);
    //                 console.error("Failed to get message template");
    //             }
    //         })();
    //     }
    // }
  }, [props.merchantId]);

  return (
    <div className="align-left">
      <div
        style={{
          maxWidth: '500px',
          justifyContent: 'flex-end',
          display: 'flex',
        }}
      />

      <span style={{ display: 'block' }}>Message Body</span>
      <TemplatedTextSalesTriggers
        val={props.salesTriggers.actionMsgText}
        changeHandler={val => {
          setTriggerActionObj({ actionMsgText: val });
        }}
      />

      <div className="flex-col-center m-top-37 m-btm-20">
        <div className="mq-w-100-vw">
          <button className="cancel-button m-right-8" onClick={props.cancelRoute}>
            Cancel
          </button>
          <button className="save-button m-left-8" onClick={props.onclickSave}>
            Save
          </button>
        </div>
      </div>
      {/* {redirect ? <Redirect to={props.cancelRoute} /> : null} */}
    </div>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  salesTriggers: state.salesTriggers,
});
export default connect(mapStateToProps, {})(ReminderMessageTriggerTemplate);
