import React from 'react';
import { parse } from 'papaparse';
import './index.css';

const DropZone = props => {
  const dragOver = e => {
    e.preventDefault();
  };

  const dragEnter = e => {
    e.preventDefault();
  };

  const dragLeave = e => {
    e.preventDefault();
  };

  const fileDrop = e => {
    e.preventDefault();

    Array.from(e.dataTransfer.files)
      .filter(file => file.type === 'text/csv')
      .forEach(async file => {
        const columns = [];
        const data = [];
        const text = await file.text();
        const results = parse(text, { header: false });
        results.data.map((field, rowIndex) => {
          const tempObj = {};
          field.map((data, index) => {
            tempObj[index] = data;
            if (rowIndex === 0) {
              columns.push(index);
            }
          });
          data.push(tempObj);
        });
        props.handleDropFile(columns, data);
      });
  };

  return (
    <div className="container">
      <div
        className="drop-container"
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        {props.content}
      </div>
    </div>
  );
};

export default DropZone;
