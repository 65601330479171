import React, { useState, useEffect } from 'react';
import { notificationError } from '../../../../core/actions';

const ContUpload = props => {
  const [upload_style_two, setUpload_style_two] = useState('upload_div_two');
  const [redX, setRedX] = useState('redX');

  useEffect(() => {
    if (props.uploadButtonText === 'Upload') {
      setUpload_style_two('upload_div_two');
      setRedX('redX');
    }
  }, [props.uploadButtonText]);

  useEffect(() => {
    if (props.backgroundUrl.length > 0) {
      setUpload_style_two('upload_div_active_two');
      setRedX('redX_active');
      const arrVars = props.backgroundUrl.split('/');
      const lastVar = arrVars.pop();
      if (lastVar.length > 15) {
        const truncateUpload = lastVar.substr(0, 15);
        const truncateReturn = `${truncateUpload}...`;
        props.setUploadButtonText(truncateReturn);
      } else {
        props.setUploadButtonText(lastVar);
      }
    }
  }, [props.backgroundUrl]);

  const delete_input = () => {
    props.setUploadButtonText('Upload');
    setUpload_style_two('upload_div_two');
    setRedX('redX');
    props.setBgImage('');
  };

  const SplitUploadTwo = event => {
    if (event.target.files[0].size < 1600000) {
      props.setBgImage(event.target.files[0]);
    } else {
      notificationError('Image file size exceeds 1.5mb');
    }
    var arrVars = event.target.value.split('\\');
    var lastVar = arrVars.pop();
    if (lastVar.length > 15) {
      var truncateUpload = lastVar.substr(0, 15);
      var truncateReturn = truncateUpload + '...';
      props.setUploadButtonText(truncateReturn);
    } else {
      props.setUploadButtonText(lastVar);
    }
    if (lastVar.length == 0) {
      props.setUploadButtonText('Upload');
      setUpload_style_two('upload_div_two');
      setRedX('redX');
    } else {
      setUpload_style_two('upload_div_active_two');
      setRedX('redX_active');
    }
  };

  return (
    <div id={upload_style_two} className=" rem-pad-width inputHeight">
      <input
        type="file"
        id="fileTwo"
        className="inputfile_red "
        accept=".jpg, .png"
        value={''}
        onChange={event => {
          SplitUploadTwo(event);
        }}
        disabled={props.disabled}
        // disabled={props.bgType !== 'Image' ? true : false}
      />
      {props.upload_label}
      <img alt="#" id={redX} src="https://dashboard-v2-images.s3.amazonaws.com/ic-remove-red.svg" onClick={() => delete_input()} />
    </div>
  );
};

export default ContUpload;
