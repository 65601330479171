import store from '../core/store';
import { dispatcher } from '../core/actions';

const initialState = {
  topStores: [],
  totalOutreach: [],
  averageDayLate: [],
  newSalesOpp: [],
  wonSalesOpp: [],
  lostSalesOpp: [],
};

// THESE ARE GLOBAL SO WATCH THE NAMES
// EX: actFirstName
export default function reducer(state = initialState, action) {
  const state2 = state;
  const { payload } = action;
  switch (action.type) {
  case 'SET_HOME_TOP_STORES':
    return { ...state2, topStores: action.payload };
  case 'SET_HOME_TOTAL_OUTREACH':
    return { ...state2, totalOutreach: action.payload };
  case 'SET_HOME_AVG_DAY_LATE':
    return { ...state2, averageDayLate: action.payload };
  case 'SET_HOME_NEW_SALESOPP':
    return { ...state2, newSalesOpp: action.payload };
  case 'SET_HOME_WON_SALESOPP':
    return { ...state2, wonSalesOpp: action.payload };
  case 'SET_HOME_LOST_SALESOPP':
    return { ...state2, lostSalesOpp: action.payload };
  case 'SET_HOME_FROM_API':
    return {
      ...state,
      topStores: [...payload.topStores],
      totalOutreach: [...payload.totalOutreach],
      averageDayLate: payload.purchaseHistory,
      newSalesOpp: payload.newSalesOpp,
      wonSalesOpp: payload.wonSalesOpp,
      lostSalesOpp: payload.lostSalesOpp,
    };
  default:
    break;
  }
  return state2;
}

export const setHomeTopStores = val => {
  dispatcher('SET_HOME_TOP_STORES', val);
};
export const setTotalOutreach = val => {
  dispatcher('SET_HOME_TOTAL_OUTREACH', val);
};
export const setAvgDaysLate = val => {
  dispatcher('SET_HOME_AVG_DAY_LATE', val);
};
export const setNewSalesOpp = val => {
  dispatcher('SET_HOME_NEW_SALESOPP', val);
};
export const setWonSalesOpp = val => {
  dispatcher('SET_HOME_WON_SALESOPP', val);
};
export const setLostSalesOpp = val => {
  dispatcher('SET_HOME_LOST_SALESOPP', val);
};

export const setHomeDetailsFromAPI = val => {
  dispatcher('SET_HOME_FROM_API', val);
};
