import React from 'react';
import TagsGroup from '../TagsGroup';
import './styles.css';

const Tags = ({ items, closable = false, onClose = () => {} }) => {
  return (
    <div className="cb-tags">
      {Object.keys(items).map(key => (
        <TagsGroup key={key} group={key} items={items[key]} closable={closable} onClose={onClose} />
      ))}
    </div>
  );
};

export default Tags;
