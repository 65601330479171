import { dispatcher } from '../core/actions';

const initialState = {
  stores: [],
  // selected store
  id: 0,
  name: '',
  address_1: '',
  address_2: '',
  phone_number: '',
  postal_code: '',
  city: '',
  timezone: '',
  state: '',
  pos_ref_num: '',
  // webchat time
  store_hour_id: '',
  after_hours: false,
  times: {
    sun_startTime: '',
    sun_endTime: '',
    mon_startTime: '',
    mon_endTime: '',
    tue_startTime: '',
    tue_endTime: '',
    wed_startTime: '',
    wed_endTime: '',
    thu_startTime: '',
    thu_endTime: '',
    fri_startTime: '',
    fri_endTime: '',
    sat_startTime: '',
    sat_endTime: '',
  },
  convertedTimes: {},
  day_checkmark: {
    sunday: false,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
  },
  after_hours_msg: '',
  // end webchat time
  review_link: '',
  daily_contact_goal: '',
  weekly_contact_goal: '',
  sms_number: '',
  default_manager: '',
  inbound_notify_all: true,
  inbound_notify_managers: [],
};
export default function reducer(state = initialState, action) {
  let state2 = state;
  switch (action.type) {
    case 'SET_STORES':
      return { ...state2, stores: action.payload };
    case 'SET_SELECTED_STORE':
      return { ...state2, ...action.payload };
    case 'SET_STORE_NAME':
      return { ...state2, name: action.payload };
    case 'SET_STORE_ADDR1':
      return { ...state2, address_1: action.payload };
    case 'SET_STORE_ADDR2':
      return { ...state2, address_2: action.payload };
    case 'SET_STORE_PHONE_NUMBER':
      return { ...state2, phone_number: action.payload };
    case 'SET_STORE_POSTAL_CODE':
      return { ...state2, postal_code: action.payload };
    case 'SET_STORE_CITY':
      return { ...state2, city: action.payload };
    case 'SET_STORE_TIMEZONE':
      return { ...state2, timezone: action.payload };
    case 'SET_STORE_STATE':
      return { ...state2, state: action.payload };
    // webchat time
    case 'SET_WC_AFTERHOURS':
      return { ...state2, after_hours: boolParse(action.payload) };
    case 'SET_STOREHOUR_ID':
      return { ...state2, store_hour_id: action.payload };
    case 'SET_TIME':
      return { ...state2, times: action.payload };
    case 'SET_CON_TIME':
      return { ...state2, convertedTimes: action.payload };
    case 'SET_DAY_CHECK':
      return { ...state2, day_checkmark: { ...state2.day_checkmark, ...action.payload } };
    case 'SET_AFTERHOURS_MSG':
      return { ...state2, after_hours_msg: action.payload };
    case 'SET_STORE_REVIEW_LINK':
      return { ...state2, review_link: action.payload };
    case 'SET_STORE_CONTACT_GOAL':
      return { ...state2, daily_contact_goal: action.payload };
    case 'SET_WEEKLY_CONTACT_GOAL':
      return { ...state2, weekly_contact_goal: action.payload };
    case 'SET_STORE_SMS':
      return { ...state2, sms_number: action.payload };
    case 'SET_STORE_REF_NUM':
      return { ...state2, pos_ref_num: action.payload };
    case 'SET_DEFAULT_MANAGER':
      return { ...state2, default_manager: action.payload };
    case 'SET_INBOUND_NOTIFY_ALL':
      return { ...state2, inbound_notify_all: action.payload };
    case 'SET_INBOUND_NOTIFY_MANAGERS':
      return { ...state2, inbound_notify_managers: action.payload };
    default:
      break;
  }
  return state2;
}

function boolParse(val) {
  if (val === 1 || val === '1' || val === 'true' || val === true) return true;
  return false;
}

export const setSelectedStore = val => {
  dispatcher('SET_SELECTED_STORE', val);
};
export const setStoreName = val => {
  dispatcher('SET_STORE_NAME', val);
};
export const setStoreAddr1 = val => {
  dispatcher('SET_STORE_ADDR1', val);
};
export const setStoreAddr2 = val => {
  dispatcher('SET_STORE_ADDR2', val);
};
export const setStorePhone = val => {
  dispatcher('SET_STORE_PHONE_NUMBER', val);
};
export const setStoreZip = val => {
  dispatcher('SET_STORE_POSTAL_CODE', val);
};
export const setStoreCity = val => {
  dispatcher('SET_STORE_CITY', val);
};
export const setStoreTimezone = val => {
  dispatcher('SET_STORE_TIMEZONE', val);
};
export const setWCAfterhours = val => {
  dispatcher('SET_WC_AFTERHOURS', val);
};
export const setStoreHourId = val => {
  dispatcher('SET_STOREHOUR_ID', val);
};
export const setAfterhoursMsg = val => {
  dispatcher('SET_AFTERHOURS_MSG', val);
};
export const setTimes = val => {
  dispatcher('SET_TIME', val);
};
export const setConTimes = val => {
  dispatcher('SET_CON_TIME', val);
};
export const setDayCheck = val => {
  dispatcher('SET_DAY_CHECK', val);
};
export const setStoreReviewLink = val => {
  dispatcher('SET_STORE_REVIEW_LINK', val);
};
export const setStoreContactGoal = val => {
  dispatcher('SET_STORE_CONTACT_GOAL', val);
};
export const setStoreWeeklyContactGoal = val => {
  dispatcher('SET_WEEKLY_CONTACT_GOAL', val);
};
export const setStoreReferenceNumber = val => {
  dispatcher('SET_STORE_REF_NUM', val);
};
export const setStoreState = val => {
  dispatcher('SET_STORE_STATE', val);
};
export const setStoreSMS = val => {
  dispatcher('SET_STORE_SMS', val);
};
export const setDefaultManager = val => {
  dispatcher('SET_DEFAULT_MANAGER', val);
};
export const setInboundNotifyAll = val => {
  dispatcher('SET_INBOUND_NOTIFY_ALL', val);
};
export const setInboundNotifyManagers = val => {
  dispatcher('SET_INBOUND_NOTIFY_MANAGERS', val);
};
