/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, addDays } from 'date-fns';
import { getRequest, postRequest } from '../../../../../core/apiRequests';
import StyledLink from '../../../../Layout/StyledLink';
import { sourceCalc, sourceDateCalc } from '../../children/logicFunctions';
import { notificationSuccess } from '../../../../../core/actions';
import KioskSlider from '../../../../Layout/KioskSlider';
import WhatsAppTimer from '../../../../Layout/WhatsAppTimer';
import ToolTip from '../../../../Layout/ToolTip';
import Table from '../../../../Layout/Table';
import PopConfirm from '../../../../Layout/PopConfirm';
import DropDownSelect from '../../../../Layout/DropdownSelect';
import { setTableType } from '../../../../../reducers/pagination';
import { associateNameFormat } from '../../../../../utils';
import SnoozeModal from './SnoozeModal';
import ReminderModal from '../../../../Layout/Modals/EditReminderModal';

const RemindersTable = ({
  initiateChat,
  isShown,
  setIsShown,
  initiateTemplates,
  selected,
  setSelected,
  allClientReminders,
  setAllClientReminders,
  tableData,
  saveSelected,
  auth,
  refresh,
  messaging,
  setShowPopConfirm,
}) => {
  const tableName = 'TODAY_REMINDERS';

  const today = format(new Date(), 'yyyyMMdd');
  const tomorrowNoFormat = addDays(new Date(), 1);
  const now = moment(new Date());

  const tomorrow = format(tomorrowNoFormat, 'yyyyMMdd');
  const expiresOn = (t, expires) => {
    const a = moment(t, 'YYYYMMDD');
    const b = moment(expires, 'YYYYMMDD');
    const diffDays = b.diff(a, 'days');
    return diffDays;
  };
  const [allReminders, setAllReminders] = useState(false);
  const [assignPopup, setAssignPopup] = useState(false);
  const [allAssoc, setAllAssoc] = useState([]);
  const [associate, setAssociate] = useState();
  const [dismissPopup, setDismissPopup] = useState(false);
  const [dismissAllPopup, setDismissAllPopup] = useState(false);
  const [noOverdue, setNoOverdue] = useState(true);
  const [singleRem, setSingleRem] = useState();
  const fullName = associateNameFormat(auth.firstName, auth.lastName);
  const [showSnoozeModal, setShowSnoozeModal] = useState(false);
  const [checkCustomReminder, setCheckCustomReminder] = useState(false);
  const [editReminderModal, setEditReminderModal] = useState(false);

  useEffect(() => {
    if (selected.length === 1) {
      const [action] = selected;
      if (
        !action.life_event_id &&
        !action.tag_action_config_id &&
        !action.sales_opp_id &&
        !action.custom_event_type_id &&
        !action.sales_action_config_id
      ) {
        setCheckCustomReminder(true);
      }
    } else {
      setCheckCustomReminder(false);
    }
  }, [selected]);

  useEffect(() => {
    if (auth.merchantId) {
      const reqObj = {
        params: ['report', auth.merchantId, auth.storeId || 0, 'clientByAction'],
        query: {
          userId: auth.userId,
          role: auth.role,
        },
      };
      // console.log('req object:', reqObj);

      const assoc_data = async () => {
        const data = await getRequest(reqObj);
        const sortedAscts = data.sort((a, b) => {
          if (`${a.first_name || ''}${a.last_name || ''}` > `${b.first_name || ''}${b.last_name}`) {
            return 1;
          }
          if (`${a.first_name || ''}${a.last_name || ''}` < `${b.first_name || ''}${b.last_name}`) {
            return -1;
          }
          return 0;
        });
        // console.log('assoc data req object:', reqObj);
        setAllAssoc([...sortedAscts]);
        setAssociate(auth.userId);
      };
      assoc_data();
    }
  }, [auth.merchantId, auth.storeId]);

  useEffect(() => {
    if (!messaging?.client?.reminderId) {
      refresh();
    }
  }, [messaging?.client?.reminderId]);

  // comment to push changes to release
  const assignAssociate = () => {
    setAssignPopup(false);
    const reqObj = {
      params: ['today', auth.merchantId, 'assignAssociate'],
      data: { selectedAction: selected },
      query: {
        assignedAssociate: associate,
        storeId: auth.storeId,
        associateId: auth.userId,
      },
    };
    // console.log('req object:', reqObj);

    (async () => {
      const data = await postRequest(reqObj);
      if (data) {
        setSelected([]);
        refresh();
        notificationSuccess('Reminder(s) Assigned!');
      }
    })();
  };

  const dismissAll = () => {
    const reqObj = {
      params: ['today', auth.merchantId, 'dismissAll'],
      query: { associateName: associateNameFormat(auth.firstName, auth.lastName), associateId: auth.userId },
    };
    if (!allClientReminders) {
      reqObj.query.myClients = auth.userId;
    }
    (async () => {
      const responseObj = await getRequest(reqObj);
      if (responseObj) {
        setDismissAllPopup(false);
        refresh();
        notificationSuccess('Reminder(s) Dismissed!');
      }
    })();
  };
  const dismissSelected = () => {
    const reqObj = {
      params: ['today', auth.merchantId, 'dismissSelected'],
      query: { associateName: associateNameFormat(auth.firstName, auth.lastName), associateId: auth.userId },
      data: { selectedAction: selected },
    };
    (async () => {
      const responseObj = await postRequest(reqObj);
      if (responseObj) {
        setSelected([]);
        setDismissPopup(false);
        refresh();
        notificationSuccess('Reminder(s) Dismissed!');
      }
    })();
  };

  const rowClickHandler = data => {
    setSingleRem([data]);
  };

  const messageConfigToday = (message, msgObj) => {
    const whatsAppTemplates = [];
    if (msgObj.preferred_contact_type === 'WHATSAPP') {
      if (WhatsAppTimer(msgObj.WA_receive)) {
        whatsAppTemplates.push(
          <FontAwesomeIcon
            className="checkbox fs-19"
            color="#818181"
            icon={['far', 'comment-alt-lines']}
            onClick={() => initiateChat(msgObj, setShowPopConfirm)}
          />,
        );
        return whatsAppTemplates;
      }
      whatsAppTemplates.push(
        <FontAwesomeIcon
          className="checkbox fs-19"
          color="#818181"
          icon={['far', 'comment-alt-lines']}
          onClick={() => initiateTemplates(msgObj)}
        />,
      );
    } else {
      whatsAppTemplates.push(
        <FontAwesomeIcon
          className="checkbox fs-19"
          color="#818181"
          icon={['far', 'comment-alt-lines']}
          onClick={() => initiateChat({ ...msgObj, reminderId: msgObj.id })}
        />,
      );
      return whatsAppTemplates;
    }
    return whatsAppTemplates;
  };

  const checkboxClickHandler = (obj, included) => {
    let tempSel = [...selected];
    if (!included) {
      tempSel.push({
        ...obj,
      });
    } else {
      tempSel = selected.filter(arrId => arrId.id !== Number(obj.id));
    }
    setSelected(tempSel);
  };

  const addAll = () => {
    if (allReminders === false) {
      setSelected(tableData);
    } else {
      setSelected([]);
    }
  };

  const tableHeaders = [
    {
      keyName: 'id',
      title: (
        <div
          value={allReminders}
          className="rem-pad-width"
          role="checkbox"
          aria-checked="false"
          tabIndex="0"
          onClick={() => {
            setAllReminders(!allReminders);
            addAll();
          }}
          onKeyDown={() => {
            setAllReminders(!allReminders);
            addAll();
          }}
          style={{
            border: allReminders ? 'none' : '2px solid #b8b8b8',
            backgroundColor: allReminders ? '#1769af' : '#fff',
            width: '16px',
            height: '16px',
            paddingBottom: allReminders ? '3px' : '0px',
            paddingLeft: allReminders ? '1.5px' : '0px',
          }}
        >
          {allReminders === true ? <FontAwesomeIcon icon={['fas', 'check']} color="#fff" /> : null}
        </div>
      ),
      rowClassName: 'associate-row-image no-text-select',
      isImg: 'image',
      style: { minWidth: '30px', width: '1%', paddingTop: '0px' },
      headerClassName: '',
      sortable: false, // This targets the header's className
      render: (t, o) => {
        if (selected.filter(obj => obj.id === t).length < 1) {
          return (
            <FontAwesomeIcon
              onClick={() => checkboxClickHandler(o, false)}
              className="fs-16 gray"
              icon={['far', 'square']}
            />
          );
        }
        return (
          <FontAwesomeIcon
            onClick={() => checkboxClickHandler(o, true)}
            className="fs-16"
            color="#1769af"
            icon={['fas', 'check-square']}
          />
        );
      },
    },
    {
      keyName: 'due_date',
      title: 'Due Date',
      style: { width: '10%', paddingTop: '0px' },
      headerClassName: '',
      rowClassName: '', // we can apply all styles to the header now.
      sortable: true, // This targets the header's className
      render: (t, o) => {
        if (t) {
          if (parseInt(today) === o.expires_on_date) {
            return (
              <div className="flex-col-left">
                {moment(t, 'YYYYMMDD').format('MM/DD/YYYY')}
                <span className="fs-12 fw-400 gray">Expires today</span>
              </div>
            );
          }
          if (parseInt(tomorrow) === o.expires_on_date) {
            return (
              <div className="flex-col-left">
                {moment(t, 'YYYYMMDD').format('MM/DD/YYYY')}
                <span className="fs-12 fw-400 gray">Expires tomorrow</span>
              </div>
            );
          }
          if (o.expires_on_date) {
            return (
              <div className="flex-col-left">
                {moment(t, 'YYYYMMDD').format('MM/DD/YYYY')}
                <span className="fs-12 fw-400 gray">Expires in {expiresOn(today, o.expires_on_date)} days</span>
              </div>
            );
          }
          return moment(t, 'YYYYMMDD').format('MM/DD/YYYY');
        }
      },
    },
    {
      keyName: 'name',
      style: { width: '5%', minWidth: '120px', paddingTop: '0px' },
      title: 'Client Name',
      sortable: false, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        return (
          <StyledLink classprops="blue-link" to={`/Clients?client=${o.client_id}`}>
            {t}
          </StyledLink>
        );
      },
    },
    {
      keyName: 'description',
      style: { width: '15%', minWidth: '120px', paddingTop: '0px' },
      title: 'Description',
      sortable: false,
    },
    {
      keyName: '',
      style: { width: '5%', paddingTop: '0px', paddingRight: '20px' },
      title: 'Source',
      sortable: false,
      render: (t, o) => {
        return sourceCalc(o);
      },
    },
    {
      keyName: 'associate_first_name',
      style: { width: '10%', minWidth: '120px', paddingTop: '0px' },
      title: 'Assigned to',
      sortable: true, // sort allows gives us the ability to sort the table
      render: (t, o) => {
        if (t) {
          if (`${t} ${o.associate_last_name}`.trim() === fullName.trim()) {
            return (
              <div onClick={() => setAssignPopup(true)}>
                <FontAwesomeIcon icon={['far', 'pencil']} className="p-right-10 gray" />
                <span className="fs-12 fw-400 gray" style={{ fontStyle: 'italic' }}>
                  {`${o.associate_first_name || ''}  ${o.associate_last_name || ''}`}
                </span>
              </div>
            );
          }
          return (
            <div onClick={() => setAssignPopup(true)}>
              <FontAwesomeIcon icon={['far', 'pencil']} className="p-right-10 gray" />
              <span className="fs-12 fw-400 black">
                {' '}
                {`${o.associate_first_name || ''}  ${o.associate_last_name || ''}`}
              </span>
            </div>
          );
        }
        return (
          <div>
            <FontAwesomeIcon
              icon={['far', 'pencil']}
              onClick={() => setAssignPopup(true)}
              className="p-right-10 gray"
              style={{ fontStyle: 'italic' }}
            />
            <span className="fs-12 fw-400 gray">Unassigned</span>
          </div>
        );
      },
    },
    {
      keyName: 'reassigned_associate_first_name',
      style: { width: '10%', minWidth: '120px', paddingTop: '0px' },
      title: 'Reassigned by',
      sortable: false,
      render: (t, o) => {
        if (t) {
          return (
            <div className="flex-col-left">
              {`${o.reassigned_associate_first_name || ''}  ${o.reassigned_associate_last_name || ''}`}
              <span className="fs-12 fw-400 gray">
                {moment(o.reassigned_date, 'YYYYMMDDhhmmss').format('MM/DD/YYYY')}
              </span>
            </div>
          );
        }
      },
    },
    {
      keyName: '',
      style: { width: '5%', paddingTop: '0px' },
      headerClassName: 'align-center',
      title: 'Message',
      sortable: false,
      render: (t, o) => {
        return (
          <div className="align-center pos-rel" style={{ maxHeight: '34px', maxWidth: '87px' }}>
            {messageConfigToday(t, o)}
          </div>
        );
      },
    },
    {
      keyName: '',
      style: { width: '5%', paddingTop: '0px' },
      title: 'Status',
      render: (t, o) => {
        const bubbleStyles = {
          padding: '4px 0 2px 0',
        };
        if (!o.complete_date) {
          if (moment(now).isAfter(moment(o.due_date, 'YYYYMMDD').add(1, 'day'))) {
            setNoOverdue(false);
            return (
              <div
                style={{
                  ...bubbleStyles,
                  backgroundColor: '#f9e0e0',
                  color: '#ff7a7a',
                }}
                className="align-center border-r3 rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 blue"
              >
                OVERDUE
              </div>
            );
          }
          return (
            <div
              style={{
                ...bubbleStyles,
                backgroundColor: '#ECF8DD',
                color: ' #7FBD31',
              }}
              className="align-center border-r3 rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 blue"
            >
              ACTIVE
            </div>
          );
        }
      },
      sortable: false,
    },
  ];

  return (
    <div className="align-left" onClick={() => setTableType('TODAY_REMINDERS')}>
      <div className="flex-row-spacebetween-nowrap">
        <div className="flex-col-left" style={{ width: '800px' }}>
          <span className="fs-15 fw-500">Reminders</span>
          <span className="gray fs-12 fw-400">
            {
              "Switch on the toggle to see all of your client's reminders. This may include reminders that are connected to your clients, but are assigned to other associates. Keep the toggle off to only see reminders that are assigned to you."
            }
          </span>
        </div>
        {assignPopup && (
          <PopConfirm
            popStyle={{
              width: '380px',
            }}
            confirmButtonText="Save"
            cancelButtonText="Cancel"
            confirm="Assign Associate"
            description={[
              <div key="primary" className="align-left m-top-10" style={{ height: '175px' }}>
                <span className="dropDownDescriptionText">Assign an Associate</span>
                <DropDownSelect
                  classProps="mq-w-200 w-295 align-left m-top-8"
                  options={allAssoc.map(asc => ({
                    label: asc.fullName ? asc.fullName : asc.first_name,
                    value: asc.id,
                  }))}
                  placeholder="Select an Associate..."
                  value={associate}
                  onChange={obj => {
                    setAssociate(obj.value);
                  }}
                />
              </div>,
            ]}
            noButtonDisable
            onCancel={() => setAssignPopup(false)}
            onConfirm={assignAssociate}
          />
        )}
        <div className="m-top-20">
          <KioskSlider
            value={allClientReminders}
            onChange={() => {
              if (allClientReminders === true) {
                setAllClientReminders(false);
              } else {
                setAllClientReminders(true);
              }
            }}
          />
          <span className="m-left-7">{"My client's reminders"}</span>
        </div>
      </div>
      {selected.length > 0 ? (
        <div className="add-edit-wrap" style={{ justifyContent: 'flex-end' }}>
          {checkCustomReminder && (
            <button
              type="button"
              className="flex-row-nospacebetwee-nowrap border-none pointer"
              style={{ alignItems: 'center', backgroundColor: '#fff', marginRight: '45px' }}
              onClick={() => setEditReminderModal(true)}
            >
              <FontAwesomeIcon icon={['far', 'pencil']} className="fs-13 m-right-8" color="#33AAFC" />
              <span className="fw-600 fs-13" style={{ color: '#33AAFC' }}>
                Edit
              </span>
            </button>
          )}
          <button
            className="flex-row-nospacebetwee-nowrap border-none pointer"
            style={{ alignItems: 'center', backgroundColor: '#fff', marginRight: '45px' }}
            onClick={() => setAssignPopup(true)}
          >
            <FontAwesomeIcon icon={['far', 'user']} className="fs-13 m-right-8" color="#1769AF" />
            <span className="fw-600 fs-13" style={{ color: '#1769AF' }}>
              Assign Associate
            </span>
          </button>
          <button
            className="flex-row-nospacebetwee-nowrap border-none pointer"
            style={{ alignItems: 'center', backgroundColor: '#fff', marginRight: '45px' }}
            onClick={saveSelected}
          >
            <FontAwesomeIcon icon={['far', 'check-circle']} className="fs-16 m-right-8" color="#1769AF" />
            <span className="fw-600 fs-13" style={{ color: '#1769AF' }}>
              Complete
            </span>
          </button>
          <button
            className="flex-row-nospacebetwee-nowrap border-none pointer"
            style={{ alignItems: 'center', backgroundColor: '#fff', marginRight: '45px' }}
            onClick={() => setDismissPopup(true)}
          >
            <FontAwesomeIcon icon={['far', 'eraser']} className="fs-16 m-right-8" color="#1769AF" />
            <span className="fw-600 fs-13" style={{ color: '#1769AF' }}>
              Dismiss
            </span>
          </button>
          <button
            className="flex-row-nospacebetwee-nowrap border-none pointer"
            style={{ alignItems: 'center', backgroundColor: '#fff', paddingRight: '0px' }}
            onClick={() => setShowSnoozeModal(true)}
          >
            <FontAwesomeIcon icon="fa-sharp fa-regular fa-alarm-clock" className="fs-16 m-right-8" color="#1769AF" />
            <span className="fw-600 fs-13" style={{ color: '#1769AF' }}>
              Snooze
            </span>
          </button>
        </div>
      ) : (
        <div className="add-edit-wrap" style={{ justifyContent: 'flex-end' }}>
          <button
            className="flex-row-nospacebetwee-nowrap border-none pointer"
            style={
              noOverdue
                ? { alignItems: 'center', backgroundColor: '#fff', paddingRight: '0px', opacity: '.5' }
                : { alignItems: 'center', backgroundColor: '#fff', paddingRight: '0px' }
            }
            onClick={() => setDismissAllPopup(true)}
            disabled={noOverdue}
          >
            <FontAwesomeIcon icon={['far', 'eraser']} className="fs-16 m-right-8" color="#1769AF" />
            <span className="fw-600 fs-13" style={{ color: '#1769AF' }}>
              Dismiss All Overdue
            </span>
          </button>
        </div>
      )}
      <Table
        tableClassName="lg-table-sidebar  rem-pad-width"
        data={tableData}
        tableHeaders={tableHeaders}
        searchable={false}
        paginationV2
        onRowClick={rowClickHandler}
        tableName={tableName}
      />
      {dismissAllPopup ? (
        <PopConfirm
          popStyle={{
            width: '380px',
          }}
          customHeaderContent="fs-16 align-center"
          confirmButtonText="Dismiss"
          cancelButtonText="Cancel"
          confirm="Dismiss All Overdue Reminders"
          description={
            allClientReminders
              ? 'All overdue reminders will be removed from this list. If you continue, these reminders will be permanently removed from all associate profiles, not just yours.'
              : 'All overdue reminders will be removed from your list. If you continue, these reminders will be permanently removed from your profile.'
          }
          noButtonDisable
          onCancel={() => setDismissAllPopup(false)}
          onConfirm={dismissAll}
        />
      ) : null}
      {dismissPopup ? (
        <PopConfirm
          popStyle={{
            width: '380px',
          }}
          customHeaderContent="fs-16 align-center"
          confirmButtonText="Dismiss"
          cancelButtonText="Cancel"
          confirm="Dismiss Selected Reminders"
          description={
            'These reminders will be dismissed from your list.  If you continue, these reminders will be permanently removed from your profile.'
          }
          noButtonDisable
          onCancel={() => setDismissPopup(false)}
          onConfirm={dismissSelected}
        />
      ) : null}
      {editReminderModal && (
        <ReminderModal
          open={editReminderModal}
          reminder={selected?.[0] ?? {}}
          allAssoc={allAssoc}
          onClose={() => setEditReminderModal(false)}
          setReload={refresh}
        />
      )}
      <SnoozeModal
        openModal={showSnoozeModal}
        setOpenModal={setShowSnoozeModal}
        merchantId={auth.merchantId}
        actions={selected}
        refresh={refresh}
      />
    </div>
  );
};

const mapStateToProps = state => ({ auth: state.auth, messaging: state.messaging });
export default connect(mapStateToProps, {})(RemindersTable);
