import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { postRequest, importLargeData } from '../../../../core/apiRequests';
import { notificationSuccess, notificationError } from '../../../../core/actions';
import { setSelectedClientList } from '../../../../reducers/client';

const MultiClientTagManagerModalV2 = props => {
  const [tagData, setTagData] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [disableSave, setDisableSave] = useState(false);
  const [areThereMoreTags, setAreThereMoreTags] = useState(true);
  const [page, setPage] = useState(1);
  const [tagAccess, setTagAccess] = useState({});
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    if (props.auth.merchantId && props.client.selected_client_list.length > 0) {
      const { ascendingColumn } = props.pagination;
      (async () => {
        const reqData = await importLargeData(
          'clients',
          props.auth.merchantId,
          props.client.selected_client_list,
          'getTagData',
          `?page=${page}&count=${100}&sortColumn=${'name'}&ascending=${ascendingColumn}&searchText=${searchText}`,
        );
        if (reqData && reqData.results) {
          const { results } = reqData;
          if (searchText.length > 0) {
            const lVal = searchText.toLowerCase();
            const filtered = results.filter(tag => {
              if (tag.name.toLowerCase().includes(lVal)) {
                return true;
              }
              return false;
            });
            if (filtered.length === 0) {
              setTagData([]);
              setPage(1);
            } else setTagData([...filtered]);
          } else if (page === 1) {
            setTagData(results);
          } else if (reqData.results.length === 0) {
            setAreThereMoreTags(false);
          } else setTagData([...tagData, ...results]);
        } else {
          notificationError('Error Getting Tags List');
        }
      })();
    }
    return () => {
      isMounted.current = false;
    };
  }, [props.auth.merchantId, page, props.pagination.ascendingColumn, searchText]);

  const saveTagAccess = () => {
    setDisableSave(true);
    (async () => {
      const fn = props.auth.firstName || '';
      const ln = props.auth.lastName || '';
      const reqObj = {
        params: ['clients', props.auth.merchantId, 'saveTagData'],
        data: {
          userList: props.client.selected_client_list,
          tagAccess,
        },
        query: {
          createdBy: `${fn} ${ln}`.trim(),
        },
      };
      const reqData = await postRequest(reqObj);
      if (reqData) {
        notificationSuccess('Tags Saved To Clients!');
        setDisableSave(false);
        setSelectedClientList([]);
        props.showTagManagerHandler(false);
        if (props.client.selected_client_list.includes(Number(props.client.client_id))) {
          props.getClientData(props.client.client_id);
        }
      } else {
        notificationError('Error Saving Tags To Clients');
        setDisableSave(false);
      }
    })();
  };

  const tagClickHandler = id => {
    if (tagAccess.hasOwnProperty(id)) {
      if (tagAccess[id] === true) {
        setTagAccess({ ...tagAccess, [id]: false });
      } else if (tagAccess[id] === false) {
        let existingData = false;
        tagData.forEach(tg => {
          if (tg.id === id) {
            if (Number(tg.cnt) > 0 && Number(tg.cnt) < props.client.selected_client_list.length) {
              existingData = true;
            }
          }
        });
        if (existingData === true) {
          const tempObj = tagAccess;
          delete tempObj[id];
          setTagAccess({ ...tempObj });
        } else {
          setTagAccess({ ...tagAccess, [id]: true });
        }
      }
    } else {
      let existingData = false;
      tagData.forEach(tg => {
        if (tg.id === id) {
          if (Number(tg.cnt) === props.client.selected_client_list.length) {
            existingData = true;
          }
        }
      });
      if (existingData === true) {
        setTagAccess({ ...tagAccess, [id]: false }); // it's already basically "true", so make it false.
      } else {
        setTagAccess({ ...tagAccess, [id]: true }); // then none of their clients had access.
      }
    }
  };

  const searchHandler = val => {
    if (val.length === 0) {
      setSearchText('');
    } else setSearchText(val);
  };

  const whichList = () => {
    const sortedTagsArr = [];
    props.client.details_tag_list.forEach(tag => {
      if (tag.client_id === null) {
        sortedTagsArr.push(tag);
      }
    });
    sortedTagsArr.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return -1;
    });
    if (searchText) {
      return filterTags;
    }
    const selected = props.client.details_tag_list.filter(tag => {
      if (tag.client_id !== null) {
        return tag;
      }
    });
    return selected.concat(sortedTagsArr);
  };

  const tagCircle = tag => {
    if (tagAccess.hasOwnProperty(tag.id)) {
      if (!tagAccess[tag.id]) {
        return <FontAwesomeIcon icon="fa-regular fa-circle" className="fs-18 m-right-15" color="#000" />;
      }
      return <FontAwesomeIcon icon="fas fa-check-circle" className="fs-18 m-right-15" color="#000" />;
    }
    if (Number(tag.cnt) === 0) {
      return <FontAwesomeIcon icon="fa-regular fa-circle" className="fs-18 m-right-15" color="#000" />;
    }
    if (Number(tag.cnt) === props.client.selected_client_list.length) {
      return <FontAwesomeIcon icon="fas fa-check-circle" className="fs-18 m-right-15" color="#000" />;
    }
    return <FontAwesomeIcon className="fs-18 m-right-15" icon={['far', 'adjust']} />;
  };

  const incrementPage = () => {
    const newPage = page + 1;
    setPage(newPage);
  };

  return (
    <div className="pop-confirm-background" id="modal-background">
      <div className="pop-confirm-container rem-pad-width" style={{ width: '540px', height: '748px' }}>
        <div
          className="flex-col-center h-100-P"
          style={{
            justifyContent: 'flex-start',
            width: '450px',
            margin: '0 auto',
          }}
        >
          <div className="flex-row-spacebetween-nowrap w-100-P m-top-15">
            <div />
          </div>
          <span className="fs-18 fw-600" style={{ marginTop: '15px' }}>
            Edit Tags
          </span>
          <div className="w-100-P align-right m-top-15">
            <Tooltip
              overlayInnerStyle={{ borderRadius: '5px', textAlign: 'center' }}
              placement="bottom"
              title="Tag is added to all selected clients"
            >
              <FontAwesomeIcon icon="fas fa-check-circle" className="fs-18 m-right-15" color="#000" />
            </Tooltip>
            <Tooltip
              overlayInnerStyle={{ borderRadius: '5px', textAlign: 'center' }}
              placement="bottom"
              title="Tag is added to some selected clients"
            >
              <FontAwesomeIcon className="fs-18 m-right-15" icon={['far', 'adjust']} />
            </Tooltip>
            <Tooltip
              overlayInnerStyle={{ borderRadius: '5px', textAlign: 'center' }}
              placement="bottom"
              title="Tag is added to no selected clients"
            >
              <FontAwesomeIcon icon="fa-regular fa-circle" className="fs-18 m-right-15" color="#000" />
            </Tooltip>
          </div>
          <input
            placeholder="Search"
            value={searchText}
            onChange={e => searchHandler(e.target.value)}
            className="h-50 w-100-P m-top-10 m-btm-6 clientMultiAsctSearch rem-pad-width focus-input-outline-blue"
            style={{
              width: '100%',
              height: '50px',
              backgroundColor: '#EBEBEB',
            }}
          />

          <div
            id="scrollableDivTag"
            style={{
              paddingTop: '25px',
              height: '600px',
              paddingLeft: '10px',
              width: '450px',
            }}
            className="w-295 rem-pad-width chatBubblePadding overflowY hide-scrollbar"
          >
            <InfiniteScroll
              dataLength={tagData.length}
              next={incrementPage}
              hasMore={areThereMoreTags}
              // loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
              endMessage={<h4 style={{ margin: '12px 16px', paddingBottom: '40px' }}>No more Tags</h4>}
              scrollableTarget="scrollableDivTag"
              pullDownToRefreshThreshold={50}
            >
              <ul className="ul-no-style flex-col-left ">
                {tagData
                  .filter(tag => tag.id !== 0)
                  .map(tag => {
                    return (
                      <li
                        key={tag.id}
                        className="fs-14 flex-row-nospacebetween-nowrap m-btm-12 pointer w-100-P p-btm-13"
                        onClick={() => tagClickHandler(tag.id)}
                        style={{ borderBottom: 'solid .5px #EBEBEB' }}
                      >
                        {tagCircle(tag)}
                        <span className="circle w-12 h-12 m-right-18" style={{ backgroundColor: tag.color }} />
                        <span className="fs-12 CBdarkBlue align-left" style={{ maxWidth: '73%' }}>
                          {tag.name}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </InfiniteScroll>
            {/* <ul className="ul-no-style flex-col-left ">
              {tagData
                .filter(tag => tag.id !== 0)
                .map(tag => {
                  return (
                    <li
                      key={tag.id}
                      className="fs-14 flex-row-nospacebetween-nowrap m-btm-12 pointer w-100-P p-btm-13"
                      onClick={() => tagClickHandler(tag.id)}
                      style={{ borderBottom: 'solid .5px #EBEBEB' }}
                    >
                      {tagCircle(tag)}
                      <span className="circle w-12 h-12 m-right-18" style={{ backgroundColor: tag.color }} />
                      <span className="fs-12 CBdarkBlue align-left" style={{ maxWidth: '73%' }}>
                        {tag.name}
                      </span>
                    </li>
                  );
                })}
            </ul> */}
          </div>
          <div className="mq-w-100-vw custom-action-save-flex m-top-30 m-btm-28">
            <button type="button" className="save-button-v2 m-btm-8" onClick={saveTagAccess}>
              Save
            </button>
            <button type="button" className="cancel-button-v2" onClick={() => props.showTagManagerHandler(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiClientTagManagerModalV2;
