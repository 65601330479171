import React from 'react';
import PaymentBannerSpacer from '../../../../Layout/PaymentBannerSpacer';

const Submitted = props => {
  const onFinishHandler = () => {
    props.setUploadModal('');
    props.resetData();
  };

  return (
    <div className="pos-abs z-index-5 left-0 align-top w-100-vw h-100-vh bg-white p-42 rem-pad-width max-h-100vh overflowY m-top-44">
      <PaymentBannerSpacer />
      <div className="maw-965">
        <div className="flex-row-spacebetween-wrap w-100-P align-items-center">
          <div className="flex-col-left">
            <span className="align-left fs-18 fw-500 w-100-P">Upload Data</span>
            <span className="align-left fs-14 w-100-P">Bulk Add {props.data.length} {props.endPoint}</span>
          </div>
          <span className="layoutBreadcrumb">
            <span
              className="completed-bread-crumb"
              style={{
                padding: '3.5px 9.765px',
              }}
            >
              Upload
            </span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span className="completed-bread-crumb" style={{ padding: '3.5px 11.87px' }}>
              Match
            </span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span className="completed-bread-crumb" style={{ padding: '3.5px 10.02px' }}>
              Review
            </span>
            <span className="bread-crumb-arrows">{'>>'}</span>
            <span className="completed-bread-crumb" style={{ padding: '3.5px 14.84px' }}>
              Done
            </span>
          </span>
        </div>
        <div className="header-container column">
          <span className="align-center fs-18 fw-500 w-100-P">Upload Submitted for Processing</span>
          <span className="w-296 h-40 fs-14 m-top-7 m-btm-41 black">Your results will be emailed after completion</span>
          <button className="import-positive-button" onClick={() => onFinishHandler()}>
            FINISH
          </button>
        </div>
      </div>
    </div>
  );
};

export default Submitted;
