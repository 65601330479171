import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'antd';

const BulkActions = props => {
  const flags = props.auth?.merchantFlags;
  const flaggedAddBulkTags = flags?.tags?.enabled;
  
  const handleMenuClick = e => {
    switch (e.key) {
    case '1':
      // console.log('Assign to You')
    case '2':
        props.setOpenAssignAsctManager(true);
        break;
    case '3':
       props.setOpenTagManager(true);
       break;
    default:
      break;
    }
  };
  const items = [
    // {
    //   label: 'Assign to You',
    //   key: '1',
    //   icon: <FontAwesomeIcon icon="fa-solid fa-bookmark" />,
    // },
    {
      label: 'Edit Associates',
      key: '2',
      icon: <FontAwesomeIcon icon="fa-solid fa-user" />,
    },
  ];

  if (flaggedAddBulkTags) {
    items.push({
      label: 'Edit Tags',
      key: '3',
      icon: <FontAwesomeIcon icon="fa-regular fa-tags" />,
    });
  }

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown menu={menuProps}>
      <div className="fw-600 fs-13">
        <FontAwesomeIcon
          size="1x"
          icon="fa-light fa-filter-list"
          style={{ color: '#33aafc' }}
          className="fs-16 m-right-5"
        />
        <span style={{ color: '#33aafc' }}>Bulk Actions</span>
      </div>
    </Dropdown>
  );
};

export default BulkActions;
