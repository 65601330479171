import React from 'react';
import { Button, Tooltip } from 'antd';
import { format } from 'date-fns';
import formatNumberAsCurrency from '../../../../../../../../utils/formatNumberAsCurrency';
import truncateText from '../../../../../../../../utils/truncateText';
import './styles.css';

const formatAmount = value => {
  const parsedValue = parseFloat(value);
  if (!value && parsedValue !== 0) {
    return '-.--';
  }

  return formatNumberAsCurrency(value);
};

const getStatusClass = value => {
  return value.toLowerCase().replace('_', '-');
};

const PaymentRequestCard = ({ paymentRequest, onClick }) => {
  return (
    <div className="cb-payment-request-card">
      <div className="cb-payment-request-card--header">
        <h2 className="cb-payment-request-card--amount">{formatAmount(paymentRequest.total_amount)}</h2>

        <h3 className="cb-payment-request-card--description">
          <Tooltip title={paymentRequest.descriptions}>
            {truncateText(paymentRequest.descriptions, 115, { cutWords: true })}
          </Tooltip>
        </h3>

        <h3 className="cb-payment-request-card--date">
          {format(new Date(paymentRequest.created_at), 'MMMM d, y')} (Created time)
        </h3>

        <span
          className={`cb-payment-request-card--status cb-payment-request-card--${getStatusClass(
            paymentRequest.status,
          )}`}
        >
          {paymentRequest.status}
        </span>
      </div>

      <hr className="cb-payment-request-card--hr" />

      <div className="cb-payment-request-card--footer">
        <Button type="link" className="cb-payment-request-card--button" onClick={onClick}>
          Message
        </Button>
      </div>
    </div>
  );
};

export default PaymentRequestCard;
