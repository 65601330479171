import React from 'react';
import { format, addDays } from 'date-fns';
import { sourceCalc, sourceDateCalc } from './logicFunctions';
import StyledLink from '../../../../Layout/StyledLink';

const moment = require('moment');

const today = format(new Date(), 'yyyyMMdd');
const tomorrowNoFormat = addDays(new Date(), 1);
const tomorrow = format(tomorrowNoFormat, 'yyyyMMdd');
const expiresOn = (t, expires) => {
  const a = moment(t, 'YYYYMMDD');
  const b = moment(expires, 'YYYYMMDD');
  const diffDays = b.diff(a, 'days');
  return diffDays;
};

export const viewActivity = [
  {
    keyName: 'created_date',
    title: 'Date',
    headerClassName: 'col-p-l-25 rem-pad-width',
    rowClassName: 'col-p-l-25 rem-pad-width',
    style: { width: '1%', minWidth: '110px', paddingRight: '22px' },
    sortable: true,
    render: t => moment(t, 'YYYYMMDDhhmmss').format('MM/DD/YYYY'),
  },
  {
    keyName: 'type',
    title: 'Activity Type',
    style: { width: '3%', minWidth: '160px', paddingRight: '22px' },
    sortable: true,
  },
  {
    keyName: 'name',
    title: 'Client',
    style: { width: '3%', minWidth: '120px', paddingRight: '22px' },
    rowClassName: '',
    sortable: true,
  },
  {
    keyName: 'notes',
    title: 'Details',
    style: { width: '3%', minWidth: '130px', paddingRight: '22px' },
    rowClassName: '',
    sortable: true,
  },
];

export const viewMessages = [
  {
    keyName: 'name',
    title: 'Client',
    headerClassName: 'col-p-l-25 rem-pad-width',
    rowClassName: 'col-p-l-25 rem-pad-width',
    style: { width: '10%', minWidth: '110px', paddingRight: '22px' },
    sortable: true,
  },
  {
    keyName: 'msg',
    title: 'Message',
    style: { width: '35%', minWidth: '160px', paddingRight: '22px' },
    sortable: true,
  },
  {
    keyName: 'sent',
    title: 'Date',
    style: { width: '3%', minWidth: '120px', paddingRight: '22px' },
    rowClassName: '',
    sortable: true,
    render: t => moment(t, 'YYYYMMDDhhmmss').format('MM/DD/YYYY'),
  },
  {
    // keyName: "sent_timestamp",
    keyName: 'sent',
    title: 'Time',
    style: { width: '3%', minWidth: '130px', paddingRight: '22px' },
    rowClassName: '',
    sortable: true,
    render: t => moment(t, 'YYYYMMDDHHmmss').format('h:mm a'),
  },
];

export const viewReminders = [
  {
    keyName: 'due_date',
    title: 'Due Date',
    style: { width: '5%', paddingTop: '0px' },
    headerClassName: '',
    rowClassName: '', // we can apply all styles to the header now.
    sortable: true, // This targets the header's className
    render: (t, o) => {
      if (t) {
        if (parseInt(today) === o.expires_on_date) {
          return (
            <div className="flex-col-left">
              {moment(t, 'YYYYMMDD').format('MM/DD/YYYY')}
              <span className="fs-12 fw-400 gray">Expires today</span>
            </div>
          );
        }
        if (parseInt(tomorrow) === o.expires_on_date) {
          return (
            <div className="flex-col-left">
              {moment(t, 'YYYYMMDD').format('MM/DD/YYYY')}
              <span className="fs-12 fw-400 gray">Expires tomorrow</span>
            </div>
          );
        }
        if (o.expires_on_date) {
          return (
            <div className="flex-col-left">
              {moment(t, 'YYYYMMDD').format('MM/DD/YYYY')}
              <span className="fs-12 fw-400 gray">Expires in {expiresOn(today, o.expires_on_date)} days</span>
            </div>
          );
        }
        return moment(t, 'YYYYMMDD').format('MM/DD/YYYY');
      }
    },
  },
  {
    keyName: 'name',
    style: { width: '5%', minWidth: '120px', paddingTop: '0px' },
    title: 'Client Name',
    sortable: true, // sort allows gives us the ability to sort the table
    render: (t, o) => {
      return (
        <StyledLink classprops="blue-link" to={`/Clients?client=${o.client_id}`}>
          {t}
        </StyledLink>
      );
    },
  },
  {
    keyName: 'description',
    style: { width: '15%', minWidth: '120px', paddingTop: '0px' },
    title: 'Description',
    sortable: false,
  },
  {
    keyName: '',
    style: { width: '5%', paddingTop: '0px', paddingRight: '20px' },
    title: 'Source',
    sortable: false,
    render: (t, o) => {
      return sourceCalc(o);
    },
  },
  {
    keyName: 'source_date',
    style: { width: '1%', minWidth: '90px', paddingTop: '0px' },
    title: 'Source Date',
    sortable: false,
    render: (t, o) => {
      return sourceDateCalc(o);
    },
  },
  {
    keyName: '',
    style: { width: '5%', paddingTop: '0px' },
    title: 'Status',
    render: (t, o) => {
      const bubbleStyles = {
        padding: '4px 0 2px 0',
      };
      if (!o.complete_date) {
        if (moment().isAfter(moment(o.due_date, 'YYYYMMDD').add(1, 'day'))) {
          return (
            <div
              style={{
                ...bubbleStyles,
                backgroundColor: '#f9e0e0',
                color: '#ff7a7a',
              }}
              className="align-center border-r3 rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 blue"
            >
              OVERDUE
            </div>
          );
        }
        return (
          <div
            style={{
              ...bubbleStyles,
              backgroundColor: '#ECF8DD',
              color: ' #7FBD31',
            }}
            className="align-center border-r3 rem-pad-width align-vert-middle h-24 fs-10 fw-600 w-72 blue"
          >
            ACTIVE
          </div>
        );
      }
    },
    sortable: false,
  },
];

export const completeActions = [
  {
    keyName: 'complete_date',
    title: 'Completed Date',
    headerClassName: 'col-p-l-25 rem-pad-width',
    rowClassName: 'col-p-l-25 rem-pad-width',
    style: { width: '1%', minWidth: '110px', paddingRight: '22px' },
    sortable: true,
    render: t => moment(t, 'YYYYMMDDhhmmss').format('MM/DD/YYYY'),
  },
  {
    keyName: 'name',
    title: 'Client',
    style: { width: '3%', minWidth: '160px', paddingRight: '22px' },
    sortable: true,
  },
  {
    keyName: 'description',
    title: 'Description',
    style: { width: '3%', minWidth: '120px', paddingRight: '22px' },
    rowClassName: '',
    sortable: true,
  },
];
