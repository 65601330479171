import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Tooltip } from 'antd';
 
const FlaggedLimitIcon = ({message, placement}) => {


    return (
        <div className="h-100-P flex-col-center p-top-7 rem-pad-width" style={{alignItems: 'center'}}>

            <Tooltip title={message} placement={placement || 'bottomRight'} overlayInnerStyle={{borderRadius: "10px", right: '20px'}} overlayStyle={{ marginLeft: '20px'}} > 
                <div className="w-46">
                    <FontAwesomeIcon className="missing-data-red fw-600" icon={['far', 'circle-info']} />
                    </div>
            </Tooltip>
        </div>
    )
}
export default FlaggedLimitIcon