import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './checkboxDesc.css';

const CheckboxDesc = props => {
  const disabledStyles = {};

  return (
    <div
      id={props.checkBoxId}
      className={props.classProps ? ` checkbox-container ${props.classProps}` : ' checkbox-container'}
    >
      {props.checked ? (
        <button
          id={props.checkBoxButtonId}
          type="button"
          onClick={() => props.onclick(!props.checked)}
          className={
            props.checkButtonProps
              ? `${props.disabled ? '' : 'pointer'} checkbox_container_button ${props.checkButtonProps}`
              : `${props.disabled ? '' : 'pointer'} checkbox_container_button m-top-28`
          }
        >
          <FontAwesomeIcon
            className={`checkbox ${props.disabled ? 'gray' : 'pointer'}`}
            color="#33AAFC"
            icon={['far-solid', 'check-square']}
            size="2x"
            id={props.checkBoxButtonIconId}
          />
        </button>
      ) : (
        <button
          id={props.checkBoxButtonId}
          type="button"
          onClick={() => props.onclick(!props.checked)}
          className={
            props.checkButtonProps
              ? `checkbox_container_button ${props.checkButtonProps}`
              : 'checkbox_container_button m-top-28'
          }
        >
          <FontAwesomeIcon
            className={`checkbox ${props.disabled ? 'gray' : 'pointer'}`}
            color="black"
            icon={['far', 'square']}
            size="2x"
            id={props.checkBoxButtonIconId}
          />
        </button>
      )}
      <div
        onClick={() => props.onclick(!props.checked)}
        // className={`checkbox-text ${props.disabled ? "gray" : ""}`}
        className={
          props.checkButtonProps
            ? `${props.disabled ? '' : 'pointer'} checkbox-text ${props.checkButtonProps}`
            : `${props.disabled ? '' : 'pointer'} checkbox-text m-top-28 m-left-18 ${props.disabled ? 'gray' : ''}`
        }
      >
        {props.text}
      </div>
    </div>
  );
};
export default CheckboxDesc;
