import React from 'react';
import hourglass from './hourglass.png';

const WhatsappInputSearchBox = ({ activeMsg, setViewAllTemplates, resetActiveTemplate, handleKeyDown }) => {
  if (!activeMsg.formattedText) {
    return (
      <div
        className="w-100-P h-40 border-20-light-grey flex-row-spacebetween-nowrap m-top-15 m-btm-15 m-left-15 m-right-13"
        style={{ justifyContent: 'center', alignItems: 'center' }}
      >
        <div
          className="w-100-P h-100-P flex-row-nospacebetween-nowrap pointer"
          onClick={() => {
            if (!activeMsg.formattedText) {
              setViewAllTemplates(true);
            }
          }}
          onKeyDown={handleKeyDown}
        >
          <img className="m-right-5 m-left-18" src={hourglass} />
          <span className="color-bdbdbd">Search Whatsapp Templates...</span>
        </div>

        <button className="link-button h-100-P m-right-20" onClick={() => setViewAllTemplates(true)}>
          View All
        </button>
      </div>
    );
  }
  return (
    <div
      className="w-100-P  border-20-light-grey  flex-row-spacebetween-nowrap m-top-15 m-btm-15 m-left-15 m-right-13"
      style={{ justifyContent: 'flex-start', alignItems: 'center' }}
    >
      <div className="w-100-P h-100-P align-left m-left-18 m-top-11 m-btm-11">
        <span className="">{activeMsg.formattedText}</span>
      </div>
      <button className="link-button h-100-P m-right-20" onClick={() => resetActiveTemplate()}>
        Cancel
      </button>
    </div>
  );
};
export default WhatsappInputSearchBox;
