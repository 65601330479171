import React from 'react';
import StandardLayout from '../../../../../../Layout/StandardLayout';
import { connect } from 'react-redux';
import ReminderMessageTemplates from '../../../../../../Layout/AutoReminders/AutoReminderSettings/ReminderMessageTemplates';
import SecondaryPanel from '../../../../../../Layout/SecondaryPanel';

const CustomMsgTemplate = props => {
  return (
    <StandardLayout
      title={`${props.msgTempAddOrEdit} Message Template for Auto Reminder`}
      description={`Create message templates that appear with the auto reminder`}
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Client Activity', to: '/Configuration/client-activity' },
        {
          title: 'Edit Client Activity',
          to: `/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}`,
        }, // DON'T FORGET THESE TWO
        {
          title: `Auto Reminder`,
          to: `/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}/edit-auto-reminder/${props.match.params.reminderId}`,
        },
        { title: `${props.msgTempAddOrEdit} Message Template` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <ReminderMessageTemplates
        cancelRoute={`/Configuration/client-activity/edit-client-activity/${props.match.params.listItemId}/edit-auto-reminder/${props.match.params.reminderId}`}
        popConfirmDesc={'This will remove the reminder from this client activity'}
        type={'clientEvent'} //// REMEMBER THIS
        match={props.match}
      />
      <SecondaryPanel title={['Automation']} current="Client Activity" />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.auth,
  ...state.autoRemMsgs,
});
export default connect(mapStateToProps, {})(CustomMsgTemplate);
