import React from 'react';
import SmallTable from '../SmallTable';

const SmallHomeGraphs = props => {
  return (
    <div
      className={
        // component has ability to override existing class.
        props.smallGraphStyles
          ? `${props.smallGraphStyles} overflowY hide-scrollbar`
          : 'assoc_days_late overflowY hide-scrollbar'
      }
    >
      <div className="align-left">
        <span className="fs-16 fw-500">{props.title}</span>
      </div>
      <div className="align-left fs-13 fw-500 light-gray "> {props.subTitle}</div>
      {props.count ? (
        <div>
          <p className="assoc_late">{props.count}</p>
        </div>
      ) : (
        <SmallTable
          tableClassName={'smallTableWidth'}
          tableContainerClass={' border-none'}
          data={props.data}
          tableHeaders={props.tableHeaders}
          st_tr_style={props.st_tr_style}
        />
      )}
    </div>
  );
};
export default SmallHomeGraphs;
