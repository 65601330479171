import React, { useState, useEffect } from 'react';
import '../navigation.css';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import assctAndMngrMenu from './menuOptions/assctAndMngrMenu.json';
import adminMenu from './menuOptions/adminMenu.json';
import adminWebChatMenu from './menuOptions/adminWebChatMenu.json';
import SPButton from './SPButton';

const SidePanel = props => {
  const [expand, setExpand] = useState(true);
  const [image, setImage] = useState('https://dashboard-v2-images.s3.amazonaws.com/ic-messaging.svg');
  const [showList, setShowList] = useState('');
  const [showList_noExpansion, setShowList_noExpansion] = useState('');
  const [showListStyle, setShowListStyle] = useState('showListStyle');
  const { merchantFlags } = props;
  const showWebchatMenu = merchantFlags?.webchat?.enabled || false;

  useEffect(() => {
    if (showList) {
      setShowListStyle('showListStyle');
      setShowList_noExpansion('');
    } else if (showList_noExpansion) {
      setShowListStyle('showList_noExpansionStyle');
      setShowList('');
    }
  }, [showList]);

  const hideLists = val => {
    if (val) {
      setShowList_noExpansion(val);
      setShowList('');
    } else {
      setShowList_noExpansion(val);
    }
  };

  const isSafe = () => {
    // const safeMerchants = [11, 70, 2, 17, 350, 354, 363, 400, 402, 410, 418, 421];
    const posType = props.pos_type ? props.pos_type.trim() : null;
    let configMenu = [];
    if (posType || process.env.REACT_APP_API_ENV === 'development' || process.env.REACT_APP_API_ENV === 'localhost') {
      configMenu = [
        { title: 'Tags', link: '/Configuration/tags' }, // these links need to be modified
        {
          title: 'Client Preferences',
          link: '/Configuration/client-preferences',
        },
        { title: 'Life Events', link: '/Configuration/life-events' },
        { title: 'Client Activity', link: '/Configuration/client-activity' },
        { title: 'Sales Triggers', link: '/Configuration/sales-triggers' },
        { title: 'Message Settings', link: '/Configuration/message-settings' },
        { title: 'Kiosk Settings', link: '/Configuration/kiosk-settings' },
        { title: 'General Settings', link: '/Configuration/app-settings' },
        { title: 'Upload Data', link: '/Configuration/upload-data' },
      ];
    } else {
      configMenu = [
        { title: 'Tags', link: '/Configuration/tags' }, // these links need to be modified
        {
          title: 'Client Preferences',
          link: '/Configuration/client-preferences',
        },
        { title: 'Life Events', link: '/Configuration/life-events' },
        { title: 'Client Activity', link: '/Configuration/client-activity' },
        { title: 'Message Settings', link: '/Configuration/message-settings' },
        { title: 'Kiosk Settings', link: '/Configuration/kiosk-settings' },
        { title: 'General Settings', link: '/Configuration/app-settings' },
        { title: 'Upload Data', link: '/Configuration/upload-data' },
      ];
    }
    if (props.pipelineEnabled) {
      configMenu.splice(1, 0, { title: 'Sales Opportunities', link: '/Configuration/sales-pipelines' });
    }
    return configMenu;
  };

  const messagingMenuOptions = () => {
    const menuListArr = [
      {
        title: 'Inbox',
        link: '/Messaging/inbox',
        current: 'Inbox',
      },
      // {
      //   title: 'My Inbox',
      //   link: '/Messaging/my-inbox',
      //   current: 'My Inbox',
      // },
      // {
      //   title: 'Store Inbox',
      //   link: '/Messaging/store-inbox',
      //   current: 'Store Inbox',
      // },
    ];
    if (props.role == 'ADMIN' || props.role == 'MANAGER') {
      menuListArr.push(
        {
          title: 'Mass Messaging',
          link: '/Messaging/mass-messaging',
          current: 'Mass Messaging',
        },
        {
          title: 'Templates',
          link: '/Messaging/all-templates',
          current: 'Templates',
        },
        {
          title: 'Auto Messages',
          link: '/Messaging/auto-messages',
          current: 'Auto Messages',
        },
      );
    }
    if (props.enabled_whatsapp) {
      menuListArr.push({
        title: 'WhatsApp',
        link: '/Messaging/WhatsApp',
        current: 'WhatsApp',
      });
    }
    return menuListArr;
  };



  return (
    <div>
      <div
        onClick={() => setShowList('')}
        className={showList ? 'pos-abs w-100-vw h-100-vhleft-0 align-top flex-col-center nav-z-100' : 'pos-abs'}
      />
      <div
        className={showList ? 'side-panel hide-scrollbar nav-z-101' : 'side-panel hide-scrollbar'}
        style={showList ? { width: '269px', overflowX: 'visible' } : {}}
      >
        <div className="nav-bar-short  hide-scrollbar">
          {/* need to build out menu options for each item below */}
          <SPButton
            showList_noExpansion={showList_noExpansion}
            setShowList_noExpansion={hideLists}
            menuOptions={[]}
            image={<img src="https://dashboard-v2-images.s3.amazonaws.com/ic-logo-menu.svg" />}
            title="Home"
          />
          <SPButton
            showList_noExpansion={showList_noExpansion}
            setShowList_noExpansion={hideLists}
            menuOptions={[]}
            image={
              <FontAwesomeIcon
                style={{
                  height: '25px',
                  marginBottom: '-5px',
                  marginTop: '15px',
                }}
                icon={['fal', 'clipboard-list-check']}
              />
            }
            title="Today"
          />
          {props.role == 'ADMIN' || props.role == 'MANAGER' ? (
            <SPButton
              showList_noExpansion={showList_noExpansion}
              setShowList_noExpansion={hideLists}
              menuOptions={[]}
              image={<img src="https://dashboard-v2-images.s3.amazonaws.com/ic-stores.svg" />}
              title="Stores"
            />
          ) : null}
          {props.role == 'ADMIN' || props.role == 'MANAGER' ? (
            <SPButton
              showList_noExpansion={showList_noExpansion}
              setShowList_noExpansion={hideLists}
              menuOptions={[]}
              image={
                <img
                  style={{ marginLeft: '4px' }}
                  src="https://dashboard-v2-images.s3.amazonaws.com/ic-associates.svg"
                />
              }
              title="Associates"
            />
          ) : null}
          <SPButton
            showList_noExpansion={showList_noExpansion}
            setShowList_noExpansion={hideLists}
            menuOptions={[]}
            image={<img src="https://dashboard-v2-images.s3.amazonaws.com/ic-clients.svg" />}
            title="Clients"
          />
          <SPButton
            showList={showList}
            setShowList={setShowList}
            menuOptions={messagingMenuOptions()}
            image={<img src="https://dashboard-v2-images.s3.amazonaws.com/ic-messaging.svg" />}
            title="Messaging"
          />
          {props.role == 'ADMIN' || props.role == 'MANAGER' ? (
            <SPButton
              showList_noExpansion={showList_noExpansion}
              setShowList_noExpansion={hideLists}
              menuOptions={[]}
              image={<img src="https://dashboard-v2-images.s3.amazonaws.com/ic-reports.svg" />}
              title="Reports"
            />
          ) : null}
          {props.role === 'ADMIN' ? (
            <SPButton
              showList={showList}
              setShowList={setShowList}
              image={<img src="https://dashboard-v2-images.s3.amazonaws.com/ic-configuration.svg" />}
              title="Configuration"
              menuOptions={isSafe()}
            />
          ) : null}
          <SPButton
            showList={showList}
            setShowList={setShowList}
            menuOptions={
              props.role === 'ADMIN'
                ?
                  showWebchatMenu ?
                    adminWebChatMenu :
                    adminMenu
                : assctAndMngrMenu
            }
            image={<img src="https://dashboard-v2-images.s3.amazonaws.com/ic-help.svg" />}
            title="Help"
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => state.auth;
export default connect(mapStateToProps, {})(SidePanel);
