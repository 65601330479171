import React from 'react';

import Field from './Field';

const Input = ({ maxLength, minLength, inputMode, ...props }) => {
  return (
    <Field {...props}>
      <input maxLength={maxLength} minLength={minLength} inputMode={inputMode} />
    </Field>
  );
};

Input.propTypes = {};

Input.defaultProps = {};

export default Input;
