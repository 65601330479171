import React from 'react';

const SelectedTemplate = props => {
  const findSelectedTemplate = () => {
    let selected = props.allTemplates.filter(tmp => tmp.id === props.selected);
    if (selected.length > 0) {
      return selected[0].template;
    }
    return null;
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontSize: '15px',
            fontWeight: '500',
            textAlign: 'left',
            marginBottom: '7px',
          }}
        >
          Selected Template
        </h2>
        <p
          className="pointer"
          style={{
            fontSize: '15px',
            fontWeight: '500',
            color: '#007bd0',
          }}
          onClick={() => {
            props.setSelected('');
          }}
        >
          Change
        </p>
      </div>

      <div className="add-edit-wrap" style={{ marginTop: '10px', marginBottom: '8px' }}>
        <p
          className="m-btm-6 rem-pad-width"
          style={{
            width: '100%',
            marginLeft: 0,
            padding: '17px 22px 21px 19px',
            borderRadius: '3px',
            backgroundColor: '#f3f3f3',
            fontSize: '12px',
            textAlign: 'left',
          }}
        >
          {findSelectedTemplate()}
        </p>
      </div>

      <h2
        style={{
          fontSize: '15px',
          fontWeight: '500',
          textAlign: 'left',
          marginBottom: 0,
          marginTop: '39px',
        }}
      ></h2>
    </div>
  );
};

export default SelectedTemplate;
