import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, message } from 'antd';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API_URL } from '../../../../../../../constants';
import PaginationV2 from '../../../../../../CustomizedAntDesign/PaginationV2';
import CollectionProductCard from '../CollectionProductCard';
import ProductOverview from '../../../../../../Layout/ProductOverview';
import './styles.css';

const CollectionProductsList = ({
  setSelectedProduct,
  collection,
  toggleShowAddProduct,
  showAddProduct,
  selectedProducts,
  toggleProductSelection,
  ...props
}) => {
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const clientId = useMemo(() => params.get('client') || '', [params]);

  const [loading, setLoading] = useState(false);
  const [collectionProducts, setCollectionProducts] = useState([]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(0);

  const showPagination = useMemo(() => total > 20, [total]);

  const fetchCollectionProducts = useCallback(async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${API_URL}/xa/clients/${clientId}/collections-v2/${collection?.collection_id}/products`,
        {
          params: {
            page,
            pageSize,
          },
        },
      );

      setPage(response.data.page);
      setPageSize(response.data.pageSize);
      setTotal(response.data.total);
      setCollectionProducts(response.data.results);
    } catch (error) {
      message.error('Error Fetching Collection Products');
    } finally {
      setLoading(false);
    }
  }, [clientId, page, pageSize]);

  useEffect(() => {
    fetchCollectionProducts();
  }, [clientId, page, pageSize, collection?.new_products_ids]);

  return (
    <div className="clients-v2-collection-products--list">
      <div style={{ ...props.listItemsStyles }} className="clients-v2-collection-products--list-items scrollbar-y">
        {loading && <div className="clients-v2-collection-products--list-subtitle">Loading...</div>}

        {!loading && showAddProduct ? (
          <ProductOverview
            selectedProducts={selectedProducts}
            selectedAndDisabledProducts={new Set(collectionProducts.map(a => a.id))}
            toggleProductSelection={toggleProductSelection}
            productDetailSelectIcon={['fas', 'folder-plus']}
            productDetailsSelectText="Add to Collection"
            onProductDetail={item => setSelectedProduct({ ...item, viewDetails: true })}
          />
        ) : collectionProducts.length > 0 ? (
          collectionProducts.map(item => (
            <CollectionProductCard key={item.id} product={item} onClick={() => setSelectedProduct(item)} />
          ))
        ) : (
          <div className="w-100-P flex-col-center" style={{ height: '50%' }}>
            <p>This collection is empty</p>
            <Button
              type="link"
              icon={<FontAwesomeIcon icon="far fa-plus" className="m-right-5" />}
              onClick={toggleShowAddProduct}
            >
              Add Products
            </Button>
          </div>
        )}
      </div>

      {showPagination && (
        <PaginationV2
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          total={total}
          showSizeChanger={false}
          className="clients-v2-collection-products--list-pagination"
        />
      )}
    </div>
  );
};

export default CollectionProductsList;
