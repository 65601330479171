import React, { useRef, useMemo, useCallback, useEffect } from 'react';

import './pop-over-video.css';

const PopOverVideo = ({ open, onClose, videoSrc, videoPosterSrc, autoPlay }) => {
  const modalRef = useRef(null);
  const videoRef = useRef(null);

  const coverStyle = useMemo(() => {
    if (open) return 'coverActive';
    return 'cover';
  }, [open]);
  const videoModalStyle = useMemo(() => {
    if (open) return 'videoModalActive';
    return 'videoModal';
  }, [open]);

  const handleClose = useCallback(() => {
    if (!open) return;
    /* eslint-disable */
    videoRef.current?.pause();
    onClose();
  }, [onClose, open]);

  useEffect(() => {
    const onClick = e => {
      if (!(modalRef.current?.contains(e.target) ?? true)) {
        handleClose();
      }
    };

    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [handleClose]);

  const activeClass = open ? ' active' : '';

  return (
    <>
      <div id={coverStyle} className={`cover${activeClass}`}>
        <div id={videoModalStyle} className={`videoModal${activeClass}`} role="dialog" ref={modalRef}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <video
                  className="fullscreen-video"
                  src={videoSrc}
                  poster={videoPosterSrc}
                  ref={videoRef}
                  controls
                  autoPlay={autoPlay || false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopOverVideo;
