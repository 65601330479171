import { dispatcher } from '../core/actions';

const initialState = {
  todayClientChat: {
    clientRecipient: 0,
    reminderId: null,
    name: '',
    storeId: 0,
    currentScope: 'Tag',
    temporaryConfigId: 0,
  },
  todayReviewLink: '',
  tableData: [],
  tempTableData: [],
};

export default function reducer(state = initialState, action) {
  const state2 = state;
  switch (action.type) {
    case 'SET_TODAY_CLIENT_CHAT':
      return { ...state2, todayClientChat: action.payload };
    case 'SET_TODAY_REVIEW_LINK':
      return { ...state2, todayReviewLink: action.payload };
    case 'SET_TODAY_TABLE_DATA':
      return { ...state2, tableData: action.payload };
    case 'SET_TODAY_TEMP_TABLE_DATA':
      return { ...state2, tempTableData: action.payload };
    default:
      break;
  }
  return state2;
}

export const setTodayClientChat = val => {
  dispatcher('SET_TODAY_CLIENT_CHAT', val);
};
export const setTodayReviewLink = val => {
  dispatcher('SET_TODAY_REVIEW_LINK', val);
};
export const setTodayTableData = val => {
  dispatcher('SET_TODAY_TABLE_DATA', val);
};
export const setTodayTempTableData = val => {
  dispatcher('SET_TODAY_TEMP_TABLE_DATA', val);
};
