/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WhatsAppTimer from '../../../Layout/WhatsAppTimer';
import ToolTip from '../../../Layout/ToolTip';
import InitiateChat from './InitiateChat';

const MessageConfigToday = ({ keyVar, msgObj, setShowPopConfirm, initiateTemplates, setIsShown, isShown }) => {
  const whatsAppTemplates = [];
  if (msgObj.preferred_contact_type === 'WHATSAPP') {
    if (WhatsAppTimer(msgObj.WA_receive)) {
      whatsAppTemplates.push(
        <div key={keyVar} id="todayMessageIconDiv" onClick={() => InitiateChat(msgObj, setShowPopConfirm)}>
          <FontAwesomeIcon className="checkbox fs-19" color="#818181" icon={['far', 'comment-alt-lines']} />
        </div>,
      );
      return whatsAppTemplates;
    }
    whatsAppTemplates.push(
      <div key={keyVar} id="todayMessageIconDiv" onClick={() => initiateTemplates(msgObj)}>
        <FontAwesomeIcon className="checkbox fs-19" color="#818181" icon={['far', 'comment-alt-lines']} />
      </div>,
    );
  } else {
    whatsAppTemplates.push(
      <div key={keyVar} id="todayMessageIconDiv" onClick={() => InitiateChat(msgObj, setShowPopConfirm)}>
        <FontAwesomeIcon className="checkbox fs-19" color="#818181" icon={['far', 'comment-alt-lines']} />
      </div>,
    );
    return whatsAppTemplates;
  }
  return whatsAppTemplates;
};

export default MessageConfigToday;
