import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Table from '../../../Layout/Table';
import Layout from '../../../Layout/StandardLayout';
import Button from '../../../Layout/Button';
import TitleDescription from '../../../Layout/DescriptionBox';
import { setAuthStoreId } from '../../../../reducers/auth';
import { getRequest } from '../../../../core/apiRequests';
import DropdownSelect from '../../../Layout/DropdownSelect';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import StyledLink from '../../../Layout/StyledLink';
import RangePicker from '../../../CustomizedAntDesign/RangePicker';
import '../reports.css';

const disabledDate = current => {
  return current && current > moment().endOf('day');
};

const CreatedOppByAssoc = props => {
  const [dateRange, setDateRange] = useState([moment().subtract(1, 'months'), moment()]);
  const [tableData, setTableData] = useState([]);
  const [showReport, setShowReport] = useState(false);

  const requestData = () => {
    const reqObj = {
      params: ['report', props.auth.merchantId, props.auth.storeId, 'createdOppByAssociate'],
      query: {
        startDt: moment(dateRange[0]).startOf('day').format('YYYYMMDDHHmmss'),
        endDt: moment(dateRange[1]).endOf('day').format('YYYYMMDDHHmmss'),
        storeAccess: props.auth.stores.filter(st => st.id !== 0).map(st => st.id),
      },
    };
    if (props.auth.storeId) {
      reqObj.query.selected_store_id = props.auth.storeId;
    }
    setShowReport(false);
    (async () => {
      const res = {};
      res.data = await getRequest(reqObj);
      setTableData(res.data);
      setTimeout(() => {
        setShowReport(true);
      }, 300);
    })();
  };

  const tableHeaders = [
    {
      keyName: 'fullName',
      style: { width: '5%', paddingLeft: '20px', paddingTop: '80px' },
      headerClassName: 'row1',
      title: 'Associate',
      sortable: true,
      render: (t, o) => (
        <StyledLink to={`/Associates/associate-details/${o.id}`}>
          {t || `${o.first_name || ''} ${o.last_name || ''}`.trim()}
        </StyledLink>
      ),
      rowClassName: 'col-p-l-25',
    },
    {
      keyName: 'cnt',
      style: { width: '5%', paddingLeft: '20px', paddingTop: '80px' },
      headerClassName: 'row1',
      title: '# Created',
      sortable: true,
      rowClassName: 'col-p-l-25',
    },
  ];

  return (
    <Layout
      title="Created Opportunities"
      backArrow="/Home"
      description="Set your parameters and click on 'Run Report.'"
      classProps="standard-width-with-sidebar flex-col-center"
      styleProps={{ justifyContent: 'flex-start' }}
    >
      <div className="report-smaller-description">
        {' '}
        Created Opportunities reports allow you to see how many Sales Opportunities your associates have created. Each
        Opportunity logged by your associates counts as 1 in the report.{' '}
      </div>
      <div className="pos-rel w-100-P " style={{ maxWidth: '866px' }}>
        <TitleDescription
          title="Reporting Location"
          description="What store location would you like to run a report on?"
          input={
            <DropdownSelect
              isSearchable
              classProps="mq-w-200 w-267 align-left"
              options={props.auth.stores.map(st => ({
                label: st.name,
                value: st.id,
              }))}
              placeholder="Select a Store..."
              value={props.auth.storeId}
              onChange={obj => {
                setAuthStoreId(obj.value);
              }}
            />
          }
        />

        <TitleDescription
          title="Date Range"
          input={
            <RangePicker
              format="MM/DD/YYYY"
              disabledDate={disabledDate}
              value={dateRange}
              onChange={setDateRange}
              ranges={{
                'Last 7 Days': [moment().subtract(7, 'd'), moment()],
                'Last 30 Days': [moment().subtract(30, 'd'), moment()],
                'Last 60 Days': [moment().subtract(60, 'd'), moment()],
                'Last 90 Days': [moment().subtract(90, 'd'), moment()],
              }}
              showToday
            />
          }
          className="report-date-picker"
        />

        <div className="report-asct-msg-row" style={{ maxWidth: '866px' }}>
          <Button id="RunCreatedOpportunityByAssociateReport" classname="darkBlueButton" onclick={e => requestData(e)}>
            Run Report
          </Button>
        </div>
      </div>
      {showReport ? (
        <div className="w-100-P rem-pad-width maw-1200">
          <div
            className="import_export_group  w-100-P"
            style={{
              justifyContent: 'flex-end',
              zIndex: '100',
              marginLeft: '0px',
              marginTop: '100px',
            }}
          >
            <img src="https://dashboard-v2-images.s3.amazonaws.com/ic-export.svg" alt="export" />

            <CSVLink
              data={tableData}
              filename="CreatedOppByAssoc.csv"
              id="DownloadCreatedOpportunityByAssociateReport"
              className="exportButton m-right-15"
            >
              Download
            </CSVLink>
          </div>

          <Table
            tableClassName="maw-1200 rem-pad-width m-top-15 overflowX hide-scrollbar w-100-P"
            tableContainerClass="maw-1200 w-100-P rem-pad-width "
            style={{ marginTop: '-100px', width: '100%' }}
            data={tableData}
            tableRowClassName="col-p-l-25"
            tableHeaders={tableHeaders}
            tableName="CREATED_OPP_BY_ASSOC_REPORT"
          />
        </div>
      ) : null}
      <SecondaryPanel
        overhead_title="Reports"
        title={['CLIENTELING', 'MESSAGING', 'Google Reviews', 'PAYMENTS', 'AUTOMATION', 'SALES OPPORTUNITY']}
        current="Created Opportunities"
      />
    </Layout>
  );
};

const mapStateToProps = state => ({
  reports: state.reports,
  auth: state.auth,
  stores: state.stores,
  salesPipeline: state.salesPipeline,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CreatedOppByAssoc);
