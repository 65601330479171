import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import yn from 'yn';
import { Layout, Avatar, Dropdown, Divider, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WhatsAppSessionIndicator from './WhatsappMessageInput/whatsapp-session-indicator';
import GoogleReviewRating from './GoogleReviewRating';
import StyledLink from '../../StyledLink';
import StoreModal from './selectedStoreModal';
import ToolTipBottom from '../../ToolTip/ToolTipBottom';

import { deleteRequest, postRequest } from '../../../../core/apiRequests';
import { notificationSuccess, notificationError } from '../../../../core/actions';
import {
  setMsgClient,
  setMsgResetClient,
  getClientChat,
  setInboxList,
  setStoreInboxList,
  setClosedInboxList,
  closeConversation,
  openConversation,
} from '../../../../reducers/messaging';

const Header = props => {
  const { inboxList, storeInboxList, closedInboxList } = props.messaging;

  const [associatesAssigned, setAssociatesAssigned] = useState(false);
  const [LimitedVisibility, setLimitedVisibility] = useState(
    yn(props?.auth?.merchantConfig?.LIMIT_ASSOCIATE_VISIBILITY_ENABLED) &&
      props.auth.role === 'ASSOCIATE' &&
      !props?.messaging?.client?.assignedAssociates?.map(item => +item).includes(props.auth.userId),
  );
  const [hovering, setHovering] = useState('');
  const hasRendered = useRef(false);

  const { type } = props.messaging.client;

  useEffect(() => {
    setLimitedVisibility(
      yn(props?.auth?.merchantConfig?.LIMIT_ASSOCIATE_VISIBILITY_ENABLED) &&
        props?.auth?.role === 'ASSOCIATE' &&
        !props?.messaging?.client?.assignedAssociates?.map(item => +item).includes(props.auth.userId),
    );
    if (props?.messaging?.client?.assignedAssociates) {
      setAssociatesAssigned(
        props?.messaging.client?.assignedAssociates?.map(item => +item).includes(props.auth.userId),
      );
    } else {
      setAssociatesAssigned(false);
    }
  }, [props.messaging.client]);

  useEffect(() => {
    const { client_id } = props.messaging.client;
    if (hasRendered.current) {
      getClientChat({ client_id });
    } else {
      hasRendered.current = true;
    }
  }, [props.auth.selectedStore]);

  const initials = props?.messaging?.client?.name
    ?.split(' ')
    .map(e => e.charAt(0))
    .slice(0, 2)
    .join('');

  const nameShorten = name => {
    if (name) {
      if (window.innerWidth < 1515 && name.length > 30) {
        return `${name.substr(0, 30)}...`;
      }
      if (window.innerWidth < 1642 && name.length > 58) {
        return `${name.substr(0, 58)}...`;
      }
      if (name.length > 80) {
        return `${name.substr(0, 80)}...`;
      }
    }
    return name || '';
  };

  const archiveChat = async () => {
    const reqObj = {
      params: ['chats', props.auth.userId, props.messaging.client.chat_id, 'archiveChat'],
      query: { type: props.messaging.client.type },
    };
    const removeChatRequest = await deleteRequest(reqObj);
    if (removeChatRequest) {
      notificationSuccess('Conversation Closed');
      closeConversation(props.messaging.client.chat_id);
      setMsgResetClient();
    } else {
      notificationError('Error closed Chat');
    }
  };

  const unarchiveChat = async () => {
    const reqObj = {
      params: ['chats', props.auth.userId, props.messaging.client.chat_id, 'unarchiveChat'],
      query: { type: props.messaging.client.type },
    };
    const removeChatRequest = await postRequest(reqObj);
    if (removeChatRequest) {
      setMsgClient({ isOpen: true });
      notificationSuccess('Conversation Opened');
      openConversation(props.messaging.client.chat_id);
    } else {
      notificationError('Error opening Chat');
    }
  };

  const updateUnreadInInboxes = chat_id => {
    setInboxList([
      ...inboxList.map(item => ({
        ...item,
        lastReadDateTime: item.id === chat_id ? null : item.lastReadDateTime,
        isUnread: item.id === chat_id ? true : item.isUnread,
      })),
    ]);
    setStoreInboxList([
      ...storeInboxList.map(item => ({
        ...item,
        lastReadDateTime: item.id === chat_id ? null : item.lastReadDateTime,
        isUnread: item.id === chat_id ? true : item.isUnread,
      })),
    ]);
    setClosedInboxList([
      ...closedInboxList.map(item => ({
        ...item,
        lastReadDateTime: item.id === chat_id ? null : item.lastReadDateTime,
        isUnread: item.id === chat_id ? true : item.isUnread,
      })),
    ]);
  };

  const blockPhoneNumber = async () => {
    try {
      const reqObj = {
        params: ['chats', 'blockPhoneNumber'],
        data: {
          mobile: props.messaging.mobile,
        },
      };

      await postRequest(reqObj);

      setMsgClient({ isBlocked: !props.messaging.client.isBlocked });
      notificationSuccess('Phone Number Blocked');
      setMsgResetClient();
    } catch (error) {
      notificationError('Error Blocking Phone Number');
    }
  };

  const unblockPhoneNumber = async () => {
    try {
      const reqObj = {
        params: ['chats', 'unblockPhoneNumber'],
        data: {
          mobile: props.messaging.mobile,
        },
      };

      await postRequest(reqObj);

      setMsgClient({ isBlocked: !props.messaging.client.isBlocked });
      notificationSuccess('Phone Number Unblocked');
    } catch (error) {
      notificationError('Error Unblocking Phone Number');
    }
  };

  const handleMarkUnread = async () => {
    const reqObj = {
      params: ['chats', props.messaging.client.chat_id, 'markChatUnread'],
      query: { type: props.messaging.client.type, clientId: props.messaging.client.client_id },
    };

    updateUnreadInInboxes(props.messaging.client.chat_id);

    await postRequest(reqObj);
  };

  const getDropdownItems = () => {
    const menu = [
      {
        key: '1',
        label: 'Mark as unread',
        icon: <img alt="unread message icon" src="https://dashboard-v2-images.s3.amazonaws.com/unreadMessage.svg" />,
        onClick: handleMarkUnread,
      },
    ];

    if (yn(props.auth.merchantConfig.DECLUTTERED_INBOX)) {
      menu.push({
        key: '2',
        label: props.messaging.client.isOpen ? 'Close' : 'Open',
        icon: <FontAwesomeIcon icon={['far', props.messaging.client.isOpen ? 'eye-slash' : 'eye']} />,
        onClick: () => (props.messaging.client.isOpen ? archiveChat() : unarchiveChat()),
      });
    }
    if (props.messaging.client.type === 'C') {
      menu.push({
        key: '3',
        label: associatesAssigned ? 'Assigned to you' : 'Assign',
        icon: <FontAwesomeIcon icon={[associatesAssigned ? 'fas' : 'far', 'bookmark']} />,
        onClick: () => props.setAssignPopup(true),
      });
    }
    if (props.messaging.client.type === 'C') {
      menu.push({
        key: '4',
        label: props.messaging.client.isBlocked ? 'Unblock Number' : 'Block Number',
        icon: (
          <FontAwesomeIcon
            icon={props.messaging.client.isBlocked ? 'fa-regular fa-phone' : 'fa-regular fa-phone-slash'}
          />
        ),
        onClick: props.messaging.client.isBlocked ? unblockPhoneNumber : blockPhoneNumber,
      });
    }

    return menu;
  };

  return (
    <div>
      <Layout className="conversation-window-layout" style={{ paddingBlock: '5px', maxHeight: '100%' }}>
        <div className="conversation-window-header flex-row-spacebetween-nowrap">
          <HeaderWrapper
            setDrawerToggle={props.setDrawerToggle}
            messaging={props.messaging}
            LimitedVisibility={props.LimitedVisibility}
          >
            <div className="flex-row-center">
              <Avatar
                className="conversation-window-header-user-icon"
                size={40}
                style={{
                  color: '#818181',
                  backgroundColor: ['F', 'G'].includes(type) ? null : props.chatIconColor,
                }}
                src={
                  // eslint-disable-next-line no-nested-ternary
                  type === 'F'
                    ? 'https://dashboard-v2-images.s3.amazonaws.com/facebook.svg'
                    : type === 'G'
                      ? 'https://dashboard-v2-images.s3.amazonaws.com/google.svg'
                      : null
                }
              >
                {['F', 'G'].includes(type) ? null : <span className="fs-14">{initials}</span>}
              </Avatar>
              <div className="flex-col-left-center">
                <span className="fs-16 darkest-gray">{nameShorten(props.messaging.client.name)}</span>
                {type === 'G' && <GoogleReviewRating rating={4} />}
                <span className="fs-12 mid-gray">
                  {props.messaging.client.preferred_name || ''}
                  {props.messaging.client.preferred_name ? ' | ' : ''}
                  {props.messaging.preferred_contact_type}
                  {props.messaging.type}
                </span>
              </div>
            </div>
          </HeaderWrapper>
          {(props.type !== 'full' || props.messaging.preferred_contact_type === 'WHATSAPP') && (
            <Divider style={{ height: '50px', color: '#EBEBEB' }} type="vertical" />
          )}
          <StoreModal
            auth={props.auth}
            hideSelector={props.type === 'full'}
            setMsgResetClient={setMsgResetClient}
            closeConversation={false}
          />
          <div className="flex-row-spacearound-nowrap" style={{ flexGrow: 2 }}>
            <WhatsAppSessionIndicator type={props.type} />
          </div>
          <div className="headingIcons" id="heading-icons">
            {/* eslint-disable-next-line no-nested-ternary */}
            {props.type === 'right' ? (
              <FontAwesomeIcon
                icon="fa-regular fa-xmark"
                size="2xl"
                style={{ color: '#000' }}
                onClick={() => props.setDrawerToggle(true)}
              />
            ) : (
              <>
                <Dropdown
                  overlayClassName="chat-dropdown-menu"
                  menu={{
                    items: getDropdownItems(),
                  }}
                >
                  <Button type="text" size="small">
                    <FontAwesomeIcon icon="fa-solid fa-ellipsis" />
                  </Button>
                </Dropdown>

                <div
                  className="pos-rel"
                  onMouseEnter={() => setHovering('toolTipClientInfo')}
                  onMouseLeave={() => setHovering('')}
                >
                  <ToolTipBottom
                    isShown="toolTipClientInfo"
                    idx={hovering}
                    description="Client info"
                    toolTipWidth="90px"
                    toolTipHeight="40px"
                    toolTipTop="30px"
                    toolTipRight="-5px"
                    arrowLeft="24px"
                    arrowBottom="51px"
                  />

                  {props.messaging.client.type === 'C' && (
                    <Button type="text" size="small">
                      <InfoCircleOutlined title="Client info" onClick={() => props.setDrawerToggle(true)} />
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Layout>
    </div>
  );
};

const HeaderWrapper = ({ messaging, LimitedVisibility, children }) => {
  return messaging.client.type === 'C' ? (
    <StyledLink to={LimitedVisibility ? '#' : `/Clients?client=${messaging.client.client_id}`}>{children}</StyledLink>
  ) : (
    children
  );
};

const mapStateToProps = state => ({
  newMessages: state.navigation.newMessages,
  messaging: state.messaging,
  auth: state.auth,
  client: state.client,
});
export default connect(mapStateToProps)(Header);
