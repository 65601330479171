import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../Layout/Button';
import PopConfirm from '../../../../Layout/PopConfirm';
import StyledLink from '../../../../Layout/StyledLink';
import StandardLayout from '../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';
import DropdownSelect from '../../../../Layout/DropdownSelect';
import { API_URL } from '../../../../../constants';
import { getTablePagList, getSingleDetails, deleteRequest } from '../../../../../core/apiRequests';
import { notificationSuccess, notificationError } from '../../../../../core/actions';
import {
  setRelationshipAddEdit,
  setRelationshipId,
  setRelationshipUUId,
  setRelationshipName,
} from '../../../../../reducers/relationshipTypes';

const tableName = 'CLIENT_PREFERENCES';

const AddEditRelationship = props => {
  const [loading, setLoading] = useState(false);
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [typedRelationshipsOptions, setTypedRelationshipsOptions] = useState([]);
  const [relationship, setRelationship] = useState('');
  const [errorRelationshipName, setErrorRelationshipName] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (props.addOrEdit === 'Add' && props.relationshipId && window.location.href.includes('add-relationship')) {
      if (props.relationshipId) {
        setRelationshipId('');
        setRelationshipUUId('');
        setRelationshipName('');
      }
    } else if (window.location.href.includes('edit-relationship') && !props.relationshipId) {
      setRelationshipAddEdit('Edit');
      if (props.merchantId) {
        (async () => {
          const data = await getSingleDetails(
            'typed-relationships',
            props.merchantId,
            props.match.params.relationshipUUId,
          );
          if (data && data.length > 0) {
            const { id, name, uuid } = data[0];
            setRelationshipId(id);
            setRelationshipUUId(uuid);
            setRelationshipName(name);
          } else {
            history.push('/Configuration/relationship-types');
          }
        })();
      }
      // query pref info
    }
  }, [history, props.addOrEdit, props.match.params.relationshipId, props.merchantId, props.relationshipId]);

  useEffect(() => {
    (async () => {
      const dataArr = await getTablePagList({
        tableName,
        type: 'typed-relationships',
        merchantId: props.merchantId,
        sortColumn: 'name',
        pageSize: 1000,
      });
      const createNewTypedRelationship =
        props.role === 'ADMIN' || props.role === 'MANAGER' ? [{ value: 'new', label: 'Create Relationship' }] : [];
      setTypedRelationshipsOptions([
        ...dataArr.map(item => ({ value: item.id, label: item.name })),
        ...createNewTypedRelationship,
      ]);
    })();
  }, [props.merchantId]);

  const confirmDelete = () => {
    if (relationship === props.relationshipId) {
      notificationError('Cannot replace relationship with itself');
      return;
    }
    if (!relationship) {
      notificationError('Select a relationship to replace with');
      return;
    }
    (async () => {
      try {
        const requestData = {
          params: ['typed-relationships', props.match.params.relationshipUUId],
          query: { replaceRelationshipId: relationship },
        };
        await deleteRequest(requestData);
        notificationSuccess('Relationship Removed');
        history.push('/Configuration/relationship-types');
      } catch (err) {
        notificationError('Failed, Relationship Not Removed');
      }
    })();
  };

  const saveRelationship = async () => {
    setLoading(true);
    const { relationshipName, relationshipId, relationshipUUId } = props;
    if (!relationshipName) {
      // TODO: a demo on error handling and edge cases
      notificationError('Relationship Name Required');
    } else {
      const dataObj = { name: relationshipName };
      if (props.addOrEdit === 'Add' && !relationshipId) {
        try {
          await Axios.post(`${API_URL}/xa/typed-relationships`, dataObj);
          setLoading(false);
          notificationSuccess('Relationship Added');
          history.push('/Configuration/relationship-types');
          setErrorRelationshipName('');
        } catch (err) {
          if (err?.response?.data?.errors?.name === 'This relationship type already exists') {
            setLoading(false);
            setErrorRelationshipName('This relationship already exists');
          } else {
            setLoading(false);
            notificationError(err?.response?.data?.errors?.name || 'Failed, Relationship Not Added');
          }
        }
      } else {
        try {
          await Axios.put(`${API_URL}/xa/typed-relationships/${relationshipUUId}`, dataObj);
          setLoading(false);
          notificationSuccess('Relationship Updated');
          setErrorRelationshipName('');
          history.push('/Configuration/relationship-types');
        } catch (err) {
          if (err?.response?.data?.errors?.name === 'This relationship type already exists') {
            setLoading(false);
            setErrorRelationshipName('This relationship already exists');
          } else {
            setLoading(false);
            notificationError(err?.response?.data?.errors?.name || 'Failed, Relationship Not Updated');
          }
        }
      }
    }
  };

  return (
    <StandardLayout
      title={`${props.addOrEdit} Relationship Type`}
      description={
        props.addOrEdit === 'Add'
          ? 'Add a relationship type to edit life events and send personalized messages.'
          : 'Edit the existing relationship below:'
      }
      // styleProps={{ marginLeft: '265px' }}
      breadCrumbs={[
        { title: 'Configuration' },
        {
          title: 'Relationship Types',
          to: '/Configuration/relationship-types',
        },
        { title: `${props.addOrEdit} Relationship` },
      ]}
      classProps="standard-width-with-sidebar"
    >
      <div className="max-w-600">
        <div className="align-left w-100-P">
          <input
            id="RelationshipNameInput"
            className={`m-top-15 fs-13 outline-none common_inputStyle left-0 input-height w-100-P rem-pad-width ${
              errorRelationshipName ? 'border-missing-data-state' : ''
            }`}
            value={props.relationshipName}
            onChange={e => setRelationshipName(e.target.value)}
            placeholder="Relationship type"
          />
          {!!errorRelationshipName && (
            <div
              className="flex-row-nospacebetween-nowrap w-100-P"
              style={{ color: '#FF7A7A', fontSize: '12px', fontStyle: 'italic', gap: '4px', marginTop: '4px' }}
            >
              <FontAwesomeIcon fontSize="14px" icon="fa-sharp fa-regular fa-circle-exclamation" />
              {errorRelationshipName}
            </div>
          )}
        </div>
        {/* here */}
        <div className="custom-action-save-flex m-top-37">
          {props.addOrEdit === 'Edit' ? (
            <Button
              id="DeleteRelationshipButton"
              classname="deleteButton-v2 m-btm-8 "
              style={{ marginBottom: '15px' }}
              onclick={() => setShowPopConfirm(true)}
            >
              Delete
            </Button>
          ) : (
            <div />
          )}
          <div className="mq-w-100-vw custom-action-save-flex">
            <button
              className="save-button-v2 m-btm-8"
              disabled={loading}
              type="button"
              id="AddEditRelationshipSaveButton"
              onClick={saveRelationship}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
            <StyledLink to="/Configuration/relationship-types">
              <button
                type="button"
                id="AddEditRelationshipCancelButton"
                className="cancel-button-v2"
                disabled={loading}
              >
                Cancel
              </button>
            </StyledLink>
          </div>
        </div>
        {/* naomi here */}
        {/* end here */}
      </div>
      {showPopConfirm ? (
        <PopConfirm
          confirm="Are you sure you want to delete this relationship type?"
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={confirmDelete}
          noButtonDisable
          description={
            <div
              style={{
                marginBottom: '16px',
                gap: '28px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: '10px',
              }}
            >
              <span className="fw-500 fs-13 bold">
                Deleting this relationship type will delete any client relationships that are tied to this relationship
                type and will notbe recoverable..
              </span>
              <span className="fw-400 fs-12 gray">
                Select the relationship you would like to assign to the tied relationships after deletion.
              </span>
              <DropdownSelect
                classProps="w-100-P align-left"
                options={typedRelationshipsOptions}
                placeholder="Select relationship"
                value={relationship}
                onChange={obj => {
                  setRelationship(obj.value);
                }}
              />
            </div>
          }
        />
      ) : null}
      <SecondaryPanel title={['SETTINGS', 'HELP', 'PROFILE']} current="Relationship Type" />
    </StandardLayout>
  );
};
const mapStateToProps = state => ({
  ...state.relationshipTypes,
  ...state.auth,
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AddEditRelationship);
