import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopConfirm from '../../../Layout/PopConfirm';
import formatNumberAsCurrency from '../../../../utils/formatNumberAsCurrency';
import Input from '../../../CustomizedAntDesign/Input/Input';

const RefundPayment = ({ onConfirm, onCancel, amount, refundSum }) => {
  const [message, setMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [amountMessage, setAmountMessage] = useState('');

  const amountAfterRefunds = Number(amount) - Number(refundSum);

  useEffect(() => {
    if (amountMessage[0] === '.') {
      setAmountMessage(`0${amountMessage}`);
    }

    let itHasAnError = false;

    if (parseFloat(amountMessage) > parseFloat(amount)) {
      itHasAnError = true;
    }

    if (amountMessage.length > 0) {
      if (!parseFloat(amountMessage)) {
        itHasAnError = true;
      } else {
        //
      }
    }
    if (itHasAnError) {
      setHasError(itHasAnError);
    }
  }, [amountMessage, isFocused]);

  return (
    <PopConfirm
      confirm="Refund Payment"
      className="pop-confirm"
      confirmButtonText="Issue Refund"
      cancelButtonText="Close"
      customContent
      noButtonDisable
      disabled={!amountMessage || hasError || !message}
      description={
        <div className="align-left p-top-20">
          <span className="align-left fs-12 m-top-20">
            {formatNumberAsCurrency(amountAfterRefunds)} available for refund
          </span>

          <Input
            onChange={({ target }) => setAmountMessage(target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              setHasError(false);
              setIsFocused(false);
            }}
            value={amountMessage}
            prefix={<FontAwesomeIcon icon={['fas', 'dollar-sign']} color="#818181" />}
            style={{ marginTop: '12px' }}
          />

          <div style={{ marginTop: '12px' }}>
            {hasError && parseFloat(amountMessage) > amountAfterRefunds ? (
              <p className="red fs-12 m-top-5 align-left">{`Amount cannot exceed ${amountAfterRefunds}`}</p>
            ) : null}

            {hasError && amountMessage.length > 0 && !parseFloat(amountMessage) ? (
              <p className="red fs-12 m-top-5 align-left">{`${amountMessage} is not a valid amount`}</p>
            ) : null}
          </div>

          <span className="align-left fs-12 m-top-20">What is the reason for this refund?</span>

          <Input
            onChange={({ target }) => setMessage(target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              setHasError(false);
              setIsFocused(false);
            }}
            value={message}
            placeholder="ex. Customer did not need items"
            style={{ marginTop: '12px' }}
          />
        </div>
      }
      onConfirm={() => {
        if (parseInt(amountMessage, 10) <= amountAfterRefunds && message) {
          onConfirm({ reason: message, amount: amountMessage });
        } else {
          setHasError(true);
        }
      }}
      onCancel={onCancel}
    />
  );
};

export default RefundPayment;
