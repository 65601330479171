import React, { useCallback, useEffect } from 'react';
import { bool, func, string, number, oneOfType } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { connect } from 'react-redux';

import { getMessageSettings } from '../../../../core/apiRequests';
import { setLogoUrl, setRoundLogo } from '../../../../reducers/messageSettings';

const today = moment().format('MMMM D, YYYY');
const due = moment().add(30, 'days').format('MMMM D, YYYY');

const Preview = ({ name, logoUrl, show, close, merchantId, roundLogo }) => {

  useEffect(() => {
    getMessageSettings('merchant', merchantId).then(({ logo_url, round_logo }) => {
      setLogoUrl(logo_url);
      setRoundLogo(round_logo);
    });
  }, [merchantId]);

  const getLogoUrl = useCallback(() => {
    return `url(${logoUrl})`;
  }, [logoUrl]);

  return (
    <aside
      className={`hide-under-1100 w-402 maw-100-P top-0 right-0 p-top-26 ${
        show ? 'display-under-1100 pos-rel' : 'pos-abs'
      }`}
    >
      <button
        className="display-under-1100 pos-abs top-0 right-0 border-none fs-24"
        style={{ padding: 8, background: 'transparent' }}
        onClick={close}
      >
        <FontAwesomeIcon icon={['fal', 'times']} color="#000" />
      </button>

      <p className="hide-under-1100 fs-13 fw-500 m-right-24 m-left-23 m-btm-25">
      Preview an invoice sample. The client will see this when they receive your requests.
      </p>

      <div
        className="d-inline-block m-btm-10"
        style={{
          width: 70,
          height: 70,
          backgroundImage: getLogoUrl(),
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          border: '1px solid #BDBDBD',
          borderRadius: roundLogo ? '50%' : 8,
        }}
      ></div>

      <p className="fs-16 fw-600 m-top-0 m-btm-0 break-word">{name}</p>
      <p className="fs-12 gray m-top-0 m-btm-15">123 Street Address, City Name, ST 00000</p>

      <section
        className="rem-pad-width align-left"
        style={{
          width: '100%',
          maxWidth: 332,
          margin: '0 auto',
          padding: '16px 24px',
          border: '1px solid #BDBDBD',
          borderRadius: 8,
        }}
      >
        <h3 className="fs-28 fw-800 margin-0">$100</h3>

        <ul className="ul-no-style">
          {[1, 2].map((num, i, arr) => (
            <li
              key={num}
              className="flex-row-nospacebetween-nowrap"
              style={{
                gap: 20,
                padding: '20px 0',
                borderBottom: '1px solid #BDBDBD',
              }}
            >
              <div
                className="border-r8 fs-42 flex-col-center"
                style={{
                  width: 72,
                  height: 72,
                  background: '#F2F3F6',
                  color: '#BCD5E7',
                }}
              >
                <FontAwesomeIcon icon={['far', 'shopping-bag']} />
              </div>
              <p className="fs-16 fw-400">Item {num} Description</p>
            </li>
          ))}
        </ul>

        <div className="m-top-15">
          <p className="fs-16 margin-0">
            <span className="fw-600">Sent on</span> {today}
          </p>
          <p className="fs-16 margin-0">
            <span className="fw-600">To</span> Customer Name
          </p>
          <p className="fs-16 margin-0">
            <span className="fw-600">From</span> Associate Name
          </p>
        </div>
      </section>

      <button
        className="standardButton fs-13 fw-600 white d-block"
        style={{
          background: '#BDBDBD',
          width: '100%',
          maxWidth: 332,
          margin: '16px auto',
          cursor: 'not-allowed',
        }}
        disabled
      >
        Pay $100
      </button>
    </aside>
  );
};

Preview.propTypes = {
  name: string,
  address: string,
  logoUrl: string,
  show: bool,
  close: func,
  merchantId: oneOfType([number, string]),
};

const mapStateToProps = state => ({
  logoUrl: state.messageSettings.logoUrl,
  roundLogo: state.messageSettings.roundLogo,
  merchantId: state.auth.merchantId,
});

export default connect(mapStateToProps)(Preview);
