import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopConfirm from '../../../../../../Layout/PopConfirm';
import DropdownSelect from '../../../../../../Layout/DropdownSelect';
import FilterChips from '../../../../../../Layout/FilterChips';
import { postRequest, getRequest } from '../../../../../../../core/apiRequests';
import { notificationSuccess } from '../../../../../../../core/actions';
import {
  setMsgClient,
  selfAssignConversation,
  selfUnassignConversation,
} from '../../../../../../../reducers/messaging';

const AssignChatPopup = props => {
  // const [tempAssoc, setTempAssoc] = useState(props.associateList);
  const [tempAssoc, setTempAssoc] = useState([]);
  const [startAssoc, setStartAssoc] = useState([]);
  const [asctPlaceholder, setAsctPlaceholder] = useState('');

  const setFilterSettings = obj => {
    if (tempAssoc.includes('none') || tempAssoc.includes(0)) {
      tempAssoc.shift();
    }
    if (obj.value === 'none' || Number(obj.value) === 0) {
      if (obj.value === 'none') {
        setTempAssoc(['none']);
      } else {
        setTempAssoc([]);
      }
    } else {
      const filteredArr = tempAssoc.filter(aid => Number(aid) !== Number(obj.value));
      setTempAssoc([...filteredArr, obj.value]);
    }
  };

  const assignAssociate = () => {
    props.setAssignPopup(false);
    const reqObj = {
      data: { associateIds: tempAssoc },
    };
    if (props.source === 'chatInbox') {
      reqObj.params = ['clients', props.messaging.client.client_id, 'changeAssignAssociates'];
    } else if (props.source === 'hoverAssign') {
      reqObj.params = ['clients', props.hoverAssignId, 'changeAssignAssociates'];
    }
    (async () => {
      const data = await postRequest(reqObj);
      if (data) {
        setMsgClient({ assignedAssociates: tempAssoc });
        setTempAssoc([]);
        notificationSuccess('Associate(s) Updated');
        if (!props.messaging.client.isOpen) {
          return;
        }
        if (tempAssoc.includes(props.auth.userId)) {
          if (!startAssoc.includes(props.auth.userId)) {
            selfAssignConversation(props.messaging.client.client_id, tempAssoc);
          }
        } else if (startAssoc.includes(props.auth.userId)) {
          selfUnassignConversation(props.messaging.client.client_id, tempAssoc);
        }
      }
    })();
  };

  const getChatObservers = () => {
    const reqObj = {
      loadingStatusOff: true,
    };
    if (props.source === 'chatInbox') {
      reqObj.params = ['clients', props.messaging.client.client_id, 'assignedAssociates'];
    } else if (props.source === 'hoverAssign') {
      reqObj.params = ['clients', props.hoverAssignId, 'assignedAssociates'];
    }
    (async () => {
      const data = await getRequest(reqObj);
      if (data) {
        const associateObserve = [];
        data.forEach(associate => {
          associateObserve.push(associate.associate_id);
        });
        setTempAssoc(associateObserve);
        setStartAssoc(associateObserve);
      }
    })();
  };

  const filteredAssociateList = useMemo(
    () =>
      props.asctFilterList
        .filter(item => !['', 'NO'].includes(item.value))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [props.asctFilterList],
  );

  useEffect(() => {
    if (tempAssoc.length > 0) {
      if (tempAssoc.length !== 0 && tempAssoc[0] !== 'none') {
        setAsctPlaceholder(`${tempAssoc.length} Selected Associate${tempAssoc.length > 1 ? 's' : ''}`);
      }
      if (tempAssoc.length === 0) {
        setAsctPlaceholder('Select Associates');
      }
      setAsctPlaceholder('No Assigned Associates');
    }
    setAsctPlaceholder('Select Associates');
  }, [tempAssoc]);

  useEffect(() => {
    if (props.messaging.client.client_id ?? props.hoverAssignId) {
      getChatObservers();
    }
  }, [props.messaging.client.client_id, props.hoverAssignId]);

  return (
    <PopConfirm
      popStyle={{
        width: '380px',
      }}
      confirmButtonText="Save"
      cancelButtonText="Cancel"
      confirm="Update Assigned Associates"
      description={[
        <div key="primary" className="align-left m-top-10">
          <span className="dropDownDescriptionText">Associates</span>
          <DropdownSelect
            classProps="w-100-P w-222 align-left h-50 w-295"
            options={filteredAssociateList}
            placeholder={asctPlaceholder}
            value={null}
            isSearchable
            selectProps={{
              maxMenuHeight: 220,
              // eslint-disable-next-line react/no-unstable-nested-components
              formatOptionLabel: obj => {
                if (tempAssoc.includes(obj.value)) {
                  return (
                    <div style={{ alignItems: 'center' }} className="flex-row-nospacebetween-nowrap">
                      <FontAwesomeIcon icon={['fal', 'check']} className="fs-12 m-right-8" />
                      <span className="p-top-3">{obj.label}</span>
                    </div>
                  );
                }
                return obj.label;
              },
            }}
            onChange={obj => {
              setFilterSettings({
                ...obj,
              });
            }}
          />
          <div className="w-100-P">
            <FilterChips
              asctFilterList={filteredAssociateList}
              filterAssociates={tempAssoc}
              setFilterAssociates={setTempAssoc}
              setTempAssoc={setTempAssoc}
            />
          </div>
        </div>,
      ]}
      noButtonDisable
      onCancel={() => props.setAssignPopup(false)}
      onConfirm={assignAssociate}
    />
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  messaging: state.messaging,
});
export default connect(mapStateToProps, {})(AssignChatPopup);
