import React, { useEffect, Fragment, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import Chart from 'chart.js';
import StyledLink from '../../../Layout/StyledLink';

const SmallCard = props => {
  const [arrowAnim, setArrowAnim] = useState('');
  const [chartDrawn, setChartDrawn] = useState(false);

  let theChart;
  const chartRef = useRef(null);
  // chart stuff
  useEffect(() => {
    if (props.apiComplete) {
      if (!chartDrawn) {
        buildChart();
        setChartDrawn(true);
      }
    }
  }, [props]);
  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  const arrowOnClick = (e, arrowId) => {
    if (arrowId == e.currentTarget.id) {
      props.setIsShown(arrowId);
    } else {
      props.setIsShown('');
    }
  };
  const arrowFunction = arrowData => {
    const arrowDataCurrent = arrowData.curr ? arrowData.curr : 0;
    const arrowDataPast = arrowData.prev ? arrowData.prev : 0;
    // difference is the comparison of last month to this month. Negative means last month did better
    const difference = arrowDataCurrent - arrowDataPast;
    const percentage = Math.round((difference / arrowDataPast) * 100);
    const normalArrow = [
      'totalMsgCnt',
      'inboundMessaging',
      'contacted',
      'automationMsgCnt',
      'conversation',
      'reminderDue',
      'reminderComplete',
      'attributedSales',
      'amountProcessed',
      'amountPaid',
    ];
    if (normalArrow.includes(arrowData.id)) {
      if (arrowDataPast == 0) {
        return (
          <img
            style={{ height: '15px', width: '15px' }}
            src="https://dashboard-v2-images.s3.amazonaws.com/blackMinus.svg"
          />
        );
      }
      if (arrowDataPast > arrowDataCurrent) {
        const percentagePos = percentage * -1;
        return (
          <div
            style={{ color: '#FF7A7A' }}
            id={arrowData.id}
            className="small_card_percent_width align-right"
            onClick={e => {
              arrowOnClick(e, arrowData.id);
            }}
            onMouseEnter={() => setArrowAnim('arrowAnim')}
            onMouseLeave={() => {
              setArrowAnim('');
              props.setIsShown('');
            }}
          >
            <img
              className={arrowAnim}
              style={{ height: '15px', width: '20px' }}
              src="https://dashboard-v2-images.s3.amazonaws.com/metricDownArrow.svg"
            />
            <span>{percentagePos}%</span>
          </div>
        );
      }
      if (arrowDataPast < arrowDataCurrent) {
        return (
          <div
            style={{ color: '#7FBD31' }}
            id={arrowData.id}
            className="small_card_percent_width align-right"
            onClick={e => arrowOnClick(e, arrowData.id)}
            onMouseEnter={() => setArrowAnim('arrowAnim')}
            onMouseLeave={() => {
              setArrowAnim('');
              props.setIsShown('');
            }}
          >
            <img
              className={arrowAnim}
              style={{ height: '15px', width: '20px' }}
              src="https://dashboard-v2-images.s3.amazonaws.com/metricUpArrow.svg"
            />
            <span>{percentage}%</span>
          </div>
        );
      }
      if (arrowDataPast == arrowDataCurrent) {
        return (
          <img
            style={{ height: '15px', width: '15px' }}
            src="https://dashboard-v2-images.s3.amazonaws.com/blackMinus.svg"
          />
        );
      }
      return null;

      // ELSE is for when larger numbers is a bad thing, such as more time to pay (timeliness, time to pay, time to respond)
    }
    if (arrowDataPast == 0) {
      return (
        <img
          style={{ height: '15px', width: '15px' }}
          src="https://dashboard-v2-images.s3.amazonaws.com/blackMinus.svg"
        />
      );
    }
    if (arrowDataPast < arrowDataCurrent) {
      return (
        <div
          style={{ color: '#FF7A7A' }}
          id={arrowData.id}
          className="small_card_percent_width align-right"
          onClick={e => {
            arrowOnClick(e, arrowData.id);
          }}
          onMouseEnter={() => setArrowAnim('arrowAnim')}
          onMouseLeave={() => {
            setArrowAnim('');
            props.setIsShown('');
          }}
        >
          <img
            className={arrowAnim}
            style={{ height: '15px', width: '20px' }}
            src="https://dashboard-v2-images.s3.amazonaws.com/metricDownArrow.svg"
          />
          <span>{percentage}%</span>
        </div>
      );
    }
    if (arrowDataPast > arrowDataCurrent) {
      const percentagePos = percentage * -1;
      return (
        <div
          style={{ color: '#7FBD31' }}
          id={arrowData.id}
          className="small_card_percent_width align-right"
          onClick={e => arrowOnClick(e, arrowData.id)}
          onMouseEnter={() => setArrowAnim('arrowAnim')}
          onMouseLeave={() => {
            setArrowAnim('');
            props.setIsShown('');
          }}
        >
          <img
            className={arrowAnim}
            style={{ height: '15px', width: '15px' }}
            src="https://dashboard-v2-images.s3.amazonaws.com/metricUpArrow.svg"
          />
          <span>{percentagePos}%</span>
        </div>
      );
    }
    if (arrowDataPast == arrowDataCurrent) {
      return (
        <img
          style={{ height: '15px', width: '15px' }}
          src="https://dashboard-v2-images.s3.amazonaws.com/blackMinus.svg"
        />
      );
    }
    return null;
  };
  const buildChart = () => {
    const ctx = chartRef.current.getContext('2d');
    if (theChart) {
      theChart.clear();
      theChart.destroy();
    }
    theChart = null;
    theChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: [
          `${props.graphContent.associate} Associate`,
          `${props.graphContent.marketing} Marketing`,
          `${props.graphContent.automation} Clientbook`,
        ],
        datasets: [
          {
            data: [props.graphContent.associate, props.graphContent.marketing, props.graphContent.automation],
            //   data: [12,10,5],
            backgroundColor: ['#2EDBC3', '#33AAFC', '#395C73'],
            borderWidth: 1,
            pointStyle: 'circle',
            pointRadius: 2,
            radius: 50,
          },
        ],
      },
      options: {
        events: [],
        legend: {
          display: true,
          position: 'right',
          align: 'right',
          labels: {
            boxWidth: 5,
            boxHeight: 5,
            fontSize: 13,
            fontStyle: 'normal',
            fontColor: '#6A7983',
            usePointStyle: true,
            padding: 5,
            textAlign: 'right',
          },
        },
        responsive: false,
      },
    });
  };
  const previousDataConfig = previousData => {
    let previousReturn = '';
    const previousArray = [];
    switch (previousData.id) {
    case 'totalMsgCnt':
      previousReturn = numberWithCommas(previousData.prev);
      break;
    case 'inboundMessaging':
      previousReturn = numberWithCommas(previousData.prev);
      break;
    case 'contacted':
      const newPercent = previousData.prev * 100;
      previousReturn = `${newPercent}%`;
      break;
    case 'automationMsgCnt':
      previousReturn = numberWithCommas(previousData.prev);
      break;
    case 'conversation':
      previousReturn = numberWithCommas(previousData.prev);
      break;
    case 'timeRespond':
      const responseTime = Math.round(previousData.prev);
      var hours = Math.floor(responseTime / 60);
      var minutes = responseTime % 60;
      if (responseTime < 50) {
        previousReturn = `${responseTime} min`;
      } else if (responseTime > 50 && responseTime <= 60) {
        previousReturn = '1 hr';
      } else {
        if (hours.toString().length < 2) {
          hours = `0${hours}`;
        }
        if (minutes.toString().length < 2) {
          minutes = `0${minutes}`;
        }
        previousReturn = `${hours} hr ${minutes} min`;
      }
      break;
    case 'reminderDue':
      previousReturn = numberWithCommas(previousData.prev);
      break;
    case 'reminderComplete':
      previousReturn = numberWithCommas(previousData.prev);
      break;
    case 'attributedSales':
      previousReturn = `$${numberWithCommas(previousData.prev)}`;
      break;
    case 'timeliness':
      previousReturn = `${numberWithCommas(previousData.prev)} days`;
      break;
    case 'amountProcessed':
      previousReturn = `$${numberWithCommas(previousData.prev)}`;
      break;
    case 'amountPaid':
      previousReturn = `$${numberWithCommas(previousData.prev)}`;
      break;
    case 'timeToPayMetric':
      // time to pay is returned in seconds
      const time_to_pay = Math.round(Number(previousData.prev));
      var hours = Math.floor(time_to_pay / 3600);
      var minutes = Math.floor((time_to_pay - hours * 3600) / 60);
      var seconds = Math.floor((time_to_pay - hours * 3600) % 60);
      if (minutes < 50) {
        if (minutes == 0) {
          previousReturn = `${minutes}.${seconds} sec`;
        } else {
          previousReturn = `${minutes} min`;
        }
      } else if (minutes > 50 && minutes <= 60) {
        previousReturn = '1 hr';
      } else {
        if (hours.toString().length < 2) {
          hours = `0${hours}`;
        }
        if (minutes.toString().length < 2) {
          minutes = `0${minutes}`;
        }
        previousReturn = `${hours} hr ${minutes} min`;
      }
      break;
    default:
      break;
    }
    previousArray.push(
      <div key={previousData.id} style={{ display: 'flex' }}>
        <span className={previousReturn ? 'previousMetricNum ' : 'previousMetricText '}>
          {previousReturn || 'no previous data'}
        </span>
      </div>,
    );
    return previousArray;
  };
  return (
    <div key={props.key} className={`small_card ${props.small_card_class}`} style={props.smallCardStyles}>
      <div className="dashboardMetrics_card_header_div align-left">
        {props.header}
        {props.arrow ? arrowFunction(props.arrow) : null}
      </div>
      <div className="dashboardMetrics_card_content_div">
        {props.graphContent ? (
          <div className="flex-col-left">
            <canvas ref={chartRef} width="185" height="75" />
            {/* id="doughnutChart" */}
            <span className="card_text">{props.graphContent.text}</span>
          </div>
        ) : (
          <div className="flex-col-left">
            <span className="card_number">{numberWithCommas(props.numberData.number)}</span>
            <span className="card_subHeader">{props.numberData.subHeader}</span>
            <span className="card_text">{props.numberData.text}</span>
          </div>
        )}
      </div>
      {(props.auth.role === 'ADMIN' || props.auth.role === 'MANAGER') && props.viewReportLink ? (
        <div className="viewReportDiv">
          <StyledLink classprops="viewReportAnchor" to={`${props.viewReportLink}`}>
            View Report
          </StyledLink>
        </div>
      ) : null}
      <div id={props.ring} className="dash-ring-small dash-ring-sm-loc">
        <div />
        <div />
        <div />
        <div />
      </div>
      {/* here */}
      <ToolTipHome description={previousDataConfig(props.arrow)} isShown={props.isShown} idx={props.arrow.id} />
    </div>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(SmallCard);
const ToolTipHome = props => {
  const { isShown, idx, description } = props;
  return (
    <div
      id={isShown === idx ? 'textBlock_active' : 'textBlock'}
      className="pos-abs border-r12"
      style={{
        backgroundColor: '#fff',
        minWidth: '184px',
        left: '210px',
        bottom: '30px',
        height: '89px',
        zIndex: '9999999999999999999999999999999999999',
        border: '1px solid #BEC8CC',
      }}
    >
      <div>
        <div className="flex-row-spacebetween-nowrap" style={{ padding: '16px 16px 0px 16px' }}>
          <span style={{ fontStyle: 'italic', color: '#6A7983' }} className="fs-10">
            Previous 30 days
          </span>
          {props.infoCircle ? (
            <FontAwesomeIcon className="checkbox fs-16" color="#1769AF" icon={['far', 'info-circle']} />
          ) : null}
        </div>
        {description}
        {/* <span className={description ? 'previousMetricNum' : 'previousMetricText'}>{description ? description : 'no previous data'}</span> */}
      </div>
      <img
        src="https://dashboard-v2-images.s3.amazonaws.com/groupCaret.svg"
        className="pos-abs"
        style={{ top: '-9px', left: '10px' }}
      />
    </div>
  );
};
