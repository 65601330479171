import React, { useState, Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledLink from '../../StyledLink';
import Button from '../../Button';
import PopConfirm from '../../PopConfirm';
import SmallTable from '../../SmallTable';
import CheckboxDesc from '../../CheckboxDesc';
import { postRequest, getRequest, deleteRequest } from '../../../../core/apiRequests';
import { notificationSuccess, AutoReminderCheck, notificationError } from '../../../../core/actions';
import {
  setReminderName,
  setReminderCount,
  setReminderInterval,
  setReminderRepeat,
  setRemRepeatCount,
  setRemRepeatInterval,
  setReminderActionId,
  setReminderAddEdit,
  setRemTempMsgs,
  setRemTempMsgText,
  setRemTempAddEdit,
  setRemTempMsgId,
  setListType,
} from '../../../../reducers/autoRemMsgs';
// import messageHighlighter from "../messageHighlighter";
import DropdownSelect from '../../DropdownSelect';
import ViewAllTemplates from '../ViewAllTemplates';
import FiveTemplates from './FiveTemplates';
import SelectedTemplate from './SelectedTemplate';

const AutoWhatsAppSettings = props => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const { listItemId } = props.match.params;
  const history = useHistory();
  const isMounted = useRef(null);
  const { whatsappId } = props.match.params;
  const [autoTemplateId, setAutoTemplateId] = useState('');
  const [showImmediateDD, setShowImmediateDD] = useState(false);
  const [autoReminderImmediate, setReminderImmediate] = useState(false);
  const [tempActionScale, setTempActionScale] = useState(1);
  const [tempActionInterval, setTempActionInterval] = useState('WEEK');
  const [showViewAll, toggleViewAll] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [fiveTemplates, setFiveTemplates] = useState([]);
  const [allTemplates, setAllTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const intervalOptions = [
    { label: 'Days', value: 'DAY' },
    { label: 'Weeks', value: 'WEEK' },
    { label: 'Months', value: 'MONTH' },
  ];

  const listFormatter = (t, o) => {
    const subsObj = {}; // an obj that will look like {1: client, 2: product, 3: something, 4: whatevz}
    if (o.substitution) {
      const substitutes = o.substitution.split(','); // they're comma delimited, make an array
      substitutes.forEach(sub => {
        const propertyDivide = sub.split('='); // split them up on that equals sign into an array
        if ((propertyDivide.length = 2)) {
          subsObj[propertyDivide[0]] = propertyDivide[1].toLowerCase(); // put them on that subsObj, and lowercase the values... we're rendering them later!
        }
      });
    }
    return t.replace(/{{\d}}/g, val => {
      const theNumber = val.replace(/[{}]/g, ''); // if found, remove all {'s and }'s
      if (subsObj[theNumber]) {
        return `[${subsObj[theNumber]}]`; // if so return it
      }
      return '[N/A]'; // else return this lol extreme edge case, I don't know.
    });
  };

  const searchFunctionality = searchText => {
    if (searchText) {
      const searchArray = searchText.split(' ');
      const filteredArr = [];
      allTemplates.forEach(tmpl => {
        let hasIt = true;
        searchArray.forEach(sText => {
          const sTextFm = sText.toLowerCase().trim() || '';
          const templateTxt = tmpl.template || '';
          const subTxt = tmpl.substitution || '';
          if (!templateTxt.toLowerCase().includes(sTextFm) && !subTxt.toLowerCase().includes(sTextFm)) {
            hasIt = false;
          }
        });
        if (hasIt) {
          filteredArr.push(tmpl);
        }
      });
      if (filteredArr.length > 5) {
        setFiveTemplates([...filteredArr].slice(0, 5));
      } else {
        setFiveTemplates([...filteredArr]);
      }
    } else if (allTemplates.length > 5) {
      setFiveTemplates([...allTemplates.slice(0, 5)]);
    } else {
      setFiveTemplates([...allTemplates]);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    const { type } = props; // do we use this anymore?
    setRemTempMsgs([]);
    if (props.merchantId) {
      if (window.location.href.includes('add-auto-whatsapp')) {
        (async () => {
          const reqObj = {
            params: [type, props.merchantId, props.match.params.listItemId, 'whatsappAutoTemplate'],
          };
          const data = await getRequest(reqObj);
          if (data.hasOwnProperty('templates')) {
            // let dataTemplates = data.templates.map((dt) => {
            //   return {
            //     ...dt,
            //     template: listFormatter(dt.template, dt),
            //   };
            // });
            if (data.templates.length > 5) {
              setFiveTemplates(data.templates.slice(0, 5));
            } else {
              setFiveTemplates(data.templates);
            }
            setAllTemplates(data.templates);
          }
        })();
      } else if (
        window.location.href.includes('edit-auto-whatsapp') &&
        Number(autoTemplateId) !== Number(props.match.params.whatsappId)
      ) {
        const reqObj = {
          params: [type, props.merchantId, props.match.params.listItemId, 'whatsappAutoTemplate'],
          /// uhh just do a query here
          query: {
            whatsappId,
          },
        };
        (async () => {
          const data = await getRequest(reqObj);
          if (data && data.hasOwnProperty('whatsappConfig') && data.hasOwnProperty('templates')) {
            const {
              id,
              description,
              action_interval,
              action_scale,
              repeats,
              repeat_interval,
              repeat_scale,
              whatsapp_template_id,
            } = data.whatsappConfig;

            if (data.templates.length > 5) {
              setFiveTemplates(data.templates.slice(0, 5));
            } else {
              setFiveTemplates(data.templates);
            }
            setSelectedTemplate(whatsapp_template_id);
            setAllTemplates(data.templates);

            setReminderAddEdit('Edit');
            setReminderActionId(id);
            setListType(type);
            setReminderName(description);
            setReminderInterval(action_interval);
            setReminderCount(action_scale);
            setReminderRepeat(Number(repeats));
            // reminder stuff set by Naomi i think
            setTempActionScale(action_scale);
            setTempActionInterval(action_interval);
            setRemRepeatInterval(repeat_interval);
            setRemRepeatCount(repeat_scale);

            if (action_scale === 0 && action_interval === 'DAY') {
              setReminderImmediate(true);
              setShowImmediateDD(false);
            }
          } else {
            console.error('Failed to get Whatsapp Template');
            history.push(props.editRoute);
          }
        })();
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [props.merchantId]);

  useEffect(() => {
    if (window.location.href.includes('add-auto-whatsapp')) {
      setReminderImmediate(true);
      props.setCount(0);
      props.setInterval('DAY');
    }
  }, []);

  useEffect(() => {
    if (autoReminderImmediate) {
      setShowImmediateDD(!showImmediateDD);
    } else {
      setShowImmediateDD(!showImmediateDD);
    }
  }, [autoReminderImmediate]);

  const tableHeaders = [
    {
      keyName: 'template',
      title: '',
      rowClassName: '',
      style: { width: '100%', paddingTop: '0px' },
      sortable: false,
    },
  ];

  const confirmDelete = () => {
    const { firstName, lastName, type } = props;
    const fn = firstName || '';
    const ln = lastName || '';
    (async () => {
      const reqObj = {
        params: [type, props.merchantId, listItemId, whatsappId, 'whatsappAutoTemplate'],
        query: { deletedBy: `${fn} ${ln}` },
      };
      const data = await deleteRequest(reqObj);
      if (data) {
        notificationSuccess('WhatsApp Template Removed');
        // props.setRedirectBack(true);
        history.push(`${props.editRoute}`);
      }
    })();
  };

  const saveAutoWhatsapp = () => {
    const { listItemId } = props.match.params;
    const {
      type,
      autoRemMsgs: {
        reminderCount,
        reminderInterval,
        reminderRepeat,
        // repeat stuff here
        autoReminderRepeatCount,
        autoReminderRepeatInterval,
      },
      firstName,
      lastName,
    } = props;
    const fn = firstName || '';
    const ln = lastName || '';
    const filterTemp = allTemplates.filter(tmp => tmp.id === selectedTemplate);
    let templateObj = {};
    if (filterTemp.length > 0) {
      templateObj = filterTemp[0];
    }
    if (type) {
      const dataObj = {
        id: whatsappId,
        templateObj,
        actionScale: reminderCount,
        actionInterval: reminderInterval,
        repeats: reminderRepeat,
        // repeat stuff here
        repeatScale: reminderRepeat === 1 ? autoReminderRepeatCount : null,
        repeatInterval: reminderRepeat === 1 ? autoReminderRepeatInterval : null,
      };
      if (
        (reminderRepeat && !autoReminderRepeatCount && type !== 'lifeEvent') ||
        (reminderRepeat && !autoReminderRepeatInterval && type !== 'lifeEvent')
      ) {
        notificationError('Please set a repeat schedule');
      } else if (!templateObj.id) {
        notificationError('Please select a template');
      } else {
        (async () => {
          const reqObj = {
            params: [type, props.merchantId, listItemId, 'whatsappAutoTemplate'],
            data: dataObj,
            query: {
              createdBy: `${fn} ${ln}`.trim(),
            },
          };
          const data = await postRequest(reqObj);
          if (data.id) {
            if (window.location.href.includes('edit-auto-whatsapp')) {
              notificationSuccess('WhatsApp Template Updated');
              history.push(`${props.editRoute}`);
            } else {
              notificationSuccess('WhatsApp Template Added');
              props.setAddEdit('Edit');
              props.setActionId(data.id);
              history.push(`${props.editRoute}/edit-auto-whatsapp/${data.id}`);
            }
          } else {
            history.push(`${props.editRoute}`);
            // TODO: error handling?
          }
        })();
      }
    }
  };

  const selectHandler = id => {
    setSelectedTemplate(id);
  };

  const rowClickHandler = data => {
    setSelectedTemplate(data.id);
    setRemTempAddEdit('Edit');
  };

  const immediateFunction = () => {
    setReminderImmediate(!autoReminderImmediate);
    if (!autoReminderImmediate) {
      props.setCount(0);
      props.setInterval('DAY');
    } else {
      props.setCount(tempActionScale);
      props.setInterval(tempActionInterval);
    }
  };

  const repeatFunction = () => {
    setReminderRepeat(props.autoRemMsgs.reminderRepeat ? 0 : 1);
    if (props.autoRemMsgs.reminderRepeat === false || props.autoRemMsgs.reminderRepeat === 0) {
      setRemRepeatCount(props.autoRemMsgs.reminderCount);
      setRemRepeatInterval(props.autoRemMsgs.reminderInterval);
    } else {
      setRemRepeatCount('');
      setRemRepeatInterval('');
    }
  };

  return (
    <>
      <div
        className=""
        style={props.paymentBannerActive ? { maxWidth: '1008px', marginTop: '70px' } : { maxWidth: '1008px' }}
      >
        {selectedTemplate ? (
          <SelectedTemplate
            selected={selectedTemplate}
            setSelected={setSelectedTemplate}
            allTemplates={allTemplates.map(dt => {
              return {
                ...dt,
                template: listFormatter(dt.template, dt),
              };
            })}
          />
        ) : (
          <FiveTemplates
            searchFunctionality={searchFunctionality}
            toggleViewAll={toggleViewAll}
            fiveTemplates={fiveTemplates.map(dt => {
              return {
                ...dt,
                template: listFormatter(dt.template, dt),
              };
            })}
            rowClickHandler={rowClickHandler}
            tableHeaders={tableHeaders}
            selectedTemplate={selectedTemplate}
            searchText={searchText}
          />
        )}

        <h2
          style={{
            fontSize: '15px',
            fontWeight: '500',
            textAlign: 'left',
            marginBottom: 0,
            marginTop: '39px',
          }}
        >
          Schedule
        </h2>
        <span className="fs-10 gray d-block m-top-30 m-btm-15 align-left">
          {window.location.href.includes('life-events')
            ? 'Select how far before the life event you want the auto message to send to the client.'
            : 'After tagging the client, how soon should the message send?'}
        </span>

        <CheckboxDesc
          checked={autoReminderImmediate}
          onclick={immediateFunction}
          text="Same Day"
          classProps="fs-13"
          checkButtonProps="m-btm-15 w-85"
        />
        {showImmediateDD ? (
          <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel m-btm-28">
            <div className="w-48-P align-left" style={{ position: 'relative' }}>
              <input
                onChange={e => {
                  const filterText = e.target.value.replace(/[^-0-9]/g, '');
                  props.setCount(filterText);
                }}
                min="1"
                max="31"
                step={1}
                value={props.autoRemMsgs.reminderCount}
                type="number"
                className="inputHeight w-100-P rem-pad-width common_inputStyle"
                placeholder="1"
              />
            </div>
            <div className=" w-48-P inputHeight" style={{ position: 'relative' }}>
              <DropdownSelect
                classProps="maw-100-P w-100-P align-left"
                options={intervalOptions}
                placeholder="Weeks"
                value={props.autoRemMsgs.reminderInterval}
                onChange={obj => {
                  props.setInterval(obj.value);
                }}
              />
            </div>
          </div>
        ) : null}
        {/* REPEATS */}
        {props.type !== 'lifeEvent' ? (
          <div className="max-w-600 w-100-P">
            <span className="fs-10 gray d-block m-btm-15 align-left">
              After the initial message is sent, how frequently would you like to repeat this WhatsApp auto template?
            </span>
            <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel m-btm-28">
              <CheckboxDesc
                checked={props.autoRemMsgs.reminderRepeat}
                onclick={repeatFunction}
                text="Repeats"
                checkButtonProps="m-btm-15"
              />
              {props.autoRemMsgs.reminderRepeat ? (
                <div className="flex-row-spacebetween-wrap w-100-P max-w-600 w-100-P pos-rel">
                  <div className="w-48-P inputHeight align-left" style={{ position: 'relative' }}>
                    <input
                      onChange={e => {
                        const filterText = e.target.value.replace(/[^-0-9]/g, '');
                        props.setRepeatCnt(filterText);
                        // setRemRepeatCount(filterText);
                      }}
                      min="1"
                      max="31"
                      step={1}
                      value={props.autoRemMsgs.autoReminderRepeatCount}
                      type="number"
                      className="inputHeight w-100-P rem-pad-width common_inputStyle"
                      placeholder="6"
                    />
                  </div>
                  <div className=" w-48-P inputHeight" style={{ position: 'relative' }}>
                    <DropdownSelect
                      classProps="maw-100-P w-100-P align-left"
                      options={intervalOptions}
                      placeholder="Months"
                      value={props.autoRemMsgs.autoReminderRepeatInterval}
                      onChange={obj => {
                        props.setRepeatInt(obj.value);
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      {/* START of save / cancel / delete code  */}
      <div className="flex-col-center m-btm-56 m-top-37 maw-600">
        <button type="button" className="save-button-v2 m-btm-15" onClick={saveAutoWhatsapp}>
          Save
        </button>
        {props.autoRemMsgs.reminderAddOrEdit === 'Edit' ? (
          <Button
            classname="deleteButton-v2 m-btm-8"
            style={{ marginBottom: '15px' }}
            onclick={() => {
              setShowPopConfirm(true);
            }}
          >
            Delete
          </Button>
        ) : (
          <div />
        )}
        <StyledLink to={props.editRoute}>
          <button type="button" className="cancel-button-v2 m-btm-8">
            Cancel
          </button>
        </StyledLink>
      </div>
      {showPopConfirm ? (
        <PopConfirm
          confirm="Are you sure you want to remove this Whatsapp config?"
          description={props.popConfirmDesc}
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={confirmDelete}
        />
      ) : null}
      {showViewAll ? (
        <ViewAllTemplates
          listItemId={listItemId}
          selected={selectedTemplate}
          toggleViewAll={toggleViewAll}
          setSelected={selectHandler}
          saveHandler={() => toggleViewAll(false)}
        />
      ) : null}
    </>
  );
};
const mapStateToProps = state => {
  return {
    ...state.auth,
    addEditTag: { ...state.addEditTag },
    autoRemMsgs: { ...state.autoRemMsgs },
    paymentBannerActive: state.paymentBanner.active,
  };
};
const mapDispatchToProps = dispatch => ({
  setName(val) {
    setReminderName(val);
  },
  setCount(val) {
    setReminderCount(val);
  },
  setInterval(val) {
    setReminderInterval(val);
  },
  setRepeat(val) {
    setReminderRepeat(val);
  },
  setRepeatCnt(val) {
    setRemRepeatCount(val);
  },
  setRepeatInt(val) {
    setRemRepeatInterval(val);
  },
  setActionId(val) {
    setReminderActionId(val);
  },
  setAddEdit(val) {
    setReminderAddEdit(val);
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AutoWhatsAppSettings);
