import React, { useEffect, useState } from 'react';
// import DescOverInput from "../../../../../Layout/DescOverInput";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setTriggerReminderInterval } from '../../../../../../../reducers/salesTriggers';
import DropdownSelect from '../../../../../../Layout/DropdownSelect';
import { getRequest, postRequest } from '../../../../../../../core/apiRequests';
import { notificationError, notificationSuccess } from '../../../../../../../core/actions';

const CategoryModal = props => {
  const [allCategories, setAllCategories] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [triggerCategories, setTriggerCategories] = useState(JSON.parse(JSON.stringify(props.triggerCategories)));
  const [cancelCategories, setCancelCategories] = useState(JSON.parse(JSON.stringify(props.triggerCategories)));

  useEffect(() => {
    getTriggerCategories();
  }, [props.merchantId]);

  const getTriggerCategories = () => {
    if (props.merchantId) {
      const reqObj = {
        params: ['salesTrigger', props.merchantId, props.triggerId || 'none', 'getMerchantCategories'],
      };
      (async () => {
        const data = await getRequest(reqObj);
        if (data && Array.isArray(data)) {
          const tempArr = data.map(cat => cat.category);
          setAllCategories([...tempArr]);
        } else {
          notificationError('Error Checking Existing Categories');
        }
      })();
    }
  };

  const buildCategoryList = () => {
    return props.categories
      .filter(catObj => catObj.category.includes(filterText.trim()))
      .sort((a, b) => {
        if (a.category.toLowerCase() < b.category.toLowerCase()) {
          return -1;
        }
        if (a.category.toLowerCase() > b.category.toLowerCase()) {
          return 1;
        }
        return 0;
      })
      .map((catObj, i) => {
        return (
          <div key={i} className="flex-row-nospacebetween-nowrap m-top-15 m-btm-15 w-333">
            {triggerCategories.includes(catObj.category) ? (
              <FontAwesomeIcon
                onClick={() => checkboxClickHandler(catObj.category, false)}
                className="fs-16 m-right-20 pointer"
                color="#1769af"
                icon={['fas', 'check-square']}
              />
            ) : (
              <FontAwesomeIcon
                onClick={() => checkboxClickHandler(catObj.category, true)}
                className="fs-16 gray m-right-20 pointer"
                icon={['far', 'square']}
              />
            )}
            <span className="m-right-15">{catObj.category}</span>
            {allCategories.includes(catObj.category) ? (
              <FontAwesomeIcon icon={['far', 'check']} color="#7fbd31" />
            ) : null}
          </div>
        );
      });
  };

  const splitThis = () => {
    const organizedCat = props.categories
      .filter(catObj => catObj.category.includes(filterText.trim()))
      .sort((a, b) => {
        if (a.category.toLowerCase() < b.category.toLowerCase()) {
          return -1;
        }
        if (a.category.toLowerCase() > b.category.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    const columns = organizedCat.length;
    let slice;
    if (columns > 24) {
      slice = Math.ceil(columns / 3);
    } else if (columns < 24 && columns > 12) {
      slice = Math.ceil(columns / 2);
    } else {
      slice = columns;
    }
    let result = [];

    if (slice > 0) {
      result = new Array(Math.ceil(organizedCat.length / slice)).fill().map(_ => organizedCat.splice(0, slice));
    }
    const all = result.map((element, i) => {
      return (
        <div
          key={`key-e-${i}`}
          style={{ display: 'flex', flexDirection: 'column', alignContent: 'stretch', alignItems: 'stretch' }}
        >
          {element.map((catObj, i) => {
            return (
              <div
                key={`key-${i}`}
                className="flex-row-nospacebetween-nowrap m-top-15 m-btm-15 "
                style={{ maxWidth: '333px' }}
              >
                {triggerCategories.includes(catObj.category) ? (
                  <FontAwesomeIcon
                    onClick={() => checkboxClickHandler(catObj.category, false)}
                    className="fs-16 m-right-20 pointer"
                    color="#1769af"
                    icon={['fas', 'check-square']}
                  />
                ) : (
                  <FontAwesomeIcon
                    onClick={() => checkboxClickHandler(catObj.category, true)}
                    className="fs-16 gray m-right-20 pointer"
                    icon={['far', 'square']}
                  />
                )}
                <span className="m-right-15">{catObj.category}</span>
                {allCategories.includes(catObj.category) ? (
                  <FontAwesomeIcon icon={['far', 'check']} color="#7fbd31" />
                ) : null}
              </div>
            );
          })}
        </div>
      );
    });
    return all;
  };

  const hasAllSelected = () => {
    let hasAll = true;
    props.categories.forEach(ct => {
      if (!triggerCategories.includes(ct.category)) {
        hasAll = false;
      }
    });
    return hasAll;
  };

  const selectAllHandler = () => {
    if (hasAllSelected()) {
      setTriggerCategories([]);
    } else {
      const categoriesCopy = props.categories;
      setTriggerCategories([...categoriesCopy.map(ct => ct.category)]);
      // props.setTriggerActionObj({
      //   triggerCategories: [...props.categories.map((ct) => ct.category)],
      // });
    }
  };

  const saveCategories = () => {
    props.setTriggerActionObj({
      triggerCategories,
    });
    props.closeModal(false);
  };

  const handleCategoryModalCancel = () => {
    props.setTriggerActionObj({
      cancelCategories,
    });
    props.handleCancelModal();
  };

  const checkboxClickHandler = (category, addIt) => {
    const tempArr = triggerCategories;
    if (addIt) {
      // add it
      tempArr.push(category);
      setTriggerCategories([...tempArr]);
    } else if (!addIt) {
      // remove it
      const filteredArr = tempArr.filter(cat => cat !== category);
      setTriggerCategories([...filteredArr]);
      // removing functionality is not working, we are modyfing props on a child component and is causing errors.
      // the props are changing without using a reducer because it is modifying indirectly though setTriggerCategories.
      // To avoid re rendering I used the reducer after the props update context in the new function handleCategoryModalCancel.
      // WE should always avoid changing PROPS on child components like this modals using JSON.parse(JSON.stringify(props.triggerCategories)) is a good example of creating a deep copy
    }
  };

  return (
    <div className="pos-abs z-index-5 left-0 align-top w-100-vw h-100-vh bg-white p-50 rem-pad-width max-h-100vh overflowY">
      <div className="w-100-P align-left  m-top-60 " style={{ maxHeight: '100%', maxWidth: '1000px' }}>
        <div className="flex-col-left w-100-P m-btm-15">
          <div className="flex-row-spacebetween-wrap w-100-P">
            <div className="flex-col-left">
              <span className="align-left fs-16 fw-500 w-100-P">Category/Department</span>
              <span className="fs-14">{triggerCategories.length || 0} Selected</span>
            </div>
            <div className="flex-row-nospacebetween-nowrap">
              <button className="cancel-button m-right-15" onClick={handleCategoryModalCancel}>
                Cancel
              </button>
              <button className="save-button" onClick={saveCategories}>
                Done
              </button>
            </div>
          </div>
          <span className="fs-12 m-btm-24 m-top-15">
            <FontAwesomeIcon icon={['far', 'check']} color="#7fbd31" /> = Used in other trigger(s)
          </span>
          <div className="w-100-P flex-row-nospacebetween-nowrap">
            <input
              className="body-head-search fs-12 m-right-30 inputHeight common_inputStyle"
              style={{ width: '100%' }}
              placeholder="Filter by category name..."
              value={filterText}
              onChange={e => setFilterText(e.target.value)}
            />
            <div className="fw-600 flex-row-nospacebetween-nowrap pointer" onClick={() => selectAllHandler()}>
              {hasAllSelected() ? (
                <FontAwesomeIcon className="m-right-15" color="#1769af" icon={['fas', 'check-square']} />
              ) : (
                <FontAwesomeIcon className="m-right-15" icon={['far', 'square']} />
              )}
              <span className="fs-13 no-wrap">Select All</span>
            </div>
          </div>
        </div>
        <div className="h-100-P" style={{ maxHeight: '100%' }}>
          <div className="salesTriggerFlex ">
            {/* {buildCategoryList()} */}
            {splitThis()}
            {/* {splitCategories.forEach(cat => buildCategoryList(cat))} */}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  auth: state.auth,
  salesTriggers: state.salesTriggers,
  autoRemMsgs: { ...state.autoRemMsgs },
});
const mapDispatchToProps = dispatch => ({
  setInterval(val) {
    setTriggerReminderInterval(val);
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CategoryModal);

const DeleteButtonDropdown = props => {
  return (
    <div className="pos-rel">
      <DropdownSelect
        key={`product type ${props.objKey}`}
        componentProps={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        classProps="mq-w-200 w-267 m-btm-12  align-left h-50"
        options={props.salesTriggers.categories
          .map(prod => ({
            label: prod.category || '',
            value: prod.category || '',
          }))
          .sort((a, b) => {
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
              return -1;
            }
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
              return 1;
            }
            // a must be equal to b
            return 0;
          })}
        /// //////////////////// MIGHT NEED TO FIND A WAY TO HANDLE PLACEHOLDER CARROT
        placeholder={
          Object.keys(props.salesTriggers.triggerCategories).length === 0 ? 'Any' : 'Choose a product category…'
        }
        value={props.salesTriggers.triggerCategories[props.objKey]}
        onChange={obj => {
          props.setTriggerDetails(obj.value, props.salesTriggers.triggerCategories[props.objKey]);
        }}
      />
      <FontAwesomeIcon
        onClick={() => props.deleteTriggerCategory(props.salesTriggers.triggerCategories[props.objKey])}
        icon={['far', 'times']}
        className="pos-abs"
        style={{ top: '18px', right: '19.5px' }}
      />
    </div>
  );
};
