import React, { useState } from 'react';
import './tabbedheadercard.css';
import HeaderCard from '../HeaderCard';

//style as a prop, style = style.props
const TabbedHeaderCard = props => {
  const [showFirst, setShowFirst] = useState(true);
  const [showContent, setShowContent] = useState(0);

  const HeaderButton = btnProps => {
    return (
      <button
        onClick={() => setShowContent(btnProps.val)}
        style={
          btnProps.standard ? { borderBottom: 'solid 5px', color: '#353538' } : { border: 'none', color: '#818181' }
        }
        className="tab-header-card-btn"
      >
        {btnProps.title}
      </button>
    );
  };

  return (
    <HeaderCard
      headerContent={props.headerTitles.map((title, i) => {
        return <HeaderButton key={i} val={i} title={title} standard={showContent === i} />;
      })}
      TabbedContainerClass={props.tabbed_container_class}
    >
      {props.content[showContent]}
    </HeaderCard>
  );
};
export default TabbedHeaderCard;
