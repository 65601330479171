import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
// reusable components
import Layout from '../../../../Layout/StandardLayout';
import SecondaryPanel from '../../../../Layout/SecondaryPanel';
import CheckboxDesc from '../../../../Layout/CheckboxDesc';
import Button from '../../../../Layout/Button';
import StyledLink from '../../../../Layout/StyledLink';
import PopConfirm from '../../../../Layout/PopConfirm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../custom.css';
import { saveListItem, deleteListItem, deleteRequest } from '../../../../../core/apiRequests';
import { notificationSuccess, notificationError } from '../../../../../core/actions';

// this is for the row handler function later on
import { setListId, setListType } from '../../../../../reducers/autoRemMsgs';
import { getSingleDetails } from '../../../../../core/apiRequests';
import {
  setCustomTitle,
  setCustomName,
  setInc_notes,
  setScored,
  setCustomId,
} from '../../../../../reducers/addEditCustomActions';
import AutomationTable from '../../../../Layout/AutomationTable';
import { associateNameFormat } from '../../../../../utils';

const AddEditCustomActions = props => {
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (props.addOrEdit === 'Add' && props.customId && window.location.href.includes('add-client-activity')) {
      if (props.customId) {
        setCustomId('');
        setCustomName('');
        setInc_notes(false);
        setScored(false);
        // setCliPrefId('');
        // setCliPrefName('');
      }
    } else if (
      (window.location.href.includes('edit-client-activity') && !props.customId) ||
      (props.match.params.listItemId && Number(props.customId) !== Number(props.match.params.listItemId))
    ) {
      setCustomTitle('Edit');
      if (props.merchantId) {
        setCustomName('');
        setInc_notes(false);
        setScored(false);
        (async () => {
          const data = await getSingleDetails('clientEvent', props.merchantId, props.match.params.listItemId);
          if (data && data.length > 0) {
            const { description, inc_notes, scored, id } = data[0];
            setCustomTitle('Edit');
            setListType('clientEvent');
            setCustomName(description);
            setInc_notes(inc_notes);
            setScored(scored);
            setCustomId(id);
            setListId(id);
          } else {
            history.push(`/Configuration/client-activity`);
          }
        })();
      }
      // query pref info
    }

    return () => {};
  }, [props.merchantId]);

  const editCustomAction = () => {
    const { customTitle, allowNotes, allowScore, firstName, lastName } = props;
    if (!customTitle) {
      notificationError('Client Activity name required');
    } else {
      const createdBy = associateNameFormat(firstName, lastName);
      (async () => {
        const dataObj = {
          description: customTitle,
          notes: allowNotes,
          score: allowScore,
          createdBy,
        };
        if (props.customId) {
          dataObj.id = props.customId;
        }
        const data = await saveListItem('clientEvent', props.merchantId, dataObj);
        if (data.id) {
          setCustomId(data.id);
          if (props.addOrEdit === 'Edit') {
            notificationSuccess('Client Activity Updated');
          } else {
            notificationSuccess('Client Activity Created');
            setCustomTitle('Edit');
            history.push(`/Configuration/client-activity/edit-client-activity/${data.id}`);
          }
          setListId(data.id);
          // props.addOrEdit === 'Edit' ? notificationSuccess('Custom Action Updated') : notificationSuccess('Custom Action Created')
        } else {
          // error handling?
        }
      })();
    }
  };

  const confirmDelete = () => {
    const { firstName, lastName } = props;
    const fn = firstName ? firstName : '';
    const ln = lastName ? lastName : '';
    (async () => {
      const reqObj = {
        params: ['clientEvent', props.merchantId, 'remove', props.customId],
        query: {
          deletedBy: `${fn} ${ln}`.trim(),
        },
      };
      const data = await deleteRequest(reqObj);
      if (data) {
        notificationSuccess('Client Activity Removed');
        history.push(`/Configuration/client-activity`);
      }
    })();
  };

  return (
    <Layout
      title={`${props.addOrEdit} Client Activity`}
      description={
        props.addOrEdit === 'Add'
          ? 'Add an activity to keep track of client actions in the app.'
          : 'Edit your client activity and set up auto reminders and messages'
      }
      breadCrumbs={[
        { title: 'Configuration' },
        { title: 'Client Activity', to: '/Configuration/client-activity' },
        { title: `${props.addOrEdit} Client Activity` },
      ]}
      classProps={'standard-width-with-sidebar'}
    >
      <div className="custom-action-wrapper">
        <div className="align-left">
          <input
            id="ClientActivityName"
            className={`m-btm-15 m-top-15 fs-13 outline-none common_inputStyle max-w-600 w-100-P  left-0 input-height `}
            type="text"
            placeholder="Client Activity Name"
            value={props.customTitle}
            onChange={e => setCustomName(e.target.value)}
          />
        </div>
        <div className="checkBoxes fs-13">
          <CheckboxDesc text="Allow Notes" onclick={setInc_notes} checked={props.allowNotes}></CheckboxDesc>
          <CheckboxDesc text="Allow to be Scored" onclick={setScored} checked={props.allowScore}></CheckboxDesc>
        </div>
        {/* START of save / cancel / delete code  */}
        <div className="d-inline-block w-600">
          <div className="mq-w-100-vw custom-action-save-flex">
            <button id="AddClientActivitySaveButton" className="save-button-v2 m-btm-8" onClick={editCustomAction}>
              Save
            </button>
             {props.addOrEdit === 'Edit' ? (
            <Button
              id="DeleteClientActivityButton"
              classname="deleteButton-v2 "
              style={{ marginBottom: '8px' }}
              onclick={() => setShowPopConfirm(true)}
            >
              Delete
            </Button>
          ) : (
            <div />
          )}
             <StyledLink to="/Configuration/client-activity">
              <button id="AddClientActivityCancelButton" className="cancel-button-v2">
                Cancel
              </button>
            </StyledLink>
          </div>
        </div>
        {/* END BLOCK */}
        {props.addOrEdit === 'Edit' ? (
          <AutomationTable
            match={props.match}
            redirectToReminderSettings={`/Configuration/client-activity/edit-client-activity/${props.customId}`}
            redirectToAutoMsgSettings={`/Configuration/client-activity/edit-client-activity/${props.customId}`}
            redirectToAutoWhatsappSettings={`/Configuration/client-activity/edit-client-activity/${props.customId}`}
            type={'clientEvent'}
          />
        ) : null}
      </div>
      {showPopConfirm ? (
        <PopConfirm
          confirm={`Are you sure you want to remove "${props.customTitle}" Client Activity?`}
          description={'This will remove the Client Activity from all associated clients'}
          onCancel={() => setShowPopConfirm(false)}
          onConfirm={confirmDelete}
        />
      ) : null}
      <SecondaryPanel title={['Automation']} current="Client Activity" />
    </Layout>
  );
};
const mapStateToProps = state => ({
  ...state.addEditCustomActions,
  ...state.auth,
  ...state.notification,
  ...state.autoRemMsgs,
});

const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AddEditCustomActions);
