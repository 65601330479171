import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import StyledLink from '../../../Layout/StyledLink';
import Button from '../../../Layout/Button';
import Table from '../../../Layout/Table';
import Layout from '../../../Layout/StandardLayout';
import SecondaryPanel from '../../../Layout/SecondaryPanel';
import './custom.css';
import { getTablePagList } from '../../../../core/apiRequests';
import { getTablePaginationData, resetTablePagination } from '../../../../core/actions';
import { setListType, setListId } from '../../../../reducers/autoRemMsgs';
import {
  setEditCustomAction,
  setCustomTitle,
  setCustomName,
  setInc_notes,
  setScored,
  setCustomId,
} from '../../../../reducers/addEditCustomActions';
import FlaggedAddClientActivityBtn from './FlaggedAddClientActivityBtn';

const tableName = 'CUSTOM_ACTIONS';

const CustomActions = props => {
  const [tableData, setTableData] = useState([]);
  const [first, setFirst] = useState(true);
  const history = useHistory();
  const flags = props.auth.merchantFlags;
  const clientActivityEnabled = flags?.sales_opps?.enabled;

  useEffect(() => {
    if (props.auth.merchantId) {
      const { page, pageSize, sortColumn, ascendingColumn, searchText } = props.pagination;
      let data;
      if (first) {
        resetTablePagination({ sortColumn: 'description' }, tableName);
        data = async () => {
          const dataArr = await getTablePagList({
            tableName,
            type: 'clientEvent',
            merchantId: props.auth.merchantId,
            sortColumn: 'description',
          });
          setTableData(dataArr);
        };
        setFirst(false);
      } else {
        data = async () => {
          const dataArr = await getTablePagList({
            tableName,
            type: 'clientEvent',
            merchantId: props.auth.merchantId,
            page,
            pageSize,
            sortColumn,
            ascendingColumn,
            searchText,
          });
          setTableData(dataArr);
        };
      }
      data();
    }
  }, [
    props.auth.merchantId,
    props.pagination.sortColumn,
    props.pagination.pageSize,
    props.pagination.page,
    props.pagination.ascendingColumn,
    props.pagination.searchText,
  ]);

  const tableHeaders = [
    {
      keyName: 'description',
      style: { width: '50%', minWidth: '200px' },
      headerClassName: 'col-p-l-25 rem-pad-width',
      rowClassName: 'col-p-l-25 darkBlue',
      title: 'Client Activity Name',
      sortable: true,
    },
    {
      keyName: 'inc_notes',
      style: { width: '10%', minWidth: '150px', paddingRight: '22px' },
      headerClassName: 'align-right',
      rowClassName: 'align-right col-p-r-70 rem-pad-width',
      title: 'Allow Notes',
      render: (text, obj) => {
        return (
          <label className="tags-checkmark pos-rel">{text ? <FontAwesomeIcon icon={['fas', 'check']} /> : null}</label>
        );
      },
      sortable: true,
    },
    {
      keyName: 'scored',
      style: { width: '10%', minWidth: '130px', paddingRight: '22px' },
      headerClassName: 'align-right',
      rowClassName: 'align-right col-p-r-70 rem-pad-width',
      title: 'Scored',
      sortable: true,
      render: (text, obj) => {
        return (
          <label className="tags-checkmark pos-rel">{text ? <FontAwesomeIcon icon={['fas', 'check']} /> : null}</label>
        );
      },
    },
    {
      keyName: 'created_date',
      style: { width: '10%', minWidth: '150px', paddingRight: '22px' },
      headerClassName: 'align-right',
      rowClassName: 'align-right col-p-r-70 rem-pad-width',
      title: 'Created Date',
      sortable: true,
      render: (t, o) => {
        return moment(t, 'YYYYMMDD').format('MM/DD/YYYY');
      },
    },
    {
      keyName: 'created_by',
      style: { width: '10%', minWidth: '150px', paddingRight: '22px' },
      headerClassName: 'align-left',
      rowClassName: 'align-left col-p-r-70 rem-pad-width no-wrap',
      render: t => {
        if (t) {
          if (t.length > 20) {
            return `${t.slice(0, 20)}...`;
          }
          return t;
        }
        return '';
      },
      title: 'Created By',
      sortable: true,
    },
  ];

  const rowClickHandler = data => {
    if (clientActivityEnabled) {
      const { description, inc_notes, scored, id } = data;
      setCustomTitle('Edit');
      setListType('clientEvent');
      setCustomName(description);
      setInc_notes(inc_notes);
      setScored(scored);
      setCustomId(id);
      setListId(id);
      history.push(`/Configuration/client-activity/edit-client-activity/${id}`);
    }
  };

  return (
    <Layout
      title="Client Activity"
      description="Monitor client interactions by creating and supervising client activities"
      breadCrumbs={[{ title: 'Configuration' }, { title: 'Client Activity' }]}
      classProps="standard-width-with-sidebar"
    >
      <div className="w-100-P h-100-P">
        <Table
          tableClassName="lg-table-sidebar  rem-pad-width"
          data={tableData}
          tableHeaders={tableHeaders}
          searchable
          paginationV2
          optionalButton={<FlaggedAddClientActivityBtn merchantFlags={props.auth.merchantFlags} clearAction={props.clearAction} />}
          onRowClick={rowClickHandler}
          tableName={tableName}
        />
      </div>
      <SecondaryPanel title={['Automation']} current="Client Activity" />
    </Layout>
  );
};

const mapStateToProps = state => ({
  ...state,
  pagination: getTablePaginationData(state, tableName),
});
const mapDispatchToProps = dispatch => ({
  clearAction() {
    setEditCustomAction(
      {
        customId: '',
        customTitle: '',
        allowNotes: false,
        allowScore: false,
      },
      'Add',
    );
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomActions);
