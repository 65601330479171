import React from 'react';
import { bool, oneOf, func, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ICON = {
  trash: ['far', 'trash'],
  check: ['far', 'check'],
  info: ['far', 'info'],
  handshake: ['far', 'handshake'],
  bell: ['far', 'bell'],
  undo: ['far', 'undo'],
  paid: ['far', 'check-circle'],
};

const ActionButton = ({ actionButtonId, title, icon, onClick, disabled }) => {
  const pointerClass = disabled ? '' : 'pointer';

  return (
    <button
      id={actionButtonId}
      className={`w-33 h-33 bg-white border-r4 ${pointerClass}`}
      style={{ marginRight: 5, padding: 0, border: 'none' }}
      title={title}
      onMouseEnter={({ target }) => {
        target.style.backgroundColor = '#e8f6ff';
      }}
      onMouseLeave={({ target }) => {
        target.style.backgroundColor = '#ffffff';
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={ICON[icon]} className={`fs-16 ${pointerClass}`} />
    </button>
  );
};

ActionButton.propTypes = {
  actionButtonId: string,
  title: string,
  icon: oneOf(Object.keys(ICON)),
  onClick: func,
  disabled: bool,
};

export default ActionButton;
