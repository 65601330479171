import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import LayoutTable from '../../../Layout/Table';

import { setPrimaryAccount, setPrimaryAccountWithoutEffects, deleteBankAccount, getBankAccountsList } from '../apiRequests';
import { DeletePrimaryAccount, SetPrimaryAccount } from '../Dialogs';
import { BANK_ACCOUNT_STATUS_LABEL as STATUS_LABEL, BANK_ACCOUNT_STATUS as STATUS } from '../const';
import { sortByKey } from '../../../utils';
import ActionButton from './ActionButton';
import Bubble from '../Bubble';
import DeleteBankAccountModal from '../Dialogs/DeleteBankAccountModal';
import ReassignBankAccountModal from '../Dialogs/ReassignBankAccountModal';

const Table = ({ data, merchantId, sortColumn, ascendingColumn, tableName, list }) => {
  const [hoverItemId, setHoverItemId] = useState(null);
  const [primaryDialogItem, setPrimaryDialogItem] = useState(null);
  const [deleteDialogItem, setDeleteDialogItem] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmReassign, setShowConfirmReassign] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const history = useHistory();

  const handleReassign = async item => {
    try {
      await deleteBankAccount(merchantId, selectedBankAccount);

      setShowConfirmReassign(false);
      setSelectedBankAccount(null);

      await setPrimaryAccountWithoutEffects(merchantId, item.value);
      await getBankAccountsList(merchantId);
    } catch (error) {
      // ERROR HANDLER
    }
  };

  const handleDelete = async itemId => {
    try {
      await deleteBankAccount(merchantId, itemId);

      setSelectedBankAccount(null);
      setShowConfirmDelete(false);

      await getBankAccountsList(merchantId);
    } catch (error) {
      // ERROR HANDLER
    }
  };

  // Because there is no pagination, we can handle sorting on the FE
  const getSortedList = useCallback(() => {
    let dataActive = data.some(x => x.status === STATUS.PRIMARY);
    if (!dataActive) {
      // setPrimaryAccount(merchantId, data[0].id); // I (Chris) commented this out, I have no idea why Asher was calling a PUT request on a function that's called in the render method.
    }

    const getValue = ({ key, value }) => (key === 'status' ? STATUS_LABEL[value].order : value);

    return sortByKey(data, sortColumn, ascendingColumn, { getValue });
  }, [data, sortColumn, ascendingColumn]);

  const rowClickHandler = (data, e) => {
    if (e.target.tagName === 'TD') {
      history.push(`/BankAccount/details/${data.id}`);
    }
  };

  return (
    <>
      <LayoutTable
        tableName={tableName}
        tableHeaders={[
          {
            keyName: 'name',
            title: 'Account',
            sortable: true,
            rowStyle: { paddingRight: 20 },
          },
          {
            keyName: 'ownerName',
            title: 'Account Owner',
            rowStyle: { paddingRight: 20 },
            sortable: true,
          },
          {
            keyName: 'status',
            title: 'Status',
            sortable: true,
          },
          {
            keyName: 'stores',
            title: 'Store Location(s)',
            render: item => item?.split(',').join(', ') || '',
          },
          {
            keyName: 'actions',
            title: 'Actions',
          },
        ]}
        noPointer={true}
        data={getSortedList().map(item => {
          return {
            ...item,
            status: <Bubble {...STATUS_LABEL[item.status]} />,
            actions: (
              <span className="hide-under-800 no-wrap" style={{ display: 'flex' }}>
                <div style={{ visibility: hoverItemId === item.id ? 'visible' : 'hidden' }}>
                  <ActionButton
                    icon="edit"
                    onClick={() => {
                      history.push(`/BankAccount/details/${item.id}`);
                    }}
                  />
                </div>

                <div style={{ visibility: hoverItemId === item.id ? 'visible' : 'hidden' }}>
                  <ActionButton
                    icon="trash"
                    disabled={item.isPrimary && list.length < 2}
                    onClick={() => {
                      setSelectedBankAccount(item.id);

                      if (item.isPrimary) {
                        setShowConfirmReassign(true);
                      } else {
                        setShowConfirmDelete(true);
                      }
                    }}
                  />
                </div>

                <div
                  style={{
                    visibility: hoverItemId === item.id || item.isPrimary ? 'visible' : 'hidden',
                    color: item.isPrimary ? '#000' : '#bdbdbd',
                  }}
                >
                  <ActionButton
                    icon="star"
                    onClick={() => {
                      setPrimaryDialogItem(item);
                    }}
                    disabled={item.isPrimary}
                  />
                </div>
              </span>
            ),
            onMouseOver: ({ id }) => setHoverItemId(id),
            onMouseLeave: () => setHoverItemId(null),
          };
        })}
        onRowClick={rowClickHandler}
      />

      {primaryDialogItem ? (
        <SetPrimaryAccount
          name={primaryDialogItem.name}
          onConfirm={() => setPrimaryAccount(merchantId, primaryDialogItem.id).then(setPrimaryDialogItem(null))}
          onCancel={() => setPrimaryDialogItem(null)}
        />
      ) : null}

      {deleteDialogItem ? (
        <DeletePrimaryAccount
          onConfirm={() => handleDelete(deleteDialogItem.id).then(setDeleteDialogItem(null))}
          onCancel={() => setDeleteDialogItem(null)}
        />
      ) : null}

      {showConfirmReassign ? (
        <ReassignBankAccountModal
          current={selectedBankAccount}
          onCancel={() => {
            setSelectedBankAccount(null);
            setShowConfirmReassign(false);
          }}
          onConfirm={handleReassign}
        />
      ) : null}

      {showConfirmDelete ? (
        <DeleteBankAccountModal
          onCancel={() => {
            setSelectedBankAccount(null);
            setShowConfirmDelete(false);
          }}
          onConfirm={() => handleDelete(selectedBankAccount)}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = state => ({
  list: state.bankAccounts.list,
});

export default connect(mapStateToProps, {})(Table);
