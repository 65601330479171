import React from 'react';

const LeadBody = ({ children, ...rest }) => {
  const newComponent = React.cloneElement(children, {
    ...rest,
  });

  return <div>{newComponent}</div>;
};

export default LeadBody;
