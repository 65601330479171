import store from '../core/store';
import { dispatcher } from '../core/actions';

const initialState = {
  reminderIntv: 'weekly',
  store: 0,
  storeid: '',
  actionType: 0,
  associate: '',
  stores: [],
};

export default function reducer(state = initialState, action) {
  let state2 = state;
  switch (action.type) {
    case 'SET_REPORT_REMIND_INT':
      return { ...state2, reminderIntv: action.payload };
    case 'SET_REPORT_STORE':
      return { ...state2, store: action.payload };
    case 'SET_REPORT_STORE_ID':
      return { ...state2, storeid: action.payload };
    case 'SET_REPORT_ACTION_TYPE':
      return { ...state2, actionType: action.payload };
    case 'SET_REPORT_ASSOCIATE':
      return { ...state2, associate: action.payload };
    case 'SET_REPORT_STORES':
      return { ...state2, stores: [...action.payload] };
    default:
      break;
  }
  return state2;
}

export const setReminderIntv = val => {
  dispatcher('SET_REPORT_REMIND_INT', val);
};
export const setReminderStore = val => {
  dispatcher('SET_REPORT_STORE', val);
};
export const setReminderStoreId = val => {
  dispatcher('SET_REPORT_STORE_ID', val);
};
export const setReportActionType = val => {
  dispatcher('SET_REPORT_ACTION_TYPE', val);
};
export const setReportAssoc = val => {
  dispatcher('SET_REPORT_ASSOCIATE', val);
};
export const setReportStores = val => {
  dispatcher('SET_REPORT_STORES', val);
};
