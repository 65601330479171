import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './styles.css';

const CollectionsListHeader = ({ setShowCreateModal, showCreateModal, flags}) => {
  const showWishlists = flags?.wishlists?.enabled;

  return (
    <div className="clients-v2-collections-list--header">
      <span>Collections List</span>
      {showWishlists && (
        <Button type="text" icon={<PlusOutlined />} onClick={() => setShowCreateModal(!showCreateModal)} />
      )}
    </div>
  );
};

export default CollectionsListHeader;
