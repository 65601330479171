import moment from "moment";

export const filterList = ({ data = [], filters, startDate, endDate }) => {

  return data?.filter(item => {

    let filterStore = filters.store.length === 0 || filters.store.includes(item?.activity_store_id?.toString());
    let filterAssociate = filters.associate.length === 0 || filters.associate.includes(item?.activity_associate_id?.toString());
    let filterTimeSpan = moment(item.sales_transaction_date || item.sales_transaction_date_time).isBetween(moment(startDate), moment(endDate), null, '[]');
    return filterStore && filterAssociate && filterTimeSpan;
  });
};
