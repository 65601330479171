import moment from 'moment';
import { notificationError, notificationSuccess } from '../../../../../core/actions';
import { postRequest } from '../../../../../core/apiRequests';
import store from '../../../../../core/store';
import { setMsgChatMessages, setInboxList, setStoreInboxList } from '../../../../../reducers/messaging';
import { actionTextAreaFileActions } from '../../../TextAreaWithActions/useActionTextAreaFiles';
import { associateNameFormat } from '../../../../../utils';

const containsSubstring = substr => str => str.includes(substr);

export default async ({
  messageTxt,
  fileData,
  chatId,
  clientId,
  paymentRequestID,
  timeOffset,
  setMessageTxt,
  fileDispatch,
  archive,
  type,
}) => {
  const props = store.getState();
  const { merchantId, firstName, lastName, selectedStore } = props.auth;
  const { sendStoreId } = props.messaging.client;
  if (messageTxt.length || fileData.images.urls.length || fileData?.videos?.urls?.length) {
    if (props.messaging.client.chat_id && merchantId) {
      // clean message square brackets and dispatch message to backend
      const bodyData = {
        clientId,
        chatId,
        msg: messageTxt.replace('[Review Link]', ':review:'),
        associateName: associateNameFormat(firstName, lastName),
        // storeId: sendStoreId || selectedStore,
        s3Images: fileData.images.urls.filter(containsSubstring('s3.amazonaws.com')),
        s3Videos: fileData.videos.urls.filter(containsSubstring('s3.amazonaws.com')),
        paymentRequestID,
        archive,
        type,
      };
      bodyData.storeId = sendStoreId || selectedStore;

      setMessageTxt('');
      // starting here
      const reqObj = {
        params: ['chats', 'sendChatMessage'],
        query: {},
        data: bodyData,
        images: {
          urls: fileData.images.urls.filter(containsSubstring('blob')),
          files: fileData.images.files,
        },
        videos: {
          urls: fileData.videos.urls.filter(containsSubstring('blob')),
          files: fileData.videos.files,
          videoFrames: fileData.videos.videoFrames,
        },
      };
      const respData = await postRequest(reqObj);
      if (respData.chatId) {
        notificationSuccess(archive ? 'Conversation Closed' : 'Message Successfully Sent');
        const tempChatList = props.messaging.chatMessages;
        const cutFilteredConvo = tempChatList.slice(0, 1);
        let shouldWeShift = true;
        const compareMessage = messageTxt ? messageTxt.replace('[Review Link]', ':review:') : null;
        cutFilteredConvo.forEach(conversation => {
          if (
            conversation.user_id === props.auth.userId &&
            conversation.msg === compareMessage &&
            !fileData.videos.urls.length
          ) {
            shouldWeShift = false;
          }
        });
        if (archive) {
          setInboxList(props.messaging.inboxList.filter(item => item.id !== respData.chatId));
          setStoreInboxList(props.messaging.storeInboxList.filter(item => item.id !== respData.chatId));
        } else if (shouldWeShift) {
          const fakeChatObj = {
            client_id: props.messaging.client.client_id,
            id: `FAKE${props.messaging.client.client_id}${Math.floor(Math.random() * 9999)}`,
            chatId: respData.chatId,
            imageUrl: null,
            collectionId: null,
            collectionName: null,
            message: messageTxt.replace('[Review Link]', ':review:'),
            productId: null,
            productImage: null,
            productName: null,
            productPrice: null,
            sent: +moment().subtract(timeOffset, 'minutes').format('YYYYMMDDHHmmss'),
            sentTimestamp: moment(),
            storeName: null,
            thumbnailUrl: null,
            userId: props.auth.userId,
            userName: `${props.auth.firstName || ''} ${props.auth.lastName || ''}`,
            userImage: `${props.auth.image || ''}`,
            videoUrl: null,
            uuid: respData.uuid,
          };
          fakeChatObj.storeId = sendStoreId || selectedStore;
          if (messageTxt) {
            tempChatList.unshift(fakeChatObj);
          }

          if (fileData?.images?.urls?.length > 0) {
            fileData.images.urls.forEach(url => {
              tempChatList.unshift({ ...fakeChatObj, imageUrl: url, fakeImage: true, message: null });
            });
          } else if (fileData.videos.urls.length > 0) {
            fileData.videos.urls.forEach((url, index) => {
              tempChatList.unshift({
                ...fakeChatObj,
                videoUrl: url,
                thumbnailUrl: fileData.videos.videoFrames.urls[index],
                fakeVideo: true,
              });
            });
          }

          setMsgChatMessages([...tempChatList]);
        }
        fileDispatch({
          type: actionTextAreaFileActions.clear,
        });
      } else {
        notificationError('Failed To Send Message');
      }
    }
  } else {
    notificationError('Please Type A Message');
  }
};
