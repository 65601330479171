import React, { memo, useMemo } from 'react';
import Chart from '../../../components/Layout/Chart';
import { calculateDatasets, calculateLabels, calculateStep } from './helpers/chartHelpers';
import { Card } from 'antd-v5';

const ReportChartBar = ({ graphData, timeSpan, stacked = false, maxTicksLimit = 11, labelLines = 1 }) => {
  const chartConfigBuilder = useMemo(() => {
    const { startDate, endDate } = timeSpan;
    const labelArr = calculateLabels(startDate, endDate);

    const datasets = calculateDatasets({ graphData, startDate, endDate });

    const yStep = calculateStep(datasets);

    return {
      type: 'bar',
      data: {
        labels: labelArr,
        datasets,
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          position: 'bottom',
          align: 'start',
          labels: {
            padding: 30,
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        scales: {
          yAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                drawBorder: true,
              },
              ticks: {
                min: 0,
                stepSize: yStep,
                padding: 8,
                maxTicksLimit,
              },
              stacked: stacked
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
              },
              ticks: {
                display: true,
                padding: 8,
              },
              stacked: stacked
            },
          ],
        },
      },
    };
  }, [graphData, timeSpan, stacked, maxTicksLimit]);
  return (
    <Card style={{ minHeight: '300px', margin: '35px 0px' }}>
      <Chart configObj={chartConfigBuilder} labelLines={labelLines} />
    </Card>
  );
};

export default memo(ReportChartBar, (prev, next) => {
  let isEqual = true;
  if( prev.graphData !== next.graphData ) {
    isEqual = false;
  } else if( prev.timeSpan.startDate !== next.timeSpan.startDate ) {
    isEqual = false;
  } else if( prev.labelLines !== next.labelLines ) {
    isEqual = false;
  }else if( prev.timeSpan.endDate !== next.timeSpan.endDate ) {
    isEqual = false;
  } else if( prev.stacked !== next.stacked ) {
    isEqual = false;
  } else if( prev.maxTicksLimit !== next.maxTicksLimit ) {
    isEqual = false;
  }
  return isEqual;
});
