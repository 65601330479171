
import React from 'react';
import { useHistory } from 'react-router';

const FlaggedViewSalesLogBtn = ({flags}) => {
  const history = useHistory();
  const viewPurchaseHistory = flags?.purchase_history?.enabled;

  if (!viewPurchaseHistory) {
    return null;
  }
  return (
    <span
      className="blue-link pointer m-right-24 fw-500 fs-13"
      onClick={() => history.push('/Configuration/sales-triggers/sales-history')}
    >
      View Sales Log
    </span>
  );
};
export default FlaggedViewSalesLogBtn;
