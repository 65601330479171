export const buildListFromResponse = (res, sentObj, clientName) => {
  if (!res.hasOwnProperty('data') || !res.data.hasOwnProperty('paymentRequest')) return;

  const {
    linkTracking,
    communications,
    paymentRequestProducts,
    paymentRequest,
    refunds,
    statusChanges,
    transactionDetails,
    transactions,
  } = res.data;

  const eventList = [sentObj];

  eventList.push(
    ...linkTracking.map(item => {
      return {
        type: 'VIEWED',
        date: item.viewedAt,
        clientName: clientName
      };
    }),
  );

  eventList.push(
    ...communications.map(item => {
      return {
        type: "REMINDER",
        date: item.createdAt,
        message: item.commMessage,
        sentBy: item.associateName,
        clientName: clientName
      };
    }),
  );

  statusChanges.forEach(item => {
    if(item.status === 'PAID_MANUALLY' ||
    item.status === 'PAID_BY_CC' ||
    item.status === 'PAID_WITH_APPLE_PAY' ||
    item.status === 'PAID_WITH_GOOGLE_PAY') {
        eventList.push({
            type: 'PAID',
            date: item.changeAt,
            message: item.reason,
            clientName: clientName,
        });
    }
  })

  return eventList.sort((a, b) => new Date(b.date) - new Date(a.date));
//   eventList.push(

//   )

  //linkTracking: For clicks
  //communications: List of outbound messages
  //paymentRequestProducts : List of products
  // hasExpired: if link has expired
  // paymentRequest: // paymentInfo {paymentRequestId: 2046, storeId: 3655, shopperId: 243, expireAt: null, totalAmount: '2.00', …}
  // refunds: []
  // statusChanges: [{…}]
  // transactionDetails: {fee: 0.35, netAmount: 1.65}
  // transactions: // wtf is this

  //I need:
  // Paid date, viewed dates (linkTracking), reminders (communications) sent, paymentRequest created Date

  console.log('the response: ', res);
};
