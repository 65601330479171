const SANITIZE_REGEX = /[^0-9.-]+/g;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const formatToCurrency = value => {
  const sanitizedValue = String(value).replace(SANITIZE_REGEX, '');
  const number = parseFloat(sanitizedValue);

  if (Number.isNaN(number)) {
    return '$--.--';
  }

  return formatter.format(number);
};

export default formatToCurrency;
