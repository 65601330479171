import Axios from 'axios';
import { getPaymentUrl } from './getBaseUrl';
import { getHeaders } from './getHeaders';

const baseURL = getPaymentUrl();
const headers = getHeaders();
const timeout = 20000;

const paymentHttpRequest = Axios.create({
  baseURL,
  headers,
  timeout,
});

paymentHttpRequest.interceptors.request.use(
  config => ({
    ...config,
    headers: {
      ...config.headers,
      ...getHeaders(),
    },
  }),
  response => response,
  error => Promise.reject(error),
);

paymentHttpRequest.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
);

export const updateRequestHeaders = ({ merchantId, associateId, token }) => {
  paymentHttpRequest.defaults.headers.common['x-clientbook-user-id'] = associateId;
  paymentHttpRequest.defaults.headers.common['x-clientbook-merchant-id'] = merchantId;
  paymentHttpRequest.defaults.headers.common.Authorization = token;
};

export default paymentHttpRequest;
